import { Injectable, OnDestroy } from '@angular/core';
import { Product } from '../interfaces/product';
import { Observable, Subject, timer } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class QuickviewService implements OnDestroy {
    private destroy$: Subject<void> = new Subject();
    private showSubject$: Subject<Product> = new Subject();
    etaList:etaList;

    private showETASubject$: Subject<etaList> = new Subject();

    show$: Observable<Product> = this.showSubject$.pipe(takeUntil(this.destroy$));
    showEta$: Observable<etaList> = this.showETASubject$.pipe(takeUntil(this.destroy$));

    constructor() { }

    show(product: Product): Observable<void> {
        // timer only for demo
        return timer(1000).pipe(map(() => {
            this.showSubject$.next(product);
        }));
    }

    showEta(etadetails): Observable<void> {
        // timer only for demo
        return timer(1000).pipe(map(() => {
            this.showETASubject$.next(etadetails);
        }));
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
export interface etaList {
    ExpectedDate: Date;
    days: number;
    part_num: any;
    part_desc:any;
    qty: any;
}