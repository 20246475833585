<div class="qrScanner">
    <div class="modal-header modal-title" style="font-weight: 700;">
        QR SCAN
        <div (click)="closeModelFunction()" type="button"><i class="fa fa-times"></i></div>
    </div>
    <!-- <div class="buttonUpload">
      <button class="btn btnbtn btn-primary" id="header-bulk-upload" 
      (click)="buttonAction('scanqr')">Scan Qr</button>
      <button class="btn btnbtn btn-success" id="header-bulk-upload" 
      (click)="buttonAction('uploadqr')">Upload Qr</button>
    </div> -->
    <div>
      <ngx-scanner-qrcode  #action="scanner" (event)="getCameraQrEvent($event,action)" [canvasStyles]="canvasstyle"></ngx-scanner-qrcode>
    <div class="buttonUpload">
      <button #myButton class="btn btnbtn btn-primary" (click)="handle(action, action.isStart ? 'stop' : 'start')">Scan QR
  </button>
      <button class="btn btnbtn btn-success" id="header-bulk-upload" 
      (click)="buttonAction('uploadqr')">Upload QR</button>
    </div>
    <p *ngIf="action.isLoading">⌛ Loading...</p>
    </div>
    
  
      <div *ngIf="uploadQrSelected">
        <h2 style="font-size: 17px;
        text-align: center;
        font-weight: 700;">Upload QR for Scanning</h2>
        <!-- <span style="margin-left: 10px;
        font-weight: 700;">
        <span style="color: red;">Note:
        </span> Please Upload Clear QR Image
      </span> -->
      <div class="row" style="width: 100%;
      margin: 0 auto;">
        <h6 style="font-weight: bold;
        color: red;
        font-size: 15px">Instructions:</h6>
        <ul style="margin-left:20px">
            <li style="font-weight: 500;">Kindly upload the QR code printed on the TGP part packing.</li>
            <li style="font-weight: 500;">Generic QR codes and any other images uploaded will not be scanned.</li>
        </ul>
    </div>
        <input style="padding: 10px;" #file type="file" (change)="onSelects(file.files)" [multiple]="'multiple'" [accept]="'.jpg, .png, .gif, .jpeg'"/>

        <div *ngFor="let item of qrCodeResult">
          <ngx-scanner-qrcode #actionFile="scanner" [src]="item.url" [config]="config" (event)="gtetfileseletctecte($event,actionFile)" [canvasStyles]="canvasstyle"></ngx-scanner-qrcode>
        </div>
        <!-- <span style="text-align: center;font-size: 16px;margin-top: 10px;">{{qrCodeResult}}</span> -->
      </div>
      <!-- <canvas #canvas style="display:none;"></canvas> -->
    <!-- <p>Scanned Result: {{ scannedResult }}</p> -->
  </div> 
  <ng-container *ngIf="showLoader">
    <div class="loader">
        <div class="ng-spinner ng-spinner-icon"></div>
    </div>
</ng-container>