<div class="modal-header modal-title">
    Scheme Details
    <div (click)="closeModel.emit()" type="button"><i class="fa fa-times"></i></div>
</div>
<div class="modal-body">
    <div *ngIf="schemeDetails.scheme_detail" class="row">
        <div class="col-12">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <ul class="feedback-order-series">
                    <li>
                        <div class="o-title">Scheme Name</div>
                        <div class="o-desc">{{schemeDetails.scheme_detail[0].scheme_name }}</div>
                    </li>
                    <!-- <li>
                        <div class="o-title">Scheme Code</div>
                        <div class="o-desc">{{schemeDetails.scheme_detail[0].scheme_code }}</div>
                    </li> -->
                    <!-- <li>
                        <div class="o-title">Scheme Type</div>
                        <div class="o-desc">{{schemeDetails.scheme_detail[0].scheme_type }}</div>
                    </li> -->
                    <!-- <li>
                        <div class="o-title">Scheme Description</div>
                        <div class="o-desc">{{schemeDetails.scheme_detail[0].scheme_description?schemeDetails.scheme_detail[0].scheme_description:'-' }}</div>
                    </li> -->
                    <!-- <li>
                        <div class="o-title">Created Date</div>
                        <div class="o-desc">{{schemeDetails.scheme_detail[0].created_at | date:"dd-MM-yyyy h:mm:ss a" }}</div>
                    </li> -->
                    <li>
                        <div class="o-title">Scheme Start Date</div>
                        <div class="o-desc">{{schemeDetails.scheme_detail[0].start_dt | date:"dd-MM-yyyy"}}</div>
                    </li>
                    <li>
                        <div class="o-title">Scheme End Date</div>
                        <div class="o-desc">{{schemeDetails.scheme_detail[0].end_dt | date:"dd-MM-yyyy"}}</div>
                    </li>
                </ul>
            </div>
        </div>
        <div *ngIf="schemeDetails.scheme_detail[0].scheme_type == 'value_scheme';else partQuantityScheme" class="col-11 p-5 pt-3 ps-3 pe-5 me-3 ms-3 card shadow">
            <div class="row">
            <div class="col-md-3">
                <div class="o-title1 mb-3">Order Value Progress :</div>
            </div>
            <div class="col-md-3">
                <div class="o-title1 mb-3 ml-3">Invoiced Amount  : {{schemeDetails.total_amount.invoice_amount}}</div>
            </div>
            <!-- <div class="col-md-3">
                <div class="o-title1 mb-3 ml-3">Eligible Discount Percentage : {{this.scheme_percentage_applied.length > 0 ? this.scheme_percentage_applied[0]:0 }}%</div>
            </div> -->
            </div>  
            <div class="col-11">
                <div class="progress" id="progress_element">
                    <div id="progressBarFill" class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar" [style.width.%]="progress" aria-valuenow="{{progress}}" aria-valuemin="0" aria-valuemax="100">
                        <span><b>Invoice Amount: {{schemeDetails.total_amount.invoice_amount}}</b></span>
                    </div>
                </div>
                <div class="d-flex">
                    <ng-container *ngFor="let point of points;let i=index">
                        <div class="point element" [style]="i!=(points.length - 1)?'margin-left:'+lengt*i+'px':'margin-left:'+lengt*i+'px'" id="progress_bar_main_{{i}}" style="position: absolute;">
                            <!-- (i==0?0:(point/points[points.length - 1])*100) -->
                            <div id="progress_bar_{{i}}" class="vline" [ngClass]="i % 2 === 0 ? 'vline-min' : 'vline-min'"></div>
                            <!-- <div class="circle circle-black"></div> -->
                            {{point}}
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <ng-template #partQuantityScheme>
            <div class="col-12 mb-3" [class]="points.length > 2?'setMaxCardListHeight':''">
                <div class="o-title1 mb-3">Part Quantity Progress :</div>
                <ng-container *ngFor="let item of points; let i=index">
                    <div class="card shadow mb-2 p-5 pt-3 pl-3 pb-3">
                        <div class="row">
                            <div class="col-md-10">
                                <h6 style="margin-bottom: 15px;margin-top: 10px; color: #000;">Part Number: <span title="{{ (item?.part_number == '' || item?.part_number == null) ? '-' : item?.part_number}}" class="part-number-text">{{item.part_number}}</span></h6>
                            </div>                         
                        </div>
                        <div class="row">
                            <div class="col-md-5">
                                <h6 style="margin-bottom: 15px;margin-top: 10px; color: #000;">Invoiced Quantity: {{item.totalQuantity?item.totalQuantity:0 }}</h6>
                            </div>
                            <!-- <div class="col-md-5">
                                <h6 style="margin-bottom: 15px;margin-top: 10px; color: #000;">Eligible Discount Percentage: {{item.slabPerc?item.slabPerc:0 }}%</h6>
                            </div> -->
                        </div>
                        <div class="progress" id="progress_element_{{i}}">
                            <div id="progressBarFill_{{i}}" class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar" [style.width.%]="item.progress" [aria-valuenow]="item.progress" aria-valuemin="0" aria-valuemax="100">
                            </div>
                        </div>
                        <div class="d-flex mb-4 pb-2">
                            <ng-container *ngFor="let point of item.list;let j=index">
                                <div class="point element" [style]="j!=(item.list.length - 1)?'margin-left:'+(item.mar_leng * j)+'px':'margin-left:'+(item.mar_leng * j)+'px'" style="position: absolute;">
                                    <div id="progress_bar_{{i}}_{{j}}" class="vline" [ngClass]="j % 2 == 0 ? 'vline-min' : 'vline-min'"></div>    
                                    {{point}}
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-template>
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3 pt-3">
            <div class="form-group table-responsive tableFixHead table-container">
                <div class="o-title1 mb-3 mt-3">Slab Details :</div>
                <div [class]="schemeDetails.scheme_detail[0].slab_detail.length > 4 ?'table-max-height-scroll':''">
                <table class="table datatable display shadow w-100 table-bordered">
                    <thead class="table-light">
                        <tr class="text-center">
                            <th *ngIf="this.schemeDetails.scheme_detail[0].scheme_type == 'quantity_scheme'" class="text-nowrap th-headingfont">Part Number</th>
                            <th class="text-nowrap th-headingfont">{{ schemeDetails.scheme_detail[0].scheme_type == 'quantity_scheme'?'Min. QTY':'Min. Value' }}</th>
                            <th class="text-nowrap th-headingfont">{{ schemeDetails.scheme_detail[0].scheme_type == 'quantity_scheme'?'Max. QTY':'Max. Value' }}</th>
                            <th class="text-nowrap th-headingfont">Discount Percentage</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngIf="schemeDetails.scheme_detail[0].slab_detail">
                            <tr *ngFor="let item of schemeDetails.scheme_detail[0].slab_detail; let i = index" class="text-center">
                                <td *ngIf="this.schemeDetails.scheme_detail[0].scheme_type == 'quantity_scheme'" class="text-nowrap">{{item?.part_number}}</td>
                                <td class="text-nowrap">{{ item?.slab_from }}</td>
                                <td class="text-nowrap">{{ item?.slab_to }}</td>
                                <td class="text-nowrap">{{ ( (item?.percentage) % 1 != 0)?item?.percentage:(item?.percentage | number: '1.0-0') }}%</td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="schemeDetails.scheme_detail[0].slab_detail.length == 0">
                            <tr>
                                <td> <p class="noPartData">No Data Found</p></td>
                            </tr>
                      </ng-container>
                    </tbody>
                </table>
                </div>
            </div>
        </div>
    </div>   
</div>