<div class="modal-content" style="width: 100%; margin-left: 0px;">
    <div class="modal-header modal-title">
        Mechanic Details
        <div (click)="closeModal()" type="button"><i class="fa fa-times"></i></div>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <ul class="feedback-order-series">
                    <li>
                        <div class="o-title">Garage Name</div>
                        <div class="o-desc">{{ mechanicDetails?.garage_name }}</div>
                    </li>
                    <li>
                        <div class="o-title">Mechanic Name</div>
                        <div class="o-desc">{{ mechanicDetails?.mechanic_name }}</div>
                    </li>
                    <li>
                        <div class="o-title">Address</div>
                        <div class="o-desc">{{ mechanicDetails?.garage_address }}</div>
                    </li>
                    <li>
                        <div class="o-title">Phone Number</div>
                        <div class="o-desc" >{{mechanicDetails.user__username}}</div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>