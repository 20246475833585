  <!-- <section>
    <mat-card *ngIf="errorMessage$| async as errorMessage" class="notification">{{errorMessage}}</mat-card>
  </section>
  <section> -->
    <!-- <mat-form-field>
      <mat-label>Select your language</mat-label>
      <mat-select [(value)]="currentLanguage">
        <mat-option *ngFor="let language of languages" [value]="language" (click)="selectLanguage(language)">
          {{language}}
        </mat-option>
      </mat-select>
    </mat-form-field> 
  </section>-->
  <!-- <section> -->
    <button class="custom-btn" mat-fab *ngIf="listening$ | async; else mic" (click)="stop('stopclicked')" title="Click to Stop Listening…">
      <!-- <mat-icon class="soundwave">mic</mat-icon> -->
      <i class="fas fa-microphone  soundwave "></i>
    </button>
    <ng-template #mic>
      <button class="custom-btn"  mat-fab (click)="start($event )" title="Click to Start Listening…">
        <!-- <mat-icon aria-hidden="true">mic</mat-icon> -->
        <i class="fas fa-microphone"></i>
      </button>
    </ng-template>
  <!-- </section> -->
<!-- <section *ngIf="transcript$ | async">
  <mat-card class="notification mat-elevation-z4">{{transcript$ | async}}</mat-card>
</section> -->
<!-- <section>
  <mat-form-field class="speech-result-width">
    <textarea matInput [value]="totalTranscript || ''" placeholder="Speech Input Result" rows="15" disabled="true"></textarea>
  </mat-form-field>
</section> -->
