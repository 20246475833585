import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { Product } from "../interfaces/product";
import { CartItem } from "../interfaces/cart-item";
import { BehaviorSubject, Observable, Subject, timer } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { isPlatformBrowser } from "@angular/common";
import { ProductService } from "src/app/shared/services/REST/product.service";
import { AppStateService } from "./app-state/app-state.service";
import { ToastrService } from 'ngx-toastr';
import { GoogleAnalyticsService } from "src/app/shared/services/GoogleAnalytics/GoogleAnalytics.service";
import { UserService } from './REST/user.service';
import { RootService } from "./REST/root.service";
import { secureStorage } from "./securestorage";
import { Router } from "@angular/router";
import { FormControl, Validators } from "@angular/forms";
import Swal from "sweetalert2";


interface CartTotal {
    title: string;
    price: number;
    type: "shipping" | "fee" | "tax" | "other";
}

interface CartData {
    items: CartItem[];
    items1?: CartItem[];
    allitems?: CartItem[]; //dynamic Cart
    quantity: number;
    quantity1?: number;
    subtotal: number;
    subtotal1?: number;
    totals: CartTotal[];
    totals1?: CartTotal[];
    total: number;
    total1?: number;
}

@Injectable({
    providedIn: "root"
})
export class CartService {
    public data: CartData = {
        items: [],
        items1: [],
        allitems: [],//dynamic Cart
        quantity: 0,
        subtotal: 0,
        totals: [],
        total: 0
    };

    public itemsSubject$: BehaviorSubject<CartItem[]> = new BehaviorSubject(
        this.data.items
    );
    private itemsSubject1$: BehaviorSubject<CartItem[]> = new BehaviorSubject(
        this.data.items1
    );

    //dynamic Cart
    private allitemsSubject$: BehaviorSubject<CartItem[]> = new BehaviorSubject(
        this.data.allitems
    );

    private quantitySubject$: BehaviorSubject<number> = new BehaviorSubject(
        this.data.quantity
    );
    private quantitySubject1$: BehaviorSubject<number> = new BehaviorSubject(
        this.data.quantity1
    );
    private subtotalSubject$: BehaviorSubject<number> = new BehaviorSubject(
        this.data.subtotal
    );
    private subtotalSubject1$: BehaviorSubject<number> = new BehaviorSubject(
        this.data.subtotal
    );
    private totalsSubject$: BehaviorSubject<CartTotal[]> = new BehaviorSubject(
        this.data.totals
    );
    private totalsSubject1$: BehaviorSubject<CartTotal[]> = new BehaviorSubject(
        this.data.totals1
    );
    private totalSubject$: BehaviorSubject<number> = new BehaviorSubject(
        this.data.total
    );
    private totalSubject1$: BehaviorSubject<number> = new BehaviorSubject(
        this.data.total1
    );
    private onAddingSubject$: Subject<Product> = new Subject();
    billingaddressid: string;
    responseItems: any[];

    get items(): ReadonlyArray<CartItem> {
        return this.data.items;
    }
    get items1(): ReadonlyArray<CartItem> {
        return this.data.items1;
    }
    get allitems(): ReadonlyArray<CartItem> {
        return this.data.allitems;
    }

    get quantity(): number {
        return this.data.quantity;
    }
        public message = new BehaviorSubject('');
    sharedMessage = this.message.asObservable();
    readonly items$: Observable<CartItem[]> = this.itemsSubject$.asObservable();
    readonly items1$: Observable<CartItem[]> = this.itemsSubject1$.asObservable();
    readonly allitems$: Observable<CartItem[]> = this.allitemsSubject$.asObservable();
    readonly quantity$: Observable<number> = this.quantitySubject$.asObservable();
    readonly quantity1$: Observable<number> = this.quantitySubject1$.asObservable();
    readonly subtotal$: Observable<number> = this.subtotalSubject$.asObservable();
    readonly subtotal1$: Observable<number> = this.subtotalSubject1$.asObservable();
    readonly totals$: Observable<CartTotal[]> = this.totalsSubject$.asObservable();
    readonly totals1$: Observable<CartTotal[]> = this.totalsSubject1$.asObservable();
    readonly total$: Observable<number> = this.totalSubject$.asObservable();
    readonly total1$: Observable<number> = this.totalSubject1$.asObservable();
    readonly onAdding$: Observable<Product> = this.onAddingSubject$.asObservable();
    private ngUnsubscribe: Subject<void> = new Subject();
    isLoggedIn: boolean = false;
    products = [];
    products1 = [];
    obj = { product: [], quantity: Number };
    address_pk: any;
    OtherUserPermission: any;
    shippingaddressid: any;
    recentItems = [];
    public isProfileDropdownOpened: boolean = false;
    isCartOpenobs = new BehaviorSubject<any>(false);
    constructor(
        @Inject(PLATFORM_ID)
        private platformId: any,
        private rootService: RootService,
        private googleAnlyatics: GoogleAnalyticsService,
        private productService: ProductService,
        private appStateService: AppStateService,
        private userService: UserService,
        private toasterService: ToastrService,
        private localstorage: secureStorage,
        public router: Router
    ) {
        if (isPlatformBrowser(this.platformId)) {
            this.load();
            this.calc();
        }
        this.appStateService.reloadcartConfiguration.pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
            if (response === "reload") {
                this.cartConfiguration();
                this.appStateService.reloadcartConfiguration.next("");
            }
        })
        // this.appStateService.isLoggedIn.subscribe(isLog => {
        //     if (isLog) {
        //         this.load();
        //         this.calc();
        //     }
        // })
    }

    add(
        product: Product,
        quantity: number,
        type: string,
        cart_type_name: string,
        options: { name: string; value: string }[] = []
    ): Observable<CartItem> {
        try {
            // timer only for demo
            let mainProduct = product;
            let products = []
            let qtyUpdated = false;
            return timer(1000).pipe(
                map(() => {
                    this.isLoggedIn = this.appStateService.isLoggedIn.value;
                    let item: any;
                    if (!this.isLoggedIn) {
                        item = this.items.find(eachItem => {
                            if (
                                eachItem.product.part_number !==
                                product.part_number ||
                                (eachItem.options && eachItem.options.length !== options.length)
                            ) {
                                return false;
                            }
                            if (eachItem.options && eachItem.options.length) {
                                for (const option of options) {
                                    if (
                                        !eachItem.options.find(
                                            itemOption =>
                                                itemOption.name === option.name &&
                                                itemOption.value === option.value
                                        )
                                    ) {
                                        return false;
                                    }
                                }
                            }
                            return true;
                        });
                        if (item) {
                            qtyUpdated = true;
                        }
                        type = (this.isLoggedIn) ? type.toLowerCase() : cart_type_name;
                        item = { product, quantity: item ? item.quantity : quantity, type, cart_type_name, options };
                    }
                    //Google Analyatics Code Start
                    var MobileNo = null;
                    if (this.localstorage.getItem('userData') != null || this.localstorage.getItem('userData') != undefined) {
                        let userData = JSON.parse(this.localstorage.getItem('userData'));
                        MobileNo = userData["contact_no"] || userData["phone_number"];
                    }
                    var Address = "";
                    if (this.localstorage.getItem('address') != null || this.localstorage.getItem('address') != undefined) {
                        let address = JSON.parse(this.localstorage.getItem('address'));
                        Address = address.address.street_address1 + ', ' + address.address.street_address2
                            + ', ' + address.address.street_address3 + ', ' + address.address.state + ', '
                            + address.address.district + ', ' + address.address.city + ', '
                            + ((address.address.postal_code) ? address.address.postal_code : address.address.pincode);
                    }
                    //Google Analyatics Code End
                    if (this.isLoggedIn) {
                        if (this.localstorage.getItem('userData') != null || this.localstorage.getItem('userData') != undefined) {
                            let cartpermission = this.userService.CheckPermission('btn_cart');
                            if (cartpermission) {
                                let userData = JSON.parse(
                                    this.localstorage.getItem("userData")
                                );
                                products.push({
                                    cart_type: cart_type_name,
                                    part_number: product.part_number,
                                    quantity: quantity,
                                });
                                let request = {
                                    account_pk: JSON.stringify(
                                        userData.account_pk
                                    ),
                                    validate_request:products.length > 1 ?false:true,
                                    cart_items:products
                                }
                                let partCartType = cart_type_name;
                                
                                let duplicate_reuest ={
                                    "account_pk": userData.account_pk,
                                    "part_number":[product.part_number],
                                    "offset":0, 
                                    "limit":20
                                }
                                let order_duplication = this.productService.get_duplicate_order_alert(duplicate_reuest)
                                order_duplication.subscribe(response => {
                                   if (response.success === true) {
                                    if(response.data.duplicate_order_data.length > 0){
                                        Swal.fire({
                                            title: '<strong>Duplicate Order Notification</strong>',
                                            html:'The selected part already exists in an active order, click on <b>View Active Order</b> to check the parts order status or click on <b>Continue</b> to proceed with the order.' ,
                                                showDenyButton: true,
                                                // showCancelButton: true,
                                                width:700,
                                                denyButtonText: 'Continue',
                                                confirmButtonText: 'View Active Order',
                                                confirmButtonColor:'#1c1479',
                                                denyButtonColor:'#1c1479',
                                                // backdrop: false,
                                                allowOutsideClick: false
                                            }).then((result) => {
                                                /* Read more about isConfirmed, isDenied below */
                                                 if(result.isConfirmed){
                                                    this.appStateService.partNumberForDuplicateOrder.next(response.data.duplicate_order_data[0].part_number);
                                                    this.router.navigate(['/orders/active-orders'], { queryParams: {isViewAll:true },});
                                                } else if (result.isDenied) {
                                                    let call = this.productService.add_to_cart_ms(request)
                                                    call.subscribe(response => {
                                                        if (response.success === true) {
                                                            this.onAddingSubject$.next(mainProduct);
                                                            this.message.next('Item Added');
                                                            this.toasterService.success(
                                                                `Product "${product.part_number}" added to cart!`
                                                            );
                                                            this.cartConfiguration();
                                                            this.appStateService.refreshHeaderCart.next(true);
                                                            let currentPageCartType = this.router.url.includes("type=0") ? "slv_cart" : this.router.url.includes("type=1") ? "mhv_cart" : this.router.url.includes("type=2") ? "tmgo_cart" : null;
                                                            let currentUrl = this.router.url;
                                                            // setTimeout(() => {
                                                            //     if (currentUrl.includes("/cart/summary")) {
                                                            //         this.localstorage.setItem("refreshCartItems", "true");
                                                            //         this.appStateService.refreshCartItems.next(true);
                                                            //     }
                                                            //     else if (currentUrl.includes("/cart/address")) {
                                                            //         if (partCartType === currentPageCartType) {
                                                            //             this.localstorage.setItem("refreshCheckout", "true");
                                                            //             let cartAddressQueryParams = JSON.parse(this.localstorage.getItem("cartAddressQueryParams"));
                                                            //             this.router.navigateByUrl('/', { skipLocationChange: true })
                                                            //                 .then(() => this.router.navigate(['cart/address'], cartAddressQueryParams));
                    
                                                            //         }
                                                            //     }
                                                            //     else if (currentUrl.includes("/cart/payment")) {
                                                            //         if (partCartType === currentPageCartType) {
                                                            //             this.localstorage.setItem("refresPayment", "true");
                                                            //             this.appStateService.refresPayment.next("refresh payment address!");
                                                            //         }
                                                            //     }
                                                            // }, 1000);
                                                            if (this.router.url.includes("cart/address")) {
                                                                if (partCartType === currentPageCartType) {
                                                                    this.appStateService.refreshSummary.next(true);
                                                                    // let cartAddressQueryParams = JSON.parse(this.localstorage.getItem("cartAddressQueryParams"));
                                                                    // cartAddressQueryParams.data = parseInt(cartAddressQueryParams.data) + 1;
                                                                    // this.localstorage.setItem("routeReused", "true");
                                                                    // this.router.navigateByUrl('/', { skipLocationChange: true })
                                                                    //     .then(() => this.router.navigate(['cart/address'], { queryParams: cartAddressQueryParams }));
                                                                }
                                                            }
                                                            else if (this.router.url.includes("cart/payment")) {
                                                                if (partCartType === currentPageCartType) {
                                                                    this.appStateService.refreshSummary.next(true);
                                                                    // let cartPaymentQueryParams = JSON.parse(this.localstorage.getItem("cartPaymentQueryParams"));
                                                                    // cartPaymentQueryParams.data = parseInt(cartPaymentQueryParams.data) + 1;
                                                                    // this.localstorage.setItem("routeReused", "true");
                                                                    // this.router.navigateByUrl('/', { skipLocationChange: true })
                                                                    //     .then(() => this.router.navigate(['cart/payment'], { queryParams: cartPaymentQueryParams }));
                                                                }
                                                            }
                                                            else if (this.router.url.includes("cart/summary")) {
                                                                let cartSummaryQueryParams = JSON.parse(this.localstorage.getItem("cartSummaryQueryParams"));
                                                                if (partCartType === currentPageCartType) {
                                                                    // cartSummaryQueryParams.data = parseInt(cartSummaryQueryParams.data) + 1;
                                                                    this.appStateService.refreshSummary.next(true);
                                                                }
                                                                // this.localstorage.setItem("routeReused", "true");
                                                                // this.router.navigateByUrl('/', { skipLocationChange: true })
                                                                //     .then(() => this.router.navigate(['cart/summary'], { queryParams: cartSummaryQueryParams }));
                                                            }
                                                            let isRecentlyAdded = this.localstorage.getItem('recentlyaddedpage');
                    
                                                            if (isRecentlyAdded == 'undefined' || isRecentlyAdded == 'null' || isRecentlyAdded == "") {
                                                                this.storeRecentItems(product.part_number);
                                                            }
                                                            else {
                                                                localStorage.removeItem('recentlyaddedpage')
                                                            }
                                                            if(response.data.Part_not_added_with_reason || response.data.Part_not_added_with_reason.length > 0){
                                                                if(response.data.Part_not_added_with_reason[0].validation_type == 'order_rationalization'){
                                                                    Swal.fire({
                                                                        title: "Maximum Order Limit",
                                                                        text: response.data.Part_not_added_with_reason[0].reason,
                                                                        icon: "info"
                                                                      });
                                                                }
                                                                else{
                                                                    this.toasterService.error(response.data.Part_not_added_with_reason[0].reason);
                                                                }
                                                            }
                                                            // this.load();
                                                            // setTimeout(() => {
                                                            // }, 1000);
                                                        }
                                                        else {
                                                            let msg: string = response?.data?.msg ? response.data.msg : response?.msg;
                                                            if (msg.length) {
                                                                this.toasterService.error(msg);
                                                            }
                                                            if (msg === "Product already exist.") {
                                                                //Google Analyatics Code Start
                                                                var MobileNo = null;
                                                                if (this.localstorage.getItem('userData') != null || this.localstorage.getItem('userData') != undefined) {
                                                                    let userData = JSON.parse(this.localstorage.getItem('userData'));
                                                                    MobileNo = userData.phone_number;
                                                                }
                                                                var Address = "";
                                                                if (this.localstorage.getItem('address') != null || this.localstorage.getItem('address') != undefined) {
                                                                    let address = JSON.parse(this.localstorage.getItem('address'));
                                                                    Address = address.address.street_address1 + ', ' + address.address.street_address2
                                                                        + ', ' + address.address.street_address3 + ', ' + address.address.state + ', '
                                                                        + address.address.district + ', ' + address.address.city + ', '
                                                                        + ((address.address.postal_code) ? address.address.postal_code : address.address.pincode);
                                                                }
                                                                try {
                                                                    this.googleAnlyatics.addToCartFail("add_to_cart_Fail_web", Address, product.part_number, quantity, MobileNo, "Product Already Exist");
                                                                }
                                                                catch (error) {
                                                                    this.userService.InsertExceptionLog({ "method_name": "add()", "page_name": "CartService", "portal_type": "Customer", "page_url": "", "error_message": error.message, "inner_exception": error });;
                                                                }
                                                                //Google Analyatics Code End
                                                                // this.toasterService.error('Product Already Exist')
                                                                return
                                                            }
                                                            if(msg === "Parts not inserted."){
                                                                if(response.data.Part_not_added_with_reason || response.data.Part_not_added_with_reason.length > 0){
                                                                    if(response.data.Part_not_added_with_reason[0]?.validation_type == 'order_rationalization'){
                                                                        Swal.fire({
                                                                            title: "Maximum Order Limit",
                                                                            text: response.data.Part_not_added_with_reason[0].reason,
                                                                            icon: "info"
                                                                          });
                                                                    }
                                                                    else{
                                                                        this.toasterService.error(response.data.Part_not_added_with_reason[0].reason);
                                                                    }
                                                                }
                                                                var MobileNo = null;
                                                                if (this.localstorage.getItem('userData') != null || this.localstorage.getItem('userData') != undefined) {
                                                                    let userData = JSON.parse(this.localstorage.getItem('userData'));
                                                                    MobileNo = userData.phone_number;
                                                                }
                                                                var Address = "";
                                                                if (this.localstorage.getItem('address') != null || this.localstorage.getItem('address') != undefined) {
                                                                    let address = JSON.parse(this.localstorage.getItem('address'));
                                                                    Address = address.address.street_address1 + ', ' + address.address.street_address2
                                                                        + ', ' + address.address.street_address3 + ', ' + address.address.state + ', '
                                                                        + address.address.district + ', ' + address.address.city + ', '
                                                                        + ((address.address.postal_code) ? address.address.postal_code : address.address.pincode);
                                                                }
                                                                try {
                                                                    this.googleAnlyatics.addToCartFail("add_to_cart_Fail_web", Address, product.part_number, quantity, MobileNo, "Product Already Exist");
                                                                }
                                                                catch (error) {
                                                                    this.userService.InsertExceptionLog({ "method_name": "add()", "page_name": "CartService", "portal_type": "Customer", "page_url": "", "error_message": error.message, "inner_exception": error });;
                                                                }
                                                                //Google Analyatics Code End
                                                                // this.toasterService.error('Product Already Exist')
                                                                return
                                                            }
                                                        }
                                                    }, (error) => {
                                                        if (error?.error?.data?.msg) {
                                                            this.toasterService.error(error.error.data.msg);
                                                        }                          //Error callback
                                                    });
                                                } 
                                        })
                                    }
                                    else{
                                        let call = this.productService.add_to_cart_ms(request)
                                        call.subscribe(response => {
                                            if (response.success === true) {
                                                this.onAddingSubject$.next(mainProduct);
                                                this.message.next('Item Added');
                                                this.toasterService.success(
                                                    `Product "${product.part_number}" added to cart!`
                                                );
                                                this.cartConfiguration();
                                                this.appStateService.refreshHeaderCart.next(true);
                                                let currentPageCartType = this.router.url.includes("type=0") ? "slv_cart" : this.router.url.includes("type=1") ? "mhv_cart" : this.router.url.includes("type=2") ? "tmgo_cart" : null;
                                                let currentUrl = this.router.url;
                                                // setTimeout(() => {
                                                //     if (currentUrl.includes("/cart/summary")) {
                                                //         this.localstorage.setItem("refreshCartItems", "true");
                                                //         this.appStateService.refreshCartItems.next(true);
                                                //     }
                                                //     else if (currentUrl.includes("/cart/address")) {
                                                //         if (partCartType === currentPageCartType) {
                                                //             this.localstorage.setItem("refreshCheckout", "true");
                                                //             let cartAddressQueryParams = JSON.parse(this.localstorage.getItem("cartAddressQueryParams"));
                                                //             this.router.navigateByUrl('/', { skipLocationChange: true })
                                                //                 .then(() => this.router.navigate(['cart/address'], cartAddressQueryParams));
        
                                                //         }
                                                //     }
                                                //     else if (currentUrl.includes("/cart/payment")) {
                                                //         if (partCartType === currentPageCartType) {
                                                //             this.localstorage.setItem("refresPayment", "true");
                                                //             this.appStateService.refresPayment.next("refresh payment address!");
                                                //         }
                                                //     }
                                                // }, 1000);
                                                if (this.router.url.includes("cart/address")) {
                                                    if (partCartType === currentPageCartType) {
                                                        this.appStateService.refreshSummary.next(true);
                                                        // let cartAddressQueryParams = JSON.parse(this.localstorage.getItem("cartAddressQueryParams"));
                                                        // cartAddressQueryParams.data = parseInt(cartAddressQueryParams.data) + 1;
                                                        // this.localstorage.setItem("routeReused", "true");
                                                        // this.router.navigateByUrl('/', { skipLocationChange: true })
                                                        //     .then(() => this.router.navigate(['cart/address'], { queryParams: cartAddressQueryParams }));
                                                    }
                                                }
                                                else if (this.router.url.includes("cart/payment")) {
                                                    if (partCartType === currentPageCartType) {
                                                        this.appStateService.refreshSummary.next(true);
                                                        // let cartPaymentQueryParams = JSON.parse(this.localstorage.getItem("cartPaymentQueryParams"));
                                                        // cartPaymentQueryParams.data = parseInt(cartPaymentQueryParams.data) + 1;
                                                        // this.localstorage.setItem("routeReused", "true");
                                                        // this.router.navigateByUrl('/', { skipLocationChange: true })
                                                        //     .then(() => this.router.navigate(['cart/payment'], { queryParams: cartPaymentQueryParams }));
                                                    }
                                                }
                                                else if (this.router.url.includes("cart/summary")) {
                                                    let cartSummaryQueryParams = JSON.parse(this.localstorage.getItem("cartSummaryQueryParams"));
                                                    if (partCartType === currentPageCartType) {
                                                        // cartSummaryQueryParams.data = parseInt(cartSummaryQueryParams.data) + 1;
                                                        this.appStateService.refreshSummary.next(true);
                                                    }
                                                    // this.localstorage.setItem("routeReused", "true");
                                                    // this.router.navigateByUrl('/', { skipLocationChange: true })
                                                    //     .then(() => this.router.navigate(['cart/summary'], { queryParams: cartSummaryQueryParams }));
                                                }
                                                let isRecentlyAdded = this.localstorage.getItem('recentlyaddedpage');
        
                                                if (isRecentlyAdded == 'undefined' || isRecentlyAdded == 'null' || isRecentlyAdded == "") {
                                                    this.storeRecentItems(product.part_number);
                                                }
                                                else {
                                                    localStorage.removeItem('recentlyaddedpage')
                                                }
                                                if(response.data.Part_not_added_with_reason || response.data.Part_not_added_with_reason.length > 0){
                                                    if(response.data.Part_not_added_with_reason[0].validation_type == 'order_rationalization'){
                                                        Swal.fire({
                                                            title: "Maximum Order Limit",
                                                            text: response.data.Part_not_added_with_reason[0].reason,
                                                            icon: "info"
                                                          });
                                                    }
                                                    else{
                                                        this.toasterService.error(response.data.Part_not_added_with_reason[0].reason);
                                                    }
                                                }
                                                // this.load();
                                                // setTimeout(() => {
                                                // }, 1000);
                                            }
                                            else {
                                                let msg: string = response?.data?.msg ? response.data.msg : response?.msg;
                                                if (msg.length) {
                                                    this.toasterService.error(msg);
                                                }
                                                if (msg === "Product already exist.") {
                                                    //Google Analyatics Code Start
                                                    var MobileNo = null;
                                                    if (this.localstorage.getItem('userData') != null || this.localstorage.getItem('userData') != undefined) {
                                                        let userData = JSON.parse(this.localstorage.getItem('userData'));
                                                        MobileNo = userData.phone_number;
                                                    }
                                                    var Address = "";
                                                    if (this.localstorage.getItem('address') != null || this.localstorage.getItem('address') != undefined) {
                                                        let address = JSON.parse(this.localstorage.getItem('address'));
                                                        Address = address.address.street_address1 + ', ' + address.address.street_address2
                                                            + ', ' + address.address.street_address3 + ', ' + address.address.state + ', '
                                                            + address.address.district + ', ' + address.address.city + ', '
                                                            + ((address.address.postal_code) ? address.address.postal_code : address.address.pincode);
                                                    }
                                                    try {
                                                        this.googleAnlyatics.addToCartFail("add_to_cart_Fail_web", Address, product.part_number, quantity, MobileNo, "Product Already Exist");
                                                    }
                                                    catch (error) {
                                                        this.userService.InsertExceptionLog({ "method_name": "add()", "page_name": "CartService", "portal_type": "Customer", "page_url": "", "error_message": error.message, "inner_exception": error });;
                                                    }
                                                    //Google Analyatics Code End
                                                    // this.toasterService.error('Product Already Exist')
                                                    return
                                                }
                                                if(msg === "Parts not inserted."){
                                                    if(response.data.Part_not_added_with_reason || response.data.Part_not_added_with_reason.length > 0){
                                                        if(response.data.Part_not_added_with_reason[0].validation_type == 'order_rationalization'){
                                                            Swal.fire({
                                                                title: "Maximum Order Limit",
                                                                text: response.data.Part_not_added_with_reason[0].reason,
                                                                icon: "info"
                                                              });
                                                        }
                                                        else{
                                                            this.toasterService.error(response.data.Part_not_added_with_reason[0].reason);
                                                        }
                                                    }
                                                    var MobileNo = null;
                                                    if (this.localstorage.getItem('userData') != null || this.localstorage.getItem('userData') != undefined) {
                                                        let userData = JSON.parse(this.localstorage.getItem('userData'));
                                                        MobileNo = userData.phone_number;
                                                    }
                                                    var Address = "";
                                                    if (this.localstorage.getItem('address') != null || this.localstorage.getItem('address') != undefined) {
                                                        let address = JSON.parse(this.localstorage.getItem('address'));
                                                        Address = address.address.street_address1 + ', ' + address.address.street_address2
                                                            + ', ' + address.address.street_address3 + ', ' + address.address.state + ', '
                                                            + address.address.district + ', ' + address.address.city + ', '
                                                            + ((address.address.postal_code) ? address.address.postal_code : address.address.pincode);
                                                    }
                                                    try {
                                                        this.googleAnlyatics.addToCartFail("add_to_cart_Fail_web", Address, product.part_number, quantity, MobileNo, "Product Already Exist");
                                                    }
                                                    catch (error) {
                                                        this.userService.InsertExceptionLog({ "method_name": "add()", "page_name": "CartService", "portal_type": "Customer", "page_url": "", "error_message": error.message, "inner_exception": error });;
                                                    }
                                                    //Google Analyatics Code End
                                                    // this.toasterService.error('Product Already Exist')
                                                    return
                                                }
                                            }
                                        }, (error) => {
                                            if (error?.error?.data?.msg) {
                                                this.toasterService.error(error.error.data.msg);
                                            }                          //Error callback
                                        });
                                    }
                                    }
                                else{
                                    let call = this.productService.add_to_cart_ms(request)
                                    call.subscribe(response => {
                                        if (response.success === true) {
                                            this.onAddingSubject$.next(mainProduct);
                                            this.message.next('Item Added');
                                            this.toasterService.success(
                                                `Product "${product.part_number}" added to cart!`
                                            );
                                            this.cartConfiguration();
                                            this.appStateService.refreshHeaderCart.next(true);
                                            let currentPageCartType = this.router.url.includes("type=0") ? "slv_cart" : this.router.url.includes("type=1") ? "mhv_cart" : this.router.url.includes("type=2") ? "tmgo_cart" : null;
                                            let currentUrl = this.router.url;
                                            // setTimeout(() => {
                                            //     if (currentUrl.includes("/cart/summary")) {
                                            //         this.localstorage.setItem("refreshCartItems", "true");
                                            //         this.appStateService.refreshCartItems.next(true);
                                            //     }
                                            //     else if (currentUrl.includes("/cart/address")) {
                                            //         if (partCartType === currentPageCartType) {
                                            //             this.localstorage.setItem("refreshCheckout", "true");
                                            //             let cartAddressQueryParams = JSON.parse(this.localstorage.getItem("cartAddressQueryParams"));
                                            //             this.router.navigateByUrl('/', { skipLocationChange: true })
                                            //                 .then(() => this.router.navigate(['cart/address'], cartAddressQueryParams));
    
                                            //         }
                                            //     }
                                            //     else if (currentUrl.includes("/cart/payment")) {
                                            //         if (partCartType === currentPageCartType) {
                                            //             this.localstorage.setItem("refresPayment", "true");
                                            //             this.appStateService.refresPayment.next("refresh payment address!");
                                            //         }
                                            //     }
                                            // }, 1000);
                                            if (this.router.url.includes("cart/address")) {
                                                if (partCartType === currentPageCartType) {
                                                    this.appStateService.refreshSummary.next(true);
                                                    // let cartAddressQueryParams = JSON.parse(this.localstorage.getItem("cartAddressQueryParams"));
                                                    // cartAddressQueryParams.data = parseInt(cartAddressQueryParams.data) + 1;
                                                    // this.localstorage.setItem("routeReused", "true");
                                                    // this.router.navigateByUrl('/', { skipLocationChange: true })
                                                    //     .then(() => this.router.navigate(['cart/address'], { queryParams: cartAddressQueryParams }));
                                                }
                                            }
                                            else if (this.router.url.includes("cart/payment")) {
                                                if (partCartType === currentPageCartType) {
                                                    this.appStateService.refreshSummary.next(true);
                                                    // let cartPaymentQueryParams = JSON.parse(this.localstorage.getItem("cartPaymentQueryParams"));
                                                    // cartPaymentQueryParams.data = parseInt(cartPaymentQueryParams.data) + 1;
                                                    // this.localstorage.setItem("routeReused", "true");
                                                    // this.router.navigateByUrl('/', { skipLocationChange: true })
                                                    //     .then(() => this.router.navigate(['cart/payment'], { queryParams: cartPaymentQueryParams }));
                                                }
                                            }
                                            else if (this.router.url.includes("cart/summary")) {
                                                let cartSummaryQueryParams = JSON.parse(this.localstorage.getItem("cartSummaryQueryParams"));
                                                if (partCartType === currentPageCartType) {
                                                    // cartSummaryQueryParams.data = parseInt(cartSummaryQueryParams.data) + 1;
                                                    this.appStateService.refreshSummary.next(true);
                                                }
                                                // this.localstorage.setItem("routeReused", "true");
                                                // this.router.navigateByUrl('/', { skipLocationChange: true })
                                                //     .then(() => this.router.navigate(['cart/summary'], { queryParams: cartSummaryQueryParams }));
                                            }
                                            let isRecentlyAdded = this.localstorage.getItem('recentlyaddedpage');
    
                                            if (isRecentlyAdded == 'undefined' || isRecentlyAdded == 'null' || isRecentlyAdded == "") {
                                                this.storeRecentItems(product.part_number);
                                            }
                                            else {
                                                localStorage.removeItem('recentlyaddedpage')
                                            }
                                            if(response.data.Part_not_added_with_reason || response.data.Part_not_added_with_reason.length > 0){
                                                if(response.data.Part_not_added_with_reason[0].validation_type == 'order_rationalization'){
                                                    Swal.fire({
                                                        title: "Maximum Order Limit",
                                                        text: response.data.Part_not_added_with_reason[0].reason,
                                                        icon: "info"
                                                      });
                                                }
                                                else{
                                                    this.toasterService.error(response.data.Part_not_added_with_reason[0].reason);
                                                }
                                            }
                                        }
                                        else {
                                            let msg: string = response?.data?.msg ? response.data.msg : response?.msg;
                                            if (msg.length) {
                                                this.toasterService.error(msg);
                                            }
                                            if (msg === "Product already exist.") {
                                                //Google Analyatics Code Start
                                                var MobileNo = null;
                                                if (this.localstorage.getItem('userData') != null || this.localstorage.getItem('userData') != undefined) {
                                                    let userData = JSON.parse(this.localstorage.getItem('userData'));
                                                    MobileNo = userData.phone_number;
                                                }
                                                var Address = "";
                                                if (this.localstorage.getItem('address') != null || this.localstorage.getItem('address') != undefined) {
                                                    let address = JSON.parse(this.localstorage.getItem('address'));
                                                    Address = address.address.street_address1 + ', ' + address.address.street_address2
                                                        + ', ' + address.address.street_address3 + ', ' + address.address.state + ', '
                                                        + address.address.district + ', ' + address.address.city + ', '
                                                        + ((address.address.postal_code) ? address.address.postal_code : address.address.pincode);
                                                }
                                                try {
                                                    this.googleAnlyatics.addToCartFail("add_to_cart_Fail_web", Address, product.part_number, quantity, MobileNo, "Product Already Exist");
                                                }
                                                catch (error) {
                                                    this.userService.InsertExceptionLog({ "method_name": "add()", "page_name": "CartService", "portal_type": "Customer", "page_url": "", "error_message": error.message, "inner_exception": error });;
                                                }
                                                //Google Analyatics Code End
                                                // this.toasterService.error('Product Already Exist')
                                                return
                                            }
                                            if(msg === "Parts not inserted."){
                                                if(response.data.Part_not_added_with_reason || response.data.Part_not_added_with_reason.length > 0){
                                                    if(response.data.Part_not_added_with_reason[0].validation_type == 'order_rationalization'){
                                                        Swal.fire({
                                                            title: "Maximum Order Limit",
                                                            text: response.data.Part_not_added_with_reason[0].reason,
                                                            icon: "info"
                                                          });
                                                    }
                                                    else{
                                                        this.toasterService.error(response.data.Part_not_added_with_reason[0].reason);
                                                    }
                                                }
                                                var MobileNo = null;
                                                if (this.localstorage.getItem('userData') != null || this.localstorage.getItem('userData') != undefined) {
                                                    let userData = JSON.parse(this.localstorage.getItem('userData'));
                                                    MobileNo = userData.phone_number;
                                                }
                                                var Address = "";
                                                if (this.localstorage.getItem('address') != null || this.localstorage.getItem('address') != undefined) {
                                                    let address = JSON.parse(this.localstorage.getItem('address'));
                                                    Address = address.address.street_address1 + ', ' + address.address.street_address2
                                                        + ', ' + address.address.street_address3 + ', ' + address.address.state + ', '
                                                        + address.address.district + ', ' + address.address.city + ', '
                                                        + ((address.address.postal_code) ? address.address.postal_code : address.address.pincode);
                                                }
                                                try {
                                                    this.googleAnlyatics.addToCartFail("add_to_cart_Fail_web", Address, product.part_number, quantity, MobileNo, "Product Already Exist");
                                                }
                                                catch (error) {
                                                    this.userService.InsertExceptionLog({ "method_name": "add()", "page_name": "CartService", "portal_type": "Customer", "page_url": "", "error_message": error.message, "inner_exception": error });;
                                                }
                                                //Google Analyatics Code End
                                                // this.toasterService.error('Product Already Exist')
                                                return
                                            }
                                        }
                                    }, (error) => {
                                        if (error?.error?.data?.msg) {
                                            this.toasterService.error(error.error.data.msg);
                                        }                          //Error callback
                                    });
                                }
                                });
                                // let call = this.productService.add_to_cart_ms(request)
                                // call.subscribe(response => {
                                //     if (response.success === true) {
                                //         this.onAddingSubject$.next(mainProduct);
                                //         this.message.next('Item Added');
                                //         this.toasterService.success(
                                //             `Product "${product.part_number}" added to cart!`
                                //         );
                                //         this.cartConfiguration();
                                //         this.appStateService.refreshHeaderCart.next(true);
                                //         let currentPageCartType = this.router.url.includes("type=0") ? "slv_cart" : this.router.url.includes("type=1") ? "mhv_cart" : this.router.url.includes("type=2") ? "tmgo_cart" : null;
                                //         let currentUrl = this.router.url;
                                //         // setTimeout(() => {
                                //         //     if (currentUrl.includes("/cart/summary")) {
                                //         //         this.localstorage.setItem("refreshCartItems", "true");
                                //         //         this.appStateService.refreshCartItems.next(true);
                                //         //     }
                                //         //     else if (currentUrl.includes("/cart/address")) {
                                //         //         if (partCartType === currentPageCartType) {
                                //         //             this.localstorage.setItem("refreshCheckout", "true");
                                //         //             let cartAddressQueryParams = JSON.parse(this.localstorage.getItem("cartAddressQueryParams"));
                                //         //             this.router.navigateByUrl('/', { skipLocationChange: true })
                                //         //                 .then(() => this.router.navigate(['cart/address'], cartAddressQueryParams));

                                //         //         }
                                //         //     }
                                //         //     else if (currentUrl.includes("/cart/payment")) {
                                //         //         if (partCartType === currentPageCartType) {
                                //         //             this.localstorage.setItem("refresPayment", "true");
                                //         //             this.appStateService.refresPayment.next("refresh payment address!");
                                //         //         }
                                //         //     }
                                //         // }, 1000);
                                //         if (this.router.url.includes("cart/address")) {
                                //             if (partCartType === currentPageCartType) {
                                //                 this.appStateService.refreshSummary.next(true);
                                //                 // let cartAddressQueryParams = JSON.parse(this.localstorage.getItem("cartAddressQueryParams"));
                                //                 // cartAddressQueryParams.data = parseInt(cartAddressQueryParams.data) + 1;
                                //                 // this.localstorage.setItem("routeReused", "true");
                                //                 // this.router.navigateByUrl('/', { skipLocationChange: true })
                                //                 //     .then(() => this.router.navigate(['cart/address'], { queryParams: cartAddressQueryParams }));
                                //             }
                                //         }
                                //         else if (this.router.url.includes("cart/payment")) {
                                //             if (partCartType === currentPageCartType) {
                                //                 this.appStateService.refreshSummary.next(true);
                                //                 // let cartPaymentQueryParams = JSON.parse(this.localstorage.getItem("cartPaymentQueryParams"));
                                //                 // cartPaymentQueryParams.data = parseInt(cartPaymentQueryParams.data) + 1;
                                //                 // this.localstorage.setItem("routeReused", "true");
                                //                 // this.router.navigateByUrl('/', { skipLocationChange: true })
                                //                 //     .then(() => this.router.navigate(['cart/payment'], { queryParams: cartPaymentQueryParams }));
                                //             }
                                //         }
                                //         else if (this.router.url.includes("cart/summary")) {
                                //             let cartSummaryQueryParams = JSON.parse(this.localstorage.getItem("cartSummaryQueryParams"));
                                //             if (partCartType === currentPageCartType) {
                                //                 // cartSummaryQueryParams.data = parseInt(cartSummaryQueryParams.data) + 1;
                                //                 this.appStateService.refreshSummary.next(true);
                                //             }
                                //             // this.localstorage.setItem("routeReused", "true");
                                //             // this.router.navigateByUrl('/', { skipLocationChange: true })
                                //             //     .then(() => this.router.navigate(['cart/summary'], { queryParams: cartSummaryQueryParams }));
                                //         }
                                //         let isRecentlyAdded = this.localstorage.getItem('recentlyaddedpage');

                                //         if (isRecentlyAdded == 'undefined' || isRecentlyAdded == 'null' || isRecentlyAdded == "") {
                                //             this.storeRecentItems(product.part_number);
                                //         }
                                //         else {
                                //             localStorage.removeItem('recentlyaddedpage')
                                //         }
                                //         if(response.data.Part_not_added_with_reason || response.data.Part_not_added_with_reason.length > 0){
                                //             if(response.data.Part_not_added_with_reason[0].validation_type == 'order_rationalization'){
                                //                 Swal.fire({
                                //                     title: "Maximum Order Limit",
                                //                     text: response.data.Part_not_added_with_reason[0].reason,
                                //                     icon: "info"
                                //                   });
                                //             }
                                //             else{
                                //                 this.toasterService.error(response.data.Part_not_added_with_reason[0].reason);
                                //             }
                                //         }
                                //         // this.load();
                                //         // setTimeout(() => {
                                //         // }, 1000);
                                //     }
                                //     else {
                                //         let msg: string = response?.data?.msg ? response.data.msg : response?.msg;
                                //         if (msg.length) {
                                //             this.toasterService.error(msg);
                                //         }
                                //         if (msg === "Product already exist.") {
                                //             //Google Analyatics Code Start
                                //             var MobileNo = null;
                                //             if (this.localstorage.getItem('userData') != null || this.localstorage.getItem('userData') != undefined) {
                                //                 let userData = JSON.parse(this.localstorage.getItem('userData'));
                                //                 MobileNo = userData.phone_number;
                                //             }
                                //             var Address = "";
                                //             if (this.localstorage.getItem('address') != null || this.localstorage.getItem('address') != undefined) {
                                //                 let address = JSON.parse(this.localstorage.getItem('address'));
                                //                 Address = address.address.street_address1 + ', ' + address.address.street_address2
                                //                     + ', ' + address.address.street_address3 + ', ' + address.address.state + ', '
                                //                     + address.address.district + ', ' + address.address.city + ', '
                                //                     + ((address.address.postal_code) ? address.address.postal_code : address.address.pincode);
                                //             }
                                //             try {
                                //                 this.googleAnlyatics.addToCartFail("add_to_cart_Fail_web", Address, product.part_number, quantity, MobileNo, "Product Already Exist");
                                //             }
                                //             catch (error) {
                                //                 this.userService.InsertExceptionLog({ "method_name": "add()", "page_name": "CartService", "portal_type": "Customer", "page_url": "", "error_message": error.message, "inner_exception": error });;
                                //             }
                                //             //Google Analyatics Code End
                                //             // this.toasterService.error('Product Already Exist')
                                //             return
                                //         }
                                //         if(msg === "Parts not inserted."){
                                //             if(response.data.Part_not_added_with_reason || response.data.Part_not_added_with_reason.length > 0){
                                //                 if(response.data.Part_not_added_with_reason[0].validation_type == 'order_rationalization'){
                                //                     Swal.fire({
                                //                         title: "Maximum Order Limit",
                                //                         text: response.data.Part_not_added_with_reason[0].reason,
                                //                         icon: "info"
                                //                       });
                                //                 }
                                //                 else{
                                //                     this.toasterService.error(response.data.Part_not_added_with_reason[0].reason);
                                //                 }
                                //             }
                                //             var MobileNo = null;
                                //             if (this.localstorage.getItem('userData') != null || this.localstorage.getItem('userData') != undefined) {
                                //                 let userData = JSON.parse(this.localstorage.getItem('userData'));
                                //                 MobileNo = userData.phone_number;
                                //             }
                                //             var Address = "";
                                //             if (this.localstorage.getItem('address') != null || this.localstorage.getItem('address') != undefined) {
                                //                 let address = JSON.parse(this.localstorage.getItem('address'));
                                //                 Address = address.address.street_address1 + ', ' + address.address.street_address2
                                //                     + ', ' + address.address.street_address3 + ', ' + address.address.state + ', '
                                //                     + address.address.district + ', ' + address.address.city + ', '
                                //                     + ((address.address.postal_code) ? address.address.postal_code : address.address.pincode);
                                //             }
                                //             try {
                                //                 this.googleAnlyatics.addToCartFail("add_to_cart_Fail_web", Address, product.part_number, quantity, MobileNo, "Product Already Exist");
                                //             }
                                //             catch (error) {
                                //                 this.userService.InsertExceptionLog({ "method_name": "add()", "page_name": "CartService", "portal_type": "Customer", "page_url": "", "error_message": error.message, "inner_exception": error });;
                                //             }
                                //             //Google Analyatics Code End
                                //             // this.toasterService.error('Product Already Exist')
                                //             return
                                //         }
                                //     }
                                // }, (error) => {
                                //     if (error?.error?.data?.msg) {
                                //         this.toasterService.error(error.error.data.msg);
                                //     }                          //Error callback
                                // });
                                try {
                                    this.googleAnlyatics.addToCart("add_to_cart_web", Address, product.part_number, quantity, MobileNo);
                                }
                                catch (error) {
                                    this.userService.InsertExceptionLog({ "method_name": "add()", "page_name": "CartService", "portal_type": "Customer", "page_url": "", "error_message": error.message, "inner_exception": error });;
                                }
                            }
                            else {
                                this.toasterService.error('Your access is restricted, please contact Organisation Manager..!')
                            }
                        }
                    } else {
                        if (!qtyUpdated) {
                            let countitems = [];
                            countitems = this.items.filter(items =>
                                items.type === cart_type_name
                            )
                            if (countitems && countitems.length >= 50) {
                                try {
                                    this.googleAnlyatics.addToCartFail("add_to_cart_Fail_web", Address, item.product.part_number, item.quantity, MobileNo, cart_type_name + " Cart Is Full");
                                }
                                catch (error) {
                                    this.userService.InsertExceptionLog({ "method_name": "add()", "page_name": "CartService", "portal_type": "Customer", "page_url": "", "error_message": error.message, "inner_exception": error });;
                                }
                                this.toasterService.error('Only 50 Unique Items per cart can be ordered against a single order. A separate order will have to be raised for the remaining parts.')
                                return
                            }
                            try {
                                this.googleAnlyatics.addToCart("add_to_cart_web", Address, item.product.part_number, item.quantity, MobileNo);
                            }
                            catch (error) {
                                this.userService.InsertExceptionLog({ "method_name": "add()", "page_name": "CartService", "portal_type": "Customer", "page_url": "", "error_message": error.message, "inner_exception": error });;
                            }
                            this.onAddingSubject$.next(mainProduct);
                            this.toasterService.success(
                                `Product "${product.part_number}" added to cart!`
                            );
                            // if (currentUrl.includes("/cart/summary")) {
                            //     setTimeout(() => {
                            //         this.localstorage.setItem("refreshCartItems", "true");
                            //         this.appStateService.refreshCartItems.next(true);
                            //     }, 1000);
                            // }
                            // if (this.router.url.includes("cart/summary")) {
                            //     let cartPaymentQueryParams = JSON.parse(this.localstorage.getItem("cartPaymentQueryParams"));
                            //     cartPaymentQueryParams.data = parseInt(cartPaymentQueryParams.data) - 1;
                            //     this.localstorage.setItem("routeReused", "true");
                            //     this.router.navigateByUrl('/', { skipLocationChange: true })
                            //             .then(() => this.router.navigate(['cart/summary'], { queryParams: cartPaymentQueryParams }));
                            // }
                            if (!this.data.items.includes(item)) {
                                this.data.items.push(item);
                            }
                            if (!this.data.allitems.includes(item)) {
                                this.data.allitems.push(item);
                            }

                            let isRecentlyAdded = this.localstorage.getItem('recentlyaddedpage');
                            if (isRecentlyAdded == 'undefined' || isRecentlyAdded == 'null' || isRecentlyAdded == "") {
                                this.storeRecentItems(product.part_number);
                            } else {
                                localStorage.removeItem('recentlyaddedpage');
                            }
                            // setTimeout(() => {
                            // }, 1000);
                        }
                        else {
                            try {
                                this.googleAnlyatics.addToCartFail("add_to_cart_Fail_web", Address, item.product.part_number, item.quantity, MobileNo, "Product Already Exist");
                            }
                            catch (error) {
                                this.userService.InsertExceptionLog({ "method_name": "add()", "page_name": "CartService", "portal_type": "Customer", "page_url": "", "error_message": error.message, "inner_exception": error });;
                            }
                            this.toasterService.error('Product Already Exist')
                        }
                    }
                    this.save();
                    this.calc();
                    return item;
                })
            );
        }
        catch (error) {
            this.userService.InsertExceptionLog({ "method_name": "add()", "page_name": "CartService", "portal_type": "Customer", "page_url": "", "error_message": error.message, "inner_exception": error });
        }
    }

    update(updates: { item: CartItem; quantity: number }[]): Observable<void> {
        try {
            // timer only for demo
            return timer(1000).pipe(
                map(() => {
                    updates.forEach(update => {
                        const item = this.items.find(
                            eachItem => eachItem === update.item
                        );
                        if (update.quantity === 0 || isNaN(update.quantity)) {
                            this.toasterService.error('Quantity field should not be empty or 0.');
                            return;
                        }
                        if (item && item.product && item.product['min_quantity'] && (parseInt(item.product['min_quantity']) > update.quantity)) {
                            this.toasterService.error('Quantity field should not be less than minimum quantity.');
                            return;
                        }
                        this.isLoggedIn = this.appStateService.isLoggedIn.value;
                        if (item) {
                            item.quantity = update.quantity;
                            this.save();
                            this.calc();
                            // if (this.router.url.includes("cart/summary")) {
                            //     this.localstorage.setItem("refreshCartItems", "true");
                            //     this.appStateService.refreshCartItems.next(true);
                            // }
                            if (this.router.url.includes("cart/summary")) {
                                let cartSummaryQueryParams = JSON.parse(this.localstorage.getItem("cartSummaryQueryParams"));
                                // cartSummaryQueryParams.data = parseInt(cartSummaryQueryParams.data) - 1;
                                this.localstorage.setItem("routeReused", "true");
                                this.router.navigateByUrl('/', { skipLocationChange: true })
                                    .then(() => this.router.navigate(['cart/summary'], { queryParams: cartSummaryQueryParams }));
                            }
                        }
                    });
                })
            );
        }
        catch (error) {
            this.userService.InsertExceptionLog({ "method_name": "update(updates: { item: CartItem; quantity: number }[])", "page_name": "CartService", "portal_type": "Customer", "page_url": "", "error_message": error.message, "inner_exception": error });
        }
    }

    updateCartPreLogin(item, quantity: number): Observable<void> {
        try {
            // timer only for demo
            return timer(1000).pipe(
                map(() => {
                    const filteredItem = this.items.find(
                        eachItem => eachItem.product === item
                    );
                    if (filteredItem && filteredItem.product && filteredItem.product['min_quantity'] && (quantity === 0 || isNaN(quantity))) {
                        this.toasterService.error('Quantity field should not be empty or 0.');
                        return;
                    }
                    if (filteredItem && filteredItem.product && filteredItem.product['min_quantity'] && (parseInt(filteredItem.product['min_quantity']) > quantity)) {
                        this.toasterService.error('Quantity field should not be less than minimum quantity.');
                        return;
                    }
                    this.isLoggedIn = this.appStateService.isLoggedIn.value;
                    if (filteredItem) {
                        filteredItem.quantity = quantity;
                        //filteredItem.product['quantity'] = quantity;
                        // filteredItem.quantity = quantity;
                        // filteredItem.product['quantity'] = quantity;    
                    }
                    this.save();
                    this.calc();
                })
            );
        }
        catch (error) {
            this.userService.InsertExceptionLog({ "method_name": "update(updates: { item: CartItem; quantity: number }[])", "page_name": "CartService", "portal_type": "Customer", "page_url": "", "error_message": error.message, "inner_exception": error });
        }
    }

    // updateFromCart(itemAfterUpdate, quantity: number, isSaveForLater?:boolean): Observable<void> {
    //     try {
    //         // timer only for demo
    //         return timer(1000).pipe(
    //             map(() => {
    //                 // const itemBeforeUpdate = this.items.find(
    //                 //     eachItem => eachItem.product.id === itemAfterUpdate['id']
    //                 // );
    //                 // itemBeforeUpdate.product.part_minimum_quanity = itemAfterUpdate.part_minimum_quanity
    //                 this.isLoggedIn = this.appStateService.isLoggedIn.value;
    //                 if (this.isLoggedIn) {
    //                     if (itemAfterUpdate && itemAfterUpdate['part_minimum_quanity'] && (quantity === 0 || isNaN(quantity))) {
    //                         this.toasterService.error('Quantity field should not be empty or 0.');
    //                         return;
    //                     }
    //                     if (itemAfterUpdate && itemAfterUpdate['part_minimum_quanity'] && (itemAfterUpdate['part_minimum_quanity'] > quantity)) {
    //                         this.toasterService.error('Quantity field should not be less than minimum quantity.');
    //                         return;
    //                     }
    //                     let userData = JSON.parse(
    //                         this.localstorage.getItem("userData")
    //                     );
    //                     if (itemAfterUpdate) {
    //                         // let updateCartData = {
    //                         //     cartitem_id: itemBeforeUpdate.product["id"],
    //                         //     quantity: quantity,
    //                         //     account_pk: JSON.stringify(
    //                         //         userData.account_pk
    //                         //     )
    //                         // };
    //                         let updateCartData = {};
    //                         if(isSaveForLater){
    //                             updateCartData = {
    //                                 account_pk: JSON.stringify(
    //                                     userData.account_pk
    //                                 ),
    //                                 action_type:"save_for_later",
    //                                 cart_item_id: itemAfterUpdate["id"],
    //                                 quantity: quantity,
    //                             };
    //                         }else{
    //                             updateCartData = {
    //                                 account_pk: JSON.stringify(
    //                                     userData.account_pk
    //                                 ),
    //                                 cart_item_id: itemAfterUpdate["id"],
    //                                 quantity: quantity,
    //                             };
    //                         }
                            
    //                         this.productService.updateCart(updateCartData).subscribe(response => {
    //                             //this.load();
    //                             this.appStateService.refreshSummary.next(true);
    //                             this.appStateService.refreshHeaderCart.next(true);
    //                         });
    //                     }
    //                 } else {
    //                     const itemBeforeUpdate = this.items.find(
    //                         eachItem => eachItem.product.id === itemAfterUpdate['id']
    //                     );
    //                     itemBeforeUpdate.product.part_minimum_quanity = itemAfterUpdate.part_minimum_quanity
    //                     if (itemBeforeUpdate) {
    //                         itemBeforeUpdate.quantity = quantity;
    //                     }
    //                 }
    //                 this.save();
    //                 //this.calc();
    //             })
    //         );
    //     }
    //     catch (error) {
    //         this.userService.InsertExceptionLog({ "method_name": "update(updates: { item: CartItem; quantity: number }[])", "page_name": "CartService", "portal_type": "Customer", "page_url": "", "error_message": error.message, "inner_exception": error });
    //     }
    // }

    updateFromCart( product: Product,
        quantity: number,
        type: string,
        cart_type_name: string,isSaveForLater?:boolean,
        options: { name: string; value: string }[] = []): Observable<void> {
            let products = []
            try {
                // timer only for demo
                return timer(1000).pipe(
                    map(() => {
                        // const itemBeforeUpdate = this.items.find(
                        //     eachItem => eachItem.product.id === itemAfterUpdate['id']
                        // );
                        // itemBeforeUpdate.product.part_minimum_quanity = itemAfterUpdate.part_minimum_quanity
                        this.isLoggedIn = this.appStateService.isLoggedIn.value;
                        if (this.isLoggedIn) {
                            if (product && product['min_quantity'] && (quantity === 0 || isNaN(quantity))) {
                                this.toasterService.error('Quantity field should not be empty or 0.');
                                return;
                            }
                            if (product && product['min_quantity'] && (product['min_quantity'] > quantity)) {
                                this.toasterService.error('Quantity field should not be less than minimum quantity.');
                                return;
                            }
                            let userData = JSON.parse(
                                this.localstorage.getItem("userData")
                            );
                            if (product) {
                                // let updateCartData = {};
                                if(isSaveForLater){
                                    let userData = JSON.parse(
                                        this.localstorage.getItem("userData")
                                    );
                                    products.push({
                                        cart_type: cart_type_name,
                                        part_number: product.part_number,
                                        quantity: quantity,
                                    });
                                    var updateCartData = {
                                        account_pk: JSON.stringify(
                                            userData.account_pk
                                        ),
                                        validate_request:false,
                                        cart_items:products
                                    }
                                }else{
                                    let userData = JSON.parse(
                                        this.localstorage.getItem("userData")
                                    );
                                    products.push({
                                        cart_type: cart_type_name,
                                        part_number: product.part_number,
                                        quantity: quantity,
                                    });
                                    var updateCartData = {
                                        account_pk: JSON.stringify(
                                            userData.account_pk
                                        ),
                                        validate_request:false,
                                        cart_items:products
                                    }
                                }
                                
                                this.productService.add_to_cart_ms(updateCartData).subscribe(response => {
                                    //this.load();
                                    if (response.success === true) {
                                    this.appStateService.refreshSummary.next(true);
                                    this.appStateService.refreshHeaderCart.next(true);
                                    if(response.data.Part_not_added_with_reason.length > 0){
                                        this.toasterService.error(response.data.Part_not_added_with_reason[0]?.reason);
                                    }
                                    }
                                    else{
                                        if(response.data.Part_not_added_with_reason || response.data.Part_not_added_with_reason.length > 0){
                                            this.toasterService.error(response.data.Part_not_added_with_reason[0]?.reason);
                                        }
                                    }
                                });
                            }
                        } else {
                            const itemBeforeUpdate = this.items.find(
                                eachItem => eachItem.product.id === product['id']
                            );
                            itemBeforeUpdate.product.part_minimum_quanity = product.min_quantity
                            if (itemBeforeUpdate) {
                                itemBeforeUpdate.quantity = quantity;
                            }
                        }
                        this.save();
                        //this.calc();
                    })
                );
            }
            catch (error) {
                this.userService.InsertExceptionLog({ "method_name": "update(updates: { item: CartItem; quantity: number }[])", "page_name": "CartService", "portal_type": "Customer", "page_url": "", "error_message": error.message, "inner_exception": error });
            }
        }

    updateFromHeaderPostLogin(itemAfterUpdate, quantity: number, itemList) {
        try {
            // timer only for demo
            return timer(1000).pipe(
                map(() => {
                    // const itemBeforeUpdate = itemList.find(
                    //     eachItem => eachItem.product.id === itemAfterUpdate.product['id']
                    // );
                    if (quantity === 0 || isNaN(quantity)) {
                        this.toasterService.error('Quantity field should not be empty or 0.');
                        return;
                    }
                    if (itemAfterUpdate && itemAfterUpdate.product['part_minimum_quanity'] && (itemAfterUpdate.product['part_minimum_quanity'] > quantity)) {
                        this.toasterService.error('Quantity field should not be less than minimum quantity.');
                        return;
                    }
                    this.isLoggedIn = this.appStateService.isLoggedIn.value;
                    if (this.isLoggedIn) {
                        let userData = JSON.parse(
                            this.localstorage.getItem("userData")
                        );
                        if (itemAfterUpdate) {
                            let partCartType = itemAfterUpdate?.type;
                            let updateCartData = {
                                account_pk: JSON.stringify(
                                    userData.account_pk
                                ),
                                cart_item_id: itemAfterUpdate.product["id"],
                                quantity: quantity,
                            };
                            this.productService.updateCart(updateCartData).subscribe(response => {
                                // this.load();
                                let currentPageCartType = this.router.url.includes("type=0") ? "slv_cart" : this.router.url.includes("type=1") ? "mhv_cart" : this.router.url.includes("type=2") ? "tmgo_cart" : null;
                                // if (this.router.url.includes("cart/address")) {
                                //     if (partCartType === currentPageCartType) {
                                //         this.localstorage.setItem("refreshCheckout", "true");
                                //         let cartAddressQueryParams = JSON.parse(this.localstorage.getItem("cartAddressQueryParams"));
                                //         this.router.navigateByUrl('/', { skipLocationChange: true })
                                //             .then(() => this.router.navigate(['cart/address'], cartAddressQueryParams));
                                //     }
                                // }
                                // else if (this.router.url.includes("cart/payment")) {
                                //     if (partCartType === currentPageCartType) {
                                //         this.localstorage.setItem("refresPayment", "true");
                                //         this.appStateService.refresPayment.next("refresh payment address!");
                                //     }
                                // }
                                // else if (this.router.url.includes("cart/summary")) {
                                //     this.localstorage.setItem("refreshCartItems", "true");
                                //     this.appStateService.refreshCartItems.next(true);
                                // }
                                if (this.router.url.includes("cart/address")) {
                                    if (partCartType === currentPageCartType) {
                                        let cartAddressQueryParams = JSON.parse(this.localstorage.getItem("cartAddressQueryParams"));
                                        // cartAddressQueryParams.data = parseInt(cartAddressQueryParams.data) - 1;
                                        // this.localstorage.setItem("routeReused", "true");
                                        // this.router.navigateByUrl('/', { skipLocationChange: true })
                                        //     .then(() => this.router.navigate(['cart/address'], { queryParams: cartAddressQueryParams }));
                                        this.appStateService.refreshSummary.next(true);
                                    }
                                }
                                else if (this.router.url.includes("cart/payment")) {
                                    if (partCartType === currentPageCartType) {
                                        let cartPaymentQueryParams = JSON.parse(this.localstorage.getItem("cartPaymentQueryParams"));
                                        // cartPaymentQueryParams.data = parseInt(cartPaymentQueryParams.data) - 1;
                                        // this.localstorage.setItem("routeReused", "true");
                                        // this.router.navigateByUrl('/', { skipLocationChange: true })
                                        //     .then(() => this.router.navigate(['cart/payment'], { queryParams: cartPaymentQueryParams }));
                                        this.appStateService.refreshSummary.next(true);
                                    }
                                }
                                else if (this.router.url.includes("cart/summary")) {
                                    // let cartSummaryQueryParams = JSON.parse(this.localstorage.getItem("cartSummaryQueryParams"));
                                    // // cartSummaryQueryParams.data = parseInt(cartSummaryQueryParams.data) - 1;
                                    // this.localstorage.setItem("routeReused", "true");
                                    // this.router.navigateByUrl('/', { skipLocationChange: true })
                                    //     .then(() => this.router.navigate(['cart/summary'], { queryParams: cartSummaryQueryParams }));
                                    this.appStateService.refreshSummary.next(true);
                                }
                            });
                        }
                    }
                    this.save();
                    //this.calc();
                })
            );
        }
        catch (error) {
            this.userService.InsertExceptionLog({ "method_name": "update(updates: { item: CartItem; quantity: number }[])", "page_name": "CartService", "portal_type": "Customer", "page_url": "", "error_message": error.message, "inner_exception": error });
        }
    }

    remove(item): Observable<void> {
        try {
            // timer only for demo
            return timer(1000).pipe(
                map(() => {
                    this.isLoggedIn = this.appStateService.isLoggedIn.value;
                    if (this.isLoggedIn) {
                        let userData = JSON.parse(
                            this.localstorage.getItem("userData")
                        );

                        // let updateCartData = {
                        //     cartitem_id: item.product["id"],
                        //     account_pk: JSON.stringify(
                        //         userData.account_pk
                        //     )
                        // };
                        let updateCartData = {
                            account_pk: JSON.stringify(
                                userData.account_pk
                            ),
                            cart_item_ids: [item["id"]]

                        };
                        this.productService.removeProduct(updateCartData).subscribe(response => {
                            //this.load();
                            this.cartConfiguration();
                            this.appStateService.refreshSummary.next(true);
                            this.appStateService.refreshHeaderCart.next(true);
                            for (let i = 0; i < this.data.items.length; i++) {
                                if (item?.part_number === this.data.items[i].product.part_number) {
                                    this.data.items.splice(i, 1);
                                }
                            }
                            for (let j = 0; j < this.data.allitems.length; j++) {
                                if (item?.part_number === this.data.allitems[j].product.part_number) {
                                    this.data.allitems.splice(j, 1);
                                }
                            }
                            this.save();
                            this.calc();
                            // setTimeout(() => {
                            // }, 1000);
                        });
                    }
                    else {
                        this.data.items.forEach((element, index) => {
                            if (element.product.part_number === item['part_number']) {
                                this.data.items.splice(index, 1);
                            }
                        })
                        this.data.items1.forEach((element, index) => {
                            if (element.product.part_number === item['part_number']) {
                                this.data.items1.splice(index, 1);
                            }
                        })
                        this.data.allitems = this.data.items;
                        this.localstorage.setItem("refreshCartItems", "true");
                        this.appStateService.refreshCartItems.next(true);
                        this.save();
                        this.calc();

                        if (this.router.url.includes("cart/summary")) {
                            let cartSummaryQueryParams = JSON.parse(this.localstorage.getItem("cartSummaryQueryParams"));
                            cartSummaryQueryParams.data = (parseInt(cartSummaryQueryParams.data) - 1) <= 0 ? 0 : (parseInt(cartSummaryQueryParams.data) - 1);
                            this.localstorage.setItem("routeReused", "true");
                            this.router.navigateByUrl('/', { skipLocationChange: true })
                                .then(() => this.router.navigate(['cart/summary'], { queryParams: cartSummaryQueryParams }));
                        }
                    }

                })
            );

        }
        catch (error) {
            this.userService.InsertExceptionLog({ "method_name": "remove(item: CartItem)", "page_name": "CartService", "portal_type": "Customer", "page_url": "", "error_message": error.message, "inner_exception": error });
        }
    }

    deleteAllCartItems(itemsList: any[]): Observable<void> {
        let userData = JSON.parse(this.localstorage.getItem("userData"));
        let itemsIds: any[] = [];
        itemsList.forEach(x => {
            itemsIds.push(x.id);
        })
        let requestBody = {
            account_pk: JSON.stringify(userData.account_pk),
            cart_item_ids: itemsIds
        };
        this.productService.removeProduct(requestBody).subscribe(response => {
            this.cartConfiguration();
            this.appStateService.refreshSummary.next(true);
            this.appStateService.refreshHeaderCart.next(true);
            for (let i = 0; i < this.data.items.length; i++) {
                itemsIds.forEach(id => {
                    if (id === this.data.items[i].product.id) {
                        this.data.items.splice(i, 1);
                    }
                })
            }
            for (let j = 0; j < this.data.allitems.length; j++) {
                itemsIds.forEach(id => {
                    if (id === this.data.allitems[j].product.id) {
                        this.data.allitems.splice(j, 1);
                    }
                })
            }
            this.save();
            this.calc();
        });
        return;
    }

    removeFromHeader(item): Observable<void> {
        try {
            // timer only for demo
            return timer(1000).pipe(
                map(() => {
                    this.isLoggedIn = this.appStateService.isLoggedIn.value;
                    if (this.isLoggedIn) {
                        let userData = JSON.parse(
                            this.localstorage.getItem("userData")
                        );
                        let partCartType = item.type;
                        let updateCartData = {
                            account_pk: JSON.stringify(
                                userData.account_pk
                            ),
                            cart_item_ids: [item?.product["id"]]

                        };
                        this.productService.removeProduct(updateCartData).subscribe(response => {
                            if (response.success) {
                                // this.load();
                                this.cartConfiguration();
                                this.appStateService.refreshHeaderCart.next(true);
                                let currentPageCartType = this.router.url.includes("type=0") ? "slv_cart" : this.router.url.includes("type=1") ? "mhv_cart" : this.router.url.includes("type=2") ? "tmgo_cart" : null;
                                if (this.router.url.includes("cart/address")) {
                                    if (partCartType === currentPageCartType) {
                                        let cartAddressQueryParams = JSON.parse(this.localstorage.getItem("cartAddressQueryParams"));
                                        // cartAddressQueryParams.data = (parseInt(cartAddressQueryParams.data) - 1) <= 0 ? 0 : (parseInt(cartAddressQueryParams.data) - 1);
                                        // this.localstorage.setItem("routeReused", "true");
                                        // this.router.navigateByUrl('/', { skipLocationChange: true })
                                        //     .then(() => this.router.navigate(['cart/address'], { queryParams: cartAddressQueryParams }));
                                        this.appStateService.refreshSummary.next(true);
                                    }
                                }
                                else if (this.router.url.includes("cart/payment")) {
                                    if (partCartType === currentPageCartType) {
                                        let cartPaymentQueryParams = JSON.parse(this.localstorage.getItem("cartPaymentQueryParams"));
                                        // cartPaymentQueryParams.data = (parseInt(cartPaymentQueryParams.data) - 1) <= 0 ? 0 : (parseInt(cartPaymentQueryParams.data) - 1);
                                        // this.localstorage.setItem("routeReused", "true");
                                        // this.router.navigateByUrl('/', { skipLocationChange: true })
                                        //     .then(() => this.router.navigate(['cart/payment'], { queryParams: cartPaymentQueryParams }));
                                        this.appStateService.refreshSummary.next(true);
                                    }
                                }
                                else if (this.router.url.includes("cart/summary")) {
                                    // let cartSummaryQueryParams = JSON.parse(this.localstorage.getItem("cartSummaryQueryParams"));
                                    // cartSummaryQueryParams.data = (parseInt(cartSummaryQueryParams.data) - 1) <= 0 ? 0 : (parseInt(cartSummaryQueryParams.data) - 1);
                                    // this.localstorage.setItem("routeReused", "true");
                                    // this.router.navigateByUrl('/', { skipLocationChange: true })
                                    //     .then(() => this.router.navigate(['cart/summary'], { queryParams: cartSummaryQueryParams }));
                                    this.appStateService.refreshSummary.next(true);
                                }
                                for (let i = 0; i < this.data.items.length; i++) {
                                    if (item?.part_number === this.data.items[i].product.part_number) {
                                        this.data.items.splice(i, 1);
                                    }
                                }
                                for (let j = 0; j < this.data.allitems.length; j++) {
                                    if (item?.part_number === this.data.allitems[j].product.part_number) {
                                        this.data.allitems.splice(j, 1);
                                    }
                                }
                                this.save();
                                this.calc();
                            }
                            else {
                                let msg = response?.data?.msg;
                                if (msg.length > 0) {
                                    this.toasterService.error(msg);
                                }
                            }
                        },
                            error => {
                                let msg = error?.error?.data?.msg;
                                if (msg.length > 0) {
                                    this.toasterService.error(msg);
                                }
                            });
                    }
                    else {
                        this.data.items.forEach((element, index) => {
                            if (element.product.part_number === item.product['part_number']) {
                                this.data.items.splice(index, 1);
                            }
                        })
                        this.data.items1.forEach((element, index) => {
                            if (element.product.part_number === item.product['part_number']) {
                                this.data.items1.splice(index, 1);
                            }
                        })
                        this.data.allitems = this.data.items;
                        this.save();
                        this.calc();
                        // if (this.router.url.includes("cart/summary")) {
                        //     this.localstorage.setItem("refreshCartItems", "true");
                        //     this.appStateService.refreshCartItems.next(true);
                        // }
                        if (this.router.url.includes("cart/summary")) {
                            let cartSummaryQueryParams = JSON.parse(this.localstorage.getItem("cartSummaryQueryParams"));
                            cartSummaryQueryParams.data = (parseInt(cartSummaryQueryParams.data) - 1) <= 0 ? 0 : (parseInt(cartSummaryQueryParams.data) - 1);
                            this.localstorage.setItem("routeReused", "true");
                            this.router.navigateByUrl('/', { skipLocationChange: true })
                                .then(() => this.router.navigate(['cart/summary'], { queryParams: cartSummaryQueryParams }));
                        }
                        this.localstorage.setItem("isCartDropdownOpened", "false");
                    }
                    // this.save();
                    // this.calc();
                    // if (!this.isLoggedIn) {
                    //     this.appStateService.refreshCartItems.next(true);
                    // }
                })
            );

        }
        catch (error) {
            this.userService.InsertExceptionLog({ "method_name": "remove(item: CartItem)", "page_name": "CartService", "portal_type": "Customer", "page_url": "", "error_message": error.message, "inner_exception": error });
        }
    }

    refreshCartList() {
        let userData = JSON.parse(this.localstorage.getItem("userData"));
        let address = JSON.parse(this.localstorage.getItem('address'));
        let user = {
            account_pk: JSON.stringify(userData.account_pk),
            shipping_address_id: address.address['default'] ? true : address?.address['address_id']
        };
        this.responseItems = [];
        this.productService.get_cart_list_ms(user).subscribe(response => {
            if (response.success) {
                response.data.cartitems.forEach(result => {
                    const img = [];
                    img.push({
                        img: result.image
                    });
                    let product = {
                        product: {
                            part_number: result.part_number,
                            umrp: result.umrp,
                            image: result.image,
                            part_desc: result.part_desc,
                            id: result.id,
                            siebel_umrp: result.siebel_umrp,
                            cgst_sgst: result.cgst_sgst,
                            rate: result.rate,
                            total_amount: result.total_amount,
                            total_quantity: result.total_quantity,
                            uom: result.uom,
                            unit_quantity: result.unit_quantity,
                            part_minimum_quanity: result.part_minimum_quanity
                        },
                        quantity: result.quantity,
                        quantityControl: new FormControl(
                            result.quantity,
                            Validators.required
                        ),
                        type: result.cart_type
                    };
                    if (!this.responseItems.includes(product)) {
                        this.responseItems.push(product);
                    }
                });
                this.data.items = [];
                this.data.allitems = [];
                if (response.data.cartitems.length > 0) {
                    for (let i = 0; i < this.responseItems.length; i++) {
                        if (!this.data.items.includes(this.responseItems[i])) {
                            this.data.items.push(this.responseItems[i]);
                        }
                        if (!this.data.allitems.includes(this.responseItems[i])) {
                            this.data.allitems.push(this.responseItems[i]);//dynamic Cart
                        }
                    }
                }
                this.save();
                this.calc();
            }
            else {
                if (response?.data?.msg === "Data not found") {
                    this.data.items = [];
                    this.data.allitems = [];
                    this.save();
                    this.calc();
                }
            }
        })
    }

    public calc(): void {
        let quantity: number = 0;
        let subtotal: number = 0;
        let quantity1: number = 0;
        let subtotal1 = 0;
        let allArr = [];
        let mhvArr = [];
        let slvArr = [];
        this.data.items.forEach(item => {
            if (!allArr.includes(item)) {
                allArr.push(item);
            }
            if (item.type === 'mhv') {
                mhvArr.push(item)
                quantity += item.quantity;
                if (item.product["total_amount"]) {
                    subtotal += item.product["total_amount"];
                }
                else {
                    subtotal += item.product["umrp"] * item.quantity;
                }
            } else {
                slvArr.push(item)
                quantity1 += item.quantity;
                if (item.product["total_amount"]) {
                    subtotal1 += item.product["total_amount"];
                }
                else {
                    subtotal1 += item.product["umrp"] * item.quantity;
                }
            }
        });
        const totals: CartTotal[] = [];
        const totals1: CartTotal[] = [];
        totals.push({
            title: "Shipping",
            price: 25,
            type: "shipping"
        });
        totals1.push({
            title: "Shipping",
            price: 25,
            type: "shipping"
        });
        totals.push({
            title: "Tax",
            price: subtotal * 0.2,
            type: "tax"
        });
        totals1.push({
            title: "Tax",
            price: subtotal1 * 0.2,
            type: "tax"
        });
        const total =
            subtotal +
            totals.reduce((acc, eachTotal) => acc + eachTotal.price, 0);
        const total1 =
            subtotal1 +
            totals1.reduce((acc, eachTotal) => acc + eachTotal.price, 0);
        this.data.quantity = quantity;
        this.data.subtotal = subtotal;
        this.data.totals = totals;
        this.data.total = total;
        this.data.quantity1 = quantity1;
        this.data.subtotal1 = subtotal1;
        this.data.totals1 = totals1;
        this.data.total1 = total1;
        this.itemsSubject$.next(mhvArr);
        this.itemsSubject1$.next(slvArr);
        this.isLoggedIn = this.appStateService.isLoggedIn.value;
        if (!this.isLoggedIn) {
            allArr.reverse();
        }
        this.allitemsSubject$.next(allArr);
        this.quantitySubject$.next(this.data.quantity);
        this.subtotalSubject$.next(this.data.subtotal);
        this.totalsSubject$.next(this.data.totals);
        this.totalSubject$.next(this.data.total);
        this.quantitySubject1$.next(this.data.quantity1);
        this.subtotalSubject1$.next(this.data.subtotal1);
        this.totalsSubject1$.next(this.data.totals1);
        this.totalSubject1$.next(this.data.total1);
    }

    private save(): void {
        this.isLoggedIn = this.appStateService.isLoggedIn.value;
        if (!this.isLoggedIn) {
            this.localstorage.setItem('cartItems', JSON.stringify(this.data.items));//dynamic Cart
        }
    }


    load(): void {
        try {
            const items = this.localstorage.getItem("cartItems");
            if (items) {
                this.data.items = JSON.parse(items);
                this.data.allitems = JSON.parse(items);//dynamic Cart
            } else {
                // CHANGED BY IMRAN FOR SHALMALI YADAV RESOLUTION
                // this.data.items = []
                // this.data.allitems = []//dynamic Cart
                // this.calc();
                this.isLoggedIn = this.appStateService.isLoggedIn.value;
                if (this.isLoggedIn) {
                    try {
                        let userData = JSON.parse(this.localstorage.getItem("userData"));
                        if (userData) {
                            if (userData && userData.account_pk) {
                                let address = JSON.parse(this.localstorage.getItem('address'));
                                if (address) {

                                    var addresstypecheck = address.address['default'];
                                    if (addresstypecheck === true) {
                                        this.shippingaddressid = "default";
                                    }
                                    else {
                                        this.shippingaddressid = address.address['address_id'];
                                    }
                                    this.products = []
                                    let user = {
                                        account_pk: JSON.stringify(userData.account_pk),
                                        shipping_address_id: this.shippingaddressid
                                    };
                                    this.productService.get_cart_list_ms(user).subscribe(response => {
                                        localStorage.removeItem('division_info')
                                        this.localstorage.setItem('division_info', JSON.stringify(response.division_info));
                                        if (response.success === true) {
                                            this.products = [];
                                            response.data.cartitems.forEach(result => {
                                                const img = [];
                                                img.push({
                                                    img: result.image
                                                });
                                                let product = {
                                                    product: {
                                                        part_number: result.part_number,
                                                        umrp: result.umrp,
                                                        image: result.image,
                                                        part_desc: result.part_desc,
                                                        id: result.id,
                                                        siebel_umrp: result.siebel_umrp,
                                                        cgst_sgst: result.cgst_sgst,
                                                        rate: result.rate,
                                                        total_amount: result.total_amount,
                                                        total_quantity: result.total_quantity,
                                                        uom: result.uom,
                                                        unit_quantity: result.unit_quantity,
                                                        part_minimum_quanity: result.part_minimum_quanity
                                                    },
                                                    quantity: result.quantity,
                                                    type: result.cart_type
                                                };
                                                if (!this.products.includes(product)) {
                                                    this.products.push(product);
                                                }
                                            });
                                            this.data.items = [];
                                            this.data.allitems = [];
                                            if (response.data.cartitems.length > 0) {
                                                for (let i = 0; i < this.products.length; i++) {
                                                    if (!this.data.items.includes(this.products[i])) {
                                                        this.data.items.push(this.products[i]);
                                                    }
                                                    if (!this.data.allitems.includes(this.products[i])) {
                                                        this.data.allitems.push(this.products[i]);//dynamic Cart
                                                    }
                                                }
                                                this.calc();
                                            }
                                        }
                                        else {
                                            this.products = [];
                                            this.data.items = [];
                                            this.data.allitems = [];
                                            if (response?.data?.msg !== "Data not found") {
                                                this.toasterService.error(response.data.msg);
                                            }
                                            if (response?.data?.msg == "Data not found") {
                                                this.data.items = [];
                                                this.data.allitems = [];
                                                this.calc();
                                            }
                                        }
                                    }, error => {
                                        if (error?.error?.data?.msg == "Invalid Request") {
                                            this.toasterService.error(error.error.data.msg);
                                        }
                                    });
                                }
                            }
                        }
                    }
                    catch (e) {
                    }

                } else {
                    this.calc();
                }
            }

        }
        catch (error) {
            this.userService.InsertExceptionLog({ "method_name": "load()", "page_name": "CartService", "portal_type": "Customer", "page_url": "", "error_message": error.message, "inner_exception": error });
        }
    }
    async carttypeload() {
        await this.rootService.getCartType();

    }
    round(value, precision) {
        var multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    }

    storeRecentItems(partNumber) {
        this.recentItems = (JSON.parse(this.localstorage.getItem("Recent items")) == null || JSON.parse(this.localstorage.getItem("Recent items")) == undefined) ? [] :
            JSON.parse(this.localstorage.getItem("Recent items"));
        if (this.recentItems.length < 20) {
            this.recentItems.splice(0, 0, partNumber);
        }
        else if (this.recentItems.length == 20) {
            this.recentItems.splice(0, 1)
            this.recentItems.splice(0, 0, partNumber);
        }
        if (this.recentItems.length == 20 || this.recentItems.length < 20) {
            this.localstorage.setItem("Recent items", JSON.stringify(this.recentItems));
            this.appStateService.recentItems.next(true);
        }
    }

    async cartConfiguration() {
        let userData = JSON.parse(this.localstorage.getItem('userData'));
        let usercart = {
            account_pk: userData ? JSON.stringify(userData.account_pk) : '',
        };
        let cartresponse = await this.productService.getCartCount(usercart);
        if (cartresponse['success'] === true) {
            var cartdata = cartresponse['data'];
            this.appStateService.currentCartValue = cartdata;
            this.appStateService.refreshcartConfiguration.next(cartdata);
        }
    }

    refreshCartAfterRemoveAll() {
        this.cartConfiguration();
        this.appStateService.refreshSummary.next(true);
        this.appStateService.refreshHeaderCart.next(true);
        this.data.items = [];
        this.data.allitems = [];
        this.save();
        this.calc();
    }

}
