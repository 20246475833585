<!-- <div class="grecaptcha-badge" data-style="bottomright" >
  <div class="rc-anchor-normal-footer" >
    <div class="rc-anchor-logo-large" role="presentation">
      <img src="./assets/images/user.png" alt=""/>
    </div>
  </div>
  <div class="rc-anchor-invisible-text">
    <div class="rc-anchor-pt"> -->

      <div  *ngIf="currentUrl" id="chat-button" class="chat-btn help-btn" (click)="open()" style="padding: 0 !important;">
        <img  alt="message for Help" src="assets/images/chat.png" class="img-fluid chat-icon"/>
      </div>

        <form [formGroup]="queryform" name="queryform" class="form" (ngSubmit)="postQuery()">
      <div id="chat-wrapper" class="chatboat-wrapper">
        <div class="chatboat-title">
          Raise A Query <span class="ms-auto btn-close" (click)="close()"></span>
        </div>
        <div class="row">
          <div class="col-6 col-sm-6 col-lg-6 col-md-6 col-xl-6">
            <div class="mb-2" >
              <label class="form-label mend" >Full Name</label>
              <input type="text" class="form-control form-control-sm" [readonly]="disableFields"
               [ngClass]="{ 'is-invalid': submitted && f.account_name.errors }" 
              formControlName="account_name" />
              <!-- -->
              <!-- <div
                class="invalid-feedback"
                *ngIf="
                  submitted &&
                  f.account_name.errors?.required
                "
              >
                <sup>*</sup>Enter your name
              </div> -->
            </div>
          </div>
          <div class="col-6 col-sm-6 col-lg-6 col-md-6 col-xl-6">
            <div class="mb-2">
              <label class="form-label">Account Id</label>
              <input type="text" class= "form-control form-control-sm"  [readonly]="disableFields"
              formControlName="account_id"   />
            </div>
          </div>
          <div class="col-6 col-sm-6 col-lg-6 col-md-6 col-xl-6">
            <div class="mb-2">
              <label class="form-label mend">Mobile No</label>
              <input type="text" class="form-control form-control-sm" maxlength="10" [readonly]="disableFields"  
              [ngClass]="{ 'is-invalid': submitted && f.contact_no.errors }" 
                (keypress)="onlyNumberKey($event);"
              formControlName="contact_no" >
              <div *ngIf="mobileNumber.errors" class="invalid-feedback">
                <div *ngIf="mobileNumber.errors.pattern">
                  <span class="danger">*</span>Invalid Mobile No.
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-6 col-lg-6 col-md-6 col-xl-6">
            <div class="mb-2">
              <label class="form-label mend">Email Id</label>
              <input type="text"  pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
               class="form-control form-control-sm" formControlName="email_id"          
                  [ngClass]="{ 'is-invalid': submitted && f.email_id.errors }" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-sm-6 col-lg-6 col-md-6 col-xl-6">
            <div class="mb-2">
              <label class="form-label ">Part Number</label>              
              <input type="text" class="form-control form-control-sm" formControlName="part_number"/>

            </div>
          </div>
          <div class="col-6 col-sm-6 col-lg-6 col-md-6 col-xl-6">
            <div class="mb-2">
              <label class="form-label mend">Chassis Number</label>
              <input type="text" class="form-control form-control-sm" formControlName="chassis_number" 
              [ngClass]="{ 'is-invalid': submitted && f.chassis_number.errors }"/>
            </div>
          </div>
          <div class="col-6 col-sm-6 col-lg-6 col-md-6 col-xl-6">
            <div class="mb-2">
              <label class="form-label">Reg Number</label>
              <input type="text" class="form-control form-control-sm" formControlName="registration_number"/>
            </div>
          </div>
          <div class="col-6 col-sm-6 col-lg-6 col-md-6 col-xl-6">
            <div class="mb-2">
              
              <label class="form-label">Category</label>
              <div class="dropdown">
                <button class="btn  dropdown-toggle dropdown-new form-control form-control-sm"
                 type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{ selectedCategory !== '' ? selectedCategory.category_name :'Select Category'}}
                </button>
                <div class="dropdown-menu"  aria-labelledby="dropdownMenu2">
                 
                  <button *ngFor="let category of categoryList" (click)="Onselectedcategory(category)"
                   [(ngModel)]="selectedCategory" [ngModelOptions]="{standalone: true}" ngDefaultControl
                   class="dropdown-item" type="button">{{category.category_name | titlecase}}</button>
                </div>
              </div>
          <!-- <mat-form-field class="example-full-width" style="display: inline">
            <mat-select placeholder="Category Id"  (selectionChange)="Onselectedcategory($event.value)" name="category" 
            [formControl]="queryform.controls['category_id']" title="{{selectedCategory}}">
                <mat-option *ngFor="let category of categoryList" [value]="category.id"> {{category.category_name | titlecase}}</mat-option>
            </mat-select>
        </mat-form-field> -->
      </div>
    </div>


          <!-- <div class="col-6 col-sm-6 col-lg-6 col-md-6 col-xl-6">
            <div class="mb-2">
              <label class="form-label">Query Type</label>
              <select class="form-control form-control-sm">Select</select>
            </div>
          </div>
          <div class="col-6 col-sm-6 col-lg-6 col-md-6 col-xl-6">
            <div class="mb-2">
              <label class="form-label">Query Id</label>
              <select class="form-control form-control-sm">Select</select>
            </div>
          </div> -->
          <div class="col-12 col-sm-12 col-lg-12 col-md-12 col-xl-12">
            <div class="mb-2 img-text">
              <label for="imageUpload" class="form-label" >Attachment</label> &nbsp;
             <div class="img-holder">
              <input  #myInput type="file" 
              accept=".pdf, .jpg, .jpeg" [style.width]="isfileSelected ? 'width:100% !important' : 'width:91% !important' "
              class="form-comtrol form-control-sm form-css newdeltecss " (change)="onFileChange($event)" id="imageUpload"/>
              <button *ngIf="isfileSelected" class="uplad_file_format form-comtrol form-control-sm form-css new-btn" (click)="clearImage()"> <i class="far fa-trash-alt"></i> </button>
            
            </div>
              <label class="uplad_file_format">( .pdf, .jpg )</label>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-lg-12 col-md-12 col-xl-12">
            <div class="mb-2">
              <label class="form-label mend">Remarks</label>
              <textarea class="form-control form-control-sm" placeholder="Type here" formControlName="description" 
              [ngClass]="{ 'is-invalid': submitted && f.description.errors }" ></textarea>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-lg-12 col-md-12 col-xl-12 text-end">
            <button type="submit" class="btn btn-sm btn-outline-primary" >Submit</button>
          </div>
        </div>
       
      
      </div>
    </form>

    <!-- </div>
  </div>
</div>  -->

<ng-container *ngIf="showLoader">
  <div class="loader">
    <div class="ng-spinner ng-spinner-icon"></div>
  </div>
</ng-container>
