<div id="add_addrsModal" >
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title mb-0" id="add_addrsModalLabel">Contact Details</h5>
            <button type="button" class="btn-close" (click)="closeModal()" data-bs-dismiss="modal"
                aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <ul id="progressbar">
                <li [ngClass]="(showPersonalDetail) ? 'active' : 'active complete'"><span></span>Personal
                    Details</li>
                <li [ngClass]="(showAddressDetail) ? 'active' : ''"><span></span>Address Details</li>
            </ul>
            <div class="pt-2 pb-2">
                <strong><span class="error-message" style="float: left;margin-left: 0;">**</span><span style="float: left;">Address is not editable</span></strong>
            </div>
            <form id="personalDetail-form" class="mat-addrs-form mat-form-align" [formGroup]="personalDetailsForm"
                name="personalDetailsForm">

                <fieldset class="mat-fieldset text-start" *ngIf="showPersonalDetail">
                    <h5 class="personal-details-heading">
                        Personal Details <img src="./assets/images/contactPerson.png" (click)="openContactPersonList(address)" class="img-fluid me-1 contact-person-list-icon" />
                    </h5>
                    <div class="row g-2">
                        <div class="col-12">
                            <div class="form-group">
                                <label>Business Name</label>
                                <span class="error-message">*</span>
                                <input type="text" class="form-control" formControlName="name" maxlength="62"
                                    required placeholder="Business Name" max="62" (keypress)="alphanumbericOnly($event)"/>
                                <div class="error-message" *ngIf="hasPatternErrorPersonalDetailForm('name')">
                                    <b> &nbsp; *invalid. </b>
                                </div>
                                <span class="error-message">Note: </span><span style="color: blue;">Character length
                                    cannot exceed 63 characters.</span><br>
                                <p class="mat-phone contactname-on-address" *ngIf="( contactPersonData.first_name && contactPersonData.first_name !='' ) || ( contactPersonData.last_name && contactPersonData.last_name !='' ) "  >
                                    Contact Person:
                                    <span *ngIf="contactPersonData.first_name && contactPersonData.first_name !=''">
                                        {{ contactPersonData.first_name }}
                                    </span>
                                    <span *ngIf="contactPersonData.last_name && contactPersonData.last_name !=''">
                                        {{ contactPersonData.last_name }}
                                    </span>
                                </p>
                                <p class="mat-phone" *ngIf="contactPersonData?.contact_number && contactPersonData?.contact_number !=''">Contact No. : +91
                                    {{ contactPersonData?.contact_number }}
                                </p>
                            </div>
                        </div>
                        <!-- <div class="col-12 col-md-6">
                            <div class="form-group">
                                <label>Contact Person Name</label>
                                <input type="text" class="form-control" formControlName="contact_person_name"
                                    placeholder="Contact Person Name" />
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <label style="margin-left: 47px;">Contact Person Mobile</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">+91</span>
                                    </div>
                                    <input type="tel" class="form-control" maxlength="10"
                                        formControlName="contact_no" placeholder="Contact Person Mobile"
                                        (keypress)="numberOnly($event)" aria-describedby="mobile no">
                                </div>
                            </div>
                            <div class="error-message" *ngIf="hasPatternErrorPersonalDetailForm('contact_no')">
                                <b> &nbsp; *invalid. </b>
                            </div>
                        </div> -->

                        <!-- add contact person  -->

                        <!-- <div class="mat-contact-person">
                            <label>Select Contact Person</label>
                            <div class="add_new_cta">
                                <select *ngIf="contactPersonList" (change)="askUpdateContactPerson($event.target.value)" class="form-select" aria-label="Default select example">
                                    <option *ngFor="let contactPerson of contactPersonList" [value]="contactPerson.row_id">
                                    {{ contactPerson.first_name }} {{  contactPerson.last_name }} ({{ contactPerson.contact_number }})
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="contact-person-list">
                            <a (click)="openAddContactPersonModal()" class="text-decoration-none add_new-addrs-cta cursor"><img
                                src="./assets/images/add-new-icon.svg" class="img-fluid me-1" /> Add Contact</a>
                        </div> -->

                        <div class="error-message ml-1">
                            <b *ngIf="isFirstFormError">All fields marked with <span class="error-message">*</span>
                                are mandatory.</b>
                            <div *ngIf="isgstcontacterror && submitted">
                                OR
                                <br>
                                <b>Please Enter Valid data.</b>
                            </div>

                            <p style="color: red;">{{Error}}</p>
                        </div>
                        <div class="col-12 text-end action-cta">
                            <button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal"
                                (click)="closeModal()">Cancel</button>
                            <button type="button" class="btn btn-primary mat-next"
                                (click)="showAddressDetails()">Next</button>
                        </div>
                    </div>

                </fieldset>
            </form>
            <form id="address-form" class="mat-addrs-form" [formGroup]="addressForm" name="addressForm">

                <fieldset class="mat-fieldset text-start" *ngIf="showAddressDetail">
                    <h5 class="address-details-heading">Address Details</h5>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <label class="w-100">Search Address By</label>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" [checked]="isManual" (keypress)="alphanumbericOnly($event)"
                                        (change)="changeSearchType('Manual')" type="radio" name="inlineRadioOptions"
                                        id="Office" value="Office">
                                    <label class="form-check-label" for="Office"
                                        (click)="changeSearchType('Manual')">Manually</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="number"
                                        (keypress)="onlyNumberKey($event);" [checked]="isPincode"
                                        (change)="changeSearchType('Pincode')" type="radio"
                                        name="inlineRadioOptions" id="Workshop" value="Workshop">
                                    <label class="form-check-label" for="Workshop"
                                        (click)="changeSearchType('Pincode')">Pincode</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" [checked]="isCity" (keypress)="alphanumbericOnly($event)"
                                        (change)="changeSearchType('City')" type="radio" name="inlineRadioOptions"
                                        id="Other" value="Other">
                                    <label class="form-check-label" for="Other"
                                        (click)="changeSearchType('City')">City</label>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="form-group" *ngIf="isPincode">
                                <input class="form-control" placeholder="Search By Pincode" maxlength="6"
                                    [(ngModel)]="searchByPincode" [ngModelOptions]="{standalone: true}"
                                    (keyup.enter)="addAddressByPinCode()" (keypress)="onlyNumberKey($event)"
                                    (keyup)="checkPincodeValidate(searchByPincode)" *ngIf="isPincode">

                                <label class="unable_verify d-flex align-items-center"
                                    *ngIf="validPincode && isPincode"><img
                                        src="./assets/images/button-circle-warning.svg"
                                        class="img-fluid me-1 icon-verify" />Enter valid 6 digit Pincode</label>

                            </div>
                            <div class="form-group" *ngIf="isCity">
                                <ng-select [items]="geocityList" (change)="clearAll(selectedCity);"
                                    bindLabel="city_name" placeholder="Select city" [(ngModel)]="selectedCity"
                                    [ngModelOptions]="{standalone: true}" [minTermLength]="3" appAlphabetOnly
                                    [typeahead]="cityInput$"></ng-select>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group" *ngIf="isPincode">
                                <label></label>
                                <span class="error-message"></span>
                                <button type="button" class="btn btn-outline-primary"
                                    (click)="addAddressByPinCode()">Search</button>
                                <label></label>
                                <span class="error-message"></span>
                                <button type="button" class="btn btn-outline-primary"
                                    (click)="clearAll(null)">Reset</button>
                            </div>

                        </div>

                    </div>
                    <div class="row">


                        <ng-container *ngIf="isManual || isAddressView">
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label>State</label>
                                    <span class="error-message">*</span>
                                    <select class="form-select" aria-label="" formControlName="state"
                                        (change)="getDistricts('false')">
                                        <option value="" selected disabled>State</option>
                                        <option *ngFor="let state of stateList" value="{{state.code}}">{{state.name
                                            |
                                            titlecase}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label>District</label>
                                    <span class="error-message">*</span>
                                    <select class="form-select" aria-label="" formControlName="district"
                                        (change)="getCities('false')">
                                        <option value="" selected disabled>District</option>
                                        <option *ngFor="let district of districtList" value="{{district}}">
                                            {{district |
                                            titlecase}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label>City</label>
                                    <span class="error-message">*</span>
                                    <select class="form-select" formControlName="city" aria-label=""
                                        (change)="getTalukas('false')">
                                        <option value="" selected disabled>City</option>
                                        <option *ngFor="let city of cityList" value="{{city}}">{{city | titlecase}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label>Taluka</label>
                                    <select class="form-select" aria-label="" formControlName="taluka"
                                        (change)="getPincodes('false')">
                                        <option value="" selected disabled>Taluka</option>
                                        <option *ngFor="let taluka of talukaList" value="{{taluka}}">{{taluka |
                                            titlecase}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-12 col-md-12">
                                <div class="form-group">
                                    <label>Address</label>
                                    <span class="error-message">*</span>
                                    <input type="text" formControlName="address" maxlength="100" class="form-control"
                                        placeholder="Enter Address" (keypress)="alphanumbericOnly($event)">
                                    <div class="error-message" *ngIf="hasPatternError('address')">
                                        <b> &nbsp; *invalid. </b>
                                    </div>
                                    <span class="error-message">Note: </span><span style="color: blue;">Character
                                        length
                                        cannot exceed 100 characters.</span>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label>Pincode</label>

                                    <span class="error-message">*</span>
                                    <select class="form-select" aria-label="" formControlName="pincode">
                                        <option value="" selected disabled>Enter Pincode</option>
                                        <option *ngFor="let pincode of pincodeList" value="{{pincode}}">{{pincode}}
                                        </option>
                                    </select>
                                    <div class="error-message" *ngIf="hasRequiredError('pincode')">
                                        <b> &nbsp; *required. </b>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label>GST Number</label>
                                    <input type="text" formControlName="gst_no" #input
                                        (input)="input.value=$event.target.value.toUpperCase()" maxlength="15"
                                        class="form-control" placeholder="GST Number" />
                                </div>
                                <div class="error-message" *ngIf="hasPatternError('gst_no')">
                                    <b> &nbsp; *invalid. </b>
                                </div>
                            </div>
                        </ng-container>

                        <div class="error-message ml-1">
                            <b *ngIf="addressForm.invalid && submitted">All fields marked with <span
                                    class="error-message">*</span> are mandatory.</b>
                            <div *ngIf="isgstcontacterror && submitted">
                                OR
                                <br>
                                <b>Please Enter Valid data.</b>
                            </div>

                            <p style="color: red;">{{Error}}</p>

                        </div>
                        <div class="col-12 text-end action-cta">
                            <button type="button" class="btn btn-outline-primary"
                                (click)="showPersonalDetails()">Prev</button>
                            <button type="button" [disabled]="btndisabled" class="btn btn-primary"
                                (click)="onSubmit()">Save</button>
                        </div>
                    </div>
                </fieldset>
            </form>

        </div>
    </div>
</div>

<!-- <ng-template #addContactPerson let-d="dismiss">
    <app-add-contact-person (closeModal)="closeContactPersonModal()"></app-add-contact-person>
</ng-template> -->

<!-- <ng-template #confirmationContactPerson let-d="dismiss">
    <app-logout [isContactPersonUpdate]="isContactPersonUpdate" (contactPersonUpdate)="updateContactPerson($event);" (modalClose)="confirmationContactPersonModal.hide()"></app-logout>
</ng-template> -->

<ng-template #listContactPersonModal let-d="dismiss">
    <app-contact-person-list [addressData]="contactPersonDetails" (closeContactPerson)="hideContactPersonList()" (updateContactDetails)="updateContactDetailsForAddAddress($event)"></app-contact-person-list>
</ng-template>

<div class="loader" *ngIf="showLoader">
    <div class="ng-spinner ng-spinner-icon"></div>
</div>
