<div class="container-fluid report">
    <div class="row">
      <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 pb-3 pt-3 mobile-m-top">
        <ul class="nav nav-tabs tabs1" id="myTab" role="tablist">
          <li class="nav-item" role="presentation" [routerLink]="['/account/report']">
            <a class="nav-link" id="order-tab" data-bs-toggle="tab" href="#order" role="tab" aria-controls="order"
              aria-selected="true">Order Tracking</a>
          </li>
          <li class="nav-item" role="presentation" [routerLink]="['/account/etareport']">
            <a class="nav-link" id="order-tab" data-bs-toggle="tab" href="#order" role="tab" aria-controls="order"
              aria-selected="true">ETA Reports</a>
          </li>
          <li class="nav-item" role="presentation" [routerLink]="['/account/servicerequestreport']" *ngIf="userDetails?.user_type == 'FO'">
            <a class="nav-link active" id="order-tab" data-bs-toggle="tab" href="#order" role="tab"
                aria-controls="order" aria-selected="true">Service Request</a>
        </li>
          <li class="nav-item ms-auto d-flex" style="align-items: center;cursor: pointer;" role="presentation">
            <!-- <button class="mat-icon-button mat-accent" matTooltip="Export to Excel" (click)="downloadExcel();">
              <span class="mat-button-wrapper">
                  <i class="fa fa-file-excel fa-lg"></i>
              </span>
            </button> -->
          </li>
        </ul>
        <hr class="hr-page-divider" />
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="order" role="tabpanel" aria-labelledby="order-tab">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3 filter-are">
                <h3 class="section-title">Filters</h3>
                <div class="ps-1 pe-1 pt-2 pb-2">
                  <button type="button" class="btn btn-outline-primary"
                    (click)="applyFilter()">Search</button>&nbsp;
                  <button type="button" class="btn btn-outline-primary" (click)="reset('')">Reset</button>
                </div>
                <div class="sub-title ps-1 pe-1">
                  DURATION
                </div>
                <div class="ps-1 pe-1 pt-2 pb-2">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                      <div class="row mt-2">
                        <div class="col-6 position-relative">
                          <mat-form-field style="display: inline">
                            <input matInput [max]="today" placeholder="From Date" (click)="picker1?.open()" readonly
                              [formControl]="filterForm.controls['start']" [matDatepicker]="picker1">
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1 disabled="false"></mat-datepicker>
                          </mat-form-field>
                        </div>
                        <div class="col-6 position-relative">
                          <mat-form-field style="display: inline">
                            <input matInput [max]="today" placeholder="To Date" (click)="dp3?.open()" readonly
                              [formControl]="filterForm.controls['end']" [matDatepicker]="dp3">
                            <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                            <mat-datepicker #dp3 disabled="false"></mat-datepicker>
                          </mat-form-field>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="sub-title ps-1 pe-1">
                  Status
                  <div class="pt-2 pb-2">
                    <select class="form-select" aria-label=""  [formControl]="filterForm.controls['status']">
                        <option value="Total" selected disabled>Select Status</option>
                        <option *ngFor="let item of CurrentStatus" [value]="item.id">{{item.text}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9 ">
                <div class="card table-wrapper">
                    <div class="card-body p-0">
                    <div class="table-responsive table-container">
                        <table class="table datatable display" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                            <thead>
                                <tr>
                                    <th class="sticky-col first-col text-nowrap" scope="col" >Request Id.</th>
                                    <th scope="col" class="text-nowrap" style="text-align: center;">Registration Number</th>
                                    <th scope="col" class="text-nowrap" style="text-align: center;">Driver Name</th>
                                    <th scope="col" class="text-nowrap" style="text-align: center;">Driver Contact No.</th>
                                    <th scope="col" class="text-nowrap" style="text-align: center;">Mechanic Name</th>
                                    <th scope="col" class="text-nowrap" style="text-align: center;">Mechanic Contact No.</th>
                                    <th scope="col" class="text-nowrap" style="text-align: center;">Service Type</th>
                                    <th scope="col" class="text-nowrap" style="text-align: center;">Current Status</th>
                                    <th scope="col" class="text-nowrap" style="text-align: center;">Created Date</th>
                                    <th scope="col" class="text-nowrap" style="text-align: center;">Action</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let item of fitmentData">
                                <tr >
                                    <td class="tdmanage sticky-col first-col" style="width: 80.609px !important">
                                        {{item.id}}
                                    </td>
                                    <td class="tdmanage"  style="text-align: center;text-transform: uppercase;">
                                        {{item.registration_number}}
                                    </td>
                                    <td class="tdmanage" style="text-align: center;">
                                        {{item.driver_name}}
                                    </td>
                                    <td class="tdmanage" style="text-align: center;">
                                        {{item.driver_mobile_number}}
                                    </td>	
                                    <td class="tdmanage" style="text-align: center;">
                                        {{item.guru_name ? item.guru_name : "NA"}}
                                    </td>
                                    <td class="tdmanage" style="text-align: center;">
                                       {{item.guru_phone_number ? item.guru_phone_number : 'NA'}}
                                    </td>
                                    <td class="tdmanage" style="text-align: center;">
                                        {{item.service_type}}
                                    </td>
                                    <td class="tdmanage" style="text-align: center;">
                                        {{item.case_status}}
                                    </td>
                                    <td class="tdmanage" style="text-align: center;">
                                        {{item.created_date  | date: 'dd-MM-yyyy h:mm a'}}
                                    </td>
                                    <td class="tdmanage" style="text-align: center;">
                                        <a class="me-2 text-nowrap" (click)="openStatus(item)" *ngIf="item.guru">View All Status</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                  </div>
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #actionDetails let-d="dismiss">
    <app-logout [isFitment]="true" (modalClose)="close($event)" [fitmentData]="previousStatus"></app-logout>
  </ng-template>
  <div class="loader" *ngIf="showLoader">
    <div class="ng-spinner ng-spinner-icon"></div>
  </div>