import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { Observable } from 'rxjs';
import { applicationConfiguration, AppConfig } from 'src/app/config/app.config';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    redirectUrl:any;
    protected server = this.appConfig.apiUrl + 'api/v2/ecom/';
    protected oldServer = this.appConfig.apiUrl + 'api/v1/ecom/';
    readonly options = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
        }),
    };
    constructor(
        private http: HttpClient,
        @Inject(applicationConfiguration) private appConfig: AppConfig
    ) { }

    // login(user): Observable<any> {
    //     return this.http.post(`${this.server}login/`, user, this.options);
    // }
    // verifyOTP(user): Observable<any> {
    //     return this.http.post(`${this.server}login/otp/`, user, this.options);
    // }

    register(user): Observable<any> {
        return this.http.post(`${this.server}register/`, user, this.options);
    }
    verifyregisterOTP(user): Observable<any> {
        return this.http.post(`${this.server}register/otp/`, user, this.options);
    }

    // otheruserlogin(user): Observable<any> {
    //     return this.http.post(`${this.server}other_user/login/`, user, this.options);
    // }
    otheruserverifyOTP(user): Observable<any> {
        return this.http.post(`${this.server}other_user/verify_otp/`, user, this.options);
    }
    GetOtherUserPermissionList(token): Observable<any> {
        return this.http.post(`${this.oldServer}get_other_user_permission_list/`,token, this.options);
    }

    SetUserNewPassword(user): Observable<any> {
        return this.http.post(`${this.server}other_user/reset_password/`, user, this.options);
    }

    GetPageList(data): Observable<any> {
        return this.http.post(
          `${this.oldServer}user/get_page_list/`,
          data,
          this.options
        );
    }

    otheruserSendOtp(user): Observable<any> {
        return this.http.post(`${this.oldServer}other_user/registration/send_otp/ `, user, this.options);
    }

    login(user): Observable<any> {
        return this.http.post(`${this.server}account/login/`, user, this.options);
    }

    verifyOTP(user): Observable<any> {
        return this.http.post(`${this.server}account/login_verify_otp/`, user, this.options);
    }

    getUserTypeList(user): Observable<any> {
        return this.http.post(`${this.server}account/get_user_list/`, user, this.options);
    }

    getAccountHealthCheck(req):Observable<any>{
        return this.http.post(`${this.server}api/v2/ecom/account/account_health_check/`,req,this.options);
    }
    UserRegistration(user): Observable<any> {
        return this.http.post(`${this.server}account/registration_send_otp/`, user, this.options);
    }

    guestUserRegistration(requestBody) :Observable<any> {
      return this.http.post(`${this.server}account/guest_user_registration/`, requestBody, this.options);
    }

    VerifyOtpUserRegistration(user): Observable<any> {
        return this.http.post(`${this.server}account/registration_verify_otp/`, user, this.options);
    }

    otheruserlogin(user): Observable<any> {
        return this.http.post(`${this.server}other_user/login/`, user, this.options);
    }

    checkForRegisteredUser(requestBody) :Observable<any> {
      return this.http.post(`${this.server}account/get_contact_number/`, requestBody, this.options);
    }

    getFBConfigurations():Observable<any>{
        return this.http.post(`${this.server}account/get_enc/`,this.options);
    }
}
