import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { Observable } from 'rxjs';
import { applicationConfiguration, AppConfig } from 'src/app/config/app.config';

@Injectable({
  providedIn: 'root'
})
export class UserCartService {

  protected server = this.appConfig.apiUrl + 'api/v1/ecom/';
  protected newServer = this.appConfig.apiUrl + 'api/v2/ecom/';
  readonly options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  constructor(
    private http: HttpClient,
    @Inject(applicationConfiguration) private appConfig: AppConfig
  ) { }

  addToCart(cartData): Observable<any> {
    return this.http.post(`${this.newServer}cart/bulk_cart_upload/`, cartData, this.options);
  }

  getCartData(user):Observable<any> {
    return this.http.post(`${this.server}cart/list/`, user, this.options);
  }
  profile(user):Observable<any> {
    return this.http.post(`${this.server}profile/`, user, this.options);
  }

  bulkUploadAddToCart(user):Observable<any> {
    return this.http.post(`${this.server}bulk_upload_excel_cart/`, user);
  }
}
