import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { AppFormErrorService } from 'src/app/shared/services/form-error.service';
import { UserService } from 'src/app/shared/services/REST/user.service';
import { ToastrService } from 'ngx-toastr';
import { secureStorage } from 'src/app/shared/services/securestorage';
import { Title } from '@angular/platform-browser';
import * as CryptoJS from "crypto-js";

@Component({
  selector: 'set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss'],
})
export class SetPasswordComponent implements OnInit {
  passwordmatch: boolean = false;
  btndisabled: boolean = false;
  submitted: boolean = false;
  setpasswordForm: FormGroup;
  Error: any;
  constructor(
    private formBuilder: FormBuilder,
    private formErrorService: AppFormErrorService,
    private userservice: UserService,
    private toaster: ToastrService,
    private localstorage: secureStorage,
    private title:Title
  ) { }
  ngOnInit() {
    this.newForm();
    this.title.setTitle('E-Dukaan | Set Password');
  }
  get f() {
    return this.setpasswordForm.controls;
  }
  newForm() {
    this.setpasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, 
          Validators.minLength(6),
          Validators.pattern(/[A-Z]/),
          Validators.pattern(/[a-z]/),
          Validators.pattern(/[0-9]/),
          Validators.pattern(/[!@#$]/)
      ]],
      confirm_password: ['', [Validators.required]],
    });
  }
  preventSpace(event: KeyboardEvent) {
    if (event.code === 'Space') {
      event.preventDefault();
    }
  }

  onSubmit() {
    this.Error = '';
    try {
      this.submitted = true;
      if (this.setpasswordForm.invalid) {
        this.formErrorService.displayFormErrors(this.setpasswordForm);
        return;
      }
      if (
        this.setpasswordForm.value.password !==
        this.setpasswordForm.value.confirm_password
      ) {
        this.passwordmatch = true;
        return this.toaster.error(
          'Password and confirm password does not match'
        );
      } else {
        this.passwordmatch = false;
      }
      this.btndisabled = true;
      let user = JSON.parse(this.localstorage.getItem('userData'));
      if (user) {
        var skey = "edp@$3#drishti"
        var salt = "dRishtI";
        var  iterations = 128;
        var  bytes = CryptoJS.PBKDF2(skey, salt, { keySize: 48, iterations: iterations });
        var  iv = CryptoJS.enc.Hex.parse(bytes.toString().slice(0, 32));
        var  key = CryptoJS.enc.Hex.parse(bytes.toString().slice(32, 96));
        if (user.isDelegateUser) {
          let requestBody = {
            new_password:CryptoJS.AES.encrypt(this.setpasswordForm.value.password, key, { iv: iv }).toString(),
            confirm_password:CryptoJS.AES.encrypt(this.setpasswordForm.value.password, key, { iv: iv }).toString(),
            user_type:user.user_type,
            account_pk:user.account_pk
          }
          this.userservice.otherUserChangePassword(requestBody).subscribe(response => {
            this.btndisabled = false;
            this.submitted = false;
            if (response.success === true) {
              this.toaster.success('User password set successfully.');
              this.setpasswordForm.patchValue({
                password: '',
                confirm_password: '',
              });
            } else {
              this.toaster.error(response.data.msg);
            }
          })
        }
        else {
          let data = {
            account_pk: JSON.stringify(user.account_pk),
            password: CryptoJS.AES.encrypt(this.setpasswordForm.value.password, key, { iv: iv }).toString(),
          };
          this.userservice.usersetPassword(data).subscribe((response) => {
            this.btndisabled = false;
            this.submitted = false;
            if (response.success === true) {
              this.toaster.success('User password set successfully.');
              this.setpasswordForm.patchValue({
                password: '',
                confirm_password: '',
              });
            } else {
              this.toaster.error(response.data.msg);
            }
          });
        }
      }
    } catch (error) { }
  }
  checkValidation(confirmpassword) {
    if (confirmpassword !== this.setpasswordForm.value.password) {
      this.submitted = true;
      this.passwordmatch = true;
    } else {
      this.submitted = false;
      this.passwordmatch = false;
    }
  }
  public hasRequiredError = (controlName: string): boolean =>
    this.formErrorService.hasRequiredError(controlName, this.setpasswordForm);
  public hasPatternError = (controlName: string): boolean =>
    this.formErrorService.hasPatternError(controlName, this.setpasswordForm);
}
