
import { Component, OnInit, Output, EventEmitter, Input, TemplateRef, ViewChild, ChangeDetectorRef } from "@angular/core";
import { ProductService } from '../../services/REST/product.service';
import { UserService } from '../../services/REST/user.service';
import { QuickviewService } from '../../services/quickview.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { secureStorage } from "../../services/securestorage";
import { Router } from "@angular/router";
import { Product } from "../../interfaces/product";
import { CartService } from "../../services/cart.service";
import { FormControl } from "@angular/forms";
import { blockPermissionMsg } from 'src/app/constant';
import { AppStateService } from "../../services/app-state/app-state.service";
import { Subject } from "rxjs";
declare var $: any;

interface ProductImage {
    id: string;
    url: string;
    active: boolean;
}

@Component({
    selector: 'alternate-part',
    templateUrl: './alternate-part.component.html',
    styleUrls: ['./alternate-part.component.scss']
})

export class AlternatePartComponent implements OnInit {
    
    @Input() alternatePartData:any;
    public dtTrigger: Subject<any> = new Subject();
    @Output() closemodal = new EventEmitter<any>();
    account_pk:any;
    address_pk:any;
    showLoader:boolean = false;
    shippingaddressid:any;
    divisionInfoData:any;
    productList:any[] = [];
    product:Product;
    btnshowChildParts:boolean = false;
    
    // discount method
    private dataProduct: Product;
    cartTypeList: any;
    selectcarttype: any;
    btndisabled: boolean = false;
    btnshowConfrimed: boolean = false;
    btnshowsubmit: boolean = false;
    cartTypeModal: any;
    billingaddressid: any;
    isLoggedIn:boolean=false;
    baseProductdiscount: any;
    ProductMRP: any;
    @ViewChild('confimationmodal', { static: false }) template: TemplateRef<any>;
    Productdiscount: any;
    images: ProductImage[] = [];

    // showchildpart
    sendchildproduct: any;
    childPartData: any[];
    totalrecord: number;

    // partnumberbaseddiscount
    baseProductdata: any;
    productNewValues: any;
    isNormalDiscount: boolean = false;
    savedAmount: number = 0;
    normalPercentage: number = 0;
    partNumber: any;

    // getOfferDetails
    discountOffers: any;
    isQuantityDiscountAvailable: boolean = false;
    showQuantityDiscountMessage: boolean = false;

    // getDiscountedPriceDetails
    quantityBasedPartDetails: any;
    quantityBasedDivisionDetails: any;
    quantityBasedPartPrice: number = 0;
    quantityBasedPercentage: number = 0;

    // readQuantity
    currentQuantity: number = 1;

    // addToCart
    addingToCart = false;
    currentlyAddingProduct: any;
    selectedProduct: Product;
    unit: FormControl = new FormControl(1);

    childPartsModal: any;
    @ViewChild('childParts', { read: TemplateRef, static: false }) childParts: TemplateRef<any>;

    isProductDetails: boolean = false;
    addressModal: any;
    @ViewChild('addressModal', { read: TemplateRef, static: false }) template1: TemplateRef<any>;

    pinCode: any;
    pinCodeNumber: number;
    usertype: any;
    addresses: any;
    default_address: any;
    new_addresses: any = [];

    showAlternatePartPopup:boolean = false;

    isWishlistedItem :any;
    etaList:any[]=[];
    partLimitCheck:any[]=[];

    @ViewChild('schemeList', { read: TemplateRef, static: false }) schemeList:TemplateRef<any>;
    schemeListModal:any;
    discountOfferData:any=[];
    discountSlabOffer :any[]=[];
    isshowMoreSlabDiscount:boolean =false;
    popupOpenType:any;
    validSchemeList:any[]=[];
    productInfo:any;
    userData:any
    isUserType:any;
    pageURL:any;
    constructor(
        private userService: UserService,
        private appStateService: AppStateService,
        private productService: ProductService,
        private router: Router,
        public quickview: QuickviewService,
        private toasterService: ToastrService,
        private modalService1: BsModalService,
        private localstorage: secureStorage,
        private cd: ChangeDetectorRef,
        private cart: CartService,
        ) { 
            this.appStateService.refreshDiscounts.subscribe(refreshDiscount => {
                if (refreshDiscount) {
                //   if (this.showProduct === false) {
                    //setTimeout(() => {
                    // this.getFreshProductDetails(true);
                    this.loadData();
                    this.appStateService.refreshDiscounts.next(false);
                  
                    //}, 1000);
                //   }
                }
              });

        }

    ngOnInit() {
        this.appStateService.wishlistItemadded.subscribe((wishlistAdded) => {
            if(this.router.url.includes('product-details') && wishlistAdded){ 
            this.isWishlistedItem = true;
           }
        });
        this.userData = JSON.parse(this.localstorage.getItem('userData'));
        let address = JSON.parse(this.localstorage.getItem('address'));
        let billingaddress = JSON.parse(this.localstorage.getItem('billingAddress'));
        this.partNumber = this.alternatePartData.data[0].part_number;
        if (address?.address['default']) {
            this.shippingaddressid = "default";
        }
        else {
            this.shippingaddressid = address?.address['address_id'];
        }
        if (billingaddress?.address['default']) {
            this.billingaddressid = "default";
        }
        else {
            this.billingaddressid = billingaddress?.address['address_id'];
        }
        this.appStateService.isLoggedIn.subscribe(isLog => {
            this.isLoggedIn = isLog;
        });
        this.appStateService.postalCode.subscribe(postalCode => {
            this.pinCodeNumber = postalCode;
        });
        this.pinCode = JSON.parse(this.localstorage.getItem('Pincode'));
        this.pinCodeNumber = (parseInt(this.pinCode)) ? parseInt(this.pinCode) : 0;
        this.getAddressDetails(true);
        this.loadData();
        setTimeout(() => {
            this.productslide();
        }, 1200);
    }
    ngAfterViewInit() {
        this.dtTrigger.next();
        if (this.localstorage.getItem('getCartType')) {
            this.cartTypeList = JSON.parse(this.localstorage.getItem('getCartType'));
        }
        if (this.cartTypeList.length > 0) {
            // let currentUrl = this.router.url;
            // if (currentUrl.includes('product-details')) {
            //     this.btndisabled = false;
            //     this.btnshowConfrimed = true;
            //     this.btnshowsubmit = false;
            //     if (this.dataProduct['part_status'] !== 'in_active') {
            //     this.modalRef = this.modalService1.show(this.template, { class: 'modal-dialog-centered modal-md', backdrop: 'static' });
            //     }
            // }
        }
    }
    loadData() {
        try {
            this.divisionInfoData = [];
            if(this.alternatePartData){
                let response = this.alternatePartData;
                if (response.success === true) {
                    this.showAlternatePartPopup = true;
                    this.divisionInfoData = {
                        ORG_NAME:response.data[0].organization_name?response.data[0].organization_name:"",
                        DIV_NAME:response.data[0].division_name?response.data[0].division_name:"",
                        PRIMISE_ADDRLINE1_s:response.data[0].primise_addrline1?response.data[0].primise_addrline1:"",
                        PRIMISE_ADDRESS2_s:response.data[0].primise_addrline2?response.data[0].primise_addrline2:"",
                        DIV_ID:response.data[0].division_id?response.data[0].division_id:"",
                        GSTIN:response.data[0].gstn?response.data[0].gstn:""
                    };
                    this.isWishlistedItem = response.is_wishlist;
                    this.productList = response.data;
                    this.productInfo= response
                    this.productInfo.division_info = {
                        ORG_NAME:response.data[0].organization_name?response.data[0].organization_name:"",
                        DIV_NAME:response.data[0].division_name?response.data[0].division_name:"",
                        PRIMISE_ADDRLINE1_s:response.data[0].primise_addrline1?response.data[0].primise_addrline1:"",
                        PRIMISE_ADDRESS2_s:response.data[0].primise_addrline2?response.data[0].primise_addrline2:"",
                        DIV_ID:response.data[0].division_id?response.data[0].division_id:"",
                        GSTIN:response.data[0].gstn?response.data[0].gstn:"",
                        ORG_Id:response.data[0].organization_id?response.data[0].organization_id:"",
                    };
                    let responseImages: any[] = [];
                    for (let i = 0; i < response.data.length; i++) {
                        this.productList[i].customerQuantity = response.data[i].min_quantity;
                        this.productList[i].unit_quantity = response.data[i].moq;
                        if (response.data[i].multi_img) {
                            responseImages = response.data[i].multi_img;
                            var prodimage = responseImages.filter(
                                (Data) => Data.is_cover == true
                            );
                            if (prodimage.length > 0) {
                                this.productList[i].image = prodimage[0]?.img;
                            }
                        }

                        // this.productList[i].customerQuantity = userqty ? userqty : response.data[i].min_quantity
                    }
                    
                    this.product = response.data[0];
                    this.product["customerQuantity"] = response.data[0].min_quantity
                    var images = (this.product as any).multi_img.map((url, index) => {
                        return {
                            id: index.toString(),
                            url: url.img,
                            active: index === 0
                        };
                    });
                    this.selectImg(images[0].url,0,'thumbnil')

                    // this.product["customerQuantity"] = userqty ? userqty : response.data[0].min_quantity;
                    this.pageURL = window.location.href;
                    this.pageURL = this.pageURL.replace(/(=)\d+/, '=' + this.product?.part_number);
                    this.showLoader = false;
                    // this.discountBasedproduct(this.productList[0],userqty);
                    this.discountBasedproduct(this.productList[0]);

                    // CHILD PART IMPLEMENTAION
                    var childPART_NUMBER_s = this.product['part_number'].substring(0, 4);
                    if (childPART_NUMBER_s == '8854') {
                        this.showchildpart(this.product);
                        //    this.rerender();
                        // this.btnshowChildParts = true;
                    }
                    else {
                        this.btnshowChildParts = false;
                    }
                }
            }
        }
        catch (error) {
            this.userService.InsertExceptionLog({ "method_name": "loadData(partNumber)", "page_name": "PageAlternatePartComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
        }
    }

    gotoProductDetails(part_number){
        this.closemodal.emit();
        this.router.navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/shop/product-details'], { queryParams: { part_Number: part_number } }));
    }

    discountBasedproduct(value: Product) {
        // discountBasedproduct(value: Product,userqty?) {
        try {
            this.dataProduct = value;
            if (value) {
                let data = {};
                let user = JSON.parse(this.localstorage.getItem('userData'));
                let address = JSON.parse(this.localstorage.getItem('address'));
                let carttypes;
                if (this.localstorage.getItem('getCartType')) {
                    this.cartTypeList = JSON.parse(this.localstorage.getItem('getCartType'));
                }
                let changePincode = this.localstorage.getItem( 'Pincode');
                var commoncarttypedata = this.cartTypeList.filter(alitems => alitems.common_distributor_categories.includes(value['distributor_category']));
                var Distributercarttypedata = this.cartTypeList.filter(alitems => alitems.distributor_categories.includes(value['distributor_category']));
                let cart_type_name = "";
                if (commoncarttypedata.length > 0) {
                    carttypes = commoncarttypedata[1]["cart_type"]
                    if (this.cartTypeList.length > 0) {
                        this.selectcarttype = carttypes;
                        let currentUrl = this.router.url;
                        if (currentUrl.includes('product-details')) {
                            this.btndisabled = false;
                            this.btnshowConfrimed = true;
                            this.btnshowsubmit = false;
                            // if (value['part_status'] !== 'in_active' && !value['alternate_part']) {
                            //     this.cartTypeModal = this.modalService1.show(this.template, { class: 'modal-dialog-centered modal-md', backdrop: 'static' });
                            // }
                        }
                    }
                }
                else if (Distributercarttypedata.length > 0) {
                    carttypes = Distributercarttypedata[0]["cart_type"]
                }
                let billingaddress = JSON.parse(this.localstorage.getItem('billingAddress'));

                let searchData = {}
                if (user && address && billingaddress) {
                    // this.account_pk = + JSON.stringify(user.user_ids[0].id);
                    this.account_pk = + JSON.stringify(user.account_pk);
                    var addresstypecheck = address.address['default'];
                    if (addresstypecheck === true) {
                        this.shippingaddressid = "default";
                    }
                    else {
                        this.shippingaddressid = address.address['address_id'];
                    }
                    var Billingaddresscheck = billingaddress.address['default'];
                    if (Billingaddresscheck === true) {
                        this.billingaddressid = "default";
                    }
                    else {
                        this.billingaddressid = billingaddress.address['address_id'];
                    }
                    searchData = {
                        search_text: "",
                        bs_model_name: "",
                        pg_line: "",
                        multi_pg_line: [],
                        multi_bs_model_name: [
                        ],
                        multi_dispgroup_name: [],
                        model_cat: "",
                        mod_uid: "",
                        category_type: "",
                        bom_id: "",
                        durafit_parts: false,
                        kit_parts: false,
                        part_number_last_four: "",
                        offset: 0,
                        limit: 20,
                        pl_id: "",
                        // is_discount_needed: true,
                        account_pk: this.account_pk,
                        // address_pk: this.address_pk,
                        shipping_address_id: this.shippingaddressid,
                        part_number: value.part_number,
                        is_for_epc_data: false,
                        is_for_discount: true,
                        is_for_ecom_detail: true,
                        // quantity : userqty,
                        cart_type: carttypes,
                        is_for_inventory:this.userData?.user_type == 'MECH' ? true: false,
                    }
                }
                else {
                    searchData = {
                        search_text: "",
                        bs_model_name: "",
                        pg_line: "",
                        multi_pg_line: [],
                        multi_bs_model_name: [
                        ],
                        multi_dispgroup_name: [],
                        model_cat: "",
                        mod_uid: "",
                        category_type: "",
                        bom_id: "",
                        durafit_parts: false,
                        kit_parts: false,
                        part_number_last_four: "",
                        offset: 0,
                        // start_row:0,
                        limit: 20,
                        pl_id: "",
                        part_number: value.part_number,
                        is_for_epc_data: false,
                        is_for_discount:changePincode?true:false,
                        is_pincode_based_division:changePincode?true:false,
                        pincode:changePincode?changePincode:"",
                        is_for_ecom_detail: true,
                        // quantity : userqty,
                        cart_type: carttypes,
                        is_for_inventory:this.userData?.user_type == 'MECH' ? true: false,

                    }
                }
                if (user || changePincode) {
                    this.partnumberbaseddiscount(searchData, value);
                }
                else {
                    if (!this.isLoggedIn) {
                        this.baseProductdata = value;
                        if (!value?.total_amount) {
                            this.baseProductdiscount = value?.total_amount;
                            if (value?.resource_type == 'Pack' ) {
                                this.ProductMRP = value?.crm_mrp * value?.moq;
                            }
                            else {
                                this.ProductMRP = value?.crm_mrp;
                            }
                        }
                        else if (value?.total_amount) {
                            this.baseProductdiscount = value?.total_amount;
                            if ((value?.base_discount_per + value?.scheme_discount_per + value?.rule_discount_per + value?.distributor2_discount_per  ) == 0) {
                                if (value?.resource_type == 'Pack') {
                                    this.ProductMRP = value?.crm_mrp * value?.moq;
                                    this.Productdiscount = 0;
                                }
                                else {
                                    this.ProductMRP = value?.crm_mrp;
                                    this.Productdiscount = 0;
                                }
                            }
                            else if ((value?.base_discount_per + value?.scheme_discount_per + value?.rule_discount_per + value?.distributor2_discount_per ) > 0) {
                                if (value?.resource_type == 'Pack' ) {
                                    this.Productdiscount = value?.total_amount * value?.moq;
                                    this.ProductMRP = value?.crm_mrp * value?.moq;
                                }
                                else {
                                    this.Productdiscount = value?.total_amount;
                                    this.ProductMRP = value?.crm_mrp;
                                    //  * response?.data[0]?.total_quantity;
                                }
                            }
                        }
                    }
                }
                // if (user) {
                //     if (address['type'] === 'default' && address['type'] !== null) {
                //         data = {
                //             cart_type: carttypes,
                //             account_pk: JSON.stringify(user.account_pk),
                //             address_pk: address.type,// 'default',//address.type,
                //             part_number: value.part_number,
                //             quantity: 1,

                //         }
                //     }
                //     else {
                //         var address_pkselect
                //         if (address.address.length === undefined || address.address.length === 0) {
                //             address_pkselect = address.address.address_id;
                //         }
                //         else {
                //             address_pkselect = address.address[0].address_id;

                //         }
                //         data = {
                //             cart_type: carttypes,
                //             account_pk: JSON.stringify(user.account_pk),
                //             address_pk: address_pkselect,// 'default',//address.type,
                //             part_number: value.part_number,
                //             quantity: 1,
                //         }
                //     }
                //     this.partnumberbaseddiscount(data, value);
                // }
                // if (value['moq']) {
                //     var quan = (1 * parseInt(value['moq']))
                //     this.quantity = new FormControl(quan);
                //     this.Productdiscount = (value['total_amount'] * parseInt(value['moq']));
                //     this.ProductMRP = (value['crm_mrp'] * parseInt(value['moq']));
                // }
                // else {
                //     this.Productdiscount = (value['total_amount'] * 1);
                //     this.ProductMRP = (value['crm_mrp'] * 1);
                // }
            }
            this.images = value ? (this.dataProduct as any).multi_img.map((url, index) => {
                return {
                    id: index.toString(),
                    url: url.img,
                    active: index === 0
                };
            }) : [];
        }
        catch (error) {
            this.userService.InsertExceptionLog({ "method_name": "@Input() set product(value: Product)", "page_name": "AlternatePartComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
        }
    }

    showchildpart(products) {
        this.sendchildproduct = products;
        try {
            //  this.calldatatable(products);
            this.childPartData = []
            this.btnshowChildParts = false;
            //this.result = false;
            let searchData = {}
            searchData = {
                part_number: products.part_number,
                //offset: page * 10,
                "offset": 0,
                size: 500,
            }
            //this.result = false;
            //if (!this.chkService) {
            //this.chkService = true;
            this.showLoader = true;
            this.productService.getChildPartList(searchData).subscribe(response => {
                //this.result = true;
                this.totalrecord = 0;
                //this.chkService = false;
                if (response.success) {
                    this.btnshowChildParts = true;
                    // this.currentPage = 1;
                    // this.childPartData = response.data;
                    // this.totalrecord = response.data.total_count;

                } else {
                    this.btnshowChildParts = false;
                    // this.childPartData = []
                }
                this.showLoader = false;
            }, (error) => {
                this.childPartData = [];
                this.showLoader = false;
                //this.result = true;
            })
            //}
        }
        catch (error) {
            this.userService.InsertExceptionLog({ "method_name": "showchildpart(products)", "page_name": "AlternatePartComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
        }
    }

    productslide() {
        $('.slider-thumb').not('.slick-initialized').slick({
            autoplay: false,
            vertical: true,
            infinite: false,
            verticalSwiping: true,
            slidesPerRow: 9,
            slidesToShow: 9,
            // asNavFor: '.slider-preview',
            focusOnSelect: false,
            prevArrow: '<button type="button" class="slick-prev"><img alt="" src="./assets/images/arrow-right.svg"/> </button>',
            nextArrow: '<button type="button" class="slick-next"><img alt="" src="./assets/images/arrow-left.svg"/></button>',
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        vertical: false,
                    }
                },
                {
                    breakpoint: 479,
                    settings: {
                        vertical: false,
                        slidesPerRow: 3,
                        slidesToShow: 3,
                    }
                },
            ]
        });
        // $('.slider-preview').not('.slick-initialized').slick({
        //     autoplay: false,
        //     vertical: true,
        //     infinite: true,
        //     slidesPerRow: 1,
        //     slidesToShow: 1,
        //     asNavFor: '.slider-thumb',
        //     arrows: false,
        //     draggable: false,
        //     responsive: [
        //         {
        //             breakpoint: 767,
        //             settings: {
        //                 vertical: false,
        //                 fade: true,
        //             }
        //         },
        //     ]
        // });
        $('.slider-preview, .slider-thumb').slick('setPosition');
    }

    async partnumberbaseddiscount(json, isAddressChanged) {
        try {
            this.showLoader = true;
            // this.productService.getpartnumberbaseddiscount(json).subscribe(response => {
            await this.productService.get_part_search_md(json).subscribe(response => {
                if (response.success === true) {
                    this.baseProductdata = response;
                    this.baseProductdata.division_info = {
                        ORG_NAME:response.data[0].organization_name?response.data[0].organization_name:"",
                        DIV_NAME:response.data[0].division_name?response.data[0].division_name:"",
                        PRIMISE_ADDRLINE1_s:response.data[0].primise_addrline1?response.data[0].primise_addrline1:"",
                        PRIMISE_ADDRESS2_s:response.data[0].primise_addrline2?response.data[0].primise_addrline2:"",
                        DIV_ID:response.data[0].division_id?response.data[0].division_id:"",
                        GSTIN:response.data[0].gstn?response.data[0].gstn:"",
                        ORG_Id:response.data[0].organization_id?response.data[0].organization_id:"",
                    };
                    if(!this.isLoggedIn){
                        this.displayDeliveryTimeline('',response.data[0].division_id);
                    }
                    this.productNewValues = response.data[0];
                    var productPirce = parseFloat(response.data[0]['crm_mrp'].toString()).toFixed(3);
                    var basediscountPirce = parseFloat(response?.data[0]?.total_amount).toFixed(3);
                    if (!response?.data[0]?.total_amount) {
                        this.baseProductdiscount = response?.data[0]?.total_amount;
                        if ( response?.data[0]?.resource_type == 'Pack') {
                            this.ProductMRP = response?.data[0]?.crm_mrp * response?.data[0]?.moq;
                        }
                        else {
                            this.ProductMRP = response?.data[0]?.crm_mrp;
                        }
                        this.isNormalDiscount = false;
                    }
                    else if (response?.data[0]?.total_amount) {
                        this.baseProductdiscount = response?.data[0]?.total_amount;
                        if ((response?.data[0]?.base_discount_per + response?.data[0]?.scheme_discount_per + response?.data[0]?.rule_discount_per + response?.data[0]?.distributor2_discount_per) == 0) {
                            if (response?.data[0]?.resource_type == 'Pack') {
                                this.ProductMRP = response?.data[0]?.crm_mrp * response?.data[0]?.moq;
                                this.Productdiscount = response?.data[0]?.total_amount * response?.data[0]?.moq;
                            }
                            else {
                                this.ProductMRP = response?.data[0]?.crm_mrp;
                                this.Productdiscount = response?.data[0]?.total_amount;
                            }
                            this.isNormalDiscount = false;
                        }
                        else if ((response?.data[0]?.base_discount_per + response?.data[0]?.scheme_discount_per + response?.data[0]?.rule_discount_per + response?.data[0]?.distributor2_discount_per) > 0) {
                            if (response?.data[0]?.resource_type == 'Pack') {
                                this.Productdiscount = response?.data[0]?.total_amount * response?.data[0]?.moq;
                                this.ProductMRP = response?.data[0]?.crm_mrp * response?.data[0]?.moq;
                            }
                            else {
                                this.Productdiscount = response?.data[0]?.total_amount;
                                this.ProductMRP = response?.data[0]?.crm_mrp;
                                //  * response?.data[0]?.total_quantity;
                            }
                            this.isNormalDiscount = true;
                        }
                    }
                    if (this.Productdiscount > 0 && this.Productdiscount < this.ProductMRP) {
                        this.savedAmount = this.ProductMRP - this.Productdiscount;
                    }
                    if ((response?.data[0]?.base_discount_per + response?.data[0]?.scheme_discount_per +
                        response?.data[0]?.tml_discount_per + response?.data[0]?.distributor_discount_per +
                        response?.data[0]?.rule_discount_per + response?.data[0]?.distributor2_discount_per) > 0) {
                        this.normalPercentage = parseFloat((((response?.data[0].crm_mrp - response?.data[0].total_amount) / response?.data[0].crm_mrp) * 100)?.toFixed(1));
                    }
                    if (this.isLoggedIn && response) {
                        this.partNumber = this.partNumber ? this.partNumber : response?.data[0]?.part_number;
                        this.getOfferDetails(this.partNumber, this.baseProductdata?.division_info?.DIV_ID, isAddressChanged);
                        this.displayDeliveryTimeline(null,this.baseProductdata?.division_info?.DIV_ID)
                        this.getPartLimitCheck(this.partNumber, this.baseProductdata?.division_info?.ORG_Id)
                    }
                    // if (productPirce === basediscountPirce) {
                    //     this.baseProductdiscount = null;
                    // }
                    // else {
                    //     this.baseProductdiscount = response.data[0].total_amount;
                    //     if (this.product?.moq) {
                    //         this.Productdiscount = (this.baseProductdiscount * this.product?.moq)
                    //         this.ProductMRP = (this.product?.crm_mrp * this.product?.moq);
                    //     }
                    //     else {
                    //         this.Productdiscount = this.baseProductdiscount
                    //         this.ProductMRP = this.product?.crm_mrp
                    //     }
                    // }
                    this.showLoader = false;
                }
                else {
                    this.showLoader = false;
                }
            }, (err) => {
                this.showLoader = false;
            })
        }
        catch (error) {
            this.userService.InsertExceptionLog({ "method_name": "partnumberbaseddiscount()", "page_name": "AlternatePartComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
        }
    }

    //check if qty based discount available for product
    getOfferDetails(partNumber, division_id, isAddressChanged) {
        let user = JSON.parse(this.localstorage.getItem('userData'));
        let requestBody = {
            account_pk: user.account_pk,
            part_number: partNumber,
            division_id: division_id
        }
        this.userService.getPartDiscounts(requestBody).subscribe(response => {
            if (response.success) {
                let discountsArray: any[] = response.data;
                this.discountOfferData = response.data;
                this.discountSlabOffer = discountsArray.filter(discount => discount.discount_type === "quantity_discount");
                if(this.discountSlabOffer.length > 0){
                    this.showQuantityDiscountMessage = true
                    this.isshowMoreSlabDiscount = false
                }
                this.discountOffers = this.discountSlabOffer[0];
                if (this.discountOffers) {
                    if (parseInt(this.productList[0].customerQuantity) >= parseInt((this.productList[0]['resource_type'] == 'Pack') ? this.discountOffers.quantity /  this.productList[0]['moq'] : this.discountOffers.quantity)) {
                        this.isQuantityDiscountAvailable = true;
                    }
                    this.getDiscountedPriceDetails(partNumber, this.discountOffers.quantity);
                }
                else {
                    this.isQuantityDiscountAvailable = false;
                }
            }
            else {
                this.discountOffers = null;
                this.showQuantityDiscountMessage = false;
            }
            if (isAddressChanged === true) {
                this.isQuantityDiscountAvailable = false;
                this.showQuantityDiscountMessage = false;
                this.refreshDiscountDetails(this.productList[0]);
            }
        },
            error => {
                //this.toasterService.error(error?.error?.data?.msg);
                this.discountOffers = null;
                this.showQuantityDiscountMessage = false;
            })
    }

    getDiscountedPriceDetails(partNumber, discountQuantity) {
        let user = JSON.parse(this.localstorage.getItem('userData'));
        let address = JSON.parse(this.localstorage.getItem('address'));
        let requestBody = {
            "search_text": "",
            "bs_model_name": "",
            "pg_line": "",
            "multi_pg_line": [],
            "multi_bs_model_name": [],
            "multi_dispgroup_name": [],
            "model_cat": "",
            "mod_uid": "",
            "category_type": "",
            "bom_id": "",
            "durafit_parts": false,
            "kit_parts": false,
            "part_number_last_four": "",
            "offset": 0,
            "size": 20,
            "pl_id": "",
            "account_pk": user.account_pk,
            "shipping_address_id": address.address['isDefault'] ? "default" : address.address['address_id'],
            "is_for_epc_data": false,
            "is_for_discount": true,
            "is_for_ecom_detail": true,
            "quantity": parseInt(discountQuantity),
            "part_number": partNumber,
            is_for_inventory:this.userData?.user_type == 'MECH' ? true: false,
        }
        this.showLoader = true
        this.productService.get_part_search_md(requestBody).subscribe(response => {
            if (response.success) {
                this.showLoader = false
                this.quantityBasedPartDetails = response?.data[0];
                this.quantityBasedDivisionDetails = {
                    ORG_NAME:response.data[0].organization_name?response.data[0].organization_name:"",
                    DIV_NAME:response.data[0].division_name?response.data[0].division_name:"",
                    PRIMISE_ADDRLINE1_s:response.data[0].primise_addrline1?response.data[0].primise_addrline1:"",
                    PRIMISE_ADDRESS2_s:response.data[0].primise_addrline2?response.data[0].primise_addrline2:"",
                    DIV_ID:response.data[0].division_id?response.data[0].division_id:"",
                    GSTIN:response.data[0].gstn?response.data[0].gstn:""
                };
                this.showQuantityDiscountMessage = true;
                if ( response?.data[0]?.resource_type == 'Pack') {
                    let tmgoQuantity = parseInt(discountQuantity) / response?.data[0]?.moq;
                    this.quantityBasedPartPrice = response.data[0]?.total_amount / tmgoQuantity;
                    let tmgoMRP = this.quantityBasedPartDetails.crm_mrp * response?.data[0]?.moq;
                    this.quantityBasedPercentage = parseFloat((((tmgoMRP - this.quantityBasedPartPrice) / tmgoMRP) * 100)?.toFixed(1));
                    this.ProductMRP = response?.data[0]?.crm_mrp * response?.data[0]?.moq;
                    this.savedAmount = this.ProductMRP - this.quantityBasedPartPrice;
                }
                else {
                    this.quantityBasedPartPrice = response.data[0]?.total_amount / parseInt(discountQuantity);
                    this.quantityBasedPercentage = parseFloat((((this.quantityBasedPartDetails.crm_mrp - this.quantityBasedPartPrice) / this.quantityBasedPartDetails.crm_mrp) * 100)?.toFixed(1));
                    this.ProductMRP = response?.data[0]?.crm_mrp;
                    this.savedAmount = this.ProductMRP - this.quantityBasedPartPrice;
                }

            }
            else {
                this.showLoader = false
                this.quantityBasedPartPrice = 0;
                this.showQuantityDiscountMessage = false;
            }
        },
            error => {
                this.showLoader = false
            })
    }

    getAddressDetails(check) {
        try {
            let userData = JSON.parse(this.localstorage.getItem('userData'));
            let usersData = JSON.parse(this.localstorage.getItem('user'));
            if (usersData) {
                this.usertype = userData.user_type;
                let user = {
                    "account_pk": parseInt(JSON.stringify(userData.account_pk)),
                    "offset": 10, "limit": 10
                };
                this.showLoader = true;
                this.productService.getAddressList(user).subscribe(response => {
                    if (response.success == true) {
                        this.showLoader = false;
                        this.addresses = response.data;
                        this.addresses.forEach(address => {
                            if (address.default == true) {
                                this.default_address = address;
                            }
                            else {
                                this.new_addresses.push(address);
                            }
                        });
                    }
                    else {
                        this.showLoader = false;
                    }
                }, error => {
                    this.showLoader = false;
                })
            }
        }
        catch (error) {
            this.userService.InsertExceptionLog({ "method_name": "getAddress(check)", "page_name": "ProductDetailsComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
        }
    }

    refreshDiscountDetails(product) {
        if (this.discountOffers) {
            if (product.customerQuantity >= parseInt(this.discountOffers.quantity)) {
                this.isQuantityDiscountAvailable = true;
                this.savedAmount = this.ProductMRP - this.quantityBasedPartPrice;
            }
            else {
                this.isQuantityDiscountAvailable = false;
                this.savedAmount = this.ProductMRP - this.Productdiscount;
            }
        }

        if(this.discountSlabOffer.length > 1){
            if(product.customerQuantity >=  (product.resource_type == 'Pack' ?this.discountOffers?.quantity / product.moq:this.discountOffers?.quantity)){
                this.isshowMoreSlabDiscount = true
            }
            else{
                this.isshowMoreSlabDiscount = false
            }
        }
      
        if (this.isLoggedIn && JSON.parse(this.localstorage.getItem("Pincode"))){
                this.getDiscountedPriceDetails(product?.part_number, product.resource_type == 'Pack' ?product.customerQuantity * product.moq:product.customerQuantity)
        }
    }

    readQuantity(event, product, isDiscountApplicable) {
        product.customerQuantity = event;
        this.currentQuantity = event;
        if (this.discountOffers && isDiscountApplicable) {
            if ( product.customerQuantity >= ((product.resource_type == 'Pack') ? (this.discountOffers.quantity / product.moq) : this.discountOffers.quantity)) {
                this.isQuantityDiscountAvailable = true;
                this.savedAmount = this.ProductMRP - this.quantityBasedPartPrice;
            }
            else {
                this.isQuantityDiscountAvailable = false;
                this.savedAmount = this.ProductMRP - this.Productdiscount;
            }
        }
        if(this.discountSlabOffer.length > 1){
            if(event >= this.discountOffers?.quantity / product.moq){
                this.isshowMoreSlabDiscount = true
            }
            else{
                this.isshowMoreSlabDiscount = false
            }
        }
        this.displayDeliveryTimeline(product);
        if ( product.customerQuantity >= ((product.resource_type == 'Pack' ) ? (this.discountOffers.quantity / product.moq) : this.discountOffers.quantity)) {
        this.getDiscountedPriceDetails(product?.part_number,  (product['resource_type'] == 'Pack') ? this.currentQuantity * product['moq'] : this.currentQuantity)
        }
        
        // if(product?.customerQuantity >= this.discountOffers?.quantity){
        //     this.route.queryParams.subscribe(params => {
        //         this.partNumber = params['part_Number'] 
        //     this.loadData(this.partNumber,
        //         product.customerQuantity)
        //     });
        // }
    }

    addToCart(product: Product): void {
        try {
            let permission = this.userService.CheckPermission(
                'btn_cart'
            );
            if (permission) {
                if (isNaN(product['customerQuantity']) || product['customerQuantity'] === 0) {
                    this.toasterService.error("Quantity cannot be empty or 0");
                    return;
                }
                if ((product['resource_type'] == 'Pack' )) {
                    // product['customerQuantity'] = parseInt(product['min_quantity']) ? parseInt(product['min_quantity']) * parseInt(product['column_4']) : 1 * parseInt(product['column_4'])
                    product['customerQuantity'] =this.isLoggedIn ? parseInt(product['customerQuantity']) ? parseInt(product['customerQuantity']) : 1: parseInt(product['customerQuantity']) ? product['customerQuantity'] * parseInt(product['moq']) : 1 * parseInt(product['moq'])
                }
                else {
                    // product['customerQuantity'] = parseInt(product['min_quantity']) ? parseInt(product['min_quantity']) : 1;
                    product['customerQuantity'] = parseInt(product['customerQuantity']) ? parseInt(product['customerQuantity']) : 1;
                }
                if (product['customerQuantity'] != null || product['customerQuantity'] != 0 || product['customerQuantity'] != undefined) {
                    if (!this.addingToCart && product && product['customerQuantity']) {
                        this.addingToCart = true;
                        if (product['customerQuantity'] < product['min_quantity']) {
                            this.toasterService.error('Quantity field cannot be less than minimum quantity.');
                            this.addingToCart = false;
                            return;
                        }
                        this.addingToCart = true;
                        if (this.localstorage.getItem('getCartType')) {
                            this.cartTypeList = JSON.parse(this.localstorage.getItem('getCartType'));
                        }
                        var commoncarttypedata = this.cartTypeList.filter(alitems => alitems.common_distributor_categories.includes(product['distributor_category']));
                        var Distributercarttypedata = this.cartTypeList.filter(alitems => alitems.distributor_categories.includes(product['distributor_category']));
                        let cart_type_name = "";
                        if (commoncarttypedata.length > 0) {
                            if (this.localstorage.getItem('selectcarttype')) {
                                cart_type_name = this.localstorage.getItem('selectcarttype');
                                this.showLoader = true;
                                this.cart.add(product, product['customerQuantity'], product['distributor_category'], cart_type_name
                                    // ,false
                                ).subscribe({
                                    complete: () => {
                                        product['customerQuantity'] = product['min_quantity'];
                                        this.addingToCart = false;
                                        this.showLoader = false;
                                        this.localstorage.removeitems('selectcarttype');
                                    }
                                });
                                localStorage.removeItem('selectcarttype')
                            }
                            else {
                                this.currentlyAddingProduct = product;
                                cart_type_name = commoncarttypedata[0]["cart_type"]
                                if (this.cartTypeList.length > 0) {
                                    this.selectcarttype = this.cartTypeList[1]["cart_type"]
                                    this.changeCartType(this.selectcarttype, product);
                                    this.selectedProduct = product;
                                    this.btndisabled = false;
                                    this.addingToCart = false
                                    this.btnshowConfrimed = false;
                                    this.btnshowsubmit = true
                                    this.cartTypeModal = this.modalService1.show(this.template, { class: 'modal-dialog-centered modal-md', backdrop: 'static' });
                                }
                            }
                        }
                        else if (Distributercarttypedata.length > 0) {
                            cart_type_name = Distributercarttypedata[0]["cart_type"]
                            this.showLoader = true;
                            this.cart.add(product, product['customerQuantity'], product['distributor_category'], cart_type_name
                                // ,false
                            ).subscribe({
                                complete: () => {
                                    product['customerQuantity'] = product['min_quantity'];
                                    this.addingToCart = false;
                                    this.showLoader = false;
                                }
                            });
                        }
                    }
                    else {
                        if (product['resource_type'] == 'Pack' ) {
                            if (this.unit.value < product['min_quantity']) {
                                this.toasterService.error(
                                    'Unit field should not be empty.'
                                );
                                this.addingToCart = false;
                                return;
                            }
                        }
                        else if (product['customerQuantity'] == 0) {
                            this.toasterService.error('Quantity field should not be 0.');
                        }
                        else { }
                    }
                }
                else {
                    if (this.product['resource_type'] == 'Pack' ) {
                        if (this.unit.value < this.product['min_quantity']) {
                            this.toasterService.error('Unit field should not be empty.');
                            this.addingToCart = false;
                            return
                        }
                    }
                    else {
                        this.toasterService.error('Quantity field should not be empty.');
                    }
                }
            }
            else {

                this.toasterService.error(blockPermissionMsg);
            }
        }
        catch (error) {
            this.addingToCart = false;
            this.userService.InsertExceptionLog({ "method_name": "addToCart()", "page_name": "AlternatePartComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
        }
    }

    changeCartType(event, product: Product) {
        try {
            let data = {};
            let user = JSON.parse(this.localstorage.getItem('userData'));
            let address = JSON.parse(this.localstorage.getItem('address'));
            let carttypes = event.cart_type?event.cart_type:event;
            let billingaddress = JSON.parse(this.localstorage.getItem('billingAddress'));
            let changePincode = this.localstorage.getItem( 'Pincode');
            this.selectedCartType = event.cart_type;
            let searchData = {}
            this.showLoader = true;
            if (user && address && billingaddress) {
                // this.account_pk = + JSON.stringify(user.user_ids[0].id);
                this.account_pk = + JSON.stringify(user.account_pk);
                var addresstypecheck = address.address['default'];
                if (addresstypecheck === true) {
                    this.shippingaddressid = "default";
                }
                else {
                    this.shippingaddressid = address.address['address_id'];
                }
                var Billingaddresscheck = billingaddress.address['default'];
                if (Billingaddresscheck === true) {
                    this.billingaddressid = "default";
                }
                else {
                    this.billingaddressid = billingaddress.address['address_id'];
                }
                searchData = {
                    search_text: "",
                    bs_model_name: "",
                    pg_line: "",
                    multi_pg_line: [],
                    multi_bs_model_name: [
                    ],
                    multi_dispgroup_name: [],
                    model_cat: "",
                    mod_uid: "",
                    category_type: "",
                    bom_id: "",
                    durafit_parts: false,
                    kit_parts: false,
                    part_number_last_four: "",
                    offset: 0,
                    limit: 20,
                    pl_id: "",
                    // is_discount_needed: true,
                    account_pk: this.account_pk,
                    // address_pk: this.address_pk,
                    shipping_address_id: this.shippingaddressid,
                    is_pincode_based_division:address.address.postal_code != changePincode?true:false,
                    pincode:address.address.postal_code != changePincode?changePincode:"",
                    part_number: this.partNumber,
                    is_for_epc_data: false,
                    is_for_discount: true,
                    is_for_ecom_detail: true,
                    cart_type: carttypes,
                    is_for_inventory:this.userData?.user_type == 'MECH' ? true: false,
                }
            }
            else {
                searchData = {
                    search_text: "",
                    bs_model_name: "",
                    pg_line: "",
                    multi_pg_line: [],
                    multi_bs_model_name: [
                    ],
                    multi_dispgroup_name: [],
                    model_cat: "",
                    mod_uid: "",
                    category_type: "",
                    bom_id: "",
                    durafit_parts: false,
                    kit_parts: false,
                    part_number_last_four: "",
                    offset: 0,
                    // start_row:0,
                    limit: 20,
                    pl_id: "",
                    is_pincode_based_division:changePincode?true:false,
                    pincode:changePincode?changePincode:"",
                    part_number: this.partNumber,
                    is_for_epc_data: false,
                    is_for_discount: changePincode?true:false,
                    is_for_ecom_detail: true,
                    cart_type: carttypes,
                    is_for_inventory:this.userData?.user_type == 'MECH' ? true: false,

                }
            }
            this.partnumberbaseddiscount(searchData, product);
        }
        catch (error) {
            this.userService.InsertExceptionLog({ "method_name": "changeCartType()", "page_name": "AlternatePartComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
        }
    }

    displayDeliveryTimeline(product?,divID?) {
        try{
            this.etaList=[];
            if(JSON.parse(this.localstorage.getItem("Pincode")) && this.productList[0].part_number ){
                let user = JSON.parse(this.localstorage.getItem('userData'));
                let requestBody = {
                    account_pk: user?JSON.stringify(user.account_pk):"",
                    "pincode": JSON.parse(this.localstorage.getItem("Pincode")),
                    "part_number": this.productList[0].part_number,
                    "quantity": product?product.customerQuantity:this.productList[0].customerQuantity?this.productList[0].customerQuantity:1,
                    "division_id":divID?divID:this.divisionInfoData.DIV_ID,
                    "action_type":"get_eta_for_part_detail"
                }
    
    
                this.userService.getEtaForOrderSuccessPage(requestBody).
                subscribe(response => {
                    if(response.success){
                        this.etaList = response.data;
                        const dateParts = this.etaList[0].ETA.split('-');
                        const year = parseInt(dateParts[2]);
                        const month = parseInt(dateParts[1]) - 1; // month is zero-indexed
                        const day = parseInt(dateParts[0]);
                        this.etaList[0].ETA = new Date(year, month, day);
                    }else{
                        this.etaList = [];
                    }

                })
            }else{
                this.toasterService.error("Please add a pincode to get Expected Delivery Date.");
            }
            this.getSchemeList();
        }
        catch(error){       
            this.getSchemeList();   
            this.userService.InsertExceptionLog({ "method_name": "goToLocationNearbyMe()", "page_name": "ProductdetailsComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
        }
        
    }

    ViewChildPart() {
        if (this.childPartsModal) {
            this.childPartsModal.hide();
        }
        this.childPartsModal = this.modalService1.show(this.childParts, { class: "modal-dialog-centered modal-lg", backdrop: "static" });
    }

    viewAddressModal() {
        this.isProductDetails = true;
        this.addressModal = this.modalService1.show(this.template1, { class: 'modal-dialog-centered modal-lg address', backdrop: 'static' });
    }

    closeModal(){
        this.closemodal.emit();
    }

    closeAdressModal(){
        this.addressModal.hide();
    }
    // common alternate part 
    showingQuickview = false;
    selectedCartType:any;
    setAddCart(event) {
        try {
            let permission = this.userService.CheckPermission(
                'btn_cart'
            );
            if (permission) {
                this.btndisabled = true;
                var cart_type_name = this.localstorage.getItem('selectcarttype');
                this.cart.add(this.selectedProduct, this.selectedProduct['customerQuantity'], this.selectedProduct['distributor_category'], cart_type_name
                    // ,false
                ).subscribe({
                    complete: () => {
                        this.selectedProduct.customerQuantity = this.selectedProduct.min_quantity;
                        this.addingToCart = false
                    }
                });
                localStorage.removeItem('selectcarttype');
                this.selectcarttype = "";
                this.cartTypeModal.hide();
                this.btndisabled = false;
            }
            else {

                this.toasterService.error(blockPermissionMsg);
            }
        }
        catch (error) {
            this.userService.InsertExceptionLog({ "method_name": "setAddCart()", "page_name": "ProductComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
        }
    }
    ShowPart() {
        this.btndisabled = true;
        this.cartTypeModal.hide();
        this.showQuickview();
    }
    showQuickview(): void {
        if (this.showingQuickview) {
            return;
        }
        this.showingQuickview = true;
        this.showLoader = false;
        this.quickview.show(this.product).subscribe({
            complete: () => {
                this.showLoader = false;
                this.showingQuickview = false;
                this.cd.markForCheck();
            }
        });
    }
    closePart() {
        if (this.btnshowConfrimed) {
            localStorage.removeItem("selectcarttype");
            this.showQuickview();
        }
        this.selectcarttype = "";
        this.cartTypeModal.hide();
    }

    openSchemeListModal(type:any){
        if(type == 'otherOffer'){
            if(this.validSchemeList.length > 0 || this.discountOfferData.length > 0){
                this.popupOpenType = "otherOffer"
                this.schemeListModal = this.modalService1.show(this.schemeList, 
                    { class: "modal-dialog-centered modal-lg", backdrop: "static" });
            }else{
                this.toasterService.error("No schemes Found");
            }
        }
        else if(type == 'slabOffer'){
            if(this.discountSlabOffer.length > 1){
                this.popupOpenType = "slabOffer"
                this.schemeListModal = this.modalService1.show(this.schemeList, 
                    { class: "modal-dialog-centered modal-lg", backdrop: "static" });
            }else{
                this.toasterService.error("No schemes Found");
            }  
        }
       
    }

    getSchemeList(){
        try{
            this.validSchemeList=[];
            let user = JSON.parse(this.localstorage.getItem('userData'));
            let req ={
                "action_type": "get_part_detail",
                "account_pk": user.account_pk,
                "part_number": this.partNumber?this.partNumber:this.productList[0].part_number,
                "division_id": this.baseProductdata?this.baseProductdata.division_info.DIV_ID:"",
                "organization_id":this.baseProductdata?this.baseProductdata.division_info.ORG_ID:"",
            }
            this.productService.getPartSpecificScheme(req).subscribe(
                res => {
                    if(res.success){
                        this.validSchemeList = res.data;
                    }
                })
        }catch (error) {
            this.userService.InsertExceptionLog({ 
                "method_name": "alternateProduct()", 
                "page_name": "ProductDetailsComponent", 
                "portal_type": "Customer", "page_url": this.router.url, 
                "error_message": error.message, "inner_exception": error 
            });
        }
    }
    getPartLimitCheck(partnumber,div_id){
        let user = JSON.parse(this.localstorage.getItem('userData'));
        var req ={
                "part_number_list" : [partnumber],
                "account_id" : user.account_id,
                "distributor_id" : div_id
        }
    
        this.productService.get_part_limit_check(req).subscribe(
            res => {
                   this.partLimitCheck = res
                if(res.success == false || res.success != undefined){
                    this.partLimitCheck = []
                }
            })
    }catch (error) {
        this.userService.InsertExceptionLog({ 
            "method_name": "getPartLimitCheck()", 
            "page_name": "ProductDetailsComponent", 
            "portal_type": "Customer", "page_url": this.router.url, 
            "error_message": error.message, "inner_exception": error 
        });
    }

    convertToInt(value:any){
        return parseInt(value)
    }
    copyMessage() {
        this.toasterService.success("Link copied.");
        navigator.clipboard.writeText(this.pageURL)
      }

      share(platform: string) {
        let shareUrl = '';
        switch (platform) {
          case 'Facebook':
            shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.pageURL)}`;
            break;
          case 'Twitter':
            shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(this.pageURL)}`;
            break;
        //   case 'linkedin':
        //     shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(this.url)}`;
        //     break;
        //   case 'pinterest':
        //     shareUrl = `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(this.url)}&media=${encodeURIComponent(this.imageUrl)}&description=${encodeURIComponent(this.text)}`;
        //     break;
          case 'whatsapp':
            shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(this.pageURL)}`;
            break;
            case 'mail':
                shareUrl = `mailto:?body=${encodeURIComponent(this.pageURL)}`;
                    break;
          // Add more platforms as needed
        }
    
        window.open(shareUrl, '_blank');
      }
    imageTempArray:any=[]
    selectImg(path:any,index:any,type:any){
      if(type == 'thumbnil'){
          this.imageTempArray = [];
          var imgPath = path + '/resize/359x204'
          setTimeout(() => {
              this.imageTempArray.push(imgPath)
          },0);
      }
    //   else{
    //       this.modalImage = path.replace('530x299', '800x452');
    //       this.fullimageModal = this.modalService1.show(this.fullImagePopup,
    //           { class: "modal-dialog-centered modal-lg", });   
    //   }
  
    }
}
