<div class="row  row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-4 g-4"
  *ngIf="layout == 'Grid' && dproduct">
  <div class="col" *ngFor="let item of userService.generateFake(8);let i = index;">
    <div class="card border-0">
      <div class="card-body p-3 pb-0">
        <div class="prod-img mx-auto text-center  position-relative">
          <skc-rectangle [loading]="loading" [skc-height]="'137.69px'" [skc-width]="'196.94px'"> </skc-rectangle>
        </div>
        <div class="prod-content position-relative">
          <h3 class="fw-bold prod-model mb-2 cursor">
            <skc-rectangle [loading]="loading" [skc-height]="'24px'" [skc-width]="'196.94px'"> </skc-rectangle>
          </h3>
          <h4 class="prod-name mb-2">
            <skc-rectangle [loading]="loading" [skc-height]="'20px'" [skc-width]="'196.94px'"> </skc-rectangle>
          </h4>
          <div class="delivery-time">
            <div class="w-50 d-inline-flex">
              <span class="fw-bold pb-2">
                <skc-rectangle [loading]="loading" [skc-height]="'16px'" [skc-width]="'196.94px'"> </skc-rectangle>
              </span>
            </div>
          </div>
          <div class="price fw-bold mb-2 text-nowrap">
            <skc-rectangle [loading]="loading" [skc-height]="'20px'" [skc-width]="'196.94px'"> </skc-rectangle>
          </div>
        </div>
      </div>
      <div class="card-footer bg-white d-flex justify-content-between">
        <skc-rectangle [loading]="loading" [skc-height]="'35px'" [skc-width]="'196.938px'"> </skc-rectangle>
      </div>
    </div>
  </div>
</div>


<!-- <div class="card border-0" *ngIf="layout == 'Grid' && dproduct">
  
  <div class="card-body p-3 pb-0">
    <div class="prod-img mx-auto text-center  position-relative">
      <skc-rectangle [loading]="loading" [skc-height]="'137.69px'" [skc-width]="'196.94px'"> </skc-rectangle>
    </div>
    <div class="prod-content position-relative">
      <h3 class="fw-bold prod-model mb-2 cursor">
        <skc-rectangle [loading]="loading" [skc-height]="'24px'" [skc-width]="'196.94px'"> </skc-rectangle>
      </h3>
      <h4 class="prod-name mb-2">
        <skc-rectangle [loading]="loading" [skc-height]="'20px'" [skc-width]="'196.94px'"> </skc-rectangle>
      </h4>
      <div class="delivery-time">
        <div class="w-50 d-inline-flex">
          <span class="fw-bold pb-2">
            <skc-rectangle [loading]="loading" [skc-height]="'16px'" [skc-width]="'196.94px'"> </skc-rectangle>
          </span>
        </div>
      </div>
      <div class="price fw-bold mb-2 text-nowrap">
        <skc-rectangle [loading]="loading" [skc-height]="'20px'" [skc-width]="'196.94px'"> </skc-rectangle>
      </div>
    </div>
  </div>
  <div class="card-footer bg-white d-flex justify-content-between">
    <skc-rectangle [loading]="loading" [skc-height]="'35px'" [skc-width]="'196.938px'"> </skc-rectangle>
  </div>

</div> -->
<div class="row  row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-4 g-4"
  *ngIf="layout == 'Grid' && productList?.length > 0;">
  <div class="col" *ngFor="let product of productList">
    <div class="card border-0">
      <!-- <div class="discount" *ngIf="((product?.base_discount + product?.scheme_discount +
            product?.tml_discount_per + product?.distributor_discount_per +
            product?.rule_discount) > 0) && isLoggedIn" [routerLink]="['/shop/product-details']"
            [queryParams]="{ part_Number : product.part_number }">
              <p class="mb-0">{{(product?.base_discount + product?.scheme_discount +
                product?.tml_discount_per + product?.distributor_discount_per +
                product?.rule_discount) + '%'}} </p> -->
                <ng-container *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
      <div class="discount" *ngIf="((convertToInteger(product?.base_discount_per) + convertToInteger(product?.scheme_discount_per) +
            convertToInteger(product?.tml_discount_per) + convertToInteger(product?.distributor_discount_per) +
            convertToInteger(product?.rule_discount_per) + convertToInteger(product?.distributor2_discount_amount)) > 0)"
        [routerLink]="['/shop/product-details']" [queryParams]="{ part_Number : product.part_number }">
        <!-- <p class="mb-0" *ngIf="product.moq">{{(((product.crm_mrp * product.moq)-(product.total_amount * product.moq)/150*100)) + '%'}} </p> -->
        <p class="mb-0">{{'SAVE ' + (((product.crm_mrp-product.total_amount)/product.crm_mrp)*100)?.toFixed(1) + '%'}}</p>
        <!-- <span>2days left</span> -->
      </div>
      </ng-container>
      <div class="card-body p-3 pb-0">
        <div [routerLink]="['/shop/product-details']" [queryParams]="{ part_Number : product.part_number }"
          [breadcrumbs]="breadcrumbs" class="prod-img mx-auto text-center  position-relative">
          <img [src]="product?.part_image_path + '/resize/223x150'" onerror="this.src='./assets/tata-vehicle.jfif';" class="img-fluid cursor"
            alt="prod-img-alt" srcset=""
            style="max-width: 100%; max-height: 100%; top: 50%; position: absolute; transform: translate(-50%, -50%);" />
        </div>
        <div class="prod-content position-relative">
          <!-- <div class="bookmarks-fav text-end p-0 position-absolute">
                  <a class="add-to-favorite"><i class="far fa-bookmark"></i></a>
                </div> -->
          <app-wishlist-icon [product]="product" [layout]="layout"></app-wishlist-icon>
          <div [routerLink]="['/shop/product-details']" [queryParams]="{ part_Number : product.part_number }"
            [breadcrumbs]="breadcrumbs">
            <h3 class="fw-bold prod-name mb-2"
              title="{{ (product.short_description == '' || product.short_description == null) ? '-' : product.short_description}}">{{
              (product.short_description == " " || product.short_description == null) ? "-" : (product.short_description.length > 28 ?(product.short_description.substr(0, 28) + '...') :product.short_description)}}</h3>
            <h4 class="prod-model mb-2 cursor">{{ product.part_number }}</h4>
            <!-- <label  title="{{product?.model ==' ' ?'-':  product?.model ==''?'-' : product?.model == null ?'-':product?.model}}" >
                  {{ product.model == " " ? "-" : product.model == "" ? '-'  : product.model == null ? '-' : product.model?.substr(0,38)  || '-'}}</label> -->
            <!-- <p class="fw-bold"><span class="fw-normal">Estd. Delivery:</span> Today</p> -->
            <!-- <p class="fw-bold" *ngIf="product?.hsn_code !== ''"><span class="fw-normal">HSN: </span>{{ product?.hsn_code }}
                </p>
                <p class="fw-bold" *ngIf="product?.tax_slab !== ''"><span class="fw-normal">GST: </span>{{ product?.tax_slab }}%
                </p> -->
            <div class="delivery-time">
              <div class="w-50 d-inline-flex">
                <ng-container *ngIf="product?.hsn_code && product?.hsn_code!=''">
                  <span class="fw-bold pb-2">HSN: </span>{{ product?.hsn_code }}
                </ng-container>
              </div>
              <div class="w-50 d-inline-flex">
                <ng-container *ngIf="product?.tax_slab && product?.tax_slab!=''">
                  <span class="fw-bold pb-2">GST: </span>{{ product?.tax_slab }}%
                </ng-container>
              </div>
            </div>
            <!-- <p *ngIf="product.column_4" class="tmgo mb-2">(MRP is applicable for {{currentQuantity}} Unit =
                  {{(product?.column_4 / 1000)*currentQuantity}} litres)</p> -->
                  <ng-container *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true); else elseTemplate">
            <ng-container *ngIf="!product.total_amount">
              <div class="price fw-bold mb-2">
                {{ ( ( product.resource_type == 'Pack' )?product.crm_mrp*product.moq:product.crm_mrp ) |currencyFormat:'INR' }}
              </div>
            </ng-container>
            <ng-container *ngIf="product.total_amount">
              <div *ngIf="(product.base_discount_per +  product.scheme_discount_per + product.rule_discount_per ) == 0.0">
                <div class="price fw-bold mb-2">
                  {{ ( ( product.resource_type == 'Pack' )?product.crm_mrp*product.moq:product.crm_mrp ) |currencyFormat:'INR' }}
                </div>
              </div>
              <div *ngIf="(product.base_discount_per +  product.scheme_discount_per + product.rule_discount_per ) > 0.0">
                <div class="price fw-bold text-nowrap">
                  {{   (( product.resource_type == 'Pack' ) ? product.total_amount*product.moq
                      : product.total_amount) |currencyFormat:'INR'
                  }}
                  <div class="card-actual-mrp">
                      <span class="m-0"> MRP
                          {{
                              (( product.resource_type == 'Pack') ? product.crm_mrp*product.moq
                              : product.crm_mrp) |currencyFormat:'INR' 
                          }}
                      </span>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-template #elseTemplate>
              <div class="price fw-bold">
              {{ (( product.resource_type == 'Pack' )?product.crm_mrp*product.moq:product.crm_mrp ) |currencyFormat:'INR' }}
              </div>
          </ng-template>
            <p *ngIf="product.resource_type == 'Pack' && product?.moq > 1" class="tmgo mb-2">
              1 Unit contains {{product.moq | number: '1.0-0'}} <span *ngIf="product.moq > 1">Quantities</span><span *ngIf="product.moq <= 1">Quantity</span>
            </p>
            <!-- <span class="error-message text-nowrap" *ngIf="product.part_status == 'in_active'">No Longer Serviced</span> -->
          </div>
        </div>
      </div>
      <span class="error-message-service text-nowrap" *ngIf="product?.is_nls == 'in_active'">No Longer
        Serviced
      </span>
      <span class="error-message-service text-nowrap"
        *ngIf="product?.is_nls != 'in_active' && product?.alternate_part?true:false">
        No Longer Available
      </span>
      <div class="card-footer bg-white d-flex justify-content-between">
        <!-- <div class="total-quantity"> -->

        <div *ngIf="(userDetails?.user_type == 'MECH' && (product.inventory_quantity > 0 && product.inventory_quantity != null) && product.is_nls != 'in_active' && (product?.alternate_part?false:true)) || (userDetails?.user_type != 'MECH' && product.is_nls != 'in_active' && (product?.alternate_part?false:true))">
          <app-input-number aria-label="Quantity" size="lg" [min]="1" [max]="8" [isTmgoValue]="product.resource_type == 'Pack'?product.moq:'1'"
            (currentQuantity)="readQuantity(product,$event)" [defaultValue]="product.min_quantity" [userType]="userDetails" [inventoryQty]="product?.inventory_quantity">
          </app-input-number>
        </div>
        <div style="display: contents;" *ngIf="userDetails?.user_type == 'MECH'">
          <ng-container *ngIf="product.inventory_quantity > 0 && product.moq < product.inventory_quantity">
            <div class="d-grid gap-2" *ngIf="product?.is_nls != 'in_active' && (product?.alternate_part?false:true)">
              <button class="add-to-card" (click)="addToCart(product)"><i aria-hidden="true"
                  class="fas fa-shopping-cart"></i>
                Add to Cart</button>
            </div>
          </ng-container>
          <ng-container *ngIf="(product.inventory_quantity <= 0 || product.inventory_quantity == null || product.moq > product.inventory_quantity) && product?.is_nls != 'in_active' && (product?.alternate_part?false:true)">
            <h6 class="col-7 text-end d-flex align-items-center" style="font-size: 13px;
            position: relative;
            font-weight: 700;
            color: red;
            top: 5px;width: 100%;">Out Of Stock</h6>
        </ng-container>
        </div>
        <div class="d-grid gap-2" *ngIf="userDetails?.user_type != 'MECH' && product?.is_nls != 'in_active' && (product?.alternate_part?false:true)">
          <button class="add-to-card" (click)="addToCart(product)"><i aria-hidden="true"
              class="fas fa-shopping-cart"></i>
            Add to Cart</button>
        </div>
        <!-- <a href="#" class="add-decrement">-</a>
                <span>20</span>
                <a href="#" class="add-increment">+</a> -->
        <!-- </div> -->
      
        <!-- <div class="col-4 offset-4 text-nowrap" *ngIf="product?.part_status == 'in_active'">
                <span style="color: blue;">View Alternate Part</span>
            </div>   -->
        <div class="col-12 view-alternate text-nowrap"
          *ngIf="product.is_nls == 'in_active' || (product?.alternate_part?true:false)">
          <span [routerLink]="['/shop/product-details']"
            [queryParams]="{ part_Number : product.alternate_part?product.alternate_part:product.part_number }"
            style="cursor: pointer;">View Alternate Part</span>
        </div>
      </div>
    </div>
  </div>
</div>




<div class="row" *ngIf="layout == 'list'">
  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
    <div class="table-responsive table-container">
      <table class="table datatable display align-middle">
        <thead>
          <tr>
            <!-- <th class="text-start">
              <div class="form-check mb-0">
                <input class="form-check-input" id="chk-all" type="checkbox">
              </div>
            </th> -->
            <th class="text-nowrap">Sr. No.</th>
            <th></th>
            <th class="text-nowrap">Product Number</th>
            <th style="width:10px"></th>
            <th class="text-nowrap">Description</th>
            <!-- <th class="text-nowrap">Est. Delivery Date</th> -->
            <th class="text-nowrap">Offer Price</th>
            <th class="text-nowrap">MRP</th>
            <th class="text-nowrap">Quantity</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="!productList?.length; else elseTemplate">
            <tr *ngFor="let item of userService.generateFake(20);let i = index;">
              <td>
                <skc-rectangle [loading]="loading" [skc-height]="'16px'" [skc-width]="'44px'"> </skc-rectangle>
              </td>
              <td>
                <skc-rectangle [loading]="loading" [skc-height]="'16px'" [skc-width]="'44px'"> </skc-rectangle>
              </td>
              <td>
                <div class="d-inline-flex align-items-start">
                  <div class="ps-2 ">
                    <div class="p-title">
                      <skc-rectangle [loading]="loading" [skc-height]="'16px'" [skc-width]="'105px'"> </skc-rectangle>
                    </div>
                  </div>
                </div>
              </td>
              <td class="p-desc">
                <skc-rectangle [loading]="loading" [skc-height]="'16px'" [skc-width]="'228px'"> </skc-rectangle>
              </td>
              <td class="fs-14" *ngIf="isLoggedIn">
                <skc-rectangle [loading]="loading" [skc-height]="'16px'" [skc-width]="'95px'"> </skc-rectangle>
              </td>
              <td>
                <skc-rectangle [loading]="loading" [skc-height]="'16px'" [skc-width]="'78px'"> </skc-rectangle>
              </td>

              <td>
                <skc-rectangle [loading]="loading" [skc-height]="'16px'" [skc-width]="'87px'"> </skc-rectangle>
              </td>
              <td>
                <skc-rectangle [loading]="loading" [skc-height]="'16px'" [skc-width]="'89px'"> </skc-rectangle>
              </td>
            </tr>

          </ng-container>
          <ng-template #elseTemplate>
            <tr *ngFor="let product of productList;let i = index;">
              <!-- <td>
              <div class="form-check mb-0">
                <input class="form-check-input" id="chk-all" type="checkbox">
              </div>
            </td> -->
              <td>{{i+1}}</td>
              <td>
                <img alt="" style="width: 44px; height: 30px;cursor: pointer;" [routerLink]="['/shop/product-details']"
                  [queryParams]="{ part_Number : product.part_number }" [src]="product?.part_image_path + '/resize/44x30'" />
              </td>
              <td>
                <div class="d-inline-flex align-items-start">
                  <!-- <img alt="" class="mt-1" src="../../assets/images/bookmark.svg" /> -->

                  <div class="ps-2 ">
                    <div style="display: flex;">
                      <div class="p-title" [routerLink]="['/shop/product-details']" style="cursor: pointer;"
                        [queryParams]="{ part_Number : product.part_number }">{{ product.part_number }}
                      </div>

                    </div>
                    <ng-container *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                    <div class="p-offer" *ngIf="((product?.base_discount_per + product?.scheme_discount_per +
                  product?.tml_discount_per + product?.distributor_discount_per +
                  product?.rule_discount_per) > 0) && isLoggedIn">
                      {{'SAVE ' + (((product.crm_mrp-product.total_amount)/product.crm_mrp)*100)?.toFixed(1) + '%'}}
                      <!-- <span class="ps-2">2 Days Left</span> -->
                    </div>
                  </ng-container>
                  </div>
                </div>
              </td>
              <td>
                <app-wishlist-icon [product]="product" [layout]="layout"></app-wishlist-icon>

              </td>
              <td class="p-desc" [routerLink]="['/shop/product-details']"
                [queryParams]="{ part_Number : product.part_number }"
                title="{{ (product?.short_description == '' || product?.short_description == null) ? '-' : product?.short_description}}"
                style="cursor: pointer;">
                {{(product?.short_description == " " || product?.short_description == null) ? "-" : product?.short_description?.substr(0,28)}}
              </td>
              <!-- <td class="fs-14">Today</td> -->
              <td class="fs-14">
                <ng-container *ngIf="product.total_amount">
                  <div
                    *ngIf="(product.base_discount_per +  product.scheme_discount_per + product.rule_discount_per ) > 0.0;else elseBlock">
                    <div>
                       {{ ((product.resource_type == 'Pack' ) ? product.total_amount * product.moq : product.total_amount) | currencyFormat :'INR' }}
                    </div>
                  </div>
                  <ng-template #elseBlock>
                    -
                    <!-- <div  *ngIf="product.moq">
                    {{ (product.crm_mrp*product.moq) |currencyFormat:'INR' }}
                  </div>
                  <div  *ngIf="!product.moq">
                    {{ product.crm_mrp |currencyFormat:'INR' }}
                  </div> -->
                  </ng-template>
                </ng-container>
                <ng-container *ngIf="!product.total_amount && !isLoggedIn">
                  -
                </ng-container>
              </td>
              <td
                [ngClass]="(product.base_discount_per +  product.scheme_discount_per + product.rule_discount_per ) > 0.0 ? 'fs-14 mrp-cross' : 'fs-14'">
                <div>
                    {{ ((product.resource_type == 'Pack' ) ? product.crm_mrp * product.moq : product.crm_mrp) | currencyFormat :'INR' }}
                </div>
              </td>
              <!-- <ng-container *ngIf="(product.base_discount +  product.scheme_discount + product.rule_discount ) > 0.0;else elseBlock">
                                <div class="price fw-bold mb-2" *ngIf="product.moq">
                                  <td class="fs-14 mrp-cross">  {{ (product.crm_mrp*product.moq) |currencyFormat:'INR' }}</td>
                                </div>
                                <div class="price fw-bold mb-2" *ngIf="!product.moq">
                                  <td class="fs-14 mrp-cross">  {{ (product.crm_mrp) |currencyFormat:'INR' }}</td>
                                </div>
            </ng-container>
            <ng-template #elseBlock>
              <div class="price fw-bold mb-2" *ngIf="product.moq">
                <td class="fs-14">  {{ (product.crm_mrp*product.moq) |currencyFormat:'INR' }}</td>
              </div>
              <div class="price fw-bold mb-2" *ngIf="!product.moq">
                <td class="fs-14">  {{ (product.crm_mrp) |currencyFormat:'INR' }}</td>
              </div>
            </ng-template> -->

              <!-- <ng-container *ngIf="!product?.total_amount">
              <td class="fs-14" *ngIf="isLoggedIn">-</td>
              <td [ngClass]="isLoggedIn ? 'fs-14 mrp-cross' : 'fs-14'">
                {{(product?.moq) ? (product?.crm_mrp*product?.moq) : product?.crm_mrp | currencyFormat:'INR' }}
              </td>
            </ng-container>
            <ng-container *ngIf="product.total_amount">
              <div *ngIf="(product.base_discount +  product.scheme_discount + product.rule_discount ) == 0.0">
                <td class="fs-14" *ngIf="isLoggedIn">-</td>
                <td class="fs-14">
                  {{(product?.moq) ? (product?.crm_mrp*product?.moq) : product?.crm_mrp | currencyFormat:'INR' }}
                </td>
              </div>
              <div *ngIf="(product.base_discount +  product.scheme_discount + product.rule_discount ) > 0.0">
                <td class="fs-14" *ngIf="isLoggedIn">
                  {{(product?.moq) ? (product?.total_amount*product?.moq) : product?.total_amount | currencyFormat:'INR' }}
                </td>
                <td class="fs-14 mrp-cross">
                  {{(product?.moq) ? (product?.crm_mrp*product?.moq) : product?.crm_mrp | currencyFormat:'INR' }}
                </td>
              </div>
            </ng-container> -->
            <ng-container>
              <td *ngIf="(userDetails?.user_type == 'MECH' && (product.inventory_quantity > 0 && product.inventory_quantity != null && product.moq < product.inventory_quantity) && product?.is_nls != 'in_active') || (userDetails?.user_type != 'MECH' && product?.is_nls != 'in_active')">             
                  <app-input-number aria-label="Quantity" size="lg" [min]="1" [max]="8"
                  (currentQuantity)="readQuantity(product,$event)" [isTmgoValue]="product.resource_type == 'Pack'?product?.moq:'1'"
                  [defaultValue]="product.min_quantity" [userType]="userDetails" [inventoryQty]="product?.inventory_quantity">
                </app-input-number>
              </td>
              <td *ngIf="(userDetails?.user_type == 'MECH' && (product?.inventory_quantity <= 0 || product?.inventory_quantity == null || product.moq > product.inventory_quantity)) && (product?.is_nls == 'active')">
                  <h6 class="col-7 text-end d-flex align-items-center" style="font-size: 13px;
                  position: relative;
                  font-weight: 700;
                  color: red;
                  top: 5px;">Out Of Stock</h6>
              </td>
                <td *ngIf="product?.is_nls == 'in_active'">
                  <span class="error-message-service text-nowrap">No Longer
                    Serviced
                  </span>
                </td>
              </ng-container>
              <ng-container *ngIf="userDetails?.user_type == 'MECH'">
                <td *ngIf="product.inventory_quantity > 0 && product?.is_nls != 'in_active'" > 
                  <button class="add-to-card" (click)="addToCart(product)"><img src="./assets/images/cart.svg" /></button>
                </td>
                
                <!-- <td>
                    <ng-container *ngIf="userDetails?.user_type == 'MECH' && (product?.inventory_quantity == 0 || product?.inventory_quantity == null) && product?.is_nls == 'in_active'">
                      <h6 class="col-7 text-end d-flex align-items-center" style="font-size: 13px;
                      position: relative;
                      font-weight: 700;
                      color: red;
                      top: 5px;">Out Of Stock</h6>
                  </ng-container>
                </td> -->
              </ng-container>
              <ng-container *ngIf="userDetails?.user_type != 'MECH'">
                <td *ngIf="product?.is_nls != 'in_active'" > 
                  <button class="add-to-card" (click)="addToCart(product)"><img src="./assets/images/cart.svg" /></button>
                </td>
              </ng-container>
          
                  <td *ngIf="product?.is_nls == 'in_active'">
                    <div class="view-alternate text-nowrap"
                    *ngIf="product.is_nls == 'in_active' || (product?.alternate_part?true:false)">
                      <span [routerLink]="['/shop/product-details']"
                        [queryParams]="{ part_Number : product.alternate_part?product.alternate_part:product.part_number }"
                        style="cursor: pointer;">View Alternate Part</span>
                    </div>
                    <div class="text-nowrap no-alternate"  *ngIf="product?.is_nls != 'in_active' && product?.alternate_part?true:false">
                      <span style="cursor: pointer;">No Alternate Part Available</span>
                    </div>
                  </td>
            </tr>
          </ng-template>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ng-template #confimationmodal let-d="dismiss">
  <app-selectCartType [btnshowConfrimed]="btnshowConfrimed" [btnshowsubmit]="btnshowsubmit"
    [currentlyAddingProduct]="selectedProduct" [cartTypeList]="cartTypeList" [baseProductdata]="productInfo"
    (selectedCartType)="ShowPart()" (setCartType)="setAddCart(cartType,product)" (inputChange)="changeCartType($event)"
    (closeModal)="closePart()">
  </app-selectCartType>
</ng-template>

<!-- <ng-container *ngIf="showLoader">
  <div class="loader">
    <div class="ng-spinner ng-spinner-icon"></div>
  </div>
</ng-container> -->