import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
    selector: 'mechanic-details',
    templateUrl: './mechanic-details.component.html',
    styleUrls: ['./mechanic-details.component.scss']
})

export class MechanicDetailsComponent implements OnInit {
    
    @Input() mechanicDetails:any;
    @Output() closePopUp = new EventEmitter<any>();

    constructor( ){}
    ngOnInit(){
    }

    closeModal(){
        this.closePopUp.emit();
    }

}