import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../../../../../src/app/shared/services/REST/user.service';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ExcelCreatorService } from '../../../../../../src/app/shared/services/excel-creator.service';
import { Inputdata, InputPartSearch } from '../../../../../../src/app/shared/interfaces/product';
import { ModelService } from '../../../../../../src/app/shared/services/REST/model.service';
import { blockPermissionMsg } from '../../../../../../src/app/constant';
import { AppStateService } from '../../../../../../src/app/shared/services/app-state/app-state.service';
import { secureStorage } from '../../../../../../src/app/shared/services/securestorage';
import { OwlOptions } from 'ngx-owl-carousel-o';
import  jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
  blockPermissionMsg = blockPermissionMsg;
  removeVehicledata = [];
  layout: any = 'card';
  vehicle = [];
  vehicleinfo = [];
  account_name: any;
  state: any;
  city: any;
  contact_no: any;
  account_id: any;
  pincode: any;
  address_2: any;
  address_1: any;
  address_3: any;
  district: any;
  userdatas: any;
  addVehicleModal: any;
  viewRCmodal: any;
  vehicleDetails: any;
  deleteVehicleModal: any;
  @ViewChild('addVehicle', { read: TemplateRef, static: false })
  addVehicle: TemplateRef<any>;
  @ViewChild('viewRCBookModalVehicle', { read: TemplateRef, static: false })
  viewRCBookModalVehicle: TemplateRef<any>;
  @ViewChild('delete', { read: TemplateRef, static: false })
  deleteVehicle: TemplateRef<any>;
  @ViewChild('uploadmodal', { read: TemplateRef, static: false })
  downloadVehicleDetails: TemplateRef<any>;
  @ViewChild('inputFile', { static: false }) myInputVariable: ElementRef;
  isVehicleDelete: boolean = false;
  VehicleDetails: any[];
  rcbookdetail;
  showLoader: boolean = false;
  isMyProfile: boolean = true;
  isAccount: boolean = false;
  isUserManagement: boolean = false;
  isUserPermission: boolean = false;
  isBulkVehicleUpload: boolean = false;
  DocumentFile: File = null;
  Filemessage: any;
  TypeName: any;
  AccountData: any;
  typevalue: any;
  myControl = new FormControl();
  submitted: boolean = false;
  user: any;
  userVehicleForm: FormGroup;
  excel = [];
  printdata: string;
  fileName: string;
  UploadResponseData: any;
  modalRef: any;
  previousText: string = '';
  selectedModelCategory: any;
  isVehicleEmpty: boolean = false;
  downloadVehicleDetailsModall: any;
  currentPage: any = 1;
  offset: any;
  totalrecord: any;
  vehiclecount;
  selectAll = false;
  // profileMenu;
  isProfilePermission;
  isVehiclePermission;
  profileMenu;
  isVehicleListUploaded: boolean = false;
  isMyVehicles: boolean = false;
  isNoDataFound:boolean;
  partCataloguePdfName:any;
  customOptionsForModels: OwlOptions = {
    //items: 4,
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 1000,
    autoWidth: true,
    nav: true,
    navText: ["<div><img src='./assets/images/arrow-left.svg' /></div>", "<div><img src='./assets/images/arrow-right.svg' /></div>"],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },

  }
  prodcardbreadcrumb: any[];

  account_pk: any;
  shippingaddressid: any;
  billingaddressid: any;
  searchuserData = '';
  pgline = [];
  isLoggedIn: boolean = false;

  constructor(
    private toastrService: ToastrService,
    private userService: UserService,
    private router: Router,
    private modalService: BsModalService,
    public modelService: ModelService,
    private excelCreatorService: ExcelCreatorService,
    private fb: FormBuilder,
    private appStateService: AppStateService,
    private localstorage: secureStorage,
    private route: ActivatedRoute,
    private title:Title
  ) {
    // this.profileMenu = JSON.parse(this.localstorage.getItem('profileLayoutMenu'));
    if (this.localstorage.getItem('isProfilePermission')) {
      this.isProfilePermission = JSON.parse(this.localstorage.getItem('isProfilePermission'));
    }
    if (this.localstorage.getItem('isVehiclePermission')) {
      this.isVehiclePermission = JSON.parse(this.localstorage.getItem('isVehiclePermission'));
    }
  }
  ngOnInit() {
    this.layout = 'card';
    this.isBulkVehicleUpload = false;
    this.offset = 0;
    this.totalrecord = 0;
    this.buildForm();
    this.userVehicle();
    this.title.setTitle('E-Dukaan | Profile Management');
    this.route.queryParams.subscribe((params) => {
      if (params['isMyVehicles'] === 'true') {
        this.isMyVehicles = true;;
      }
    })
    this.appStateService.isprofilelist.subscribe((isprofilelist) => {
      if (this.localstorage.getItem('profileLayoutMenu')) {
        this.profileMenu = JSON.parse(this.localstorage.getItem('profileLayoutMenu'));
      }
    });

    this.appStateService.isLoggedIn.subscribe((isLog) => {
      this.isLoggedIn = isLog;
    });

    let address = JSON.parse(this.localstorage.getItem('address'));
        var addresstypecheck = address?.address['default'];
        if (addresstypecheck === true) {
          this.shippingaddressid = 'default';
        } else {
          this.shippingaddressid = address?.address['address_id'];
        }

    this.appStateService.isProfilePermission.subscribe(
      (isProfilePermission) => {
        if (this.localstorage.getItem('isProfilePermission')) {
          this.isProfilePermission = JSON.parse(this.localstorage.getItem('isProfilePermission'));
        }
      }
    );
    this.appStateService.isVehiclePermission.subscribe(
      (isVehiclePermission) => {
        if (this.localstorage.getItem('isVehiclePermission')) {
          this.isVehiclePermission = JSON.parse(this.localstorage.getItem('isVehiclePermission'));
        }
      }
    );
  }

  buildForm() {
    this.userVehicleForm = this.fb.group({
      searchText: [''],
    });
  }

  filterData(formControl) {
    if (this.previousText !== formControl.value) {
      if (
        formControl != '' &&
        formControl != null &&
        formControl != undefined &&
        String(formControl.value).length > 2 || String(formControl.value).length == 0
      ) {
        this.previousText = formControl.value;
        this.getvehicle(this.selectedModelCategory, formControl.value);
      }
    }
  }

  clearsearch(event, formControl) {
    this.getvehicle(this.selectedModelCategory, formControl.value);
  }
  pageChange(page: any) {
    this.currentPage = page;
    page = (page - 1) * 12;
    this.offset = page;
    this.getvehicle(this.selectedModelCategory, '', false, true);
  }
  async userVehicle(newVehicleAddRoute?) {
    try {
      this.isBulkVehicleUpload = newVehicleAddRoute
        ? newVehicleAddRoute
        : !this.isBulkVehicleUpload;
      this.vehicle = [];
      if (this.localstorage.getItem('userData')) {
        this.userdatas = JSON.parse(this.localstorage.getItem('userData'));
        if (this.userdatas && this.userdatas.account_pk) {
          this.showLoader = true;
          this.isVehicleEmpty = false;
          await this.userService
            .userprofile({ account_pk: this.userdatas.account_pk })
            .subscribe(
              (response) => {
                if (response.success) {
                  this.vehicle = response.data;
                  this.getvehicle(response.data[0]);
                } else {
                  this.isVehicleEmpty = true;
                  this.isVehicleListUploaded = true;
                  this.totalrecord = 0;
                  this.vehicleinfo = [];
                  this.vehiclecount = 0;
                  if (response.data.msg != 'Data not found') {
                    this.toastrService.error(response.data.msg);
                  }
                }
                this.showLoader = false;
              },
              (error) => {
                this.isVehicleEmpty = true;
                this.showLoader = false;
              }
            );
        }
      }
    } catch (error) { }
  }

  getvehicle(data, searchText?, newlistcall?, isPaginationUsed?) {
    try {
      if (newlistcall) {
        this.currentPage = 1;
        this.offset = 0;
      }
      this.isVehicleListUploaded = false;
      this.VehicleDetails = [];
      this.removeVehicledata = [];
      let userdatas = JSON.parse(this.localstorage.getItem('userData'));
      this.selectedModelCategory = data;
      // var request = {
      //   "account_pk": userdatas.account_pk,
      //   "from_date": "",
      //   "to_date": "",
      //   "text_search": "",
      //   "model_cat": data.model_cat,
      //   "offset": 0,
      //   "size": 10
      // }
      var request = {
        account_pk: userdatas.account_pk,
        from_date: '',
        to_date: '',
        text_search: searchText ? searchText : '',
        model_cat: data.model_cat,
        base_model: '',
        fuel_type: '',
        mod_uid: '',
        vc_no: '',
        engine_type: '',
        list_type: '',
        offset: this.offset,
        limit: 12,
      };
      this.vehicleinfo = [];
      this.showLoader = true;
      this.isVehicleEmpty = true;
      this.userService.GetVehicleList(request).subscribe(
        (response) => {
          if (response.success) {
            this.isVehicleEmpty = false;
            this.totalrecord = response.total_result;
            this.vehicleinfo = response.data;
            this.vehiclecount = response.data.length;
            if (this.isMyVehicles) {
              this.redirectToMyVehicles();
              this.isMyVehicles = false;
            }
          } else {
            this.totalrecord = 0;
            this.isVehicleEmpty = true;
            if (response?.data?.msg != 'Data not found') {
              this.toastrService.error(response.data.msg);
            }
          }
          this.isVehicleListUploaded = true;
          if (searchText == undefined) {
            this.userVehicleForm.reset();
          }
          this.showLoader = false;
          if (isPaginationUsed) {
            setTimeout(() => {
              document.getElementById('topPosition').scrollIntoView();
            }, 250);
          }
        },
        (error) => {
          this.isVehicleListUploaded = true;
          this.showLoader = false;
        }
      );
    } catch (error) { }
  }

  addvehicle(vehicle?: any) {
    let permission = this.userService.CheckPermission('btn_add_vehicle');

    if (permission) {
      if (vehicle !== null && vehicle !== undefined && vehicle !== '') {
        this.vehicleDetails = vehicle;
        this.addVehicleModal = this.modalService.show(this.addVehicle, {
          class: 'modal-dialog modal-dialog-centered',
          backdrop: 'static',
        });
      } else {
        this.addVehicleModal = this.modalService.show(this.addVehicle, {
          class: 'modal-dialog modal-dialog-centered',
          backdrop: 'static',
        });
      }
    } else {
      this.toastrService.error(blockPermissionMsg);
    }
  }
  openrcpopup(data) {
    if (data !== null && data !== undefined && data !== '') {
      this.rcbookdetail = data;
      this.viewRCmodal = this.modalService.show(this.viewRCBookModalVehicle, {
        class: 'modal-dialog modal-dialog-centered',
        backdrop: 'static',
      });
    }
  }
  closeModal(event) {
    if (event == 'Vehicle Added Successfully') {
      setTimeout(() => {
        this.userVehicle(true);
      }, 500);
    } else {
    }
    this.vehicleDetails = '';
    this.addVehicleModal.hide();
  }
  closeRCPopUp(event) {
    this.viewRCmodal.hide();
  }
  GotoView(layoutname) {
    this.layout = layoutname;
  }

  selectedVehicle(event, vehicle?: any) {
    if (event.target.checked === true) {
      if (vehicle) {
        this.removeVehicledata.push(vehicle.id);
        this.VehicleDetails = vehicle;
      } else {
        this.toastrService.error('Please select a vehicle to delete');
      }
    } else {
      const index = this.removeVehicledata.indexOf(vehicle.id);
      if (index > -1) {
        this.removeVehicledata.splice(index, 1);
      }
    }
  }

  deleteVehicleRequest() {
    let permission = this.userService.CheckPermission('btn_delete_vehicle');
    if (permission) {
      if (this.removeVehicledata.length > 0) {
        this.isVehicleDelete = true;
        this.deleteVehicleModal = this.modalService.show(this.deleteVehicle, {
          class: 'modal-dialog modal-dialog-centered',
          backdrop: 'static',
        });
      } else {
        this.toastrService.error('Please select a vehicle to delete');
      }
    } else {
      this.toastrService.error(blockPermissionMsg);
    }
  }

  Delete() {
    try {
      // let permission = this.userService.CheckPermission('btn_delete_vehicle');
      // if (permission) {
      let user = JSON.parse(this.localstorage.getItem('userData'));
      if (user && user.account_pk) {
        // let requestBody = {
        //   account_pk: '' + user.account_pk,
        //   id: parseInt(this.VehicleDetails['id'])
        // }
        let requestBody = {
          account_pk: '' + user.account_pk,
          vehicle_ids: this.removeVehicledata,
        };
        this.showLoader = true;
        this.userService.RemoveVehicle(requestBody).subscribe(
          (response) => {
            if (response.success) {
              this.VehicleDetails = [];
              this.removeVehicledata = [];
              this.toastrService.success(response.data.msg);
              this.isBulkVehicleUpload = false;
              this.userVehicle();
            } else {
              this.toastrService.error(response.data.msg);
              // for(let i=0; i<this.removeVehicledata.length; i++){
              //   var checked = <HTMLInputElement>document.getElementById(this.removeVehicledata[i]);
              //   checked.checked = false;
              // }
              //this.removeVehicledata = [];
              this.VehicleDetails = [];
            }
            // setTimeout(() => {
            //   this.sliderFunction();
            // }, 200);
            this.showLoader = false;
            this.deleteVehicleModal.hide();
          },
          (error) => {
            this.showLoader = false;
            this.deleteVehicleModal.hide();
            this.toastrService.error(error.error.data.msg);
            // setTimeout(() => {
            //   this.sliderFunction();
            // }, 200);
          }
        );
      }
      // } else {
      //   this.toastrService.error(blockPermissionMsg);
      // }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'Delete()',
        page_name: 'PageVehicleListComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  SelectDocumentFiles(event) {
    try {
      this.fileName = '';
      if (event.target.files && event.target.files[0]) {
        this.Filemessage =
          'Are You Sure to upload ' + event.target.files[0].name + '?';
        var Extension = event.target.files[0].name
          .substring(event.target.files[0].name.lastIndexOf('.') + 1)
          .toLowerCase();
        // if (Extension == "csv") {
        if (Extension == 'xlsx' || Extension == 'XLSX') {
          const reader = new FileReader();
          const file = event.target.files[0];
          this.fileName = event.target.files[0].name;
          this.DocumentFile = event.target.files[0];

          if (file.size < 5000000) {
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = (event) => {
              let target: any = event.target;
            };
          } else {
            this.toastrService.error('Upload only 5 MB size files!');
          }
        } else {
          this.toastrService.error('Upload only XLSX Files');
        }
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'SelectDocumentFiles(event) ',
        page_name: 'PageVehicleAddComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }
  checkUncheckAll() {
    for (var i = 0; i < this.vehicleinfo.length; i++) {
      this.vehicleinfo[i].isSelected = this.selectAll;
    }

    this.getCheckedItemList();
  }

  isAllSelected() {
    this.selectAll = this.vehicleinfo.every(function (item: any) {
      return item.isSelected == true;
    });

    this.getCheckedItemList();
  }

  getCheckedItemList() {
    this.removeVehicledata = [];

    for (var i = 0; i < this.vehicleinfo.length; i++) {
      if (this.vehicleinfo[i].isSelected)
        this.removeVehicledata.push(this.vehicleinfo[i].id);
    }
  }
  Upload() {
    try {
      if (this.DocumentFile) {
        var Check = false;
        let user = JSON.parse(this.localstorage.getItem('userData'));
        if (user) {
          this.UploadResponseData = [];
          const formData = new FormData();
          formData.append('file', this.DocumentFile);
          formData.append('account_pk', user.account_pk);
          Check = true;
          if (Check) {
            this.userService.UploadVehicle(formData).subscribe((response) => {
              if (response.success === true) {
                if (
                  response.data.msg === 'Provided xls or xlsx file is empty.' ||
                  response.data.msg ===
                  'Invalid file format. Please reupload using the assigned format only.'
                ) {
                  this.toastrService.error(response.data.msg);
                } else {
                  this.UploadResponseData = response.data;
                  this.toastrService.success('File is uploaded successfully.');
                  if (this.modalRef) {
                    this.modalRef.hide();
                  }
                  this.downloadVehicleDetailsModall = this.modalService.show(
                    this.downloadVehicleDetails,
                    {
                      class: 'modal-dialog-centered modal-md',
                      backdrop: 'static',
                    }
                  );
                  this.DocumentFile = null;
                  this.myInputVariable.nativeElement.value = '';
                  this.fileName = '';
                }
              } else {
                this.DocumentFile = null;
                this.fileName = '';
                this.myInputVariable.nativeElement.value = '';
                this.toastrService.error(response.data.msg);
              }
            });
          } else {
            this.toastrService.error('Please Try After Some Times');
          }
        }
      } else {
        this.toastrService.error('Please Upload Excel File.');
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'Upload() ',
        page_name: 'PageVehicleAddComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  changeBody() {
    let permission = this.userService.CheckPermission('btn_add_vehicle');

    if (permission) {
      this.isBulkVehicleUpload = !this.isBulkVehicleUpload;
    } else {
      this.toastrService.error(blockPermissionMsg);
    }
  }

  DownloadFile() {
    try {
      this.excelCreatorService.exportAsExcelFileVehicleStatus(
        this.UploadResponseData,
        'vehicle_upload_status'
      );
      this.UploadResponseData = [];
      this.downloadVehicleDetailsModall.hide();
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'DownloadFile() ',
        page_name: 'PageVehicleAddComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  redirectToMyVehicles() {
    document.getElementById("myVehicles").scrollIntoView();
  }

  viewPartsForSelectedVehicle(vehicle,searchText) {
    this.prodcardbreadcrumb = [];
    localStorage.removeItem('productcardBreadcrumb');
    if (vehicle?.chassis_no === searchText && vehicle?.chassis_no === searchText && vehicle?.chassis_no === searchText) {
      this.prodcardbreadcrumb.push({
        label: vehicle.base_model + ' > ',
        url: '/shop/productcards',
        params: {
          ModelName: vehicle.model_cat,
          type: 'Model',
          baseModel: vehicle.base_model,
          vcNo: (vehicle?.vc_no != '' && vehicle?.vc_no != undefined && vehicle?.vc_no != null) ? vehicle?.vc_no : undefined,
          headerSearchText: searchText
        },
      });
      this.localstorage.setItem('productcardBreadcrumb', JSON.stringify(this.prodcardbreadcrumb))
      this.router.navigate(['/shop/productcards'], {
        queryParams: { 
          ModelName: vehicle.model_cat, 
          type: 'Model', 
          baseModel: vehicle.base_model, 
          vcNo: (vehicle?.vc_no != '' && vehicle?.vc_no != undefined && vehicle?.vc_no != null) ? vehicle?.vc_no : undefined, 
          headerSearchText: searchText
        },
      });
    }
    else {
      this.prodcardbreadcrumb.push({
        label: vehicle.base_model + ' > ',
        url: '/shop/productcards',
        params: {
          ModelName: vehicle.model_cat,
          baseModel: vehicle.base_model,
          type: 'Model',
          vcNo: (vehicle?.vc_no != '' && vehicle?.vc_no != undefined && vehicle?.vc_no != null) ? vehicle?.vc_no : undefined,
          headerSearchText: searchText
        },
      });
      this.localstorage.setItem('productcardBreadcrumb', JSON.stringify(this.prodcardbreadcrumb))
      this.router.navigate(['/shop/productcards'], {
        queryParams: { 
          ModelName: vehicle.model_cat, 
          baseModel: vehicle.base_model,
          vcNo: (vehicle?.vc_no != '' && vehicle?.vc_no != undefined && vehicle?.vc_no != null) ? vehicle?.vc_no : undefined,
          type: 'Model', 
          headerSearchText: searchText 
        },
      });
    }
  }

  async downloadVehicleParts(vcObject){
    this.partCataloguePdfName = vcObject.registration_no? ( "Part Catalogue - "+vcObject.registration_no): vcObject.chassis_no ? ( "Part Catalogue - "+vcObject.chassis_no ) : "Part Catalogue";
    this.showLoader = true;
    const ListInput: Inputdata = {} as Inputdata;
    ListInput.offset = 0;
    ListInput.limit = 200;
    ListInput.multi_model_cat = [vcObject.model_cat];
    ListInput.multi_base_model =  [vcObject.base_model];
    ListInput.multi_aggregate =  [];
    if(vcObject.vc_no){
      ListInput.multi_vc_no =  [vcObject.vc_no];
    }
    ListInput.multi_sub_aggregate_no = [];
    this.isNoDataFound = false;
    let response = await this.modelService.getBom(ListInput);
    let bomData:any[] = [] 
    while(response['success']){
      this.showLoader = true;
      bomData.push(response['data']);
      ListInput.offset = ListInput.offset + ListInput.limit;
      response = await this.modelService.getBom(ListInput);
    } 
    let partSearchRes:any;
    let mainArrayPartDetails:any[] = [];
    for(var i=0;i<bomData.length;i++){
      for (var j=0;j<bomData[i].length;j++){
        let temBomData:any[] = [];
        let countBom = 0;
        let ofst = 0;
        let tempPartArray:any[] = [];
        while( j<bomData[i].length && countBom<50 ){
          temBomData.push(bomData[i][j]);
          countBom++;
          if(countBom<=49){
            j++;
          }
        }
        partSearchRes = await this.bindTestPartSearch(temBomData,ofst);
        if(partSearchRes!=undefined){
          tempPartArray = tempPartArray.concat(partSearchRes['data']);
          while( partSearchRes != undefined  ){
            ofst = ofst + 50;
            partSearchRes = await this.bindTestPartSearch(temBomData,ofst);
            if(partSearchRes != undefined ){
              tempPartArray = tempPartArray.concat(partSearchRes['data']);
            }
          }
          mainArrayPartDetails = mainArrayPartDetails.concat(tempPartArray);
        }
      }
    }
    const data:any[] = [];
    if(mainArrayPartDetails.length > 0){
      const doc = new jsPDF();
      const columns = [['Part Number', 'Part Description', 'Quantity', 'Category' ]];
      
      mainArrayPartDetails.forEach(element => {
        data.push([element?.part_number, element?.short_description, element?.min_quantity, element?.distributor_category]);
      });
      autoTable(doc, {
            head: columns,
            body: data,
            didDrawPage: (dataArg) => { 
            doc.text(this.partCataloguePdfName, dataArg.settings.margin.left, 10);
            }
      }); 

      doc.save(this.partCataloguePdfName);
    }else{
      this.toastrService.error("No Data Found");
    }
    this.showLoader = false;
  }

  async bindTestPartSearch(data, offset){
    let BomIdData  = data;
      if (BomIdData) {
        let user = JSON.parse(this.localstorage.getItem('userData'));
        let address = JSON.parse(this.localstorage.getItem('address'));
        if (user && address) {
          this.account_pk = +JSON.stringify(user.account_pk);
          let billingaddress = JSON.parse(
            this.localstorage.getItem('billingAddress')
          );
          var addresstypecheck = address?.address['default'];
          if (addresstypecheck === true) {
            this.shippingaddressid = 'default';
          } else {
            this.shippingaddressid = address?.address['address_id'];
          }
          var Billingaddresscheck = billingaddress?.address['default'];
          if (Billingaddresscheck === true) {
            this.billingaddressid = 'default';
          } else {
            this.billingaddressid = billingaddress?.address['address_id'];
          }
        }
  
        const ListPartInput: InputPartSearch = {} as InputPartSearch;
        ListPartInput.offset = offset;
        ListPartInput.limit = 50;
        ListPartInput.search_text = this.searchuserData
          ? this.searchuserData
          // : this.searchValue
          // ? this.searchValue
          : '';
        ListPartInput.bs_model_name = '';
        ListPartInput.pg_line = '';
        ListPartInput.multi_pg_line = [];
        ListPartInput.multi_bs_model_name = [];
        ListPartInput.multi_dispgroup_name = [];
        ListPartInput.model_cat = '';
        ListPartInput.mod_uid = '';
        ListPartInput.category_type = '';
        ListPartInput.bom_id = '';
        ListPartInput.durafit_parts = false;
        ListPartInput.kit_parts = false;
        ListPartInput.part_number_last_four = '';
        ListPartInput.pl_id = '';
        ListPartInput.is_home = false;
        ListPartInput.part_number = '';
        ListPartInput.is_for_epc_data = false;
        ListPartInput.is_for_discount = false;
        ListPartInput.is_for_ecom_detail = false;
        ListPartInput.bom_id_list = BomIdData ? BomIdData : []; //result[i];
        ListPartInput.account_pk = this.isLoggedIn == true ? this.account_pk : '';
        ListPartInput.is_for_inventory = user?.user_type == 'MECH' ? true: false;
        ListPartInput.shipping_address_id =
          this.isLoggedIn == true ? this.shippingaddressid : '';
        this.showLoader = true;
        this.isNoDataFound = false;
        let data = await this.modelService.getPartSearchMd(ListPartInput);
        if (data['success'] === true) {
          return data;
        }
    }
  }
}
