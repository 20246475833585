import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
// import { MatIconModule } from '@angular/material/icon';
import { NgOtpInputModule } from 'ng-otp-input';
//import { HomeComponent } from './home/home.component';
import { BannerComponent } from './banner/banner.component';
import { LocationStrategy, TitleCasePipe } from '@angular/common';
import { CustomLocationStrategy } from './custom-location-strategy';
import { RootService } from './shared/services/REST/root.service';
import { AppStateService } from './shared/services/app-state/app-state.service';
import { HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { CookieModule, CookieService } from 'ngx-cookie';
import { SharedModule } from './shared/shared.module';
import { RootComponent } from './components/root/root.component';
import { MyHttpInterceptor } from './shared/interceptor/http.interceptor';
import { HeaderModule } from './modules/header/header.module';
import { FooterModule } from './modules/footer/footer.module';
import { IndexComponent } from './pages/index/index.component';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { ToastrModule } from 'ngx-toastr';
import { AccountModule } from './modules/account/account.module';
import { ShopModule } from "../app/modules/shop/shop.module";
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { secureStorage } from './shared/services/securestorage';
import { environment } from '../../src/environments/environment';
// import { NgxSkeletonSmartModule } from 'ngx-skeleton-smart';
import { NgxSkeletonSmartModule } from 'ngx-smart-skeleton';
import { AgmCoreModule, AgmMap, AgmMarker } from '@agm/core';
import { Router } from '@angular/router';
import { EdukaanNotificationComponent } from './edukaan-notification/edukaan-notification.component';
import { MySavingComponent } from './modules/account/my-saving/my-saving.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
export function init_app(
  rootService: RootService,
  appStateService: AppStateService,
  cookieService: CookieService,
  localstorage: secureStorage,
  router: Router
) {
  const APP_VERSION = environment?.version;
  if (typeof localStorage.APP_VERSION === 'undefined' || localStorage.APP_VERSION === null) {
    localStorage.clear();
    localStorage.setItem('APP_VERSION', APP_VERSION);
  }

  if (localStorage.APP_VERSION != APP_VERSION) {
    localStorage.clear();
    localStorage.removeItem('userData');
    localStorage.removeItem('user');
    localStorage.removeItem('orderCalculationData');
    localStorage.removeItem('OrderDetails');
    localStorage.removeItem('isOtherUser');
    localStorage.removeItem('otheruser');
    localStorage.removeItem('OtherUserPermission');
    localStorage.removeItem('usertype');
    localStorage.removeItem('PageList');
    localStorage.removeItem('ref_token');
    localStorage.removeItem('division_info');
    localStorage.removeItem('division_info1');
    localStorage.removeItem('SLVCart');
    localStorage.removeItem('MHVCart');
    localStorage.removeItem('isVehicleSelected');
    localstorage.getItem('OrderType');
    localstorage.getItem('OrderData');
    cookieService.remove('access_token');
    cookieService.remove('ref_token');
    localstorage.setItem('coachMarksRender','false');
    localStorage.setItem('APP_VERSION', APP_VERSION);
    localstorage.setItem("isCartDropdownOpened", "false");
    localstorage.setItem("isProfileDropdownOpened", "false");
    router.navigate(['']);
  }

  let user = localstorage.getItem('userData');
  let userRecord = JSON.parse(localstorage.getItem('user'));
  let token = cookieService.get('access_token');
  let refreshToken = cookieService.get('ref_token') ? cookieService.get('ref_token') : localstorage.getItem('ref_token');
  let isVehicleSelected = JSON.parse(localstorage.getItem('isVehicleSelected'));
  if (user && userRecord && refreshToken && isVehicleSelected === true) {
    appStateService.isLoggedIn.next(true);
    appStateService.reloadcartConfiguration.next("reload");
    localStorage.removeItem('cartItems');
    appStateService.userSubject.next(JSON.parse(user));
    localstorage.setItem("isCartDropdownOpened", "false");
    localstorage.setItem("isProfileDropdownOpened", "false");
    return () => rootService.refreshToken();
  }
  localStorage.removeItem('userData');
  localStorage.removeItem('user');
  localStorage.removeItem('orderCalculationData');
  localStorage.removeItem('OrderDetails');
  localStorage.removeItem('isOtherUser');
  localStorage.removeItem('otheruser');
  localStorage.removeItem('OtherUserPermission');
  localStorage.removeItem('usertype');
  localStorage.removeItem('PageList');
  localStorage.removeItem('ref_token');
  localStorage.removeItem('division_info');
  localStorage.removeItem('division_info1');
  localStorage.removeItem('SLVCart');
  localStorage.removeItem('MHVCart');
  localStorage.removeItem('isVehicleSelected');
  JSON.parse(localstorage.getItem('OrderType'));
  JSON.parse(localstorage.getItem('OrderData'));
  cookieService.remove('access_token');
  cookieService.remove('ref_token');
  localstorage.setItem("isCartDropdownOpened", "false");
  localstorage.setItem("isProfileDropdownOpened", "false");
  return () => rootService.getToken();
}

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    //  HomeComponent,
    BannerComponent,
    RootComponent,
    MySavingComponent,
    // BreadcrumbComponent,
    EdukaanNotificationComponent,
    
  ],
  imports: [
    NgbModule,
    BrowserModule,
    ShopModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxSkeletonSmartModule,
    MatCardModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    // MatIconModule,
    HttpClientModule,
    AccountModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-CSRF-TOKEN',
    }),
    NgProgressModule,
    NgProgressHttpModule.withConfig({
      silentApis: [`${environment.apiUrl}api/v1/ecom/get/nav/pl_wise_list/`,
      // `${environment.apiUrl}api/v1/ecom/get/nav/ppl_wise_list/`,
      // `${environment.apiUrl}api/v1/ecom/get/nav/pg_line_group_list/`,
      // `${environment.apiUrl}api/v1/ecom/order/partnumber_based_discount_calc/`,
      // `${environment.apiUrl}api/v1/ecom/get/list/model_category/`,
      // `${environment.apiUrl}api/v1/ecom/user/list/distributor/`,
      // `${environment.apiUrl}api/v2/ecom/search/address_dropdowns/`,
      // `${environment.apiUrl}api/v2/ecom/search/group_category_list/`,
      // `${environment.apiUrl}api/v2/ecom/search/vehicle_dropdowns/`,
      // `${environment.apiUrl}api/v2/ecom/search/data_for_home/`,
      ]
    }),
    NgOtpInputModule,
    HttpClientModule,
    SharedModule,
    CookieModule.forRoot(),
    HeaderModule,
    FooterModule,
    //OrdersModule,
    ModalModule.forRoot(),
    ToastrModule.forRoot(),
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    CarouselModule,
    // AgmCoreModule.forRoot({}),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyBcGudZMXCMmd9uHo-WXYrBGplcWvCNOZU",
    }),
  ],
  // exports: [MatButtonModule, MatIconModule, NgOtpInputModule],
  exports: [NgOtpInputModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: MyHttpInterceptor, multi: true, },
    { provide: LocationStrategy, useClass: CustomLocationStrategy },
    { provide: APP_INITIALIZER, useFactory: init_app, deps: [RootService, AppStateService, CookieService, secureStorage, Router], multi: true, },
    BsModalService,
    TitleCasePipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
