import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AppStateService } from '../../services/app-state/app-state.service';
import { AuthService } from '../../services/REST/auth.service';
import { secureStorage } from '../../services/securestorage';
@Component({
  selector: 'select-user',
  templateUrl: './select-user.component.html',
  styleUrls: ['./select-user.component.scss'],
})
export class SelectUserComponent implements OnInit {
  @Output() closeUserPopUp = new EventEmitter<any>();
  @Output() setselectedUser = new EventEmitter<any>();
  @Output() bindUserAccounts = new EventEmitter<any>();
  @Input() btndisabled: boolean;
  @Input() isSwitchAccount: boolean = false;

  @Input() userIds: any[];
  selectedUser: any;
  is_active: boolean = true;
  @Input() currentAccount: any;
  isLoggedIn: boolean = false;
  isConfirmButtonClicked: boolean = false;
  isCurrentAccountSelected: boolean = true;
  constructor(
    private appStateService: AppStateService,
    private toastrService: ToastrService,
    private localstorage: secureStorage,
  ) {
    
  }

  ngOnInit(): void {
    this.userIds;
    this.appStateService.isLoggedIn.subscribe((isLog) => {
      this.isLoggedIn = isLog;
    });
  }

  closePopUp() {
    this.closeUserPopUp.emit();
  }

  selectUser(event) {
    this.selectedUser = event;
    if(this.isLoggedIn && this.isSwitchAccount){
      let userData = JSON.parse(this.localstorage.getItem('userData'));
      if (userData.account_pk === this.selectedUser.account_pk) {
        this.isCurrentAccountSelected = true;
      }
      else{
        this.isCurrentAccountSelected = false;
      }
    }
  }

  setUser() {
    if (!this.isConfirmButtonClicked) {
      this.isCurrentAccountSelected = true;
      this.isConfirmButtonClicked = true;
      let userData = JSON.parse(this.localstorage.getItem('userData'));
      if (this.selectedUser != null && this.selectedUser != undefined && this.userIds.includes(this.selectedUser)) {
        // if (userData.account_pk != this.selectedUser.account_pk) {
          this.setselectedUser.emit(this.selectedUser);
          this.isConfirmButtonClicked = false;
        // }
        // else{
        //   this.toastrService.error('You have already Same Account ID Login.');
        //   this.isConfirmButtonClicked = false;
        // }
      }
      else {
        this.toastrService.error("Please select a user account");
        this.isConfirmButtonClicked = false;
      }
    }
    else {
      return;
    }
  }
}
