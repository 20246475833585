import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
// modules
import { SharedModule } from '../../shared/shared.module';
// components
import { FooterComponent } from './footer.component';
@NgModule({
    declarations: [
        FooterComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        SharedModule
    ],
    exports: [
        FooterComponent
    ]
})
export class FooterModule { }
