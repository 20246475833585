<div class="container-fluid colntainer-md pt-3 pb-3">
    <div class="row">

        <!-- <app-widgetfilters></app-widgetfilters> -->
        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-3 filter-are">
            <div class="card border-0">
                <div class="card-body p-0">
                    <div class="ltr-filtter">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">
                                <h3 class="fw-bold text-uppercase mb-0">Filter</h3>
                            </li>
                            <li class="list-group-item">
                                <input class="form-check-input me-1" type="checkbox" value="" aria-label="...">
                                Deals and Discounts
                            </li>
                            <li class="list-group-item">
                                <input class="form-check-input me-1" type="checkbox" value="" aria-label="...">
                                Same Day Delivery
                            </li>
                        </ul>
                    </div>
                    <div class="vc-filter filter-box">
                        <h3 class="fw-bold mb-0">Vehicle Category</h3>
                        <div class="list-check">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="buses">
                                <label class="form-check-label" for="buses">
                                    Buses
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="commercial">
                                <label class="form-check-label" for="commercial">
                                    Commercial
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="hmt">
                                <label class="form-check-label" for="hmt">
                                    Heavy & Medium Trucks
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="autoriksa">
                                <label class="form-check-label" for="autoriksa">
                                    Auto-Riksa
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="fleet-models filter-box">
                        <h3 class="fw-bold mb-0">My Fleet Models</h3>
                        <div class="list-check">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="allModels">
                                <label class="form-check-label" for="allModels">
                                    All Models
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="tata-prima">
                                <label class="form-check-label" for="commercial">
                                    Tata Prima
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="hmt">
                                <label class="form-check-label" for="tata-sigma">
                                    Tata Sigma
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="tata-ultra">
                                <label class="form-check-label" for="tata-ultra">
                                    Tata Ultra
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="EMission filter-box">
                        <h3 class="fw-bold mb-0">EMission Norms Compliance</h3>
                        <div class="list-check">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="bsi56">
                                <label class="form-check-label" for="bsi56">
                                    BSI(56)
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="bsi64">
                                <label class="form-check-label" for="bsi64">
                                    BSI(64)
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="bsi32">
                                <label class="form-check-label" for="bsi32">
                                    BSI(32)
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="bsi48">
                                <label class="form-check-label" for="bsi48">
                                    BSI(48)
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="part-car filter-box">
                        <h3 class="fw-bold mb-0">Part Category</h3>
                        <div class="filter-search-cat mb-3">
                            <input class="form-control" (change)="filterData($event)" type="search"
                                placeholder="Search Category" [(ngModel)]="searchPgLine" aria-label="input search_cat">
                        </div>
                        <div class="list-check">
                            <ng-container
                                *ngFor="let halfProduct of AllProductsList | filter: searchPgLine;let i=index;">
                                <ng-container *ngIf="!showMore && i<10">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="{{halfProduct.LINE_2_s}}"
                                            [disabled]="halfProduct.LINE_2_s==selectedPgLine"
                                            [checked]="halfProduct.LINE_2_s==selectedPgLine"
                                            (click)="pgLineSelected($event,halfProduct.LINE_2_s)">
                                        <label class="form-check-label" for="{{halfProduct.LINE_2_s}}">
                                            {{ halfProduct.LINE_2_s | uppercase }}
                                        </label>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="showMore">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="{{halfProduct.LINE_2_s}}"
                                            [disabled]="halfProduct.LINE_2_s==selectedPgLine"
                                            [checked]="halfProduct.LINE_2_s==selectedPgLine"
                                            (click)="pgLineSelected($event,halfProduct.LINE_2_s)">
                                        <label class="form-check-label" for="{{halfProduct.LINE_2_s}}">
                                            {{ halfProduct.LINE_2_s | uppercase }}
                                        </label>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <!-- <div class="form-check" *ngFor="let halfProduct of halfProducts;let i=index;">
                                <input class="form-check-input" type="checkbox" id="{{halfProduct.LINE_2_s}}"
                                [disabled]="halfProduct.LINE_2_s==selectedPgLine"
                                [checked]="halfProduct.LINE_2_s==selectedPgLine"
                                (click)="pgLineSelected($event,halfProduct.LINE_2_s)">
                                <label class="form-check-label" for="{{halfProduct.LINE_2_s}}">
                                    {{ halfProduct.LINE_2_s }}
                                </label>
                            </div> -->
                        </div>
                        <!-- <div class="list-check" *ngIf="showMore">
                            <div class="form-check" *ngFor="let AllProduct of AllProductsList;let i=index;">
                                <input class="form-check-input" type="checkbox" id="{{AllProduct.LINE_2_s}}"
                                [disabled]="AllProduct.LINE_2_s==selectedPgLine"
                                [checked]="AllProduct.LINE_2_s==selectedPgLine"
                                (click)="pgLineSelected($event,AllProduct.LINE_2_s)">
                                <label class="form-check-label" for="{{AllProduct.LINE_2_s}}">
                                    {{ AllProduct.LINE_2_s }}
                                </label>
                            </div>
                        </div> -->
                        <div class="view-more mt-3" *ngIf="!showMore && AllProductsList.length > 10">
                            <a (click)="viewMore()" style="cursor: pointer;">View More</a>
                        </div>
                        <div class="view-more mt-3" *ngIf="showMore">
                            <a (click)="viewMore()" style="cursor: pointer;">View Less</a>
                        </div>
                    </div>
                    <div class="brand filter-box">
                        <h3 class="fw-bold mb-0">Brand</h3>
                        <div class="list-check">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="bsi56">
                                <label class="form-check-label" for="bsi56">
                                    BSI(56)
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="bsi64">
                                <label class="form-check-label" for="bsi64">
                                    BSI(64)
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="bsi32">
                                <label class="form-check-label" for="bsi32">
                                    BSI(32)
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="bsi48">
                                <label class="form-check-label" for="bsi48">
                                    BSI(48)
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9 col-xxl-9 product-lists">
            <div class="d-flex mb-4 justify-content-between align-items-center">
                <div clas="prod-count col-12 col-sm-6">
                    Showing
                    <!-- <span>40</span>/ -->
                    <span>{{ productList.length}} </span> results for <span class="fw-bold">‘Filter’</span>
                </div>
                <div class=" col-6 col-sm-3 ms-auto">
                    <div class="sort-by row g-0">
                        <label for="sort-by-sel" class="col-sm-4 col-form-label text-end">Sort BY</label>
                        <div class="col-sm-7 ms-1">
                            <select class="form-select" name="sort-by-sel" id="sort-by-sel">
                                <option value="">Delivery Date</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="grid-flter col-2 col-sm-1 text-nowrap text-end">
                    <button class="btn btn-link p-0 me-1" (click)="GotoView('list')"><img src="./assets/images/list.svg"
                            alt="icon" srcset=""></button>
                    <button class="btn btn-link p-0 me-1" (click)="GotoView('Grid')"><img src="./assets/images/card.svg"
                            alt="icon" srcset=""></button>
                </div>
            </div>
            <!-- Change number of boxes show in a row -->
            <!-- <div class="row row-cols-1 row-cols-md-3 row-cols-lg-3 row-cols-xl-3 row-cols-xxl-3 g-4"> -->
            <!-- GRID -->
            <div class="row row-cols-1 row-cols-md-3 row-cols-lg-4 row-cols-xl-4 row-cols-xxl-4 g-4"
                *ngIf="layout == 'Grid'">
                <div class="col" *ngFor="let product of productList">
                    <app-product-list [product]="product" [layout]="layout"></app-product-list>
                </div>
            </div>
            <!-- LIST -->
            <div *ngIf="layout == 'list'">
                <app-product-list [productList]="productList" [layout]="layout"></app-product-list>
            </div>
        </div>
        <div *ngIf="productList && productList?.length=== 0" style="text-align: center; margin-top: -59rem;">
            <img src="./assets/images/empty box.png" style="margin-left: 16rem;">
            <p class="text-center" style="margin-left: 16rem;">No Record Found</p>

        </div>
        <div class="products-view__pagination">
            <br>
            <app-pagination formControlName="page" [siblings]="2" [total]="" (pageChange1)="filter($event)"
                [showPagination]="showPagination"></app-pagination>
        </div>
    </div>
</div>

<ng-container *ngIf="showLoader">
    <div class="loader">
        <div class="ng-spinner ng-spinner-icon"></div>
    </div>
</ng-container>