<app-otp *ngIf="isOtpGenerated" [registrationForm]="registrationForm" [guestLoginForm]="guestLoginForm" [isGuestLogin]="isGuestLogin" (resendOtp)="resendOnSubmitOTP()"
(enteredOtp)="setAndVerifyOtp($event)" [events]="eventsSubject.asObservable()" (nextClick)="verifyOTP()" [isTimer]="isTimer" (redirectToRegistration)="reEnterMobile(false)" (redirectToGuestLogin)="reEnterMobile(false)"></app-otp>
<section class="ng-sign p-sm-4 p-md-5" *ngIf="!isOtpGenerated && !showVehicleDetails" [ngClass]="(showHeader) ? 'clsmargin' : ''">
    <div class="d-flex justify-content-sm-between">
        <div class="sec-logo d-none d-sm-block">
            <div class="mat-aap">
                <h3 class="mb-0">AAP Ke liye</h3>
                <h4 class="mb-0">Apno ke liye</h4>
            </div>
            <div class="benfits bg-white">
                <h5 class="mb-0">Benefits :</h5>
                <p class="mb-0">Higher stability | Long life | Value for money</p>
            </div>
        </div>
        <mat-card [ngClass]="(isGuestLogin && !isForgottenUser) ? 'login-form-card p-5 guest-width' : (isGuestLogin && isForgottenUser) ? 'login-form-card forgottent-user-padding guest-width' : 'login-form-card p-5'">
            <mat-card-header [ngClass]="(isGuestLogin && isForgottenUser) ? 'justify-content-center remove-header-margin' : 'justify-content-center mb-4'">
                <mat-card-title class="mat-login-header">
                    <div class="login-header-img text-end">
                        <img  src="./assets/images/logo.png" class="img-fluid"/>
                    </div>
                    <h1>e- Dukaan</h1>
                </mat-card-title>
                <mat-card-subtitle class="mat-subtitle">{{(isGuestLogin && !isForgottenUser) ? 'Guest Login for eDukaan' : (isGuestLogin && isForgottenUser) ? 'Forgot your login credentials ?' : 'Sign up for eDukaan'}}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <form [formGroup]="registrationForm" name="registrationForm" class="form" *ngIf="!isGuestLogin">
                    <div class="form-group">
                        <mat-form-field appearance="standard" floatLabel="always" class="w-100">
                            <mat-label class="label-control">Mobile number</mat-label>
                            <input matInput autocomplete="off" placeholder="Enter mobile number" (keypress)="onlyNumberKey($event);" class="input-control"
                            formControlName="contact_no" maxlength="10" (keyup.enter)="onSubmitOTP()">
                            <mat-icon matSuffix style="cursor: pointer;">info</mat-icon>
                          </mat-form-field>
                    </div>
                    <!-- <div class="form-group">
                        <mat-form-field appearance="standard" floatLabel="always" class="w-100">
                            <mat-label class="label-control">Password</mat-label>
                            <input matInput [type]="hide ? 'password' : 'text'" class="input-control">
                            <mat-icon matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">{{hide ? 'visibility' :  'visibility_off'}}</mat-icon>
                          </mat-form-field>
                    </div>
                    <div class="form-group">
                        <mat-form-field appearance="standard" floatLabel="always" class="w-100">
                            <mat-label class="label-control">Confirm password</mat-label>
                            <input matInput [type]="hide ? 'password' : 'text'" class="input-control">
                            <mat-icon matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide confirm password'" [attr.aria-pressed]="hide">{{hide ? 'visibility' :  'visibility_off'}}</mat-icon>
                          </mat-form-field>
                    </div> -->
                    <div class="text-center mt-3">
                        <button type="button" class="btn btn-primary text-uppercase login-btn" (click)="onSubmitOTP()">SEND OTP</button>
                        <p class="mt-4 mb-0">If Already Registered ? <a class="text-primary text-decoration-none"  [routerLink]="['/account/login']" [queryParams]="{'isLogin':true}">Click here</a></p>
                        <p class="mt-4 mb-0">Go back to <a class="text-primary text-decoration-none" style="cursor: pointer;" (click)="goBackToHome();">Homepage</a></p>
                    </div>
                    <div class="fonr-group">
                        <p class="mt-2 mb-0" style="color: rgb(37, 150, 190)">Login made Easy - You may now use your Fleet Edge mobile number to login to eDukaan !</p>
                    </div>
                </form>
                <form [formGroup]="guestLoginForm" *ngIf="isGuestLogin">
                  <p class="mat-subtitle" *ngIf="!isForgottenUser" style="font-size: 18px;font-weight: 600;">If you are already registered with TATA Motors and don't remember the details,<br>
                    <a class="text-primary text-decoration-none" style="cursor: pointer;" (click)="goBackToGuestLogin(true)">click here</a>
                    to search your account.
                  </p>
                  <p class="mat-subtitle remove-margin" *ngIf="isForgottenUser">
                    You can search your existing account using any of the following option
                  </p>
                  <div class="row" *ngIf="isForgottenUser">
                    <div class="col-md-5">
                      <mat-form-field id="mat-select-seearch-form" appearance="fill">
                        <mat-label class="label-control">Search Option</mat-label>
                        <mat-select (selectionChange)="getSearchParameter($event)">
                          <mat-option value="chassis_no">Chassis Number</mat-option>
                          <mat-option value="registration_no">Registration No</mat-option>
                          <mat-option value="arn">ARN No</mat-option>
                          <mat-option value="crn">CRN No</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field appearance="standard" floatLabel="always" class="w-100">
                        <!-- <mat-label class="label-control">Chassis Number</mat-label> -->
                        <input autocomplete="off" matInput [placeholder]="phoneNoSearchParam" [(ngModel)]="stringParameters" [ngModelOptions]="{standalone: true}"
                          class="input-control" maxlength="20">
                      </mat-form-field>
                    </div>
                    <div class="col-md-3">
                      <button type="button" class="btn btn-primary" style="margin-top: 1.5rem;" (click)="checkForRegisteredUser(stringParameters);">CHECK</button>
                    </div>
                    <div *ngIf="relevantMobileNumbers.mobile_number1 || relevantMobileNumbers.mobile_number2" class="col-md-12 relevantMobileNumber">
                      The mobile number associated with this account is
                      <ng-container *ngIf="relevantMobileNumbers.mobile_number1; else elseMobileNumber2">
                        {{ relevantMobileNumbers.mobile_number1[0]+''+relevantMobileNumbers.mobile_number1[1]+'XXXXXX'+relevantMobileNumbers.mobile_number1[8]+''+relevantMobileNumbers.mobile_number1[9] }},Please 
                        <a class="text-primary text-decoration-none" style="cursor: pointer;" (click)="registerDirectWithNumber(relevantMobileNumbers.mobile_number1)">click here </a> 
                      </ng-container>
                      <ng-template #elseMobileNumber2>
                        {{ relevantMobileNumbers.mobile_number2[0]+''+relevantMobileNumbers.mobile_number2[1]+'XXXXXX'+relevantMobileNumbers.mobile_number2[8]+''+relevantMobileNumbers.mobile_number2[9] }},Please
                        <a class="text-primary text-decoration-none" style="cursor: pointer;" (click)="registerDirectWithNumber(relevantMobileNumbers.mobile_number2)">click here </a> 
                      </ng-template>
                      to register with the mobile number.
                    </div>
                    <div *ngIf="relevantMobileNumbers.mobile_number1 || relevantMobileNumbers.mobile_number2" class="col-md-12 relevantMobileNumber orText">
                      OR
                    </div>
                    <div *ngIf="relevantMobileNumbers.mobile_number1 || relevantMobileNumbers.mobile_number2" class="col-md-12 relevantMobileNumber">
                      <p>To change the mobile number please contact CRMDMS team at email: crmdms@tatamotors.com or call: 020 6713 6800</p>
                    </div>
                    <!-- <div class="col-md-4" id="chassisNum">
                        <mat-checkbox [color]="'primary'" (change)="changeInParameters('isChassis')" class="label-control">Chassis Number</mat-checkbox>
                    </div>
                    <div class="col-md-4" *ngIf="!searchByParameters.isChassis"></div>
                    <div class="col-md-4" *ngIf="searchByParameters.isChassis">
                      <mat-form-field appearance="standard" floatLabel="always" class="w-100">
                        <mat-label class="label-control">Chassis Number</mat-label>
                        <input autocomplete="off" matInput placeholder="Enter Chassis Numebr" [(ngModel)]="stringParameters.isChassis" [ngModelOptions]="{standalone: true}"
                          class="input-control" maxlength="20">
                      </mat-form-field>
                    </div>
                    <div class="col-md-4"></div>
                    <div class="col-md-4" id="chassisNum">
                      <mat-checkbox [color]="'primary'" class="label-control" (change)="changeInParameters('isReg')">Registration No.</mat-checkbox>
                    </div>
                    <div class="col-md-4" *ngIf="!searchByParameters.isReg"></div>
                    <div class="col-md-4" *ngIf="searchByParameters?.isReg">
                      <mat-form-field appearance="standard" floatLabel="always" class="w-100">
                        <mat-label class="label-control">Registration No.</mat-label>
                        <input autocomplete="off" matInput placeholder="Enter Registration No." [(ngModel)]="stringParameters.isReg" [ngModelOptions]="{standalone: true}"
                          class="input-control" maxlength="20">
                      </mat-form-field>
                    </div>
                    <div class="col-md-4"></div>
                    <div class="col-md-4" id="chassisNum">
                        <mat-checkbox [color]="'primary'" class="label-control" (change)="changeInParameters('isARN')">ARN No.</mat-checkbox>
                    </div>
                    <div class="col-md-4" *ngIf="!searchByParameters.isARN"></div>
                    <div class="col-md-4" *ngIf="searchByParameters.isARN">
                      <mat-form-field appearance="standard" floatLabel="always" class="w-100">
                        <mat-label class="label-control">ARN No.</mat-label>
                        <input autocomplete="off" matInput placeholder="Enter ARN No." [(ngModel)]="stringParameters.isARN" [ngModelOptions]="{standalone: true}"
                          class="input-control" maxlength="20">
                      </mat-form-field>
                    </div>
                    <div class="col-md-4"></div>
                    <div class="col-md-4" id="chassisNum">
                      <mat-checkbox [color]="'primary'" class="label-control" (change)="changeInParameters('isCRN')">CRN No.</mat-checkbox>
                    </div>
                    <div class="col-md-4" *ngIf="!searchByParameters.isCRN"></div>
                    <div class="col-md-4" *ngIf="searchByParameters.isCRN">
                      <mat-form-field appearance="standard" floatLabel="always" class="w-100">
                        <mat-label class="label-control">CRN No.</mat-label>
                        <input autocomplete="off" matInput placeholder="Enter CRN No." [(ngModel)]="stringParameters.isCRN" [ngModelOptions]="{standalone: true}"
                          class="input-control" maxlength="20">
                      </mat-form-field>
                    </div>
                    <div class="col-md-4"></div> -->
                  </div>
                  <div class="row" *ngIf="!isForgottenUser">
                    <div class="col-md-4">
                      <mat-form-field appearance="standard" floatLabel="always" class="w-100">
                        <mat-label class="label-control">Name</mat-label>
                        <input autocomplete="off" matInput placeholder="Enter Name" (keypress)="alphanumbericOnly($event);"
                          class="input-control" formControlName="name" maxlength="20">
                          <mat-error *ngIf="guestLoginForm.controls.name.errors?.required">Name is required</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field appearance="standard" floatLabel="always" class="w-100">
                        <mat-label class="label-control">Mobile Number</mat-label>
                        <input autocomplete="off" matInput placeholder="Enter Mobile" (keypress)="onlyNumberKey($event);"
                          class="input-control" formControlName="contact_no" maxlength="10">
                          <mat-error *ngIf="guestLoginForm.controls.contact_no.errors?.required">Mobile Number is required</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field appearance="standard" floatLabel="always" class="w-100">
                        <mat-label class="label-control">Address Line 1</mat-label>
                        <input autocomplete="off" matInput placeholder="Enter Address Line 1"
                          class="input-control" formControlName="street_address" maxlength="60">
                          <mat-error *ngIf="guestLoginForm.controls.street_address.errors?.required">Address Line 1 is required</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field appearance="standard" floatLabel="always" class="w-100">
                        <mat-label class="label-control">Address Line 2</mat-label>
                        <input autocomplete="öff" matInput placeholder="Enter Address Line 2"
                          class="input-control" formControlName="street_address2" maxlength="100">
                          <mat-error *ngIf="guestLoginForm.controls.street_address2.errors?.required">Address Line 2 is required</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <ng-container *ngIf="pincodeList.length > 1;else elseOneOrLessPincode">
                        <mat-form-field appearance="standard" floatLabel="always" class="w-100">
                          <mat-label class="label-control">Search By Pincode</mat-label>
                          <mat-select formControlName="postal_code" [name]="'postal_code'" [placeholder]="'Select Pin Code'" [disableOptionCentering]="true">
                            <mat-option *ngFor="let postal_code of pincodeList" [value]="postal_code">{{postal_code | titlecase}}</mat-option>
                          </mat-select>
                          <mat-error *ngIf="guestLoginForm.controls.postal_code.errors?.required">Pin Code is required</mat-error>
                        </mat-form-field>
                      </ng-container>
                      <ng-template #elseOneOrLessPincode>
                        <mat-form-field appearance="standard" floatLabel="always" class="w-100">
                          <mat-label class="label-control">Search By Pincode</mat-label>
                          <input autocomplete="öff" matInput placeholder="Enter Pincode" (keypress)="onlyNumberKey($event);"
                          (keyup.enter)="getDataBasedOnPincode()"
                            class="input-control" formControlName="postal_code" maxlength="6">
                            <mat-icon style="cursor: pointer;" matSuffix (click)="getDataBasedOnPincode()">search</mat-icon>
                            <mat-error *ngIf="guestLoginForm.controls.postal_code.errors?.required">Pin Code is required</mat-error>
                        </mat-form-field>
                      </ng-template>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field appearance="standard" floatLabel="always" class="w-100">
                        <mat-label class="label-control">State</mat-label>
                        <mat-select formControlName="state" [name]="'state'" [placeholder]="'Select State'" (selectionChange)="getDistricts($event)" [disableOptionCentering]="true">
                          <mat-option *ngFor="let state of stateList" [value]="state?.code">{{state.name}}</mat-option>
                        </mat-select>
                          <mat-error *ngIf="guestLoginForm.controls.state.errors?.required">State is required</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field appearance="standard" floatLabel="always" class="w-100">
                        <mat-label class="label-control">Select District</mat-label>
                        <mat-select formControlName="district" [name]="'district'" [placeholder]="'Select District'" (selectionChange)="getCities($event)" disableOptionCentering>
                          <mat-option *ngFor="let district of districtList" [value]="district">{{district}}</mat-option>
                        </mat-select>
                          <mat-error *ngIf="guestLoginForm.controls.district.errors?.required">District is required</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field appearance="standard" floatLabel="always" class="w-100">
                        <mat-label class="label-control">City</mat-label>
                        <mat-select formControlName="city" [name]="'city'" [placeholder]="'Select City'" (selectionChange)="getTalukas($event)"[disableOptionCentering]="true">
                          <mat-option *ngFor="let city of cityList" [value]="city">{{city | titlecase}}</mat-option>
                        </mat-select>
                          <mat-error *ngIf="guestLoginForm.controls.city.errors?.required">City is required</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field appearance="standard" floatLabel="always" class="w-100">
                        <mat-label class="label-control">Taluka</mat-label>
                        <mat-select formControlName="taluka" [name]="'taluka'" [placeholder]="'Select Taluka'" (selectionChange)="getPincodes($event)" [disableOptionCentering]="true">
                          <mat-option *ngFor="let taluka of talukaList" [value]="taluka">{{taluka | titlecase}}</mat-option>
                        </mat-select>
                          <!-- <mat-error *ngIf="guestLoginForm.controls.taluka.errors?.required">Taluka is required</mat-error> -->
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="text-center mt-3" *ngIf="!isForgottenUser">
                    <button type="button" class="btn btn-primary text-uppercase login-btn" (click)="sendOtpForGuestLogin();">SEND
                      OTP</button>
                    <p class="mt-4 mb-0">Go back to <a class="text-primary text-decoration-none" style="cursor: pointer;"
                        (click)="goBackToHome();">Homepage</a></p>
                  </div>
                  <div class="text-center" *ngIf="isForgottenUser">
                    <p class="mt-2 mb-0">Register as a<a class="text-primary text-decoration-none" style="cursor: pointer;"
                      (click)="goBackToGuestLogin(false)"> Guest User</a></p>
                    <p class="mt-2 mb-2">Go back to <a class="text-primary text-decoration-none" style="cursor: pointer;"
                        (click)="goBackToHome();">Homepage</a></p>
                  </div>
                </form>
            </mat-card-content>
        </mat-card>

    </div>
</section>

<div class="loader" *ngIf="showLoader">
  <div class="ng-spinner ng-spinner-icon"></div>
</div>

<ng-template #selectUser let-d="dismiss">
    <select-user (closeUserPopUp)="closeUserPopUp()" [btndisabled]="btndisabled" [userIds]="userIds"
    (setselectedUser)="setselectedUser($event)"></select-user>
</ng-template>

<ng-template #selectUserType let-d="dismiss">
    <select-user-type (bindUserType)="bindUserType($event)" [userTypes]="keys"></select-user-type>
</ng-template>


<app-fleet-details *ngIf="showVehicleDetails" [userData]="userData" [account_pk]="account_pk" [registrationForm]="registrationForm" (closeVehicleDetails)="GetAddress()"></app-fleet-details>
