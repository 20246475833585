<div class="modal-header" *ngIf="isLogout">
  <h4 class="modal-title">Are you sure you want to Logout?</h4>
</div>
<div class="modal-footer" *ngIf="isLogout">
  <button type="button" class="btn btn-primary" (click)="UserLogout()">
    &nbsp;Yes</button>
  <button type="button" class="btn btn-primary" (click)="closeModal()">
    &nbsp;No</button>
</div>

<div class="modal-header" *ngIf="clearAllCartPermission">
  <h4 class="modal-title">Are you sure you want to clear all cart ?</h4>
</div>
<div class="modal-footer" *ngIf="clearAllCartPermission">
  <button type="button" class="btn btn-primary" (click)="closeModal('Yes')">
    &nbsp;Yes</button>
  <button type="button" class="btn btn-primary" (click)="closeModal('No')">
    &nbsp;No</button>
</div>

<div class="modal-header" *ngIf="clearAllWishlist">
  <h4 class="modal-title">Are you sure you want to clear all the wishlisted items?</h4>
</div>
<div class="modal-footer" *ngIf="clearAllWishlist">
  <button type="button" class="btn btn-primary" (click)="closeModal('Yes')">
    &nbsp;Yes</button>
  <button type="button" class="btn btn-primary" (click)="closeModal('No')">
    &nbsp;No</button>
</div>

<div class="modal-header" *ngIf="deletedItems">
  
  <div >
    
  <h4 class="modal-title" style="
  padding-bottom: 20px;
  text-align: center;">Items not added to cart</h4>
    <table class="table datatable display"  *ngIf="this.Part_not_added_with_reason">
       <thead>
           <tr>
               <th class="text-nowrap">Sr.No.</th>
               <th class="text-nowrap" style="width: 150px; text-align: center;">Parts</th>
               <th class="text-nowrap" style="width: 250px; text-align: center;">Reason</th>
           </tr>
       </thead>
       <tbody>
           <tr *ngFor="let part of Part_not_added_with_reason let i = index;">
               <td>{{i+1}}</td>
               <td style="text-align: center;">
                <div class=" d-inline-flex">
                    {{part.part_number}}
                </div>
                
            </td>
            <td style="text-align: center;">
              <div>
                {{part.reason}}
              </div>
            </td>
            </tr>
            </tbody>
            </table>
              
  </div>
</div>
<div class="modal-footer" *ngIf="deletedItems">
  
  <button type="button" class="btn btn-primary" (click)="closeModal('No')">
    &nbsp;OK</button>
</div>

<div class="modal-header" *ngIf="orderCancel">
  <h4 class="modal-title">Are you sure you want to cancel order ?</h4>
</div>
<div class="modal-footer" *ngIf="orderCancel">
  <button type="button" class="btn btn-primary" (click)="UserLogout()">
    &nbsp;Yes</button>
  <button type="button" class="btn btn-primary" (click)="closeModal()">
    &nbsp;No</button>
</div>

<div class="modal-header" *ngIf="isCashDiscountPermission">
  <h5 class="modal-title" *ngIf="!showWarningForCashDiscount">As you have selected online payment method, Cash Discount is applied. Your payment details will be updated.</h5>
  <h5 class="modal-title" *ngIf="showWarningForCashDiscount">Are you sure you want to change the payment method ?</h5><br>
  <p ></p>
</div>
<div class="modal-body" *ngIf="showWarningForCashDiscount && isUserType?.user_type != 'MECH'">
    <span class="error-message" >Note: </span><span style="color: blue;">If you select payment method other than online payment, Cash discount will not be applicable</span>
</div>
<div class="modal-footer" *ngIf="isCashDiscountPermission">
  <button type="button" class="btn btn-primary" (click)="closeModal('Yes')" *ngIf="showWarningForCashDiscount">
    &nbsp;Yes</button>
  <button type="button" class="btn btn-primary" (click)="closeModal('No')" *ngIf="showWarningForCashDiscount">
    &nbsp;No</button>
  <button type="button" class="btn btn-primary" (click)="closeModal('Okay')" *ngIf="!showWarningForCashDiscount">
    &nbsp;Okay</button>
</div>

<div class="modal-header" *ngIf="isPONO">
  <h6 class="modal-title" style="font-size: 16px;
    font-weight: 500">Would you like to assign this <b style="font-size: 14px;">PO No. {{ Po_No }}</b> to the parts
    already present in cart?<br> If Yes, click on Continue, otherwise click on View Cart and remove parts from cart.
  </h6>
</div>
<div class="modal-footer" *ngIf="isPONO">
  <button type="button" class="btn btn-primary" (click)="closeModal('Yes')">
    &nbsp;Continue</button>
  <button type="button" class="btn btn-primary" (click)="closeModal('ViewCart')">
    &nbsp;View Cart</button>
</div>


<div class="modal-header" *ngIf="isVehicleDelete">
  <h4 class="modal-title">Are you sure you want to delete the vehicle ?</h4>
</div>
<div class="modal-footer" *ngIf="isVehicleDelete">
  <button type="button" class="btn btn-primary" (click)="UserLogout()">
    &nbsp;Yes</button>
  <button type="button" class="btn btn-primary" (click)="closeModal()">
    &nbsp;No</button>
</div>

<div class="modal-header" *ngIf="isUserDelete">
  <h4 class="modal-title">Are you sure you want to delete ?</h4>
</div>
<div class="modal-footer" *ngIf="isUserDelete">
  <button type="button" class="btn btn-primary" (click)="UserLogout()">
    &nbsp;Yes</button>
  <button type="button" class="btn btn-primary" (click)="closeModal()">
    &nbsp;No</button>
</div>

<div class="modal-header" *ngIf="isContactPersonUpdate.contactUpdat">
  <h5 class="modal-title" *ngIf="isContactPersonUpdate.contactPersonName && isContactPersonUpdate.contactPersonName!='' else elseNoNameContactPerson">Are you sure you want to <span style="font-weight: 800;">{{ isContactPersonUpdate.contactPersonName }}</span> as the contact person ?</h5>
  <ng-template #elseNoNameContactPerson>
    <h5 class="modal-title">Are you sure you want to select this contact person ?</h5>

  </ng-template>
  
</div>
<div class="modal-footer" *ngIf="isContactPersonUpdate.contactUpdat">
  <button type="button" class="btn btn-primary" (click)="UserLogout()">
    &nbsp;Yes</button>
  <button type="button" class="btn btn-primary" (click)="closeModal()">
    &nbsp;No</button>
</div>


<div class="modal-header model-header-contact-add" *ngIf="isContactPersonRemove">
    <h5 class="modal-title">
      <span class="error-message">**</span> Contacts removed will be visible in removed contact list</h5>
</div>
<div class="modal-body" *ngIf="isContactPersonRemove">
  <h5>
    Are you sure you want to remove contact?
  </h5>
</div>
<div class="modal-footer" *ngIf="isContactPersonRemove">
  <button type="button" class="btn btn-primary" (click)="UserLogout()">
    &nbsp;Yes</button>
  <button type="button" class="btn btn-primary" (click)="closeModal()">
    &nbsp;No</button>
</div>


<div class="modal-header model-header-contact-add" *ngIf="isContactPersonAdd">
  <h5 class="modal-title">
    The selected delivery address does not have contact person associate with it. 
  </h5>
</div>
<div class="modal-body" *ngIf="isContactPersonAdd">
  <h5>
    Do you like to add one ?
  </h5>
</div>
<div class="modal-footer" *ngIf="isContactPersonAdd">
<button type="button" class="btn btn-primary" (click)="UserLogout()">
  &nbsp;Yes</button>
<button type="button" class="btn btn-primary" (click)="closeModal()">
  &nbsp;No</button>
</div>


<div class="modal-header" *ngIf="returnmoqcheck">
  <div >
  <h4 class="modal-title" style="
  padding-bottom: 20px;
  text-align: center;">Incase of pack parts, for partial return the return quantity should be as per MOQ.</h4>
    <table class="table datatable display"  *ngIf="this.Part_not_added_with_reason">
       <thead>
           <tr>
               <th class="text-nowrap">Sr.No.</th>
               <th class="text-nowrap" style="width: 150px; text-align: center;">Parts</th>
               <th class="text-nowrap" style="width: 250px; text-align: center;">Ordered Pack Of</th>
               <th class="text-nowrap" style="width: 250px; text-align: center;">New Pack Of</th>
           </tr>
       </thead>
       <tbody>
           <tr *ngFor="let part of Part_not_added_with_reason let i = index;">
               <td>{{i+1}}</td>
               <td style="text-align: center;">
                <div class=" d-inline-flex">
                    {{part.part_number}}
                </div>
            </td>
            <td style="text-align: center;">
              <div>
                {{part.ordered_moq}}
              </div>
            </td>
            <td style="text-align: center;">
              <div>
                {{part.moq}}
              </div>
            </td>
            </tr>
            </tbody>
            </table>            
  </div>
</div>
<div class="modal-footer" *ngIf="returnmoqcheck">
  <button type="button" class="btn btn-primary" (click)="closeModal()">
    &nbsp;OK</button>
</div>

<div class="modal-header" *ngIf="isFitment">
  Fitment Segment Status
  <div (click)="closeModal()" type="button"><i class="fa fa-times"></i></div>
</div>
<div *ngIf="isFitment">
  <table class="table track_tbl table-borderless">
    <!-- <thead>
        <tr>
            <th></th>
            <th>Status</th>
        </tr>
    </thead> -->
    <tbody>
      <tr *ngFor="let item of fitmentData let i = index;">
        <td class="track_dot p-2">
          <span class="track_line"></span>
          <div *ngIf="item.checked">
            <i class="fa fa-circle" style="font-size:24px; color: rgb(15, 135, 4);"></i>
          </div>
          <div *ngIf="!item.checked">
            <i class="fa fa-circle" style="font-size:24px; color: rgba(108, 126, 107, 0.89);"></i> </div>         
      </td>
        <td>
         <div class=" d-inline-flex">
             {{item.status}} <span class="datestyle">{{item.updated_at | date: 'dd-MM-yyyy h:mm:ss a'}}</span>
             <!-- <span>{{ item.checked ? (item.updated_at | date: 'dd-MM-yyyy h:mm:ss a') : '-'}}</span> -->
         </div>
     </td>
     </tr>
        <!-- <tr *ngFor="let item of statusdata">
            <td class="track_dot">
                <span class="track_line"></span>
                <i class="fa fa-circle" style="font-size:24px"></i>
            </td>
            <td>{{item.status}}</td>
            <td>{{item.date}}</td>
        </tr> -->
    </tbody>
</table>
  <!-- <table class="table datatable display"  >
    <thead>
        <tr>
            <th class="text-nowrap"></th>
            <th class="text-nowrap" style="width: 250px; text-align: center;">Status</th>
            <th class="text-nowrap" style="width: 250px; text-align: center;">Date</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of fitmentData let i = index;">
            <td>
              <div class="circle-done" *ngIf="item.checked"></div>
              <div class="circle-disable" *ngIf="!item.checked"> </div>
            </td>
            <td >
             <div class=" d-inline-flex">
                 {{item.status}}
             </div>
         </td>
         <td >
           <div >
            {{ item.checked ? (item.updated_at | date: 'dd-MM-yyyy h:mm:ss a') : '-'}}
           </div>
         </td>
         </tr>
         </tbody>
         </table>     -->
</div>