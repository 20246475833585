<div id="overlay">
    <div [class]="coachMrksData.index == 1?'row overlay-element':coachMrksData.beforePosition=='top'?'row overlay-elements overlay-elements-top':coachMrksData.beforePosition=='left'?'row overlay-elements overlay-elements-left':'row overlay-elements overlay-elements-right'" [style.top.%]="coachMrksData.top" [style.left.%]="coachMrksData.left">
        <i *ngIf="displayIndex>1" (click)="goToNextCoachMark('back')" class="fa fa-solid fa-arrow-left back_txt" data-toggle="tooltip" data-placement="top" title="Go to Back"></i> 
        <div class="col-12 mb-2 text-center">
           <h5 class="heading-txt">{{coachMrksData.heading}}</h5>
        </div>
        <div *ngIf="coachMrksData.imgUrl" class="col-12 d-flex justify-content-center">
            <img width="90px" [src]="coachMrksData.imgUrl" alt="tour_image" class="mb-3">
        </div>
        <div class="col-12 mb-3">
            <span class="info_txt" [innerHtml]="coachMrksData.infoText"></span>
        </div>
        <!-- <div class="col-12 mb-3">
            <span class="info_txt" style="margin-top: 10px;" [innerHtml]="coachMrksData.infoTextsecond"></span>
        </div> -->
        <div class="col-6">
    <!-- <h6 class="skip_tour_txt" *ngIf="displayIndex != (this.allElements.length -1)"  (click)="skipCoachMarks()">Skip Tour</h6>  -->
    <!--for slab-->
    <h6 class="skip_tour_txt"   (click)="skipCoachMarks()">Close</h6>   
    <!--for slab--> 
        </div>
        <!-- <div class="col-6">
            <h6 class="next_txt" (click)="goToNextCoachMark()">{{ displayIndex==0?'Take a tour':displayIndex>0 && displayIndex!=(allElements.length - 1) ?'Next':'Done' }}</h6>
        </div> -->
    </div>
</div>
