<div class="modal-header modal-title">
  {{ videoList[0].description }}
  <div (click)="closeVideoPlayerModal()" type="button"><i class="fa fa-times"></i></div>
</div>
<div class="modal-body">
    <div class="video">
        <video controls autoplay id="banner-video" itemid="banner-video" style="width: 100%;">
          <source src="{{videoList[0].banner_url}}" type="video/mp4" />
        </video>
      </div>
</div>