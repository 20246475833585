<div class="modal-content">
    <div class="modal-header modal-title">
        Child Part Details
        <div (click)="closeModal()" type="button"><i class="fa fa-times"></i></div>
    </div>

    <!-- dataTables_info styling
    text-align: center;
    width: 100%;
    margin-bottom: 10px; -->

    <div class="modal-body">
        <div class="row">
            <div class="table-responsive">
                <table  class="table datatable display"
            datatable
            [dtOptions]="dtOptions"
             [dtTrigger]="dtTrigger" >
                    <thead>
                        <tr>
                            <th>Image</th>
                            <th>Part No. & Part Description</th>
                            <th>Quantity</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let objpart of childPartData">
                            <td class="order-list__column-image">
                                <a>
                                    <img [src]="objpart?.multi_img[0].img" alt="">
                                </a>
                            </td>
                            <td>
                                {{objpart?.COMPONENT_s}}
                                <br>{{objpart?.PART_DESC_s}}
                            </td>
                            <td>{{objpart?.QUANTITY_s | number}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>