import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: 'credit-details',
  templateUrl: './credit-details.component.html',
  styleUrls: ['./credit-details.component.scss']
})

export class CreditDetailsComponent implements OnInit {
  @Input() creditDetails: any;
  @Output() closePopUp = new EventEmitter<any>();
  availableLimit: number;
  availableLimitExpiry: Date;
  creditsUsed: number;
  creditDetailsList: any[] = [];
  constructor(){}

  ngOnInit(): void {
    this.availableLimit = this.creditDetails.user_credit_limit - (this.creditDetails.pending_invoice_amount + this.creditDetails.pending_order_amount);
    // this.availableLimitExpiry = new Date();
    // this.availableLimitExpiry.setDate(this.availableLimitExpiry.getDate() + this.creditDetails.user_credit_days);
    this.creditsUsed = this.creditDetails.user_credit_limit - this.availableLimit;
    this.creditDetails.availableLimit = this.availableLimit;
    // this.creditDetails.availableLimitExpiry = this.availableLimitExpiry;
    this.creditDetails.creditsUsed = this.creditsUsed;
    this.creditDetailsList = [this.creditDetails];
  }

  closeModal(){
    this.closePopUp.emit();
  }
}
