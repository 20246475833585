import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../shared/services/REST/user.service';
import { secureStorage } from '../shared/services/securestorage';
import { ToastrService } from 'ngx-toastr';
import { AppStateService } from '../shared/services/app-state/app-state.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-edukaan-notification',
  templateUrl: './edukaan-notification.component.html',
  styleUrls: ['./edukaan-notification.component.scss']
})
export class EdukaanNotificationComponent implements OnInit {
  @ViewChild('videoPlayer') videoplayer: ElementRef;
  isReadMore = true;
  status: boolean = false;
  activeVal: any = 'TML';
  timelineData: any[] = [];
  tmlCount: number = 0;
  inboxCount: number = 0;
  otherCount: number = 0;
  isApiLoaded: boolean = false;
  showLoader: boolean = false;
  isPlaying: boolean = false;
  errMessage: string = "";
  offsetTImeline:number = 0;
  dataNotFound:boolean = false;

  @ViewChild('topContainer', { read: ElementRef }) public topContainer: ElementRef<any> = null;

  constructor(
    private router: Router,
    private toasterService: ToastrService,
    private userService: UserService,
    private localstorage: secureStorage,
    private title:Title,
    private appStateService: AppStateService
  ) { }

  ngOnInit(): void {
    // this.appStateService.refreshTimelineList.next(null);
    this.offsetTImeline = 0;
    this.updateTab(this.activeVal, 10, this.offsetTImeline);
    this.title.setTitle('E-Dukaan | Notification');
    this.appStateService.refreshTimelineData.subscribe((val) => {
      if (val)
        this.updateTab(val, 10, this.offsetTImeline);
    });
  }

  showText(val, index) {
    if (val == 'less') {
      document.getElementById("notification-text" + index).classList.add('limitTextHeight');
      document.getElementById("read-less" + index).classList.add('hideReadLess');
      document.getElementById("read-more" + index).classList.remove('hideReadLess')
    } else if (val == 'more') {
      document.getElementById("notification-text" + index).classList.remove('limitTextHeight');
      document.getElementById("read-less" + index).classList.remove('hideReadLess');
      document.getElementById("read-more" + index).classList.add('hideReadLess')
    }
    this.isReadMore = !this.isReadMore
  }

  clickEvent() {
    this.status = !this.status;
  }

  updateTab(val, limit:number, offset:number) {
    try {
      let address = JSON.parse(this.localstorage.getItem('address'));
      this.showLoader = true;
      this.isApiLoaded = false;
      this.dataNotFound = false;
      // this.topContainer.nativeElement.scrollTop = this.topContainer.nativeElement.scrollHeight;
      if(this.topContainer){
        this.topContainer.nativeElement.scrollTop = 0;
      }
      if(val != this.activeVal ){
        this.timelineData = [];
        this.activeVal = val;
      }
      let user = JSON.parse(this.localstorage.getItem('userData'));
      if (user) {
        let request = {
          "account_pk": parseInt(user.account_pk),
          "action_type": val,
          "shipping_address_id": address.address['default'] ? "default" : address?.address['address_id'],
          "limit":limit,
          "offset":offset
        }
        this.userService.getEdukaanTimeline(request).subscribe(
          res => {
            this.showLoader = false;
            this.isApiLoaded = true;
            if (res.success) {
              this.tmlCount = res.data.timeline_stats.TML_count;
              this.inboxCount = res.data.timeline_stats.Inbox_count;
              this.otherCount = res.data.timeline_stats.DLR_count;
              if(res.data.timeline_data.length > 0 ){
                res.data.timeline_data.forEach(element => {
                  element.extention = element.image_url.split(/[#?]/)[0].split('.').pop().trim();
                })
                this.timelineData = res.data.timeline_data;
                this.timelineData.forEach(element => {
                  element.spaceInString = (/\s/).test(element.post) == false?false:true;
                });
              }
            } else {
              this.tmlCount = res.data.timeline_stats.TML_count;
              this.inboxCount = res.data.timeline_stats.Inbox_count;
              this.otherCount = res.data.timeline_stats.DLR_count;
              this.timelineData = [];
              this.errMessage = res.data.msg;
              this.toasterService.error(res.data.msg);
            }
          }, err => {
            this.showLoader = false;
            this.isApiLoaded = true;
            this.timelineData = [];
            this.toasterService.error(err.error.data.msg);
          }
        )
      }
    } catch (error) {
      this.userService.InsertExceptionLog({ "method_name": "updateTab(tab)", "page_name": "EdukaanNotificationComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
    }
  }

  async onScrollLoadNotification(val, offset, limit){
    try{
      let user = JSON.parse(this.localstorage.getItem('userData'));
      let address = JSON.parse(this.localstorage.getItem('address'));
      let data:any = {};
      if (user) {
        let request = {
          "account_pk": parseInt(user.account_pk),
          "action_type": val,
          "shipping_address_id": address.address['default'] ? "default" : address?.address['address_id'],
          "limit":limit,
          "offset":offset
        }
        data = await this.userService.getEdukaanTimelineAsync(request);
        if (data['success'] === true) {
          data.data.timeline_data.forEach(element => {
            element.extention = element.image_url.split(/[#?]/)[0].split('.').pop().trim();
            element.spaceInString = (/\s/).test(element.post) == false?false:true;
          })
          this.timelineData.push(...data.data.timeline_data);
        }else{
          this.dataNotFound = true;
        }  
      }
    }catch(error){
      this.dataNotFound = true;
    }
    
  }

  downloadTimelineFile(url:string){
    window.location.href = url;
    // return url;
  }

  isEmptyObject(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }

  toggleVideo(index) {
    let audioPlayer = <HTMLVideoElement>document.getElementById('videoPlay' + index);
    if (audioPlayer.paused) {
      audioPlayer.play();
    } else {
      audioPlayer.pause();
    }
  }

  onScroll(event: any) {
    // visible height + pixel scrolled >= total height 
    this.offsetTImeline == 0 ? console.log(event.target.scrollTop):'';
    
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !this.dataNotFound) {
      this.offsetTImeline = this.offsetTImeline + 10;
      this.onScrollLoadNotification(this.activeVal, this.offsetTImeline, 10)
    }
}

}
