import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Injectable,Inject } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { applicationConfiguration, AppConfig } from 'src/app/config/app.config';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Injectable({
  providedIn: 'root'
})
export class ExcelCreatorService {
  
  protected server = this.appConfig.apiUrl + 'api/v1/ecom/';
  constructor(
    private http: HttpClient,
    @Inject(applicationConfiguration) private appConfig: AppConfig,) { }
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
  
    
   // worksheet['!cols'].push({ width: 20 })

   var wscols = [
    {wch:30},
    {wch:30},
    {wch:50},
    {wch:30},
    {wch:50},
    {wch:30},
    {wch:10},
    {wch:10},
    {wch:20},
    {wch:20},
    {wch:30}
    
];

worksheet['!cols'] = wscols;

 
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportAsExcelFileVehicleStatus(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
   
   // worksheet['!cols'].push({ width: 20 })

   var wscols = [
    {wch:30},
    {wch:30},
    {wch:50},
    {wch:15},
    {wch:10},
    {wch:50},
    {wch:10},
    {wch:50},
    {wch:20},
    {wch:20},
    {wch:20},

    
    
];

worksheet['!cols'] = wscols;


 
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
     const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
     FileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + EXCEL_EXTENSION);
  }
//   public downloadPdf(dataObj){
//   //   const data: Blob = new Blob([buffer], {type: 'application/pdf'});
//   //   FileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + PDF_EXTENSION);

//     // try2
//     let headerOptions = new HttpHeaders({ 
//       'Content-Type': 'application/json',
//     // 'Accept': 'application/pdf'
//   });
//     let requestOptions = { headers: headerOptions };
//     let serviceURL = `${this.server}admin/InvoiceDetailWithPdf/`
//     this.http.post(serviceURL, dataObj, requestOptions).pipe(map((data: any) => {
// console.log(data.invoice_url,"data",data,data.success)
// console.log(data.data.invoice_url)
//       let blob = new Blob([new Uint8Array(data.data.invoice_url)], {
//           type: 'application/json' // must match the Accept type
//           // type: 'application/octet-stream' // for excel 
//       });
//       var link = document.createElement('a');
//       link.href = window.URL.createObjectURL(blob);
//       console.log(link)
//       link.download = "file.pdf";
//       document.body.appendChild(link);
//       link.click();
//       setTimeout(function(){
//         document.body.removeChild(link);
//         window.URL.revokeObjectURL(link.href);
//     }, 100);  
//   })).subscribe((result: any) => {
//     console.log(result)
//   });
//   }

}
