import { Component, OnInit } from '@angular/core';
import { coachMarksElements } from './coach-marks-const';
import { AppStateService } from '../../services/app-state/app-state.service';
import { secureStorage } from '../../services/securestorage';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-coach-marks',
  templateUrl: './coach-marks.component.html',
  styleUrls: ['./coach-marks.component.scss']
})
export class CoachMarksComponent implements OnInit {
  coachMrksData:any;
  allElements:any[]=[];
  afterLoginMainHeader:any[] = [];
  displayIndex:number = 0;
  constructor(
    private appStateService:AppStateService, 
    private scroller: ViewportScroller, 
    private router: Router,
    private localStorage:secureStorage) { }

  ngOnInit(): void {
    this.allElements = coachMarksElements;
    this.coachMrksData = coachMarksElements[this.displayIndex];
    this.afterLoginMainHeader = JSON.parse(this.localStorage.getItem('afterLoginMainHeader'));
  }

  async goToNextCoachMark(action?:string){
    this.displayIndex = action=='back'?this.displayIndex -1:this.displayIndex +1;
    if(this.displayIndex < coachMarksElements.length){
      this.coachMrksData = coachMarksElements[this.displayIndex];
      if(coachMarksElements[this.displayIndex].routerLink){
        this.afterLoginMainHeader.indexOf(coachMarksElements[this.displayIndex].TriggerNext) != -1?
        await this.router.navigate(['/account/home']):"";
        // this.openCloseMySavingModal(coachMarksElements[this.displayIndex].index)
        await this.router.navigateByUrl(coachMarksElements[this.displayIndex].routerLink).then(() => {
          setTimeout(() => {
            window.scrollTo({
              top: (document.getElementById(coachMarksElements[this.displayIndex].elementId).offsetTop -
                  (document.getElementsByTagName('header')[0].offsetHeight  + 32)), left: 0, behavior: 'smooth'
          })
          }, 500);
        });
      }
    }else{
      this.localStorage.setItem("coachMarksRender", 'true');
      this.appStateService.coachMarksCheckedOrSkip.next(false);
    }
  }
  skipCoachMarks(){
    this.localStorage.setItem("coachMarksRender", 'true');
    this.appStateService.coachMarksCheckedOrSkip.next(false);
  }
  // openCloseMySavingModal(index){
  //   if(index == 3 || index == 4 ){
  //     document.getElementById("mySidenav").style.width = "400px";   
  //     document.getElementById("my-saving-btn").style.display = 'none';  
  //   }else if(index == 2 || index == 5){
  //     document.getElementById("mySidenav").style.width = "0";
  //     document.getElementById("my-saving-btn").style.display = 'block';  
  //   }
  // }
}
