export const blockPermissionMsg =
  'Your access is restricted, please contact Organisation Manager...!';

export const extraOrderMenus = [
  {
    category: "Order Management",
    icon: null,
    id: 0,
    isPermission: true,
    is_active: true,
    is_hidden: false,
    page_display_name: "On-Hold Orders",
    page_url: "/orders/on-hold-orders",
    sequence: 0,
    tooltip: null,
    type: "dropdown"
  },
  {
    category: "Order Management",
    icon: null,
    id: 0,
    isPermission: true,
    is_active: true,
    is_hidden: false,
    page_display_name: "Temporary Orders",
    page_url: "/orders/temp-orders",
    sequence: 0,
    tooltip: null,
    type: "dropdown"
  }
  ,
  {
    category: "Order Management",
    icon: null,
    id: 0,
    isPermission: true,
    is_active: true,
    is_hidden: false,
    page_display_name: "Schedule Delivery",
    page_url: "/orders/shedule-orders",
    sequence: 0,
    tooltip: null,
    type: "dropdown"
  }
]

export function Part_Search_Request_Of_Recommendation(account_pk, recommnedation_arr, shipping_id,usertype) {
  return {
    "offset": 0,
    "limit": 20,
    "search_text": "",
    "bs_model_name": "",
    "pg_line": "",
    "multi_pg_line": [],
    "multi_bs_model_name": [],
    "multi_dispgroup_name": [],
    "model_cat": "",
    "mod_uid": "",
    "category_type": "",
    "bom_id": "",
    "durafit_parts": false,
    "kit_parts": false,
    "part_number_last_four": "",
    "pl_id": "",
    "part_number": "",
    "is_for_epc_data": false,
    "is_for_discount": account_pk && shipping_id?true:false,
    "is_for_ecom_detail": false,
    "account_pk": account_pk,
    "shipping_address_id": shipping_id,
    "is_for_top_sold_parts": false,
    "part_number_list":recommnedation_arr,
    "is_for_inventory":usertype?.user_type == 'MECH' ? true: false,
  }
}
export const returnOrderReasons = [
  "Wrong Discount Assigned","Wrong UMRP Selected","Wrong Customer Selected","Wrong item inside the box",
  "Wrong part supplied","Incorrect quantity supplied","Incorrect quantity ordered","Part found damaged Pre Fitment",
  "Part found damaged Post Fitment","Considered for Goodwill warran","Part not required by retailer","purchased from another source",
  "Price in bill is higher","Product MRP differs in bill","Wrong Tax Category (GST,IGST)","Wrong item delivered","Wrong Personal details in bill","Product or Packing damaged","Old product delivered"
]
export const cancelOrderReasons = [
  "Wrong Part","Part broken","Short quantity","Excess quantity","Incorrect part ordered","My customer has cancelled the order",
  "Incorrect Billing or delivery address selected / incorrect contact number entered","Seller denied delivery (credit related, outstanding etc)",
  "Part locally arranged as ETA has been exceeded","Can't wait, ETA is too high","Incorrect quantity ordered","Part available at lower price",
  "Incorrect GST no entered in the order","Other Reason"
]
