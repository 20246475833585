import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './components/layout/layout.component';
import { AccountComponent } from './pages/account/account.component';
import { AddressComponent } from './pages/address/address.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from "./pages/login/login.component";
import { OtpComponent } from './pages/otp/otp.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { UserManagementComponent } from './pages/user-management/user-management.component';
import { UserPermissionComponent } from './pages/user-permission/user-permission.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { ReportComponent } from './pages/report/report.component';
import { BulkCartComponent } from './pages/bulk-cart/bulk-cart.component';
import { SetPasswordComponent } from './pages/set-password/set-password.component';
import { EtaReportComponent } from './pages/eta-report/eta-report.component';
import { NearbyMapLocatorComponent } from '../../shared/components/nearby-map-locator/nearby-map-locator.component';
import { EdukaanNotificationComponent } from '../../../../src/app/edukaan-notification/edukaan-notification.component';
import { MyOffersComponent } from './pages/my-offers/my-offers.component';
import { CreditListComponent } from './pages/credit-list/credit-list.component';
import { CouponDetailsComponent } from '../../shared/modals/coupon-details/coupon-details.component';
import { MyCouponsComponent } from './pages/my-coupons/my-coupons.component';
import { MySchemeComponent } from './pages/my-scheme/my-scheme.component';
import { AuthGuardGuard } from '../../shared/services/REST/auth-guard.guard';
import { FitmentSegmentListComponent } from './pages/fitment-segment-list/fitment-segment-list.component';
import { MyComboOfferComponent } from './pages/my-combo-offer/my-combo-offer.component';
import { ProfileloyalityComponent } from './pages/profileloyality/profileloyality.component';
const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard',
      },
      {
        path: 'otp',
        component: OtpComponent,
      },
      {
        path: 'profile',
        component: UserProfileComponent,
        canActivate: [AuthGuardGuard]
      },
      {
        path: 'accountlist',
        component: AccountComponent,
        canActivate: [AuthGuardGuard]
      },
      {
        path: 'userRegistrationList',
        component: UserManagementComponent,
        canActivate: [AuthGuardGuard]
      },
      {
        path: 'userpermission',
        component: UserPermissionComponent,
        canActivate: [AuthGuardGuard]
      },
      {
        path: 'addbulkcart',
        component: BulkCartComponent,
        canActivate: [AuthGuardGuard]
      },
      {
        path: 'setpassword',
        component: SetPasswordComponent,
        canActivate: [AuthGuardGuard]
      },
      {
        path: 'profile-loyality',
        component: ProfileloyalityComponent,
        canActivate: [AuthGuardGuard]
      }
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'registration',
    component: RegistrationComponent,
  },
  {
    path: 'address',
    component: AddressComponent,
  },
  {
    path: 'report',
    component: ReportComponent,
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'etareport',
    component: EtaReportComponent,
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'nearbyme',
    component: NearbyMapLocatorComponent
  },
  {
    path: 'notification',
    component: EdukaanNotificationComponent
  },
  {
    path: 'myoffer',
    component: MyOffersComponent
  },
  {
    path: 'coupons',
    component:MyCouponsComponent,
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'schemes',
    component:MySchemeComponent,
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'credit-list',
    component: CreditListComponent,
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'coupon-details',
    component: CouponDetailsComponent,
  },
  {
    path: 'servicerequestreport',
    component: FitmentSegmentListComponent,
    canActivate: [AuthGuardGuard]
  },
  { path: 'combo-offer',
  component:MyComboOfferComponent,
  canActivate: [AuthGuardGuard]
}
];
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AccountRoutingModule { }
