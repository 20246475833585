import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '../../services/REST/user.service';

@Component({
  selector: 'app-productspecification',
  templateUrl: './productspecification.component.html',
  styleUrls: ['./productspecification.component.scss']
})
export class ProductspecificationComponent implements OnInit {
  @Input() product: any;
  
  constructor(public userService: UserService) { }

  ngOnInit(): void {
  }

}
