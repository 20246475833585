<h5 class="mb-3" *ngIf="product">Specification</h5>
<h5 class="mb-3" *ngIf="!product"><skc-rectangle [loading]="loading" [skc-height]="'18px'" [skc-width]="'50%'"></skc-rectangle></h5>
<div class="table-responsive">
  <table class="table tbl-Specification table-striped table-borderless">
    <ng-container *ngIf="!product; else productSpecifications">
      <tbody>
        <ng-container *ngFor="let image of userService.generateFake(8);">
          <tr>
            <td><skc-rectangle [loading]="loading" [skc-height]="'34px'" [skc-width]="'100%'"></skc-rectangle></td>
            <td><skc-rectangle [loading]="loading" [skc-height]="'34px'" [skc-width]="'100%'"></skc-rectangle></td>
          </tr>
        </ng-container>
      </tbody>
    </ng-container>
    <ng-template #productSpecifications>
      <tbody>
        <tr>
          <td>Part:</td>
          <td> {{ product?.short_description == " " || product?.short_description == null ? "-" : product?.short_description }}</td>
        </tr>
        <!-- <tr>
          <td>Brand:</td>
          <td>TATA motors genuine parts</td>
        </tr>
        <tr>
          <td>Box dimensions:</td>
          <td>Height: 12.10"
            Length: 12.10"
            Width: 6.10"</td>
        </tr>
        <tr>
          <td>Series:</td>
          <td>Air filtration system</td>
        </tr>
        <tr>
          <td>Quantity Sold:</td>
          <td>SKU47-21972 KN</td>
        </tr>
        <tr>
          <td>Recommended Use:</td>
          <td>OE Replacement</td>
        </tr>
        <tr>
          <td>Material:</td>
          <td>Synthetic paper foam</td>
        </tr> -->
        <!-- <tr>
          <td>Material Composition:</td>
          <td>{{product?.material_composition || "-" }}</td>
        </tr> -->
        <!-- <tr>
          <td>Product Fit:</td>
          <td>Exact Fit</td>
        </tr>
        <tr>
          <td>Alignment Tool Included:</td>
          <td>With Alignment Tool</td>
        </tr> -->
        <!-- <tr>
          <td>Weight:</td>
          <td>{{ product?.part_weight == " " || product?.part_weight == "" ||  product?.part_weight == null ||  product?.part_weight == undefined ? "-" : product?.part_weight}}</td>
        </tr> -->
        <!-- <tr>
          <td>Project Group Description:</td>
          <td>{{ product?.geolus_group_description == "" || product?.geolus_group_description == null
                ? "-" : product?.geolus_group_description}}</td>
        </tr> -->
        <tr>
          <td>LH/RH Indicator:</td>
          <td>
            {{ product?.is_lh_rh_indicator == "false" ? "Not Applicable" : product?.is_lh_rh_indicator || "-"
            }}
          </td>
        </tr>
        <tr >
          <td>Hazardous Indicator:</td>
          <td>
            {{ product?.is_hazardous_indicator == "false" ? "Not Applicable" : product?.is_hazardous_indicator || "-"}}
          </td>
        </tr>
        <tr>
          <td>Part Type:</td>
          <td>
            {{
              product?.distributor_category == "MHV"
                ? "MHCV Part"
                : product?.distributor_category == "RMH"
                ? "MHCV Part"
                : product?.distributor_category == "SLV"
                ? "SLCV Part"
                : product?.distributor_category == "RSL"
                ? "SLCV Part"
                : product?.distributor_category == "TMGO"
                ? "TMGO Part"
                : "Common Part" || "-"
            }}
          </td>
        </tr>
        <!-- <tr>
          <td>Product Dimensions:</td>
          <td>{{ product?.product_dimensions || "-" }}</td>
        </tr> -->
        <tr>
          <td>Length:</td>
          <td>{{ product?.length_s ? product.length_s : '-' }}</td>
        </tr>
        <tr>
          <td>Width:</td>
          <td>{{ product?.width_s ? product.width_s : '-' }}</td>
        </tr>
        <tr>
          <td>Height:</td>
          <td>{{ product?.height_s ? product.height_s : '-' }}</td>
        </tr>
        <tr>
          <td>Weight:</td>
          <td>{{ product?.weight_s ? product.weight_s : '-' }}</td>
        </tr>
        <tr>
          <td>Brand:</td>
          <td>{{ product?.brand_s ? product.brand_s : '-' }}</td>
        </tr>
        <tr>
          <td>Material:</td>
          <td>{{ product?.epdc_material_s ? product.epdc_material_s : '-' }}</td>
        </tr>
        <tr>
          <td>Product Type:</td>
          <td>{{ product?.product_type_s ? product.product_type_s : '-' }}</td>
        </tr>
        <tr>
          <td>Product Length with Packaging:</td>
          <td>{{ product?.product_length_with_packaging_s ? product.product_length_with_packaging_s : '-' }}</td>
        </tr>
        <tr>
          <td>Product Width with Packaging:</td>
          <td>{{ product?.product_width_with_packaging_s ? product.product_width_with_packaging_s : '-' }}</td>
        </tr>
        <tr>
          <td>Product Height with Packaging:</td>
          <td>{{ product?.product_height_with_packaging_s ? product.product_height_with_packaging_s : '-' }}</td>
        </tr>
        <tr>
          <td>Product Weight with Packaging:</td>
          <td>{{ product?.product_weight_with_packaging_s ? product.product_weight_with_packaging_s : '-' }}</td>
        </tr>
        <tr>
          <td>Pack Type:</td>
          <td>{{ product?.pack_type_s ? product.pack_type_s : '-' }}</td>
        </tr>
        <tr>
          <td>No of Units in a box:</td>
          <td>{{ product?.no_of_units_in_a_box_s ? product.no_of_units_in_a_box_s : '-' }}</td>
        </tr>
        <tr>
          <td>UC to be done:</td>
          <td>{{ product?.UC_to_be_done_s ? product.UC_to_be_done_s : '-' }}</td>
        </tr>
        <tr>
          <td>Special care (heavy):</td>
          <td>{{ product?.special_care_heavy_s ? product.special_care_heavy_s : '-' }}</td>
        </tr>
        <tr>
          <td>Special care (large):</td>
          <td>{{ product?.special_care_large_s ? product.special_care_large_s : '-' }}</td>
        </tr>
        <!-- <tr>
          <td>Release Bearing Included:</td>
          <td>Direct Fit</td>
        </tr>
        <tr>
          <td>Clutch Disc Included:</td>
          <td>With Alignment Tool</td>
        </tr>
        <tr>
          <td>Pilot Bushing Included:</td>
          <td>With Pilot Bushing</td>
        </tr>
        <tr>
          <td>Disc Diameter:</td>
          <td>8.38 in. Disc Dia</td>
        </tr>
        <tr>
          <td>Spline Count:</td>
          <td></td>
        </tr> -->
      </tbody>
    </ng-template>
  </table>
</div>