<div class="container-fluid">
    <!--RECOMMENDED FOR YOU-->
    <div class="row recommended-option mb-4 mt-3">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <h2>RECOMMENDED FOR YOU</h2>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
            <div id="Vehicleslist">
                <ng-container *ngIf="!vehicle?.length && !isVehicleListLoaded; else modelList">
                    <owl-carousel-o [options]="customOptionsForModels">
                        <ng-container  *ngFor="let item of userService.generateFake(8);let i = index;">
                            <ng-template carouselSlide [id]="i">
                                <div class="form-check cat-option m-2" style="width: 10rem;">
                                    <!-- <div class="d-inline-block">
                                        <skc-rectangle [loading]="loading" [skc-height]="'18px'" [skc-width]="'100%'"> </skc-rectangle>
                                    </div> -->
                                    <div class="d-inline-block" style="cursor: pointer;">
                                        <skc-rectangle [loading]="loading" [skc-height]="'40px'" [skc-width]="'100%'"> </skc-rectangle>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </ng-container>
                <ng-template #modelList>
                    <owl-carousel-o [options]="customOptionsForModels">
                        <ng-container  *ngFor="let obj of vehicle;let i=index;">
                            <ng-template carouselSlide [id]="obj?.model_cat">
                                <div [ngClass]="(modelsCategory == obj?.model_cat) ? 'form-check m-2 cat-option select-cat' :
                                'form-check cat-option m-2'">
                                    <input class="form-check-input" type="radio" (click)="getModelAggregate(obj?.model_cat,true);"
                                    name="inlineRadioOptions" id="inlinePrima" value="{{obj?.model_cat}}" id="{{obj?.model_cat}}">
                                    <label class="form-check-label text-nowrap d-flex" style="align-items: center; cursor: pointer;"
                                    for="{{obj?.model_cat}}">
                                        <div class="d-inline-block">
                                            {{obj?.model_cat}}
                                        </div>
                                        <div class="d-inline-block" style="cursor: pointer;">
                                            <img alt="" onerror="this.src='./assets/tata-vehicle.jfif';"
                                                src="{{obj?.model_img_name + '/resize/63x40'}}" />
                                        </div>
                                    </label>
                                </div>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </ng-template>


            </div>
            <!-- <div class="form-check form-check-inline cat-option">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineSigma" value="Sigma">
                <label class="form-check-label" for="inlineSigma">Sigma <img alt=""
                        src="./assets/images/mega-saving.png" /></label>
            </div> -->
        </div>
        <!-- <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
            <div id="Vehicleslist" class="owl-carousel owl-theme fleet-nav-slide mb-4 mt-3">
                <div class="item v-box  rounded position-relative" *ngFor="let category of categories">
                    <div [ngClass]="(category?.type == modelsCategory) ? 'v-box-body border p-3 m-2 d-flex align-items-center select-cat position-relative' : 'v-box-body border p-3 m-2 d-flex align-items-center position-relative'"
                        (click)="shopByPopularModels(category?.name);getModelAggregate(category?.type);">
                        <div class="v-box-img">
                            <img src="{{category?.image}}" class="img-fluid" alt=""
                                onerror="this.src='./assets/tata-vehicle.jfif';">
                        </div>
                        <div class="v-box-content">
                            <h5 class="mb-0">{{category?.name}}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
            <!-- <div [ngClass]="(isAllAggregate) ? 'form-check form-check-inline parts-option select-part-option' : 'form-check form-check-inline parts-option'">
                <input class="form-check-input" type="radio" name="data" id="All" checked="isAllAggregate"
                    value="All">
                <label class="form-check-label" for="All">All </label>
            </div> -->
            <div id="aggregatelist">
                <ng-container *ngIf="!isAggregateListLoaded; else aggregateList">
                    <owl-carousel-o [options]="customOptionsForAggregates">
                        <ng-container *ngFor="let item of userService.generateFake(10);let i = index;">
                            <ng-template carouselSlide [id]="i">
                                <div class="form-check  parts-option ms-3" style="width: 8rem;">
                                    <skc-rectangle [loading]="loading" [skc-height]="'20px'" [skc-width]="'100%'"> </skc-rectangle>
                                </div>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </ng-container>
                <ng-template #aggregateList>
                    <owl-carousel-o [options]="customOptionsForAggregates">
                        <ng-container *ngFor="let aggregate of modelAggregateData">
                            <ng-template carouselSlide [id]="aggregate.aggregate">
                                <div [ngClass]="(selectedAggregate === aggregate.aggregate) ? 'form-check  parts-option select-part-option ms-3' : 'form-check  parts-option ms-3'"
                                >
                                    <input class="form-check-input" type="radio" name="data" id="{{aggregate.aggregate}}" checked=""
                                    value="{{aggregate.aggregate}}">
                                    <label class="form-check-label" (click)="GetBomDetail(aggregate.aggregate,0,true)"
                                        for="aggregate.aggregate">{{aggregate.aggregate}} </label>
                                </div>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </ng-template>

            </div>
            <!-- <div class="form-check form-check-inline parts-option">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="Clutch" checked=""
                    value="Clutch">
                <label class="form-check-label" for="Clutch">Clutch </label>
            </div>
            <div class="form-check form-check-inline parts-option">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="Brakes" checked=""
                    value="Brakes">
                <label class="form-check-label" for="Brakes">Brakes </label>
            </div>
            <div class="form-check form-check-inline parts-option">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="Gearbox" checked=""
                    value="Gearbox">
                <label class="form-check-label" for="Gearbox">Gearbox </label>
            </div>
            <div class="form-check form-check-inline parts-option">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="Axel" checked=""
                    value="Axel">
                <label class="form-check-label" for="Axel">Axel </label>
            </div>
            <div class="form-check form-check-inline parts-option">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="Suspension" checked=""
                    value="Suspension">
                <label class="form-check-label" for="Suspension">Suspension </label>
            </div> -->
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="row recommended-item-list">
                <div class="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 ">
                    <div class="row">
                        <ng-container *ngIf="!productList.length && !isPartListLoaded; else productListTemplate">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 pe-lg-0"
                                *ngFor="let item of userService.generateFake(8)">
                                <div class="card mb-3">
                                    <div class="card-body" style="height: 286px;">
                                        <div class="prod-image prod-imagenew">
                                            <skc-rectangle [loading]="loading" [skc-height]="'80px'" [skc-width]="'100%'"> </skc-rectangle>
                                        </div>
                                        <div class="prod-desc">
                                            <div class="row">
                                                <div class="col-11">
                                                    <div class="modal-number">
                                                        <skc-rectangle [loading]="loading" [skc-height]="'19px'" [skc-width]="'100%'"> </skc-rectangle>
                                                    </div>
                                                    <div class="modal-name">
                                                        <skc-rectangle [loading]="loading" [skc-height]="'16px'" [skc-width]="'100%'"> </skc-rectangle>
                                                    </div>
                                                    <div class="delivery-time w-50 d-inline-block text-nowrap"
                                                        style="text-overflow: ellipsis; overflow:hidden;">
                                                        <skc-rectangle [loading]="loading" [skc-height]="'8px'" [skc-width]="'100%'"> </skc-rectangle>
                                                    </div>
                                                    <!-- <div class="delivery-time w-50 d-inline-block text-end text-nowrap"
                                                    style="text-overflow: ellipsis; overflow:hidden;">
                                                    <ngx-skeleton-loader></ngx-skeleton-loader>
                                                </div> -->
                                                    <ng-container>
                                                        <div class="price fw-bold">
                                                            <skc-rectangle [loading]="loading" [skc-height]="'20px'" [skc-width]="'100%'"> </skc-rectangle>
                                                        </div>
                                                        <!-- <div class="price fw-bold">
                                                        <ngx-skeleton-loader></ngx-skeleton-loader>
                                                    </div>
                                                    <div class="tmgproduct">
                                                        <ngx-skeleton-loader></ngx-skeleton-loader>
                                                    </div> -->
                                                    </ng-container>
                                                    <!-- <ng-container>
                                                    <div>
                                                        <div class="price fw-bold">
                                                            <ngx-skeleton-loader></ngx-skeleton-loader>
                                                        </div>
                                                        <div class="price fw-bold">
                                                            <ngx-skeleton-loader></ngx-skeleton-loader>
                                                        </div>
                                                    </div>
                                                    <div class="price">
                                                        <div>
                                                            <div class="card-actual-mrp blockloader"><span>
                                                                <ngx-skeleton-loader
                                                                [theme]="{ width: '170px', 'border-radius': '0', height: '15px' }"></ngx-skeleton-loader>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <div class="row align-items-center">
                                            <div class="col-5 ">
                                                <skc-rectangle [loading]="loading" [skc-height]="'24px'" [skc-width]="'100%'"> </skc-rectangle>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #productListTemplate>
                            <ng-container *ngIf="productList.length > 0 && !isNoProductList; else NoProductsFound">
                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 pe-lg-0"
                                    *ngFor="let product of productList">
                                    <div class="card mb-3">
                                        <div class="card-body" [ngClass]="product.is_nls == 'in_active'?'no-service-body':'cart-body'" style="height: 286px;">
                                            <div [routerLink]="['/shop/product-details']"
                                                [queryParams]="{ part_Number : product.part_number }" class="prod-image" *ngIf="product.part_image_path">
                                                <img class="product image" [src]="product?.part_image_path + '/resize/114x80'" onerror="this.src='./assets/tata-vehicle.jfif';"/>
                                            </div>
                                            <div class="prod-desc">
                                                <div class="row">
                                                    <div class="col-11">

                                                        <app-wishlist-icon [product]="product" [layout]="'Nolayout'"></app-wishlist-icon>
                                                        <div [routerLink]="['/shop/product-details']"
                                                            [queryParams]="{ part_Number : product.part_number }">
                                                            <div class="modal-name"
                                                                title="{{ (product.short_description == '' || product.short_description == null) ? '-' : product.short_description}}">
                                                                {{ (product.short_description == " " || product.short_description == null) ? "-" : (product.short_description.length > 25 ?(product.short_description.substr(0, 25) + '...') :product.short_description)}}
                                                            </div>
                                                            <div class="modal-number">
                                                                {{ product.part_number }}
                                                            </div>
                                                            <div class="delivery-time w-50 d-inline-block text-nowrap" style="text-overflow: ellipsis; overflow:hidden;"
                                                                *ngIf="product?.hsn_code && product?.hsn_code != ''">
                                                                HSN: <span title="{{ product?.hsn_code }}">{{ product?.hsn_code }}</span>
                                                            </div>
                                                            <div class="delivery-time w-50 d-inline-block text-end text-nowrap" style="text-overflow: ellipsis; overflow:hidden;"
                                                                *ngIf="product?.tax_slab && product?.tax_slab!=''">
                                                                GST: <span title="{{ product?.tax_slab }}">{{ product?.tax_slab }}%</span>
                                                            </div>
                                                            <!-- <div class="delivery-time">
                                                            Estd. Delivery: <span>Today</span>
                                                        </div> -->
                                                        <ng-container *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true); else elseTemplate">
                                                            <ng-container *ngIf="!product.total_amount">
                                                                <div class="price fw-bold">
                                                                    {{ ( ( product.resource_type == 'Pack' )?product.crm_mrp*product.moq:product.crm_mrp ) |currencyFormat:'INR' }}
                                                                </div>
                                                            </ng-container>
                                                            <ng-container *ngIf="product.total_amount">
                                                                <div *ngIf="(product.base_discount_per +  product.scheme_discount_per + product.rule_discount_per + product.distributor2_discount_amount ) == 0">
                                                                    <div class="price fw-bold">
                                                                        {{ ( ( product.resource_type == 'Pack')?product.crm_mrp*product.moq:product.crm_mrp ) |currencyFormat:'INR' }}
                                                                    </div>
                                                                </div>
                                                                <div class="price" *ngIf="(product.base_discount_per +  product.scheme_discount_per + product.rule_discount_per + product.distributor2_discount_amount ) > 0">
                                                                    <div>
                                                                        {{  ( ( product.resource_type == 'Pack') ? product.total_amount*product.moq
                                                                        : product.total_amount) |currencyFormat:'INR'
                                                                        }}
                                                                        <div class="card-actual-mrp">
                                                                            <span>
                                                                                MRP {{
                                                                                ( ( product.resource_type == 'Pack') ? product.crm_mrp*product.moq
                                                                                : product.crm_mrp ) |currencyFormat:'INR'
                                                                                }}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>
                                                        <ng-template #elseTemplate>
                                                            <div class="price fw-bold">
                                                            {{ (( product.resource_type == 'Pack' )?product.crm_mrp*product.moq:product.crm_mrp ) |currencyFormat:'INR' }}
                                                            </div>
                                                        </ng-template>
                                                            <div class="tmgproduct moqProductInfo" *ngIf="( product.resource_type == 'Pack' && product.moq > 1)">
                                                                <p class="save-mode">1 Unit contains {{product.moq | number: '1.0-0'}} <span *ngIf="product.moq > 1">Quantities</span><span *ngIf="product.moq <= 1">Quantity</span></p>
                                                            </div>
                                                            <span class="error-message-service text-nowrap" *ngIf="product.is_nls == 'in_active'">No Longer Serviced</span>
                                                            <span class="error-message-service text-nowrap" *ngIf="product?.is_nls != 'in_active' && product?.alternate_part?true:false">No
                                                                Longer available</span>
                                                            <div class="col-12 view-alternate text-nowrap mt-1" *ngIf="product.is_nls == 'in_active' || (product?.alternate_part?true:false)">
                                                                <span style="color: blue;" [routerLink]="['/shop/product-details']"
                                                                [queryParams]="{ part_Number : product.alternate_part?product.alternate_part:product.part_number }" style="cursor: pointer;">View Alternate Part</span>
                                                            </div>

                                                        </div>
                                                        <!-- <div class="col-1 text-end p-0">
                                                        <a href="#" class="add-to-favorite"><img alt=""
                                                                src="./assets/images/bookmark.svg" /></a>
                                                    </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr *ngIf="product.is_nls != 'in_active'  &&  ( product.is_nls == 'active' && !product?.alternate_part )" style="margin: 0px;" />
                                        <div class="card-footer" style="border-top: none;" *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                                            <div class="row align-items-center">
                                                <div class="col-5 " *ngIf="(userData?.user_type == 'MECH' && (product?.inventory_quantity > 0 && product?.inventory_quantity != null && product.moq <= product.inventory_quantity) && product.is_nls != 'in_active' && (product?.alternate_part?false:true)) || (userData?.user_type != 'MECH' && product.is_nls != 'in_active' && (product?.alternate_part?false:true))">
                                                    <app-input-number aria-label="Quantity" size="lg" [min]="1"
                                                        [defaultValue]="product.min_quantity"
                                                        (currentQuantity)="readQuantity($event,product)" [isTmgoValue]="product.resource_type == 'Pack' ?product.moq:'1'" [userType]="userData" [inventoryQty]="product.inventory_quantity" >
                                                    </app-input-number>
                                                </div>
                                                <div style="display: contents;" *ngIf="userData?.user_type == 'MECH'">
                                                    <ng-container *ngIf="product?.inventory_quantity > 0 && product.moq <= product.inventory_quantity">
                                                        <div class="col-7 text-end" *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                                                            <button class="add-to-card" (click)="addToCart(product)"><img alt=""
                                                                    src="./assets/images/cart.svg" /> Add to
                                                                Cart</button>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngIf="(product?.inventory_quantity <= 0 || product?.inventory_quantity == null || product.moq > product.inventory_quantity) && product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                                                        <h6 class="col-7 text-end d-flex align-items-center" style="font-size: 13px;
                                                        position: relative;
                                                        font-weight: 700;
                                                        color: red;
                                                        top: 5px;">Out Of Stock</h6>
                                                    </ng-container>
                                                </div>
                                                <div class="col-7 text-end" *ngIf="userData?.user_type != 'MECH' && product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                                                    <button class="add-to-card" (click)="addToCart(product)"><img alt=""
                                                            src="./assets/images/cart.svg" /> Add to
                                                        Cart</button>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="card-footer" style="border-top: none; height: 40px;"
                                        *ngIf="(product.is_nls == 'in_active') || (product.is_nls != 'in_active' && (product?.alternate_part?true:false))">
                                            <div class="row">
                                                <div class="col-md-12">
                                                </div>
                                            </div>
                                        </div>
                                        <ng-container *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                                        <div class="discount" *ngIf="(convertToFloat(product?.base_discount_per) + convertToFloat(product?.scheme_discount_per) +
                                                convertToFloat(product?.tml_discount_per) + convertToFloat(product?.distributor_discount_per) +
                                                convertToFloat(product?.rule_discount_per) + convertToFloat(product.distributor2_discount_amount) ) > 0" [routerLink]="['/shop/product-details']"
                                            [queryParams]="{ part_Number : product.part_number }">
                                            <!-- {{(product?.base_discount + product?.scheme_discount +
                                                    product?.tml_discount_per + product?.distributor_discount_per +
                                                    product?.rule_discount)}} % -->
                                            {{'SAVE ' + (((product.crm_mrp-product.total_amount)/product.crm_mrp)*100)?.toFixed(1) + '%'}}
                                        </div>
                                    </ng-container>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #NoProductsFound>
                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 pe-0">
                                    <div class="container-fluid show">
                                        <div>
                                            <!-- <div class="block-empty__message">Your shopping cart is empty!</div> -->
                                            <img src="./assets/images/empty box.png"
                                                style="margin-left: 14rem;margin-top: 5rem;">
                                        </div>
                                    </div>
                                </div>
                                <p class="error-message">No Records Found.</p>
                            </ng-template>
                        </ng-template>
                    </div>
                    <!-- <div class="col-12 view-alternate text-nowrap mt-1" *ngIf="productList.length > 0 && !isNoProductList;">
                                                <span (click)="gotoAggregate()" style="cursor: pointer;">View All</span>
                                            </div> -->
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                    <div class="row">
                        <ng-container *ngIf="!postloginRightBannerList?.length; else rightBanners">
                            <div class="col-12 col-sm-6 col-md-6 col-xl-12 col-lg-12" *ngFor="let item of userService.generateFake(2)">
                                <div class="card offer-kit mb-3" >
                                    <skc-rectangle [loading]="loading" [skc-height]="'193px'" [skc-width]="'100%'"> </skc-rectangle>
                                    <div class="card-body p-2">
                                        <h5 class="service-kit-title" style="height: 38px; line-height: 38px;">
                                            <skc-rectangle [loading]="loading" [skc-height]="'38px'" [skc-width]="'100%'"> </skc-rectangle>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #rightBanners>
                            <div class="col-12 col-sm-6 col-md-6 col-xl-12 col-lg-12" *ngFor="let data of postloginRightBannerList">
                                <div class="card offer-kit mb-3" (click)="onBannerRedirect(data,'PostLoginRightBanner')" [ngStyle]="{'cursor':data?.banner_hyper_link ? 'pointer' : ''}">
                                    <img [src]="data?.banner_url + '/resize/354x327'" [style.height]="data?.description?'273px':'327px'" class="card-img-top" alt="Service Kit Name">
                                    <div *ngIf="data?.description" class="card-body p-2" style="cursor:context-menu;">
                                        <h5 class="service-kit-title" style="height: 38px; line-height: 38px;">{{data?.description}}</h5>
                                        <!-- <h4>Get this kit by tomorrow</h4>
                                        <h5 class="service-kit-title">USE CODE DEFL2345. GET 80% OFF</h5> -->
                                    </div>
                                </div>
                                <!-- <div class="card offer-kit mb-3">
                                    <img src="./assets/images/service-kit.png" class="card-img-top" alt="Service Kit Name">
                                    <div class="card-body p-2">
                                        <h4>Get this kit by tomorrow</h4>
                                        <h5 class="service-kit-title">USE CODE DEFL2345. GET 80% OFF</h5>
                                    </div>
                                </div>
                                <div class="card offer-kit mb-3">
                                    <img src="./assets/images/service-kit.png" class="card-img-top" alt="Service Kit Name">
                                    <div class="card-body p-2">
                                        <h4>Get this kit by tomorrow</h4>
                                        <h5 class="service-kit-title">USE CODE DEFL2345. GET 80% OFF</h5>
                                    </div>
                                </div> -->
                            </div>
                        </ng-template>

                    </div>
                </div>
            </div>

        </div>
    </div>
    <!-- Fleet Recommendation -->
    <!-- <div class="related-product rel-prod"  *ngIf="fleetEdgePartList &&  isLoggedIn; elseNoFleetEdgePartList ">
        <div class="container-fluid"  >
            <div class="row new-product mb-4" *ngIf="fleetEdgePartList">
                <div class="col-12 col-sm-12 col-lg-12 col-md-12 col-xl-12" style="padding-left: 0;">
                    <h2>Fleet Recommendation</h2>
                </div>
          <div class="row">
            <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12">
              <div id="related-product-fleet-edge-level">
                <div class="item me-2 ms-2" *ngFor="let product of fleetEdgePartList">
                  <div class="card">
                    <div class="row g-0 align-items-center">
                      <div class="col-md-3">
                        <div class="prod-img mx-auto">
                          <img [src]="product?.image" (click)="onRouteSameUrl(product.part_number);" class="img-fluid"
                            alt="prod-img-alt" srcset="">
                        </div>
                      </div>
                      <div class="col-md-9">
                        <div class="card-body">
                          <div class="prod-content position-relative">
                            <app-wishlist-icon [product]="product" [layout]="'Nolayout'" ></app-wishlist-icon>
                            <h3 class="fw-bold prod-model mb-2" (click)="onRouteSameUrl(product.part_number);">
                              {{ product.part_number }}</h3>

                            <h4 class="prod-name mb-2" (click)="onRouteSameUrl(product.part_number);">
                              {{ (product.part_desc == " " || product.part_desc == null) ? "-" : product.part_desc?.substr(0,23) }}
                            </h4>
                            <ng-container *ngIf="!product.total_amount">
                              <div class="price fw-bold mb-3" *ngIf="product.moq">
                                {{ (product.umrp*product.moq) |currencyFormat:'INR' }}
                              </div>
                              <div class="price fw-bold mb-3" *ngIf="!product.moq">
                                {{ product.umrp |currencyFormat:'INR' }}
                              </div>
                            </ng-container>
                            <ng-container *ngIf="product.total_amount">
                              <div *ngIf="(product.base_discount +  product.scheme_discount + product.rule_discount ) == 0.0">
                                <div class="price fw-bold mb-3" *ngIf="product.moq">
                                  {{ (product.umrp*product.moq) |currencyFormat:'INR' }}
                                </div>
                                <div class="price fw-bold mb-3" *ngIf="!product.moq">
                                  {{ product.umrp |currencyFormat:'INR' }}
                                </div>
                              </div>
                              <div *ngIf="(product.base_discount +  product.scheme_discount + product.rule_discount ) > 0.0">
                                <div class="price fw-bold mb-3" *ngIf="product.moq">
                                  {{ (product.total_amount * product.moq) |currencyFormat:'INR' }}
                                  <span>
                                    MRP {{ (product.umrp * product.moq) | currencyFormat :'INR' }} </span>
                                </div>
                                <div class="price fw-bold mb-3" *ngIf="!product.moq">
                                  {{ product.total_amount |currencyFormat:'INR' }}
                                  <span>
                                    MRP {{ product.umrp | currencyFormat :'INR' }} </span>
                                </div>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer bg-white d-flex justify-content-between">
                      <app-input-number aria-label="Quantity" size="lg" [min]="1" [max]="8"
                        [defaultValue]="product.min_quantity" (currentQuantity)="readQuantity($event,product,false);">
                      </app-input-number>
                      <div class="d-grid gap-2">
                        <button class="add-to-card" (click)="addToCart(product)"><i aria-hidden="true"
                            class="fas fa-shopping-cart"></i> Add to Cart</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <span #elseNoFleetEdgePartList></span> -->
    <div class="row new-product mb-4" id="fleet-rec-list" *ngIf="showFleetRecProducts">
        <div class="col-12 col-sm-12 col-lg-12 col-md-12 col-xl-12">
            <h2>Fleet Recommendations</h2>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <ng-container *ngIf="!fleetEdgePartList.length;else fleetRecList;">
                <owl-carousel-o [options]="customOptionsForFleetRecProducts">
                    <ng-container *ngFor="let item of userService.generateFake(7);let i = index;">
                        <ng-template carouselSlide [id]="i">
                            <div class="me-2 ms-2">
                                <div class="new-product-card mb-3">
                                    <div class="card-body cursor">
                                        <div class="row align-items-center">
                                            <div class="col-4 p-0 cursor">
                                                <div class="prod-image text-center">
                                                    <skc-rectangle [loading]="loading" [skc-height]="'80px'" [skc-width]="'100%'"> </skc-rectangle>
                                                </div>
                                            </div>
                                            <div class="col-8 cursor">
                                                <div class="row">
                                                    <div class="col-12  prod-desc">
                                                        <div class="modal-number">
                                                            <skc-rectangle [loading]="loading" [skc-height]="'19px'" [skc-width]="'100%'"> </skc-rectangle>
                                                        </div>
                                                        <div class="modal-name">
                                                            <skc-rectangle [loading]="loading" [skc-height]="'16px'" [skc-width]="'75%'"> </skc-rectangle>
                                                        </div>
                                                        <div class="delivery-time w-50 d-inline-block text-nowrap">
                                                            <skc-rectangle [loading]="loading" [skc-height]="'13px'" [skc-width]="'50%'"> </skc-rectangle>
                                                        </div>
                                                        <div class="delivery-time w-50 d-inline-block text-end text-nowrap">
                                                            <skc-rectangle [loading]="loading" [skc-height]="'13px'" [skc-width]="'50%'"> </skc-rectangle>
                                                        </div>
                                                        <div class="price fw-bold">
                                                            <skc-rectangle [loading]="loading" [skc-height]="'19px'" [skc-width]="'50%'"> </skc-rectangle>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <skc-rectangle [loading]="loading" [skc-height]="'22px'" [skc-width]="'100%'"> </skc-rectangle>
                                            </div>
                                            <div class="col-4 ms-auto me-2 p-0  text-end d-flex align-items-center ps-0">
                                                <skc-rectangle [loading]="loading" [skc-height]="'20px'" [skc-width]="'100%'"> </skc-rectangle>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </ng-container>
            <ng-template #fleetRecList>
                <owl-carousel-o [options]="customOptionsForFleetRecProducts">
                    <ng-container  *ngFor="let product of fleetEdgePartList;let i=index;">
                        <ng-template carouselSlide [id]="product.part_number">
                            <div class="me-2 ms-2">
                                <div class="new-product-card mb-3">
                                    <div class="card-body cursor" [ngClass]="product.is_nls == 'in_active'?'no-service-body-2':'cart-body-2'" style="height: 180px;">
                                        <div class="row align-items-center">
                                            <div class="col-4 p-0 cursor" [routerLink]="['/shop/product-details']"
                                                [queryParams]="{ part_Number : product.part_number }">
                                                <div class="prod-image text-center">
                                                    <img class="product image img-fluid" [src]="product?.part_image_path + '/resize/117x80'" />
                                                </div>
                                            </div>
                                            <div class="col-8 cursor" >
                                                <div class="row">
                                                    <div class="col-12  prod-desc">
                                                        <div class="wishlist-m-top">
                                                            <app-wishlist-icon [product]="product" [layout]="'NewProdlayout'"></app-wishlist-icon>
                                                        </div>
                                                        <div [routerLink]="['/shop/product-details']" [queryParams]="{ part_Number : product.part_number }" >
                                                            <div class="modal-name discription-m-top"
                                                                title="{{ (product.short_description == '' || product.short_description == null) ? '-' : product.short_description}}">
                                                                {{ (product.short_description == " " || product.short_description == null) ? "-" :
                                                                (product.short_description.length > 15 ?(product.short_description.substr(0, 15) + '...') :product.short_description)}}
                                                            </div>
                                                            <div class="modal-number">
                                                                {{ product.part_number }}
                                                            </div>
                                                            <div class="delivery-time w-50 d-inline-block text-nowrap" style="text-overflow: ellipsis; overflow:hidden;"
                                                                *ngIf="product?.hsn_code && product?.hsn_code != ''">
                                                                HSN: <span title="{{ product?.hsn_code }}">{{ product?.hsn_code }}</span>
                                                            </div>
                                                            <div class="delivery-time w-50 d-inline-block text-end text-nowrap" style="text-overflow: ellipsis; overflow:hidden;"
                                                                *ngIf="product?.tax_slab && product?.tax_slab!=''">
                                                                GST: <span title="{{ product?.tax_slab }}">{{ product?.tax_slab }}%</span>
                                                            </div>
                                                            <ng-container *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true); else elseTemplate">
                                                            <ng-container *ngIf="!product.total_amount">
                                                                <div class="price fw-bold">
                                                                    {{ (( product.resource_type == 'Pack' )?product.crm_mrp*product.moq:product.crm_mrp ) |currencyFormat:'INR' }}
                                                                </div>
                                                            </ng-container>
                                                            <ng-container *ngIf="product.total_amount">
                                                                <div *ngIf="(product.base_discount_per +  product.scheme_discount_per + product.rule_discount_per + product.distributor2_discount_amount) == 0.0">
                                                                    <div class="price fw-bold">
                                                                        {{ (( product.resource_type == 'Pack')?product.crm_mrp*product.moq:product.crm_mrp ) |currencyFormat:'INR' }}
                                                                    </div>
                                                                </div>
                                                                <div class="price" *ngIf="(product.base_discount_per +  product.scheme_discount_per + product.rule_discount_per + product.distributor2_discount_amount) > 0.0">
                                                                    <div>
                                                                        {{   (( product.resource_type == 'Pack'  ) ? product.total_amount*product.moq
                                                                        : product.total_amount) |currencyFormat:'INR'
                                                                        }}
                                                                        <div class="card-actual-mrp-fleet">
                                                                            <span class="pt-1">
                                                                                MRP {{
                                                                                (( product.resource_type == 'Pack' ) ? product.crm_mrp*product.moq
                                                                                : product.crm_mrp) |currencyFormat:'INR'
                                                                                }}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>
                                                        <ng-template #elseTemplate>
                                                            <div class="price fw-bold">
                                                            {{ (( product.resource_type == 'Pack' )?product.crm_mrp*product.moq:product.crm_mrp ) |currencyFormat:'INR' }}
                                                            </div>
                                                        </ng-template>
                                                            <div class="tmgproduct moqProductInfo" *ngIf="( product.resource_type == 'Pack' && product.moq > 1)">
                                                                <p class="save-mode">1 Unit contains {{product.moq | number: '1.0-0'}} <span *ngIf="product.moq > 1">Quantities</span><span *ngIf="product.moq <= 1">Quantity</span></p>
                                                            </div>
                                                            <span class="error-message-service text-nowrap" *ngIf="product.is_nls == 'in_active'">No Longer Serviced</span>
                                                            <span class="error-message-service text-nowrap" *ngIf="product?.is_nls != 'in_active' && product?.alternate_part?true:false">No
                                                                Longer available</span>
                                                            <div class="col-12 view-alternate text-nowrap mt-1" *ngIf="product.is_nls == 'in_active' || (product?.alternate_part?true:false)">
                                                                <span [routerLink]="['/shop/product-details']"
                                                                    [queryParams]="{ part_Number : product.part_number }" style="cursor: pointer;">View Alternate Part</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ng-container *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                                            <div class="discount-for-newproduct" *ngIf="(convertToFloat(product?.base_discount_per) + convertToFloat(product?.scheme_discount_per) +
                                                    convertToFloat(product?.tml_discount_per) + convertToFloat(product?.distributor_discount_per) +
                                                    convertToFloat(product?.rule_discount_per) + convertToFloat(product.distributor2_discount_amount)) > 0" [routerLink]="['/shop/product-details']"
                                                [queryParams]="{ part_Number : product.part_number }">
                                                {{'SAVE ' + (((product.crm_mrp-product.total_amount)/product.crm_mrp)*100)?.toFixed(1) + '%'}}
                                            </div>
                                        </ng-container>
                                        </div>
                                    </div>

                                    </div>
                                    <hr *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true)" style="margin: 0px;" />
                                    <div class="card-footer" style="border-top: none;">
                                        <div class="row">
                                            <div class="col-4" *ngIf="(userData?.user_type == 'MECH' && (product?.inventory_quantity != 0 && product?.inventory_quantity != null) && product.is_nls != 'in_active' && (product?.alternate_part?false:true)) || (userData?.user_type != 'MECH' && product.is_nls != 'in_active' && (product?.alternate_part?false:true))">
                                                <app-input-number aria-label="Quantity" (currentQuantity)="readQuantity($event,product)" [max]="5"
                                                size="lg" [min]="1" [defaultValue]="product.min_quantity" [isTmgoValue]="product.resource_type == 'Pack'?product.moq:'1'" [userType]="userData" [inventoryQty]="product.inventory_quantity" >
                                                </app-input-number>
                                            </div>
                                            <div style="display: contents;" *ngIf="userData?.user_type == 'MECH'">
                                                <ng-container *ngIf="product.inventory_quantity > 0 && product.moq <= product.inventory_quantity">
                                                    <div class="col-4 margin-left-add-to-cart me-2 p-0  text-end d-flex align-items-center ps-0" *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                                                        <button class="add-to-card" (click)="addToCart(product)"> <img alt=""
                                                                src="./assets/images/cart.svg" />
                                                            Add to Cart</button>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="(product?.inventory_quantity <= 0 || product?.inventory_quantity == null || product.moq > product.inventory_quantity) && product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                                                    <h6 class="col-7 text-end d-flex align-items-center" style="font-size: 13px;
                                                    position: relative;
                                                    font-weight: 700;
                                                    color: red;
                                                    top: 5px;">Out Of Stock</h6>
                                                </ng-container>
                                            </div>

                                            <div class="col-4 margin-left-add-to-cart me-2 p-0  text-end d-flex align-items-center ps-0" *ngIf="userData?.user_type != 'MECH' && product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                                                <button class="add-to-card" (click)="addToCart(product)"> <img alt=""
                                                        src="./assets/images/cart.svg" />
                                                    Add to Cart</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer" style="border-top: none; height: 25px;"
                                    *ngIf="(product.is_nls == 'in_active') || (product.is_nls != 'in_active' && (product?.alternate_part?true:false))">
                                        <div class="row">
                                            <div class="col-md-12">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </ng-template>
        </div>
    </div>
    <!-- End of Fleet Recommendation -->
    <!--New Product-->
    <div class="row new-product mb-4" *ngIf="showNewProducts">
        <div class="col-12 col-sm-12 col-lg-12 col-md-12 col-xl-12">
            <h2>New Products</h2>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <!-- <div class="new-product-list slider"> -->
            <ng-container *ngIf="!featuredProducts.length;else newProductsList;">
                <!-- <ng-container *ngIf="takeFalse"> -->
                <owl-carousel-o [options]="customOptionsForNewProducts">
                    <ng-container *ngFor="let item of userService.generateFake(7);let i = index;">
                        <ng-template carouselSlide [id]="i">
                            <div class="me-2 ms-2">
                                <div class="new-product-card mb-3">
                                    <div class="card-body cursor">
                                        <div class="row align-items-center">
                                            <div class="col-4 p-0 cursor">
                                                <div class="prod-image text-center">
                                                    <skc-rectangle [loading]="loading" [skc-height]="'80px'" [skc-width]="'100%'"> </skc-rectangle>
                                                </div>
                                            </div>
                                            <div class="col-8 cursor">
                                                <div class="row">
                                                    <div class="col-12  prod-desc">
                                                        <div class="modal-number">
                                                            <skc-rectangle [loading]="loading" [skc-height]="'19px'" [skc-width]="'100%'"> </skc-rectangle>
                                                        </div>
                                                        <div class="modal-name">
                                                            <skc-rectangle [loading]="loading" [skc-height]="'16px'" [skc-width]="'75%'"> </skc-rectangle>
                                                        </div>
                                                        <div class="delivery-time w-50 d-inline-block text-nowrap">
                                                            <skc-rectangle [loading]="loading" [skc-height]="'13px'" [skc-width]="'50%'"> </skc-rectangle>
                                                        </div>
                                                        <div class="delivery-time w-50 d-inline-block text-end text-nowrap">
                                                            <skc-rectangle [loading]="loading" [skc-height]="'13px'" [skc-width]="'50%'"> </skc-rectangle>
                                                        </div>
                                                        <div class="price fw-bold">
                                                            <skc-rectangle [loading]="loading" [skc-height]="'19px'" [skc-width]="'50%'"> </skc-rectangle>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <skc-rectangle [loading]="loading" [skc-height]="'22px'" [skc-width]="'100%'"> </skc-rectangle>
                                            </div>
                                            <div class="col-4 ms-auto me-2 p-0  text-end d-flex align-items-center ps-0">
                                                <skc-rectangle [loading]="loading" [skc-height]="'20px'" [skc-width]="'100%'"> </skc-rectangle>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </ng-container>
            <ng-template #newProductsList>
                <owl-carousel-o [options]="customOptionsForNewProducts">
                    <ng-container  *ngFor="let product of featuredProducts;let i=index;">
                        <ng-template carouselSlide [id]="product.part_number">
                            <div class="me-2 ms-2">
                                <div class="new-product-card mb-3">
                                    <div class="card-body cursor" [ngClass]="product.is_nls == 'in_active'?'no-service-body-2':'cart-body-2'" style="height: 180px;">
                                        <div class="row align-items-center">
                                            <div class="col-4 p-0 cursor" [routerLink]="['/shop/product-details']"
                                                [queryParams]="{ part_Number : product.part_number }">
                                                <div class="prod-image text-center">
                                                    <img class="product image img-fluid" [src]="product?.part_image_path + '/resize/117x80'" />
                                                </div>
                                            </div>
                                            <div class="col-8 cursor" >
                                                <div class="row">
                                                    <div class="col-12  prod-desc">
                                                        <div class="wishlist-m-top">
                                                            <app-wishlist-icon [product]="product" [layout]="'NewProdlayout'"></app-wishlist-icon>
                                                        </div>
                                                        <div [routerLink]="['/shop/product-details']"
                                                            [queryParams]="{ part_Number : product.part_number }" >
                                                            <div class="modal-name discription-m-top"
                                                                title="{{ (product.short_description == '' || product.short_description == null) ? '-' : product.short_description}}">
                                                                {{ (product.short_description == " " || product.short_description == null) ? "-" : (product.short_description.length > 20 ?(product.short_description.substr(0, 20) + '...') :product.short_description)}}
                                                            </div>
                                                            <div class="modal-number">
                                                                {{ product.part_number }}
                                                            </div>
                                                            <div class="delivery-time w-50 d-inline-block text-nowrap" style="text-overflow: ellipsis; overflow:hidden;"
                                                                *ngIf="product?.hsn_code && product?.hsn_code != ''">
                                                                HSN: <span title="{{ product?.hsn_code }}">{{ product?.hsn_code }}</span>
                                                            </div>
                                                            <div class="delivery-time w-50 d-inline-block text-end text-nowrap" style="text-overflow: ellipsis; overflow:hidden;"
                                                                *ngIf="product?.tax_slab && product?.tax_slab!=''">
                                                                GST: <span title="{{ product?.tax_slab }}">{{ product?.tax_slab }}%</span>
                                                            </div>
                                                            <!-- <div class="delivery-time">
                                                                Estd. Delivery: <span>Today</span>
                                                            </div> -->
                                                            <!-- <div class="price">
                                                                <img alt="indian rupee sign" src="./assets/images/rupee-indian.svg" />
                                                                990.00 <span>MRP 1100.00</span>
                                                            </div> -->
                                                            <ng-container *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true); else elseTemplate">
                                                            <ng-container *ngIf="!product.total_amount">
                                                                <div class="price fw-bold">
                                                                    {{ (( product.resource_type == 'Pack'  )?product.crm_mrp*product.moq:product.crm_mrp ) |currencyFormat:'INR' }}
                                                                </div>
                                                            </ng-container>
                                                            <ng-container *ngIf="product.total_amount">
                                                                <div *ngIf="(product.base_discount_per +  product.scheme_discount_per + product.rule_discount_per + product.distributor2_discount_amount) == 0.0">
                                                                    <div class="price fw-bold">
                                                                        {{ (( product.resource_type == 'Pack' )?product.crm_mrp*product.moq:product.crm_mrp ) |currencyFormat:'INR' }}
                                                                    </div>
                                                                </div>
                                                                <div class="price" *ngIf="(product.base_discount_per +  product.scheme_discount_per + product.rule_discount_per + product.distributor2_discount_amount) > 0.0">
                                                                    <div>
                                                                        {{  (( product.resource_type == 'Pack' ) ? product.total_amount*product.moq
                                                                        : product.total_amount) |currencyFormat:'INR'
                                                                        }}
                                                                        <div class="card-actual-mrp">
                                                                            <span class="pt-1">
                                                                                MRP {{
                                                                                (( product.resource_type == 'Pack' ) ? product.crm_mrp*product.moq
                                                                                : product.crm_mrp) |currencyFormat :'INR'
                                                                                }}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>
                                                        <ng-template #elseTemplate>
                                                            <div class="price fw-bold">
                                                            {{ (( product.resource_type == 'Pack' )?product.crm_mrp*product.moq:product.crm_mrp ) |currencyFormat:'INR' }}
                                                            </div>
                                                        </ng-template>
                                                            <div class="tmgproduct moqProductInfo" *ngIf="( product.resource_type == 'Pack' && product.moq > 1)">
                                                                <p class="save-mode">1 Unit contains {{product.moq | number: '1.0-0'}} <span *ngIf="product.moq > 1">Quantities</span><span *ngIf="product.moq <= 1">Quantity</span></p>
                                                                <!-- <br>(MRP is applicable for 1 Unit =
                                                                {{ product.column_4 ? product?.column_4 / 1000 + ' litres' : product.moq/1 + ' Nos.' }}
                                                                ) -->
                                                            </div>
                                                            <span class="error-message-service text-nowrap" *ngIf="product.is_nls == 'in_active'">No Longer Serviced</span>
                                                            <span class="error-message-service text-nowrap" *ngIf="product?.is_nls != 'in_active' && product?.alternate_part?true:false">No
                                                                Longer available</span>
                                                            <div class="col-12 view-alternate text-nowrap mt-1" *ngIf="product.is_nls == 'in_active' || (product?.alternate_part?true:false)">
                                                                <span [routerLink]="['/shop/product-details']"
                                                                    [queryParams]="{ part_Number : product.part_number }" style="cursor: pointer;">View Alternate Part</span>
                                                            </div>
                                                        </div>
                                                        <!-- <div class="col-1 text-end p-0">
                                                             <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <ng-container *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                                            <div class="discount-for-newproduct" *ngIf="(convertToFloat(product?.base_discount_per) + convertToFloat(product?.scheme_discount_per) +
                                                        convertToFloat(product?.tml_discount_per) + convertToFloat(product?.distributor_discount_per) +
                                                        convertToFloat(product?.rule_discount_per) + convertToFloat(product.distributor2_discount_amount)) > 0" [routerLink]="['/shop/product-details']"
                                                [queryParams]="{ part_Number : product.part_number }">
                                                <!-- {{(product?.base_discount + product?.scheme_discount +
                                                    product?.tml_rule_discount + product?.dealer_rule_discount +
                                                    product?.rule_discount)}} % -->
                                                {{'SAVE ' + (((product.crm_mrp-product.total_amount)/product.crm_mrp)*100)?.toFixed(1) + '%'}}
                                            </div>
                                        </ng-container>
                                        </div>
                                    </div>
                                    <hr *ngIf="product.is_nls != 'in_active'  && (product?.alternate_part?false:true)" style="margin: 0px;" />
                                    <div class="card-footer" style="border-top: none;">
                                        <div class="row">
                                            <div class="col-4" *ngIf="(userData?.user_type == 'MECH' && (product?.inventory_quantity != 0 && product?.inventory_quantity != null && product.moq <= product.inventory_quantity) && product.is_nls != 'in_active'  && (product?.alternate_part?false:true)) || (userData?.user_type != 'MECH' && product.is_nls != 'in_active'  && (product?.alternate_part?false:true))">
                                                <app-input-number aria-label="Quantity" (currentQuantity)="readQuantity($event,product)" [max]="5"
                                                size="lg" [min]="1" [defaultValue]="product.min_quantity" [isTmgoValue]="product.resource_type == 'Pack' ?product.moq:'1'" [userType]="userData" [inventoryQty]="product.inventory_quantity" >
                                                </app-input-number>
                                            </div>
                                            <div style="display: contents;" *ngIf="userData?.user_type == 'MECH'">
                                                <ng-container *ngIf="product?.inventory_quantity > 0 && product.moq <= product.inventory_quantity">
                                                    <div class="col-4 margin-right-add-to-cart me-2 p-0  text-end d-flex align-items-center ps-0" *ngIf="product.is_nls != 'in_active'  && (product?.alternate_part?false:true)">
                                                        <button class="add-to-card" (click)="addToCart(product)"> <img alt=""
                                                                src="./assets/images/cart.svg" />
                                                            Add to Cart</button>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="(product?.inventory_quantity <= 0 || product?.inventory_quantity == null || product.moq > product.inventory_quantity) && product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                                                    <h6 class="col-7 text-end d-flex align-items-center" style="font-size: 13px;
                                                    position: relative;
                                                    font-weight: 700;
                                                    color: red;
                                                    top: 5px;">Out Of Stock</h6>
                                                </ng-container>
                                            </div>
                                            <div class="col-4 margin-right-add-to-cart me-2 p-0  text-end d-flex align-items-center ps-0" *ngIf="userData?.user_type != 'MECH' && product.is_nls != 'in_active'  && (product?.alternate_part?false:true)">
                                                <button class="add-to-card" (click)="addToCart(product)"> <img alt=""
                                                        src="./assets/images/cart.svg" />
                                                    Add to Cart</button>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="card-footer" style="border-top: none; height: 25px;"
                                    *ngIf="(product.is_nls == 'in_active') || (product.is_nls != 'in_active' && (product?.alternate_part?true:false))">
                                        <div class="row">
                                            <div class="col-md-12">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="card">
                                        <div class="card-body">
                                            <div class="row align-items-center">
                                                <div class="col-4" [routerLink]="['/shop/product-details']"
                                                [queryParams]="{ part_Number : product.part_number }">
                                                    <div class="prod-image text-center">
                                                        <img class="product image img-fluid" [src]="product?.image" />
                                                    </div>
                                                </div>
                                                <div class="col-8" [routerLink]="['/shop/product-details']"
                                                [queryParams]="{ part_Number : product.part_number }">
                                                    <div class="row">
                                                        <div class="col-11  prod-desc">
                                                            <div class="modal-number">
                                                                {{ product.part_number }}
                                                            </div>
                                                            <div class="modal-name" title="{{ (product.part_desc == '' || product.part_desc == null) ? '-' : product.part_desc}}">
                                                                {{ (product.part_desc == " " || product.part_desc == null) ? "-" :
                                                                product.part_desc?.substr(0,20)}}
                                                            </div>
                                                            <div class="delivery-time">
                                                                Estd. Delivery: <span>Today</span>
                                                            </div>
                                                            <div class="price">
                                                                <img alt="indian rupee sign" src="./assets/images/rupee-indian.svg" />
                                                                990.00 <span>MRP 1100.00</span>
                                                            </div>

                                                        </div>
                                                        <div class="col-1 text-end p-0">
                                                             <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                                        </div>

                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="col-4 offset-4">
                                                    <app-input-number aria-label="Quantity" (currentQuantity)="readQuantity($event)"
                                                        size="lg" [min]="1" [defaultValue]="product.min_quantity">
                                                    </app-input-number>
                                                </div>
                                                <div class="col-4 text-end d-flex align-items-center ps-0">
                                                    <button class="add-to-card" (click)="addToCart(product)"><img alt="" src="./assets/images/cart.svg"/>
                                                        Add to Cart</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </ng-template>


            <!-- <div class="me-2 ms-2">
                    <div class="card mb-3">
                        <div class="card-body">
                            <div class="row align-items-center">
                                <div class="col-4">
                                    <div class="prod-image text-center">
                                        <img class="product image img-fluid" src="./assets/images/menuIcon.PNG" />
                                    </div>
                                </div>
                                <div class="col-8">
                                    <div class="row">
                                        <div class="col-11  prod-desc">
                                            <div class="modal-number">
                                                266835300130
                                            </div>
                                            <div class="modal-name">
                                                Crown W. & Pinion (37/7),OER - Spirac BS4
                                            </div>
                                            <div class="delivery-time">
                                                Estd. Delivery: <span>Today</span>
                                            </div>
                                            <div class="price">
                                                <img alt="indian rupee sign" src="./assets/images/rupee-indian.svg" />
                                                990.00 <span>MRP 1100.00</span>
                                            </div>

                                        </div>
                                        <div class="col-1 text-end p-0">
                                            <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                        </div>

                                    </div>
                                </div>
                                <hr />
                                <div class="col-4 offset-4">
                                    <app-input-number aria-label="Quantity"  size="lg" [min]="1" [defaultValue]="1">  </app-input-number>
                                </div>
                                <div class="col-4 text-end d-flex align-items-center  ps-0">
                                    <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/>
                                        Add to Cart</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-body">
                            <div class="row align-items-center">
                                <div class="col-4">
                                    <div class="prod-image text-center">
                                        <img class="product image img-fluid" src="./assets/images/menuIcon.PNG" />
                                    </div>
                                </div>
                                <div class="col-8">
                                    <div class="row">
                                        <div class="col-11  prod-desc">
                                            <div class="modal-number">
                                                266835300130
                                            </div>
                                            <div class="modal-name">
                                                Crown W. & Pinion (37/7),OER - Spirac BS4
                                            </div>
                                            <div class="delivery-time">
                                                Estd. Delivery: <span>Today</span>
                                            </div>
                                            <div class="price">
                                                <img alt="indian rupee sign" src="./assets/images/rupee-indian.svg" />
                                                990.00 <span>MRP 1100.00</span>
                                            </div>

                                        </div>
                                        <div class="col-1 text-end p-0">
                                             <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                        </div>

                                    </div>
                                </div>
                                <hr />
                                <div class="col-4 offset-4">
                                    <app-input-number aria-label="Quantity"  size="lg" [min]="1" [defaultValue]="1">  </app-input-number>
                                </div>
                                <div class="col-4 text-end d-flex align-items-center  ps-0">
                                    <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/>
                                        Add to Cart</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="me-2 ms-2">
                    <div class="card mb-3">
                        <div class="card-body">
                            <div class="row align-items-center">
                                <div class="col-4">
                                    <div class="prod-image text-center">
                                        <img class="product image img-fluid" src="./assets/images/menuIcon.PNG" />
                                    </div>
                                </div>
                                <div class="col-8">
                                    <div class="row">
                                        <div class="col-11  prod-desc">
                                            <div class="modal-number">
                                                266835300130
                                            </div>
                                            <div class="modal-name">
                                                Crown W. & Pinion (37/7),OER - Spirac BS4
                                            </div>
                                            <div class="delivery-time">
                                                Estd. Delivery: <span>Today</span>
                                            </div>
                                            <div class="price">
                                                <img alt="indian rupee sign" src="./assets/images/rupee-indian.svg" />
                                                990.00 <span>MRP 1100.00</span>
                                            </div>

                                        </div>
                                        <div class="col-1 text-end p-0">
                                             <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                        </div>

                                    </div>
                                </div>
                                <hr />
                                <div class="col-4 offset-4">
                                    <app-input-number aria-label="Quantity"  size="lg" [min]="1" [defaultValue]="1">  </app-input-number>
                                </div>
                                <div class="col-4 text-end d-flex align-items-center  ps-0">
                                    <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/>
                                        Add to Cart</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-body">
                            <div class="row align-items-center">
                                <div class="col-4">
                                    <div class="prod-image text-center">
                                        <img class="product image img-fluid" src="./assets/images/menuIcon.PNG" />
                                    </div>
                                </div>
                                <div class="col-8">
                                    <div class="row">
                                        <div class="col-11  prod-desc">
                                            <div class="modal-number">
                                                266835300130
                                            </div>
                                            <div class="modal-name">
                                                Crown W. & Pinion (37/7),OER - Spirac BS4
                                            </div>
                                            <div class="delivery-time">
                                                Estd. Delivery: <span>Today</span>
                                            </div>
                                            <div class="price">
                                                <img alt="indian rupee sign" src="./assets/images/rupee-indian.svg" />
                                                990.00 <span>MRP 1100.00</span>
                                            </div>

                                        </div>
                                        <div class="col-1 text-end p-0">
                                             <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                        </div>

                                    </div>
                                </div>
                                <hr />
                                <div class="col-4 offset-4">
                                    <app-input-number aria-label="Quantity"  size="lg" [min]="1" [defaultValue]="1">  </app-input-number>
                                </div>
                                <div class="col-4 text-end d-flex align-items-center  ps-0">
                                    <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/>
                                        Add to Cart</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="me-2 ms-2">
                    <div class="card mb-3">
                        <div class="card-body">
                            <div class="row align-items-center">
                                <div class="col-4">
                                    <div class="prod-image text-center">
                                        <img class="product image img-fluid" src="./assets/images/menuIcon.PNG" />
                                    </div>
                                </div>
                                <div class="col-8">
                                    <div class="row">
                                        <div class="col-11  prod-desc">
                                            <div class="modal-number">
                                                266835300130
                                            </div>
                                            <div class="modal-name">
                                                Crown W. & Pinion (37/7),OER - Spirac BS4
                                            </div>
                                            <div class="delivery-time">
                                                Estd. Delivery: <span>Today</span>
                                            </div>
                                            <div class="price">
                                                <img alt="indian rupee sign" src="./assets/images/rupee-indian.svg" />
                                                990.00 <span>MRP 1100.00</span>
                                            </div>

                                        </div>
                                        <div class="col-1 text-end p-0">
                                             <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                        </div>

                                    </div>
                                </div>
                                <hr />
                                <div class="col-4 offset-4">
                                    <app-input-number aria-label="Quantity"  size="lg" [min]="1" [defaultValue]="1">  </app-input-number>
                                </div>
                                <div class="col-4 text-end d-flex align-items-center  ps-0">
                                    <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/>
                                        Add to Cart</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-body">
                            <div class="row align-items-center">
                                <div class="col-4">
                                    <div class="prod-image text-center">
                                        <img class="product image img-fluid" src="./assets/images/menuIcon.PNG" />
                                    </div>
                                </div>
                                <div class="col-8">
                                    <div class="row">
                                        <div class="col-11  prod-desc">
                                            <div class="modal-number">
                                                266835300130
                                            </div>
                                            <div class="modal-name">
                                                Crown W. & Pinion (37/7),OER - Spirac BS4
                                            </div>
                                            <div class="delivery-time">
                                                Estd. Delivery: <span>Today</span>
                                            </div>
                                            <div class="price">
                                                <img alt="indian rupee sign" src="./assets/images/rupee-indian.svg" />
                                                990.00 <span>MRP 1100.00</span>
                                            </div>

                                        </div>
                                        <div class="col-1 text-end p-0">
                                             <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                        </div>

                                    </div>
                                </div>
                                <hr />
                                <div class="col-4 offset-4">
                                    <app-input-number aria-label="Quantity"  size="lg" [min]="1" [defaultValue]="1">  </app-input-number>
                                </div>
                                <div class="col-4 text-end d-flex align-items-center  ps-0">
                                    <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/>
                                        Add to Cart</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="me-2 ms-2">
                    <div class="card mb-3">
                        <div class="card-body">
                            <div class="row align-items-center">
                                <div class="col-4">
                                    <div class="prod-image text-center">
                                        <img class="product image img-fluid" src="./assets/images/menuIcon.PNG" />
                                    </div>
                                </div>
                                <div class="col-8">
                                    <div class="row">
                                        <div class="col-11  prod-desc">
                                            <div class="modal-number">
                                                266835300130
                                            </div>
                                            <div class="modal-name">
                                                Crown W. & Pinion (37/7),OER - Spirac BS4
                                            </div>
                                            <div class="delivery-time">
                                                Estd. Delivery: <span>Today</span>
                                            </div>
                                            <div class="price">
                                                <img alt="indian rupee sign" src="./assets/images/rupee-indian.svg" />
                                                990.00 <span>MRP 1100.00</span>
                                            </div>

                                        </div>
                                        <div class="col-1 text-end p-0">
                                             <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                        </div>

                                    </div>
                                </div>
                                <hr />
                                <div class="col-4 offset-4">
                                    <app-input-number aria-label="Quantity"  size="lg" [min]="1" [defaultValue]="1">  </app-input-number>
                                </div>
                                <div class="col-4 text-end d-flex align-items-center  ps-0">
                                    <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/>
                                        Add to Cart</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-body">
                            <div class="row align-items-center">
                                <div class="col-4">
                                    <div class="prod-image text-center">
                                        <img class="product image img-fluid" src="./assets/images/menuIcon.PNG" />
                                    </div>
                                </div>
                                <div class="col-8">
                                    <div class="row">
                                        <div class="col-11  prod-desc">
                                            <div class="modal-number">
                                                266835300130
                                            </div>
                                            <div class="modal-name">
                                                Crown W. & Pinion (37/7),OER - Spirac BS4
                                            </div>
                                            <div class="delivery-time">
                                                Estd. Delivery: <span>Today</span>
                                            </div>
                                            <div class="price">
                                                <img alt="indian rupee sign" src="./assets/images/rupee-indian.svg" />
                                                990.00 <span>MRP 1100.00</span>
                                            </div>

                                        </div>
                                        <div class="col-1 text-end p-0">
                                            <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                        </div>

                                    </div>
                                </div>
                                <hr />
                                <div class="col-4 offset-4">
                                    <app-input-number aria-label="Quantity"  size="lg" [min]="1" [defaultValue]="1">  </app-input-number>
                                </div>
                                <div class="col-4 text-end d-flex align-items-center  ps-0">
                                    <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/>
                                        Add to Cart</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="me-2 ms-2">
                    <div class="card mb-3">
                        <div class="card-body">
                            <div class="row align-items-center">
                                <div class="col-4">
                                    <div class="prod-image text-center">
                                        <img class="product image img-fluid" src="./assets/images/menuIcon.PNG" />
                                    </div>
                                </div>
                                <div class="col-8">
                                    <div class="row">
                                        <div class="col-11  prod-desc">
                                            <div class="modal-number">
                                                266835300130
                                            </div>
                                            <div class="modal-name">
                                                Crown W. & Pinion (37/7),OER - Spirac BS4
                                            </div>
                                            <div class="delivery-time">
                                                Estd. Delivery: <span>Today</span>
                                            </div>
                                            <div class="price">
                                                <img alt="indian rupee sign" src="./assets/images/rupee-indian.svg" />
                                                990.00 <span>MRP 1100.00</span>
                                            </div>

                                        </div>
                                        <div class="col-1 text-end p-0">
                                             <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                        </div>

                                    </div>
                                </div>
                                <hr />
                                <div class="col-4 offset-4">
                                    <app-input-number aria-label="Quantity"  size="lg" [min]="1" [defaultValue]="1">  </app-input-number>
                                </div>
                                <div class="col-4 text-end d-flex align-items-center  ps-0">
                                    <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/>
                                        Add to Cart</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-body">
                            <div class="row align-items-center">
                                <div class="col-4">
                                    <div class="prod-image text-center">
                                        <img class="product image img-fluid" src="./assets/images/menuIcon.PNG" />
                                    </div>
                                </div>
                                <div class="col-8">
                                    <div class="row">
                                        <div class="col-11  prod-desc">
                                            <div class="modal-number">
                                                266835300130
                                            </div>
                                            <div class="modal-name">
                                                Crown W. & Pinion (37/7),OER - Spirac BS4
                                            </div>
                                            <div class="delivery-time">
                                                Estd. Delivery: <span>Today</span>
                                            </div>
                                            <div class="price">
                                                <img alt="indian rupee sign" src="./assets/images/rupee-indian.svg" />
                                                990.00 <span>MRP 1100.00</span>
                                            </div>

                                        </div>
                                        <div class="col-1 text-end p-0">
                                             <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                        </div>

                                    </div>
                                </div>
                                <hr />
                                <div class="col-4 offset-4">
                                    <app-input-number aria-label="Quantity"  size="lg" [min]="1" [defaultValue]="1">  </app-input-number>
                                </div>
                                <div class="col-4 text-end d-flex align-items-center  ps-0">
                                    <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/>
                                        Add to Cart</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            <!-- </div> -->
        </div>
    </div>
    <!--Mansoon offer-->
    <!-- <div class="row mansoon-offer mb-4 justify-content-md-end align-items-center">
        <div class="col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 text-left">
            <span class="mansoon-text">
                MONSOON
            </span>
            <span class="mansoon-text text-white">OFFER <img alt="" src="./assets/images/umbrella.png" /></span>
        </div>
        <div class="col-12 col-sm-12 col-md-9 col-lg-10 col-xl-10 pt-4 pb-4 ps-5 pe-0">
            <div class="mansoon slider">
                <div class="me-2">
                    <div class="card">
                        <div class="card-body">
                            <div class="prod-image">
                                <img class="product image" src="./assets/images/menuIcon.PNG" />
                            </div>
                            <div class="prod-desc">
                                <div class="row">
                                    <div class="col-11">
                                        <div class="modal-number">
                                            266835300130
                                        </div>
                                        <div class="modal-name">
                                            Crown W. & Pinion (37/7),OER - Spirac BS4
                                        </div>
                                        <div class="delivery-time">
                                            Estd. Delivery: <span>Today</span>
                                        </div>
                                        <div class="price">
                                            <img alt="indian rupee sign" src="./assets/images/rupee-indian.svg" />
                                            990.00 <span>MRP 1100.00</span>
                                        </div>

                                    </div>
                                    <div class="col-1 text-end p-0">
                                         <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                    </div>
                                    <hr />
                                    <div class="col-6 ">
                                        <app-input-number aria-label="Quantity"  size="lg" [min]="1" [defaultValue]="1">  </app-input-number>
                                    </div>
                                    <div class="col-6 text-end d-flex align-items-center">
                                        <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/> Add to Cart</button>
                                    </div>
                                </div>
                                <div class="discount" *ngIf="isLoggedIn">
                                    20%
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="me-2">
                    <div class="card">
                        <div class="card-body">
                            <div class="prod-image">
                                <img class="product image" src="./assets/images/menuIcon.PNG" />
                            </div>
                            <div class="prod-desc">
                                <div class="row">
                                    <div class="col-11">
                                        <div class="modal-number">
                                            266835300130
                                        </div>
                                        <div class="modal-name">
                                            Crown W. & Pinion (37/7),OER - Spirac BS4
                                        </div>
                                        <div class="delivery-time">
                                            Estd. Delivery: <span>Today</span>
                                        </div>
                                        <div class="price">
                                            <img alt="indian rupee sign" src="./assets/images/rupee-indian.svg" />
                                            990.00 <span>MRP 1100.00</span>
                                        </div>

                                    </div>
                                    <div class="col-1 text-end p-0">
                                         <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                    </div>
                                    <hr />
                                    <div class="col-6 ">
                                        <app-input-number aria-label="Quantity"  size="lg" [min]="1" [defaultValue]="1">  </app-input-number>
                                    </div>
                                    <div class="col-6 text-end d-flex align-items-center">
                                        <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/> Add to Cart</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="me-2">
                    <div class="card">
                        <div class="card-body">
                            <div class="prod-image">
                                <img class="product image" src="./assets/images/menuIcon.PNG" />
                            </div>
                            <div class="prod-desc">
                                <div class="row">
                                    <div class="col-11">
                                        <div class="modal-number">
                                            266835300130
                                        </div>
                                        <div class="modal-name">
                                            Crown W. & Pinion (37/7),OER - Spirac BS4
                                        </div>
                                        <div class="delivery-time">
                                            Estd. Delivery: <span>Today</span>
                                        </div>
                                        <div class="price">
                                            <img alt="indian rupee sign" src="./assets/images/rupee-indian.svg" />
                                            990.00 <span>MRP 1100.00</span>
                                        </div>

                                    </div>
                                    <div class="col-1 text-end p-0">
                                         <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                    </div>
                                    <hr />
                                    <div class="col-6 ">
                                        <app-input-number aria-label="Quantity"  size="lg" [min]="1" [defaultValue]="1">  </app-input-number>
                                    </div>
                                    <div class="col-6 text-end d-flex align-items-center">
                                        <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/> Add to Cart</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="me-2">
                    <div class="card">
                        <div class="card-body">
                            <div class="prod-image">
                                <img class="product image" src="./assets/images/menuIcon.PNG" />
                            </div>
                            <div class="prod-desc">
                                <div class="row">
                                    <div class="col-11">
                                        <div class="modal-number">
                                            266835300130
                                        </div>
                                        <div class="modal-name">
                                            Crown W. & Pinion (37/7),OER - Spirac BS4
                                        </div>
                                        <div class="delivery-time">
                                            Estd. Delivery: <span>Today</span>
                                        </div>
                                        <div class="price">
                                            <img alt="indian rupee sign" src="./assets/images/rupee-indian.svg" />
                                            990.00 <span>MRP 1100.00</span>
                                        </div>

                                    </div>
                                    <div class="col-1 text-end p-0">
                                         <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                    </div>
                                    <hr />
                                    <div class="col-6 ">
                                        <app-input-number aria-label="Quantity"  size="lg" [min]="1" [defaultValue]="1">  </app-input-number>
                                    </div>
                                    <div class="col-6 text-end d-flex align-items-center">
                                        <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/> Add to Cart</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="me-2">
                    <div class="card">
                        <div class="card-body">
                            <div class="prod-image">
                                <img class="product image" src="./assets/images/menuIcon.PNG" />
                            </div>
                            <div class="prod-desc">
                                <div class="row">
                                    <div class="col-11">
                                        <div class="modal-number">
                                            266835300130
                                        </div>
                                        <div class="modal-name">
                                            Crown W. & Pinion (37/7),OER - Spirac BS4
                                        </div>
                                        <div class="delivery-time">
                                            Estd. Delivery: <span>Today</span>
                                        </div>
                                        <div class="price">
                                            <img alt="indian rupee sign" src="./assets/images/rupee-indian.svg" />
                                            990.00 <span>MRP 1100.00</span>
                                        </div>

                                    </div>
                                    <div class="col-1 text-end p-0">
                                         <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                    </div>
                                    <hr />
                                    <div class="col-6 ">
                                        <app-input-number aria-label="Quantity"  size="lg" [min]="1" [defaultValue]="1">  </app-input-number>
                                    </div>
                                    <div class="col-6 text-end d-flex align-items-center">
                                        <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/> Add to Cart</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="me-2">
                    <div class="card">
                        <div class="card-body">
                            <div class="prod-image">
                                <img class="product image" src="./assets/images/menuIcon.PNG" />
                            </div>
                            <div class="prod-desc">
                                <div class="row">
                                    <div class="col-11">
                                        <div class="modal-number">
                                            266835300130
                                        </div>
                                        <div class="modal-name">
                                            Crown W. & Pinion (37/7),OER - Spirac BS4
                                        </div>
                                        <div class="delivery-time">
                                            Estd. Delivery: <span>Today</span>
                                        </div>
                                        <div class="price">
                                            <img alt="indian rupee sign" src="./assets/images/rupee-indian.svg" />
                                            990.00 <span>MRP 1100.00</span>
                                        </div>

                                    </div>
                                    <div class="col-1 text-end p-0">
                                         <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                    </div>
                                    <hr />
                                    <div class="col-6 ">
                                        <app-input-number aria-label="Quantity"  size="lg" [min]="1" [defaultValue]="1">  </app-input-number>
                                    </div>
                                    <div class="col-6 text-end d-flex align-items-center">
                                        <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/> Add to Cart</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <!--Mega Cost Saving-->
    <div class="row mega-cost-saving mb-4" *ngIf="showeDukaanFeatures">
        <div
            class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 mb-3 d-flex justify-content-between align-items-center">
            <div>
                <h2>e-Dukaan Features</h2>
                <div class="sub-title">Get your Tata Parts from nearest distributor in your area at great prices.</div>
            </div>
            <!-- <a class="view-all">
                View All <i class="fas fa-angle-right"></i>
            </a> -->
        </div>
        <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12">
            <!-- style="display:flex" -->
            <!-- <div class="mega-cost-saving-list slider" >  -->
            <ng-container *ngIf="!megaCostBannerList?.length;else mcsList">
                <owl-carousel-o [options]="customOptions">
                    <ng-container *ngFor="let item of userService.generateFake(7);let i = index;">
                        <ng-template carouselSlide [id]="i">
                            <div class="me-2 ms-2">
                                <div class="card mb-3">
                                    <skc-rectangle [loading]="loading" [skc-height]="'150px'" [skc-width]="'100%'"> </skc-rectangle>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </ng-container>
            <ng-template #mcsList>
                <owl-carousel-o [options]="customOptions">
                    <ng-container *ngFor="let data of megaCostBannerList">
                        <ng-template carouselSlide [id]="data?.description">
                            <div class="me-2 ms-2">
                                <div class="card mb-3" (click)="onBannerRedirect(data,'MegaCostBanner')" [ngStyle]="{'cursor':data?.banner_hyper_link ? 'pointer' : ''}">
                                    <img [src]="data?.banner_url + '/resize/356x201'" class="card-img-top" alt="car" style="height: 201px;">
                                    <div *ngIf="data?.description" class="cost-saving text-center">
                                        {{data?.description | uppercase}}
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </ng-template>

            <!-- <div class="me-2 ms-2">
                    <div class="card mb-3">
                        <img src="./assets/images/Group 14.png" class="card-img-top" alt="car">
                        <div class="cost-saving text-center">
                            SHOP FOR FLEET. MEGA 15% OFF
                        </div>
                    </div>
                    <div class="card">
                        <img src="./assets/images/Group 15.png" class="card-img-top" alt="car">
                        <div class="cost-saving text-center">
                            SHOP FOR FLEET. MEGA 15% OFF
                        </div>
                    </div>
                </div>

                 <div class="me-2 ms-2">
                    <div class="card mb-3">
                        <img src="./assets/images/Group 18.png" class="card-img-top" alt="car">
                        <div class="cost-saving text-center">
                            SHOP FOR FLEET. MEGA 15% OFF
                        </div>
                    </div>
                    <div class="card">
                        <img src="./assets/images/Group 19.png" class="card-img-top" alt="car">
                        <div class="cost-saving text-center">
                            SHOP FOR FLEET. MEGA 15% OFF
                        </div>
                    </div>
                </div>
                <div class="me-2 ms-2">
                    <div class="card mb-3">
                        <img src="./assets/images/Group 14.png" class="card-img-top" alt="car">
                        <div class="cost-saving text-center">
                            SHOP FOR FLEET. MEGA 15% OFF
                        </div>
                    </div>
                    <div class="card ">
                        <img src="./assets/images/Group 15.png" class="card-img-top" alt="car">
                        <div class="cost-saving text-center">
                            SHOP FOR FLEET. MEGA 15% OFF
                        </div>
                    </div>
                </div>
                <div class="me-2 ms-2">
                    <div class="card mb-3">
                        <img src="./assets/images/Group 18.png" class="card-img-top" alt="car">
                        <div class="cost-saving text-center">
                            SHOP FOR FLEET. MEGA 15% OFF
                        </div>
                    </div>
                    <div class="card ">
                        <img src="./assets/images/Group 19.png" class="card-img-top" alt="car">
                        <div class="cost-saving text-center">
                            SHOP FOR FLEET. MEGA 15% OFF
                        </div>
                    </div>
                </div>
                <div class="me-2 ms-2">
                    <div class="card mb-3">
                        <img src="./assets/images/Group 14.png" class="card-img-top" alt="car">
                        <div class="cost-saving text-center">
                            SHOP FOR FLEET. MEGA 15% OFF
                        </div>
                    </div>
                    <div class="card ">
                        <img src="./assets/images/Group 15.png" class="card-img-top" alt="car">
                        <div class="cost-saving text-center">
                            SHOP FOR FLEET. MEGA 15% OFF
                        </div>
                    </div>
                </div>
                <div class="me-2 ms-2">
                    <div class="card mb-3">
                        <img src="./assets/images/Group 18.png" class="card-img-top" alt="car">
                        <div class="cost-saving text-center">
                            SHOP FOR FLEET. MEGA 15% OFF
                        </div>
                    </div>
                    <div class="card ">
                        <img src="./assets/images/Group 19.png" class="card-img-top" alt="car">
                        <div class="cost-saving text-center">
                            SHOP FOR FLEET. MEGA 15% OFF
                        </div>
                    </div>
                </div> -->
            <!-- </div> -->
        </div>
    </div>
    <!--MY INVENTORY-->
    <!-- <div class="row my-inventory mb-4">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <h2>MY INVENTORY</h2>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

            <nav>
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <a class="nav-link active" id="nav-Routine-tab" data-bs-toggle="tab" href="#nav-Routine" role="tab"
                        aria-controls="nav-Routine" aria-selected="true">Routine parts</a>
                    <a class="nav-link" id="nav-Service-tab" data-bs-toggle="tab" href="#nav-Service" role="tab"
                        aria-controls="nav-Service" aria-selected="false">Service kits</a>
                    <a class="nav-link" id="nav-Replacement-tab" data-bs-toggle="tab" href="#nav-Replacement" role="tab"
                        aria-controls="nav-Replacement" aria-selected="false">Replacement Kits</a>
                </div>
            </nav>
            <div class="tab-content mt-3" id="nav-tabContent ">
                <div class="tab-pane fade show active" id="nav-Routine" role="tabpanel"
                    aria-labelledby="nav-Routine-tab">
                    <div class="customer-favourite-list slider">
                        <div class="me-2">
                            <div class="card mb-4">
                                <div class="card-body">
                                    <div class="prod-image">
                                        <img class="product image" src="./assets/images/menuIcon.PNG" />
                                    </div>
                                    <div class="prod-desc">
                                        <div class="row">
                                            <div class="col-12 quant-left">
                                                1 Unit left
                                            </div>
                                            <div class="col-11">
                                                <div class="modal-number">
                                                    266835300130
                                                </div>
                                                <div class="modal-name">
                                                    Crown W. & Pinion (37/7),OER - Spirac BS4
                                                </div>
                                                <div class="delivery-time">
                                                    Estd. Delivery: <span>Today</span>
                                                </div>
                                                <div class="price">
                                                    <img alt="indian rupee sign"
                                                        src="./assets/images/rupee-indian.svg" /> 990.00 <span>MRP 1100.00</span>
                                                </div>

                                            </div>
                                            <div class="col-1 text-end p-0">
                                                 <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                            </div>
                                            <hr />
                                            <div class="col-5 ">
                                                <app-input-number aria-label="Quantity"  size="lg" [min]="1" [defaultValue]="1"></app-input-number>
                                            </div>
                                            <div class="col-7 text-end d-flex align-items-center">
                                                <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/> Add to Cart</button>
                                            </div>
                                        </div>
                                        <div class="discount" *ngIf="isLoggedIn">
                                            20%
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-body">
                                    <div class="prod-image">
                                        <img class="product image" src="./assets/images/menuIcon.PNG" />
                                    </div>
                                    <div class="prod-desc">
                                        <div class="row">
                                            <div class="col-12 quant-left">
                                                1 Unit left
                                            </div>
                                            <div class="col-11">
                                                <div class="modal-number">
                                                    266835300130
                                                </div>
                                                <div class="modal-name">
                                                    Crown W. & Pinion (37/7),OER - Spirac BS4
                                                </div>
                                                <div class="delivery-time">
                                                    Estd. Delivery: <span>Today</span>
                                                </div>
                                                <div class="price">
                                                    <img alt="indian rupee sign"
                                                        src="./assets/images/rupee-indian.svg" /> 990.00 <span>MRP 1100.00</span>
                                                </div>

                                            </div>
                                            <div class="col-1 text-end p-0">
                                                 <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                            </div>
                                            <hr />
                                            <div class="col-5">
                                                <app-input-number aria-label="Quantity"  size="lg" [min]="1" [defaultValue]="1"></app-input-number>
                                            </div>
                                            <div class="col-7 text-end d-flex align-items-center">
                                                <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/> Add to Cart</button>
                                            </div>
                                        </div>
                                        <div class="discount" *ngIf="isLoggedIn">
                                            20%
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="me-2">
                            <div class="card mb-4">
                                <div class="card-body">
                                    <div class="prod-image">
                                        <img class="product image" src="./assets/images/menuIcon.PNG" />
                                    </div>
                                    <div class="prod-desc">
                                        <div class="row">
                                            <div class="col-12 quant-left">
                                                1 Unit left
                                            </div>
                                            <div class="col-11">
                                                <div class="modal-number">
                                                    266835300130
                                                </div>
                                                <div class="modal-name">
                                                    Crown W. & Pinion (37/7),OER - Spirac BS4
                                                </div>
                                                <div class="delivery-time">
                                                    Estd. Delivery: <span>Today</span>
                                                </div>
                                                <div class="price">
                                                    <img alt="indian rupee sign"
                                                        src="./assets/images/rupee-indian.svg" /> 990.00 <span>MRP 1100.00</span>
                                                </div>

                                            </div>
                                            <div class="col-1 text-end p-0">
                                                 <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                            </div>
                                            <hr />
                                            <div class="col-5 ">
                                                <app-input-number aria-label="Quantity"  size="lg" [min]="1" [defaultValue]="1"></app-input-number>
                                            </div>
                                            <div class="col-7 text-end d-flex align-items-center">
                                                <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/> Add to Cart</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-body">
                                    <div class="prod-image">
                                        <img class="product image" src="./assets/images/menuIcon.PNG" />
                                    </div>
                                    <div class="prod-desc">
                                        <div class="row">
                                            <div class="col-12 quant-left">
                                                1 Unit left
                                            </div>
                                            <div class="col-11">
                                                <div class="modal-number">
                                                    266835300130
                                                </div>
                                                <div class="modal-name">
                                                    Crown W. & Pinion (37/7),OER - Spirac BS4
                                                </div>
                                                <div class="delivery-time">
                                                    Estd. Delivery: <span>Today</span>
                                                </div>
                                                <div class="price">
                                                    <img alt="indian rupee sign"
                                                        src="./assets/images/rupee-indian.svg" /> 990.00 <span>MRP 1100.00</span>
                                                </div>

                                            </div>
                                            <div class="col-1 text-end p-0">
                                                 <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                            </div>
                                            <hr />
                                            <div class="col-5 ">
                                                <app-input-number aria-label="Quantity"  size="lg" [min]="1" [defaultValue]="1"></app-input-number>
                                            </div>
                                            <div class="col-7 text-end d-flex align-items-center">
                                                <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/> Add to Cart</button>
                                            </div>
                                        </div>
                                        <div class="discount" *ngIf="isLoggedIn">
                                            20%
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="me-2">
                            <div class="card mb-4">
                                <div class="card-body">
                                    <div class="prod-image">
                                        <img class="product image" src="./assets/images/menuIcon.PNG" />
                                    </div>
                                    <div class="prod-desc">
                                        <div class="row">
                                            <div class="col-12 quant-left">
                                                1 Unit left
                                            </div>
                                            <div class="col-11">
                                                <div class="modal-number">
                                                    266835300130
                                                </div>
                                                <div class="modal-name">
                                                    Crown W. & Pinion (37/7),OER - Spirac BS4
                                                </div>
                                                <div class="delivery-time">
                                                    Estd. Delivery: <span>Today</span>
                                                </div>
                                                <div class="price">
                                                    <img alt="indian rupee sign"
                                                        src="./assets/images/rupee-indian.svg" /> 990.00 <span>MRP 1100.00</span>
                                                </div>

                                            </div>
                                            <div class="col-1 text-end p-0">
                                                 <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                            </div>
                                            <hr />
                                            <div class="col-5 ">
                                                <app-input-number aria-label="Quantity"  size="lg" [min]="1" [defaultValue]="1"></app-input-number>
                                            </div>
                                            <div class="col-7 text-end d-flex align-items-center">
                                                <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/> Add to Cart</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-body">
                                    <div class="prod-image">
                                        <img class="product image" src="./assets/images/menuIcon.PNG" />
                                    </div>
                                    <div class="prod-desc">
                                        <div class="row">
                                            <div class="col-12 quant-left">
                                                1 Unit left
                                            </div>
                                            <div class="col-11">
                                                <div class="modal-number">
                                                    266835300130
                                                </div>
                                                <div class="modal-name">
                                                    Crown W. & Pinion (37/7),OER - Spirac BS4
                                                </div>
                                                <div class="delivery-time">
                                                    Estd. Delivery: <span>Today</span>
                                                </div>
                                                <div class="price">
                                                    <img alt="indian rupee sign"
                                                        src="./assets/images/rupee-indian.svg" /> 990.00 <span>MRP 1100.00</span>
                                                </div>

                                            </div>
                                            <div class="col-1 text-end p-0">
                                                 <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                            </div>
                                            <hr />
                                            <div class="col-5 ">
                                                <app-input-number aria-label="Quantity"  size="lg" [min]="1" [defaultValue]="1"></app-input-number>
                                            </div>
                                            <div class="col-7 text-end d-flex align-items-center">
                                                <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/> Add to Cart</button>
                                            </div>
                                        </div>
                                        <div class="discount" *ngIf="isLoggedIn">
                                            20%
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="me-2">
                            <div class="card mb-4">
                                <div class="card-body">
                                    <div class="prod-image">
                                        <img class="product image" src="./assets/images/menuIcon.PNG" />
                                    </div>
                                    <div class="prod-desc">
                                        <div class="row">
                                            <div class="col-12 quant-left">
                                                1 Unit left
                                            </div>
                                            <div class="col-11">
                                                <div class="modal-number">
                                                    266835300130
                                                </div>
                                                <div class="modal-name">
                                                    Crown W. & Pinion (37/7),OER - Spirac BS4
                                                </div>
                                                <div class="delivery-time">
                                                    Estd. Delivery: <span>Today</span>
                                                </div>
                                                <div class="price">
                                                    <img alt="indian rupee sign"
                                                        src="./assets/images/rupee-indian.svg" /> 990.00 <span>MRP 1100.00</span>
                                                </div>

                                            </div>
                                            <div class="col-1 text-end p-0">
                                                <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                            </div>
                                            <hr />
                                            <div class="col-5 ">
                                                <app-input-number aria-label="Quantity"  size="lg" [min]="1" [defaultValue]="1"></app-input-number>
                                            </div>
                                            <div class="col-7 text-end d-flex align-items-center">
                                                <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/> Add to Cart</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-body">
                                    <div class="prod-image">
                                        <img class="product image" src="./assets/images/menuIcon.PNG" />
                                    </div>
                                    <div class="prod-desc">
                                        <div class="row">
                                            <div class="col-12 quant-left">
                                                1 Unit left
                                            </div>
                                            <div class="col-11">
                                                <div class="modal-number">
                                                    266835300130
                                                </div>
                                                <div class="modal-name">
                                                    Crown W. & Pinion (37/7),OER - Spirac BS4
                                                </div>
                                                <div class="delivery-time">
                                                    Estd. Delivery: <span>Today</span>
                                                </div>
                                                <div class="price">
                                                    <img alt="indian rupee sign"
                                                        src="./assets/images/rupee-indian.svg" /> 990.00 <span>MRP 1100.00</span>
                                                </div>

                                            </div>
                                            <div class="col-1 text-end p-0">
                                                 <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                            </div>
                                            <hr />
                                            <div class="col-5 ">
                                                <app-input-number aria-label="Quantity"  size="lg" [min]="1" [defaultValue]="1"></app-input-number>
                                            </div>
                                            <div class="col-7 text-end d-flex align-items-center">
                                                <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/> Add to Cart</button>
                                            </div>
                                        </div>
                                        <div class="discount" *ngIf="isLoggedIn">
                                            20%
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="me-2">
                            <div class="card mb-4">
                                <div class="card-body">
                                    <div class="prod-image">
                                        <img class="product image" src="./assets/images/menuIcon.PNG" />
                                    </div>
                                    <div class="prod-desc">
                                        <div class="row">
                                            <div class="col-12 quant-left">
                                                1 Unit left
                                            </div>
                                            <div class="col-11">
                                                <div class="modal-number">
                                                    266835300130
                                                </div>
                                                <div class="modal-name">
                                                    Crown W. & Pinion (37/7),OER - Spirac BS4
                                                </div>
                                                <div class="delivery-time">
                                                    Estd. Delivery: <span>Today</span>
                                                </div>
                                                <div class="price">
                                                    <img alt="indian rupee sign"
                                                        src="./assets/images/rupee-indian.svg" /> 990.00 <span>MRP 1100.00</span>
                                                </div>

                                            </div>
                                            <div class="col-1 text-end p-0">
                                                 <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                            </div>
                                            <hr />
                                            <div class="col-5 ">
                                                <app-input-number aria-label="Quantity"  size="lg" [min]="1" [defaultValue]="1"></app-input-number>
                                            </div>
                                            <div class="col-7 text-end d-flex align-items-center">
                                                <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/> Add to Cart</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-body">
                                    <div class="prod-image">
                                        <img class="product image" src="./assets/images/menuIcon.PNG" />
                                    </div>
                                    <div class="prod-desc">
                                        <div class="row">
                                            <div class="col-12 quant-left">
                                                1 Unit left
                                            </div>
                                            <div class="col-11">
                                                <div class="modal-number">
                                                    266835300130
                                                </div>
                                                <div class="modal-name">
                                                    Crown W. & Pinion (37/7),OER - Spirac BS4
                                                </div>
                                                <div class="delivery-time">
                                                    Estd. Delivery: <span>Today</span>
                                                </div>
                                                <div class="price">
                                                    <img alt="indian rupee sign"
                                                        src="./assets/images/rupee-indian.svg" /> 990.00 <span>MRP 1100.00</span>
                                                </div>

                                            </div>
                                            <div class="col-1 text-end p-0">
                                                 <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                            </div>
                                            <hr />
                                            <div class="col-5 ">
                                                <app-input-number aria-label="Quantity"  size="lg" [min]="1" [defaultValue]="1"></app-input-number>
                                            </div>
                                            <div class="col-7 text-end d-flex align-items-center">
                                                <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/> Add to Cart</button>
                                            </div>
                                        </div>
                                        <div class="discount" *ngIf="isLoggedIn">
                                            20%
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="me-2">
                            <div class="card mb-4">
                                <div class="card-body">
                                    <div class="prod-image">
                                        <img class="product image" src="./assets/images/menuIcon.PNG" />
                                    </div>
                                    <div class="prod-desc">
                                        <div class="row">
                                            <div class="col-12 quant-left">
                                                1 Unit left
                                            </div>
                                            <div class="col-11">
                                                <div class="modal-number">
                                                    266835300130
                                                </div>
                                                <div class="modal-name">
                                                    Crown W. & Pinion (37/7),OER - Spirac BS4
                                                </div>
                                                <div class="delivery-time">
                                                    Estd. Delivery: <span>Today</span>
                                                </div>
                                                <div class="price">
                                                    <img alt="indian rupee sign"
                                                        src="./assets/images/rupee-indian.svg" /> 990.00 <span>MRP 1100.00</span>
                                                </div>

                                            </div>
                                            <div class="col-1 text-end p-0">
                                                 <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                            </div>
                                            <hr />
                                            <div class="col-5 ">
                                                <app-input-number aria-label="Quantity"  size="lg" [min]="1" [defaultValue]="1"></app-input-number>
                                            </div>
                                            <div class="col-7 text-end d-flex align-items-center">
                                                <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/> Add to Cart</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-body">
                                    <div class="prod-image">
                                        <img class="product image" src="./assets/images/menuIcon.PNG" />
                                    </div>
                                    <div class="prod-desc">
                                        <div class="row">
                                            <div class="col-12 quant-left">
                                                1 Unit left
                                            </div>
                                            <div class="col-11">
                                                <div class="modal-number">
                                                    266835300130
                                                </div>
                                                <div class="modal-name">
                                                    Crown W. & Pinion (37/7),OER - Spirac BS4
                                                </div>
                                                <div class="delivery-time">
                                                    Estd. Delivery: <span>Today</span>
                                                </div>
                                                <div class="price">
                                                    <img alt="indian rupee sign"
                                                        src="./assets/images/rupee-indian.svg" /> 990.00 <span>MRP 1100.00</span>
                                                </div>

                                            </div>
                                            <div class="col-1 text-end p-0">
                                                <a href="#" class="add-to-favorite"><img alt=""
                                                        src="./assets/images/bookmark.svg" /></a>
                                            </div>
                                            <hr />
                                            <div class="col-5 ">
                                                <app-input-number aria-label="Quantity"  size="lg" [min]="1" [defaultValue]="1"></app-input-number>
                                            </div>
                                            <div class="col-7 text-end d-flex align-items-center">
                                                <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/> Add to Cart</button>
                                            </div>
                                        </div>
                                        <div class="discount" *ngIf="isLoggedIn">
                                            20%
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="nav-Service" role="tabpanel" aria-labelledby="nav-Service-tab">...</div>
                <div class="tab-pane fade" id="nav-Replacement" role="tabpanel" aria-labelledby="nav-Replacement-tab">
                    ...</div>
            </div>


        </div>
    </div> -->

    <!-- POPULAR SERVICE KIT -->
    <div class="row popular-service-kit mb-4" *ngIf="showKits">
        <div
            class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 mb-3 d-flex justify-content-between align-items-center">
            <div>
                <h2>Popular service kit</h2>
            </div>
            <a class="view-all" style="cursor: pointer;" [routerLink]="['/shop/productcards']" [queryParams]="{searchBy: 'kit'}">
                View All <i class="fas fa-angle-right"></i>
            </a>
        </div>
        <div class="col-12 col-sm-12 col-lg-12 col-md-12 col-xl-12 mb-4 pe-0" *ngIf="showKits">
            <!-- <div class="service-kit slider"> -->
            <ng-container *ngIf="!PopularKitsData?.length; else kitList">
                <owl-carousel-o [options]="customOptionsForKits">
                    <ng-container *ngFor="let item of userService.generateFake(7);let i = index;">
                        <ng-template carouselSlide [id]="i">
                            <div class="me-3">
                                <div class="card" style="cursor: pointer;">
                                    <div class="service-kit-image-wrapper">
                                        <skc-rectangle [loading]="loading" [skc-height]="'175px'" [skc-width]="'100%'"> </skc-rectangle>
                                    </div>
                                    <div class="card-body">
                                        <h5 class="service-kit-title">
                                            <skc-rectangle [loading]="loading" [skc-height]="'40px'" [skc-width]="'100%'"> </skc-rectangle>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </ng-container>
            <ng-template #kitList>
                <owl-carousel-o [options]="customOptionsForKits">
                    <ng-container *ngFor="let kit of PopularKitsData">
                        <ng-template carouselSlide [id]="kit.line_2">
                            <div class="me-3">
                                <div class="card" style="cursor: pointer;">
                                    <div class="service-kit-image-wrapper">
                                        <img [src]="kit?.image_url + '/resize/284x175'" (click)="KitSpecificParts(kit?.line_2)"
                                            class="card-img-top" alt="Service Kit Name">
                                    </div>
                                    <div class="card-body" (click)="KitSpecificParts(kit?.line_2)">
                                        <h5 class="service-kit-title">{{ kit?.line_2 }}</h5>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </ng-template>

            <!-- </div> -->
        </div>
    </div>

    <!--Recently viewed-->
    <div class="row recently-viewed mb-4" *ngIf="showRecentItems">
        <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 d-flex justify-content-between align-items-center">
            <h2>RECENTLY VIEWED AND RELATED ITEMS</h2>
            <!-- <a class="view-all">
                View All <i class="fas fa-angle-right"></i>
            </a> -->
        </div>
        <div  [ngClass]="recentItems.length < 5  ? 'col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 single-item': 'col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12'" >
            <!-- <div class="recently-viewed-list slider"> -->
            <ng-container *ngIf="!recentItems?.length; else recentItemsList">
                <owl-carousel-o [options]="customOptionsForRecentItems">
                    <ng-container *ngFor="let item of userService.generateFake(7);let i = index;">
                        <ng-template carouselSlide [id]="i">
                            <div class="me-2">
                                <div class="recent-item-card">
                                    <div class="card-body cursor">
                                        <div class="prod-image cursor">
                                            <skc-rectangle [loading]="loading" [skc-height]="'80px'" [skc-width]="'100%'"> </skc-rectangle>
                                        </div>
                                        <div class="prod-desc" >
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="modal-number">
                                                        <skc-rectangle [loading]="loading" [skc-height]="'19px'" [skc-width]="'100%'"> </skc-rectangle>
                                                    </div>
                                                    <div class="modal-name">
                                                        <skc-rectangle [loading]="loading" [skc-height]="'16px'" [skc-width]="'100%'"> </skc-rectangle>
                                                    </div>
                                                    <div class="delivery-time w-50 d-inline-block text-nowrap">
                                                        <skc-rectangle [loading]="loading" [skc-height]="'14px'" [skc-width]="'100%'"> </skc-rectangle>
                                                    </div>
                                                    <div class="price fw-bold ">
                                                        <skc-rectangle [loading]="loading" [skc-height]="'14px'" [skc-width]="'100%'"> </skc-rectangle>
                                                    </div>
                                                </div>
                                                <div class="col-5">
                                                    <skc-rectangle [loading]="loading" [skc-height]="'20px'" [skc-width]="'100%'"> </skc-rectangle>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </ng-container>
            <ng-template #recentItemsList>
                <owl-carousel-o [options]="customOptionsForRecentItems">
                    <ng-container *ngFor="let product of recentItems">
                        <ng-template carouselSlide [id]="product.part_number">
                            <div class="me-2">
                                <div class="recent-item-card">
                                    <div class="card-body cursor" style="height: 263px;">
                                        <div class="prod-image cursor" [routerLink]="['/shop/product-details']"
                                            [queryParams]="{ part_Number : product.part_number }">
                                            <img class="product image" [src]="product?.part_image_path + '/resize/117x80'" onerror="this.src='./assets/tata-vehicle.jfif';"/>
                                        </div>
                                        <div class="prod-desc" >
                                            <div class="row">
                                                <div class="col-12" >

                                                    <app-wishlist-icon [product]="product" [layout]="'Nolayout'"></app-wishlist-icon>
                                                    <div [routerLink]="['/shop/product-details']"
                                                        [queryParams]="{ part_Number : product.part_number }">
                                                        <div class="modal-name"
                                                            title="{{ (product.short_description == '' || product.short_description == null) ? '-' : product.short_description}}">
                                                            {{ (product.short_description == " " || product.short_description == null) ? "-" :(product.short_description.length > 20 ?(product.short_description.substr(0, 20) + '...') :product.short_description)}}
                                                        </div>
                                                        <div class="modal-number">
                                                            {{ product?.part_number }}
                                                        </div>
                                                        <div class="delivery-time w-50 d-inline-block text-nowrap" style="text-overflow: ellipsis; overflow:hidden;"
                                                            *ngIf="product?.hsn_code && product?.hsn_code != ''">
                                                            HSN: <span title="{{ product?.hsn_code }}">{{ product?.hsn_code }}</span>
                                                        </div>
                                                        <div class="delivery-time w-50 d-inline-block text-end text-nowrap" style="text-overflow: ellipsis; overflow:hidden;"
                                                            *ngIf="product?.tax_slab && product?.tax_slab!=''">
                                                            GST: <span title="{{ product?.tax_slab }}">{{ product?.tax_slab }}%</span>
                                                        </div>
                                                        <!-- <div class="delivery-time">
                                                            Estd. Delivery: <span>Today</span>
                                                        </div>
                                                        <div class="price">
                                                            <img alt="indian rupee sign" src="./assets/images/rupee-indian.svg" />
                                                            990.00 <span>MRP 1100.00</span>
                                                        </div> -->
                                                        <ng-container *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true); else elseTemplate">
                                                        <ng-container *ngIf="!product.total_amount">
                                                            <div class="price fw-bold">
                                                                {{ (( product.resource_type == 'Pack' )?product.crm_mrp*product.moq:product.crm_mrp ) |currencyFormat:'INR' }}
                                                            </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="product.total_amount">
                                                            <div
                                                                *ngIf="(product.base_discount_per +  product.scheme_discount_per + product.rule_discount_per + product.distributor2_discount_amount) == 0.0">
                                                                <div class="price fw-bold">
                                                                    {{ (( product.resource_type == 'Pack')?product.crm_mrp*product.moq:product.crm_mrp ) |currencyFormat:'INR' }}
                                                                  </div>
                                                            </div>
                                                            <div
                                                                *ngIf="(product.base_discount_per +  product.scheme_discount_per + product.rule_discount_per + product.distributor2_discount_amount) > 0.0">
                                                                <div class="price fw-bold " >
                                                                    {{  (( product.resource_type == 'Pack'  ) ? product.total_amount*product.moq
                                                                    : product.total_amount )|currencyFormat:'INR'
                                                                    }}
                                                                    <div class="card-actual-mrp">
                                                                        <span>
                                                                            MRP
                                                                            {{
                                                                            ( ( product.resource_type == 'Pack' ) ? product.crm_mrp*product.moq
                                                                            : product.crm_mrp) |currencyFormat:'INR'
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>
                                                    <ng-template #elseTemplate>
                                                        <div class="price fw-bold">
                                                        {{ (( product.resource_type == 'Pack' )?product.crm_mrp*product.moq:product.crm_mrp ) |currencyFormat:'INR' }}
                                                        </div>
                                                    </ng-template>
                                                        <div class="tmgproduct moqProductInfo" *ngIf="( product.resource_type == 'Pack' && product.moq > 1)">
                                                            <p class="save-mode">1 Unit contains {{product.moq | number: '1.0-0'}} <span *ngIf="product.moq > 1">Quantities</span><span *ngIf="product.moq <= 1">Quantity</span></p>
                                                        </div>
                                                        <span class="error-message-service text-nowrap" *ngIf="product.is_nls == 'in_active'">No Longer Serviced</span>
                                                        <span class="error-message-service text-nowrap" *ngIf="product?.is_nls != 'in_active' && product?.alternate_part?true:false">No
                                                            Longer available</span>
                                                        <div class="col-12 view-alternate text-nowrap mt-2" *ngIf="product.is_nls == 'in_active' || (product?.alternate_part?true:false)">
                                                            <span [routerLink]="['/shop/product-details']"
                                                                [queryParams]="{ part_Number : product.part_number }" style="cursor: pointer;">View Alternate Part</span>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-1 text-end p-0">
                                                         <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                        <ng-container *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                                        <div class="discount-for-recentItems" *ngIf="(convertToFloat(product?.base_discount_per) + convertToFloat(product?.scheme_discount_per) +
                                        convertToFloat(product?.tml_discount_per) + convertToFloat(product?.distributor_discount_per) +
                                        convertToFloat(product?.rule_discount_per) + convertToFloat(product.distributor2_discount_amount) ) > 0" [routerLink]="['/shop/product-details']"
                                            [queryParams]="{ part_Number : product.part_number }">
                                            <!-- {{(product?.base_discount + product?.scheme_discount +
                                                    product?.tml_rule_discount + product?.dealer_rule_discount +
                                                    product?.rule_discount)}} % -->
                                            {{'SAVE ' + (((product.crm_mrp-product.total_amount)/product.crm_mrp)*100)?.toFixed(1) + '%'}}
                                        </div>
                                    </ng-container>
                                    </div>
                                    <hr *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true)" style="margin: 0px;" />
                                    <div class="card-footer" style="border: none;">
                                        <div class="row">
                                            <div class="col-5" *ngIf="(userData?.user_type == 'MECH' && (product?.inventory_quantity != 0 && product?.inventory_quantity != null && product.moq <= product.inventory_quantity) && product.is_nls != 'in_active' && (product?.alternate_part?false:true)) || (userData?.user_type != 'MECH' && product.is_nls != 'in_active' && (product?.alternate_part?false:true))">
                                                <app-input-number aria-label="Quantity" (currentQuantity)="readQuantity($event,product)"
                                                size="lg" [min]="1" [defaultValue]="product.min_quantity" [isTmgoValue]="product.resource_type == 'Pack' ?product.moq:'1'" [userType]="userData" [inventoryQty]="product.inventory_quantity" >
                                                </app-input-number>
                                            </div>
                                            <div style="display: contents;" *ngIf="userData?.user_type == 'MECH'">
                                                <ng-container  *ngIf="product?.inventory_quantity > 0 && product.moq <= product.inventory_quantity">
                                                    <div class="col-7 text-end d-flex align-items-center justify-content-end" *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                                                        <button class="add-to-card-new-product" (click)="addToCart(product,true)"><img alt=""
                                                                src="./assets/images/cart.svg" /> Add to
                                                            Cart</button>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="(product?.inventory_quantity <= 0 || product?.inventory_quantity == null || product.moq > product.inventory_quantity) && product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                                                    <h6 class="col-7 text-end d-flex align-items-center" style="font-size: 13px;
                                                    position: relative;
                                                    font-weight: 700;
                                                    color: red;
                                                    top: 5px;">Out Of Stock</h6>
                                                </ng-container>
                                            </div>
                                            <div class="col-7 text-end d-flex align-items-center justify-content-end" *ngIf="userData?.user_type != 'MECH' && product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                                                <button class="add-to-card-new-product" (click)="addToCart(product,true)"><img alt=""
                                                        src="./assets/images/cart.svg" /> Add to
                                                    Cart</button>
                                            </div>

                                        </div>

                                    </div>
                                    <div class="card-footer" style="border-top: none; height: 25px;"
                                    *ngIf="(product.is_nls == 'in_active') || (product.is_nls != 'in_active' && (product?.alternate_part?true:false))">
                                        <div class="row">
                                            <div class="col-md-12">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </ng-template>

            <!-- <div class="me-2">
                    <div class="card">
                        <div class="card-body">
                            <div class="prod-image">
                                <img class="product image" src="./assets/images/menuIcon.PNG" />
                            </div>
                            <div class="prod-desc">
                                <div class="row">
                                    <div class="col-11">
                                        <div class="modal-number">
                                            266835300130
                                        </div>
                                        <div class="modal-name">
                                            Crown W. & Pinion (37/7),OER - Spirac BS4
                                        </div>
                                        <div class="delivery-time">
                                            Estd. Delivery: <span>Today</span>
                                        </div>
                                        <div class="price">
                                            <img alt="indian rupee sign" src="./assets/images/rupee-indian.svg" />
                                            990.00 <span>MRP 1100.00</span>
                                        </div>

                                    </div>
                                    <div class="col-1 text-end p-0">
                                         <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                    </div>
                                    <hr />
                                    <div class="col-5 ">
                                        <app-input-number aria-label="Quantity" size="lg" [min]="1" [defaultValue]="1">
                                        </app-input-number>
                                    </div>
                                    <div class="col-7 text-end d-flex align-items-center">
                                        <button class="add-to-card"><img alt="" src="./assets/images/cart.svg" /> Add to
                                            Cart</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="me-2">
                    <div class="card">
                        <div class="card-body">
                            <div class="prod-image">
                                <img class="product image" src="./assets/images/menuIcon.PNG" />
                            </div>
                            <div class="prod-desc">
                                <div class="row">
                                    <div class="col-11">
                                        <div class="modal-number">
                                            266835300130
                                        </div>
                                        <div class="modal-name">
                                            Crown W. & Pinion (37/7),OER - Spirac BS4
                                        </div>
                                        <div class="delivery-time">
                                            Estd. Delivery: <span>Today</span>
                                        </div>
                                        <div class="price">
                                            <img alt="indian rupee sign" src="./assets/images/rupee-indian.svg" />
                                            990.00 <span>MRP 1100.00</span>
                                        </div>

                                    </div>
                                    <div class="col-1 text-end p-0">
                                         <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                    </div>
                                    <hr />
                                    <div class="col-5 ">
                                        <app-input-number aria-label="Quantity" size="lg" [min]="1" [defaultValue]="1">
                                        </app-input-number>
                                    </div>
                                    <div class="col-7 text-end d-flex align-items-center">
                                        <button class="add-to-card"><img alt="" src="./assets/images/cart.svg" /> Add to
                                            Cart</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="me-2">
                    <div class="card">
                        <div class="card-body">
                            <div class="prod-image">
                                <img class="product image" src="./assets/images/menuIcon.PNG" />
                            </div>
                            <div class="prod-desc">
                                <div class="row">
                                    <div class="col-11">
                                        <div class="modal-number">
                                            266835300130
                                        </div>
                                        <div class="modal-name">
                                            Crown W. & Pinion (37/7),OER - Spirac BS4
                                        </div>
                                        <div class="delivery-time">
                                            Estd. Delivery: <span>Today</span>
                                        </div>
                                        <div class="price">
                                            <img alt="indian rupee sign" src="./assets/images/rupee-indian.svg" />
                                            990.00 <span>MRP 1100.00</span>
                                        </div>

                                    </div>
                                    <div class="col-1 text-end p-0">
                                         <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                    </div>
                                    <hr />
                                    <div class="col-5 ">
                                        <app-input-number aria-label="Quantity" size="lg" [min]="1" [defaultValue]="1">
                                        </app-input-number>
                                    </div>
                                    <div class="col-7 text-end d-flex align-items-center">
                                        <button class="add-to-card"><img alt="" src="./assets/images/cart.svg" /> Add to
                                            Cart</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="me-2">
                    <div class="card">
                        <div class="card-body">
                            <div class="prod-image">
                                <img class="product image" src="./assets/images/menuIcon.PNG" />
                            </div>
                            <div class="prod-desc">
                                <div class="row">
                                    <div class="col-11">
                                        <div class="modal-number">
                                            266835300130
                                        </div>
                                        <div class="modal-name">
                                            Crown W. & Pinion (37/7),OER - Spirac BS4
                                        </div>
                                        <div class="delivery-time">
                                            Estd. Delivery: <span>Today</span>
                                        </div>
                                        <div class="price">
                                            <img alt="indian rupee sign" src="./assets/images/rupee-indian.svg" />
                                            990.00 <span>MRP 1100.00</span>
                                        </div>

                                    </div>
                                    <div class="col-1 text-end p-0">
                                         <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                    </div>
                                    <hr />
                                    <div class="col-5 ">
                                        <app-input-number aria-label="Quantity" size="lg" [min]="1" [defaultValue]="1">
                                        </app-input-number>
                                    </div>
                                    <div class="col-7 text-end d-flex align-items-center">
                                        <button class="add-to-card"><img alt="" src="./assets/images/cart.svg" /> Add to
                                            Cart</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="me-2">
                    <div class="card">
                        <div class="card-body">
                            <div class="prod-image">
                                <img class="product image" src="./assets/images/menuIcon.PNG" />
                            </div>
                            <div class="prod-desc">
                                <div class="row">
                                    <div class="col-11">
                                        <div class="modal-number">
                                            266835300130
                                        </div>
                                        <div class="modal-name">
                                            Crown W. & Pinion (37/7),OER - Spirac BS4
                                        </div>
                                        <div class="delivery-time">
                                            Estd. Delivery: <span>Today</span>
                                        </div>
                                        <div class="price">
                                            <img alt="indian rupee sign" src="./assets/images/rupee-indian.svg" />
                                            990.00 <span>MRP 1100.00</span>
                                        </div>

                                    </div>
                                    <div class="col-1 text-end p-0">
                                         <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                    </div>
                                    <hr />
                                    <div class="col-5 ">
                                        <app-input-number aria-label="Quantity" size="lg" [min]="1" [defaultValue]="1">
                                        </app-input-number>
                                    </div>
                                    <div class="col-7 text-end d-flex align-items-center">
                                        <button class="add-to-card"><img alt="" src="./assets/images/cart.svg" /> Add to
                                            Cart</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            <!-- </div> -->
        </div>
    </div>
</div>

<ng-template #logoutModal let-d="dismiss">
    <app-logout (userLogout)="UserLogout($event)" (modalClose)="logout($event)"></app-logout>
</ng-template>

<!-- <ng-container *ngIf="showLoader">
    <div class="loader">
        <div class="ng-spinner ng-spinner-icon"></div>
    </div>
</ng-container> -->

<ng-template #confimationmodal let-d="dismiss">
    <app-selectCartType [btnshowConfrimed]="btnshowConfrimed" [btnshowsubmit]="btnshowsubmit" (setCartType)="setAddCart();"
    [cartTypeList]="cartTypeList" [baseProductdata]="productInfo" (selectedCartType)="ShowPart()"
    (inputChange)="changeCartType($event,null)" (closeModal)="cartTypeModal.hide();"></app-selectCartType>
</ng-template>
