<div class="modal-header modal-title">
    Bulk Upload
    <div (click)="closeModal()" type="button"><i class="fa fa-times"></i></div>
</div>
<div class="modal-body">
    <!-- <p style="color: red;">We've identified pack type items in your upload. These items are ordered in multiples of the pack size. Adjust the Units for these items accordingly. For instance, if you want 30 pieces of [Product 1] with a pack size of 3, enter '3' in the Unit column.</p> -->
    <div class="col-12 col-sm-7 col-md-8 col-lg-8 col-xl-9" style="width: 100%;text-align: center;">
        <div class="buy-again table-wrapper">
            <div class="buy-again-body p-0">
                <div class="table-responsive table-container" #TABLE #table  [ngClass]="{'addscroll': parts.length > 5}">
                    <table class="table datatable display" >
                        <thead>
                            <tr>
                                <th class="text-nowrap" style="text-align:center;">Sr.No.</th>
                                <th class="text-nowrap" style="text-align:center;">Parts</th>
                                <th class="text-nowrap" style="text-align:center;">Units</th>
                                <th class="text-nowrap" style="text-align:center;">Order Qty</th>
                                <th class="text-nowrap" style="text-align:center;">Pack Of</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let part of parts; let i = index;">
                                <td>{{i+1}}</td>
                                <td style="text-align:center;">
                                    <span [ngClass]="{ 'blink': part.isInventoryAvailable==false && userDetails?.user_type == 'MECH' }">{{part.part_number}}</span>
                                </td>
                                <!-- <p>We've identified pack type items in your upload. These items are ordered in multiples of the pack size. Adjust the Units for these items accordingly. For instance, if you want 30 pieces of [Product 1] with a pack size of 3, enter '3' in the Unit column.</p> -->
                                <td style="text-align:center;">
                                    <app-input-number [defaultValue]="part?.quantity" [min]="1"
                                        (currentQuantity)="CheckQuantity($event, i)" [isTmgoValue]="part?.resource_type == 'Pack'?part?.moq:'1'" [userType]="userDetail" [inventoryQty]="part.inventory_quantity">
                                    </app-input-number><br/>
                                    <p style="color: red;margin-bottom: 0;margin-top: 2px;" *ngIf="part?.resource_type == 'Pack' && product?.moq > 1">
                                       1 Unit contains {{part.moq | number: '1.0-0'}} <span *ngIf="part.moq > 1">Quantities</span><span *ngIf="part.moq <= 1">Quantity</span>
                                </td>
                                <td style="text-align:center;">
                                    {{ part?.orderQuantity }}
                                </td>
                                <td style="text-align:center;">
                                    {{ part?.moq | number: '1.0-0'}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-2" *ngIf="filterInventory.length > 0">
        <p style="color: red;">Out Of stock Parts</p>
        <div class="card">
            <ng-container *ngFor="let item of filterInventory">
                {{item?.part_number }},
            </ng-container>
        </div>
    </div>
    <div class="row mt-2" *ngIf="invalidPartList.length > 0">
        <p style="color: red;">Invalid Parts</p>
        <div class="card">
            {{invalidPartList}}
        </div>
    </div>
    <div class="row mt-4" *ngIf="duplicateParts && duplicateParts.length > 0">
        <p style="color: red;"><b>Parts Pending In Orders</b></p>
        <p>Below is the list of part numbers which you have already ordered and are pending to be invoiced, Please check in Active orders tab, before placing order.</p>
        <div class="buy-again table-wrapper">
            <div class="buy-again-body p-0">
                <div class="table-responsive table-container" #TABLE #table  [ngClass]="{'addscrollToDuplicate': parts.length > 5}" style="width: 70%;">
                    <table class="table datatable display" >
                        <thead>
                            <tr>
                                <th class="text-nowrap" style="text-align:center;">Sr No</th>
                                <th class="text-nowrap" style="text-align:center;">Part No</th>
                                <th class="text-nowrap" style="text-align:center;">Pending Quantity</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let part of duplicateParts; let i = index;">
                                <td style="text-align: center;">{{i+1}}</td>
                                <td style="text-align:center;">
                                    {{part.part_number}}
                                </td>
                                <td style="text-align:center;">
                                    {{ part?.quantity_total_count | number: '1.0-0'}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
          
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="submitBuyAgainData()">
        &nbsp;Continue</button>
</div>