import { Injectable } from "@angular/core";
import { AppStateService } from "../services/app-state/app-state.service";
import { secureStorage } from "../services/securestorage";

@Injectable({
    providedIn: "root"
})

export class PartSearchRequestBodyService {
    isLoggedIn: boolean = false;
    userDetails: any;
    billingAddress;
    shippingAddress;
    billingAddressId: any;
    shippingAddressId: any;
    account_pk: any;
    constructor(private appStateService: AppStateService, private localstorage: secureStorage) {
        this.isLoggedIn = this.appStateService.isLoggedIn.value;
        this.shippingAddress = JSON.parse(this.localstorage.getItem('address'));
        this.shippingAddressId = (this.shippingAddress?.address?.default) ? 'default' : this.shippingAddress?.address?.address_id;
        this.billingAddress = JSON.parse(this.localstorage.getItem('billingAddress'));
        this.billingAddressId = (this.billingAddress?.address?.default) ? 'default' : this.billingAddress?.address?.address_id;
        this.userDetails = JSON.parse(this.localstorage.getItem('userData'));
    }

    returnRequestbodyFor(name, offset, limit) {
        this.isLoggedIn = this.appStateService.isLoggedIn.value;
        if (this.isLoggedIn) {
            this.shippingAddress = JSON.parse(this.localstorage.getItem('address'));
            this.shippingAddressId = (this.shippingAddress?.address?.default) ? 'default' : this.shippingAddress?.address?.address_id;
            this.billingAddress = JSON.parse(this.localstorage.getItem('billingAddress'));
            this.billingAddressId = (this.billingAddress?.address?.default) ? 'default' : this.billingAddress?.address?.address_id;
            this.userDetails = JSON.parse(this.localstorage.getItem('userData'));
        }
        let requestBody: requestBody = {} as requestBody;
        requestBody.offset = offset;
        requestBody.limit = limit;
        let pincode = JSON.parse(this.localstorage.getItem('Pincode'));
        if (name === "dotd") {
            requestBody.durafit_parts = false,
                requestBody.kit_parts = false,
                requestBody.is_home = true,
                requestBody.is_for_new_parts = false
            requestBody.is_for_epc_data = false,
                requestBody.is_for_ecom_detail = false,
                requestBody.is_for_inventory = this.userDetails?.user_type == 'MECH' ? true: false
        }
        if (name === "np") {
            requestBody.durafit_parts = false,
                requestBody.kit_parts = false,
                requestBody.is_home = false,
                requestBody.is_for_new_parts = true,
                requestBody.is_for_epc_data = false,
                requestBody.is_for_ecom_detail = false,
                requestBody.is_for_inventory = this.userDetails?.user_type == 'MECH' ? true: false

        }
        if(pincode && (pincode != (this.shippingAddress!=null?this.shippingAddress["address"].postal_code : ""))){
       
            requestBody.is_for_discount = true ,
            requestBody.is_pincode_based_division = true,
            requestBody.pincode = pincode,
            requestBody.is_for_inventory = this.userDetails?.user_type == 'MECH' ? true: false

        }
        if (this.userDetails) {
            requestBody.account_pk = this.userDetails.account_pk;
            requestBody.shipping_address_id = this.shippingAddressId;
            requestBody.is_for_discount = true,
            requestBody.is_for_inventory = this.userDetails?.user_type == 'MECH' ? true: false

        }
        return requestBody;
    }
}

export interface requestBody {
    offset: number,
    limit: number,
    durafit_parts: boolean,
    kit_parts: boolean,
    is_home: boolean,
    is_for_new_parts: boolean,
    is_for_epc_data: boolean,
    is_for_ecom_detail: boolean,
    account_pk: string | number,
    shipping_address_id: string | number,
    is_for_discount: boolean,
    is_pincode_based_division : boolean,
    pincode : number,
    is_for_inventory :boolean
}