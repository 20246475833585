import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { UserService } from 'src/app/shared/services/REST/user.service';
import { secureStorage } from 'src/app/shared/services/securestorage';
declare var $: any;
@Component({
  selector: 'app-main',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss']
})
export class RootComponent implements OnInit {
  showHeader: boolean = false;
  showCoachMarks:boolean = false;
  isLoggedIn:boolean = false;
  loyalityInfo:any = ''
  userData:any;
  constructor(private appStateService:AppStateService, private route: ActivatedRoute, private localStorage:secureStorage,private userService: UserService, ) { }

  ngOnInit(){
    // var cturl = this.router.url;
    // if(cturl.includes('/account/login?isLogin=true') || cturl.includes('account/registration?isRegistration=true')){
    //   this.showHeader = true;
    // }
    // else{
    //   this.showHeader = false;
    // }
  
    this.appStateService.isLoggedIn.subscribe((isLog) => {
      this.isLoggedIn = isLog;
    });
    if(this.isLoggedIn){
      this.userData = JSON.parse(this.localStorage.getItem('userData'));
    }
    this.appStateService.coachMarksCheckedOrSkip.subscribe((val) => {
      let isCoachMarkRender = this.localStorage.getItem("coachMarksRender");
      if ( ( val == true || isCoachMarkRender == 'false' || !isCoachMarkRender ) && this.isLoggedIn){
        this.showCoachMarks = true;
      }else{
        this.showCoachMarks = false;
      }
    });
    this.route.queryParams.subscribe(params => {
      var isLogin = params['isLogin'];
      var isRegistration = params['isRegistration'];
      if(isLogin === 'true'){
        this.showHeader = true;
      }
      else if(isRegistration === 'true'){
        this.showHeader = true;
      }
      else{ 
        this.showHeader = false;

      }
    });
    var screenHeight=$(window).height();
    $('.ng-sign').css('minHeight',screenHeight +'px');

    $("input[id$='Product-nav']").click(function() {
      $('.nav-product-wrapper').removeClass('d-none').addClass('d-block');
      $('.nav-modals-wrapper').removeClass('d-block').addClass('d-none'); 
      $('.category-tab-wrapper-product').addClass('nav-cat-avtive');
      $('.category-tab-wrapper-modals').removeClass('nav-cat-avtive');
    })
    $("input[id$='Modals-nav']").click(function() {
      $('.nav-modals-wrapper').removeClass('d-none').addClass('d-block');
      $('.nav-product-wrapper').removeClass('d-block').addClass('d-none');    
      $('.category-tab-wrapper-modals').addClass('nav-cat-avtive');
      $('.category-tab-wrapper-product').removeClass('nav-cat-avtive');
    })

    $("a[data-theme]").click(function () {
        $("head link#theme").attr("href", $(this).data("theme"));
        $(this).toggleClass('active').siblings().removeClass('active');
      });
      $("a[data-effect]").click(function () {
        $("head link#effect").attr("href", $(this).data("effect"));
        $(this).toggleClass('active').siblings().removeClass('active');
      });
   
  }
}

