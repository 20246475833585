<div class="container-fluid orders mobile-margin-top">
  <div class="row">
    <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 pb-3 pt-3 ">
     
      <ul class="nav nav-tabs tabs1" id="user-tab" role="tablist">
    
        <li class="nav-item cursor" role="presentation" *ngIf="isProfilePermission == null">
        <a [ngClass]="currentUrl.includes('profile') ? 'nav-link active' : 'nav-link'" [routerLink]="['/account/profile']"
            >Profile
            Management</a>
        </li>
       
        <li class="nav-item cursor" role="presentation" *ngFor="let menuitem of profileMenu">
         
          <a [ngClass]="currentUrl.includes('password') && menuitem?.page_url.includes('password') ? 'nav-link active' : menuitem?.page_url == currentUrl 
          ? 'nav-link active' : 'nav-link'"  
          [routerLink]="menuitem?.page_url"
           >{{ menuitem?.page_display_name == 'My Profile' ? 'Profile Management' : menuitem?.page_display_name}} </a>
        </li>
        <li class="nav-item cursor" role="presentation" *ngIf="dataArray.length">
          <a [ngClass]="currentUrl.includes('profile-loyality') ? 'nav-link active' : 'nav-link'" 
          [routerLink]="['/account/profile-loyality']"
           >Loyalty</a>
        </li>
         <li class="nav-item cursor" role="presentation" *ngIf="!isKeyClockUser">
          <a [ngClass]="currentUrl.includes('password') ? 'nav-link active' : 'nav-link'" 
          [routerLink]="['/account/setpassword']"
           >Set Password</a>
        </li>
      </ul>
    </div>
  </div>
</div>

<router-outlet></router-outlet>