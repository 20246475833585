import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { UserService } from '../../services/REST/user.service';
import { Router } from '@angular/router';
import { ProductService } from '../../services/REST/product.service';
import { secureStorage } from '../../services/securestorage';

@Component({
  selector: 'app-bulk-upload-part-details',
  templateUrl: './bulk-upload-part-details.component.html',
  styleUrls: ['./bulk-upload-part-details.component.scss']
})
export class BulkUploadPartDetailsComponent implements OnInit {

  @Input() parts: any;
  @Output() closemodal = new EventEmitter<any>();
  @Output() submitData = new EventEmitter<any>();
  @Input() invalidParts: any;
  @Input() userDetail:any
  invalidPartList: any = []
  duplicateParts:any=[]
  showLoader:boolean = false;
  userDetails:any;
  partList:any=[];
  isInventoryCheck:any;
  filterInventory:any=[]

  constructor(private toastrService: ToastrService, private userService: UserService,private productService: ProductService,
    private router: Router,private localstorage: secureStorage) { }

  ngOnInit(): void {
    if (this.invalidParts) {
      for (var i = 0; i < this.invalidParts.length; i++) {
        if (this.invalidParts[i] != "None" || this.invalidParts[i] != undefined || this.invalidParts[i] != null) {
          this.invalidPartList.push(' ' + this.invalidParts[i])
        }
      }
    }
    if (this.parts) {
      for (var j = 0; j < this.parts.length; j++) {
        this.partList.push(this.parts[j].part_number)
      }
    }
    this.userDetails = JSON.parse(this.localstorage.getItem('userData'))
    this.duplicateOrderCheck();
  }

  CheckQuantity(quantityVal, i) {
    try {
      if (quantityVal == undefined || quantityVal == 0 || quantityVal == '' || isNaN(quantityVal)) {
        this.parts[i].isInvalidQuantity = true;
        this.toastrService.error('Quantity field should not be empty.');
        return;
      } else {
        this.parts[i].isInvalidQuantity = false;
        if (this.parts[i].resource_type == 'Pack') {
          this.parts[i].orderQuantity = String(quantityVal * this.parts[i].moq);
        } else {
          this.parts[i].orderQuantity = String(quantityVal);
        }
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'CheckQuantity(event, i)',
        page_name: 'BuyAgainComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error,
        inner_exception: '',
      });
    }
  }

  submitBuyAgainData() {
    if(this.userDetail?.user_type == 'MECH' ){
      // this.items[0].inventory_quantity = 40
      // this.items[1].inventory_quantity = 4
      for(let j=0;j< this.parts.length; j++){
          if(this.parts[j].orderQuantity > this.parts[j].inventory_quantity){
              this.parts[j].isInventoryAvailable = false
          }
          else{
              this.parts[j].isInventoryAvailable = true
          }
      }
      
      this.filterInventory = this.parts.filter(items => items.inventory_quantity == 0)
      console.log("this.filterInventory ",this.filterInventory )

      this.isInventoryCheck =  this.parts.find(item => item.isInventoryAvailable === false)
     if(this.isInventoryCheck){
      this.toastrService.error("Please note that some parts inventory is not available.");
      return
     }
  }
    this.submitData.emit(this.parts);
  }

  closeModal() {
    this.closemodal.emit();
  }

  duplicateOrderCheck() {
    try {
      let duplicate_reuest ={
        "account_pk": this.userDetails.account_pk,
        "part_number": this.partList,
        "order_type":"bulk"
    }
      this.productService.get_duplicate_order_alert(duplicate_reuest).subscribe((response) => {
        if (response.success === true) {
          if(response.data.duplicate_order_data.length > 0){
            this.duplicateParts = response.data.duplicate_order_data;
          }
          else{
            this.duplicateParts = []
          }
        }else{
          this.duplicateParts = []
        }
      },
        error => {
          if (error?.error?.data?.msg) {
            this.toastrService.error(error.error.data.msg);
          }
          this.showLoader = false;
        });
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'duplicateOrderCheck() ',
        page_name: 'BulkUploadPartDetailsComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }
}
