import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from "ngx-bootstrap/modal";
import { AppStateService } from '../../services/app-state/app-state.service';
import { ProductService } from '../../services/REST/product.service';
import { UserService } from '../../services/REST/user.service';
import { secureStorage } from '../../services/securestorage';
import { ToastrService } from "ngx-toastr";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AddressService } from '../../services/REST/address.service';
@Component({
  selector: 'delivery-address',
  templateUrl: './delivery-address.component.html',
  styleUrls: ['./delivery-address.component.scss'],
})
export class DeliveryAddressComponent implements OnInit {
  @Input() categoryType: any;
  @Input() usertype: any;
  @Input() addresses: any;
  @Input() pinCodeNumber: number;
  @Output() SelectedAddress = new EventEmitter<any>();
  @Output() closemodal = new EventEmitter<any>();
  @Input() isProductDetails: boolean = false;
  isLoggedIn: boolean = false;
  cartTypeList: any;
  selectedAddress: any;
  SelectAddressId: any;
  default_address: any = [];
  new_addresses: any = [];
  @Output() changedAddress = new EventEmitter<any>();

  totalcount;
  editedAddresses = [];
  Selectaddress: any;
  selectedDeliveryPosition: number = -1;
  selectedBillingPosition: number = -1;
  selectedDeliveryAddress: any = {};
  selectedBillingAddress: any = {};
  isDeliveryAddress: string = 'No';
  isBillingAddress: string = 'No';
  defaultBillingAddress: number;
  defaultDeliveryAddress: number;
  //pagination
  currentPage: any = 1;
  offset: any;
  totalrecord: any;
  addresscount;
  isBillingAddressSelected: boolean = false;
  isDeliveryAddressSelected: boolean = false;
  checkedBillingAddressId;
  checkedDeliveryAddressId;
  showLoader: boolean = false;
    // add contact person
    addressData:any;
    @ViewChild('addContactPerson', { read: TemplateRef, static: false })
   addContactPerson: TemplateRef<any>;
   addContactPersonModal:any;
   @ViewChild('confirmationContactPerson', { read: TemplateRef, static: false })
   confirmationContactPerson: TemplateRef<any>;
   confirmationContactPersonModal:any;
   @ViewChild('listContactPersonModal', { read: TemplateRef, static: false })
   listContactPersonModal: TemplateRef<any>;
   listContactPersonModalDetails:any;
   @ViewChild('openAddContactPersonModal', {read: TemplateRef, static: false })
   openAddContactPersonModal: TemplateRef<any>;
   openAddContactPersonModalDetails:any;
   contactPersonAddModal:boolean = false;
   private ngUnSubscribe: Subject<void> = new Subject();
   isNoRecord: boolean = false;
   pincodeChangeFrom:FormGroup;
  constructor(
    private addressService: AddressService,
    private fb:FormBuilder,
    private productService: ProductService,
    private router: Router,
    private userService: UserService,
    private appStateService: AppStateService,
    private modalService: BsModalService,
    private localstorage: secureStorage,
    private toastrService: ToastrService
  ) { }

  ngOnInit() {
    this.usertype = JSON.parse(this.localstorage.getItem('userData'));
    this.addresses;
    this.appStateService.isLoggedIn.subscribe((isLog) => {
      this.isLoggedIn = isLog;
    });
    this.pincodeChangeFrom = this.fb.group({
      pincodeSearchCtrl: ['']
    });
    this.SelectAddressId =
      this.localstorage.getItem('AddressId') !== undefined &&
        this.localstorage.getItem('AddressId') !== null &&
        this.localstorage.getItem('AddressId') !== 'undefined'
        ? this.localstorage.getItem('AddressId')
        : '0';
    this.refreshAddressList();
    this.appStateService.openContactPersonList.pipe(takeUntil(this.ngUnSubscribe)).subscribe( addAddress => {
      if(addAddress == "Yes" ){
        this.openContactPersonList(this.addressData);
        this.appStateService.openContactPersonList.next("");
      }
    })
  }
  getAddressNew(ListInput) {
    try {
      let usersData = JSON.parse(this.localstorage.getItem('userData'));
      if (usersData) {
        this.usertype = usersData.user_type;
        this.showLoader = true;
        this.isNoRecord = false;
        this.productService.getAddressList(ListInput).subscribe((response) => {
          if (response.success === true) {
            this.isNoRecord = false;
            this.addresses = response.data;
            this.editedAddresses = [];
            let selectedDeliveryAddress = JSON.parse(this.localstorage.getItem("address"));
            let selectedBillingAddress = JSON.parse(this.localstorage.getItem("billingAddress"));
            for (let i = 0; i < response.data.length; i++) {
              let address = {
                account_id: response.data[i].account_id,
                address_id: response.data[i].address_id,
                contact_row_id:response.data[i].contact_row_id,
                contact_first_name: response.data[i].contact_first_name,
                contact_last_name:response.data[i].contact_last_name,
                contact_no:response.data[i].contact_no,
                alt_contact_no:response.data[i].alt_contact_no,
                city: response.data[i].city,
                district: response.data[i].district,
                gst_no: response.data[i].gst_no,
                postal_code: response.data[i].postal_code,
                pr_bl_addr_id: response.data[i].pr_bl_addr_id,
                state: response.data[i].state,
                street_address1: response.data[i].street_address1,
                street_address2: response.data[i].street_address2,
                street_address3: response.data[i].street_address3,
                taluka: response.data[i].taluka,
                isDefault: response.data[i].default,
                default: response.data[i].default,
                isBillingAddress:false,
                isDeliveryAddress:false,
              };
              if(response.selected_address['billing_details'] && response.selected_address['shipping_details']){
                address.isBillingAddress = response.selected_address['billing_details'].address_id == response.data[i].address_id ? true:false;
                address.isDeliveryAddress = response.selected_address['shipping_details'].address_id == response.data[i].address_id ? true:false;
              }
              this.editedAddresses.push(address);
              if (response?.data[i].address_id === selectedDeliveryAddress?.address?.address_id) {
                selectedDeliveryAddress.address = response?.data[i];
                this.selectedDeliveryPosition = i;
                this.isDeliveryAddressSelected = true;
              }
              if (response?.data[i].address_id === selectedBillingAddress?.address?.address_id) {
                selectedBillingAddress.address = response?.data[i];
                this.selectedBillingPosition = i;
                this.isBillingAddressSelected = true;
              }
              this.checkedBillingAddressId = selectedBillingAddress?.address?.address_id;
              this.checkedDeliveryAddressId = selectedDeliveryAddress?.address?.address_id;
              
              // this.defaultBillingAddress = i;
              // this.defaultDeliveryAddress = i;
              // this.default_address = address;
              // this.localstorage.setItem(
              //   'address',
              //   JSON.stringify({
              //     address: this.default_address,
              //     type: 'default',
              //   })
              // );
              // this.localstorage.setItem(
              //   'billingAddress',
              //   JSON.stringify({
              //     address: this.default_address,
              //     type: 'default',
              //   })
              // );
            }
            // added for selecting default billing and shipping addresss
            
            if( selectedBillingAddress?.address?.address_id != selectedDeliveryAddress?.address?.address_id ){
              if(this.selectedBillingPosition > 0 ){
                this.editedAddresses.splice(this.selectedBillingPosition, 1 );
                this.editedAddresses.splice(1,0,selectedBillingAddress?.address)
                this.selectedBillingPosition = 1
                this.editedAddresses.forEach((element,index) => {
                  if(element.address_id == selectedDeliveryAddress?.address.address_id){
                    this.selectedDeliveryPosition = index;
                  }
                });
              }
              if(this.selectedDeliveryPosition > 0  && this.editedAddresses.length > 2 ){
                this.editedAddresses.splice(this.selectedDeliveryPosition, 1)
                this.editedAddresses.splice(2,0,selectedDeliveryAddress?.address)
                this.selectedDeliveryPosition = 2
              }
              // this.selectedBillingPosition > 0 ? this.editedAddresses.splice(1,0,selectedBillingAddress?.address):'';
              // this.selectedDeliveryPosition > 0 && this.editedAddresses.length > 2 ? this.editedAddresses.splice(2,0,selectedDeliveryAddress?.address):'';
              // this.selectedBillingPosition > 0 ? this.selectedBillingPosition = 1 : '';
              // this.selectedDeliveryPosition > 0 && this.editedAddresses.length > 2 ? this.selectedDeliveryPosition = 2: '';
            }else{ 
              if(this.selectedBillingPosition > 0 ){
                this.editedAddresses.splice(this.selectedBillingPosition, 1 );
              }
              this.selectedBillingPosition > 0 ? this.editedAddresses.splice(1,0,selectedBillingAddress?.address) : '';
              this.selectedBillingPosition = 0;
              this.selectedDeliveryPosition = 0;
              if(this.editedAddresses.length > 1 && ( !selectedBillingAddress?.address.default && !selectedDeliveryAddress?.address.default ) ){
                this.selectedBillingPosition = 1;
                this.selectedDeliveryPosition = 1;
              }
            }
            if(this.usertype == 'RT'){
                this.editedAddresses.splice(1);
            }
            this.showLoader = false;
            this.totalrecord = this.usertype == 'RT'?1:response.total_result;
            this.addresscount = response.data.length - 1;
          }
          else{
            this.isNoRecord = true;
            this.showLoader = false;
          }
        });
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'getAddressNew(check)',
        page_name: 'MenuheaderComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }
  pageChange(page: any) {
    // window.scrollTo(0, 0);
    document.getElementById('addressHead').scrollIntoView();
    this.currentPage = page;
    page = (page - 1) * 29;
    let userData = JSON.parse(this.localstorage.getItem('userData'));
    const ListInput: GetAddressparameter = {} as GetAddressparameter;
    ListInput.account_pk = JSON.stringify(userData.account_pk);
    ListInput.offset = page;
    ListInput.limit = 29;
    ListInput.state = '';
    ListInput.district = '';
    ListInput.city = '';
    this.getAddressNew(ListInput);
  }
  closemodel() {
    this.closemodal.emit();
  }

  // setSelectedAddress() {
  //     this.SelectedAddress.emit(this.selectedAddress);
  // }

  bindAddress(address: any) {
    this.selectedAddress = address;
    //this.changedAddress.emit(address);
  }

  async setSelectedAddress() {
    try {
      if (!this.isBillingAddressSelected) {
        this.toastrService.error("Please Select Billing Address");
        return;
      }
      if (!this.isDeliveryAddressSelected) {
        this.toastrService.error("Please Select Delivery Address");
        return;
      }
      let userData = JSON.parse(this.localstorage.getItem('userData'));
      let selectedDeliveryAddress = JSON.parse(this.localstorage.getItem("address"));
      let selectedBillingAddress = JSON.parse(this.localstorage.getItem("billingAddress"));
      if( userData['user_type']=='FO' && JSON.stringify(this.selectedDeliveryAddress) !== '{}' && !this.selectedDeliveryAddress['postal_code'] ){
        this.pageChange(1);
        this.toastrService.error("The selected Delivery Address does not have a pincode, please create a new address with valid pincode.");  
        return;
      }else if(this.isBillingAddress != 'Yes' && this.isDeliveryAddress != 'Yes'){
        this.appStateService.postalCode.next( selectedDeliveryAddress?.address.postal_code );
        let address = selectedDeliveryAddress?.address;
        let type = address?.isDefault ? 'default' : 'new';
        this.appStateService.address.next({ address, type });
        this.localstorage.setItem( 'address', JSON.stringify({ address: selectedDeliveryAddress?.address, type: selectedDeliveryAddress?.address.isDefault ? 'default' : 'new',}));
        this.localstorage.setItem( 'Pincode', selectedDeliveryAddress?.address.postal_code );
      }
      let request = {
        "account_pk": JSON.stringify(userData.account_pk),
        "shipping_address_id": this.selectedDeliveryAddress['address_id'] && this.selectedDeliveryAddress['default']
         ?'default'
         :this.selectedDeliveryAddress['address_id'] && !this.selectedDeliveryAddress['default']
         ?this.selectedDeliveryAddress['address_id']
         :selectedDeliveryAddress.address.address_id && selectedDeliveryAddress.address.default
         ?'default'
         :selectedDeliveryAddress.address.address_id && !selectedDeliveryAddress.address.default
         ?selectedDeliveryAddress.address.address_id
         :"",
        "billing_address_id":this.selectedBillingAddress['address_id'] && this.selectedBillingAddress['default'] 
          ?'default'
          :this.selectedBillingAddress['address_id'] && !this.selectedBillingAddress['default']
          ?this.selectedBillingAddress['address_id']
          :selectedBillingAddress.address.address_id && selectedBillingAddress.address.default
          ?'default'
          :selectedBillingAddress.address.address_id && !selectedBillingAddress.address.default
          ?selectedBillingAddress.address.address_id
          :""
      }
      this.productService.setBillingShippingAddress(request).subscribe(
        res => {
          if(res.success){
            if (this.isBillingAddress == 'Yes') {
              this.localstorage.setItem( 'billingAddress',
                JSON.stringify({ address: this.selectedBillingAddress,type: this.selectedBillingAddress?.isDefault ? 'default' : 'new' })
              );
              for (let i = 0; i < this.editedAddresses.length; i++) {
                if (this.editedAddresses[i].address_id === this.selectedBillingAddress.address_id) {
                  this.selectedBillingPosition = i;
                }
              }
            }
            if (this.isDeliveryAddress == 'Yes') {
              this.appStateService.postalCode.next(
                this.selectedDeliveryAddress?.postal_code
              );
              for (let i = 0; i < this.editedAddresses.length; i++) {
                if (this.editedAddresses[i].address_id === this.selectedDeliveryAddress.address_id) {
                  this.selectedDeliveryPosition = i;
                }
              }
              let address = this.selectedDeliveryAddress;
              let type = address?.isDefault ? 'default' : 'new';
              this.appStateService.address.next({ address, type });
              this.localstorage.setItem( 'address', JSON.stringify({ address: this.selectedDeliveryAddress, type: this.selectedDeliveryAddress?.isDefault ? 'default' : 'new',}));
              this.localstorage.setItem( 'Pincode', this.selectedDeliveryAddress.postal_code );
            }
            this.toastrService.success(res.data.msg);
          }else{
            this.toastrService.error(res.data.msg);
          }
          this.closemodal.emit('Address changed');
          this.onRefresh();
        },
        error => {
          this.closemodal.emit('Address changed');
          this.onRefresh();
          this.toastrService.error(error.error.data.msg);
      });
      //this.appStateService.refreshCartOnAddressChange.next(true);
      //setTimeout(() => {
      //this.cart.load();
      //}, 500);
      //if (!this.isProductDetails) {
      
      //}
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'setSelectedAddress()',
        page_name: 'MenuheaderComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  applyPincode() {
    let pinVal = this.pincodeChangeFrom.controls['pincodeSearchCtrl'].value
    if (pinVal === '') {
      return this.toastrService.error('Please Enter pincode');
    }
    if (!/^[1-9][0-9]{5}$/.test(pinVal)) {
      return this.toastrService.error('Please Enter valid 6 digit pincode');
    }
    try {
      let requestBody = {
        dropdown_type: "get_data_by_pincode",
        multi_district: [],
        multi_taluka: [],
        multi_city: [],
        multi_pincode: [pinVal],
        offset: 0,
        limit: 10000
      }
      this.addressService.addAddressByPincode(requestBody).subscribe((response : any) => {
        if (response['success']) {
          this.localstorage.setItem( 'Pincode', pinVal );
          this.appStateService.postalCode.next(pinVal);
          this.closemodal.emit('Address changed');
          this.onRefresh();
          this.toastrService.success("Data update successfully");
        }
        else {
          if(response['data']?.msg){
            this.toastrService.error(response['data']?.msg);
          }
        }
      },error => {
        if(error?.error?.data?.msg){
          this.toastrService.error(error?.error?.data?.msg);
        }
      })
    }
    catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'addressByPinCode()',
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'deliveryAddress',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }



  onlyNumberKey(event) {
    console.log( this.pincodeChangeFrom.controls['pincodeSearchCtrl'].value.length );
    var ASCIICode = event.which ? event.which : event.keyCode;
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) return false;
    return true;
  }
  onRefresh() {
    // this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
    let currentUrl = this.router.url;
    // if (currentUrl.includes('cart/address')) {
    //   this.appStateService.refreshCartAddress.next(true);
    //   //window.location.reload();
    //   // this.router.navigateByUrl(currentUrl)
    //   //   .then(() => {
    //   //     this.router.navigated = false;
    //   //     this.router.navigate([this.router.url]);
    //   //   });
    // }
    // else if(currentUrl.includes('cart/payment')){
    //   this.appStateService.refreshPaymentAddress.next(true);
    // }
    // else if(currentUrl.includes("cart/summary")){
    //   this.localstorage.setItem("refreshCartItems","true");
    //   this.appStateService.refreshCartItems.next(true);
    // }
      if (this.router.url.includes("cart/address")) {
        // let cartAddressQueryParams = JSON.parse(this.localstorage.getItem("cartAddressQueryParams"));
        // this.localstorage.setItem("routeReused", "true");
        // this.router.navigateByUrl('/', { skipLocationChange: true })
        //   .then(() => this.router.navigate(['cart/address'], { queryParams: cartAddressQueryParams }));
        this.appStateService.refreshSummary.next(true);
      }
      else if (this.router.url.includes("cart/payment")) {
          // let cartPaymentQueryParams = JSON.parse(this.localstorage.getItem("cartPaymentQueryParams"));
          // this.localstorage.setItem("routeReused", "true");
          // this.router.navigateByUrl('/', { skipLocationChange: true })
          //   .then(() => this.router.navigate(['cart/payment'], { queryParams: cartPaymentQueryParams }));
          this.appStateService.refreshSummary.next(true);
      }
      else if (this.router.url.includes("cart/summary")) {
        // let cartSummaryQueryParams = JSON.parse(this.localstorage.getItem("cartSummaryQueryParams"));
        // this.localstorage.setItem("routeReused", "true");
        // this.router.navigateByUrl('/', { skipLocationChange: true })
        //   .then(() => this.router.navigate(['cart/summary'], { queryParams: cartSummaryQueryParams }));
        this.appStateService.refreshSummary.next(true);
      }
      if (currentUrl.includes("product-details")) {
        this.appStateService.refreshDiscounts.next(true);
      }
      if (currentUrl.endsWith("/")) {
         this.appStateService.refreshOfferHomePage.next(true);
      }
    // else if (currentUrl.includes('checkout')) {
    //   if (this.categoryType) {
    //     window.location.reload();
    //   }
    // } else if (currentUrl.includes('order-confirmation')) {
    //   if (this.categoryType) {
    //     window.location.reload();
    //   }
    // }
    // else if (currentUrl.includes('cart')) {
    //     window.location.reload();
    // }
    if(currentUrl.includes("account/notification")){
      this.appStateService.refreshTimelineData.next("TML");
    }
    if(currentUrl.includes("orders/create-schedule-order")){
      this.appStateService.refreshOrderShedulerAddress.next({"addChange":true,"actionType":"addChange"});
    }
    if(currentUrl.includes("account/myoffer") || currentUrl.includes("account/coupons")){
      this.appStateService.refreshOfferBanner.next(true);
    }
    if(currentUrl.includes("account/schemes")){
      this.appStateService.refreshMySchemes.next(true);
    }

  }

  refreshAddressList(){
    if(this.isLoggedIn){
      let userData = JSON.parse(this.localstorage.getItem('userData'));
      const ListInput: GetAddressparameter = {} as GetAddressparameter;
      ListInput.account_pk = JSON.stringify(userData.account_pk);
      ListInput.offset = 0;
      ListInput.limit = 29;
      ListInput.state = '';
      ListInput.district = '';
      ListInput.city = '';
      this.getAddressNew(ListInput);
    }
  }

  setDeliveryAddress(event, addres, type, i) {
    this.isDeliveryAddress = 'Yes';
    let address = addres;
    this.selectedDeliveryAddress = [];
    this.isDeliveryAddressSelected = event.checked;
    if (event.checked) {
      this.checkedDeliveryAddressId = addres.address_id;
      this.selectedDeliveryAddress = address;
      this.selectedDeliveryPosition = i;
    } else if (!event.checked) {
      this.selectedDeliveryAddress = null;
    }
  }

  setBillingAddress(event, addres, type, i) {
    this.isBillingAddress = 'Yes';
    let address = addres;
    this.selectedBillingAddress = [];
    this.isBillingAddressSelected = event.checked;
    if (event.checked) {
      this.selectedBillingAddress = address;
      this.checkedBillingAddressId = addres.address_id;
      this.selectedBillingPosition = i;
    } else if (!event.checked) {
      this.selectedBillingAddress = null;
    }
  }

  openContactPersonList(addressData?){
    if(addressData){
        this.addressData = addressData;
    }
    if(this.openAddContactPersonModalDetails){ 
        this.openAddContactPersonModalDetails.hide();
    }
    this.listContactPersonModalDetails = this.modalService.show(this.listContactPersonModal, {
        class: 'modal-dialog modal-dialog-centered modal-lg',
        backdrop: 'static',
    });
}

hideContactPersonList(){
    this.listContactPersonModalDetails.hide();
    this.refreshAddressList();
}

}
export class GetAddressparameter {
  account_pk: any
  offset: any
  limit: any
  state: string
  district: string
  city: string
}