import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { Observable } from 'rxjs';
import { applicationConfiguration, AppConfig } from '../../../../../src/app/config/app.config';

@Injectable({
  providedIn: 'root'
})
export class UserAddressService {

  protected server = this.appConfig.apiUrl+'api/v1/ecom/';
  protected newserver = this.appConfig.apiUrl+'api/v2/ecom/account/'; 
  // private options;
  readonly options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  constructor(
    private http: HttpClient,
    // private router: Router,
    // private appStateService: AppStateService,
    @Inject(applicationConfiguration) private appConfig: AppConfig
  ) { }

  addAddress(address): Observable<any> {
    return this.http.post(`${this.newserver}add_address/`,address,this.options);
  }
  
}
