import { ChangeDetectorRef, Component, HostListener, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../../../shared/services/REST/user.service';
import { ProductService } from '../../../../shared/services/REST/product.service';
import { secureStorage } from '../../../../shared/services/securestorage';
import { formatDate } from '@angular/common';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { Title } from '@angular/platform-browser';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-my-offers',
  templateUrl: './my-offers.component.html',
  styleUrls: ['./my-offers.component.scss'],
})
export class MyOffersComponent implements OnInit {
  ProductListData: any[] = [];

  layout: string = 'Grid';
  breadcrumbs;
  isNoDataFound: boolean = false;
  showMore: boolean = false;
  showMoreModel: boolean = false;
  showMoreSubModel: boolean = false;
  showMoreaggregate: boolean = false;
  url = "shop/productcards"
  mainheader: any[] = [];
  prodcardbreadcrumb: any[] = [];
  loading: boolean = false;
  offset: number = 0;
  limit: number = 20;
  isShowOnlyToDate: boolean = false;
  isLoggedIn:boolean=false;
  pinCode:number;
  constructor(
    private productService: ProductService,
    private localstorage: secureStorage,
    private cd: ChangeDetectorRef,
    private router: Router,
    private title:Title,
    public userService: UserService,
    private appStateService: AppStateService
  ) { }

  ngOnInit(): void {
    this.ProductListData = []
    this.isNoDataFound = false;
    this.getProduct(this.offset, this.limit);
    this.showMore = false;
    this.breadcrumbs = JSON.parse(
      this.localstorage.getItem('productcardBreadcrumb')
    );
    this.appStateService.isLoggedIn.subscribe((isLog) => {
      this.isLoggedIn = isLog;
    });
    this.appStateService.refreshOfferBanner.subscribe((val) => {
      if (val == true){
        this.ProductListData = []
        this.isNoDataFound = false;
        this.getProduct(0,20);
      }
    });
    this.title.setTitle('E-Dukaan | My Offers');
  }

  viewMore() {
    this.showMore = !this.showMore ? true : false;
  }

  viewMoreModel() {
    this.showMoreModel = !this.showMoreModel ? true : false;
  }

  viewMoreSubModel() {
    this.showMoreSubModel = !this.showMoreSubModel ? true : false;
  }

  viewMoreAggregate() {
    this.showMoreaggregate = !this.showMoreaggregate ? true : false;
  }

  GotoView(layoutMethod) {
    this.layout = layoutMethod;
  }
  getProduct(offset: any, limit: any) {
    try {
      let data;
      let user = JSON.parse(this.localstorage.getItem('userData'));
      let address = JSON.parse(this.localstorage.getItem('address'));
      this.pinCode = JSON.parse(this.localstorage.getItem('Pincode'));
      data = {
        "account_pk": "",
        "action_type": "discount_banner_list",
        "division_id": "",
        "organization_id": "",
        "from_date": "",
        "to_date": "",
        "offset": offset,
        "limit": limit,
        "pincode":this.pinCode ?this.pinCode.toString():""
      }
      if(user && address){
        data.account_pk = +JSON.stringify(user.account_pk) 
      }
      this.productService.getOfferDetailList(data).subscribe(
        (res) => {
          if (res.success) {
            let date = new Date()
            let newdate = formatDate(date, 'dd MMM yy', 'en-GB')
            this.ProductListData = res.data;
            // for (let i = 0; i < this.ProductListData.length; i++) {
            //   this.ProductListData[i].isShowOnlyToDate = false
            // }
            for (let i = 0; i < this.ProductListData.length; i++) {
              this.ProductListData[i].isShowOnlyToDate = false;
              this.ProductListData[i].from_date = formatDate(this.ProductListData[i].from_date, 'dd MMM yy', 'en-GB')
              this.ProductListData[i].to_date = formatDate(this.ProductListData[i].to_date, 'dd MMM yy', 'en-GB')
              if (this.ProductListData[i].from_date == this.ProductListData[i].to_date || this.ProductListData[i].from_date == newdate)
                this.ProductListData[i].isShowOnlyToDate = true
            }
            if (this.ProductListData.length == 0) {
              this.isNoDataFound = true
            }
          }
          if (!res.success)
            this.isNoDataFound = true
        },
        (err) => {
          this.isNoDataFound = true
         }
      )
    }
    catch (error) {
      this.isNoDataFound = true
      this.userService.InsertExceptionLog({
        method_name: 'getProducts(page)',
        page_name: 'PageCategoryComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }
  ngOnDestroy() {
    this.localstorage.removeitems("CurrentListQueryParam");
  }
  GotoPartCategory(partcategory, type, isViewAll, discount_code) {
    if(!this.isLoggedIn){
      Swal.fire({
        html: '<h5 style="margin-top:1rem;font-weight: 700;">Please login to view applicable discounts.</h5>',
        confirmButtonText: 'Click Here To Login',
        confirmButtonColor:'#1c1479'
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            this.router.navigate(['account/login'],{ queryParams:{isLogin:true} } );
        }
      })
    }else{
      localStorage.removeItem(
        'productcardBreadcrumb'
      );
      this.prodcardbreadcrumb.push({
        label: partcategory,
        url: this.url,
        params: { searchBy: partcategory, type: type, isViewAll: isViewAll },
      });
      let newarr = [...this.mainheader, ...this.prodcardbreadcrumb];
      this.cd.markForCheck();
      this.localstorage.setItem('pgLineDiscountCode', discount_code)
      this.localstorage.setItem('productcardBreadcrumb', JSON.stringify(newarr));
      this.router.navigate(['/shop/productcards'], {
        queryParams: { searchBy: partcategory, type: type, isViewAll: isViewAll, pgLineDiscount: true },
      });
    }
  }
  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }
  @HostListener('window:scroll', [])
  async onScroll() {
    if ( !this.ProductListData || this.ProductListData?.length === 0 || this.isNoDataFound || this.loading) {
      return;
    }
    else {
      if ( window.innerHeight + window.scrollY + 950 >= document.body.offsetHeight ) {
        this.loading = true;
        await this.loadingMoreData();
      } else if (
        window.innerHeight + window.scrollY <
        document.body.offsetHeight
      ) {
      }
    }

  }
  async loadingMoreData() {
    try{
      let date = new Date()
      let newdate = formatDate(date, 'dd MMM yy', 'en-GB')
      this.offset = this.offset + this.limit ;
      let user = JSON.parse(this.localstorage.getItem('userData'));
      this.pinCode = JSON.parse(this.localstorage.getItem('Pincode'));
      if( this.isNoDataFound && this.loading ){
        return
      }else{
        let data ={
          "account_pk":"",
          "action_type": "discount_banner_list",
          "division_id": "",
          "organization_id": "",
          "from_date": "",
          "to_date": "",
          "offset": this.offset,
          "limit": this.limit,
          "pincode":this.pinCode ?this.pinCode.toString():""
        }
        if(user){
          data.account_pk = JSON.stringify(user.account_pk);
        }
        await this.productService.getOfferDetailList(data).subscribe(
          res => {
            this.loading = false;
            if(res.success){
              res.data.forEach(element => {
                this.ProductListData.push(element);
              });
              for (let i = 0; i < this.ProductListData.length; i++) {
                this.ProductListData[i].isShowOnlyToDate = false;
                this.ProductListData[i].from_date = formatDate(this.ProductListData[i].from_date, 'dd MMM yy', 'en-GB')
                this.ProductListData[i].to_date = formatDate(this.ProductListData[i].to_date, 'dd MMM yy', 'en-GB')
                if (this.ProductListData[i].from_date == this.ProductListData[i].to_date || this.ProductListData[i].from_date == newdate)
                  this.ProductListData[i].isShowOnlyToDate = true
              }
            }else{
              this.isNoDataFound = true;
            }
          }, error => { this.isNoDataFound = true; this.loading = false; }
        )  
      }
    }catch(error){
      this.isNoDataFound = true;
      this.loading = false;
    }
    // this.getProduct(this.offset, this.limit)
  }
}