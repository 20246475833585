<div class="address_sec pt-4">
    <div class="container-fluid">
        <h5 class="addrs-heading">My Address</h5>
        <div class="card addrs-card  mt-3 border-0 shadow mb-3">
            <div class="pt-2">
                <strong data-toggle="popover"  placement="bottom" ngbPopover=" Newly added address may take sometime to get updated in the list. Thank you for your patience." triggers="mouseenter:mouseleave" popoverTitle="Note">
                    <span *ngIf="usertype !='RT'" class="error-message"><img  src="assets/icons/info.png" style="width:20px;height:20px;"></span>
                    <ng-container *ngIf="usertype !='RT'">Can't see your recently added address?&nbsp;</ng-container>
                    <span *ngIf="!isHideAddressList && usertype !='RT'"   class="refresh-button"
                      (click)="refreshAddressList()">Refresh List</span>
                    <span *ngIf="isHideAddressList && usertype !='RT'" class="refresh-button"
                    (click)="getHideAddressList()">Refresh List</span>
                </strong><br>
                <ng-container *ngIf="usertype==='RT'"><strong><span class="error-message">**</span>As you are logged into the retailer's account, the Add Address feature is unavailable. Please log in as Fleet Owner Account to request delivery to specific addresses.</strong><br></ng-container>
                <strong><span class="error-message">**</span>Address is not editable</strong>
                <a *ngIf="!isHideAddressList && usertype !='RT'" (click)="getHideAddressList()" class="btn btn-outline-primary me-3 mt-3 mt-sm-0" style="float: right;">Archived Address</a>
                <a *ngIf="isHideAddressList && usertype !='RT'" (click)="refreshAddressList(true)" class="btn btn-outline-primary me-3 mt-3 mt-sm-0" style="float: right;">Unarchive Address</a>
            </div>
            <div class="card-body pt-5">
                <div class="row row-cols-2 row-sm-cols-3 row-md-cols-4 row-lg-cols-5 row-xl-cols-5">
                    <div class="col mb-3 p-3 border border-dashed mat-min-h addrs-box add_new_addrs position-relative"  *ngIf="!isHideAddressList && ( usertype==='FO' || usertype == 'GU' )">
                        <!-- <div class=""> -->
                            <div class="add_new_cta">
                                <a (click)="openModal()" class="text-decoration-none add_new-addrs-cta cursor"><img
                                        src="./assets/images/add-new-icon.svg" class="img-fluid me-1" /> Add New</a>
                            </div>
                        <!-- </div> -->
                        <!-- <div class="add_new_cta" (click)="popupLoad()">
                            <a href="javascript:void(0)" class="text-decoration-none add_new-addrs-cta"><img
                                    src="../../assets/images/add-new-icon.svg" class="img-fluid me-1" /> Add New</a>
                        </div> -->
                    </div>
                    
                    <ng-container *ngIf="addresses?.length; else elseTemplate">
                        <div class="col mb-3 p-3 border border-dashed mat-min-h addrs-box position-relative"
                            *ngFor="let address of addresses; let i = index;">
                            <p class="p-2 dt-address" *ngIf="address.isDefault"><span
                                    class="border p-2 border-success text-success"><i class="fas fa-check-circle me-2"></i>
                                    Default </span></p>
                            <div class="d-flex justify-content-between  mb-2 ">
                                <!-- <h4 class="mat-location-type mb-0">Office</h4> -->
                                <!-- <div class="action_icon ms-auto">
                                    <img src="./assets/images/icons-delete.svg" class="img-fluid me-3" />
                                    <img src="./assets/images/edit.svg" class="img-fluid" />
                                </div> -->
                            </div>

                            <p class="mat-location-name">{{address.street_address1}}</p>
                            <!-- (change)="changeAccountStatus($event,account.account_id);" [disabled]="userDetails?.account_id === account.account_id" [checked]="account?.is_active" -->
                            <p class="mat-deliver-address">
                                {{(address.street_address1) ? address.street_address1 : ''}}
                                {{(address.street_address2) ? address.street_address2 + ',' : ''}}
                                {{(address.street_address3) ? address.street_address3 + ',' : ''}}
                                {{(address.district) ? address.district + ',' : ''}}
                                {{(address.taluka) ? address.taluka + ',' : ''}}
                                {{(address.city) ? address.city + ',' : ''}}
                                {{(address.state) ? address.state + ',' : ''}}
                                {{(address.postal_code) ? address.postal_code + ',' : ''}}
                            </p>
                            <p class="mat-gst" *ngIf="address?.gst_no && (address?.gst_no !='')">GST No. {{ address?.gst_no
                                }} </p>
                            <p *ngIf="!isHideAddressList && !address.isDefault" style="position: absolute; bottom: 0;">
                                <mat-label ngClass="labelBeforeSlide">Archive</mat-label>
                                <mat-slide-toggle #toggleElement [checked]="checked" (change)="hideAddressFromList(address.address_id, toggleElement)"></mat-slide-toggle>
                            </p>
                            <p *ngIf="isHideAddressList && !address.isDefault" style="position: absolute; bottom: 0;">
                                <mat-label ngClass="labelBeforeSlide">Unarchive</mat-label>
                                <mat-slide-toggle #toggleElement1 [checked]="true" (change)="unhideAddressFromList(address.address_id, toggleElement1)"></mat-slide-toggle>
                            </p>
                        </div>
                    </ng-container>  
                    <ng-template #elseTemplate>
                        <ng-container *ngIf="!isAddressEmpty && !addresses?.length else noRecordsBlock">
                        <div class="col mb-3 p-3 border border-dashed mat-min-h addrs-box position-relative"
                        *ngFor="let address of userService.generateFake(9); let i = index;">
                        <p class="p-2 dt-address">
                                <skc-rectangle [loading]="loading" [skc-height]="'65px'" [skc-width]="'100%'"> </skc-rectangle> </p>
                        <div class="d-flex justify-content-between  mb-2 ">
                        </div>

                        <p class="mat-location-name"> <skc-rectangle [loading]="loading" [skc-height]="'19px'" [skc-width]="'100%'"> </skc-rectangle></p>
                        <p class="mat-deliver-address"> 
                            <skc-rectangle [loading]="loading" [skc-height]="'57px'" [skc-width]="'100%'"> </skc-rectangle>
                        </p>
                        <p class="mat-gst"> 
                            <skc-rectangle [loading]="loading" [skc-height]="'19px'" [skc-width]="'100%'"> </skc-rectangle> 
                        </p>
                        </div>
                    </ng-container>
                    </ng-template>
                    <ng-template #noRecordsBlock>
                        <h5>No Records In Address List</h5>
                    </ng-template>
                </div>
                <div class="paging" *ngIf="totalrecord>30 && addresses?.length && usertype !='RT'">
                    <order-pagination
                      [activePage]="currentPage"
                      [totalRecords]="totalrecord"
                      [vehicleCount]="addresscount"
                      [recordsPerPage]="30"
                      [hideShowingRecords] = "true"
                      (onPageChange)="pageChange($event)"
                    ></order-pagination>
                  </div>
            </div>
        </div>
    </div>

</div>

    <ng-template #addAddressModal let-d="dismiss">
        <add-address (closePopUp)="closeModal($event);"></add-address>
    </ng-template>
    
<!-- <div class="loader" *ngIf="showLoader">
    <div class="ng-spinner ng-spinner-icon"></div>
</div> -->


