
<div class="modal-header modal-title"  >
    {{ isLoggedIn?'Select Billing & Delivery Address':'Pincode Search' }}
    <div (click)="closemodel()" type="button"><i class="fa fa-times"></i></div>
</div>
<div class="modal-body">
    <ng-container *ngIf="usertype != 'RT'">
        <form [formGroup]="pincodeChangeFrom" name="pincodeChangeFrom" (keypress)="onlyNumberKey($event);" class="form" (ngSubmit)="applyPincode()">
            <div class="row col-12 mb-2">
                <div class="col-6">
                    <input formControlName="pincodeSearchCtrl"  class="form-control" maxlength="6" placeholder="Enter an Indian Pincode" type="text">
                </div>
                <div class="col-6">
                    <button class="btn btn-primary mobile-veiw" type="submit">Apply</button>
                </div>
            </div>
        </form>
    </ng-container>
    <!-- <div>
       <hr>
    </div> -->
    <div *ngIf="isLoggedIn && usertype != 'RT'" class="row align-items-center" style="padding: 1rem;">
        <div class="col dropdown-divider"></div>
        <div class="col-auto"><h6>or select an address from list</h6></div>
        <div class="col dropdown-divider"></div>
    </div>
    <div *ngIf="isLoggedIn" class="form-group form-row">
        <div class="addresses-list" style="max-height:calc(100vh - 220px); overflow-y: auto;">
            <div class="row m-0 w-100" id="addressHead" >
            <ng-container *ngIf="editedAddresses?.length; else elseTemplate">
                <div class="p-3 border border-dashed mat-min-h setdeliveryaddress col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4" *ngFor="let address of editedAddresses;let i=index;">
                <div class="d-flex justify-content-between align-items-center mb-2 ">
                    <p class="p-2"><span class="border p-1 border-success"
                        style="font-size: 12px; font-weight: 600;color: #3e894b;"
                          *ngIf="address.isDefault"><i
                            class="fas fa-check-circle me-2 cursor"></i> Default </span></p>
                  <h4 class="mat-location-type mb-0">
                    <!-- Office -->
                  </h4>
                </div>
                <p class="mat-location-name">{{address.street_address1}}
                    <img src="./assets/images/contactPerson.png" (click)="openContactPersonList(address)" class="img-fluid me-1 contact-person-list-icon" />
                </p>
                <p class="mat-deliver-address">{{(address.street_address1) ? address.street_address1 : ''}}
                    {{(address.street_address2) ? address.street_address2 + ',' : ''}}
                    {{(address.street_address3) ? address.street_address3 + ',' : ''}}
                    {{(address.city) ? address.city + ',' : ''}}
                    {{(address.district) ? address.district + ',' : ''}}
                    {{(address.taluka) ? address.taluka + ',' : ''}}
                    {{(address.state) ? address.state + ',' : ''}}
                    {{(address.postal_code) ? address.postal_code + ',' : ''}}
                </p>
                <p class="mat-phone" *ngIf="( address.contact_first_name && address.contact_first_name !='' ) || ( address.contact_last_name && address.contact_last_name !='' ) "  >
                    Contact Person: 
                    <span *ngIf="address.contact_first_name && address.contact_first_name !=''">
                      {{ address.contact_first_name }}
                    </span>
                    <span *ngIf="address.contact_last_name && address.contact_last_name !=''">
                      {{ address.contact_last_name }}
                    </span>
                </p>
                <p class="mat-phone" *ngIf="address?.contact_no && address?.contact_no !=''">Contact No. : +91
                  {{ address?.contact_no }}</p>
                <p class="mat-phone" *ngIf="(!address?.contact_no || address?.contact_no =='') && (address?.alt_contact_no && address?.alt_contact_no !='') ">
                  Contact No. : +91 {{ address.alt_contact_no }}</p>
                <p class="mat-gst" *ngIf="address?.gst_no !='' && address?.gst_no !=null && address?.gst_no !=undefined">GST No. {{ address?.gst_no }} </p>
                <div class="from-bottom">
                    <mat-checkbox class="example-margin" class="billing" [checked]="selectedBillingPosition === i && (checkedBillingAddressId == address.address_id)" (change)="setBillingAddress($event,address,'Billing',i)">Set as Billing Address</mat-checkbox>
                    <mat-checkbox class="example-margin" class="delivery" [checked]="selectedDeliveryPosition === i && (checkedDeliveryAddressId == address.address_id)" (change)="setDeliveryAddress($event,address,'Delivery',i)">Set as Delivery Address</mat-checkbox><br>
                </div>
                
              </div>
            </ng-container>
            <ng-template #elseTemplate>
                <ng-container *ngIf="!isNoRecord && !editedAddresses?.length else noRecordsBlock">
                <div class="p-3 border border-dashed mat-min-h setdeliveryaddress col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4" *ngFor="let address of userService.generateFake(9); let i=index;">
                    <div class="d-flex justify-content-between align-items-center mb-2 ">
                        
                      <h4 class="mat-location-type mb-0">
                      </h4>
                    </div>
                    <p class="mat-location-name">
                        <skc-rectangle [loading]="loading" [skc-height]="'19px'" [skc-width]="'100%'"> </skc-rectangle>
                    </p>
                    <p class="mat-deliver-address">
                        <skc-rectangle [loading]="loading" [skc-height]="'57px'" [skc-width]="'100%'"> </skc-rectangle>
                    </p>
                    <p class="mat-gst">
                        <skc-rectangle [loading]="loading" [skc-height]="'19px'" [skc-width]="'100%'"> </skc-rectangle>
                     </p>
                    <div class="from-bottom">
                        <skc-rectangle [loading]="loading" [skc-height]="'48px'" [skc-width]="'100%'"> </skc-rectangle> 
                    </div>
                    
                  </div>
                </ng-container>
            </ng-template>
            <ng-template #noRecordsBlock>
                <div class="no-record-block" *ngIf="isNoRecord">
                    <b> No Records Found</b>
                  </div>
            </ng-template>
              <div class="addresses-list__divider"></div>
              
            </div>
        </div>
    </div>
</div>
<div class="paging" *ngIf="totalrecord">
    <order-pagination
      [activePage]="currentPage"
      [totalRecords]="totalrecord"
      [vehicleCount]="addresscount"
      [recordsPerPage]="30"
      [hideShowingRecords] = "true"
      (onPageChange)="pageChange($event)"
    ></order-pagination>
  </div>
<div class="modal-footer" *ngIf="totalrecord">
    <button type="button"  class="btn btn-primary" (click)="setSelectedAddress()" style="color: #ffff !important;">
        &nbsp;Submit</button>
</div>


 <!-- <div class="modal-header">
    <h4 class="modal-title">Select Delivery Address</h4>
    <button type="button" class="btn-close" (click)="closemodel()">&times;</button>
</div> -->
            <!-- <div class="addresses-list__item card address-card row" *ngIf="default_address">
                <div class="address-card__badge" *ngIf="default_address">Default</div>
                <div class="address-card__body">
                    <div class="address-card__name">
                        <div class="custom-control custom-radio">
                            <input type="radio" class="custom-control-input" (change)="bindAddress(default_address)" id="{{default_address.address_id}}" 
                            checked value="{{default_address.address_id}}" [(ngModel)]="SelectAddressId">
                            &nbsp;<label class="custom-control-label" for="{{default_address.address_id}}">
                                {{default_address?.street_address1}}</label>
                        </div>
                    </div>
                    <div class="address-card__row">
                        {{default_address?.street_address1}}, {{default_address?.street_address2}}, 
                        {{ default_address?.city }},{{ default_address?.taluka }}, {{ default_address?.district }}<br>
                        {{ default_address?.state }}, {{ default_address?.postal_code }}<br>
                    </div>
                    <div class="address-card__row" *ngIf="default_address?.phone_number">
                        <div class="address-card__row-title">Phone Number</div>
                        <div class="address-card__row-content">+91 {{ default_address?.phone_number }}
                        </div>
                    </div>
                    <div class="address-card__row" *ngIf="default_address?.email">
                        <div class="address-card__row-title">Email Address</div>
                        <div class="address-card__row-content">{{ default_address?.email }}</div>
                    </div>
                </div>
            </div>
            <div class="addresses-list__divider"></div>
            <ng-container *ngIf="new_addresses?.length && usertype==='FO'">
                <ng-container *ngFor="let address of new_addresses">
                    <div class="addresses-list__item card address-card">
                        <div class="address-card__body">
                            <div class="address-card__name">
                                <div class="custom-control custom-radio">
                                    <input type="radio" (change)="bindAddress(address)" class="custom-control-input" id="{{address.address_id}}"
                                        value="{{ address.address_id}}" [(ngModel)]="SelectAddressId">
                                &nbsp;<label class="custom-control-label" for="{{address.address_id}}">{{
                                        address?.street_address1 }}</label>
                                </div>
                            </div>
                            <div class="address-card__row">
                                {{ address?.street_address1 }},<br>
                                {{ address?.street_address2 }},<br>
                                {{ address?.city }}, {{ address?.taluka }}<br>
                                {{ address?.district }}, {{ address?.state }}, {{ address?.postal_code }}
                            </div>
                            <div class="address-card__row" *ngIf="address?.contact_no">
                                <div class="address-card__row-title">Contact No.</div>
                                <div class="address-card__row-content">+91 {{ address?.contact_no }}</div>
                            </div>
                            <div class="address-card__row" *ngIf="address?.gst_no !=''">
                                <div class="address-card__row-title">GST No.</div>
                                <div class="address-card__row-content">{{ address?.gst_no }}</div>
                            </div>
                            <div class="address-card__row" *ngIf="address?.phone_number">
                                <div class="address-card__row-title">Phone Number</div>
                                <div class="address-card__row-content">+91 {{ address?.phone_number }}</div>
                            </div>
                            <div class="address-card__row" *ngIf="address?.email">
                                <div class="address-card__row-title">Email Address</div>
                                <div class="address-card__row-content">{{ address?.email }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="addresses-list__divider"></div>
                </ng-container>
            </ng-container>
            <div class="addresses-list__divider"></div> -->


            <!-- <ng-container *ngIf="showLoader">
                <div class="loader">
                  <div class="ng-spinner ng-spinner-icon"></div>
                </div>
              </ng-container> -->
<ng-template #listContactPersonModal let-d="dismiss">
    <app-contact-person-list [addressData]="addressData" (closeContactPerson)="hideContactPersonList()"></app-contact-person-list>
</ng-template>

<ng-template #openAddContactPersonModal>
    <app-logout [isContactPersonAdd]="contactPersonAddModal"  (ContactPersonAdd)="openContactPersonList()" (modalClose)="this.openAddContactPersonModalDetails.hide()"  ></app-logout>
</ng-template>