import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Inputdata } from '../shared/interfaces/product';
import { ModelService } from '../shared/services/REST/model.service';
import { UserService } from 'src/app/shared/services/REST/user.service';
import { secureStorage } from '../shared/services/securestorage';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AppStateService } from '../shared/services/app-state/app-state.service';
import { GoogleAnalyticsService } from '../shared/services/GoogleAnalytics/GoogleAnalytics.service';
declare var $: any;
@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {
  prodcutmenuData = [];
  prodcutsubmenuData: any;
  preloginHomeBannerList: any[] = [];
  preloginRightBannerList = [];
  prodcardbreadcrumb = [];
  centerBanners: any[] = [];
  currentBanner: any;
  @Input() loading;
  showLeftBanner: boolean = true;
  showCenterBanner: boolean = true;
  showRightBanner: boolean = true;
  customOptionsForBanner: OwlOptions = {
    items: 1,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    // navSpeed: 700,
    autoWidth: false,
    nav: false,
    autoplay: true,
    autoplaySpeed: 1000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
  }
  isLoggedIn: boolean = false;
  userDetails:any;
  accountPK:any=''
  constructor(
    private model: ModelService,
    private router: Router,
    private toasterService: ToastrService,
    public userService: UserService,
    private localstorage: secureStorage,
    private appStateService: AppStateService,
    private googleAnlyatics: GoogleAnalyticsService,
  ) {}

  ngOnInit(): void {
    let userDetails = JSON.parse(this.localstorage.getItem('userData'));
    this.accountPK = userDetails?.account_pk
    this.appStateService.isLoggedIn.subscribe((isLog) => {
      this.isLoggedIn = isLog;
    });
    this.showNewProduct();
    // this.getPreloginHomeBanner();
    // this.getPreloginRightBanner();
    this.getPreloginBanners();
    
    $('#bannerslider').carousel({
      interval: 1000
    });
  }

  async showNewProduct() {
    const ListInput: Inputdata = {} as Inputdata;
    let response = await this.model.getProductMenu(ListInput);
    if (response['success'] == true) {
      this.showLeftBanner = true;
      this.prodcutmenuData = [];
      this.prodcutmenuData = response['data'];
    } else {
      this.showLeftBanner = false;
      this.prodcutmenuData = [];
    }
  }

  async getProductName(item) {
    localStorage.removeItem('prodcardbreadcrumb');
    const ListInput: Inputdata = {} as Inputdata;
    ListInput.group_category_list = item.category;
    let response = await this.model.getdataforhome(ListInput);
    if (response['success'] == true) {
      this.prodcutsubmenuData = [];
      var prodcatdata = response['data'];
      for (var type in response['data']) {
        this.prodcutsubmenuData.push(
          this.productsubmenuformData(response['data'][type]['pg_line'], type)
        );
      }
      var catdata = this.prodcutsubmenuData.filter((Data) => Data.categorylist);
      if (catdata.length > 0) {
        let line_2 = null;
        catdata.forEach(element => {
          if(element.categorylist.length > 0 ){
            line_2 = element.categorylist[0].line_2
            return
          }
        });
        if(line_2){
          this.GotoPartCategory( item.group_category,line_2,'PARTS CATEGORIES');
        }else{
          this.toasterService.error('Data not found');  
        }
      } else {
        this.toasterService.error('Data not found');
      }
    } else {
      this.prodcutsubmenuData = [];
    }
  }

  productsubmenuformData(categoylist: any, itemname: any): productsubmenu {
    const objstateData: productsubmenu = {} as productsubmenu;
    objstateData.groupcategoryname = itemname;
    objstateData.categorylist = categoylist;
    return objstateData;
  }

  GotoPartCategory(itemcategory, partcategory, type) {
    if (partcategory) {
      this.prodcardbreadcrumb.push({
        label: itemcategory,
        url: 'shop/productcards',
        params: {
          searchBy: partcategory,
          type: type,
        },
      });
      this.localstorage.setItem(
        'productcardBreadcrumb',
        JSON.stringify(this.prodcardbreadcrumb)
      );
      this.router.navigate(['/shop/productcards'], {
        queryParams: { searchBy: partcategory, type: type },
      });
    }
  }

  getPreloginHomeBanner() {
    let newRequestBody = {
      banner_type: 'PreloginHomeBanner',
      account_pk:this.isLoggedIn == true ? this.accountPK : null
    };
    this.userService.getBannerImages(newRequestBody).subscribe(
      (response) => {
        if (response.success) {
          this.showCenterBanner = true;
          this.centerBanners = response.data;
          this.preloginHomeBannerList = response.data;
          //let currentBanner = response.data[0];
          //this.changeBanner(currentBanner);
        } else {
          this.showCenterBanner = false;
          if (response?.data?.msg) {
            this.toasterService.error(response.data.msg);
          }
        }
      },
      (error) => {
        this.showCenterBanner = false;
        if (error?.error?.data?.msg) {
          this.toasterService.error(error.error.data.msg);
        }
      }
    );
  }

  getPreloginBanners() {
    let requestBody = {
      multi_banner_type: ["PreloginHomeBanner"],
      offset: 0,
      limit: 10,
      account_pk:this.isLoggedIn == true ? this.accountPK : null
    }

    let requestBody1 = {
      multi_banner_type: ["PreloginRightBanner"],
      offset: 0,
      limit: 3,
      account_pk: this.isLoggedIn == true ? this.accountPK : null
    }
    this.centerBanners = [];
    this.preloginRightBannerList = [];
    this.preloginHomeBannerList = [];
    this.userService.getBannerImages(requestBody).subscribe(response => {
      if (response.success) {
        let banners: any[] = response.data;
        banners?.forEach(banner => {
          if (banner.banner_type === "PreloginHomeBanner") {
            this.showCenterBanner = true;
            this.centerBanners.push(banner);
            this.preloginHomeBannerList.push(banner);
          }
        })
      }
      else {
        this.showRightBanner = false;
        this.showCenterBanner = false;
        if (response?.data?.msg) {
          this.toasterService.error(response.data.msg);
        }
      }
    }, error => {
      this.showCenterBanner = false;
      this.showRightBanner = false;
      if (error?.error?.data?.msg) {
        this.toasterService.error(error.error.data.msg);
      }
    });

    this.userService.getBannerImages(requestBody1).subscribe(response => {
        if (response.success) {
        let banners: any[] = response.data;
        banners?.forEach(banner => {
          if (banner.banner_type === "PreloginRightBanner") {
            this.showRightBanner = true;
            this.preloginRightBannerList.push(banner);
          }
          else {
            this.showRightBanner = false;
            this.showCenterBanner = false;
            if (response?.data?.msg) {
              this.toasterService.error(response.data.msg);
            }
          }
        })
      }
    },
    error => {
      this.showCenterBanner = false;
      this.showRightBanner = false;
      if (error?.error?.data?.msg) {
        this.toasterService.error(error.error.data.msg);
      }
     });
  }

  async getPreloginRightBanner() {
    let newRequestBody = {
      banner_type: 'PreloginRightBanner',
      account_pk:this.isLoggedIn == true ? this.accountPK : null
    };
    await this.userService.getBannerImages(newRequestBody).subscribe(
      (response) => {
        if (response.success) {
          this.showRightBanner = true;
          this.preloginRightBannerList = response.data;
        } else {
          this.showRightBanner = false;
          this.toasterService.error(response.data.msg);
        }
      },
      (error) => {
        this.showRightBanner = false;
        if (error?.error?.data?.msg) {
          this.toasterService.error(error.error.data.msg);
        }
      }
    );
  }

  changeBanner(banner){
    this.currentBanner = banner;
  }

  onBannerRedirect(url:any,type:any){
    if(url?.banner_hyper_link){
      if(this.isLoggedIn){
        var MobileNo = null;
        if (
          this.localstorage.getItem('userData') != null ||
          this.localstorage.getItem('userData') != undefined
        ) {
          let userData = JSON.parse(
            this.localstorage.getItem('userData')
          );
          MobileNo = userData.contact_no;
        }

        var Address = '';
        if (
          this.localstorage.getItem('address') != null ||
          this.localstorage.getItem('address') != undefined
        ) {
          let address = JSON.parse(this.localstorage.getItem('address'));
          Address =
            address.address.street_address1 +
            ', ' +
            address.address.state +
            ', ' +
            address.address.district +
            ', ' +
            address.address.city +
            ', ' +
            address.address.postal_code;
        }
        if(type == 'PreloginHomeBanner'){
          try {
            this.googleAnlyatics.bannerClick(
              'PreloginHomeBanner',
              Address,
              MobileNo
            );
          } catch (error) {}
        }
        if(type == 'PreloginRightBanner'){
          try {
            this.googleAnlyatics.bannerClick(
              'PreloginRightBanner',
              Address,
              MobileNo
            );
          } catch (error) {}
        }
      }
      window.open(url?.banner_hyper_link);
    }
    if(url?.part_numbers?.length != 0){
      if(this.isLoggedIn){
        var MobileNo = null;
        if (
          this.localstorage.getItem('userData') != null ||
          this.localstorage.getItem('userData') != undefined
        ) {
          let userData = JSON.parse(
            this.localstorage.getItem('userData')
          );
          MobileNo = userData.contact_no;
        }

        var Address = '';
        if (
          this.localstorage.getItem('address') != null ||
          this.localstorage.getItem('address') != undefined
        ) {
          let address = JSON.parse(this.localstorage.getItem('address'));
          Address =
            address.address.street_address1 +
            ', ' +
            address.address.state +
            ', ' +
            address.address.district +
            ', ' +
            address.address.city +
            ', ' +
            address.address.postal_code;
        }
        if(type == 'PreloginHomeBanner'){
          try {
            this.googleAnlyatics.bannerClick(
              'PreloginHomeBanner',
              Address,
              MobileNo
            );
          } catch (error) {}
        }
        if(type == 'PreloginRightBanner'){
          try {
            this.googleAnlyatics.bannerClick(
              'PreloginRightBanner',
              Address,
              MobileNo
            );
          } catch (error) {}
        }
      }
      // if(url?.banner_title){
      //   this.localstorage.setItem("bannerTitle","Title");
      // }
      this.router.navigate(['/shop/productcards'], {
        queryParams: {
          bannerId: url?.id,
        },
      });
    }
  }
}
export class productsubmenu {
  groupcategoryname: any
  categorylist: any
}