<div class="modal-content">
    <div class="modal-header modal-title">
        Contact Person List
        <div (click)="closeContactPerson.emit()" type="button"><i class="fa fa-times"></i></div>
    </div>
    <div class="modal-body">
      <div class="row">
            <div class="table-responsive tableFixHead" [id]="contactPersonList.length >5?'newHeightTableHeader':''">
                <table  class="table datatable display">
                    <!-- datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" -->
                    <thead>
                        <tr class="top-heading-border">
                            <th>
                                <a *ngIf="!showHideContactList" (click)="getContactPersonList()" class="text-decoration-none add_new-addrs-cta cursor">Refresh List</a>
                                <a *ngIf="showHideContactList" (click)="getHideContactPersonList()" class="text-decoration-none add_new-addrs-cta cursor">Refresh List</a>
                            </th>
                            <th>
                                <a *ngIf="!showHideContactList" (click)="getHideContactPersonList()" class="text-decoration-none add_new-addrs-cta cursor">View removed contacts</a>
                                <a *ngIf="showHideContactList" (click)="getContactPersonList()" class="text-decoration-none add_new-addrs-cta cursor">View active contacts</a>
                            </th>
                            <th></th>
                            <th *ngIf="!showHideContactList" style="text-align: end;">
                                <a *ngIf="!showHideContactList" (click)="openAddContactPersonModal()" class="text-decoration-none add_new-addrs-cta cursor"><img
                                    src="./assets/images/add-new-icon.svg" class="img-fluid me-1" /> Add Contact</a>
                            </th>
                        </tr>
                        <tr>
                            <th>Name</th>
                            <th>Number</th>
                            <th *ngIf="!showHideContactList">Select Contact</th>
                            <th>
                                <span *ngIf="!showHideContactList">Remove Contact</span>
                                <span *ngIf="showHideContactList">Unhide Contact</span>
                            </th>
                        </tr>
                    </thead>
                    <ng-container *ngIf="!isApiLoaded">
                        <tr *ngFor="let item of userService.generateFake(5);">
                            <td>
                                <skc-rectangle [loading]="loading" [skc-height]="'15px'" [skc-width]="'250px'"></skc-rectangle><br>
                            </td>
                            <td>
                                <skc-rectangle [loading]="loading" [skc-height]="'15px'" [skc-width]="'250px'"></skc-rectangle><br>
                            </td>
                            <td>
                                <skc-rectangle [loading]="loading" [skc-height]="'15px'" [skc-width]="'50px'"></skc-rectangle><br>
                            </td>
                        </tr>
                    </ng-container>
                    
                    <tbody *ngIf="contactPersonList.length > 0;else elseNoDataContactList">
                        <tr *ngFor="let contactPerson of contactPersonList">
                            <td>
                                {{ contactPerson.first_name }} {{  contactPerson.last_name }}
                            </td>
                            <td>
                                {{ contactPerson.contact_number }}
                            </td>
                            <td *ngIf="!showHideContactList" class="action-buttons">
                                <!-- (change)="unhideAddressFromList(address.address_id)" -->
                                <!-- <a class="btn btn-primary btn--add-cart" role="button" >Update</a> -->
                                <!-- <mat-slide-toggle [checked]="true" ></mat-slide-toggle> -->
                                <img *ngIf="(addressData && addressData.contact_row_id == contactPerson.row_id) || (selectedContactRowId == contactPerson.row_id) " src="./assets/images/tick-mark.png" class="img-fluid me-1" />
                                <img *ngIf="(!addressData || addressData.contact_row_id != contactPerson.row_id) && (selectedContactRowId != contactPerson.row_id) " src="./assets/images/cross-icon.png" (click)="askUpdateContactPerson(contactPerson)" class="img-fluid me-1" />
                                <!-- <i class="fa-solid fa-pen-to-square" ></i>
                                <i class="material-icons" ></i> -->
                                <!-- *ngIf="isfileSelected" -->
                                <!-- (click)="clearImage()" -->
                            </td>
                            <td class="action-buttons">
                                <i *ngIf="!showHideContactList" (click)="askRemoveContactPerson(contactPerson.row_id)" class="far fa-trash-alt"></i>
                                <img *ngIf="showHideContactList" src="./assets/images/hide-eye.png" (click)="unhideContactPerson(contactPerson.row_id)" class="img-fluid me-1" />
                            </td>
                        </tr>
                    </tbody>
                    <ng-template #elseNoDataContactList>
                        <td>No Data Found</td>
                    </ng-template>
                </table>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="showLoader">
  <div class="loader">
    <div class="ng-spinner ng-spinner-icon"></div>
  </div>
</ng-container>

<ng-template #confirmationContactPerson let-d="dismiss">
    <app-logout [isContactPersonUpdate]="isContactPersonUpdate" (contactPersonUpdate)="updateContactPerson($event);" (modalClose)="confirmationContactPersonModal.hide()"></app-logout>
</ng-template>

<ng-template #confirmationContactPersonRemove let-d="dismiss">
    <app-logout [isContactPersonRemove]="isContactPersonRemove" (contactPersonRemove)="hidecontactPersonDetails($event);" (modalClose)="confirmationContactPersonRemoveModal.hide()"></app-logout>
</ng-template>

<ng-template #addContactPerson let-d="dismiss">
    <app-add-contact-person (closeModal)="closeContactPersonModal()"></app-add-contact-person>
</ng-template>