import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { AppFormErrorService } from '../../../../../../src/app/shared/services/form-error.service';
import { RegexService } from '../../../../../../src/app/shared/services/regex-service';
import { AddressService } from '../../../../../../src/app/shared/services/REST/address.service';
import { ProductService } from '../../../../../../src/app/shared/services/REST/product.service';
import { UserService } from '../../../../../../src/app/shared/services/REST/user.service';
import { blockPermissionMsg } from '../../../../../../src/app/constant';
import { secureStorage } from '../../../../../../src/app/shared/services/securestorage';
import { AppStateService } from '../../../../shared/services/app-state/app-state.service';
import { takeUntil } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddressComponent implements OnInit {
  blockPermissionMsg = blockPermissionMsg;
  @ViewChild('addAddressModal', { read: TemplateRef, static: false }) template1:TemplateRef<any>;
  addAddressModal: any;
  addresses: any[] = [];
  isAddressModal: boolean = false;
  showLoader: boolean = false;
  showAddressDetail: boolean = false;
  showPersonalDetail: boolean = true;
  addressForm: FormGroup;
  stateList = [];
  cityList = [];
  districtList = [];
  pincodeList = [];
  talukaList = [];
  isFirstFormError: boolean = false;
  btndisabled: boolean = false;
  Error: any;
  submitted: boolean = false;
  isgstcontacterror: boolean = false;
  searchByPincode: any;
  validPincode: boolean = false;
  geocityList = [];
  selectedCity: any;
  public cityInput$ = new Subject<string | null>();
  private ngUnSubscribe: Subject<void> = new Subject();
  isManual: boolean = true;
  isPincode: boolean = false;
  isCity: boolean = false;
  currentNameValue: any;
  StateJson = [
    {
      'state_name ▾': 'Andaman and Nicobar Islands',
      alpha_code: 'AN',
      gst_state_code: '35',
    },
    {
      'state_name ▾': 'Andhra Pradesh',
      alpha_code: 'AP',
      gst_state_code: '37',
    },
    {
      'state_name ▾': 'Arunachal Pradesh',
      alpha_code: 'AR',
      gst_state_code: '12',
    },
    {
      'state_name ▾': 'Assam',
      alpha_code: 'AS',
      gst_state_code: '18',
    },
    {
      'state_name ▾': 'Bihar',
      alpha_code: 'BR',
      gst_state_code: '10',
    },
    {
      'state_name ▾': 'Chandigarh',
      alpha_code: 'CH',
      gst_state_code: '04',
    },
    {
      'state_name ▾': 'Chhattisgarh',
      alpha_code: 'CG',
      gst_state_code: '22',
    },
    {
      'state_name ▾': 'Dadra and Nagar Haveli',
      alpha_code: 'DN',
      gst_state_code: '26',
    },
    {
      'state_name ▾': 'Daman and Diu',
      alpha_code: 'DD',
      gst_state_code: '25',
    },
    {
      'state_name ▾': 'Delhi',
      alpha_code: 'DL',
      gst_state_code: '07',
    },
    {
      'state_name ▾': 'Foreign Country',
      alpha_code: 'FC',
      gst_state_code: '96',
    },
    {
      'state_name ▾': 'Goa',
      alpha_code: 'GA',
      gst_state_code: '30',
    },
    {
      'state_name ▾': 'Gujarat',
      alpha_code: 'GJ',
      gst_state_code: '24',
    },
    {
      'state_name ▾': 'Haryana',
      alpha_code: 'HR',
      gst_state_code: '06',
    },
    {
      'state_name ▾': 'Himachal Pradesh',
      alpha_code: 'HP',
      gst_state_code: '02',
    },
    {
      'state_name ▾': 'Jammu and Kashmir',
      alpha_code: 'JK',
      gst_state_code: '01',
    },
    {
      'state_name ▾': 'Jharkhand',
      alpha_code: 'JH',
      gst_state_code: '20',
    },
    {
      'state_name ▾': 'Karnataka',
      alpha_code: 'KA',
      gst_state_code: '29',
    },
    {
      'state_name ▾': 'Kerala',
      alpha_code: 'KL',
      gst_state_code: '32',
    },
    {
      'state_name ▾': 'Ladakh',
      alpha_code: 'LA',
      gst_state_code: '38',
    },
    {
      'state_name ▾': 'Lakshadweep',
      alpha_code: 'LD',
      gst_state_code: '31',
    },
    {
      'state_name ▾': 'Madhya Pradesh',
      alpha_code: 'MP',
      gst_state_code: '23',
    },
    {
      'state_name ▾': 'Maharashtra',
      alpha_code: 'MH',
      gst_state_code: '27',
    },
    {
      'state_name ▾': 'Manipur',
      alpha_code: 'MN',
      gst_state_code: '14',
    },
    {
      'state_name ▾': 'Meghalaya',
      alpha_code: 'ML',
      gst_state_code: '17',
    },
    {
      'state_name ▾': 'Mizoram',
      alpha_code: 'MZ',
      gst_state_code: '15',
    },
    {
      'state_name ▾': 'Nagaland',
      alpha_code: 'NL',
      gst_state_code: '13',
    },
    {
      'state_name ▾': 'Odisha',
      alpha_code: 'OR',
      gst_state_code: '21',
    },
    {
      'state_name ▾': 'Other Territory',
      alpha_code: 'OT',
      gst_state_code: '97',
    },
    {
      'state_name ▾': 'Puducherry',
      alpha_code: 'PY',
      gst_state_code: '34',
    },
    {
      'state_name ▾': 'Punjab',
      alpha_code: 'PB',
      gst_state_code: '03',
    },
    {
      'state_name ▾': 'Rajasthan',
      alpha_code: 'RJ',
      gst_state_code: '08',
    },
    {
      'state_name ▾': 'Sikkim',
      alpha_code: 'SK',
      gst_state_code: '11',
    },
    {
      'state_name ▾': 'Tamil Nadu',
      alpha_code: 'TN',
      gst_state_code: '33',
    },
    {
      'state_name ▾': 'Telangana',
      alpha_code: 'TS',
      gst_state_code: '36',
    },
    {
      'state_name ▾': 'Tripura',
      alpha_code: 'TR',
      gst_state_code: '16',
    },
    {
      'state_name ▾': 'Uttar Pradesh',
      alpha_code: 'UP',
      gst_state_code: '09',
    },
    {
      'state_name ▾': 'Uttarakhand',
      alpha_code: 'UA',
      gst_state_code: '05',
    },
    {
      'state_name ▾': 'West Bengal',
      alpha_code: 'WB',
      gst_state_code: '19',
    },
  ];
//pagination
  currentPage: any = 1;
  offset: any;
  totalrecord: any;
  addresscount;
  userData: any;
  usertype: any;

  isHideAddressList:boolean;
  
  constructor(
    private appStateService: AppStateService,
    private modalService: BsModalService,
    private productService: ProductService,
    private userService: UserService,
    private router: Router,
    private toasterService: ToastrService,
    private addressService: AddressService,
    private formErrorService: AppFormErrorService,
    private regexService: RegexService,
    private formBuilder: FormBuilder,
    private localstorage: secureStorage,
    private title:Title
  ) {
    this.cityInput$.subscribe((newTerm) => {
      this.getGeoCities(newTerm);
    });
  }

  ngOnInit() {
    this.userData = JSON.parse(this.localstorage.getItem('userData'));
    this.usertype = this.userData.user_type;
    const ListInput: GetAddressparameter = {} as GetAddressparameter;
    ListInput.account_pk = JSON.stringify(this.userData.account_pk);
    ListInput.offset = 0;
    ListInput.limit = 28;
    ListInput.state = '';
    ListInput.district = '';
    ListInput.city = '';
    this.appStateService.openNewAddAddress.pipe(takeUntil(this.ngUnSubscribe)).subscribe( addAddress => {
      if(this.router.url.includes("account/address" ) && addAddress!="" ){
        this.openModal();
        this.appStateService.openNewAddAddress.next("");
      }
    })
    this.getAddressNew(ListInput);
    this.getStates();
    this.newForm();
    this.title.setTitle('E-Dukaan | '+'Address');
  }

  refreshAddressList(isUnhideAddress?:boolean){
    const ListInput: GetAddressparameter = {} as GetAddressparameter;
      ListInput.account_pk = JSON.stringify(this.userData.account_pk);
      ListInput.offset = 0;
      ListInput.limit = 28;
      ListInput.state = '';
      ListInput.district = '';
      ListInput.city = '';
      if(isUnhideAddress){
        this.isHideAddressList = false;
      }
      this.getAddressNew(ListInput);
  }

  isAddressEmpty: boolean = false;
  getAddressNew(ListInput) {
    try {
      let usersData = JSON.parse(this.localstorage.getItem('user'));
      if (usersData) {
        this.addresses = [];
        this.showLoader = true;
        this.isAddressEmpty = false;
        this.productService.getAddressList(ListInput).subscribe(
          (response) => {
            if (response.success === true) {
              this.isAddressEmpty = false;
              for (let i = 0; i < response.data.length; i++) {
                let address = {
                  account_id: response.data[i].account_id,
                  address_id: response.data[i].address_id,
                  city: response.data[i].city,
                  district: response.data[i].district,
                  gst_no: response.data[i].gst_no,
                  postal_code: response.data[i].postal_code,
                  pr_bl_addr_id: response.data[i].pr_bl_addr_id,
                  state: response.data[i].state,
                  street_address1: response.data[i].street_address1,
                  street_address2: response.data[i].street_address2,
                  street_address3: response.data[i].street_address3,
                  taluka: response.data[i].taluka,
                  isDefault: response.data[i].default,
                  isBillingAddress: response.data[i].default,
                  isDeliveryAddress: response.data[i].default,
                };
                // if (response.data[i].isDefault) {
                //   this.localstorage.setItem(
                //     'address',
                //     JSON.stringify({
                //       address: response.data[i],
                //       type: 'default',
                //     })
                //   );
                //   this.localstorage.setItem(
                //     'billingAddress',
                //     JSON.stringify({
                //       address: response.data[i],
                //       type: 'default',
                //     })
                //   );
                // }
                this.addresses.push(address);
              }
              if(this.usertype == 'RT'){
                this.addresses.splice(1);
              }
              this.totalrecord = this.usertype == 'RT'?1:response.total_result;
              this.addresscount = response.data.length - 1;
            }
            this.isAddressEmpty = true;
            this.showLoader = false;
          },
          (error) => {
            this.toasterService.error(error.error.data.msg);
            this.showLoader = false;
            this.isAddressEmpty = true;
          }
        );
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'getAddressNew(check)',
        page_name: 'MenuheaderComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  // Draf address

  hideAddressFromList(addressId, elem){
    try{
      let selectedDeliveryAddress = JSON.parse(this.localstorage.getItem("address"));
      let selectedBillingAddress = JSON.parse(this.localstorage.getItem("billingAddress"));
      if(selectedBillingAddress.address.address_id == addressId || selectedDeliveryAddress.address.address_id == addressId){
        this.toasterService.error("The selected address cannot be archived because it is currently set as Billing Or Shipping address. To archive this address, please select a different Billing and Shipping address.");
        elem.checked = false;
        return
      }else{
        const ListInput: GetAddressparameter = {} as GetAddressparameter;
        ListInput.account_pk = JSON.stringify(this.userData.account_pk);
        ListInput.address_id = addressId;
        ListInput.action_type = "save_to_draft";
        // this.getDraftAddressList(ListInput);
        // this.refreshAddressList(); 
        this.showLoader = true;
        this.productService.getAddressDrafList(ListInput).subscribe(
        (response) => {
          if(response.success === true){
            let objIndex = this.addresses.findIndex(obj => obj.address_id == addressId);
            if (objIndex > -1) {
              this.addresses.splice(objIndex, 1);
            }
            this.toasterService.success("Address Archived successfully");
          }
          else{
            elem.checked = false;
            this.toasterService.error(response.data.msg);
          }
          this.showLoader = false;
        },
        (error) => {
          this.toasterService.error(error.error.data.msg);
          this.showLoader = false;
          this.isAddressEmpty = true;
        })
      }
    }catch(error){
      this.userService.InsertExceptionLog({
        method_name: 'hideAddressFromList(check)',
        page_name: 'MenuheaderComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }
  
  unhideAddressFromList(addressId, elm){
  try{
    const ListInput: GetAddressparameter = {} as GetAddressparameter;
    ListInput.account_pk = JSON.stringify(this.userData.account_pk);
    ListInput.address_id = addressId;
    ListInput.action_type = "remove_draft";
    this.showLoader = true;
    this.productService.getAddressDrafList(ListInput).subscribe(
      (response) => {
        if(response.success === true){
          let objIndex = this.addresses.findIndex(obj => obj.address_id == addressId);
            if (objIndex > -1) {
              this.addresses.splice(objIndex, 1);
            }
            this.toasterService.success("Address Unarchived successfully");
          }
          else{
            elm.checked=true
            this.toasterService.error(response.data.msg);
          }
          this.showLoader = false;
      },
      (error) => {
        this.toasterService.error(error.error.data.msg);
        this.showLoader = false;
        this.isAddressEmpty = true;
      }
    )
  }catch(error){
    this.userService.InsertExceptionLog({
      method_name: 'hideAddressFromList(check)',
      page_name: 'MenuheaderComponent',
      portal_type: 'Customer',
      page_url: this.router.url,
      error_message: error.message,
      inner_exception: error,
    });
  }

    // this.getDraftAddressList(ListInput);
    // this.getHideAddressList();
  }

  getHideAddressList(){
    const ListInput: GetAddressparameter = {} as GetAddressparameter;
    ListInput.account_pk = JSON.stringify(this.userData.account_pk);
    ListInput.action_type = "draft_list";
    ListInput.offset = 0;
    ListInput.limit = 28;
    this.getDraftAddressList(ListInput);
    this.isHideAddressList = true;
  }

  getDraftAddressList(ListInput){
    try {
      let usersData = JSON.parse(this.localstorage.getItem('user'));
      if (usersData) {
        this.addresses = [];
        this.showLoader = true;
        this.isAddressEmpty = false;
        this.productService.getAddressDrafList(ListInput).subscribe(
          (response) => {
            if (response.success === true) {
              this.isAddressEmpty = false;
              for (let i = 0; i < response.data.length; i++) {
                let address = {
                  account_id: response.data[i].account_id,
                  address_id: response.data[i].address_id,
                  city: response.data[i].city,
                  district: response.data[i].district,
                  gst_no: response.data[i].gst_no,
                  postal_code: response.data[i].postal_code,
                  pr_bl_addr_id: response.data[i].pr_bl_addr_id,
                  state: response.data[i].state,
                  street_address1: response.data[i].street_address1,
                  street_address2: response.data[i].street_address2,
                  street_address3: response.data[i].street_address3,
                  taluka: response.data[i].taluka,
                  isDefault: response.data[i].default,
                  isBillingAddress: response.data[i].default,
                  isDeliveryAddress: response.data[i].default,
                };
                this.addresses.push(address);
              }
             this.totalrecord = response.total_result;
            this.addresscount = response.data.length - 1;
            }
            this.isAddressEmpty = true;
            this.showLoader = false;
          },
          (error) => {
            this.toasterService.error(error.error.data.msg);
            this.showLoader = false;
            this.isAddressEmpty = true;
          }
        );
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'getDraftAddressList(check)',
        page_name: 'MenuheaderComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  openModal() {
    let permission = this.userService.CheckPermission('btn_add_address');
    if (permission) {
      this.isAddressModal = true;
      this.addAddressModal = this.modalService.show(this.template1, {
        class: 'modal-dialog modal-dialog-centered modal-lg',
        backdrop: 'static',
      });
    } else {
      this.toasterService.error(blockPermissionMsg);
    }
  }

  closeModal(event) {
    if (event == 'Address added successfully.') {
      this.addAddressModal.hide();
      this.isAddressModal = false;
      let userData = JSON.parse(this.localstorage.getItem('userData'));
      const ListInput: GetAddressparameter = {} as GetAddressparameter;
      ListInput.account_pk = JSON.stringify(userData.account_pk);
      ListInput.offset = 0;
      ListInput.limit = 28;
      ListInput.state = '';
      ListInput.district = '';
      ListInput.city = '';
      this.getAddressNew(ListInput);
    } else {
      this.isAddressModal = false;
      this.addAddressModal.hide();
    }
  }

  getStates() {
    try {
      this.districtList = [];
      this.addressService.getStates().subscribe((response) => {
        if (response) {
          this.stateList = response;
        }
      });
    } catch (error) {
      this.userService.InsertExceptionLog({
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'PageEditAddressComponent',
        method_name: 'getStates()',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  async getDistricts(checkdata, addresstype) {
    try {
      if (addresstype === 'city') {
        let data = this.stateList.filter(
          (result) => result.code === this.addressForm.value.state
        );
        const ListGeodistrictInput: GetModalAddressparameter =
          {} as GetModalAddressparameter;
        ListGeodistrictInput.offset = 0;
        ListGeodistrictInput.limit = 300;
        ListGeodistrictInput.dropdown_type = 'district';
        ListGeodistrictInput.multi_state = [data[0]['code']];
        ListGeodistrictInput.multi_city = [this.selectedCity.city_name];
        let districtgeodata;
        let districtresponse = await this.addressService.getGeoData(
          ListGeodistrictInput
        );
        if (districtresponse['success'] === true) {
          districtgeodata = districtresponse['data']['Districts'];
          this.districtList = [];
          for (var j = 0; j < districtgeodata.length; j++) {
            this.districtList.push(districtgeodata[j].district_name);
          }
        }
      } else {
        this.districtList = [];
        this.pincodeList = [];
        this.talukaList = [];
        this.cityList = [];
        let data = this.stateList.filter(
          (result) => result.code === this.addressForm.value.state
        );
        let ll = {};
        ll = {
          code: data[0]['code'],
          state: data[0]['name'],
        };
        let state = {
          state: ll,
        };
        await this.addressService.getDistricts(state).subscribe((response) => {
          this.districtList = response;
          if (checkdata == 'false') {
            this.addressForm.patchValue({
              district: '',
              pincode: '',
              taluka: '',
            });
          }
        });
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'getDistricts()',
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'PageEditAddressComponent',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  getCities(checkdata) {
    try {
      this.pincodeList = [];
      this.talukaList = [];
      this.cityList = [];
      let data = this.stateList.filter(
        (result) => result.code === this.addressForm.value.state
      );
      let ll = {};
      ll = {
        code: data[0]['code'],
        state: data[0]['name'],
      };
      let state = {
        state: ll,
        district: this.addressForm.value.district,
      };
      this.addressService.getCities(state).subscribe((response) => {
        this.cityList = response;
        if (checkdata == 'false') {
          this.addressForm.patchValue({
            city: '',
            pincode: '',
            taluka: '',
          });
        }
      });
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'getCities()',
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'PageEditAddressComponent',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  getTalukas(checkdata) {
    try {
      this.pincodeList = [];
      this.talukaList = [];
      let data = this.stateList.filter(
        (result) => result.code === this.addressForm.value.state
      );
      let ll = {};
      ll = {
        code: data[0]['code'],
        state: data[0]['name'],
      };
      let state = {
        state: ll,
        district: this.addressForm.value.district,
        city: this.addressForm.value.city,
      };
      this.addressService.getTalukas(state).subscribe((response) => {
        this.talukaList = response;
        if (checkdata == 'false') {
          this.addressForm.patchValue({
            taluka: '',
            pincode: '',
          });
        }
      });
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'getTalukas()',
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'PageEditAddressComponent',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  getPincodes(checkdata) {
    try {
      let data = this.stateList.filter(
        (result) => result.code === this.addressForm.value.state
      );
      let ll = {};
      ll = {
        code: data[0]['code'],
        state: data[0]['state'] ? data[0]['state'] : data[0]['name'],
      };
      let state = {
        state: ll,
        district: this.addressForm.value.district,
        city: this.addressForm.value.city,
        taluka: this.addressForm.value.taluka,
      };
      if (this.addressForm.value.taluka) {
        this.addressService.getPincodes(state).subscribe((response) => {
          this.pincodeList = response.pincodes;
        });
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'getPincodes()',
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'PageEditAddressComponent',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  showAddressDetails() {
    if (
      this.addressForm.controls['name'].invalid ||
      this.addressForm.controls['contact_no'].invalid ||
      this.addressForm.controls['gst_no'].invalid
    ) {
      this.isFirstFormError = true;
      return;
    } else {
      this.showAddressDetail = true;
      this.showPersonalDetail = false;
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  pageChange(page: any) {
    window.scrollTo(0, 0);
    this.currentPage = page;
    page = (page - 1) * 28;
    let userData = JSON.parse(this.localstorage.getItem('userData'));
    const ListInput: GetAddressparameter = {} as GetAddressparameter;
    ListInput.account_pk = JSON.stringify(userData.account_pk);
    ListInput.offset = page;
    ListInput.limit = 28;
    if(this.isHideAddressList){
      ListInput.action_type = "draft_list"
      this.getDraftAddressList(ListInput);
    }else {
      ListInput.state = '';
      ListInput.district = '';
      ListInput.city = '';
      this.getAddressNew(ListInput);
    }
  }
  // onSubmit() {
  //   this.Error = '';
  //   if (this.addressForm.value.gst_no) {
  //     var Selectedstate = this.addressForm.value.state;
  //     let GstCode = this.StateJson.filter(
  //       (Data) => Data.alpha_code === Selectedstate
  //     );
  //     var Statecode = this.addressForm.value.gst_no.substring(0, 2);
  //     var Stategstfromjson = GstCode[0].gst_state_code;
  //     if (Statecode !== Stategstfromjson) {
  //       this.Error =
  //         'Please enter Valid GST Code (Valid GST State code is : ' +
  //         Stategstfromjson +
  //         ')';
  //       return false;
  //     }
  //   }
  //   try {
  //     this.submitted = true;
  //     if (this.addressForm.invalid) {
  //       this.formErrorService.displayFormErrors(this.addressForm);
  //       if (
  //         this.addressForm.value.gst_no ||
  //         this.addressForm.value.contact_no
  //       ) {
  //         this.isgstcontacterror = true;
  //       } else {
  //         this.isgstcontacterror = false;
  //       }
  //       return;
  //     }
  //     this.btndisabled = true;
  //     let user = JSON.parse(this.localstorage.getItem('userData'));
  //     if (user) {
  //       let data = {
  //         street_address1: this.addressForm.value.name,
  //         street_address2: this.addressForm.value.address,
  //         city: this.addressForm.value.city.toUpperCase(),
  //         state: this.addressForm.value.state,
  //         taluka: this.addressForm.value.taluka.toUpperCase()
  //           ? this.addressForm.value.taluka.toUpperCase()
  //           : this.addressForm.value.city.toUpperCase(),
  //         postal_code: this.addressForm.value.pincode
  //           ? this.addressForm.value.pincode
  //           : this.searchByPincode,
  //         district: this.addressForm.value.district.toUpperCase(),
  //         country: 'India',
  //         account_pk: JSON.stringify(user.account_pk),
  //         user_type: 'RT',
  //         gst_no: this.addressForm.value.gst_no,
  //         contact_no: this.addressForm.value.contact_no,
  //       };
  //       let permission = this.userService.CheckPermission('btn_add_address');
  //       if (permission) {
  //         var Address =
  //           this.addressForm.value.name +
  //           ', ' +
  //           this.addressForm.value.state +
  //           ', ' +
  //           this.addressForm.value.district +
  //           ', ' +
  //           this.addressForm.value.city +
  //           ', ' +
  //           this.addressForm.value.pincode;
  //         this.showLoader = true;
  //         this.userAddressService.addAddress(data).subscribe(
  //           (response) => {
  //             this.btndisabled = false;
  //             if (response.success === true) {
  //               this.showLoader = false;
  //               this.toasterService.success('Address added successfully.');
  //               this.googleAnalyticsService.addressConfirmation(
  //                 'Address_Confirmation_web',
  //                 user.phone_number,
  //                 Address
  //               );
  //               $('#add_addrsModal').hide();
  //             } else {
  //               this.showLoader = false;
  //               this.toasterService.error(response.data.msg);
  //               this.googleAnalyticsService.addressConfirmation(
  //                 'Address_Confirmation_Fail_web',
  //                 user.phone_number,
  //                 Address
  //               );
  //               $('#add_addrsModal').hide();
  //             }
  //           },
  //           (error) => {
  //             this.showLoader = false;
  //             this.toasterService.error(error.msg);
  //             $('#add_addrsModal').hide();
  //           }
  //         );
  //       } else {
  //         this.toasterService.error(blockPermissionMsg);
  //       }
  //     }
  //   } catch (error) {
  //     this.userService.InsertExceptionLog({
  //       method_name: 'onSubmit()',
  //       portal_type: 'Customer',
  //       page_url: this.router.url,
  //       page_name: 'PageEditAddressComponent',
  //       error_message: error.message,
  //       inner_exception: error,
  //     });
  //   }
  // }

  public hasRequiredError = (controlName: string): boolean =>
    this.formErrorService.hasRequiredError(controlName, this.addressForm);
  public hasPatternError = (controlName: string): boolean =>
    this.formErrorService.hasPatternError(controlName, this.addressForm);

  showPersonalDetails() {
    this.showPersonalDetail = true;
    this.showAddressDetail = false;
  }

  checkPincodeValidate(pincode) {
    if (!/^[0-9]{6}$/.test(pincode)) {
      return (this.validPincode = true);
    }
    this.validPincode = false;
  }

  addressByPinCode() {
    if (this.searchByPincode === '') {
      return this.toasterService.error('Please Enter pincode');
    }
    if (!/^[1-9][0-9]{5}$/.test(this.searchByPincode)) {
      return this.toasterService.error('Please Enter valid 6 digit pincode');
    }
    try {
      this.addressService
        .getAddressByPincode({ pincode: this.searchByPincode })
        .subscribe((response) => {
          if (response) {
            this.addressForm.patchValue({
              state:
                response.state && response.state.code
                  ? response.state.code
                  : '',
              city: response.city ? response.city.toLowerCase() : '',
              district: response.district
                ? response.district.toLowerCase()
                : '',
              pincode: response.pincode ? response.pincode : '',
              taluka: response.taluka ? response.taluka.toLowerCase() : '',
            });
            this.getDistricts('true', '');
            this.getCities('true');
            this.getTalukas('true');
            this.getPincodes('true');
          }
        });
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'addressByPinCode()',
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'PageEditAddressComponent',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  async getGeoCities(checkdata) {
    try {
      this.geocityList = [];
      const ListGeoInput: GetModalAddressparameter =
        {} as GetModalAddressparameter;
      ListGeoInput.offset = 0;
      ListGeoInput.limit = 300;
      ListGeoInput.dropdown_type = 'city';
      ListGeoInput.search_city = checkdata;
      let cityresponse = await this.addressService.getGeoData(ListGeoInput);
      if (cityresponse['success'] === true) {
        this.geocityList = cityresponse['data']['Cities'];
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'getGeoCities(checkdata)',
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'PageEditAddressComponent',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  async addressBycity() {
    if (this.selectedCity.city_name === '') {
      return this.toasterService.error('Please Select City');
    }
    //this.submitted = true;
    try {
      //this.submitted = false;
      //this.isAddressView = true;
      let stategeodata, districtgeodata;
      const ListGeostateInput: GetModalAddressparameter =
        {} as GetModalAddressparameter;
      ListGeostateInput.offset = 0;
      ListGeostateInput.limit = 300;
      ListGeostateInput.dropdown_type = 'state';
      ListGeostateInput.multi_city = [this.selectedCity.city_name];
      let stateresponse = await this.addressService.getGeoData(
        ListGeostateInput
      );
      if (stateresponse['success'] === true) {
        stategeodata = stateresponse['data']['States'];
        this.stateList = [];
        for (var i = 0; i < stategeodata.length; i++) {
          this.stateList.push(this.formData(stategeodata[i]));
        }
      }
      const ListGeodistrictInput: GetModalAddressparameter =
        {} as GetModalAddressparameter;
      ListGeodistrictInput.offset = 0;
      ListGeodistrictInput.limit = 300;
      ListGeodistrictInput.dropdown_type = 'district';
      ListGeodistrictInput.multi_state = [this.stateList[0].code];
      ListGeodistrictInput.multi_city = [this.selectedCity.city_name];
      let districtresponse = await this.addressService.getGeoData(
        ListGeodistrictInput
      );
      if (districtresponse['success'] === true) {
        districtgeodata = districtresponse['data']['Districts'];
        this.districtList = [];
        for (var j = 0; j < districtgeodata.length; j++) {
          this.districtList.push(districtgeodata[j].district_name);
        }
      }
      if (stategeodata.length > 1) {
        this.addressForm.patchValue({
          state:
            this.stateList[0] && this.stateList[0].code
              ? this.stateList[0].code
              : '',
          district: this.districtList[0] ? this.districtList[0] : '',
          city: this.selectedCity.city_name
            ? this.selectedCity.city_name.toLowerCase()
            : '',
        });
        this.toasterService.error(
          'Entered City is appearing in multiple states, please select the desired state.'
        );
        this.getCities('true');
      } else {
        this.addressForm.patchValue({
          state:
            this.stateList[0] && this.stateList[0].code
              ? this.stateList[0].code
              : '',
          district: this.districtList[0] ? this.districtList[0] : '',
          city: this.selectedCity.city_name
            ? this.selectedCity.city_name.toLowerCase()
            : '',
        });
        this.getDistricts('true', 'city');
        this.getCities('true');
        this.getTalukas('true');
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'addressByPinCode()',
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'PageEditAddressComponent',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  formData(data: any): StateData {
    const objstateData: StateData = {} as StateData;
    objstateData.code = data.state_code;
    objstateData.name = data.state_name;
    return objstateData;
  }

  changeSearchType(type) {
    if (type == 'Manual') {
      this.isManual = true;
      this.isPincode = false;
      this.isCity = false;
      this.getStates();
    } else if (type == 'City') {
      this.isManual = false;
      this.isPincode = false;
      this.isCity = true;
      this.searchByPincode = '';
      this.validPincode = false;
      this.selectedCity = null;
    } else if (type == 'Pincode') {
      this.isManual = false;
      this.isPincode = true;
      this.isCity = false;
      this.searchByPincode = '';
      this.validPincode = false;
    }
    if (this.addressForm.controls['name'].value) {
      this.currentNameValue = this.addressForm.controls['name'].value;
    }
    this.newForm();
  }

  newForm() {
    this.addressForm = this.formBuilder.group({
      name: [
        this.currentNameValue ? this.currentNameValue : '',
        [Validators.required, Validators.maxLength(65)],
      ],
      address: ['', [Validators.required, Validators.maxLength(65)]],
      contact_no: ['', [Validators.pattern(this.regexService.Phone)]],
      gst_no: ['', [Validators.pattern(this.regexService.GstNo)]],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]],
      district: ['', [Validators.required]],
      taluka: [''],
      pincode: [''],
    });
    this.currentNameValue = '';
  }

  popupLoad() {
    this.showPersonalDetail = true;
    this.showAddressDetail = false;
    this.isManual = true;
    this.isPincode = false;
    this.isCity = false;
    this.searchByPincode = '';
    this.selectedCity = '';
    this.currentNameValue = '';
    this.newForm();
  }
}

export class GetAddressparameter {
  account_pk: any;
  offset: any;
  limit: any;
  state: string;
  district: string;
  city: string;
  address_id:any;
  action_type:any;

}

export class GetModalAddressparameter {
  dropdown_type: string;
  multi_state: any;
  multi_district: any;
  multi_taluka: any;
  multi_city: any;
  search_city: string;
  offset: any;
  limit: any;
}

export class StateData {
  code: string;
  name: string;
}
