import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FitmentService } from '../../services/REST/fitment.service';
import { Router } from '@angular/router';
import { UserService } from '../../services/REST/user.service';
import { secureStorage } from '../../services/securestorage';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DatePipe, formatDate } from '@angular/common';
import Swal from 'sweetalert2';
import { AppFormErrorService } from '../../services/form-error.service';
import { RegexService } from '../../services/regex-service';
import * as moment from 'moment'
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'fitment-segment',
  templateUrl: './fitment-segment.component.html',
  styleUrls: ['./fitment-segment.component.scss']
})
export class FitmentSegmentComponent implements OnInit {
  @Output() closemodal = new EventEmitter<any>();

  @Input() mechanicData: any;
  @Input() orderInfo: any;
  @Input() otcNo: any;
  @Input() openType: any;
  fitmentForm: FormGroup;

  labelForNo: string = 'Registration';
  today: any;
  userDetails: any;
  fitmentModal: any
  serviceTypedata: any = [];
  showLoader: boolean = false;
  order: any;
  address: any;
  etaDetails: any
  etaDates: any = []
  maxETADate: any
  timeSlot: any = [
    { id: '1', text: '8:00:00 AM - 10:00:00 AM' },
    { id: '2', text: '10:00:00 AM- 12:00:00 PM' },
    { id: '3', text: '12:00:00 PM - 14:00:00 PM' },
    { id: '4', text: '14:00:00 PM - 16:00:00 PM' },
    { id: '5', text: '16:00:00 PM - 18:00:00 PM' },
    { id: '6', text: '18:00:00 PM - 20:00:00 PM' }
  ]

  serviceType: any = [
    {id: '1', text: 'Air Conditioning' },
    {id: '2', text: 'Brakes' },
    {id: '3', text: 'Cab/Body' },
    {id: '4', text: 'Clutch play adjust' },
    {id: '5', text: 'Clutch Overhaul - Major' },
    {id: '6', text: 'Clutch Overhaul - Minor' },
    {id: '7', text: 'Electricals' },
    {id: '8', text: 'Engine performance' },
    {id: '9', text: 'FIP Service' },
    {id: '10', text: 'Wheel Alignment' },
    {id: '11',text: "Diagnosis required"},
    {id: '12', text: "Adjust timing/Valve Clearance"},
    {id: '13', text: "Air cleaner check/replace"},
    {id: '14', text: "Coolant hose check/replace"},
    {id: '15', text: "Diesel filter replace"},
    {id: '16', text: "Accelerator cable repair/replace"},
    {id: '17', text: "Engine mount Replacement"},
    {id: '18', text: "Engine oil change service"},
    {id: '19', text: "Engine Overhaul - Major"},
    {id: '20', text: "Diagnosis required"},
    {id: '21', text: "Gear Box oil change"},
    {id: '22', text: "Gear lever mechanism repair"},
    {id: '23', text: "Gear Box Speed sensor replacement" },
    {id: '24', text: "Gear Box overhaul"},
    {id: '25', text: "Diagnose Abnormal noise"},
    {id: '26', text: "Other repairs/machining charges"},
    {id: '27', text: "Axle repairs"},
    {id: '28', text: "Axle replace"},
    {id: '29', text: "Propeller shaft replace"},
    {id: '30', text: "Propeller shaft repairs"},
    {id: '31', text: "Diagnose Abnormal noise"},
    {id: '32', text: "Steering oil top up/replace"},
    {id: '33', text: "Steering gear box repair"},
    {id: '34', text: "Steering column repairs"},
    {id: '35', text: "Steering hose replace"},
    {id: '36', text: "Steering pump remove/replace"},
    {id: '37', text: "Wheel Alignment" },
    {id: '38', text: "Diagnosis required"},
    {id: '39', text: "Suspension overhaul(Front)"},
    {id: '40',text: "Suspension overhaul(Rear)" },
    {id: '41', text: "Diagnose abnormal noise"}
  ]

  // distanceBtn: any = '0KM - 10KM';
  // radioOptions = [
  //   { label: '0KM - 10KM', value: '0KM - 10KM' },
  //   { label: '10KM - 20KM', value: '10KM - 20KM'},
  //   { label: '20KM - 30KM', value: '20KM - 30KM'},
  //   { label: '30KM - 40KM', value: '0KM - 40KM'},
  //   { label: '40KM - 50KM', value: '40KM - 50KM'}
  // ];

  // serviceType = [];
  mechanicDataList: any = []
  isCheckVehicle: boolean = false;
  vehicleInfo:any;
  latLongData:any;
  etamsg:string;

  selectType: any = [
    { id: 'Registration', text: 'Registration No' },
    { id: 'Chassis', text: 'Chassis No' },
  ]

  constructor(
    private fitmentService: FitmentService,
    private router: Router,
    private userService: UserService,
    private localstorage: secureStorage,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private datePipe: DatePipe,
    private toasterService: ToastrService,
    private formErrorService: AppFormErrorService,
    private regexService: RegexService,
    private modalService: BsModalService,
  ) { }

  ngOnInit(): void {
    this.userDetails = JSON.parse(this.localstorage.getItem('userData'));
    this.today = new Date();
    // this.serviceType = []
    this.newFormBuilder()
    // this.getserviceType()
    if (this.orderInfo) {
      var ship_address = this.orderInfo.order_address.filter(item => item.address_type === "shipping_address")
      this.address = {
        "address_1": ship_address[0]?.street_address1,
        "address_2": ship_address[0]?.street_address2,
        'ship_city': ship_address[0]?.city,
        'ship_country': ship_address[0]?.country,
        'ship_district': ship_address[0]?.district,
        'ship_pincode': ship_address[0]?.postal_code,
        'ship_state': ship_address[0]?.state,
        'ship_taluka': ship_address[0]?.taluka,
        'orderDate': ship_address[0]?.created_at
      }
      this.getEtARealTime(this.orderInfo?.order_number)
      this.getLatLong()
    }
    else {
      this.getOrderInfo()
    }
  }

  newFormBuilder() {
    this.fitmentForm = this.formBuilder.group({
      type: ['Registration'],
      number: ['', [Validators.required,]],
      custName: [this.userDetails?.account_name],
      custMobile: [this.userDetails?.contact_no],
      serviceType: ['', [Validators.required]],
      timeSlot: ['', [Validators.required]],
      deliveryDate: ['', [Validators.required]],
      driverName: ['', [Validators.required, Validators.pattern(this.regexService.HumanName)]],
      driverMobile: ['', [Validators.required, Validators.pattern(this.regexService.Phone)]],
      machanicName: ['',],
      machanicMobile: ['',],
    });

    if (this.mechanicData) {
      this.fitmentForm.get("machanicName").patchValue(this.mechanicData.mechanic_name);
      this.fitmentForm.get("machanicMobile").patchValue(this.mechanicData.user__username);
    }
    else {
      this.getMechanicList(JSON.parse(this.localstorage.getItem("address")))
    }
  }

  closeModal() {
    this.mechanicData = ''
    this.closemodal.emit();
  }

  selectNoType(event) {
    this.labelForNo = event.target.value;
    this.fitmentForm.get("number").patchValue('');
    this.isCheckVehicle = true;
    if(this.labelForNo == 'Registration'){
      // this.fitmentForm.controls['number'].setValidators([Validators.maxLength(10),Validators.pattern(this.regexService.Registrationregx)]);              
    }
    else{
      // this.fitmentForm.controls['number'].setValidators([ Validators.maxLength(10),Validators.pattern(this.regexService.Registrationregx)]);
    }
  }

  custInfoCall() {
    let req
    if (this.labelForNo == 'Registration') {
      req = {
        "registration_number": this.fitmentForm.value.number,
        "user_id": "",
        "account_pk": this.userDetails?.account_pk
      }
      this.getCustomerInfo(req)
    }
    else {
      req = {
        "chassis_no": this.fitmentForm.value.number,
        "user_id": this.userDetails?.account_pk,
        "account_pk": this.userDetails?.account_pk
      }
      this.getCustomerInfo(req)
    }
  }

  getCustomerInfo(req) {
    try {
      this.fitmentService.getCustInfoFromReg(req).subscribe(
        res => {
          if (res.is_valid) {
            this.isCheckVehicle = false;
            this.vehicleInfo = res.results[0]
            if(this.vehicleInfo.customer_name != this.userDetails?.account_name){
              Swal.fire({
                icon: "info",
                text: "As per our records, the entered registration no./chassis no. is not updated against your name. Do you want to continue raising the request?",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
                cancelButtonText : "No",
                backdrop: false
              }).then((result) => {
                if (result.isConfirmed) {
                } else  {
                  this.mechanicData = ''
                  this.closemodal.emit();
                }
              });
            }
          }
          else {
            this.isCheckVehicle = true
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: 'As per our records, the entered registration no./chassis no. is not avilable in source',
            });
          }
        }
      );
    }
    catch (error) {
      this.userService.InsertExceptionLog({ "method_name": "getCustInfoFromReg(req)", "page_name": "FitmentSegmentComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
    }
  }

  getserviceType() {
    var req = {
      "account_pk": this.userDetails?.account_pk
    }
    try {
      this.serviceType = []
      this.fitmentService.getServicetype(req).subscribe(data => {
        this.serviceTypedata = data.service_types
        for (let i = 0; i < this.serviceTypedata.length; i++) {
          var json = {
            'id': this.serviceTypedata[i].id,
            'text': this.serviceTypedata[i].service_types
          }
          this.serviceType.push(json)
        }
      });
    }
    catch (error) {

    }
  }

  getOrderInfo() {
    this.showLoader = true;
    var requestBody = {
      "account_pk": this.userDetails?.account_pk,
      "otc_number": this.otcNo
    }
    this.userService.OrderDetailsForOTC(requestBody).subscribe(response => {
      if (response['success']) {
        this.order = response['data'][0];
        this.address = {
          "address_1": this.order?.shipping_address?.ship_address_1,
          "address_2": this.order?.shipping_address?.ship_address_2,
          'ship_city': this.order?.shipping_address?.ship_city,
          'ship_country': this.order?.shipping_address?.ship_country,
          'ship_district': this.order?.shipping_address?.ship_district,
          'ship_pincode': this.order?.shipping_address?.ship_pincode,
          'ship_state': this.order?.shipping_address?.ship_state,
          'ship_taluka': this.order?.shipping_address?.ship_taluka,
          'orderDate': this.order?.order_date,
        }
        this.getETADate(this.order?.order_number)
        this.getLatLong()
      }
      else {
        this.toastrService.error(response['data']['msg']);
      }
      this.showLoader = false;
    },
      error => {
        this.toastrService.error(error.error.data.msg);
        this.showLoader = false;
      });
  }

  getETADate(order) {
    this.etaDetails = [];
    var requestBody = {
      "account_pk": this.userDetails?.account_pk,
      "order_number": order,
      "offset": 0,
      "limit": 20
    }
    this.showLoader = true
    this.userService.getEtaForOrderDetails(requestBody).subscribe(response => {
      this.showLoader = false
      if (response.success) {
        let etaDetails = response.data;
        if (etaDetails.length > 1) {
          for (let i = 0; i < etaDetails.length; i++) {
            var deliveryDate = moment(etaDetails[i].delivery_date)
            deliveryDate = deliveryDate.subtract(1, 'days');
            this.etaDates.push(deliveryDate)
            var json = {
              "part_number": etaDetails[i].part_number,
              "part_desc":etaDetails[i].part_desc,
              "delivery_date":moment(deliveryDate).format('L')
            }
            this.etaDetails.push(json)
          }
          const dateObjects = this.etaDates.map(dateStr => new Date(dateStr));
          const maxDateObject = new Date(Math.max.apply(null, dateObjects));
          // this.maxETADate = maxDateObject.toISOString().split('T')[0];
          this.maxETADate = maxDateObject
          this.calculateETADate(this.maxETADate)
        }
        else {
          for (let i = 0; i < etaDetails.length; i++) {
            var deliveryDate = moment(etaDetails[i].delivery_date)
            deliveryDate = deliveryDate.subtract(1, 'days');
            var json = {
              "part_number": etaDetails[i].part_number,
              "part_desc":etaDetails[i].part_desc,
              "delivery_date":moment(deliveryDate).format('L')
            }
            this.etaDetails.push(json)
          }
          this.maxETADate = this.etaDetails[0].delivery_date;
          this.calculateETADate(this.maxETADate)
        }
      }
      else {
        this.showLoader = false
        this.etaDetails = [];
        this.etamsg = 'The ETA details are being calculated. Please try creating request after sometime.'
      }
    },
      error => {
        this.showLoader = false
        this.etaDetails = [];
        this.etamsg = 'The ETA details are being calculated. Please try creating request after sometime.'

      });
  }

  getEtARealTime(order) {
    var json = {
      "account_pk": this.userDetails?.account_pk,
      "order_number": order,
      "limit": 20,
      "is_real_time_order_eta": true,
      "offset": 0
    }
    this.showLoader = true
    this.userService.GetUserEtaDetails(json).subscribe(response => {
      this.showLoader = false
      if (response.success) {
        this.etaDetails = response.data;
        if (this.etaDetails.length > 1) {
          for (let i = 0; i < this.etaDetails.length; i++) {
            this.etaDates.push(this.dateConvert(this.etaDetails[i].ETA))
          }
          const dateObjects = this.etaDates.map(dateStr => new Date(dateStr));
          const maxDateObject = new Date(Math.max.apply(null, dateObjects));
          this.maxETADate = formatDate(maxDateObject, 'MM-dd-yyyy', 'en-US');
          this.calculateETADate(this.maxETADate)
        }
        else {
          this.maxETADate = this.dateConvert(this.etaDetails[0].ETA)
          this.calculateETADate(this.maxETADate)
        }
      }
      else {
        this.showLoader = false
        this.etaDetails = [];
        this.etamsg = 'The ETA details are being calculated. Please try creating request after sometime. Alternatively, you can also create a service request from Order > Order History > Click on “Raise Service Request”'
      }
    },
      error => {
        this.etaDetails = [];
        this.etamsg = 'The ETA details are being calculated. Please try creating request after sometime. Alternatively, you can also create a service request from Order > Order History > Click on “Raise Service Request”'
        this.showLoader = false
      });

  }

  dateConvert(dateString:any){
    // dateString = '14-03-2023'
   let parts = dateString.split('-');
  
   let day = parseInt(parts[0], 10);
   let month = parseInt(parts[1], 10);
   let year = parseInt(parts[2], 10);
   let isoDateString = year + '-' + month + '-' + day;
    return formatDate(isoDateString, 'MM-dd-yyyy', 'en-US');
  }

  calculateETADate(data) {
    let todayDate = new Date()
    // let orderDate = new Date(this.address.orderDate);
    // let maxetaDate = new Date(this.maxETADate);
    // let days = Math.floor((Date.UTC(maxetaDate.getFullYear(), maxetaDate.getMonth(), maxetaDate.getDate()) - Date.UTC(orderDate.getFullYear(), orderDate.getMonth(), orderDate.getDate())) / (1000 * 60 * 60 * 24));
    // if(maxetaDate.getDate() < todayDate.getDate()){
    //   this.maxETADate = new Date()
    // }
    // else{
    //   if (days > 3) {
    //     let maxDate = new Date(this.maxETADate)
    //     maxDate.setDate(maxDate.getDate() - 2);
    //     this.maxETADate = this.datePipe.transform(maxDate, 'yyyy-MM-dd');
    //   }
    //   else {
    //     this.maxETADate = this.maxETADate
    //   }
    // }


    const momentDate1 = moment(todayDate);
    const momentDate2 = moment(this.maxETADate);
    const orderDate = moment(this.address.orderDate);
    // const maxetaDate = momentDate2.subtract(1, 'days');
    let days = momentDate2.diff(momentDate1, 'days');
    if (momentDate1.isBefore(momentDate2)) {
      if (days > 3) {
        this.maxETADate = momentDate2.subtract(2, 'days').toDate();
      }
      else if(days == 0){
        this.maxETADate = new Date()
      }
      else{
        this.maxETADate = momentDate2.subtract(days, 'days').toDate();
      }
    }
    else{
      this.maxETADate = new Date()
    }
  }

  getLatLong(){
    var address =(this.address?.address_1 ? ( this.address?.address_1 + ', '):'') + (this.address?.address_2 ? (this.address?.address_2 + ', ') : '')
    + (this.address?.ship_taluka ? (this.address?.ship_taluka+','):'') 
    + (this.address?.ship_city ? (this.address?.ship_city+','):'' )
   + (this.address?.ship_district ? (this.address?.ship_district+','):'') 
    + (this.address?.ship_pincode ? ( ' - '+this.address?.ship_pincode+', '):'' )
    + (this.address?.ship_state ? (this.address?.ship_state+'.'):', ' )
    + (this.address?.ship_country ? ( this.address?.ship_country + ''): '' )
    let req = { "account_pk": this.userDetails.account_pk,"address":address}
    try {
    this.fitmentService.getLatLongByAddress(req).subscribe(
      resp => {
        if(resp.success){
          this.latLongData = resp.data
        }
        else{

        }
    })
  }
  catch (error) {
    this.showLoader = false;
    this.userService.InsertExceptionLog({ "method_name": "getLatLong()", "page_name": "FitmentSegmentComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
  }
  }
  getMechanicList(data) {
    this.mechanicDataList = []
    try {
      let req = { "account_pk": this.userDetails.account_pk, "address": data.address.postal_code }
      this.showLoader = true
      this.fitmentService.getGuruList(req).subscribe(
        res => {
          this.showLoader = false
          if (res) {
            this.mechanicDataList = res.result;
          } else {
            this.toasterService.error("Data Not Found");
          }
          this.showLoader = false;
        }
      )
    }
    catch (error) {
      this.showLoader = false;
      this.userService.InsertExceptionLog({ "method_name": "getMechanicList()", "page_name": "FitmentSegmentComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
    }
  }

  selectMechanic(data) {
    this.mechanicData = this.mechanicDataList.filter(item => item.id === parseInt(data.target.value));
    this.mechanicData = this.mechanicData[0]
    this.fitmentForm.get("machanicMobile").patchValue(this.mechanicData?.user__username);
  }

  onSubmit() {
    if(this.maxETADate == undefined || this.maxETADate == null ||this.maxETADate == '' || !this.maxETADate){
      this.toasterService.error("ETA Not available at a time try after sometime");
      return
    } 

    if(this.latLongData == '' || this.latLongData == undefined || this.latLongData == null){
      this.toasterService.error("Address Not Proper");
      return
    } 

    if (this.fitmentForm.invalid) {
      this.formErrorService.displayFormErrors(this.fitmentForm);
      return;
    }

    var json = {
      "account_pk": this.userDetails?.account_pk,
      "mobile_no": this.userDetails?.contact_no,
      "company_name": this.userDetails?.account_name,
      "customer_name": this.userDetails?.account_name,
      "vehicle_number": this.labelForNo == 'Registration' ? this.fitmentForm.value.number : '',
      "chassis_no": this.labelForNo == 'Chassis' ? this.fitmentForm.value.number : '',
      "guru_id": this.mechanicData ? this.mechanicData.id : '',
      "date": this.datePipe.transform(this.fitmentForm.value.deliveryDate, 'yyyy-MM-dd'),
      "start_time": "",
      "end_time": "",
      "timeslot": this.fitmentForm.value.timeSlot,
      "description": "",
      "latitude": this.latLongData.lat,
      "longitude": this.latLongData.lng,
      "service_type": "",
      "service": this.fitmentForm.value.serviceType,
      "last_served_guru": "",
      "other_guru": "True",
      "case_type": "Edukan",
      "account_id": this.userDetails?.account_id,
      "existing_case_id": "",
      "reschedule": "",
      "driver_name": this.fitmentForm.value.driverName,
      "driver_mobile_no": this.fitmentForm.value.driverMobile,
    }
    this.fitmentService.sendBooking(json).subscribe(resp => {
      if (resp.success) {
        Swal.fire({
          icon: "success",
          text: resp.msg,
          showCancelButton: true,
          cancelButtonColor: "#d33",
          confirmButtonColor: "#3085d6",
          cancelButtonText : "OK",
          confirmButtonText: "Go-To Service Request",
          backdrop: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigate(['/account/servicerequestreport']);
            this.closeModal()
            this.modalService.hide()
          } else  {
            // this.router.navigate(['/account/servicerequestreport']);
            this.closeModal()
            this.modalService.hide()
          }
        });
      }
      else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: resp.msg,
          showCancelButton: true,
          cancelButtonColor: "#d33",
          confirmButtonColor: "#3085d6",
          cancelButtonText : "OK",
          confirmButtonText: "Go-To Service Request",
          backdrop: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigate(['/account/servicerequestreport']);
            this.closeModal()
            this.modalService.hide()
          } else  {
            // this.router.navigate(['/account/servicerequestreport']);
            this.closeModal()
            this.modalService.hide()
          }
        });
      }
    })
  }

  public hasRequiredError = (controlName: string): boolean =>
    this.formErrorService.hasRequiredError(
      controlName,
      this.fitmentForm
    );
  public hasPatternError = (controlName: string): boolean =>
    this.formErrorService.hasPatternError(
      controlName,
      this.fitmentForm
    );

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onlyChars(event) : boolean{
    const charCode = event.which ? event.which : event.keyCode;
    // if (charCode.key === 'Tab' || charCode.key === 'TAB') {
    //   return;
    // }
    if ((charCode > 64 && charCode < 91) || charCode === 8 || charCode === 32 || (charCode > 96 && charCode < 124)) {
      return true;
    }
    return false;
  }
}
