import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { secureStorage } from "../../services/securestorage";

@Component({
    selector: 'all-models',
    templateUrl: './all-models.component.html',
    styleUrls: ['./all-models.component.scss']
})

export class AllModelsComponent implements OnInit {
    @Input() allModels: any[];
    @Output() closeModalPopup = new EventEmitter<any>();
    activeModelCategory: string = "";
    prodcardbreadcrumb: any[] = [];
    constructor(private localstorage: secureStorage, private router: Router) { }
    ngOnInit() {
        // console.log(this.allModels);
    }

    closeModal() {
        this.closeModalPopup.emit();
    }

    viewPartsForSelectedModel(model) {
        this.activeModelCategory = model.LINE_2_s;
        this.prodcardbreadcrumb = [];
        this.prodcardbreadcrumb.push({
            label: model.LINE_2_s,
            url: '/shop/productcards',
            params: { ModelName: model?.LINE_2_s, type: 'Model' },
        });
        localStorage.removeItem('productcardBreadcrumb');
        this.localstorage.setItem('productcardBreadcrumb', JSON.stringify(this.prodcardbreadcrumb));
        this.closeModalPopup.emit();
        this.router.navigate(['/shop/productcards'], { queryParams: { ModelName: model?.LINE_2_s, type: 'Model' } });
    }
}