import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxScannerQrcodeService, ScannerQRCodeConfig, ScannerQRCodeSelectedFiles } from 'ngx-scanner-qrcode';
import { ProductService } from '../../services/REST/product.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { secureStorage } from '../../services/securestorage';

@Component({
  selector: 'app-qr-scanner',
  templateUrl: './qr-scanner.component.html',
  styleUrls: ['./qr-scanner.component.scss']
})
export class QrScannerComponent implements OnInit,OnDestroy  {
  scannedResult:any;
  scanQrSelected:boolean = true;
  uploadQrSelected:boolean = false;
  @Input() closeModel:any = [];
  uploadedImageUrl: string;
  @ViewChild('uploadedImage', { static: false }) uploadedImage: ElementRef<HTMLImageElement>;
  @ViewChild('canvas', { static: false }) canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('file', { static: false }) fileInput: ElementRef<HTMLInputElement>
  public qrCodeResult: ScannerQRCodeSelectedFiles[] = [];
  public config: ScannerQRCodeConfig = {
    constraints: { 
      video: {
        width: window.innerWidth
      }
    } 
  };
  canvasstyle:any = [{ lineWidth: 1, strokeStyle: 'green', fillStyle: '#55f02880' }];
  // Errorcanvasstyle = [{ lineWidth: 1, strokeStyle: 'rgba(255, 0, 0, 0.7)', fillStyle: 'rgba(255, 0, 0, 0.7)',opacity:0.4 },{ font: '15px serif', strokeStyle: '#fff0', fillStyle: '#ff0000' }];
  storeAction:any;
  storeActionFile:any;
  partSerachSubscribtion:Subscription;
  showLoader: boolean = false;

  constructor(private router: Router,private qrcode: NgxScannerQrcodeService,
    private productService: ProductService,
    private toastrService: ToastrService,
    private localstorage: secureStorage,
  ) {
   
   }

  ngOnInit(): void {
  }
  onSelects(files: any) {
    // console.log("get_filelelel",files,files[0]?.name);
    var Extension = files[0].name
    .substring(files[0].name.lastIndexOf('.') + 1)
    .toLowerCase();
  if (Extension == 'jpg' || Extension == 'png' || Extension == 'gif' || Extension == 'jpeg') {
    this.qrcode.loadFiles(files).subscribe((res: ScannerQRCodeSelectedFiles[]) => {
      this.qrCodeResult = res;
    });
  }else{
    this.toastrService.error("Please Upload Only .jpg, .png, .gif, .jpeg");
  }
    
  }
  handleQrCodeResult(resultString: string) {
    this.scannedResult;
    this.scannedResult = resultString.split('#');
    if (this.scannedResult && this.scannedResult[7]) {
      this.router.navigate(['/shop/product-details'], {
        queryParams: { part_Number: this.scannedResult[7]},
      }).then((res) => {
       this.closeModel.hide();
      })
    }else{

    }
  }
  getCameraQrEvent(event,action){
    let address = JSON.parse(this.localstorage.getItem('address'));
    let user = JSON.parse(this.localstorage.getItem('userData'));
    this.storeAction = action;
    if(event){
      event.forEach(element => {
        this.scannedResult = element.value.split('#');
        if (this.scannedResult && this.scannedResult[7]) {
          let req = {
              "account_pk": (user && user.account_pk) ? user.account_pk : '',
              "parts_list": [this.scannedResult[7]],
              "is_for_basic_info":true,
              "shipping_address_id":(address && address.address) ? (address.address['default'] == true) ? "default" : address.address['address_id']:'',
          }
          this.partSerachSubscribtion = this.productService.get_part_search_md(req).subscribe((res) => {
            if(res && res.success){
              this.router.navigate(['/shop/product-details'], {
                queryParams: { part_Number: this.scannedResult[7]},
              }).then((res) => {
                action['stop']().subscribe((r: any) => console.log('stop', r));
               this.closeModel.hide();
              })
            }else{
              this.toastrService.error("Part Number Not Found");
              if(action){
                action['stop']().subscribe((r: any) => console.log('stop', r));
                this.closeModel.hide();
              }else{}
              // this.canvasstyle = [{ lineWidth: 1, strokeStyle: 'rgba(255, 0, 0, 0.7)', fillStyle: 'rgba(255, 0, 0, 0.7)',opacity:0.4 },{ font: '15px serif', strokeStyle: '#fff0', fillStyle: '#ff0000' }]
            }
          })
        }else{
          this.toastrService.error("Invalid QR");
          if(action){
            action['stop']().subscribe((r: any) => console.log('stop', r));
          this.closeModel.hide();
      
          }else{}
          // this.canvasstyle = [{ lineWidth: 1, strokeStyle: 'rgba(255, 0, 0, 0.7)', fillStyle: 'rgba(255, 0, 0, 0.7)',opacity:0.4 },{ font: '15px serif', strokeStyle: '#fff0', fillStyle: '#ff0000' }]
        }
      });
    }else{}
   
  }
  public handle(action: any, fn: string): void {
    this.storeAction = action;
    this.uploadQrSelected =  false
    const playDeviceFacingBack = (devices: any[]) => {
      // front camera or back camera check here!
      const device = devices.find(f => (/back|rear|environment/gi.test(f.label))); // Default Back Facing Camera
      action.playDevice(device ? device.deviceId : devices[0].deviceId);
    }

    if (fn === 'start') {
      action[fn](playDeviceFacingBack).subscribe((r: any) => {
        if(r){
          console.log(fn, r)
        }else{

        }
      });
      this.qrCodeResult = [];
      // console.log("chejk_qr_result_after_emoty",this.qrCodeResult)
    } else {
      action[fn]().subscribe((r: any) => console.log(fn, r));
    }
  }

  buttonAction(value){
    if(value == 'scanqr'){
      this.scanQrSelected = true;
      this.uploadQrSelected = false;
    }else{
  
      
      this.scanQrSelected = false;
      this.showLoader = true;
      setTimeout(() => {
      this.uploadQrSelected = true;
      if(this.storeAction){
        this.storeAction['stop']().subscribe((r: any) => console.log('stop', r));
  
      }else{}
      this.showLoader = false;
      }, 2000);
    }
  }
  gtetfileseletctecte(event,actionfile){
    this.storeActionFile = actionfile;
    let address = JSON.parse(this.localstorage.getItem('address'));
    let user = JSON.parse(this.localstorage.getItem('userData'));
    event.forEach(element => {
      this.scannedResult = element.value.split('#');
      if (this.scannedResult && this.scannedResult[7]) {
        let req = {
          "account_pk": (user && user?.account_pk) ? user?.account_pk : '',
          "parts_list": [this.scannedResult[7]],
          "is_for_basic_info":true,
          "shipping_address_id":(address && address.address) ? (address.address['default'] == true) ? "default" : address.address['address_id']:'',
      }
      this.partSerachSubscribtion = this.productService.get_part_search_md(req).subscribe((res) => {
        if(res && res.success){
          this.router.navigate(['/shop/product-details'], {
            queryParams: { part_Number: this.scannedResult[7]},
          }).then((res) => {
            if(this.storeAction){
              this.storeAction['stop']().subscribe((r: any) => console.log('stop', r));
        
            }else{}
           this.closeModel.hide();
          })
        }else{
          this.toastrService.error("Part Number Not Found");
        }
      })
      }else{
        this.toastrService.error("Invalid QR");
      }
    });
  }
  closeModelFunction(){
    if(this.storeAction){
      this.storeAction['stop']().subscribe((r: any) => {
        if(r){
          console.log('stop', r)
        }else{}
      });

    }else{}
    this.closeModel.hide();
  
  }
  ngOnDestroy() {
    if(this.storeAction){
      this.storeAction['stop']().subscribe((r: any) => {
        if(r){
          console.log('stop', r)
        }else{}
      });

    }else{}
    if(this.partSerachSubscribtion){
      this.partSerachSubscribtion.unsubscribe();
    }
  }
}
