import { DatePipe, formatDate } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { FitmentService } from 'src/app/shared/services/REST/fitment.service';
import { UserService } from 'src/app/shared/services/REST/user.service';
import { secureStorage } from 'src/app/shared/services/securestorage';

@Component({
  selector: 'app-fitment-segment-list',
  templateUrl: './fitment-segment-list.component.html',
  styleUrls: ['./fitment-segment-list.component.scss']
})
export class FitmentSegmentListComponent implements OnInit {

  fitmentData:any=[];

  @ViewChild('actionDetails', { read: TemplateRef, static: false })
  actionDetails: TemplateRef<any>;

  isotcnoFilter: boolean = false;
  public dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  newRequestBody: any;
  datatsetempty = false;
  dtOptions: DataTables.Settings = {};

  filterForm: FormGroup;
  today: any;
  toDate: any;
  fromDate: any;
  toDate1: any;
  fromDate1: any;
  totalLineItem: any;
  showLoader: boolean = false;
  totalrecord: any;
  orders = [];
  Items: [];
  totalQuantity: number;
  orderItemsQuantity: number[];
  isEComFilter: boolean = false;
  isFormValid: boolean = true;
  
  showActiveOrderList;
  isToggleOpened: boolean = false;
  userDetails: any;
  activeorderPermission: any[];

  actionModel: any;
  modalRef: any;
  previousStatus:any

  CurrentStatus: any = [
    { id: 'Total', text: 'All' },
    { id: 'Accepted', text: 'Accepted' },
    { id: 'Confirmed', text: 'Confirmed' },
    { id: 'Completed', text: 'Completed' },
    { id: 'Ongoing', text: 'Ongoing' },
    { id: 'Pending', text: 'Pending' },
    { id: 'Unattended', text: 'Unattended' },
  ]
  
  constructor(
    private dp:DatePipe,
    private router: Router,
    private userService: UserService,
    private fb: FormBuilder,
    private title:Title,
    private localstorage: secureStorage,
    private fitmentservice:FitmentService,
    private toastrService: ToastrService,
    private modalService: BsModalService,
  ) {}
  ngOnInit() {
    let ordersMenu:any[]=[];
    this.userDetails = JSON.parse(this.localstorage.getItem('userData'));
    // if(this.localstorage.getItem('orderHistoryMenuList')){
    //   ordersMenu = JSON.parse(this.localstorage.getItem('orderHistoryMenuList'));
    // }
    // this.activeorderPermission = ordersMenu.filter(
    //   (obj) =>  obj.page_display_name === 'E-Com Orders'
    // );
    // if(this.activeorderPermission.length == 1){
    //   this.showActiveOrderList = true;
    // }
    // else{
    //   this.showActiveOrderList = false;
    // }
    
    this.today = new Date();
    this.buildForm();
    this.getBookingData(0, 'booking', false);
    this.title.setTitle('E-Dukaan | Fitment Segment');
  }

  getBookingData(page, type, isFilter?) {
    try {
      document.body.scrollTop = 0;

      if (this.filterForm.value.start) {
        this.fromDate = new Date(this.filterForm.value.start);
        this.fromDate1 = formatDate(this.fromDate, 'yyyy-MM-dd', 'en-US');
      }
      if (this.filterForm.value.end) {
        this.toDate = new Date(this.filterForm.value.end);
        this.toDate1 = formatDate(this.toDate, 'yyyy-MM-dd', 'en-US');
      }
      let user = JSON.parse(this.localstorage.getItem('userData'));
      if (user) {
        if (user && user.account_pk) {
          this.calldatatable(type);
        }
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'getBookingData(page, type)',
        page_name: 'FitmentSegmentListComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }


  buildForm() {
    this.filterForm = this.fb.group({
      start: ['', [Validators.required]],
      end: ['', [Validators.required]],
      status: ['Total', [Validators.required]],
    });
  }
  
  currentValueinHistory
  applyFilter(page = 0,otcnoFilter?) {
    if (
      this.filterForm.value.start &&
      this.filterForm.value.end &&
      !(this.filterForm.value.end >= this.filterForm.value.start)
    ) {
      return this.toastrService.error(
        'To date should be greater than or equal to From date'
      );
    } else if (this.filterForm.value.start && !this.filterForm.value.end) {
      this.isFormValid = false;
      return this.toastrService.error('To date is required');
    } else if (!this.filterForm.value.start && this.filterForm.value.end) {
      this.isFormValid = false;
      return this.toastrService.error('From date is required');
    } else {
      this.isFormValid = true;
      if (
        (this.filterForm.controls['status'].value !== '' &&
          this.filterForm.controls['status'].value !== undefined &&
          this.filterForm.controls['status'].value !== null) ||
        (this.filterForm.controls['status'].value == '' &&
          (this.filterForm.controls['start'].value !== '' ||
            this.filterForm.controls['end'].value !== ''))
      ) {
        this.isEComFilter = false;
        this.getBookingData(0, 'booking', true);
        this.rerender();
      } else if (
        this.currentValueinHistory !== this.filterForm.controls['status'].value
      ) {
        this.isEComFilter = false;
        this.getBookingData(0, 'booking', true);
        this.rerender();
        if(this.isToggleOpened){
          $('.filter-container').toggle();
          this.isToggleOpened = false;
        }
      }
    }
  }

  filter() {
    //this.isEComFilter = !this.isEComFilter;
    this.isToggleOpened = true;
    $('.filter-container').toggle();
  }

  reset(event) {
    this.isFormValid = true;
    this.filterForm.reset();
    this.fromDate = '';
    this.fromDate1 = '';
    this.toDate = '';
    this.toDate1 = '';
    this.isEComFilter = false;
    this.filterForm.controls['status'].setValue('Total');
    this.getBookingData(0, 'booking', true);
    this.rerender();
  }
  
  calldatatable(type) {
    const that = this;
    let user = JSON.parse(this.localstorage.getItem('userData'));
    if (user && user.account_pk) {
      this.newRequestBody = {
        "from_date":this.fromDate1 ? this.fromDate1 : ' ',
        "to_date":this.toDate1 ? this.toDate1 : '',
        "account_id": this.userDetails?.account_id,
        "account_pk": this.userDetails?.account_pk,
        "case_type": "Edukan",
        "customer_name": "",
        "limit": 10,
        "offset": 0,
        "search": "",
        "status_type": this.filterForm.value.status
    };
      this.dtOptions = {
        pagingType: 'full_numbers',
        responsive: true,
        serverSide: true,
        processing: true,
        lengthChange: false,
        searching: false,
        pageLength: 10,
        ordering: false,
        scrollX: true,

        language: {
          info: 'Showing _START_ to _END_ of _TOTAL_ entries',
          infoEmpty: 'No records available',
          // infoFiltered: ' ',
        },
        ajax: (dataTablesParameters: any, callback) => {
          if (this.isFormValid) {
            this.newRequestBody['offset'] = dataTablesParameters.start;
            this.orders = [];
            this.totalLineItem = 0;
            this.totalrecord = 0;
            this.showLoader = true;
            this.fitmentservice.getBookingbyStatus(this.newRequestBody).subscribe(
              (response) => {
                window.scrollTo(0,0);
                this.orders = [];
                this.totalLineItem = 0;
                this.totalrecord = 0;
                if (response.success) {
                  this.fitmentData = response.results;
                  this.totalrecord = response.count;
                  this.showLoader = false;
                } else {
                  this.fitmentData = [];
                  this.showLoader = false;
                  this.totalrecord = 0;
                  // this.toastrService.error(response.data.msg);
                  if (response.msg == 'No records found') {
                    this.datatsetempty = true;
                  }
                }
                callback({
                  recordsTotal: this.totalrecord,
                  recordsFiltered: this.totalrecord,
                  data: [],
                });
                this.isEComFilter = false;
              },
              (err) => {
                this.orders = [];
                this.showLoader = false;
              }
            );
          }
        },
        columns: [
          {
            data: 'id',
            cellType: 'th/td',
            className: 'sticky-col first-col',
            width: '80px',
          },
          { data: 'registration_number', width: '170px' },
          { data: 'driver_name', width: '130px' },
          { data: 'driver_mobile_number', width: '130px' },
          { data: 'guru_name', width: '120px' },
          { data: 'guru_phone_number' ,width: '150px' },
          { data: 'service_type' ,width: '120px' },
          { data: 'case_status',width: '120px' },
          { data: 'created_date' ,width: '100px'},
          { data: 'Action' ,width: '120px'},
        ],
      };
    }
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
  
  ngOnDestroy() {
    // this.ngUnSubscribe.next();
    // this.ngUnSubscribe.complete();
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  openStatus(data:any){
    var req ={
      "account_pk":this.userDetails?.account_pk,
      "case_id": data.id
    }
    try{
    this.showLoader = true
    this.fitmentservice.getMilestoneStatus(req).subscribe(res => {
      this.showLoader = false
      if (res.success) {
        this.previousStatus = res.milestone_status;
        this.actionModel = this.modalService.show(this.actionDetails, {
          class: 'modal-dialog-centered modal-lg',
          backdrop: 'static',
        });
      }
      else{
        this.previousStatus = []
        this.showLoader = false
      }
    },
    (err) => {
      this.orders = [];
      this.showLoader = false;
    })
  }
  catch (error) {
    this.showLoader = false;
    this.userService.InsertExceptionLog({ "method_name": "openStatus()", "page_name": "FitmentSegmentListComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
  }
  }

  close(){
    this.actionModel.hide();
  }
}
