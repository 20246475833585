<!-- <div class="modal-header modal-title">
    Scheme Details
    <div (click)="closeModel.emit()" type="button"><i class="fa fa-times"></i></div>
</div>
<div class="modal-body">
    <div class="container">
        <div class="col-md-12">
           <div class="panel panel-default">
              <div [class]="schemeDetails.length > 6 ?'panel-body table-max-height-scroll':'panel-body'">
                 <table class="table table-condensed table-striped">
                    <thead class="table-light">
                       <tr class="text-center">
                        <th class="text-nowrap th-headingfont">Scheme Name</th>
                        <th class="text-nowrap th-headingfont">Start Date</th>
                        <th class="text-nowrap th-headingfont">End Date</th>
                        <th class="text-nowrap th-headingfont">Scheme Status</th>
                        <th class="text-nowrap th-headingfont">View Details</th>
                       </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngIf="schemeDetails">
                            <ng-container *ngFor="let item of schemeDetails; let i = index">
                                <tr data-toggle="collapse" [attr.data-target]="'#demo_'+i" class="accordion-toggle text-center" role="button">
                                    <td class="text-nowrap">{{item?.scheme_name}}</td>
                                    <td class="text-nowrap">{{ item?.start_dt | date:"dd-MM-yyyy" }}</td>
                                    <td class="text-nowrap">{{ item?.end_dt | date:"dd-MM-yyyy" }}</td>
                                    <td class="text-nowrap">{{ item?.is_active == 'True'?'Active':'In Active' }}</td>
                                    <td class="text-nowrap"><p class="use-coupon-text">Slab Details</p></td>
                                </tr>
                                <tr>
                                    <td colspan="12" class="hiddenRow">
                                        <div class="hiddenRow accordian-body collapse" id="demo_{{i}}">
                                           <table class="table table-striped">
                                              <thead>
                                                 <tr class="text-center">
                                                    <th>Min. QTY</th>
                                                    <th>Max. QTY</th>
                                                    <th>Discount Percentage</th>
                                                 </tr>
                                              </thead>
                                              <tbody>
                                                   <tr *ngFor="let slab of schemeDetails[i].slab_details;" data-toggle="collapse"  class="text-center accordion-toggle">
                                                       <td class="text-nowrap">{{slab?.slab_from}}</td>
                                                       <td class="text-nowrap">{{ slab?.slab_to }}</td>
                                                       <td class="text-nowrap">{{ ( (slab?.percentage) % 1 != 0)?slab?.percentage:(slab?.percentage | number: '1.0-0') }} %</td>
                                                   </tr>
                                              </tbody>
                                           </table>
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="schemeDetails.length == 0">
                            <tr>
                                <td> <p class="noPartData">No Data Found</p></td>
                            </tr>
                        </ng-container>
                    </tbody>
                 </table>
              </div>
           </div>
        </div>
     </div>
</div> -->

<div class="modal-header modal-title" *ngIf="popupOpenType == 'otherOffer'">
    Offer Details
    <div (click)="closeModel.emit()" type="button"><i class="fa fa-times"></i></div>
</div>
<div class="modal-body" style="padding-left:0px" *ngIf="popupOpenType == 'otherOffer'">
    <div class="container">
        <div class="col-md-12">
           <div class="panel panel-default">
              <div [class]="schemeDetails.length > 6 ?'panel-body table-max-height-scroll':'panel-body'" class="table-responsive">
                 <table class="table table-condensed table-striped" width="80">
                    <thead class="table-light">
                       <tr class="text-center">
                        <th class="text-nowrap th-headingfont">Discount Name</th>
                        <!-- <th class="text-nowrap th-headingfont">Discount Code</th> -->
                        <th class="text-nowrap th-headingfont">Discount Type</th>
                        <th class="text-nowrap th-headingfont">Min Qty</th>
                        <th class="text-nowrap th-headingfont">Max Qty</th>
                        <th class="text-nowrap th-headingfont">Percentage</th>
                        <th class="text-nowrap th-headingfont">Start Date</th>
                        <th class="text-nowrap th-headingfont">End Date</th>
                        <th class="text-nowrap th-headingfont">Status</th>
                       </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngIf="schemeSlabList">
                            <ng-container *ngFor="let item of schemeSlabList; let i = index">
                                <tr>
                                    <td class="text-nowrap ">{{item?.discount_name}}</td>
                                    <!-- <td class="text-nowrap">{{ item?.discount_code }}</td> -->
                                    <td class="text-nowrap">{{ item?.discount_type }}</td>
                                    <td class="text-nowrap">{{ item?.min_qty == 'NA'? item?.min_qty : (item?.min_qty | number:'1.0-0')}}</td>
                                    <td class="text-nowrap">{{ item?.max_qty == 'NA'? item?.max_qty : (item?.max_qty | number:'1.0-0')}}</td>
                                    <td class="text-nowrap">{{ item?.percentage}} %</td>
                                    <td class="text-nowrap">{{ item?.start_end | date:"dd-MM-yyyy"}}</td>
                                    <td class="text-nowrap">{{ item?.end_date | date:"dd-MM-yyyy"}}</td>
                                    <td class="text-nowrap">{{ item?.is_active == 'True' || 'true'?'Active':'In Active'}}</td>
                                </tr>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="schemeSlabList.length == 0">
                            <tr>
                                <td> <p class="noPartData">No Data Found</p></td>
                            </tr>
                        </ng-container>
                    </tbody>
                 </table>
              </div>
           </div>
        </div>
     </div>
</div>

<div class="modal-header modal-title" *ngIf="popupOpenType == 'slabOffer'">
    Slab Offer
    <div (click)="closeModel.emit()" type="button"><i class="fa fa-times"></i></div>
</div>
<div class="modal-body" style="padding-left:0px" *ngIf="popupOpenType == 'slabOffer'">
    <div class="container">
        <div class="col-md-12">
           <div class="panel panel-default">
              <div [class]="schemeDetails.length > 6 ?'panel-body table-max-height-scroll':'panel-body'" class="table-responsive">
                 <table class="table table-condensed table-striped" width="80">
                    <thead class="table-light">
                       <tr class="text-center">
                        <th class="text-nowrap th-headingfont">Discount Name</th>
                        <!-- <th class="text-nowrap th-headingfont">Discount Code</th> -->
                        <th class="text-nowrap th-headingfont">Discount Type</th>
                        <th class="text-nowrap th-headingfont">Min Qty</th>
                        <th class="text-nowrap th-headingfont">Max Qty</th>
                        <th class="text-nowrap th-headingfont">Percentage</th>
                        <th class="text-nowrap th-headingfont">Start Date</th>
                        <th class="text-nowrap th-headingfont">End Date</th>
                        <th class="text-nowrap th-headingfont">Status</th>
                       </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngIf="schemeSlabList">
                            <ng-container *ngFor="let item of schemeSlabList; let i = index">
                                <tr>
                                    <td class="text-nowrap ">{{item?.discount_name}}</td>
                                    <!-- <td class="text-nowrap">{{ item?.discount_code }}</td> -->
                                    <td class="text-nowrap">{{ item?.discount_type }}</td>
                                    <td class="text-nowrap">{{ item?.min_qty == 'NA'? item?.min_qty : (item?.min_qty | number:'1.0-0')}}</td>
                                    <td class="text-nowrap">{{ item?.max_qty == 'NA'? item?.max_qty : (item?.max_qty | number:'1.0-0')}}</td>
                                    <td class="text-nowrap">{{ item?.percentage}} %</td>
                                    <td class="text-nowrap">{{ item?.start_end | date:"dd-MM-yyyy"}}</td>
                                    <td class="text-nowrap">{{ item?.end_date | date:"dd-MM-yyyy"}}</td>
                                    <td class="text-nowrap">{{ item?.is_active == 'True' || 'true'?'Active':'In Active'}}</td>
                                </tr>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="schemeSlabList.length == 0">
                            <tr>
                                <td> <p class="noPartData">No Data Found</p></td>
                            </tr>
                        </ng-container>
                    </tbody>
                 </table>
              </div>
           </div>
        </div>
     </div>
</div>

<!-- cart adress dialog Data -->
<div class="modal-header modal-title" *ngIf="popupOpenType == 'cartaddress'">
    Combo Offers
    <div (click)="closeModel.emit()" type="button"><i class="fa fa-times"></i></div>
</div>
<div class="modal-body" style="padding-left:0px"*ngIf="popupOpenType == 'cartaddress'">
    <div class="container">
        <div class="col-md-12">
           <div class="panel panel-default">
              <div [class]="slabDetailarr.length > 6 ?'panel-body table-max-height-scroll':'panel-body'" class="table-responsive">
                 <table class="table table-condensed table-striped" width="80">
                    <thead class="table-light">
                       <tr class="text-center">
                        <th></th>

                        <th class="text-nowrap th-headingfont">Quantity</th>
                        <th class="text-nowrap th-headingfont">Percentage</th>
                       </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngIf="slabDetailarr">
                            <ng-container *ngFor="let item of slabDetailarr; let i = index">
                                <tr>
                                    <td class="text-nowrap " style="text-align: center;">Slab {{i+1}}</td>
                                    <td class="text-nowrap " style="text-align: center;">{{item?.quantity | number:'1.0-0'}}</td>
                                    <!-- <td class="text-nowrap">{{ item?.discount_code }}</td> -->
                                    <td class="text-nowrap" style="text-align: center;">{{ item?.percentage }}</td>
                                </tr>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="slabDetailarr.length == 0">
                            <tr>
                                <td> <p class="noPartData">No Data Found</p></td>
                            </tr>
                        </ng-container>
                    </tbody>
                 </table>
              </div>
              
                
           </div>
           <h4>Part Numbers</h4>
           <div style="display: flex;flex-wrap: wrap;margin-top: 5px;">
            <mat-chip-list>
                <mat-chip
                disableRipple
                  class="customViewModelClass"
                  *ngFor="let item of skuItemArr;let counterIndex = index">
            
                                    <div class="partnameImgclass">
                                      <div>
                                        <img (click)="routeToProduct(item)" src="{{item.part_image_path}}" class="imgClass" width="100%"  alt="banner 1">
                                      </div>
                                      <div>
                                        <span (click)="routeToProduct(item)" style="cursor: pointer;"><span style="font-weight: bold;">{{item.part_name}}</span> {{item.sku_item}}</span>
                                      </div>
                                      <!-- <div style="display: flex;align-items: center;">
                                        <app-input-number aria-label="Quantity" size="lg" [min]="1" [max]="8" [isTmgoValue]="mrkdetail.resource_type == 'Pack' && mrkdetail.moq > 1?mrkdetail.moq:'1'"
                                          (currentQuantity)="readproductdetaailQuantity(mrkdetail,$event,counterIndex)" [defaultValue]="mrkdetail.minimum_quantity">
                                        </app-input-number>
                                        <span style="position: relative;margin-left: 5px; width: 35px;" class="col-7 text-end d-flex align-items-center justify-content-center">
                                          <button class="add-to-card-new-product" (click)="partAddtoCart(mrkdetail,counterIndex)"><img alt=""
                                            src="./assets/images/cart.svg" /></button>
                                        </span>
                                      </div> -->
                                    </div>
                              
                  <!-- <span (click)="routeToProduct(item)" style="cursor: pointer;">
                    {{item.part_name}} - {{item.sku_item}}
                  </span> -->
                  <!-- <span *ngIf="item.sku_item" style="align-items: center;
                  width: 100%;
                  display: flex;
              ">
                  <app-input-number aria-label="Quantity" size="lg" [min]="1" [max]="8" [isTmgoValue]="item.resource_type == 'Pack' && item.moq > 1?item.moq:'1'"
                    (currentQuantity)="readproductdetaailQuantity(item,$event,counterIndex)" [defaultValue]="item.minimum_quantity">
                  </app-input-number>
                  <span style="position: relative;margin-left: 5px; width: 35px;" class="col-7 text-end d-flex align-items-center justify-content-center">
                    <button class="add-to-card-new-product" (click)="partAddtoCart(item,counterIndex)"><img alt=""
                      src="./assets/images/cart.svg" /></button>
                  </span>
                </span> -->
                </mat-chip>
              </mat-chip-list>

           </div>
              <!-- <mat-chip-grid aria-label="Part Numbers">
                <mat-chip disableRipple *ngFor="let item of slabMaster">
                 {{item.sku_item}}
                </mat-chip>
            </mat-chip-grid> -->
    

        </div>
    </div>
</div>