import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig, applicationConfiguration } from 'src/app/config/app.config';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class MenuService {

  protected server = this.appConfig.apiUrl + 'api/v1/ecom/get/';
  protected new_server = this.appConfig.apiUrl + 'api/v2/ecom/'
  readonly options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(
    private http: HttpClient,
    @Inject(applicationConfiguration) private appConfig: AppConfig
  ) { }

  GetPplWiseList(data): Observable<any> {
    return this.http.post(`${this.server}nav/ppl_wise_list/`, data, this.options);
  }
  GetPlWiseList(data): Observable<any> {
    return this.http.post(`${this.server}nav/pl_wise_list/`, data, this.options);
  }
  GetPgLineList(data): Observable<any> {
    return this.http.post(`${this.new_server}search/pg_line_group_list/`, data, this.options);
  }
}
