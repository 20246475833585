<div class="modal-header modal-title">
    Service Request
    <div (click)="closeModal()" type="button"><i class="fa fa-times"></i></div>
</div>
<div class="modal-body">
    <div class="cotainer">
    <form [formGroup]="fitmentForm">
        <div class="row">
            <div class="form-group col-4">
                <label class="o-title-1" for="inputEmail4">Select Type</label>
                <select class="form-select" aria-label="" formControlName="type"  (change)="selectNoType($event)">
                    <option *ngFor="let item of selectType" [value]="item.id">{{item.text}}</option>
                </select>
            </div>
            <div class="col-4">
                <label class="o-title-1" for="inputEmail4">{{labelForNo}} Number<span class="error-message">*</span></label>
                <input type="email" class="form-control" id="inputEmail4" placeholder="Enter Number" formControlName="number" oninput="this.value = this.value.toUpperCase()" (focusout)="custInfoCall()">
                <div class="error-message" *ngIf="hasRequiredError('number')">
                    <b> &nbsp; *required. </b>
                </div>
            </div>
        </div>
        <div class="row mt-4">
                <div class="row">
                    <div class="form-group col-md-6">
                        <label class="o-title-1" for="inputEmail4">Customer Name</label>
                        <input type="email" class="form-control" id="inputEmail4" placeholder="Enter Customer Name" formControlName="custName" readonly>
                    </div>
                    <div class="form-group col-md-4">
                        <label class="o-title-1" for="inputEmail4">Customer Mobile Number</label>
                        <input type="email" class="form-control" id="inputEmail4" placeholder="Enter  Mobile Number" formControlName="custMobile" readonly>
                    </div>
                </div>
        </div>
        <div class="row mt-3">
        <div class="col-12">
            <div class="table-responsive">
                <table class="table table-md return-table">
                    <thead>
                        <tr>
                            <th>Part No</th>
                            <th>ETA</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="etaDetails?.length > 0">
                        <!-- <ng-container *ngIf="!showLoader;else showData1;">
                            <tr *ngFor="let item of userService.generateFake(5);let i = index;">
                              <td>
                                <div class="d-inline-flex ps-2" style="vertical-align: top; flex-direction: column;">
                                  <div class="tbl-c-title pb-2"><skc-rectangle [loading]="loading" [skc-height]="'15px'" [skc-width]="'100px'"> </skc-rectangle></div>
                                  <div class="tbl-c-desc" style="cursor: pointer;">
                                    <skc-rectangle [loading]="loading" [skc-height]="'15px'" [skc-width]="'100px'"> </skc-rectangle>
                                  </div>
                                </div>
                              </td>
                              <td><skc-rectangle [loading]="loading" [skc-height]="'30px'" [skc-width]="'160px'"> </skc-rectangle></td>
                              <td><skc-rectangle [loading]="loading" [skc-height]="'30px'" [skc-width]="'360px'"> </skc-rectangle></td>
                            </tr>
                          </ng-container>
                          <ng-template #showData1> -->
                        <tr *ngFor="let item of etaDetails">
                            <td class="showcursor text-nowrap">
                                <div class="d-inline-flex">
                                    <img alt=""
                                        src="https://d26uj34udz53va.cloudfront.net/ecom/parts_image/default/default.jpg"
                                        style="width: 44px; height: 30px;">
                                </div>
                                <div class="d-inline-flex ps-2" style="vertical-align: top; flex-direction: column;">
                                    <div class="tbl-c-title" style="cursor: pointer;">
                                        {{item.part_desc ? item.part_desc : item.part_desc}}
                                    </div>
                                    <div class="tbl-c-desc">{{item.part_number ? item.part_number:part_num}}</div>
                                </div>
                            </td>
                            <td>{{(item.delivery_date ? (item.delivery_date |date: 'dd-MM-yyyy' )  : (item?.ETA ) )}}</td>
                        </tr>
                        <!-- </ng-template> -->
                    </tbody>
                    <tbody *ngIf="etaDetails?.length == 0">
                        <tr><span class="error-message">{{etamsg}}</span></tr>
                    </tbody>
                </table>
            </div>
        </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-12">
                <div class="o-title">Delivery Address</div>
                <div class="o-desc">
               {{ address?.address_1 ? (address?.address_1 + ', ') : '' }}
                {{ address?.address_2 ? (address?.address_2 + ', ') : '' }}
                {{ address?.ship_taluka ? (address?.ship_taluka+','):'' }}
                {{ address?.ship_city ? (address?.ship_city+','):'' }}
                {{ address?.ship_district ? (address?.ship_district+','):'' }}
                {{ address?.ship_pincode ? ( ' - '+address?.ship_pincode+', '):'' }}
                {{ address?.ship_state ? (address?.ship_state+'.'):', ' }}
                {{ address?.ship_country ? ( address?.ship_country + ''): '' }}
            </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="form-group col-4">
                <label class="o-title-1" for="inputEmail4">Select Service Type<span class="error-message">*</span></label>
                <!-- <select class="form-control" name="cars" id="cars">
                    <option [value]="item.id" *ngFor="let item of serviceType">{{item.text}}</option>
                </select> -->
                <select class="form-select" aria-label="" formControlName="serviceType">
                <option value="" selected disabled>Select Service Type</option>
                <option *ngFor="let item of serviceType" [value]="item.id">{{item.text}}</option>
                </select>
                <div class="error-message" *ngIf="hasRequiredError('serviceType')">
                    <b> &nbsp; *required. </b>
                </div>
            </div>
            <div class="form-group col-4">
                <label class="o-title-1" for="inputEmail4">Select Service Date<span class="error-message">*</span></label>
                <mat-form-field style="display: flex;align-items: center; margin-top: -4px;" appearance="outline">
                    <input matInput formControlName="deliveryDate" [min]="maxETADate" placeholder="Service Date" (click)="dp2?.open()"
                        readonly [matDatepicker]="dp2" >
                    <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                    <mat-datepicker #dp2 disabled="false"></mat-datepicker>
                </mat-form-field>
                <!-- <input type="date" id="datepicker" name="datepicker" min="today" placeholder="ETA Date"
                readonly matDatepicker="dp2"> -->
                <div class="error-message" *ngIf="hasRequiredError('deliveryDate')" style="margin-top: -22px;">
                    <b> &nbsp; *required. </b>
                </div>
            </div>
            <div class="form-group col-4">
                <label class="o-title-1" for="inputEmail4">Select Time Slot<span class="error-message">*</span></label>
                <select class="form-select" aria-label="" formControlName="timeSlot">
                    <option value="" selected disabled>Select Time Slot</option>
                    <option *ngFor="let item of timeSlot" [value]="item.id">{{item.text}}</option>
                </select>
                <div class="error-message" *ngIf="hasRequiredError('timeSlot')">
                    <b> &nbsp; *required. </b>
                </div>
                <!-- <ng-select  [items]="timeSlot" [virtualScroll]="true" bindLabel="text" bindValue="id"
                            aria-label="no" placeholder="Select Time slot">
                </ng-select> -->
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-12">
                <div class="o-title">Driver Details</div>
                    <div class="row">
                        <div class="form-group col-md-6">
                            <label class="o-title-1" for="inputEmail4">Driver Name<span class="error-message">*</span></label>
                            <input type="text" class="form-control" id="inputEmail4" placeholder="Enter Driver Name" formControlName="driverName" (keypress)="onlyChars($event)" maxlength="100">
                            <div class="error-message" *ngIf="hasRequiredError('driverName')"> &nbsp;
                                <b>*required</b>
                            </div>
                            <div class="error-message" *ngIf="hasPatternError('driverName')"><b> &nbsp;
                                    *invalid</b></div>
                        </div>
                        <div class="form-group col-md-6">
                            <label class="o-title-1" for="inputEmail4">Mobile Number<span class="error-message">*</span></label>
                            <input type="text" class="form-control" id="inputEmail4" placeholder="Enter Mobile Number" formControlName="driverMobile" (keypress)="numberOnly($event)" maxlength="10">
                            <div class="error-message" *ngIf="hasRequiredError('driverMobile')"> &nbsp;
                                <b>*required</b>
                            </div>
                            <div class="error-message" *ngIf="hasPatternError('driverMobile')"><b> &nbsp;
                                    *invalid</b></div>
                        </div>
                    </div>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-md-12">
                <div class="o-title">Mechanic Details</div>
                <!-- <div class="row" *ngIf="openType =='All'">
                    <div class="radio-buttons">
                        <label *ngFor="let option of radioOptions" class="radio-label">
                          <input type="radio" [value]="option.value" [(ngModel)]="distanceBtn" > {{ option.label }}
                        </label>
                    </div>
                </div> -->
                    <div class="row mb-4">
                        <div class="form-group col-md-6">
                            <label class="o-title-1" for="inputEmail4">Mechanic Name <span *ngIf="openType =='All'">(Optional)</span></label>
                            <input type="text" class="form-control" id="inputEmail4" placeholder="Enter Mechanic Name" formControlName="machanicName" readonly *ngIf="openType == 'select'">
                            <select class="form-select" aria-label="" formControlName="machanicName" *ngIf="openType =='All'" (change)="selectMechanic($event)">
                                <option value="" selected disabled>Select Mechanic</option>
                                <option *ngFor="let item of mechanicDataList" [value]="item.id">{{item.mechanic_name}} (Distance: {{item.distance}}, Duration: {{item.duration}})</option>
                            </select>
                        </div>
                        <div class="form-group col-md-6">
                            <label class="o-title-1" for="inputEmail4">Mobile Number <span *ngIf="openType =='All'">(Optional)</span></label>
                            <input type="text" class="form-control" id="inputEmail4" placeholder="Enter Mobile Number" formControlName="machanicMobile" readonly>
                        </div>
                    </div>
            </div>
        </div>
    </form>
    </div>
    <button type="button" class="btn btn-primary text-uppercase mat-action-btn mat-next" style="float: right; margin-bottom: 20px;" (click)="onSubmit()" [disabled]="isCheckVehicle"> Submit Request </button>
</div>

<ng-container *ngIf="showLoader">
    <div class="loader">
        <div class="ng-spinner ng-spinner-icon"></div>
    </div>
</ng-container>