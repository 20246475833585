import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output, SimpleChanges, OnChanges,
} from '@angular/core';
import { ProductService } from '../../services/REST/product.service';
import { UserService } from '../../services/REST/user.service';
import { MatRadioChange } from '@angular/material/radio';
@Component({
  selector: 'app-productcompatiblemodel',
  templateUrl: './productcompatiblemodel.component.html',
  styleUrls: ['./productcompatiblemodel.component.scss'],
})
export class ProductcompatiblemodelComponent
  implements OnInit, OnChanges {
  @Input() partNumber: any;
  @Output() showsubModel = new EventEmitter<any>();
  @Output() slideToTop = new EventEmitter<any>();
  // public dtTrigger: Subject<any> = new Subject();
  // @ViewChild(DataTableDirective, { static: false })
  // dtElement: DataTableDirective;
  newRequestBody: any;
  datatsetempty = false;
  showLoader = false;
  // dtOptions: DataTables.Settings = {};
  firstload = true;
  Compatiblemodeldata: any;
  allModelData = [];
  totalcomprecord: any;
  compcurrentPage: any;
  compatibleModelPage: boolean = true;
  vehiclecount;
  variantList: any[] = [];
  variantdata = [];
  isNoDataFound: boolean = false;
productList: any;
loading: boolean = true;
  constructor(
    private productService: ProductService,
    public userService: UserService
  ) { }


  ngOnInit(): void {
    this.compcurrentPage = 1;
    this.CompatibleModel(0);
    // });
    // this.CompatibleModel(0);
    // if(!this.firstload){
    //   this.rerender();
    // }
    // this.firstload=false;
  }
  CompatibleModel(page, isPagination?) {
    try {
      // this.calldatatable(page);
      //   this.rerender();
      this.Compatiblemodeldata = [];
      this.allModelData = [];
      let searchData;
      searchData = {
        part_number: this.partNumber,
        offset: page,
        limit: 20,
      };
      this.Compatiblemodeldata = [];
      this.showLoader = true;
      this.productService.get_applicable_model(searchData).subscribe(
        (data) => {
          if (data.success) {
            var responseArray: any[] = data.data;
            if (responseArray.length > 0) {
              responseArray = responseArray.sort((a, b) => {
                if (a.model_cat < b.model_cat) return -1;
                else if (a.model_cat > b.model_cat) return 1;
                else return 0;
              });
            }
            let modelArray: any[] = [];
            responseArray.forEach(x => modelArray.push(x.model_cat));
            modelArray = modelArray.filter((value, index, modelArray) => modelArray.indexOf(value) === index);
            modelArray.forEach(x => {
              responseArray.forEach(y => {
                if (x === y.model_cat) {
                  let isModelAlreadyPresent: boolean = false;
                  this.allModelData.forEach(z => {
                    if (z.model_cat === y.model_cat) {
                      isModelAlreadyPresent = true;
                    }
                  });
                  if (isModelAlreadyPresent) {
                    this.allModelData.forEach(a => {
                      if (a.model_cat === y.model_cat) {
                        let isVcAlreadyPresent: boolean = false;
                        Array(a.vcArray).forEach(vc => {
                          if(vc.vc_no === y.vc_no && vc.engine_type === y.engine_type && vc.fuel_type === y.fuel_type){
                            isVcAlreadyPresent = true;
                          }
                        });
                        if(!isVcAlreadyPresent){
                          a.vcArray.push({
                            base_model: y.base_model,
                            vc_no: y.vc_no,
                            engine_type: y.engine_type,
                            fuel_type: y.fuel_type
                          });
                        }
                      }
                    });
                  }
                  else {
                    this.allModelData.push({
                      model_cat: y.model_cat,
                      model_desc: y.model_desc,
                      model_image: y.model_image,
                      vcArray: [{
                        base_model: y.base_model,
                        vc_no: y.vc_no,
                        engine_type: y.engine_type,
                        fuel_type: y.fuel_type
                      }]
                    });
                  }
                }
              });
            });
            // for (let i = 0; i < responseArray.length; i++) {
            //   let model = responseArray[i];
            //   if (model) {
            //     let isModelAlreadyPresent: boolean = false;
            //     this.allModelData.forEach(x => {
            //       if (x.model_cat === model.model_cat) {
            //         isModelAlreadyPresent = true;
            //       }
            //     });
            //     if (isModelAlreadyPresent) {
            //       this.allModelData.forEach(x => {
            //         if (x?.model_cat === model?.model_cat) {
            //           let isVariantPresent: boolean = false;
            //           if (x?.varianList?.length > 0) {
            //             x.varianList.forEach(y => {
            //               if (y.vc_no === model.vc_no) {
            //                 isVariantPresent = true;
            //               }
            //             })
            //           }
            //           if (!isVariantPresent) {
            //             x?.variantList.push({
            //               vc_no: (model.vc_no != '' && model.vc_no != null && model.vc_no != undefined) ? model.vc_no : '-',
            //               engine_type: (model.engine_type != '' && model.engine_type != null && model.engine_type != undefined) ? model.engine_type : '-',
            //               fuel_type: (model.fuel_type != '' && model.fuel_type != null && model.fuel_type != undefined) ? model.fuel_type : '-',
            //             })
            //           }
            //         }
            //       })
            //     }
            //     else {
            //       this.allModelData.push({
            //         model_image: (model.model_image != '' && model.model_image != null && model.model_image != undefined) ? model.model_image : '',
            //         model_cat: (model.model_cat != '' && model.model_cat != null && model.model_cat != undefined) ? model.model_cat : '-',
            //         model_desc: (model.model_desc != '' && model.model_desc != null && model.model_desc != undefined) ? model.model_desc : '-',
            //         variantList: [
            //           {
            //             vc_no: (model.vc_no != '' && model.vc_no != null && model.vc_no != undefined) ? model.vc_no : '-',
            //             engine_type: (model.engine_type != '' && model.engine_type != null && model.engine_type != undefined) ? model.engine_type : '-',
            //             fuel_type: (model.fuel_type != '' && model.fuel_type != null && model.fuel_type != undefined) ? model.fuel_type : '-',
            //           }
            //         ]
            //       })
            //     }
            //   }
            // }
            this.Compatiblemodeldata = this.allModelData;
            if (isPagination) {
              this.slideToTop.emit();
            }
            this.totalcomprecord = data.total_result;
            this.vehiclecount = data.data.length;
            this.isNoDataFound = false;
            this.showLoader = false;
          } else {
            this.totalcomprecord = 0;
            this.showLoader = false;
            this.Compatiblemodeldata = [];
            this.isNoDataFound = true;
          }
        },
        (err) => {

          this.totalcomprecord = 0;
          this.showLoader = false;
          this.Compatiblemodeldata = [];
        }
      );
    } catch (error) {
      // this.userService.InsertExceptionLog({ "method_name": "CompatibleModel()", "page_name": "ProductDetailsComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
    }
  }

  FormModelData(data: any, variantdata: any): compatible {
    const objmodeldata: compatible = {} as compatible;
    objmodeldata.model_image = data['model_image'] ? data['model_image'] : '-';
    objmodeldata.model_cat = data['model_cat'] ? data['model_cat'] : '-';
    objmodeldata.model_desc = data['model_desc'] ? data['model_desc'] : '-';
    objmodeldata.base_model = data['base_model'] ? data['base_model'] : '-';
    objmodeldata.variantList = variantdata;
    return objmodeldata;
  }

  FormVarientData(data: any): ModelVariant {
    const objvariantdata: ModelVariant = {} as ModelVariant;
    objvariantdata.vc_no = data['vc_no'] ? data['vc_no'] : '-';
    objvariantdata.engine_type = data['engine_type']
      ? data['engine_type']
      : '-';
    objvariantdata.capacity = data['capacity'] ? data['capacity'] : '-';
    objvariantdata.vc_base_model = data['base_model'] ? data['base_model'] : '-';
    objvariantdata.fuel_type = data['fuel_type'] ? data['fuel_type'] : '-';
    return objvariantdata;
  }

  pageChange(page: any) {
    this.compcurrentPage = page;
    page = (page === 1) ? 0 : (page * 20 - 20);
    this.CompatibleModel(page, true);
    // this.rerender();
  }

  GetsubModelDeails(item, vcNumber) {
    let data = {
      base_model: vcNumber.vc_base_model,
      model_cat: item.model_cat,
      model_desc: item.model_desc,
      vcNumber: vcNumber.vc_no
    }
    this.showsubModel.emit(data);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.partNumber = changes?.partNumber?.currentValue;

    // this.CompatibleModel(0);
  }
  // calldatatable(page) {
  //   this.Compatiblemodeldata = [];
  //   this.allModelData = [];

  //   this.Compatiblemodeldata = [];
  //   //           this.showLoader = true;

  //   this.newRequestBody = {
  //     part_number: this.partNumber,
  //     // offset: page,
  //     limit: 20,
  //   };
  //   this.dtOptions = {
  //     // pagingType: 'full_numbers',
  //     responsive: true,
  //     serverSide: true,
  //     processing: true,
  //     lengthChange: false,
  //     searching: false,
  //     // pageLength: 10,
  //     ordering: false,
  //     info: false,
  //     paging: false,
  //     language: {
  //       infoEmpty: 'No records available',
  //       infoFiltered: '',
  //       info: '',
  //     },
  //     ajax: (dataTablesParameters: any, callback) => {
  //       this.newRequestBody['offset'] = dataTablesParameters.start;

  //        this.productService.get_applicable_model(this.newRequestBody).subscribe(
  //         (data) => {
  //           let count = 0;
  //           if (data.success) {
  //             var CompData = data.data;
  //             this.totalcomprecord = data.total_result;
  //             for (var i = 0; i < CompData.length; i++) {
  //               var modelcatname = CompData[i]['model_cat'];
  //               if (modelcatname) {
  //                 var checkdatamodel = this.allModelData.filter(
  //                   (model) => model.model_cat === modelcatname
  //                 );
  //                 if (checkdatamodel.length == 0) {
  //                   this.variantdata = [];
  //                   this.variantdata.push(this.FormVarientData(CompData[i]));
  //                   this.allModelData.push(
  //                     this.FormModelData(CompData[i], this.variantdata)
  //                   );
  //                 } else {
  //                   this.variantdata.push(this.FormVarientData(CompData[i]));
  //                   this.allModelData.find(
  //                     (item) => item.model_cat == modelcatname
  //                   ).variantList = this.variantdata;
  //                   // this.allModelData.push(this.FormModelData(checkdatamodel[0],this.variantdata));
  //                 }
  //               }
  //             }
  //             this.Compatiblemodeldata = this.allModelData;

  //             //    this.showLoader = false;
  //           } else {
  //             //  this.showLoader = false;
  //             this.Compatiblemodeldata = [];
  //             // this.toastrService.error(data.data.msg);

  //             this.totalcomprecord = 0;
  //             if (data.data.msg == 'Data not found') {
  //               this.datatsetempty = true;
  //             }
  //             // this.dtTrigger.next();
  //           }

  //           callback({
  //             recordsTotal: this.totalcomprecord,
  //             recordsFiltered: this.totalcomprecord,
  //             data: [],
  //           });
  //         },
  //         (err) => {
  //           this.Compatiblemodeldata = [];
  //         }
  //       );
  //     },
  //   };
  // }
  // rerender(): void {
  //   this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
  //     // Destroy the table first
  //     dtInstance.destroy();
  //     // Call the dtTrigger to rerender again
  //     this.dtTrigger.next();
  //   });
  // }

  // ngOnDestroy() {
  // this.dtTrigger.unsubscribe();
  // }

  // ngAfterViewInit() {
  // this.dtTrigger.next();
  // }

}
export class compatible {
  model_image: any;
  model_cat: any;
  model_desc: any;
  base_model: any;
  variantList: any;
}
export class ModelVariant {
  vc_no: any;
  engine_type: any;
  capacity: any;
  fuel_type: any;
  vc_base_model: any;
}
