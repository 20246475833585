import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/shared/services/REST/user.service';
import { secureStorage } from 'src/app/shared/services/securestorage';

@Component({
  selector: 'app-profileloyality',
  templateUrl: './profileloyality.component.html',
  styleUrls: ['./profileloyality.component.scss']
})
export class ProfileloyalityComponent implements OnInit {
  userDetails: any;
  dataArray:any = [];
  loyalityData:any;
  constructor(private userService:UserService,
    private localstorage: secureStorage,
private router:Router
  ) { }

  ngOnInit(): void {
    this.getLimitData()
  }
  getLimitData(){
    this.userDetails = JSON.parse(this.localstorage.getItem('userData'));
    let request = {
      "account_pk":this.userDetails.account_pk,
      // "account_pk":1499,

    }
    this.userService.loyalityLimitApi(request).subscribe((res) => {
      this.dataArray = [];
      if(res && res.success){
        if(res.data.membership_number){
          this.loyalityData = res.data
          this.dataArray.push(res.data);

        }

      }else{

      }
    })
  }

  OnRedirectLoyalty(value){
    if(value == 'paybyloyalty'){
      this.router.navigate(['/orders/loyalty-payment'])
    }else{
      this.router.navigate(['/orders/csr-list'])
    }
  }
}
