import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
// These regular expressions are all used for form validation
export class RegexService {
  constructor() { }

  // used for FirstName, LastName, City
  get HumanName() {
    return "^[a-zA-Z0-9-.'\\s]*$"; // only alphanumic and space . '
    // return "^[a-zA-Z0-9!@#$&()\\- `.+,/\"]*$"
  }
get NamewithAnd(){
  return "^[ A-Za-z0-9_@./#&+-]*$"
}
  get FullName() {
    return "^[A-Z][a-zA-Z]{3,}(?: [A-Z][a-zA-Z]*){0,2}$";
  }

  get Email() {
    return '^.+@.+\\..+$'; // contains @ and . with text surrounding
  }

  get Phone() {
    return '^[6-9][0-9]{9}$'; // max 20 chars, numbers and -
  }

  get Chasisregx() {
    return "^[a-zA-Z0-9]*$"; //number and alphabates only
  }

  get Registrationregx() {
    return "^[A-Z]{2}[ -][0-9]{1,2}(?: [A-Z])?(?: [A-Z]*)? [0-9]{4}$"; //number and alphabates only
  }

  get Percentage() {
    // return '(^100|([0-9]{2})$)';
    return '^(100([\.][0]{1,})?$|[0-9]{1,2}([\.][0-9]{1,})?)$'
  }

  get Date() {
    return '^[0-9]{2}-[0-9]{2}-[0-9]{4}$'; // mm-dd-yyyy, all numbers
  }

  get Aadhar() {
    return '[0-9]{12}';
  }
  get chequeNumber() {
    return '[0-9]{6}';
  }
  getZip(countryCode = 'US') {
    switch (countryCode) {
      case 'CA':
        return '^[A-Za-z]\\d[A-Za-z][ -]?\\d[A-Za-z]\\d$';
      case 'US':
        return '^[0-9]{5}$'; // US zip - five numbers
    }
  }
  get Pan(){
    return '^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$'
  }
  get Ifsc(){
    return '^[A-Za-z]{4}[a-zA-Z0-9]{7}$'
  }
  
  get GstNo(){
    return '^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$'
  }
}
