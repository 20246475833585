<div class="modal-content">
    <div class="modal-header modal-title  pt-2 pb-2">
     Model
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
    </div>
    <div class="modal-body">
      <!-- <div class="row">
        <div class="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 search-model mb-3" *ngFor="let objmodel of allModelData; let m = index">
          <div class="box-container"  (click)="GetsubModelDeails(objmodel)" [ngClass]="activeModel == objmodel?.model_name ? 'active':''" style="  align-items: center; display: flex; justify-content: center;"> 
            <div class="text" title="{{objmodel?.model_name}}">{{objmodel?.model_name}}</div>
          </div>
        </div>
      </div> -->
      <app-productcompatiblemodel [partNumber]="partNumber" (showsubModel)="GetsubModelDeails($event)"></app-productcompatiblemodel>

      <!-- <div class="row">
        <div class="table-responsive">
          <table class="table table-compatible" *ngIf="allModelData.length>0">
            <thead class="text-uppercase">
              <tr>
                <th>Model</th>
                <th>Variant</th>
                <th>Engine</th>
                <!-- <th>Capacity</th> -
                <th>Fuel</th>
              </tr>
            </thead>
            <tbody *ngIf="allModelData.length>0">
              <tr *ngFor="let objcmodel of allModelData">
                <td>
                  <img [src]="objcmodel?.model_image" alt="" class="img-fluid"
                    onerror="this.src='./assets/tata-vehicle.jfif';" style="max-width: 60px;">
                  <div class="model">{{ objcmodel?.model_cat }}</div>
                  <div class="variants">{{ objcmodel?.variantList?.length }} Variants</div>

                </td>
                <td>
                  <table class="table table-borderless table-sm table align-middle mb-0">
                    <tbody>
                      <tr *ngFor="let objvc of objcmodel?.variantList">
                        <td (click)="GetsubModelDeails(objcmodel)"> <a style="cursor: pointer;color: #0d6efd;">{{ objvc?.vc_no }}</a></td>
                      </tr>

                    </tbody>
                  </table>
                </td>
                <td>
                  <table class="table table-borderless table-sm table align-middle mb-0">
                    <tbody>
                      <tr *ngFor="let objengine of objcmodel?.variantList">
                        <td>{{ objengine?.engine_type }}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table class="table table-borderless table-sm table align-middle mb-0">
                    <tbody>
                      <tr *ngFor="let objdisel of objcmodel?.variantList">
                        <td>{{ objdisel?.fuel_type }}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <div *ngIf="allModelData.length == 0" style=" text-align: center;">
            <span> <b>No Data Found</b></span>
          </div>
        </div>
      </div> -->
    </div>
  </div>