<div class="fleet_retail_type_owner" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" 
tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog-centered modal-xl">
        <div class="modal-content pb-3 pb-md-5">
            <div class="modal-header justify-content-center border-0">
                <div class="modal-header-area">
                    <div class="login-header-img">
                        <picture>
                            <source srcset="./assets/images/e-dukaan-logo.png" media="(min-width: 600px)">
                            <img src="./assets/images/e-dukaan-logo.png" class="img-fluid" alt="">
                        </picture>
                    </div>
                    <div class="c_fleet_heading text-center">
                        <h4 class="mt-3">Continue as</h4>
                    </div>
                </div>
            </div>
            <div class="modal-body" *ngIf="isLogin">
                <div class="row d-flex fleet_type_select_card justify-content-center">
                    <div *ngFor="let usertype of userTypes" [ngClass]="size400_900 == true?'card col-6':'card col-6 p-3 p-sm-5'" [style]="( usertype=='RT' && userTypes.length > 2 && size400_900 == false ) ?'cursor: pointer; width:55%;top:2rem;':( usertype=='RT' && userTypes.length > 2 && size400_900 == true ) ?'cursor: pointer; width:95%;top:2rem;margin-bottom:1rem;':'cursor: pointer; width:40%;top:1rem;'" (click)="getUsertypeList(usertype,0,30)">
                        <div class="card-body">
                            <h3 [class]="usertype=='RT' && userTypes.length > 2?'mb-0 text-nowrap':'mb-0'">{{ usertype == 'FO'?'Fleet Owner':usertype=='RT'?'Retailer and Champion':usertype=='GU'?'Guest User':usertype }}</h3>
                        </div>
                    </div>
                    <!-- <div [ngClass]="(isRTSeleceted) ? 'card card-active' : 'card'" style="cursor: pointer; width:40%" (click)="getUsertypeList('RT',0,30)">
                        <div class="card-body p-3 p-sm-5">
                            <h3 class="mb-0">Retailer and Champion</h3>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="modal-body" *ngIf="isRegistration">
                <div class="row d-flex fleet_type_select_card justify-content-center">
                    <div *ngFor="let usertype of userTypes" [class]="size400_900 == true?'card col-6':'card col-6 p-3 p-sm-5'" [style]="( usertype=='RT' && userTypes.length > 2 && size400_900 == false ) ?'cursor: pointer; width:55%;top:2rem;':( usertype=='RT' && userTypes.length > 2 && size400_900 == true ) ?'cursor: pointer; width:95%;top:2rem;margin-bottom:1rem;':'cursor: pointer; width:40%;top:1rem;'" (click)="bindUsertype(usertype)">
                        <div class="card-body">
                            <h3 [class]="usertype=='RT' && userTypes.length > 2?'mb-0 text-nowrap':'mb-0'">{{ usertype == 'FO'?'Fleet Owner':usertype=='RT'?'Retailer and Champion':usertype=='GU'?'Guest User':usertype }}</h3>
                        </div>
                    </div>
                    <!-- <div class="card" style="cursor: pointer; width:40%" (click)="bindUsertype('FO')">
                        <div class="card-body p-3 p-sm-5">
                            <h3 class="mb-0">Fleet Owner</h3>
                        </div>
                    </div>
                    <div class="card" style="cursor: pointer; width:40%" (click)="bindUsertype('RT')">
                        <div class="card-body p-3 p-sm-5">
                            <h3 class="mb-0">Retailer and Champion</h3>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>