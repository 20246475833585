import { Component, OnInit } from '@angular/core';
import { ProductService } from "../../../../shared/services/REST/product.service";
import { ActivatedRoute } from '@angular/router';
import { GoogleAnalyticsService } from 'src/app/shared/services/GoogleAnalytics/GoogleAnalytics.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/shared/services/REST/user.service';
import { MenuService } from 'src/app/shared/services/REST/menu.service';
import { ToastrService } from 'ngx-toastr';
import { secureStorage } from 'src/app/shared/services/securestorage';
@Component({
  selector: 'app-page-category-list',
  templateUrl: './page-category.component.html',
  styleUrls: ['./page-category.component.scss'],
})
export class PageCategoryComponent implements OnInit {
  address_pk: any;
  account_pk: any;
  featuredProducts1 = [];
  featuredProducts2 = [];
  featuredProducts: any = [];
  searchBy: string;
  page: any = 0;
  result: boolean;
  productFilterData: any[];
  showPagination: boolean = false;
  pagestart: any;
  isApplyFilter: boolean = false;
  filterArr = [];
  productList;
  kit_parts: boolean = true;
  durafit_parts: boolean = false;
  category_type: any;
  search_text: any;
  partnumberlastfour: any;
  searchValue: string;
  aggType: any;
  categoryType: string;
  halfProductsList: any[];
  AllProductsList: any[];
  halfProducts: any[];
  AllProducts: any[];
  showMore: boolean = false;
  selectedPgLine: any;
  pgline = [];
  dispgroup = [];
  basemodel = [];
  Mainmodel = '';
  showLoader: boolean = false;
  shippingaddressid: any;
  billingaddressid: any;
  searchPgLine = '';
  kitName: any;
  layout: string = 'Grid';
  constructor(
    private productService: ProductService,
    private route: ActivatedRoute,
    private googleAnlyatics: GoogleAnalyticsService,
    private router: Router,
    private userService: UserService,
    private menu: MenuService,
    private toastrService: ToastrService,
    private localstorage: secureStorage
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.searchBy = params['searchBy'];
      this.searchValue = params['search'];
      this.categoryType = params['type'];
      this.aggType = params['aggtype'];
      this.kitName = params['kitName'];
      this.page = 0;
      this.selectedPgLine = '';
      this.showMore = false;
      this.getProducts(this.page);
      this.showProductsFilter();
    });
  }

  getProducts(page) {
    try {
      document.body.scrollTop = 0;
      let user = JSON.parse(this.localstorage.getItem('userData'));
      let address = JSON.parse(this.localstorage.getItem('address'));
      if (user && address) {
        this.account_pk = +JSON.stringify(user.account_pk);
        // if (address['type'] === 'default' && address['type'] !== null) {
        //   this.address_pk = address.type;
        // }
        // else {
        //   if (address.address.length === undefined || address.address.length === 0) {
        //     this.address_pk = address.address.id;
        //   }
        //   else {
        //     this.address_pk = address.address[0].id;
        //   }
        // }
        let address = JSON.parse(this.localstorage.getItem('address'));
        let billingaddress = JSON.parse(
          this.localstorage.getItem('billingAddress')
        );
        var addresstypecheck = address?.address['default'];
        if (addresstypecheck === true) {
          this.shippingaddressid = 'default';
        } else {
          this.shippingaddressid = address?.address['address_id'];
        }
        var Billingaddresscheck = billingaddress?.address['default'];
        if (Billingaddresscheck === true) {
          this.billingaddressid = 'default';
        } else {
          this.billingaddressid = billingaddress?.address['address_id'];
        }
      }
      this.localstorage.setItem('page', this.searchBy);
      let data = {};
      this.productList = [];
      if (this.searchBy === 'durafit') {
        let searchData = {};
        if (user && address) {
          searchData = {
            search_text: '',
            bs_model_name: '',
            pg_line: '',
            multi_pg_line: [],
            multi_bs_model_name: [],
            multi_dispgroup_name: [],
            model_cat: '',
            mod_uid: '',
            category_type: '',
            bom_id: '',
            durafit_parts: true,
            kit_parts: false,
            part_number_last_four: '',
            offset: page,
            // start_row:0,
            limit: 20,
            pl_id: '',
            //is_discount_needed: true,
            account_pk: this.account_pk,
            //address_pk: this.address_pk,
            shipping_address_id: this.shippingaddressid,
            part_number: '',
            is_for_epc_data: false,
            is_for_discount: true,
            is_for_ecom_detail: false,
          };
        } else {
          searchData = {
            search_text: '',
            bs_model_name: '',
            pg_line: '',
            multi_pg_line: [],
            multi_bs_model_name: [],
            multi_dispgroup_name: [],
            model_cat: '',
            mod_uid: '',
            category_type: '',
            bom_id: '',
            durafit_parts: true,
            kit_parts: false,
            part_number_last_four: '',
            offset: page,
            // start_row:0,
            limit: 20,
            pl_id: '',
          };
        }
        this.result = false;
        this.productFilterData = [];
        this.showLoader = true;
        this.productService.get_part_search_md(searchData).subscribe(
          (response) => {
            this.result = true;
            this.productFilterData = [];
            if (response.success) {
              this.showPagination =
                response.total_result === response.end ? true : false;
              this.page = response.end;
              this.pagestart = response.start;
              let t = +this.page - Math.abs(response.start - response.end);
              this.localstorage.setItem('prevOffset', '' + (t <= 0 ? 0 : t));
              this.localstorage.setItem('nextOffset', '' + this.showPagination);
              //this.page = response.data.end;
              this.productList = response.data;
              this.productFilterData = response.data;
              //Google Analyatics Code Start
              var MobileNo = null;
              if (
                this.localstorage.getItem('userData') != null ||
                this.localstorage.getItem('userData') != undefined
              ) {
                let userData = JSON.parse(
                  this.localstorage.getItem('userData')
                );
                MobileNo = userData.phone_number;
              }
              var Address = '';
              if (
                this.localstorage.getItem('address') != null ||
                this.localstorage.getItem('address') != undefined
              ) {
                let address = JSON.parse(this.localstorage.getItem('address'));
                Address =
                  address.address.street_address1 +
                  ', ' +
                  address.address.state +
                  ', ' +
                  address.address.district +
                  ', ' +
                  address.address.city +
                  ', ' +
                  address.address.postal_code;
              }
              try {
                this.googleAnlyatics.viewAllParts(
                  'View_all_Parts_web',
                  Address,
                  'Durafit',
                  MobileNo
                );
              } catch (error) {}
              //Google Analyatics Code End
              this.showLoader = false;
            } else {
              // this.showToast(response);
              this.productList = [];
              //Google Analyatics Code Start
              var MobileNo = null;
              if (
                this.localstorage.getItem('userData') != null ||
                this.localstorage.getItem('userData') != undefined
              ) {
                let userData = JSON.parse(
                  this.localstorage.getItem('userData')
                );
                MobileNo = userData.phone_number;
              }
              var Address = '';
              if (
                this.localstorage.getItem('address') != null ||
                this.localstorage.getItem('address') != undefined
              ) {
                let address = JSON.parse(this.localstorage.getItem('address'));
                Address =
                  address.address.street_address1 +
                  ', ' +
                  address.address.state +
                  ', ' +
                  address.address.district +
                  ', ' +
                  address.address.city +
                  ', ' +
                  address.address.postal_code;
              }
              try {
                this.googleAnlyatics.viewAllPartsFail(
                  'View_all_Parts_Fail_web',
                  Address,
                  'Durafit',
                  MobileNo
                );
              } catch (error) {}
              //Google Analyatics Code End
              this.showLoader = false;
              this.toastrService.error(response.data.msg);
            }
          },
          (error) => {
            this.showLoader = false;
            // this.toast.error(error.error)
            this.productList = [];

            this.result = true;
          }
        );
      }
      if (this.searchBy === 'kit') {
        let searchData = {};
        if (user && address) {
          searchData = {
            search_text: '',
            bs_model_name: '',
            pg_line: '',
            multi_pg_line: [],
            multi_bs_model_name: [],
            multi_dispgroup_name: [],
            model_cat: '',
            mod_uid: '',
            category_type: '',
            bom_id: '',
            durafit_parts: false,
            kit_parts: true,
            part_number_last_four: '',
            offset: page,
            // start_row:0,
            limit: 20,
            pl_id: '',
            // is_discount_needed: true,
            account_pk: this.account_pk,
            address_pk: this.address_pk,

            shipping_address_id: this.shippingaddressid,
            part_number: '',
            is_for_epc_data: false,
            is_for_discount: true,
            is_for_ecom_detail: false,
          };
        } else {
          searchData = {
            search_text: '',
            bs_model_name: '',
            pg_line: '',
            multi_pg_line: [],
            multi_bs_model_name: [],
            multi_dispgroup_name: [],
            model_cat: '',
            mod_uid: '',
            category_type: '',
            bom_id: '',
            durafit_parts: false,
            kit_parts: true,
            part_number_last_four: '',
            offset: page,
            // start_row:0,
            limit: 20,
            pl_id: '',
          };
        }
        this.result = false;
        this.productFilterData = [];
        this.showLoader = true;
        this.productService.get_part_search_md(searchData).subscribe(
          (response) => {
            this.result = true;
            this.productFilterData = [];
            if (response.success) {
              //Google Analyatics Code Start
              var MobileNo = null;
              if (
                this.localstorage.getItem('userData') != null ||
                this.localstorage.getItem('userData') != undefined
              ) {
                let userData = JSON.parse(
                  this.localstorage.getItem('userData')
                );
                MobileNo = userData.phone_number;
              }
              var Address = '';
              if (
                this.localstorage.getItem('address') != null ||
                this.localstorage.getItem('address') != undefined
              ) {
                let address = JSON.parse(this.localstorage.getItem('address'));
                Address =
                  address.address.street_address1 +
                  ', ' +
                  address.address.state +
                  ', ' +
                  address.address.district +
                  ', ' +
                  address.address.city +
                  ', ' +
                  address.address.postal_code;
              }
              try {
                this.googleAnlyatics.viewAllParts(
                  'View_all_Parts_web',
                  Address,
                  'Kit',
                  MobileNo
                );
              } catch (error) {}
              //Google Analyatics Code End

              this.showPagination =
                response.total_result === response.end ? true : false;
              this.page = response.end;
              this.pagestart = response.start;
              let t = +this.page - Math.abs(response.start - response.end);
              this.localstorage.setItem('prevOffset', '' + (t <= 0 ? 0 : t));
              this.localstorage.setItem('nextOffset', '' + this.showPagination);
              this.productList = response.data;
              this.productFilterData = response.data;
              //  this.page = response.data.end;
              this.showLoader = false;
            } else {
              // this.showToast(response);
              this.productList = [];
              //Google Analyatics Code Start
              var MobileNo = null;
              if (
                this.localstorage.getItem('userData') != null ||
                this.localstorage.getItem('userData') != undefined
              ) {
                let userData = JSON.parse(
                  this.localstorage.getItem('userData')
                );
                MobileNo = userData.phone_number;
              }
              var Address = '';
              if (
                this.localstorage.getItem('address') != null ||
                this.localstorage.getItem('address') != undefined
              ) {
                let address = JSON.parse(this.localstorage.getItem('address'));
                Address =
                  address.address.street_address1 +
                  ', ' +
                  address.address.state +
                  ', ' +
                  address.address.district +
                  ', ' +
                  address.address.city +
                  ', ' +
                  address.address.postal_code;
              }
              try {
                this.googleAnlyatics.viewAllPartsFail(
                  'View_all_Parts_Fail_web',
                  Address,
                  'Kit',
                  MobileNo
                );
              } catch (error) {}
              //Google Analyatics Code End
              this.showLoader = false;
              this.toastrService.error(response.data.msg);
            }
          },
          (error) => {
            // this.toast.error(error.error)
            this.productList = [];
            this.showLoader = false;
            this.result = true;
          }
        );
      }
      if (this.searchBy === 'prolife') {
        let prolifeData = {};
        if (user && address) {
          prolifeData = {
            search_text: '',
            bs_model_name: '',
            pg_line: '',
            multi_pg_line: [],
            multi_bs_model_name: [],
            pg: 'PG6',
            multi_dispgroup_name: [],
            model_cat: '',
            mod_uid: '',
            category_type: '',
            bom_id: '',
            durafit_parts: false,
            kit_parts: false,
            prolife_parts: true,
            part_number_last_four: '',
            offset: page,
            size: 20,
            //  is_discount_needed: true,
            account_pk: this.account_pk,
            // address_pk: this.address_pk,
            shipping_address_id: this.shippingaddressid,
            part_number: '',
            is_for_epc_data: false,
            is_for_discount: true,
            is_for_ecom_detail: false,
          };
        } else {
          prolifeData = {
            search_text: '',
            bs_model_name: '',
            pg_line: '',
            multi_pg_line: [],
            multi_bs_model_name: [],
            pg: 'PG6',
            multi_dispgroup_name: [],
            model_cat: '',
            mod_uid: '',
            category_type: '',
            bom_id: '',
            durafit_parts: false,
            kit_parts: false,
            prolife_parts: true,
            part_number_last_four: '',
            offset: page,
            size: 20,
            pl_id: '',
          };
        }
        this.result = false;
        this.productFilterData = [];
        this.showLoader = true;
        this.productService.get_part_search_md(prolifeData).subscribe(
          (response) => {
            this.result = true;
            if (response.success) {
              this.localstorage.setItem('pgdata', response.data);
              this.productFilterData = [];
              this.showPagination =
                response.total_results === response.end ? true : false;
              this.page = response.end;
              this.pagestart = response.start;
              let t = +this.page - Math.abs(response.start - response.end);
              this.localstorage.setItem('prevOffset', '' + (t <= 0 ? 0 : t));
              this.localstorage.setItem('nextOffset', '' + this.showPagination);
              this.productList = response.data;
              this.productList = response.data;
              this.productFilterData = response.data;
              //Google Analyatics Code Start
              var MobileNo = null;
              if (
                this.localstorage.getItem('userData') != null ||
                this.localstorage.getItem('userData') != undefined
              ) {
                let userData = JSON.parse(
                  this.localstorage.getItem('userData')
                );
                MobileNo = userData.phone_number;
              }
              var Address = '';
              if (
                this.localstorage.getItem('address') != null ||
                this.localstorage.getItem('address') != undefined
              ) {
                let address = JSON.parse(this.localstorage.getItem('address'));
                Address =
                  address.address.street_address1 +
                  ', ' +
                  address.address.state +
                  ', ' +
                  address.address.district +
                  ', ' +
                  address.address.city +
                  ', ' +
                  address.address.postal_code;
              }
              try {
                this.googleAnlyatics.viewAllParts(
                  'View_all_Parts_web',
                  Address,
                  'OIL & LUBRICANTS',
                  MobileNo
                );
              } catch (error) {}
              //Google Analyatics Code End
              this.showLoader = false;
              // this.page = response.data.end;
              // this.productList = response.data;
            } else {
              // this.showToast(response);
              this.productList = [];
              //Google Analyatics Code Start
              var MobileNo = null;
              if (
                this.localstorage.getItem('userData') != null ||
                this.localstorage.getItem('userData') != undefined
              ) {
                let userData = JSON.parse(
                  this.localstorage.getItem('userData')
                );
                MobileNo = userData.phone_number;
              }
              var Address = '';
              if (
                this.localstorage.getItem('address') != null ||
                this.localstorage.getItem('address') != undefined
              ) {
                let address = JSON.parse(this.localstorage.getItem('address'));
                Address =
                  address.address.street_address1 +
                  ', ' +
                  address.address.state +
                  ', ' +
                  address.address.district +
                  ', ' +
                  address.address.city +
                  ', ' +
                  address.address.postal_code;
              }
              try {
                this.googleAnlyatics.viewAllPartsFail(
                  'View_all_Parts_Fail_web',
                  Address,
                  'OIL & LUBRICANTS',
                  MobileNo
                );
              } catch (error) {}
              //Google Analyatics Code End
              this.showLoader = false;
              this.toastrService.error(response.data.msg);
            }
          },
          (error) => {
            this.showLoader = false;
            this.result = true;
            this.productList = [];
          }
        );
      }
      if (this.searchBy === 'oillubricants') {
        let oilLubricantData = {};
        if (user && address) {
          oilLubricantData = {
            search_text: '',
            bs_model_name: '',
            pg_line: '',
            multi_pg_line: [],
            multi_bs_model_name: [],
            pg: 'PG6',
            multi_dispgroup_name: [],
            model_cat: '',
            mod_uid: '',
            category_type: '',
            bom_id: '',
            durafit_parts: false,
            kit_parts: false,
            part_number_last_four: '',
            offset: page,
            // start_row:0,
            limit: 20,
            pl_id: '',
            //  is_discount_needed: true,
            account_pk: this.account_pk,
            // address_pk: this.address_pk,
            shipping_address_id: this.shippingaddressid,
            part_number: '',
            is_for_epc_data: false,
            is_for_discount: true,
            is_for_ecom_detail: false,
          };
        } else {
          oilLubricantData = {
            search_text: '',
            bs_model_name: '',
            pg_line: '',
            multi_pg_line: [],
            multi_bs_model_name: [],
            pg: 'PG6',
            multi_dispgroup_name: [],
            model_cat: '',
            mod_uid: '',
            category_type: '',
            bom_id: '',
            durafit_parts: false,
            kit_parts: false,
            part_number_last_four: '',
            offset: page,
            // start_row:0,
            limit: 20,
            pl_id: '',
          };
        }
        this.result = false;
        this.productFilterData = [];
        this.showLoader = true;
        this.productService.get_part_search_md(oilLubricantData).subscribe(
          (response) => {
            this.result = true;
            if (response.success) {
              this.localstorage.setItem('pgdata', response.data);
              this.productFilterData = [];
              this.showPagination =
                response.total_result === response.end ? true : false;
              this.page = response.end;
              this.pagestart = response.start;
              let t = +this.page - Math.abs(response.start - response.end);
              this.localstorage.setItem('prevOffset', '' + (t <= 0 ? 0 : t));
              this.localstorage.setItem('nextOffset', '' + this.showPagination);
              this.productList = response.data;
              this.productList = response.data;
              this.productFilterData = response.data;
              //Google Analyatics Code Start
              var MobileNo = null;
              if (
                this.localstorage.getItem('userData') != null ||
                this.localstorage.getItem('userData') != undefined
              ) {
                let userData = JSON.parse(
                  this.localstorage.getItem('userData')
                );
                MobileNo = userData.phone_number;
              }
              var Address = '';
              if (
                this.localstorage.getItem('address') != null ||
                this.localstorage.getItem('address') != undefined
              ) {
                let address = JSON.parse(this.localstorage.getItem('address'));
                Address =
                  address.address.street_address1 +
                  ', ' +
                  address.address.state +
                  ', ' +
                  address.address.district +
                  ', ' +
                  address.address.city +
                  ', ' +
                  address.address.postal_code;
              }
              try {
                this.googleAnlyatics.viewAllParts(
                  'View_all_Parts_web',
                  Address,
                  'OIL & LUBRICANTS',
                  MobileNo
                );
              } catch (error) {}
              //Google Analyatics Code End
              this.showLoader = false;
              // this.page = response.data.end;
              // this.productList = response.data;
            } else {
              // this.showToast(response);
              this.productList = [];
              //Google Analyatics Code Start
              var MobileNo = null;
              if (
                this.localstorage.getItem('userData') != null ||
                this.localstorage.getItem('userData') != undefined
              ) {
                let userData = JSON.parse(
                  this.localstorage.getItem('userData')
                );
                MobileNo = userData.phone_number;
              }
              var Address = '';
              if (
                this.localstorage.getItem('address') != null ||
                this.localstorage.getItem('address') != undefined
              ) {
                let address = JSON.parse(this.localstorage.getItem('address'));
                Address =
                  address.address.street_address1 +
                  ', ' +
                  address.address.state +
                  ', ' +
                  address.address.district +
                  ', ' +
                  address.address.city +
                  ', ' +
                  address.address.postal_code;
              }
              try {
                this.googleAnlyatics.viewAllPartsFail(
                  'View_all_Parts_Fail_web',
                  Address,
                  'OIL & LUBRICANTS',
                  MobileNo
                );
              } catch (error) {}
              //Google Analyatics Code End
              this.showLoader = false;
              this.toastrService.error(response.data.msg);
            }
          },
          (error) => {
            this.showLoader = false;
            this.result = true;
            this.productList = [];
          }
        );
      } else if (this.searchBy == 'description') {
        let searchData = {};
        if (user && address) {
          searchData = {
            search_text: this.searchValue,
            bs_model_name: '',
            pg_line: '',
            multi_pg_line: [],
            multi_bs_model_name: [],
            multi_dispgroup_name: [],
            model_cat: '',
            mod_uid: '',
            category_type: '',
            bom_id: '',
            durafit_parts: false,
            kit_parts: false,
            part_number_last_four: '',
            offset: page,
            limit: 20,
            pl_id: '',
            // is_discount_needed: true,
            account_pk: this.account_pk,
            //  address_pk: this.address_pk,
            shipping_address_id: this.shippingaddressid,
            part_number: '',
            is_for_epc_data: false,
            is_for_discount: true,
            is_for_ecom_detail: false,
          };
        } else {
          searchData = {
            search_text: this.searchValue,
            bs_model_name: '',
            pg_line: '',
            multi_pg_line: [],
            multi_bs_model_name: [],
            multi_dispgroup_name: [],
            model_cat: '',
            mod_uid: '',
            category_type: '',
            bom_id: '',
            durafit_parts: false,
            kit_parts: false,
            part_number_last_four: '',
            offset: page,
            limit: 20,
            pl_id: '',
          };
        }
        this.result = false;
        this.productFilterData = [];
        this.productService.get_part_search_md(searchData).subscribe(
          (response) => {
            this.result = true;
            this.productFilterData = [];
            if (response.success) {
              this.showPagination =
                response.total_result === response.end ? true : false;
              this.page = response.end;
              this.pagestart = response.start;
              let t = +this.page - Math.abs(response.start - response.end);
              this.localstorage.setItem('prevOffset', '' + (t <= 0 ? 0 : t));
              this.localstorage.setItem('nextOffset', '' + this.showPagination);
              // this.page = response.data.end;
              this.productList = response.data;
              this.productFilterData = response.data;
              //Google Analyatics Code Start
              var MobileNo = null;
              if (
                this.localstorage.getItem('userData') != null ||
                this.localstorage.getItem('userData') != undefined
              ) {
                let userData = JSON.parse(
                  this.localstorage.getItem('userData')
                );
                MobileNo = userData.phone_number;
              }
              var Address = '';
              if (
                this.localstorage.getItem('address') != null ||
                this.localstorage.getItem('address') != undefined
              ) {
                let address = JSON.parse(this.localstorage.getItem('address'));
                Address =
                  address.address.street_address1 +
                  ', ' +
                  address.address.state +
                  ', ' +
                  address.address.district +
                  ', ' +
                  address.address.city +
                  ', ' +
                  address.address.postal_code;
              }
              try {
                this.googleAnlyatics.descriptionSearch(
                  'Description_Search_web',
                  Address,
                  this.searchValue,
                  MobileNo
                );
              } catch (error) {}
              //Google Analyatics Code End
            } else {
              // this.showToast(response);
              this.productList = [];
              //Google Analyatics Code Start
              var MobileNo = null;
              if (
                this.localstorage.getItem('userData') != null ||
                this.localstorage.getItem('userData') != undefined
              ) {
                let userData = JSON.parse(
                  this.localstorage.getItem('userData')
                );
                MobileNo = userData.phone_number;
              }
              var Address = '';
              if (
                this.localstorage.getItem('address') != null ||
                this.localstorage.getItem('address') != undefined
              ) {
                let address = JSON.parse(this.localstorage.getItem('address'));
                Address =
                  address.address.street_address1 +
                  ', ' +
                  address.address.state +
                  ', ' +
                  address.address.district +
                  ', ' +
                  address.address.city +
                  ', ' +
                  address.address.postal_code;
              }
              try {
                this.googleAnlyatics.descriptionSearchFail(
                  'Description_Search_Fail_web',
                  Address,
                  this.searchValue,
                  MobileNo
                );
              } catch (error) {}
              //Google Analyatics Code End
              this.toastrService.error(response.data.msg);
            }
          },
          (error) => {
            this.showLoader = false;
            this.result = true;
            this.productList = [];
            this.toastrService.error(error.error.data.msg);
          }
        );
      }
      if (this.categoryType === 'PARTS CATEGORIES') {
        let partsCategories = {};
        if (user && address) {
          partsCategories = {
            search_text: '',
            bs_model_name: '',
            pg_line: this.searchBy,
            multi_pg_line: [],
            multi_bs_model_name: [],
            pg: '',
            multi_dispgroup_name: [],
            model_cat: '',
            mod_uid: '',
            category_type: '',
            bom_id: '',
            durafit_parts: false,
            kit_parts: false,
            part_number_last_four: '',
            offset: page,
            limit: 20,
            pl_id: '',
            account_pk: this.account_pk,
            shipping_address_id: this.shippingaddressid,
            part_number: '',
            is_for_epc_data: false,
            is_for_discount: true,
            is_for_ecom_detail: false,
          };
        } else {
          partsCategories = {
            search_text: '',
            bs_model_name: '',
            pg_line: this.searchBy,
            multi_pg_line: [],
            multi_bs_model_name: [],
            pg: '',
            multi_dispgroup_name: [],
            model_cat: '',
            mod_uid: '',
            category_type: '',
            bom_id: '',
            durafit_parts: false,
            kit_parts: false,
            part_number_last_four: '',
            offset: page,
            limit: 20,
            pl_id: '',
          };
        }
        this.result = false;
        this.productFilterData = [];
        this.productService.get_part_search_md(partsCategories).subscribe(
          (response) => {
            this.result = true;
            if (response.success) {
              this.productFilterData = [];
              this.showPagination =
                response.total_results === response.end ? true : false;
              this.page = response.end;
              this.pagestart = response.start;
              let t = +this.page - Math.abs(response.start - response.end);
              this.localstorage.setItem('prevOffset', '' + (t <= 0 ? 0 : t));
              this.localstorage.setItem('nextOffset', '' + this.showPagination);
              this.productList = response.data;
              this.productList = response.data;
              this.productFilterData = response.data;

              //Google Analyatics Code Start
              var MobileNo = null;
              if (
                this.localstorage.getItem('userData') != null ||
                this.localstorage.getItem('userData') != undefined
              ) {
                let userData = JSON.parse(
                  this.localstorage.getItem('userData')
                );
                MobileNo = userData.phone_number;
              }
              var Address = '';
              if (
                this.localstorage.getItem('address') != null ||
                this.localstorage.getItem('address') != undefined
              ) {
                let address = JSON.parse(this.localstorage.getItem('address'));
                Address =
                  address.address.street_address1 +
                  ', ' +
                  address.address.state +
                  ', ' +
                  address.address.district +
                  ', ' +
                  address.address.city +
                  ', ' +
                  address.address.postal_code;
              }
              try {
                this.googleAnlyatics.viewAllParts(
                  'View_all_Parts_web',
                  Address,
                  this.searchBy,
                  MobileNo
                );
              } catch (error) {}
              //Google Analyatics Code End
            } else {
              if (response.success == false) {
                this.toastrService.error(response.data.msg);
              }
              this.productList = [];
              //Google Analyatics Code Start
              var MobileNo = null;
              if (
                this.localstorage.getItem('userData') != null ||
                this.localstorage.getItem('userData') != undefined
              ) {
                let userData = JSON.parse(
                  this.localstorage.getItem('userData')
                );
                MobileNo = userData.phone_number;
              }
              var Address = '';
              if (
                this.localstorage.getItem('address') != null ||
                this.localstorage.getItem('address') != undefined
              ) {
                let address = JSON.parse(this.localstorage.getItem('address'));
                Address =
                  address.address.street_address1 +
                  ', ' +
                  address.address.state +
                  ', ' +
                  address.address.district +
                  ', ' +
                  address.address.city +
                  ', ' +
                  address.address.postal_code;
              }
              try {
                this.googleAnlyatics.viewAllPartsFail(
                  'View_all_Parts_Fail_web',
                  Address,
                  this.searchBy,
                  MobileNo
                );
              } catch (error) {}
              //Google Analyatics Code End
            }
          },
          (error) => {
            this.showLoader = false;
            this.result = true;
            this.productList = [];
          }
        );
      }
      if (this.categoryType === 'Model') {
        let populaModelData = {};
        if (user && address) {
          populaModelData = {
            search_text: '',
            bs_model_name: '',
            pg_line: '',
            multi_pg_line: [],
            multi_bs_model_name: [],
            pg: '',
            multi_dispgroup_name: [],
            model_cat: this.searchBy,
            mod_uid: '',
            category_type: '',
            bom_id: '',
            durafit_parts: false,
            kit_parts: false,
            part_number_last_four: '',
            offset: page,
            // start_row:0,
            limit: 20,
            pl_id: '',
            account_pk: this.account_pk,
            shipping_address_id: this.shippingaddressid,
            part_number: '',
            is_for_epc_data: false,
            is_for_discount: true,
            is_for_ecom_detail: false,
          };
        } else {
          populaModelData = {
            search_text: '',
            bs_model_name: '',
            pg_line: '',
            multi_pg_line: [],
            multi_bs_model_name: [],
            pg: '',
            multi_dispgroup_name: [],
            model_cat: this.searchBy,
            mod_uid: '',
            category_type: '',
            bom_id: '',
            durafit_parts: false,
            kit_parts: false,
            part_number_last_four: '',
            offset: page,
            // start_row:0,
            limit: 20,
            pl_id: '',
          };
        }
        this.result = false;
        this.productFilterData = [];
        this.productService.get_part_search_md(populaModelData).subscribe(
          (response) => {
            this.result = true;
            if (response.success) {
              this.productFilterData = [];
              this.showPagination =
                response.total_results === response.end ? true : false;
              this.page = response.end;
              this.pagestart = response.start;
              let t = +this.page - Math.abs(response.start - response.end);
              this.localstorage.setItem('prevOffset', '' + (t <= 0 ? 0 : t));
              this.localstorage.setItem('nextOffset', '' + this.showPagination);
              this.productList = response.data;
              this.productList = response.data;
              this.productFilterData = response.data;
              //Google Analyatics Code Start
              var MobileNo = null;
              if (
                this.localstorage.getItem('userData') != null ||
                this.localstorage.getItem('userData') != undefined
              ) {
                let userData = JSON.parse(
                  this.localstorage.getItem('userData')
                );
                MobileNo = userData.phone_number;
              }
              var Address = '';
              if (
                this.localstorage.getItem('address') != null ||
                this.localstorage.getItem('address') != undefined
              ) {
                let address = JSON.parse(this.localstorage.getItem('address'));
                Address =
                  address.address.street_address1 +
                  ', ' +
                  address.address.state +
                  ', ' +
                  address.address.district +
                  ', ' +
                  address.address.city +
                  ', ' +
                  address.address.postal_code;
              }
              try {
                this.googleAnlyatics.modelSearch(
                  'model_Search_web',
                  Address,
                  this.searchBy,
                  MobileNo
                );
              } catch (error) {}
              //Google Analyatics Code End
            } else {
              this.productList = [];
              //Google Analyatics Code Start
              var MobileNo = null;
              if (
                this.localstorage.getItem('userData') != null ||
                this.localstorage.getItem('userData') != undefined
              ) {
                let userData = JSON.parse(
                  this.localstorage.getItem('userData')
                );
                MobileNo = userData.phone_number;
              }
              var Address = '';
              if (
                this.localstorage.getItem('address') != null ||
                this.localstorage.getItem('address') != undefined
              ) {
                let address = JSON.parse(this.localstorage.getItem('address'));
                Address =
                  address.address.street_address1 +
                  ', ' +
                  address.address.state +
                  ', ' +
                  address.address.district +
                  ', ' +
                  address.address.city +
                  ', ' +
                  address.address.postal_code;
              }
              try {
                this.googleAnlyatics.modelSearchFail(
                  'model_Search_Fail_web',
                  Address,
                  this.searchBy,
                  MobileNo
                );
              } catch (error) {}
              //Google Analyatics Code End
            }
          },
          (error) => {
            this.result = true;
            this.productList = [];
          }
        );
      }
      if (this.searchBy == 'popularKits') {
        let searchData = {};
        if (user && address) {
          searchData = {
            search_text: '',
            bs_model_name: '',
            pg_line: this.kitName,
            multi_pg_line: [],
            multi_bs_model_name: [],
            multi_dispgroup_name: [],
            model_cat: '',
            mod_uid: '',
            category_type: '',
            bom_id: '',
            durafit_parts: false,
            kit_parts: false,
            part_number_last_four: '',
            offset: page,
            // start_row:0,
            limit: 20,
            pl_id: '',
            //is_discount_needed: true,
            account_pk: user.account_pk,
            //address_pk: this.address_pk,
            shipping_address_id: this.shippingaddressid,
            part_number: '',
            is_for_epc_data: false,
            is_for_discount: true,
            is_for_ecom_detail: false,
          };
        } else {
          searchData = {
            search_text: '',
            bs_model_name: '',
            pg_line: this.kitName,
            multi_pg_line: [],
            multi_bs_model_name: [],
            multi_dispgroup_name: [],
            model_cat: '',
            mod_uid: '',
            category_type: '',
            bom_id: '',
            durafit_parts: false,
            kit_parts: false,
            part_number_last_four: '',
            offset: page,
            // start_row:0,
            limit: 20,
            pl_id: '',
          };
        }
        this.productService.get_part_search_md(searchData).subscribe(
          (response) => {
            this.result = true;
            if (response.success) {
              this.productFilterData = [];
              this.showPagination =
                response.total_results === response.end ? true : false;
              this.page = response.end;
              this.pagestart = response.start;
              let t = +this.page - Math.abs(response.start - response.end);
              this.localstorage.setItem('prevOffset', '' + (t <= 0 ? 0 : t));
              this.localstorage.setItem('nextOffset', '' + this.showPagination);
              this.productList = response.data;
              this.productList = response.data;
              this.productFilterData = response.data;
              //Google Analyatics Code Start
              var MobileNo = null;
              if (
                this.localstorage.getItem('userData') != null ||
                this.localstorage.getItem('userData') != undefined
              ) {
                let userData = JSON.parse(
                  this.localstorage.getItem('userData')
                );
                MobileNo = userData.phone_number;
              }
              var Address = '';
              if (
                this.localstorage.getItem('address') != null ||
                this.localstorage.getItem('address') != undefined
              ) {
                let address = JSON.parse(this.localstorage.getItem('address'));
                Address =
                  address.address.street_address1 +
                  ', ' +
                  address.address.state +
                  ', ' +
                  address.address.district +
                  ', ' +
                  address.address.city +
                  ', ' +
                  address.address.postal_code;
              }
              try {
                this.googleAnlyatics.modelSearch(
                  'model_Search_web',
                  Address,
                  this.searchBy,
                  MobileNo
                );
              } catch (error) {}
              //Google Analyatics Code End
            } else {
              this.productList = [];
              //Google Analyatics Code Start
              var MobileNo = null;
              if (
                this.localstorage.getItem('userData') != null ||
                this.localstorage.getItem('userData') != undefined
              ) {
                let userData = JSON.parse(
                  this.localstorage.getItem('userData')
                );
                MobileNo = userData.phone_number;
              }
              var Address = '';
              if (
                this.localstorage.getItem('address') != null ||
                this.localstorage.getItem('address') != undefined
              ) {
                let address = JSON.parse(this.localstorage.getItem('address'));
                Address =
                  address.address.street_address1 +
                  ', ' +
                  address.address.state +
                  ', ' +
                  address.address.district +
                  ', ' +
                  address.address.city +
                  ', ' +
                  address.address.postal_code;
              }
              try {
                this.googleAnlyatics.modelSearchFail(
                  'model_Search_Fail_web',
                  Address,
                  this.searchBy,
                  MobileNo
                );
              } catch (error) {}
              //Google Analyatics Code End
            }
          },
          (error) => {
            this.result = true;
            this.productList = [];
          }
        );
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'getProducts(page)',
        page_name: 'PageCategoryComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  filter(event) {
    try {
      document.body.scrollTop = 0;
      if (event == 'next') {
        this.localstorage.setItem('prevOffset', this.page);
        document.body.scrollTop = 0;
      } else {
        let p = this.localstorage.getItem('prevOffset');
        document.body.scrollTop = 0;
        if (p) {
          if (parseInt(p) <= 20) {
            this.page = 0;
            this.localstorage.setItem('prevOffset', this.page);
          } else if (parseInt(p) >= 20) {
            this.page = this.page - this.pagestart;
            this.localstorage.setItem('prevOffset', this.page);
          } else {
            this.page = +p;
          }
        }
      }
      if (this.isApplyFilter) {
        this.applyFilter(this.filterArr, this.page);
      } else {
        this.getProducts(this.page);
      }
      window.scrollTo(0, 0);
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'filter(event)',
        page_name: 'PageCategoryComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  applyFilter(event, page = 0) {
    try {
      document.body.scrollTop = 0;
      var oils = '';
      this.filterArr = event;
      this.isApplyFilter = true;
      this.productList = false;
      if (this.searchBy === 'kit') {
        this.kit_parts = true;
        this.durafit_parts = false;
        this.category_type = '';
        this.search_text = '';
        this.partnumberlastfour = '';
      } else if (this.searchBy === 'durafit') {
        this.durafit_parts = true;
        this.kit_parts = false;
        this.category_type = '';
        this.search_text = '';
        this.partnumberlastfour = '';
      } else if (this.searchBy === 'oillubricants') {
        this.category_type = '';
        this.kit_parts = false;
        this.durafit_parts = false;
        this.search_text = '';
        this.partnumberlastfour = '';
        oils = 'PG6';
      } else if (this.searchBy === 'description') {
        this.category_type = '';
        this.kit_parts = false;
        this.durafit_parts = false;
        this.search_text = this.searchValue;
        this.partnumberlastfour = '';
      } else if (this.searchBy === 'partno') {
        (this.partnumberlastfour = this.searchValue), (this.category_type = '');
        this.kit_parts = false;
        this.durafit_parts = false;
        this.search_text = '';
      } else if (this.categoryType === 'PARTS CATEGORIES') {
        (this.partnumberlastfour = ''), (this.category_type = '');
        this.kit_parts = false;
        this.durafit_parts = false;
        this.search_text = '';
      } else if (this.aggType === 'aggregate') {
        this.searchValue = this.searchBy;
        (this.partnumberlastfour = ''), (this.category_type = '');
        this.kit_parts = false;
        this.durafit_parts = false;
        this.search_text = '';
      } else {
        this.kit_parts = false;
        this.durafit_parts = false;
        this.category_type = this.searchBy;
        this.search_text = '';
        this.partnumberlastfour = '';
      }
      let user = JSON.parse(this.localstorage.getItem('userData'));
      let address = JSON.parse(this.localstorage.getItem('address'));
      let searchData = {};
      if (user && address) {
        this.account_pk = +JSON.stringify(user.account_pk);
        if (address['type'] === 'default' && address['type'] !== null) {
          this.address_pk = address.type;
        } else {
          if (
            address.address.length === undefined ||
            address.address.length === 0
          ) {
            this.address_pk = address.address.id;
          } else {
            this.address_pk = address.address[0].id;
          }
        }
        searchData = {
          search_text: this.search_text,
          bs_model_name: '',
          pg_line: '',
          multi_pg_line: event.pgline ? event.pgline : '',
          multi_bs_model_name: event.basemodel ? event.basemodel : '',
          multi_dispgroup_name: event.dispgroup,
          model_cat: event.Mainmodel ? event.Mainmodel : '',
          mod_uid: '',
          category_type: this.category_type,
          bom_id: '',
          pg: oils ? oils : '',
          durafit_parts: this.durafit_parts,
          kit_parts: this.kit_parts,
          part_number_last_four: this.partnumberlastfour,
          // start_row: 0,
          offset: page,
          limit: 20,
          pl_id: '',
          // is_discount_needed: true,
          account_pk: this.account_pk,
          //  address_pk: this.address_pk,
          shipping_address_id: this.shippingaddressid,
          part_number: '',
          is_for_epc_data: false,
          is_for_discount: true,
          is_for_ecom_detail: false,
        };
      } else {
        searchData = {
          search_text: this.search_text,
          bs_model_name: '',
          pg_line: '',
          multi_pg_line: event.pgline ? event.pgline : '',
          multi_bs_model_name: event.basemodel ? event.basemodel : '',
          multi_dispgroup_name: event.dispgroup,
          model_cat: event.Mainmodel ? event.Mainmodel : '',
          mod_uid: '',
          category_type: this.category_type,
          bom_id: '',
          pg: oils ? oils : '',
          durafit_parts: this.durafit_parts,
          kit_parts: this.kit_parts,
          part_number_last_four: this.partnumberlastfour,
          // start_row: 0,
          offset: page,
          limit: 20,
          pl_id: '',
        };
      }
      this.result = false;
      this.productList = [];
      this.showLoader = true;
      this.productService.get_part_search_md(searchData).subscribe(
        (response) => {
          this.result = true;
          if (response.success) {
            this.showPagination =
              response.total_results === response.end ? true : false;
            this.page = response.end;
            this.pagestart = response.start;
            let t = +this.page - Math.abs(response.start - response.end);
            this.localstorage.setItem('prevOffset', '' + (t <= 0 ? 0 : t));
            this.localstorage.setItem('nextOffset', '' + this.showPagination);
            this.showLoader = false;
            this.productList = response.data;
            window.scrollTo(0, 0);
            //Google Analyatics Code Start
            var MobileNo = null;
            if (
              this.localstorage.getItem('userData') != null ||
              this.localstorage.getItem('userData') != undefined
            ) {
              let userData = JSON.parse(this.localstorage.getItem('userData'));
              MobileNo = userData.phone_number;
            }
            var Address = '';
            if (
              this.localstorage.getItem('address') != null ||
              this.localstorage.getItem('address') != undefined
            ) {
              let address = JSON.parse(this.localstorage.getItem('address'));
              Address =
                address.address.street_address1 +
                ', ' +
                address.address.state +
                ', ' +
                address.address.district +
                ', ' +
                address.address.city +
                ', ' +
                address.address.postal_code;
            }
            try {
              this.googleAnlyatics.viewAllParts(
                'View_all_Parts_web',
                Address,
                this.search_text,
                MobileNo
              );
            } catch (error) {}
            //Google Analyatics Code End
          } else {
            this.showLoader = false;
            this.toastrService.error(response.data.msg);
            this.productList = [];
            //Google Analyatics Code Start
            var MobileNo = null;
            if (
              this.localstorage.getItem('userData') != null ||
              this.localstorage.getItem('userData') != undefined
            ) {
              let userData = JSON.parse(this.localstorage.getItem('userData'));
              MobileNo = userData.phone_number;
            }
            var Address = '';
            if (
              this.localstorage.getItem('address') != null ||
              this.localstorage.getItem('address') != undefined
            ) {
              let address = JSON.parse(this.localstorage.getItem('address'));
              Address =
                address.address.street_address1 +
                ', ' +
                address.address.state +
                ', ' +
                address.address.district +
                ', ' +
                address.address.city +
                ', ' +
                address.address.postal_code;
            }
            try {
              this.googleAnlyatics.viewAllPartsFail(
                'View_all_Parts_Fail_web',
                Address,
                this.search_text,
                MobileNo
              );
            } catch (error) {}
            //Google Analyatics Code End
          }
        },
        (error) => {
          this.result = true;
          this.productList = [];
        }
      );
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'applyFilter(event, page = 0)',
        page_name: 'PageCategoryComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  showProductsFilter() {
    this.halfProductsList = [];
    this.AllProductsList = [];
    this.halfProducts = [];
    this.AllProducts = [];
    let json = '{"size":22}';
    this.showLoader = true;
    let user = JSON.parse(this.localstorage.getItem('userData'));
    let address = JSON.parse(this.localstorage.getItem('address'));
    if (this.searchBy !== 'oillubricants') {
      this.menu.GetPgLineList(json).subscribe(
        (data) => {
          if (data.success) {
            try {
              var datas = data.data.sort();
              for (var i = 0; i < datas.length; i++) {
                this.AllProducts.push(datas[i]);
                if (i < 4) {
                  this.halfProducts.push(datas[i]);
                }
              }
              this.halfProductsList = this.halfProducts;
              this.AllProductsList = this.AllProducts;
              this.showLoader = false;
            } catch (error) {
              this.userService.InsertExceptionLog({
                method_name: 'ShowMore(item)',
                page_name: 'MenuheaderComponent',
                portal_type: 'Customer',
                page_url: this.router.url,
                error_message: error.message,
                inner_exception: error,
              });
            }
          } else {
            this.showLoader = false;
          }
        },
        (error) => {
          this.showLoader = false;
        }
      );
    } else if (this.searchBy == 'oillubricants') {
      let oilLubricantData = {};
      if (user && address) {
        oilLubricantData = {
          search_text: '',
          bs_model_name: '',
          pg_line: '',
          multi_pg_line: [],
          multi_bs_model_name: [],
          pg: 'PG6',
          multi_dispgroup_name: [],
          model_cat: '',
          mod_uid: '',
          category_type: '',
          bom_id: '',
          durafit_parts: false,
          kit_parts: false,
          part_number_last_four: '',
          offset: 0,
          size: 20,
          pl_id: '',
          is_discount_needed: true,
          account_pk: this.account_pk,
          address_pk: this.address_pk,
        };
      } else {
        oilLubricantData = {
          search_text: '',
          bs_model_name: '',
          pg_line: '',
          multi_pg_line: [],
          multi_bs_model_name: [],
          pg: 'PG6',
          multi_dispgroup_name: [],
          model_cat: '',
          mod_uid: '',
          category_type: '',
          bom_id: '',
          durafit_parts: false,
          kit_parts: false,
          part_number_last_four: '',
          offset: 0,
          size: 20,
          pl_id: '',
        };
      }
      this.productService.get_part_search_md(oilLubricantData).subscribe(
        (response) => {
          if (response.success) {
            this.AllProductsList = [];
            let pglistdata = [];
            var datas = response.data.result.pg_line_list;
            for (var i = 0; i < datas.length; i++) {
              pglistdata.push(this.FormPgOilData(datas[i]));
            }
            for (let i = 0; i < pglistdata.length; i++) {
              this.AllProductsList.push({
                LINE_2_s: pglistdata[i].pg_line_name,
              });
            }
          } else {
          }
        },
        (error) => {}
      );
    }
  }

  FormPgOilData(data): PgLineData {
    const objdata: PgLineData = {} as PgLineData;
    objdata.pg_line_name = data.pg_line_name;
    return objdata;
  }

  viewMore() {
    this.showMore = !this.showMore ? true : false;
  }

  pgLineSelected(event, pgline) {
    try {
      this.searchValue = pgline;
      if (event.target.checked === true) {
        this.selectedPgLine = '';
        this.pgline = [];
        this.selectedPgLine = pgline;
        this.pgline.push(pgline);
        this.applyFilter({
          Mainmodel: this.Mainmodel,
          basemodel: this.basemodel,
          dispgroup: this.dispgroup,
          pgline: this.pgline,
        });
      } else {
        // this.selectedPgLine = ''
        // this.pgline = [];
        // this.selectedPgLine = this.searchBy
        // this.pgline.push(this.searchBy);
        // this.applyFilter(event)
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'pgLineSelected(event, pgline)',
        page_name: 'WidgetFiltersComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  filterData(event) {}

  GotoView(layoutMethod) {
    this.layout = layoutMethod;
  }
}

export interface PgLineData {
  pg_line_name: string;
}

