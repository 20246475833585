<div class="modal-content">
    <div class="modal-header modal-title">
        Add Account
        <button type="button" class="btn-close" (click)="closeModal()"></button>
    </div>
    <div class="modal-body">
        <div class="row">
            <form [formGroup]="userAccountForm" name="userAccountForm">
                <div class="search">
                    <label class="modal-title">Account ID</label><br /><br>
                    <input class="form-control" [(ngModel)]="accountid" formControlName="custid" maxlength="10" (keypress)="alphanumbericOnly($event)" type="search" placeholder="Account ID" />
                </div><br /><br>
                <div class="text-end">
                    <button type="button" class="btn btn-sm btn-primary" [disabled]="btndisabled" (click)="onSubmit()">Submit</button>
                </div>
            </form>
        </div>
    </div>
</div>

<ng-container *ngIf="showLoader">
    <div class="loader">
        <div class="ng-spinner ng-spinner-icon"></div>
    </div>
</ng-container>