import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { secureStorage } from '../../services/securestorage';
import { ProductService } from '../../services/REST/product.service';
import { UserService } from '../../services/REST/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-comboofferdetail',
  templateUrl: './comboofferdetail.component.html',
  styleUrls: ['./comboofferdetail.component.scss']
})
export class ComboofferdetailComponent implements OnInit {
  @Input() discountCode:any;
  marketDetailArr:any= [];
  slab_details:any = [];
  skuItemsArry:any = []
  @Output() closeModel = new EventEmitter<any>();
  @Input() modalDataa:any
  constructor(private localStorage:secureStorage, 
    private productService:ProductService, private userService:UserService, private router:Router ) { }

  ngOnInit(): void {
    this.fetchMarketBsketDetail()
  }
  fetchMarketBsketDetail(){
    let user = JSON.parse(this.localStorage.getItem('userData')); 
    let req = {
        "account_pk": parseInt(user.account_pk),
        "discount_code": this.discountCode,
        // data.discount_code
    };
    this.productService.getMarketbasketDetails(req).subscribe(
        (response) => {
          this.marketDetailArr = [];
          this.slab_details = [];
          let slab_master_detail = []
          this.marketDetailArr = response.data;
          this.marketDetailArr.forEach((ele) => {
            this.slab_details = ele.discount_slab_detail;
            slab_master_detail = ele.discount_master_detail;
          })
          this.skuItemsArry = slab_master_detail.reduce((accumulator, current) => {
            if (!accumulator.some(obj => obj.sku_item === current.sku_item)) {
                accumulator.push(current);
            }
            return accumulator;
        }, []);
        // console.log("remocoeemccmcmomce",this.skuItemsArry)
        })
  }
  routeToProduct(value){
    // debugger
    if (value.sku_item != 'ALL') {
      this.router.navigate(['/shop/product-details'], {
        queryParams: { part_Number: value.sku_item },
      }).then(res => {
        this.closeModel.emit()
      });
    }
  }
}
