<app-otp *ngIf="isOtpGenerated && !showVehicleDetails" [loginForm]="loginForm" (resendOtp)="resendOnSubmitOTP()"
    (enteredOtp)="setAndVerifyOtp($event)" [events]="eventsSubject.asObservable()" (nextClick)="verifyOTP()"
    [isTimer]="isTimer" (redirectToLogin)="isOtpGenerated = false;"></app-otp>
<section class="ng-sign p-sm-4 p-md-5" *ngIf="!isOtpGenerated && !isOtpVerified && !showVehicleDetails" [ngClass]="(showHeader) ? 'clsmargin' : ''">
    <div class="d-flex justify-content-center justify-content-sm-between login-wrapper">
        <div class="sec-logo d-none d-sm-block">
            <div class="mat-aap">
                <h3 class="mb-0">AAP Ke liye</h3>
                <h4 class="mb-0">Apno ke liye</h4>
            </div>
            <div class="benfits bg-white">
                <h5 class="mb-0">Benefits :</h5>
                <p class="mb-0">Higher stability | Long life | Value for money</p>
            </div>
        </div>
        <mat-card class="login-form-card p-5">
            <mat-card-header class="justify-content-center mb-4">
                <mat-card-title class="mat-login-header">
                    <div class="login-header-img text-end">
                        <img src="./assets/images/logo.png" class="img-fluid" />
                    </div>
                    <h1>e- Dukaan</h1>
                </mat-card-title>
                <mat-card-subtitle class="mat-subtitle">Sign in for eDukaan</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <form [formGroup]="loginForm" name="loginForm" class="form">
                    
                    <div class="form-group">
                        <label class="signinlabel">Sign in by</label>
                        <div class="some-class">
                            <input type="radio" class="radio" id="OTP" (click)="SetLogintype('OTP')"
                                formControlName="loginBy" value="otp">
                            <label for="OTP">OTP</label>
                            <input type="radio" class="radio" id="Password" (click)="SetLogintype('PASSWORD')"
                                formControlName="loginBy" value="Password">
                            <label for="Password">Password</label>
                        </div>
                        <mat-form-field appearance="standard" floatLabel="always" class="w-100">
                            <mat-label class="label-control">Mobile number</mat-label>
                            <input matInput placeholder="Enter mobile number" class="input-control" autocomplete="off"
                                formControlName="contact_no" maxlength="10" (keypress)="onlyNumberKey($event);"
                                (keyup.enter)="onSubmitOTP()">
                            <mat-error *ngIf="f.contact_no.errors !== null && f.contact_no.errors.required">Mobile
                                number is
                                required</mat-error>
                            <mat-icon matSuffix style="cursor: pointer;">info</mat-icon>
                        </mat-form-field>
                        <mat-form-field appearance="standard" floatLabel="always" class="w-100" *ngIf="loginForm.value.loginBy === 'Password'">
                            <mat-label class="label-control">Password</mat-label>
                            <input matInput [type]="hide ? 'password' : 'text'" maxlength="14" minlength="6"
                                formControlName="password" autocomplete="off" class="input-control" matInput
                                placeholder="Enter password" (keyup.enter)="onSubmitOTP()">
                            <mat-error *ngIf="f.password.errors !== null && f.password.errors.required">Password is
                                required</mat-error>
                            <mat-error *ngIf="f.password.errors !== null && f.password.errors.minlength">Password must
                                be at least 6 characters
                            </mat-error>
                            <mat-icon class="showcursor"  matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                                [attr.aria-pressed]="hide">{{!hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                        </mat-form-field>
                    </div>
                    <!-- <div class="form-group">
                        <mat-form-field appearance="standard" floatLabel="always" class="w-100">
                            <mat-label class="label-control">Mobile number</mat-label>
                            <input matInput placeholder="Enter mobile number" class="input-control" autocomplete="off"
                                formControlName="contact_no" maxlength="10" (keypress)="onlyNumberKey($event);"
                                (keyup.enter)="onSubmitOTP()">
                            <mat-error *ngIf="f.contact_no.errors !== null && f.contact_no.errors.required">Mobile
                                number is
                                required</mat-error>
                            <mat-icon matSuffix style="cursor: pointer;">info</mat-icon>
                        </mat-form-field>
                        <mat-form-field appearance="standard" floatLabel="always" class="w-100" *ngIf="loginForm.value.loginBy === 'Password'">
                            <mat-label class="label-control">Password</mat-label>
                            <input matInput [type]="hide ? 'password' : 'text'" maxlength="14" minlength="6"
                                formControlName="password" autocomplete="off" class="input-control" matInput
                                placeholder="Enter password" (keyup.enter)="onSubmitOTP()">
                            <mat-error *ngIf="f.password.errors !== null && f.password.errors.required">Password is
                                required</mat-error>
                            <mat-error *ngIf="f.password.errors !== null && f.password.errors.minlength">Password must
                                be at least 6 characters
                            </mat-error>
                            <mat-icon matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                                [attr.aria-pressed]="hide">{{!hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                        </mat-form-field>
                    </div> -->

                    <!-- <div class="form-group" *ngIf="loginForm.value.loginBy === 'Password'">
                        <mat-form-field appearance="standard" floatLabel="always" class="w-100">
                            <mat-label class="label-control">Password</mat-label>
                            <input matInput [type]="hide ? 'password' : 'text'" maxlength="14" minlength="6"
                                formControlName="password" autocomplete="off" class="input-control" matInput
                                placeholder="Enter password" (keyup.enter)="onSubmitOTP()">
                            <mat-error *ngIf="f.password.errors !== null && f.password.errors.required">Password is
                                required</mat-error>
                            <mat-error *ngIf="f.password.errors !== null && f.password.errors.minlength">Password must
                                be at least 6 characters
                            </mat-error>
                            <mat-icon matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                                [attr.aria-pressed]="hide">{{!hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                        </mat-form-field>
                    </div> -->
                    <!--  <div class="form-group">
                        <mat-form-field appearance="standard" floatLabel="always" class="w-100">
                            <mat-label class="label-control">Confirm password</mat-label>
                            <input matInput [type]="hide ? 'password' : 'text'" class="input-control">
                            <mat-icon matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide confirm password'" [attr.aria-pressed]="hide">{{hide ? 'visibility' :  'visibility_off'}}</mat-icon>
                          </mat-form-field>
                    </div> -->
                    <div class="text-center">
                        <button type="button" *ngIf="!ShowSigninButton" class="btn btn-primary text-uppercase login-btn"
                            (click)="onSubmitOTP()">SEND OTP</button>
                        <button type="button" *ngIf="ShowSigninButton" class="btn btn-primary text-uppercase login-btn"
                            (click)="onSubmitOTP()">Sign in</button>
                        <p class="mt-4 mb-0">Not Registered ? <a class="text-primary text-decoration-none"
                                [routerLink]="['/account/registration']" [queryParams]="{'isRegistration':true}">Click
                                here</a></p>
                        <p class="mt-4 mb-0">Go back to <a class="text-primary text-decoration-none" style="cursor: pointer;" (click)="goBackToHome();">Homepage</a></p>
                    </div>
                    <div class="fonr-group pt-2">
                        <p class="mt-2 mb-0" style="color: rgb(37, 150, 190)">Login made Easy - You may now use your Fleet Edge mobile number to login to eDukaan !</p>
                    </div>
                </form>

            </mat-card-content>
        </mat-card>

    </div>
</section>

<ng-template #selectUser let-d="dismiss">
    <select-user (closeUserPopUp)="closeUserPopUp()" [btndisabled]="btndisabled" [userIds]="userIds"
        (setselectedUser)="setselectedUser($event)" (bindUserAccounts)="bindUserAccounts($event)"></select-user>
</ng-template>

<ng-template #selectUserType let-d="dismiss">
    <select-user-type (bindUserAccounts)="bindUserAccounts($event)" [userIds]="userIds" [userTypes]="keys"></select-user-type>
</ng-template>

<ng-container *ngIf="showLoader">
    <div class="loader">
        <div class="ng-spinner ng-spinner-icon"></div>
    </div>
</ng-container>

<app-fleet-details *ngIf="showVehicleDetails && !isOtpGenerated" (closeVehicleDetails)="continueViaVehicleDetails()" [account_pk]="account_pk"
    [loginForm]="loginForm"></app-fleet-details>