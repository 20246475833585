<!-- <div class="call-btn help-btn">
  <img alt="Call for Help" src="../../../assets/images/call.png" class="img-fluid"/>
</div> -->

<app-query-form></app-query-form>


<footer class="footer-wrapper" *ngIf="!showHeader">
  <div class="container-fluid">
    <div class="row">
      <div class="col-10 col-sm-9 col-md-9 col-lg-10 col-xl-10">
        <div class="row">
          <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12">
            <h2 class="footer-title">e-Dukaan</h2>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-xl-2 col-lg-2">
            <ul class="menu-option">
              <li><a [routerLink]="['/company-informations/about-us']">About us</a></li>
              <li><a [routerLink]="['/company-informations/contact-us']">Contact us</a></li>
              <li><a [routerLink]="['/company-informations/feedback']">Feedback</a></li>
              <!-- <li><a [routerLink]="['/company-informations/disclaimer']">Disclaimer</a></li> -->
              <!-- <li><a [routerLink]="['/company-informations/legal']">Legal</a></li> -->
            </ul>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-xl-2 col-lg-2">
            <ul class="menu-option">
              <li><a [routerLink]="['/company-informations/privacy-policy']">Privacy Policy</a></li>
              <!-- <li><a [routerLink]="['/company-informations/shipping-policy']">Shipping Policy</a></li> -->
              <li><a [routerLink]="['/company-informations/refund-policy']">Refund Policy</a></li>
              <li><a [routerLink]="['/company-informations/terms-condition']">Terms & Conditions</a></li>
              <!-- <li><a [routerLink]="['/company-informations/connect-with-us']">Connect With Us</a></li> -->
            </ul>
          </div>
          <!-- <div class="col-6 col-sm-6 col-md-4 col-xl-2 col-lg-2">
            <img src="./assets/images/GPIPSA.png" class="img-fluid" />
          </div> -->
<!-- <div class="col-6 col-sm-6 col-md-4 col-xl-4 col-lg-4 mailing-list">
        <div class="mb-3">
          Join our mailing list for exclusive offers
        </div>
        <div>
          <div class="mailing-wrapper">
            <input type="email" placeholder="abc@xyz.com"/><button type="button"><i class="fas fa-arrow-right"></i></button>
          </div>
        </div>
      </div> -->
        </div>
      
      </div>
      <div class="col-2 col-sm-3 col-md-3 col-lg-2 col-xl-2 pe-0">         
        <!-- <img src="./assets/images/peacock_bottom.webp" class="img-fluid" style="max-height: 150px;"/> -->
       </div>
      
    </div>
    <!-- <div class="row">
      
    </div> -->
    <hr />
  
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <ul class="bottom-footer">
          <li>
            &copy; Copyright 2020 Tata Motors. All Rights Reserved. Legal Disclaimer Open Source License Disclosure &nbsp;
          </li>
          <li class="pl-4">
            Powered by  &nbsp;&nbsp;<a style="text-decoration: none;" href="https://crmdms@www.tatamotors.com/" target="_blank">TATA Motors Ltd</a>
          </li>
          <li class="ml-sm-auto">
            <a href="https://www.facebook.com/TataMotorsGroup/" target="_blank"><i alt="" class="fab fa-facebook-f fb-color"></i></a>
            <a href="https://twitter.com/TataMotors?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor" target="_blank"><img alt="Twitter" src="./assets/images/twitter.png"/></a>
            <a href="https://www.linkedin.com/company/tata-motors/?originalSubdomain=in" target="_blank"><img alt="LinkedIn" src="./assets/images/linkedIn.png"/></a>
            <a href="https://www.youtube.com/user/TataMotorsGroup" target="_blank"><img alt="YouTube" src="./assets/images/youtube.png"/></a>
            <a href="https://www.instagram.com/tatamotorsgroup/?hl=en" target="_blank"><img alt="Instagram" src="./assets/images/insta.png"/></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>