
<div class="modal-header modal-title">
   Alternate Part
   <div (click)="closeModal()" type="button"><i class="fa fa-times"></i></div>
</div>
<div class="modal-body">
  <div class="product-details-layout">
     <div class="container-fluid">
       <div class="box-prod-detail">
        <div class="row" *ngFor="let product of productList">
           <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div class="card shadow">
                 <div class="card-body">
                    <div class="row">
                       <div class="col-12 col-sm-12 col-md-5 col-lg-5 colxl-5 col-xxl-5">
                          <div class="slider-wrapper">
                             <ul class="slider-thumb">
                                <li class="type-image" *ngFor="let image of product?.multi_img;let i = index">
                                   <img [src]="image.img+'/resize/60x36'" onerror="this.src='./assets/tata-vehicle.jfif';"
                                      style="cursor: pointer;" class="img-fluid" alt="prod-img-alt" (mouseover)="selectImage(image.img,i,'partFifteen')"
                                      (click)="selectImg(image.img,i,'thumbnil')">
                                </li>
                             </ul>
                             <ul class="slider-preview">
                                <li class="type-image" *ngFor="let image of imageTempArray;let j = index">
                                   <img [src]="image" onerror="this.src='./assets/tata-vehicle.jfif';" class="img-fluid"
                                      alt="prod-img-alt">
                                </li>
                             </ul>
                             <ng-container  *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                             <div class="product-offer" *ngIf="normalPercentage > 0 && !isQuantityDiscountAvailable">
                               {{'SAVE ' + normalPercentage + '%'}}
                             </div>
                             <div class="product-offer" *ngIf="quantityBasedPercentage > 0 && isQuantityDiscountAvailable">
                               {{'SAVE ' + quantityBasedPercentage + '%'}}
                             </div>
                             </ng-container>
                             <!-- <div class="view360" (click)="getCompatibleModel(partNumber)" style="cursor: pointer;">
                                <img alt="scale2" src="./assets/images/scale.svg" />
                             </div> -->
                           
                          </div>
                       </div>
                       <div class="col-12 col-sm-6 col-md-5 col-lg-5 colxl-5 col-xxl-5 position-relative">
                          <div class="bookmark-share">
                             <div class="btn-grop">
                             </div>
                          </div>
                          <div class="prod-cont-details pe-5">
                             <div class="prod-cont-heading">
                              <div id="socialShare" class="btn-group share-group">
                                 <a data-toggle="dropdown" class="btn btn-info" style="border: 1px solid #000; border-radius: 8px; padding: 2px; margin-left: 5px;">
                                    <i class="fa fa-share-alt fa-inverse" data-toggle="tooltip" data-placement="top" title="Click here to share product"></i>
                                    <span style="margin-left: 5px;">share</span>
   
                                   </a>
                         <ul class="dropdown-menu p-2" style="min-width: 20px; z-index: 5;">
                             <li>
                               <div>
                                 <a data-toggle="tooltip" title="WhatsApp" target="_blank"
                                   rel="noopener noreferrer" (click)="share('whatsapp')" style="cursor: pointer;">
                                   <img alt="WhatsApp" src="./assets/icons/WhatsApp_icon.png" height="25px"/>
                                 </a>
                               </div>
                           </li>
                           <li>
                             <div>
                               <a data-toggle="tooltip" title="Twitter" (click)="share('Twitter')" style="cursor: pointer;"
                                 rel="noopener noreferrer">
                                 <img alt="Twitter" src="./assets/icons/twitter.png" height="22px" class="mt-3"/>
                               </a>
                             </div>
                           </li>					
                           <li>
                             <div>
                               <a data-toggle="tooltip" title="Facebook" (click)="share('Facebook')" style="cursor: pointer;"
                                 rel="noopener noreferrer">
                                 <img alt="Facebook" src="./assets/icons/Facebook.png" height="22px" class="mt-3"/>
                               </a>
                             </div>
                           </li>
                             <li>
                               <div>
                                 <a  data-toggle="tooltip" title="Mail" target="_blank" rel="noopener noreferrer" (click)="share('mail')">
                                   <img alt="Mail" src="./assets/icons/mail.png" height="22px" class="mt-3"/>
                                 </a>
                               </div>
                           </li>
                           <li>
                             <div>
                               <a data-toggle="tooltip" class="cursor-pointer" title="Copy link" style="cursor: pointer;"
                                 (click)="copyMessage()" target="_blank" rel="noopener noreferrer">
                                 <img alt="Copy link" src="./assets/icons/link.png" height="22px" class="mt-3"/>
                               </a>
                             </div>
                           </li>
                                 </ul>
                       </div>
                                <app-wishlist-icon [product]="product" [layout]="'Nolayout'"  [isWishlistedItem]="isWishlistedItem"></app-wishlist-icon>
                                <h6 class="ellipse-2line" style="cursor: pointer;" (click)="gotoProductDetails(product?.part_number)"
                                title="{{ (product.short_description == '' || product.short_description == null) ? '-' : product.short_description}}">
                                {{(product.short_description == " " || product.short_description == null) ? "-" :
                                product.short_description}}
                                </h6>
                                <h4 class="modal-no mb-3"  style="cursor: pointer;" (click)="gotoProductDetails(product?.part_number)">{{ product?.part_number }}</h4>
                                <div class="delivery-time w-30 d-inline-block" *ngIf="product?.hsn_code">
                                   HSN: <span class="fw-bold">{{ product?.hsn_code }}</span>
                                </div>
                                &nbsp;&nbsp;
                                <div class="delivery-time w-30 d-inline-block text-end" *ngIf="product?.tax_slab">
                                   GST: <span class="fw-bold">{{ product?.tax_slab }}%</span>
                                </div>
                             </div>
                             <p *ngIf="( product.resource_type == 'Pack' && product?.moq > 1)" class="tmgo mb-2">
                                <span class="save-mode">1 Unit contains {{product.moq | number: '1.0-0'}} <span *ngIf="product.moq > 1">Quantities</span><span *ngIf="product.moq <= 1">Quantity</span></span><br/>
                                (MRP is applicable for 1 Unit =
                                {{product.moq | number :'1.0-0'}} {{product.uom}}
                                )</p>
                                <ng-container *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true);else nslpartCondition">
                             <ng-container *ngIf="baseProductdiscount">
                                <div class="price mt-4" *ngIf="isNormalDiscount && Productdiscount < ProductMRP && !isQuantityDiscountAvailable;">
                                   {{ Productdiscount | currencyFormat :'INR' }}
                                   <span class="actual-mrp"> MRP {{ ProductMRP |
                                   currencyFormat :'INR' }} </span>
                                </div>
                                <div class="price mt-4" *ngIf="!isNormalDiscount && !isQuantityDiscountAvailable && quantityBasedPartPrice === 0;">
                                   {{ ProductMRP | currencyFormat :'INR' }}
                                </div>
                                <div class="price mt-4" *ngIf="!isNormalDiscount && !isQuantityDiscountAvailable && quantityBasedPartPrice > 0;">
                                   {{ ProductMRP | currencyFormat :'INR' }}
                                </div>
                                <div class="price mt-4" *ngIf="isQuantityDiscountAvailable && quantityBasedPartPrice > 0;">
                                   {{ quantityBasedPartPrice | currencyFormat :'INR' }}
                                   <span class="actual-mrp"> MRP {{ ProductMRP |
                                   currencyFormat :'INR' }} </span>
                                </div>
                             </ng-container>
                             <ng-container *ngIf="!baseProductdiscount">
                                <div class="price mt-4">
                                   <i></i>{{ ProductMRP | currencyFormat :'INR' }}
                                </div>
                             </ng-container>
                           </ng-container>
                           <ng-template #nslpartCondition>
                              <div class="price mt-4">
                                <i></i>{{ ProductMRP | currencyFormat :'INR' }}
                                <!-- <span class="ms-3">MRP 12,290.00 </span> -->
                              </div>
                            </ng-template>
                            <ng-container *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                         <div class="save-mode"
                          *ngIf="isLoggedIn && savedAmount > 0 && isQuantityDiscountAvailable;else normalDiscountTemplate">
                          You save {{ savedAmount | currencyFormat :'INR' }} </div>
                          <ng-template #normalDiscountTemplate>
                            <div class="save-mode"
                            *ngIf="( ProductMRP > Productdiscount && Productdiscount > 0 && (ProductMRP - Productdiscount)) > 0 ">
                            You save {{ (ProductMRP - Productdiscount) | currencyFormat :'INR' }} </div>
                          </ng-template>
                          <div style="margin-top: 10px;" *ngIf="product?.loyalty_accrual?.point_percentage && (Productdiscount / 100 * product.loyalty_accrual.point_percentage) >= 0.5 && product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                           <span  class="save-mode">On purchase of this part, you are eligible to earn up to</span><span class="save-mode" style="margin-left: 5px;font-weight: bold;">{{Productdiscount / 100 * product.loyalty_accrual.point_percentage | number:'1.0-0'}}</span>
                           <span style="margin-left: 5px;"  class="save-mode">loyalty points, So start earning reward points today</span>
                         </div>
                        </ng-container>
                         <div class="mt-3" *ngIf="isLoggedIn && isUserType?.user_type == 'RT'">
                           <p class="pin mb-0" style="color: #417be2;font-weight: 700;"><span style="font-weight: 600;">Discount Category</span>&nbsp;:&nbsp;{{product?.discount_code_cvbu}}</p>  
                         </div>
                             <div class="deliv-sec_label mt-2 mb-2 text-nowrap" *ngIf="etaList.length > 0 && product?.is_nls != 'in_active' && !product?.alternate_part">
                                <span class="mt-2 mat-location-type" style="font-weight: 700;">Expected Delivery Timeline&nbsp;:&nbsp;
                                  <span class="save-mode">{{ etaList[0].ETA | date:'dd MMMM yyyy' }}</span>
                                </span>
                              </div>
                             <div class="deliv-sec mt-2 mb-3">
                                <p class="pin mb-0" *ngIf="isLoggedIn">Delivery Pincode: <span
                                   class="ms-3">{{pinCodeNumber}}</span> <a class="ms-3 text-decoration-none"
                                   (click)="viewAddressModal()" style="cursor: pointer;">
                                   Change</a>
                                </p>
                             </div>
                             <ng-container  *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                              <!-- (click)="openSchemeListModal('otherOffer')" -->
                             <span *ngIf="(validSchemeList && validSchemeList.length > 0) || (discountOfferData && discountOfferData.length > 0)"  style="cursor: pointer; display: flex;margin-bottom: 1rem;">
                              <img width="25px" height="25px" src="assets/images/offerg-1550058133.png"> 
                              <p class="use-coupon-text"> View Offers</p>
                            </span>
                            </ng-container>
                             <h4 class="mb-0 mt-2 mat-location-type pb-2" *ngIf="isLoggedIn || baseProductdata?.division_info">Distributor Details:</h4>
                             <p class="mat-location-name" *ngIf="isLoggedIn || baseProductdata?.division_info">{{baseProductdata?.division_info?.ORG_NAME}}</p>
                             <p class="mat-deliver-address" *ngIf="isLoggedIn || baseProductdata?.division_info">
                                {{ (baseProductdata?.division_info?.DIV_NAME != '' && baseProductdata?.division_info?.DIV_NAME != null && baseProductdata?.division_info?.DIV_NAME != undefined) ? (baseProductdata?.division_info?.DIV_NAME + ',') : ''}}
                                {{ (baseProductdata?.division_info?.PRIMISE_ADDRLINE1_s != '' && baseProductdata?.division_info?.PRIMISE_ADDRLINE1_s != null && baseProductdata?.division_info?.PRIMISE_ADDRLINE1_s != undefined) ? (baseProductdata?.division_info?.PRIMISE_ADDRLINE1_s + ',') : ''}}
                                {{ (baseProductdata?.division_info?.PRIMISE_ADDRESS2_s != '' && baseProductdata?.division_info?.PRIMISE_ADDRESS2_s != null && baseProductdata?.division_info?.PRIMISE_ADDRESS2_s != undefined) ? (baseProductdata?.division_info?.PRIMISE_ADDRESS2_s + ',') : ''}}
                             </p>
                             <ng-container  *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                            <span *ngIf="showQuantityDiscountMessage" class="text-decoration-none save-mode">
                              <span
                              class="highlights-header" *ngIf="!isshowMoreSlabDiscount">
                                  <div class="main-card">
                                    <div class="small-card">
                                      <span
                                          class="highlights-header p-2">Buy More Save More</span>
                                    </div>
                                    <div class="card-content">
                                      <span *ngIf="showQuantityDiscountMessage" class="text-decoration-none save-mode"><span
                                        class="highlights-header" *ngIf="!isshowMoreSlabDiscount"><span style="color: #1d3087;" *ngIf="( product.resource_type == 'Pack' )">ORDER
                                          {{(discountOffers?.quantity / product.moq) | number}} UNIT, GET
                                          {{((quantityBasedPercentage === NaN) ? 0 : quantityBasedPercentage) + '%'}} OFF</span>
                                          <span style="color: #1d3087;" *ngIf="!(product.resource_type == 'Pack' )">ORDER
                                            {{discountOffers?.quantity | number}} QUANTITY, GET
                                            {{((quantityBasedPercentage === NaN) ? 0 : quantityBasedPercentage) + '%'}} OFF</span> <br></span>
                                          </span>
                                    </div>
                                </div>
                              </span>
                                  <span
                                  class="highlights-header" *ngIf="isshowMoreSlabDiscount">
                                  <div class="main-card">
                                    <div class="small-card-2">
                                      <span
                                          class="highlights-header p-2">Buy More Save More</span>
                                    </div>
                                    <div class="card-content">
                                      <span *ngIf="showQuantityDiscountMessage" class="text-decoration-none save-mode">
                                            <span
                                            class="highlights-header" *ngIf="isshowMoreSlabDiscount"><span style="color: #1d3087;cursor: pointer;" >Quantity Slab </span><span><a (click)="openSchemeListModal('slabOffer')" style="float: right; cursor: pointer; color: blue;">View</a></span></span>
                                          </span>
                                    </div>
                                </div>
                              </span>
                              </span>
                              </ng-container>
                             <div class="d-flex pt-4 pb-4 quantity-sec align-items-center"
                             *ngIf="(userData?.user_type == 'MECH' && (product.inventory_quantity > 0 && product.inventory_quantity != null && convertToInt(product.moq) <= product.inventory_quantity) && product.is_nls != 'in_active' && (product?.alternate_part?false:true)) || (userData?.user_type != 'MECH' && product.is_nls != 'in_active' && (product?.alternate_part?false:true)) ">
                                <span class="mat-del" *ngIf="(product.resource_type == 'Pack') && product?.uom">UNIT: &nbsp;</span>
                                <app-input-number aria-label="Quantity" size="lg" [min]="1" [isTmgoValue]="(product.resource_type == 'Pack')?product.moq:'1'"
                                (currentQuantity)="readQuantity($event,product,true)" [defaultValue]="product?.min_quantity" [userType]="userData" [inventoryQty]="product.inventory_quantity">
                                </app-input-number>
                                <span class="mat-del" *ngIf="(product.resource_type == 'Pack' && product.moq > 1) && product?.uom">&nbsp;&nbsp;QUANTITY:
                                &nbsp;</span>
                                <input class="qty-input input-number__input ipunitquantity" type="text"
                                   *ngIf="(product.resource_type == 'Pack' && product.moq > 1)" size="lg" disabled
                                   value="{{ currentQuantity ? product?.moq * currentQuantity : 0 }}" maxlength="50">
                             </div>
                             <span class="error-message" *ngIf="(product.resource_type == 'Pack'  && product.moq > 1) && product?.uom">Note: </span>
                             <span class="mat-del" *ngIf="(product.resource_type == 'Pack'  && product.moq > 1) && product?.uom">1 unit will be equal to
                                {{product?.moq | number: '1.0-0'}} {{ product.uom }}</span>
                                <span class="error-message mt-4" *ngIf="partLimitCheck.length !=0 && isLoggedIn">Max quantity allowed is {{partLimitCheck[0]?.available_qty}}<br></span>
                                <ng-container *ngIf="userData?.user_type == 'MECH'">
                                 <ng-container *ngIf="product.inventory_quantity > 0 && product.moq < product.inventory_quantity">
                                    <a class="btn btn-primary btn--add-cart mt-2" role="button" (click)="addToCart(product)"
                                    *ngIf="userData?.user_type != 'MECH' && product.is_nls != 'in_active' && (product?.alternate_part?false:true) "> Add To Cart</a><br>
                                 </ng-container>
                                 <ng-container  *ngIf="(product.inventory_quantity <= 0 || product.inventory_quantity == null || product.moq > product.inventory_quantity) && product.is_nls != 'in_active' && (product?.alternate_part?false:true)" style="margin-left: auto;">
                                    <h6 style="font-size: 13px;
                                    position: relative;
                                    font-weight: 700;
                                    color: red;
                                    top: 5px;text-align: start;">Out Of Stock</h6>
                                </ng-container>

                                </ng-container>
                             <a class="btn btn-primary btn--add-cart mt-2" role="button" (click)="addToCart(product)"
                             *ngIf="userData?.user_type != 'MECH' && product.is_nls != 'in_active' && (product?.alternate_part?false:true) "> Add To Cart</a><br>
                                <span class="error-message-service text-nowrap" *ngIf="product?.is_nls == 'in_active'">No
                                Longer Serviced...</span>
                                <span class="error-message-service text-nowrap" *ngIf="product?.is_nls != 'in_active' && (product?.alternate_part?true:false) ">No
                                   Longer Available...</span>
                                <!-- <div class="col-12 view-alternate text-nowrap"
                                   *ngIf="(product?.part_status == 'active' || product?.part_status == 'in_active') && alternateProductsList.length > 0 || ( product?.alternate_part && alternatePartData )"
                                   (click)="showRelatedList(product.alternate_part)">
                                   <span>View Alternate Part</span>
                                </div> -->
                          <a class="btn btn-primary btn--add-cart secondbtn" role="button" (click)="ViewChildPart()"
                          *ngIf="btnshowChildParts"> Show Child Parts</a>
                          <div class="part-description mt-4" *ngIf="product?.large_description != '' && product?.large_description != null && product?.large_description != undefined">
                             <h5>Part description</h5>
                             <p>{{ product?.large_description }} </p>
                          </div>
                       </div>
                    </div>
                    <!-- <div class="col-12 col-sm-6 col-md-3 col-lg-3 colxl-3 col-xxl-3 border-start">
                       <h5 class="highlights">Highlights</h5>
                       <ul class="lists-highlights">
                          <li>We have catalogue for each part we deliver</li>
                          <li>Purchase Parts at Discounted Rates</li>
                          <li>Part Recommendation to match your requirements</li>
                          <li>Get timely delivery of your order</li>
                       </ul>
                       <div class="form-highlights-ques" *ngIf="isLoggedIn">
                          <h5 class="highlights"> Do you have questions about this product ?</h5>
                          <textarea class="form-control fs-6" [(ngModel)]="userQuery" id="type-que" rows="3"
                          placeholder="Type your question..."></textarea>
                          <div class="text-end mt-2">
                             <a class="text-decoration-none text-uppercase btn-anchor" role="button"
                                (click)="onSubmitQuery(product?.part_number);">Submit</a>
                          </div>
                       </div>
                       <div class="form-highlights-ques" *ngIf="!isLoggedIn">
                          <h5 class="highlights">Do you have questions about this product ?<br>Please contact TML on
                             020-67136236 or
                          </h5>
                             <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=edukaansupport@tatamotors.com"
                                target="_blank" style="text-decoration: none;">edukaansupport@tatamotors.com</a>
                          </div>
                       </div> -->
                    </div>
                 </div>
              </div>
           </div>
       </div>
       </div>
     </div>
  </div>
</div>
<!-- <div class="modal-footer">
   <button type="button" class="btn btn-primary">
       &nbsp;Continue</button>
</div> -->
<ng-template #addressModal let-d="dismiss" style="width: auto !important;">
  <delivery-address (closemodal)="closeAdressModal()" [isProductDetails]="isProductDetails"></delivery-address>
</ng-template>
<ng-template #confimationmodal let-d="dismiss" style="width: auto !important;">
  <app-selectCartType [btnshowConfrimed]="btnshowConfrimed" [currentlyAddingProduct]="currentlyAddingProduct"
    [btnshowsubmit]="btnshowsubmit" [cartTypeList]="cartTypeList" [baseProductdata]="baseProductdata"
    (setCartType)="setAddCart($event)" (selectedCartType)="ShowPart()" (inputChange)="changeCartType($event)"
    (closeModal)="closePart()">
  </app-selectCartType>
</ng-template>
<ng-template #schemeList let-d="dismiss">
   <app-product-details-schemes (closeModel)="schemeListModal.hide()" [slabOffer]="discountSlabOffer" [schemeDetails]="validSchemeList" [offerDetails]="discountOfferData"
   [popupOpenType]="popupOpenType"></app-product-details-schemes>
 </ng-template>
 <ng-container *ngIf="showLoader">
   <div class="loader">
     <div class="ng-spinner ng-spinner-icon"></div>
   </div>
 </ng-container>