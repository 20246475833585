import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe, formatDate } from '@angular/common';
import { EtaExcelCreatorServiceService } from 'src/app/shared/services/eta-excel-creator-service.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/shared/services/REST/user.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { secureStorage } from 'src/app/shared/services/securestorage';
import { BsModalService } from 'ngx-bootstrap/modal';
import { blockPermissionMsg } from 'src/app/constant';
import { AppStateService } from "src/app/shared/services/app-state/app-state.service";
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'eta-report',
  templateUrl: './eta-report.component.html',
  styleUrls: ['./eta-report.component.scss'],
})
export class EtaReportComponent implements OnInit {
  txtcustomer_name: string;
  EtareportForm: FormGroup;
  today: Date;
  filteredOptions: any;
  DistributorData: any;
  filterValue: any;
  options: string[] = [];
  currentPage = 1;
  etaFromDate: any;
  etaToDate: any;
  etaFromDate1: any;
  etaToDate1: any;
  showLoader: boolean;
  etaData: any[];
  totalrecord: any;
  txtdistributor_name: any = '';
  myControl = new FormControl();
  isFormValid: boolean = true;

  // Donwload Report
  DataPrepareArray: any;
  inprocesstoDate: any;
  inprocessfromDate: any;
  inprocesstoDate1: any;
  inprocessfromDate1: any;
  showOrders:boolean = false;

  // eta order detail 

  orderInformations: any;
  orderDetailsModal: any;
  @ViewChild('orderDetails', { read: TemplateRef, static: false })
  orderDetails: TemplateRef<any>;
  isReport:boolean = false;
  isOrderInProcessSelected:boolean = false;
  isFeedBackSelected:boolean = false;
  isOTCSelected = false;
  isReturn: boolean = false;
  isCancelOrderSelected: boolean = false;
  isActivePage: boolean = false;

  public dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  newRequestBody: any;
  datatsetempty = false;
  dtOptions: DataTables.Settings = {};

  reportsubscription: any;
  downloadoncall:boolean = false;
  userDetails: any;

  constructor(
    private fb: FormBuilder,
    private datepipe: DatePipe,
    private excelCreatorService: EtaExcelCreatorServiceService,
    private router: Router,
    private toastrService: ToastrService,
    private userService: UserService,
    private modalService: BsModalService,
    private localstorage: secureStorage,
    private appStateService: AppStateService,
    private title:Title
) {}
  ngOnInit() {
    this.today = new Date();
    this.buildForm();
    this.loadData();
    this.title.setTitle('E-Dukaan | Eta Report');
    this.userDetails = JSON.parse(this.localstorage.getItem('userData'));
  }

  loadData() {
    try {
      if (
        this.EtareportForm.value.start &&
        this.EtareportForm.value.end &&
        !(this.EtareportForm.value.end >= this.EtareportForm.value.start)
      ) {
        this.toastrService.error(
          'To date should be greater than or equal to From date'
        );
        return;
      }
      if (
        this.EtareportForm.value.start &&
        this.EtareportForm.value.end &&
        !(this.EtareportForm.value.end >= this.EtareportForm.value.start)
      ) {
        this.toastrService.error(
          'To date should be greater than or equal to From date'
        );
        return;
      } else if (
        this.EtareportForm.value.start &&
        !this.EtareportForm.value.end
      ) {
        this.isFormValid = false;
        this.toastrService.error('To date is required');
        return;
      } else if (
        !this.EtareportForm.value.start &&
        this.EtareportForm.value.end
      ) {
        this.isFormValid = false;
        this.toastrService.error('From date is required');
        return;
      } else {
        this.isFormValid = true;
      }
      if (this.EtareportForm.value.start) {
        this.etaFromDate = new Date(this.EtareportForm.value.start);
        this.etaFromDate1 = formatDate(this.etaFromDate, 'yyyy-MM-dd', 'en-US');
      }
      if (this.EtareportForm.value.end) {
        this.etaToDate = new Date(this.EtareportForm.value.end);
        this.etaToDate1 = formatDate(this.etaToDate, 'yyyy-MM-dd', 'en-US');
      }
      let user = JSON.parse(this.localstorage.getItem('userData'));
      if (user) {
        if (user && user.account_pk && this.isFormValid) {
          this.calldatatable();
        }
      }
    } catch (error) {
      this.showLoader = false;
      this.userService.InsertExceptionLog({
        method_name: 'loadData(page)',
        page_name: 'OrderTrackingReportComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  calldatatable() {
    let user = JSON.parse(this.localstorage.getItem('userData'));
    if (user && user.account_pk) {
      this.newRequestBody = {
        account_pk: '' + user.account_pk,
        size: 10,
        otc_order_number: this.EtareportForm.value.otc_number
          ? this.EtareportForm.value.otc_number.trim()
          : '',
        order_number: this.EtareportForm.value.order_number
          ? this.EtareportForm.value.order_number.trim()
          : '',
        part_number: this.EtareportForm.value.part_number
          ? this.EtareportForm.value.part_number.trim()
          : '',
        distributor_name: this.EtareportForm.value.distributor_name
        ? this.EtareportForm.value.distributor_name
        : '',
        from_date: this.etaFromDate1 ? this.etaFromDate1 : '',
        to_date: this.etaToDate1 ? this.etaToDate1 : '',
      };
      this.showLoader = true;
      this.dtOptions = {
        pagingType: 'full_numbers',
        responsive: true,
        serverSide: true,
        processing: true,
        lengthChange: false,
        searching: false,
        pageLength: 10,
        ordering: false,
        scrollX: true,
        language: {
          info: 'Showing _START_ to _END_ of _TOTAL_ entries',
          infoEmpty: 'No records available',
          // infoFiltered: ' ',
        },
        ajax: (dataTablesParameters: any, callback) => {
          if (this.isFormValid) {
            this.newRequestBody['offset'] = dataTablesParameters.start;

            this.userService.GetEtaList(this.newRequestBody).subscribe(
              (response) => {
                if (response.success) {
                  this.showLoader = false;
                  this.etaData = response.data;

                  this.totalrecord = response.total_count;
                } else if (response.success == false) {
                  this.totalrecord = 0;
                  this.etaData = [];
                  if (response.data.msg == 'Data not found') {
                  }
                  // this.toastrService.error(response.data.msg);
                } else {
                  this.totalrecord = 0;
                  this.etaData = [];
                  if (response.data.msg == 'Data not found') {
                  }

                  this.toastrService.error(response.data.msg);
                }
                this.showLoader = false;

                callback({
                  recordsTotal: this.totalrecord,
                  recordsFiltered: this.totalrecord,
                  data: [],
                });
              },
              (err) => {
                this.showLoader = false;
              }
            );
          }
        },
        columns: [
          {
            data: 'otc_order_number',
            cellType: 'th/td',
            className: 'sticky-col first-col',
            width: '170px',
          },
          { data: 'order_number', width: '145px' },
          { data: 'distributor_name', width: '195px' },
          { data: 'part_number', width: '130px' },
          { data: 'part_desc', width: '240px' },
          { data: 'order_quantity' ,width: '100px' },
          { data: 'order_date' ,width: '100px' },
          { data: 'delivery_date' },
        ],
      };
    }
  }

  details(order, type) {
    let permission = this.userService.CheckPermission(
      'btn_view_order_tracking'
    );
    if (permission) {
      order.order_date = order.ord_date;
      this.orderInformations = order;
      if (type == 'isEtaReport') {
        this.isReport = true;
        this.isOrderInProcessSelected = false;
        this.isFeedBackSelected = false;
        this.isOTCSelected = false;
        this.isReturn = false;
        this.isCancelOrderSelected = false;
        this.isActivePage = false;
      }
      if (order.otc_order_number != null) {
        this.orderDetailsModal = this.modalService.show(this.orderDetails, {
          class: 'modal-dialog-centered modal-xl',
          backdrop: 'static',
        });
      }
    } else {
      this.toastrService.error(blockPermissionMsg);
    }
  }

  buildForm() {
    this.txtdistributor_name = '';
    this.txtcustomer_name = '';
    this.EtareportForm = this.fb.group({
      start: ['', [Validators.required]],
      end: ['', [Validators.required]],
      otc_number: ['', [Validators.required]],
      order_number: ['', [Validators.required]],
      part_number: ['', [Validators.required]],
      distributor_name: ['', [Validators.required]],
      customer_name: ['', [Validators.required]],
    });
    let user = JSON.parse(this.localstorage.getItem('userData'));
    if (user) {
      const data: InputData = {} as InputData;
      data.account_pk = user.account_pk;
      data.size = 10;
      data.org_search_text = '';
      this.GetDistributor(data);
    }
  }

  GetDistributor(Data) {
    this.userService.DistributorList(Data).subscribe(
      (data) => {
        if (data.success == true) {
          this.DistributorData = data.data.result;
          this.filteredOptions = data.data.result;
        } else {
          this.toastrService.error(data.data.msg);
        }
      },
      (err) => {
        this.toastrService.error(err.error.data.msg);
      }
    );
  }

  downloadExcel() {
    this.DownloadReport();
  }

  async DownloadReport() {
    try {
      this.showLoader = true;
      this.DataPrepareArray = [];
      if (
        this.EtareportForm.value.start &&
        this.EtareportForm.value.end &&
        !(
          this.EtareportForm.value.end >=
          this.EtareportForm.value.start
        )
      ) {
        return this.toastrService.error(
          'To date should be greater than or equal to From date'
        );
      }
      if (this.EtareportForm.value.start) {
        this.inprocessfromDate = new Date(
          this.EtareportForm.value.start
        );
        this.inprocessfromDate1 = formatDate(
          this.inprocessfromDate,
          'yyyy-MM-dd',
          'en-US'
        );
      }
      if (this.EtareportForm.value.end) {
        this.inprocesstoDate = new Date(this.EtareportForm.value.end);
        this.inprocesstoDate1 = formatDate(
          this.inprocesstoDate,
          'yyyy-MM-dd',
          'en-US'
        );
      }

      this.showOrders = false;
      this.showLoader = true;
      let user = JSON.parse(this.localstorage.getItem('userData'));
      if (user) {
        if (user && this.totalrecord > 0) {
          var Size = 20;
          var offset = 0;
          var rou = Math.ceil(this.totalrecord / Size);
          for (let j = 0; j < rou; j++) { 
            if(user && user.account_pk){
              let response =  await this.userService
              .downloadEtaReport({
  
                account_pk: '' + user.account_pk,
                size: Size,
                offset: offset,
                otc_order_number: this.EtareportForm.value.otc_number
                  ? this.EtareportForm.value.otc_number.trim()
                  : '',
                order_number: this.EtareportForm.value.order_number
                  ? this.EtareportForm.value.order_number.trim()
                  : '',
                part_number: this.EtareportForm.value.part_number
                  ? this.EtareportForm.value.part_number.trim()
                  : '',
                distributor_name: this.EtareportForm.value.distributor_name
                ? this.EtareportForm.value.distributor_name
                : '',
                from_date: this.etaFromDate1 ? this.etaFromDate1 : '',
                to_date: this.etaToDate1 ? this.etaToDate1 : '',
                })
                if (response['success'] === true) {
                  let data = response['data'];
                  for (let i = 0; i < response['data'].length; i++) {
                          const ListInput: FinalData = {} as FinalData;
                          ListInput.OTC_No = data[i].otc_order_number;
                          ListInput.Order_no = data[i].order_number;
                          ListInput.Distributor_name = data[i].distributor_name;
                          ListInput.Part_No = data[i].part_number;
                          ListInput.Part_Desc = data[i].part_desc;
                          ListInput.Quantity = Number(data[i].total_quantity).toString();
                          ListInput.Order_Date = this.datepipe.transform(data[i].order_date, 'dd-MM-yyyy hh:mm:ss a'); //  this.datepipe.transform(data[i].order_date, 'dd-MM-yyyy')//data[i].order_date
                          ListInput.Expected_Delivery_Date =this.datepipe.transform(data[i].delivery_date, 'dd-MM-yyyy');
                          ListInput.SortDate = data[i].order_date;
                          this.DataPrepareArray.push(ListInput);
                        }
                }else {
                  this.toastrService.error(response['data']['msg']);
                }
            }
            offset = offset + Size;
          }
          this.showLoader = false;
          if (this.DataPrepareArray.length == this.totalrecord) {
            this.DataPrepareArray.sort((a: any, b: any) => {
              return Date.parse(b.SortDate) - Date.parse(a.SortDate);
            });
            this.DataPrepareArray.forEach(function (x) {
              delete x.SortDate;
            });
          }
          this.excelCreatorService.exportAsExcelFileVehicleStatus(
            this.DataPrepareArray,
            'ETAReport'
          );
          
        }else {
          this.showLoader = false;
          if(this.totalrecord === 0){
            this.toastrService.error("List is empty");
          }
        }
        this.downloadoncall = false;
          (<HTMLInputElement>document.getElementById('selectexceloption')).value =
            'Select';
  
          this.appStateService.downloadExcelType.next('Select');
      }
    } catch (error) {
      this.showLoader = false;
      this.userService.InsertExceptionLog({
        method_name: 'DownloadReport()',
        page_name: 'ETAReportComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  

  applyETAReportsFilter(formControl) {
    if (
      (formControl.value !== '' &&
        formControl.value !== null &&
        formControl.value !== undefined) ||
      formControl == 'Global'
    ) {
      this.currentPage = 1;
      this.currentPage = 1;
      this.loadData();
      this.rerender();
    }
  }

  reset() {
    this.etaFromDate = '';
    this.etaFromDate1 = '';
    this.etaToDate = '';
    this.etaToDate1 = '';
    this.txtdistributor_name = '';
    this.txtcustomer_name = '';
    this.EtareportForm.reset();
    this.buildForm();
    this.loadData();
    this.rerender();
  }

  _filterDistributor(value) {
    let user = JSON.parse(this.localstorage.getItem('userData'));
    if (user) {
      this.filterValue = value;
      if (this.filterValue == '') {
        this.filterValue = null;
      }
      const data: InputData = {} as InputData;
      data.account_pk = user.account_pk;
      data.size = 10;
      data.org_search_text = this.filterValue;
      this.GetDistributor(data);
      return this.options.filter((option) =>
        option.toLowerCase().includes(this.filterValue)
      );
    }
  }

  filterMyOptionsDistributor(Event) {
    if (this.localstorage.getItem('userData')) {
      let user = JSON.parse(this.localstorage.getItem('userData'));
      this.myControl.setValue('');
      var distlist = this.DistributorData.filter(
        (a) => a.distributor_id == Event
      );
      this.txtdistributor_name = distlist[0].distributor_name;
      this.filterValue = Event;
      const data: InputData = {} as InputData;
      data.distributor_id = Event;
      data.account_pk = user.account_pk;
      data.size = 100;
      data.org_search_text = '';
      this.GetDistributor(data);
    }
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }
}


export class InputData {
    account_pk: number
    org_search_text: string
    distributor_id: string
    offset: number
    size: number
}
export class InputData2 {
    account_name: string
}
export interface FinalData {
  Total_Inoviced_QTY: String;
  Cancelled: String;
  Delivered: String;
  Out_For_Delivery_Delivery: String;
  Invoiced_but_not_dispatched: String;
  Pending: String;
  Quantity: String;
  shipped_quantity: String;
  Part_Status: String;
  Part_Desc: String;
  Part_No: String;
  Order_Amount: String;
  Order_Date: String;
  OTC_No: String;
  Order_Status: String;
  Order_no: String;
  Distributor_name: String;
  State: String;
  Month_Year: String;
  SortDate: String;
  Expected_Delivery_Date:string;

    // OTC_No: String
    // Order_No: String
    // Customer_Name: String
    // Distributor_Name: String
    // Part_No: String
    // Part_Desc: String
    // Quantity: String
    // Order_date: String
    // SortDate: any
    // Expected_Delivery_date: String
}