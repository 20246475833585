<div class="modal-content">
    <div class="modal-header modal-title">
        Add Contact Person 
        <button type="button" class="btn-close" (click)="closeModal.emit()"></button>
    </div>
    <div class="modal-body">
        <div class="row">
            <form [formGroup]="addContactPersonForm">
                <div class="row pb-3">
                    <div class="form-group col-sm-12 col-md-6 col-lg-6">
                        <label for="" class="pb-2">First Name<span class="error-message">*</span></label> &nbsp;&nbsp;
                        <input type="text" maxlength="50" formControlName="first_name"
                            (keypress)="alphanumbericOnly($event)" class="form-control" placeholder="Enter First Name"
                            [ngClass]="{ 'is-invalid': submitted && f.first_name.errors  }">
                        <div class="error-message" *ngIf="hasPatternError('first_name')"><b> &nbsp;
                                *invalid</b></div>
                        <div class="text-end">
                        </div>
                    </div>
                    <div class="form-group col-sm-12 col-md-6 col-lg-6">
                        <label for="user-last-name" class="pb-2">Last Name<span class="error-message">*</span></label> &nbsp;&nbsp;
                        <input type="text" maxlength="50" formControlName="last_name" class="form-control"
                            (keypress)="alphanumbericOnly($event)" id="user-Last-Name" placeholder="Enter Last Name"
                            [ngClass]="{ 'is-invalid': submitted && f.last_name.errors  }">
                        <div class="error-message" *ngIf="hasPatternError('last_name')"><b> &nbsp;
                                *invalid</b></div>
                    </div>
                </div>
                <div class="row pb-3">
                    <div class="form group col-sm-12 col-md-6 col-lg-6">
                        <label for="user-contact" class="pb-2">Contact No<span class="error-message">*</span></label>
                        <input type="text" formControlName="contact_no" class="form-control" maxlength="10"
                            (keypress)="numberOnly($event)" id="user-Phone_Number" placeholder="Enter Contact No"
                            [ngClass]="{ 'is-invalid': submitted && f.contact_no.errors  }">
                        <div class="error-message"><b> &nbsp;{{patternErrMsgPhone}}</b></div>
                    </div>
                    <div class="form group col-sm-12 col-md-6 col-lg-6">
                        <label for="user-contact" class="pb-2">Alternate Contact No</label>
                        <input type="text" formControlName="alt_contact_no" class="form-control" maxlength="10"
                            (keypress)="numberOnly($event)" id="user-Phone_Number" placeholder="Enter Contact No"
                            [ngClass]="{ 'is-invalid': submitted && f.alt_contact_no.errors  }">
                        <div class="error-message"><b> &nbsp;{{patternErrMsgPhoneAlt}}</b></div>
                    </div>
                </div>
                <br>
                <b *ngIf="!issubmit && submitted">
                    All fields marked with 
                    <span class="error-message">*</span> 
                    are mandatory.
                </b><br>
                <button type="button" class="btn btn-sm btn-primary" [ngStyle]="{'float':'right'}" (click)="onSubmit()">Submit</button>
            </form>
        </div>
    </div>
</div>

<ng-container *ngIf="showLoader">
    <div class="loader">
        <div class="ng-spinner ng-spinner-icon"></div>
    </div>
</ng-container>