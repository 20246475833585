import {
  Component,
  ViewChild,
  TemplateRef,
  OnInit,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/shared/services/REST/user.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ProductService } from '../../services/REST/product.service';
import { StarRatingComponent } from 'ng-starrating';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
//import { MatDialogConfig, MatDialog } from '@angular/material';
//import { EDTpopupComponent } from 'src/app/shared/components/edtpopup/edtpopup.component';
declare var $: any;

import { blockPermissionMsg, cancelOrderReasons } from 'src/app/constant';
import { secureStorage } from '../../services/securestorage';
import { AppStateService } from '../../services/app-state/app-state.service';
import * as CryptoJS from "crypto-js";
@Component({
  selector: 'order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent implements OnInit {
  blockPermissionMsg = blockPermissionMsg;
  isshowEtaValidation: boolean = false;
  partStatus: any;
  totalpartCount: any = 0;
  partConfirmedCount: any = 0;
  //@ViewChild("etamodal", { read: TemplateRef, static: false }) etatemplate: TemplateRef<any>;
  etamodalRef: any;
  OrderDetailData: any;
  isshowOrderDetails: boolean = false;
  modalRef: any;
  //@ViewChild('modal', { read: TemplateRef, static: false }) template: TemplateRef<any>;
  total_tml_rule_discount: any;
  total_dealer_rule_discount: any;
  total_welcome_discount:any;
  total_coupon_discount:any;
  paymentvai: any;
  @Input() order: any;
  total: any;
  total1: any;
  total2: any;
  basediscount: any;
  schemediscount: any;
  tax: any;
  orderTotal: any;
  discountedprice: any;
  lineItemdiscount: any;
  FinalPrice1: any;
  TotalTax1: any;
  LineQty: any;
  subTotal: number;
  baseDiscount: number;
  taxTotal: number;
  FinalGross: number;
  orderFinalTotal: number;
  @Output() closemodal = new EventEmitter<any>();
  grandTotal: number = 0;
  @Input() isFeedBackSelected: boolean = false;
  @Input() isOTCSelected: boolean = false;
  @Input() isReturn: boolean = false;
  @Input() isOrderInProcessSelected: boolean = false;
  @Input() isCancelOrderSelected: boolean = false;
  @Input() isInvoiceReturnSelected: boolean = false;

  partsToReturn = [];
  sumOfParts: number = 0;
  orderCancelForm: FormGroup;
  showOther: boolean = false;
  @ViewChild('cancelOrder', { read: TemplateRef, static: false })
  template: TemplateRef<any>;
  orderCancel: boolean = false;
  showLoader: boolean = false;
  DataPushArray = [];
  chkService: boolean = false;
  isSelected = {};
  isSelectedAll: boolean = false;
  isDisabledchk: boolean = false;
  returnOrderList: any[] = [];
  returnOrderSumAmount: number = 0;
  otcOrderSubmit: number = 0;
  feedbackOrderSubmit: number = 0;
  showPaymentDetails: boolean = false;
  paymentHistory = [];
  PaymentDetailData = [];
  CardData = [];
  billingAddress: any;
  shippingAddress: any;
  showGatewayDetails: boolean = false;
  cancelOrderParts = [];
  urlData: string;
  isActiveOrders: boolean;
  isOrderInProcess: boolean;
  isEComOrders: boolean;
  isCRMOrders: boolean;
  @Input() isActivePage: boolean = false;
  ActiveOrderSumAmount: number = 0;
  @Input() isReport: boolean = false;
  @Input() isActiveReturns: boolean = false;
  starclick: string;
  currentRate = 0;
  Feedbackform: FormGroup;
  checkedcolor: string;
  uncheckedcolor: string;
  size: string;
  readonly: boolean;
  submitted: boolean = false;
  cancelOrderBillingAddress: any;
  cancelOrderShippingAddress: any;
  @Input() orderValue: any = '';
  adjustamt: number = 0;
  isAllChecked: boolean = false;
  makeAllDisabled: boolean = false;
  cancelledPartsCount: number = null;
  cancellablePartsCount: number = null;
  userDetails: any;
  total_cash_discount_amount: number = 0;
  isOrderQuantityDiscountBased: boolean = false;
  cancelReasons:string[]=cancelOrderReasons;
  private watchId: number | null = null;
  errorMessage: string | null = null;
  showRequestButton: boolean = false;

  constructor(
    private toastrService: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private modalService: BsModalService,
    private productService: ProductService,
    private localstorage: secureStorage,
    private appStateService: AppStateService,
  ) {}

  ngOnInit() {
    this.isOrderQuantityDiscountBased = false;
    this.showLoader = true;
    this.userDetails = JSON.parse(this.localstorage.getItem('userData'));
    if (this.isReport) {
      if (this.order.otc_number || this.order.otc_order_number) {
        let otc_no = this.order.otc_number || this.order.otc_order_number;
        this.getReportOrderDetail(otc_no);
      }
    }
    if (this.isReturn) {
      let orderNumber = this.order.order_number;
      this.getPaymentDetails(orderNumber);
      this.orderCancelForm = this.formBuilder.group({
        ReasonRepalcement: ['', Validators.required],
        OtherReason: [''],
      });
    } else if (this.isCancelOrderSelected || this.isInvoiceReturnSelected) {
      this.getCancelOrderDetail(this.order.cancel_order_number);
    }
    //this.openmodel();
    this.getLocation()
    $.getJSON('https://api.db-ip.com/v2/free/self', function (data) {
      if (data.ipAddress) {
        let valuenew = CryptoJS.AES.encrypt(data.ipAddress, "chair@tata$987");
        localStorage.setItem('ipAddress', valuenew.toString());
      }
    });
  }

  // openmodel() {
  //     $('.datatable').DataTable({
  //         "dom": 't<"bottom"ipl><"clear">',
  //         'aoColumnDefs': [{
  //             'bSortable': true,
  //             'aTargets': [-1] /* 1st one, start by the right */,
  //             paging: false,
  //             searching: false
  //         }]
  //     });
  // }

  getReportOrderDetail(otcNumber) {
    // console.log(otcNumber);
    try {
      if (this.localstorage.getItem('userData')) {
        this.partStatus = '';
        this.totalpartCount = 0;
        this.partConfirmedCount = 0;
        let requestBody = {
          account_pk: JSON.parse(this.localstorage.getItem('userData'))
            .account_pk,
          otc_number: otcNumber,
        };
        this.showLoader = true;
        this.userService.OrderDetailsForOTC(requestBody).subscribe(
          (response) => {
            if (response['success']) {
              let order = response['data'][0];
              if (order) {
                this.order = order;
                //this.totalpartCount = order[0].line_items.length;
                this.basediscount = 0;
                this.total_tml_rule_discount = 0;
                this.total_dealer_rule_discount = 0;
                this.total_cash_discount_amount = 0;
                this.total_welcome_discount = 0;
                this.total_coupon_discount = 0;
                this.tax = 0;
                this.schemediscount = 0;
                this.total = 0;
                this.total1 = 0;
                this.orderTotal = 0;
                if (
                  this.order.order_line_items &&
                  this.order.order_line_items.length
                ) {
                  this.order.order_line_items.forEach((product) => {
                    this.basediscount += product.base_discount_amount;
                    this.schemediscount += product.scheme_discount_amount;
                    this.total_tml_rule_discount += product.tml_rule_discount_amount;
                    this.total_dealer_rule_discount += product.dealer_rule_discount_amount;
                    this.total_welcome_discount += product.distributor2_discount_amnt;
                    this.total_cash_discount_amount += product.cash_discount_amount; 
                    this.tax += product.total_tax;
                    this.FinalPrice1 = product.gross_amount;
                    this.TotalTax1 = product.total_tax;
                    this.LineQty = product.quantity;

                    if (
                      product.gross_amount === undefined ||
                      product.gross_amount === '' ||
                      isNaN(product.gross_amount)
                    ) {
                      this.FinalPrice1 = 0;
                    }

                    if (
                      product.total_tax === undefined ||
                      product.total_tax === '' ||
                      isNaN(product.total_tax)
                    ) {
                      this.TotalTax1 = 0;
                    }

                    if (
                      product.quantity === undefined ||
                      product.quantity === '' ||
                      isNaN(product.quantity )
                    ) {
                      this.LineQty = 0;
                    }
                    this.total1 +=
                      this.FinalPrice1 -
                      this.TotalTax1 * this.LineQty +
                      product.base_discount_amount;

                    this.total +=
                      product.item_final_amount -
                      product.total_tax +
                      product.total_tax * product.quantity;
                    this.total = Math.round(this.total * 100) / 100;
                  });
                  this.total_coupon_discount = this.order.flat_discount;
                  this.orderTotal = this.total - this.basediscount + this.tax;
                  this.calculation();
                }
              } else {
                this.toastrService.error('No Record Found');
              }
            } else {
              if (
                response['data'].msg ==
                'Your access is restricted, please contact Organisation Manager.'
              ) {
                setTimeout(() => {
                  this.showLoader = false;
                  this.closemodal.emit(
                    'Your access is restricted, please contact Organisation Manager.'
                  );
                }, 300);
              } else {
                this.showLoader = false;
                this.toastrService.error(response['data'].msg);
              }
            }
            this.showLoader = false;
          },
          (error) => {
            this.toastrService.error(error.error.data.msg);
            this.showLoader = false;
          }
        );
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'getOrderDetail(orderNumber)',
        page_name: 'PageOrderDetailsComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  getPaymentDetails(orderNumber) {
    try {
      if (this.localstorage.getItem('userData')) {
        this.returnOrderSumAmount = 0;
        let user = JSON.parse(this.localstorage.getItem('userData'));
        let data = {
          account_pk: '' + user.account_pk,
          order_number: orderNumber,
          action_type: 'Cancelled',
        };
        this.showLoader = true;
        this.userService.returnOrderDetails(data).subscribe(
          (response) => {
            if (response.success) {
              this.order['coupon_code'] = response.data.coupon_code;
              this.returnOrderList = !this.isActiveReturns
                ? response.data.order_lines
                : response.data.order_lines.filter((item) => {
                    return item.part_number == this.order.part_number;
                  });
              for (let i = 0; i < this.returnOrderList.length; i++) {
                this.returnOrderList[i].isChecked = false;
                if(this.returnOrderList[i]?.invoiced_quantity > 0 && (this.returnOrderList[i]?.tml_discount_type === 'quantity_discount' || this.returnOrderList[i]?.dealer_discount_type === 'quantity_discount')){
                  this.isOrderQuantityDiscountBased = true;
                }
              }
              //this.order = response.data;
              this.returnOrderSumAmount = parseFloat(
                response.data.total_amount
              );
              this.showLoader = false;
              this.checkAllCancellled(this.returnOrderList);
            } else {
              this.toastrService.error(response.data.msg);
              this.showLoader = false;
            }
            this.showLoader = false;
          },
          (error) => {
            this.toastrService.error(error.error.data.msg);
            this.showLoader = false;
          }
        );
      } else if (
        this.localstorage.getItem('userData') &&
        this.isOrderInProcessSelected
      ) {
        this.PaymentDetailData = [];
        this.CardData = [];
        let user = {
          account_pk:
            '' + JSON.parse(this.localstorage.getItem('userData')).account_pk,
          order_number: orderNumber,
        };
        this.showLoader = true;
        this.userService.PaymentDetails(user).subscribe(
          (response) => {
            if (response.success) {
              this.PaymentDetailData = response.data;
              if (response.data.order_address.length > 0) {
                this.shippingAddress = response.data.order_address.filter(
                  (add) => add.address_type === 'shipping_address'
                )[0];
                this.billingAddress = response.data.order_address.filter(
                  (add) => add.address_type === 'billing_address'
                )[0];
              }
              if (
                (this.shippingAddress == undefined ||
                  this.shippingAddress == null ||
                  this.shippingAddress == '') &&
                (this.billingAddress == undefined ||
                  this.billingAddress == null ||
                  this.billingAddress == '')
              ) {
                this.shippingAddress = response.data.order_address[0];
                this.billingAddress = response.data.order_address[0];
              }
              var gateway = this.PaymentDetailData['gateway_detail'];
              if (gateway.length > 0) {
                this.showGatewayDetails = true;
                if (this.PaymentDetailData['gateway_detail'][0].card) {
                  var jsonObj: any = JSON.parse(
                    this.PaymentDetailData['gateway_detail'][0].card
                  );
                  this.CardData.push(jsonObj);
                }
              } else {
                this.showGatewayDetails = false;
              }
              this.showLoader = false;
            } else {
              this.showLoader = false;
              // this.toastrService.error(response.data.msg);
            }
            this.showLoader = false;
          },
          (error) => {
            this.showLoader = false;
          }
        );
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'getOrderDetail(orderNumber)',
        page_name: 'PageOrderDetailsComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  cancelOorderCalculation() {
    try {
      this.subTotal = 0;
      this.baseDiscount = 0;
      this.schemediscount = 0;
      this.taxTotal = 0;
      this.FinalGross = 0;
      this.total_tml_rule_discount = 0;
      this.total_dealer_rule_discount = 0;
      this.total_welcome_discount = 0;
      this.total_coupon_discount = 0;
      this.order.order_cancelation_line.forEach((element) => {
        this.subTotal =
          Number(this.subTotal) + Number(element.rate * element.total_quantity);
        this.baseDiscount =
          Number(this.baseDiscount) +
          Number(element.base_discount_amount * element.total_quantity);
        this.schemediscount =
          Number(this.schemediscount) +
          Number(element.scheme_discount_amount * element.total_quantity);
        this.taxTotal = Number(this.taxTotal) + Number(this.getTax(element));
        this.FinalGross =
          Number(this.FinalGross) + Number(element.GROSS_AMOUNT);
        this.total_tml_rule_discount =
          Number(this.total_tml_rule_discount) +
          Number(element.tml_rule_discount_amount * element.total_quantity);
        this.total_dealer_rule_discount =
          Number(this.total_dealer_rule_discount) +
          Number(element.dealer_rule_discount_amount * element.total_quantity);
        this.total_welcome_discount = 
          Number(this.total_welcome_discount) + 
          Number(element.distributor2_discount_amnt * element.total_quantity);
      });
      this.total_coupon_discount = this.order.flat_discount;
      this.orderFinalTotal =
        this.subTotal -
        this.baseDiscount -
        this.schemediscount -
        this.total_tml_rule_discount -
        this.total_dealer_rule_discount -  
        this.total_coupon_discount -
        this.total_welcome_discount +
        this.taxTotal;
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'calculation()',
        page_name: 'PageOrderCancelDetailsComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error,
        inner_exception: '',
      });
    }
  }

  calculation() {
    this.subTotal = 0;
    this.baseDiscount = 0;
    this.schemediscount = 0;
    this.taxTotal = 0;
    this.FinalGross = 0;
    this.total_tml_rule_discount = 0;
    this.total_dealer_rule_discount = 0;
    this.total_welcome_discount = 0 ;
    this.total_coupon_discount = 0;
    this.total_cash_discount_amount = 0;
    this.order.order_line_items.forEach((element) => {
      this.subTotal = Number(this.subTotal) + Number(element.rate * element.quantity);
      this.baseDiscount = Number(this.baseDiscount) + Number(element.base_discount_amount * element.quantity);
      this.schemediscount = Number(this.schemediscount) + Number(element.scheme_discount_amount * element.quantity);
      this.taxTotal = Number(this.taxTotal) + Number(element.quantity) * Number(this.getTax(element));
      this.FinalGross = Number(this.FinalGross) + Number(element.gross_amount);
      this.total_tml_rule_discount = Number(this.total_tml_rule_discount) + Number(element.tml_rule_discount_amount * element.quantity);
      this.total_dealer_rule_discount = Number(this.total_dealer_rule_discount) + Number(element.dealer_rule_discount_amount * element.quantity);
      this.total_welcome_discount = Number(this.total_welcome_discount) + Number(element.distributor2_discount_amnt * element.quantity )
      this.total_cash_discount_amount += Number(this.total_cash_discount_amount) + Number(element.cash_discount_amount * element.quantity);
    });
    this.total_coupon_discount = this.order.flat_discount;
    //this.orderFinalTotal = this.subTotal - this.baseDiscount - this.schemediscount - this.total_tml_rule_discount - this.total_dealer_rule_discount + this.taxTotal;
    this.orderFinalTotal = this.FinalGross;
    var adamt = (
      Number(this.orderFinalTotal) -
      (Number(this.subTotal) -
        Number(this.baseDiscount) -
        Number(this.schemediscount) -
        Number(this.total_tml_rule_discount) -
        Number(this.total_welcome_discount) -
        Number(this.total_coupon_discount) - 
        Number(this.total_dealer_rule_discount) +
        Number(this.taxTotal))
    ).toFixed(2);
    this.adjustamt = Number(adamt);
  }

  getTax(lineitem) {
    let tax = lineitem.total_tax;
    if (
      lineitem.total_tax == '' ||
      lineitem.total_tax == undefined ||
      isNaN(lineitem.total_tax)
    ) {
      tax = 0;
    }
    return Number(tax).toFixed(5);
    //return Number(tax);
  }

  getDiscountedPrice(lineitem) {
    let tax = lineitem.total_tax;
    let finalAmount = lineitem.ITEM_FINAL_AMOUNT;
    if (
      lineitem.total_tax == '' ||
      lineitem.total_tax == undefined ||
      isNaN(lineitem.total_tax)
    ) {
      tax = 0;
    }
    if (
      lineitem.ITEM_FINAL_AMOUNT == '' ||
      lineitem.ITEM_FINAL_AMOUNT == undefined ||
      isNaN(lineitem.ITEM_FINAL_AMOUNT)
    ) {
      finalAmount = 0;
    }
    // return (Number(finalAmount - tax)).toFixed(2);
    return Number(finalAmount - tax);
  }

  getTotal(lineitem) {
    if (!this.isReturn && !this.isOrderInProcessSelected && !this.isReport) {
      let finalePrice = lineitem.ITEM_FINAL_AMOUNT;
      let qty = lineitem.QUANTITY_s;
      if (
        lineitem.ITEM_FINAL_AMOUNT == '' ||
        lineitem.ITEM_FINAL_AMOUNT == undefined ||
        isNaN(lineitem.ITEM_FINAL_AMOUNT )
      ) {
        finalePrice = 0;
      }
      if (
        lineitem.QUANTITY_s == '' ||
        lineitem.QUANTITY_s == undefined ||
        isNaN(lineitem.QUANTITY_s)
      ) {
        qty = 0;
      }
      return finalePrice * qty;
    } else if (this.isReport) {
      let finalePrice = lineitem.item_final_amount;
      let qty = lineitem.quantity;
      if (
        lineitem.item_final_amount == '' ||
        lineitem.item_final_amount == undefined ||
        isNaN(lineitem.item_final_amount)
      ) {
        finalePrice = 0;
      }
      if (
        lineitem.quantity == '' ||
        lineitem.quantity == undefined ||
        isNaN(lineitem.quantity)
      ) {
        qty = 0;
      }
      return finalePrice * qty;
    } else if (this.isReturn || this.isOrderInProcessSelected) {
      let finalePrice = lineitem.rate;
      let qty = lineitem.total_quantity;
      if (
        lineitem.umrp == '' ||
        lineitem.umrp == undefined ||
        isNaN(lineitem.umrp)
      ) {
        finalePrice = 0;
      }
      if (
        lineitem.total_quantity == '' ||
        lineitem.total_quantity == undefined ||
        isNaN(lineitem.total_quantity)
      ) {
        qty = 0;
      }
      return finalePrice * qty;
    }
  }

  closeModal() {
    this.closemodal.emit();
  }

  selectItem(event, Item) {
    if (event.currentTarget.checked) {
      Item.isChecked = true;
      this.partsToReturn.push(Item);
      this.sumOfParts += this.cancellationPrice(Item);
    } else if (!event.currentTarget.checked) {
      Item.isChecked = false;
      let positionOfElement = this.partsToReturn.indexOf(Item);
      this.partsToReturn.splice(positionOfElement, 1);
      this.sumOfParts -= this.cancellationPrice(Item);
      if (this.partsToReturn.length === 0) {
        this.orderCancelForm.reset();
      }
    }
    this.isAllChecked =
      this.cancellablePartsCount === this.partsToReturn.length ? true : false;
  }

  checkAllCancellled(returnOrderList) {
    let cancelledArray: any[] = [];
    for (let i = 0; i < returnOrderList.length; i++) {
      if (
        parseFloat(returnOrderList[i]?.invoiced_quantity) +
          parseFloat(returnOrderList[i]?.cancelled_quantity) ==
        parseFloat(returnOrderList[i]?.total_quantity)
      ) {
        if (!cancelledArray.includes(returnOrderList[i])) {
          cancelledArray.push(returnOrderList[i]);
        }
      }
      if (
        returnOrderList[i]?.cr_request_status === 'In-Process' ||
        returnOrderList[i]?.cr_request_status === 'Cancelled'
      ) {
        if (!cancelledArray.includes(returnOrderList[i])) {
          cancelledArray.push(returnOrderList[i]);
        }
      }
    }
    this.cancelledPartsCount = cancelledArray.length;
    this.cancellablePartsCount = returnOrderList.length - cancelledArray.length;
    this.makeAllDisabled =
      cancelledArray.length === returnOrderList.length ? true : false;
  }

  OnchangeReason(event) {
    if (event === 'Other Reason') {
      this.showOther = true;
      const type = this.orderCancelForm.get('OtherReason');
      type.updateValueAndValidity();
    } else {
      this.orderCancelForm.patchValue({
        OtherReason: '',
      });
      this.showOther = false;
      const type = this.orderCancelForm.get('OtherReason');
      type.setValidators([]);
      type.updateValueAndValidity();
    }
  }

  OrderCanceldetail(order) {
    let permission = this.userService.CheckPermission('btn_cancel_order');
    if (permission) {
      if (this.partsToReturn.length === 0) {
        this.toastrService.error('Please select part');
        return;
      }
      if (
        this.orderCancelForm.value.ReasonRepalcement === '' ||
        this.orderCancelForm.value.ReasonRepalcement === null ||
        this.orderCancelForm.value.ReasonRepalcement === undefined
      ) {
        this.toastrService.error('Please Select Reason for Cancellation');
        return;
      }
      if (this.orderCancelForm.value.ReasonRepalcement === 'Other Reason') {
        if (
          this.orderCancelForm.value.OtherReason === '' ||
          this.orderCancelForm.value.OtherReason === null ||
          this.orderCancelForm.value.OtherReason === undefined
        ) {
          this.toastrService.error('Please enter remarks for Other Reason');
          return;
        }
      }
      for (var i = 0; i < this.partsToReturn.length; i++) { 
        if(this.partsToReturn[i].ordered_resource_type == 'Pack' ){
          if(!(this.partsToReturn[i].invoiced_quantity % this.partsToReturn[i].ordered_moq === 0)){
            this.toastrService.error('You cannot cancel an order because it was partially invoiced.');
            return
          }
        }
      }
      if (order === 'CancelOrder') {
        this.orderCancel = true;
        this.modalRef = this.modalService.show(this.template, {
          class: 'modal-dialog-centered modal-md',
          backdrop: 'static',
        });
      } else {
      }
    } else {
      this.toastrService.error(blockPermissionMsg);
    }
  }

  orderCancelled(event) {
    try {
      let permission = this.userService.CheckPermission('btn_cancel_order');
      if (permission) {
        if (event == 'Yes') {
          this.modalRef.hide();
          this.DataPushArray = [];
          for (var i = 0; i < this.partsToReturn.length; i++) { 
            // if(this.partsToReturn[i].ordered_resource_type == 'Pack' ){
            //   if(!(this.partsToReturn[i].invoiced_quantity % this.partsToReturn[i].ordered_moq === 0)){
            //     this.toastrService.error('You cannot cancel an order because it was partially invoiced.');
            //     return
            //   }
            // }
            const cancel_parts: cancel_parts_detail = {} as cancel_parts_detail;
            cancel_parts.part_number = this.isReturn
              ? this.partsToReturn[i].part_number
              : this.partsToReturn[i].PRODUCTNAME_s;
            this.DataPushArray.push(cancel_parts);
          }
          let user = JSON.parse(this.localstorage.getItem('userData'));
          if (user) {
            let orderCancelDetails = {
              account_pk: JSON.stringify(user.account_pk),
              order_number: this.order.order_number,
              user_cancel_reason: this.orderCancelForm.value.ReasonRepalcement,
              user_other_reason: this.orderCancelForm.value.OtherReason,
              cancel_parts_detail: this.DataPushArray,
              latitude:this.localstorage.getItem('geolocation') ? (JSON.parse(this.localstorage.getItem("geolocation")).latitude).toString(): '',
              longitude:this.localstorage.getItem('geolocation') ? (JSON.parse(this.localstorage.getItem("geolocation")).longitude).toString(): '',
              ip:this.localstorage.getItem('ipAddress')
            };
            if (!this.chkService) {
              this.chkService = true;
              this.showLoader = true;
              this.productService
                .ordercancellation(orderCancelDetails)
                .subscribe(
                  (result) => {
                    this.chkService = false;
                    if (result.success === true) {
                      this.partsToReturn = [];
                      this.DataPushArray = [];
                      this.showLoader = false;
                      this.toastrService.success(
                        'Order Cancellation Request Accepted.'
                      );
                      // this.router.navigateByUrl(`orders/active-orders`)
                      this.appStateService.refreshHeader();
                      this.closeModal();
                    } else {
                      this.toastrService.error(result.data.msg);
                      this.showLoader = false;
                      this.chkService = false;
                    }
                    this.showLoader = false;
                  },
                  (error) => {
                    this.chkService = false;
                    this.showLoader = false;
                    this.toastrService.error(error.error.data.msg);
                  }
                );
            }
          }
        } else if (event == 'No') {
          this.modalRef.hide();
        }
      } else {
        this.toastrService.error(blockPermissionMsg);
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'orderCancelled()',
        page_name: 'PageOrderCancelledComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  getOrderPaymentHistory(otc_number) {
    try {
      let user = JSON.parse(this.localstorage.getItem('userData'));
      if (user) {
        if (user && user.account_pk) {
          this.showLoader = true;
          this.userService
            .OrderPaymentDetails({
              account_pk: parseInt(user.account_pk),
              otc_number: otc_number ? otc_number : this.order.order_number,
            })
            .subscribe(
              (response) => {
                if (response.success) {
                  this.paymentHistory = response.data.result;
                  this.showLoader = false;
                } else {
                  this.toastrService.error(response.data.msg);
                  this.paymentHistory = [];
                  this.showLoader = false;
                }
                this.showLoader = false;
              },
              (error) => {
                this.showLoader = false;
              }
            );
        }
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'getOrderPaymentHistory(otc_number)',
        page_name: 'PageOrderInprocessDetailsComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  getCancelOrderDetail(cancel_order_number) {
    try {
      if (this.localstorage.getItem('userData')) {
        this.showLoader = true;
        this.cancelOrderBillingAddress = {};
        this.cancelOrderShippingAddress = {};
        this.userService
          .cancelorderdetail({
            account_pk:
              '' + JSON.parse(this.localstorage.getItem('userData')).account_pk,
            cancel_order_number: cancel_order_number,
          })
          .subscribe(
            (response) => {
              if (response.success) {
                this.cancelOrderParts = response.data.result[0];
                for (
                  let i = 0;
                  i < this.cancelOrderParts['order_address'].length;
                  i++
                ) {
                  if (
                    this.cancelOrderParts['order_address'][i].address_type ==
                    'billing_address'
                  ) {
                    this.cancelOrderBillingAddress =
                      this.cancelOrderParts['order_address'][i];
                  }
                  if (
                    this.cancelOrderParts['order_address'][i].address_type ==
                    'shipping_address'
                  ) {
                    this.cancelOrderShippingAddress =
                      this.cancelOrderParts['order_address'][i];
                  }
                }
                this.cancelOorderCalculation();
              } else {
                this.toastrService.error('No Record Found');
              }
              this.showLoader = false;
            },
            (error) => {
              this.showLoader = false;
            }
          );
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'getOrderDetail(cancel_order_number)',
        page_name: 'PageOrderCancelDetailsComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error,
        inner_exception: '',
      });
    }
  }

  parseInt(number) {
    return parseInt(number);
  }

  mathRound(number) {
    let decimal = Math.floor(number);
    return Math.round(number);
  }

  onRate($event: {
    oldValue: number;
    newValue: number;
    starRating: StarRatingComponent;
  }) {
    this.starclick = `${$event.newValue}`;
  }

  onSubmit() {
    try {
      let permission = this.userService.CheckPermission('btn_add_feedback');
      if (permission) {
        this.submitted = true;
        if (this.Feedbackform.invalid) {
          return;
        }
        if (this.currentRate == 0) {
          // if (this.starclick == "0" || this.starclick == undefined || this.starclick == "") {
          Swal.fire('Please give star rating');
          return;
        }

        const InputData = this.FormData(
          this.Feedbackform,
          this.Feedbackform.value.comment
        );
        this.userService.feedbackbinding(InputData).subscribe(
          (res) => {
            if (res instanceof HttpErrorResponse) {
              Swal.fire(res.error.data.msg);
              return;
            }
            if (res.success) {
              Swal.fire({
                title: 'Thank you for sharing your valuable feedback.',
                text: '',
                icon: 'success',
                confirmButtonText: 'Ok',
              }).then((result) => {
                if (result.value) {
                  this.onRefresh();
                }
              });
            } else {
              const errorMessage = res;
              // Swal.fire("Please give star rating");
              Swal.fire(res.data.msg);
              return;
            }
          },
          (err) => {
            Swal.fire(err.error.data.msg);
          }
        );
      } else {
        this.toastrService.error(blockPermissionMsg);
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'onSubmit()',
        page_name: 'PagePopupFeedbackComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  FormData(Data, feedbackcomment): feedback {
    const FeedbackData: feedback = {} as feedback;
    // FeedbackData.rating = this.starclick;
    FeedbackData.rating = this.currentRate.toString();
    FeedbackData.account_pk = this.Feedbackform.value.account_pk;
    FeedbackData.comment = feedbackcomment;
    FeedbackData.otc_number = this.Feedbackform.value.otc_number;
    FeedbackData.invoice_no = this.Feedbackform.value.INVC_NUM_s;
    return FeedbackData;
  }

  onRefresh() {
    // this.router.routeReuseStrategy.shouldReuseRoute = function () {
    //   return false;
    // };
    let currentUrl = this.router.url;
    this.router.navigateByUrl(currentUrl).then(() => {
      this.router.navigated = false;
      this.router.navigate([this.router.url]);
    });
  }

  taxInclusivePrice(item) {
    let finalePrice = item.single_part_amount;
    let qty = item.total_quantity;
    if (item.single_part_amount == '' || item.single_part_amount == undefined || isNaN(item.single_part_amount) ) {
      finalePrice = 0;
    }
    if (
      item.total_quantity == '' ||
      item.total_quantity == undefined ||
      isNaN(item.total_quantity)
    ) {
      qty = 0;
    }
    let total_price = parseFloat(finalePrice) * parseFloat(qty);
    let total_price_including_gst =
      total_price + parseFloat(item.tax_cgst_sgst_amount);
    return total_price_including_gst;
  }

  cancellationPrice(item) {
    let finalePrice = item.single_part_amount;
    let qty = item.pending_quantity;
    if (item.single_part_amount == '' || item.single_part_amount == undefined || isNaN(item.single_part_amount) ) {
      finalePrice = 0;
    }
    if (
      item.pending_quantity == '' ||
      item.pending_quantity == undefined ||
      isNaN(item.pending_quantity)
    ) {
      qty = 0;
    }
    let total_price = parseFloat(finalePrice) * parseFloat(qty);
    let gstForCancellation =
      (parseFloat(item.tax_cgst_sgst_amount) /
        parseFloat(item.total_quantity)) *
      parseFloat(item.pending_quantity);
    let total_price_including_gst = total_price + gstForCancellation;
    return total_price_including_gst;
  }

  routeToProductDetails(partnumber) {
    this.closemodal.emit();
    this.userService.routeToProductDetails(partnumber);
  }

  selectAllItem(event) {
    if (event.currentTarget.checked) {
      for (let i = 0; i < this.returnOrderList.length; i++) {
        if (
          this.returnOrderList[i]?.cr_request_status != 'In-Process' &&
          this.returnOrderList[i]?.cr_request_status != 'Cancelled'
        ) {
          if (!this.returnOrderList[i].isChecked) {
            if(this.returnOrderList[i]?.invoiced_quantity > 0 && (this.returnOrderList[i]?.tml_discount_type === 'quantity_discount' || this.returnOrderList[i]?.dealer_discount_type === 'quantity_discount')){
              this.returnOrderList[i].isChecked = false;
            }
            else{
              this.partsToReturn.push(this.returnOrderList[i]);
              this.returnOrderList[i].isChecked = true;
              this.sumOfParts += this.cancellationPrice(this.returnOrderList[i]);
            }
            
          }
        }
      }
      this.isAllChecked = true;
    } else if (!event.currentTarget.checked) {
      this.partsToReturn = [];
      this.sumOfParts = 0;
      for (let i = 0; i < this.returnOrderList.length; i++) {
        this.partsToReturn = [];
        this.returnOrderList[i].isChecked = false;
        this.sumOfParts = 0;
      }
      this.isAllChecked = false;
    }
  }

  getStatus(item, type) {
    if (type == 'Checked') {
      if (
        parseFloat(item?.invoiced_quantity) +
          parseFloat(item?.cancelled_quantity) ==
        parseFloat(item?.total_quantity)
      ) {
        return true;
      } else if (
        item?.cr_request_status === 'In-Process' ||
        item?.cr_request_status === 'Cancelled'
      ) {
        return true;
      } else if (item?.isChecked) {
        return true;
      } else {
        return false;
      }
    } else {
      if (parseFloat(item?.invoiced_quantity) + parseFloat(item?.cancelled_quantity) == parseFloat(item?.total_quantity)) {
        return true;
      } else if (item?.cr_request_status === 'In-Process' || item?.cr_request_status === 'Cancelled') {
        return true;
      }
      else if(parseFloat(item?.invoiced_quantity) > 0 && (item?.tml_discount_type === "quantity_discount" || item?.dealer_discount_type === "quantity_discount")){
        return true;
      } 
      else {
        return false;
      }
    }
  }

  getLocation(): void {
    if ('geolocation' in navigator) {
      this.watchId = navigator.geolocation.watchPosition(
        (position) => {
          const geoLocation = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };

        this.localstorage.setItem('geolocation',JSON.stringify(geoLocation));
          this.errorMessage = null; // Clear any error message
          this.showRequestButton = false; // Hide the request button
        },
        (error: any) => {
          throw(error);
        },
        {
          enableHighAccuracy: true,
          maximumAge: 0,
          timeout: 5000
        }
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  }
}

export interface cancel_parts_detail {
  part_number: string;
  row_id: string;
}

export class feedback {
  otc_number: string;
  rating: string;
  comment: string;
  account_pk: string;
  invoice_no: string;
}
