import { InjectionToken } from "@angular/core";
import { environment } from "../../environments/environment";

export const myAppConfig: any = {
  appname: "E-Dukaan",
  titlename: "E-Dukaan",
  apiUrl: environment.apiUrl,
  previousUrl:environment.previousUrl,
  pgGroup: [
    { name: " Pg Group 1", _id: "pg1" },
    { name: "Pg Group 2", _id: "pg2" },
    { name: "Pg Group 3", _id: "pg3" },
  ],
  pgLine: [
    { name: "Pg Line 1", _id: "pg1" },
    { name: "Pg Line 1", _id: "pg2" },
    { name: "Pg Line 1", _id: "pg3" },
  ],
  categoryTypes:[
    { name:'Mhcv',value:'mhv'},
    { name:'Slcv',value:'slv'}
  ],
  searchOptions :[
    {name:'Part No', value:'partno'},
    {name:'Chassis No', value:'chassisno'},
    {name:'Registration No', value:'regno'},
    {name:'Part Description', value:'description'},
  ]
};


export const applicationConfiguration = new InjectionToken<AppConfig>(
  "app.config",
  { providedIn: "root", factory: () => myAppConfig }
);


export interface AppConfig {
  appname: string;
  titlename: string;
  apiUrl: string;
  previousUrl:string;
  pgGroup: string[];
  pgLine: string[];
  categoryTypes: string[];
  searchOptions: string[];
}
