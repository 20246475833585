import { Component, OnInit } from '@angular/core';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { NavigationEnd, Router, Event } from '@angular/router';
import { NavigationLink } from 'src/app/shared/interfaces/navigation-link';
import { secureStorage } from 'src/app/shared/services/securestorage';
import { Title } from '@angular/platform-browser';
import { UserService } from 'src/app/shared/services/REST/user.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  links = [];
  items: NavigationLink[];
  currentUrl;
  profileMenu;
  profileroute = '/account/profile';
  isProfilePermission;
  isVehiclePermission;
  isKeyClockUser: boolean = false;
  userDetails:any;
  dataArray:any = []
  constructor(
    private appStateService: AppStateService,
    private router: Router,
    private title:Title,
    private localstorage: secureStorage,
    private userService:UserService
  ) {}

  ngOnInit() {
    this.isKeyClockUser = this.localstorage.getItem("isKeyClockUser") === "true" ? true : false;
    this.currentUrl = this.router.url.includes("isMyVehicles=true") ? this.router.url.slice(0,16) : this.router.url;
    this.profileMenu = JSON.parse(this.localstorage.getItem('profileLayoutMenu'));
    if(this.localstorage.getItem('isProfilePermission')){
      this.isProfilePermission = JSON.parse(this.localstorage.getItem('isProfilePermission'));
    }
    if(this.localstorage.getItem('isVehiclePermission')){
      this.isVehiclePermission = JSON.parse(this.localstorage.getItem('isVehiclePermission'));
    }

    this.appStateService.isprofilelist.subscribe(() => {
      this.profileMenu = JSON.parse(this.localstorage.getItem('profileLayoutMenu'));
    });
    this.appStateService.isProfilePermission.subscribe(
      (isProfilePermission) => {
        this.isProfilePermission = isProfilePermission;
        if(this.localstorage.getItem('isProfilePermission')){
          this.isProfilePermission = JSON.parse(this.localstorage.getItem('isProfilePermission'));
        }
      }
    );
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url.includes("isMyVehicles=true") ? event.url.slice(0,16) : event.url;
        this.updateTitle(event)
      }
    });
    this.getLimitData();
  }
  updateTitle(menuItem){
    this.profileMenu.forEach(element => {
      if( element.page_url == menuItem.url ){
        this.title.setTitle('E-Dukaan | '+element.page_display_name);
        return
      }
    });
  }
  getLimitData(){
    this.userDetails = JSON.parse(this.localstorage.getItem('userData'));
    // console.log("checK-usre_detailslsls",this.userDetails)
    let request = {
      "account_pk":this.userDetails.account_pk,
      // "account_pk":1499,

    }
    this.userService.loyalityLimitApi(request).subscribe((res) => {
      this.dataArray = [];
      if(res && res.success){
              // console.log("check_limit_reponse>>",res)

        this.dataArray.push(res.data);

      }else{

      }
    })
  }
}