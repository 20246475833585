import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProductdetailsComponent } from 'src/app/shared/components/productdetails/productdetails.component';
import { PageCategoryComponent } from './pages/page-category/page-category.component';
import { CommonModule } from '@angular/common';
import { ProductcardComponent } from './pages/productcard/productcard.component';
import { ProductDetailEngineComponent } from 'src/app/shared/components/product-detail-engine/product-detail-engine.component';
// import { TermsConditionComponent } from './pages/terms-condition/terms-condition.component';

const routes: Routes = [
    {
        path: 'productcards',
        component: ProductcardComponent,
    },
    {
        path: 'catalog',
        component: PageCategoryComponent,
    },
    {
        path: 'product-details',
        component: ProductdetailsComponent,
    },
    {
        path: 'productengine',
        component: ProductDetailEngineComponent,
    }
    // {
    //     path: 'terms-conditions',
    //     component: TermsConditionComponent
    // }
    // {
    //     path: 'catalog/:bom_id/:mod_id',
    //     component: PageCategoryComponent,
    // },

    // {
    //     path: 'catalog/:categorySlug',
    //     component: PageCategoryComponent,
        
    // },

];

@NgModule({
    declarations:[],
    imports: [RouterModule.forChild(routes),CommonModule],
    exports: [RouterModule]
})
export class ShopRoutingModule { }
