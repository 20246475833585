import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from '../../services/REST/product.service';
import { UserService } from '../../services/REST/user.service';
import { secureStorage } from '../../services/securestorage';
declare var $: any;
@Component({
  selector: 'app-fleet-details',
  templateUrl: './fleet-details.component.html',
  styleUrls: ['./fleet-details.component.scss']
})
export class FleetDetailsComponent implements OnInit {
  @Output() closeVehicleDetails = new EventEmitter<any>();
  @Input() userData: any;
  @Input() account_pk;
  @Input() registrationForm: FormGroup;
  @Input() loginForm: FormGroup;
  vehicle = [];
  vehicleinfo = [];
  isLogin: boolean = false;
  isRegistration: boolean = false;
  showHeader: boolean = false;
  selectedModelCategory: any;
  constructor(
    private toastrService: ToastrService,
    private userService: UserService,
    private route: ActivatedRoute ) { }
  vehicleDetails: any;
  ngOnInit(): void {
    // $('.owl-carousel').owlCarousel({
    //   loop: false,
    //   margin: 10,
    //   autoWidth: true,
    //   nav: true,
    //   dots: false,
    //   navText: [
    //     '<i class="fa fa-angle-left left-arrow"></i>',
    //     '<i class="fa fa-angle-right right-arrow"></i>'
    //   ],
    //   autoplay: false,
    //   autoplayHoverPause: true,
    //   responsive: {
    //     0: {
    //       items: 1
    //     },
    //     600: {
    //       items: 3
    //     },
    //     1000: {
    //       items: 5
    //     }
    //   }
    // })
    try {
      this.route.queryParams.subscribe(params => {
        this.isLogin = params['isLogin'];
        this.isRegistration = params['isRegistration'];
        if (params['isLogin'] === 'true') {
          this.showHeader = true;
        }
        else if (params['isRegistration'] === 'true') {
          this.showHeader = true;
        }
        else {
          this.showHeader = false;
        }
      });
      this.getVehicleCategoryList();
    }
    catch (error) {

    }
  }

  async getVehicleCategoryList() {
    try {
      await this.userService.VechileListWithoutLogin({ "account_pk": this.account_pk }).subscribe(response => {
        if (response.success) {
          this.vehicle = response.data;
          //setTimeout(() => {
          if (this.vehicle) {
            this.slider();
          }
          //}, 300);
          this.selectedModelCategory = response.data[0];
          this.getVehicleList(response.data[0])
        } else {
          this.toastrService.error(response.data.msg);
        }
      })
    }
    catch (error) {

    }
  }

  openUserType() {
    this.AddVehicleCheck();
    this.closeVehicleDetails.emit();
  }
  async AddVehicleCheck() {
    try {
        await this.userService.AddVehicleCheck({ "account_pk": this.account_pk }).subscribe(response => {
          if (response.success) {
            this.vehicleinfo = response.data;
          } else {
            if(response?.data?.msg){
              this.toastrService.error(response.data.msg);
            }
          }
        })
    }
    catch (error) {

    }
  }

  async getVehicleList(modelCategory) {
    try {
      this.selectedModelCategory = modelCategory;
      var request = {
        "account_pk": this.account_pk,
        "multi_pl": modelCategory.pl_list,
        "api_type": "vehicle_info",
        "size": 100
      }
      await this.userService.VechileListWithoutLogin(request).subscribe(response => {
        if (response.success) {
          this.vehicleinfo = response.data;
        } else {
          if(response?.data?.msg){
            this.toastrService.error(response.data.msg);
          }
        }
      })
    }
    catch (error) {

    }
  }

  slider() {
    $('#fleetCro').owlCarousel({
      loop: false,
      margin: 10,
      autoWidth: true,
      nav: true,
      dots: false,
      navText: [
        "<i class='fa fa-angle-left'></i>",
        "<i class='fa fa-angle-right'></i>"
      ],
      autoplay: false,
      autoplayHoverPause: true,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 3
        },
        1000: {
          items: 5
        }
      }
    })
  }
}
