import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from '../../services/REST/product.service';
import { MatRadioChange } from '@angular/material/radio';
import { UserService } from '../../services/REST/user.service';
import { Router } from '@angular/router';

import { blockPermissionMsg } from 'src/app/constant';
import { secureStorage } from '../../services/securestorage';
@Component({
  selector: 'app-add-vehicle',
  templateUrl: './add-vehicle.component.html',
  styleUrls: ['./add-vehicle.component.scss'],
})
export class AddVehicleComponent implements OnInit {
  blockPermissionMsg = blockPermissionMsg;
  vehicleList = [
    { name: 'Registration Number', id: '0', checked: true,disabled: false },
    { name: 'Chassis Number', id: '1', checked: false, disabled: false },
  ];
  chosenItem = this.vehicleList[0].name;
  submitCalled = false;
  @Input() vehicleDetails: any;
  @Output() closePopUp = new EventEmitter<any>();
  userVehicleForm: FormGroup;
  typevalue: string;
  searchBy: string;
  isRegistration: boolean = true;
  isChassis: boolean = false;
  VarientData: any[];
  productList: any[];
  total_results: any;
  imagePath: any;
  errormsg: string;
  rcUpdate = false;
  errorinNo = false;
  showLoader: boolean = false;
  typeArray = [];
  searchedVehicles: any;
  imageSrc: string;
  isSizeIssue: boolean = false;
  isFileTypeIssue: boolean = false;
  disableRegistrationInput = false;
  disableChassisInput = false;
  submitButtonDisable = true;
  isApiCalled:boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private productService: ProductService,
    private toastrService: ToastrService,
    private userService: UserService,
    private router: Router,
    private localstorage: secureStorage
  ) {}

  ngOnInit(): void {
    this.typeArray = [
      {
        label: 'Registration Number',
        value: 'RegistrationNo',
        isSelected: true,
      },
      { label: 'Chassis Number', value: 'ChassisNo', isSelected: false },
    ];
    this.BuildItem();
    if(this.vehicleDetails){
      this.vehicleList.forEach(x => {
        x.disabled = true;
      })
    }
  }

  closeModal() {
    this.vehicleDetails = '';
    this.closePopUp.emit();
  }

  BuildItem() {
    this.typevalue = '';
    let user = JSON.parse(this.localstorage.getItem('user'));

    this.userVehicleForm = this.formBuilder.group({
      name: [''],
      type: ['ChassisNo'],
      ModelName: [''],
      subModelName: [''],
      selectvalue: [''],
      varient: [''],
      rc_book_doc: [''],
      //varient: ['', [Validators.required]],
    });

    if (this.vehicleDetails?.registration_no) {
      this.submitButtonDisable = false;
      this.chosenItem = this.vehicleList[0].name;
      this.disableRegistrationInput = true;
      this.disableChassisInput = true;
      this.isChassis = false;
      this.isRegistration = true;
      this.typevalue = this.vehicleDetails?.registration_no
        ? this.vehicleDetails?.registration_no
        : '';
    } else if (this.vehicleDetails?.chassis_no) {
      this.submitButtonDisable = false;
      this.chosenItem = this.vehicleList[1].name;
      this.disableRegistrationInput = true;
      this.disableChassisInput = true;
      this.isChassis = true;
      this.isRegistration = false;
      this.typevalue = this.vehicleDetails?.chassis_no
        ? this.vehicleDetails?.chassis_no
        : '';
    }
  }

  radioChange(event: MatRadioChange, item) {
    this.isApiCalled = false;
    this.userVehicleForm.controls['type'].setValue(event.value);
    this.userVehicleForm.patchValue({
      ModelName: '',
      subModelName: '',
      varient: '',
    });
    if (item.name == 'Chassis Number') {
      this.chosenItem = item.name;
      this.typevalue = this.vehicleDetails?.chassis_no
        ? this.vehicleDetails?.chassis_no
        : '';
      this.isChassis = true;
      this.isRegistration = false;
      this.errormsg = '';
      this.errorinNo = false;
      this.disableChassisInput = this.vehicleDetails?.chassis_no ? true : false;

      this.searchedVehicles = [];
    } else {
      this.chosenItem = item.name;
      this.typevalue = this.vehicleDetails?.registration_no
        ? this.vehicleDetails?.registration_no
        : '';
      this.isChassis = false;
      this.isRegistration = true;
      this.errormsg = '';
      this.errorinNo = false;
      this.disableRegistrationInput = this.vehicleDetails?.registration_no
        ? true
        : false;
      this.searchedVehicles = [];
    }
  }

  applyFilter() {
    this.isApiCalled = false;
    if (!this.typevalue) {
      this.errorinNo = true;
      this.errormsg = 'Please Enter ' + this.chosenItem + '';

      return;
    }
    this.VarientData = [];
    let data = {};
    this.productList = [];

    let searchby =
      this.chosenItem == 'Registration Number'
        ? 'registration_num'
        : 'chassis_num';

    data[searchby] = this.typevalue;
    this.showLoader = true;
    this.errormsg = '';
    this.productService.getProductListByRegOrChassionov2(data).subscribe(
      (response) => {
        if (response.success) {
          this.isApiCalled = true;
          this.submitButtonDisable = false;
          this.showLoader = false;
          var MobileNo = null;
          this.searchedVehicles = response.data;
          if (
            this.localstorage.getItem('userData') != null ||
            this.localstorage.getItem('userData') != undefined
          ) {
            let userData = JSON.parse(this.localstorage.getItem('userData'));
            MobileNo = userData.phone_number;
          }
          var Address = '';
          if (
            this.localstorage.getItem('address') != null ||
            this.localstorage.getItem('address') != undefined
          ) {
            let address = JSON.parse(this.localstorage.getItem('address'));
            Address =
              address.address.street_address1 +
              ', ' +
              address.address.state +
              ', ' +
              address.address.district +
              ', ' +
              address.address.city +
              ', ' +
              address.address.postal_code;
          }
          this.productList = response.data;

          for (let data of response.data) {
            this.VarientData.push(this.FormVarientData(data));
          }
          this.imagePath = this.productList[0].mod_img_name;
        } else {
          this.submitButtonDisable = true;
          this.showLoader = false;
          this.errorinNo = true;
          this.errormsg = response.data.msg;
          this.userVehicleForm.patchValue({});
          this.productList = [];
        }
        this.showLoader = false;
      },
      (error) => {
        this.showLoader = false;
      }
    );
  }

  FormVarientData(data): Varientlist {
    const objdata: Varientlist = {} as Varientlist;
    objdata.VC_No = data.VC_No;
    objdata.Model_Version = data.Base_Model;
    return objdata;
  }

  onSubmit() {
    try {
      if (!this.typevalue) {
        let showText =
          'Please enter vehicle ' +
          (this.isChassis ? 'chassis number' : 'registration number');
        this.toastrService.error(showText);

        return;
      }
      // if (!this.userVehicleForm.controls['rc_book_doc'].value) {
      //   this.toastrService.error('Please upload vehicle RC Book');
      //   return;
      // }
      let user = JSON.parse(this.localstorage.getItem('userData'));
      if (user && !this.isSizeIssue && !this.isFileTypeIssue) {
        let data;

        if (this.vehicleDetails) {
          this.rcUpdate = true;
          data = {
            account_pk: parseInt(user.account_pk),
            action_type: 'update',
            vehicle_id: this.vehicleDetails.id,
            rc_book_doc: this.userVehicleForm.controls['rc_book_doc'].value
              ? this.userVehicleForm.controls['rc_book_doc'].value
              : '',
          };
        } else {
          this.rcUpdate = false;
          data = {
            account_pk: parseInt(user.account_pk),
            chassis_type: this.productList[0]['Chassis_Type'],
            mod_uid: this.productList[0]['MOD_UID'],
            vc_no: this.productList[0]['VC_No'],
            chassis_no: this.isChassis ? this.typevalue : '',
            registration_no: this.isRegistration ? this.typevalue : '',
            rc_book_doc: this.userVehicleForm.controls['rc_book_doc'].value
              ? this.userVehicleForm.controls['rc_book_doc'].value
              : '',
          };
        }

        let permission = this.userService.CheckPermission('btn_add_vehicle');

        if (permission) {
          this.showLoader = true;

          if (
            this.rcUpdate &&
            this.userVehicleForm.controls['rc_book_doc'].value == '' &&
            this.vehicleDetails.rc_book_doc == ''
          ) {
            this.showLoader = false;
            this.toastrService.error('Please upload vehicle RC Book.');
            return;
          } else if (data.rc_book_doc == '' && data.action_type == 'update') {
            this.showLoader = false;
            return;
          } else {
            this.userService.AddVehicle(data).subscribe(
              (response) => {
                if (response.success === true) {
                  this.typevalue = '';
                  if (this.rcUpdate) {
                    this.toastrService.success('Vehicle Updated Successfully.');
                  } else {
                    this.toastrService.success('Vehicle Added Successfully.');
                  }
                } else {
                  this.toastrService.error(response.data.msg);
                }
                this.showLoader = false;
                this.vehicleDetails = '';
                if (response.data.msg !== 'Vehicle already exists.') {
                  //just to call vehicle list again via emit
                  this.closePopUp.emit('Vehicle Added Successfully');
                }
              },
              (error) => {
                this.showLoader = false;
                this.toastrService.error(error);
              }
            );
          }
        } else {
          this.toastrService.error(blockPermissionMsg);
        }
      } else if (this.isSizeIssue) {
        this.toastrService.error(
          'Image size must be less than or equal to 2mb.'
        );
      } else if (this.isFileTypeIssue) {
        this.toastrService.error('Please upload a pdf, jpeg or jpg file');
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'onSubmit() ',
        page_name: 'PageVehicleAddComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files) {
      let imageData = event.target.files[0];
      var kbSize = imageData.size / 1024;
      if (Math.round(kbSize / 1024) > 2) {
        this.isSizeIssue = true;
        this.toastrService.error(
          'Image size must be less than or equal to 2mb.'
        );
        return;
      }
      if (
        imageData.type === 'application/pdf' ||
        imageData.type === 'image/jpeg' ||
        imageData.type === 'image/jpg'
      ) {
        if (event.target.files.length) {
          const [file] = event.target.files;
          reader.readAsDataURL(file);
          reader.onload = () => {
            this.imageSrc = reader.result as string;
            this.userVehicleForm.patchValue({
              rc_book_doc: reader.result,
            });
          };
        }
        this.isSizeIssue = false;
        this.isFileTypeIssue = false;
      } else {
        this.isFileTypeIssue = true;
        this.toastrService.error('Please upload a pdf, jpeg or jpg file');
        return;
      }
    }
  }
}

export interface Varientlist {
  VC_No: string;
  Model_Version: string;
}
