import { Component } from '@angular/core';
import { ConnectionService } from 'ng-connection-service';
import Swal from 'sweetalert2';
import { PaymentService } from './shared/services/razorpaypayment/payment.service';
import { AuthService } from './shared/services/REST/auth.service';
import { UserService } from './shared/services/REST/user.service';
import { Router } from '@angular/router';
declare let firebase: any;
declare let anuvadak:any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'e-dukaan';
  connectionCount: number = 0;
  constructor( private authService:AuthService, private connectionService: ConnectionService,private paymentService:PaymentService, private router:Router, private userService:UserService ) {
    this.connectionService.monitor().subscribe(isConnected => {
      if (isConnected) {
        if (this.connectionCount == 1) {
          Swal.fire(
            'Connected!'
          );
          this.connectionCount = 0;
          window.location.reload();
        }
      }
      else {
        this.connectionCount = 1;
        // Swal.fire(
        //   'Connection Interrupted!        Please check your internet connection'
        // );
        Swal.fire({
          title: "<i>Connection Interrupted!</i>", 
          html: "Please check your internet connection",  
          confirmButtonText: "OK", 
        });
      }
    })
  }
  ngOnInit() {
    this.initalizedExternalApplication()
  }

  async initalizedExternalApplication(){
    try{
      this.authService.getFBConfigurations().subscribe(
        res => {
          if(res.success){
            let dec_data = this.paymentService.decrypt(res.data);
            dec_data = dec_data.replaceAll("'", '"')
            dec_data = JSON.parse(dec_data);
            var firebaseConfig = {
              apiKey: dec_data.FB_K,
              authDomain: dec_data.FB_DM,
              databaseURL: dec_data.FB_BD,
              projectId: dec_data.FB_DI,
              storageBucket: dec_data.FB_ST,
              messagingSenderId: dec_data.FB_MSG,
              appId: dec_data.FB_APP,
              measurementId: dec_data.FB_MT
            }
            firebase.initializeApp(firebaseConfig);
            firebase.analytics();
            // this.initializeAVK(dec_data.ANU_RES)
          }
        }
      )
    }catch(error){
      this.userService.InsertExceptionLog({
        method_name: 'initalizedExternalApplication()',
        page_name: 'AppComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  async  initializeAVK(enc_anu_k) {
    anuvadak({
        project: {
            key:enc_anu_k
        }
    })
  }

}
