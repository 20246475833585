

<div class="part-popup for-desktop" *ngIf="showDivPartdetails && showPartData">
    <div [ngClass]="router.url.includes('active-orders') ? 'triangle-left' : 'triangle-right' "></div>
    <div class="close" (click)="ClosePart()">X</div>
    <div class="p-2">
      <div class="row">
        <div class="col-12 col-sm-7 order-2 order-sm-1">          
          <div class="prod-no" style="cursor: pointer;" [routerLink]="['/shop/product-details']" [queryParams]="{ part_Number : showPartData.part_number }">
            {{showPartData?.part_desc}}
          </div>
          <div class="pro-desc" style="cursor: pointer;" [routerLink]="['/shop/product-details']" [queryParams]="{ part_Number : showPartData.part_number }">
            {{showPartData?.part_number}}
          </div>
          <!-- <div class="price">
            {{ showPartData?.umrp|currencyFormat:'INR' }}
          </div> -->
          <div class="">
              <ng-container *ngIf="showPartData['ordered_resource_type'] == 'Pack'; else elseTmgoCart">
                <app-input-number aria-label="Quantity" size="lg" [min]="1"
                  (currentQuantity)="readQuantity($event,showPartData)" [isTmgoValue]="showPartData['ordered_moq']" 
                  [defaultValue]="pageName =='activeOrder' ?  (checkNan(showPartData?.customerQuantity) ? 1 : showPartData?.customerQuantity ) : showPartData?.min_quantity" class="d-inline-block"
                  [userType]="userDetail" [inventoryQty]="showPartData['inventory_quantity']" [isCheckBuy]="buyAgain">
                </app-input-number>
                ( {{ pageName =='activeOrder'? showPartData.customerQuantity * showPartData.moq:showPartData.customerQuantity }} ) 
              </ng-container>
              <ng-template #elseTmgoCart>
                  <app-input-number aria-label="Quantity" size="lg" [min]="1" [isTmgoValue]="1" 
                  (currentQuantity)="readQuantity($event,showPartData)"  
                  [defaultValue]="pageName =='activeOrder' ?  checkNan(showPartData?.customerQuantity) ? 1 : showPartData?.customerQuantity : showPartData?.min_quantity" class="d-inline-block"
                  [userType]="userDetail" [inventoryQty]="showPartData['inventory_quantity']" [isCheckBuy]="buyAgain">
                </app-input-number>
 
              </ng-template>
          </div>
       

          <div class="mt-2">
            <button id="btn_{{showPartData?.part_number}}" class="btn btn-primary" (click)="addtoCartitems(showPartData)" >ADD TO CART</button>
          </div>
        </div>
        <div class="col-12 col-sm-5 ps-sm-0 order-1 order-sm-2">
          <img alt="" style="cursor: pointer;" [routerLink]="['/shop/product-details']" 
          [queryParams]="{ part_Number : showPartData.part_number }" 
          [src]="router.url.includes('active-orders') ? showPartData?.part_image_path : showPartData?.part_image_path" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>


  <div class="part-popup-1 for-mobile" *ngIf="showDivPartdetails && showPartData">
    <div [ngClass]="router.url.includes('active-orders') ? 'triangle-right' : 'triangle-left' "></div>
    <div class="close" (click)="ClosePart()">X</div>
    <div class="p-2">
      <div class="row">
        <div class="col-12 col-sm-7 order-2 order-sm-1">          
          <div class="prod-no" style="cursor: pointer;" [routerLink]="['/shop/product-details']" [queryParams]="{ part_Number : showPartData.part_number }">
            {{showPartData?.part_desc}}
          </div>
          <div class="pro-desc" style="cursor: pointer;" [routerLink]="['/shop/product-details']" [queryParams]="{ part_Number : showPartData.part_number }">
            {{showPartData?.part_number}}
          </div>
          <!-- <div class="price">
            {{ showPartData?.umrp|currencyFormat:'INR' }}
          </div> -->
          <div class="">
              <ng-container *ngIf="showPartData['ordered_resource_type'] == 'Pack'; else elseTmgoCart">
                <app-input-number aria-label="Quantity" size="lg" [min]="1"
                  (currentQuantity)="readQuantity($event,showPartData)" [isTmgoValue]="showPartData['ordered_moq']" 
                  [defaultValue]="pageName =='activeOrder' ?  (checkNan(showPartData?.customerQuantity) ? 1 : showPartData?.customerQuantity ) : showPartData?.min_quantity" class="d-inline-block"
                  [userType]="userDetail" [inventoryQty]="showPartData['inventory_quantity']">
                </app-input-number>
                ( {{ pageName =='activeOrder'? showPartData.customerQuantity * showPartData.moq:showPartData.customerQuantity }} ) 
              </ng-container>
              <ng-template #elseTmgoCart>
                  <app-input-number aria-label="Quantity" size="lg" [min]="1" [isTmgoValue]="1"
                  (currentQuantity)="readQuantity($event,showPartData)"  
                  [defaultValue]="pageName =='activeOrder' ?  checkNan(showPartData?.customerQuantity) ? 1 : showPartData?.customerQuantity : showPartData?.min_quantity" class="d-inline-block"
                  [userType]="userDetail" [inventoryQty]="showPartData['inventory_quantity']">
                </app-input-number>
              
              </ng-template>
          </div>
      
          <div class="mt-2">
            <button id="btn_{{showPartData?.part_number}}" class="btn btn-primary" (click)="addtoCartitems(showPartData)" >ADD TO CART</button>
          </div>
        </div>
        <div class="col-12 col-sm-5 ps-sm-0 order-1 order-sm-2">
          <img alt="" style="cursor: pointer;" [routerLink]="['/shop/product-details']" 
          [queryParams]="{ part_Number : showPartData.part_number }" 
          [src]="router.url.includes('active-orders') ? showPartData?.part_image : showPartData?.image" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
  
<ng-template #confimationmodal let-d="dismiss">
  <app-selectCartType [btnshowConfrimed]="btnshowConfrimed" [btnshowsubmit]="btnshowsubmit"
    [currentlyAddingProduct]="selectedProduct" [cartTypeList]="cartTypeList" [baseProductdata]="productInfo"
    (selectedCartType)="ShowPart()" (setCartType)="setAddCart(cartType,product)" (inputChange)="changeCartType($event)"
    (closeModal)="closePart()">
  </app-selectCartType>
</ng-template>