<div class="container-fluid orders">
    <div class="row">
        <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 pb-3 pt-3">
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                    <ul class="nav nav-tabs tabs1" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <h5 class="profile-heading mb-3">Set Password</h5>
                        </li>
                    </ul>
                    <div class="tab-content mt-3" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="nav-Items" role="tabpanel"
                            aria-labelledby="nav-Items-tab">
                            <div class="row">
                                <form [formGroup]="setpasswordForm">
                                    <div class="col-12 col-sm-10 col-md-7 col-lg-4 col-xl-3 pe-5">
                                        <label class="nav-link">Password<span class="error-message">*</span></label>
                                        <input type="password" 
                                            class="form-control position" id="checkout-Shop-name" formControlName="password"
                                            placeholder="Password" minlength="6" maxlength="20" required (keydown)="preventSpace($event)">
                                        <div class="error-message" *ngIf="submitted">
                                            <!-- <b> &nbsp; *invalid. </b> -->
                                            <mat-error class="position error-message" *ngIf="f.password.errors !== null && f.password.errors.required">
                                                Password is
                                                required</mat-error>
                                            <mat-error class="position error-message"
                                                *ngIf="f.password.errors !== null && !f.password.errors.pattern && !f.password.errors.required && ( f.password.errors.minLength ) ">
                                                Password must
                                                be at least 6 characters
                                            </mat-error>
                                            <mat-error class="position error-message"
                                                *ngIf="f.password.errors !== null && f.password.errors.pattern && !f.password.errors.required">
                                                    Your password must meet the following criteria:
                                                    <ul>
                                                        <li>At least one uppercase character: Include at least one uppercase letter (A-Z).</li>
                                                        <li>At least one lowercase character: Include at least one lowercase letter (a-z).</li>
                                                        <li>At least one numeric digit: Include at least one number (0-9).</li>
                                                        <li>At least one special character: Include at least one special character, such as !, @, #, $, %, etc.</li>
                                                    </ul>  
                                            </mat-error>
                                        </div><br><br>
                                        <label class="nav-link">Confirm Password<span
                                                class="error-message">*</span></label>
                                        <input type="password" 
                                            class="form-control position" id="checkout-address"
                                            formControlName="confirm_password" placeholder="Confirm password"
                                            minlength="6" maxlength="20"
                                            (change)="checkValidation($event.target.value)" (keydown)="preventSpace($event)">
                                        <div class="error-message" *ngIf="submitted">
                                            <mat-error class="position error-message"
                                                *ngIf="f.confirm_password.errors !== null && f.confirm_password.errors.required">
                                                Confirm Password is required</mat-error>
                                            <mat-error class="position error-message"
                                                *ngIf="(passwordmatch && f.confirm_password.value) ||
                                                    (f.confirm_password.value.length < 6 && f.confirm_password.value.length !== 0)">
                                                Password and confirm password does not match</mat-error>
                                        </div><br><br>
                                        <button type="button" [disabled]="btndisabled" class="btn btn-primary position"
                                            (click)="onSubmit()">Save</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>