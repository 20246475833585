<div class="container-fluid colntainer-md pt-3 margin-top-for-mobile">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 Fcol-xl-12 col-xxl-12 product-lists">
            <ul class="nav nav-tabs tabs1" id="myTab" role="tablist">
                <li class="nav-item" role="presentation" [routerLink]="['/account/myoffer']" [queryParams]="{'isMyOffer':true}">
                    <a class="nav-link" id="order-tab" data-bs-toggle="tab"  role="tab"
                        aria-controls="order" aria-selected="true">My Discounts</a>
                </li>
                <li class="nav-item" role="presentation" [routerLink]="['/account/coupons']" [queryParams]="{'isMyOffer':true}">
                    <a class="nav-link active" id="order-tab" data-bs-toggle="tab"  role="tab"
                        aria-controls="order" aria-selected="true">Coupons</a>
                </li>
                <li class="nav-item" role="presentation" [routerLink]="['/account/schemes']" [queryParams]="{'isMyOffer':true}">
                    <a class="nav-link" id="order-tab" data-bs-toggle="tab"  role="tab"
                        aria-controls="order" aria-selected="true">My Schemes</a>
                </li>
                <li  class="nav-item" role="presentation" [routerLink]="['/account/combo-offer']" [queryParams]="{'isMyOffer':true}">
                    <a class="nav-link" id="order-tab" data-bs-toggle="tab"  role="tab"
                        aria-controls="order" aria-selected="true">My Combo Offers</a>
                  </li>
            </ul>
            <hr class="hr-page-divider" />
            <!-- <h4 style="float:left;" class="">My Discounts</h4> -->
            <div *ngIf="ProductListData?.length == 0 && isNoDataFound" class="empty-box" style="margin-top: 10%;">
                <img src="./assets/images/empty box.png" />
                <p class="text-center">No Coupons Available</p>
            </div>
            <!-- Card "Grid"-->
            <div class="row">
                <div class="col-12">
                    <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4 row-cols-xl-4 row-cols-xxl-4 g-3" *ngIf="ProductListData?.length > 0; else skeletonForGrid">
                        <div *ngFor="let product of ProductListData">
                            <div class="card border-0">
                                <div class="card-body p-3 pb-0">
                                    <div  style="margin-top: 5px;" [breadcrumbs]="breadcrumbs">
                                        <h5 class="fw-bold prod-model mb-2 cursor" (click)="openCouponDetail(product)">{{ product.coupon_code }}</h5>
                                        <h6>{{ product.discount_name }}</h6>
                                    </div>
                                    <div class="col-12">
                                        <h6 *ngIf="product.isShowOnlyToDate==false">Validity: {{product.from_dt}} - {{product.to_dt }}</h6>
                                        <h6 *ngIf="product.isShowOnlyToDate==true">Valid till {{product.to_dt }}</h6>
                                    </div>
                                    <div class="row col-12" style="margin-bottom: 10px;">
                                        <div class="col-6"></div>
                                        <div (click)="openCouponDetail(product)" [breadcrumbs]="breadcrumbs" class="prod-img mx-auto text-center  position-relative col-6">
                                            <img [src]="product?.discount_image_path" onerror="this.src='./assets/tata-vehicle.jfif';"
                                            class="img-fluid cursor" alt="prod-img-alt" srcset=""
                                            style="max-width: 100%; max-height: 100%; top: 50%; position: absolute; transform: translate(-50%, -50%);" />
                                        </div>
                                    </div>
                                    <div class="prod-content position-relative row col-12">
                                        <p class="oneLine" [innerHTML]="product.discount_description"></p><br>
                                        <p (click)="openCouponDetail(product)" style="cursor: pointer;">
                                            <a *ngIf="product.discount_description && product.discount_description.length > 30" class="text-nowrap stretched-link">Read More</a>
                                        </p>
                                    </div>
                                </div>
                                <div class="discount">
                                    <!-- [routerLink]="['/shop/product-details']" [queryParams]="{ part_Number : product.part_number }"> -->
                                    {{'SAVE Rs.' + product.flat_discount }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #skeletonForGrid>
                <div *ngIf=" ProductListData?.length == 0 && !isNoDataFound">
                    <div class="col-12" style="display: flex; flex-wrap: wrap; flex-direction:row;">
                        <div class="row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4 row-cols-xl-4 row-cols-xxl-4 g-4"
                            *ngFor="let item of generateFake(12);let i = index;" style="padding: 25px;">
                            <div class="card border-0" style="width: 100%;">
                                <div class="card-body p-3 ">
                                    <div class="prod-content position-relative">
                                        <skc-rectangle [loading]="loading" [skc-height]="'12px'" [skc-width]="'250px'">
                                        </skc-rectangle>
                                    </div>
                                    <div class="prod-img mx-auto text-center  position-relative">
                                        <skc-rectangle [loading]="loading" [skc-height]="'12px'" [skc-width]="'250px'">
                                        </skc-rectangle>
                                        <skc-rectangle [loading]="loading" [skc-height]="'122px'" [skc-width]="'250px'">
                                        </skc-rectangle>
                                    </div>
                                    <div class="prod-content position-relative">
                                        <skc-rectangle [loading]="loading" [skc-height]="'8px'" [skc-width]="'250px'">
                                        </skc-rectangle>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <!-- End of cards -->
        </div>
    </div>
</div>

<ng-template #openCouponDetailsModal let-d="dismiss">
    <app-coupon-details [couponDetails]="couponDetail" (closeCouponDetails)="this.openCouponDetailsModalDetails.hide()"  ></app-coupon-details>
</ng-template>