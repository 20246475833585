import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { InputNumberComponent } from './components/input-number/input-number.component';
// import { CartService } from './services/cart.service';
import { PaginationComponent } from './components/pagination/pagination.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ProductdetailsComponent } from './components/productdetails/productdetails.component';
import { OtpModalComponent } from "./modals/otp/otp-modal.component";
import { SelectUserComponent } from "./modals/select-user/select-user.component";
//import { MaskPipe } from './pipes/mask.pipe';
//import { CurrencyFormatPipe } from "./pipes/currency-format.pipe";
import { LogoutModalComponent } from './modals/logout/logout.component';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { SelectCartTypeComponent } from "./modals/select-carttype/select-carttype.component";
import { DeliveryAddressComponent } from "./modals/delivery-address/delivery-address.component";
import { MaskPipe } from './pipes/mask.pipe';
import { CurrencyFormatPipe } from './pipes/currency-format.pipe';
import { LoyaltyPointsModalComponent } from './modals/loyalty-points/loyalty-points.component';
import { SelectUserTypeComponent } from './modals/select-user-type/select-user-type.component';
import { FleetDetailsComponent } from './modals/fleet-details/fleet-details.component';
import { MatCardModule } from '@angular/material/card';
import { OrderDetailsComponent } from './modals/order-details/order-details.component';
import { AddVehicleComponent } from './modals/add-vehicle/add-vehicle.component';
import { ViewDocumentComponent } from './modals/view-document/view-document.component';
import { BuyAgainComponent } from './modals/buy-again/buy-again.component';
import { AlternatePartComponent } from './modals/alternate-part/alternate-part.component';

import { AgmCoreModule } from '@agm/core';
import { NearbyMapLocatorComponent } from './components/nearby-map-locator/nearby-map-locator.component';
import { SearchPipe } from "./pipes/search.pipe";
import {MatCheckboxModule} from '@angular/material/checkbox';
import { AddAddressComponent } from './modals/add-address/add-address.component';
import {MatRadioModule} from '@angular/material/radio';
import { NgSelectModule } from '@ng-select/ng-select';
import { AddUserComponent } from './modals/add-user/add-user.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatSelectModule} from '@angular/material/select';
import { AddAccountComponent } from './modals/add-account/add-account.component';
import { ChildPartsComponent } from './modals/child-parts/child-parts.component';
import { InvoiceComponent } from './modals/invoices/invoices.component';
import { InvoiceLogComponent } from './modals/invoice-log/invoice-log.component';
import { InvoiceDetailsComponent } from './modals/invoice-details/invoice-details.component';
import { MechanicDetailsComponent } from './modals/mechanic-details/mechanic-details.component';
import { GstNumberComponent } from './modals/gst-number/gst-number.component';
import { PaymentHistoryComponent } from './modals/payment-history/payment-history.component';
import { AccidentalCoverComponent } from './modals/accidenal-cover/accidenal-cover.component';
import { RatingModule } from 'ng-starrating';
import { InvoiceReturnListComponent } from './modals/invoice-list/invoice-list.component';
import { ProductDetailEngineComponent } from './components/product-detail-engine/product-detail-engine.component';
import { ProductmodelComponent } from './modals/productmodel/productmodel.component';
import { ProductsubmodelComponent } from './modals/productsubmodel/productsubmodel.component';
import { OrderPaginationComponent } from './components/order-pagination/order-pagination.component';
import { MatIconModule } from '@angular/material/icon';
import { ProductcompatiblemodelComponent } from './components/productcompatiblemodel/productcompatiblemodel.component';
import { ProductspecificationComponent } from './components/productspecification/productspecification.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FeedbackComponent } from './modals/feedback/feedback.component';
import { DataTablesModule } from 'angular-datatables';
import { GstPermissionComponent } from './modals/gst-permission/gst-permission.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { NewOrderDetailsComponent } from './modals/new-order-details/new-order-details.component';
import { ReturnOrderComponent } from './modals/return-order/return-order.component';
import { SkeletonLoaderComponent } from './components/skeleton-loader/skeleton-loader.component';
import { PartDiscountsComponent } from './modals/part-discounts/part-discounts.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { TimelineReportComponent } from './modals/timeline-report/timeline-report.component';
import { NgxSkeletonSmartModule } from 'ngx-smart-skeleton';
import { ClickElsewhereDirective } from './helpers/clickelsewhere.directive';
import { AggregatesInPartListComponent } from './modals/aggregates-in-partlist/aggregates-in-partlist.component';
import { AllModelsComponent } from './modals/all-models/all-models.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ImageZoomViewComponent } from './modals/image-zoom-view/image-zoom-view.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { VideoPlayerComponent } from './modals/video-player/video-player.component';
import { QueryFormComponent } from './modals/query-form/query-form.component';
import { InvoiceTransactionLogComponent } from './modals/invoice-transaction-log/invoice-transaction-log.component';
import { InvoicePaymentConfirmationComponent } from './modals/invoice-payment-confirmation/invoice-payment-confirmation.component';
import { CashInvoiceLogComponent } from './modals/cash-invoice-log/cash-invoice-log.component';
import { RouterModule } from '@angular/router';
import { PartpopupComponent } from './modals/partpopup/partpopup.component'
import { WebSpeechComponent } from './modals/web-speech/web-speech.component';
import { WishlistIconComponent } from './components/wishlist-icon/wishlist-icon.component';
import { AddtocarticonComponent } from './components/addtocarticon/addtocarticon.component';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { AddContactPersonComponent } from './modals/add-contact-person/add-contact-person.component';
import { ContactPersonListComponent } from './modals/contact-person-list/contact-person-list.component';
import { CouponDetailsComponent } from './modals/coupon-details/coupon-details.component';
import { RegisteredPhoneNumberComponent } from './modals/registered-phone-number/registered-phone-number.component';
import { MatDialogModule } from '@angular/material/dialog';
import {MatListModule} from '@angular/material/list';
import { KnownNumbersMaskPipe } from './pipes/known-numbers.pipe';
import { CreditDetailsComponent } from './modals/credit-details/credit-details.component';
import { SheduleOrderFormComponent } from './modals/shedule-order-form/shedule-order-form.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import {MatTabsModule} from '@angular/material/tabs';
import { SheduleOrderDetailsComponent } from './modals/shedule-order-details/shedule-order-details.component';
import { CoachMarksComponent } from './components/coach-marks/coach-marks.component';
import { CurrencyNumToCurrencyTextPipe } from './pipes/currency-num-to-currency-text.pipe';
import { ScheduleOrderConfirmationComponent } from './modals/shedule-order-form/schedule-order-confirmation/schedule-order-confirmation.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SchemeDetailsComponent } from './modals/scheme-details/scheme-details.component';
import { ProductDetailsSchemesComponent } from './modals/product-details-schemes/product-details-schemes.component';
import { PaymentgatewayTypeComponent } from './modals/paymentgateway-type/paymentgateway-type.component';
import { MultiSellerComponent } from './modals/multi-seller/multi-seller.component';
import { BulkUploadPartDetailsComponent } from './modals/bulk-upload-part-details/bulk-upload-part-details.component';
import { FitmentSegmentComponent } from './modals/fitment-segment/fitment-segment.component';
import {MatChipsModule} from '@angular/material/chips';
import { ComboofferdetailComponent } from './modals/comboofferdetail/comboofferdetail.component';
import { PdpFullimageComponent } from './modals/pdp-fullimage/pdp-fullimage.component';
import { TrackingStatusComponent } from './modals/tracking-status/tracking-status.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { QrScannerComponent } from './modals/qr-scanner/qr-scanner.component';
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { DiscountModelComponent } from './modals/discount-model/discount-model.component';
import { ShipmentDetailsComponent } from './modals/shipment-details/shipment-details.component';
import { ShippingLogComponent } from './modals/shipping-log/shipping-log.component';
import { FullImagePopupComponent } from './modals/full-image-popup/full-image-popup.component';

@NgModule({
  declarations: [
    InputNumberComponent,
    PaginationComponent,
    InvoiceDetailsComponent,
    OrderPaginationComponent,
    ProductdetailsComponent,
    InvoiceLogComponent,
    GstNumberComponent,
    PaymentHistoryComponent,
    InvoiceReturnListComponent,
    OtpModalComponent,
    SelectUserTypeComponent,
    OrderDetailsComponent,
    InvoiceComponent,
    AccidentalCoverComponent,
    SelectUserComponent,
    PaginationComponent,
    LoyaltyPointsModalComponent,
    FleetDetailsComponent,
    ReturnOrderComponent,
    LogoutModalComponent,
    SelectCartTypeComponent,
    DeliveryAddressComponent,
    MaskPipe,
    KnownNumbersMaskPipe,
    ChildPartsComponent,
    FeedbackComponent,
    CurrencyFormatPipe,
    AddVehicleComponent,
    ViewDocumentComponent,
    SearchPipe,
    AddAddressComponent,
    AddUserComponent,
    AddAccountComponent,
    ProductDetailEngineComponent,
    ProductmodelComponent,
    ProductsubmodelComponent,
    ProductcompatiblemodelComponent,
    ProductspecificationComponent,
    GstPermissionComponent,
    PageHeaderComponent,
    NewOrderDetailsComponent,
    SkeletonLoaderComponent,
    PartDiscountsComponent,
    TimelineReportComponent,
    ClickElsewhereDirective,
    AggregatesInPartListComponent,
    AllModelsComponent,
    ImageZoomViewComponent,
    VideoPlayerComponent,
    QueryFormComponent,
    BuyAgainComponent,
    AlternatePartComponent,
    InvoiceTransactionLogComponent,
    InvoicePaymentConfirmationComponent,
    PartpopupComponent,WebSpeechComponent,WishlistIconComponent,AddtocarticonComponent,
    NearbyMapLocatorComponent,
    MechanicDetailsComponent,
    CashInvoiceLogComponent,
    AddContactPersonComponent,
    ContactPersonListComponent,
    CouponDetailsComponent,
    RegisteredPhoneNumberComponent,
    CreditDetailsComponent,
    SheduleOrderFormComponent,
    SheduleOrderDetailsComponent,
    CoachMarksComponent,
    CurrencyNumToCurrencyTextPipe,
    ScheduleOrderConfirmationComponent,
    SchemeDetailsComponent,
    ProductDetailsSchemesComponent,
    PaymentgatewayTypeComponent,
    MultiSellerComponent,
    BulkUploadPartDetailsComponent,
    FitmentSegmentComponent,
    ComboofferdetailComponent,
    PdpFullimageComponent,
    TrackingStatusComponent,
    QrScannerComponent,
    DiscountModelComponent,
    ShipmentDetailsComponent,
    ShippingLogComponent,
    FullImagePopupComponent
  ],
  imports: [
    AgmCoreModule,
    NgbModule,
    CommonModule,
    DataTablesModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSkeletonSmartModule,
    NgbPopoverModule,
    ModalModule.forRoot(),
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatIconModule,
    MatRadioModule,
    RatingModule,
    NgSelectModule,
    MatGridListModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatSelectModule,
    DataTablesModule,
    CarouselModule,
    MatTooltipModule,
    MatFormFieldModule,
    RouterModule,
    MatDialogModule,
    MatButtonModule,
    MatListModule,
    MatChipsModule,
    MatExpansionModule,
    NgxScannerQrcodeModule,
    MatExpansionModule
  ],
  exports: [
    AgmCoreModule,
    MatButtonModule,
    InputNumberComponent,
    OrderPaginationComponent,
    FeedbackComponent,
    PaginationComponent,
    GstNumberComponent,
    PaymentHistoryComponent,
    AccidentalCoverComponent,
    OtpModalComponent,
    ChildPartsComponent,
    InvoiceComponent,
    InvoiceLogComponent,
    InvoiceDetailsComponent,
    InvoiceReturnListComponent,
    SelectUserComponent,
    LoyaltyPointsModalComponent,
    SelectUserTypeComponent,
    FleetDetailsComponent,
    ReturnOrderComponent,
    LogoutModalComponent,
    SelectCartTypeComponent,
    DeliveryAddressComponent,
    MaskPipe,
    KnownNumbersMaskPipe,
    AddAccountComponent,
    CurrencyFormatPipe,
    OrderDetailsComponent,
    AddVehicleComponent,
    ViewDocumentComponent,
    SearchPipe,
    AddAddressComponent,
    AddUserComponent,
    GstPermissionComponent,
    PageHeaderComponent,
    NewOrderDetailsComponent,
    SkeletonLoaderComponent,
    PartDiscountsComponent,
    TimelineReportComponent,
    ClickElsewhereDirective,
    AggregatesInPartListComponent,
    AllModelsComponent,
    ImageZoomViewComponent,
    VideoPlayerComponent,
    QueryFormComponent,
    BuyAgainComponent,
    AlternatePartComponent,
    InvoiceTransactionLogComponent,
    PartpopupComponent,
    WebSpeechComponent,WishlistIconComponent,AddtocarticonComponent,
    NearbyMapLocatorComponent,
    MechanicDetailsComponent,
    MultiSellerComponent,
    InvoicePaymentConfirmationComponent,
    NgbPopoverModule,
    CashInvoiceLogComponent,
    AddContactPersonComponent,
    ContactPersonListComponent,
    CouponDetailsComponent,
    CreditDetailsComponent,
    SheduleOrderFormComponent,
    SheduleOrderDetailsComponent,
    CoachMarksComponent,
    SchemeDetailsComponent,
    PaymentgatewayTypeComponent,
    CurrencyNumToCurrencyTextPipe,
    BulkUploadPartDetailsComponent,
    FitmentSegmentComponent,
    ProductDetailsSchemesComponent,
    ComboofferdetailComponent,
    QrScannerComponent,
    DiscountModelComponent,
    ShipmentDetailsComponent,
    ShippingLogComponent,
    FullImagePopupComponent
],
  providers: [BsModalService,NgbPopoverModule],
})
export class SharedModule {}
