<!-- <a (mousedown)="sub()" class="add-decrement">-</a>
<span>
       <input  type="text" style="width: 20px !important;"
      
       [min]="options.min"
       [max]="options.max"
       [value]="defaultValue"
       [disabled]="options.disabled"
       [readOnly]="options.readonly"
       (input)="input()"
       (blur)="onTouched()" numbersOnly maxlength="8"  #inputElement >
</span>
<a (mousedown)="add()" class="add-increment">+</a> -->

<div [ngClass]="isEmpty ? 'quantity-sec-error d-inline-block' : 'quantity-sec d-inline-block'">
       <div class="qty-input">
                
               <!-- onKeyPress="if(this.value.length==8) return false;" maxlength="8"  -->
              <!-- (change)="quantityChange($event)" class="product-qty" -->
              <button class="qty-count qty-count--minus" (mousedown)="sub()" data-action="minus" type="button">-</button>
              <input [ngClass]="value>=9999 ? 'product-qty tmgoqty' :'product-qty'" 
               oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
    type = "number"
    [maxLength] = "maxLength"
               name="product-qty" [min]="options.min"  
              [max]="options.max"
              [value]="defaultValue"
              [disabled]="options.disabled"
              [readOnly]="options.readonly"
              id="qInput" #qInput
              (input)="input(qInput.value)" 
              (focusout)="checkInput(qInput.value)"
              (blur)="onTouched()" numbersOnly  #inputElement>
              <button [disabled]="disablebtn" class="qty-count qty-count--add" (mousedown)="add()" data-action="add" type="button">+</button>
       </div>
</div>

<!-- (keydown.backspace)="onBackspaceKeydown($event)" -->

<!-- <input class="form-control input-number__input" type="text" style="width: 20px;"
       [ngClass]="{'form-control-sm': size === 'sm', 'form-control-lg': size === 'lg'}"
       [min]="options.min"
       [max]="options.max"
       [value]="value"
       [disabled]="options.disabled"
       [readOnly]="options.readonly"
       (input)="input()"
       (blur)="onTouched()" numbersOnly maxlength="8"  #inputElement >

<div class="input-number__add" (mousedown)="add()"></div>
<div class="input-number__sub" (mousedown)="sub()"></div> -->