<div class="container-fluid e-dukaan-notification d-flex">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex align-items-center pt-2">
            <span class="caret-up-down" (click)="clickEvent()">
                <i class="fas" aria-hidden="true" [ngClass]="status ? 'fa-bars' : 'fa-bars'"></i>
            </span>
            <h2 class="page-title">Notifications</h2>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 d-flex">
            <div class="side-wrapper mt-3" [ngClass]="status ? 'side-small-width' : 'side-width'">
                <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <button [class]="activeVal=='TML'?'nav-link active d-flex':'nav-link d-flex'" id="v-pills-home-tab" type="button" role="tab"  (click)="updateTab('TML',10,0)"> 
                         <img alt="" src="assets/images/m6.png" />TML<span class="ms-auto">({{tmlCount}})</span></button>
                    <button [class]="activeVal=='inbox'?'nav-link active d-flex':'nav-link d-flex'" id="v-pills-profile-tab" type="button" role="tab" (click)="updateTab('inbox',10,0)">
                        <img alt="" src="assets/images/m4.png" />Personal<span class="ms-auto">({{inboxCount}})</span></button>
                    <button [class]="activeVal=='DLR'?'nav-link active d-flex':'nav-link d-flex'" id="v-pills-messages-tab" type="button" role="tab"  (click)="updateTab('DLR',10,0)">
                        <img alt="" src="assets/images/m2.png" />Distributor<span class="ms-auto">({{otherCount}})</span></button>
                </div>
            </div>
            <div  class="content-wrapper mt-3" [ngClass]="status ? 'content-full-width' : 'content-width'" #topContainer  (scroll)="onScroll($event)">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <nav>
                            <div class="nav nav-tabs tabs1" id="nav-tab" role="tablist">
                                <button [class]="activeVal=='TML'?'nav-link active d-flex':'nav-link d-flex'" id="nav-tata-tab"  type="button" role="tab"  (click)="updateTab('TML',10,0)"> 
                                   <img alt="" src="assets/images/m6.png" />TML <span class="ms-auto ps-3">({{tmlCount}})</span></button>
                                <button [class]="activeVal=='inbox'?'nav-link active' :'nav-link'" id="nav-edukaan-tab" type="button" role="tab"  (click)="updateTab('inbox',10,0)">
                                    <img alt="" src="assets/images/m4.png" />Personalised<span class="ms-auto ps-3">({{inboxCount}})</span></button>
                                <button [class]="activeVal=='DLR'?'nav-link active' :'nav-link'" id="nav-others-tab" type="button" role="tab"  (click)="updateTab('DLR',10,0)">
                                    <img alt="" src="assets/images/m2.png" />Distributor<span class="ms-auto ps-3">({{otherCount}})</span></button>
                            </div>
                        </nav>
                        <div class="tab-content" id="nav-tabContent">
                            <div class="tab-pane fade show active" id="nav-tata" role="tabpanel" aria-labelledby="nav-tata-tab">
                                <div *ngIf="!isApiLoaded ; else elseTimlineData " class="row">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"  *ngFor="let item of userService.generateFake(6);">
                                        <div class="card mb-3">
                                            <div class="card-body">
                                                <div class="message-title">
                                                    <skc-rectangle [loading]="loading" [skc-height]="'18px'" [skc-width]="'70%'"> </skc-rectangle>
                                                </div>
                                                    <div class="messages-media">
                                                        <skc-rectangle [loading]="loading" [skc-height]="'110px'" [skc-width]="'100%'"> </skc-rectangle>
                                                        <div class="d-flex">
                                                            <skc-rectangle [loading]="loading" [skc-height]="'10px'" [skc-width]="'100%'"> </skc-rectangle>
                                                            <skc-rectangle [loading]="loading" [skc-height]="'10px'" [skc-width]="'100%'"> </skc-rectangle>
                                                            <!-- <span class="icon-buttons me-2"><img alt="" src="../../assets/images/noti-image.png"/></span>
                                                            <span class="icon-buttons me-2"><img alt="" src="../../assets/images/noti-play.png"/></span> -->
                                                        </div>
                                                    </div>
                                                <div class="message-content">
                                                    <div [ngClass]="{'limitTextHeight': isReadMore}">
                                                        <skc-rectangle [loading]="loading" [skc-height]="'25px'" [skc-width]="'100%'"> </skc-rectangle>
                                                    </div>
                                                    <a class="link-all" type="button" (click)="showText()">
                                                        <skc-rectangle [loading]="loading" [skc-height]="'5px'" [skc-width]="'5%'"> </skc-rectangle>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ng-template #elseTimlineData>
                                    <div *ngIf="timelineData && !isEmptyObject(timelineData)" class="row">
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"  *ngFor="let timline of timelineData; let i=index">
                                            <div class="card mb-3">
                                                <div class="card-body">
                                                    <div class="message-title">{{ timline.title }} 
                                                        <span *ngIf="activeVal=='inbox'">
                                                            {{ timline.account_id }}
                                                        </span> 
                                                    </div>
                                                    <div *ngIf="timline.image_url" class="messages-media">
                                                        <ng-container *ngIf="timline.extention == 'png' || timline.extention == 'jpeg' || timline.extention == 'jpg'">
                                                            <img class="img-fluid" [src]="timline.image_url" />
                                                        </ng-container>
                                                        <!-- <ng-template >
                                                            <iframe [src]="timline.image_url"></iframe>
                                                            
                                                            Download {{ timline.extention }} File
                                                            <a title="Download" target="_blank" style="color:#1a66ff; cursor: pointer;" (click)="downloadTimelineFile(timline.image_url)">
                                                                <i class="fas fa-download"></i>
                                                            </a>

                                                        </ng-template> -->
                                                        <!-- <div class="d-flex">
                                                            <span class="icon-buttons me-2"><img alt="" src="assets/images/noti-image.png"/></span>
                                                        </div> -->
                                                    </div>
                                                    <div *ngIf="timline.video_url" class="messages-media">
                                                        <video controls id="{{ 'videoPlay' + i}}">
                                                            <source [src]="timline.video_url" type="video/mp4">
                                                        </video>
                                                        <div class="d-flex">
                                                            <span (click)="toggleVideo(i)" class="icon-buttons me-2"><img alt="" src="assets/images/noti-play.png"/></span>
                                                        </div>
                                                    </div>
                                                    <div class="message-content">
                                                        <div id="{{ 'notification-text' + i }}" class="limitTextHeight">
                                                            <ng-container *ngIf="timline.spaceInString; else elseNoSpaceTimelineDesc">
                                                                <p [innerHTML]="timline.post"></p>
                                                            </ng-container>
                                                            <ng-template #elseNoSpaceTimelineDesc>
                                                                <p [innerHTML]="timline.post | slice:0:100"></p>
                                                                <p [innerHTML]="timline.post | slice:101:200"></p>
                                                                <p [innerHTML]="timline.post | slice:201:300"></p>
                                                                <p [innerHTML]="timline.post | slice:301:400"></p>
                                                                <p [innerHTML]="timline.post | slice:401:450"></p>
                                                            </ng-template>
                                                            <!-- [ngClass]="{'limitTextHeight': isReadMore}" -->
                                                            <!-- Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorem amet quisquam
                                                            error deleniti odio accusamus sapiente asperiores? Alias cupiditate temporibus
                                                            ad.
                                                            Provident libero a quia voluptatibus fugiat optio. Reprehenderit, ipsam!
                                                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorem amet quisquam
                                                            error deleniti odio accusamus sapiente asperiores? Alias cupiditate temporibus
                                                            ad.
                                                            Provident libero a quia voluptatibus fugiat optio. Reprehenderit, ipsam! -->

                                                            <!-- {{ timline.post | slice:0:100 }}
                                                            {{ timline.post | slice:101:200 }}
                                                            {{ timline.post | slice:201:300 }}
                                                            {{ timline.post | slice:301:400 }}
                                                            {{ timline.post | slice:401:450 }}
                                                            <div class="col-9 font-weight-bolder" ></div> -->
                                                        </div>
                                                        <ng-container *ngIf="timline.post.length >= 110">
                                                            <a class="link-all" id="{{ 'read-more' + i }}" type="button" (click)="showText('more',i)">
                                                                Read More
                                                            </a>
                                                            <a class="link-all hideReadLess" id="{{ 'read-less' + i }}" type="button" (click)="showText('less',i)">
                                                                Read Less
                                                            </a>
                                                        </ng-container>
                                                    </div>
                                                    <ng-container *ngIf="(timline.extention != 'png' && timline.extention != 'jpeg' && timline.extention != 'jpg') && !timline.video_url && timline.image_url" >
                                                            <p style="text-align: center;
                                                            font-size: 20px;
                                                            font-weight: 700;">
                                                                <span style="color:#1a66ff;cursor:pointer;" (click)="downloadTimelineFile(timline.image_url)">
                                                                <u>Click here to download</u> 
                                                                </span>
                                                            </p>
                                                            
                                                    </ng-container >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!timelineData || isEmptyObject(timelineData)" class="row">
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                            <div class="card mb-3">
                                                <div style="padding: 5rem; font-weight: 500;" class="card-body">
                                                    There are no notification for this Timeline
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                                <!-- <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <div class="card mb-3">
                                            <div class="card-body">
                                                <div class="message-title">1 Messages Title Lorem ipsum dolor, sit amet consectetur
                                                    adipisicing elit. Dolorem amet quisquam error</div>
                                                    <div class="messages-media">
                                                        <img class="img-fluid" src="../../assets/images/tata-motors-aboutus.jpg" />
                                                        <div class="d-flex">
                                                            <span class="icon-buttons me-2"><img alt="" src="../../assets/images/noti-image.png"/></span>
                                                            <span class="icon-buttons me-2"><img alt="" src="../../assets/images/noti-play.png"/></span>
                                                        </div>
                                                    </div>
                                                <div class="message-content">
                                                    <div [ngClass]="{'limitTextHeight': isReadMore}">
                                                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorem amet quisquam
                                                        error deleniti odio accusamus sapiente asperiores? Alias cupiditate temporibus
                                                        ad.
                                                        Provident libero a quia voluptatibus fugiat optio. Reprehenderit, ipsam!
                                                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorem amet quisquam
                                                        error deleniti odio accusamus sapiente asperiores? Alias cupiditate temporibus
                                                        ad.
                                                        Provident libero a quia voluptatibus fugiat optio. Reprehenderit, ipsam!
                                                    </div>
                
                
                                                    <a class="link-all" type="button" (click)="showText()">
                                                        {{ isReadMore ? 'Read More': 'Read Less' }}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                            </div>
                            <!-- <div class="tab-pane fade" id="nav-edukaan" role="tabpanel" aria-labelledby="nav-edukaan-tab">
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <div class="card mb-3">
                                            <div class="card-body">
                                                <div class="message-title">1 Messages Title Lorem ipsum dolor, sit amet consectetur
                                                    adipisicing elit. Dolorem amet quisquam error</div>
                                                    <div class="messages-media">
                                                        <img class="img-fluid" src="../../assets/images/tata-motors-aboutus.jpg" />
                                                        <div class="d-flex">
                                                            <span class="icon-buttons me-2"><img alt="" src="../../assets/images/noti-image.png"/></span>
                                                            <span class="icon-buttons me-2"><img alt="" src="../../assets/images/noti-play.png"/></span>
                                                        </div>
                                                    </div>
                                                <div class="message-content">
                                                    <div [ngClass]="{'limitTextHeight': isReadMore}">
                                                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorem amet quisquam
                                                        error deleniti odio accusamus sapiente asperiores? Alias cupiditate temporibus
                                                        ad.
                                                        Provident libero a quia voluptatibus fugiat optio. Reprehenderit, ipsam!
                                                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorem amet quisquam
                                                        error deleniti odio accusamus sapiente asperiores? Alias cupiditate temporibus
                                                        ad.
                                                        Provident libero a quia voluptatibus fugiat optio. Reprehenderit, ipsam!
                                                    </div>
                
                
                                                    <a class="link-all" type="button" (click)="showText()">
                                                        {{ isReadMore ? 'Read More': 'Read Less' }}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <div class="card mb-3">
                                            <div class="card-body">
                                                <div class="message-title">1 Messages Title Lorem ipsum dolor, sit amet consectetur
                                                    adipisicing elit. Dolorem amet quisquam error</div>
                                                    <div class="messages-media">
                                                        <img class="img-fluid" src="../../assets/images/tata-motors-aboutus.jpg" />
                                                        <div class="d-flex">
                                                            <span class="icon-buttons me-2"><img alt="" src="../../assets/images/noti-image.png"/></span>
                                                            <span class="icon-buttons me-2"><img alt="" src="../../assets/images/noti-play.png"/></span>
                                                        </div>
                                                    </div>
                                                <div class="message-content">
                                                    <div [ngClass]="{'limitTextHeight': isReadMore}">
                                                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorem amet quisquam
                                                        error deleniti odio accusamus sapiente asperiores? Alias cupiditate temporibus
                                                        ad.
                                                        Provident libero a quia voluptatibus fugiat optio. Reprehenderit, ipsam!
                                                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorem amet quisquam
                                                        error deleniti odio accusamus sapiente asperiores? Alias cupiditate temporibus
                                                        ad.
                                                        Provident libero a quia voluptatibus fugiat optio. Reprehenderit, ipsam!
                                                    </div>
                
                
                                                    <a class="link-all" type="button" (click)="showText()">
                                                        {{ isReadMore ? 'Read More': 'Read Less' }}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="nav-others" role="tabpanel" aria-labelledby="nav-others-tab">
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <div class="card mb-3">
                                            <div class="card-body">
                                                <div class="message-title">1 Messages Title Lorem ipsum dolor, sit amet consectetur
                                                    adipisicing elit. Dolorem amet quisquam error</div>
                                                    <div class="messages-media">
                                                        <img class="img-fluid" src="../../assets/images/tata-motors-aboutus.jpg" />
                                                        <div class="d-flex">
                                                            <span class="icon-buttons me-2"><img alt="" src="../../assets/images/noti-image.png"/></span>
                                                            <span class="icon-buttons me-2"><img alt="" src="../../assets/images/noti-play.png"/></span>
                                                        </div>
                                                    </div>
                                                <div class="message-content">
                                                    <div [ngClass]="{'limitTextHeight': isReadMore}">
                                                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorem amet quisquam
                                                        error deleniti odio accusamus sapiente asperiores? Alias cupiditate temporibus
                                                        ad.
                                                        Provident libero a quia voluptatibus fugiat optio. Reprehenderit, ipsam!
                                                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorem amet quisquam
                                                        error deleniti odio accusamus sapiente asperiores? Alias cupiditate temporibus
                                                        ad.
                                                        Provident libero a quia voluptatibus fugiat optio. Reprehenderit, ipsam!
                                                    </div>
                
                
                                                    <a class="link-all" type="button" (click)="showText()">
                                                        {{ isReadMore ? 'Read More': 'Read Less' }}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <div class="card mb-3">
                                            <div class="card-body">
                                                <div class="message-title">1 Messages Title Lorem ipsum dolor, sit amet consectetur
                                                    adipisicing elit. Dolorem amet quisquam error</div>
                                                <div class="messages-media">
                                                    <img class="img-fluid" src="../../assets/images/tata-motors-aboutus.jpg" />
                                                    <div class="d-flex">
                                                        <span class="icon-buttons me-2"><img alt="" src="../../assets/images/noti-image.png"/></span>
                                                        <span class="icon-buttons me-2"><img alt="" src="../../assets/images/noti-play.png"/></span>
                                                    </div>
                                                </div>
                                                <div class="message-content">
                                                    <div [ngClass]="{'limitTextHeight': isReadMore}">
                                                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorem amet quisquam
                                                        error deleniti odio accusamus sapiente asperiores? Alias cupiditate temporibus
                                                        ad.
                                                        Provident libero a quia voluptatibus fugiat optio. Reprehenderit, ipsam!
                                                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorem amet quisquam
                                                        error deleniti odio accusamus sapiente asperiores? Alias cupiditate temporibus
                                                        ad.
                                                        Provident libero a quia voluptatibus fugiat optio. Reprehenderit, ipsam!
                                                    </div>
                
                
                                                    <a class="link-all" type="button" (click)="showText()">
                                                        {{ isReadMore ? 'Read More': 'Read Less' }}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 side-banner">
            <!-- <div class="mb-3">
                <img alt="" src="../../assets/images/profile.png" class="img-fluid"/>
            </div>
            <div class="mb-3">
                <img alt="" src="../../assets/images/profile.png" class="img-fluid"/>
            </div>
            <div class="mb-3">
                <img alt="" src="../../assets/images/profile.png" class="img-fluid"/>
            </div> -->
        </div>
    </div>
</div>

<div class="loader" *ngIf="showLoader">
    <div class="ng-spinner ng-spinner-icon"></div>
</div>