import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { applicationConfiguration, AppConfig } from '../../../../../src/app/config/app.config';
import { AppStateService } from '../app-state/app-state.service';
import { Router } from '@angular/router';
import { secureStorage } from '../securestorage';
import { isPlatformBrowser } from "@angular/common";

@Injectable({
  providedIn: 'root',
})
export class UserService {
  isLoggedIn: boolean = false;
  protected server = this.appConfig.apiUrl + 'api/v1/ecom/';
  protected newserver = this.appConfig.apiUrl + 'api/v2/ecom/';
  height:any;
  width:any;
  isMobile:boolean= false;

  readonly options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(
    @Inject(PLATFORM_ID)
    private platformId: any,
    private http: HttpClient,
    private appStateService: AppStateService,
    private router: Router,
    @Inject(applicationConfiguration) private appConfig: AppConfig,
    private localstorage: secureStorage,
  ) { 
    if (isPlatformBrowser(this.platformId)) {
      this.height = window.innerHeight;
      this.width = window.innerWidth
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }

    }
  }

  logout(): Observable<any>{
    return this.http.post(`${this.newserver}account/logout/`,this.options);
  }

  orderInprocessList(user): Observable<any> {
    return this.http.post(`${this.server}order/list/`, user, this.options);
  }

  newOrderInprocessList(user): Observable<any> {
    return this.http.post(
      `${this.newserver}order/otc_order_db_list/`,
      user,
      this.options
    );
  }

  getShedulerOrderList(request):Observable<any>{
    return this.http.post(`${this.newserver}order/schedule_order_list/`, request, this.options );
  }

  createSheduleOrder(request):Observable<any>{
    return this.http.post(`${this.newserver}order/create_schedule_order/`, request, this.options );
  }

  getSheduleOrderDetails(request):Observable<any>{
    return this.http.post(`${this.newserver}order/schedule_order_details/`, request, this.options );
  }

  orderList(user): Observable<any> {
    return this.http.post(`${this.server}user/OtcList/`, user, this.options);
  }

  orderDetails(order): Observable<any> {
    return this.http.post(
      `${this.newserver}order/otc_order_db_details/`,
      order,
      this.options
    );
  }

  getPartDiscounts(requestBody): Observable<any> {
    return this.http.post(
      `${this.newserver}order/get_part_discount/`,
      requestBody,
      this.options
    );
  }

  OrderDetailsForOTC(requestBody) {
    return this.http.post(
      `${this.newserver}order/order_details/`,
      requestBody,
      this.options
    );
  }

  newOrderList(data): Observable<any> {
    return this.http.post(
      `${this.newserver}order/otc_order_list/`,
      data,
      this.options
    );
  }

  invoiceOrderList(data): Observable<any>{
    return this.http.post(
      `${this.newserver}order/get_invoice_list/`,
      data,
      this.options
    )
  }
  loyalityList(data): Observable<any>{
    return this.http.post(
      `${this.newserver}order/get_loyalty_list/`,
      data,
      this.options
    )
  }
  loyalityListDetail(data): Observable<any>{
    return this.http.post(
      `${this.newserver}order/get_invoice_list/`,
      data,
      this.options
    )
  }
  loyalityDetails(data): Observable<any>{
    return this.http.post(
      `${this.newserver}order/get_loyalty_details/`,
      data,
      this.options
    )
  }
  loyalityLimitApi(data): Observable<any>{
    return this.http.post(
      `${this.newserver}order/loyalty_limit/`,
      data,
      this.options
    )
  }

  payInvoiceApi(data): Observable<any>{
    return this.http.post(
      `${this.newserver}order/loyalty_request/`,
      data,
      this.options
    )
  }
  getCreditList(requestBody: any): Observable<any> {
    return this.http.post(
      `${this.newserver}account/get_user_credit_list/`,
      requestBody,
      this.options
    )
  }

  invoiceTransactionLog(data):Observable<any>{
    return this.http.post(
      `${this.newserver}order/transaction_log_list/`,
      data,
      this.options
    )
  }

  invoicePaymentDetails(data):Observable<any>{
    return this.http.post(
      `${this.newserver}order/invoice_payment_detail/`, data, this.options );
  }

  profile(user): Observable<any> {
    return this.http.post(`${this.server}profile/`, user, this.options);
  }

  invoicePdfDownload(user): Observable<any> {
    return this.http.post(
      `${this.server}admin/InvoiceDetailWithPdf/`,
      user,
      this.options
    );
  }
  // async invoicePdfDownload1(data) {
  //   return await this.http
  //     .post(`${this.server}admin/InvoiceDetailWithPdf/`, data, this.options)
  //     .toPromise()
  //     .then((res) => {
  //       return res;
  //     });
  // }
  TatListBasedInvoiceView(user): Observable<any> {
    return this.http.post(
      `${this.newserver}order/get_invoice_tat/`,
      user,
      this.options
    );
  }
  getLoyaltyDetails(user): Observable<any> {
    return this.http.post(
      `${this.server}order/loyalty/details/`,
      user,
      this.options
    );
  }
  sendOtp(user): Observable<any> {
    return this.http.post(
      `${this.server}order/loyalty/otp/`,
      user,
      this.options
    );
  }
  verifyLoyaltyOtp(user): Observable<any> {
    return this.http.post(
      `${this.server}order/loyalty/verify/otp/`,
      user,
      this.options
    );
  }

  feedbackbinding(user): Observable<any> {
    return this.http.post(
      `${this.server}user/OrderFeedback/`,
      user,
      this.options
    );
  }
  PaymentDetails(data): Observable<any> {
    return this.http.post(
      `${this.server}order/order_by_id/`,
      data,
      this.options
    );
  }

  returnOrderDetails(requestBody): Observable<any> {
    return this.http.post(
      `${this.newserver}order/otc_order_db_details/`,
      requestBody,
      this.options
    );
  }

  feedbackFromInvoice(requestBody): Observable<any> {
    return this.http.post(
      `${this.newserver}order/invoice_feedback/`,
      requestBody,
      this.options
    );
  }

  invoiceListForFeedback(requestBody): Observable<any> {
    return this.http.post(
      `${this.newserver}order/get_invoice_list/`,
      requestBody,
      this.options
    );
  }

  orderCancelList(data): Observable<any> {
    return this.http.post(
      `${this.server}user/cancel_orders_list/`,
      data,
      this.options
    );
  }

  invoiceReturnList(requestBody): Observable<any> {
    return this.http.post(
      `${this.newserver}order/part_invoice_return_list/`,
      requestBody,
      this.options
    );
  }

  // async invoiceReturnListAwaited(requestBody): Promise<any> {
  //   return await this.http.post(`${this.newserver}order/part_invoice_return_list/`, requestBody, this.options).toPromise();
  // }

  async invoiceReturnListAwaited(requestBody): Promise<any> {
    return await this.http
      .post(
        `${this.newserver}order/part_invoice_return_list/`,
        requestBody,
        this.options
      )
      .toPromise();
  }

  invoiceReturnRequest(requestBody): Observable<any> {
    return this.http.post(
      `${this.newserver}order/part_return_request/`,
      requestBody,
      this.options
    );
  }

  cancelorderdetail(data): Observable<any> {
    return this.http.post(
      `${this.server}user/cancel_order_detail/`,
      data,
      this.options
    );
  }

  OrderPaymentDetails(data): Observable<any> {
    return this.http.post(
      `${this.server}user/payment_detail/`,
      data,
      this.options
    );
  }

  AddAccount(data): Observable<any> {
    return this.http.post(
      `${this.server}insert_acount_id/`,
      data,
      this.options
    );
  }

  GetAccountsearchData(data): Observable<any> {
    return this.http.post(`${this.server}get_account_id/`, data, this.options);
  }

  GetAccountList(data): Observable<any> {
    return this.http.post(
      `${this.newserver}account/get_account_list/`,
      data,
      this.options
    );
  }

  updateAccountStatus(requestBody) {
    return this.http.post(`${this.newserver}account/update_account/`, requestBody, this.options);
  }

  // GetVehicleList(data): Observable<any> {
  //   return this.http.post(`${this.server}get/vehicle_list/`, data, this.options);
  // }

  GetVehicleList(data): Observable<any> {
    return this.http.post(
      `${this.newserver}account/vehicle_list/`,
      data,
      this.options
    );
  }

  AddVehicle(data): Observable<any> {
    return this.http.post(
      `${this.newserver}account/add_vehicle/`,
      data,
      this.options
    );
  }

  // RemoveVehicle(data): Observable<any> {
  //   return this.http.post(`${this.server}remove_vehicle/`, data, this.options);
  // }
  RemoveVehicle(data): Observable<any> {
    return this.http.post(
      `${this.newserver}account/remove_vehicle/`,
      data,
      this.options
    );
  }

  UploadVehicle(data: FormData) {
    return this.http.post<any>(`${this.server}upload/vehicle/`, data);
  }

  OtherUserRegistration(data): Observable<any> {
    return this.http.post(
      `${this.server}other_user_registration/ `,
      data,
      this.options
    );
  }

  GetOtherUserRoleList(data): Observable<any> {
    return this.http.post(
      `${this.server}other_user/roles_list/`,
      data,
      this.options
    );
  }
  GetOtherUserList(data): Observable<any> {
    return this.http.post(`${this.server}other_user_list/`, data, this.options);
  }

  SendSMS(Data: any): Observable<any> {
    return this.http.post(
      `${this.server}other_user/set_password/`,
      Data,
      this.options
    );
  }

  GetOtherUserbyId(data): Observable<any> {
    return this.http.post(
      `${this.server}other_user/fetch_one/`,
      data,
      this.options
    );
  }

  GetVehicleCRMList(data): Observable<any> {
    return this.http.post(
      `${this.server}get_vehicles_info/ `,
      data,
      this.options
    );
  }

  RemoveOtherUser(data): Observable<any> {
    return this.http.post(
      `${this.server}other_user/remove/`,
      data,
      this.options
    );
  }

  SetUserNewPassword(user): Observable<any> {
    return this.http.post(
      `${this.server}other_user/reset_password/`,
      user,
      this.options
    );
  }

  OtherUserPermission: any;
  CheckPermission(accesscode) {
    try {
      let cartpermission = false;
      let isOtherUser = (this.localstorage.getItem('isOtherUser') == "false") ? false : (this.localstorage.getItem('isOtherUser') == "true") ? true : this.localstorage.getItem('isOtherUser');
      if (isOtherUser) {
        this.OtherUserPermission = JSON.parse(
          this.localstorage.getItem('OtherUserPermission')
        );
        if (this.OtherUserPermission) {
          let cartaccess = this.OtherUserPermission.filter(
            (user) => {
              // user.access_code === accesscode;
              if (user.access_code === accesscode) {
                return user;
              }
            }
          );
          if (cartaccess.length > 0) {
            if (cartaccess[0].role_name) {
              cartpermission = true;
            }
          } else {
            cartpermission = false;
          }
        }
        else if (isOtherUser && this.OtherUserPermission == null) {
          cartpermission = false;
        } else {
          cartpermission = true;
        }
      } else {
        cartpermission = true;
      }
      return cartpermission;
    } catch (error) {
      this.InsertExceptionLog({
        method_name: ' CheckPermission(accesscode)',
        page_name: 'UserService',
        portal_type: 'Customer',
        page_url: '',
        error_message: error,
        inner_exception: '',
      });
    }
  }

  GetUserEtaDetails(user): Observable<any> {
    return this.http.post(
      `${this.newserver}order/get_eta_calculation/`,
      user,
      this.options
    );
  }

  GetotcNoInvoiceDetails(user): Observable<any> {
    return this.http.post(
      `${this.server}user/OtcBasedinvoice/`,
      user,
      this.options
    );
  }

  GetInvoiceDetails(user): Observable<any> {
    return this.http.post(
      `${this.newserver}order/get_invoice_detail/`,
      user,
      this.options
    );
  }

  InsertExceptionLog(data) {
    try {
      this.isLoggedIn = this.appStateService.isLoggedIn.value;
      if (this.isLoggedIn) {
        if (
          this.localstorage.getItem('userData') != null ||
          this.localstorage.getItem('userData') != undefined
        ) {
          this.AddErrorLog(data).subscribe(
            (response) => {
              if (response.success === true) {
              } else {
              }
            },
            (error) => { }
          );
        }
      }
    } catch (error) { }
  }

  AddErrorLog(user): Observable<any> {
    return this.http.post(
      `${this.server}admin/portal/error_log/`,
      user,
      this.options
    );
  }

  GetEtaForOrder(user): Observable<any> {
    return this.http.post(
      `${this.server}user/eta_for_order/`,
      user,
      this.options
    );
  }

  getEtaForOrderSuccessPage(requestBody): Observable<any> {
    return this.http.post(`${this.newserver}order/get_eta_calculation/`, requestBody, this.options);
  }

  getEtaForOrderDetails(requestBody): Observable<any> {
    return this.http.post(`${this.server}user/eta_for_order/`, requestBody, this.options);
  }

  orderTrackingReport(user): Observable<any> {
    return this.http.post(
      `${this.server}user/mis_report_order_level/`,
      user,
      this.options
    );
  }

  DistributorList(Data): Observable<any> {
    return this.http.post(
      `${this.server}user/list/distributor/`,
      Data,
      this.options
    );
  }

  GetEtaList(Data): Observable<any> {
    return this.http.post(
      `${this.server}user/get/eta_list/`,
      Data,
      this.options
    );
  }

  //user-profile-api
  // userprofile(user): Observable<any> {
  //   return this.http.post(`${this.newserver}search/vehicle_category/`, user, this.options);
  // }

  //user-profile-api
  VechileListWithoutLogin(user): Observable<any> {
    return this.http.post(
      `${this.newserver}search/one_time_vehicle_list/`,
      user,
      this.options
    );
  }

  //user-profile-api
  userprofile(user): Observable<any> {
    return this.http.post(
      `${this.newserver}search/vehicle_category/`,
      user,
      this.options
    );
  }
  AddVehicleCheck(data): Observable<any> {
    return this.http.post(
      `${this.newserver}account/vehichle_check/`,
      data,
      this.options
    );
  }

  getActiveOrdersList(data): Observable<any> {
    return this.http.post(
      `${this.newserver}order/otc_order_db_list/`,
      data,
      this.options
    );
  }

  usersetPassword(data): Observable<any> {
    return this.http.post(
      `${this.newserver}account/set_password/`,
      data,
      this.options
    );
  }

  otherUserChangePassword(requestBody): Observable<any> {
    return this.http.post(`${this.server}other_user/reset_password/`, requestBody, this.options);
  }

  async orderAsyncList(user): Promise<any> {
    return await this.http
      .post(`${this.server}user/OtcList/`, user, this.options)
      .toPromise();
  }

  submitQuery(query): Observable<any> {
    return this.http.post(
      this.newserver + 'account/ask_part_query/',
      query,
      this.options
    );
  }

  getReturnedInvoiceList(data): Observable<any> {
    return this.http.post(
      `${this.newserver}order/cr_orders_list/`,
      data,
      this.options
    );
  }
  userHelplineQuery(data): Observable<any> {
    return this.http.post(
      `${this.newserver}account/insert_helpline_query/`,
      data,
      this.options
    );
  }
  userHelplineCategoryList(data): Observable<any> {
    return this.http.post(
      `${this.newserver}account/helpline_category_list/`,
      data,
      this.options
    );
  }

  getUserTimelineList(data): Observable<any>{
    return this.http.post(`${this.newserver}account/view_timeline_post/`, data, this.options)
  }
  
  async downloadEcomList(data) {
    return await this.http
      .post(`${this.newserver}order/otc_order_list/`, data, this.options)
      .toPromise()
      .then((res) => {
        return res;
      });
  }
  async downloadActiveOrderList(data) {
    return await this.http
      .post(`${this.newserver}order/otc_order_db_list/`, data, this.options)
      .toPromise()
      .then((res) => {
        return res;
      });
  }

  async downloadInvoicePaymentList(data) {
    return await this.http
      .post(`${this.newserver}order/get_invoice_list/`, data, this.options)
      .toPromise()
      .then((res) => {
        return res;
      })
  }

  async downloadEtaReport(data) {
    return await this.http
      .post(`${this.server}user/get/eta_list/`, data, this.options)
      .toPromise()
      .then((res) => {
        return res;
      })
  }

  getBannerImages(data): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    if(this.isMobile == true){
      headers = headers.set('Source', 'mobile_application');
    }
    else{
      headers = headers.set('Source', 'web_application');
    }
    return this.http.post(
      `${this.newserver}account/get_banner_list/`,
      data,
      {headers}
    );
  }

  clearAllCart(requestBody): Observable<any> {
    return this.http.post(
      `${this.newserver}cart/delete_cart_item/`,requestBody,this.options);
  }

  routeToProductDetails(partnumber) {
    this.router.navigate(['/shop/product-details'], {
      queryParams: { part_Number: partnumber },
    });
  }

  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }

  loadScript() {        
    var isFound = false;
    var scripts = document.getElementsByTagName("script")
    for (var i = 0; i < scripts.length; ++i) {
        if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("checkout")) {
            isFound = true;
        }
    }

    if (!isFound) {
        var dynamicScripts = ["https://checkout.razorpay.com/v1/checkout.js"];

        for (var i = 0; i < dynamicScripts.length; i++) {
            let node = document.createElement('script');
            node.src = dynamicScripts [i];
            node.type = 'text/javascript';
            node.async = false;
            node.charset = 'utf-8';
            document.getElementsByTagName('head')[0].appendChild(node);
        }

    }
  }

  getEdukaanTimeline(data): Observable<any>{
    return this.http.post(`${this.newserver}account/view_timeline_post/`, data, this.options);
  }

  async getEdukaanTimelineAsync(data): Promise<any>{
    return await this.http.post(`${this.newserver}account/view_timeline_post/`, data, this.options).toPromise();
  }

  getHeaderSearchInteligence(data): Observable<any>{
    return this.http.post('https://skindevreplica.api.tatamotors/api/v2/ecom/account/call/',data, this.options);
  }

  getUserCreditList(data):Observable<any>{
    return this.http.post(`${this.newserver}account/get_user_credit_list/`, data, this.options );
  }

  getMySavingAmount(data):Observable<any>{
    return this.http.post(`${this.newserver}api/v2/ecom/order/get_saving/`, data, this.options );
  }
  getMintifyCredit(data): Observable<any> {
    return this.http.post(
      `${this.newserver}order/check_mintifi_limit/`,
      data,
      this.options
    );
  }

  getEtaForDelhivery(requestBody): Observable<any> {
    return this.http.post(`${this.newserver}order/eta_for_delhivery/`, requestBody, this.options);
  }
  fetchTrackApi(data): Observable<any> {
    return this.http.post(
      `${this.newserver}order/get_invoice_tracking_details/`,
      data,
      this.options
    );
  }

  async downloadLoyaltyList(data) {
    return await this.http
      .post(`${this.newserver}order/get_invoice_list/`, data, this.options)
      .toPromise()
      .then((res) => {
        return res;
      });
  }

  async downloadCSRList(data) {
    return await this.http
      .post(`${this.newserver}order/get_loyalty_details/`, data, this.options)
      .toPromise()
      .then((res) => {
        return res;
      });
    }
    
  getDelhiveryWaybillDetails(requestBody): Observable<any> {
    return this.http.post(`${this.newserver}order/get_delhivery_waybill_details/`, requestBody, this.options);
  }

  generateDelhiveryShippingLabel(requestBody): Observable<any> {
    return this.http.post(`${this.newserver}order/generate_delhivery_shipping_label/`, requestBody, this.options);
  }
  getShippingDetails(requestBody): Observable<any> {
  return this.http.post(`${this.newserver}order/delhivery_configuration_detail/`, requestBody, this.options);
  }
}