import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from "@angular/common/http";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { AppStateService } from "../services/app-state/app-state.service";
import { CookieService } from 'ngx-cookie';
import { RootService } from '../services/REST/root.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { switchMap, take, filter } from 'rxjs/operators';
import { secureStorage } from "../services/securestorage";
import { isPlatformBrowser } from "@angular/common";

@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {
  isLoggedIn: boolean = false;
  private refreshTokenInProgress = false;
  private refreshTokenSubject: Subject<any> = new BehaviorSubject<any>(null);

  private FreeTokenInProgress = false;
  private FreeTokenSubject: Subject<any> = new BehaviorSubject<any>(null);
  height:any;
  width:any;
  isMobile:boolean= false;
  
  constructor(
    @Inject(PLATFORM_ID)
    private platformId: any,
    private rootService: RootService,
    public router: Router,
    public appStateService: AppStateService,
    private cookieService: CookieService,
    private localstorage: secureStorage
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.height = window.innerHeight;
      this.width = window.innerWidth
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    }
  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.indexOf('refresh') !== -1) {
      return next.handle(request);
    }
    const accessExpired = this.cookieService.getObject('access_token');
    const refreshExpired = this.cookieService.get('ref_token')
      ? this.cookieService.get('ref_token')
      : this.localstorage.getItem('ref_token');

    if (accessExpired && refreshExpired) {
      return next.handle(this.injectToken(request));
    }
    if (!accessExpired && !refreshExpired) {
      if (!this.FreeTokenInProgress) {
        this.FreeTokenInProgress = true;
        this.FreeTokenSubject.next(null);
      } else {
        this.isLoggedIn = this.appStateService.isLoggedIn.value;
        if (this.isLoggedIn) {
          if (!this.refreshTokenInProgress) {
            this.refreshTokenInProgress = true;
            this.refreshTokenSubject.next(null);
            if (!accessExpired) {
              if (
                this.localstorage.getItem('refreshTokenInProgress') != 'true'
              ) {
                this.localstorage.setItem('refreshTokenInProgress', 'true');
                return this.rootService.refreshToken2().pipe(
                  switchMap((authResponse) => {
                    if (authResponse) {
                      this.refreshTokenInProgress = false;
                    }
                    this.refreshTokenSubject.next(
                      authResponse.data.refresh_token
                    );
                    return next.handle(this.injectToken(request));
                  })
                );
              }
            } else {
            }
          } else {
            if (!accessExpired) {
              if (
                this.localstorage.getItem('refreshTokenInProgress') != 'true'
              ) {
                this.localstorage.setItem('refreshTokenInProgress', 'true');
                return this.rootService.refreshToken2();
              }
            }
          }
        } else {
          window.location.reload();
        }
      }
    }
    if (!accessExpired && refreshExpired) {
      if (!this.refreshTokenInProgress) {
        this.refreshTokenInProgress = true;
        this.refreshTokenSubject.next(null);
        if (!accessExpired) {
          if (this.localstorage.getItem('refreshTokenInProgress') != 'true') {
            this.localstorage.setItem('refreshTokenInProgress', 'true');
            return this.rootService.refreshToken2().pipe(
              switchMap((authResponse) => {
                if (authResponse) {
                  this.refreshTokenInProgress = false;
                }
                this.refreshTokenSubject.next(authResponse.data.refresh_token);
                return next.handle(this.injectToken(request));
              })
            );
          }
        } else {
        }
      } else {
        return this.refreshTokenSubject.pipe(
          filter((result) => result !== null),
          take(1),
          switchMap((res) => {
            return next.handle(this.injectToken(request));
          })
        );
      }
    }
    if (!accessExpired) {
      return next.handle(this.injectToken(request));
    }
  }
  injectToken(request: HttpRequest<any>) {
    const token = this.cookieService.getObject('access_token');
    if(request.url.includes('skindevreplica') || request.url.includes('skinprod') || request.url.includes('skinqa')){
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        },
      });
    }
    else{
      var Source = 'web_application' 
      if(request.url.includes('account/get_banner_list')){
        console.log("request",request.url)
        if(this.isMobile == true){
          Source = 'mobile_application'
        }
        else{
          Source = 'web_application'
        }
      }
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          Source: Source
        },
      });
    }
   
  }
}