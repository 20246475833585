<div class="modal-content">
    <div class="modal-header modal-title  pt-2 pb-2">
      {{ selectmodel }} : Sub Model 
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 search-model mb-3" *ngFor="let objmodel of allsubModelData.slice(0,5); let m = index">
          <div class="box-container"  (click)="GetVcNo(objmodel)" [ngClass]="activeModel == objmodel?.base_model_name ? 'active':''" style="  align-items: center; display: flex; justify-content: center;"> 
            <div class="text" title="{{objmodel?.base_model_name}}">{{objmodel?.base_model_name}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>