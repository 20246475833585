import { AfterViewInit, ChangeDetectorRef, Component, SimpleChanges, Input, OnInit, OnChanges, TemplateRef, ViewChild, EventEmitter, Output, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { InputPartSearch, Product } from '../../interfaces/product';
import { AppStateService } from '../../services/app-state/app-state.service';
import { ProductService } from '../../services/REST/product.service';
import { UserService } from '../../services/REST/user.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { QuickviewService } from '../../services/quickview.service';
import { ToastrService } from 'ngx-toastr';
import { CartService } from '../../services/cart.service';
import { ModelService } from '../../services/REST/model.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { blockPermissionMsg, Part_Search_Request_Of_Recommendation } from '../../../constant';
import { secureStorage } from '../../services/securestorage';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AddressService } from '../../services/REST/address.service';
import { Title } from '@angular/platform-browser';
import { MatRadioChange } from '@angular/material/radio';
declare var $: any;
interface ProductImage {
    id: string;
    url: string;
    active: boolean;
}
@Component({
    selector: 'app-productdetails',
    templateUrl: './productdetails.component.html',
    styleUrls: ['./productdetails.component.scss']
})
export class ProductdetailsComponent implements OnInit, AfterViewInit, OnChanges {
    
    // customOptions: OwlOptions = {
    //     items: 4,
    //     loop: false,
    //     mouseDrag: true,
    //     touchDrag: true,
    //     pullDrag: true,
    //     dots: false,
    //     navSpeed: 700,
    //     autoWidth: false,
    //     navText: [
    //       "<div><img src='./assets/images/button-circle-prev-white.svg' /></div>",
    //       "<div><img src='./assets/images/button-circle-next-white.svg' /></div>",
    //     ],
    //     responsive: {
    //       0: {
    //         items: 1,
    //       },
    //       400: {
    //         items: 2,
    //       },
    //       740: {
    //         items: 3,
    //       },
    //       940: {
    //         items: 4,
    //       },
    //     },
    //     nav: true,
    //   };
    @Input() breadcrumbs;
    public dtTrigger: Subject<any> = new Subject();
    @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective;
    newRequestBody: any;
    datatsetempty = false;
    dtOptions: DataTables.Settings = {};
    @ViewChild('selectModalPopup', { read: TemplateRef, static: false }) selectModalPopup: TemplateRef<any>;
    selectsubModalRef: any;
    @ViewChild('selectsubModalPopup', { read: TemplateRef, static: false }) selectsubModalPopup: TemplateRef<any>;
    selectModalRef: any;
    @ViewChild('schemeList', { read: TemplateRef, static: false }) schemeList: TemplateRef<any>;
    @ViewChild('fullImage', { read: TemplateRef, static: false }) fullImage: TemplateRef<any>;
    @ViewChild('fullImagePopup', { read: TemplateRef, static: false }) fullImagePopup: TemplateRef<any>;
    schemeListModal: any;
    divisionInfoData: any;
    showProduct: boolean = true;
    showProductEngine: boolean = false;
    partNumber: any;
    product: Product;
    productList :any;
    isLoggedIn: boolean;
    private dataProduct: Product;
    cartTypeList: any;
    btndisabled: boolean = false;
    btnshowsubmit: boolean = false;
    btnshowConfrimed: boolean = false;
    selectcarttype: any;
    Productdiscount: any;
    ProductMRP: any;
    quantity: FormControl = new FormControl(1);
    unit: FormControl = new FormControl(1);
    images: ProductImage[] = [];
    baseProductdiscount: any;
    baseProductdata: any;
    modalRef: any;
    modalRef1: any;
    showingQuickview = false;
    Selectaddress: any = "Select Address";
    SelectAddressId: any;
    offercard: any[] = [];
    @ViewChild('confimationmodal', { static: false }) template: TemplateRef<any>;
    @ViewChild('addressModal', { read: TemplateRef, static: false }) template1: TemplateRef<any>;
    @ViewChild('childParts', { read: TemplateRef, static: false }) childParts: TemplateRef<any>;
    @ViewChild('partDiscounts', { read: TemplateRef, static: false }) partDiscounts: TemplateRef<any>;
    @ViewChild('alternatePartModal', { read: TemplateRef, static: false }) alternatePartModal: TemplateRef<any>;
    usertype: any;
    addresses: any;
    pinCode: any;
    pinCodeNumber: number;
    addingToCart = false;
    showLoader = false;
    default_address: any;
    new_addresses: any = [];
    address_pk: any;
    account_pk: any;
    shippingaddressid: any;
    billingaddressid: any;
    alternateProductsList = [];
    currentQuantity: number = 1;
    savedAmount: number = 0;
    addressModal: any;
    cartTypeModal: any;
    btnshowChildParts: boolean = false;
    childPartData: any[];
    totalrecord: number;
    currentPage: number;
    childPartsModal: any;
    viewedItems = [];
    userQuery: any;
    isProductDetails: boolean = false;
    sendchildproduct: any;
    previousUrl;
    currentlyAddingProduct: any;
    recentItems: any[] = [];
    discountOffers: any;
    alternatePart: any;
    partDiscountsModal: any;
    Compatiblemodeldata: any;
    allModelData = [];
    variantList: any[] = [];
    variantdata = [];
    isQuantityDiscountAvailable: boolean = false;
    quantityBasedPartDetails: any;
    quantityBasedDivisionDetails: any;
    quantityBasedPartPrice: number = 0;
    normalPercentage: number = 0;
    quantityBasedPercentage: number = 0;
    showQuantityDiscountMessage: boolean = false;
    productNewValues: any;
    isNormalDiscount: boolean = false;
    selectedProduct: Product;

    alternatePartData: any;
    isWishlistedItem: any;

    overallPartList: any[];
    accountLevelPartList: any[];
    // fleetEdgePartList:any[];
    isApiRecommededLoad: boolean = false;

    discountOfferData: any = [];
    marketbaskpercentage:any;
    marketbasketquantity:any;
  isNoDataFound: boolean = false;
  vehicleList = [
    { name: 'Registration Number', id: '0', checked: true,disabled: false },
    { name: 'Chassis Number', id: '1', checked: false, disabled: false },
  ];
  chosenItem = this.vehicleList[0].name;
  checkCompatible:any='';
  errormsg :any;
  searchedVehicles:any
typevalue: string='';
isApiCalled:boolean = false;
compatibilityMessage: string;
isProductDetailComponent: boolean = true;
isProductDetailEngineComponent : boolean = false
    customOptions: OwlOptions = {
        items: 3,
        loop: false,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        rewind: true,
        autoplay: false,
        margin: 0,
        dots:false,
        navText: [
            "<div><img src='./assets/images/arrow-left.svg' /></div>",
            "<div><img src='./assets/images/arrow-right.svg' /></div>",
        ],
        responsive: {
            0: {
                items: 2,
            },
            400: {
                items: 2,
            },
            740: {
                items: 2,
            },
            1350: {
                items: 2,
            },
        },
        nav: true,
    }

    loyaltyData:any;
    customOptionsForAccountRec: OwlOptions = {
        items: 5,
        loop: false,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        dots: false,
        navSpeed: 700,
        autoWidth: false,
        navText: ["<div><img src='./assets/images/arrow-left.svg' /></div>", "<div><img src='./assets/images/arrow-right.svg' /></div>"],
        responsive: {
            0: {
                items: 1
            },
            400: {
                items: 2
            },
            740: {
                items: 3
            },
            940: {
                items: 5
            }
        },
        nav: true
    }
    selectedCartType: any = 'mhv_cart';
    deliveryTimeLineReq:any;
    isOrderDetailsPage:boolean=false;
    etaList:any[]=[];
    validSchemeList:any[]=[];
    isshowMoreSlabDiscount:boolean =false;
    discountSlabOffer :any[]=[];
    popupOpenType:any;
    partLimitCheck:any[]=[];
    productdetailpage:any ='productdetailpage'
    productInfo:any;
    innerViewoffer: any = [];
    storeCrouselname: any = [];
    mergeDisountArray: any = [];
    marketDetailsarr:any = [];
    uniquepercentquant:any = []
    ismarketbasketapicall:boolean = false;
    stireduplicatearra = [];
    newArrayforPartnumner:any = [];
    storeQuantity:any;
    storeApiQuant:any;
    isPartaddtocart_clicked:boolean = false;
    displayToggleName:any;
    description: string = '';
    public isCollapsed: boolean = false;
    public isCollapsable: boolean = false;
    maxHeight:number = 50;
    uniqueListContact:any = [];
    marketBasketDescription:any;
    showStaticImage:boolean = false;
    xenonImages:any = [{
               fullImg:[
            'https://dyxo8yglv4typ.cloudfront.net/resize/800x450/sandbox/ecom/parts_image/253414605802/253414605802_1.jpg',
            'https://dyxo8yglv4typ.cloudfront.net/resize/800x450/sandbox/ecom/parts_image/253414605802/253414605802_2.jpg',
            'https://dyxo8yglv4typ.cloudfront.net/resize/800x450/sandbox/ecom/parts_image/253414605802/253414605802_3.jpg',
            'https://dyxo8yglv4typ.cloudfront.net/resize/800x450/sandbox/ecom/parts_image/253414605802/253414605802_4.jpg'
            ],
            mediumImg:[
            'https://dyxo8yglv4typ.cloudfront.net/resize/528x355/sandbox/ecom/parts_image/253414605802/253414605802_1.jpg',
            'https://dyxo8yglv4typ.cloudfront.net/resize/528x355/sandbox/ecom/parts_image/253414605802/253414605802_2.jpg',
            'https://dyxo8yglv4typ.cloudfront.net/resize/528x355/sandbox/ecom/parts_image/253414605802/253414605802_3.jpg',
            'https://dyxo8yglv4typ.cloudfront.net/resize/528x355/sandbox/ecom/parts_image/253414605802/253414605802_4.jpg'
            ],
            miniImg:[
             'https://dyxo8yglv4typ.cloudfront.net/resize/43x29/sandbox/ecom/parts_image/253414605802/253414605802_1.jpg',
             'https://dyxo8yglv4typ.cloudfront.net/resize/43x29/sandbox/ecom/parts_image/253414605802/253414605802_2.jpg',
             'https://dyxo8yglv4typ.cloudfront.net/resize/43x29/sandbox/ecom/parts_image/253414605802/253414605802_3.jpg',
             'https://dyxo8yglv4typ.cloudfront.net/resize/43x29/sandbox/ecom/parts_image/253414605802/253414605802_4.jpg'

            ]
    }]
    ganeshImages:any = [{
        fullImg:[
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x450/sandbox/ecom/parts_image/203241100118/203241100118_1.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x450/sandbox/ecom/parts_image/203241100118/203241100118_2.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x450/sandbox/ecom/parts_image/203241100118/203241100118_3.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x450/sandbox/ecom/parts_image/203241100118/203241100118_4.jpg',
        ],
        mediumImg:[
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x355/sandbox/ecom/parts_image/203241100118/203241100118_1.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x355/sandbox/ecom/parts_image/203241100118/203241100118_2.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x355/sandbox/ecom/parts_image/203241100118/203241100118_3.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x355/sandbox/ecom/parts_image/203241100118/203241100118_4.jpg'
        ],
        miniImg:[
        'https://dyxo8yglv4typ.cloudfront.net/resize/43x29/sandbox/ecom/parts_image/203241100118/203241100118_1.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/43x29/sandbox/ecom/parts_image/203241100118/203241100118_2.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/43x29/sandbox/ecom/parts_image/203241100118/203241100118_3.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/43x29/sandbox/ecom/parts_image/203241100118/203241100118_4.jpg'
        ]
    }];
    PartsImages:any = [{
        fullImg:[
            'https://dyxo8yglv4typ.cloudfront.net/resize/800x450/sandbox/ecom/parts_image/285426203104/285426203104_1.jpg',
            'https://dyxo8yglv4typ.cloudfront.net/resize/800x450/sandbox/ecom/parts_image/285426203104/285426203104_2.jpg',
            'https://dyxo8yglv4typ.cloudfront.net/resize/800x450/sandbox/ecom/parts_image/285426203104/285426203104_3.jpg',
            'https://dyxo8yglv4typ.cloudfront.net/resize/800x450/sandbox/ecom/parts_image/285426203104/285426203104_4.jpg'
            ],
            mediumImg:[
            'https://dyxo8yglv4typ.cloudfront.net/resize/528x355/sandbox/ecom/parts_image/285426203104/285426203104_1.jpg',
            'https://dyxo8yglv4typ.cloudfront.net/resize/528x355/sandbox/ecom/parts_image/285426203104/285426203104_2.jpg',
            'https://dyxo8yglv4typ.cloudfront.net/resize/528x355/sandbox/ecom/parts_image/285426203104/285426203104_3.jpg',
            'https://dyxo8yglv4typ.cloudfront.net/resize/528x355/sandbox/ecom/parts_image/285426203104/285426203104_4.jpg'
            ],
            miniImg:[
            'https://dyxo8yglv4typ.cloudfront.net/resize/43x29/sandbox/ecom/parts_image/285426203104/285426203104_1.jpg',
            'https://dyxo8yglv4typ.cloudfront.net/resize/43x29/sandbox/ecom/parts_image/285426203104/285426203104_2.jpg',
            'https://dyxo8yglv4typ.cloudfront.net/resize/43x29/sandbox/ecom/parts_image/285426203104/285426203104_3.jpg',
            'https://dyxo8yglv4typ.cloudfront.net/resize/43x29/sandbox/ecom/parts_image/285426203104/285426203104_4.jpg'
            ]
    }];
    part511854600146Img:any = [{
        fullImg:[
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x450/sandbox/ecom/parts_image/511854600146/EV109711.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x450/sandbox/ecom/parts_image/511854600146/EV109712.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x450/sandbox/ecom/parts_image/511854600146/EV109713.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x450/sandbox/ecom/parts_image/511854600146/EV109715.jpg',
        ],
        mediumImg:[
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x355/sandbox/ecom/parts_image/511854600146/EV109711.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x355/sandbox/ecom/parts_image/511854600146/EV109712.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x355/sandbox/ecom/parts_image/511854600146/EV109713.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x355/sandbox/ecom/parts_image/511854600146/EV109715.jpg'
        ],
        miniImg:[
        'https://dyxo8yglv4typ.cloudfront.net/resize/43x29/sandbox/ecom/parts_image/511854600146/EV109711.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/43x29/sandbox/ecom/parts_image/511854600146/EV109712.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/43x29/sandbox/ecom/parts_image/511854600146/EV109713.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/43x29/sandbox/ecom/parts_image/511854600146/EV109715.jpg'
        ]
    }];
    part580326513803Img:any = [{
        fullImg:[
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x450/sandbox/ecom/parts_image/580326513803/EV109722.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x450/sandbox/ecom/parts_image/580326513803/EV109723.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x450/sandbox/ecom/parts_image/580326513803/EV109724.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x450/sandbox/ecom/parts_image/580326513803/EV109725.jpg',
        ],
        mediumImg:[
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x355/sandbox/ecom/parts_image/580326513803/EV109722.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x355/sandbox/ecom/parts_image/580326513803/EV109723.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x355/sandbox/ecom/parts_image/580326513803/EV109724.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x355/sandbox/ecom/parts_image/580326513803/EV109725.jpg'
        ],
        miniImg:[
        'https://dyxo8yglv4typ.cloudfront.net/resize/43x29/sandbox/ecom/parts_image/580326513803/EV109722.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/43x29/sandbox/ecom/parts_image/580326513803/EV109723.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/43x29/sandbox/ecom/parts_image/580326513803/EV109724.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/43x29/sandbox/ecom/parts_image/580326513803/EV109725.jpg'
        ]
    }];
    part885425202518Img:any = [{
        fullImg:[
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x450/sandbox/ecom/parts_image/273235305113/273235305113_1-transformed.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x450/sandbox/ecom/parts_image/273235305113/273235305113_2-transformed.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x450/sandbox/ecom/parts_image/273235305113/273235305113_3-transformed.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x450/sandbox/ecom/parts_image/273235305113/273235305113_4-transformed.jpg',
        ],
        mediumImg:[
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x355/sandbox/ecom/parts_image/273235305113/273235305113_1-transformed.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x355/sandbox/ecom/parts_image/273235305113/273235305113_2-transformed.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x355/sandbox/ecom/parts_image/273235305113/273235305113_3-transformed.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x355/sandbox/ecom/parts_image/273235305113/273235305113_4-transformed.jpg'
        ],
        miniImg:[
        'https://dyxo8yglv4typ.cloudfront.net/resize/43x29/sandbox/ecom/parts_image/273235305113/273235305113_1-transformed.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/43x29/sandbox/ecom/parts_image/273235305113/273235305113_2-transformed.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/43x29/sandbox/ecom/parts_image/273235305113/273235305113_3-transformed.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/43x29/sandbox/ecom/parts_image/273235305113/273235305113_4-transformed.jpg'
        ]
    }];
    part13150611614Img:any = [{
        fullImg:[
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x452/sandbox/ecom/parts_image/13150611614/13150611614_1.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x452/sandbox/ecom/parts_image/13150611614/13150611614_2.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x452/sandbox/ecom/parts_image/13150611614/13150611614_3.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x452/sandbox/ecom/parts_image/13150611614/13150611614_4.jpg',
        ],
        mediumImg:[
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/13150611614/13150611614_1.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/13150611614/13150611614_2.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/13150611614/13150611614_3.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/13150611614/13150611614_4.jpg'
        ],
        miniImg:[
        'https://dyxo8yglv4typ.cloudfront.net/resize/60x34/sandbox/ecom/parts_image/13150611614/13150611614_1.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/60x34/sandbox/ecom/parts_image/13150611614/13150611614_2.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/60x34/sandbox/ecom/parts_image/13150611614/13150611614_3.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/60x34/sandbox/ecom/parts_image/13150611614/13150611614_4.jpg'
        ]
    }];
    part250526207903Img:any = [{
        fullImg:[
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x452/sandbox/ecom/parts_image/250526207903/250526207903_1.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x452/sandbox/ecom/parts_image/250526207903/250526207903_2.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x452/sandbox/ecom/parts_image/250526207903/250526207903_3.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x452/sandbox/ecom/parts_image/250526207903/250526207903_4.jpg',
        ],
        mediumImg:[
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/250526207903/250526207903_1.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/250526207903/250526207903_2.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/250526207903/250526207903_3.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/250526207903/250526207903_4.jpg'
        ],
        miniImg:[
        'https://dyxo8yglv4typ.cloudfront.net/resize/60x34/sandbox/ecom/parts_image/250526207903/250526207903_1.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/60x34/sandbox/ecom/parts_image/250526207903/250526207903_2.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/60x34/sandbox/ecom/parts_image/250526207903/250526207903_3.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/60x34/sandbox/ecom/parts_image/250526207903/250526207903_4.jpg'
        ]
    }];
    part250526706009Img:any = [{
        fullImg:[
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x452/sandbox/ecom/parts_image/250526706009/250526706009_1.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x452/sandbox/ecom/parts_image/250526706009/250526706009_2.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x452/sandbox/ecom/parts_image/250526706009/250526706009_3.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x452/sandbox/ecom/parts_image/250526706009/250526706009_4.jpg',
        ],
        mediumImg:[
        'https://dyxo8yglv4typ.cloudfront.net/resize/502x282/sandbox/ecom/parts_image/250526706009/250526706009_1.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/502x282/sandbox/ecom/parts_image/250526706009/250526706009_2.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/502x282/sandbox/ecom/parts_image/250526706009/250526706009_3.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/502x282/sandbox/ecom/parts_image/250526706009/250526706009_4.jpg'
        ],
        miniImg:[
        'https://dyxo8yglv4typ.cloudfront.net/resize/110x62/sandbox/ecom/parts_image/250526706009/250526706009_1.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/110x62/sandbox/ecom/parts_image/250526706009/250526706009_2.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/110x62/sandbox/ecom/parts_image/250526706009/250526706009_3.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/110x62/sandbox/ecom/parts_image/250526706009/250526706009_4.jpg'
        ]
    }];
    part251526203802Img:any = [{
        fullImg:[
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x452/sandbox/ecom/parts_image/251526203802/251526203802_1.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x452/sandbox/ecom/parts_image/251526203802/251526203802_2.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x452/sandbox/ecom/parts_image/251526203802/251526203802_3.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x452/sandbox/ecom/parts_image/251526203802/251526203802_4.jpg',
        ],
        mediumImg:[
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/251526203802/251526203802_1.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/251526203802/251526203802_2.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/251526203802/251526203802_3.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/251526203802/251526203802_4.jpg'
        ],
        miniImg:[
        'https://dyxo8yglv4typ.cloudfront.net/resize/60x34/sandbox/ecom/parts_image/251526203802/251526203802_1.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/60x34/sandbox/ecom/parts_image/251526203802/251526203802_2.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/60x34/sandbox/ecom/parts_image/251526203802/251526203802_3.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/60x34/sandbox/ecom/parts_image/251526203802/251526203802_4.jpg'
        ]
    }];
    part251626307906Img:any = [{
        fullImg:[
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x452/sandbox/ecom/parts_image/251626307906/251626307906_1.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x452/sandbox/ecom/parts_image/251626307906/251626307906_2.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x452/sandbox/ecom/parts_image/251626307906/251626307906_3.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x452/sandbox/ecom/parts_image/251626307906/251626307906_4.jpg',
        ],
        mediumImg:[
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/251626307906/251626307906_1.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/251626307906/251626307906_2.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/251626307906/251626307906_3.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/251626307906/251626307906_4.jpg'
        ],
        miniImg:[
        'https://dyxo8yglv4typ.cloudfront.net/resize/60x34/sandbox/ecom/parts_image/251626307906/251626307906_1.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/60x34/sandbox/ecom/parts_image/251626307906/251626307906_2.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/60x34/sandbox/ecom/parts_image/251626307906/251626307906_3.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/60x34/sandbox/ecom/parts_image/251626307906/251626307906_4.jpg'
        ]
    }];

    part580725601601Img:any = [{
        fullImg:[
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x452/sandbox/ecom/parts_image/580725601601/580725601601_1.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x452/sandbox/ecom/parts_image/580725601601/580725601601_2.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x452/sandbox/ecom/parts_image/580725601601/580725601601_3.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x452/sandbox/ecom/parts_image/580725601601/580725601601_4.jpg',
        ],
        mediumImg:[
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/580725601601/580725601601_1.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/580725601601/580725601601_2.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/580725601601/580725601601_3.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/580725601601/580725601601_4.jpg'
        ],
        miniImg:[
        'https://dyxo8yglv4typ.cloudfront.net/resize/60x34/sandbox/ecom/parts_image/580725601601/580725601601_1.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/60x34/sandbox/ecom/parts_image/580725601601/580725601601_2.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/60x34/sandbox/ecom/parts_image/580725601601/580725601601_3.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/60x34/sandbox/ecom/parts_image/580725601601/580725601601_4.jpg'
        ]
    }];

    part571001155313Img:any = [{
        fullImg:[
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x452/sandbox/ecom/parts_image/571001155313/571001155313_1.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x452/sandbox/ecom/parts_image/571001155313/571001155313_2.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x452/sandbox/ecom/parts_image/571001155313/571001155313_3.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x452/sandbox/ecom/parts_image/571001155313/571001155313_4.jpg',
        ],
        mediumImg:[
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/571001155313/571001155313_1.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/571001155313/571001155313_2.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/571001155313/571001155313_3.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/571001155313/571001155313_4.jpg'
        ],
        miniImg:[
        'https://dyxo8yglv4typ.cloudfront.net/resize/60x34/sandbox/ecom/parts_image/571001155313/571001155313_1.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/60x34/sandbox/ecom/parts_image/571001155313/571001155313_2.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/60x34/sandbox/ecom/parts_image/571001155313/571001155313_3.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/60x34/sandbox/ecom/parts_image/571001155313/571001155313_4.jpg'
        ]
    }];

     part269126204651Img:any = [{
        fullImg:[
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x452/sandbox/ecom/parts_image/269126204651/269126204651_1.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x452/sandbox/ecom/parts_image/269126204651/269126204651_2.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x452/sandbox/ecom/parts_image/269126204651/269126204651_3.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x452/sandbox/ecom/parts_image/269126204651/269126204651_4.jpg',
        ],
        mediumImg:[
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/269126204651/269126204651_1.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/269126204651/269126204651_2.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/269126204651/269126204651_3.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/269126204651/269126204651_4.jpg'
        ],
        miniImg:[
        'https://dyxo8yglv4typ.cloudfront.net/resize/60x34/sandbox/ecom/parts_image/269126204651/269126204651_1.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/60x34/sandbox/ecom/parts_image/269126204651/269126204651_2.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/60x34/sandbox/ecom/parts_image/269126204651/269126204651_3.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/60x34/sandbox/ecom/parts_image/269126204651/269126204651_4.jpg'
        ]
    }];
    part551747100150Img:any = [{
        fullImg:[
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x452/sandbox/ecom/parts_image/551747100150/551747100150_1.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x452/sandbox/ecom/parts_image/551747100150/551747100150_2.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x452/sandbox/ecom/parts_image/551747100150/551747100150_3.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x452/sandbox/ecom/parts_image/551747100150/551747100150_4.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x452/sandbox/ecom/parts_image/551747100150/551747100150_5.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/800x452/sandbox/ecom/parts_image/551747100150/551747100150_6.jpg',
        ],
        mediumImg:[
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/551747100150/551747100150_1.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/551747100150/551747100150_2.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/551747100150/551747100150_3.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/551747100150/551747100150_4.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/551747100150/551747100150_5.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/551747100150/551747100150_6.jpg'
        ],
        miniImg:[
        'https://dyxo8yglv4typ.cloudfront.net/resize/60x50/sandbox/ecom/parts_image/551747100150/551747100150_1.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/60x50/sandbox/ecom/parts_image/551747100150/551747100150_2.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/60x50/sandbox/ecom/parts_image/551747100150/551747100150_3.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/60x50/sandbox/ecom/parts_image/551747100150/551747100150_4.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/60x50/sandbox/ecom/parts_image/551747100150/551747100150_5.jpg',
        'https://dyxo8yglv4typ.cloudfront.net/resize/60x50/sandbox/ecom/parts_image/551747100150/551747100150_6.jpg'
        ]
    }];
    medimTemparr:any = [];
    ganeshMediumarr:any = [];
    partsMediumImg:any = [];
    partfourTemparr: any = [];
    partfiveTemparr: any =[];
    partsixTemparr: any =[];
    partsevenTemparr: any =[];
    parteightTemparr: any =[];
    partnineTemparr: any =[];
    parttenTemparr: any =[];
    partelevenTemparr: any =[];
    partTwelveTemparr: any =[];
    partThirteenTemparr: any =[];
    partFifteenTemparr: any =[];
    partFourteenTemparr: any =[];
    selectedMiniImage: string | null = null;
    fullimageModal:any;
    modalImage:any;
    imgType:any
    isUserType:any;
    inventoryQuantity:any;
    externalTrackSource:any;
    addtocartDisable:boolean = false
    pageURL:any

    constructor(
        private addressService: AddressService,
        private route: ActivatedRoute,
        private modelse: ModelService,
        private cart: CartService,
        private userService: UserService,
        private productService: ProductService,
        private router: Router,
        private appStateService: AppStateService,
        public quickview: QuickviewService,
        private cd: ChangeDetectorRef,
        private modalService1: BsModalService,
        private toasterService: ToastrService,
        private localstorage: secureStorage,
        private title: Title,
        private modalService: BsModalService,
        private elementRef: ElementRef
    ) {      
    }
    selectImage(image: string,index,type) {
        // console.log("clicke_image>>",image,index,type)
        this.selectedMiniImage = image; // Set selected mini image when clicked;
        if(type == 'xenon'){
            // this.medimTemparr = [];
            // setTimeout(() => {
            //     this.xenonImages.forEach((ele) => {
            //         ele.mediumImg.forEach((med) => {
            //             if(image == med){
            //                 this.medimTemparr.push(med)
            //             }
            //         })
        
            //     })
            // }, 1000);

            this.medimTemparr = [];
            setTimeout(() => {
                this.xenonImages.forEach((ele) => {
                    ele.mediumImg.forEach((med) => {
                        // const filename1 = image.substring(image.lastIndexOf('/') + 1);
                        // const filename2 = med.substring(med.lastIndexOf('/') + 1);
                        const parts = image.split('/');
                        const urltwo = med.split('/');
                        var lastPart = parts[parts.length - 1];
                        const lastpart2 = urltwo[urltwo.length - 1];
                        if(lastPart == 'ik-thumbnail.jpg'){
                            lastPart = parts[parts.length - 2];
                        }
                        // Remove any query parameters if present
                        var filename1 = lastPart.split('?')[0];
                        const filename2 = lastpart2.split('?')[0];
                        if(filename1 == 'ik-thumbnail.jpg'){
                             filename1 = lastPart.split('?')[0];
                        }

                        if(filename1 == filename2){
                        // console.log("check_filename1>>",filename1,"filenameTwo>>",filename2)

                            this.medimTemparr.push(med)
                            // console.log("cehcehhechcec",this.ganeshMediumarr)
                        }
                    })
        
                })
            },0);
        }else if(type == 'ganesh'){
            this.ganeshMediumarr = [];
            setTimeout(() => {
                
                this.ganeshImages.forEach((ele) => {
                    // console.log("first_for-each",ele)
                    ele.mediumImg.forEach((med) => {
                    // console.log("second_for_each",med)
                        // const filename1 = image.substring(image.lastIndexOf('/') + 1);
                        // const filename2 = med.substring(med.lastIndexOf('/') + 1);

                        const parts = image.split('/');
                        const urltwo = med.split('/');
                        const lastPart = parts[parts.length - 1];
                        const lastpart2 = urltwo[urltwo.length - 1];

                        // Remove any query parameters if present
                        const filename1 = lastPart.split('?')[0];
                        const filename2 = lastpart2.split('?')[0];

                        // console.log("check_filename1>>",filename1,"filenameTwo>>",filename2)

                        if(filename1 == filename2){
                        // console.log("check_filename1>>",filename1,"filenameTwo>>",filename2)

                            this.ganeshMediumarr.push(med)
                            // console.log("cehcehhechcec",this.ganeshMediumarr)
                        }
                    })
        
                })
            },0);
        }else if(type == 'parts'){
            // this.partsMediumImg = [];
            // setTimeout(() => {
            //     this.PartsImages.forEach((ele) => {
            //         ele.mediumImg.forEach((med) => {
            //             if(image == med){
            //                 this.partsMediumImg.push(med)
            //             }
            //         })
        
            //     })
            // }, 1000);

            this.partsMediumImg = [];
            setTimeout(() => {
                
                this.PartsImages.forEach((ele) => {
                    // console.log("first_for-each",ele)
                    ele.mediumImg.forEach((med) => {
                    // console.log("second_for_each",med)
                        // const filename1 = image.substring(image.lastIndexOf('/') + 1);
                        // const filename2 = med.substring(med.lastIndexOf('/') + 1);

                        const parts = image.split('/');
                        const urltwo = med.split('/');
                        const lastPart = parts[parts.length - 1];
                        const lastpart2 = urltwo[urltwo.length - 1];

                        // Remove any query parameters if present
                        const filename1 = lastPart.split('?')[0];
                        const filename2 = lastpart2.split('?')[0];

                        // console.log("check_filename1>>",filename1,"filenameTwo>>",filename2)

                        if(filename1 == filename2){
                        // console.log("check_filename1>>",filename1,"filenameTwo>>",filename2)

                            this.partsMediumImg.push(med)
                            // console.log("cehcehhechcec",this.ganeshMediumarr)
                        }
                    })
        
                })
            },0);
        }else if(type == 'partfour'){
            this.partfourTemparr = [];
            setTimeout(() => {
                
                this.part511854600146Img.forEach((ele) => {
                    // console.log("first_for-each",ele)
                    ele.mediumImg.forEach((med) => {
                    // console.log("second_for_each",med)
                        // const filename1 = image.substring(image.lastIndexOf('/') + 1);
                        // const filename2 = med.substring(med.lastIndexOf('/') + 1);

                        const parts = image.split('/');
                        const urltwo = med.split('/');
                        const lastPart = parts[parts.length - 1];
                        const lastpart2 = urltwo[urltwo.length - 1];

                        // Remove any query parameters if present
                        const filename1 = lastPart.split('?')[0];
                        const filename2 = lastpart2.split('?')[0];

                        // console.log("check_filename1>>",filename1,"filenameTwo>>",filename2)

                        if(filename1 == filename2){
                        // console.log("check_filename1>>",filename1,"filenameTwo>>",filename2)

                            this.partfourTemparr.push(med)
                            // console.log("cehcehhechcec",this.ganeshMediumarr)
                        }
                    })
        
                })
            },0);
        }else if(type == 'partfive'){
            this.partfiveTemparr = [];
            setTimeout(() => {
                
                this.part580326513803Img.forEach((ele) => {
                    // console.log("first_for-each",ele)
                    ele.mediumImg.forEach((med) => {
                    // console.log("second_for_each",med)
                        // const filename1 = image.substring(image.lastIndexOf('/') + 1);
                        // const filename2 = med.substring(med.lastIndexOf('/') + 1);

                        const parts = image.split('/');
                        const urltwo = med.split('/');
                        const lastPart = parts[parts.length - 1];
                        const lastpart2 = urltwo[urltwo.length - 1];

                        // Remove any query parameters if present
                        const filename1 = lastPart.split('?')[0];
                        const filename2 = lastpart2.split('?')[0];

                        // console.log("check_filename1>>",filename1,"filenameTwo>>",filename2)

                        if(filename1 == filename2){
                        // console.log("check_filename1>>",filename1,"filenameTwo>>",filename2)

                            this.partfiveTemparr.push(med)
                            // console.log("cehcehhechcec",this.ganeshMediumarr)
                        }
                    })
        
                })
            },0);
        }else if(type == 'partsix'){
            this.partsixTemparr = [];
            setTimeout(() => {
                
                this.part885425202518Img.forEach((ele) => {
                    // console.log("first_for-each",ele)
                    ele.mediumImg.forEach((med) => {
                    // console.log("second_for_each",med)
                        // const filename1 = image.substring(image.lastIndexOf('/') + 1);
                        // const filename2 = med.substring(med.lastIndexOf('/') + 1);

                        const parts = image.split('/');
                        const urltwo = med.split('/');
                        const lastPart = parts[parts.length - 1];
                        const lastpart2 = urltwo[urltwo.length - 1];

                        // Remove any query parameters if present
                        const filename1 = lastPart.split('?')[0];
                        const filename2 = lastpart2.split('?')[0];

                        // console.log("check_filename1>>",filename1,"filenameTwo>>",filename2)

                        if(filename1 == filename2){
                        // console.log("check_filename1>>",filename1,"filenameTwo>>",filename2)

                            this.partsixTemparr.push(med)
                            // console.log("cehcehhechcec",this.ganeshMediumarr)
                        }
                    })
        
                })
            },0);
        }else if(type == 'partseven'){
            this.partsevenTemparr = [];
            setTimeout(() => {
                
                this.part13150611614Img.forEach((ele) => {
                    // console.log("first_for-each",ele)
                    ele.mediumImg.forEach((med) => {
                    // console.log("second_for_each",med)
                        // const filename1 = image.substring(image.lastIndexOf('/') + 1);
                        // const filename2 = med.substring(med.lastIndexOf('/') + 1);

                        const parts = image.split('/');
                        const urltwo = med.split('/');
                        const lastPart = parts[parts.length - 1];
                        const lastpart2 = urltwo[urltwo.length - 1];

                        // Remove any query parameters if present
                        const filename1 = lastPart.split('?')[0];
                        const filename2 = lastpart2.split('?')[0];

                        // console.log("check_filename1>>",filename1,"filenameTwo>>",filename2)

                        if(filename1 == filename2){
                        // console.log("check_filename1>>",filename1,"filenameTwo>>",filename2)

                            this.partsevenTemparr.push(med)
                            // console.log("cehcehhechcec",this.ganeshMediumarr)
                        }
                    })
        
                })
            },0);
        }else if(type == 'parteight'){
            this.parteightTemparr = [];
            setTimeout(() => {
                
                this.part250526207903Img.forEach((ele) => {
                    // console.log("first_for-each",ele)
                    ele.mediumImg.forEach((med) => {
                    // console.log("second_for_each",med)
                        // const filename1 = image.substring(image.lastIndexOf('/') + 1);
                        // const filename2 = med.substring(med.lastIndexOf('/') + 1);

                        const parts = image.split('/');
                        const urltwo = med.split('/');
                        const lastPart = parts[parts.length - 1];
                        const lastpart2 = urltwo[urltwo.length - 1];

                        // Remove any query parameters if present
                        const filename1 = lastPart.split('?')[0];
                        const filename2 = lastpart2.split('?')[0];

                        // console.log("check_filename1>>",filename1,"filenameTwo>>",filename2)

                        if(filename1 == filename2){
                        // console.log("check_filename1>>",filename1,"filenameTwo>>",filename2)

                            this.parteightTemparr.push(med)
                            // console.log("cehcehhechcec",this.ganeshMediumarr)
                        }
                    })
        
                })
            },0);
        }else if(type == 'partnine'){
            this.partnineTemparr = [];
            setTimeout(() => {
                
                this.part250526706009Img.forEach((ele) => {
                    // console.log("first_for-each",ele)
                    ele.mediumImg.forEach((med) => {
                    // console.log("second_for_each",med)
                        // const filename1 = image.substring(image.lastIndexOf('/') + 1);
                        // const filename2 = med.substring(med.lastIndexOf('/') + 1);

                        const parts = image.split('/');
                        const urltwo = med.split('/');
                        const lastPart = parts[parts.length - 1];
                        const lastpart2 = urltwo[urltwo.length - 1];

                        // Remove any query parameters if present
                        const filename1 = lastPart.split('?')[0];
                        const filename2 = lastpart2.split('?')[0];

                        // console.log("check_filename1>>",filename1,"filenameTwo>>",filename2)

                        if(filename1 == filename2){
                        // console.log("check_filename1>>",filename1,"filenameTwo>>",filename2)

                            this.partnineTemparr.push(med)
                            // console.log("cehcehhechcec",this.ganeshMediumarr)
                        }
                    })
        
                })
            },0);
        }else if(type == 'partten'){
            this.parttenTemparr = [];
            setTimeout(() => {
                
                this.part251526203802Img.forEach((ele) => {
                    // console.log("first_for-each",ele)
                    ele.mediumImg.forEach((med) => {
                    // console.log("second_for_each",med)
                        // const filename1 = image.substring(image.lastIndexOf('/') + 1);
                        // const filename2 = med.substring(med.lastIndexOf('/') + 1);

                        const parts = image.split('/');
                        const urltwo = med.split('/');
                        const lastPart = parts[parts.length - 1];
                        const lastpart2 = urltwo[urltwo.length - 1];

                        // Remove any query parameters if present
                        const filename1 = lastPart.split('?')[0];
                        const filename2 = lastpart2.split('?')[0];

                        // console.log("check_filename1>>",filename1,"filenameTwo>>",filename2)

                        if(filename1 == filename2){
                        // console.log("check_filename1>>",filename1,"filenameTwo>>",filename2)

                            this.parttenTemparr.push(med)
                            // console.log("cehcehhechcec",this.ganeshMediumarr)
                        }
                    })
        
                })
            },0);
        }else if(type == 'parteleven'){
            this.partelevenTemparr = [];
            setTimeout(() => {
                
                this.part251626307906Img.forEach((ele) => {
                    // console.log("first_for-each",ele)
                    ele.mediumImg.forEach((med) => {
                    // console.log("second_for_each",med)
                        // const filename1 = image.substring(image.lastIndexOf('/') + 1);
                        // const filename2 = med.substring(med.lastIndexOf('/') + 1);

                        const parts = image.split('/');
                        const urltwo = med.split('/');
                        const lastPart = parts[parts.length - 1];
                        const lastpart2 = urltwo[urltwo.length - 1];

                        // Remove any query parameters if present
                        const filename1 = lastPart.split('?')[0];
                        const filename2 = lastpart2.split('?')[0];

                        // console.log("check_filename1>>",filename1,"filenameTwo>>",filename2)

                        if(filename1 == filename2){
                        // console.log("check_filename1>>",filename1,"filenameTwo>>",filename2)

                            this.partelevenTemparr.push(med)
                            // console.log("cehcehhechcec",this.ganeshMediumarr)
                        }
                    })
        
                })
            },0);
        }else if(type == 'partTwlve'){
            this.partTwelveTemparr = [];
            setTimeout(() => {
                
                this.part580725601601Img.forEach((ele) => {
                    ele.mediumImg.forEach((med) => {
                        const parts = image.split('/');
                        const urltwo = med.split('/');
                        const lastPart = parts[parts.length - 1];
                        const lastpart2 = urltwo[urltwo.length - 1];
                        const filename1 = lastPart.split('?')[0];
                        const filename2 = lastpart2.split('?')[0];
                        if(filename1 == filename2){
                            this.partTwelveTemparr.push(med)
                        }
                    })
        
                })
            },0);
        }else if(type == 'partThirteen'){
            this.partThirteenTemparr = [];
            setTimeout(() => {
                
                this.part571001155313Img.forEach((ele) => {
                    ele.mediumImg.forEach((med) => {
                        const parts = image.split('/');
                        const urltwo = med.split('/');
                        const lastPart = parts[parts.length - 1];
                        const lastpart2 = urltwo[urltwo.length - 1];
                        const filename1 = lastPart.split('?')[0];
                        const filename2 = lastpart2.split('?')[0];
                        if(filename1 == filename2){
                            this.partThirteenTemparr.push(med)
                        }
                    })
        
                })
            },0);
        }else if(type == 'partEourteen'){
            this.partFourteenTemparr = [];
            setTimeout(() => {
                
                this.part269126204651Img.forEach((ele) => {
                    ele.mediumImg.forEach((med) => {
                        const parts = image.split('/');
                        const urltwo = med.split('/');
                        const lastPart = parts[parts.length - 1];
                        const lastpart2 = urltwo[urltwo.length - 1];
                        const filename1 = lastPart.split('?')[0];
                        const filename2 = lastpart2.split('?')[0];
                        if(filename1 == filename2){
                            this.partFourteenTemparr.push(med)
                        }
                    })
        
                })
            },0);
        }else if(type == 'partFifteen'){
            this.partFifteenTemparr = [];
            setTimeout(() => {
                
                this.part551747100150Img.forEach((ele) => {
                    ele.mediumImg.forEach((med) => {
                        const parts = image.split('/');
                        const urltwo = med.split('/');
                        const lastPart = parts[parts.length - 1];
                        const lastpart2 = urltwo[urltwo.length - 1];
                        const filename1 = lastPart.split('?')[0];
                        const filename2 = lastpart2.split('?')[0];
                        if(filename1 == filename2){
                            this.partFifteenTemparr.push(med)
                        }
                    })
        
                })
            },0);
        }
       
      }
      
    ngOnInit() {
        let user = JSON.parse(this.localstorage.getItem('userData'));
        this.isUserType =  user;
        this.pageURL = window.location.href;
        // console.log("check_userType",this.isUserType)
        this.appStateService.wishlistItemadded.subscribe((wishlistAdded) => {
            if (this.router.url.includes('product-details') && wishlistAdded) {
                // this.isWishlistedItem = true; 
                this.route.queryParams.subscribe(params => {
                    this.partNumber = params['part_Number'] || params['search'];
                });
                this.loadData(this.partNumber, true);
            }
        });

        this.breadcrumbs = JSON.parse(this.localstorage.getItem(
            'productcardBreadcrumb'
        ));
        if ((this.breadcrumbs != null && this.breadcrumbs.length < 1) ||
            (this.breadcrumbs == null || this.breadcrumbs == undefined)) {
            this.breadcrumbs = [];
        }
        this.cd.markForCheck();
        this.showProduct = true;
        this.showProductEngine = false;

        let address = JSON.parse(this.localstorage.getItem('address'));
        let billingaddress = JSON.parse(this.localstorage.getItem('billingAddress'));
        if (address?.address['default']) {
            this.shippingaddressid = "default";
        }
        else {
            this.shippingaddressid = address?.address['address_id'];
        }
        if (billingaddress?.address['default']) {
            this.billingaddressid = "default";
        }
        else {
            this.billingaddressid = billingaddress?.address['address_id'];
        }
        this.appStateService.isLoggedIn.subscribe(isLog => {
            this.isLoggedIn = isLog;
        })
        var QtyInput = (function () {
            var $qtyInputs = $(".qty-input");

            if (!$qtyInputs.length) {
                return;
            }

            var $inputs = $qtyInputs.find(".product-qty");
            var $countBtn = $qtyInputs.find(".qty-count");
            var qtyMin = parseInt($inputs.attr("min"));
            var qtyMax = parseInt($inputs.attr("max"));

            $inputs.change(function () {
                var $this = $(this);
                var $minusBtn = $this.siblings(".qty-count--minus");
                var $addBtn = $this.siblings(".qty-count--add");
                var qty = parseInt($this.val());

                if (isNaN(qty) || qty <= qtyMin) {
                    $this.val(qtyMin);
                    $minusBtn.attr("disabled", true);
                } else {
                    $minusBtn.attr("disabled", false);

                    if (qty >= qtyMax) {
                        $this.val(qtyMax);
                        $addBtn.attr('disabled', true);
                    } else {
                        $this.val(qty);
                        $addBtn.attr('disabled', false);
                    }
                }
            });

            $countBtn.click(function () {
                var operator = this.dataset.action;
                var $this = $(this);
                var $input = $this.siblings(".product-qty");
                var qty = parseInt($input.val());

                if (operator == "add") {
                    qty += 1;
                    if (qty >= qtyMin + 1) {
                        $this.siblings(".qty-count--minus").attr("disabled", false);
                    }

                    if (qty >= qtyMax) {
                        $this.attr("disabled", true);
                    }
                } else {
                    qty = qty <= qtyMin ? qtyMin : (qty -= 1);

                    if (qty == qtyMin) {
                        $this.attr("disabled", true);
                    }

                    if (qty < qtyMax) {
                        $this.siblings(".qty-count--add").attr("disabled", false);
                    }
                }

                $input.val(qty);
            });
        })();
        // $(function () {
        //     $('.owl-carousel').owlCarousel({
        //         loop: false,
        //         margin: 20,
        //         nav: true,
        //         dots: false,
        //         responsive: {
        //             0: {
        //                 items: 1
        //             },
        //             600: {
        //                 items: 3
        //             },
        //             1000: {
        //                 items: 4
        //             },
        //             1400: {
        //                 items: 5
        //             }
        //         }
        //     });
        // });

        try {
            this.route.queryParams.subscribe(params => {
                this.showProductEngine = false;
                this.showProduct = true;
                this.partNumber = params['part_Number'] || params['search'];
                console.log("chek_part_numbebrbrbrb",this.partNumber)
                if(this.partNumber == '203241100118' || this.partNumber == '253414605802' || this.partNumber == '285426203104' || this.partNumber == '273235305113' || this.partNumber == '580326513803' || this.partNumber == '511854600146' || this.partNumber == '13150611614' || this.partNumber == '250526207903' || this.partNumber == '250526706009' || this.partNumber == '251526203802' || this.partNumber == '251626307906' ||
                    this.partNumber == '580725601601' || this.partNumber == '571001155313' || this.partNumber == '269126204651' || this.partNumber == '551747100150'
                ){
                    this.showStaticImage = true;
                }else{
                    this.showStaticImage = false;
                }
                if(this.partNumber == '253414605802'){
                    setTimeout(() => {
                    // this.selectImage('https://ik.imagekit.io/nq1nxw7tt/253414605802_2.jpg?tr=h-458,w-450,c-at_max,dpr-2',0,'xenon')
                    this.selectImage('https://dyxo8yglv4typ.cloudfront.net/resize/528x355/sandbox/ecom/parts_image/253414605802/253414605802_1.jpg',0,'xenon')
                    },500);
                }else if(this.partNumber == '203241100118'){
                    setTimeout(() => {
                    this.selectImage('https://dyxo8yglv4typ.cloudfront.net/resize/528x355/sandbox/ecom/parts_image/203241100118/203241100118_1.jpg',0,'ganesh')
                        
                    },500);
                }else if(this.partNumber == '285426203104'){
                    setTimeout(() => {
                        this.selectImage('https://dyxo8yglv4typ.cloudfront.net/resize/528x355/sandbox/ecom/parts_image/285426203104/285426203104_1.jpg',0,'parts')
                            
                        },500);      
                }else if(this.partNumber == '511854600146'){
                    setTimeout(() => {
                        this.selectImage('https://dyxo8yglv4typ.cloudfront.net/resize/528x355/sandbox/ecom/parts_image/511854600146/EV109711.jpg',0,'partfour')
                            
                        },500);
                    }else if(this.partNumber == '580326513803'){
                        setTimeout(() => {
                            this.selectImage('https://dyxo8yglv4typ.cloudfront.net/resize/528x355/sandbox/ecom/parts_image/580326513803/EV109722.jpg',0,'partfive')
                                
                            },500);
                        }else if(this.partNumber == '273235305113'){
                            setTimeout(() => {
                                this.selectImage('https://dyxo8yglv4typ.cloudfront.net/resize/528x355/sandbox/ecom/parts_image/273235305113/273235305113_1-transformed.jpg',0,'partsix')
                                    
                                },500);
                        }else if(this.partNumber == '13150611614'){
                                setTimeout(() => {
                                    this.selectImage('https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/13150611614/13150611614_1.jpg',0,'partseven')
                                        
                            },500);
                        }else if(this.partNumber == '250526207903'){
                                    setTimeout(() => {
                                        this.selectImage('https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/250526207903/250526207903_1.jpg',0,'parteight')
                                            
                            },500);
                        }else if(this.partNumber == '250526706009'){
                            setTimeout(() => {
                                this.selectImage('https://dyxo8yglv4typ.cloudfront.net/resize/502x282/sandbox/ecom/parts_image/250526706009/250526706009_1.jpg',0,'partnine')
                                                
                             },500);
                        }else if(this.partNumber == '251526203802'){
                            setTimeout(() => {
                                this.selectImage('https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/251526203802/251526203802_1.jpg',0,'partten')
                                                    
                                },500);
                        }else if(this.partNumber == '251626307906'){
                            setTimeout(() => {
                                 this.selectImage('https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/251626307906/251626307906_1.jpg',0,'parteleven')
                                                        
                                 },500);
                        }else if(this.partNumber == '251626307906'){
                            setTimeout(() => {
                               this.selectImage('https://dyxo8yglv4typ.cloudfront.net/resize/528x355/sandbox/ecom/parts_image/251626307906/251626307906_1.jpg',0,'parteleven')
                                },500);
                         }else if(this.partNumber == '580725601601'){
                            setTimeout(() => {
                               this.selectImage('https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/580725601601/580725601601_1.jpg',0,'partTwlve')
                                },500);
                        }else if(this.partNumber == '269126204651'){
                            setTimeout(() => {
                               this.selectImage('https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/269126204651/269126204651_1.jpg',0,'partEourteen')
                                },500);
                        }else if(this.partNumber == '571001155313'){
                            setTimeout(() => {
                               this.selectImage('https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/571001155313/571001155313_1.jpg',0,'partThirteen')
                                },500);
                        }else if(this.partNumber == '551747100150'){
                            setTimeout(() => {
                               this.selectImage('https://dyxo8yglv4typ.cloudfront.net/resize/528x299/sandbox/ecom/parts_image/551747100150/551747100150_1.jpg',0,'partFifteen')
                                },500);
                        }
                this.isProductDetailComponent = true;
                this.isProductDetailEngineComponent = false
                this.typevalue = ''
                this.errormsg = ''
                this.compatibilityMessage = ''
                this.checkCompatible = null;
                this.alternateProducts(0);
                this.loadData(this.partNumber, false);
                if (this.breadcrumbs.length == 0 ||
                    (this.breadcrumbs.length > 0 && (this.partNumber !== this.breadcrumbs[this.breadcrumbs.length - 1].label))) {
                    this.breadcrumbs.push({
                        label: this.partNumber,
                        url: 'shop/product-details',
                        params: { part_Number: this.partNumber },
                    });
                }
                // RECENT ITEMS IMPLEMENTATION
                this.recentItems = (JSON.parse(this.localstorage.getItem("Recent items")) == null || JSON.parse(this.localstorage.getItem("Recent items")) == undefined) ? [] :
                    JSON.parse(this.localstorage.getItem("Recent items"));
                if (this.recentItems.length < 20) {
                    if (!this.recentItems.includes(params['part_Number'])) {
                        this.recentItems.splice(0, 0, params['part_Number']);
                    }
                }
                else if (this.recentItems.length == 20) {
                    if (!this.recentItems.includes(params['part_Number'])) {
                        this.recentItems.splice(0, 1)
                        this.recentItems.splice(0, 0, params['part_Number']);
                    }
                }
                if (this.recentItems.length == 20 || this.recentItems.length < 20) {
                    this.localstorage.setItem("Recent items", JSON.stringify(this.recentItems));
                    this.appStateService.recentItems.next(true);
                }
            });
            this.appStateService.postalCode.subscribe(postalCode => {
                this.pinCodeNumber = postalCode;
            })
            this.pinCode = JSON.parse(this.localstorage.getItem('Pincode'));
            this.pinCodeNumber = (parseInt(this.pinCode)) ? parseInt(this.pinCode) : 0;
            this.getAddressDetails(true);
            this.getRecommnededPartlist();

            this.cart.isCartOpenobs.subscribe((res) => {
                if(res){
                    this.isSidebarOpen = false;
                }else{

                }
            })
        }
        catch (error) {
            this.userService.InsertExceptionLog({ "method_name": "ngOnInit()", "page_name": "PageProductComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
        }
        setTimeout(() => {
            this.productslide();
        }, 1000);
        this.appStateService.refreshDiscounts.subscribe(refreshDiscount => {
            if (refreshDiscount) {
                if (this.showProduct) {
                    setTimeout(() => {
                        this.addressChange(true);
                        this.appStateService.refreshDiscounts.next(false);
                    }, 1000);
                }
            }
        });
        // this.appStateService.refreshTimelineList.next(null);
        this.title.setTitle('E-Dukaan | ' + 'Product Details');
        // this.getmarketbasketlist()
    }
    ngOnChanges(changes: SimpleChanges) {
        this.breadcrumbs = this.breadcrumbs;
        let localbreadcrumb = JSON.parse(
            this.localstorage.getItem('productcardBreadcrumb')
        );
        if ((this.breadcrumbs != null && this.breadcrumbs.length < 1) ||
            (this.breadcrumbs == null || this.breadcrumbs == undefined)) {
            this.breadcrumbs = [];
        }
    }
    truncateText(text: string, maxLength: number): string {
        if (text.length > maxLength) {
          return text.slice(0, maxLength) + '...'; // Adjust to your truncation needs
        } else {
          return text;
        }
      }
    getRecommnededPartlist() {
        try {
            let user = JSON.parse(this.localstorage.getItem('userData'));
            let address = JSON.parse(this.localstorage.getItem('address'));
            let shippingaddressid = "";
            if (address.address['default'] === true) {
                shippingaddressid = "default";
            }
            else {
                shippingaddressid = address.address['address_id'];
            }
            let req = {
                "part_number": this.partNumber,
                "account_id": user.account_id,
                "action_type": "recommendation_parts",
                "is_for_inventory":this.isUserType?.user_type == 'MECH' ? true: false,
            }
            this.productService.getRecommnededParts(req).subscribe(
                res => {
                    if (res['Overall_Recommendation'] && res['Overall_Recommendation'].length > 0) {
                        let overallPartNum: any[] = [];
                        res['Overall_Recommendation'].forEach(element => {
                            overallPartNum.push(element);
                        });
                        let req = Part_Search_Request_Of_Recommendation(user.account_pk, overallPartNum, shippingaddressid,this.isUserType);
                        this.productService.get_part_search_md(req).subscribe(
                            res => {
                                if (res.success) {
                                    this.isApiRecommededLoad = true;
                                    this.overallPartList = res.data;
                                    if (this.overallPartList.length > 0) {
                                        setTimeout(() => {
                                            this.alternateProductsSlider("related-product-overall");
                                        }, 700)
                                    }
                                    for (let i = 0; i < res.data.length; i++) {
                                        this.overallPartList[i].customerQuantity = res.data[i].min_quantity;
                                        this.overallPartList[i].unit_quantity = res.data[i].moq;
                                        this.inventoryQuantity = res.data[i].inventory_quantity;
                                        this.externalTrackSource = res.data[i].external_tracking_source;
                                    }
                                }
                            }
                        )
                    }
                    if (res['Account_Recommendation'] && res['Account_Recommendation'].length > 0) {
                        let overallPartNUmAcc: any[] = [];
                        res['Account_Recommendation'].forEach(element => {
                            overallPartNUmAcc.push(element);
                        });
                        let req = Part_Search_Request_Of_Recommendation(user.account_pk, overallPartNUmAcc, shippingaddressid,this.isUserType);
                        this.productService.get_part_search_md(req).subscribe(
                            res => {
                                if (res.success) {
                                    this.accountLevelPartList = res.data;
                                    if (this.accountLevelPartList.length > 0) {
                                        setTimeout(() => {
                                            this.alternateProductsSlider("related-product-account-level");
                                        }, 700)
                                    }
                                }
                                for (let i = 0; i < res.data.length; i++) {
                                    this.accountLevelPartList[i].customerQuantity = res.data[i].min_quantity;
                                    this.accountLevelPartList[i].unit_quantity = res.data[i].moq;
                                    this.inventoryQuantity = res.data[i].inventory_quantity;
                                    this.externalTrackSource = res.data[i].external_tracking_source;
                                }
                            }
                        )
                    }
                    // if( res['Fleet_Recommendation'] && res['Fleet_Recommendation'].length > 0 ){
                    //     let FleetPartNum:any[] = [];
                    //     res['Fleet_Recommendation'].forEach(element => {
                    //         FleetPartNum.push(element);
                    //     });
                    //     let req = Part_Search_Request_Of_Recommendation(user.account_pk, FleetPartNum, shippingaddressid);
                    // this.productService.get_part_search_md(req).subscribe(
                    //         res => {
                    //             if(res.success){
                    //                 this.fleetEdgePartList = res.data;
                    //                 if(this.fleetEdgePartList.length > 0){
                    //                     setTimeout(() => {
                    //                         this.alternateProductsSlider("related-product-fleet-edge-level");
                    //                     }, 700)
                    //                 }
                    //             }
                    //             for (let i = 0; i < res.data.length; i++) {
                    //                 this.fleetEdgePartList[i].customerQuantity = res.data[i].min_quantity;
                    //                 this.fleetEdgePartList[i].unit_quantity = res.data[i].moq;
                    //             }
                    //         }
                    //     )
                    // }
                }
            )
        }
        catch (error) {
            this.userService.InsertExceptionLog({ "method_name": "getRecommnededPartlist()", "page_name": "PageProductComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
        }

    }

    getCompatibleModel(partNumber) {
        try {
            // this.calldatatable(page);
            //   this.rerender();
            this.isProductDetailEngineComponent = true;
            this.isProductDetailComponent = false;
            this.Compatiblemodeldata = [];
            this.allModelData = [];
            this.typevalue = ''
            this.errormsg = ''
            this.compatibilityMessage = ''
            let searchData;
            searchData = {
                part_number: partNumber,
                offset: 0,
                limit: 20,
            };
            this.Compatiblemodeldata = [];
            //         
            this.productService.get_applicable_model(searchData).subscribe(
                (data) => {
                    if (data.success) {

                        var CompData = data.data;
                        // this.totalcomprecord = data.total_result;
                        // this.vehiclecount = data.data.length;
                        for (var i = 0; i < CompData.length; i++) {
                            var modelcatname = CompData[i]['base_model'];
                            if (modelcatname) {
                                var checkdatamodel = this.allModelData.filter(
                                    (model) => model.model_cat === modelcatname
                                );
                                if (checkdatamodel.length == 0) {
                                    this.variantdata = [];
                                    this.variantdata.push(this.FormVarientData(CompData[i]));
                                    this.allModelData.push(
                                        this.FormModelData(CompData[i], this.variantdata)
                                    );
                                } else {
                                    this.variantdata.push(this.FormVarientData(CompData[i]));
                                    this.allModelData.find(
                                        (item) => item.model_cat == modelcatname
                                    ).variantList = this.variantdata;
                                }
                            }
                        }
                        this.Compatiblemodeldata = this.allModelData;
                        let vcNumber = this.Compatiblemodeldata[0].variantList[0];
                        let item = this.Compatiblemodeldata[0];
                        let modeldata = {
                            base_model: vcNumber.vc_base_model,
                            model_cat: item.model_cat,
                            model_desc: item.model_desc,
                            vcNumber: vcNumber.vc_no
                        }
                        //this.showsubModel(modeldata, partNumber)
                        this.getFirstDrawingImage(modeldata, partNumber)
                    } else {

                        this.toasterService.error(data.data.msg);
                        this.Compatiblemodeldata = [];
                    }
                },
                (err) => {
                    this.Compatiblemodeldata = [];
                }
            );
        } catch (error) {
        }
    }
    showsubModel(modelname?) {
        //called after selecting submodel
        if (this.selectModalRef) {
            this.selectModalRef.hide();
        }
        this.modelVcData = [];
        // this.showProduct = false;
        // this.showProductEngine = true;
        this.selectmodel = modelname.model_cat;
        let vcno = modelname.vcNumber;
        //this.getFirstDrawingImage(modelname, partNumber);
        this.showVcDetails(modelname.base_model, vcno)
        document.body.scrollTop = 0;
        window.scrollTo(0, 0);
    }

    async getFirstDrawingImage(model, partNumber) {
        let requestBody = {
            "part_number": partNumber,
            "base_model": model.base_model,
            "model_cat": model.model_cat,
            "vc_no": ""
        }
        let response = await this.modelse.get_sub_aggregates_new(requestBody);
        if (response['success']) {
            this.modelVcData = response['data'];
            this.showProduct = false;
            this.showProductEngine = true;
        }
        else {

        }
    }

    FormModelData(data: any, variantdata: any): compatible {
        const objmodeldata: compatible = {} as compatible;
        objmodeldata.model_image = data['model_image'] ? data['model_image'] : '-';
        objmodeldata.model_cat = data['model_cat'] ? data['model_cat'] : '-';
        objmodeldata.model_desc = data['model_desc'] ? data['model_desc'] : '-';
        objmodeldata.base_model = data['base_model'] ? data['base_model'] : '-';
        objmodeldata.variantList = variantdata;
        return objmodeldata;
    }

    FormVarientData(data: any): ModelVariant {
        const objvariantdata: ModelVariant = {} as ModelVariant;
        objvariantdata.vc_no = data['vc_no'] ? data['vc_no'] : '-';
        objvariantdata.engine_type = data['engine_type']
            ? data['engine_type']
            : '-';
        objvariantdata.capacity = data['capacity'] ? data['capacity'] : '-';
        objvariantdata.vc_base_model = data['base_model'] ? data['base_model'] : '-';
        objvariantdata.fuel_type = data['fuel_type'] ? data['fuel_type'] : '-';
        return objvariantdata;
    }
    showRelatedList(alternate_part) {
        if (alternate_part != null && alternate_part != undefined && alternate_part) {
            this.router.navigateByUrl('/', { skipLocationChange: true })
                .then(() => this.router.navigate(['/shop/product-details'], { queryParams: { part_Number: alternate_part } }));
        }
        else if (this.alternateProductsList.length > 0) {
            document.getElementById("relatedlistview").scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }
    alternateProductsSlider(id?) {
        $("#" + id).slick({
            dots: false,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            responsive: [
                {
                    breakpoint: 1920,
                    settings: {
                        arrows: false,
                        centerMode: false,
                        centerPadding: '30px',
                        slidesToShow: 4
                    }
                },
                {
                    breakpoint: 1550,
                    settings: {
                        arrows: false,
                        centerMode: false,
                        centerPadding: '30px',
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        arrows: false,
                        centerMode: true,
                        centerPadding: '30px',
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        arrows: false,
                        centerMode: true,
                        centerPadding: '30px',
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        arrows: false,
                        centerMode: true,
                        centerPadding: '30px',
                        slidesToShow: 1
                    }
                }
            ]
        });
    }

    productslide() {
        $('.slider-thumb').not('.slick-initialized').slick({
            autoplay: false,
            vertical: true,
            infinite: false,
            verticalSwiping: true,
            slidesPerRow: 8,
            slidesToShow: 8,
            // asNavFor: '.slider-preview',
            focusOnSelect: false,
            prevArrow: '<button type="button" class="slick-prev"><img alt="" src="./assets/images/arrow-right.svg"/> </button>',
            nextArrow: '<button type="button" class="slick-next"><img alt="" src="./assets/images/arrow-left.svg"/></button>',
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        vertical: false,
                    }
                },
                {
                    breakpoint: 479,
                    settings: {
                        vertical: false,
                        slidesPerRow: 3,
                        slidesToShow: 3,
                    }
                },
            ]
        });
        // $('.slider-preview').not('.slick-initialized').slick({
        //     autoplay: false,
        //     vertical: true,
        //     infinite: true,
        //     slidesPerRow: 1,
        //     slidesToShow: 1,
        //     // asNavFor: '.slider-thumb',
        //     arrows: false,
        //     draggable: false,
        //     responsive: [
        //         {
        //             breakpoint: 767,
        //             settings: {
        //                 vertical: false,
        //                 fade: true,
        //             }
        //         },
        //     ]
        // });
        $('.slider-preview, .slider-thumb').slick('setPosition');
    }

    ngAfterViewInit() {
        this.dtTrigger.next();
        if (this.localstorage.getItem('getCartType')) {
            this.cartTypeList = JSON.parse(this.localstorage.getItem('getCartType'));
        }
        if (this.cartTypeList.length > 0) {
            // let currentUrl = this.router.url;
            // if (currentUrl.includes('product-details')) {
            //     this.btndisabled = false;
            //     this.btnshowConfrimed = true;
            //     this.btnshowsubmit = false;
            //     if (this.dataProduct['part_status'] !== 'in_active') {
            //     this.modalRef = this.modalService1.show(this.template, { class: 'modal-dialog-centered modal-md', backdrop: 'static' });
            //     }
            // }
        }
        let currentHeight = this.elementRef.nativeElement.getElementsByTagName('div')[0].offsetHeight;
       //collapsable only if the contents make container exceed the max height
        if (currentHeight > this.maxHeight) {
            this.isCollapsed = true;
            this.isCollapsable = true;
        }
    }


    loadData(partNumber, viaWishlist) {
        // loadData(partNumber,userqty?) {
        try {
            let user = JSON.parse(this.localstorage.getItem('userData'));
            let address = JSON.parse(this.localstorage.getItem('address'));
            let changePincode = this.localstorage.getItem('Pincode');
            if (user && address) {
                this.account_pk = + JSON.stringify(user.account_pk);
                if (address['type'] === 'default' && address['type'] !== null) {
                    this.address_pk = address.type;
                }
                else {
                    if (address.address.length === undefined || address.address.length === 0) {
                        this.address_pk = address.address.id;
                    }
                    else {
                        this.address_pk = address.address[0].id;
                    }
                }
            }

            let productData = {};
            if (user && address) {
                productData = {
                    search_text: "",
                    bs_model_name: "",
                    pg_line: "",
                    multi_pg_line: [],
                    multi_bs_model_name: [

                    ],
                    //pg: "",
                    multi_dispgroup_name: [],
                    model_cat: "",
                    mod_uid: "",
                    category_type: "",
                    bom_id: "",
                    durafit_parts: false,
                    kit_parts: false,
                    part_number_last_four: "",
                    offset: 0,
                    size: 20,
                    pl_id: "",
                    account_pk: this.account_pk,
                    shipping_address_id: address?.address?.isDefault ? "default" : this.shippingaddressid,
                    // shipping_address_id: ?this.shippingaddressid:"",
                    is_pincode_based_division: address.address.postal_code != changePincode ? true : false,
                    pincode: address.address.postal_code != changePincode ? changePincode : "",
                    is_for_epc_data: false,
                    is_for_discount: true,
                    is_for_ecom_detail: true,
                    // quantity : userqty,
                    part_number: partNumber,
                    is_for_inventory:this.isUserType?.user_type == 'MECH' ? true: false,
                }
            }
            else {
                productData = {
                    search_text: "",
                    bs_model_name: "",
                    pg_line: "",
                    multi_pg_line: [],
                    multi_bs_model_name: [
                    ],
                    //pg: "",
                    multi_dispgroup_name: [],
                    model_cat: "",
                    mod_uid: "",
                    category_type: "",
                    bom_id: "",
                    durafit_parts: false,
                    kit_parts: false,
                    part_number_last_four: "",
                    offset: 0,
                    size: 20,
                    pl_id: "",
                    is_for_epc_data: false,
                    is_for_discount: changePincode ? true : false,
                    is_for_ecom_detail: true,
                    is_pincode_based_division: changePincode ? true : false,
                    pincode: changePincode ? changePincode : "",
                    // quantity : userqty,
                    part_number: partNumber,
                    is_for_inventory:this.isUserType?.user_type == 'MECH' ? true: false,
                }
            }
            this.divisionInfoData = [];
            this.productService.get_part_search_md(productData).subscribe(response => {
                if (response.success === true) {
                    if ((response?.data[0]?.base_discount_per + response?.data[0]?.scheme_discount_per +
                        response?.data[0]?.tml_discount_per + response?.data[0]?.dealer_rule_discount +
                        response?.data[0]?.rule_discount_per + response?.data[0]?.distributor2_discount_amount) > 0) {
                        this.normalPercentage = parseFloat((((response?.data[0].crm_mrp - response?.data[0].total_amount) / response?.data[0].crm_mrp) * 100)?.toFixed(1));
                    }
                    this.divisionInfoData = {
                        ORG_NAME: response.data[0].organization_name ? response.data[0].organization_name : "",
                        DIV_NAME: response.data[0].division_name ? response.data[0].division_name : "",
                        PRIMISE_ADDRLINE1_s: response.data[0].primise_addrline1 ? response.data[0].primise_addrline1 : "",
                        PRIMISE_ADDRESS2_s: response.data[0].primise_addrline2 ? response.data[0].primise_addrline2 : "",
                        DIV_ID: response.data[0].division_id ? response.data[0].division_id : "",
                        GSTIN: response.data[0].gstn ? response.data[0].gstn : "",
                        zipcode: response.data[0].zipcode ? response.data[0].zipcode : ""
                    }
                    this.productList = response.data;
                    this.productInfo= response
                    this.productInfo.division_info = {
                        ORG_NAME:response.data[0].organization_name?response.data[0].organization_name:"",
                        DIV_NAME:response.data[0].division_name?response.data[0].division_name:"",
                        PRIMISE_ADDRLINE1_s:response.data[0].primise_addrline1?response.data[0].primise_addrline1:"",
                        PRIMISE_ADDRESS2_s:response.data[0].primise_addrline2?response.data[0].primise_addrline2:"",
                        DIV_ID:response.data[0].division_id?response.data[0].division_id:"",
                        GSTIN:response.data[0].gstn?response.data[0].gstn:"",
                        ORG_Id:response.data[0].organization_id?response.data[0].organization_id:"",
                        zipcode: response.data[0].zipcode ? response.data[0].zipcode : ""
                    };
                    this.isWishlistedItem = response.is_wishlist;
                    let responseImages: any[] = [];
                    for (let i = 0; i < response.data.length; i++) {
                        this.productList[i].customerQuantity = response.data[i].min_quantity;
                        this.productList[i].unit_quantity = response.data[i].moq;
                        this.inventoryQuantity = response.data[i].inventory_quantity;
                        this.externalTrackSource = response.data[i].external_tracking_source;
                        this.selectedCartType = response.data[i].distributor_category == "SLV" ? 'slv_cart' : response.data[i].distributor_category == "MHV" ? 'mhv_cart' : response.data[i].distributor_category == "TMGO" ? 'tmgo_cart' : 'mhv_cart';
                        if (response.data[i].multi_img) {
                            responseImages = response.data[i].multi_img;
                            var prodimage = responseImages.filter(
                                (Data) => Data.is_cover == true
                            );
                            if (prodimage.length > 0) {
                                this.productList[i].image = prodimage[0]?.img;
                            }
                        }

                        // this.productList[i].customerQuantity = userqty ? userqty : response.data[i].min_quantity
                    }
                    this.product = response.data[0];
                    this.loyaltyData = response.data[0]?.loyalty_accrual
                    this.product["customerQuantity"] = response.data[0].min_quantity

                    // this.product["customerQuantity"] = userqty ? userqty : response.data[0].min_quantity;
                    setTimeout(() => {
                        this.productslide();
                    }, 1000);
                    var images = (this.product as any).multi_img.map((url, index) => {
                        return {
                            id: index.toString(),
                            url: url.img,
                            active: index === 0
                        };
                    });
                    this.selectImg(images[0].url,0,'thumbnil')
                    this.showLoader = false;
                    // this.discountBasedproduct(this.productList[0],userqty);
                    if (!viaWishlist) {
                        this.discountBasedproduct(this.productList[0]);
                    }
                    // CHILD PART IMPLEMENTAION
                    var childPART_NUMBER_s = this.product['part_number'].substring(0, 4);
                    if (childPART_NUMBER_s == '8854') {
                        this.showchildpart(this.product);
                        //    this.rerender();
                        // this.btnshowChildParts = true;
                    }
                    else {
                        this.btnshowChildParts = false;
                    }
                    if (this.product['alternate_part'] && this.product['alternate_part'] != "" && this.product['alternate_part'] != undefined && this.product['alternate_part'] != null) {
                        if (!viaWishlist) {
                            this.alternatePartAvailable(this.product['alternate_part'])
                        }
                    }
                    // this.partSearchMD2(productData)
                }
                else {
                    this.toasterService.error(response.data.msg);
                    this.showLoader = false;
                }
            }, (err) => {
                this.showLoader = false;
                this.router.navigateByUrl(this.localstorage.getItem("previousUrl"))

            })
        }
        catch (error) {
            this.userService.InsertExceptionLog({ "method_name": "loadData(partNumber)", "page_name": "PageProductComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
        }
    }

    alternatePartAvailable(partNumber) {
        try {
            let user = JSON.parse(this.localstorage.getItem('userData'));
            let address = JSON.parse(this.localstorage.getItem('address'));
            let changePincode = this.localstorage.getItem('Pincode');
            if (user && address) {
                this.account_pk = + JSON.stringify(user.account_pk);
                if (address['type'] === 'default' && address['type'] !== null) {
                    this.address_pk = address.type;
                }
                else {
                    if (address.address.length === undefined || address.address.length === 0) {
                        this.address_pk = address.address.id;
                    }
                    else {
                        this.address_pk = address.address[0].id;
                    }
                }
            }

            let productData = {};
            if (user && address) {
                productData = {
                    search_text: "",
                    bs_model_name: "",
                    pg_line: "",
                    multi_pg_line: [],
                    multi_bs_model_name: [

                    ],
                    //pg: "",
                    multi_dispgroup_name: [],
                    model_cat: "",
                    mod_uid: "",
                    category_type: "",
                    bom_id: "",
                    durafit_parts: false,
                    kit_parts: false,
                    part_number_last_four: "",
                    offset: 0,
                    size: 20,
                    pl_id: "",
                    account_pk: this.account_pk,
                    shipping_address_id: address?.address?.isDefault ? "default" : this.shippingaddressid,
                    is_pincode_based_division: address.address.postal_code != changePincode ? true : false,
                    pincode: address.address.postal_code != changePincode ? changePincode : "",
                    is_for_epc_data: false,
                    is_for_discount: true,
                    is_for_ecom_detail: true,
                    // quantity : userqty,
                    part_number: partNumber,
                    is_for_inventory:this.isUserType?.user_type == 'MECH' ? true: false,
                }
            }
            else {
                productData = {
                    search_text: "",
                    bs_model_name: "",
                    pg_line: "",
                    multi_pg_line: [],
                    multi_bs_model_name: [
                    ],
                    //pg: "",
                    multi_dispgroup_name: [],
                    model_cat: "",
                    mod_uid: "",
                    category_type: "",
                    bom_id: "",
                    durafit_parts: false,
                    kit_parts: false,
                    part_number_last_four: "",
                    offset: 0,
                    size: 20,
                    pl_id: "",
                    is_for_epc_data: false,
                    is_for_discount: false,
                    is_for_ecom_detail: true,
                    // quantity : userqty,
                    part_number: partNumber,
                    is_for_inventory:this.isUserType?.user_type == 'MECH' ? true: false,
                }
            }
            this.productService.get_part_search_md(productData).subscribe(response => {
                if (response.success === true) {
                    this.showLoader = false;
                    this.alternatePartData = response;
                    this.alternatePart = this.modalService1.show(this.alternatePartModal, { class: 'modal-dialog-centered modal-xl', backdrop: 'static' });
                }
                else {
                    this.toasterService.error(response.data.msg);
                    this.showLoader = false;
                }
            }, (err) => {
                this.showLoader = false;
                this.router.navigateByUrl(this.localstorage.getItem("previousUrl"))

            })
        }
        catch (error) {
            this.userService.InsertExceptionLog({ "method_name": "loadData(partNumber)", "page_name": "PageProductComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
        }
    }

    showchildpart(products) {
        this.sendchildproduct = products;
        try {
            //  this.calldatatable(products);
            this.childPartData = []
            this.btnshowChildParts = false;
            //this.result = false;
            let searchData = {}
            searchData = {
                part_number: products.part_number,
                //offset: page * 10,
                "offset": 0,
                size: 500,
            }
            //this.result = false;
            //if (!this.chkService) {
            //this.chkService = true;

            this.productService.getChildPartList(searchData).subscribe(response => {
                //this.result = true;
                this.totalrecord = 0;
                //this.chkService = false;
                if (response.success) {
                    this.btnshowChildParts = true;
                    // this.currentPage = 1;
                    // this.childPartData = response.data;
                    // this.totalrecord = response.data.total_count;

                } else {
                    this.btnshowChildParts = false;
                    // this.childPartData = []
                }
                this.showLoader = false;
            }, (error) => {
                this.childPartData = [];
                this.showLoader = false;
                //this.result = true;
            })
            //}
        }
        catch (error) {
            this.userService.InsertExceptionLog({ "method_name": "showchildpart(products)", "page_name": "ProductComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
        }
    }

    discountBasedproduct(value: Product) {
        // discountBasedproduct(value: Product,userqty?) {
        try {
            this.dataProduct = value;
            if (value) {
                let data = {};
                let user = JSON.parse(this.localstorage.getItem('userData'));
                let address = JSON.parse(this.localstorage.getItem('address'));
                let changePincode = this.localstorage.getItem('Pincode');
                let carttypes;
                if (this.localstorage.getItem('getCartType')) {
                    this.cartTypeList = JSON.parse(this.localstorage.getItem('getCartType'));
                }
                var commoncarttypedata = this.cartTypeList.filter(alitems => alitems.common_distributor_categories.includes(value['distributor_category']));
                var Distributercarttypedata = this.cartTypeList.filter(alitems => alitems.distributor_categories.includes(value['distributor_category']));
                let cart_type_name = "";
                if (commoncarttypedata.length > 0) {
                    carttypes = commoncarttypedata[1]["cart_type"]
                    if (this.cartTypeList.length > 0) {
                        this.selectcarttype = carttypes;
                        let currentUrl = this.router.url;
                        if (currentUrl.includes('product-details')) {
                            this.btndisabled = false;
                            this.btnshowConfrimed = true;
                            this.btnshowsubmit = false;
                            if (value['is_nls'] !== 'in_active' && !value['alternate_part'])  {
                                this.cartTypeModal = this.modalService1.show(this.template, { class: 'modal-dialog-centered modal-md', backdrop: 'static' });
                            }
                        }
                    }
                }
                else if (Distributercarttypedata.length > 0) {
                    carttypes = Distributercarttypedata[0]["cart_type"]
                }
                let billingaddress = JSON.parse(this.localstorage.getItem('billingAddress'));

                let searchData = {}
                if (user && address && billingaddress) {
                    // this.account_pk = + JSON.stringify(user.user_ids[0].id);
                    this.account_pk = + JSON.stringify(user.account_pk);
                    var addresstypecheck = address.address['default'];
                    if (addresstypecheck === true) {
                        this.shippingaddressid = "default";
                    }
                    else {
                        this.shippingaddressid = address.address['address_id'];
                    }
                    var Billingaddresscheck = billingaddress.address['default'];
                    if (Billingaddresscheck === true) {
                        this.billingaddressid = "default";
                    }
                    else {
                        this.billingaddressid = billingaddress.address['address_id'];
                    }
                    searchData = {
                        search_text: "",
                        bs_model_name: "",
                        pg_line: "",
                        multi_pg_line: [],
                        multi_bs_model_name: [
                        ],
                        multi_dispgroup_name: [],
                        model_cat: "",
                        mod_uid: "",
                        category_type: "",
                        bom_id: "",
                        durafit_parts: false,
                        kit_parts: false,
                        part_number_last_four: "",
                        offset: 0,
                        limit: 20,
                        pl_id: "",
                        // is_discount_needed: true,
                        account_pk: this.account_pk,
                        // address_pk: this.address_pk,
                        shipping_address_id: this.shippingaddressid,
                        is_pincode_based_division: address.address.postal_code != changePincode ? true : false,
                        pincode: address.address.postal_code != changePincode ? changePincode : "",
                        part_number: value.part_number,
                        is_for_epc_data: false,
                        is_for_discount: true,
                        is_for_ecom_detail: true,
                        // quantity : userqty,
                        cart_type: carttypes,
                        is_for_inventory:this.isUserType?.user_type == 'MECH' ? true: false,

                    }
                }
                else {
                    searchData = {
                        search_text: "",
                        bs_model_name: "",
                        pg_line: "",
                        multi_pg_line: [],
                        multi_bs_model_name: [
                        ],
                        multi_dispgroup_name: [],
                        model_cat: "",
                        mod_uid: "",
                        category_type: "",
                        bom_id: "",
                        durafit_parts: false,
                        kit_parts: false,
                        part_number_last_four: "",
                        offset: 0,
                        // start_row:0,
                        limit: 20,
                        pl_id: "",
                        part_number: value.part_number,
                        is_for_epc_data: false,
                        is_for_discount: changePincode ? true : false,
                        is_pincode_based_division: changePincode ? true : false,
                        pincode: changePincode ? changePincode : "",
                        is_for_ecom_detail: true,
                        // quantity : userqty,
                        cart_type: carttypes,
                    is_for_inventory:this.isUserType?.user_type == 'MECH' ? true: false,

                    }
                }
                if (user || (changePincode && !this.isLoggedIn)) {
                    this.partnumberbaseddiscount(searchData, value);
                }
                else {
                    if (!this.isLoggedIn) {
                        if (!value?.total_amount) {
                            this.baseProductdiscount = value?.total_amount;
                            if (value['resource_type'] == 'Pack') {
                                this.ProductMRP = value?.crm_mrp * value?.moq;
                            }
                            else {
                                this.ProductMRP = value?.crm_mrp;
                            }
                        }
                        else if (value?.total_amount) {
                            this.baseProductdiscount = value?.total_amount;
                            if ((value?.base_discount_per + value?.scheme_discount_per + value?.rule_discount_per + value?.distributor2_discount_amount) == 0) {
                                if (value['resource_type'] == 'Pack' ) {
                                    this.ProductMRP = value?.crm_mrp * value?.moq;
                                    this.Productdiscount = 0;
                                }
                                else {
                                    this.ProductMRP = value?.crm_mrp;
                                    this.Productdiscount = 0;
                                }
                            }
                            else if ((value?.base_discount_per + value?.scheme_discount_per + value?.rule_discount_per + value?.distributor2_discount_amount) > 0) {
                                if (value['resource_type'] == 'Pack' ) {
                                    this.Productdiscount = value?.total_amount * value?.moq;
                                    this.ProductMRP = value?.crm_mrp * value?.moq;
                                }
                                else {
                                    this.Productdiscount = value?.total_amount;
                                    this.ProductMRP = value?.crm_mrp;
                                    //  * response?.data[0]?.total_quantity;
                                }
                            }
                        }
                    }
                }
                // if (user) {
                //     if (address['type'] === 'default' && address['type'] !== null) {
                //         data = {
                //             cart_type: carttypes,
                //             account_pk: JSON.stringify(user.account_pk),
                //             address_pk: address.type,// 'default',//address.type,
                //             part_number: value.part_number,
                //             quantity: 1,

                //         }
                //     }
                //     else {
                //         var address_pkselect
                //         if (address.address.length === undefined || address.address.length === 0) {
                //             address_pkselect = address.address.address_id;
                //         }
                //         else {
                //             address_pkselect = address.address[0].address_id;

                //         }
                //         data = {
                //             cart_type: carttypes,
                //             account_pk: JSON.stringify(user.account_pk),
                //             address_pk: address_pkselect,// 'default',//address.type,
                //             part_number: value.part_number,
                //             quantity: 1,
                //         }
                //     }
                //     this.partnumberbaseddiscount(data, value);
                // }
                // if (value['moq']) {
                //     var quan = (1 * parseInt(value['moq']))
                //     this.quantity = new FormControl(quan);
                //     this.Productdiscount = (value['total_amount'] * parseInt(value['moq']));
                //     this.ProductMRP = (value['umrp'] * parseInt(value['moq']));
                // }
                // else {
                //     this.Productdiscount = (value['total_amount'] * 1);
                //     this.ProductMRP = (value['umrp'] * 1);
                // }
            }
            this.images = value ? (this.dataProduct as any).multi_img.map((url, index) => {
                return {
                    id: index.toString(),
                    url: url.img,
                    active: index === 0
                };
            }) : [];
        }
        catch (error) {
            this.userService.InsertExceptionLog({ "method_name": "@Input() set product(value: Product)", "page_name": "ProductComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
        }
    }

    async partnumberbaseddiscount(json, isAddressChanged) {
        try {

            // this.productService.getpartnumberbaseddiscount(json).subscribe(response => {
            await this.productService.get_part_search_md(json).subscribe(response => {
                if (response.success === true) {
                    this.baseProductdata = response;
                    this.inventoryQuantity = response.data[0].inventory_quantity;
                    this.externalTrackSource = response.data[0].external_tracking_source;
                    this.baseProductdata.division_info = {
                        ORG_NAME: response.data[0].organization_name ? response.data[0].organization_name : "",
                        DIV_NAME: response.data[0].division_name ? response.data[0].division_name : "",
                        PRIMISE_ADDRLINE1_s: response.data[0].primise_addrline1 ? response.data[0].primise_addrline1 : "",
                        PRIMISE_ADDRESS2_s: response.data[0].primise_addrline2 ? response.data[0].primise_addrline2 : "",
                        DIV_ID: response.data[0].division_id ? response.data[0].division_id : "",
                        GSTIN: response.data[0].gstn ? response.data[0].gstn : "",
                        ORG_ID: response.data[0].organization_id ? response.data[0].organization_id : "",
                        zipcode: response.data[0].zipcode ? response.data[0].zipcode : ""
                    }
                                        if (!this.isLoggedIn) {
                        this.divisionInfoData = response.division_info;
                        if(this.isUserType?.user_type == 'MECH' && this.externalTrackSource?.includes('Delhivery')){
                            this.displayDeliveryETA()
                        }
                        else{
                            this.displayDeliveryTimeline();
                        }
                    }
                    this.productNewValues = response.data[0];
                    var productPirce = parseFloat(response.data[0]['crm_mrp'].toString()).toFixed(3);
                    var basediscountPirce = parseFloat(response?.data[0]?.total_amount).toFixed(3);
                    if (!response?.data[0]?.total_amount) {
                        this.baseProductdiscount = response?.data[0]?.total_amount;
                        if (response?.data[0]?.resource_type == 'Pack') {
                            this.ProductMRP = response?.data[0]?.crm_mrp * response?.data[0]?.moq;
                        }
                        else {
                            this.ProductMRP = response?.data[0]?.crm_mrp;
                        }
                        this.isNormalDiscount = false;
                    }
                    else if (response?.data[0]?.total_amount) {
                        this.baseProductdiscount = response?.data[0]?.total_amount;
                        if ((response?.data[0]?.base_discount_per + response?.data[0]?.scheme_discount_per + response?.data[0]?.rule_discount_per + response?.data[0]?.distributor2_discount_amount) == 0) {
                            if ((response?.data[0]['resource_type'] == 'Pack' )) {
                                this.Productdiscount = response?.data[0]?.total_amount * response?.data[0]?.moq;
                                this.ProductMRP = response?.data[0]?.crm_mrp * response?.data[0]?.moq;
                            }
                            else {
                                this.Productdiscount = response?.data[0]?.total_amount;
                                this.ProductMRP = response?.data[0]?.crm_mrp;
                            }
                            this.isNormalDiscount = false;
                        }
                        else if ((response?.data[0]?.base_discount_per + response?.data[0]?.scheme_discount_per + response?.data[0]?.rule_discount_per + response?.data[0]?.distributor2_discount_amount) > 0) {
                            if (response?.data[0]['resource_type'] == 'Pack' ) {
                                this.Productdiscount = response?.data[0]?.total_amount * response?.data[0]?.moq;
                                this.ProductMRP = response?.data[0]?.crm_mrp * response?.data[0]?.moq;
                            }
                            else {
                                this.Productdiscount = response?.data[0]?.total_amount;
                                this.ProductMRP = response?.data[0]?.crm_mrp;
                                //  * response?.data[0]?.total_quantity;
                            }
                            this.isNormalDiscount = true;
                        }
                    }
                    if (this.Productdiscount > 0 && this.Productdiscount < this.ProductMRP) {
                        this.savedAmount = this.ProductMRP - this.Productdiscount;
                    }
                    if ((response?.data[0]?.base_discount_per + response?.data[0]?.scheme_discount_per +
                        response?.data[0]?.tml_rule_discount + response?.data[0]?.dealer_rule_discount +
                        response?.data[0]?.rule_discount_per + response?.data[0]?.distributor2_discount_amount) > 0) {
                        this.normalPercentage = parseFloat((((response?.data[0].crm_mrp - response?.data[0].total_amount) / response?.data[0].crm_mrp) * 100)?.toFixed(1));
                    }
                    else {
                        this.normalPercentage = parseFloat((((response?.data[0].crm_mrp - response?.data[0].total_amount) / response?.data[0].crm_mrp) * 100)?.toFixed(1));

                    }
                    if (this.isLoggedIn && response) {
                        if(this.isUserType?.user_type != 'MECH'){
                            this.getmarketbasketlist();
                            this.getOfferDetails(this.partNumber, this.baseProductdata?.division_info?.DIV_ID, isAddressChanged);
                            setTimeout(() => {
                                this.newOffersMethod();
                            }, 2000);
                        }
                        if(this.isUserType?.user_type == 'MECH' && this.externalTrackSource?.includes('Delhivery')){
                            this.displayDeliveryETA()
                        }
                        else{
                            this.displayDeliveryTimeline(null, this.baseProductdata?.division_info?.DIV_ID)
                        }
                        this.getPartLimitCheck(response.data[0].organization_id ? response.data[0].organization_id : "");
                   
                    }

                    // if (productPirce === basediscountPirce) {
                    //     this.baseProductdiscount = null;
                    // }
                    // else {
                    //     this.baseProductdiscount = response.data[0].total_amount;
                    //     if (this.product?.moq) {
                    //         this.Productdiscount = (this.baseProductdiscount * this.product?.moq)
                    //         this.ProductMRP = (this.product?.crm_mrp * this.product?.moq);
                    //     }
                    //     else {
                    //         this.Productdiscount = this.baseProductdiscount
                    //         this.ProductMRP = this.product?.crm_mrp
                    //     }
                    // }
                    this.showLoader = false;
                }
                else {
                    this.showLoader = false;
                }
            }, (err) => {
                this.showLoader = false;
            })
        }
        catch (error) {
            this.userService.InsertExceptionLog({ "method_name": "partnumberbaseddiscount()", "page_name": "ProductComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
        }
    }

    ShowPart() {
        this.btndisabled = true;
        this.cartTypeModal.hide();
        this.showQuickview();
    }

    changeCartType(event, product: Product) {
        try {
            let data = {};
            let user = JSON.parse(this.localstorage.getItem('userData'));
            let address = JSON.parse(this.localstorage.getItem('address'));
            let carttypes = event.cart_type ? event.cart_type : event;
            let billingaddress = JSON.parse(this.localstorage.getItem('billingAddress'));
            let changePincode = this.localstorage.getItem('Pincode');
            this.selectedCartType = event.cart_type;
            let searchData = {}
            this.showLoader = true;
            if (user && address && billingaddress) {
                // this.account_pk = + JSON.stringify(user.user_ids[0].id);
                this.account_pk = + JSON.stringify(user.account_pk);
                var addresstypecheck = address.address['default'];
                if (addresstypecheck === true) {
                    this.shippingaddressid = "default";
                }
                else {
                    this.shippingaddressid = address.address['address_id'];
                }
                var Billingaddresscheck = billingaddress.address['default'];
                if (Billingaddresscheck === true) {
                    this.billingaddressid = "default";
                }
                else {
                    this.billingaddressid = billingaddress.address['address_id'];
                }
                searchData = {
                    search_text: "",
                    bs_model_name: "",
                    pg_line: "",
                    multi_pg_line: [],
                    multi_bs_model_name: [
                    ],
                    multi_dispgroup_name: [],
                    model_cat: "",
                    mod_uid: "",
                    category_type: "",
                    bom_id: "",
                    durafit_parts: false,
                    kit_parts: false,
                    part_number_last_four: "",
                    offset: 0,
                    limit: 20,
                    pl_id: "",
                    // is_discount_needed: true,
                    account_pk: this.account_pk,
                    // address_pk: this.address_pk,
                    shipping_address_id: this.shippingaddressid,
                    is_pincode_based_division: address.address.postal_code != changePincode ? true : false,
                    pincode: address.address.postal_code != changePincode ? changePincode : "",
                    part_number: this.partNumber,
                    is_for_epc_data: false,
                    is_for_discount: true,
                    is_for_ecom_detail: true,
                    cart_type: carttypes,
                    is_for_inventory:this.isUserType?.user_type == 'MECH' ? true: false,

                }
            }
            else {
                searchData = {
                    search_text: "",
                    bs_model_name: "",
                    pg_line: "",
                    multi_pg_line: [],
                    multi_bs_model_name: [
                    ],
                    multi_dispgroup_name: [],
                    model_cat: "",
                    mod_uid: "",
                    category_type: "",
                    bom_id: "",
                    durafit_parts: false,
                    kit_parts: false,
                    part_number_last_four: "",
                    offset: 0,
                    // start_row:0,
                    limit: 20,
                    pl_id: "",
                    is_pincode_based_division: changePincode ? true : false,
                    pincode: changePincode ? changePincode : "",
                    part_number: this.partNumber,
                    is_for_epc_data: false,
                    is_for_discount: changePincode ? true : false,
                    is_for_ecom_detail: true,
                    cart_type: carttypes,
                    is_for_inventory:this.isUserType?.user_type == 'MECH' ? true: false,


                }
            }
            this.partnumberbaseddiscount(searchData, product);
            // if (user) {
            //     carttypes = event['cart_type'];
            //     this.localstorage.setItem('selectcarttype', event['cart_type']);
            //     if (address['type'] === 'default' && address['type'] !== null) {
            //         data = {
            //             cart_type: carttypes,
            //             account_pk: JSON.stringify(user.account_pk),
            //             address_pk: address.type,// 'default',//address.type,
            //             part_number: product.part_number,
            //             quantity: 1,
            //         }
            //     }
            //     else {
            //         var address_pkselect
            //         if (address.address.length === undefined || address.address.length === 0) {
            //             address_pkselect = address.address.id;
            //         }
            //         else {
            //             address_pkselect = address.address[0].id;
            //         }
            //         data = {
            //             cart_type: carttypes,
            //             account_pk: JSON.stringify(user.account_pk),
            //             address_pk: address_pkselect,// 'default',//address.type,
            //             part_number: product.part_number,
            //             quantity: 1,
            //         }
            //     }
            //     this.partnumberbaseddiscount(data, product);
            // }
        }
        catch (error) {
            this.userService.InsertExceptionLog({ "method_name": "changeCartType()", "page_name": "ProductComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
        }
    }

    closePart() {
        if (this.btnshowConfrimed) {
            localStorage.removeItem("selectcarttype");
            this.showQuickview();
        }
        this.selectcarttype = "";
        this.cartTypeModal.hide();
    }

    showQuickview(): void {
        if (this.showingQuickview) {
            return;
        }
        this.showingQuickview = true;
        this.showLoader = false;
        this.quickview.show(this.product).subscribe({
            complete: () => {
                this.showLoader = false;
                this.showingQuickview = false;
                this.cd.markForCheck();
            }
        });
    }

    getAddressDetails(check) {
        try {
            let userData = JSON.parse(this.localstorage.getItem('userData'));
            let usersData = JSON.parse(this.localstorage.getItem('user'));
            if (usersData) {
                this.usertype = userData.user_type;
                let user = {
                    "account_pk": parseInt(JSON.stringify(userData.account_pk)),
                    "offset": 10, "limit": 10
                };

                this.productService.getAddressList(user).subscribe(response => {
                    if (response.success == true) {
                        this.showLoader = false;
                        this.addresses = response.data;
                        this.addresses.forEach(address => {
                            if (address.default == true) {
                                this.default_address = address;
                            }
                            else {
                                this.new_addresses.push(address);
                            }
                        });
                    }
                    else {
                        this.showLoader = false;
                    }
                }, error => {
                    this.showLoader = false;
                })
            }
        }
        catch (error) {
            this.userService.InsertExceptionLog({ "method_name": "getAddress(check)", "page_name": "ProductDetailsComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
        }
    }

    viewAddressModal() {
        this.isProductDetails = true;
        this.addressModal = this.modalService1.show(this.template1, { class: 'modal-dialog-centered modal-lg address', backdrop: 'static' });
    }

    closemodel() {
        this.isProductDetails = false;
        this.addressModal.hide();
    }

    addressChange(isAddressChanged) {
        let data = {};
        let user = JSON.parse(this.localstorage.getItem('userData'));
        let address = JSON.parse(this.localstorage.getItem('address'));
        let carttypes = (this.localstorage.getItem('selectcarttype') != '' && this.localstorage.getItem('selectcarttype') != null && this.localstorage.getItem('selectcarttype') != undefined) ? this.localstorage.getItem('selectcarttype') : this.selectcarttype;
        let billingaddress = JSON.parse(this.localstorage.getItem('billingAddress'));
        let changePincode = this.localstorage.getItem('Pincode');
        let searchData = {}
        if (user && address && billingaddress) {
            // this.account_pk = + JSON.stringify(user.user_ids[0].id);
            this.account_pk = + JSON.stringify(user.account_pk);
            var addresstypecheck = address.address['default'];
            if (addresstypecheck === true) {
                this.shippingaddressid = "default";
            }
            else {
                this.shippingaddressid = address.address['address_id'];
            }
            var Billingaddresscheck = billingaddress.address['default'];
            if (Billingaddresscheck === true) {
                this.billingaddressid = "default";
            }
            else {
                this.billingaddressid = billingaddress.address['address_id'];
            }
            searchData = {
                search_text: "",
                bs_model_name: "",
                pg_line: "",
                multi_pg_line: [],
                multi_bs_model_name: [
                ],
                multi_dispgroup_name: [],
                model_cat: "",
                mod_uid: "",
                category_type: "",
                bom_id: "",
                durafit_parts: false,
                kit_parts: false,
                part_number_last_four: "",
                offset: 0,
                limit: 20,
                pl_id: "",
                // is_discount_needed: true,
                account_pk: this.account_pk,
                // address_pk: this.address_pk,
                shipping_address_id: this.shippingaddressid,
                is_pincode_based_division: address.address.postal_code != changePincode ? true : false,
                pincode: address.address.postal_code != changePincode ? changePincode : "",
                part_number: this.partNumber,
                is_for_epc_data: false,
                is_for_discount: true,
                is_for_ecom_detail: true,
                cart_type: carttypes,
                is_for_inventory:this.isUserType?.user_type == 'MECH' ? true: false,

            }
        }
        else {
            searchData = {
                search_text: "",
                bs_model_name: "",
                pg_line: "",
                multi_pg_line: [],
                multi_bs_model_name: [
                ],
                multi_dispgroup_name: [],
                model_cat: "",
                mod_uid: "",
                category_type: "",
                bom_id: "",
                durafit_parts: false,
                kit_parts: false,
                part_number_last_four: "",
                offset: 0,
                // start_row:0,
                limit: 20,
                pl_id: "",
                part_number: this.partNumber,
                is_for_discount: changePincode ? true : false,
                is_for_ecom_detail: true,
                is_pincode_based_division: changePincode ? true : false,
                pincode: changePincode ? changePincode : "",
                is_for_epc_data: false,
                cart_type: carttypes,
                is_for_inventory:this.isUserType?.user_type == 'MECH' ? true: false,


            }
        }
        this.partnumberbaseddiscount(searchData, isAddressChanged)
    }
    // setSelectedAddress(event: any) {
    //     try {
    //         this.SelectAddressId = event['id'];
    //         this.localstorage.setItem('AddressId', this.SelectAddressId);
    //         //var selectpincode = this.addresses.new_address.filter(add => add.id === parseInt(this.SelectAddressId));
    //         if (event !== this.default_address) {
    //             this.SelectAddressId = event.address_id;
    //             this.Selectaddress = event.postal_code;
    //             this.localstorage.setItem('Pincode', JSON.stringify(this.Selectaddress))
    //             let userAddress = {
    //                 address: event,
    //                 type: 'new'
    //             }
    //             this.appStateService.address.next(userAddress)
    //             this.localstorage.setItem('address', JSON.stringify(userAddress));
    //             this.localstorage.setItem('billingAddress', JSON.stringify(userAddress));
    //         }
    //         else {
    //             this.SelectAddressId = event.address_id;
    //             if (event == this.default_address) {
    //                 this.Selectaddress = this.default_address.postal_code;
    //                 this.localstorage.setItem('Pincode', this.Selectaddress ? this.Selectaddress : 'Select Address')
    //             }
    //             let address = this.default_address;
    //             let userAddress = {
    //                 address: address,
    //                 type: 'default'
    //             }
    //             this.appStateService.address.next(userAddress)
    //             this.localstorage.setItem('address', JSON.stringify(userAddress));
    //             this.localstorage.setItem('billingAddress', JSON.stringify(userAddress));
    //         }
    //         localStorage.removeItem('cartItems');
    //         setTimeout(() => {
    //             this.cart.load();
    //         }, 500);
    //         this.modalRef1.hide();
    //         this.onRefresh();
    //     }
    //     catch (error) {
    //         this.userService.InsertExceptionLog({ "method_name": "setSelectedAddress()", "page_name": "MenuheaderComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
    //     }
    // }

    // onRefresh() {
    //     // this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
    //     let currentUrl = this.router.url;
    //     if (currentUrl.includes('product-details')) {
    //         this.router.navigateByUrl(currentUrl)
    //             .then(() => {
    //                 this.router.navigated = false;
    //                 this.router.navigate([this.router.url]);
    //             });
    //     }
    // }

   
    partAddtoCart(product: any,index): void {
        
        try {
            let permission = this.userService.CheckPermission(
                'btn_cart'
            );
            if (permission) {
                
                // console.log("final_fix_counter>>>",product, this.storeQuantity,this.newArrayforPartnumner[index]['tempQuantity'],)
                // console.log("afterassign_quantity",product,product["quantity"],product["tempQuantity"]);
                if(this.newArrayforPartnumner[index]['tempQuantity'] == undefined){
                    this.newArrayforPartnumner[index]['tempQuantity'] = this.storeQuantity;
                }else{
            }
                if (product["tempQuantity"] != null || product["tempQuantity"] != 0 || product["tempQuantity"] != undefined) {
                    if (!this.addingToCart && product && product["tempQuantity"]) {
                        this.addingToCart = true;
                        // if (product["quantity"] < product['min_quantity']) {
                        //     this.toasterService.error('Quantity field cannot be less than minimum quantity.');
                        //     this.addingToCart = false;
                        //     return;
                        // }
                        this.addingToCart = true;
                        if (this.localstorage.getItem('getCartType')) {
                            this.cartTypeList = JSON.parse(this.localstorage.getItem('getCartType'));
                        }
                        var commoncarttypedata = this.cartTypeList.filter(alitems => alitems.common_distributor_categories.includes(product['cart_type']));
                        var Distributercarttypedata = this.cartTypeList.filter(alitems => alitems.distributor_categories.includes(product['cart_type']));
                        let cart_type_name = "";
                        if (commoncarttypedata.length > 0 || commoncarttypedata.length ) {
                            if (this.localstorage.getItem('selectcarttype')) {
                                cart_type_name = this.localstorage.getItem('selectcarttype');
                                // console.log("cart_add_item>>>",product["tempQuantity"])
                                product.tempQuantity = this.newArrayforPartnumner[index].tempQuantity;
                                product.part_number = product.sku_item;
                                // console.log("added_new_key_tempQuant",product, product.tempQuantityS)
                                this.cart.add(product, product['tempQuantity'], product['cart_type'], cart_type_name
                                    // ,false
                                ).subscribe({
                                    complete: () => {
                                        // product["quantity"] = product['min_quantity'];
                                        if (product['resource_type'] == 'Pack' ) {
                                            product["tempQuantity"] = (product["tempQuantity"]) ? product["tempQuantity"] / parseInt(product['moq']) : 1 * parseInt(product['moq'])
                                        }
                                        this.addingToCart = false;
                                        this.showLoader = false;
                                        this.localstorage.removeitems('selectcarttype');
                                    }
                                });
                                localStorage.removeItem('selectcarttype')
                            }
                            else {
                                this.currentlyAddingProduct = product;
                                cart_type_name = commoncarttypedata[0]["cart_type"]
                                if (this.cartTypeList.length > 0) {
                                    this.selectcarttype = this.cartTypeList[1]["cart_type"]
                                    this.changeCartType(this.selectcarttype, product);
                                    this.selectedProduct = product;
                                    this.isPartaddtocart_clicked = true;
                                    this.btndisabled = false;
                                    this.addingToCart = false
                                    this.btnshowConfrimed = false;
                                this.btnshowsubmit = true
                                    this.cartTypeModal = this.modalService1.show(this.template, { class: 'modal-dialog-centered modal-md', backdrop: 'static' });
                                }
                            }
                        }
                        else if (Distributercarttypedata.length > 0) {
                            cart_type_name = Distributercarttypedata[0]["cart_type"]
                            product.tempQuantity = this.newArrayforPartnumner[index].tempQuantity;
                            product.part_number = product.sku_item;
                            // console.log("distributer_added_new_key_tempQuant",product, product.tempQuantity)
                            this.cart.add(product, product["tempQuantity"], product['distributor_category'], cart_type_name
                                // ,false
                            ).subscribe({
                                complete: () => {
                                    // product[product.quantity] = product['min_quantity'];
                                    if (product['resource_type'] == 'Pack' ) {
                                        product["tempQuantity"] = (product["tempQuantity"]) ? product['customerQuantity'] : 1 * parseInt(product['moq'])
                                    }
                                    this.addingToCart = false;
                                    this.showLoader = false;
                                }
                            });
                        }
                    }
                    else {
                        if (product['resource_type'] == 'Pack' ) {
                            if (this.unit.value < product['minimum_quantity']) {
                                this.toasterService.error(
                                    'Unit field should not be empty.'
                                );
                                this.addingToCart = false;
                                return;
                            }
                        }
                        else if (product[product.tempQuantity] == 0) {
                            this.toasterService.error('Quantity field should not be 0.');
                        }
                        else { }
                    }
                }
                else {
                    if (this.product['resource_type'] == 'Pack' ) {
                        if (this.unit.value < this.product['minimum_quantity']) {
                            this.toasterService.error('Unit field should not be empty.');
                            this.addingToCart = false;
                            return
                        }
                    }
                    else {
                        this.toasterService.error('Quantity field should not be empty.');
                    }
                }
            }
            else {

                this.toasterService.error(blockPermissionMsg);
            }
        }
        catch (error) {
            this.addingToCart = false;
            this.userService.InsertExceptionLog({ "method_name": "partAddToCart()", "page_name": "ProductComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
        }
    }

    productEngineAddtocart(product:any){
        // console.log("part_add_to_cart>>",product)
        // product = product.data;
        try {
            let permission = this.userService.CheckPermission(
                'btn_cart'
            );
            if (permission) {
                if(product.data['tempQuantity'] == undefined){
                    product.data['tempQuantity'] = product.tempQuantity;
                }else{}
                // console.log("final_fix_counter>>>",this.marketDetailsarr[index],this.marketDetailsarr[index].tempQuantity,this.marketDetailsarr[index]['tempQuantity'])
                // console.log("afterassign_quantity",product,product["quantity"],product["tempQuantity"])
                if (product.data["tempQuantity"] != null || product.data["tempQuantity"] != 0 || product.data["tempQuantity"] != undefined) {
                    if (!this.addingToCart && product.data && product.data["tempQuantity"]) {
                        this.addingToCart = true;
                        // if (product.data["quantity"] < product.data['min_quantity']) {
                        //     this.toasterService.error('Quantity field cannot be less than minimum quantity.');
                        //     this.addingToCart = false;
                        //     return;
                        // }
                        this.addingToCart = true;
                        if (this.localstorage.getItem('getCartType')) {
                            this.cartTypeList = JSON.parse(this.localstorage.getItem('getCartType'));
                        }
                        var commoncarttypedata = this.cartTypeList.filter(alitems => alitems.common_distributor_categories.includes(product.data['cart_type']));
                        var Distributercarttypedata = this.cartTypeList.filter(alitems => alitems.distributor_categories.includes(product.data['cart_type']));
                        let cart_type_name = "";
                        if (commoncarttypedata.length > 0 || commoncarttypedata.length ) {
                            if (this.localstorage.getItem('selectcarttype')) {
                                cart_type_name = this.localstorage.getItem('selectcarttype');
                                // console.log("cart_add_item>>>",product,product.data["tempQuantity"])
                                product.data.tempQuantity = product.tempQuantity;
                                product.data.part_number = product.data.sku_item;
                                // console.log("added_new_key_tempQuant",product.data, product.data.tempQuantityS)
                                this.cart.add(product.data, product.data['tempQuantity'], product.data['cart_type'], cart_type_name
                                    // ,false
                                ).subscribe({
                                    complete: () => {
                                        // product.data["quantity"] = product.data['min_quantity'];
                                        if (product.data['resource_type'] == 'Pack' ) {
                                            product.data["tempQuantity"] = (product.data["tempQuantity"]) ? product.data["tempQuantity"] / parseInt(product.data['moq']) : 1 * parseInt(product.data['moq'])
                                        }
                                        this.addingToCart = false;
                                        this.showLoader = false;
                                        this.localstorage.removeitems('selectcarttype');
                                    }
                                });
                                localStorage.removeItem('selectcarttype')
                            }
                            else {
                                this.currentlyAddingProduct = product.data;
                                cart_type_name = commoncarttypedata[0]["cart_type"]
                                if (this.cartTypeList.length > 0) {
                                    this.selectcarttype = this.cartTypeList[1]["cart_type"]
                                    this.changeCartType(this.selectcarttype, product.data);
                                    this.selectedProduct = product.data;
                                    this.isPartaddtocart_clicked = true;
                                    this.btndisabled = false;
                                    this.addingToCart = false
                                    this.btnshowConfrimed = false;
                                    this.btnshowsubmit = true
                                    this.cartTypeModal = this.modalService1.show(this.template, { class: 'modal-dialog-centered modal-md', backdrop: 'static' });
                                }
                            }
                        }
                        else if (Distributercarttypedata.length > 0) {
                            cart_type_name = Distributercarttypedata[0]["cart_type"];
                            // console.log("cart_add_item>>>",product,product.data["tempQuantity"])

                            product.data.tempQuantity = product.tempQuantity;
                            product.data.part_number = product.data.sku_item;
                            // console.log("distributer_added_new_key_tempQuant",product,product.data, product.tempQuantity)
                            this.cart.add(product.data, product.data["tempQuantity"], product.data['distributor_category'], cart_type_name
                                // ,false
                            ).subscribe({
                                complete: () => {
                                    // product[product.quantity] = product['min_quantity'];
                                    if (product.data['resource_type'] == 'Pack' ) {
                                        product.data["tempQuantity"] = (product.data["tempQuantity"]) ? product.data['customerQuantity'] : 1 * parseInt(product.data['moq'])
                                    }
                                    this.addingToCart = false;
                                    this.showLoader = false;
                                }
                            });
                        }
                    }
                    else {
                        if (product.data['resource_type'] == 'Pack' ) {
                            if (this.unit.value < product.data['minimum_quantity']) {
                                this.toasterService.error(
                                    'Unit field should not be empty.'
                                );
                                this.addingToCart = false;
                                return;
                            }
                        }
                        else if (product.data[product.data.tempQuantity] == 0) {
                            this.toasterService.error('Quantity field should not be 0.');
                        }
                        else { }
                    }
                }
                else {
                    if (this.product['resource_type'] == 'Pack' ) {
                        if (this.unit.value < this.product['minimum_quantity']) {
                            this.toasterService.error('Unit field should not be empty.');
                            this.addingToCart = false;
                            return
                        }
                    }
                    else {
                        this.toasterService.error('Quantity field should not be empty.');
                    }
                }
            }
            else {

                this.toasterService.error(blockPermissionMsg);
            }
        }
        catch (error) {
            this.addingToCart = false;
            this.userService.InsertExceptionLog({ "method_name": "addToCart()", "page_name": "ProductComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
        }
    }
    addToCart(product: Product): void {
        // console.log("check_product_in_addtocart",product);
                try {
            let permission = this.userService.CheckPermission(
                'btn_cart'
            );
            if (permission) {
                if (isNaN(product['customerQuantity']) || product['customerQuantity'] === 0) {
                    this.toasterService.error("Quantity cannot be empty or 0");
                    return;
                }
                if (product['resource_type'] == 'Pack' ) {
                    // product['customerQuantity'] = parseInt(product['min_quantity']) ? parseInt(product['min_quantity']) * parseInt(product['column_4']) : 1 * parseInt(product['column_4'])
                    // product['customerQuantity'] = parseInt(product['customerQuantity']) ? product['customerQuantity'] * parseInt(product['moq']) : 1 * parseInt(product['moq'])
                    product['customerQuantity'] = this.isLoggedIn ? parseInt(product['customerQuantity']) ? parseInt(product['customerQuantity']) : 1 : parseInt(product['customerQuantity']) ? product['customerQuantity'] * parseInt(product['moq']) : 1 * parseInt(product['moq'])
                }
                else {
                    // product['customerQuantity'] = parseInt(product['min_quantity']) ? parseInt(product['min_quantity']) : 1;
                    product['customerQuantity'] = parseInt(product['customerQuantity']) ? parseInt(product['customerQuantity']) : 1;
                }
                if (product['customerQuantity'] != null || product['customerQuantity'] != 0 || product['customerQuantity'] != undefined) {
                    if (!this.addingToCart && product && product['customerQuantity']) {
                        this.addingToCart = true;
                        if (product['customerQuantity'] < product['min_quantity']) {
                            this.toasterService.error('Quantity field cannot be less than minimum quantity.');
                            this.addingToCart = false;
                            return;
                        }
                        this.addingToCart = true;
                        if (this.localstorage.getItem('getCartType')) {
                            this.cartTypeList = JSON.parse(this.localstorage.getItem('getCartType'));
                        }
                        var commoncarttypedata = this.cartTypeList.filter(alitems => alitems.common_distributor_categories.includes(product['distributor_category']));
                        var Distributercarttypedata = this.cartTypeList.filter(alitems => alitems.distributor_categories.includes(product['distributor_category']));
                        let cart_type_name = "";
                        if (commoncarttypedata.length > 0) {
                            if (this.localstorage.getItem('selectcarttype')) {
                                cart_type_name = this.localstorage.getItem('selectcarttype');

                                this.cart.add(product, product['customerQuantity'], product['distributor_category'], cart_type_name
                                    // ,false
                                ).subscribe({
                                    complete: () => {
                                        // product['customerQuantity'] = product['min_quantity'];
                                        if (product['resource_type'] == 'Pack' ) {
                                            product['customerQuantity'] = parseInt(product['customerQuantity']) ? product['customerQuantity'] / parseInt(product['moq']) : 1 * parseInt(product['moq'])
                                        }
                                        this.addingToCart = false;
                                        this.showLoader = false;
                                        this.localstorage.removeitems('selectcarttype');
                                    }
                                });
                                localStorage.removeItem('selectcarttype')
                            }
                            else {
                                this.currentlyAddingProduct = product;
                                cart_type_name = commoncarttypedata[0]["cart_type"]
                                if (this.cartTypeList.length > 0) {
                                    this.selectcarttype = this.cartTypeList[1]["cart_type"]
                                    this.changeCartType(this.selectcarttype, product);
                                    this.selectedProduct = product;
                                    this.btndisabled = false;
                                    this.addingToCart = false
                                    this.btnshowConfrimed = false;
                                    this.btnshowsubmit = true
                                    this.cartTypeModal = this.modalService1.show(this.template, { class: 'modal-dialog-centered modal-md', backdrop: 'static' });
                                }
                            }
                        }
                        else if (Distributercarttypedata.length > 0) {
                            cart_type_name = Distributercarttypedata[0]["cart_type"]

                            this.cart.add(product, product['customerQuantity'], product['distributor_category'], cart_type_name
                                // ,false
                            ).subscribe({
                                complete: () => {
                                    // product['customerQuantity'] = product['min_quantity'];
                                    if (product['resource_type'] == 'Pack' ) {
                                        product['customerQuantity'] = parseInt(product['customerQuantity']) ? product['customerQuantity'] : 1 * parseInt(product['moq'])
                                    }
                                    this.addingToCart = false;
                                    this.showLoader = false;
                                }
                            });
                        }
                    }
                    else {
                        if (product['resource_type'] == 'Pack' ) {
                            if (this.unit.value < product['min_quantity']) {
                                this.toasterService.error(
                                    'Unit field should not be empty.'
                                );
                                this.addingToCart = false;
                                return;
                            }
                        }
                        else if (product['customerQuantity'] == 0) {
                            this.toasterService.error('Quantity field should not be 0.');
                        }
                        else { }
                    }
                }
                else {
                    if (this.product['resource_type'] == 'Pack' ) {
                        if (this.unit.value < this.product['min_quantity']) {
                            this.toasterService.error('Unit field should not be empty.');
                            this.addingToCart = false;
                            return
                        }
                    }
                    else {
                        this.toasterService.error('Quantity field should not be empty.');
                    }
                }
            }
            else {

                this.toasterService.error(blockPermissionMsg);
            }
        }
        catch (error) {
            this.addingToCart = false;
            this.userService.InsertExceptionLog({ "method_name": "addToCart()", "page_name": "ProductComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
        }
    }

    async alternateProducts(page) {
        try {
            this.alternateProductsList = [];
            let user = JSON.parse(this.localstorage.getItem('userData'));
            let address = JSON.parse(this.localstorage.getItem('address'));
            let billingaddress = JSON.parse(this.localstorage.getItem('billingAddress'));
            let searchData = {}
            if (user && address && billingaddress) {
                // this.account_pk = + JSON.stringify(user.user_ids[0].id);
                this.account_pk = + JSON.stringify(user.account_pk);
                var addresstypecheck = address.address['default'];
                if (addresstypecheck === true) {
                    this.shippingaddressid = "default";
                }
                else {
                    this.shippingaddressid = address.address['address_id'];
                }
                var Billingaddresscheck = billingaddress.address['default'];
                if (Billingaddresscheck === true) {
                    this.billingaddressid = "default";
                }
                else {
                    this.billingaddressid = billingaddress.address['address_id'];
                }
                searchData = {
                    search_text: "",
                    bs_model_name: "",
                    pg_line: "",
                    multi_pg_line: [],
                    multi_bs_model_name: [
                    ],
                    multi_dispgroup_name: [],
                    model_cat: "",
                    mod_uid: "",
                    category_type: "",
                    bom_id: "",
                    durafit_parts: false,
                    kit_parts: false,
                    part_number_last_four: "",
                    offset: page,
                    limit: 20,
                    pl_id: "",
                    //is_discount_needed: true,
                    account_pk: this.account_pk,
                    // address_pk: this.address_pk,
                    part_number_alt: this.partNumber,
                    shipping_address_id: this.shippingaddressid,
                    part_number: "",
                    is_for_epc_data: false,
                    is_for_discount: true,
                    is_for_ecom_detail: false,
                    is_for_inventory:this.isUserType?.user_type == 'MECH' ? true: false,

                    //  cart_type: cart_type
                }
            }
            else {
                searchData = {
                    search_text: "",
                    bs_model_name: "",
                    pg_line: "",
                    multi_pg_line: [],
                    multi_bs_model_name: [
                    ],
                    multi_dispgroup_name: [],
                    model_cat: "",
                    mod_uid: "",
                    category_type: "",
                    bom_id: "",
                    durafit_parts: false,
                    kit_parts: false,
                    part_number_last_four: "",
                    offset: page,
                    // start_row:0,
                    limit: 20,
                    pl_id: "",
                    part_number_alt: this.partNumber,
                    is_for_inventory:this.isUserType?.user_type == 'MECH' ? true: false,

                    //  cart_type: cart_type
                }
            }
            this.alternateProductsList = [];

            this.localstorage.setItem('showalternatepart', 'false')
            await this.productService.get_part_search_md(searchData).subscribe(data => {
                if (data.success === true) {
                    this.alternateProductsList = data.data;
                    for (let i = 0; i < data.data.length; i++) {
                        this.alternateProductsList[i].customerQuantity = data.data[i].min_quantity;
                        this.inventoryQuantity = data.data[i].inventory_quantity;
                        this.externalTrackSource = data.data[i].external_tracking_source;
                    }

                    if (this.alternateProductsList.length > 0) {
                        this.localstorage.setItem('showalternatepart', 'true')
                    }
                    if (this.alternateProductsList.length > 0) {
                        setTimeout(() => {
                            this.alternateProductsSlider("related-product");
                        }, 700)
                    }
                    this.showLoader = false;
                } else {
                    this.showLoader = false;
                    //this.toasterService.error(data.data.msg);
                }
            }, err => {
            })
        }
        catch (error) {
            this.userService.InsertExceptionLog({ "method_name": "alternateProduct()", "page_name": "ProductDetailsComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
        }
    }

    readQuantity(event, product, isDiscountApplicable) {
        product.customerQuantity = event;
        this.currentQuantity = event;
        if (this.discountOffers && isDiscountApplicable) {
            if (product.customerQuantity >= parseInt((product['resource_type'] == 'Pack' ) ? (this.discountOffers.quantity / product['moq']) : this.discountOffers.quantity)) {
                this.isQuantityDiscountAvailable = true;
                this.savedAmount = this.ProductMRP - this.quantityBasedPartPrice;
            }
            else {
                this.isQuantityDiscountAvailable = false;
                this.savedAmount = this.ProductMRP - this.Productdiscount;
            }
            
            if(this.discountSlabOffer.length > 1){
                if(event >= ( product.resource_type == 'Pack' ?this.discountOffers?.quantity / product.moq:this.discountOffers?.quantity)){
                    this.isshowMoreSlabDiscount = true
                }
                else{
                    this.isshowMoreSlabDiscount = false
                }
            }
        }
        if (this.isLoggedIn && JSON.parse(this.localstorage.getItem("Pincode"))){
            if(this.isUserType?.user_type == 'MECH' && this.externalTrackSource?.includes('Delhivery')){
                this.displayDeliveryETA()
            }
            else{
                this.displayDeliveryTimeline(product);
            }
            if(event >= (product.resource_type == 'Pack' ?this.discountOffers?.quantity / product.moq:this.discountOffers?.quantity)){
                this.getDiscountedPriceDetails(product?.part_number,  (product['resource_type'] == 'Pack' ) ? this.currentQuantity * product['moq'] : this.currentQuantity)
            }
           
        }
        // if(product?.customerQuantity >= this.discountOffers?.quantity){
        //     this.route.queryParams.subscribe(params => {
        //         this.partNumber = params['part_Number'] 
        //     this.loadData(this.partNumber,
        //         product.customerQuantity)
        //     });
        // }
    }
    readproductdetaailQuantity(data,event,index) {          
        // console.log("check_readProductQuantity",data,event,index,this.newArrayforPartnumner, this.newArrayforPartnumner[index]?.tempQuantity,this.newArrayforPartnumner.tempQuantity,this.newArrayforPartnumner["tempQuantity"] )  
        if(event > this.inventoryQuantity){
            this.addtocartDisable = true;
        }else{
            this.addtocartDisable = false;
            this.storeQuantity = event;
            this.newArrayforPartnumner[index].tempQuantity =  this.storeQuantity;
            // console.log("checK-temppnxsndcdcdocdc",this.newArrayforPartnumner[index].tempQuantity,this.newArrayforPartnumner[index].resource_type)
                if( this.newArrayforPartnumner[index].resource_type == 'Pack' ){
                  this.newArrayforPartnumner[index].tempQuantity = event * this.newArrayforPartnumner[index]?.moq; 
                }
        }
     
    }
    setAddCart(event) {
        try {
            let permission = this.userService.CheckPermission(
                'btn_cart'
            );
            if (permission) {
                this.btndisabled = true;
                var cart_type_name = this.localstorage.getItem('selectcarttype');
                // console.log("checkcdkcdcdcmcmdcd",this.selectedProduct);
                if(this.isPartaddtocart_clicked){
                    this.selectedProduct.part_number = this.selectedProduct['sku_item'];
                    this.selectedProduct['customerQuantity'] = this.selectedProduct['tempQuantity'];
                }
       
                this.cart.add(this.selectedProduct, this.selectedProduct.customerQuantity, this.selectedProduct['distributor_category'], cart_type_name
                    // ,false
                ).subscribe({
                    complete: () => {
                        this.selectedProduct.customerQuantity = this.selectedProduct.min_quantity;
                        this.addingToCart = false;
                        this.isPartaddtocart_clicked = false;
                    }
                });
                localStorage.removeItem('selectcarttype');
                this.selectcarttype = "";
                this.cartTypeModal.hide();
                this.btndisabled = false;
            }
            else {

                this.toasterService.error(blockPermissionMsg);
                this.isPartaddtocart_clicked = false;

            }
        }
        catch (error) {
            this.isPartaddtocart_clicked = false;
            this.userService.InsertExceptionLog({ "method_name": "setAddCart()", "page_name": "ProductComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
        }
    }

    ViewChildPart() {
        if (this.childPartsModal) {
            this.childPartsModal.hide();
        }
        this.childPartsModal = this.modalService1.show(this.childParts, { class: "modal-dialog-centered modal-lg", backdrop: "static" });
    }

    preventLeadingSpace(event: KeyboardEvent) {
        // Check if the pressed key is space (keyCode 32) and if the cursor is at the beginning of the input
        if (event.keyCode === 32 && event.target['selectionStart'] === 0) {
            event.preventDefault(); // Prevent the space from being added
        }
    }

    onSubmitQuery(partNumber) {
        if (this.userQuery === "" || this.userQuery === null || this.userQuery === undefined) {
            this.toasterService.error("Please type your query");
            return;
        }
        else {
            let user = JSON.parse(this.localstorage.getItem('userData'));
            let requestBody = {
                account_pk: user.account_pk,
                part_number: partNumber,
                query: this.userQuery
            }

            this.userService.submitQuery(requestBody).subscribe(response => {
                if (response.success) {
                    this.toasterService.success(response.data.msg);
                    this.userQuery = "";
                    this.modalService.hide();
                }
                else {
                    this.toasterService.error(response.data.msg);
                }
                this.showLoader = false;
            },
                error => {
                    this.showLoader = false;
                    this.toasterService.error(error.error.data.msg);
                })
        }
    }


    ShowEngine() {
        if (this.selectModalRef) {
            this.selectModalRef.hide();
        }
        this.selectModalRef = this.modalService1.show(this.selectModalPopup, { class: "modal-dialog-centered modal-lg", backdrop: "static" });
    }

    closeModelPopUp() {
        if (this.selectModalRef) {
            this.selectModalRef.hide();
        }
    }

    closePopUp() {
        this.childPartsModal.hide()
    }
    selectmodel: any;

    modelVcData: any;
    selectsubmodelname: any;
    async showVcDetails(submodelname, vcno) {
        try {
            // this.showProduct = true;
            //this.showProductEngine = false;
            this.selectsubmodelname = submodelname;
            const ListInput: InputPartSearch = {} as InputPartSearch;
            ListInput.part_number = this.partNumber;
            ListInput.base_model = this.selectsubmodelname;
            ListInput.model_cat = this.selectmodel;
            ListInput.vc_no = vcno;

            //this.modelVcData = [];
            let response = await this.modelse.get_sub_aggregates(ListInput);
            if (response["success"] == true) {

                this.modelVcData = response["data"];
                if (this.modelVcData.length > 0) {
                    // this.showVcPartDetails(this.modelVcData.vc_no);
                    this.showProduct = false;
                    this.showProductEngine = true;
                    if (this.selectModalRef) {
                        this.selectModalRef.hide();
                    }
                    //this.router.navigate(['/shop/productengine'], { queryParams: { part_Number: this.partNumber } });


                    // // if (this.selectsubModalRef) {
                    // //     this.selectsubModalRef.hide();
                    // // }
                }
                else {
                    this.toasterService.error("Data not found");
                }

            }
            else {
                this.modelVcData = [];
                this.toasterService.error(response["data"]["msg"]);
            }


        }
        catch (error) {

        }
    }



    closeSubModelPopUp() {
        if (this.selectsubModalRef) {
            this.selectsubModalRef.hide();
        }
    }

    slideToTop() {
        document.getElementById('compatible-header').scrollIntoView({ behavior: 'smooth', block: 'start' });
    }



    onRouteSameUrl(partnumber) {
        // this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
        let currentUrl = this.router.url;
        if (currentUrl.includes('product-details')) {
            this.router.navigateByUrl('/', { skipLocationChange: true })
                .then(() => {
                    this.router.navigated = false;
                    this.router.navigate(["/shop/product-details"], { queryParams: { part_Number: partnumber } });
                });
        }
    }

    newOffersMethod(){

        this.mergeDisountArray = [];
        this.mergeDisountArray = this.discountOfferData.concat(this.validSchemeList, this.offercard);
        let samevalue = [];
        this.mergeDisountArray.forEach((ele) => {
            let words = ele.discount_type.split('_');
            for (let i = 0; i < words.length; i++) {
                words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
            }
            let capitalizedDiscountType = words.join(' ');
            if (!samevalue.includes(capitalizedDiscountType)) {
                samevalue.push(capitalizedDiscountType)
            }
        })
        this.storeCrouselname = samevalue;
 
    }
    //check if qty based discount available for product
    getOfferDetails(partNumber, division_id, isAddressChanged) {
        let user = JSON.parse(this.localstorage.getItem('userData'));
        let requestBody = {
            account_pk: user.account_pk,
            part_number: partNumber,
            division_id: division_id
        }
        // setTimeout(() => {
            this.userService.getPartDiscounts(requestBody).subscribe(response => {
                if (response.success == true) {
                    let discountsArray: any[] = response.data;
                    this.discountOfferData = response.data;
                    this.discountOfferData = this.discountOfferData.filter((result) => result.discount_type !== 'market_basket');
                    this.discountSlabOffer = discountsArray.filter(discount => discount.discount_type === "quantity_discount");
                    if (this.discountSlabOffer.length > 0) {
                        this.showQuantityDiscountMessage = true
                        this.isshowMoreSlabDiscount = false
                    }
                    this.discountOffers = this.discountSlabOffer[0];
                    if (this.discountOffers) {
                        if (parseInt(this.productList[0].customerQuantity) >= parseInt((this.productList[0]['resource_type'] == 'Pack' ) ? this.discountOffers.quantity / this.productList[0]['moq'] : this.discountOffers.quantity)) {
                            this.isQuantityDiscountAvailable = true;
                        }
                        this.getDiscountedPriceDetails(partNumber, this.discountOffers.quantity);
                    }
                    else {
                        this.isQuantityDiscountAvailable = false;
                        isAddressChanged = true;
                    }
                }
                else {
                    this.quantityBasedPartPrice = 0;
                    this.isQuantityDiscountAvailable = false;
                    this.discountOffers = null;
                    this.showQuantityDiscountMessage = false;
                }
                if (isAddressChanged === true) {
                    this.isQuantityDiscountAvailable = false;
                    this.showQuantityDiscountMessage = false;
                    this.refreshDiscountDetails(this.productList[0]);
                }
            },
                error => {
                    this.quantityBasedPartPrice = 0;
                    this.isQuantityDiscountAvailable = false;
                    //this.toasterService.error(error?.error?.data?.msg);
                    this.discountOffers = null;
                    this.showQuantityDiscountMessage = false;
                })
        // },500);
    }

    getDiscountedPriceDetails(partNumber, discountQuantity) {
        let user = JSON.parse(this.localstorage.getItem('userData'));
        let address = JSON.parse(this.localstorage.getItem('address'));
        let changePincode = this.localstorage.getItem('Pincode');
        let requestBody = {
            "search_text": "",
            "bs_model_name": "",
            "pg_line": "",
            "multi_pg_line": [],
            "multi_bs_model_name": [],
            "multi_dispgroup_name": [],
            "model_cat": "",
            "mod_uid": "",
            "category_type": "",
            cart_type: this.selectedCartType,
            "bom_id": "",
            "durafit_parts": false,
            "kit_parts": false,
            "part_number_last_four": "",
            "offset": 0,
            "size": 20,
            "pl_id": "",
            "account_pk": user.account_pk,
            "shipping_address_id": address.address['isDefault'] ? "default" : address.address['address_id'],
            "is_pincode_based_division": address.address.postal_code != changePincode ? true : false,
            "pincode": address.address.postal_code != changePincode ? changePincode : "",
            "is_for_epc_data": false,
            "is_for_discount": true,
            "is_for_ecom_detail": true,
            "quantity": parseInt(discountQuantity),
            "part_number": partNumber,
            "is_for_inventory":this.isUserType?.user_type == 'MECH' ? true: false,

        }
        this.showLoader = true
        this.productService.get_part_search_md(requestBody).subscribe(response => {
            this.showLoader = false
            if (response.success) {
                this.quantityBasedPartDetails = response?.data[0];
                this.quantityBasedDivisionDetails = response.division_info;
                this.inventoryQuantity =  response?.data[0].inventory_quantity;
                this.externalTrackSource = response.data[0].external_tracking_source;
                // this.showQuantityDiscountMessage = true;
                if (response?.data[0]['resource_type'] == 'Pack') {
                    let tmgoQuantity = parseInt(discountQuantity) / response?.data[0]['moq'];
                    this.quantityBasedPartPrice = response.data[0]?.total_amount / tmgoQuantity;
                    let tmgoMRP = this.quantityBasedPartDetails.crm_mrp * response?.data[0]['moq'];
                    this.quantityBasedPercentage = parseFloat((((tmgoMRP - this.quantityBasedPartPrice) / tmgoMRP) * 100)?.toFixed(1));
                    this.quantityBasedPercentage = isNaN(this.quantityBasedPercentage) ? 0 : this.quantityBasedPercentage
                    this.ProductMRP = response?.data[0]?.crm_mrp * response?.data[0]?.moq;
                    this.savedAmount = this.ProductMRP - this.quantityBasedPartPrice;
                }
                else {
                    this.quantityBasedPartPrice = response.data[0]?.total_amount / parseInt(discountQuantity);
                    this.quantityBasedPercentage = parseFloat((((this.quantityBasedPartDetails.crm_mrp - this.quantityBasedPartPrice) / this.quantityBasedPartDetails.crm_mrp) * 100)?.toFixed(1));
                    this.quantityBasedPercentage = isNaN(this.quantityBasedPercentage) ? 0 : this.quantityBasedPercentage
                    this.ProductMRP = response?.data[0]?.crm_mrp;
                    this.savedAmount = this.ProductMRP - this.quantityBasedPartPrice;
                }

            }
            else {
                this.quantityBasedPartPrice = 0;
                this.showQuantityDiscountMessage = false;
            }
        },
            error => {
                this.showLoader = false
            })
    }

    percentageCalculation() {
        if ((this.productList[0]?.base_discount + this.productList[0]?.scheme_discount +
            this.productList[0]?.tml_rule_discount + this.productList[0]?.dealer_rule_discount +
            this.productList[0]?.rule_discount + this.productList[0]?.distributor2_discount_amount) > 0) {
            this.normalPercentage = parseFloat((((this.productList[0].crm_mrp - this.productList[0].total_amount) / this.productList[0].crm_mrp) * 100)?.toFixed(1));
        }
        this.quantityBasedPercentage = parseFloat((((this.quantityBasedPartDetails.crm_mrp - this.quantityBasedPartPrice) / this.quantityBasedPartDetails.crm_mrp) * 100)?.toFixed(1));
        this.quantityBasedPercentage = isNaN(this.quantityBasedPercentage) ? 0 : this.quantityBasedPercentage
    }

    ViewOffers() {
        this.partDiscountsModal = this.modalService1.show(this.partDiscounts, { class: "modal-dialog-centered modal-md", backdrop: "static" });
    }
    openFullimage(image,type){
        // console.log("modal_click_function>>",image,type)
        // this.modalImage = image;
        this.imgType = type;
        if(type == 'xenon'){
            // this.xenonImages.forEach((ele) => {
            //     ele.fullImg.forEach((med) => {
            //         if(image == med){
            //             this.modalImage = med
            //         }
            //     })
    
            // })
            this.xenonImages.forEach((ele) => {
                ele.fullImg.forEach((med) => {
                   
                    const parts = image.split('/');
                    const urltwo = med.split('/');
                    const lastPart = parts[parts.length - 1];
                    const lastpart2 = urltwo[urltwo.length - 1];

                    // Remove any query parameters if present
                    const fullimge1 = lastPart.split('?')[0];
                    const fullimage2 = lastpart2.split('?')[0];
                
                    if(fullimge1 == fullimage2){
                        // console.log("file1>>>file@>>",fullimge1,fullimage2)
                        this.modalImage = med;
                        // console.log("this.mmmsmsmsmmsms",this.modalImage)
                    }
                })
    
            })
        }else if(type == 'ganesh'){
            this.ganeshImages.forEach((ele) => {
                ele.fullImg.forEach((med) => {
                   
                    const parts = image.split('/');
                    const urltwo = med.split('/');
                    const lastPart = parts[parts.length - 1];
                    const lastpart2 = urltwo[urltwo.length - 1];

                    // Remove any query parameters if present
                    const fullimge1 = lastPart.split('?')[0];
                    const fullimage2 = lastpart2.split('?')[0];
                
                    if(fullimge1 == fullimage2){
                        // console.log("file1>>>file@>>",fullimge1,fullimage2)
                        this.modalImage = med;
                        // console.log("this.mmmsmsmsmmsms",this.modalImage)
                    }
                })
    
            })
        }else if(type == 'parts'){
            // this.PartsImages.forEach((ele) => {
            //     ele.fullImg.forEach((med) => {
            //         if(image == med){
            //             this.modalImage = med
            //         }
            //     })
    
            // })

            this.PartsImages.forEach((ele) => {
                ele.fullImg.forEach((med) => {
                   
                    const parts = image.split('/');
                    const urltwo = med.split('/');
                    const lastPart = parts[parts.length - 1];
                    const lastpart2 = urltwo[urltwo.length - 1];

                    // Remove any query parameters if present
                    const fullimge1 = lastPart.split('?')[0];
                    const fullimage2 = lastpart2.split('?')[0];
                
                    if(fullimge1 == fullimage2){
                        // console.log("file1>>>file@>>",fullimge1,fullimage2)
                        this.modalImage = med;
                        // console.log("this.mmmsmsmsmmsms",this.modalImage)
                    }
                })
    
            })
        }else if(type == 'partfour'){
            this.part511854600146Img.forEach((ele) => {
                ele.fullImg.forEach((med) => {
                   
                    const parts = image.split('/');
                    const urltwo = med.split('/');
                    const lastPart = parts[parts.length - 1];
                    const lastpart2 = urltwo[urltwo.length - 1];

                    // Remove any query parameters if present
                    const fullimge1 = lastPart.split('?')[0];
                    const fullimage2 = lastpart2.split('?')[0];
                
                    if(fullimge1 == fullimage2){
                        // console.log("file1>>>file@>>",fullimge1,fullimage2)
                        this.modalImage = med;
                        // console.log("this.mmmsmsmsmmsms",this.modalImage)
                    }
                })
    
            })
        }else if(type == 'partfive'){
            this.part580326513803Img.forEach((ele) => {
                ele.fullImg.forEach((med) => {
                   
                    const parts = image.split('/');
                    const urltwo = med.split('/');
                    const lastPart = parts[parts.length - 1];
                    const lastpart2 = urltwo[urltwo.length - 1];

                    // Remove any query parameters if present
                    const fullimge1 = lastPart.split('?')[0];
                    const fullimage2 = lastpart2.split('?')[0];
                
                    if(fullimge1 == fullimage2){
                        // console.log("file1>>>file@>>",fullimge1,fullimage2)
                        this.modalImage = med;
                        // console.log("this.mmmsmsmsmmsms",this.modalImage)
                    }
                })
    
            })
        }else if(type == 'partsix'){
            this.part885425202518Img.forEach((ele) => {
                ele.fullImg.forEach((med) => {
                   
                    const parts = image.split('/');
                    const urltwo = med.split('/');
                    const lastPart = parts[parts.length - 1];
                    const lastpart2 = urltwo[urltwo.length - 1];

                    // Remove any query parameters if present
                    const fullimge1 = lastPart.split('?')[0];
                    const fullimage2 = lastpart2.split('?')[0];
                
                    if(fullimge1 == fullimage2){
                        // console.log("file1>>>file@>>",fullimge1,fullimage2)
                        this.modalImage = med;
                        // console.log("this.mmmsmsmsmmsms",this.modalImage)
                    }
                })
    
            })
        }else if(type == 'partseven'){
            this.part13150611614Img.forEach((ele) => {
                ele.fullImg.forEach((med) => {
                   
                    const parts = image.split('/');
                    const urltwo = med.split('/');
                    const lastPart = parts[parts.length - 1];
                    const lastpart2 = urltwo[urltwo.length - 1];

                    // Remove any query parameters if present
                    const fullimge1 = lastPart.split('?')[0];
                    const fullimage2 = lastpart2.split('?')[0];
                
                    if(fullimge1 == fullimage2){
                        // console.log("file1>>>file@>>",fullimge1,fullimage2)
                        this.modalImage = med;
                        // console.log("this.mmmsmsmsmmsms",this.modalImage)
                    }
                })
    
            })
        }else if(type == 'parteight'){
            this.part250526207903Img.forEach((ele) => {
                ele.fullImg.forEach((med) => {
                   
                    const parts = image.split('/');
                    const urltwo = med.split('/');
                    const lastPart = parts[parts.length - 1];
                    const lastpart2 = urltwo[urltwo.length - 1];

                    // Remove any query parameters if present
                    const fullimge1 = lastPart.split('?')[0];
                    const fullimage2 = lastpart2.split('?')[0];
                
                    if(fullimge1 == fullimage2){
                        // console.log("file1>>>file@>>",fullimge1,fullimage2)
                        this.modalImage = med;
                        // console.log("this.mmmsmsmsmmsms",this.modalImage)
                    }
                })
    
            })
        }else if(type == 'partnine'){
            this.part250526706009Img.forEach((ele) => {
                ele.fullImg.forEach((med) => {
                   
                    const parts = image.split('/');
                    const urltwo = med.split('/');
                    const lastPart = parts[parts.length - 1];
                    const lastpart2 = urltwo[urltwo.length - 1];

                    // Remove any query parameters if present
                    const fullimge1 = lastPart.split('?')[0];
                    const fullimage2 = lastpart2.split('?')[0];
                
                    if(fullimge1 == fullimage2){
                        // console.log("file1>>>file@>>",fullimge1,fullimage2)
                        this.modalImage = med;
                        // console.log("this.mmmsmsmsmmsms",this.modalImage)
                    }
                })
    
            })
        }else if(type == 'partten'){
            this.part251526203802Img.forEach((ele) => {
                ele.fullImg.forEach((med) => {
                   
                    const parts = image.split('/');
                    const urltwo = med.split('/');
                    const lastPart = parts[parts.length - 1];
                    const lastpart2 = urltwo[urltwo.length - 1];

                    // Remove any query parameters if present
                    const fullimge1 = lastPart.split('?')[0];
                    const fullimage2 = lastpart2.split('?')[0];
                
                    if(fullimge1 == fullimage2){
                        // console.log("file1>>>file@>>",fullimge1,fullimage2)
                        this.modalImage = med;
                        // console.log("this.mmmsmsmsmmsms",this.modalImage)
                    }
                })
    
            })
        }else if(type == 'parteleven'){
            this.part251626307906Img.forEach((ele) => {
                ele.fullImg.forEach((med) => {
                   
                    const parts = image.split('/');
                    const urltwo = med.split('/');
                    const lastPart = parts[parts.length - 1];
                    const lastpart2 = urltwo[urltwo.length - 1];

                    // Remove any query parameters if present
                    const fullimge1 = lastPart.split('?')[0];
                    const fullimage2 = lastpart2.split('?')[0];
                
                    if(fullimge1 == fullimage2){
                        // console.log("file1>>>file@>>",fullimge1,fullimage2)
                        this.modalImage = med;
                        // console.log("this.mmmsmsmsmmsms",this.modalImage)
                    }
                })
    
            })
        }else if(type == 'partTwlve'){
            this.part580725601601Img.forEach((ele) => {
                ele.fullImg.forEach((med) => {
                   
                    const parts = image.split('/');
                    const urltwo = med.split('/');
                    const lastPart = parts[parts.length - 1];
                    const lastpart2 = urltwo[urltwo.length - 1];

                    // Remove any query parameters if present
                    const fullimge1 = lastPart.split('?')[0];
                    const fullimage2 = lastpart2.split('?')[0];
                
                    if(fullimge1 == fullimage2){
                        // console.log("file1>>>file@>>",fullimge1,fullimage2)
                        this.modalImage = med;
                        // console.log("this.mmmsmsmsmmsms",this.modalImage)
                    }
                })
    
            })
        }else if(type == 'partThirteen'){
            this.part571001155313Img.forEach((ele) => {
                ele.fullImg.forEach((med) => {
                   
                    const parts = image.split('/');
                    const urltwo = med.split('/');
                    const lastPart = parts[parts.length - 1];
                    const lastpart2 = urltwo[urltwo.length - 1];

                    // Remove any query parameters if present
                    const fullimge1 = lastPart.split('?')[0];
                    const fullimage2 = lastpart2.split('?')[0];
                
                    if(fullimge1 == fullimage2){
                        // console.log("file1>>>file@>>",fullimge1,fullimage2)
                        this.modalImage = med;
                        // console.log("this.mmmsmsmsmmsms",this.modalImage)
                    }
                })
    
            })
        }else if(type == 'partEourteen'){
            this.part269126204651Img.forEach((ele) => {
                ele.fullImg.forEach((med) => {
                   
                    const parts = image.split('/');
                    const urltwo = med.split('/');
                    const lastPart = parts[parts.length - 1];
                    const lastpart2 = urltwo[urltwo.length - 1];

                    // Remove any query parameters if present
                    const fullimge1 = lastPart.split('?')[0];
                    const fullimage2 = lastpart2.split('?')[0];
                
                    if(fullimge1 == fullimage2){
                        // console.log("file1>>>file@>>",fullimge1,fullimage2)
                        this.modalImage = med;
                        // console.log("this.mmmsmsmsmmsms",this.modalImage)
                    }
                })
    
            })
        }else if(type == 'partFifteen'){
            this.part551747100150Img.forEach((ele) => {
                ele.fullImg.forEach((med) => {
                   
                    const parts = image.split('/');
                    const urltwo = med.split('/');
                    const lastPart = parts[parts.length - 1];
                    const lastpart2 = urltwo[urltwo.length - 1];

                    // Remove any query parameters if present
                    const fullimge1 = lastPart.split('?')[0];
                    const fullimage2 = lastpart2.split('?')[0];
                
                    if(fullimge1 == fullimage2){
                        // console.log("file1>>>file@>>",fullimge1,fullimage2)
                        this.modalImage = med;
                        // console.log("this.mmmsmsmsmmsms",this.modalImage)
                    }
                })
    
            })
        }
        if(this.modalImage){
            // setTimeout(() => {
         
                this.fullimageModal = this.modalService1.show(this.fullImage,
                    { class: "modal-dialog-centered modal-lg", });   
            // }, );   
        }
     
    }
    openSchemeListModal(type: any) {
        if (type == 'otherOffer') {
            if (this.validSchemeList.length > 0 || this.discountOfferData.length > 0) {
                this.popupOpenType = "otherOffer"
                this.schemeListModal = this.modalService1.show(this.schemeList,
                    { class: "modal-dialog-centered modal-lg", backdrop: "static" });
            } else {
                this.toasterService.error("No schemes Found");
            }
        }
        else if (type == 'slabOffer') {
            if (this.discountSlabOffer.length > 1) {
                this.popupOpenType = "slabOffer"
                this.schemeListModal = this.modalService1.show(this.schemeList,
                    { class: "modal-dialog-centered modal-lg", backdrop: "static" });
            } else {
                this.toasterService.error("No schemes Found");
            }
        }

    }
    refreshDiscountDetails(product) {
        if (this.discountOffers) {
            if (product.customerQuantity >= parseInt(this.discountOffers.quantity)) {
                this.isQuantityDiscountAvailable = true;
                this.savedAmount = this.ProductMRP - this.quantityBasedPartPrice;
            }
            else {
                this.isQuantityDiscountAvailable = false;
                this.savedAmount = this.ProductMRP - this.Productdiscount;
            }
        }
        if(this.discountSlabOffer.length > 1){
            if(product.customerQuantity >=  (product.resource_type == 'Pack' ?this.discountOffers?.quantity / product.moq:this.discountOffers?.quantity)){
                this.isshowMoreSlabDiscount = true
            }
            else {
                this.isshowMoreSlabDiscount = false
            }
        }

        if (this.isLoggedIn && JSON.parse(this.localstorage.getItem("Pincode"))){
                this.getDiscountedPriceDetails(product?.part_number, product.resource_type == 'Pack' ?product.customerQuantity * product.moq:product.customerQuantity)
        }

    }
    getSchemeList() {
        try {
            this.validSchemeList = [];
                        let user = JSON.parse(this.localstorage.getItem('userData'));
            let req = {
                "action_type": "get_part_detail",
                "account_pk": user.account_pk,
                "part_number": this.partNumber ? this.partNumber : this.productList[0].part_number,
                "division_id": this.baseProductdata ? this.baseProductdata.division_info.DIV_ID : "",
                "organization_id": this.baseProductdata ? this.baseProductdata.division_info.ORG_ID : "",
            }
            this.productService.getPartSpecificScheme(req).subscribe(
                res => {
                    if (res.success) {
                        for (let i = 0; i < res.data.length; i++) {
                            res.data[i].discount_type = "Bulk Purchase";
                            res.data[i].discount_name = res.data[i].scheme_name;
                        }
                        this.validSchemeList = res.data;
                    }
                })
        } catch (error) {
            this.userService.InsertExceptionLog({
                "method_name": "alternateProduct()",
                "page_name": "ProductDetailsComponent",
                "portal_type": "Customer", "page_url": this.router.url,
                "error_message": error.message, "inner_exception": error
            });
        }
    }
    goToLocationNearbyMe() {
        // let userData = this.localstorage.getItem("userData");
        // let address = this.localstorage.getItem("address");
        this.router.navigate(['/account/nearbyme']);
        // try{
        //     let req = {
        //         "pincode":this.pinCodeNumber.toString()
        //     }
        //     this.addressService.getLatLong(req).subscribe(
        //         res => {
        //             if(res.success){
        //                 let latLong:any = res.data;
        //                 this.localstorage.setItem('latlong',JSON.stringify(req));
        //                 this.router.navigate(['/account/nearbyme']);
        //             }else{
        //                this.toasterService.error(res.data.msg);
        //             }
        //     })
        // }
        // catch (error) {
        //     this.userService.InsertExceptionLog({ "method_name": "goToLocationNearbyMe()", "page_name": "ProductdetailsComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
        // }
    }
    displayDeliveryTimeline(product?, divID?) {
        try {
            this.etaList = [];
            if (JSON.parse(this.localstorage.getItem("Pincode")) && this.productList[0].part_number && this.divisionInfoData.DIV_ID) {
                let user = JSON.parse(this.localstorage.getItem('userData'));
                let requestBody = {
                    account_pk: user ? JSON.stringify(user.account_pk) : "",
                    "pincode": JSON.parse(this.localstorage.getItem("Pincode")),
                    "part_number": this.productList[0].part_number,
                    "quantity": product ? product.customerQuantity : this.productList[0].customerQuantity ? this.productList[0].customerQuantity : 1,
                    "division_id": divID ? divID : this.divisionInfoData.DIV_ID,
                    "action_type": "get_eta_for_part_detail"
                }


                this.userService.getEtaForOrderSuccessPage(requestBody).
                    subscribe(response => {
                        if (response.success) {
                            this.etaList = response.data;
                            const dateParts = this.etaList[0].ETA.split('-');
                            const year = parseInt(dateParts[2]);
                            const month = parseInt(dateParts[1]) - 1; // month is zero-indexed
                            const day = parseInt(dateParts[0]);
                            this.etaList[0].ETA = new Date(year, month, day);
                        } else {
                            this.etaList = [];
                        }

                    })
            } else {
                this.toasterService.error("Please add a pincode to get Expected Delivery Date.");
            }
            if(this.isUserType?.user_type != 'MECH'){
            this.getSchemeList();
            }
        }
        catch (error) {
            if(this.isUserType?.user_type != 'MECH'){
            this.getSchemeList();
            }
            this.userService.InsertExceptionLog({ "method_name": "goToLocationNearbyMe()", "page_name": "ProductdetailsComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
        }

    }
    //  viewQtyBasedOffer(offer?: any) {
    //      this.vehicleDetails = vehicle;
    //         this.addVehicleModal = this.modalService.show(this.addVehicle, {
    //           class: 'modal-dialog modal-dialog-centered',
    //           backdrop: 'static',
    //         });
    //       }

    displayDeliveryETA(product?, divID?){
        try {
            this.etaList = [];
            if (JSON.parse(this.localstorage.getItem("Pincode")) && this.productList[0].part_number && this.divisionInfoData.DIV_ID) {
                let user = JSON.parse(this.localstorage.getItem('userData'));
                let requestBody = {
                    "account_pk" : user ? user.account_pk : "",
                    "origin_pin": this.divisionInfoData?.zipcode,
                    "destination_pin": this.localstorage.getItem("Pincode"),
                }


                this.userService.getEtaForDelhivery(requestBody).
                    subscribe(response => {
                        if (response.success) {
                            // debugger
                            var json = {
                                'tat' : response.data?.tat
                            }
                            this.etaList.push(json)
                            let currentDate = new Date();
                            currentDate.setDate(currentDate.getDate() + response.data?.tat);
                            this.etaList[0].ETA = new Date(currentDate);
                        } else {
                            this.etaList = [];
                        }

                    })
            } else {
                this.toasterService.error("Please add a pincode to get Expected Delivery Date.");
            }
            if(this.isUserType?.user_type != 'MECH'){
            this.getSchemeList();
            }
        }
        catch (error) {
            if(this.isUserType?.user_type != 'MECH'){
            this.getSchemeList();
            }
            this.userService.InsertExceptionLog({ "method_name": "displayDeliveryETA()", "page_name": "ProductdetailsComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
        }
    }

    getPartLimitCheck(dist_id) {
        let user = JSON.parse(this.localstorage.getItem('userData'));
        var req = {
            "part_number_list": [this.partNumber],
            "account_id": user.account_id,
            "distributor_id": dist_id
        }

        this.productService.get_part_limit_check(req).subscribe(
            res => {
                this.partLimitCheck = res
                if (res.success == false || res.success != undefined) {
                    this.partLimitCheck = []
                }
            })
    } catch(error) {
        this.userService.InsertExceptionLog({
            "method_name": "getPartLimitCheck()",
            "page_name": "ProductDetailsComponent",
            "portal_type": "Customer", "page_url": this.router.url,
            "error_message": error.message, "inner_exception": error
        });
    }
    isSidebarOpen: boolean = false;

    toggleSidebar(value: any, index: any) {
        this.displayToggleName = value;
        this.innerViewoffer = [];
        this.uniqueListContact = []; let flags = {};
        if( this.displayToggleName == 'Quantity Discount' || this.displayToggleName == 'Value Discount' || this.displayToggleName == 'Bulk Purchase'){
            this.mergeDisountArray.forEach((ele) => {
                if (ele) {
                    let words = ele.discount_type.split('_');
                            for (let i = 0; i < words.length; i++) {
                                words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
                            }
                            let capitalizedDiscountType = words.join(' ');
                    if (capitalizedDiscountType == value) {
                    
                            this.innerViewoffer.push(ele);              
            
                    }
                                }
            })
            //
            
            this.uniqueListContact = this.innerViewoffer.filter((entry) => {
                if (flags[entry.discount_name]) {
                    return false;
                }
                flags[entry.discount_name] = true;
                return true;
            });
            // console.log('this.uniqueListContact ==== ',this.uniqueListContact);
            for(let i = 0; i < this.uniqueListContact.length; i++){
                this.uniqueListContact[i].newArr = [];
                for(let j = 0; j < this.innerViewoffer.length; j++){
                    if(this.uniqueListContact[i].discount_name == this.innerViewoffer[j].discount_name){
                        // this.uniqueListContact[i].newArr.serialNumber = srnumber++
                        this.uniqueListContact[i].newArr.push(this.innerViewoffer[j]);
                        
                    }
                }

            }
                
        }else{

            this.mergeDisountArray.forEach((ele) => {
                if (ele) {
                    let words = ele.discount_type.split('_');
                            for (let i = 0; i < words.length; i++) {
                                words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
                            }
                            let capitalizedDiscountType = words.join(' ');
                    if (capitalizedDiscountType == value) {
                            this.innerViewoffer.push(ele)
                    }
                                }
            })
        }
        this.isSidebarOpen = !this.isSidebarOpen;
    }

    closeModal() {
        this.modalService1.hide();
    }
    getmarketbasketlist() {
        let user = JSON.parse(this.localstorage.getItem('userData'));
        let req = {
            "account_pk":parseInt(user.account_pk),
            "offset": 0,
            "limit": 100,
            "part_number_list": [this.partNumber],
            "division_id": [this.baseProductdata ? this.baseProductdata?.division_info?.DIV_ID: ""],
            "organization_id":[this.baseProductdata ? this.baseProductdata?.division_info?.ORG_ID: ""],
        };
        
        //----------for Tetsing ------------//
    //     let req = {
           
    // "account_pk":parseInt(user.account_pk),
    // "part_number_list":["264183106321"],
    // "organization_id" : ["1-1IJGRE5"],
    // "division_id": ["1-4RL59V"]
                
    //     };
        this.productService.getMarketbasket(req).subscribe(
            (response) => {
                if (response.success) {
                    this.offercard = [];
                    response.data.forEach((ele) => {
                        this.offercard.push(ele.discount)
                    })

                } else {
                    if (response?.data?.msg) {
                        this.toasterService.error(response.data.msg);
                    }
                }
            },
            (error) => {
                if (error?.error?.data?.msg) {
                    this.toasterService.error(error.error.data.msg);
                }
            }
        );
    }
    generateFake(count: number): Array<number> {
        const indexes = [];
        for (let i = 0; i < count; i++) {
          indexes.push(i);
        }
        return indexes;
      }
    marketBasketCollapeApi(data: any, i: any) {
        let user = JSON.parse(this.localstorage.getItem('userData'));
        let req = {
            "account_pk": parseInt(user.account_pk),
            "discount_code": data.discount_code,
            // data.discount_code
        };
        this.productService.getMarketbasketDetails(req).subscribe(
            (response) => {
                if(response){
                    this.ismarketbasketapicall = true
                    // response.forEach((ele) => {
                        this.marketDetailsarr = [];
                        // this.marketDetailsarr = response.data;                 
                        this.stireduplicatearra = [];
                        this.newArrayforPartnumner = [];
                        let tempArray:any = [];
                        let srnumber = 1
                        response.data.forEach((ele) => {
                            this.marketBasketDescription = ele?.discount_description ? ele?.discount_description: '';
                            // console.log("check_lengththhtht",this.marketBasketDescription.length,this.marketBasketDescription.length > 250)
                            // console.log("inmarket)basbsbds",this.marketBasketDescription)
                            // for (let i = 0; i < ele.discount_slab_detail.length; i++) {
                            //     ele.discount_slab_detail[i].partQuantity = 1;
                            // }
                            ele.discount_slab_detail.forEach(ele => {
                                ele.slab = srnumber++
                            
                            })
                            this.marketDetailsarr = ele.discount_slab_detail;
                            tempArray = ele.discount_master_detail
                            tempArray.forEach(part => {
                                // console.log("part_minimum_quanity",part.minimum_quantity)
                                // if(part.minimum_quantity == null || part.minimum_quantity == ''){
                                //     part.minimum_quantity = 1;
                                // }else{}
                                part['tempQuantity'] = 1;
                              });
                        });
                        this.newArrayforPartnumner = tempArray.reduce((accumulator, current) => {
                            if (!accumulator.some(obj => obj.sku_item === current.sku_item)) {
                                accumulator.push(current);
                            }
                            return accumulator;
                        }, []);
      
                        // const maxLength = 250;
                        // if (this.description.length > maxLength) {
                        //   this.truncatedDescription = this.description.substr(0, maxLength) + '...';
                        // this.fullDescription = this.description;
                         
                        // } else {
                        //   this.truncatedDescription = this.description;
                        // }
                  
                }else{
                    this.ismarketbasketapicall =false
                }
             
            })
    }
 
    notAllowSpaceatFirst(event: any) {
        if (event.target.selectionStart === 0 && event.code === "Space") {
          event.preventDefault();
        }
    }

    routeToProduct(value){
        if (value.sku_item != 'ALL') {
          this.router.navigate(['/shop/product-details'], {
            queryParams: { part_Number: value.sku_item },
          }).then((res) => {
            this.isSidebarOpen = false;
          })
        }
      }
      applyFilter() {
        if (!this.typevalue) {
          this.errormsg = 'Please Enter ' + this.chosenItem + '';
          return;
        }
        let data = {};
        // this.isApiCalled = false;
        let searchby =
          this.chosenItem == 'Registration Number'
            ? 'registration_num'
            : 'chassis_num';
      
        data[searchby] = this.typevalue;
        this.showLoader = true;
        this.errormsg = '';
        this.productService.getProductListByRegOrChassionov2(data).subscribe(
          (response) => {
            if (response.success) {
              // this.showLoader = false;
              // this.isApiCalled = true;
              this.searchedVehicles = response.data;
              this.checkCompatibility()
            } else {
              this.errormsg = response.data.msg;
              this.compatibilityMessage = '';
              this.checkCompatible = null;
              this.isApiCalled = false;
              this.searchedVehicles = [];
              this.showLoader = false;
            }
          },
          (error) => {
            this.showLoader = false;
          }
        );
      }
      
      checkCompatibility() {
        var json = {
          "chassis_number":  this.chosenItem == 'Chassis Number'? this.typevalue : '',
          "registration_number": this.chosenItem == 'Registration Number'? this.typevalue : '',
          "part_number": this.partNumber,
          "check_compatibility": true
      }   
      this.showLoader = true;
        this.productService.get_applicable_model(json).subscribe(
          (response) => {
            this.isApiCalled = true;
            this.showLoader = false;
            if (response.success) {
              this.checkCompatible = true;
              this.compatibilityMessage = response.data.msg;
            } else {
              this.checkCompatible = false;
              this.compatibilityMessage = response.data.msg;
            }
            this.showLoader = false;
          },
          (error) => {
            this.isApiCalled = false;
            this.showLoader = false;
          }
        );
      }
      radioChange(event: MatRadioChange, item) {
        this.isApiCalled = false;
        this.compatibilityMessage = '';
        this.checkCompatible = null;
        this.typevalue= ""
        this.errormsg = ""
        if (item.name == 'Chassis Number') {
          this.chosenItem = item.name;
          this.searchedVehicles = [];
      
        } else {
          this.chosenItem = item.name;
          this.searchedVehicles = [];
        }
      }

    onRedirectLoyalty(){
        this.router.navigate(['/account/profile-loyality'])
    }

      convertToInt(value:any){
        return parseInt(value)
      }
      
      backToDetails(){
        this.showProduct = true;
        this.showProductEngine = false;
        this.isProductDetailEngineComponent = false;
        this.isProductDetailComponent = true;
        this.typevalue = ''
        this.errormsg = ''
        this.compatibilityMessage = ''
        this.checkCompatible = null;
        // setTimeout(() => {
        //     this.productslide();
        // }, 200);
        this.ngOnInit();
    }

    copyMessage() {
        this.toasterService.success("Link copied.");
        navigator.clipboard.writeText(this.pageURL)
      }
      
      share(platform: string) {
        let shareUrl = '';
        switch (platform) {
          case 'Facebook':
            shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.pageURL)}`;
            break;
          case 'Twitter':
            shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(this.pageURL)}`;
            break;
        //   case 'linkedin':
        //     shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(this.url)}`;
        //     break;
        //   case 'pinterest':
        //     shareUrl = `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(this.url)}&media=${encodeURIComponent(this.imageUrl)}&description=${encodeURIComponent(this.text)}`;
        //     break;
          case 'whatsapp':
            shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(this.pageURL)}`;
            break;
         case 'mail':
            shareUrl = `mailto:?body=${encodeURIComponent(this.pageURL)}`;
                break;
          // Add more platforms as needed
          case 'mail':
            shareUrl = `mailto:?body=${encodeURIComponent(this.pageURL)}`;
                break;
        }
    
        window.open(shareUrl, '_blank');
      }


      partSearchMD2(payLoad){
        this.productService.get_part_search_md_2(payLoad).subscribe(response => {
            if (response.success === true) {
            }
            else {
                this.toasterService.error(response.data.msg);
                this.showLoader = false;
            }
        }, (err) => {

        })
      }

      imageTempArray:any=[]
      selectImg(path:any,index:any,type:any){
        if(type == 'thumbnil'){
            this.imageTempArray = [];
            var imgPath = path + '/resize/530x299'
            setTimeout(() => {
                this.imageTempArray.push(imgPath)
            },0);
        }
        else{
            this.modalImage = path.replace('530x299', '800x452');
            this.fullimageModal = this.modalService1.show(this.fullImagePopup,
                { class: "modal-dialog-centered modal-lg", });   
        }
    
      }
}


export class compatible {
    model_image: any;
    model_cat: any;
    model_desc: any;
    base_model: any;
    variantList: any;
}
export class ModelVariant {
    vc_no: any;
    engine_type: any;
    capacity: any;
    fuel_type: any;
    vc_base_model: any;
}
