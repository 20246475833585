<div class="container-fluid">
  <h5 class="title-header">Credit History</h5>
  <!-- <div class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3  mb-3">
    <div class="card our-moto">
      <div class="card-body">
        <ul>
          <li><img alt="" src="./assets/images/discount.webp" /></li>
          <li>
            <h3 class="title">Credit Available : <span matPrefix>&#8377; &nbsp;</span>3,55,000.00</h3>
            <hr class="header-hr">
            <div class="description-card-header">
              Credit Used : <span matPrefix>&#8377; &nbsp;</span>1,45,000.00 (29%)
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div> -->
  <div class="tab-content mt-3" id="nav-tabContent">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-left: 15px; padding-right: 15px;">
        <!-- <h4 class="title-subHeader">Credit History</h4> -->
        <!-- <div class="row">
                <div class="col-xs-12 col-md-6">
                    <div class="col-xs-12 col-md-4">
                        <div class="push">
                            <div class="search">
                                <img class="search-icon" id="credit-search-icon" alt="" src="./assets/images/search.svg" />
                                <input class="form-control" id="credit-formControl" [formControl]="filterForm.controls['invoiceno']" type="search" placeholder="Type a keyword to search"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-md-6">
                    <div class="col-xs-12 col-md-4">
                        <div class="form-group">
                            <select class="form-select" aria-label="">
                                <option value="" selected disabled>State</option>
                                <option></option>
                            </select>
                        </div>
                    </div>
                </div>
            </div> -->
        <div class="table-responsive table-container">
          <table class="table datatable display" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
            <thead>
              <tr>
                <!-- <th>Sr. No.</th> -->
                <th>Account ID</th>
                <th>Customer Name</th>
                <th>Distributor Code</th>
                <th>Distributor Name</th>
                <th>Credit Type</th>
                <th>Credit Limit</th>
                <th class="text-nowrap">Credit Period</th>
                <th>Credit Balance</th>
                <th>Action</th>
                <th>Created At</th>
                <th>Last Updated</th>
                <!-- <th>Order Number</th>
                <th>Invoice Value(<span matPrefix>&#8377;&nbsp;</span>)</th>
                <th>Invoice Date</th>
                <th>Invoice Age(Days)</th>
                <th>Payment Status</th>
                <th>Amount Due(<span matPrefix>&#8377;&nbsp;</span>)</th>
                <th>Cleared On</th>
                <th>Balance</th>
                <th>Action</th> -->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let credit of creditList; let i=index;">
                <!-- <td class="text-nowrap">{{i+1}}</td> -->
                <td class="text-nowrap">{{credit.account_id}}</td>
                <td class="text-nowrap">{{credit.account_name}}</td>
                <td class="text-nowrap">{{credit.organization_id}}</td>
                <td class="text-nowrap">{{credit.organization_name}}</td>
                <td class="text-nowrap">{{credit.credit_type}}</td>
                <td class="text-nowrap">{{credit.credit_limit | currencyFormat:'INR':true:'1.0-0'}}</td>
                <td class="text-nowrap">{{credit.days + ' Days'}}</td>
                <td class="text-nowrap">
                  <a class="me-2" (click)="getCreditDetails(credit.organization_id)">View Balance</a>
                </td>
                <td class="text-nowrap">
                  <a class="me-2" [routerLink]="['/orders/invoice-payment']">Pay Now</a>
                </td>
                <td class="text-nowrap">{{credit.created_at | date: 'dd-MM-yyyy h:mm:ss a'}}</td>
                <td class="text-nowrap">{{credit.updated_at | date: 'dd-MM-yyyy h:mm:ss a'}}</td>
                <!-- <td class="text-nowrap">{{(credit.order_number !== undefined && credit.order_number !== null && credit.order_number !== '') ? credit.order_number : '-'}}</td>
                <td class="text-nowrap">{{(credit.invoice_value !== undefined && credit.invoice_value !== null && credit.invoice_value !== '') ? credit.invoice_value : '-'}}</td>
                <td class="text-nowrap">{{(credit.invoice_date !== undefined && credit.invoice_date !== null && credit.invoice_date !== '') ? credit.invoice_date : '-'}}</td>
                <td class="text-nowrap">{{(credit.days !== undefined && credit.days !== null && credit.days !== '') ? credit.days : '-'}}</td>
                <td class="text-nowrap">{{(credit.payment_status !== undefined && credit.payment_status !== null && credit.payment_status !== '') ? credit.payment_status : '-'}}</td>
                <td class="text-nowrap">{{(credit.amount_due !== undefined && credit.amount_due !== null && credit.amount_due !== '') ? credit.amount_due : '-'}}</td>
                <td class="text-nowrap">{{(credit.cleared_on !== undefined && credit.cleared_on !== null && credit.cleared_on !== '') ? credit.cleared_on : '-'}}</td>
                <td class="text-nowrap">{{(credit.credit_limit !== undefined && credit.credit_limit !== null && credit.credit_limit !== '') ? credit.credit_limit : '-'}}</td>
                <td class="text-nowrap">
                  <a class="me-2 text-nowrap">
                    Pay Now
                  </a>
                </td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #creditDetails let-d="dismiss">
  <credit-details [creditDetails]="selectedCreditDetails" (closePopUp)="creditDetailsModal.hide();"></credit-details>
</ng-template>
