import { Component, OnInit, TemplateRef, HostListener, ViewChild } from '@angular/core';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { ProductService } from 'src/app/shared/services/REST/product.service';
import { UserService } from 'src/app/shared/services/REST/user.service';
import { secureStorage } from 'src/app/shared/services/securestorage';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-my-coupons',
  templateUrl: './my-coupons.component.html',
  styleUrls: ['./my-coupons.component.scss']
})
export class MyCouponsComponent implements OnInit {
  isNoDataFound:boolean = false;
  layout:string = "";
  ProductListData:any[];
  pinCode:number;
  offset: number = 0;
  limit: number = 20;
  loading: boolean = false;
  couponDetail:any;
  @ViewChild('openCouponDetailsModal', {read: TemplateRef, static: false })
  openCouponDetailsModal: TemplateRef<any>;
  openCouponDetailsModalDetails:any;
  constructor( 
    private productService: ProductService, 
    private localstorage: secureStorage,
    private router: Router,
    public userService: UserService,
    private modalService: BsModalService,
    private appStateService: AppStateService,
    private title:Title ) { }

  ngOnInit(): void {
    this.ProductListData = []
    this.isNoDataFound = false;
    this.getProduct(this.offset, this.limit);
    this.appStateService.refreshOfferBanner.subscribe((val) => {
      if (val == true){
        this.ProductListData = []
        this.isNoDataFound = false;
        this.getProduct(0,20);
      }
    });
    this.title.setTitle('E-Dukaan | Coupons');
  }

  getProduct(offset: any, limit: any) {
    try {
      let data;
      let user = JSON.parse(this.localstorage.getItem('userData'));
      let address = JSON.parse(this.localstorage.getItem('address'));
      this.pinCode = JSON.parse(this.localstorage.getItem('Pincode'));
      data = {
        "account_pk": "",
        "action_type": "coupon_banner_list",
        "division_id": "",
        "organization_id": "",
        "from_date": "",
        "to_date": "",
        "offset": offset,
        "limit": limit,
        "pincode":this.pinCode ?this.pinCode.toString():""
      }
      if(user && address){
        data.account_pk = +JSON.stringify(user.account_pk) 
      }
      this.productService.getOfferDetailList(data).subscribe(
        (res) => {
          if (res.success) {
            let date = new Date()
            let newdate = formatDate(date, 'dd MMM yy', 'en-GB')
            this.ProductListData = res.data;
            for (let i = 0; i < this.ProductListData.length; i++) {
              this.ProductListData[i].isShowOnlyToDate = false;
              this.ProductListData[i].from_dt = formatDate(this.ProductListData[i].from_dt, 'dd MMM yy', 'en-GB')
              this.ProductListData[i].to_dt = formatDate(this.ProductListData[i].to_dt, 'dd MMM yy', 'en-GB')
              if (this.ProductListData[i].from_dt == this.ProductListData[i].to_dt || this.ProductListData[i].from_dt == newdate)
                this.ProductListData[i].isShowOnlyToDate = true
            }
            if (this.ProductListData.length == 0) {
              this.isNoDataFound = true
            }
          }
          if (!res.success)
            this.isNoDataFound = true
        },
        (err) => {
          this.isNoDataFound = true
         }
      )
    }
    catch (error) {
      this.isNoDataFound = true
      this.userService.InsertExceptionLog({
        method_name: 'getProducts(page)',
        page_name: 'PageCategoryComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }
  
  openCouponDetail(row){
    this.couponDetail = row;
    this.openCouponDetailsModalDetails = this.modalService.show(this.openCouponDetailsModal, {
        class: 'modal-dialog modal-dialog-centered modal-lg',
        backdrop: 'static',
    });
  }

  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }
  
  @HostListener('window:scroll', [])
  async onScroll() {
    if ( !this.ProductListData || this.ProductListData?.length === 0 || this.isNoDataFound || this.loading) {
      return;
    }
    else {
      if ( window.innerHeight + window.scrollY + 950 >= document.body.offsetHeight ) {
        this.loading = true;
        await this.loadingMoreData();
      } else if (
        window.innerHeight + window.scrollY <
        document.body.offsetHeight
      ) {
      }
    }

  }
  async loadingMoreData() {
    try{
      let date = new Date()
      let newdate = formatDate(date, 'dd MMM yy', 'en-GB')
      this.offset = this.offset + this.limit ;
      let user = JSON.parse(this.localstorage.getItem('userData'));
      this.pinCode = JSON.parse(this.localstorage.getItem('Pincode'));
      if( this.isNoDataFound && this.loading){
        return
      }else{
        let data ={
          "account_pk":"",
          "action_type": "coupon_banner_list",
          "division_id": "",
          "organization_id": "",
          "from_date": "",
          "to_date": "",
          "offset": this.offset,
          "limit": this.limit,
          "pincode":this.pinCode ?this.pinCode.toString():""
        }
        if(user){
          data.account_pk = JSON.stringify(user.account_pk);
        }
        await this.productService.getOfferDetailList(data).subscribe(
          res => {
            this.loading = false;
            if(res.success){
              res.data.forEach(element => {
                this.ProductListData.push(element);
              });
              for (let i = 0; i < this.ProductListData.length; i++) {
                this.ProductListData[i].isShowOnlyToDate = false;
                this.ProductListData[i].from_date = formatDate(this.ProductListData[i].from_date, 'dd MMM yy', 'en-GB')
                this.ProductListData[i].to_date = formatDate(this.ProductListData[i].to_date, 'dd MMM yy', 'en-GB')
                if (this.ProductListData[i].from_date == this.ProductListData[i].to_date || this.ProductListData[i].from_date == newdate)
                  this.ProductListData[i].isShowOnlyToDate = true
              }
            }else{
              this.isNoDataFound = true;
              this.loading = false;
            }
          }, error => { this.isNoDataFound = true; this.loading = false; }
        )  
      }
    }catch(error){
      this.isNoDataFound = true;
      this.loading = false;
    }
  }
}
