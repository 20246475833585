<div id="add_viewDocument" class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title mb-0" id="add_vehicleModalLabel">RC BOOK</h5>
    <button type="button" class="btn-close" (click)="closeModal()"></button>
  </div>
  <div class="modal-body pe-md-5">
    <div >
      <div class="form-group">
        <div class="mat-prod-img me-3" style="
    width: 100%;
    text-align: center;">
<a target="_blank"  [href] = "rcbookdetail?.rc_book_doc" download = "file"> Open Document</a>
  <!-- <img src="{{(rcbookdetail?.rc_book_doc) ? rcbookdetail?.rc_book_doc : ''}}" class="img-fluid" alt=""> -->
            </div>
      </div>
    </div>
  </div>
  <div class="modal-footer border-0">
    </div>
</div>

<ng-container *ngIf="showLoader">
  <div class="loader">
    <div class="ng-spinner ng-spinner-icon"></div>
  </div>
</ng-container>
