<div class="container-fluid report">
  <div class="row">
    <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 pb-3 pt-3 mobile-m-top">
      <ul class="nav nav-tabs tabs1" id="myTab" role="tablist">
        <li class="nav-item" role="presentation" [routerLink]="['/account/report']">
          <a class="nav-link" id="order-tab" data-bs-toggle="tab" href="#order" role="tab" aria-controls="order"
            aria-selected="true">Order Tracking</a>
        </li>
        <li class="nav-item" role="presentation" [routerLink]="['/account/etareport']">
          <a class="nav-link active" id="order-tab" data-bs-toggle="tab" href="#order" role="tab" aria-controls="order"
            aria-selected="true">ETA Reports</a>
        </li>
        <li class="nav-item" role="presentation" [routerLink]="['/account/servicerequestreport']" *ngIf="userDetails?.user_type == 'FO'">
          <a class="nav-link" id="order-tab" data-bs-toggle="tab" href="#order" role="tab"
              aria-controls="order" aria-selected="true">Service Request</a>
      </li>
        <li class="nav-item ms-auto d-flex" style="align-items: center;cursor: pointer;" role="presentation">
          <!-- Export
          <select id="selectexceloptionreport" class="export-format" style="cursor: pointer;"
            (change)="downloadExcel($event);">
            <option style="cursor: pointer;" value="Select">Select</option>
            <option style="cursor: pointer;" value="Excel">Excel</option>
          </select> -->
          <button class="mat-icon-button mat-accent" matTooltip="Export to Excel" (click)="downloadExcel();">
            <span class="mat-button-wrapper">
                <i class="fa fa-file-excel fa-lg"></i>
            </span>
          </button>
        </li>
      </ul>
      <hr class="hr-page-divider" />
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="order" role="tabpanel" aria-labelledby="order-tab">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3 filter-are">
              <h3 class="section-title">Filters</h3>
              <div class="ps-1 pe-1 pt-2 pb-2">
                <button type="button" class="btn btn-outline-primary"
                  (click)="applyETAReportsFilter('Global')">Search</button>&nbsp;
                <button type="button" class="btn btn-outline-primary" (click)="reset()">Reset</button>
              </div>
              <div class="ps-1 pe-1 pt-2 pb-2">
                <div class="form-group search position-relative mb-2">
                  <input type="text" [formControl]="EtareportForm.controls['otc_number']"
                    (keyup.enter)="applyETAReportsFilter(EtareportForm.controls['otc_number'])" class="form-control"
                    placeholder="Search by OTC No." />
                  <span class="searchicon"><img alt="" src="./assets/images/search.svg" /></span>
                </div>
                <div class="form-group search position-relative mb-2">
                  <input type="text" [formControl]="EtareportForm.controls['order_number']"
                    (keyup.enter)="applyETAReportsFilter(EtareportForm.controls['order_number'])" class="form-control"
                    placeholder="Search by Order No." />
                  <span class="searchicon"><img alt="" src="./assets/images/search.svg" /></span>
                </div>
                <div class="form-group search position-relative mb-2">
                  <input type="text" [formControl]="EtareportForm.controls['part_number']"
                    (keyup.enter)="applyETAReportsFilter(EtareportForm.controls['part_number'])" class="form-control"
                    placeholder="Search by Part No." />
                  <span class="searchicon"><img alt="" src="./assets/images/search.svg" /></span>
                </div>
              </div>
              <div class="sub-title ps-1 pe-1">
                DURATION
              </div>
              <div class="ps-1 pe-1 pt-2 pb-2">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    <div class="row mt-2">
                      <div class="col-6 position-relative">
                        <mat-form-field style="display: inline">
                          <input matInput [max]="today" placeholder="From Date" (click)="picker1?.open()" readonly
                            [formControl]="EtareportForm.controls['start']" [matDatepicker]="picker1">
                          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                          <mat-datepicker #picker1 disabled="false"></mat-datepicker>
                        </mat-form-field>
                      </div>
                      <div class="col-6 position-relative">
                        <mat-form-field style="display: inline">
                          <input matInput [max]="today" placeholder="To Date" (click)="dp3?.open()" readonly
                            [formControl]="EtareportForm.controls['end']" [matDatepicker]="dp3">
                          <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                          <mat-datepicker #dp3 disabled="false"></mat-datepicker>
                        </mat-form-field>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="sub-title ps-1 pe-1">
                Distributor Name
                <div class="pt-2 pb-2">
                  <mat-form-field class="example-full-width" style="display: inline">
                    <input type="text" placeholder="" aria-label="Number" matInput name="distributor_name"
                      [formControl]="EtareportForm.controls['distributor_name']" [matAutocomplete]="auto"
                      (keypress)="_filterDistributor($event.target.value)" title=" {{txtdistributor_name}}">
                    <mat-autocomplete #auto="matAutocomplete">
                      <mat-option stymat-autocompletele="width: 100%;"
                        (onSelectionChange)="filterMyOptionsDistributor(option.distributor_id)"
                        *ngFor="let option of filteredOptions " [value]="option.distributor_name"
                        [title]="option.distributor_name">
                        {{option.distributor_name}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9 ">
              <div class="card table-wrapper">
                <div class="card-body p-0">
                  <div class="table-responsive table-container">
                    <table  class="table datatable display"  datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                      <thead>
                        <tr>
                          <th class="sticky-col first-col" scope="col" style="text-align: center;">OTC No.</th>
                          <th scope="col" class="text-nowrap" style="text-align: center;">Order No.</th>
                          <th scope="col" class="text-nowrap">Distributor Name</th>
                          <th scope="col" class="text-nowrap">Part No.</th>
                          <th scope="col" class="text-nowrap">Part Desc</th>
                          <th scope="col" style="text-align: center;">Quantity</th>
                          <th class="text-nowrap" scope="col">Order Date</th>
                          <th class="text-nowrap" scope="col">Expected Delivery Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let order of etaData">
                            <td class="tdmanage sticky-col first-col" (click)="details(order,'isEtaReport');" style="width: 173.609px !important">
                              <a *ngIf="order?.otc_order_number; else elseOtcInEtaReport " class="me-2">{{ order?.otc_order_number }}</a>
                              <ng-template #elseOtcInEtaReport>
                                <a class="me-2">NA</a>
                              </ng-template>
                            </td>
                            <td class="tdmanage">{{ order?.order_number }}</td>
                            <td class="tdmanage" style="text-align: center;">{{ order?.distributor_name }}</td>
                            <td class="tdmanage" style="text-align: center;">{{ order?.part_number }}</td>
                            <td class="tdmanage">{{ order?.part_desc }}</td>
                            <td style="text-align: center;">{{ order?.total_quantity | number }}</td>
                            <td class="tdmanage">{{ order?.order_date | date: 'dd-MM-yyyy h:mm:ss a'}}</td>
                            <td class="tdmanage" style="text-align: center;">{{ order?.delivery_date | date: 'dd-MM-yyyy'}}</td>
                            
                        </tr>
                    </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #orderDetails let-d="dismiss">
  <order-details [order]="orderInformations" [isReport]="isReport" [isActivePage]="isActivePage" (closemodal)="orderDetailsModal.hide();" [isReturn]="isReturn"
    [isOTCSelected]="isOTCSelected" [isFeedBackSelected]="isFeedBackSelected"></order-details>
</ng-template>
<div class="loader" *ngIf="showLoader">
  <div class="ng-spinner ng-spinner-icon"></div>
</div>