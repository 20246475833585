import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// modules
import { SharedModule } from '../../shared/shared.module';
import { ShopRoutingModule } from './shop-routing.module';
import { WidgetsModule } from '../widgets/widgets.module';
// components
// pages
import { MyHttpInterceptor } from '../../shared/interceptor/http.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { GoogleAnalyticsService } from 'src/app/shared/services/GoogleAnalytics/GoogleAnalytics.service';
import { PageCategoryComponent } from './pages/page-category/page-category.component';
import { ProductListComponent } from 'src/app/shared/components/product-list/product-list.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import {MatTabsModule} from '@angular/material/tabs';
import {MatCardModule} from '@angular/material/card';
import {MatTableModule} from '@angular/material/table';
import {MatIconModule} from '@angular/material/icon';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {MatFormFieldModule} from '@angular/material/form-field';
import { ProductcardComponent } from './pages/productcard/productcard.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxSkeletonSmartModule } from 'ngx-smart-skeleton';
import { MatTooltipModule } from "@angular/material/tooltip";
@NgModule({
    declarations: [
        // components

        // pages
        //        ProductListComponent
        ProductListComponent,
        PageCategoryComponent, ProductcardComponent
    ],
    imports: [
        // modules (angular)
        CommonModule,
        NgxSkeletonSmartModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        ShopRoutingModule,
        WidgetsModule,
        RouterModule,
        MatTabsModule,
        MatTableModule,
        MatIconModule,
        MatExpansionModule,
        MatCheckboxModule,
        MatRadioModule,
        MatCardModule,
        MatFormFieldModule,
        NgSelectModule,
        MatTooltipModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MyHttpInterceptor,
            multi: true,
        }, GoogleAnalyticsService
    ],
    exports: [
        MatTabsModule,
        MatTableModule,
        MatIconModule,
        MatExpansionModule,
        MatCheckboxModule,
        MatRadioModule,
        MatCardModule,
        MatFormFieldModule,
    ]
})
export class ShopModule {

}
