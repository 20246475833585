import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-coupon-details',
  templateUrl: './coupon-details.component.html',
  styleUrls: ['./coupon-details.component.scss']
})
export class CouponDetailsComponent implements OnInit {
  @Output() closeCouponDetails = new EventEmitter<any>()
  @Input() couponDetails:any;
  constructor() { }

  ngOnInit(): void {
    this.couponDetails
  }

}
