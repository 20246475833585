import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-view-document',
  templateUrl: './view-document.component.html',
  styleUrls: ['./view-document.component.scss'],
})
export class ViewDocumentComponent implements OnInit {
  @Input() rcbookdetail: any;
  @Output() closeRCPopUp = new EventEmitter<any>();

  showLoader: boolean = false;
  constructor() {
  }

  ngOnInit(): void {
    
  }
 
  closeModal() {
    // this.vehicleDetails = '';
    this.closeRCPopUp.emit();
  }
}
