import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { secureStorage } from '../../services/securestorage';
import { AppStateService } from '../../services/app-state/app-state.service';
import { ProductService } from '../../services/REST/product.service';
import { UserService } from '../../services/REST/user.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
@Component({
  selector: 'app-selectCartType',
  templateUrl: './select-carttype.component.html',
  styleUrls: ['./select-carttype.component.scss'],
})
export class SelectCartTypeComponent implements OnInit {
  @Input() baseProductdata: any;
  @Input() btnshowConfrimed;
  @Input() btnshowsubmit;
  @Input() isBulkUpload: boolean = false;
  @Input() btnshowConfrimedCartType:boolean = false;
  @Input() currentlyAddingProduct: any;
  @Output() selectedCartType = new EventEmitter<any>();
  @Output() inputChange = new EventEmitter<any>();
  @Input() cartTypeList: any;
  @Output() closeModal = new EventEmitter<any>();
  @Output() setCartType = new EventEmitter<any>();
  @Input() productDetail:any;
  @Input() fromPath:any;
  confirmedCartType: any = 'mhv_cart';
  // isButtonClicked: boolean = false;
  confirmedCartTypesbulk: any = '';
  isButtonClicked: boolean = false;
  newRadiobtns:any = [];
  confirmNonpg:boolean = false;
  valuenpnPg:any = '';
  selectedNonPG: number;
  section1SelectedTMGO: boolean = false;
  section1SelectedCart: string = '';
  secondSectionRadiobtn:any;
  selectedBulktype:any;
  isLoggedIn: boolean = false;
  disAddress:any;
  showLoader = false;
  constructor(
    private localstorage: secureStorage, private appStateService: AppStateService,private router: Router,
    private productService:ProductService,private userService:UserService,private toasterService: ToastrService,) {}

  ngOnInit() {
    this.newRadiobtns = [...this.cartTypeList];
    this.disAddress = this.baseProductdata.division_info;
  }

  // changedCartType(event) {
  //   this.confirmedCartType = event['cart_type'];
  //   this.inputChange.emit(event);
  // }
  changedCartType(event) {
    this.confirmedCartType = event['cart_type'];
    this.confirmedCartTypesbulk = event['cart_type'];
    this.inputChange.emit(event);
    this.section1SelectedCart = event['display_name'];
    if (event['display_name'] === 'TMGO Cart') {
      this.section1SelectedTMGO = true;
      this.secondSectionRadiobtn = ''

    } else {
      this.section1SelectedTMGO = false;
      if (event['display_name'] === 'SLV Cart') {
        this.selectSection2Cart('slv_cart');
      } else if ( event['display_name'] === 'MHV Cart') {
        this.selectSection2Cart('mhv_cart');
      }
    }
    this.loadData()
  }
  selectSection2Cart(cart: string) {
    // this.ngZone.run(() => {
      this.secondSectionRadiobtn = cart;
  
    // });
    
    // const section2CartInput = document.querySelector(`input[name="section2"][value="${cart}"]`) as HTMLInputElement;
    // if (section2CartInput) {
    //   section2CartInput.checked = true;
    // }
  }


  confirmPart() {
    if(!this.isButtonClicked){
      this.isButtonClicked = true;
      if (!this.isBulkUpload) {
        localStorage.removeItem('selectcarttype');
        this.localstorage.setItem('selectcarttype', this.confirmedCartType);
        this.isButtonClicked = false;
      }
      if (this.isBulkUpload) {
        let tempobj ={
          'Pg':this.secondSectionRadiobtn  ,
          'nonPg':this.confirmedCartType
        }
        this.selectedCartType.emit(tempobj);
        this.isButtonClicked = false;
      } else {
        this.selectedCartType.emit();
        this.isButtonClicked = false;
      }
    }
    else{
      return;
    }
  }

  modalClose() {
    this.closeModal.emit();
  }

  setAddCart() {
    if(!this.isButtonClicked){
      this.isButtonClicked = true;
      this.localstorage.setItem('selectcarttype', this.confirmedCartType);
      this.setCartType.emit(this.currentlyAddingProduct);
      this.isButtonClicked = false;
    }
    else{
      return;
    }
  }

  confirmCartType(){
    this.setCartType.emit(this.confirmedCartType)
  }
  onSection2Change(value:any){
    this.secondSectionRadiobtn = value['cart_type']
  }

  loadData() {
    try {
        let user = JSON.parse(this.localstorage.getItem('userData'));
        let address = JSON.parse(this.localstorage.getItem('address'));
        let changePincode = this.localstorage.getItem( 'Pincode');

        var searchData = {
          search_text: "",
          bs_model_name: "",
          pg_line: "",
          multi_pg_line: [],
          multi_bs_model_name: [
          ],
          multi_dispgroup_name: [],
          model_cat: "",
          mod_uid: "",
          category_type: "",
          bom_id: "",
          durafit_parts: false,
          kit_parts: false,
          part_number_last_four: "",
          offset: 0,
          // start_row:0,
          limit: 20,
          pl_id: "",
          is_pincode_based_division:changePincode?true:false,
          pincode:changePincode?changePincode:"",
          part_number: this.baseProductdata?.data[0].part_number,
          is_for_epc_data: false,
          is_for_discount: changePincode?true:false,
          is_for_ecom_detail: true,
          cart_type: this.confirmedCartType
      }
        this.productService.get_part_search_md(searchData).subscribe(response => {
            if (response.success === true) {
                 let division_info = {
                    ORG_NAME:response.data[0].organization_name?response.data[0].organization_name:"",
                    DIV_NAME:response.data[0].division_name?response.data[0].division_name:"",
                    PRIMISE_ADDRLINE1_s:response.data[0].primise_addrline1?response.data[0].primise_addrline1:"",
                    PRIMISE_ADDRESS2_s:response.data[0].primise_addrline2?response.data[0].primise_addrline2:"",
                    DIV_ID:response.data[0].division_id?response.data[0].division_id:"",
                    GSTIN:response.data[0].gstn?response.data[0].gstn:"",
                    ORG_Id:response.data[0].organization_id?response.data[0].organization_id:"",
                };
                this.disAddress = division_info
            }
            else {
                this.toasterService.error(response.data.msg);
                this.showLoader = false;
            }
        }, (err) => {
            this.showLoader = false;

        })
    }
    catch (error) {
        this.userService.InsertExceptionLog({ "method_name": "loadData(partNumber)", "page_name": "SelectCartTypeComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
    }
}
}
