import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/shared/services/REST/user.service';
declare var $: any;
import { blockPermissionMsg } from 'src/app/constant';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { secureStorage } from 'src/app/shared/services/securestorage';
@Component({
  selector: 'user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
})
export class UserManagementComponent implements OnInit, AfterViewInit, OnDestroy {
  public dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  newRequestBody: any;
  datatsetempty = false;
  dtOptions: DataTables.Settings = {};
  blockPermissionMsg = blockPermissionMsg;
  totalrecord: any;
  modalRef: any;
  currentPage = 1;
  userList: any[];
  userRegistrationForm: FormGroup;
  UserDetails: any;
  isUserDelete: boolean = false;
  addUserModal: any;
  showLoader: boolean = false;
  @ViewChild('deleteModal', { read: TemplateRef, static: false })
  template: TemplateRef<any>;
  @ViewChild('addUserPopUp', { read: TemplateRef, static: false })
  addUserTemplate: TemplateRef<any>;
  pkuserid: any;

  constructor(
    private toastrService: ToastrService,
    private userService: UserService,
    private router: Router,
    private modalService: BsModalService,
    private localstorage: secureStorage
  ) {}

  ngOnInit() {
    this.loadRegistrationList();
  }

  openmodel() {
    $(document).ready(function () {
      $('[data-toggle="popover"]').popover();
      $('#user-management').DataTable({
        dom: 't<"bottom"ipl><"clear">',
        aoColumnDefs: [
          {
            bSortable: false,
            aTargets: [-1] /* 1st one, start by the right */,
          },
        ],
      });
    });
  }

  alphanumbericOnly(event): boolean {
    var inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z0-9-]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  loadRegistrationList() {
    try {
      this.calldatatable();
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'loadRegistrationList(page)',
        page_name: 'PageUserRegistrationListComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  calldatatable() {
    let user = JSON.parse(this.localstorage.getItem('userData'));
    if (user) {
      if (user && user.account_pk && user.account_pk) {
        this.newRequestBody = {
          account_pk: '' + user.account_pk,
          // offset: page,
          size: 10,
        };
        this.dtOptions = {
          pagingType: 'full_numbers',
          responsive: true,
          serverSide: true,
          processing: true,
          lengthChange: false,
          searching: false,
          pageLength: 10,
          ordering: false,
          language: {
            info: 'Showing _START_ to _END_ of _TOTAL_ entries',
            infoEmpty: 'No records available',

            // infoFiltered: ' ',
          },
          ajax: (dataTablesParameters: any, callback) => {
            this.newRequestBody['offset'] = dataTablesParameters.start;
            this.userService.GetOtherUserList(this.newRequestBody).subscribe(
              (response) => {
                if (response.success) {
                  this.userList = response.data.result;
                  this.totalrecord = response.data.total_results;
                  // if (type !== 'Deleted') {
                  //   this.openmodel();
                  // }
                } else {
                  this.userList = [];

                  this.totalrecord = 0;
                  if (response.data.msg == 'Data not found') {
                    this.datatsetempty = true;
                  }
                  else{
                    this.toastrService.error(response.data.msg);
                  }
                  // this.dtTrigger.next();
                }
                callback({
                  recordsTotal: this.totalrecord,
                  recordsFiltered: this.totalrecord,
                  data: [],
                });
              },
              (err) => {
                this.showLoader = false;
              }
            );
          },
        };
      }
    }
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  deleteRequest(user) {
    let permission = this.userService.CheckPermission('btn_delete_account');

    if (permission) {
      this.UserDetails = user;
      this.isUserDelete = true;
      this.modalRef = this.modalService.show(this.template, {
        class: 'modal-dialog-centered modal-md',
        backdrop: 'static',
      });
    } else {
      this.toastrService.error(blockPermissionMsg);
    }
  }

  Delete() {
    try {
      let permission = this.userService.CheckPermission('btn_delete_account');

      if (permission) {
        let user = JSON.parse(this.localstorage.getItem('userData'));
        if (user) {
          if (user && user.account_pk && user.account_pk) {
            this.showLoader = true;
            this.userService
              .RemoveOtherUser({
                account_pk: user.account_pk,
                other_user_pk: parseInt(this.UserDetails.id),
              })
              .subscribe(
                (response) => {
                  if (response.success) {
                    this.UserDetails = [];
                    this.loadRegistrationList();
                    this.rerender();
                    this.toastrService.success('User Deleted Successfully');
                  } else {
                    this.toastrService.error(response.data.msg);
                    this.UserDetails = [];
                  }
                  this.showLoader = false;
                  this.modalRef.hide();
                },
                (error) => {
                  this.showLoader = false;
                }
              );
          }
        }
      } else {
        this.toastrService.error(blockPermissionMsg);
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'Delete()',
        page_name: 'PageUserRegistrationListComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  addUser(user?) {
    let permission = this.userService.CheckPermission(
      'btn_add_user_management'
    );

    if (permission) {
      this.pkuserid = user ? user.id : undefined;
      this.addUserModal = this.modalService.show(this.addUserTemplate, {
        class: 'modal-dialog modal-lg modal-dialog-centered',
        backdrop: 'static',
      });
    } else {
      this.toastrService.error(blockPermissionMsg);
    }
  }

  closeModal(event) {
    if (event == 'User Registered Successfully.') {
      this.loadRegistrationList();
      this.rerender();
      this.addUserModal.hide();
    } else if (event == 'User Data Updated Successfully.') {
      this.addUserModal.hide();

      this.loadRegistrationList();
      this.rerender();
    } else {
      this.addUserModal.hide();
    }
  }
}

export interface UserIdData {
  id: number;
  account_id: string;
}
