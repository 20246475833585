<div style="margin: 10px;">
    <!-- a)	Member ID/ Member No.
b)	Account Name
c)	Account ID
d)	Tier Category
e)	Balance Points
f)	ARN Number
g)	Key Account Code -->



<!-- card -->
<mat-card>
    <mat-card-content>
        <div style=" display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));row-gap: 10px;" *ngFor="let item of dataArray">
            <div>
                <p>
                    <span style="font-size: 16px;font-weight: bold;">Account Name</span>
                </p>
                <p>
                    <span style="font-size: 14px;font-weight: 500;">{{item.account_name}}</span>
                </p>
            </div>

            <div>
                <p>
                    <span style="font-size: 16px;font-weight: bold;">Account ID</span>
                </p>
                <p>
                    <span style="font-size: 14px;font-weight: 500;">{{item.account_id}}</span>
                </p>
            </div>
            <div>
                <p>
                    <span style="font-size: 16px;font-weight: bold;">Member Id</span>
                </p>
                <p>
                    <span style="font-size: 18px;font-weight: 700;color: blue;">{{item.membership_number}}</span>
                </p>
            </div>
            <div>
                <p>
                    <span style="font-size: 16px;font-weight: bold;">Key Account Code</span>
                </p>
                <p>
                    <span style="font-size: 18px;font-weight: 700;color: blue;">{{item.key_account_code}}</span>
                </p>
            </div>
            <div>
                <p>
                    <span style="font-size: 16px;font-weight: bold;">Balance Points</span>
                </p>
                <p>
                    <span style="font-size: 18px;font-weight: 700;color: green;">{{item.balance_points| currency:'':'':'1.0-0'}}</span>
                </p>
            </div>
            <div>
                <p>
                    <span style="font-size: 16px;font-weight: bold;">Master Mobile No</span>
                </p>
                <p>
                    <span *ngIf="userDetails?.contact_no" style="font-size: 14px;font-weight: 500;">{{userDetails?.contact_no}}</span>
                    <span *ngIf="!userDetails?.contact_no" style="    font-size: 14px;
                    font-weight: 500;
                    text-align: center;
                    height: -1px;
                    width: 10px;
                    border: 1px solid black;
                    display: flex;
                    margin-left: 20px;"><span></span></span>
                </p>
            </div>       
            <div >
                <p>
                    <span style="font-size: 16px;font-weight: bold;">Email Id</span>
                </p>
                <p>
                    <span *ngIf="item.email_id" style="font-size: 14px;font-weight: 500;">{{item.email_id}}</span>
                    <span *ngIf="!item.email_id" style="    font-size: 14px;
                    font-weight: 500;
                    text-align: center;
                    height: -1px;
                    width: 10px;
                    border: 1px solid black;
                    display: flex;
                    margin-left: 20px;"><span></span></span>
                </p>
            </div>
            <div>
                <p>
                    <span style="font-size: 16px;font-weight: bold;">Tier Category</span>
                </p>
                <p>
                    <span style="font-size: 14px;font-weight: 500;">{{item.tier_category}} </span>
                    <span style="font-size: 14px;font-weight: 500;">({{item.tier_category == 'Emperor Gold'? '3%' : item.tier_category == 'Emperor Silver' ? '1.5%':item.tier_category == 'Emperor Silver +'?'2%':item.tier_category == 'Emperor Platinum'?'4%':''}})</span>
                </p>
            </div>
            <div>
                <p>
                    <span style="font-size: 16px;font-weight: bold;">ARN Number</span>
                </p>
                <p>
                    <span *ngIf="item.ARN_number" style="font-size: 14px;font-weight: 500;">{{item.ARN_number}}</span>
                    <span *ngIf="!item.ARN_number" style="font-size: 14px;
                    font-weight: 500;
                    text-align: center;
                    height: -1px;
                    width: 10px;
                    border: 1px solid black;
                    display: flex;
                    margin-left: 20px;"><span></span></span>
                </p>
            </div>
        </div>
        <div class="row">
            <span style="color: red;">*The Loyalty percentage is calculated on the total invoice amount.</span>
            <span style="float: right;font-weight: 700;" class="mt-2">To pay your unpaid invoices using loyalty points, <a style="color: blue;text-decoration: underline;cursor: pointer;" (click)="OnRedirectLoyalty('paybyloyalty')">Click here</a></span>
            <span style="float: right;font-weight: 700;" class="mt-2">To view your redemption requests, <a style="color: blue;text-decoration: underline;cursor: pointer;" (click)="OnRedirectLoyalty('csrlist')">Click here</a></span>
        </div>
        <div class="row mt-4">
            <h5>Terms & Conditions</h5>
            <span style="font-weight: 700;">Earning Conditions:</span>
            <ul style="margin-left:20px">
                <li>If the member's email or mobile number is not linked to their account, they will not be able to use loyalty points as verification cannot be completed using email.</li>
                <li>Partially paid invoices will not be provided with the option to pay using loyalty points. </li>
                <li>Loyalty points can only be redeemed when the invoice is fully pending and fulfills the sufficient points criteria.</li>
                <li>In-case, Invoice cancellation is initiated post settlement, Loyalty points will not be refunded back to the account.</li>
                <li>Loyalty points refund is not applicable for Return orders.</li>
                <li >The points on the PDP are indicative. Final accrual of points will happen at the time of invoicing.</li>
            </ul>

            <span style="font-weight: 700;">Redemption Conditions:</span>
            <ul style="margin-left:20px">
                <li>The use of Loyalty points for redemption is subject to the approval by the main user of the account.</li>
            </ul>
        </div>
    </mat-card-content>
  </mat-card>
</div>