<div class="modal-header modal-title">
    Combo Offers
    <div (click)="closeModel.emit()" type="button"><i class="fa fa-times"></i></div>
</div>
<div class="modal-body" style="padding-left:0px">
    <div class="container">
        <div class="col-md-12">
           <div class="panel panel-default">
              <div [class]="slab_details.length > 6 ?'panel-body table-max-height-scroll':'panel-body'" class="table-responsive">
                 <table class="table table-condensed table-striped" width="80">
                    <thead class="table-light">
                       <tr class="text-center">
                        <th></th>
                        <th class="text-nowrap th-headingfont">Quantity</th>
                        <th class="text-nowrap th-headingfont">Percentage</th>
                       </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngIf="slab_details">
                            <ng-container *ngFor="let item of slab_details; let i = index">
                                <tr>
                                    <td class="text-nowrap " style="text-align: center;">Slab {{i+1}}</td>
                                    <td class="text-nowrap " style="text-align: center;">{{item?.quantity | number:'1.0-0'}}</td>
                                    <!-- <td class="text-nowrap">{{ item?.discount_code }}</td> -->
                                    <td class="text-nowrap" style="text-align: center;">{{ item?.percentage }}</td>
                                </tr>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="slab_details.length == 0">
                            <tr>
                                <td> <p class="noPartData">No Data Found</p></td>
                            </tr>
                        </ng-container>
                    </tbody>
                 </table>
              </div>
              
                
           </div>
           <h4>Part Numbers</h4>
           <div style="display: flex;flex-wrap: wrap;margin-top: 5px;">
            <mat-chip-list>
                <mat-chip
                disableRipple
                class="customViewModelClass"
                  *ngFor="let item of skuItemsArry">
                  <div class="partnameImgclass">
                    <div>
                      <img (click)="routeToProduct(item)" src="{{item.part_image_path}}" class="imgClass" width="100%"  alt="banner 1">
                    </div>
                    <div>
                      <span (click)="routeToProduct(item)" style="cursor: pointer;"><span style="font-weight: bold;">{{item.part_name}}</span> {{item.sku_item}}</span>
                    </div>
                    <!-- <div style="display: flex;align-items: center;">
                      <app-input-number aria-label="Quantity" size="lg" [min]="1" [max]="8" [isTmgoValue]="mrkdetail.resource_type == 'Pack' && mrkdetail.moq > 1?mrkdetail.moq:'1'"
                        (currentQuantity)="readproductdetaailQuantity(mrkdetail,$event,counterIndex)" [defaultValue]="mrkdetail.minimum_quantity">
                      </app-input-number>
                      <span style="position: relative;margin-left: 5px; width: 35px;" class="col-7 text-end d-flex align-items-center justify-content-center">
                        <button class="add-to-card-new-product" (click)="partAddtoCart(mrkdetail,counterIndex)"><img alt=""
                          src="./assets/images/cart.svg" /></button>
                      </span>
                    </div> -->
                  </div>
                  <!-- {{item.sku_item}} -->
                  <!-- <span (click)="routeToProduct(item)" style="cursor: pointer;">
                    {{item.part_name}}-{{item.sku_item}}
                  </span> -->
                </mat-chip>
              </mat-chip-list>
           </div>
              <!-- <mat-chip-grid aria-label="Part Numbers">
                <mat-chip disableRipple *ngFor="let item of slabMaster">
                 {{item.sku_item}}
                </mat-chip>
            </mat-chip-grid> -->
    

        </div>
    </div>
</div>