import {
  Component,
  Input,
  OnInit,
  OnChanges,
  ChangeDetectorRef,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router } from '@angular/router';

import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { secureStorage } from '../../services/securestorage';
@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit, OnChanges {
  @Input() header: any;
  @Input() menuHeadName: string;
  @Input() breadcrumbs;
  @Input() searchValue: string;
  @Input() searchBy: string;
  @Output() breadcrumbsChange = new EventEmitter<any>();
  breadcrumbs1;
  newparams;
  localbreadcrumb;
  hidebreadcrumb = false;
  isLoggedIn: boolean = false;
  gotohome;
  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private appStateService: AppStateService,
    private localstorage: secureStorage
  ) {
    if (this.searchValue == 'oillubricants') {
      this.searchValue = 'Oil & Lubricants';
    }
    if (this.searchValue == '') {
      //this.searchValue= this.menuHeadName;
    }
  }
  ngOnInit() {
    this.appStateService.isLoggedIn.subscribe((isLog) => {
      this.isLoggedIn = isLog;
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    this.breadcrumbs = this.breadcrumbs;
    if (this.breadcrumbs == undefined || this.breadcrumbs == null) {
      this.hidebreadcrumb = true;
    } else {
      this.hidebreadcrumb = false;
    }
    this.cd.markForCheck();

    //     // this.breadcrumbsChange.emit();
    // var value = this.searchValue;
    // if (value != undefined) {
    //   this.searchValue = value.trim();
    //   //// this.searchValue = value.split("-").pop().trim();
    // } else {
    //   this.searchValue = this.searchBy;
    //   // this.searchValue =this.searchValue +'ss';
    // }
  }

  GotoRoute(breadcrumb) {
    if (breadcrumb.url == ' ') {
      return;
    } else {
      this.breadcrumbs.pop();
      localStorage.removeItem('prodcardbreadcrumb');

      this.localstorage.setItem(
        'productcardBreadcrumb',
        JSON.stringify(this.breadcrumbs)
      );
      // this.breadcrumbs=localSto
      if (breadcrumb.url.includes('orders')) {
        if (breadcrumb.url.includes('active')) {
          this.router.navigate([breadcrumb.url], {
            queryParams: {
              isViewAll: breadcrumb.params.isViewAll,
            },
          });
        } else {
          this.router.navigate([breadcrumb.url]);
        }
      } else if (breadcrumb.url == '/account/report') {
        this.router.navigate(['/account/report'], {
          queryParams: {
            isViewAll: breadcrumb.params.isViewAll,
          },
        });
      }  else if (breadcrumb.url == 'shop/product-details') {
        this.router.navigate(['/shop/product-details'], {
          queryParams: {
            part_Number: breadcrumb.params.part_Number,
          },
        }
        );
      } else if(breadcrumb.url == '/account/myoffer'){
        this.router.navigate(['/account/myoffer'], {
          queryParams: {
            isMyOffer: breadcrumb.params.isMyOffer,
          },
        }
        );
      } else if(breadcrumb.url == '/account/credit-list'){
        this.router.navigate(['/account/credit-list']);
      }
      else {
        this.router.navigate(['/shop/productcards'], {
          queryParams: breadcrumb.params
        });
      }
    }
  }
  gotoHome() {
    this.gotohome = this.isLoggedIn ? '/account/home' : '';
    this.router.navigate([this.gotohome]);
  }
  call(url, params) {
    if ((url = ' ')) {
      return;
    } else {
      this.newparams = params.searchBy;
      this.router.navigate([url], {
        queryParams: {
          searchBy: params.searchBy,
          type: params.type,
          isViewAll: params.isViewAll,
          ModelName: params.ModelName,
        },
      });
    }
  }
  // Goto(url) {
  //   this.router.navigate(['/shop/Main'], {
  //     queryParams: {
  //       searchBy: 'model',
  //       type: 'POPULAR MODELS',
  //       search: url.trim(),
  //     },
  //   });
  // }
}
