import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { secureStorage } from '../../services/securestorage';
@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss']
})
export class LogoutModalComponent implements OnInit {
    // public dtTrigger: Subject<any> = new Subject();
    // dtOptions: DataTables.Settings = {};

    @Output() userLogout = new EventEmitter<any>();
    @Output() modalClose = new EventEmitter<any>();
    @Input() orderCancel : boolean = false;
    @Input() isLogout : boolean = false;
    @Input() isPONO: boolean = false;
    @Input() Po_No: any;
    @Input() isVehicleDelete: boolean = false;
    @Input() isCashDiscountPermission: boolean = false;
    @Output() deleteVehicle= new EventEmitter<any>();
    @Input() isUserDelete: boolean = false;
    @Input() showWarningForCashDiscount: boolean = false;
    @Input() clearAllCartPermission: boolean = false;
    @Input() clearAllWishlist: boolean = false;
    @Input() deletedItems : boolean = false;
    @Input() Part_not_added_with_reason;
    @Input() isContactPersonUpdate:{ contactUpdat:boolean, contactPersonName:string } = { contactUpdat:false,contactPersonName:""};
    @Output() contactPersonUpdate = new EventEmitter<any>();
    @Input() isContactPersonRemove:boolean = false;
    @Output() contactPersonRemove = new EventEmitter<any>();
    @Input() isContactPersonAdd:boolean = false;
    @Output() ContactPersonAdd = new EventEmitter<any>();
    @Input() returnmoqcheck : boolean = false;
    @Input() isFitment:boolean = false;
    @Output() fitment = new EventEmitter<any>();
    @Input() fitmentData:any
    isUserType:any;

    constructor(
        private localstorage: secureStorage,
    ) { }

    ngOnInit() { 
        this.isUserType = JSON.parse(this.localstorage.getItem('userData'));
    }

    UserLogout() {
        if(this.orderCancel == true){
            this.userLogout.emit('Yes');
        }else if(this.isVehicleDelete){
            this.deleteVehicle.emit();
        }else if(this.isLogout == true){
            this.userLogout.emit('Logout');
        }else if(this.isUserDelete == true){
            this.userLogout.emit('Yes Delete');
        }else if(this.isContactPersonUpdate.contactUpdat == true){
            this.contactPersonUpdate.emit('update');
        }else if( this.isContactPersonRemove == true){
            this.contactPersonRemove.emit('remove');
        }else if( this.isContactPersonAdd == true){
            this.ContactPersonAdd.emit('add');
        }
    }

    closeModal(event) {
        if(this.orderCancel == true){
            this.userLogout.emit('No');
        }
        else if(this.isLogout == true){
            this.modalClose.emit('Logout');
        }
        else if(this.isPONO == true){
            this.modalClose.emit(event);
        }
        else if(this.isVehicleDelete){
            this.modalClose.emit();
        }
        else if(this.isUserDelete == true){
            this.modalClose.emit();
        }
        else if(this.isCashDiscountPermission === true){
            this.modalClose.emit(event);
        }
        else if(this.clearAllCartPermission){
            this.modalClose.emit(event);
        }
        else if(this.clearAllWishlist){
            this.modalClose.emit(event);
        }
        else if(this.deletedItems){
            this.modalClose.emit(event);
            
        }else if(this.isContactPersonUpdate.contactUpdat){
            this.modalClose.emit();
        }else if(this.isContactPersonRemove){
            this.modalClose.emit();
        }else if(this.isContactPersonAdd){
            this.modalClose.emit();
        }
        else if(this.returnmoqcheck){
            this.modalClose.emit(event);          
        }
        else if(this.isFitment){
            this.modalClose.emit();          
        }
    }

}
