import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree ,Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppStateService } from '../app-state/app-state.service';
import { AuthService } from './auth.service';
import { secureStorage } from '../securestorage';
@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard  {
  constructor(
    private appStateService: AppStateService, 
    private router: Router,
    private authServ:AuthService,
    private localstorage: secureStorage,) {}
  canActivate(state: RouterStateSnapshot,router: Router): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let isLogin:boolean = false;  
    this.appStateService.isLoggedIn.subscribe(
        isLogIn => {
 
          if(!isLogIn){
            this.authServ.redirectUrl = router.url;
            this.localstorage.setItem("redirectUrl",this.authServ.redirectUrl);
            console.log("store_url_to_rdierct",this.authServ.redirectUrl);

            setTimeout(() => {
            this.router.navigate(['account/login'], {queryParams: {isLogin: 'true'}});      
            },);
          }else{          
         
            isLogin=true;
          }
          // !isLogIn ? this.router.navigate(['account/login'], {queryParams: {isLogin: 'true'}}):isLogin=true;
        });
      return isLogin;
    }

}
