import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../services/REST/user.service';

import { blockPermissionMsg } from 'src/app/constant';
import { secureStorage } from '../../services/securestorage';
@Component({
  selector: 'add-account',
  templateUrl: './add-account.component.html',
  styleUrls: ['./add-account.component.scss'],
})
export class AddAccountComponent implements OnInit {
  blockPermissionMsg = blockPermissionMsg;
  userAccountForm: FormGroup;
  accountid: any;
  btndisabled: boolean = false;
  @Output() closePopUp = new EventEmitter<any>();
  showLoader: boolean = false;
  constructor(
    private toastrService: ToastrService,
    private userService: UserService,
    private router: Router,
    private formBuilder: FormBuilder,
    private localstorage: secureStorage
  ) {}

  ngOnInit() {
    this.accountForm();
  }

  alphanumbericOnly(event): boolean {
    var inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z0-9-]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  onSubmit() {
    try {
      let permission = this.userService.CheckPermission('btn_add_account');
      if (permission) {
        if (!this.accountid) {
          //this.formErrorService.displayFormErrors(this.userAccountForm);
          this.toastrService.error('Please Enter account Id');
          return;
        }
        this.btndisabled = true;
        let user = JSON.parse(this.localstorage.getItem('userData'));
        if (user) {
          let data = {
            account_id: this.accountid,
            account_pk: parseInt(user.account_pk),
          };
          // let permission = this.userService.CheckPermission("btn_add_account");
          // if (permission) {
          this.localstorage.setItem('AccountData', 'true'); //for test
          this.showLoader = true;
          this.userService.AddAccount(data).subscribe(
            (response) => {
              if (response.success === true) {
                this.showLoader = false;
                this.accountid = '';
                localStorage.removeItem('AccountData');
                this.localstorage.setItem('AccountData', 'true');
                this.toastrService.success('Account Id Added Successfully.');
                this.closePopUp.emit('Account Id Added Successfully.');
                //this.router.navigate(['/account/accountlist']);
              } else {
                this.showLoader = false;
                this.btndisabled = false;
                this.toastrService.error(response.data.msg);
              }
              this.showLoader = false;
            },
            (error) => {
              this.showLoader = false;
            }
          );
          // } else {
          //   this.toastrService.error(
          //     'Your access is restricted, please contact Organisation Manager...!'
          //   );
          // }
        }
      } else {
        this.toastrService.error(this.blockPermissionMsg);
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: ' onSubmit()',
        page_name: 'PageAddAccountComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  accountForm() {
    this.userAccountForm = this.formBuilder.group({
      name: [''],
      custid: ['', [Validators.required]],
    });
  }

  closeModal() {
    this.closePopUp.emit();
  }
}
