<div class="container-fluid orders">
    <!-- <h5 class="profile-heading mb-3">Account List</h5> -->
    <div class="row">
        <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 pb-3 pt-3">
            <!-- <ul class="nav nav-tabs tabs1" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                    <a class="nav-link active" id="profile-tab" data-bs-toggle="tab" href="#profile" role="tab"
                        aria-controls="profile" aria-selected="false">Account List</a>
                </li>
            </ul> -->
            <!-- <hr class="hr-page-divider" /> -->
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                    <ul class="nav nav-tabs tabs1 position-relative" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <h5 class="profile-heading mb-3">Account List</h5>
                        </li>
                        <button style="position: absolute; right: 16px; top: -5px;" type="button" class="btn btn-outline-primary" (click)="addAccount()"
                            href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#cancelorderModal">Add
                            Account</button>
                    </ul>
                    <div class="tab-content mt-3" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="nav-Items" role="tabpanel"
                            aria-labelledby="nav-Items-tab">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <div class="table-responsive table-container">
                                        <table  class="table datatable display" datatable  [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                                            <thead>
                                                <tr>
                                                    <th>Account Id</th>
                                                    <th>Account Name</th>
                                                    <th>Contact No.</th>
                                                    <th>Address</th>
                                                    <th>Account Status (Activate/Deactivate)</th>
                                                    <!-- <th>Created Date</th> -->
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let account of accountList">
                                                    <td>{{ account.account_id}}</td>
                                                    <td>{{ account.account_name}}</td>
                                                    <td>{{ account.contact_no }}</td>
                                                    <td class="text-break">{{ account.address_1}},
                                                        {{ account.address_2}},
                                                        {{ account.address_3}},
                                                        {{ account.city}},
                                                        {{ account.district}},
                                                        {{ account.state}},
                                                        {{ account.pincode}}</td>
                                                    <td><mat-slide-toggle (change)="changeAccountStatus($event,account.account_id);" [disabled]="userDetails?.account_id === account.account_id" [checked]="account?.is_active"></mat-slide-toggle></td>
                                                    <!-- <td>{{ account.created_at | date:'dd-MM-yyyy':'UTC'}}</td> -->
                                                </tr>
                                                <!-- <tr>
                                                    <td>41d5-4f6-c04f9355f004</td>
                                                    <td>
                                                        21 Aug, 4 PM
                                                    </td>
                                                    <td class="part-qty">20 <span>Parts</span> 100 <span>Qty</span></td>
                                                    <td class="text-end">45,450.00</td>
                                                    <td>Credit</td>
                                                    <td>
                                                        Today, 10 PM
                                                    </td>
                                                    <td class="feedback text-nowrap">
                                                        <i class="fas fa-star"></i><i class="fas fa-star"></i><i
                                                            class="fas fa-star"></i><i class="fas fa-star"></i><i
                                                            class="far fa-star"></i>
                                                        <a href="javascript:void(0);" data-bs-toggle="modal"
                                                            data-bs-target="#feedbackModal">Edit</a>
                                                        <div class="feedback-desc">Good service. Delivery boy was 1 hour
                                                            late, lost Good service. Delivery boy was 1 hour late, lost
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <a class="me-2" href="javascript:void(0);"
                                                            data-bs-toggle="modal">Re-order</a>
                                                        <a class="me-2" href="javascript:void(0);"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#returnModal">Return</a>
                                                        <a class="me-2" href="javascript:void(0);"
                                                            data-bs-toggle="modal">Pay</a>
                                                        <a class="me-2 paid" href="javascript:void(0);"
                                                            data-bs-toggle="modal">Paid</a>
                                                    </td>
                                                </tr> -->

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #addUserAccount let-d="dismiss">
    <add-account (closePopUp)="closeModal($event);"></add-account>
</ng-template>

<ng-container *ngIf="showLoader">
    <div class="loader">
        <div class="ng-spinner ng-spinner-icon"></div>
    </div>
</ng-container>