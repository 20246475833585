// coach marks for Slab Quantity
export const coachMarksElements = [
    // delivery consignment coachmark
    {
        index: 1,
        imgUrl: "assets/images/rocket-circle.png",
        heading: "What's New",
        infoText: "Now you can track status of each parts in your order, in-order to check this, Under Orders, click on the OTC No. and then click on <strong>TRACK ORDER</strong> truck icon to view status of your order.",
        routerLink: "",
        top: 30,
        left: 30,
        beforePosition: "",
        elementId: "",
        TriggerNext: "",
        queryParam: "",
        paramValue: ""
    },
    // {
    //     index: 1,
    //     imgUrl: "assets/images/rocket-circle.png",
    //     heading: "What's New",
    //     infoText: "1) Now you can share any product details and its specifications from the part details page, use it to share to various social media sites such as WhatsApp, X, Facebook, Gmail or copy link to the clipboard.",
    //     infoTextsecond: "2)  Now you can enter your registration number or chassis number in-order to check whether the part you're ordering is compatible with your vehicle or not. Head to the product detail page and scroll below to find the vehicle compatibility check option.",
    //     routerLink: "",
    //     top: 30,
    //     left: 30,
    //     beforePosition: "",
    //     elementId: "",
    //     TriggerNext: "",
    //     queryParam: "",
    //     paramValue: ""
    // },
    
    /* loyality coach mark*/
    // {
    //     index: 1,
    //     imgUrl: "assets/images/rocket-circle.png",
    //     heading: "What's New",
    //     infoText: "Emperor users can start earning loyalty points on purchases made through e-Dukaan.  Emperor users can use <strong>'Pay By Loyalty'</strong> option when placing an order, With sufficient points balance, the accumulated loyalty points will be used to complete the transaction.",
    //     routerLink: "",
    //     top: 30,
    //     left: 30,
    //     beforePosition: "",
    //     elementId: "",
    //     TriggerNext: "",
    //     queryParam: "",
    //     paramValue: ""
    // },
]

    /*qr code coach mark */
//     {
//         index: 1,
//         imgUrl: "assets/images/rocket-circle.png",
//         heading: "What's New",
//         infoText: "Instead of manually typing the part number, now you can <strong>scan the QR code</strong> printed on the carton box to search and find any parts!! <br> Click on the <strong> QR Code icon</strong>, Beside the search bar, to start scanning your QR Codes.",
//         routerLink: "",
//         top: 30,
//         left: 30,
//         beforePosition: "",
//         elementId: "",
//         TriggerNext: "",
//         queryParam: "",
//         paramValue: ""
//     },
// ]

//Combo Offers
// {
//     index: 1,
//     imgUrl: "assets/images/rocket-circle.png",
//     heading: "What's New",
//     infoText: "Explore additional benefits on <strong>Combo Offers</strong> for eligible parts now available.Simply navigate to the part details page.",
//     routerLink: "",
//     top: 30,
//     left: 30,
//     beforePosition: "",
//     elementId: "",
//     TriggerNext: "",
//     queryParam: "",
//     paramValue: ""
// },
//Combo Offers

    // Additional Discount CoachMark
//     {
//         index: 1,
//         imgUrl: "assets/images/rocket-circle.png",
//         heading: "What's New",
//         infoText: "Now you can receive <strong>Additional Discounts</strong> from selected distributors on placing an order using on-credit option, in-order to check the discounts received, click on <strong>Order > Order History</strong> and click on any order to check the additional discounts in payment summary.",
//         routerLink: "",
//         top: 30,
//         left: 30,
//         beforePosition: "",
//         elementId: "",
//         TriggerNext: "",
//         queryParam: "",
//         paramValue: ""
//     },
// ]
    // Additional Discount CoachMark
    //PG6 and Duplication order notification Coachmark//
    // {
    //     index: 1,
    //     imgUrl: "assets/images/rocket-circle.png",
    //     heading: "What's New",
    //     infoText: "1) Introducing TMGO as a New Multi-Seller Category for  <strong>PG6 Parts! </strong>.",
    //     infoTextsecond: "2)  <strong>Avoid duplicate orders! </strong>Get notified if you're about to order a part already in your pending list. Easily check your active order for details before confirming.",
    //     routerLink: "",
    //     top: 30,
    //     left: 30,
    //     beforePosition: "",
    //     elementId: "",
    //     TriggerNext: "",
    //     queryParam: "",
    //     paramValue: ""
    // },
    //PG6 and Duplication order notification Coachmark//

//Bandhu Core connection Coachmark//
// {
//     index: 1,
//     imgUrl: "assets/images/rocket-circle.png",
//     heading: "What's New",
//     infoText: "Presenting the New <strong>Bandhu Service Request</strong> feature in e-Dukaan. With this feature, you can now quickly submit a request in e-Dukaan for the installation of any items you have ordered. You can schedule the arrival of our Bandhu mechanic at your convenience",
//     routerLink: "",
//     top: 30,
//     left: 30,
//     beforePosition: "",
//     elementId: "",
//     TriggerNext: "",
//     queryParam: "",
//     paramValue: ""
// },
// {
//     index: 2,
//     imgUrl: "",
//     heading: "Raise Service Request",
//     infoText: "We have added this feature in the Order History section to make it easier to find. Please use the scroller to move to the right side of the screen and click on <strong>'Raise Service Request'</strong>, You can place a request by filling the form. <br/>Alternatively, You can access this option after ordering a part by clicking on the <strong>'Bandhu icon'</strong> on the top right part of the screen in the payment confirmation page.",
//     routerLink: "/orders/ecom-orders",
//     top: 44,
//     left: 70,
//     beforePosition: "right",
//     elementId: "",
//     TriggerNext: "",
//     queryParam: "",
//     paramValue: ""
// },
// {
//     index: 4,
//     imgUrl: "",
//     heading: "Service Request",
//     infoText: "In the Report section, under <strong>'Service Request'</strong>, you can check the details and status of the Service Requests Raised.",
//     routerLink: "/account/servicerequestreport",
//     top: 40,
//     left: 5,
//     beforePosition: "right",
//     elementId: "",
//     TriggerNext: "",
//     queryParam: "",
//     paramValue: ""
// }

//Bandhu Core connection Coachmark//

    //My Saving coachmark//
    // {
    //     index: 1,
    //     imgUrl: "assets/images/rocket-circle.png",
    //     heading: "What's New",
    //     infoText: "Introducing, <strong>My Savings! </strong>With My Savings, you can conveniently track and monitor the amount you've saved on every order. Stay informed about your accumulated savings and enjoy a transparent view of the benefits you've gained through our platform.",
    //     routerLink: "",
    //     top: 30,
    //     left: 30,
    //     beforePosition: "",
    //     elementId: "",
    //     TriggerNext: "",
    //     queryParam: "",
    //     paramValue: ""
    // },
    // {
    //     index: 2,
    //     imgUrl: "",
    //     heading: "My Savings",
    //     infoText: "Click here to view total savings you have accumulated so far this month.",
    //     routerLink: "/",
    //     top: 52,
    //     left: 70,
    //     beforePosition: "right",
    //     elementId: "my-savings",
    //     TriggerNext: "",
    //     queryParam: "",
    //     paramValue: "",
    //     width: 20
    // },
    // {
    //     index: 3,
    //     imgUrl: "",
    //     heading: "My Savings",
    //     infoText: "To view your savings for specific period, select the desired date range and click on search. This will show you the amount you've saved within the specified timeframe.",
    //     routerLink: "/",
    //     top: 50,
    //     left: 44,
    //     beforePosition: "right",
    //     elementId: "saving-order-list",
    //     TriggerNext: "",
    //     queryParam: "",
    //     paramValue: "",
    //     width: 20
    // },
    // {
    //     index: 4,
    //     imgUrl: "",
    //     heading: "My Savings",
    //     infoText: "To view the order-wise saving details, please click here. ",
    //     routerLink: "/",
    //     top: 61,
    //     left: 44,
    //     beforePosition: "right",
    //     elementId: "saving-order-list",
    //     TriggerNext: "",
    //     queryParam: "",
    //     paramValue: "",
    //     width: 20
    // },
    // {
    //     index: 5,
    //     imgUrl: "",
    //     heading: "My Savings",
    //     infoText: "This will give you a comprehensive overview of the savings associated with each individual order.",
    //     routerLink: "/orders/ecom-orders",
    //     top: 53,
    //     left: 42,
    //     beforePosition: "right",
    //     elementId: "my-savings",
    //     TriggerNext: "",
    //     queryParam: "",
    //     paramValue: ""
    // }
    //My Saving coachmark//
// ]
// coach marks for scheme module
// export const coachMarksElements = [
//     {
//         index:1,
//         imgUrl:"assets/images/rocket-circle.png",
//         heading:"What's New",
//         infoText:"Introducing<b>'My Schemes'</b> - a new feature that allows you to conveniently view newly created schemes tailored specifically for you. Explore the latest schemes and track your progress within each scheme with ease.",
//         routerLink:"",
//         top:30,
//         left:30,
//         beforePosition:"",
//         elementId:"",
//         TriggerNext:"",
//         queryParam:"",
//         paramValue:""
//     },
//     {   
//         index:2,
//         imgUrl:"",
//         heading:"Scheme Filters",
//         infoText:"Search for a specific scheme here.",
//         routerLink:"account/schemes",
//         top: 33,
//         left: 55,
//         beforePosition:"right",
//         elementId:"scheme-list",
//         TriggerNext:"MY OFFERS",
//         queryParam:"isMyOffer",
//         paramValue:"",
//         width:20
//     },
//     {   
//         index:3,
//         imgUrl:"",
//         heading:"Scheme Details",
//         infoText:"To view scheme details and track your progress, simply click on the scheme name.This will provide you with in-depth information about the scheme and enable you to monitor your progress effectively.",
//         routerLink:"",
//         top:45,
//         left:13,
//         beforePosition:"left",
//         elementId:"scheme-list",
//         TriggerNext:"",
//         queryParam:"",
//         paramValue:""
//     },
//     {   
//         index:4,
//         imgUrl:"",
//         heading:"Scheme Type",
//         infoText:"This list will provide you with an overview of the various types or categories of schemes that are offered.",
//         routerLink:"",
//         top:46,
//         left:31,
//         beforePosition:"left",
//         elementId:"scheme-list",
//         TriggerNext:"",
//         queryParam:"",
//         paramValue:""
//     },
//     {   
//         index:5,
//         imgUrl:"",
//         heading:"Scheme Status",
//         infoText:"You can check the current status of the schemes here. This  allows you to monitor and stay updated on the status of the schemes.",
//         routerLink:"account/schemes",
//         top: 45,
//         left: 60,
//         beforePosition:"right",
//         elementId:"scheme-list",
//         TriggerNext:"",
//         queryParam:"",
//         paramValue:""
//     },
//     {   
//         index:6,
//         imgUrl:"",
//         heading:"Product Scheme List",
//         infoText:"Here you can view schemes related for the particular part. Click on View Schemes to get scheme details with slab breakup.",
//         routerLink:"shop/product-details?part_Number=269126200212",
//         top: 30,
//         left: 60,
//         beforePosition:"left",
//         elementId:"scheme-list",
//         TriggerNext:"",
//         queryParam:"",
//         paramValue:""
//     }
// ]