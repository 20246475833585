import { Input, Component, OnDestroy, OnInit, TemplateRef, ViewChild,AfterViewInit } from '@angular/core';
import { Router, Event, NavigationEnd, ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject, interval } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie';
import { ToastrService } from 'ngx-toastr';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { CartService } from 'src/app/shared/services/cart.service';
import { UserService } from 'src/app/shared/services/REST/user.service';
import { RootService } from 'src/app/shared/services/REST/root.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserCartService } from 'src/app/shared/services/REST/usercart.service';
import { AuthService } from 'src/app/shared/services/REST/auth.service';
import { ProductService } from 'src/app/shared/services/REST/product.service';
import { CartItem } from 'src/app//shared/interfaces/cart-item';
import { ModelService } from 'src/app/shared/services/REST/model.service';
import { Inputdata } from 'src/app/shared/interfaces/product';
import { blockPermissionMsg } from 'src/app/constant';
import { secureStorage } from 'src/app/shared/services/securestorage';
import { GoogleAnalyticsService } from 'src/app/shared/services/GoogleAnalytics/GoogleAnalytics.service';
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  blockPermissionMsg = blockPermissionMsg;
  @ViewChild('SearchModalPopup', { read: TemplateRef, static: false })
  SearchModalPopUp: TemplateRef<any>;
  @ViewChild('videoPlayer', { read: TemplateRef, static: false })
  videoPlayer: TemplateRef<any>;
  videoPlayerModal: any;
  SearchModalRef: any;
  showNoRecord: boolean = false;
  public searchInput$ = new Subject<string | null>();
  model_total_count: any;
  activeModel: any;
  modelAggregateData: any;
  showdivAggregate: boolean = false;
  showdivModel: boolean = false;
  showdivMultiModel: boolean = false;
  showSearchdiv: any;
  allModelData: any[] = [];
  showdiv: any;
  showHeader: boolean = false;
  private destroy$: Subject<void> = new Subject();
  items: Item[] = [];
  updating = false;
  removedItems: CartItem[] = [];
  isCartCount = {};
  cartTypeList: any;
  itemdata = [];
  setAddress: boolean = false;
  isLoggedIn: boolean = false;
  pinCode: any;
  pinCodeNumber: any;
  modalRef: any;
  showLoader: boolean = false;
  expiredDate: any;
  previousUrl: string = '';
  currentUrl: string = '';
  searchForm: FormGroup;
  appStatePinCode: any;
  addressModal: any;
  selectUserModal: any;
  userIds = [];
  btndisabled: boolean = false;
  isSwitchAccount: boolean = false;
  previousAccount: any;
  instantAccount: any;
  clearData: boolean = false;
  @ViewChild('logoutModal', { read: TemplateRef, static: false })
  template: TemplateRef<any>;
  @ViewChild('addressModal', { read: TemplateRef, static: false })
  address: TemplateRef<any>;
  @ViewChild('selectUser', { read: TemplateRef, static: false })
  selectUser: TemplateRef<any>;
  @ViewChild('openScanner', { read: TemplateRef, static: false })
  openScanner: TemplateRef<any>;
  searchType =
    'Search by Part No., Part Description, Model Name, Registration No., Chassis No. etc.';
  isLogout: boolean = false;
  userDetails: any;
  userData: any;
  userDetail: any;
  mhvCart: any = [];
  cartArr: any = [];
  Defaultaddress: any;
  isorderHistoryProfilePermission;
  isChangePassPermission;
  postLoginCartLength: number = 0;
  responseItems = [];
  isNoItemsPresent: boolean = false;
  headerLoader: boolean = false;
  @Input() loading;
  isAutoSuggestApiLoaded: boolean = false;
  openSearchModal: boolean = false;
  isModelSelected: boolean = false;
  isAggregateApiLoaded: boolean = false;
  activeVCno: any;
  activeBaseModel = undefined;
  singleModelResponse: any;
  isHeaderCartApiLoaded: boolean = false;
  prodcardbreadcrumb = [];
  isKeyClockUser: boolean = false;
  isCartClicked: boolean = false;
  currentAccount: any[];
  isSetUserClicked: boolean = false;
  isSwitchAccountClicked: boolean = false;
  videoList: any[] = [];
  showSearchButton: boolean = true;
  speechData: string = '';
  showRing: boolean = false;
  startbtnClicked: boolean = false;
  emittedText: string;
  edukaanTimelineData: { count: any, isNewNotification: boolean } = { count: 0, isNewNotification: false };
  subInterval: any;
  isAutoSuggest: boolean = false;
  autoSuggestList: any[] = []
  SearchSuggestValue: string = '';
  searchtextWidth: number = 0;
  timer: any;

  billingAddress:any;
  shippingAddress:any;
  searchtext: string;
  suggestdata: any;
  suggestPartNodata: any;
  mintifylimit: any;
  private responsesuggest: any = null;
  private foo: any = null;
  iscartopen:boolean = false;
  openScannerUi:boolean = false;
  scanModalservice:any
  loyaltyBalance:any;
  tierCategory:any;
  loyaltyMemberId:any
  loyalityInfo:any;
  isPopupVisible:boolean = false;
  loyaltyUserDeatil:any
  constructor(
    private appStateService: AppStateService,
    private route: ActivatedRoute,
    private userService: UserService,
    public toastrService: ToastrService,
    private router: Router,
    public cart: CartService,
    private modalService: BsModalService,
    private cookieService: CookieService,
    private rootService: RootService,
    private formBuilder: FormBuilder,
    private userCart: UserCartService,
    private authService: AuthService,
    public modelService: ModelService,
    private productService: ProductService,
    private localstorage: secureStorage,
    private googleAnlyatics: GoogleAnalyticsService,) {
    this.showSearchButton = true;
    this.searchForm = this.formBuilder.group({
      searchBy: ['description'],
      search: [''],
      category_type: ['mhv'],
    });
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.showSearchdiv = '';
      }
    });
  }
  ngOnInit(): void {
    this.subInterval = interval(3000).subscribe(
      (val) => {
        this.edukaanTimelineData = JSON.parse(this.localstorage.getItem('edukaanTImelineData'));
      });
    this.showdiv = 'fd';
    this.appStateService.isLoggedIn.subscribe((isLog) => {
      this.isKeyClockUser = this.localstorage.getItem("isKeyClockUser") === "true" ? true : false;
      this.isLoggedIn = isLog;
      this.userDetails = JSON.parse(this.localstorage.getItem('userData'));

      if (isLog) {
        // this.mintifyCredit();
        this.openLoyality()
      }
    });
    this.cart.allitems$
      .pipe(
        takeUntil(this.destroy$),
        map((cartItems) =>
          cartItems.map((cartItem) => {
            return {
              cartItem,
              quantity: cartItem.quantity,
              quantityControl: new FormControl(
                cartItem.quantity,
                Validators.required
              ),
            };
          })
        )
      )
      .subscribe((items) => {
        if (!this.isLoggedIn) {
          this.items = items;
        }

      });
    this.appStateService.refreshcartConfiguration.pipe(takeUntil(this.destroy$)).subscribe(countArray => {
      if (countArray?.length > 0) {
        this.postLoginCartLength = 0;
        for (var i = 0; i < countArray.length; i++) {
          this.postLoginCartLength += parseInt(countArray[i]?.cart_count);
        }
      }

    });
    this.appStateService.isorderHistoryProfilePermission.subscribe(
      (orderHistoryPermission) => {
        this.isorderHistoryProfilePermission = orderHistoryPermission;
        this.isorderHistoryProfilePermission = JSON.parse(
          this.localstorage.getItem('orderHistoryProfilePermission')
        );
      }
    );
    this.appStateService.postalCode.subscribe((postalCode) => {
      this.appStatePinCode = postalCode;

      this.pinCodeNumber =
        this.appStatePinCode !== null &&
          this.appStatePinCode !== undefined &&
          this.appStatePinCode !== '' &&
          this.appStatePinCode !== 'undefined'
          ? parseInt(this.appStatePinCode)
          : '';
    });
    this.pinCode =
      this.localstorage.getItem('Pincode') ||
      this.localstorage.getItem('postal_code');
    if (this.appStatePinCode) {
      this.pinCodeNumber =
        this.appStatePinCode !== null &&
          this.appStatePinCode !== undefined &&
          this.appStatePinCode !== '' &&
          this.appStatePinCode !== 'undefined'
          ? parseInt(JSON.parse(this.appStatePinCode))
          : '';
    } else {
      this.pinCodeNumber =
        this.pinCode !== null &&
          this.pinCode !== undefined &&
          this.pinCode !== '' &&
          this.pinCode !== 'undefined'
          ? parseInt(JSON.parse(this.pinCode))
          : '';
    }
    if (isNaN(this.pinCodeNumber)) {
      this.pinCodeNumber = '';
    }
    this.route.queryParams.subscribe((params) => {
      var isLogin = params['isLogin'];
      var isRegistration = params['isRegistration'];
      if (isLogin === 'true') {
        this.showHeader = true;
      } else if (isRegistration === 'true') {
        this.showHeader = true;
      }else if(params['isguestLogin'] === 'true' ){
        this.showHeader = true
      }else {
        this.setAddress = false;
        this.showHeader = false;
      }

    });
    this.appStateService.clearHeaderTopSearch.subscribe((clresponse) => {
      if (clresponse) {
        setTimeout(() => {
          this.clear();
          this.cancel();
        }, 1000);
      }
    });
    this.appStateService.refreshHeaderCart.pipe(takeUntil(this.destroy$)).subscribe(isItemUpdated => {
      if (isItemUpdated && this.localstorage.getItem("isCartDropdownOpened") === "true" && this.postLoginCartLength > 0) {
         this.appStateService.refreshHeaderCart.next(false);
      }

    });

    // awadhesh

    $(document).click(function (e) {

      this.isHeaderCartApiLoaded = false;
      //Check the element is none of the DIVS which we wants prevent.
      if (e.target.id == "cart-details") {
        //alert('cart');
        //Hide the content div which is visible now.
        $('.dropout-user-details').hide();
        $('.cart-item-wrapper').show();
        //this.displayCartItems(false);
        $('.cart-loyality-wrapper').hide();
      }
      else if (e.target.id == "user-details") {
        // alert('user');
        $('.cart-item-wrapper').hide();
        $('.dropout-user-details').show();
        $('.cart-loyality-wrapper').hide();
      }
      else if (e.target.id == "cart-loyality") {
        // alert('user');
        $('.cart-item-wrapper').hide();
        $('.dropout-user-details').hide();
        $('.cart-loyality-wrapper').show();
      }
      else {
        // alert('none');
        $('.dropout-user-details').hide();
        $('.cart-item-wrapper').hide();
        $('.cart-loyality-wrapper').hide();
      }

      if (e.target.id == "card-item-container1" || $(e.target).parents("#card-item-container1").length) {
        $('.cart-item-wrapper').show();
      }
      if (e.target.id == "card-item-container2" || $(e.target).parents("#card-item-container2").length) {
        $('.cart-item-wrapper').show();
      }
      if (e.target.id == "card-item-container3" || $(e.target).parents("#card-item-container3").length) {
        $('.cart-item-wrapper').show();
      }
      if (e.target.id == "card-item-container4" || $(e.target).parents("#card-item-container4").length) {
        $('.cart-item-wrapper').show();
      }
      if (e.target.id == "card-item-container5" || $(e.target).parents("#card-item-container5").length) {
        $('.cart-loyality-wrapper').show();
      }
    });

    this.appStateService.getHeaderRefreshObservable().subscribe(() => {
      // this.mintifyCredit();
      
    });
    
  }
  // ngAfterViewInit() {
  //   this.pointBalanceMethod();
  // }
  SelectAddress() {
    this.setAddress == true
      ? (this.setAddress = false)
      : this.setAddress == false
        ? (this.setAddress = true)
        : (this.setAddress = false);
  }
  setAddressChange() {
    this.setAddress == true
      ? (this.setAddress = false)
      : this.setAddress == false
        ? (this.setAddress = true)
        : (this.setAddress = false);
  }

  viewCart(type, isCheck) {
    try {
      let permission = isCheck == true ? this.userService.CheckPermission('btn_cart_checkout') : this.userService.CheckPermission('btn_cart');

      if (permission) {
        if (this.isLoggedIn) {
          let count = 0;
          let currentCartState = this.appStateService.currentCartValue;
          for (let i = 0; i < currentCartState.length; i++) {
            if (currentCartState[i]['cart_count'] > 0) {
              this.router.navigate(['cart/summary'], { queryParams: { type: i, data: currentCartState[i]['cart_count'] } });
              count++;
              return;
            }
          }
          if (count == 0) {
            this.router.navigate(['cart/summary'], { queryParams: { type: 0, data: 0 } });
          }
        }
        else {
          this.isCartCount = {};
          this.itemdata = [];
          this.cart.allitems$.forEach((item) => {
            this.itemdata.push(item);
          });
          this.cartTypeList = [];
          this.cartTypeList = JSON.parse(
            this.localstorage.getItem('getCartType')
          );
          for (var i = 0; i < this.cartTypeList.length; i++) {
            let carttypename = this.cartTypeList[i]['cart_type'];
            let itemcount = this.itemdata[0].filter(
              (alitems) => alitems.type === carttypename
            );
            this.isCartCount[i] = itemcount.length;
          }
          this.openCart();
          for (var j = 0; j < this.cartTypeList.length; j++) {
            if (this.isCartCount[j] > 0) {
              if (isCheck && !this.isLoggedIn) {
                // $('.cart-item-wrapper').toggle();
                // $('.dropout-user-details').hide();
                // $('.cart-item-wrapper').hide();
                this.router.navigate(['/account/login'], {
                  queryParams: { isLogin: true },
                });
              } else {
                // $('.cart-item-wrapper').toggle();
                // $('.dropout-user-details').hide();
                // $('.cart-item-wrapper').hide();
                this.router.navigate(['cart/summary'], {
                  queryParams: {
                    type: j,
                    data: this.isCartCount[j],
                  },
                });
                break;
              }
            }
          }
        }
        //this.cartDropdownToggle();
      } else {
        this.toastrService.error(blockPermissionMsg);
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'viewCart(type)',
        page_name: 'DropcartComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }
  bulkOrderRoute() {
    let permission = this.userService.CheckPermission('bnt_view_add_bulk_cart');
    if (permission) {
      this.openCart();   
      this.router.navigateByUrl('/account/addbulkcart');
    } else {
      this.toastrService.error(this.blockPermissionMsg);
    }
  }
  routeToHome() {
    this.router.navigate(['']);
  }
  logout(item) {
    try {
      if (item === 'Logout') {
        if (this.modalRef) {
          this.modalRef.hide();
          this.modalRef = '';
        } else {
          this.isLogout = true;
          this.modalRef = this.modalService.show(this.template, {
            class: 'modal-dialog-centered modal-md',
            backdrop: 'static',
          });
        }
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'logout(item)',
        page_name: 'MenuComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }
  logoutAPI(type) {
    this.userService.logout().subscribe(response => {
      if (response.success) {
        this.UserLogout(type);
      }
    })
  }
  UserLogout(type) {
    try {
      if (type === 'Logout') {
        this.modalRef.hide();
        this.appStateService.isLoggedIn.next(false);
        this.appStateService.userSubject.next(null);
        this.localstorage.setItem("isCartDropdownOpened", "false");
        this.localstorage.setItem("isProfileDropdownOpened", "false");
        localStorage.removeItem('getCartType');
        this.localstorage.setItem('AddressId', '0');
        this.localstorage.setItem('Pincode', '');

        localStorage.removeItem('orderHistoryMenuList');
        localStorage.removeItem('orderMenuList');
        localStorage.removeItem('profileLayoutMenu');
        localStorage.removeItem('isVehiclePermission');
        localStorage.removeItem('isProfilePermission');
        localStorage.removeItem('afterLoginMainHeader');
        localStorage.removeItem('orderHistoryProfilePermission');

        localStorage.removeItem('userData');
        localStorage.removeItem('user');
        localStorage.removeItem('address');
        localStorage.removeItem('type');
        localStorage.removeItem('wishlistItems');
        localStorage.removeItem('compareItems');

        localStorage.removeItem('isOtherUser');
        localStorage.removeItem('otheruser');
        localStorage.removeItem('OtherUserPermission');
        localStorage.removeItem('usertype');
        localStorage.removeItem('AccountData');
        localStorage.removeItem('PageList');
        localStorage.removeItem('pageHeader');
        localStorage.removeItem('OrderData');
        localStorage.removeItem('OrderType');
        localStorage.removeItem('Pincode');
        localStorage.removeItem('AddressId');
        localStorage.removeItem('ref_token');
        localStorage.removeItem('division_info');
        localStorage.removeItem('division_info1');
        localStorage.removeItem('SLVCart');
        localStorage.removeItem('MHVCart');
        localStorage.removeItem('editedAddress');
        localStorage.removeItem('userAccounts');
        localStorage.removeItem('selectcarttype');
        localStorage.removeItem('redirectUrl')
        localStorage.removeItem('loyaltyDetail')
        this.cookieService.removeAll();
        window.location.reload();
        this.cart.data.items = [];
        this.cart.data.allitems = [];
        this.cart.calc();
        
        if (!this.cookieService.getObject('access_token')) {
          this.showLoader = true;
          this.rootService.login().subscribe(
            (response) => {
              if (response['success'] === true) {
                this.expiredDate = new Date();
                let Minutes = response.data['expires_in'] * 1000; // convert 2 minutes to milliseconds
                var expirein = Minutes - Minutes / 10;
                let expiredate2 = new Date(
                  this.expiredDate.getTime() + expirein
                );
                this.cookieService.putObject(
                  'access_token',
                  response.data['access_token'],
                  { expires: expiredate2 }
                );
                this.cookieService.putObject(
                  'ref_token',
                  response.data['refresh_token']
                );
                this.localstorage.setItem(
                  'ref_token',
                  response.data['refresh_token']
                );
                this.localstorage.setItem('refreshTokenInProgress', 'false');
                this.router.navigateByUrl('');
                this.showLoader = false;
              } else {
                this.showLoader = false;
                this.router.navigateByUrl('');
              }
            }, (error) => {
              this.showLoader = false;
              this.router.navigateByUrl('');
            }
          );
        }
        this.router.navigateByUrl('');
      } else {
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'UserLogout(item, type)',
        page_name: 'MenuComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  alphanumbericOnly(event): boolean {
    this.searchtextWidth = (document.getElementById('header-paste-search').offsetWidth);
    var check = true;
    if (event.keyCode !== 13) {
      if (
        this.searchType === 'Chassis No. (e.g. 357164CQZ805447)' ||
        this.searchType === 'Registration No. (e.g. MH01LB1234)'
      ) {
        var inp = String.fromCharCode(event.keyCode);
        if (/[a-zA-Z0-9]/.test(inp)) {
          //  return true;
          check = true;
        } else {
          event.preventDefault();
          if (this.searchType === 'Registration No. (e.g. MH01LB1234)') {
            this.toastrService.error(
              'Special characters and space not allowed. Enter Registration No. MH01LB1234.'
            );
          }
          if (this.searchType === 'Chassis No. (e.g. 357164CQZ805447)') {
            this.toastrService.error(
              'Special characters and Spaces not allowed in Chassis number across.'
            );
          }
          //return false;
          check = false;
        }
      }
    }
    return check;
  }

  onPaste() {
    this.showdivAggregate = false;
    let str;
    if (
      this.searchType === 'Chassis No. (e.g. 357164CQZ805447)' ||
      this.searchType === 'Registration No. (e.g. MH01LB1234)'
    ) {
      str = this.searchForm.value.search
        .replace(/[^\w\s]/g, '') // Remove non word characters
        .trim() // Remove trailing and leadings spaces
        .replace(/\b\w/g, (s) => s); // Make the first letter of each word upper case
      //  str = this.searchForm.value.search.trim().replace(/\s/g, "");
      str = str.replace(/\s/g, '');
      //this.searchForm.reset();
      this.searchForm.patchValue({ search: str });
    } else {
      str = this.searchForm.value.search
        .replace(/[^\w\s]/g, '')
        .trim()
        .replace(/\b\w/g, (s) => s); // Make the first letter of each word upper case
      //this.searchForm.reset();
      this.searchForm.patchValue({ search: str });
    }

    return;
  }

  searchAutoSuggestData(sercdata) {
    this.autoSuggestList = []
    this.searchtext = sercdata;
    if (this.searchtext.length <= 0) {
      this.clear();
      this.isAutoSuggest = false
    }
    if (sercdata.length >= 3) {
      this.isAutoSuggest = true;
      this.getAutoSuggest(sercdata)
    }

  }
  emitText(value) {
    this.emittedText = value;
    this.startClicked(false, true)
  }
  stopClick(value) {
    this.emittedText = '';
    this.startClicked(false, false)
  }
  startClicked(val, emitText?) {
    this.searchtext = ''
    this.showNoRecord = false;
    this.showSearchdiv = '';
    this.emittedText = val ? '' : this.emittedText;
    this.speechData = val ? 'Listening...' : this.emittedText;
    this.startbtnClicked = val;
    if (emitText && emitText !== undefined) {
      this.onSubmitshowpopup(this.emittedText)
    }

  }
  onSubmitshowpopup(emitText?, searchTextVal?) {
    this.isAutoSuggest = false
    if (this.startbtnClicked && emitText == undefined) {
      return;
    }
    if (emitText !== '') {
      this.searchtext = this.speechData;
      this.searchAutoSuggest(this.searchtext);
    }
    else {
      this.emittedText = '';
      this.searchtext = this.searchForm.controls.search.value;
      this.speechData = this.searchtext;
      this.searchAutoSuggest(this.searchtext);
    }
  }

  onSubmit() {
    this.localstorage.setItem('previousUrl', this.router.url);
    this.showdiv = '';
    this.showSearchdiv = '';
    if (this.searchForm.value.search.trim() != '') {
      this.router.navigate(['/shop/productcards'], {
        queryParams: {
          searchBy: 'description',
          search: this.searchForm.value.search.trim(),
        },
      });

      this.searchForm.reset();
      setTimeout(() => {
        this.clear();
      }, 500);
    }
  }

  switchAccount() {
    if (!this.isSwitchAccountClicked) {
      this.isSwitchAccount = true;
      this.previousAccount = JSON.parse(this.localstorage.getItem('userData'))['account_id'];
      let isDelegateUser = JSON.parse(this.localstorage.getItem('userData'))['isDelegateUser'];
      let userRole = JSON.parse(this.localstorage.getItem('userData'))['userRole'];
      var requestBody = { user_type: JSON.parse(this.localstorage.getItem('userData')).user_type, offset: 0, limit: 30 };
      this.authService.getUserTypeList(requestBody).subscribe(response => {
        if (response.success) {
          this.isSwitchAccount = true;
          let responseArray: any[] = response.data;
          responseArray.forEach(x => {
            x.isDelegateUser = isDelegateUser;
            x.userRole = userRole;
          })
          this.localstorage.removeitems("userAccounts");
          this.localstorage.setItem("userAccounts", JSON.stringify(responseArray));
          this.userIds = responseArray;
          if (this.userIds.length === 1) {
            this.toastrService.error("Only 1 account is associated with your number");
          }
          else {
            this.selectUserModal = this.modalService.show(this.selectUser, { class: 'modal-dialog-centered modal-lg', backdrop: 'static' });
          }
        }
        else {
          if (response?.data?.msg) {
            this.toastrService.error(response?.data?.msg);
          }
        }
        this.isSwitchAccountClicked = false;
      }, error => {
        if (error?.error?.data?.msg) {
          this.toastrService.error(error?.error?.data?.msg);
        }
        this.isSwitchAccountClicked = false;
      });
    }
    else {
      return;
    }
  }

  closeUserPopUp() {
    this.isSwitchAccount = false;
    this.selectUserModal.hide();
  }

  setUser(event) {
    try {
      //this.instantAccount = JSON.parse(this.localstorage.getItem('userData'))['account_id'];
      if (this.isSetUserClicked) {
        return;
      }
      else {
        this.isSetUserClicked = true;
        this.instantAccount = event !== undefined && event !== null ? event : JSON.parse(this.localstorage.getItem('userData'));
        if (!this.instantAccount) {
          return this.toastrService.error('Please select user!');
        }
        this.userDetail = JSON.parse(this.localstorage.getItem('user'));
        let userData = JSON.parse(this.localstorage.getItem('userData'));
        if (userData && this.userDetail) {
          this.userData = this.userIds.filter((fuser) => fuser.account_pk === this.instantAccount.account_pk);
          if (this.userData && this.userData[0] && this.userData[0]['account_pk']) {
            this.authService.getAccountHealthCheck({"account_pk":this.userData[0]['account_pk']}).subscribe(
            res => {
              localStorage.removeItem('profileLayoutMenu');
              this.appStateService.isprofilelist.next(true);
              this.appStateService.isVehiclePermission.next(null);
              this.appStateService.isProfilePermission.next(null);
              localStorage.removeItem('orderHistoryPermission');
              localStorage.removeItem('orderInProcessPermission');
              localStorage.removeItem('cancelOrderPermission');
              localStorage.removeItem('isVehiclePermission');
              localStorage.removeItem('afterLoginMainHeader');
              localStorage.removeItem('Pincode');
              localStorage.removeItem('AddressId');
              localStorage.removeItem('editedAddress');
              localStorage.removeItem('address');
              localStorage.removeItem('billingAddress');
              localStorage.removeItem('DefaultState');
              this.localstorage.setItem('user', JSON.stringify(this.userDetail));
              this.localstorage.setItem('userData', JSON.stringify(this.userData[0]));
              this.toastrService.success('Switch Account Success.');
              this.BindPageList();
              this.appStateService.userSubject.next(this.userData);
              this.selectUserModal.hide();
              let currentUrl = this.router.url;
              if (currentUrl.includes('account/profile')) {
                this.reloadCurrentRoute(currentUrl);
                this.cart.cartConfiguration();
              }
              else {
                this.router.navigateByUrl(currentUrl).then(() => {
                  this.router.navigated = false;
                  this.router.navigate(['/account/profile']);
                });
                this.cart.cartConfiguration();
              }
            }, error => {
              localStorage.removeItem('profileLayoutMenu');
              this.appStateService.isprofilelist.next(true);
              this.appStateService.isVehiclePermission.next(null);
              this.appStateService.isProfilePermission.next(null);
              localStorage.removeItem('orderHistoryPermission');
              localStorage.removeItem('orderInProcessPermission');
              localStorage.removeItem('cancelOrderPermission');
              localStorage.removeItem('isVehiclePermission');
              localStorage.removeItem('afterLoginMainHeader');
              localStorage.removeItem('Pincode');
              localStorage.removeItem('AddressId');
              localStorage.removeItem('editedAddress');
              localStorage.removeItem('address');
              localStorage.removeItem('billingAddress');
              localStorage.removeItem('DefaultState');
              this.localstorage.setItem('user', JSON.stringify(this.userDetail));
              this.localstorage.setItem('userData', JSON.stringify(this.userData[0]));
              this.toastrService.success('Switch Account Success.');
              this.BindPageList();
              this.appStateService.userSubject.next(this.userData);
              this.selectUserModal.hide();
              let currentUrl = this.router.url;
              if (currentUrl.includes('account/profile')) {
                this.reloadCurrentRoute(currentUrl);
                this.cart.cartConfiguration();
              }
              else {
                this.router.navigateByUrl(currentUrl).then(() => {
                  this.router.navigated = false;
                  this.router.navigate(['/account/profile']);
                });
                this.cart.cartConfiguration();
              }
            });
          }
        }
        this.isSetUserClicked = false;
      }

    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'setUser()',
        page_name: 'MenuComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  pushCartData(userId) {
    try {
      this.mhvCart = JSON.parse(this.localstorage.getItem('cartItems'));
      if (this.mhvCart !== null && this.mhvCart.length > 0) {
        this.mhvCart.forEach((result) => {
          if (this.localstorage.getItem('getCartType')) {
            this.cartTypeList = JSON.parse(
              this.localstorage.getItem('getCartType')
            );
          }
          var Distributercarttypedata = this.cartTypeList.filter((alitems) =>
            alitems.distributor_categories.includes(
              result.product.distributor_category
            )
          );
          let cart_type_name = Distributercarttypedata[0]['cart_type'];
          let cartData = {};
          cartData = {
            part_number: result.product.part_number,
            quantity: result.total_quantity,
            cart_type: cart_type_name,
          };
          this.cartArr.push(cartData);
        });
      }
      if (this.cartArr.length > 0) {
        let order_lines = this.cartArr;
        let cartData = {};
        cartData = {
          account_pk: userId,
          cart_items: order_lines,
        };
        this.userCart.addToCart(cartData).subscribe((response) => {
          if (response.success === true) {
            localStorage.removeItem('cartItems');
            this.cart.cartConfiguration();
          }
        }, error => {
          this.cart.cartConfiguration();
        });
      } else {
        this.cart.cartConfiguration();
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'pushCartData(userId)',
        page_name: 'MenuComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  async BindPageList() {
    try {
      localStorage.removeItem('PageList');
      let user = JSON.parse(this.localstorage.getItem('userData'));
      if (user) {
        await this.authService.GetPageList({ account_pk: user.account_pk }).subscribe(
          (response) => {
            if (response.success === true) {
              let afterLoginMainHeader = ['HOME'];
              let layoutMenu = [
                // 'Set Password',
                'Account Management',
                'User Management',
                'User permission',
                'Bulk Order Upload',
              ];
              let ordersMenu = [
                'Active Order',
                'Order History',
                'Orders In-Process',
                'Cancel Order History',
                'Invoice Return',
              ];
              let orderHistoryMenu = ['E-Com Orders', 'CRM Orders', 'Invoice Payment','Pay By Loyalty'];

              var datas: any[] = response.data;
              //profile menu start
              let profilelist = datas.filter((obj) => {
                for (let i = 0; i <= layoutMenu.length; i++) {
                  if (obj.page_display_name == layoutMenu[i]) {
                    obj.page_url = '/' + obj.page_url;
                    obj.isPermission = true;
                    return obj;
                  } else if (obj.page_display_name == 'My Profile') {
                    obj.page_url = '/' + obj.page_url;
                    obj.isPermission = true;
                    this.localstorage.setItem('isProfilePermission', JSON.stringify(obj.isPermission));
                    this.appStateService.isProfilePermission.next(true);
                    return obj;
                  } else if (obj.page_display_name == 'Vehicle Management') {
                    obj.isPermission = true;
                    this.localstorage.setItem('isVehiclePermission', JSON.stringify(obj.isPermission));
                    this.appStateService.isVehiclePermission.next(true);
                  } else {
                  }
                }
              });
              this.localstorage.setItem('profileLayoutMenu', JSON.stringify(profilelist));
              this.appStateService.isprofilelist.next(true);
              //profile menu end
              //----------------------------//
              //orderhistory start//
              let orderHistoryMenuAllowed = datas.filter((obj) => {
                for (let i = 0; i <= orderHistoryMenu.length; i++) {
                  if (obj.page_display_name == orderHistoryMenu[i]) {
                    obj.page_url = '/' + obj.page_url;
                    obj.isPermission = true;
                    return obj;
                  }
                }
              });
              this.localstorage.setItem('orderHistoryMenuList', JSON.stringify(orderHistoryMenuAllowed));
              //orderhistory end//
              //----------------------------//
              //orders menu start
              let orderMenuList = datas.filter((obj) => {
                for (let i = 0; i <= ordersMenu.length; i++) {
                  if (obj.page_display_name == ordersMenu[i]) {
                    obj.page_url =
                      obj.page_display_name == 'Order History'
                        ? '/orders/ecom-orders'
                        : obj.page_url.includes('account')
                          ? '/' + obj.page_url.replace('account', 'orders')
                          : '/' + obj.page_url;
                    obj.page_display_name =
                      obj.page_display_name == 'Cancel Order History'
                        ? 'Cancel Order'
                        : obj.page_display_name;
                    obj.isPermission = true;
                    return obj;
                  }
                }
              });

              let sortedOrderMenu: any[] = [];
              orderMenuList.forEach((menu) => {
                if (menu.page_display_name === 'Active Order') {
                  sortedOrderMenu.splice(0, 0, menu);
                }
                if (menu.page_display_name === 'Order History') {
                  sortedOrderMenu.splice(1, 0, menu);
                }
                if (menu.page_display_name === 'Orders In-Process') {
                  sortedOrderMenu.splice(2, 0, menu);
                }
                if (menu.page_display_name === 'Cancel Order History' || menu.page_display_name === 'Cancel Order') {
                  sortedOrderMenu.splice(3, 0, menu);
                }
                if (menu.page_display_name === 'Invoice Return') {
                  sortedOrderMenu.splice(4, 0, menu);
                }
              });
              this.localstorage.setItem('orderMenuList', JSON.stringify(sortedOrderMenu));

              //orders menu close
              //----------------------------//
              //after login menu start
              let reportsPermission = datas.filter((obj) => {
                if (obj.page_display_name == 'Order Tracking') {
                  obj.page_url = '/' + obj.page_url;
                  obj.isPermission = true;
                  return obj;
                }
              });
              if (orderMenuList?.length >= 1) {
                afterLoginMainHeader.push('ORDER');
              }
              if (reportsPermission?.length >= 1) {
                afterLoginMainHeader.push('REPORTS');
              }
              afterLoginMainHeader.splice(1, 0, 'MY DASHBOARD');
              afterLoginMainHeader.push('MY VEHICLES');
              afterLoginMainHeader.push('MY OFFERS');
              // afterLoginMainHeader.push('MY CREDIT');
              this.localstorage.setItem('afterLoginMainHeader', JSON.stringify(afterLoginMainHeader));

              //after login menu close
              //----------------------------//
              //dropdown menu start
              // let changePasswordPermission = datas.filter((obj) => {
              //   if (obj.page_display_name == 'Set Password') {
              //     obj.isPermission = true;
              //     return obj;
              //   }
              // });
              let orderHistoryProfilePermission = datas.filter((obj) => {
                if (obj.page_display_name == 'Order History') {
                  obj.isPermission = true;
                  return obj;
                }
              });
              // let changePassBehaviour: boolean;
              let orderHistoryProfileBehaviour: boolean;
              // if (changePasswordPermission.length == 0) {
              //   changePassBehaviour = false;
              // } else {
              //   changePassBehaviour = true;
              // }
              if (orderHistoryProfilePermission.length == 0) {
                orderHistoryProfileBehaviour = false;
              } else {
                orderHistoryProfileBehaviour = true;
              }

              this.localstorage.setItem(
                'orderHistoryProfilePermission',
                JSON.stringify(orderHistoryProfileBehaviour)
              );
              this.appStateService.isorderHistoryProfilePermission.next(
                orderHistoryProfileBehaviour
              );
              //eta end
            } else {
              let setAllPermission = false;
              this.localstorage.setItem(
                'orderHistoryProfilePermission',
                JSON.stringify(setAllPermission)
              );
              this.localstorage.setItem('isProfilePermission', null);
              this.localstorage.setItem('isVehiclePermission', null);

              this.appStateService.isProfilePermission.next(null);
              this.appStateService.isVehiclePermission.next(null);
              this.localstorage.setItem(
                'profileLayoutMenu',
                JSON.stringify(setAllPermission)
              );
              this.localstorage.setItem(
                'orderMenuList',
                JSON.stringify(setAllPermission)
              );
              this.localstorage.setItem(
                'orderHistoryMenuList',
                JSON.stringify(setAllPermission)
              );
              let afterLoginMainHeader = ['HOME'];
              this.localstorage.setItem(
                'afterLoginMainHeader',
                JSON.stringify(afterLoginMainHeader)
              );
            }
            this.GetAddress(true);
          },
          (error) => {
            this.GetAddress(true);
          });
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'BindPageList()',
        page_name: 'MenuComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  onRefresh() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl(currentUrl).then(() => {
      this.router.navigated = false;
      this.router.navigate(['/account/profile']);
    });
  }

  async GetAddress(isAccountSwitched?) {
    try {
      let userData = JSON.parse(this.localstorage.getItem('userData'));
      if (userData) {
        const ListInput: GetAddressparameter = {} as GetAddressparameter;
        ListInput.account_pk = JSON.stringify(userData.account_pk);
        ListInput.offset = 0;
        ListInput.limit = 29;
        ListInput.state = '';
        ListInput.district = '';
        ListInput.city = '';
        if (ListInput) {
          await this.productService
            .getAddressList(ListInput)
            .subscribe((response) => {
              if (response.success === true) {
                var resData = response.data;
                this.billingAddress = response.selected_address['billing_details'];
                this.shippingAddress = response.selected_address['shipping_details'];
                this.Defaultaddress = resData.filter(
                  (Data) => Data.default === true
                );
                this.Defaultaddress[0].isDefault = this.Defaultaddress[0].default;
                // let address1 = response.data.default_address;
                var pincode = this.Defaultaddress[0].postal_code
                  ? parseInt(this.Defaultaddress[0].postal_code)
                  : '';
                var DefaultState = this.Defaultaddress[0].state;
                this.billingAddress
                ?this.localstorage.setItem('billingAddress', JSON.stringify({address: this.billingAddress, type:'new'}))
                :this.localstorage.setItem('billingAddress', JSON.stringify({ address: this.Defaultaddress[0], type: 'default' }))
                this.shippingAddress
                ?this.localstorage.setItem('address', JSON.stringify({address: this.shippingAddress, type:'new'}))
                :this.localstorage.setItem('address', JSON.stringify({ address: this.Defaultaddress[0], type: 'default' }))
                if(this.shippingAddress){
                  pincode = parseInt(this.shippingAddress.postal_code);
                  DefaultState = parseInt(this.shippingAddress.state);
                }
                this.appStateService.postalCode.next(pincode);
                this.localstorage.setItem('Pincode', JSON.stringify(pincode));
                this.localstorage.setItem('AddressId', '0');
                this.localstorage.setItem('DefaultState', DefaultState);
              } else {
                if (response?.data?.msg) {
                  return this.toastrService.error(response.data.msg);
                }
              }
              this.localstorage.setItem("isCashDiscountApplicable", JSON.stringify(false));
              this.appStateService.isLoggedIn.next(true);
              if (isAccountSwitched) {
                this.appStateService.accountSwitched.next(true);
              }
              this.pushCartData(userData.account_pk);
            },
              error => {
                if (isAccountSwitched) {
                  this.appStateService.accountSwitched.next(true);
                }
                this.localstorage.setItem("isCashDiscountApplicable", JSON.stringify(false));
                this.appStateService.isLoggedIn.next(true);
                this.pushCartData(userData.account_pk);
              });
        }
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'PageLoginComponent',
        method_name: 'GetAddress()',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }
  remove(item: CartItem): void {
    try {
      if (this.removedItems.includes(item)) {
        return;
      }
      // $('.cart-item-wrapper').toggle();

      this.showLoader = true;
      this.removedItems.push(item);
      this.cart.removeFromHeader(item).subscribe({
        complete: () => {
          this.removedItems = this.removedItems.filter(
            (eachItem) => eachItem !== item
          );
          if (this.isLoggedIn && this.postLoginCartLength > 0) {
            this.displayCartItems(true);
          }
          this.showLoader = false;
        },
      });
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'remove(item: CartItem)',
        page_name: 'DropcHeaderComponentartComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  CheckQuantity(event, i) {
    try {
      if (event == undefined || event == 0 || event == '' || isNaN(event)) {
        this.toastrService.error('Quantity field should not be empty or 0.');
        return;
      } else {
        this.showLoader = true;
        if (this.isLoggedIn) {
          this.responseItems[i].quantityControl.setValue(event);
          if(this.responseItems[i].product.resource_type == 'Pack' ){
            this.responseItems[i].quantityControl.setValue(event * this.responseItems[i]?.product?.moq); 
          }
        }
        else {
          this.items[i].quantityControl.setValue(event);
          if(this.items[i].cartItem.product.moq ){
            this.items[i].quantityControl.setValue(event * this.items[i].cartItem.product.moq); 
          }
        }
        this.update(i);
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'CheckQuantity(event, i)',
        page_name: 'PageCartComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error,
        inner_exception: '',
      });
    }
  }
  update(i?): void {
    try {
      if (this.isLoggedIn) {
        this.cart
          .updateFromCart(
            this.responseItems[i].product,
            this.responseItems[i].product.resource_type == 'Pack' ? this.responseItems[i].quantityControl.value/this.responseItems[i].product?.moq: this.responseItems[i].quantityControl.value,
            this.responseItems[i].type,this.responseItems[i].type,
            // this.items[item]['cart_type'],false
          )
          .subscribe({
            complete: () => {
              this.updating = false;
              setTimeout(()=>{
                this.openCart();
                this.showLoader = false;
              },1000)
            },
          });
      } else {
        this.updating = true;
        let data = this.items
          .filter((item) => item.quantityControl.value !== item.quantity)
          .map((item) => ({
            item: item.cartItem,
            quantity: item.quantityControl.value,
          }));
        this.showLoader = true;
        this.cart.update(data).subscribe({
          complete: () => {
            this.updating = false;
            this.showLoader = false;
          },
        });
      }

      // this.showMhcvButton = false;
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'update()',
        page_name: 'PageCartComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error,
        inner_exception: '',
      });
    }
  }
  ngOnDestroy(): void {
    this.clear();
    this.subInterval.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();

  }
  async searchAutoSuggest(val) {
    try {
      this.openSearchModal = true;
      this.isAutoSuggestApiLoaded = false;
      this.showLoader = true;
      this.showdiv = '';
      this.showSearchdiv = '';
      this.showdivModel = false;
      this.showdivAggregate = false;
      this.showdivMultiModel = false;
      this.allModelData = [];
      this.suggestdata = [];
      this.suggestPartNodata = [];
      if (val.length >= 3) {
        this.searchtext = val;
        this.foo = this.modelService
          .getAutoSuggest({ search_text: val, limit: 15 })
          .subscribe((response) => {
            this.responsesuggest = response;
            this.allModelData = [];
            this.suggestdata = [];
            this.suggestPartNodata = [];
            this.searchtext = val;
            this.searchForm.controls.search.patchValue(val);
            if (this.responsesuggest['success'] == true) {
              this.showSearchdiv = 'SearchData';


              //for part number search
              this.suggestdata = this.responsesuggest['data'];
              this.suggestPartNodata =
                this.responsesuggest['data']['search_ecom_parts'];
              if (this.suggestPartNodata['data'].length > 0) {
                this.showdiv = 'part_description';
              }

              let type, url, parameter;

              // For search_model_and_cat
              var search_model_and_catdata =
                this.responsesuggest['data']['search_model_and_cat']['data'];
              for (var i = 0; i < search_model_and_catdata.length; i++) {
                type =
                  this.responsesuggest['data']['search_model_and_cat'][
                  'parameter'
                  ];
                url =
                  this.responsesuggest['data']['search_model_and_cat']['url'];
                parameter =
                  this.responsesuggest['data']['search_model_and_cat'][
                  'parameter'
                  ];

                // base model filter
                var baseModelName = search_model_and_catdata[i]['base_model'];
                var checkbasemodel;
                if (baseModelName) {
                  checkbasemodel = this.allModelData.filter(
                    (model) => model.base_model === baseModelName
                  );
                  if (checkbasemodel?.length == 0) {
                    this.allModelData.push(
                      this.FormModelData(
                        search_model_and_catdata[i],
                        url,
                        parameter,
                        type,
                        false
                      )
                    );
                  }
                }
              }
              if (this.allModelData.length > 0) {
                this.allModelData = this.allModelData.sort((a, b) => {
                  if (a.model_cat < b.model_cat) return -1;
                  else if (a.model_cat > b.model_cat) return 1;
                  else return 0;
                });
                this.showdivMultiModel = true;
                this.showdivModel = false;
              } else {
                this.showdivMultiModel = false;
                // this.showdivModel =true;
              }

              // For search_chassis_num
              var search_chassis_numdata =
                this.responsesuggest['data']['search_chassis_num']['data'];
              for (var i = 0; i < search_chassis_numdata.length; i++) {
                type = this.responsesuggest['data']['search_chassis_num']['parameter'];
                url = this.responsesuggest['data']['search_chassis_num']['url'];
                parameter = this.responsesuggest['data']['search_chassis_num']['parameter'];
                // this.allModelData.push(this.FormModelData(search_chassis_numdata[i],url,parameter,type));
                // base model filter
                var baseModelName = search_chassis_numdata[i]['base_model'];
                var checkBaseModel
                if (baseModelName) {
                  checkBaseModel = this.allModelData.filter(model => model.base_model === baseModelName);
                  if (checkBaseModel?.length == 0) {
                    this.allModelData.push(
                      this.FormModelData(
                        search_chassis_numdata[i],
                        url,
                        parameter,
                        type,
                        true
                      )
                    );
                  }
                }
              }

              // For search_registration_num
              var search_registration_numdata =
                this.responsesuggest['data']['search_registration_num']['data'];
              for (var i = 0; i < search_registration_numdata.length; i++) {
                type =
                  this.responsesuggest['data']['search_registration_num'][
                  'parameter'
                  ];
                url =
                  this.responsesuggest['data']['search_registration_num'][
                  'url'
                  ];
                parameter =
                  this.responsesuggest['data']['search_registration_num'][
                  'parameter'
                  ];
                if (this.responsesuggest['data']['search_registration_num']['data'].length === 1) {
                  this.activeVCno = this.responsesuggest['data']['search_registration_num']['data'][0]['vc_no'];
                }
                // this.allModelData.push(this.FormModelData(search_registration_numdata[i],url,parameter,type));
                // base model filter
                var basemodelName = search_registration_numdata[i]['base_model'];
                var checkBaseModelData;
                if (basemodelName) {
                  checkBaseModelData = this.allModelData.filter(model => model.base_model === basemodelName);
                }
                if (checkBaseModelData?.length === 0) {
                  this.allModelData.push(
                    this.FormModelData(
                      search_registration_numdata[i],
                      url,
                      parameter,
                      type,
                      true
                    )
                  );
                }
              }

              if (search_chassis_numdata.length > 0) {
                this.GoogleAnalyticsUserDetails('chassis_Search_web', this.searchtext, 'chassisSearch');
              }
              else if (search_registration_numdata.length > 0) {
                this.GoogleAnalyticsUserDetails('reg_Search_web', this.searchtext, 'regSearch');
              }
              else if (this.suggestPartNodata.data.length > 0) {
                this.GoogleAnalyticsUserDetails('View_all_Parts_web', this.searchtext, 'viewAllParts');
              }
              else if (search_chassis_numdata.length == 0 && (search_registration_numdata.length == 0 && this.suggestPartNodata.data.length == 0)) {
                this.GoogleAnalyticsUserDetails('chassis_Search_Fail_web', this.searchtext, 'chassisSearchFail');
              }
              else if (search_registration_numdata.length == 0 && (search_chassis_numdata.length == 0 && this.suggestPartNodata.data.length == 0)) {
                this.GoogleAnalyticsUserDetails('reg_Search_Fail_web', this.searchtext, 'regSearchFail');
              }
              else if (this.suggestPartNodata.data.length == 0 && (search_chassis_numdata.length == 0 && search_registration_numdata.length == 0)) {
                this.GoogleAnalyticsUserDetails('View_all_Parts_web', this.searchtext, 'viewAllParts');
              }
              else {
              }


              if (this.allModelData.length > 0) {
                this.allModelData = this.allModelData.filter(
                  (v, i, a) =>
                    a.findIndex((t) => t.base_model === v.base_model) === i
                );
                this.allModelData = this.allModelData.sort((a, b) => {
                  if (a.base_model < b.base_model) return -1;
                  else if (a.base_model > b.base_model) return 1;
                  else return 0;
                });
                this.showdivMultiModel = true;
                this.showdivModel = false;
              } else {
                this.showdivMultiModel = false;

                // this.showdivModel =true;
              }
              // Single Model Response
              if (this.allModelData.length === 1 && this.allModelData[0]) {
                if (this.allModelData[0].typedata === "chassis_num") {
                  this.singleModelResponse = this.responsesuggest['data']['search_chassis_num']['data'][0];
                  this.showdivMultiModel = false;
                  this.showdivModel = true;
                  this.ShowModelAggregate(this.allModelData[0]);
                }
                else if (this.allModelData[0].typedata === "registration_num") {
                  this.singleModelResponse = this.responsesuggest['data']['search_registration_num']['data'][0];
                  this.showdivMultiModel = false;
                  this.showdivModel = true;
                  this.ShowModelAggregate(this.allModelData[0]);
                }
              }

              if (
                this.suggestPartNodata['data'].length === 0 &&
                this.allModelData.length == 0
              ) {
                this.showNoRecord = true;
              } else {
                this.showNoRecord = false;
              }
            } else {
              this.showSearchdiv = 'NoRecord';
              this.suggestdata = [];
              this.suggestPartNodata = [];
            }
            this.showLoader = false;
            this.isAutoSuggestApiLoaded = true;
          }, error => {
            this.showSearchdiv = 'NoRecord';
            this.suggestdata = [];
            this.suggestPartNodata = [];
            this.showLoader = false;
            this.isAutoSuggestApiLoaded = true;
          })
          .add(() => {
            this.foo = null;
          });
      } else {
        this.showSearchdiv = '';
        this.showLoader = false;
      }
    } catch (err) {
      this.showSearchdiv = '';
      this.showLoader = false;
    }
  }
  cancel() {
    if (this.foo != null) {
      this.foo.unsubscribe();
      // cancel the request
      this.modelService.stopsRequest.next();
    }
  }

  GotoPage(item: any, alldata: any) {
    this.activeModel = '';
    this.searchForm.patchValue({ search: '' });
    if (alldata['parameter'] === 'part_number') {
      this.showdiv = '';
      this.showSearchdiv = '';
      let currentUrl = this.router.url;
      if (currentUrl.includes('product-details')) {
        this.router.navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/shop/product-details'], { queryParams: { part_Number: item.part_number } }));
      }
      else {
        this.router.navigate(['/shop/product-details'], {
          queryParams: { part_Number: item.part_number },
        });
      }
    }
  }

  ShowModelAggregate(itemmodel: any) {
    this.activeModel = itemmodel.model_cat;
    this.activeBaseModel = itemmodel.base_model;
    this.activeVCno = itemmodel.vc_no != undefined && itemmodel.vc_no != null && itemmodel.vc_no != "" ? itemmodel.vc_no : "";
    this.isModelSelected = true;
    this.isAggregateApiLoaded = false;
    if (this.activeVCno != "") {
      this.getModelName(itemmodel.model_cat, itemmodel.base_model, this.activeVCno);
    }
    else {
      this.getModelName(itemmodel.model_cat, itemmodel.base_model, "");
    }
  }
  modelNameAgg: any;
  subModelNameAgg: any;
  async getModelName(model_cat, base_model_cat, activeVCno) {
    this.modelNameAgg = model_cat;
    this.subModelNameAgg = base_model_cat;
    const ListInput: Inputdata = {} as Inputdata;
    ListInput.offset = 0;
    // ListInput.limit = 12;
    ListInput.limit = 100;
    ListInput.dropdown_type = 'aggregate';
    ListInput.multi_base_model = [base_model_cat],
      ListInput.multi_vc_no = activeVCno != "" ? [activeVCno] : [],
      ListInput.multi_model_cat = [model_cat];
    ListInput.is_image_required = true;
    this.showLoader = true;
    let response = await this.modelService.getModelCategory(ListInput);
    if (response['success'] == true) {
      this.modelAggregateData = response['data']['Aggregates'];
      this.modelAggregateData = this.modelAggregateData.sort((a, b) => {
        if (a.aggregate < b.aggregate) return -1;
        else if (a.aggregate > b.aggregate) return 1;
        else return 0;
      });
      if (this.modelAggregateData.length > 0) {
        this.showdiv = null;
        this.showSearchdiv = 'SearchData';
        this.showdivAggregate = true;
      } else {
        this.showdivAggregate = false;
      }
      this.isAggregateApiLoaded = true;
      this.isModelSelected = false;
    } else {
      this.isAggregateApiLoaded = true;
      this.isModelSelected = false;
    }
    this.showLoader = false;
  }

  FormModelData(data, url, parameter, type, isChassisOrRegNo): AutoSuggestData {
    const objmodeldata: AutoSuggestData = {} as AutoSuggestData;
    objmodeldata.base_model = data['base_model'] ? data['base_model'] : '';
    objmodeldata.model_cat = data['model_cat'] ? data['model_cat'] : '';
    objmodeldata.mod_img_name = data['mod_img_name']
      ? data['mod_img_name']
      : '';
    if (isChassisOrRegNo) {
      objmodeldata.vc_no = data['vc_no'] ? data['vc_no'] : '';
    }
    else {
      objmodeldata.vc_no = '';
    }
    objmodeldata.chassis_num = data['chassis_num'] ? data['chassis_num'] : '';
    objmodeldata.registration_num = data['registration_num']
      ? data['registration_num']
      : '';
    objmodeldata.image_url = data['image_url'] ? data['image_url'] : '';
    objmodeldata.url = url;
    objmodeldata.parameter = parameter;
    objmodeldata.typedata = type;
    return objmodeldata;
  }

  ViewModel() {
    if (this.SearchModalRef) {
      this.SearchModalRef.hide();
    }
    this.SearchModalRef = this.modalService.show(this.SearchModalPopUp, {
      class: 'modal-dialog-centered modal-lg',
      backdrop: 'static',
    });
  }

  gotoModel(item, type) {
    this.activeModel = '';
    if (type === 'aggregate') {
      // this.clear();
      if (this.activeVCno != '' && this.activeVCno != null && this.activeVCno != '') {
        this.prodcardbreadcrumb = [];
        localStorage.removeItem('productcardBreadcrumb');
        this.prodcardbreadcrumb.push({
          label: this.subModelNameAgg + ' > ' + item,
          url: '/shop/productcards',
          params: {
            searchBy: item,
            aggtype: type,
            ModelName: this.modelNameAgg,
            subModelName: this.subModelNameAgg,
            headerSearchText: this.searchForm.value.search,
            vcNo: (this.activeVCno != '' && this.activeVCno != undefined && this.activeVCno != null) ? this.activeVCno : undefined
          },
        });
        this.localstorage.setItem('productcardBreadcrumb', JSON.stringify(this.prodcardbreadcrumb))
        this.router.navigate(['/shop/productcards'], {
          queryParams: {
            searchBy: item,
            aggtype: type,
            ModelName: this.modelNameAgg,
            subModelName: this.subModelNameAgg,
            headerSearchText: this.searchForm.value.search,
            vcNo: (this.activeVCno != '' && this.activeVCno != undefined && this.activeVCno != null) ? this.activeVCno : undefined
          },
        });
      }
      else {
        this.prodcardbreadcrumb = [];
        localStorage.removeItem('productcardBreadcrumb');
        this.prodcardbreadcrumb.push({
          label: this.subModelNameAgg + ' > ' + item,
          url: '/shop/productcards',
          params: {
            searchBy: item,
            aggtype: type,
            ModelName: this.modelNameAgg,
            subModelName: this.subModelNameAgg,
            headerSearchText: this.searchForm.value.search
          },
        });
        this.localstorage.setItem('productcardBreadcrumb', JSON.stringify(this.prodcardbreadcrumb))
        this.router.navigate(['/shop/productcards'], {
          queryParams: {
            searchBy: item,
            aggtype: type,
            ModelName: this.modelNameAgg,
            subModelName: this.subModelNameAgg,
            headerSearchText: this.searchForm.value.search
          },
        });
      }
      this.clear();
    } else if (type === 'Model') {
      if (this.SearchModalRef) {
        this.SearchModalRef.hide();
      }
      // this.clear();
      this.prodcardbreadcrumb = [];
      localStorage.removeItem('productcardBreadcrumb');

      if (item.vc_no != undefined && item.vc_no != null && item.vc_no != "") {
        this.prodcardbreadcrumb.push({
          label: item.base_model,
          url: '/shop/productcards',
          params: {
            vcNo: item.vc_no,
            type: type,
            ModelName: item.model_cat,
            baseModel: item.base_model,
            headerSearchText: this.searchForm.value.search
          },
        });
        this.localstorage.setItem('productcardBreadcrumb',
          JSON.stringify(this.prodcardbreadcrumb))
        this.router.navigate(['/shop/productcards'], {
          queryParams: { ModelName: item.model_cat, baseModel: item.base_model, type: type, vcNo: item.vc_no, headerSearchText: this.searchForm.value.search },
        });
        this.clear();
      }
      else {
        this.prodcardbreadcrumb.push({
          label: item.base_model,
          url: '/shop/productcards',
          params: {
            type: type,
            ModelName: item.model_cat,
            baseModel: item.base_model,
            headerSearchText: this.searchForm.value.search
          },
        });
        this.localstorage.setItem('productcardBreadcrumb',
          JSON.stringify(this.prodcardbreadcrumb))
        this.router.navigate(['/shop/productcards'], {
          queryParams: { ModelName: item.model_cat, baseModel: item.base_model, type: type, headerSearchText: this.searchForm.value.search },
        });
        this.clear();
      }
    }
  }
  GoTOAllModelPart(type, item) {
    this.clear();
    this.router.navigate(['/shop/productcards'], {
      queryParams: { searchBy: 'description', search: item },
    });
  }
  clear() {
    this.clearData = true;
    this.showRing = false;
    this.showSearchButton = true;
    this.activeVCno = '';
    this.activeBaseModel = undefined;
    this.activeModel = '';
    this.searchtext = '';
    this.openSearchModal = false;
    this.searchForm.patchValue({ search: '' });
    this.showdiv = '';
    this.showSearchdiv = '';
    this.isAutoSuggest = false
  }

  viewAll(item, type) {
    this.prodcardbreadcrumb = [];
    localStorage.removeItem('productcardBreadcrumb');
    if (type == 'POPULAR MODELS') {
      if (this.activeVCno != "" && this.activeVCno != undefined && this.activeVCno != null) {
        this.prodcardbreadcrumb.push({
          label: this.subModelNameAgg + ' > ' + item,
          url: '/shop/productcards',
          params: {
            ModelName: this.activeModel,
            type: 'Model',
            baseModel: this.activeBaseModel,
            vcNo: (this.activeVCno != '' && this.activeVCno != null && this.activeVCno != undefined) ? this.activeVCno : undefined,
            headerSearchText: this.searchForm.value.search
          },
        });
        this.localstorage.setItem('productcardBreadcrumb', JSON.stringify(this.prodcardbreadcrumb))
        this.router.navigate(['/shop/productcards'], {
          queryParams: { ModelName: this.activeModel, type: 'Model', baseModel: this.activeBaseModel, vcNo: (this.activeVCno != '' && this.activeVCno != null && this.activeVCno != undefined) ? this.activeVCno : undefined, headerSearchText: this.searchForm.value.search },
        });
        this.clear();
      }
      else {
        this.prodcardbreadcrumb.push({
          label: this.subModelNameAgg + ' > ' + item,
          url: '/shop/productcards',
          params: {
            ModelName: this.activeModel,
            baseModel: this.activeBaseModel,
            type: 'Model',
            headerSearchText: this.searchForm.value.search
          },
        });
        this.localstorage.setItem('productcardBreadcrumb', JSON.stringify(this.prodcardbreadcrumb))
        this.router.navigate(['/shop/productcards'], {
          queryParams: { ModelName: this.activeModel, baseModel: this.activeBaseModel, type: 'Model', headerSearchText: this.searchForm.value.search },
        });
        this.clear();
      }
    }
    if (type == 'PARTS CATEGORIES') {
      this.prodcardbreadcrumb.push({
        label: this.subModelNameAgg + ' > ' + item,
        url: '/shop/productcards',
        params: {
          searchBy: item.line_2,
          type: type,
          headerSearchText: this.searchForm.value.search
        },
      });
      this.localstorage.setItem('productcardBreadcrumb', JSON.stringify(this.prodcardbreadcrumb))
      this.router.navigate(['/shop/productcards'], {
        queryParams: { searchBy: item.line_2, type: type, headerSearchText: this.searchForm.value.search },
      });
      this.clear();
    }
  }

  reloadCurrentRoute(currentUrl) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
  GoogleAnalyticsUserDetails(eventName, Part_Viewed, funcname) {
    var MobileNo = null;
    if (
      this.localstorage.getItem('userData') != null ||
      this.localstorage.getItem('userData') != undefined
    ) {
      let userData = JSON.parse(
        this.localstorage.getItem('userData')
      );
      MobileNo = userData.contact_no || userData.phone_number;
    }
    var Address = '';
    if (
      this.localstorage.getItem('address') != null ||
      this.localstorage.getItem('address') != undefined
    ) {
      let address = JSON.parse(this.localstorage.getItem('address'));
      Address =
        address.address.street_address1 +
        ', ' +
        address.address.state +
        ', ' +
        address.address.district +
        ', ' +
        address.address.city +
        ', ' +
        address.address.postal_code;
    }
    try {
      this.googleAnlyatics[funcname](
        eventName,
        Address,
        Part_Viewed,
        MobileNo
      );
    } catch (error) { }
  }

  openCart() {
    if (this.isCartClicked) {
      return;
    }
    else {
      this.isCartClicked = true;
      this.iscartopen = true;
      this.cart.isCartOpenobs.next(this.iscartopen);
      if (this.isLoggedIn) {
        if (this.postLoginCartLength > 0) {
          this.isHeaderCartApiLoaded = false;
          this.responseItems = [];
          this.displayCartItems(false);
        }
        else {
          if (this.postLoginCartLength === 0) {
            this.isHeaderCartApiLoaded = true;
            this.responseItems = [];
            this.isNoItemsPresent = true;
          }
          this.isCartClicked = false;
        }
      }
      else {
        this.isCartClicked = false;
      }
    }
  }

  openCartForAwdhesh() {
    // $('.cart-item-wrapper').toggle();
    if (this.isLoggedIn) {
      this.isHeaderCartApiLoaded = false;
      this.responseItems = [];
      this.displayCartItems(false);
    }
  }
  closeCart() {
    $(document).on('click', function (e) {
      if ($(e.target).closest(".cart-item-wrapper").length === 0) {
        $(".cart-item-wrapper").hide();
      }
    });
  }

  openUserOptions() {
    if (this.localstorage.getItem("isCartDropdownOpened") === "true") {
      // $('.cart-item-wrapper').toggle();
      this.responseItems = [];
      this.localstorage.setItem("isCartDropdownOpened", "false");
    }
    this.localstorage.setItem("isProfileDropdownOpened", "true")
    // $('.dropContainOption .dropOut').toggle();
  }

  displayCartItems(isItemRemoved) {
    this.isHeaderCartApiLoaded = false;
    let userData = JSON.parse(this.localstorage.getItem("userData"));
    let address = JSON.parse(this.localstorage.getItem('address'));
    let user = {
      account_pk: JSON.stringify(userData.account_pk),
      shipping_address_id: address.address['default'] ? "default" : address?.address['address_id'],
      is_pagination: true,
      offset: 0,
      limit: 6
    };
    //this.showLoader = true;
    this.responseItems = [];
    this.headerLoader = true;
    this.productService.get_cart_list_ms(user).subscribe(response => {
      localStorage.removeItem('division_info');
      response.division_info = {
        ORG_NAME:response.data?.cartitems[0]?.organization_name?response.data?.cartitems[0]?.organization_name:"",
        DIV_NAME:response.data?.cartitems[0]?.division_name?response.data?.cartitems[0]?.division_name:"",
        PRIMISE_ADDRLINE1_s:response.data?.cartitems[0]?.primise_addrline1?response.data?.cartitems[0]?.primise_addrline1:"",
        PRIMISE_ADDRESS2_s:response.data?.cartitems[0]?.primise_addrline2?response.data?.cartitems[0]?.primise_addrline2:"",
        DIV_ID:response.data?.cartitems[0]?.division_id?response.data?.cartitems[0]?.division_id:"",
        GSTIN:response.data?.cartitems[0].gstn?response.data?.cartitems[0]?.gstn:""
      }
      this.localstorage.setItem('division_info', JSON.stringify(response.division_info));
      if (response.success) {
        response.data.cartitems.forEach(result => {
          const img = [];
          img.push({
            img: result.part_image_path
          });
          let product = {
            product: {
              part_number: result.part_number,
              crm_mrp: result.crm_mrp,
              image: result.part_image_path,
              part_desc: result.short_description,
              id: result.id,
              siebel_umrp: result.siebel_umrp,
              cgst_sgst: result.cgst_sgst,
              rate: result.base_rate,
              total_amount: result.total_amount,
              total_quantity: result.total_quantity,
              uom: result.uom,
              unit_quantity: result.unit_quantity,
              part_minimum_quanity: result.min_quantity,
              moq : result.moq,
              resource_type: result.resource_type,
              min_quantity:result.min_quantity,
            },
            quantity: result.total_quantity,
            quantityControl: new FormControl(
              result.total_quantity,
              Validators.required
            ),
            type: result.cart_type
          };
          if (!this.responseItems.includes(product)) {
            this.responseItems.push(product);
          }
        });
        this.cart.data.items = [];
        this.cart.data.allitems = [];
        if (response.data.length > 0) {
          for (let i = 0; i < this.responseItems.length; i++) {
            if (!this.cart.data.items.includes(this.responseItems[i])) {
              this.cart.data.items.push(this.responseItems[i]);
            }
            if (!this.cart.data.allitems.includes(this.responseItems[i])) {
              this.cart.data.allitems.push(this.responseItems[i]);//dynamic Cart
            }
          }
          this.cart.calc();
        }
        if (!isItemRemoved) {
          // setTimeout(() => {
          //   $('.cart-item-wrapper').toggle();
          //   this.showLoader = false;
          //   this.localstorage.setItem("isCartDropdownOpened", "true");
          // }, 100);
        }
        else {
          this.showLoader = false;
          this.localstorage.setItem("isCartDropdownOpened", "false");
        }
      }
      else {
        if (response?.data?.msg) {
          if (response?.data?.msg === "Data not found") {
            this.responseItems = [];
            this.cart.data.items = [];
            this.cart.data.allitems = [];
            this.cart.calc();
            if (!isItemRemoved) {
              this.showLoader = false;
              this.isNoItemsPresent = true;
              this.localstorage.setItem("isCartDropdownOpened", "true");
            }
          }
          else {
            if (response?.data?.msg === "We are unable to process your request at this moment. Please try after sometime.") {
              $(".cart-item-wrapper").hide();
            }
            else {
              this.showLoader = false;
              this.toastrService.error(response?.data?.msg);
              $(".cart-item-wrapper").hide();
            }
          }
        }
      }
      this.isCartClicked = false;
      this.isHeaderCartApiLoaded = true;
      this.headerLoader = false;
    }, error => {
      this.isCartClicked = false;
      this.isHeaderCartApiLoaded = true;
      this.headerLoader = false;
      this.showLoader = false;
    })
  }

  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }
  closeCartForDirective(event) { }

  openVideoLink() {
    let requestBody = {
      multi_banner_type: ["PreloginHomeBannerVideo"],
      offset: 0,
      limit: 10,
      account_pk: this.isLoggedIn == true ? this.userData.account_pk : null
    }
    this.userService.getBannerImages(requestBody).subscribe(response => {
      if (response.success) {
        let banners: any[] = response.data;
        banners?.forEach(banner => {
          if (banner.banner_type === "PreloginHomeBannerVideo") {
            this.videoList.push(banner);
          }
        });
        if (this.videoList.length > 0) {
          this.videoPlayerModal = this.modalService.show(this.videoPlayer, { class: 'modal-dialog-centered modal-md', backdrop: 'static' });
        }
        else {
          this.toastrService.error("We are unable to load video, Please try again after sometime")
        }
      }
      else {
        if (response?.data?.msg) {
          this.toastrService.error(response.data.msg);
        }
      }
    })
  }

  onSuggetionClick(event) {
    this.searchForm.controls['search'].setValue(event)
    this.onSubmitshowpopup(event)
  }
  getAutoSuggest(sercdata) {
    try {
      let request = {
        "searchInput": sercdata,
        "action_type": "enterprise_search",
      }
      clearTimeout(this.timer)
      this.timer=setTimeout(() => {
        this.userService.getHeaderSearchInteligence(request).subscribe(
          (res) => {
            this.autoSuggestList = res.correctedSearchInput
            if ( this.autoSuggestList.length <= 0 ) {
              this.autoSuggestList = []
              this.isAutoSuggest = false
            }
          },
            (err) => {
            this.isAutoSuggest = false
          }
        );
      }, 300);
      
    }
    catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'viewCart(type)',
        page_name: 'DropcartComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }

  }
  mintifyCredit() {
    try {
      let req = {
        account_pk: JSON.parse(this.localstorage.getItem('userData')).account_pk,       
      }
      this.showLoader = true;
      this.userService.getMintifyCredit(req).subscribe(
        data => {
          if (data.success == true) {
            this.showLoader = false;
            this.mintifylimit = data.data;
            const availableLimit = (( this.mintifylimit.available_limit) / this.mintifylimit.loan_amount) * 100;
            this.mintifylimit.availableCredit = isNaN(availableLimit)?0:availableLimit
          } else {
            this.showLoader = false;
          }
        }, 
        (err) => {
          this.showLoader = false;
        }
      );
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'mintifyCredit(val)',
        page_name: 'MenuComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  openScannerModel(){
    this.openScannerUi = true; 
    this.scanModalservice = this.modalService.show(this.openScanner, {
      class: 'modal-dialog model-sm modal-dialog-centered',
      backdrop: 'static',
    });
  }

  // pointBalanceMethod(){
  //   let request = {
  //     "account_pk":this.userDetails.account_pk,
  //     // "account_pk":1499,

  //   }
  //   this.userService.loyalityLimitApi(request).subscribe((res) => {
  //     if(res && res.success){ 
  //       this.loyaltyBalance = Number(res.data.balance_points).toLocaleString();
  //       this.tierCategory = res.data.tier_category;
  //       this.loyaltyMemberId = res.data.membership_number
  //     }else{
  //     }
  //   }, 
  //   (err) => {
  //   })
  // }

//   getTooltipText():string  {
//     return `Loyalty Balance: ${this.loyaltyBalance ? this.loyaltyBalance : 0}\n${this.tierCategory ? 'Tier Category :'+ this.tierCategory : ''}`;
// }

openLoyality(){
  // $('.cart-loyality-wrapper').show();
  this.userDetails = JSON.parse(this.localstorage.getItem('userData'));
    let request = {
      "account_pk":this.userDetails.account_pk,
    }
    this.userService.loyalityLimitApi(request).subscribe((res) => {
      this.loyalityInfo = [];
      if(res && res.success){
          this.loyalityInfo = res.data;
          // if(this.localstorage.getItem('loyaltyDetail')){
          //   localStorage.removeItem('loyaltyDetail')
          // }
          this.localstorage.setItem('loyaltyDetail',JSON.stringify(this.loyalityInfo))
          this.isPopupVisible = true;
      }else{
        this.loyaltyUserDeatil = this.localstorage.getItem('loyaltyDetail') ? JSON.parse(this.localstorage.getItem('loyaltyDetail')) : '';
        if(this.loyaltyUserDeatil){
          localStorage.removeItem('loyaltyDetail')
        }
      }
    }, 
    (err) => {
    })
}

onRedirectLoyalty(){
  this.router.navigate(['/account/profile-loyality'])
  // $('.cart-loyality-wrapper').hide();
  this.isPopupVisible = false;
}
}
export class GetAddressparameter {
  account_pk: any;
  offset: any;
  limit: any;
  state: string;
  district: string;
  city: string;
}
export class AutoSuggestData {
  base_model: any;
  model_cat: any;
  mod_img_name: any;
  vc_no: any;
  chassis_num: any;
  registration_num: any;
  image_url: any;
  url: any;
  parameter: any;
  typedata: any;
}
interface Item {
  cartItem: CartItem;
  quantity: number;
  quantityControl: FormControl;
}