<div class="product-details-layout">
    <div class="container-fluid">
        <div class="box-prod-detail">
            <!-- <app-page-header  [breadcrumbs]="breadcrumbs"></app-page-header> -->
           
            <div class="row" style="margin: 1rem;margin-top: 2rem !important;">
                <a (click)="goToBack()" class="me-2" style="text-decoration: none; cursor: pointer;" *ngIf="!isfitment">
                    <i class="	fa fa-angle-left" style="margin-right: 0.5rem;">
                    </i>Go Back
                </a>
                <div *ngIf="isfitment" style="text-align: end;cursor: pointer;color: black;" (click)="goToBack()"><i class="fa fa-times"></i></div>
            </div>
            <div class="row">
                <div class="col-10">
                  <h5>Near by Garage (Around 50KM Based on {{pinCode}})</h5>  
                </div>
                <div class="col-2">
                    <a *ngIf="isfitment && userDetails?.user_type == 'FO' && mechanicDataList.length > 0" class="text-decoration-none mat-save cursor save-for-later-label text-nowrap" style="float: right;" (click)="openFitment('','All')">Service Request</a> 
                </div>
            </div>
            <div class="row form-check" style="text-align: end;">
                  <h5 class="error-message declaimer-related-prices">**Garage List coming from your delivery address pincode.</h5>
            </div>
            <!-- <div class="row">
                <div class="col-10">
                    <span>Select Garage Range</span>
                    <div class="radio-buttons mt-2">
                        <label *ngFor="let option of radioOptions" class="radio-label">
                          <input type="radio" [value]="option.label" [(ngModel)]="distanceBtn" (change)="onRangeChange(option.value)"> {{ option.label }}
                        </label>
                    </div>
                </div>
                <div class="col-2">
                    <a *ngIf="isfitment && userDetails?.user_type == 'FO'" class="text-decoration-none mat-save cursor save-for-later-label text-nowrap" style="float: right;" (click)="openFitment('','All')">Service Request</a> 
                </div>
            </div> -->
            <div class="row mt-2">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <fieldset class="mat-fieldset mb-3">
                        <div *ngIf="mechanicDataList.length > 0;else elseNoBandhuAvailable" class=" mainnewwidth row text-start">
                            <div id="cart-items" class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 no-print">
                                <div *ngFor="let mechanic of mechanicDataList; let i=index">
                                    <mat-card *ngIf="i<100">
                                        <div class="mat-flex-table">
                                            <div class="table-responsive" style="height: 100px;">
                                                <div class="mat-flex-table-body-cell">
                                                    <div class="mat-cart-prod-item d-flex align-items-center">
                                                        <div class="mat-prod-details" >
                                                            <p class="mat-prod-modal-id fs-6 align-content-with-space" style="margin-bottom: 4px;">
                                                                {{mechanic?.mechanic_name}}
                                                            </p>
                                                            <p class="mat-prod-name" style="margin-bottom: 4px;">
                                                                Distance : {{ mechanic?.distance}}
                                                            </p>
                                                            <p class="mat-prod-name" style="margin-bottom: 4px;">
                                                                Duration : {{ mechanic?.duration}}
                                                            </p>
                                                            <!-- <p class="mat-prod-name">
                                                                {{ mechanic?.address}}
                                                            </p> -->
                                                            <!-- <p class="mat-hsn-name text-nowrap">
                                                                HSN Code:&nbsp;&nbsp;<b>:  2710.19.90</b>
                                                            </p> -->
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="mat-table-cell-footer  align-items-center">
                                                    <a class="text-decoration-none mat-save cursor save-for-later-label text-nowrap" (click)="getMechanicDetails(mechanic)">View Details</a> 
                                                    <a *ngIf="isfitment && userDetails?.user_type == 'FO'" class="text-decoration-none mat-save cursor save-for-later-label text-nowrap" style="float: right;" (click)="openFitment(mechanic,'select')">Service Request</a> 
                                                </div>
                                            </div>
                                        </div>
                                    </mat-card>
                                </div>
                            </div>
                            <div id="map" class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8 mb-3" *ngIf="!showLoader">
                                <agm-map [ngStyle]="{height: '450px'}" [zoom]="zoom" [latitude]="mapCenter.lat" [longitude]="mapCenter.lng">
                                    <agm-marker *ngFor="let data of mylocations; let i = index" [latitude]="data.lat" [longitude]="data.lng" [label]="" (markerClick)="clickedMarker(infoWindow)">
                                        <!-- (markerClick)="clickedMarker(infoWindow)" -->
                                        <agm-info-window [disableAutoPan]="false" #infoWindow>
                                            <div>
                                                <a><strong>Name:</strong>{{data.Name}} </a> <br />
                                                <!-- <a><strong>Available Quantity:</strong>{{data.Quantity}} </a><br /> -->
                                                <a><strong>Latitude:</strong>{{data.lat}} </a><br />
                                                <a><strong>Longitude:</strong>{{data.lng}} </a><br />
                                                <a><strong>Distance:</strong>{{data.Range }} </a><br />
                                
                                                <a style=" text-decoration-line: underline; color: blue; cursor: pointer;" routerLinkActive="active"
                                                routerLinkActive="active" (click)="getMechanicDetailsFromMpView(data)">View Details</a>
                                            </div>
                        
                                      </agm-info-window>
                        
                                    </agm-marker>
                                  </agm-map>
                                <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d15085.110395879636!2d72.88698149799527!3d19.051528652120712!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1shospital!5e0!3m2!1sen!2sin!4v1639832754824!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe> -->
                            </div>
                        </div>
                        <ng-template #elseNoBandhuAvailable>
                            <div *ngIf="!showLoader" class="empty-box" style="margin-top: 10%;">
                                <img width="100" height="100" src="assets/icons/gurulist-active-icon.svg" />
                                <p class="text-center">No Bandhu Available</p>
                            </div>
                        </ng-template>
                    </fieldset>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #mechanicDetail let-d="dismiss">
    <mechanic-details [mechanicDetails]="mechanicDetails"  (closePopUp)="mechanicDetailsModal.hide();"></mechanic-details>
</ng-template>

<div class="loader" *ngIf="showLoader">
    <div class="ng-spinner ng-spinner-icon"></div>
</div>

<ng-template #fitment let-d="dismiss">
    <fitment-segment [mechanicData]="mechanicData" (closemodal)="fitmentModal.hide();" [orderInfo]="orderInfo" [otcNo]="otc_Number" [openType]="openType"></fitment-segment>
  </ng-template>