<div class="container-fluid report" *ngIf="showActiveOrderList">
    <div class="row">
        <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 pb-3 pt-4 mobile-m-top ">
            <ul class="nav nav-tabs tabs1" id="myTab" role="tablist">
                <li class="nav-item" role="presentation" [routerLink]="['/account/report']">
                    <a class="nav-link active" id="order-tab" data-bs-toggle="tab" href="#order" role="tab"
                        aria-controls="order" aria-selected="true">Order Tracking</a>
                </li>
                <li class="nav-item" role="presentation" [routerLink]="['/account/etareport']">
                    <a class="nav-link" id="order-tab" data-bs-toggle="tab" href="#order" role="tab"
                        aria-controls="order" aria-selected="true">ETA Reports</a>
                </li>
                <li class="nav-item" role="presentation" [routerLink]="['/account/servicerequestreport']" *ngIf="userDetails?.user_type == 'FO'">
                    <a class="nav-link" id="order-tab" data-bs-toggle="tab" href="#order" role="tab"
                        aria-controls="order" aria-selected="true">Service Request</a>
                </li>
                <li class="nav-item ms-auto d-flex" style="align-items: center;cursor: pointer;" role="presentation">
                    <button class="mat-icon-button mat-accent" matTooltip="Export to Excel" (click)="downloadExcel();">
                        <span class="mat-button-wrapper">
                            <i class="fa fa-file-excel fa-lg"></i>
                        </span>
                    </button>
                    <!-- Export
                    <select
            id="selectexceloptionreport" class="export-format" style="cursor: pointer;" (change)="downloadExcel($event);">
                        <option style="cursor: pointer;" value ="Select">Select</option>
                        <option style="cursor: pointer;" value ="Excel">Excel</option>
                    </select> -->
                </li>
            </ul>
            <hr class="hr-page-divider" />
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="order" role="tabpanel" aria-labelledby="order-tab">
                    <div class="row">
                        
                        <div class="col-12 col-sm-5 col-md-4 col-lg-4 col-xl-3 filter-are">
                            <h3 class="section-title d-flex align-items-center">Filters  <span class="ms-auto close-filter d-inline-flex d-lg-none" style="width: 20px;"><img alt="Close" src="./assets/images/close.svg" class="img-fluid hide-for-mobile-view" /> </span> </h3>
                            <div class="filter-wrapper">
                                <div class="ps-1 pe-1 pt-2 pb-2">
                                    <button type="button" class="btn btn-outline-primary"
                                        (click)="applyInprocessFilter('Global')">Search</button>&nbsp;
                                    <button type="button" class="btn btn-outline-primary" (click)="reset()">Reset</button>
                                </div>
                                <div class="ps-1 pe-1 pt-2 pb-2">
                                    <div class="form-group search position-relative mb-2">
                                        <input type="text" [formControl]="ordertrackingreportForm.controls['otc_number']"
                                            (keyup.enter)="applyInprocessFilter(ordertrackingreportForm.controls['otc_number'])"
                                            class="form-control" placeholder="Search by OTC No." />
                                        <span class="searchicon"><img alt="" src="./assets/images/search.svg" /></span>
                                    </div>
                                    <div class="form-group search position-relative mb-2">
                                        <input type="text"
                                            [formControl]="ordertrackingreportForm.controls['edukan_order_no']"
                                            (keyup.enter)="applyInprocessFilter(ordertrackingreportForm.controls['edukan_order_no'])"
                                            class="form-control" placeholder="Search by Order No." />
                                        <span class="searchicon"><img alt="" src="./assets/images/search.svg" /></span>
                                    </div>
                                    <div class="form-group search position-relative mb-2">
                                        <input type="text" [formControl]="ordertrackingreportForm.controls['part_number']"
                                            (keyup.enter)="applyInprocessFilter(ordertrackingreportForm.controls['part_number'])"
                                            class="form-control" placeholder="Search by Part No." />
                                        <span class="searchicon"><img alt="" src="./assets/images/search.svg" /></span>
                                    </div>
                                </div>
                                <div class="sub-title ps-1 pe-1">
                                    DURATION
                                    <!-- <span>Clear all <i class="fas fa-times" aria-hidden="true"></i></span> -->
                                </div>
                                <div class="ps-1 pe-1 pt-2 pb-2">
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item">
                                            <div class="row mt-2">
                                                <div class="col-6 position-relative">
                                                    <mat-form-field style="display: inline">
                                                        <input matInput [max]="today" placeholder="From Date" (click)="picker1?.open()" readonly
                                                            [formControl]="ordertrackingreportForm.controls['start']" [matDatepicker]="picker1">
                                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                                        <mat-datepicker #picker1 disabled="false"></mat-datepicker>
                                                    </mat-form-field>
                                                    <!-- <span class="cal-icon"><img alt=""
                                                            src="./assets/images/calender.svg" /></span> -->
                                                </div>
                                                <div class="col-6 position-relative">
                                                    <mat-form-field style="display: inline">
                                                        <input matInput [max]="today" placeholder="To Date" (click)="dp3?.open()" readonly
                                                            [formControl]="ordertrackingreportForm.controls['end']" [matDatepicker]="dp3">
                                                        <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                                                        <mat-datepicker #dp3 disabled="false"></mat-datepicker>
                                                    </mat-form-field>
                                                </div>
                                            </div>
    
                                        </li>
                                    </ul>
                                </div>
                                <div class="sub-title ps-1 pe-1">
                                    Distributor Name
                                    <!-- <span>Clear all <i class="fas fa-times" aria-hidden="true"></i></span> -->
                                    <!-- ps-3  -->
                                    <div class="pt-2 pb-2">
                                        <mat-form-field class="example-full-width" style="display: inline">
                                            <input type="text" placeholder="" aria-label="Number" matInput
                                                name="distributor_name" 
                                                [formControl]="ordertrackingreportForm.controls['distributor_name']"
                                                [matAutocomplete]="auto" (keyup)="_filter($event.target.value)"
                                                title=" {{txtdistributor_name}}">
                                            <mat-autocomplete #auto="matAutocomplete">
                                                <mat-option stymat-autocompletele="width: 100%;"
                                                    (onSelectionChange)="filterMyOptions(option.distributor_id)"
                                                    *ngFor="let option of filteredOptions " [value]="option.distributor_name"
                                                    [title]="option.distributor_name">
                                                    {{option.distributor_name}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="sub-title ps-1 pe-1">
                                    Locations
                                    <!-- <span>Clear all <i class="fas fa-times" aria-hidden="true"></i></span> -->
                                </div>
                                <div class="ps-1 pe-1 pt-2 pb-2">
                                    <mat-form-field class="example-full-width" style="display: inline">
                                        <mat-select placeholder="State"  (selectionChange)="Onselectedstate($event.value)" name="state" [formControl]="ordertrackingreportForm.controls['state']" title="{{txtstate}}">
                                            <mat-option *ngFor="let state of stateList" [value]="state"> {{state.name | titlecase}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <!-- <ul class="list-group list-group-flush">
                                        <li class="list-group-item">
                                            <input class="form-check-input me-1" id="label1" type="checkbox" value="">
                                            <label for="label1">All</label>
                                        </li>
                                        <li class="list-group-item">
                                            <input class="form-check-input me-1" id="label2" type="checkbox" value="">
                                            <label for="label2">Thane</label>
                                        </li>
                                        <li class="list-group-item">
                                            <input class="form-check-input me-1" id="label3" type="checkbox" value="">
                                            <label for="label3">Kalimboli</label>
                                        </li>
                                        <li class="list-group-item">
                                            <input class="form-check-input me-1" id="label4" type="checkbox" value="">
                                            <label for="label4">Andheri</label>
                                        </li>
                                    </ul>
                                    <a href="#" class="more">+ 32 more</a> -->
                                </div>
                                <div class="sub-title ps-1 pe-1">
                                    PART STATUS
                                    <!-- <span>Clear all <i class="fas fa-times" aria-hidden="true"></i></span> -->
                                </div>
                                <div class="ps-1 pe-1 pt-2 pb-2">
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item" *ngFor="let status of partStatusArray">
                                            <input class="form-check-input me-1" name="part_status" [checked]="status.isChecked" (change)="bindStatus($event,status.statusName,ordertrackingreportForm.controls['order_status'],'Part Status')" id="{{status.statusName}}" type="radio" value="{{status.statusName}}">
                                            <label for="{{status.statusName}}">{{status.statusName}}</label>
                                        </li>
                                        <!-- <li class="list-group-item">
                                            <input class="form-check-input me-1" id="chk5" type="checkbox" value="">
                                            <label for="chk5">Partially Invoiced</label>
                                        </li>
                                        <li class="list-group-item">
                                            <input class="form-check-input me-1" id="chk6" type="checkbox" value="">
                                            <label for="chk6">In-Process</label>
                                        </li>
                                        <li class="list-group-item">
                                            <input class="form-check-input me-1" id="chk6" type="checkbox" value="">
                                            <label for="chk6">New</label>
                                        </li>
                                        <li class="list-group-item">
                                            <input class="form-check-input me-1" id="chk7" type="checkbox" value="">
                                            <label for="chk7">Cancelled</label>
                                        </li> -->
                                    </ul>
                                </div>
                                <div class="sub-title ps-1 pe-1">
                                    ORDER STATUS
                                    <!-- <span>Clear all <i class="fas fa-times" aria-hidden="true"></i></span> -->
                                </div>
                                <div class="ps-1 pe-1 pt-2 pb-2">
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item" *ngFor="let status of orderStatusArray">
                                            <input class="form-check-input me-1" name="order_status" [checked]="status.isChecked" (change)="bindStatus($event,status.statusName,ordertrackingreportForm.controls['status'],'Order Status')" id="or_{{status.statusName}}" type="radio" value="{{status.statusName}}">
                                            <label for="or_{{status.statusName}}">{{status.statusName}}</label>
                                        </li>
                                        <!-- <li class="list-group-item">
                                            <input class="form-check-input me-1" id="chk5" type="checkbox" value="">
                                            <label for="chk5">Partially Invoiced</label>
                                        </li>
                                        <li class="list-group-item">
                                            <input class="form-check-input me-1" id="chk6" type="checkbox" value="">
                                            <label for="chk6">In-Process</label>
                                        </li>
                                        <li class="list-group-item">
                                            <input class="form-check-input me-1" id="chk6" type="checkbox" value="">
                                            <label for="chk6">New</label>
                                        </li>
                                        <li class="list-group-item">
                                            <input class="form-check-input me-1" id="chk7" type="checkbox" value="">
                                            <label for="chk7">Cancelled</label>
                                        </li> -->
                                    </ul>
                                </div>
                                <div class="sub-title ps-1 pe-1">
                                    <!-- MY FLEET MODELS -->
                                    <!-- <span>Clear all <i class="fas fa-times" aria-hidden="true"></i></span> -->
                                </div>
                                <div class="ps-1 pe-1 pt-2 pb-2">
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item">
                                            <!-- <input class="form-check-input me-1" id="chk1" type="checkbox" value=""> -->
                                            <!-- <label for="chk1">MY FLEET MODELS</label> -->
                                        </li>
                                        <li class="list-group-item">
                                            <!-- <input class="form-check-input me-1" id="chk2" type="checkbox" value=""> -->
                                            <!-- <label for="chk2">Tata Prima</label> -->
                                        </li>
                                        <li class="list-group-item">
                                            <!-- <input class="form-check-input me-1" id="chk3" type="checkbox" value=""> -->
                                            <!-- <label for="chk3">Tata Ultra</label> -->
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            

                            

                        </div>
                        <div class="col-12 col-sm-7 col-md-8 col-lg-8 col-xl-9 ">
                            <div class="card table-wrapper">
                                <div class="card-body p-0">
                                    <div class="table-responsive table-container">
                                        <table class="table datatable display" 
                                         datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                                            <thead>
                                                <tr>
                                                    <th class="sticky-col first-col" scope="col" style="width: 173.609px !important">OTC No.</th>
                                                    <th scope="col">Order No.</th>
                                                    <th scope="col" class="text-nowrap">Purchase Order No.</th>
                                                    <th scope="col" class="text-nowrap">Month-Year</th>
                                                    <th scope="col">State</th>
                                                    <th scope="col" style="max-width: 250px;">Distributor name</th>
                                                    <th scope="col" class="text-nowrap">Order Status</th>
                                                    <th scope="col" class="text-nowrap">Order Amount</th>
                                                    <th scope="col" class="text-nowrap">Coupon Code</th>
                                                    <th scope="col" class="text-nowrap">Coupon Amount</th>
                                                    <th scope="col" class="text-nowrap" style="text-align: center;">Order Date</th>
                                                    <th scope="col" style="text-align: center;">Part No.</th>
                                                    <th scope="col" style="text-align: center;">Part Description</th>
                                                    <th scope="col" class="text-nowrap">Part Status </th>
                                                    <th scope="col" class="text-nowrap">Order Quantity</th>
                                                    <th scope="col">Pending</th>
                                                    <th scope="col" class="text-nowrap">Shipped Quantity</th>
                                                    <th scope="col" class="text-nowrap">Invoiced but not dispatched</th>
                                                    <th scope="col" class="text-nowrap">Out For Delivery</th>
                                                    <th scope="col">Delivered</th>
                                                    <th scope="col">Cancelled</th>
                                                    <th scope="col" class="text-nowrap">Total Invoiced QTY</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let order of ordertrackingData">
                                                    <td class="tdmanage sticky-col first-col" (click)="details(order,'isReport');" style="width: 173.609px !important">
                                                        <a class="me-2">{{ order.otc_number }}</a>
                                                    </td>
                                                    <td class="tdmanage">{{ order?.edukan_orders }}</td>
                                                    <td class="tdmanage">{{ order?.po_no?order?.po_no:'NA' }}</td>
                                                    <td class="tdmanage">{{ order?.month_year }}</td>
                                                    <td>{{ order?.state}}</td>
                                                    <td style="max-width: 250px; word-wrap: break-word;">{{ order?.distributor_name }}</td>
                                                    <td style="text-align: center;">{{order?.status | titlecase }} </td>
                                                    <td style="text-align: center;">{{ order?.order_amount | currencyFormat:'INR'}}</td>
                                                    <td style="text-align: center;" class="tdmanage">{{ order?.coupon_code?order?.coupon_code:'NA' }}</td>
                                                    <td style="text-align: center;" class="tdmanage">{{ order?.flat_discount | currencyFormat:'INR' }}</td>
                                                    <td class="tdmanage text-nowrap">{{ order?.ord_date | date: 'dd-MM-yyyy h:mm:ss a' }}</td>
                                                    <td>{{ order?.part_number }}</td>
                                                    <td class="tdmanage">{{ order?.part_desc }}</td>
                                                    <td class="tdmanage">{{order?.order_status | titlecase }} </td>
                                                    <td>{{ order?.order_quantity }}</td>
                                                    <td>{{ order?.pending}}</td>
                                                    <td>{{ order?.shipped_quantity}}</td>
                                                    <td>{{ order?.invoiced_but_not_dispatched }}</td>
                                                    <td>{{ order?.out_for_delivery }}</td>
                                                    <td>{{order?.delivered }} </td>
                                                    <td>{{ order?.cancelled_order }}</td>
                                                    <td>{{ order?.total_invoiced_qty}}</td>
                                                </tr>                                             
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="Invoices" role="tabpanel" aria-labelledby="Invoices-tab">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3 filter-are">
                            <h3 class="section-title">Filters</h3>
                            <div class="sub-title ps-3">
                                DURATION
                                <span>Clear all <i class="fas fa-times" aria-hidden="true"></i></span>
                            </div>
                            <div class="ps-3 pt-2 pb-2">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item">
                                        <input class="form-check-input me-1" type="radio" value="" id="radio1">
                                        <label class="form-check-label" for="radio1">
                                            Last 7 days
                                        </label>
                                    </li>
                                    <li class="list-group-item">
                                        <input class="form-check-input me-1" type="radio" value="" id="radio2">
                                        <label class="form-check-label" for="radio2">
                                            Last 7 days
                                        </label>
                                    </li>
                                    <li class="list-group-item">
                                        <input class="form-check-input me-1" type="radio" value="" id="radio3">
                                        <label class="form-check-label" for="radio3">
                                            Custom
                                        </label>
                                    </li>
                                    <li class="list-group-item">
                                        <div class="row mt-2">
                                            <div class="col-6 position-relative">
                                                <input type="text" class="form-control date" placeholder="From Date" />
                                                <span class="cal-icon"><img alt=""
                                                        src="./assets/images/calender.svg" /></span>
                                            </div>
                                            <div class="col-6 position-relative">
                                                <input type="text" class="form-control date" placeholder="To Date" />
                                                <span class="cal-icon"><img alt=""
                                                        src="./assets/images/calender.svg" /></span>
                                            </div>
                                        </div>

                                    </li>
                                </ul>
                            </div>
                            <div class="sub-title ps-3">
                                Locations
                                <span>Clear all <i class="fas fa-times" aria-hidden="true"></i></span>
                            </div>
                            <div class="ps-3 pt-2 pb-2">
                                <div class="form-group search position-relative mb-2">
                                    <input type="text" class="form-control" placeholder="Search by Location....." />
                                    <span class="searchicon"><img alt="" src="./assets/images/search.svg" /></span>
                                </div>
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item">
                                        <input class="form-check-input me-1" id="label1" type="checkbox" value="">
                                        <label for="label1">All</label>
                                    </li>
                                    <li class="list-group-item">
                                        <input class="form-check-input me-1" id="label2" type="checkbox" value="">
                                        <label for="label2">Thane</label>
                                    </li>
                                    <li class="list-group-item">
                                        <input class="form-check-input me-1" id="label3" type="checkbox" value="">
                                        <label for="label3">Kalimboli</label>
                                    </li>
                                    <li class="list-group-item">
                                        <input class="form-check-input me-1" id="label4" type="checkbox" value="">
                                        <label for="label4">Andheri</label>
                                    </li>
                                </ul>
                                <a href="#" class="more">+ 32 more</a>
                            </div>

                            <div class="sub-title ps-3">
                                PAYMENT STATUS
                                <span>Clear all <i class="fas fa-times" aria-hidden="true"></i></span>
                            </div>
                            <div class="ps-3 pt-2 pb-2">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item">
                                        <input class="form-check-input me-1" id="chk1" type="checkbox" value="">
                                        <label for="chk1">All</label>
                                    </li>
                                    <li class="list-group-item">
                                        <input class="form-check-input me-1" id="chk2" type="checkbox" value="">
                                        <label for="chk2">Cleared</label>
                                    </li>
                                    <li class="list-group-item">
                                        <input class="form-check-input me-1" id="chk3" type="checkbox" value="">
                                        <label for="chk3">Uncleared</label>
                                    </li>
                                </ul>
                            </div>



                        </div>
                        <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9 ">
                            <div class="card table-wrapper">
                                <div class="card-body p-0">
                                    <div class="table-responsive table-container">
                                        <table class="table datatable display">
                                            <thead>
                                                <tr>
                                                    <th>Order #</th>
                                                    <th>Order Value (₹)</th>
                                                    <th>Order Status</th>
                                                    <th>Invoice #</th>
                                                    <th>Invoice Date</th>
                                                    <th>Invoice Age</th>
                                                    <th>Payment Mode</th>
                                                    <th>Payment Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="text-nowrap">41d5-4f6-c04f9355f004</td>
                                                    <td class="text-nowrap">20,058.00</td>
                                                    <td class="text-nowrap">Delivered</td>
                                                    <td>#ISuSS2021000832
                                                        <div class="delivered-desc">2 Products , 50 pieces</div>
                                                    </td>

                                                    <td>
                                                        21 Aug, 4 PM
                                                    </td>
                                                    <td>
                                                        22 days
                                                    </td>
                                                    <td>Credit</td>
                                                    <td>Cleared</td>
                                                </tr>
                                                <tr>
                                                    <td class="text-nowrap">41d5-4f6-c04f9355f004</td>
                                                    <td class="text-nowrap">20,058.00</td>
                                                    <td class="text-nowrap">Delivered</td>
                                                    <td>#ISuSS2021000832
                                                        <div class="delivered-desc">2 Products , 50 pieces</div>
                                                    </td>

                                                    <td>
                                                        21 Aug, 4 PM
                                                    </td>
                                                    <td>
                                                        22 days
                                                    </td>
                                                    <td>Credit</td>
                                                    <td>Cleared</td>
                                                </tr>
                                                <tr>
                                                    <td class="text-nowrap">41d5-4f6-c04f9355f004</td>
                                                    <td class="text-nowrap">20,058.00</td>
                                                    <td class="text-nowrap">Delivered</td>
                                                    <td>#ISuSS2021000832
                                                        <div class="delivered-desc">2 Products , 50 pieces</div>
                                                    </td>

                                                    <td>
                                                        21 Aug, 4 PM
                                                    </td>
                                                    <td>
                                                        22 days
                                                    </td>
                                                    <td>Credit</td>
                                                    <td>Cleared</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="Parts" role="tabpanel" aria-labelledby="Parts-tab">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3 filter-are">
                            <h3 class="section-title">Filters</h3>
                            <div class="sub-title ps-3">
                                DURATION
                                <span>Clear all <i class="fas fa-times" aria-hidden="true"></i></span>
                            </div>
                            <div class="ps-3 pt-2 pb-2">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item">
                                        <input class="form-check-input me-1" type="radio" value="" id="radio1">
                                        <label class="form-check-label" for="radio1">
                                            Last 7 days
                                        </label>
                                    </li>
                                    <li class="list-group-item">
                                        <input class="form-check-input me-1" type="radio" value="" id="radio2">
                                        <label class="form-check-label" for="radio2">
                                            Last 7 days
                                        </label>
                                    </li>
                                    <li class="list-group-item">
                                        <input class="form-check-input me-1" type="radio" value="" id="radio3">
                                        <label class="form-check-label" for="radio3">
                                            Custom
                                        </label>
                                    </li>
                                    <li class="list-group-item">
                                        <div class="row mt-2">
                                            <div class="col-6 position-relative">
                                                <input type="text" class="form-control date" placeholder="From Date" />
                                                <span class="cal-icon"><img alt=""
                                                        src="./assets/images/calender.svg" /></span>
                                            </div>
                                            <div class="col-6 position-relative">
                                                <input type="text" class="form-control date" placeholder="To Date" />
                                                <span class="cal-icon"><img alt=""
                                                        src="./assets/images/calender.svg" /></span>
                                            </div>
                                        </div>

                                    </li>
                                </ul>
                            </div>
                            <div class="sub-title ps-3">
                                Locations
                                <span>Clear all <i class="fas fa-times" aria-hidden="true"></i></span>
                            </div>
                            <div class="ps-3 pt-2 pb-2">
                                <div class="form-group search position-relative mb-2">
                                    <input type="text" class="form-control" placeholder="Search by Location....." />
                                    <span class="searchicon"><img alt="" src="./assets/images/search.svg" /></span>
                                </div>
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item">
                                        <input class="form-check-input me-1" id="label1" type="checkbox" value="">
                                        <label for="label1">All</label>
                                    </li>
                                    <li class="list-group-item">
                                        <input class="form-check-input me-1" id="label2" type="checkbox" value="">
                                        <label for="label2">Thane</label>
                                    </li>
                                    <li class="list-group-item">
                                        <input class="form-check-input me-1" id="label3" type="checkbox" value="">
                                        <label for="label3">Kalimboli</label>
                                    </li>
                                    <li class="list-group-item">
                                        <input class="form-check-input me-1" id="label4" type="checkbox" value="">
                                        <label for="label4">Andheri</label>
                                    </li>
                                </ul>
                                <a href="#" class="more">+ 32 more</a>
                            </div>

                            <div class="sub-title ps-3">
                                PAYMENT Order QTY
                                <span>Clear all <i class="fas fa-times" aria-hidden="true"></i></span>
                            </div>
                            <div class="ps-3 pt-2 pb-2">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item">
                                        <input class="form-check-input me-1" id="chk1" type="checkbox" value="">
                                        <label for="chk1">All</label>
                                    </li>
                                    <li class="list-group-item">
                                        <input class="form-check-input me-1" id="chk2" type="checkbox" value="">
                                        <label for="chk2">0-15</label>
                                    </li>
                                    <li class="list-group-item">
                                        <input class="form-check-input me-1" id="chk3" type="checkbox" value="">
                                        <label for="chk3">16-50</label>
                                    </li>
                                </ul>
                            </div>

                            <div class="sub-title ps-3">
                                Product Lines
                                <span>Clear all <i class="fas fa-times" aria-hidden="true"></i></span>
                            </div>
                            <div class="ps-3 pt-2 pb-2">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item">
                                        <input class="form-check-input me-1" id="chk8" type="checkbox" value="">
                                        <label for="chk8">All</label>
                                    </li>

                                </ul>
                            </div>


                        </div>
                        <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9 ">
                            <div class="card table-wrapper">
                                <div class="card-body p-0">
                                    <div class="table-responsive table-container">
                                        <table class="table datatable display">
                                            <thead>
                                                <tr>
                                                    <th>Part</th>
                                                    <th>Product Line</th>
                                                    <th>(Qty) Order</th>
                                                    <th>Order Frequency</th>
                                                    <th>(Qty) Inventory</th>
                                                    <th>Revenue Generated (₹)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="text-nowrap">
                                                        <div>#286309130191</div>
                                                        <div>Air Filter BS3</div>
                                                    </td>
                                                    <td class="text-nowrap">Product line 1</td>
                                                    <td class="text-nowrap">15,785</td>
                                                    <td>56 times </td>
                                                    <td class="text-orange">37</td>
                                                    <td class="text-end">9,45,450.00</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="filter-cta">
        <a href="javascript:void(0);" class="filter-btn" ></a>
    </div>
    <div class="loader" *ngIf="showLoader">
        <div class="ng-spinner ng-spinner-icon"></div>
    </div>
</div>
<div class="card mt-5 mb-3 border-0 shadow" *ngIf="!showActiveOrderList">
    <div class="access-restricted">
      Your access is restricted, please contact Organisation Manager...!
  </div>
  </div>
    <ng-template #orderDetails let-d="dismiss">
        <order-details [order]="orderInformations" [isReport]="isReport" [isActivePage]="isActivePage" (closemodal)="orderDetailsModal.hide();" [isReturn]="isReturn"
          [isOTCSelected]="isOTCSelected" [isFeedBackSelected]="isFeedBackSelected"></order-details>
      </ng-template>