import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
    selector: 'video-player',
    templateUrl: './video-player.component.html',
    styleUrls: ['./video-player.component.scss']
})

export class VideoPlayerComponent implements OnInit {
    @Input() videoList: any[] = [];
    @Output() closeVideoPlayer = new EventEmitter<any>();
    constructor() { }
    ngOnInit() {
        // console.log(this.videoList);
    }

    closeVideoPlayerModal(){
        this.closeVideoPlayer.emit();
    }

}