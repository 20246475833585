import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig, applicationConfiguration } from '../../../../../src/app/config/app.config';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModelService {
  protected server = this.appConfig.apiUrl + 'api/v1/ecom/';
  protected newServer = this.appConfig.apiUrl + 'api/v2/ecom/';
  // private options;
  readonly options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  public stopRequest: Subject<void> = new Subject<void>();
  constructor(
    private http: HttpClient,
    @Inject(applicationConfiguration) private appConfig: AppConfig
  ) { }

  groupByModel(data): Observable<any> {
    return this.http.post(`${this.server}search/groups_by_model/`, data, this.options);
  }

  subGroupByModel(data): Observable<any> {
    return this.http.post(`${this.server}search/sub_groups_by_model/`, data, this.options);
  }

  partListBySubGroup(data): Observable<any> {
    return this.http.post(`${this.server}search/parts_by_sub_group/`, data, this.options);
  }

  getAll(): Observable<any> {
    return this.http.post(`${this.appConfig.apiUrl}api/v1/ecom/get/list/model_category/`, this.options);
  }

  getAggregate(data): Observable<any> {
    return this.http.post(`${this.appConfig.apiUrl}api/v1/ecom/get/list/aggregate_list/`,data, this.options);
  }

  async getModelCategory(data) {
    return  await this.http.post(`${this.newServer}search/vehicle_dropdowns/`, data, this.options).toPromise().then(res => {  return res; });
  }

  getSubAggregates(requestBody): Observable<any>{
    return this.http.post(`${this.newServer}search/vehicle_dropdowns/`,requestBody, this.options);
  }
  
  async getBom(data) {
    return  await this.http.post(`${this.newServer}search/bom_uid_list/`, data, this.options).toPromise().then(res => {  return res; });
  }

  getBomUidList(requestBody): Observable<any>{
    return  this.http.post(`${this.newServer}search/bom_uid_list/`, requestBody, this.options);
  }
  
  // async getPartSearch(data) {
  //   return  await this.http.post(`${this.newServer}search/part_search/`, data, this.options).toPromise().then(res => {  return res; });
  // } 

  async getProductMenu(data) {
    return  await this.http.post(`${this.newServer}search/group_category_list/`, data, this.options).toPromise().then(res => {  return res; });
  }

  async getdataforhome(data) {
    return  await this.http.post(`${this.newServer}search/data_for_home/`, data, this.options).toPromise().then(res => {  return res; });
  }
  
  // async getAutoSuggest(data) {
  //   // return  await this.http.post(`${this.newServer}search/auto_suggest/`, data, this.options).toPromise().then(res => {  return res; });
  //   return await this.http.post(`${this.newServer}search/auto_suggest/`, data, this.options).pipe(takeUntil(this.stopRequest)).toPromise().then(res => {  return res; });
  // }
  public stopsRequest: Subject<void> = new Subject<void>();
  getAutoSuggest(data): Observable<any> {
   // return this.http.post(`${this.newServer}search/auto_suggest/`,data, this.options);
   return this.http.post(`${this.newServer}search/auto_suggest/`,data, this.options).pipe(takeUntil(this.stopsRequest));
  }

  // get_sub_aggregates(data):Observable<any> {
  //   return this.http.post(`${this.newServer}search/get_sub_aggregates/`, data,this.options);
  // }
  async get_sub_aggregates(data) {
    return  await this.http.post(`${this.newServer}search/get_sub_aggregates/`, data, this.options).toPromise().then(res => {  return res; });
  }

  async get_applicable_model_new(data) {
    return  await this.http.post(`${this.newServer}search/get_applicable_model/`, data, this.options).toPromise().then(res => {  return res; });
  }

  async get_sub_aggregates_new(data) {
    return  await this.http.post(`${this.newServer}search/get_sub_aggregates/`, data, this.options).toPromise().then(res => {  return res; });
  }

    // Multi seller api 

    get_part_search_md(data):Observable<any>{
      return this.http.post(`${this.newServer}search/part_search_md/`,data,this.options)
    }

    async getPartSearchMd(data) {
      return  await this.http.post(`${this.newServer}search/part_search_md/`, data, this.options).toPromise().then(res => {  return res; });
    } 
}
