import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output, OnChanges, SimpleChanges, ViewChild, TemplateRef, ElementRef
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { InputPartSearch, Product } from '../../interfaces/product';
import { AppStateService } from '../../services/app-state/app-state.service';
import { CartService } from '../../services/cart.service';
import { ModelService } from '../../services/REST/model.service';
import { ProductService } from '../../services/REST/product.service';
import { UserService } from '../../services/REST/user.service';
import { blockPermissionMsg } from 'src/app/constant';
import { secureStorage } from '../../services/securestorage';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
declare var $: any;

@Component({
  selector: 'app-product-detail-engine',
  templateUrl: './product-detail-engine.component.html',
  styleUrls: ['./product-detail-engine.component.scss'],
})
export class ProductDetailEngineComponent implements OnInit, AfterViewInit, OnChanges {
  form: FormGroup;
  // selectedOption: string = '';
  showDivPartdetails: boolean = false;
  showPartData: any;
  ProductListData: any;
  bomid: any;
  prodstart: any;
  prodend: any;
  showLoader: boolean = false;
  shippingaddressid: any;
  billingaddressid: any;
  address_pk: any;
  account_pk: any;
  selectvc: any;
  vcimage: any;
  storeCrouselname: any = [];
  mergeDisountArray: any = [];
  innerViewoffer: any = [];
  offercard: any[] = [];
  isSidebarOpen: boolean = false;
  ismarketbasketapicall:boolean = false;
  marketDetailsarr:any = [];
  marketbaskpercentage:any;
  marketbasketquantity:any;
  stireduplicatearra = [];
  compatibilityMessage: string;
  // selectedOption: string = 'Chassis Number';
  @Input() product: Product;
  @Input() pinCodeNumber: any;
  @Input() baseProductdiscount: any;
  @Input() modelVcData: any[] = [];
  @Input() vcPartData: any;
  @Input() divisionInfoData: any;
  @Input() showProduct: boolean;
  @Input() loyaltyDatapercentage:any;

  //showProduct - true - product detail page active 
  
  //showProduct - false - product engine page active 
  @Output() viewAddressModal = new EventEmitter<any>();
  @Output() addEngineCart = new EventEmitter<any>();
  @Output() ShowEnginemodel = new EventEmitter<any>();
  @Output() showviewAddress = new EventEmitter<any>();
  @Output() addEngineMBCart = new EventEmitter<any>();
  @Output() backToDetails = new EventEmitter<any>();
  @ViewChild('schemeList', { read: TemplateRef, static: false }) schemeList:TemplateRef<any>;
  @ViewChild('fullImagePopup', { read: TemplateRef, static: false }) fullImagePopup: TemplateRef<any>;

  Productdiscount: any;
  ProductMRP: any;
  isLoggedIn: boolean;
  currentQuantity: number = 1;
  partNumber: any;
  productList: any;
  quantity: any;
  cartTypeList: any;
  isQuantityDiscountAvailable: boolean = false;
  isModalOpen = false;
  customOptionsForRecentItems: OwlOptions = {
    items: 5,
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    autoWidth: false,
    navText: ["<div><img src='./assets/images/arrow-left.svg' /></div>", "<div><img src='./assets/images/arrow-right.svg' /></div>"],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 1.2
      }
    },
    nav: true
  }
  showQuantityDiscountMessage: boolean = false;
  quantityBasedPartDetails: any;
  quantityBasedDivisionDetails: any;
  quantityBasedPartPrice: number = 0;
  quantityBasedPercentage: number;
  discountOffers: any;
  normalPercentage: number =0;
  savedAmount: number;
  isNormalDiscount: boolean = false;
  
  showPopup: boolean = false;
  
  isWishlistedItem :any;
  baseProductdata:any;
  isshowMoreSlabDiscount:boolean =false;
  discountSlabOffer :any[]=[];
  popupOpenType:any;
  schemeListModal:any;
  discountOfferData:any=[]
  validSchemeList:any[]=[];
  partLimitCheck:any[]=[];
  newArrayforPartnumner:any = [];
  storeMbquantity:number = 1;
  displayToggleName:any;
  public isCollapsed: boolean = false;
  public isCollapsable: boolean = false;
  maxHeight:number = 50;
  uniqueListContact:any = [];
  marketBasketDescription:any;
  userData:any
  constructor(
    private productService: ProductService,
    private userService: UserService,
    private toasterService: ToastrService,
    private router: Router,
    private appStateService: AppStateService,
    public modelService: ModelService,
    public cart: CartService,
    private cd: ChangeDetectorRef,
    private localstorage: secureStorage,
    private modalService1: BsModalService,
    private elementRef: ElementRef,
    private fb: FormBuilder
  ) {
    this.appStateService.isLoggedIn.subscribe((isLog) => {
      this.isLoggedIn = isLog;
    });
    this.form = this.fb.group({
      selectedOption: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    // console.log("get_inoyrttt",this.product)
    this.appStateService.wishlistItemadded.subscribe((wishlistAdded) => {
      if(this.router.url.includes('product-details') && wishlistAdded){ 
      this.isWishlistedItem = true;
     }
  });
  this.userData = JSON.parse(this.localstorage.getItem('userData'));
    document.body.scrollTop = 0;
    window.scrollTo(0, 0);
    this.appStateService.isLoggedIn.subscribe((isLog) => {
      this.isLoggedIn = isLog;
    });
    // if (this.modelVcData) {
    //   this.vcimage = this.modelVcData[0]['ref_image'];
    //   // this.selectvc = this.modelVcData[0]['vc_no'];
    //   this.BindProduct(this.modelVcData[0]['bom_uid'], this.modelVcData[0]['vc_no'], 0);
    // }
    // this.loyaltyData =
    if (this.product['moq'] && this.product['resource_type'] == 'Pack') {
      this.Productdiscount =
        this.product['total_amount'] * parseInt(this.product['moq']);
      this.ProductMRP =
        this.product['crm_mrp'] * parseInt(this.product['moq']);
    } else {
      this.Productdiscount = this.product['total_amount'] * 1;
      this.ProductMRP = this.product['crm_mrp'] * 1;
    }
    if (this.Productdiscount > 0 && this.Productdiscount < this.ProductMRP) {
      this.isNormalDiscount = true;
    }
    else {
      this.isNormalDiscount = false;
    }
    setTimeout(() => {
      this.prodslider();
      this.aggregateSlider();
    }, 2000);
    this.appStateService.refreshDiscounts.subscribe(refreshDiscount => {
      if (refreshDiscount) {
        if (this.showProduct === false) {
          //setTimeout(() => {
          // this.getFreshProductDetails(true);
          this.appStateService.refreshDiscounts.next(false);
          //}, 1000);
        }
      }
    });
    this.normalPercentage = parseFloat((((this.product.crm_mrp - this.product.total_amount) / this.product.crm_mrp) * 100)?.toFixed(1));
    this.appStateService.postalCode.subscribe(postalCode => {
      if(postalCode !== ""){
    this.pinCodeNumber = postalCode ;
  //   this.getFreshProductDetails(true);
      } 
})
this.cart.isCartOpenobs.subscribe((res) => {
  if(res){
      this.isSidebarOpen = false;
  }else{

  }
})
  }

  openModal() {
    this.chosenItem = this.vehicleList[0].name;
    this.isModalOpen = true;
  }

  closeModal() {
    this.isModalOpen = false;
    this.typevalue = '';
    this.errormsg ='';
    this.compatibilityMessage = '';
    this.checkCompatible = null;
  }
  ngOnChanges(changes: SimpleChanges) {
    // if (changes?.modelVcData?.previousValue != changes?.modelVcData?.currentValue && changes?.modelVcData?.currentValue.length != 0 && changes?.modelVcData?.firstChange === false) {
    //  this.bindDataByVC();
    //   // setTimeout(() => {
    //   //   this.prodslider();
    //   //   this.aggregateSlider();
    //   // }, 2000);
    // }
    if (changes?.showProduct?.currentValue === true) {
      this.showProduct = true;
    }
    else {
      this.showProduct = false;
    }

    if (this.showProduct == false && this.isLoggedIn) {
      this.ProductListData = [];
      this.isProductEmpty = true;
      this.normalPercentage = parseFloat((((this.product.crm_mrp - this.product.total_amount) / this.product.crm_mrp) * 100)?.toFixed(1));
      // if(changes?.pinCodeNumber?.previousValue != changes?.pinCodeNumber?.currentValue && changes?.pinCodeNumber?.currentValue.length != 0 && changes?.pinCodeNumber?.firstChange === false ){
      this.getFreshProductDetails(true);   
      // }else{
      // this.getOfferDetails(this.product.part_number, this.divisionInfoData?.DIV_ID, false);
      // }
    }
    this.bindDataByVC();
  }

  bindDataByVC(){
    this.ProductListData = [];
    if (this.modelVcData.length > 0) {
      this.vcimage = this.modelVcData[0]['ref_image'];
      //this.selectvc = this.modelVcData[0]['vc_no'];
      // this.BindProduct(this.modelVcData[0]['bom_uid'], 0);
      this.BindProduct(this.modelVcData[0]['bom_uid'], this.modelVcData[0]['vc_no'], 0);
    }
  }
  readQuantity(event, product, isDiscountApplicable?) {
    product.customerQuantity = event;
    this.currentQuantity = event;
    if (this.discountOffers && isDiscountApplicable) {
      if (((product.resource_type == 'Pack') ? (this.discountOffers.quantity / product.moq ) : this.discountOffers.quantity)) {
        this.isQuantityDiscountAvailable = true;
        this.savedAmount = this.ProductMRP - this.quantityBasedPartPrice;
      }
      else {
        this.isQuantityDiscountAvailable = false;
        this.savedAmount = this.ProductMRP - this.Productdiscount;
      }
      if(this.discountSlabOffer.length > 0){
        if(event >= this.discountOffers?.quantity / product.moq){
            this.isshowMoreSlabDiscount = true
        }
        else{
            this.isshowMoreSlabDiscount = false
        }
    }

    if (this.isLoggedIn && JSON.parse(this.localstorage.getItem("Pincode"))){
      if (((product.resource_type == 'Pack') ? (this.discountOffers.quantity / product.moq ) : this.discountOffers.quantity)) {
      this.getDiscountedPriceDetails(product?.part_number,  (product['resource_type'] == 'Pack' ) ? this.currentQuantity * product['moq'] : this.currentQuantity)
      }
  }
    }
  }

  aggregateSlider() {
    $(function () {
      $('#aggregatelist1').owlCarousel('destroy');
      $('#aggregatelist1').owlCarousel({
        loop: false,
        nav: true,
        autoWidth: true,
        dots: false,
        navText: [
          "<img src='../../../../assets/images/arrow-left.svg'/>",
          "<img src='../../../../assets/images/arrow-right.svg'/>",
        ],
        autoplay: false,
        autoplayHoverPause: true,
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 3,
          },
          1000: {
            items: 5,
          },
        },
      });
      $('.owl-nav').removeClass('disabled');
      $('.owl-next, .owl-prev').on('click', function () {
        $('.owl-nav').removeClass('disabled');
      });
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.prodslider();
      this.aggregateSlider();
    }, 500);
    document.body.scrollTop = 0;
    $('.btn-part-popup').on('click', function () {
      $('.part-popup').css('display', 'block');
    });
    $('.close').on('click', function () {
      $('.part-popup').css('display', 'none');
    });
    $('#related-product').slick({
      dots: false,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '30px',
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '30px',
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '30px',
            slidesToShow: 1,
          },
        },
      ],
    });

    let currentHeight = this.elementRef.nativeElement.getElementsByTagName('div')[0].offsetHeight;
       //collapsable only if the contents make container exceed the max height
       console.log(currentHeight)
        if (currentHeight > this.maxHeight) {
            this.isCollapsed = true;
            this.isCollapsable = true;
        }
  }
  prodslider() {
    $('.s-slick-slider').not('.slick-initialized').slick({
      dots: true,
      slidesToShow: 1.1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
    });
    $('.slick-prev').addClass('custom-prev');
    $('.slick-next').addClass('custom-next');
  }

  ClosePart() {
    this.showDivPartdetails = false;
    this.showPartData = [];
  }

  tableoffset() {
    $('#tbl-part-details').on('click', 'td', function () {
      var topPosition = $(this).offset().top - $('table').offset().top;
      var popupHeight = $('.part-popup').height();
      var fromTop = topPosition - popupHeight / 2 + 30;
      $('.part-popup').css('top', fromTop);
    });
  }

 
  showparts(order) {
    $('#tbl-part-details').on('click', 'td', function () {
        var fromTop = $(this).position().top - 70;
        $('.part-popup').css('top', fromTop);
        var fromLeft = $(this).position().left - 380;
        $('.part-popup').css('left', fromLeft);
    });
    this.showPopup = true;
    this.showPartData = order;
  }
  closePopup(event) {
    if (event == 'Close') {
      this.showPopup = false;
    }
  }
  addtoCartitems(Item) {
    let permission = this.userService.CheckPermission(
      'btn_cart'
    );
    if (permission) {
      if (isNaN(Item.customerQuantity)) {
        this.toasterService.error('Quantity cannot be empty or 0');
        return;
      }
      else if (
        Item.customerQuantity !== null &&
        Item.customerQuantity > 0 &&
        Item.customerQuantity !== undefined
      ) {

        if (Item.customerQuantity < Item['min_quantity']) {
          this.toasterService.error('Quantity field cannot be less than minimum quantity');
          return;
        }

        else {

          this.showDivPartdetails = false;
          this.showPartData = [];
          $('#btn_' + Item.part_number).prop('disabled', true);


          if (Item['resource_type'] == 'Pack' ) {
            this.quantity == Item.customerQuantity
              ? Item.customerQuantity * parseInt(Item['moq'])
              : 1 * parseInt(Item['moq']);

            // this.quantity = parseInt(Item.customerQuantity) ? parseInt(Item.customerQuantity) * parseInt(Item['column_4']) : 1 * parseInt(Item['column_4'])
          } else {
            this.quantity = Item.customerQuantity ? Item.customerQuantity : 1;
            // this.quantity = parseInt(Item.customerQuantity) ? parseInt(Item.customerQuantity) : 1;
          }
          this.showLoader = true;
          if (this.localstorage.getItem('getCartType')) {
            this.cartTypeList = JSON.parse(this.localstorage.getItem('getCartType'));
          }
          var commoncarttypedata = this.cartTypeList.filter((alitems) =>
            alitems.common_distributor_categories.includes(
              Item['distributor_category']
            )
          );
          var Distributercarttypedata = this.cartTypeList.filter((alitems) =>
            alitems.distributor_categories.includes(Item['distributor_category'])
          );

          let cart_type_name = '';
          // cart_type_name = localStorage.getItem('selectcarttype')
          // this.cart.add(Item, this.quantity, Item['distributor_category'], cart_type_name).subscribe({
          //   complete: () => {

          //     $("#btn_" + Item.part_number).prop('disabled', false);
          //     this.cd.markForCheck();
          //   }
          // });

          if (commoncarttypedata.length > 0) {
            if (this.localstorage.getItem('selectcarttype')) {
              cart_type_name = this.localstorage.getItem('selectcarttype');

              this.cart
                .add(
                  Item,
                  this.quantity,
                  Item['distributor_category'],
                  cart_type_name
                  // ,false
                )
                .subscribe({
                  complete: () => {
                    $('#btn_' + Item.part_number).prop('disabled', false);
                    this.cd.markForCheck();
                    this.showLoader = false;
                  },
                });
              localStorage.removeItem('selectcarttype');
            }
            // else {
            //     cart_type_name = commoncarttypedata[0]["cart_type"]
            //     if (this.cartTypeList.length > 0) {
            //         this.selectcarttype = this.cartTypeList[0]["cart_type"]
            //         this.changeCartType(this.selectcarttype, product);
            //         this.btndisabled = false;
            //         this.addingToCart = false
            //         this.btnshowConfrimed = false;
            //         this.btnshowsubmit = true
            //         this.cartTypeModal = this.modalService1.show(this.template, { class: 'modal-dialog-centered modal-md', backdrop: 'static' });
            //     }
            // }
          } else if (Distributercarttypedata.length > 0) {
            cart_type_name = Distributercarttypedata[0]['cart_type'];
            this.cart
              .add(
                Item,
                this.quantity,
                Item['distributor_category'],
                cart_type_name
                // ,false
              )
              .subscribe({
                complete: () => {
                  $('#btn_' + Item.part_number).prop('disabled', false);
                  this.cd.markForCheck();
                },
              });
          }
        }
      }
      else {
        if (Item['resource_type'] == 'Pack' ) {
          if (Item.customerQuantity < Item['min_quantity']) {
            this.toasterService.error('Unit field should not be empty.');

            return;
          }
        } else if (Item.customerQuantity == 0) {

          this.toasterService.error('Quantity field cannot be 0 .Minimum quantity required.');
        } else {
          this.toasterService.error('Quantity field should not be empty.');
        }
      }
    } else {
      this.toasterService.error(blockPermissionMsg);
    }
    // else {

    //   this.toasterService.error(blockPermissionMsg);
    // }
  }
  // this.addEngineCart.emit(items);

  addtoCart(product: Product) {
    this.addEngineCart.emit(product);
  }
  partAddtombCart(data,index){
  
    let obj = {
      'data':data,
      'index':index,
      'tempQuantity':this.newArrayforPartnumner[index].tempQuantity,
      'part_number':this.newArrayforPartnumner[index].sku_item
    }
    this.addEngineMBCart.emit(obj)
  }
  viewAddress() {
    this.showviewAddress.emit();
  }
  isProductEmpty: boolean = false;
  async BindProduct(BomIdData: any, vcno, offset) {
    this.ProductListData = [];
    if (BomIdData) {
      this.selectvc = vcno;
      let user = JSON.parse(this.localstorage.getItem('userData'));
      let address = JSON.parse(this.localstorage.getItem('address'));
      if (user && address) {
        this.account_pk = +JSON.stringify(user.account_pk);
        var addresstypecheck = address?.address['default'];
        if (addresstypecheck === true) {
          this.shippingaddressid = 'default';
        } else {
          this.shippingaddressid = address?.address['address_id'];
        }
      }

      const ListPartInput: InputPartSearch = {} as InputPartSearch;
      ListPartInput.offset = offset;
      ListPartInput.limit = 100;
      ListPartInput.search_text = '';
      ListPartInput.bs_model_name = '';
      ListPartInput.pg_line = '';
      ListPartInput.multi_bs_model_name = [];
      ListPartInput.multi_dispgroup_name = [];
      ListPartInput.model_cat = '';
      ListPartInput.mod_uid = '';
      ListPartInput.category_type = '';
      ListPartInput.bom_id = BomIdData;
      ListPartInput.durafit_parts = false;
      ListPartInput.kit_parts = false;
      ListPartInput.part_number_last_four = '';
      ListPartInput.pl_id = '';
      ListPartInput.is_home = false;
      ListPartInput.part_number = '';
      ListPartInput.is_for_epc_data = true;
      ListPartInput.is_for_discount = this.isLoggedIn == true ? true : false;
      ListPartInput.is_for_ecom_detail = false;
      // ListPartInput.bom_id_list = BomIdData ? BomIdData : [];//result[i];
      ListPartInput.account_pk = this.isLoggedIn == true ? this.account_pk : '';
      ListPartInput.shipping_address_id =
        this.isLoggedIn == true ? this.shippingaddressid : '';
      ListPartInput.is_for_inventory = this.userData?.user_type == 'MECH' ? true: false;
      this.showLoader = true;
      this.isProductEmpty = false;
      this.ProductListData = [];
      let data = await this.modelService.getPartSearchMd(ListPartInput);
      if (data['success'] === true) {
        var proddata = data['data'];
        for (let i = 0; i < proddata.length; i++) {
          proddata[i].customerQuantity = proddata[i].min_quantity;
          if (this.ProductListData.length > 0) {
            var dupicatecount = this.ProductListData.filter(
              (Data) => Data.part_number == proddata[i].part_number
            );
            if (dupicatecount.length > 1) {
            } else {
              this.ProductListData.push(proddata[i]);
            }
          } else {
            this.ProductListData.push(proddata[i]);
          }
          if (proddata[i]['resource_type'] == 'Pack') {
            this.ProductListData[i].ProductMRP = proddata[i]?.crm_mrp * proddata[i]?.moq;
        }
        else {
          this.ProductListData[i].ProductMRP = proddata[i]?.crm_mrp;
        }
        }
        this.ProductListData = this.ProductListData.sort((a, b) => {
          if (parseInt(a.fig_no) > parseInt(b.fig_no)) {
            return 1;
          } else if (parseInt(a.fig_no) < parseInt(b.fig_no)) {
            return -1;
          } else {
            return 0;
          }
        });
        this.prodstart = data['start'];
        this.prodend = data['end'];
        this.showLoader = false;
      } else if (
        data['success'] === false &&
        data['data']['msg'] === 'Data not found'
      ) {
        this.isProductEmpty = true;
        this.showLoader = false;
      } else {
        this.showLoader = false;
        this.isProductEmpty = false;
      }
    } else {
      this.showLoader = false;
      this.isProductEmpty = false;
      this.ProductListData = [];
    }
  }
  ShowEngine() {
    this.ShowEnginemodel.emit();
  }
  engineviewAddressModal() {
    this.viewAddressModal.emit();
  }

  readQuantityForEnginePartList(customerQuantity, product) {
    product.customerQuantity = customerQuantity;
  }

  addtoCartitemsForEnginePartList(Item) {
    this.showPopup = false;
    let permission = this.userService.CheckPermission(
      'btn_cart'
    );
    if (permission) {
      if (isNaN(Item.customerQuantity)) {
        this.toasterService.error('Quantity cannot be empty or 0');
        return;
      }
      else if (
        Item.customerQuantity !== null &&
        Item.customerQuantity > 0 &&
        Item.customerQuantity !== undefined
      ) {
        if (Item.customerQuantity < Item['min_quantity']) {
          this.toasterService.error('Quantity field cannot be less than minimum quantity');
          return;
        }
        else {
          this.showDivPartdetails = false;
          this.showPartData = [];
          $('#btn_' + Item.part_number).prop('disabled', true);
          // if (Item['resource_type'] == 'Pack') {
          //   this.quantity = Item.customerQuantity
          //     ? (parseInt(Item.customerQuantity) * parseInt(Item['moq']))
          //     : 1 * parseInt(Item['moq']);
          // } else {
            
            this.quantity = Item.customerQuantity ? Item.customerQuantity : 1;
          // }
          this.showLoader = true;
          if (this.localstorage.getItem('getCartType')) {
            this.cartTypeList = JSON.parse(this.localstorage.getItem('getCartType'));
          }
          var commoncarttypedata = this.cartTypeList.filter((alitems) =>
            alitems.common_distributor_categories.includes(
              Item['distributor_category']
            )
          );
          var Distributercarttypedata = this.cartTypeList.filter((alitems) =>
            alitems.distributor_categories.includes(Item['distributor_category'])
          );
          let cart_type_name = '';
          if (commoncarttypedata.length > 0) {
            if (this.localstorage.getItem('selectcarttype')) {
              cart_type_name = this.localstorage.getItem('selectcarttype');

              this.cart
                .add(
                  Item,
                  this.quantity,
                  Item['distributor_category'],
                  cart_type_name
                )
                .subscribe({
                  complete: () => {
                    $('#btn_' + Item.part_number).prop('disabled', false);
                    this.cd.markForCheck();
                    this.showLoader = false;
                  },
                });
              localStorage.removeItem('selectcarttype');
            }
          } else if (Distributercarttypedata.length > 0) {
            cart_type_name = Distributercarttypedata[0]['cart_type'];
            this.cart
              .add(
                Item,
                this.quantity,
                Item['distributor_category'],
                cart_type_name
              )
              .subscribe({
                complete: () => {
                  $('#btn_' + Item.part_number).prop('disabled', false);
                  this.cd.markForCheck();
                  this.showLoader = false;
                },
              });
          }
        }
      }
      else {
        if (Item['resource_type'] == 'Pack') {
          if (Item.customerQuantity < Item['min_quantity']) {
            this.toasterService.error('Unit field should not be empty.');
            return;
          }
        } else if (Item.customerQuantity == 0) {

          this.toasterService.error('Quantity field cannot be 0 .Minimum quantity required.');
        } else {
          this.toasterService.error('Quantity field should not be empty.');
        }
      }
    }
    else {
      this.toasterService.error(blockPermissionMsg);
    }
  }

  openSchemeListModal(type:any){
        if(this.validSchemeList.length > 0 || this.discountOfferData.length > 0){
            this.popupOpenType = "otherOffer"
            this.schemeListModal = this.modalService1.show(this.schemeList, 
                { class: "modal-dialog-centered modal-lg", backdrop: "static" });
        }else{
            this.toasterService.error("No schemes Found");
        }  
   
}

newOffersMethod(){
  this.mergeDisountArray = [];
  this.mergeDisountArray = this.discountOfferData.concat(this.validSchemeList, this.offercard);
  let samevalue = [];
  this.mergeDisountArray.forEach((ele) => {
      let words = ele.discount_type.split('_');
      for (let i = 0; i < words.length; i++) {
          words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
      }
      let capitalizedDiscountType = words.join(' ');
      if (!samevalue.includes(capitalizedDiscountType)) {
          samevalue.push(capitalizedDiscountType)
      }
  })
  this.storeCrouselname = samevalue;
}
getOfferDetails(partNumber, division_id, isAddressChanged) {
  let user = JSON.parse(this.localstorage.getItem('userData'));
  let requestBody = {
      account_pk: user.account_pk,
      part_number: partNumber,
      division_id: division_id
  }
  // setTimeout(() => {
      this.userService.getPartDiscounts(requestBody).subscribe(response => {
          if (response.success == true) {
              let discountsArray: any[] = response.data;
              this.discountOfferData = response.data;
              this.discountOfferData = this.discountOfferData.filter((result) => result.discount_type !== 'market_basket');
              this.discountSlabOffer = discountsArray.filter(discount => discount.discount_type === "quantity_discount");
              if (this.discountSlabOffer.length > 0) {
                  this.showQuantityDiscountMessage = true
                  this.isshowMoreSlabDiscount = false
              }
              this.discountOffers = this.discountSlabOffer[0];
              if (this.discountOffers) {
                  if (parseInt(this.productList[0].customerQuantity) >= parseInt((this.productList[0]['resource_type'] == 'Pack' ) ? this.discountOffers.quantity / this.productList[0]['moq'] : this.discountOffers.quantity)) {
                      this.isQuantityDiscountAvailable = true;
                  }
                  this.getDiscountedPriceDetails(partNumber, this.discountOffers.quantity);
              }
              else {
                  this.isQuantityDiscountAvailable = false;
                  isAddressChanged = true;
              }
          }
          else {
              this.quantityBasedPartPrice = 0;
              this.isQuantityDiscountAvailable = false;
              this.discountOffers = null;
              this.showQuantityDiscountMessage = false;
          }
          if (isAddressChanged === true) {
              this.isQuantityDiscountAvailable = false;
              this.showQuantityDiscountMessage = false;
              this.refreshDiscountDetails(this.productList[0]);
          }
      },
          error => {
              this.quantityBasedPartPrice = 0;
              this.isQuantityDiscountAvailable = false;
              //this.toasterService.error(error?.error?.data?.msg);
              this.discountOffers = null;
              this.showQuantityDiscountMessage = false;
          })
  // },500);
}
  getmarketbasketlist() {
    let user = JSON.parse(this.localstorage.getItem('userData'));
    let req = {
        "account_pk":parseInt(user.account_pk),
        "part_number_list": [this.product?.part_number],
        "division_id": [this.baseProductdata ? this.baseProductdata?.division_info?.DIV_ID: ""],
        "organization_id":[this.baseProductdata ? this.baseProductdata?.division_info?.ORG_ID: ""],
        "offset": 0,
        "limit": 100,
    };
    this.productService.getMarketbasket(req).subscribe(
        (response) => {
            if (response.success) {
                this.offercard = [];
                response.data.forEach((ele) => {
                    this.offercard.push(ele.discount)
                })

            } else {
                if (response?.data?.msg) {
                    this.toasterService.error(response.data.msg);
                }
            }
        },
        (error) => {
            if (error?.error?.data?.msg) {
                this.toasterService.error(error.error.data.msg);
            }
        }
    );
}

toggleSidebar(value: any, index: any) {
  this.displayToggleName = value;
  this.innerViewoffer = [];
  this.uniqueListContact = []; let flags = {};
  if( this.displayToggleName == 'Quantity Discount' || this.displayToggleName == 'Value Discount' || this.displayToggleName == 'Bulk Purchase'){
      this.mergeDisountArray.forEach((ele) => {
          if (ele) {
              let words = ele.discount_type.split('_');
                      for (let i = 0; i < words.length; i++) {
                          words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
                      }
                      let capitalizedDiscountType = words.join(' ');
              if (capitalizedDiscountType == value) {
              
                      this.innerViewoffer.push(ele);              
      
              }
                          }
      })
      // console.log("finalArrray",  this.innerViewoffer)
      //
      
      this.uniqueListContact = this.innerViewoffer.filter((entry) => {
          if (flags[entry.discount_name]) {
              return false;
          }
          flags[entry.discount_name] = true;
          return true;
      });
      // console.log('this.uniqueListContact ==== ',this.uniqueListContact);
      for(let i = 0; i < this.uniqueListContact.length; i++){
          this.uniqueListContact[i].newArr = [];
          for(let j = 0; j < this.innerViewoffer.length; j++){
              if(this.uniqueListContact[i].discount_name == this.innerViewoffer[j].discount_name){
                  // this.uniqueListContact[i].newArr.serialNumber = srnumber++
                  this.uniqueListContact[i].newArr.push(this.innerViewoffer[j]);
                  
              }

          }

      }
          
  }else{

      this.mergeDisountArray.forEach((ele) => {
          if (ele) {
              let words = ele.discount_type.split('_');
                      for (let i = 0; i < words.length; i++) {
                          words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
                      }
                      let capitalizedDiscountType = words.join(' ');
              if (capitalizedDiscountType == value) {
                      this.innerViewoffer.push(ele)
              }
                          }
      })
  }
  this.isSidebarOpen = !this.isSidebarOpen;
}
readproductdetaailQuantity(event, product, isDiscountApplicable,index) {          
  // console.log("check_readProductQuantity",this.marketDetailsarr,event, product, isDiscountApplicable,index)  
  // product.customerQuantity = event;
  
  //   this.storeMbquantity = event;
  //   this.newArrayforPartnumner[index].tempQuantity = this.storeMbquantity ;
  //   if (this.discountOffers && isDiscountApplicable) {
  //     if (((this.newArrayforPartnumner[index].resource_type == 'Pack' && this.newArrayforPartnumner[index].moq > 1) ? (this.discountOffers.quantity / this.newArrayforPartnumner[index].moq ) : this.discountOffers.quantity)) {
  //       this.isQuantityDiscountAvailable = true;
  //       this.savedAmount = this.ProductMRP - this.quantityBasedPartPrice;
  //     }
  //     else {
  //       this.isQuantityDiscountAvailable = false;
  //       this.savedAmount = this.ProductMRP - this.Productdiscount;
  //     }
  //     if(this.discountSlabOffer.length > 0){
  //       if(event >= this.discountOffers?.quantity / this.newArrayforPartnumner[index].moq){
  //           this.isshowMoreSlabDiscount = true
  //       }
  //       else{
  //           this.isshowMoreSlabDiscount = false
  //       }
  //   }

  // //   if (this.isLoggedIn && JSON.parse(this.localstorage.getItem("Pincode"))){
  // //     if (((this.newArrayforPartnumner[index].resource_type == 'Pack' && this.newArrayforPartnumner[index].moq > 1) ? (this.discountOffers.quantity / this.newArrayforPartnumner[index].moq ) : this.discountOffers.quantity)) {
  // //     this.getDiscountedPriceDetails(this.newArrayforPartnumner[index]?.part_number,  (this.newArrayforPartnumner[index]['resource_type'] == 'Pack' && this.marketDetailsarr[index]['moq'] > 1) ? this.storeMbquantity * product['moq'] : this.storeMbquantity)
  // //     }
  // // }
  //   }

  // if(event > product?.inventoryQuantity){
  //   this.addtocartDisable = true;
  // }else{
  //   this.addtocartDisable = false;
    this.storeMbquantity = event;
    this.newArrayforPartnumner[index].tempQuantity =  this.storeMbquantity;
    // console.log("checK-temppnxsndcdcdocdc",this.newArrayforPartnumner[index].tempQuantity,this.newArrayforPartnumner[index].resource_type)
        if( this.newArrayforPartnumner[index].resource_type == 'Pack' ){
          this.newArrayforPartnumner[index].tempQuantity = event * this.newArrayforPartnumner[index]?.moq; 
        }
// }
}

marketBasketCollapeApi(data: any, i: any) {
  let user = JSON.parse(this.localstorage.getItem('userData'));
  let req = {
      "account_pk": parseInt(user.account_pk),
      "discount_code": data.discount_code,
      "offset": 0,
      "limit": 100,
      // data.discount_code
  };
  this.productService.getMarketbasketDetails(req).subscribe(
      (response) => {
          if(response){
              this.ismarketbasketapicall = true
              // response.forEach((ele) => {
                  this.marketDetailsarr = [];
                  // this.marketDetailsarr = response.data;                 
                  this.stireduplicatearra = [];
                  this.newArrayforPartnumner = [];
                  let tempArray:any = [];
                  let srnumber = 1
                  response.data.forEach((ele) => {
                    this.marketBasketDescription = ele?.discount_description ? ele?.discount_description : '';
                      // for (let i = 0; i < ele.discount_slab_detail.length; i++) {
                      //     ele.discount_slab_detail[i].partQuantity = 1;
                      // }
                      ele.discount_slab_detail.forEach(ele => {
                        ele.slab = srnumber++;
                    })
                      this.marketDetailsarr = ele.discount_slab_detail;
                      tempArray = ele.discount_master_detail;
                      tempArray.forEach(part => {
                          part['tempQuantity'] = 1;
                        });
                  })
                                     // })
      this.newArrayforPartnumner = tempArray.reduce((accumulator, current) => {
                            if (!accumulator.some(obj => obj.sku_item === current.sku_item)) {
                                accumulator.push(current);
                            }
                            return accumulator;
                        }, []);
                  //  console.log("chek_newArrayvalue", this.newArrayforPartnumner)
          
            
          }else{
              this.ismarketbasketapicall =false
          }
       
      })
}
  getSchemeList(partNumber, division_id,organization_id){
    try{
        this.validSchemeList=[];
        let user = JSON.parse(this.localstorage.getItem('userData'));
        let req ={
            "action_type": "get_part_detail",
            "account_pk": user.account_pk,
            "part_number":partNumber,
            "division_id": division_id,
            "organization_id":organization_id,
        }
        this.productService.getPartSpecificScheme(req).subscribe(
            res => {
                if(res.success){
                  for (let i = 0; i < res.data.length; i++) {
                    res.data[i].discount_type = "Bulk Purchase";
                    res.data[i].discount_name = res.data[i].scheme_name;
                }
                    this.validSchemeList = res.data;
                }
            })
    }catch (error) {
        this.userService.InsertExceptionLog({ 
            "method_name": "alternateProduct()", 
            "page_name": "ProductDetailsComponent", 
            "portal_type": "Customer", "page_url": this.router.url, 
            "error_message": error.message, "inner_exception": error 
        });
    }
}

  getDiscountedPriceDetails(partNumber, discountQuantity) {
    let user = JSON.parse(this.localstorage.getItem('userData'));
    let address = JSON.parse(this.localstorage.getItem('address'));
    let requestBody = {
      "search_text": "",
      "bs_model_name": "",
      "pg_line": "",
      "multi_pg_line": [],
      "multi_bs_model_name": [],
      "multi_dispgroup_name": [],
      "model_cat": "",
      "mod_uid": "",
      "category_type": "",
      "bom_id": "",
      "durafit_parts": false,
      "kit_parts": false,
      "part_number_last_four": "",
      "offset": 0,
      "size": 20,
      "pl_id": "",
      "account_pk": user.account_pk,
      "shipping_address_id": address.address['isDefault'] ? "default" : address.address['address_id'],
      "is_for_epc_data": false,
      "is_for_discount": true,
      "is_for_ecom_detail": true,
      "quantity": parseInt(discountQuantity),
      "part_number": partNumber,
      "is_for_inventory":this.userData?.user_type == 'MECH' ? true: false,
    }
    this.showLoader = true
    this.productService.get_part_search_md(requestBody).subscribe(response => {
      if (response.success) {
        this.showLoader = false
        this.quantityBasedPartDetails = response.data[0];
        this.quantityBasedDivisionDetails = response.division_info;
        this.showQuantityDiscountMessage = true;
        this.isQuantityDiscountAvailable = true
        if (response?.data[0]?.resource_type == 'Pack' ) {
          let tmgoQuantity = parseInt(discountQuantity) / response?.data[0]?.moq;
          this.quantityBasedPartPrice = response.data[0]?.total_amount / tmgoQuantity;
          let tmgoMRP = this.quantityBasedPartDetails.crm_mrp * response?.data[0]?.moq;
          this.quantityBasedPercentage = parseFloat((((tmgoMRP - this.quantityBasedPartPrice) / tmgoMRP) * 100)?.toFixed(1));
          this.ProductMRP = response?.data[0]?.crm_mrp * response?.data[0]?.moq;
          this.savedAmount = this.ProductMRP - this.quantityBasedPartPrice;
        }
        else {
          this.quantityBasedPartPrice = response.data[0]?.total_amount / parseInt(discountQuantity);
          this.quantityBasedPercentage = parseFloat((((this.quantityBasedPartDetails.crm_mrp - this.quantityBasedPartPrice) / this.quantityBasedPartDetails.crm_mrp) * 100)?.toFixed(1));
          this.ProductMRP = response?.data[0]?.crm_mrp;
          this.savedAmount = this.ProductMRP - this.quantityBasedPartPrice;
        }
        // if (this.isQuantityDiscountAvailable === true) {
        //   this.savedAmount = this.ProductMRP - this.quantityBasedPartPrice;
        // }
      }
      else {
        this.showLoader = false
        this.quantityBasedPartPrice = 0;
        this.showQuantityDiscountMessage = false;
      }
    },
      error => {
        this.showLoader = false
      })
  }

  async getFreshProductDetails(isAddressChanged) {
    let user = JSON.parse(this.localstorage.getItem('userData'));
    let address = JSON.parse(this.localstorage.getItem('address'));
    let billingaddress = JSON.parse(this.localstorage.getItem('billingAddress'));
    let requestBody = {
      durafit_parts: false,
      kit_parts: false,
      offset: 0,
      limit: 20,
      account_pk: user ? user.account_pk : "",
      shipping_address_id: address.address['default'] ? "default" : address.address['address_id'],
      part_number: this.product.part_number,
      is_for_epc_data: false,
      is_for_discount: true,
      is_for_ecom_detail: true,
      cart_type: this.localstorage.getItem('selectcarttype'),
      is_for_inventory:this.userData?.user_type == 'MECH' ? true: false,
    }
    await this.productService.get_part_search_md(requestBody).subscribe(response => {
      if (response.success) {
        this.baseProductdata = response;
        this.baseProductdata.division_info = {
          ORG_NAME:response.data[0].organization_name?response.data[0].organization_name:"",
          DIV_NAME:response.data[0].division_name?response.data[0].division_name:"",
          PRIMISE_ADDRLINE1_s:response.data[0].primise_addrline1?response.data[0].primise_addrline1:"",
          PRIMISE_ADDRESS2_s:response.data[0].primise_addrline2?response.data[0].primise_addrline2:"",
          DIV_ID:response.data[0].division_id?response.data[0].division_id:"",
          GSTIN:response.data[0].gstn?response.data[0].gstn:"",
          ORG_ID:response.data[0].organization_id?response.data[0].organization_id:"",
      }
        if (!response?.data[0]?.total_amount) {
          this.baseProductdiscount = response?.data[0]?.total_amount;
          if (response?.data[0]?.resource_type == 'Pack') {
            this.ProductMRP = response?.data[0]?.crm_mrp * response?.data[0]?.moq;
          }
          else {
            this.ProductMRP = response?.data[0]?.crm_mrp;
          }
          this.isNormalDiscount = false;
        }
        if (response?.data[0]?.total_amount) {
          this.baseProductdiscount = response?.data[0]?.total_amount;
          if ((response?.data[0]?.base_discount + response?.data[0]?.scheme_discount + response?.data[0]?.rule_discount) == 0) {
            if (response?.data[0]?.resource_type == 'Pack') {
              this.ProductMRP = response?.data[0]?.crm_mrp * response?.data[0]?.crm_mrp;
              this.Productdiscount = 0;
            }
            else {
              this.ProductMRP = response?.data[0]?.crm_mrp;
              this.Productdiscount = 0;
            }
            this.isNormalDiscount = false;
          }
          else if ((response?.data[0]?.base_discount + response?.data[0]?.scheme_discount + response?.data[0]?.rule_discount) > 0) {
            if (response?.data[0]?.resource_type == 'Pack') {
              this.Productdiscount = response?.data[0]?.total_amount * response?.data[0]?.moq;
              this.ProductMRP = response?.data[0]?.crm_mrp * response?.data[0]?.moq;
            }
            else {
              this.Productdiscount = response?.data[0]?.total_amount;
              this.ProductMRP = response?.data[0]?.crm_mrp;
            }
            this.isNormalDiscount = true;
            this.normalPercentage = parseFloat((((response?.data[0].crm_mrp - response?.data[0].total_amount) / response?.data[0].crm_mrp) * 100)?.toFixed(1));
            this.savedAmount = this.ProductMRP - this.Productdiscount;
          }
        }
        if (this.isLoggedIn && response) {
          this.getmarketbasketlist();
          this.divisionInfoData = response?.division_info;
          this.isWishlistedItem = response?.is_wishlist ? response?.is_wishlist : false;
          this.getOfferDetails(this.product.part_number, response?.division_info.DIV_ID, isAddressChanged)
          this.getSchemeList(this.product.part_number, response?.division_info.DIV_ID,this.baseProductdata.division_info?.ORG_ID)
          this.getPartLimitCheck(this.product.part_number,response.data[0].organization_id?response.data[0].organization_id:"");
          setTimeout(() => {
            this.newOffersMethod();
          }, 2000);
        }
      }
      else {
        let msg: string = response?.data?.msg;
        if (msg.length > 0) {
          this.toasterService.error(msg);
        }
      }
    })
  }

  refreshDiscountDetails(product) {
    if (this.discountOffers) {
      if (product.customerQuantity >= parseInt(this.discountOffers.quantity)) {
        this.isQuantityDiscountAvailable = true;
        this.savedAmount = this.ProductMRP - this.quantityBasedPartPrice;
      }
      else {
        this.isQuantityDiscountAvailable = false;
        this.savedAmount = this.ProductMRP - this.Productdiscount;
      }
    }
    if(this.discountSlabOffer.length > 0){
      if(product.customerQuantity >=  (product.resource_type == 'Pack' ?this.discountOffers?.quantity / product.moq:this.discountOffers?.quantity)){
          this.isshowMoreSlabDiscount = true
      }
      else{
          this.isshowMoreSlabDiscount = false
      }
  }

  if (this.isLoggedIn && JSON.parse(this.localstorage.getItem("Pincode"))){
          this.getDiscountedPriceDetails(product?.part_number, product.resource_type == 'Pack' ?product.customerQuantity * product.moq:product.customerQuantity)
  }
  }
  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }
  onRouteSameUrl(partnumber) {
    let currentUrl = this.router.url;
    if (currentUrl.includes('product-details')) {
      this.router.navigateByUrl('/', { skipLocationChange: true })
        .then(() => {
          this.router.navigated = false;
          this.router.navigate(["/shop/product-details"], { queryParams: { part_Number: partnumber } });
        });
    }
  }

  getPartLimitCheck(partnumber,div_id){
    let user = JSON.parse(this.localstorage.getItem('userData'));
    var req ={
            "part_number_list" : [partnumber],
            "account_id" : user.account_id,
            "distributor_id" : div_id
    }

    this.productService.get_part_limit_check(req).subscribe(
        res => {
               this.partLimitCheck = res
            if(res.success == false || res.success != undefined){
                this.partLimitCheck = []
            }
        })
    }catch (error) {
        this.userService.InsertExceptionLog({ 
        "method_name": "getPartLimitCheck()", 
        "page_name": "ProductDetailsComponent", 
        "portal_type": "Customer", "page_url": this.router.url, 
        "error_message": error.message, "inner_exception": error 
    });
}
routeToProduct(value){
  if (value.sku_item != 'ALL') {
    this.router.navigate(['/shop/product-details'], {
      queryParams: { part_Number: value.sku_item },
    }).then((res) => {
      this.isSidebarOpen = false;
    })
  }
}

convertValue(value){
  return parseFloat(value)
}
convertToInt(value:any){
  return parseInt(value)
}

onBack(){
  this.backToDetails.emit(true);
}

errormsg :any;
searchedVehicles:any
typevalue: string;
isApiCalled:boolean = false;
vehicleList = [
  { name: 'Registration Number', id: '0', checked: true,disabled: false },
  { name: 'Chassis Number', id: '1', checked: false, disabled: false },
];
chosenItem = this.vehicleList[0].name;
checkCompatible:any=''
radioChange(event: MatRadioChange, item) {
  this.isApiCalled = false;
  this.compatibilityMessage = '';
  this.checkCompatible = null;
  this.typevalue= ""
  this.errormsg = ""
  if (item.name == 'Chassis Number') {
    this.chosenItem = item.name;
    this.searchedVehicles = [];

  } else {
    this.chosenItem = item.name;
    this.searchedVehicles = [];
  }
}

applyFilter() {
  if (!this.typevalue) {
    this.errormsg = 'Please Enter ' + this.chosenItem + '';
    return;
  }
  let data = {};
  this.productList = [];
  // this.isApiCalled = false;
  let searchby =
    this.chosenItem == 'Registration Number'
      ? 'registration_num'
      : 'chassis_num';

  data[searchby] = this.typevalue;
  this.showLoader = true;
  this.errormsg = '';
  this.productService.getProductListByRegOrChassionov2(data).subscribe(
    (response) => {
      if (response.success) {
        // this.showLoader = false;
        // this.isApiCalled = true;
        this.searchedVehicles = response.data;
        this.checkCompatibility()
      } else {
        this.errormsg = response.data.msg;
        this.compatibilityMessage = '';
        this.checkCompatible = null;
        this.isApiCalled = false;
        this.searchedVehicles = [];
        this.showLoader = false;
      }
    },
    (error) => {
      this.showLoader = false;
    }
  );
}

checkCompatibility() {
  var json = {
    "chassis_number":  this.chosenItem == 'Chassis Number'? this.typevalue : '',
    "registration_number": this.chosenItem == 'Registration Number'? this.typevalue : '',
    "part_number": this.product?.part_number,
    "check_compatibility": true
}   
this.showLoader = true;
  this.productService.get_applicable_model(json).subscribe(
    (response) => {
      this.isApiCalled = true;
      this.showLoader = false;
      if (response.success) {
        this.checkCompatible = true;
        this.compatibilityMessage = response.data.msg;
      } else {
        this.checkCompatible = false;
        this.compatibilityMessage = response.data.msg;
      }
      this.showLoader = false;
    },
    (error) => {
      this.isApiCalled = false;
      this.showLoader = false;
    }
  );
}
imageTempArray:any=[]
fullimageModal:any;
modalImage:any;
selectImg(path:any,index:any,type:any){
  this.modalImage = path + '/resize/800x452'
      this.fullimageModal = this.modalService1.show(this.fullImagePopup,
          { class: "modal-dialog-centered modal-lg", });   

 }
}