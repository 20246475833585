import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  OnDestroy,
  OnChanges,
  AfterViewInit,
} from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

import { ProductService } from '../../services/REST/product.service';
@Component({
  selector: 'child-parts',
  templateUrl: './child-parts.component.html',
  styleUrls: ['./child-parts.component.scss'],
})
export class ChildPartsComponent
  implements OnInit, OnDestroy,OnChanges, AfterViewInit
{
  //   @Input()
  childPartData: any;

  public dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  newRequestBody: any;
  datatsetempty = false;
  dtOptions: DataTables.Settings = {};
  @Output() closePopUp = new EventEmitter<any>();
  @Input() sendchildproduct;
  totalrecord;
  constructor(private productService: ProductService) {}
  ngOnInit() {
    this.calldatatable(this.sendchildproduct);
    // this.rerender();
  }

  closeModal() {
    this.closePopUp.emit();
  }
  ngOnChanges() {
    // this.calldatatable(this.sendchildproduct);
    // this.rerender();
  }
  calldatatable(products) {

    this.childPartData = [];
    // this.btnshowChildParts = false;

    // this.showLoader = true;

    this.newRequestBody = {
      part_number: products.part_number,
      //    offset: 0,
      size: 10,
    };
    this.dtOptions = {
      pagingType: 'full_numbers',
      responsive: true,
      serverSide: true,
      processing: true,
      lengthChange: false,
      searching: false,
      pageLength: 10,
      ordering: false,
      language: {
        info: 'Showing _START_ to _END_ of _TOTAL_ entries',
        infoEmpty: 'No records available',
        // infoFiltered: ' ',
      },
      ajax: (dataTablesParameters: any, callback) => {
        this.newRequestBody['offset'] = dataTablesParameters.start;
        this.productService.getChildPartList(this.newRequestBody).subscribe(
          (response) => {
            //this.result = true;
            this.totalrecord = 0;
            //this.chkService = false;
            if (response.success) {
              // this.btnshowChildParts = true;
              // this.currentPage = 1;
              this.childPartData = response.data;
              this.totalrecord = response.total_count;
            } else {
              // this.btnshowChildParts = false;
              this.childPartData = [];
              this.totalrecord = 0;
            }
            //   this.showLoader = false;
            callback({
              recordsTotal: this.totalrecord,
              recordsFiltered: this.totalrecord,
              data: [],
            });
          },
          (err) => {
            this.childPartData = [];
            //   this.showLoader = false;
          }
        );
      },
    };
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }
}