<!-- <div class="container-fluid colntainer-md pt-5 pb-5"> -->
    <div class="container-fluid colntainer-md pt-3">
    <div class="row">
        
     
      <!-- <nav aria-label="breadcrumb" class="mt-3 mb-3">    -->
<app-page-header [header]="pageHeader" [searchValue]="searchuserData" [breadcrumbs]="breadcrumbs" class="margin-mtop"></app-page-header>
<!-- </nav> -->
        <!-- <app-widgetfilters></app-widgetfilters> -->
        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-3  filter-are filter-fixed-sm">
            
            <div class="card border-0">
                <h3 class="fw-bold mb-0 d-flex align-items-center">&nbsp; <span class="ms-auto close-filter d-inline-flex d-lg-none" style="width: 20px;"><img alt="Close" src="./assets/images/close.svg" class="img-fluid" /> </span></h3>
                <div class="card-body p-0">
                    <!-- <div class="ltr-filtter">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">
                                <h3 class="fw-bold text-uppercase mb-0">Filter</h3>
                            </li>
                            <li class="list-group-item">
                                <input class="form-check-input me-1" type="checkbox" value="" aria-label="...">
                                Deals and Discounts
                            </li>
                            <li class="list-group-item">
                                <input class="form-check-input me-1" type="checkbox" value="" aria-label="...">
                                Same Day Delivery
                            </li>
                        </ul>
                    </div> -->
                    <div class="part-car filter-box">
                        <!-- <h3 class="fw-bold mb-0 d-flex align-items-center">&nbsp; <span class="ms-auto close-filter d-inline-flex d-lg-none" style="width: 20px;"><img alt="Close" src="./assets/images/close.svg" class="img-fluid" /> </span></h3> -->
                        <!-- placeholder="Type a keyword to search"  (keypress)="searchUserdataFilter($event.target.value)"-->
                        <div class="filter-search-cat cat-highlited-wrapper mb-3">
                            <input class="form-control"  type="search" id="text-focus" 
                             placeholder="Search by e.g. King pin, Air filter, Stearing filter etc." 
                            [(ngModel)]="searchuserData" (keyup.enter)="searchUserdataFilter('')"
                            (search)="searchUserdataFilter($event.target.value)" 
                             aria-label="input search_cat">
                             <button class="btn-search" type="button" role="button" (click)="searchUserdataFilter('')"><img alt=""
                                src="./assets/images/search-h.svg" /></button>
                        </div>
                    </div>
                   
                    <!-- MODEL CATEGORY FILTER -->
                    <div class="part-car filter-box">
                        <h3 class="fw-bold mb-0">Vehicle Model</h3>
                        <div class="filter-search-cat mb-3">
                            <input class="form-control" type="search" placeholder="Search Vehicle Model"
                            [(ngModel)]="searchselectedModel" aria-label="input search_cat">
                        </div>
                        <div class="list-check table-responsive" style="max-height:calc(100vh - 360px);overflow-x: hidden;">
                            <ng-container *ngIf="!isModelApiLoaded; else vehicleModel">
                                <div class="form-check" *ngFor="let item of userService.generateFake(4);let i = index;">
                                    <div class="form-check-input">
                                        <skc-rectangle [loading]="loading" [skc-height]="'15px'" [skc-width]="'100%'"> </skc-rectangle>
                                    </div>
                                    <div class="form-check-label">
                                        <skc-rectangle [loading]="loading" [skc-height]="'24px'" [skc-width]="'100%'"> </skc-rectangle>
                                    </div>
                                </div>
                                <div class="view-more mt-3">
                                    <a class="cursor"><skc-rectangle [loading]="loading" [skc-height]="'19px'" [skc-width]="'50%'"> </skc-rectangle></a>
                                </div>
                            </ng-container>
                            <ng-template #vehicleModel>
                            <ng-container *ngFor="let models of modelData | filter: searchselectedModel; let j=index;">
                                <ng-container *ngIf="!showMoreModel && j<4">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="{{models}}"
                                        (click)="ModelCategorySelected($event,models)" [checked]="ModelSelectedData.includes(models)">
                                        <label class="form-check-label" for="{{ models }}" title="{{models}}">
                                            {{ models.substr(0,30) | uppercase }} 
                                        </label>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="showMoreModel">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="{{models}}" [checked]="ModelSelectedData.includes(models)"
                                        (click)="ModelCategorySelected($event,models)">
                                        <label class="form-check-label" for="{{ models }}" title="{{models}}">
                                            {{ models.substr(0,30) | uppercase }} 
                                        </label>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-template>
                        </div>
                        
                        <div class="view-more mt-3" *ngIf="!showMoreModel && modelData.length > 4">
                            <a class="cursor" (click)="viewMoreModel()">View More</a>
                        </div>
                        <div class="view-more mt-3" *ngIf="showMoreModel">
                            <a class="cursor" (click)="viewMoreModel()">View Less</a>
                        </div>
                    </div>

                    <!-- SUB MODEL CATEGORY FILTER -->
                    <div class="part-car filter-box">
                        <h3 class="fw-bold mb-0">Vehicle Model Category</h3>
                        <div class="filter-search-cat mb-3">
                            <input class="form-control"  type="search" placeholder="Search Model Category"
                            [(ngModel)]="searchselectesubdModel" aria-label="input search_cat">
                        </div>
                        <div class="list-check table-responsive" style="max-height:calc(100vh - 365px);overflow-x: hidden;">
                            <ng-container *ngIf="!isSubmodelApiLoaded; else modelCategory">
                                <div class="form-check" *ngFor="let item of userService.generateFake(4);let i = index;">
                                    <div class="form-check-input">
                                        <skc-rectangle [loading]="loading" [skc-height]="'15px'" [skc-width]="'100%'"> </skc-rectangle>
                                    </div>
                                    <div class="form-check-label">
                                        <skc-rectangle [loading]="loading" [skc-height]="'24px'" [skc-width]="'100%'"> </skc-rectangle>
                                    </div>
                                </div>
                                <div class="view-more mt-3">
                                    <a class="cursor"><skc-rectangle [loading]="loading" [skc-height]="'19px'" [skc-width]="'50%'"> </skc-rectangle></a>
                                </div>
                            </ng-container>
                            <ng-template #modelCategory>
                            <ng-container *ngFor="let submodels of submodelData | filter: searchselectesubdModel; let k=index;">
                                <ng-container *ngIf="!showMoreSubModel && k<4">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="{{submodels}}"
                                        (click)="ModelSubCategorySelected($event,submodels)" [checked]="subModelSelectedData.includes(submodels)">
                                        <label class="form-check-label" for="{{ submodels }}" title="{{submodels}}">
                                            {{ submodels.substr(0,30) | uppercase }} 
                                        </label>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="showMoreSubModel">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="{{submodels}}" [checked]="subModelSelectedData.includes(submodels)"
                                        (click)="ModelSubCategorySelected($event,submodels)">
                                        <label class="form-check-label" for="{{ submodels }}" title="{{submodels}}">
                                            {{ submodels.substr(0,30) | uppercase }} 
                                        </label>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-template>
                        </div>
                        <div class="view-more mt-3" *ngIf="!showMoreSubModel && submodelData.length > 4">
                            <a class="cursor" (click)="viewMoreSubModel()">View More</a>
                        </div>
                        <div class="view-more mt-3" *ngIf="showMoreSubModel">
                            <a class="cursor" (click)="viewMoreSubModel()">View Less</a>
                        </div>
                    </div>

                    <!-- AGGREGATE FILTER -->
                    <div class="part-car filter-box">
                        <h3 class="fw-bold mb-0">Aggregate</h3>
                        <div class="filter-search-cat mb-3">
                            <input class="form-control"  type="search" placeholder="Search Aggregate"
                            [(ngModel)]="searchselecteaggregate" aria-label="input search_cat">
                        </div>
                        <div class="list-check table-responsive"  style="max-height:calc(100vh - 365px);overflow-x: hidden;">
                            <ng-container *ngIf="!IsAggregateApiLoaded; else aggregateList">
                                <div class="form-check" *ngFor="let item of userService.generateFake(4);let i = index;">
                                    <div class="form-check-input">
                                        <skc-rectangle [loading]="loading" [skc-height]="'15px'" [skc-width]="'100%'"> </skc-rectangle>
                                    </div>
                                    <div class="form-check-label">
                                        <skc-rectangle [loading]="loading" [skc-height]="'24px'" [skc-width]="'100%'"> </skc-rectangle>
                                    </div>
                                </div>
                                <div class="view-more mt-3">
                                    <a class="cursor"><skc-rectangle [loading]="loading" [skc-height]="'19px'" [skc-width]="'50%'"> </skc-rectangle></a>
                                </div>
                            </ng-container>
                            <ng-template #aggregateList>
                            <ng-container *ngFor="let objagg of aggregateData | filter: searchselecteaggregate; let l=index;">
                                <ng-container *ngIf="!showMoreaggregate && l<4">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="{{objagg.aggregate}}" [checked]="aggregateSelectedData.includes(objagg.aggregate)"
                                        (click)="AggregateSelected($event,objagg.aggregate)">
                                        <label class="form-check-label" for="{{ objagg.aggregate }}" title="{{objagg.aggregate}}">
                                            {{ objagg.aggregate.substr(0,30) | uppercase }} 
                                        </label>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="showMoreaggregate">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="{{objagg.aggregate}}" [checked]="aggregateSelectedData.includes(objagg.aggregate)"
                                        (click)="AggregateSelected($event,objagg.aggregate)">
                                        <label class="form-check-label" for="{{ objagg.aggregate }}" title="{{objagg.aggregate}}">
                                            {{ objagg.aggregate.substr(0,30) | uppercase }} 
                                        </label>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-template>
                        </div>
                        <div class="view-more mt-3" *ngIf="!showMoreaggregate && submodelData.length > 4">
                            <a class="cursor" (click)="viewMoreAggregate()">View More</a>
                        </div>
                        <div class="view-more mt-3" *ngIf="showMoreaggregate">
                            <a class="cursor" (click)="viewMoreAggregate()">View Less</a>
                        </div>
                    </div>
                    
                    <!-- PART CATEGORY FILTER -->
                    <div class="part-car filter-box">
                        <h3 class="fw-bold mb-0">Part Category</h3>
                        <div class="filter-search-cat mb-3">
                            <input class="form-control" (change)="onPartCategorySelection($event)" type="search" placeholder="Search Part Category"
                            [(ngModel)]="searchPgLine" aria-label="input search_cat">
                        </div>
                        <div class="list-check table-responsive"  style="max-height:calc(100vh - 365px);overflow-x: hidden;">
                            <ng-container *ngIf="!isPartCategoryApiLoaded; else partCategory">
                                <div class="form-check" *ngFor="let item of userService.generateFake(4);let i = index;">
                                    <div class="form-check-input">
                                        <skc-rectangle [loading]="loading" [skc-height]="'15px'" [skc-width]="'100%'"> </skc-rectangle>
                                    </div>
                                    <div class="form-check-label">
                                        <skc-rectangle [loading]="loading" [skc-height]="'24px'" [skc-width]="'100%'"> </skc-rectangle>
                                    </div>
                                </div>
                                <div class="view-more mt-3">
                                    <a class="cursor"><skc-rectangle [loading]="loading" [skc-height]="'19px'" [skc-width]="'50%'"> </skc-rectangle></a>
                                </div>
                            </ng-container>
                            <ng-template #partCategory>
                            <ng-container *ngFor="let halfProduct of AllProductsList | filter: searchPgLine;let i=index;">
                                <ng-container *ngIf="!showMore && i<4 && halfProduct.LINE_2_s != '' && halfProduct.LINE_2_s != null && halfProduct.LINE_2_s != undefined">
                                    <div class="form-check">
                                        <!-- [disabled]="halfProduct.LINE_2_s==selectedPgLine"
                                        [checked]="halfProduct.LINE_2_s==selectedPgLine" -->
                                        <input class="form-check-input" type="checkbox" id="{{halfProduct.LINE_2_s}}" [checked]="pgline.includes(halfProduct.LINE_2_s)"
                                            (change)="pgLineSelected($event,halfProduct.LINE_2_s)">
                                        <label class="form-check-label" for="{{halfProduct.LINE_2_s}}" title="{{halfProduct.LINE_2_s}}">
                                            {{ halfProduct.LINE_2_s.substr(0,30) | uppercase }} 
                                        </label>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="showMore">
                                    <div class="form-check">
                                        <!-- [disabled]="halfProduct.LINE_2_s==selectedPgLine"
                                            [checked]="halfProduct.LINE_2_s==selectedPgLine" -->
                                        <input class="form-check-input" type="checkbox" id="{{halfProduct.LINE_2_s}}" [checked]="pgline.includes(halfProduct.LINE_2_s)"
                                            (change)="pgLineSelected($event,halfProduct.LINE_2_s)">
                                        <label class="form-check-label" for="{{halfProduct.LINE_2_s}}" title="{{halfProduct.LINE_2_s}}">
                                            {{ halfProduct.LINE_2_s.substr(0,30) | uppercase }}
                                        </label>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-template>
                            <!-- <div class="form-check" *ngFor="let halfProduct of halfProducts;let i=index;">
                                <input class="form-check-input" type="checkbox" id="{{halfProduct.LINE_2_s}}"
                                [disabled]="halfProduct.LINE_2_s==selectedPgLine"
                                [checked]="halfProduct.LINE_2_s==selectedPgLine"
                                (click)="pgLineSelected($event,halfProduct.LINE_2_s)">
                                <label class="form-check-label" for="{{halfProduct.LINE_2_s}}">
                                    {{ halfProduct.LINE_2_s }}
                                </label>
                            </div> -->
                        </div>
                        <!-- <div class="list-check" *ngIf="showMore">
                            <div class="form-check" *ngFor="let AllProduct of AllProductsList;let i=index;">
                                <input class="form-check-input" type="checkbox" id="{{AllProduct.LINE_2_s}}"
                                [disabled]="AllProduct.LINE_2_s==selectedPgLine"
                                [checked]="AllProduct.LINE_2_s==selectedPgLine"
                                (click)="pgLineSelected($event,AllProduct.LINE_2_s)">
                                <label class="form-check-label" for="{{AllProduct.LINE_2_s}}">
                                    {{ AllProduct.LINE_2_s }}
                                </label>
                            </div>
                        </div> -->
                        <div class="view-more mt-3" *ngIf="!showMore && AllProductsList.length > 4">
                            <a class="cursor" (click)="viewMore()">View More</a>
                        </div>
                        <div class="view-more mt-3" *ngIf="showMore">
                            <a class="cursor" (click)="viewMore()">View Less</a>
                        </div>
                    </div>
                    
                    <!-- <div class="vc-filter filter-box">
                        <h3 class="fw-bold mb-0">Vehicle Category</h3>
                        <div class="list-check">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="buses">
                                <label class="form-check-label" for="buses">
                                    Buses
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="commercial">
                                <label class="form-check-label" for="commercial">
                                    Commercial
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="hmt">
                                <label class="form-check-label" for="hmt">
                                    Heavy & Medium Trucks
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="autoriksa">
                                <label class="form-check-label" for="autoriksa">
                                    Auto-Riksa
                                </label>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="fleet-models filter-box">
                        <h3 class="fw-bold mb-0">My Fleet Models</h3>
                        <div class="list-check">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="allModels">
                                <label class="form-check-label" for="allModels">
                                    All Models
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="tata-prima">
                                <label class="form-check-label" for="commercial">
                                    Tata Prima
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="hmt">
                                <label class="form-check-label" for="tata-sigma">
                                    Tata Sigma
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="tata-ultra">
                                <label class="form-check-label" for="tata-ultra">
                                    Tata Ultra
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="EMission filter-box">
                        <h3 class="fw-bold mb-0">EMission Norms Compliance</h3>
                        <div class="list-check">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="bsi56">
                                <label class="form-check-label" for="bsi56">
                                    BSI(56)
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="bsi64">
                                <label class="form-check-label" for="bsi64">
                                    BSI(64)
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="bsi32">
                                <label class="form-check-label" for="bsi32">
                                    BSI(32)
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="bsi48">
                                <label class="form-check-label" for="bsi48">
                                    BSI(48)
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="brand filter-box">
                        <h3 class="fw-bold mb-0">Brand</h3>
                        <div class="list-check">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="bsi56">
                                <label class="form-check-label" for="bsi56">
                                    BSI(56)
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="ch`eckbox" value="" id="bsi64">
                                <label class="form-check-label" for="bsi64">
                                    BSI(64)
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="bsi32">
                                <label class="form-check-label" for="bsi32">
                                    BSI(32)
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="bsi48">
                                <label class="form-check-label" for="bsi48">
                                    BSI(48)
                                </label>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>

        <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9 col-xxl-9 product-lists">
            <div *ngIf="ProductListData?.length === 0 && isNoDataFound" class="empty-box">
                <img src="./assets/images/empty box.png">
                <p class="text-center">No Record Found</p>
    
            </div>
            <div class="d-flex mb-4 justify-content-between align-items-center" *ngIf="ProductListData?.length > 0">
                <div clas="prod-count col-12 col-sm-6">
                     
                    <!-- <span>40</span>/ -->
                    <!-- <span>{{ ProductListData.length}} / {{ totalResult }} </span> results for <span class="fw-bold">‘Filter’</span> -->
                    <span class="search-results" style="text-transform: capitalize;">  {{bannerTitle }} </span>  
                    <span class="search-results"  *ngIf="headerSearchText != '' && headerSearchText != undefined && headerSearchText != null">  {{'Search Results For ' + headerSearchText}} </span>  
                </div>
                <!-- <div class=" col-6 col-sm-3 ms-auto">
                  <div class="sort-by row g-0">
                    <label for="sort-by-sel" class="col-sm-4 col-form-label text-end">Sort by</label>
                    <div class="col-sm-7 ms-1">
                      <select class="form-select" name="sort-by-sel" id="sort-by-sel">
                        <option value="">Delivery Date</option>
                      </select>
                    </div>
                  </div>
                </div> -->
                <div class="grid-flter col-2 col-sm-1 text-nowrap text-end d-none d-md-flex">
                    <img  alt="scale2" (click)="openAggregatesList();" height="35px" width="100%" style="cursor: pointer;" src="./assets/images/scale.svg" />
                   <button class="btn btn-link p-0" (click)="GotoView('Grid')" *ngIf="layout == 'Grid'"><img src="./assets/images/card.svg" alt="icon" srcset=""></button>
                   <button class="btn btn-link p-0" (click)="GotoView('Grid')" *ngIf="layout == 'list'"><img src="./assets/images/card-inactive.svg" alt="icon" srcset=""></button>
                  <button class="btn btn-link p-0 me-1" (click)="GotoView('list')" *ngIf="layout == 'Grid'"><img src="./assets/images/list.svg" alt="icon" srcset=""></button>
                    <button class="btn btn-link p-0 me-1" (click)="GotoView('list')" *ngIf="layout == 'list'"><img src="./assets/images/list-active.svg" alt="icon" srcset=""></button>
                </div>
              </div>
              <!-- Change number of boxes show in a row -->
              <!-- <div class="row awadhesh row-cols-1 row-cols-md-3 row-cols-lg-4 row-cols-xl-4 row-cols-xxl-4 g-4">
                <div class="col" *ngFor="let product of ProductListData">
                    <app-product-list [product]="product"></app-product-list>
                </div>
            </div> -->
            <!-- GRID -->
            <!-- <div class="row  row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-4 g-4"
                *ngIf="layout == 'Grid' && ProductListData?.length > 0"> -->
                <ng-container *ngIf="layout == 'Grid' && ProductListData?.length > 0; else skeletonForGrid">
                    <!-- <div class="col" *ngFor="let product of ProductListData"> -->
                        <app-product-list [productList]="ProductListData" [layout]="layout" [showMore]="showMore" [showMoreModel]="showMoreModel"
                        [showMoreSubModel]="showMoreSubModel" [showMoreaggregate]="showMoreaggregate" [breadcrumbs]="breadcrumbs"></app-product-list>
                    <!-- </div> -->
                </ng-container>
                <ng-template #skeletonForGrid>
                    <ng-container *ngIf="layout == 'Grid' && ProductListData?.length === 0 && !isNoDataFound">
                        <div class="col" *ngFor="let item of userService.generateFake(9);let i = index;">
                            <app-product-list [product]=""  [dproduct]="item"[layout]="layout" [showMore]="showMore" [showMoreModel]="showMoreModel"
                            [showMoreSubModel]="showMoreSubModel" [showMoreaggregate]="showMoreaggregate" [breadcrumbs]="breadcrumbs"></app-product-list>
                        </div>
                    </ng-container>
                </ng-template>
            <!-- </div> -->
            <!-- <div class="row  row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-4 g-4"
            *ngIf="layout == 'Grid' && !ProductListData?.length && !isNoDataFound"> -->

            
        <!-- </div> -->
            <!-- LIST -->
            <div *ngIf="layout == 'list' && ProductListData?.length > 0">
                <app-product-list [productList]="ProductListData" [layout]="layout"></app-product-list>
            </div>
            <div *ngIf="layout == 'list' && !ProductListData?.length && !isNoDataFound">
                <app-product-list [productList]="ProductListData" [layout]="layout"></app-product-list>
            </div>
        </div>
        
        <!-- <div class="empty-box">
            <img style="margin-top: -201rem" src="./assets/images/empty box.png">
            <p style="margin-left:1rem; margin-top: -95rem" class="text-center">No Record Found</p>

        </div> -->
        <!-- <ng-container *ngIf="searchBy !== ''">
        <div class="products-view__pagination">
            <br>
            <app-pagination formControlName="page" [siblings]="2" [total]="" (pageChange1)="filter($event)"
                [showPagination]="showPagination"></app-pagination>
        </div>
        </ng-container> -->
    </div>
</div>
<div class="filter-cta">
    <a href="javascript:void(0);" class="filter-btn" ></a>
</div>
<!-- <ng-container *ngIf="showLoader">
    <div class="loader">
        <div class="ng-spinner ng-spinner-icon"></div>
    </div>
</ng-container> -->
<ng-template #aggregatesInPartList let-d="dismiss">
    <aggregates-in-partlist [modelList]="modelData" [subModelList]="submodelData" [aggregateList]="aggregateData" [selectedVcNo]="activeVcNo"
    [selectedModelCategory]="ModelSelectedData[0]" [selectedSubModelCategory]="subModelSelectedData[0]" [selectedAggregate]="aggregateSelectedData[0]"
    (closePopUp)="aggregatesInPartListModal.hide()">
</aggregates-in-partlist>
</ng-template>
