import { Input, TemplateRef, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie';
import { ToastrService } from 'ngx-toastr';
import { Address } from '../../../../../../src/app/shared/interfaces/address';
import { AppStateService } from '../../../../../../src/app/shared/services/app-state/app-state.service';
import { CartService } from '../../../../../../src/app/shared/services/cart.service';
import { AppFormErrorService } from '../../../../../../src/app/shared/services/form-error.service';
import { GoogleAnalyticsService } from '../../../../../../src/app/shared/services/GoogleAnalytics/GoogleAnalytics.service';
import { RegexService } from '../../../../../../src/app/shared/services/regex-service';
import { AuthService } from '../../../../../../src/app/shared/services/REST/auth.service';
import { ProductService } from '../../../../../../src/app/shared/services/REST/product.service';
import { RootService } from '../../../../../../src/app/shared/services/REST/root.service';
import { UserService } from '../../../../../../src/app/shared/services/REST/user.service';
import { UserCartService } from '../../../../../../src/app/shared/services/REST/usercart.service';
import { Subject } from 'rxjs';
import { secureStorage } from '../../../../../../src/app/shared/services/securestorage'
import { Title } from '@angular/platform-browser';
import * as CryptoJS from 'crypto-js';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  showHeader: boolean = false;
  isVehicleSelected: boolean = true;
  @Input() btndisabled: boolean = false;
  user: any;
  HideOtpbtn: boolean = false;
  userAccounrDetails: any;
  address: Address;
  usertypelist;
  expiredDate: any;
  loginForm: FormGroup;
  @Input() otpInputValue: string;
  userDetail = [];
  otherUserId;
  otherUserInfo;
  OtherUserNewOtp: string;
  OtherUserNewPassword: any;
  OtherUserConfrmPAssword: any;
  disabledAgreement: boolean = true;
  userIds = [];
  userData: any;
  mhvCart: any = [];
  slcvCart: any = [];
  cartArr = [];
  cartTypeList: any;
  isOtpGenerated: boolean = false;
  ShowSigninButton: boolean = false;
  keys: any;
  showLoader: boolean = false;
  addresses: any = [];
  default_address: any;
  new_addresses: any = [];
  showVehicleDetails: boolean = false;
  isSwitchAccount: boolean = false;
  isDelegateUser: boolean = false;
  delegateUserRole: string = "";
  isKeyClockRegistration: boolean = false;
  account_pk: any;
  isVehicleListPresent: boolean = false;
  vehicleinfo: any;
  billingAddress:any;
  shippingAddress:any;
  constructor(
    private userService: UserService,
    private productService: ProductService,
    private googleAnlyatics: GoogleAnalyticsService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private modalService: BsModalService,
    private formErrorService: AppFormErrorService,
    private router: Router,
    private toastrService: ToastrService,
    private appStateService: AppStateService,
    private regexService: RegexService,
    private rootService: RootService,
    private cartService: UserCartService,
    private cart: CartService,
    private cookieService: CookieService,
    private route: ActivatedRoute,
    private localstorage: secureStorage,
    private title:Title
  ) { }
  @ViewChild('Otpmodal', { read: TemplateRef, static: false })
  template: TemplateRef<any>;
  @ViewChild('selectUser', { read: TemplateRef, static: false })
  selectUser: TemplateRef<any>;
  @ViewChild('selectUserType', { read: TemplateRef, static: false })
  selectUserType: TemplateRef<any>;
  @ViewChild('vehicleDetails', { read: TemplateRef, static: false })
  vehicleDetails: TemplateRef<any>;

  modalRef: any;
  selectUserModal: any;
  selectUserTypeModal: any;
  vehicleDetailsModal: any;
  isOtpVerified: boolean = false;
  hide = true;
  is_active: boolean = true;
  isOtp: boolean = true;
  isTimer: boolean = false;
  eventsSubject: Subject<void> = new Subject<void>();
  ngOnInit(): void {
    this.getLocation();
    this.loginForm = this.formBuilder.group({
      type: ['' ],
      contact_no: [
        '',
        [Validators.required, Validators.pattern(this.regexService.Phone)],
      ],
      loginBy: ['otp'],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(14),
        ],
      ],
    });
    this.route.queryParams.subscribe((params) => {
      var isLogin = params['isLogin'];
      var isRegistration = params['isRegistration'];
      if (isLogin === 'true') {
        this.showHeader = true;
      } else if (isRegistration === 'true') {
        this.showHeader = true;
      } else {
        this.showHeader = false;
      }
    });
    this.title.setTitle('E-Dukaan | Login');
    $.getJSON('https://api.db-ip.com/v2/free/self', function (data) {
      if (data.ipAddress) {
          let valuenew = CryptoJS.AES.encrypt(data.ipAddress, "chair@tata$987");
          localStorage.setItem('ipAddress', valuenew.toString());
      }
  });
  }

  onSubmitOTP(otpinput = null) {
    try {
      this.loginForm.markAllAsTouched();
      let number: string = this.loginForm.value.contact_no;
      if (number.length < 10 || number.length == 0) {
        this.toastrService.error('Please enter 10 digit mobile number');
        return;
      }
      if (!this.loginForm.controls['contact_no'].valid) {
        if (this.loginForm.controls.contact_no.errors.pattern) {
          this.toastrService.error(
            'Mobile number should start with 6, 7, 8 or 9'
          );
          return;
        }
      }
      if (
        number.substring(0, 1) != '9' &&
        number.substring(0, 1) != '8' &&
        number.substring(0, 1) != '7' &&
        number.substring(0, 1) != '6'
      ) {
        this.toastrService.error(
          'Mobile number should start with 6, 7, 8 or 9'
        );
        return;
      }
      if (this.loginForm.invalid) {
        if (this.loginForm.value.loginBy == 'Password') {
          const type = this.loginForm.get('password');
          type.setValidators([Validators.required]);
          type.updateValueAndValidity();
        } else {
          const type = this.loginForm.get('password');
          type.setValidators([]);
          type.updateValueAndValidity();
        }
        if (this.loginForm.invalid) {
          this.formErrorService.displayFormErrors(this.loginForm);
          return;
        }
      }
      this.disabledAgreement = true;
      this.otpInputValue = '';
      if (this.loginForm.value.loginBy === 'otp') {
        this.showLoader = true;
        this.authService.login({
          contact_no: this.loginForm.value.contact_no,
          // type: this.loginForm.value.type,
        }).subscribe(response => {
          if (response.success) {
            this.keys = response.data['keys'];
            this.isKeyClockRegistration = response.data['registration_status'];
            this.toastrService.success('OTP Sent');
            this.isOtpGenerated = true;
            this.isTimer = true;
            this.eventsSubject.next();
            this.showLoader = false;
          } else {
            try {
              this.showLoader = false;
              this.googleAnlyatics.loginFail('OtherUser_Login_OTP_Fail_web', this.loginForm.value.type, this.loginForm.value.contact_no);
            }
            catch (error) { }
            this.toastrService.error(response.data.msg);
          }
        }, error => {
          if (error.status == 403) {
            this.toastrService.error("Your account has temporarily blocked due to multiple attempts to login using an incorrect password. Either login with OTP and change the password or try login in again with correct password after some time.")
          }
          else {
            if (error?.error?.data?.msg) {
              this.toastrService.error(error.error.data.msg);
            }
          }
          this.showLoader = false;
        })
      } else {
        if (this.loginForm.value.loginBy === 'Password') {
          this.showLoader = true;
          var skey = "edp@$3#drishti"
          var salt = "dRishtI";
          var iterations = 128;
          var bytes = CryptoJS.PBKDF2(skey, salt, { keySize: 48, iterations: iterations });
          var iv = CryptoJS.enc.Hex.parse(bytes.toString().slice(0, 32));
          var key = CryptoJS.enc.Hex.parse(bytes.toString().slice(32, 96));
          var cipherpassword = CryptoJS.AES.encrypt(this.loginForm.value.password, key, { iv: iv }).toString();
          let latitude = this.localstorage.getItem('geolocation') ? JSON.parse(this.localstorage.getItem("geolocation")).latitude: '';
          let longitude = this.localstorage.getItem('geolocation') ? JSON.parse(this.localstorage.getItem("geolocation")).longitude: '';
          this.authService.login({
            contact_no: this.loginForm.value.contact_no,
            password: cipherpassword,
            longitude: longitude,
            latitude: latitude,
            ip : this.localstorage.getItem('ipAddress')
          }).subscribe(response => {
            let isKeyClockUser = response?.data?.is_keycloak_user ? JSON.stringify(true) : JSON.stringify(false);
            this.localstorage.setItem("isKeyClockUser", isKeyClockUser);
            if (response.success) {
              this.userDetail = response.data;
              if (response.data.user_type_list.length === 0) {
                //this.modalRef.hide();
                return this.toastrService.error(
                  'This mobile number is not associated with any Account ID.'
                );
              }
              this.isDelegateUser = response.data.other_user_id ? true : false;
              this.delegateUserRole = this.isDelegateUser ? response.data.other_user_info.role_name : "Owner";
              this.keys = response.data.user_type_list;
              this.otherUserId = response.data.other_user_id ? response.data.other_user_id : null;
              this.otherUserInfo = response.data.other_user_info ? response.data.other_user_info : null;
              this.localstorage.setItem('otheruserdata', JSON.stringify(this.otherUserInfo));
              this.localstorage.setItem('otheruser', this.otherUserId);
              this.localstorage.setItem('usertype', this.otherUserId ? this.otherUserId : this.loginForm.value.type);
              this.localstorage.setItem('isOtherUser', this.otherUserId ? 'true' : 'false');
              this.isVehicleSelected = response.data.is_vehicle_checked;
              this.localstorage.setItem('isVehicleSelected', JSON.stringify(response.data.is_vehicle_checked));
              // this.localstorage.setItem('usertype', this.loginForm.value.type);
              this.expiredDate = new Date();
              let Minutes = response.data.token['expires_in'] * 1000; // convert 2 minutes to milliseconds
              var expirein = Minutes - Minutes / 10;
              let expiredate2 = new Date(this.expiredDate.getTime() + expirein);
              this.cookieService.putObject('access_token', response.data.token['access_token'], { expires: expiredate2 });
              const tokenobj = { token: response.data.token['access_token'] }
              if (this.isDelegateUser) {
                this.GetAccessPermission(tokenobj);
              }
              this.cookieService.putObject('ref_token', response.data.token['refresh_token']);
              this.localstorage.setItem('ref_token', response.data.token['refresh_token']);
              this.localstorage.setItem('refreshTokenInProgress', 'false');
              if (this.keys.length > 1) {
                this.showLoader = false;
                this.selectUserTypeModal = this.modalService.show(
                  this.selectUserType,
                  {
                    class: 'modal-dialog-centered modal-lg',
                    backdrop: 'static',
                  }
                );
              } else {
                var json = {
                  user_type: this.keys[0],
                  offset: 0,
                  limit: 30,
                };
                this.authService.getUserTypeList(json).subscribe(
                  (response) => {
                    if (response.success === true) {
                      if (response['data'].length == 1) {
                        response.data[0].isDelegateUser = this.isDelegateUser;
                        response.data[0].userRole = this.delegateUserRole;
                        this.localstorage.setItem('userData', JSON.stringify(response.data[0]));
                        this.localstorage.setItem('userAccounts', JSON.stringify(response.data));
                        this.localstorage.setItem('user', JSON.stringify(response.data[0]));
                        this.userData = response.data[0];
                        this.account_pk = response.data[0].account_pk;
                        this.authService.getAccountHealthCheck({"account_pk":this.account_pk}).subscribe(
                        res => {
                          if (this.isVehicleSelected) {
                            this.BindPageList();
                          }
                          else {
                            // this.showLoader = false;
                            // this.showVehicleDetails = true;
                            // this.isOtpGenerated = false;
                            this.checkVehicleList();
                          }
                        }, error => {
                          if (this.isVehicleSelected) {
                            this.BindPageList();
                          }
                          else {
                            // this.showLoader = false;
                            // this.showVehicleDetails = true;
                            // this.isOtpGenerated = false;
                            this.checkVehicleList();
                          }
                        })

                      } else {
                        this.userIds = response['data'];
                        this.userIds.forEach(userId => {
                          userId.isDelegateUser = this.isDelegateUser;
                          userId.userRole = this.delegateUserRole;
                        })
                        this.localstorage.setItem('userAccounts', JSON.stringify(this.userIds));
                        this.showLoader = false;
                        this.isSwitchAccount = false;
                        this.selectUserModal = this.modalService.show(
                          this.selectUser,
                          {
                            class: 'modal-dialog-centered modal-lg',
                            backdrop: 'static',
                          }
                        );
                      }
                    }
                  },
                  (error) => {
                    this.toastrService.error(error.error.data.msg);
                  }
                );
              }
              // this.showLoader = false;
            } else {
              if (response.success == false) {
                this.toastrService.error(response.data.msg);
              }
              try {
                this.googleAnlyatics.loginFail(
                  'OtherUser_Login_Password_Fail_web',
                  this.loginForm.value.type,
                  this.loginForm.value.contact_no
                );
              } catch (error) { }
              this.showLoader = false;
            }
            // this.showLoader = false;
          }, error => {
            if (error.status == 403) {
              this.toastrService.error("Your account has temporarily blocked due to multiple attempts to login using an incorrect password. Either login with OTP and change the password or try login in again with correct password after some time.")
            }
            else {
              this.toastrService.error(error.error.data.msg);
            }
            this.showLoader = false;
          })
        }
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'PageLoginComponent',
        method_name: 'onSubmitOTP()',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  verifyOTP() {
    try {
      if (!this.otpInputValue) {
        return this.toastrService.error('Please enter otp!');
      }
      if (!this.btndisabled) {
        this.btndisabled = true;
        let latitude = this.localstorage.getItem('geolocation') ? JSON.parse(this.localstorage.getItem("geolocation")).latitude: '';
          let longitude = this.localstorage.getItem('geolocation') ? JSON.parse(this.localstorage.getItem("geolocation")).longitude: '';
        let userData = {
          contact_no: this.loginForm.value.contact_no,
          // user_type: this.loginForm.value.type,
          otp_number: this.otpInputValue,
          registration_status: this.isKeyClockRegistration,
          longitude: longitude,
          latitude: latitude,
          ip : this.localstorage.getItem('ipAddress')
        };
        this.showLoader = true;
        this.authService.verifyOTP(userData).subscribe((response) => {
          this.btndisabled = false;
          this.userDetail = response.data;
          this.otherUserId = response.data.other_user_id ? response.data.other_user_id : null;
          this.otherUserInfo = response.data.other_user_info ? response.data.other_user_info : null;
          this.localstorage.setItem('otheruserdata', JSON.stringify(this.otherUserInfo));

          this.localstorage.setItem('usertype', this.otherUserId);
          this.localstorage.setItem('isOtherUser', this.otherUserId ? 'true' : 'false');
          let isKeyClockUser = response?.data?.is_keycloak_user ? JSON.stringify(true) : JSON.stringify(false);
          this.localstorage.setItem("isKeyClockUser", isKeyClockUser);
          if (response.success === true) {
            this.isDelegateUser = response.data.other_user_id ? true : false;
            this.delegateUserRole = this.isDelegateUser ? response.data.other_user_info.role_name : "Owner";
            this.isVehicleSelected = response.data.is_vehicle_checked;
            this.localstorage.setItem('otheruser', this.otherUserId);
            this.localstorage.setItem('isVehicleSelected', JSON.stringify(response.data.is_vehicle_checked));
            this.expiredDate = new Date();
            let Minutes = response.data.token['expires_in'] * 1000; // convert 2 minutes to milliseconds
            var expirein = Minutes - Minutes / 10;
            let expiredate2 = new Date(this.expiredDate.getTime() + expirein);
            this.cookieService.putObject('access_token', response.data.token['access_token'], { expires: expiredate2 });
            // const token = this.cookieService.getObject('access_token');
            const tokenobj = { token: response.data.token['access_token'] }
            if (this.isDelegateUser) {
              this.GetAccessPermission(tokenobj);
            }
            this.cookieService.putObject('ref_token', response.data.token['refresh_token']);
            this.localstorage.setItem('ref_token', response.data.token['refresh_token']);
            this.localstorage.setItem('refreshTokenInProgress', 'false');
            this.rootService.getCartType();
            if (this.keys.length > 1) {
              this.showLoader = false;
              this.selectUserTypeModal = this.modalService.show(
                this.selectUserType,
                {
                  class: 'modal-dialog-centered modal-xl',
                  backdrop: 'static',
                }
              );
            } else {
              var json = { user_type: this.keys[0], offset: 0, limit: 30 };
              this.authService.getUserTypeList(json).subscribe(
                (response) => {
                  if (response.success === true) {
                    if (response['data'].length == 1) {
                      response.data[0].isDelegateUser = this.isDelegateUser;
                      response.data[0].userRole = this.delegateUserRole;
                      this.localstorage.setItem('userAccounts', JSON.stringify(response.data));
                      this.localstorage.setItem('userData', JSON.stringify(response.data[0]));
                      this.localstorage.setItem('user', JSON.stringify(response.data[0]));
                      this.userData = response.data[0];
                      this.account_pk = response.data[0].account_pk;
                      this.authService.getAccountHealthCheck({"account_pk":this.account_pk}).subscribe(
                      res => {
                        if (this.isVehicleSelected) {
                          this.BindPageList();
                        } else {
                          this.checkVehicleList();
                        }
                      }, error => {
                        if (this.isVehicleSelected) {
                          this.BindPageList();
                        } else {
                          this.checkVehicleList();
                        }
                      })
                    } else {
                      this.showLoader = false;
                      this.userIds = response['data'];
                      this.userIds.forEach(userId => {
                        userId.isDelegateUser = this.isDelegateUser;
                        userId.userRole = this.delegateUserRole;
                      })
                      this.localstorage.setItem('userAccounts', JSON.stringify(this.userIds));
                      this.isSwitchAccount = false;
                      this.selectUserModal = this.modalService.show(
                        this.selectUser,
                        {
                          class: 'modal-dialog-centered modal-xl',
                          backdrop: 'static',
                        }
                      );
                    }
                  }
                },
                (error) => {
                  this.toastrService.error(error.error.data.msg);
                }
              );
            }
            //this.isOtpGenerated = false;
            //this.showLoader = false;
          } else {
            this.toastrService.error(response?.data?.msg);
            try {
              this.googleAnlyatics.loginFail(
                'Login_Fail_web',
                this.loginForm.value.type,
                this.loginForm.value.contact_no
              );
            } catch (error) { }
            this.showLoader = false;
          }
        }, error => {
          this.btndisabled = false;
          if (error.status == 403) {
            this.toastrService.error("Your account has temporarily blocked due to multiple attempts to login using an incorrect OTP. Either login with OTP and change the password or try login in again with correct password after some time.")
          }
          else {
            this.toastrService.error(error.error.data.msg);
          }
          this.showLoader = false;
        })
      }



    } catch (error) {
      this.userService.InsertExceptionLog({
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'PageLoginComponent',
        method_name: 'verifyOTP()',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  GetAccessPermission(token) {
    try {
      // this.showLoader = true;
      this.authService.GetOtherUserPermissionList(token).subscribe((response) => {

        if (response.success) {
          var vehicleList = response.data;
          // this.showLoader = false;
          this.localstorage.setItem(
            'OtherUserPermission',
            JSON.stringify(response.data)
          );
        } else {

          // this.showLoader = false;
        }
      }),
        (error) => {
          // this.showLoader = false;
        };
    } catch (error) {
      this.userService.InsertExceptionLog({
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'PageLoginComponent',
        method_name: 'GetAccessPermission()',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  async BindPageList() {
    try {
      localStorage.removeItem('PageList');
      let user = JSON.parse(this.localstorage.getItem('userData'));
      if (user) {
        this.showLoader = true;
        await this.authService
          .GetPageList({ account_pk: user.account_pk })
          .subscribe(
            (response) => {
              if (response.success === true) {
                let afterLoginMainHeader = ['HOME'];
                let layoutMenu = [
                  // 'Set Password',
                  'Account Management',
                  'User Management',
                  'User permission',
                  'Bulk Order Upload',
                ];
                let ordersMenu = [
                  'Active Order',
                  'Order History',
                  'Orders In-Process',
                  'Cancel Order History',
                  'Invoice Return',
                ];
                let orderHistoryMenu = ['E-Com Orders', 'CRM Orders', 'Invoice Payment','Pay By Loyalty' ];
                var datas: any[] = response.data;
                //profile menu start
                let profilelist = datas.filter((obj) => {
                  for (let i = 0; i <= layoutMenu.length; i++) {
                    if (obj.page_display_name == layoutMenu[i]) {
                      obj.page_url = '/' + obj.page_url;
                      obj.isPermission = true;
                      return obj;
                    } else if (obj.page_display_name == 'My Profile') {
                      obj.page_url = '/' + obj.page_url;
                      obj.isPermission = true;
                      this.localstorage.setItem(
                        'isProfilePermission',
                        JSON.stringify(obj.isPermission)
                      );

                      this.appStateService.isProfilePermission.next(true);
                      return obj;
                    } else if (
                      obj.page_display_name == 'Vehicle Management'
                    ) {
                      obj.isPermission = true;
                      this.localstorage.setItem(
                        'isVehiclePermission',
                        JSON.stringify(obj.isPermission)
                      );

                      this.appStateService.isVehiclePermission.next(true);
                    } else {
                    }
                  }
                });
                this.localstorage.setItem(
                  'profileLayoutMenu',
                  JSON.stringify(profilelist)
                );
                this.appStateService.isprofilelist.next(true);
                //profile menu end

                //----------------------------//
                //orderhistory start//
                let orderHistoryMenuAllowed = datas.filter((obj) => {
                  for (let i = 0; i <= orderHistoryMenu.length; i++) {
                    if (obj.page_display_name == orderHistoryMenu[i]) {
                      obj.page_url = '/' + obj.page_url;
                      obj.isPermission = true;
                      return obj;
                    }
                  }
                });
                this.localstorage.setItem(
                  'orderHistoryMenuList',
                  JSON.stringify(orderHistoryMenuAllowed)
                );
                //orderhistory end//
                //----------------------------//
                //orders menu start
                let orderMenuList = datas.filter((obj) => {
                  for (let i = 0; i <= ordersMenu.length; i++) {
                    if (obj.page_display_name == ordersMenu[i]) {
                      obj.page_url =
                        obj.page_display_name == 'Order History'
                          ? '/orders/ecom-orders'
                          : obj.page_url.includes('account')
                            ? '/' + obj.page_url.replace('account', 'orders')
                            : '/' + obj.page_url;
                      obj.page_display_name =
                        obj.page_display_name == 'Cancel Order History'
                          ? 'Cancel Order'
                          : obj.page_display_name;
                      obj.isPermission = true;
                      return obj;
                    }
                  }
                });

                let sortedOrderMenu: any[] = [];
                orderMenuList.forEach((menu) => {
                  if (menu.page_display_name === 'Active Order') {
                    sortedOrderMenu.splice(0, 0, menu);
                  }
                  if (menu.page_display_name === 'Order History') {
                    sortedOrderMenu.splice(1, 0, menu);
                  }
                  if (menu.page_display_name === 'Orders In-Process') {
                    sortedOrderMenu.splice(2, 0, menu);
                  }
                  if (menu.page_display_name === 'Cancel Order History' || menu.page_display_name === 'Cancel Order') {
                    sortedOrderMenu.splice(3, 0, menu);
                  }
                  if (menu.page_display_name === 'Invoice Return') {
                    sortedOrderMenu.splice(4, 0, menu);
                  }
                });
                this.localstorage.setItem(
                  'orderMenuList',
                  JSON.stringify(sortedOrderMenu)
                );
                //orders menu close
                //----------------------------//
                //after login menu start
                let reportsPermission = datas.filter((obj) => {
                  if (obj.page_display_name == 'Order Tracking') {
                    obj.page_url = '/' + obj.page_url;
                    obj.isPermission = true;
                    return obj;
                  }
                });
                if (orderMenuList?.length >= 1) {
                  afterLoginMainHeader.push('ORDER');
                }
                if (reportsPermission?.length >= 1) {
                  afterLoginMainHeader.push('REPORTS');
                }
                afterLoginMainHeader.splice(1, 0, 'MY DASHBOARD');
                afterLoginMainHeader.push('MY VEHICLES');
                if(this.userData?.user_type != 'MECH'){
                  afterLoginMainHeader.push('MY OFFERS');
                }
                // afterLoginMainHeader.push('MY CREDIT');
                this.localstorage.setItem('afterLoginMainHeader', JSON.stringify(afterLoginMainHeader));
                this.appStateService.afterLoginPublish.next(true);
                //after login menu close
                //----------------------------//
                //dropdown menu start
                // let changePasswordPermission = datas.filter((obj) => {
                //   if (obj.page_display_name == 'Set Password') {
                //     obj.isPermission = true;
                //     return obj;
                //   }
                // });
                let orderHistoryProfilePermission = datas.filter((obj) => {
                  if (obj.page_display_name == 'Order History') {
                    obj.isPermission = true;
                    return obj;
                  }
                });
                // let changePassBehaviour: boolean;
                let orderHistoryProfileBehaviour: boolean;
                // if (changePasswordPermission.length == 0) {
                //   changePassBehaviour = false;
                // } else {
                //   changePassBpehaviour = true;
                // }
                if (orderHistoryProfilePermission.length == 0) {
                  orderHistoryProfileBehaviour = false;
                } else {
                  orderHistoryProfileBehaviour = true;
                }

                this.localstorage.setItem(
                  'orderHistoryProfilePermission',
                  JSON.stringify(orderHistoryProfileBehaviour)
                );
                this.appStateService.isorderHistoryProfilePermission.next(
                  orderHistoryProfileBehaviour
                );

                // this.localstorage.setItem(
                //   'changePasswordPermission',
                //   JSON.stringify(changePassBehaviour)
                // );
                // this.appStateService.isChangepasswordPermission.next(
                //   changePassBehaviour
                // );
                // dropdown menu end
                //----------------------------//
                //eta start
                  let isETAReport  = datas.filter((obj) => {
                  for(let i=0;i<=ordersMenu.length;i++){
                  if(obj.page_display_name == 'ETA Report'){
                    obj.page_url = "/" + obj.page_url;;
                    obj.isPermission=true;
                    return obj;
                  }
                  }
                  });
                this.localstorage.setItem(
                  'isETAReport',
                  JSON.stringify(isETAReport)
                );
                //eta end
              } else {
                let setAllPermission = false;
                // this.localstorage.setItem(
                //   'changePasswordPermission',
                //   JSON.stringify(setAllPermission)
                // );
                this.localstorage.setItem(
                  'orderHistoryProfilePermission',
                  JSON.stringify(setAllPermission)
                );
                this.localstorage.setItem(
                  'isProfilePermission', null
                );
                this.localstorage.setItem(
                  'isVehiclePermission', null
                );

                this.appStateService.isProfilePermission.next(null);
                this.appStateService.isVehiclePermission.next(null);
                this.localstorage.setItem(
                  'profileLayoutMenu',
                  JSON.stringify(setAllPermission)
                );
                this.localstorage.setItem(
                  'orderMenuList',
                  JSON.stringify(setAllPermission)
                );

                let afterLoginMainHeader = ['HOME'];
                this.localstorage.setItem(
                  'afterLoginMainHeader',
                  JSON.stringify(afterLoginMainHeader)
                );
                this.appStateService.afterLoginPublish.next(true);
              }
              this.redirectPostLogin();
            },
            (error) => {
              this.redirectPostLogin();
            }
          );
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'BindPageList()',
        page_name: 'MenuComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }


  GetAddress(routeUrl, queryParams) {
    try {
      let userData = JSON.parse(this.localstorage.getItem('userData'));
      if (userData) {
        const ListInput: GetAddressparameter = {} as GetAddressparameter;
        ListInput.account_pk = JSON.stringify(userData.account_pk);
        ListInput.offset = 0;
        ListInput.limit = 10;
        ListInput.state = '';
        ListInput.district = '';
        ListInput.city = '';
        this.default_address = null;
        this.productService.getAddressList(ListInput).subscribe(
          (response) => {
            if (response.success === true) {
              this.addresses = response.data;
              this.billingAddress = response.selected_address['billing_details'];
              this.shippingAddress = response.selected_address['shipping_details'];
              this.addresses.forEach((address) => {
                if (address.default == true) {
                  this.default_address = address;
                } else {
                  this.new_addresses.push(address);
                }
              });
              if (this.default_address != null && this.default_address != undefined && this.default_address != '') {
                var pincode = this.default_address.postal_code ? parseInt(this.default_address.postal_code) : '';
                let address = {
                  account_id: this.default_address.account_id,
                  address_id: this.default_address.address_id,
                  city: this.default_address.city,
                  district: this.default_address.district,
                  gst_no: this.default_address.gst_no,
                  postal_code: this.default_address.postal_code,
                  pr_bl_addr_id: this.default_address.pr_bl_addr_id,
                  state: this.default_address.state,
                  street_address1: this.default_address.street_address1,
                  street_address2: this.default_address.street_address2,
                  street_address3: this.default_address.street_address3,
                  taluka: this.default_address.taluka,
                  isDefault: this.default_address.default,
                  default: this.default_address.default,
                  isBillingAddress: this.default_address.default,
                  isDeliveryAddress: this.default_address.default,
                };
                let userAddress = {
                  address: address,
                  type: 'default',
                };

                this.billingAddress
                ?this.localstorage.setItem('billingAddress', JSON.stringify({address: this.billingAddress, type:'new'}))
                :this.localstorage.setItem('billingAddress', JSON.stringify(userAddress));
                this.shippingAddress
                ?this.localstorage.setItem('address', JSON.stringify({address: this.shippingAddress, type:'new'}))
                :this.localstorage.setItem('address', JSON.stringify(userAddress));
                this.shippingAddress
                ? pincode = parseInt(this.shippingAddress.postal_code)
                : pincode = pincode;
                this.appStateService.postalCode.next(pincode);
                this.localstorage.setItem('Pincode', JSON.stringify(pincode));
                this.localstorage.setItem('AddressId', this.default_address.address_id);
                if(this.billingAddress && this.shippingAddress){
                  (this.default_address.address_id == this.billingAddress.address_id && this.billingAddress.default == false)
                  &&(this.default_address.address_id == this.shippingAddress.address_id && this.shippingAddress.default == false)
                  ? this.setBillingShippingDefaultAddress(this.shippingAddress,'both')
                  :(this.default_address.address_id == this.billingAddress.address_id && this.billingAddress.default == false)
                  ?this.setBillingShippingDefaultAddress(this.shippingAddress,'billing')
                  :(this.default_address.address_id == this.shippingAddress.address_id && this.shippingAddress.default == false)
                  ?this.setBillingShippingDefaultAddress(this.billingAddress,'shipping')
                  :''
                }
              }
              else {
                let errorMessage = "The account Id '" + userData.account_id + "' you are trying to login, is not updated.";
                this.toastrService.error(errorMessage);
              }
            }
            else {
              this.showLoader = false;
              if (response?.data?.msg) {
                return this.toastrService.error(response.data.msg);
              }
            }
            this.localstorage.setItem("isCashDiscountApplicable",JSON.stringify(false));
            if (queryParams != '') {
              this.appStateService.userSubject.next({ user_ids: this.userData });
              this.appStateService.isLoggedIn.next(true);
              this.router.navigate(routeUrl, queryParams).then(res =>{
              });
            }
            else {
              this.appStateService.userSubject.next({ user_ids: this.userData });
              this.appStateService.isLoggedIn.next(true);
            let redirectUrl = this.localstorage.getItem("redirectUrl") ?  this.localstorage.getItem("redirectUrl"): '' ;
              if(redirectUrl){
                this.router.navigateByUrl(`${redirectUrl}`)

              }else{
                this.router.navigate(routeUrl)

              }
              let isCoachMarksDone = this.localstorage.getItem('coachMarksRender');
              if(!isCoachMarksDone || isCoachMarksDone === 'false' ){
                this.appStateService.coachMarksCheckedOrSkip.next(true);
              }
            }
            this.showLoader = false;
          },
          (error) => {
            this.localstorage.setItem("isCashDiscountApplicable",JSON.stringify(false));
            if (queryParams != '') {
              this.appStateService.userSubject.next({ user_ids: this.userData });
              this.appStateService.isLoggedIn.next(true);
              this.router.navigate(routeUrl, queryParams);
            }
            else {
              this.appStateService.userSubject.next({ user_ids: this.userData });
              this.appStateService.isLoggedIn.next(true);
              this.router.navigate(routeUrl);
            }
            this.showLoader = false;
            if (error?.error?.data?.msg) {
              this.toastrService.error(error.error.data.msg);
            }
          }
        );
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'PageLoginComponent',
        method_name: 'GetAddress()',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  async setBillingShippingDefaultAddress(address, addressType){
    try{
      let userData = JSON.parse(this.localstorage.getItem('userData'));
      let add_id = ( address.address_id && address.default == false )?address.address_id:( address.address_id && address.default == true )?'default':''
      let request = {
        "account_pk": JSON.stringify(userData.account_pk),
        "shipping_address_id":addressType == 'shipping' || addressType == 'both' ?'default':add_id,
        "billing_address_id":addressType == 'billing' || addressType == 'both' ?'default':add_id
      }
      await this.productService.setBillingShippingAddress(request).subscribe(
      res => {
        if(res){
          this.GetAddress([''], '');
        }
      });
    }catch(error){
      this.userService.InsertExceptionLog({
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'PageLoginComponent',
        method_name: 'setBillingShippingDefaultAddress(address, addressType)',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }
  BindMethod() {
    try {
      let cartQueryParams;
      let user_ids = { user_ids: this.userData };
      this.appStateService.userSubject.next(user_ids);
      this.pushCartData(this.userData['account_pk']);
      this.appStateService.isLoggedIn.next(true);
      this.cart.cartConfiguration();
      cartQueryParams = (this.localstorage.getItem("cartQueryParams") != "" && this.localstorage.getItem("cartQueryParams") != null && this.localstorage.getItem("cartQueryParams") != undefined) ? JSON.parse(this.localstorage.getItem("cartQueryParams")) : null;
      if (cartQueryParams != null && cartQueryParams != undefined) {
        let permission = this.userService.CheckPermission('btn_cart');
        this.localstorage.setItem("cartQueryParams", null);
        if (permission) {
          this.localstorage.setItem("isCartLoggedIn", "true");
          this.router.navigate(['cart/address'], {
            queryParams: cartQueryParams,
          });
        }
        else {
          this.router.navigate(['/account/home']);
        }
      }
      else {
        this.router.navigate(['/account/home']);
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'PageLoginComponent',
        method_name: 'BindMethod()',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }


  pushCartData(userId) {
    try {
      this.mhvCart = JSON.parse(this.localstorage.getItem('cartItems'));
      if (this.mhvCart !== null && this.mhvCart.length > 0) {
        this.mhvCart.forEach((result) => {
          if (this.localstorage.getItem('getCartType')) {
            this.cartTypeList = JSON.parse(this.localstorage.getItem('getCartType'));
          }
          var Distributercarttypedata = this.cartTypeList.filter((alitems) =>
            alitems.distributor_categories.includes(
              result.product.distributor_category
            )
          );
          let cartData = {};
          cartData = {
            part_code: result.product.part_number,
            quantity: result.quantity,
            price: result.product.umrp,
            cart_type: result.cart_type_name,
          };
          this.cartArr.push(cartData);
        });
      }
      if (this.cartArr.length > 0) {
        let order_lines = this.cartArr;
        let cartData = {};
        cartData = {
          account_pk: userId,
          order_lines: order_lines,
          validate_request:true
        };
        this.showLoader = true;
        this.productService.add_to_cart_ms(cartData).subscribe(
          (response) => {
            if (response.success === true) {
              localStorage.removeItem('cartItems');
              // setTimeout(() => {
              //   this.cart.load();
              // }, 500);
              this.showLoader = false;
            } else {
              this.showLoader = false;
            }
          },
          (error) => {
            this.showLoader = false;
          }
        );
      } else {
        // setTimeout(() => {
        //   this.cart.load();
        // }, 500);
        this.showLoader = false;
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'PageLoginComponent',
        method_name: 'pushCartData()',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  async redirectPostLogin() {
    this.localstorage.setItem("isProfileDropdownOpened", "false");
    this.localstorage.setItem("isCartDropdownOpened", "false");
    let addToCartItems: any[] = [];
    // let addTOWishListItems: any[] = JSON.parse(this.localstorage.getItem('wishListItem'));
    let preloginItems: any[] = JSON.parse(this.localstorage.getItem('cartItems'));
    // if(addTOWishListItems != null && addTOWishListItems != undefined && addTOWishListItems?.length > 0){
    //   let wishList:{"part_number":any, "cart_type":any}[] = [];
    //   addTOWishListItems.forEach(element => {
    //     let cart_type = element.distributor_category=='SLV'?'slv_cart':element.distributor_category=='MHV'?'mhv_cart':element.distributor_category=='TMGO'?'tmgo_cart':null;
    //     wishList.push({"part_number":element.part_number,"cart_type":cart_type});
    //   });
    //   this.addToWishlist(wishList);
    // }
    if (preloginItems != null && preloginItems != undefined && preloginItems?.length > 0) {
      preloginItems.forEach((item) => {
        addToCartItems.push({
          part_number: item.product.part_number,
          quantity: item.product.resource_type =='Pack' ? item.quantity/item.product.moq :item.quantity,
          cart_type: item.cart_type_name,
        });
      });
      let requestBody = {
        account_pk: this.userData['account_pk'],
        cart_items: addToCartItems,
        validate_request:true
      }
      this.showLoader = true;
      let cartQueryParams = (this.localstorage.getItem("cartQueryParams") != "" && this.localstorage.getItem("cartQueryParams") != null && this.localstorage.getItem("cartQueryParams") != undefined) ? JSON.parse(this.localstorage.getItem("cartQueryParams")) : null;
      await this.productService.add_to_cart_ms(requestBody).subscribe(response => {
        this.cart.cartConfiguration();
        if (response.success) {
          localStorage.removeItem('cartItems');
          if (cartQueryParams != null && cartQueryParams != undefined) {
            let permission = this.userService.CheckPermission('btn_cart');
            this.localstorage.setItem("cartQueryParams", null);
            if (permission) {
              this.localstorage.setItem("isCartLoggedIn", "true");
              this.GetAddress(['cart/address'], { queryParams: cartQueryParams, });
              // this.router.navigate(['cart/address'], { queryParams: cartQueryParams, });
              // this.showLoader = false;
            }
            else {
              this.GetAddress([''], '');
              // this.router.navigate(['']);
              // this.showLoader = false;
            }
          }
          else {
            this.GetAddress([''], '');
            // this.router.navigate(['']);
            // this.showLoader = false;
          }
        }
        else {
          localStorage.removeItem('cartItems');
          if (cartQueryParams != null && cartQueryParams != undefined) {
            let permission = this.userService.CheckPermission('btn_cart');
            this.localstorage.setItem("cartQueryParams", null);
            if (permission) {
              this.localstorage.setItem("isCartLoggedIn", "true");
              this.GetAddress(['cart/address'], { queryParams: cartQueryParams, });
              // this.router.navigate(['cart/address'], { queryParams: cartQueryParams, });
              // this.showLoader = false;
            }
            else {
              this.GetAddress([''], '');
              // this.router.navigate(['']);
              // this.showLoader = false;
            }
          }
          else {
            this.GetAddress([''], '');
            // this.router.navigate(['']);
            // this.showLoader = false;
          }
        }
      }, error => {
        localStorage.removeItem('cartItems');
        this.cart.cartConfiguration()
        if (cartQueryParams != null && cartQueryParams != undefined) {
          let permission = this.userService.CheckPermission('btn_cart');
          this.localstorage.setItem("cartQueryParams", null);
          if (permission) {
            this.localstorage.setItem("isCartLoggedIn", "true");
            this.GetAddress(['cart/address'], { queryParams: cartQueryParams, });
            // this.router.navigate(['cart/address'], { queryParams: cartQueryParams, });
            // this.showLoader = false;
          }
          else {
            this.GetAddress([''], '');
            // this.router.navigate(['']);
            // this.showLoader = false;
          }
        }
        else {
          this.GetAddress([''], '');
          // this.router.navigate(['']);
          // this.showLoader = false;
        }
      })
    }
    else {
      this.GetAddress([''], '');
      //this.router.navigate(['']);
      this.cart.cartConfiguration();
      // this.showLoader = false;
    }
  }

  resendOnSubmitOTP() {
    this.otpInputValue = '';
    this.onSubmitOTP();
  }

  CloseOtpVerify() {
    this.modalRef.hide();
    this.disabledAgreement = !this.disabledAgreement;
  }

  setAndVerifyOtp(event) {
    if (event.length === 6) {
      this.otpInputValue = event;
    }
    if (event == "") {
      this.otpInputValue = "";
    }
  }

  closeUserPopUp() {
    this.isOtpGenerated = false;
    this.isOtpVerified = false;
    this.showVehicleDetails = false;
    this.selectUserModal.hide();
  }

  setselectedUser(event) {
    event.isDelegateUser = this.isDelegateUser;
    event.userRole = this.delegateUserRole;
    this.user = event;
    this.setUser();
  }

  setUser() {
    try {
      if (!this.user) {
        return this.toastrService.error('Please select user!');
      }
      if (!this.btndisabled) {
        this.showLoader = true;
        this.btndisabled = true;
        let userData = this.userIds.filter(
          (user) => user.account_id === this.user['account_id']
        );
        if (userData && userData[0] && userData[0]['account_pk']) {
          this.authService.getAccountHealthCheck({"account_pk":userData[0]['account_pk']}).subscribe(
          res => {
            userData[0].isDelegateUser = this.isDelegateUser;
            userData[0].userRole = this.delegateUserRole;
            this.localstorage.setItem('user', JSON.stringify(this.userDetail));
            this.localstorage.setItem('userData', JSON.stringify(userData[0]));
            this.userData = userData[0];
            this.account_pk = userData[0]['account_pk'];
            //  'access_token'
            if (this.isVehicleSelected) {
              this.BindPageList();
              this.btndisabled = false;
              this.selectUserModal.hide();
              // this.showLoader = false;
            } else {
              this.selectUserModal.hide();
              this.btndisabled = false;
              // this.showVehicleDetails = true;
              // this.isOtpGenerated = false;
              // this.showLoader = false;
              this.checkVehicleList();
            }
          }, error => {
            userData[0].isDelegateUser = this.isDelegateUser;
            userData[0].userRole = this.delegateUserRole;
            this.localstorage.setItem('user', JSON.stringify(this.userDetail));
            this.localstorage.setItem('userData', JSON.stringify(userData[0]));
            this.userData = userData[0];
            this.account_pk = userData[0]['account_pk'];
            //  'access_token'
            if (this.isVehicleSelected) {
              this.BindPageList();
              this.btndisabled = false;
              this.selectUserModal.hide();
              // this.showLoader = false;
            } else {
              this.selectUserModal.hide();
              this.btndisabled = false;
              // this.showVehicleDetails = true;
              // this.isOtpGenerated = false;
              // this.showLoader = false;
              this.checkVehicleList();
            }
          }
          );
        }
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'PageLoginComponent',
        method_name: 'setUser()',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }
  SetLogintype(logintype) {
    if (logintype == 'PASSWORD') {
      this.ShowSigninButton = true;
    } else {
      this.ShowSigninButton = false;
    }
  }

  bindUserAccounts(event) {
    this.userIds = event;
    this.userIds.forEach(userId => {
      userId.isDelegateUser = this.isDelegateUser;
      userId.userRole = this.delegateUserRole;
    })
    this.localstorage.setItem('userAccounts', JSON.stringify(this.userIds));
    this.selectUserTypeModal.hide();
    if (this.userIds.length > 1) {
      this.showLoader = false;
      this.isSwitchAccount = false;
      this.selectUserModal = this.modalService.show(this.selectUser, {
        class: 'modal-dialog-centered modal-lg',
        backdrop: 'static',
      });
    } else {
      event[0].isDelegateUser = this.isDelegateUser;
      event[0].userRole = this.delegateUserRole;
      this.localstorage.setItem('userAccounts', JSON.stringify(event));
      this.localstorage.setItem('userData', JSON.stringify(event[0]));
      this.localstorage.setItem('user', JSON.stringify(event[0]));
      this.userData = event[0];
      this.account_pk = event[0]['account_pk'];
      this.authService.getAccountHealthCheck({"account_pk":this.account_pk}).subscribe(
        res => {
          if (this.isVehicleSelected) {
            this.BindPageList();
          } else {
            // this.showVehicleDetails = true;
            // this.isOtpGenerated = false;
            // this.showLoader = false;
            this.checkVehicleList();
          }
        }, error => {
          if (this.isVehicleSelected) {
            this.BindPageList();
          } else {
            // this.showVehicleDetails = true;
            // this.isOtpGenerated = false;
            // this.showLoader = false;
            this.checkVehicleList();
          }
        })
    }
  }

  openUserType() {
    if (this.keys.length > 1) {
      this.selectUserTypeModal = this.modalService.show(this.selectUserType, {
        class: 'modal-dialog-centered modal-lg',
        backdrop: 'static',
      });
    } else {
      var json = { user_type: this.keys[0], offset: 0, limit: 30 };
      this.authService.getUserTypeList(json).subscribe(
        (response) => {
          if (response.success === true) {
            this.userIds = response['data'];
            this.userIds.forEach(userId => {
              userId.isDelegateUser = this.isDelegateUser;
              userId.userRole = this.delegateUserRole;
            })
            this.localstorage.setItem('userAccounts', JSON.stringify(this.userIds));
            this.isSwitchAccount = false;
            this.selectUserModal = this.modalService.show(this.selectUser, {
              class: 'modal-dialog-centered modal-lg',
              backdrop: 'static',
            });
          }
        },
        (error) => {
          this.toastrService.error(error.error.data.msg);
        }
      );
    }
    this.isOtpGenerated = false;
    this.isOtpVerified = false;
  }

  continueViaVehicleDetails() {
    this.showLoader = true;
    this.BindPageList();
  }

  onlyNumberKey(event) {
    var ASCIICode = event.which ? event.which : event.keyCode;
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) return false;
    return true;
  }

  get f() {
    return this.loginForm.controls;
  }

  async checkVehicleList() {
    this.showLoader = true;
    await this.userService.VechileListWithoutLogin({ "account_pk": this.account_pk }).subscribe(
      response => {
        this.isVehicleListPresent = false;
        if (response.data?.length > 0) {
          for (let i = 0; i < response?.data?.length; i++) {
            if (response.data[i].count > 0) {
              this.isVehicleListPresent = true;
              break;
            }
          }
          if (this.isVehicleListPresent) {
            this.showVehicleDetails = true;
            this.isOtpGenerated = false;
            this.showLoader = false;
          }
          else {
            this.BindPageList();
            this.AddVehicleCheck();
          }
        }
        else {
          this.BindPageList();
          this.AddVehicleCheck();
        }
      }, error => {
        this.BindPageList();
        this.AddVehicleCheck();
      })
  }

  async AddVehicleCheck() {
    try {
      await this.userService.AddVehicleCheck({ "account_pk": this.account_pk }).subscribe(response => {
        if (response.success) {
          this.vehicleinfo = response.data;
        } else {
          // if(response?.data?.msg){
          //   this.toastrService.error(response.data.msg);
          // }
        }
        this.localstorage.setItem('isVehicleSelected',JSON.stringify(true));
      },error => {
        this.localstorage.setItem('isVehicleSelected',JSON.stringify(true));
      })
    }
    catch (error) {

    }
  }

  goBackToHome() {
    this.router.navigate(['']);
    this.localstorage.removeitems("cartForCheckout");
    this.localstorage.removeitems("cartIndex");
    this.localstorage.removeitems("cartQueryParams");
    this.localstorage.removeitems("cartSummaryQueryParams");
  }
  private watchId: number | null = null;
  errorMessage: string | null = null;
  showRequestButton: boolean = false;

  getLocation(): void {
    if ('geolocation' in navigator) {
      this.watchId = navigator.geolocation.watchPosition(
        (position) => {
          const geoLocation = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };

        this.localstorage.setItem('geolocation',JSON.stringify(geoLocation));
          // Store the object in localStorage as a JSON string
          this.errorMessage = null; // Clear any error message
          this.showRequestButton = false; // Hide the request button
        },
        (error: any) => {
          throw(error);
        },
        {
          enableHighAccuracy: true,
          maximumAge: 0,
          timeout: 5000
        }
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  }

}
export class GetAddressparameter {
  account_pk: any;
  offset: any;
  limit: any;
  state: string;
  district: string;
  city: string;
}
