<div class="product-details-layout">
  <div class="">

    <!-- <nav aria-label="breadcrumb" class="mt-3 mb-3">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#"><img alt="" src="./assets/images/home-h1.svg"
                            style="filter: sepia() saturate(10000%) hue-rotate(225deg );width: 14px; vertical-align: initial;" />
                    </a></li>
                <li class="breadcrumb-item"><a href="#">All Categories</a></li>
                <li class="breadcrumb-item"><a href="#">Product</a></li>
                <li class="breadcrumb-item"><a href="#">Filter and emmision</a></li>
                <li class="breadcrumb-item active"><a href="#">Air filtter</a></li>
            </ol>
        </nav> -->

    <div class="row">
      <a (click)="onBack()"><img src="./assets/images/U_turn_back_button.jpg" style="width: 25px;margin-left: 20px;margin-bottom: 5px;cursor: pointer;" alt=""> Back</a>
      <div class="col-12 col-sm-12 col-lg-12 col-xl-12">
        <div class="card shadow mb-3">
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <div class="owl-slider-custom border p-3" >
                  <owl-carousel-o [options]="customOptionsForRecentItems">
                    <ng-container *ngFor="let image of product?.multi_img">
                      <ng-template carouselSlide [id]="product?.multi_img">
                        <div class="me-1">
                          <img alt="" [src]="image.img + '/resize/310x208'" class="img-fluid" onerror="this.src='./assets/tata-vehicle.jfif';" (click)="selectImg(image.img,j,'fullScreen')"/>
                        </div>
                        
                      </ng-template>
                    </ng-container>
                  </owl-carousel-o>
                  <ng-container  *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                  <div class="product-offer" *ngIf="normalPercentage > 0 && !isQuantityDiscountAvailable">
                        <!-- {{(product?.base_discount + product?.scheme_discount +
                      product?.tml_rule_discount + product?.dealer_rule_discount +
                      product?.rule_discount)}} % -->

                        {{'SAVE ' + normalPercentage + '%'}}

                        <!-- <span>2 days left</span> -->
                      </div>
                      <div class="product-offer" *ngIf="quantityBasedPercentage > 0 && isQuantityDiscountAvailable">
                        <!-- {{(product?.base_discount + product?.scheme_discount +
                      product?.tml_rule_discount + product?.dealer_rule_discount +
                      product?.rule_discount)}} % -->

                        {{'SAVE ' + quantityBasedPercentage + '%'}}

                        <!-- <span>2 days left</span> -->
                      </div>
                  <!-- <div class="me-3"><img alt="" [src]="image.img" class="img-fluid" /></div> -->
                  <!-- <div class="me-3"><img alt="" src="./assets/images/login-bg.jpg"
                                            class="img-fluid" /></div>
                                    <div class="me-3"><img alt="" src="./assets/images/login-bg.jpg"
                                            class="img-fluid" /></div>
                                    <div class="me-3"><img alt="" src="./assets/images/login-bg.jpg"
                                            class="img-fluid" /></div> -->
                  </ng-container>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 sec-2">
                <h3 class="part-desc" *ngIf="product?.large_description != '' && product?.large_description != null && product?.large_description != undefined">Part Description </h3>
                <p *ngIf="product?.large_description != '' && product?.large_description != null && product?.large_description != undefined">{{ product?.large_description }}
                </p>
                <div class="d-flex button">
                  <!-- <div class="viewvideo d-inline-flex"><img alt="" src="./assets/images/video.svg"></div>
                  <div class="view360 d-inline-flex"><img alt="" src="./assets/images/360.svg">
                  </div> -->
                   <!-- <div class="view360 d-inline-flex" (click)="ShowEngine()" style="cursor: pointer;">
                        <img alt="scale" src="./assets/images/scale.svg" />
                      </div> -->
                  <div class="view360 d-inline-flex" (click)="openModal()" style="cursor: pointer;border-style: solid;">
                    <img alt="scale" src="./assets/images/vehicleCompatibility.png" style="width: 55px;">
                  </div>
                  <!-- compatable Modal -->
                  <div class="modal fade" [class.show]="isModalOpen" [style.display]="isModalOpen ? 'block' : 'none'">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="comptitle" id="exampleModalLabel">Vehicle Compatibility Check ({{product?.part_number}})</h5> 
                          <div class="cursor" (click)="closeModal()" aria-label="Close">
                            <i class="fa fa-times"></i>
                          </div>
                        </div>
                        <!-- <h4 class="chksutxt">Check the product's compatibility with your vehicle using Reg. No or Chassis Number.</h4> -->
                        <div class="modal-body">
                          <div>
                            <mat-radio-group name="vehicleList"  fxLayout="column" [(ngModel)]="chosenItem" [ngModelOptions]="{standalone: true}" >
                              <mat-radio-button  class="fw-bold newspace mb-2 mb-sm-0"  [checked]="item.checked" [disabled]="item.disabled" *ngFor="let item of vehicleList" [value]="item.name" 
                              name="vehicleList"(change)="radioChange($event,item)" >{{ item.name}}</mat-radio-button>
                            </mat-radio-group>
                          </div>
                          <div class="mt-3">
                            <div class="row">
                              <label for="" class="pb-2">Enter Vehicle {{chosenItem}}</label>
                              <div class="input-validatebtn">
                                  <input class="form-control inputwidth" type="text" [(ngModel)]="typevalue">
                                  <!-- <button type="button" class="btn btn-primary" (click)="applyFilter()">Validate</button> -->
                                  <label class="verified-msg d-flex align-items-center"  *ngIf="isApiCalled && typevalue && !errormsg">
                                    <img src="./assets/images/select-tick.gif" class="img-fluid me-1 icon-verify" *ngIf="checkCompatible == true"/>
                                    <img src="./assets/images/cross-mark.gif" class="img-fluid me-1 icon-verify" *ngIf="checkCompatible == false"/>                                   
                                  </label>
                              </div>
                              <span [ngStyle]="{'color': checkCompatible ? 'green' : 'red'}" style="margin-top: 10px;">{{ compatibilityMessage }}</span>
                            <label class="unable_verify d-flex align-items-center mt-2" *ngIf="errormsg"><img
                                src="./assets/images/button-circle-warning.svg" class="img-fluid me-1 icon-warning" />{{errormsg}}</label>
                            </div>
                          </div>
                        </div>
                        <div class="modal-footer border-0">
                          <button type="button" class="btn btn-primary" (click)="applyFilter()">Check</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 sec-3 mt-3 mt-lg-0">
                
                <app-wishlist-icon [product]="product" [layout]="'Nolayout'"  [isWishlistedItem]="isWishlistedItem"></app-wishlist-icon>
                <h3 class="model-no d-flex">{{(product?.short_description == " " ||
                  product?.short_description == null) ? "-" : product?.short_description}}
                  <div class="right-tools">
                    <!-- <img alt="" src="./assets/images/share.svg" />
                                        <img alt="" src="./assets/images/bookmark.svg" /> -->
                  </div>
                </h3>
                <!-- <div class="model-name">Air filter BS3</div> -->
                <div class="model-name ellipse-2line" style="line-height: 1;">#{{ product?.part_number }}</div>
                  <div class="mb-3">
                    <div class="delivery-time w-30 d-inline-block" *ngIf="product?.hsn_code && product?.hsn_code!=''">
                      HSN: <span class="fw-bold">{{ product?.hsn_code }}</span>
                    </div>&nbsp;&nbsp;
                    <div class="delivery-time w-30 d-inline-block text-end" *ngIf="product?.tax_slab && product?.tax_slab!=''">
                      GST: <span class="fw-bold">{{ product?.tax_slab }}%</span>
                    </div>
                  </div>
                  <p *ngIf="( product.resource_type == 'Pack' && product.moq > 1 )" class="tmgo mb-2">
                    <!-- <span class="save-mode">Pack of {{product.moq | number: '1.0-0'}} {{product.uom}}</span><br/> -->
                    (MRP is applicable for 1 Unit =
                    {{product.moq | number :'1.0-0'}} <span *ngIf="product.moq > 1">Quantities</span><span *ngIf="product.moq <= 1">Quantity</span>
                    )</p>  
                <ng-container *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true);else nslpartCondition">
                <ng-container *ngIf="baseProductdiscount">
                  <div class="mrp d-flex" *ngIf="isNormalDiscount && Productdiscount < ProductMRP && !isQuantityDiscountAvailable;">
                    {{ Productdiscount | currencyFormat :'INR' }}
                    <div class="actual-mrp">
                      MRP {{ ProductMRP |currencyFormat :'INR' }}
                    </div>
                  </div>
                    <div class="mrp d-flex" *ngIf="!isNormalDiscount && quantityBasedPartPrice === 0 && !isQuantityDiscountAvailable;">
                      {{ ProductMRP | currencyFormat :'INR' }}
                    </div>
                    <div class="mrp d-flex" *ngIf="!isNormalDiscount && !isQuantityDiscountAvailable && quantityBasedPartPrice > 0;">
                      {{ ProductMRP | currencyFormat :'INR' }}
                      <!-- <span class="ms-3">MRP 12,290.00 </span> -->
                    </div>
                  <div class="mrp d-flex" *ngIf="isQuantityDiscountAvailable && quantityBasedPartPrice > 0;">
                    {{ quantityBasedPartPrice | currencyFormat :'INR' }}
                    <div class="actual-mrp" *ngIf="quantityBasedPartPrice != ProductMRP">
                      MRP {{ ProductMRP |currencyFormat :'INR' }}
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="!baseProductdiscount">
                  <div class="mrp d-flex">
                    {{ ProductMRP | currencyFormat :'INR' }}
                  </div>
                </ng-container> 
                </ng-container>
                <ng-template #nslpartCondition>
                  <div class="price mt-2">
                    <i></i>{{ ProductMRP | currencyFormat :'INR' }}
                  </div>
                </ng-template>
                <ng-container  *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                <div class="total-saving mt-2" *ngIf="isLoggedIn && ((ProductMRP - Productdiscount) > 0) && !isQuantityDiscountAvailable">
                  You save {{ (ProductMRP - Productdiscount) | currencyFormat :'INR' }}
                </div>
                <div class="total-saving mt-2" *ngIf="isLoggedIn && ((ProductMRP - quantityBasedPartPrice) > 0) && isQuantityDiscountAvailable">
                  You save {{ savedAmount | currencyFormat :'INR' }}
                </div>
              </ng-container>
                
                <div style="margin-top: 10px;" *ngIf="loyaltyDatapercentage && (Productdiscount / 100 *  loyaltyDatapercentage?.point_percentage) >= 0.5 &&  product?.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                  <span class="total-saving">On purchase of this part, you are eligible to earn up to</span><span class="total-saving" style="margin-left: 5px;font-weight: bold;">{{Productdiscount / 100 * loyaltyDatapercentage?.point_percentage | number:'1.0-0'}}</span>
                  <span class="total-saving" style="margin-left: 5px;">loyalty points, So start earning reward points today</span>
                </div>
                <ng-container *ngIf="isLoggedIn && userData?.user_type != 'MECH' && product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                <span *ngIf="(validSchemeList && validSchemeList.length > 0) || (discountOfferData && discountOfferData.length > 0)" (click)="openSchemeListModal('otherOffer')" style="cursor: pointer; display: flex;margin-bottom: 1rem;">
                  <img width="25px" height="25px" src="assets/images/offerg-1550058133.png">
                  <p class="use-coupon-text">  View Offers</p>
                </span>
                </ng-container>
                <div class="mt-2 mb-2" *ngIf="isLoggedIn && userData?.user_type == 'RT'">
                  <p class="pin mb-0"><span style="font-weight: 600;">Discount category</span>&nbsp;:&nbsp;{{product?.discount_code_cvbu}}</p> 
                </div>
                <div class="get-it-by mb-2" *ngIf="isLoggedIn">
                  Delivery Pincode: <span>{{pinCodeNumber == 0 ? '' : pinCodeNumber}}</span>
                  <a (click)="engineviewAddressModal()"  class="change" style="margin-left:10px;cursor: pointer;">Change</a>
                </div>
                <li class="distributors drop-consumable mb-2" *ngIf="isLoggedIn && divisionInfoData">
                  <p class="mat-location-name">{{divisionInfoData?.ORG_NAME}}</p>
                  <div class="card consumable-hover-dropdown mt-2 shadow">
                    <div class="triangle"></div>
                    <div class="card-body consumable">
                      <div class="d-flex notify-heading">
                        <div class="row">
                  <p class="mat-deliver-address">
                    {{ divisionInfoData?.DIV_NAME != '' ? (divisionInfoData?.DIV_NAME + ',') : ''}}, 
                    {{ divisionInfoData?.PRIMISE_ADDRLINE1_s != '' ? (divisionInfoData?.PRIMISE_ADDRLINE1_s + ',') : ''}},
                    {{divisionInfoData?.PRIMISE_ADDRESS2_s != '' ? (divisionInfoData?.PRIMISE_ADDRESS2_s + ',') : ''}}</p>
                    </div>
                    </div>
                    </div>
                    </div>
                  </li>
                  <div class="mt-1 d-inline-block mb-2" 
                  *ngIf="(userData?.user_type == 'MECH' && (product['inventory_quantity'] > 0 && product['inventory_quantity'] != null && convertToInt(product.moq) <= product['inventory_quantity']) && product?.is_nls != 'in_active' && (product?.alternate_part?false:true)) || (userData?.user_type != 'MECH' && product?.is_nls != 'in_active' && (product?.alternate_part?false:true))">
                  <div class="quantity-sec d-inline-flex">
                      <span class="mat-del" *ngIf=" (product.resource_type == 'Pack' ) && product?.uom">UNIT: &nbsp;</span>
                      <app-input-number aria-label="Quantity" size="lg" [min]="1"
                        (currentQuantity)="readQuantity($event,product,true)" [defaultValue]="product?.customerQuantity" [isTmgoValue]="product.resource_type == 'Pack' ?product.moq:'1'" [userType]="userData" [inventoryQty]="product?.inventory_quantity">
                      </app-input-number>&nbsp;
                      <span class="mat-del" *ngIf="(product.resource_type == 'Pack' && product.moq > 1) && product?.uom">&nbsp;&nbsp;QUANTITY:
                        &nbsp;</span>
                      <input type="text" class="qty-input input-number__input ipunitquantity" *ngIf="(product.resource_type == 'Pack' && product.moq > 1)" size="lg" disabled
                        value="{{  product?.moq * product?.customerQuantity }}" maxlength="50">
                      <!-- <div class="qty-input">
                                                <button class="qty-count qty-count--minus"
                                                     data-action="minus"
                                                    type="button">-</button>
                                                <input class="product-qty" type="number"
                                                    name="product-qty" [min]="1"
                                                    [max]="10" [value]="1"
                                                    numbersOnly maxlength="8">
                                                <button class="qty-count qty-count--add"
                                                     data-action="add"
                                                    type="button">+</button>
                                            </div> -->
                    </div>
                  </div><br/>
                  <span class="error-message-service text-nowrap" *ngIf="product?.is_nls == 'in_active'">No Longer Serviced</span>
                  <span class="error-message-service text-nowrap" *ngIf="product?.is_nls != 'in_active' && product?.alternate_part?true:false">
                  No Longer available
                </span>
                  <div class="col-12 view-alternate text-nowrap" *ngIf="product?.is_nls == 'in_active' || (product?.alternate_part?true:false)">
                        <span [routerLink]="['/shop/product-details']"
                        [queryParams]="{ part_Number : product.alternate_part }" style="cursor: pointer;">View Alternate Part</span>
                  </div>
  
  
                  <span class="error-message" *ngIf="(product.resource_type == 'Pack' && product.moq > 1) && product?.uom">Note: </span>
                          <span class="mat-del" *ngIf="(product.resource_type == 'Pack' && product.moq > 1) && product?.uom">1 Unit contains {{product.moq | number: '1.0-0'}} 
                            <span *ngIf="product.moq > 1">Quantities</span><span *ngIf="product.moq <= 1">Quantity</span><br></span>
                            <span class="error-message mt-4" *ngIf="partLimitCheck.length !=0 && isLoggedIn">Max quantity allowed is {{partLimitCheck[0]?.available_qty}}<br></span>
                <div *ngIf="userData?.user_type == 'MECH'">
                  <ng-container *ngIf="product.inventory_quantity > 0 && convertToInt(product.moq) <= product.inventory_quantity">
                    <div class="mt-3" *ngIf="product?.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                      <button class="btn btn-primary" (click)="addtoCart(product)">ADD TO CART</button>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="(product.inventory_quantity <= 0 || product.inventory_quantity == null || convertToInt(product.moq) > product.inventory_quantity) && product?.is_nls != 'in_active' && product?.alternate_part?false:true">
                    <span style="margin-top: 5px;" class="outOfStock mt-2">Out Of Stock</span>
                  </ng-container>
                </div>
                <div *ngIf="userData?.user_type != 'MECH'">
                  <div class="mt-3" *ngIf="product?.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                    <button class="btn btn-primary" (click)="addtoCart(product)">ADD TO CART</button>
                  </div>
                </div>
              
               
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 sec-4 mt-3 mt-lg-0">
                <div class="finance-offer mt-2" *ngIf="isLoggedIn && (userData?.user_type != 'MECH' && product.is_nls != 'in_active' && (product?.alternate_part?false:true))">
                  <div class="offer-header">
                    <h4>
                      <img width="25px" height="25px" src="assets/images/offerg-1550058133.png">
                      <span class="use-coupon-text">Other Offers</span></h4>
                  </div>           
                </div>
 <!--offersidebar-->
 <div class="sidebar shadow" [ngClass]="{ 'open': isSidebarOpen }">
  <div class="container header-bg">
    <a href="javascript:void(0)" class="closebtn" (click)="toggleSidebar('', '')">&times;</a>
    <h4 class="header-txt">{{displayToggleName == 'Default Discount' ? 'Standard Discount' :displayToggleName == 'Market Basket' ? 'Combo Offers': displayToggleName}}</h4>
    <hr style="color: #ebedf7;">
  </div>
  <div class="accordion p-2" style="max-height: 70vh;overflow: auto;scrollbar-width: thin;" id="accordionExample">
    <ng-container *ngIf="displayToggleName != 'Quantity Discount' && displayToggleName != 'Value Discount' && displayToggleName != 'Bulk Purchase'">
      <div *ngFor="let item of innerViewoffer; let i = index">
        <div class="accordion-item">
          <h2 class="accordion-header" [id]="'heading'+i">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse'+i" aria-expanded="false" [attr.aria-controls]="'collapse'+i" style="border: none;" (click)="item.discount_type == 'market_basket' ? marketBasketCollapeApi(item,i): ismarketbasketapicall =false">
              {{ item.discount_name ? item.discount_name : item.scheme_name  }}        
            </button>
          </h2>
          <div [id]="'collapse'+i" class="accordion-collapse collapse" [attr.aria-labelledby]="'heading'+i" data-bs-parent="#accordionExample" style="border: none;">
            <div class="accordion-body">
              <div *ngIf="!ismarketbasketapicall" style="    display: grid;
              grid-template-columns: 1fr 1fr;">
                <span><p class="headingFont" >Percentage</p><p class="innerheading">{{item.percentage}}</p></span>
                <span *ngIf="item.quantity"><p  class="headingFont">Quantity</p><p class="innerheading">{{item.quantity != 0 ? (item.quantity | number:'1.0-0') : 'NA'}}</p></span> 

                <span><p  class="headingFont">Start Date</p><p class="innerheading">{{(item.from_date ? item.from_date :item.start_dt) | date: "dd-MM-yyyy"}}</p></span>
  
                <span><p  class="headingFont">End Date</p><p class="innerheading">{{(item.to_date ? item.to_date : item.end_dt) | date: "dd-MM-yyyy"}}</p></span> 
                <span *ngIf="item.slab_from"><p  class="headingFont">Slab From</p><p class="innerheading">{{item.slab_from}}</p></span> 
                <span *ngIf="item.slab_to"><p  class="headingFont">Slab To</p><p class="innerheading">{{item.slab_to}}</p></span> 
                <span><p  class="headingFont">Status</p><p class="innerheading">{{item.is_active ? 'Active' : 'Not Active'}}</p></span>
    
              </div>
              <ng-container *ngIf="ismarketbasketapicall">
                <div style="width: 100%;">
                  <span *ngIf="marketBasketDescription"><p class="headingFont" >Description</p>
                    <div  class="innerheading" style="padding-bottom: 10px;">
                      <div [innerHTML]="marketBasketDescription" [class.collapsed]="isCollapsed" [style.height]="isCollapsed ? maxHeight+'px' : 'auto'">
                      </div>
                          <a *ngIf="isCollapsable && marketBasketDescription.length > 200" (click)="isCollapsed =! isCollapsed" style="cursor: pointer;">Read {{isCollapsed? 'more':'less'}}</a>
                    </div>
            
                  </span>
                  <div style="display: grid; grid-template-columns:24% 1fr 1fr;">
                    <span class="headingFont"></span>
                    <p class="headingFont">Quantity</p>
                    <p class="headingFont">Percentage</p>                               
                  </div>
                  <div style="    max-height: 20vh;
                  overflow: auto;">
                <div *ngFor="let slab of marketDetailsarr" style="display: grid; grid-template-columns:24% 1fr 1fr;">
                  <span><p class="innerheading">Slab {{slab.slab}}</p></span>
                  <p class="innerheading">{{ slab.quantity | number:'1.0-0'}}</p>
                  <p class="innerheading">{{ slab.percentage  }}</p>
                </div>
                  </div>
                
                </div>
                <hr>
                <div>
                  <p class="headingFont" >Part Number</p>
                    
                <!-- <div style="overflow: auto;
                width: 100%;
                max-height: 25vh;
                padding: 5px;"  >
                  <span *ngFor="let mrkdetail of newArrayforPartnumner;let counterIndex = index">
                    <p class="innerheading" style="display: flex;width: 100%;"> 
                    <span style="display: flex;
                    align-items: center;
                    width: 100%;cursor: pointer;" (click)="routeToProduct(mrkdetail)">
                      {{mrkdetail.part_name}}  {{mrkdetail.sku_item}}</span> 
                    <span *ngIf="mrkdetail.sku_item" style="align-items: center;
                    width: 100%;
                    display: flex;
                ">
                    <app-input-number aria-label="Quantity" size="lg" [min]="1" [max]="8" [isTmgoValue]="mrkdetail.resource_type == 'Pack' && mrkdetail.moq > 1?mrkdetail.moq:'1'"
                      (currentQuantity)="readproductdetaailQuantity($event,product,true,counterIndex)" [defaultValue]="mrkdetail.minimum_quantity">
                    </app-input-number>
                    <span style="position: relative;margin-left: 5px; width: 35px;" class="col-7 text-end d-flex align-items-center justify-content-center">
                      <button class="add-to-card-new-product" (click)="partAddtombCart(mrkdetail,counterIndex)"><img alt=""
                        src="./assets/images/cart.svg" /></button>
                    </span>
                  </span>
                </p>
              </span>
                 
                </div> -->
                <div style="overflow: auto;
                width: 100%;
                max-height: 25vh;
                padding: 5px;">
                <ng-container *ngFor="let mrkdetail of newArrayforPartnumner;let counterIndex = index">
                  <div style="display: grid;
                  grid-template-columns: 20% 40% 40%;text-align: center;
                  align-items: center;
                  justify-items: center;margin-bottom: 10px;">
                    <div>
                      <img *ngIf="mrkdetail.sku_item !== 'ALL'" (click)="routeToProduct(mrkdetail)" src="{{mrkdetail.part_image_path}}" height="50x" width="100%"  alt="banner 1">
                      <img *ngIf="mrkdetail.sku_item == 'ALL'" src="{{mrkdetail.part_image_path}}" height="50x" width="100%"  alt="banner 1">
                    </div>
                    <div>
                      <span *ngIf="mrkdetail.sku_item !== 'ALL'" (click)="routeToProduct(mrkdetail)" style="cursor: pointer;"><span style="font-weight: bold;">{{mrkdetail.part_name}}</span> {{mrkdetail.sku_item}}</span>
                      <span *ngIf="mrkdetail.sku_item == 'ALL'"><span style="font-weight: bold;">{{mrkdetail.part_name}}</span> {{mrkdetail.sku_item}}</span>
                    </div>
                    <div *ngIf="mrkdetail.sku_item !== 'ALL'" style="display: flex;align-items: center;">
                      <app-input-number aria-label="Quantity" size="lg" [min]="1" [max]="8" [isTmgoValue]="mrkdetail.resource_type == 'Pack' && mrkdetail.moq > 1?mrkdetail.moq:'1'"
                        (currentQuantity)="readproductdetaailQuantity($event,product,true,counterIndex)" [defaultValue]="mrkdetail.minimum_quantity" [userType]="userData" [inventoryQty]="mrkdetail.inventory_quantity">
                      </app-input-number>
                      <span style="position: relative;margin-left: 5px; width: 35px;" class="col-7 text-end d-flex align-items-center justify-content-center">
                        <ng-container *ngIf="userData?.user_type == 'MECH' && mrkdetail.inventory_quantity > 0 && mrkdetail.moq <= mrkdetail.inventory_quantity">
                          <button class="add-to-card-new-product" (click)="partAddtombCart(mrkdetail,counterIndex)"><img alt=""
                            src="./assets/images/cart.svg" /></button>
                        </ng-container>
                        <ng-container *ngIf="userData?.user_type == 'MECH' && (mrkdetail.inventory_quantity <= 0 || mrkdetail.inventory_quantity == null || convertToInt(mrkdetail.moq) > mrkdetail.inventory_quantity)">
                          <span style="margin-top: 5px;" class="outOfStock mt-2">Out Of Stock</span>
                        </ng-container>
                        <ng-container *ngIf="userData?.user_type != 'MECH'">
                          <button class="add-to-card-new-product" (click)="partAddtombCart(mrkdetail,counterIndex)"><img alt=""
                            src="./assets/images/cart.svg" /></button>
                        </ng-container>
                     
                      </span>
                    </div>
                  </div>
                  <hr>
                </ng-container>
             

                </div>
                </div>
          
              </ng-container>
            </div>
          </div>
        </div>
        <hr>
      </div>
    </ng-container>
    <ng-container *ngIf="displayToggleName == 'Quantity Discount' ||  displayToggleName == 'Value Discount' || displayToggleName == 'Bulk Purchase'">
      <div *ngFor="let item of uniqueListContact; let i = index">
        <div class="accordion-item">
          <h2 class="accordion-header" [id]="'heading'+i">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse'+i" aria-expanded="false" [attr.aria-controls]="'collapse'+i" style="border: none;" (click)="item.discount_type == 'market_basket' ? marketBasketCollapeApi(item,i): ismarketbasketapicall =false">
              {{ item.discount_name}}        
            </button>
          </h2>
          <div [id]="'collapse'+i" class="accordion-collapse collapse" [attr.aria-labelledby]="'heading'+i" data-bs-parent="#accordionExample" style="border: none;">
            <div class="accordion-body">
              <div style="    display: grid;
              grid-template-columns:24% 1fr 1fr 1fr;
              position: sticky;
              background: white;
              z-index: 999;
              width: 90%;">
               <span class="headingFont"></span>
               <ng-container *ngIf="displayToggleName == 'Quantity Discount'">
                <span class="headingFont">Quantity</span>
                <span class="headingFont">Percentage</span>
               </ng-container >
               <ng-container *ngIf="displayToggleName == 'Value Discount'">
               <span class="headingFont">Amount</span>
               <span class="headingFont">Discount</span>
              </ng-container>
              <ng-container *ngIf="displayToggleName == 'Bulk Purchase'">
                <span class="headingFont">From</span>
                <span class="headingFont">To</span>
                <span class="headingFont">Percentage</span>
               </ng-container >
              </div>
              <div style="    max-height: 20vh;
              overflow: auto;">
                <div *ngFor="let slab of item.newArr;let id = index" style="display: grid; grid-template-columns:24% 1fr 1fr 1fr;margin-top: 10px;">
                  <span><p class="innerheading">Slab {{ id + 1 }}</p></span>
                  <ng-container *ngIf="displayToggleName == 'Quantity Discount'">
                    <span><p class="innerheading">{{ slab.quantity  | number:'1.0-0'}}</p></span>
                    <span><p class="innerheading">{{ slab.discount_detail_percentage  }}</p></span>
                  </ng-container>
                  <ng-container *ngIf="displayToggleName == 'Value Discount'">
                    <span><p class="innerheading">{{ slab.value  | number:'1.0-0'}}</p></span>
                    <span><p class="innerheading">{{ slab.discount_amount != 0 && slab.discount_amount != null ? slab.discount_amount : slab.discount_detail_percentage  + '%' }}</p></span>
                  </ng-container>
                  <ng-container *ngIf="displayToggleName == 'Bulk Purchase'">
                    <span><p class="innerheading">{{ slab.slab_from  | number:'1.0-0'}}</p></span>
                    <span><p class="innerheading">{{ slab.slab_to  }}</p></span>
                    <span><p class="innerheading">{{ slab.percentage  }}</p></span>
                  </ng-container>
                </div>
              </div>
              <div *ngIf="!ismarketbasketapicall" style="    display: grid;
              grid-template-columns: 1fr 1fr;">
              
               <!-- <span *ngIf="item.discount_description"><p class="headingFont" >Description</p><p class="innerheading">{{item.discount_description}}</p></span> -->
                <span><p  class="headingFont">Start Date</p><p class="innerheading">{{(item.from_date ? item.from_date : item.start_dt) | date: "dd-MM-yyyy"}}</p></span>
                <span><p  class="headingFont">End Date</p><p class="innerheading">{{(item.to_date ? item.to_date : item.end_dt) | date: "dd-MM-yyyy"}}</p></span>
                <span><p  class="headingFont">Status</p><p class="innerheading">{{item.is_active ? 'Active' : 'Not Active'}}</p></span>
    
              </div>
            </div>
          </div>
        </div>
        <hr>
      </div>
    </ng-container>
               
  </div>
</div>
    <!--offersidebar-->
    <ng-container *ngIf="isLoggedIn && userData?.user_type != 'MECH' && product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                <div class="container offersection" *ngIf="storeCrouselname && storeCrouselname?.length > 0; else noDataMessage">
                  <ul [ngClass]="{'scrollable': storeCrouselname.length > 3}" class="mleft">
                    <li class="list-style" *ngFor="let data of storeCrouselname;let i = index">
                      <div class="card shadow m-auto p-2 sidecontainer mt-3 mb-2 h-100">
                        <div class="row mt-3">
                          <div class="col-8">
                            <span class="d-flex"><img src="../../../../assets/images/offerg-1550058133.png" style="height: 20px; width: 20px;"><span style="margin-left: 10px;word-break: break-all;text-wrap: nowrap;"><p class="offer-txt" style="font-size: 12px;"><strong>{{ data == 'Default Discount' ? 'Standard Discount' :data == 'Market Basket' ? 'Combo Offers': data}}</strong></p></span></span>   
                          </div>
                          <div class="col-4">
                            <p style="color: blue; font-size: 12px; text-align: center;"class="cursor" (click)="toggleSidebar(data,i)"><strong>View</strong></p>
                          </div>
                        </div>
                      </div> 
                    </li>
                  </ul>
          </div>
          <ng-template #noDataMessage>
            <div class="container offersection" *ngIf="isLoggedIn && userData?.user_type != 'MECH' && product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                <p class="mt-5"><strong> New Offer Not Available</strong></p>
            </div>
        </ng-template>
      </ng-container>
          <!-- <ng-template #skeletonForGrid>
            <div [ngStyle]="!storeCrouselname.length ? {'display':'none'}:''" *ngIf="storeCrouselname && storeCrouselname?.length == 0 && !isNoDataFound">
              <div class="col-12">
                <div class="row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4 row-cols-xl-4 row-cols-xxl-4 "
                  *ngFor="let item of generateFake(3);let i = index;">
                  <div class="card border-0" style="width: 100%;">
                    <div class="card-body">
                      <div class="prod-content position-relative">
                        <skc-rectangle [loading]="loading" [skc-height]="'12px'" [skc-width]="'250px'">
                        </skc-rectangle>
                      </div>
                      <div class="prod-img mx-auto text-center  position-relative">
                        <skc-rectangle [loading]="loading" [skc-height]="'12px'" [skc-width]="'250px'">
                        </skc-rectangle>
                        <skc-rectangle [loading]="loading" [skc-height]="'60px'" [skc-width]="'250px'">
                        </skc-rectangle>
                      </div>
                      <div class="prod-content position-relative">
                        <skc-rectangle [loading]="loading" [skc-height]="'8px'" [skc-width]="'250px'">
                        </skc-rectangle>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template> -->
                <!-- <div class="get-it-by">
                                    Get it by: <span>20 Sep</span>
                                </div> -->
                <!-- <div class="get-it-by" *ngIf="isLoggedIn">
                  Delivery Pincode: <span>{{pinCodeNumber == 0 ? '' : pinCodeNumber}}</span>
                  <a (click)="engineviewAddressModal()"  class="change">Change</a>
                </div> -->
                <!-- <li class="distributors drop-consumable" *ngIf="isLoggedIn && divisionInfoData">
                      <p class="mat-location-name">{{divisionInfoData?.ORG_NAME}}</p>
                      <div class="card consumable-hover-dropdown mt-2 shadow">
                        <div class="triangle"></div>
                        <div class="card-body consumable">
                          <div class="d-flex notify-heading">
                            <div class="row">
                      <p class="mat-deliver-address">
                        {{ divisionInfoData?.DIV_NAME != '' ? (divisionInfoData?.DIV_NAME + ',') : ''}}, 
                        {{ divisionInfoData?.PRIMISE_ADDRLINE1_s != '' ? (divisionInfoData?.PRIMISE_ADDRLINE1_s + ',') : ''}},
                        {{divisionInfoData?.PRIMISE_ADDRESS2_s != '' ? (divisionInfoData?.PRIMISE_ADDRESS2_s + ',') : ''}}</p>
                        </div>
                        </div>
                        </div>
                        </div>
                      </li> -->
                <!-- <span *ngIf="(validSchemeList && validSchemeList.length > 0) || (discountOfferData && discountOfferData.length > 0)" (click)="openSchemeListModal('otherOffer')" style="cursor: pointer; display: flex;margin-bottom: 1rem;">
                  <img width="25px" height="25px" src="assets/images/offerg-1550058133.png">
                  <p class="use-coupon-text">  View Offers</p>
                </span> -->
                <!-- <span *ngIf="showQuantityDiscountMessage" class="text-decoration-none save-mode"><span
                  class="highlights-header" *ngIf="!isshowMoreSlabDiscount"><img src="./assets/images/buy_more_new.png"
                    height="25%" width="25%">&nbsp;<span style="color: #1d3087;" *ngIf="(product.resource_type == 'Pack' && product.moq > 1)">ORDER
                      {{(discountOffers?.quantity / product.moq ) | number}} UNIT, GET
                      {{quantityBasedPercentage + '%'}} OFF</span>
                      <span style="color: #1d3087;" *ngIf="!(product.resource_type == 'Pack' && product.moq > 1)">ORDER
                        {{discountOffers?.quantity | number}} QUANTITY, GET
                        {{quantityBasedPercentage + '%'}} OFF</span>&nbsp;<br></span>
                        <span
                        class="highlights-header" *ngIf="isshowMoreSlabDiscount"><img src="./assets/images/buy_more_new.png"
                        height="25%" width="25%">&nbsp;<span style="color: #1d3087;cursor: pointer;" (click)="openSchemeListModal('slabOffer')">view quantity slabs</span></span> -->
                <!-- For Normal Quantity, you will get {{normalPercentage + '%'}} discount.<br>
                If you order {{discountOffers?.quantity | number}} Quantity, you will get
                {{quantityBasedPercentage + '%'}} -->
                <!-- If you order{{discountOffers?.quantity | number}} quantities of this part you will get additional discount
                of {{discountOffers?.percentage}}% -->
              <!-- </span> -->
                
                <div>
                  <!-- <span class="left-item d-inline-block ms-3">Only 4 left</span> -->
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 aggregate-list">
                <div id="aggregatelist1" class="owl-carousel owl-theme fleet-nav-slide">
                  <div class="" *ngFor="let obj of modelVcData" [ngClass]="(selectvc == obj?.vc_no) ? 'form-check form-check-inline parts-option select-part-option' :
                  'form-check form-check-inline parts-option'"  >
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="obj?.vc_no" checked=""
                      value="obj?.vc_no">
                    <label class="form-check-label" (click)="BindProduct(obj?.bom_uid,obj?.vc_no,0);" for="obj?.vc_no">{{obj?.vc_no}}</label>
                  </div>
                  <!-- <div class="form-check form-check-inline parts-option">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="Clutch2" checked=""
                      value="Clutch">
                    <label class="form-check-label" for="Clutch2">Clutch  Clutch v Clutch v v Clutch  Clutch v Clutch v v Clutch  Clutch v Clutch v v</label>
                  </div>
                  <div class="form-check form-check-inline parts-option">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="Clutch3" checked=""
                      value="Clutch">
                    <label class="form-check-label" for="Clutch3">Clutch  Clutch v Clutch v v Clutch  Clutch v Clutch v v Clutch  Clutch v Clutch v v</label>
                  </div>
                  <div class="form-check form-check-inline parts-option">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="Clutch4" checked=""
                      value="Clutch">
                    <label class="form-check-label" for="Clutch4">Clutch  Clutch v Clutch v v Clutch  Clutch v Clutch v v Clutch  Clutch v Clutch v v</label>
                  </div>
                  <div class="form-check form-check-inline parts-option">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="Clutch5" checked=""
                      value="Clutch">
                    <label class="form-check-label" for="Clutch5">Clutch  Clutch v Clutch v v Clutch  Clutch v Clutch v v Clutch  Clutch v Clutch v v</label>
                  </div> -->
                </div>
                
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 mt-3 large-img-container">
                <div class="img-part border">
                  <img alt="" class="img-fluid"  [src]="vcimage" />
                </div>
                
              </div>
              <div class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 mt-3 part-table">
                 <!-- //part-popup -->
         <app-partpopup *ngIf="showPopup" [showPart]="showPartData" (closePopup)="closePopup($event)" [pageName]="Isometric" [userDetail]="userData"></app-partpopup>
         <div class="table-responsive">
                  <table class="table table-sm" id="tbl-part-details">
                    <thead>
                      <tr>
                        <!-- <th></th> -->
                        <th>FIG</th>
                        <th>Part No.</th>
                        <th>Part Name</th>
                        <th>Price(₹) </th>
                        <!-- <th>MRP(₹) </th> -->
                        <th>Qty</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngIf="ProductListData?.length; else ProductListBlock">
                        <tr id="tr_{{items?.part_number}}" *ngFor="let items of ProductListData">
                          <!-- <td>
                            <input type="checkbox" />
                          </td> -->
                          <td>
                            {{ items?.fig_no }}
                          </td>
                          <td>
                            <a (click)="showparts(items)" class="btn-part-popup">{{ items?.part_number }}</a>
                          </td>
                          <td> {{ items?.short_description }}</td>
                          <td>
                            {{ items?.total_amount|currencyFormat:'INR' }}
                          </td>
                          <td>
                            <ng-container *ngIf="convertValue(items?.ProductMRP) != convertValue(items?.total_amount);else discontnLabel;">
                              <span style="text-decoration: line-through;" >
                                {{ items?.ProductMRP|currencyFormat:'INR' }}</span>
                            </ng-container>
                            <ng-template #discontnLabel>
                                  {{ items?.ProductMRP|currencyFormat:'INR' }}
                            </ng-template>
                          </td>
                          <td>
                            <ng-conatiner *ngIf="(userData?.user_type == 'MECH' && (product.inventory_quantity > 0 && product.inventory_quantity != null && convertToInt(product.moq) <= product.inventory_quantity)) || userData?.user_type != 'MECH'">
                              <app-input-number aria-label="Quantity" size="lg" [min]="1"
                              (currentQuantity)="readQuantityForEnginePartList($event,items)"  [defaultValue]="items?.min_quantity" [isTmgoValue]="item?.resource_type == 'Pack'? item?.moq :'1'" [userType]="userData" [inventoryQty]="item?.inventory_quantity">
                            </app-input-number>
                            </ng-conatiner>
                            <ng-container *ngIf="userData?.user_type == 'MECH' && (items.inventory_quantity <= 0 || items.inventory_quantity == null || convertToInt(items.moq) > items.inventory_quantity)">
                              <span style="margin-top: 5px;" class="outOfStock mt-2">Out Of Stock</span>
                            </ng-container>
                          </td>
                          <td>
                            <img  *ngIf="items.distributor_category == 'COM'"  class="cursor" id="btn_{{items?.part_number}}" alt="" src="./assets/images/cart.svg" (click)="showparts(items)" />
                          
                            <img *ngIf="items.distributor_category != 'COM'" class="cursor" id="btn_{{items?.part_number}}" alt="" src="./assets/images/cart.svg" (click)="addtoCartitemsForEnginePartList(items)" />
                          </td>
                        </tr>
                      </ng-container>
                      <ng-template #ProductListBlock>
                        <ng-container *ngIf="!isProductEmpty && !ProductListData?.length else noRecordsProductBlock">
                          <tr  *ngFor="let items of userService.generateFake(20); let i=index;">
                            <td>
                              <skc-rectangle [loading]="loading" [skc-height]="'24px'" [skc-width]="'20px'"> </skc-rectangle>
                            </td>
                            <td>
                              <skc-rectangle [loading]="loading" [skc-height]="'24px'" [skc-width]="'95px'"> </skc-rectangle>
                            </td>
                            <td> <skc-rectangle [loading]="loading" [skc-height]="'24px'" [skc-width]="'178px'"> </skc-rectangle></td>
                            <td><skc-rectangle [loading]="loading" [skc-height]="'24px'" [skc-width]="'64px'"> </skc-rectangle></td>
                            <td>
                              <skc-rectangle [loading]="loading" [skc-height]="'24px'" [skc-width]="'74px'"> </skc-rectangle>
                            </td>
                            <td>
                              <skc-rectangle [loading]="loading" [skc-height]="'24px'" [skc-width]="'18px'"> </skc-rectangle>
                            </td>
                          </tr>
                        </ng-container>
                      </ng-template>
                      <ng-template #noRecordsProductBlock>
                        <tr>
                          <th colspan="12">
                            <div class="no-record-block-product-details"  *ngIf="isProductEmpty">
                              <h6>No Record Found</h6>
                            </div>
                          </th>
                        </tr>
                      </ng-template>
                      <!-- <tr>
                        <td>
                          <input type="checkbox" />
                        </td>
                        <td>
                          1
                        </td>
                        <td>
                          <a href="javascript:void(0)" class="btn-part-popup">#286309130191</a>
                        </td>
                        <td>#286309130191</td>
                        <td>10,254.00</td>
                        <td>
                          <input type="text" class="form-control-sm" style="width: 80px;" />
                        </td>
                        <td>
                          <img alt="" src="./assets/images/cart.svg" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input type="checkbox" />
                        </td>
                        <td>
                          2
                        </td>
                        <td>
                          <a href="">#286309130191</a>
                        </td>
                        <td>#286309130191</td>
                        <td>10,254.00</td>
                        <td>
                          <input type="text" class="form-control-sm" style="width: 80px;" />
                        </td>
                        <td>
                          <img alt="" src="./assets/images/cart.svg" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input type="checkbox" />
                        </td>
                        <td>
                          2
                        </td>
                        <td>
                          <a href="">#286309130191</a>
                        </td>
                        <td>#286309130191</td>
                        <td>10,254.00</td>
                        <td>
                          <input type="text" class="form-control-sm" style="width: 80px;" />
                        </td>
                        <td>
                          <img alt="" src="./assets/images/cart.svg" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input type="checkbox" />
                        </td>
                        <td>
                          2
                        </td>
                        <td>
                          <a href="">#286309130191</a>
                        </td>
                        <td>#286309130191</td>
                        <td>10,254.00</td>
                        <td>
                          <input type="text" class="form-control-sm" style="width: 80px;" />
                        </td>
                        <td>
                          <img alt="" src="./assets/images/cart.svg" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input type="checkbox" />
                        </td>
                        <td>
                          2
                        </td>
                        <td>
                          <a href="">#286309130191</a>
                        </td>
                        <td>#286309130191</td>
                        <td>10,254.00</td>
                        <td>
                          <input type="text" class="form-control-sm" style="width: 80px;" />
                        </td>
                        <td>
                          <img alt="" src="./assets/images/cart.svg" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input type="checkbox" />
                        </td>
                        <td>
                          2
                        </td>
                        <td>
                          <a href="">#286309130191</a>
                        </td>
                        <td>#286309130191</td>
                        <td>10,254.00</td>
                        <td>
                          <input type="text" class="form-control-sm" style="width: 80px;" />
                        </td>
                        <td>
                          <img alt="" src="./assets/images/cart.svg" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input type="checkbox" />
                        </td>
                        <td>
                          -
                        </td>
                        <td>
                          <a href="">#286309130191</a>
                        </td>
                        <td>#286309130191</td>
                        <td>10,254.00</td>
                        <td>
                          <input type="text" class="form-control-sm" style="width: 80px;" />
                        </td>
                        <td>
                          <img alt="" src="./assets/images/cart.svg" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input type="checkbox" />
                        </td>
                        <td>
                          1
                        </td>
                        <td>
                          <a href="">#286309130191</a>
                        </td>
                        <td>#286309130191</td>
                        <td>10,254.00</td>
                        <td>
                          <input type="text" class="form-control-sm" style="width: 80px;" />
                        </td>
                        <td>
                          <img alt="" src="./assets/images/cart.svg" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input type="checkbox" />
                        </td>
                        <td>
                          2
                        </td>
                        <td>
                          <a href="">#286309130191</a>
                        </td>
                        <td>#286309130191</td>
                        <td>10,254.00</td>
                        <td>
                          <input type="text" class="form-control-sm" style="width: 80px;" />
                        </td>
                        <td>
                          <img alt="" src="./assets/images/cart.svg" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input type="checkbox" />
                        </td>
                        <td>
                          2
                        </td>
                        <td>
                          <a href="">#286309130191</a>
                        </td>
                        <td>#286309130191</td>
                        <td>10,254.00</td>
                        <td>
                          <input type="text" class="form-control-sm" style="width: 80px;" />
                        </td>
                        <td>
                          <img alt="" src="./assets/images/cart.svg" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input type="checkbox" />
                        </td>
                        <td>
                          2
                        </td>
                        <td>
                          <a href="">#286309130191</a>
                        </td>
                        <td>#286309130191</td>
                        <td>10,254.00</td>
                        <td>
                          <input type="text" class="form-control-sm" style="width: 80px;" />
                        </td>
                        <td>
                          <img alt="" src="./assets/images/cart.svg" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input type="checkbox" />
                        </td>
                        <td>
                          2
                        </td>
                        <td>
                          <a href="">#286309130191</a>
                        </td>
                        <td>#286309130191</td>
                        <td>10,254.00</td>
                        <td>
                          <input type="text" class="form-control-sm" style="width: 80px;" />
                        </td>
                        <td>
                          <img alt="" src="./assets/images/cart.svg" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input type="checkbox" />
                        </td>
                        <td>
                          2
                        </td>
                        <td>
                          <a href="">#286309130191</a>
                        </td>
                        <td>#286309130191</td>
                        <td>10,254.00</td>
                        <td>
                          <input type="text" class="form-control-sm" style="width: 80px;" />
                        </td>
                        <td>
                          <img alt="" src="./assets/images/cart.svg" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input type="checkbox" />
                        </td>
                        <td>
                          -
                        </td>
                        <td>
                          <a href="">#286309130191</a>
                        </td>
                        <td>#286309130191</td>
                        <td>10,254.00</td>
                        <td>
                          <input type="text" class="form-control-sm" style="width: 80px;" />
                        </td>
                        <td>
                          <img alt="" src="./assets/images/cart.svg" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input type="checkbox" />
                        </td>
                        <td>
                          1
                        </td>
                        <td>
                          <a href="">#286309130191</a>
                        </td>
                        <td>#286309130191</td>
                        <td>10,254.00</td>
                        <td>
                          <input type="text" class="form-control-sm" style="width: 80px;" />
                        </td>
                        <td>
                          <img alt="" src="./assets/images/cart.svg" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input type="checkbox" />
                        </td>
                        <td>
                          2
                        </td>
                        <td>
                          <a href="">#286309130191</a>
                        </td>
                        <td>#286309130191</td>
                        <td>10,254.00</td>
                        <td>
                          <input type="text" class="form-control-sm" style="width: 80px;" />
                        </td>
                        <td>
                          <img alt="" src="./assets/images/cart.svg" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input type="checkbox" />
                        </td>
                        <td>
                          2
                        </td>
                        <td>
                          <a href="">#286309130191</a>
                        </td>
                        <td>#286309130191</td>
                        <td>10,254.00</td>
                        <td>
                          <input type="text" class="form-control-sm" style="width: 80px;" />
                        </td>
                        <td>
                          <img alt="" src="./assets/images/cart.svg" />
                        </td>
                      </tr> -->

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
   
    <div class="row">
      <div class="col-12 col-sm-12 col-lg-12 col-xl-12">
        <div class="card shadow mb-3">
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 highlight-section">
                <h5 class="highlights">Highlights</h5>
                <ul class="lists-highlights">
                  <li>We have catalogue for each part we deliver</li>
                  <li>Purchase Parts at Discounted Rates</li>
                  <li>Part Recommendation to match your requirements</li>
                  <li>Get timely delivery of your order</li>
                  <!-- <li>High performance filtration systems play critical role in engines</li>
                                    <li>Nano fiber technology is applied to air filters.</li>
                                    <li>Exclusive filter media blend of natural and synthetic fibers provide superior performance and
                                      durability
                                      in air filters.</li> -->
                </ul>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 question-section">
                <h5>Do you have questions about this product ? <br>Please contact TML on
                  020-67136236 or</h5>
                <div class="mb-4">Visit 
                  <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=edukaansupport@tatamotors.com" target="_blank">edukaansupport@tatamotors.com</a> for more information or ask our experts!</div>
                <!-- <div class="form-group mb-3">
                                    <textarea class="form-control" placeholder="Type your question..."></textarea>
                                </div> 
                                <div class="text-end"><a href="javascript:void(0);">Submit</a></div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row">
            <div class="col-12 col-sm-12 col-lg-12 col-xl-12">
                <div class="card shadow mb-3">
                    <div class="card-body specfication-box-area">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <h5 class="mb-3">Specification</h5>
                                <div  class="table-responsive"><table  class="table tbl-Specification table-striped table-borderless"><tbody ><tr ><td >Part:</td><td >Air Filter BS3</td></tr><tr ><td >Brand:</td><td >TATA motors genuine parts</td></tr><tr ><td >Box dimensions:</td><td >Height: 12.10" Length: 12.10" Width: 6.10"</td></tr><tr ><td >Series:</td><td >Air filtration system</td></tr><tr ><td >Quantity Sold:</td><td >SKU47-21972 KN</td></tr><tr ><td >Recommended Use:</td><td >OE Replacement</td></tr><tr ><td >Material:</td><td >Synthetic paper foam</td></tr><tr ><td >Product Fit:</td><td >Exact Fit</td></tr><tr ><td >Alignment Tool Included:</td><td >With Alignment Tool</td></tr><tr ><td >Weight:</td><td >2 kg</td></tr><tr ><td >Release Bearing Included:</td><td >Direct Fit</td></tr><tr ><td >Clutch Disc Included:</td><td >With Alignment Tool</td></tr><tr ><td >Pilot Bushing Included:</td><td >With Pilot Bushing</td></tr><tr ><td >Disc Diameter:</td><td >8.38 in. Disc Dia</td></tr><tr ><td >Spline Count:</td><td ></td></tr></tbody></table></div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 border-start">
                                <h5 class="mb-3">Compatible Models (2)</h5>
                                <div class="table-responsive">
                                  <table class="table table-compatible">
                                    <thead class="text-uppercase">
                                      <tr>
                                        <th>Model</th>
                                        <th>Variant</th>
                                        <th>Engine</th>
                                        <th>Capacity</th>
                                        <th>Fuel</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <img src="" alt="" class="img-fluid"
                                            style="max-width: 60px;">
                                          <div class="model">PRIMA</div>
                                          <div class="variants">4 Variants</div>
              
                                        </td>
                                        <td>
                                          <table class="table table-borderless table-sm table align-middle mb-0">
                                            <tbody>
                                              <tr>
                                                <td>Prima LX 4928. S SRT</td>
                                              </tr>
                                              <tr>
                                                <td>Prima LX 4928. S SRT</td>
                                              </tr>
                                              <tr>
                                                <td>Prima LX 4928. S SRT</td>
                                              </tr>
                                              <tr>
                                                <td>Prima LX 4928. S SRT</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                        <td>
                                          <table class="table table-borderless table-sm table align-middle mb-0">
                                            <tbody>
                                              <tr>
                                                <td>0.7 L</td>
                                              </tr>
                                              <tr>
                                                <td>0.7 L</td>
                                              </tr>
                                              <tr>
                                                <td>0.7 L</td>
                                              </tr>
                                              <tr>
                                                <td>0.7 L</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                        <td>
                                          <table class="table table-borderless table-sm table align-middle mb-0">
                                            <tbody>
                                              <tr>
                                                <td>16 h.p.</td>
                                              </tr>
                                              <tr>
                                                <td>16 h.p.</td>
                                              </tr>
                                              <tr>
                                                <td>16 h.p.</td>
                                              </tr>
                                              <tr>
                                                <td>16 h.p.</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                        <td>
                                          <table class="table table-borderless table-sm table align-middle mb-0">
                                            <tbody>
                                              <tr>
                                                <td>Diesel</td>
                                              </tr>
                                              <tr>
                                                <td>Diesel</td>
                                              </tr>
                                              <tr>
                                                <td>Diesel</td>
                                              </tr>
                                              <tr>
                                                <td>Diesel</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <img src="" alt="" class="img-fluid"
                                            style="max-width: 60px;">
                                          <div class="model">PRIMA</div>
                                          <div class="variants">4 Variants</div>
                                        </td>
                                        <td>
                                          <table class="table table-borderless table-sm table align-middle mb-0">
                                            <tbody>
                                              <tr>
                                                <td>Prima LX 4928. S SRT</td>
                                              </tr>
                                              <tr>
                                                <td>Prima LX 4928. S SRT</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                        <td>
                                          <table class="table table-borderless table-sm table align-middle mb-0">
                                            <tbody>
                                              <tr>
                                                <td>0.7 L</td>
                                              </tr>
                                              <tr>
                                                <td>0.7 L</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                        <td>
                                          <table class="table table-borderless table-sm table align-middle mb-0">
                                            <tbody>
                                              <tr>
                                                <td>16 h.p.</td>
                                              </tr>
                                              <tr>
                                                <td>16 h.p.</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                        <td>
                                          <table class="table table-borderless table-sm table align-middle mb-0">
                                            <tbody>
                                              <tr>
                                                <td>Diesel</td>
                                              </tr>
                                              <tr>
                                                <td>Diesel</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div class="waranty-policy">
                                  <h6>Warranty Policy</h6>
                                  <p>Right parts, guaranteed. If you find any defects, let us know within 90 days and we’ll take care
                                    of it.
                                    Check out our Warranty Policy for more details.</p>
                                </div>
                                <div class="shipping-policy">
                                  <h6>Shipping Policy</h6>
                                  <p>We ship fast so you can get on the road that much faster. Check out our Shipping Policy for more
                                    details.
                                  </p>
                                </div>
                                <div class="return-policy">
                                  <h6>Easy Return Policy</h6>
                                  <p>We’re only happy if you’re happy. If you’re not entirely satisfied with your order, you may be
                                    entitled
                                    to a refund. Check out our Return Policy for more details.</p>
                                </div>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row pt-2 rel-prod">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <h4 class=" mb-3 rel-prod-heading ">Related Products</h4>
            </div>
            
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                <div id="related-product">
                    <div class="item m-2">
                      <div class="card">
                        <div class="row g-0 align-items-center">
                          <div class="col-md-4">
                            <div class="prod-img mx-auto">
                              <img src="" class="img-fluid" alt="prod-img-alt" srcset="">
                            </div>
                          </div>
                          <div class="col-md-8">
                              <div class="card-body">
                                <div class="prod-content position-relative">
                                    <div class="bookmarks-fav text-end p-0 position-absolute">
                                      <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                    </div>
                                    <h3 class="fw-bold prod-model mb-2">264154249903</h3>
                                    <h4 class="prod-name mb-2">Assy. Mag. Valve (Ex.brake) w/voss 24V</h4>
                                    <p class="fw-bold"><span class="fw-normal">Estd. Delivery:</span> Today</p>
                                    
                                  
                                      <div class="mt-3">
                                        
                                        <div class="price fw-bold ">
                                            8,383.00
                                          <span>
                                            MRP 8,383.00 </span>
                                        </div>
                                      </div>
                                  </div>   
                              </div>
                                                       
                          </div>
                        </div>
                        <div class="card-footer bg-white ">
                            <div class="row g-0 align-items-center">
                                <div class="col-4"></div>
                                <div class="col-8 d-flex justify-content-between">
                                    <app-input-number aria-label="Quantity" size="lg" [min]="1" >
                                    </app-input-number>
                                    <div class="d-grid gap-2">
                                      <button class="add-to-card"><img alt="" src="./assets/images/cart.svg" class="d-inline-block"/> Add to Cart</button>
                                    </div>
                                </div>
                            </div>
                            
                          
                        </div>
                      </div>
                    </div>
                    <div class="item m-2">
                        <div class="card">
                          <div class="row g-0 align-items-center">
                            <div class="col-md-4">
                              <div class="prod-img mx-auto">
                                <img src="" class="img-fluid" alt="prod-img-alt" srcset="">
                              </div>
                            </div>
                            <div class="col-md-8">
                                <div class="card-body">
                                  <div class="prod-content position-relative">
                                      <div class="bookmarks-fav text-end p-0 position-absolute">
                                        <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                      </div>
                                      <h3 class="fw-bold prod-model mb-2">264154249903</h3>
                                      <h4 class="prod-name mb-2">Assy. Mag. Valve (Ex.brake) w/voss 24V</h4>
                                      <p class="fw-bold"><span class="fw-normal">Estd. Delivery:</span> Today</p>
                                      
                                    
                                        <div class="mt-3">
                                          
                                          <div class="price fw-bold">
                                              8,383.00
                                            <span>
                                              MRP 8,383.00 </span>
                                          </div>
                                        </div>
                                    </div>   
                                </div>
                                                         
                            </div>
                          </div>
                          <div class="card-footer bg-white ">
                              <div class="row g-0 align-items-center">
                                  <div class="col-4"></div>
                                  <div class="col-8 d-flex justify-content-between">
                                      <app-input-number aria-label="Quantity" size="lg" [min]="1" >
                                      </app-input-number>
                                      <div class="d-grid gap-2">
                                        <button class="add-to-card"><img alt="" src="./assets/images/cart.svg" class="d-inline-block"/> Add to Cart</button>
                                      </div>
                                  </div>
                              </div>
                              
                            
                          </div>
                        </div>
                      </div>
                      <div class="item m-2">
                        <div class="card">
                          <div class="row g-0 align-items-center">
                            <div class="col-md-4">
                              <div class="prod-img mx-auto">
                                <img src="" class="img-fluid" alt="prod-img-alt" srcset="">
                              </div>
                            </div>
                            <div class="col-md-8">
                                <div class="card-body">
                                  <div class="prod-content position-relative">
                                      <div class="bookmarks-fav text-end p-0 position-absolute">
                                        <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                      </div>
                                      <h3 class="fw-bold prod-model mb-2">264154249903</h3>
                                      <h4 class="prod-name mb-2">Assy. Mag. Valve (Ex.brake) w/voss 24V</h4>
                                      <p class="fw-bold"><span class="fw-normal">Estd. Delivery:</span> Today</p>
                                      
                                    
                                        <div class="mt-3">
                                          
                                          <div class="price fw-bold">
                                              8,383.00
                                            <span>
                                              MRP 8,383.00 </span>
                                          </div>
                                        </div>
                                    </div>   
                                </div>
                                                         
                            </div>
                          </div>
                          <div class="card-footer bg-white ">
                              <div class="row g-0 align-items-center">
                                  <div class="col-4"></div>
                                  <div class="col-8 d-flex justify-content-between">
                                      <app-input-number aria-label="Quantity" size="lg" [min]="1" >
                                      </app-input-number>
                                      <div class="d-grid gap-2">
                                        <button class="add-to-card"><img alt="" src="./assets/images/cart.svg" class="d-inline-block"/> Add to Cart</button>
                                      </div>
                                  </div>
                              </div>
                              
                            
                          </div>
                        </div>
                      </div>
                      <div class="item m-2">
                        <div class="card">
                          <div class="row g-0 align-items-center">
                            <div class="col-md-4">
                              <div class="prod-img mx-auto">
                                <img src="" class="img-fluid" alt="prod-img-alt" srcset="">
                              </div>
                            </div>
                            <div class="col-md-8">
                                <div class="card-body">
                                  <div class="prod-content position-relative">
                                      <div class="bookmarks-fav text-end p-0 position-absolute">
                                        <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                      </div>
                                      <h3 class="fw-bold prod-model mb-2">264154249903</h3>
                                      <h4 class="prod-name mb-2">Assy. Mag. Valve (Ex.brake) w/voss 24V</h4>
                                      <p class="fw-bold"><span class="fw-normal">Estd. Delivery:</span> Today</p>
                                      
                                    
                                        <div class="mt-3">
                                          
                                          <div class="price fw-bold ">
                                              8,383.00
                                            <span>
                                              MRP 8,383.00 </span>
                                          </div>
                                        </div>
                                    </div>   
                                </div>
                                                         
                            </div>
                          </div>
                          <div class="card-footer bg-white ">
                              <div class="row g-0 align-items-center">
                                  <div class="col-4"></div>
                                  <div class="col-8 d-flex justify-content-between">
                                      <app-input-number aria-label="Quantity" size="lg" [min]="1" >
                                      </app-input-number>
                                      <div class="d-grid gap-2">
                                        <button class="add-to-card"><img alt="" src="./assets/images/cart.svg" class="d-inline-block"/> Add to Cart</button>
                                      </div>
                                  </div>
                              </div>
                              
                            
                          </div>
                        </div>
                      </div>
                    
                  </div>
            </div>
        </div> -->
  
  </div>
</div>
<ng-template #schemeList let-d="dismiss">
  <app-product-details-schemes (closeModel)="schemeListModal.hide()" [schemeDetails]="validSchemeList" [offerDetails]="discountOfferData" [slabOffer]="discountSlabOffer"
  [popupOpenType]="popupOpenType"></app-product-details-schemes>
</ng-template>
<ng-container *ngIf="showLoader">
  <div class="loader">
    <div class="ng-spinner ng-spinner-icon"></div>
  </div>
</ng-container>

<ng-template #fullImagePopup>
  <app-full-image-popup [modalImage]="modalImage"  [modelClose]="fullimageModal"></app-full-image-popup>
</ng-template>