import { NgModule } from '@angular/core';
// modules (angular)
import { CommonModule } from '@angular/common';
// modules
import { AccountRoutingModule } from './account-routing.module';
import { SharedModule } from '../../shared/shared.module';
// components
import { LayoutComponent } from './components/layout/layout.component';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogModule} from  '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { LoginComponent } from "./pages/login/login.component";
import { GoogleAnalyticsService } from "../../shared/services/GoogleAnalytics/GoogleAnalytics.service";
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { CookieModule } from 'ngx-cookie';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OtpComponent } from "./pages/otp/otp.component";
import { NgOtpInputModule } from  'ng-otp-input';
import { HomeComponent } from './pages/home/home.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { UserProfileComponent } from "./pages/user-profile/user-profile.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { AddressComponent } from './pages/address/address.component';
import { MatRadioModule } from '@angular/material/radio';
import { NgSelectModule } from '@ng-select/ng-select';
import { AccountComponent } from './pages/account/account.component';
import { UserManagementComponent } from './pages/user-management/user-management.component';
import { UserPermissionComponent } from './pages/user-permission/user-permission.component';
import { MatSelectModule } from '@angular/material/select';
import { BulkCartComponent } from './pages/bulk-cart/bulk-cart.component';
import { ReportComponent } from "./pages/report/report.component";
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SetPasswordComponent } from './pages/set-password/set-password.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTabsModule } from '@angular/material/tabs';
// pages
import { DataTablesModule } from 'angular-datatables';
import { EtaReportComponent } from './pages/eta-report/eta-report.component';
import { DatePipe } from '@angular/common';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxSkeletonSmartModule } from 'ngx-smart-skeleton';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MyOffersComponent } from './pages/my-offers/my-offers.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CreditListComponent } from './pages/credit-list/credit-list.component';
import { MyCouponsComponent } from './pages/my-coupons/my-coupons.component';
import { MySchemeComponent } from './pages/my-scheme/my-scheme.component';
import { FitmentSegmentListComponent } from './pages/fitment-segment-list/fitment-segment-list.component';
import { MyComboOfferComponent } from './pages/my-combo-offer/my-combo-offer.component';
import { ProfileloyalityComponent } from './pages/profileloyality/profileloyality.component';
@NgModule({
    declarations: [
        LayoutComponent,
        LoginComponent,
        CreditListComponent,
        OtpComponent,
        HomeComponent,SetPasswordComponent,
        RegistrationComponent,UserPermissionComponent,BulkCartComponent,ReportComponent,
        UserProfileComponent,AddressComponent,AccountComponent,UserManagementComponent,EtaReportComponent, MyOffersComponent, MyCouponsComponent, MySchemeComponent, FitmentSegmentListComponent,MyComboOfferComponent,ProfileloyalityComponent
    ],
    imports: [
        CommonModule,NgOtpInputModule,
        DataTablesModule,
        NgxSkeletonSmartModule,
        AccountRoutingModule,
        SharedModule,
        MatDialogModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,
        MatCardModule,
        MatCheckboxModule,
        MatButtonModule,MatRadioModule,
        ModalModule.forRoot(),
        CookieModule.forRoot(),
        FormsModule, ReactiveFormsModule,
        MatDatepickerModule,MatNativeDateModule,
        NgSelectModule,MatSelectModule,
        MatAutocompleteModule,
        MatGridListModule,
        MatTabsModule,
        CarouselModule,
        MatTooltipModule,
        MatSlideToggleModule
    ],
    entryComponents:[],
    providers:[
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        GoogleAnalyticsService,BsModalService,DatePipe
    ]
})
export class AccountModule { }
