<!-- <div class="modal-dialog modal-xl modal-dialog-centered"> -->

  <div class="modal-header modal-title" *ngIf="isReport">
    Order Details
    <div (click)="closeModal()" type="button"><i class="fa fa-times"></i></div>
  </div>
  <div class="modal-header modal-title" *ngIf="isReturn">
    Cancel Parts
    <div (click)="closeModal()" type="button"><i class="fa fa-times"></i></div>
  </div>
  <div class="modal-header modal-title" *ngIf="isCancelOrderSelected">
    Cancelled Order Details
    <div (click)="closeModal()" type="button"><i class="fa fa-times"></i></div>
  </div>
  <div class="modal-header modal-title" *ngIf="isInvoiceReturnSelected">
    Invoice Return Details
    <div (click)="closeModal()" type="button"><i class="fa fa-times"></i></div>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <ul class="feedback-order-series"
          *ngIf="isReport">
          <li *ngIf="order?.order_number">
            <div class="o-title">Order Number</div>
            <div class="o-desc">{{ order?.order_number }}</div>
          </li>
          <li *ngIf="order?.otc_order_number">
            <div class="o-title">OTC No.</div>
            <div class="o-desc">{{ order?.otc_order_number }}</div>
          </li>
          <li
            *ngIf="order?.CUSTOMER_POWO_s !== '' && order?.CUSTOMER_POWO_s !== undefined && order?.CUSTOMER_POWO_s !== null">
            <div class="o-title">PO No.</div>
            <div class="o-desc">{{ order?.CUSTOMER_POWO_s }}</div>
          </li>
          <li>
            <div class="o-title">Order Date</div>
            <div class="o-desc">{{ order?.order_date | date: 'dd-MM-yyyy h:mm:ss a' }}</div>
          </li>
          <li>
            <div class="o-title">Order Value</div>
            <div class="o-desc">{{ orderFinalTotal?.toFixed(0) | currencyFormat:'INR' ||
                            0}}</div>
          </li>
          <li *ngIf="cancelOrderParts?.coupon_code">
            <div class="o-title">Coupon Code</div>
            <div class="o-desc">{{ cancelOrderParts?.coupon_code }}</div>
          </li>
          <li *ngIf="order?.invoice_status != '' && order?.invoice_status != undefined && order?.invoice_status != null">
            <div class="o-title">Invoice Details</div>
            <div class="o-desc">{{order?.invoice_status | titlecase}}</div>
          </li>
          <li *ngIf="order?.payment_method">
            <div class="o-title">Payment Method </div>
            <div class="o-desc">
              {{ order?.payment_method =='COD' ? 'On Credit': order?.payment_method
                            =='COD_LOYALTY' ? 'On Credit & Loyalty':order?.payment_method =='WIRE_TRANSFER' ? 'Online
                            Transfer' : order?.payment_method =='WIRE_TRANSFER_LOYALTY' ? 'Online Transfer &
                            Loyalty': order?.payment_method == 'CASH_INVOICE' ? 'Cash Invoice' :  order?.payment_method == 'Mintifi' ? 'Mintifi' : order?.payment_method}}</div>

          </li>
          <li *ngIf="order?.status != '' && order?.status != null && order?.status != undefined">
            <div class="o-title">Status </div>
            <div class="o-desc">{{ order?.status}}</div>
          </li>
          <!-- <li>
                        <div class="o-title">Received by </div>
                        <div class="o-desc">Nikhil J</div>
                    </li> -->
        </ul>
        <ul class="feedback-order-series"
          *ngIf="isReturn">
          <li *ngIf="order?.order_number">
            <div class="o-title">Order No.</div>
            <div class="o-desc">{{ order?.order_number }}</div>
          </li>
          <li *ngIf="order?.otc_number">
            <div class="o-title">OTC No.</div>
            <div class="o-desc">{{ order?.otc_number }}</div>
          </li>
          <li
            *ngIf="order?.CUSTOMER_POWO_s !== '' && order?.CUSTOMER_POWO_s !== undefined && order?.CUSTOMER_POWO_s !== null">
            <div class="o-title">PO No.</div>
            <div class="o-desc">{{ order?.CUSTOMER_POWO_s }}</div>
          </li>
          <!-- <li *ngIf="order?.order_date">
            <div class="o-title">Order Date</div>
            <div class="o-desc">{{ order?.order_date | date: 'dd-MM-yyyy h:mm:ss a' }}</div>
          </li> -->
          <li *ngIf="order?.order_date && !isOrderInProcessSelected; else elseOrderInProcessNoOrderDate">
            <div class="o-title">Order Date</div>
            <div class="o-desc">{{ order?.order_date | date: 'dd-MM-yyyy h:mm:ss a' }}</div>
          </li>
          <ng-template #elseOrderInProcessNoOrderDate>
            <li>
              <div class="o-title">Order Created Date</div>
              <div class="o-desc">{{ order?.created_at | date: 'dd-MM-yyyy h:mm:ss a' }}</div>
            </li>
          </ng-template>
          <li>
            <div class="o-title">Order Value</div>
            <div class="o-desc">{{ order?.order_amount.toFixed(0) | currencyFormat:'INR'
                            || 0}}</div>
          </li>
          <li *ngIf="order?.coupon_code">
            <div class="o-title">Coupon Code</div>
            <div class="o-desc">{{ order?.coupon_code }}</div>
          </li>
          <li *ngIf="order.invoice_status != '' && order.invoice_status != undefined && order.invoice_status != null">
            <div class="o-title">Invoice Details</div>
            <div class="o-desc">{{order.invoice_status | titlecase}}</div>
          </li>
          <li *ngIf="order?.payment_method">
            <div class="o-title">Payment Method </div>
            <div class="o-desc">
              {{ order?.payment_method =='COD' ? 'On Credit': order?.payment_method
                            =='COD_LOYALTY' ? 'On Credit & Loyalty':order?.payment_method =='WIRE_TRANSFER' ? 'Online
                            Transfer' : order?.payment_method =='WIRE_TRANSFER_LOYALTY' ? 'Online Transfer &
                            Loyalty': order?.payment_method == 'CASH_INVOICE' ? 'Cash Invoice' :  order?.payment_method == 'Mintifi' ? 'Mintifi' : order?.payment_method}}</div>

          </li>
          <li *ngIf="order?.status != '' && order?.status != null && order?.status != undefined">
            <div class="o-title">Status </div>
            <div class="o-desc">{{ order?.status}}</div>
          </li>
          <!-- <li>
                        <div class="o-title">Received by </div>
                        <div class="o-desc">Nikhil J</div>
                    </li> -->
        </ul>
        <ul class="feedback-order-series" *ngIf="isCancelOrderSelected">
          <li>
            <div class="o-title">Order No.</div>
            <div class="o-desc">{{ order?.order_number }}</div>
          </li>
          <li *ngIf="order.otc_order_number">
            <div class="o-title">OTC No.</div>
            <div class="o-desc">{{ order?.otc_order_number }}</div>
          </li>
          <li *ngIf="order?.coupon_code">
            <div class="o-title">Coupon Code</div>
            <div class="o-desc">{{ order?.coupon_code }}</div>
          </li>
          <li>
            <div class="o-title">Cancel Request No.</div>
            <div class="o-desc">{{ order?.cancel_order_number }}</div>
          </li>
          <li>
            <div class="o-title">Cancel Request Date</div>
            <div class="o-desc" *ngIf="!isReturn">{{ order?.created_at | date: 'dd-MM-yyyy h:mm:ss a'}}</div>
          </li>
          <li>
            <div class="o-title">Order Date</div>
            <div class="o-desc">{{order?.order_date | date: 'dd-MM-yyyy h:mm:ss a'}}</div>
          </li>
          <!-- <li>
            <div class="o-title">Distributor Approval Status</div>
            <div class="o-desc">{{ order?.approved_status == 'Approved' ? order?.approved_status : 
                            order?.approved_status =='Rejected' ? 'Rejected' + ' ('+ order?.admin_reject_reason + ')'  :
                            order?.approved_status}}</div>
          </li> -->
          <li>
            <div class="o-title">Cancellation Status </div>
            <div class="o-desc">{{ order?.cr_request_status | titlecase}}</div>
          </li>
          <li *ngIf="order?.user_cancel_reason != '' && order?.user_cancel_reason != null && order?.user_cancel_reason != undefined">
            <div class="o-title">Reason for Cancellation</div>
            <div class="o-desc">{{ order?.user_cancel_reason}}</div>
          </li>
          <li *ngIf="order?.user_cancel_reason === 'Other Reason'">
            <div class="o-title">Remarks</div>
            <div class="o-desc">{{ order?.user_other_reason}}</div>
          </li>
        </ul>
        <ul class="feedback-order-series" *ngIf="isInvoiceReturnSelected">
          <li>
            <div class="o-title">Order No.</div>
            <div class="o-desc">{{ order?.order_number }}</div>
          </li>
          <li *ngIf="order.otc_order_number">
            <div class="o-title">OTC No.</div>
            <div class="o-desc">{{ order?.otc_order_number }}</div>
          </li>
          <li *ngIf="order?.coupon_code">
            <div class="o-title">Coupon Code</div>
            <div class="o-desc">{{ order?.coupon_code }}</div>
          </li>
          <li>
            <div class="o-title">Return Request No.</div>
            <div class="o-desc">{{ order?.cancel_order_number }}</div>
          </li>
          <li>
            <div class="o-title">Return Request Date</div>
            <div class="o-desc" *ngIf="!isReturn">{{ order?.created_at | date: 'dd-MM-yyyy h:mm:ss a'}}</div>
          </li>
          <li>
            <div class="o-title">Order Date</div>
            <div class="o-desc">{{order?.order_date | date: 'dd-MM-yyyy h:mm:ss a'}}</div>
          </li>
          <!-- <li>
            <div class="o-title">Distributor Approval Status</div>
            <div class="o-desc">{{ order?.approved_status == 'Approved' ? order?.approved_status : 
                            order?.approved_status =='Rejected' ? 'Rejected' + ' ('+ order?.admin_reject_reason + ')'  :
                            order?.approved_status}}</div>
          </li> -->
          <li>
            <div class="o-title">Return Status </div>
            <div class="o-desc">{{ order?.cr_request_status | titlecase}}</div>
          </li>
          <li>
            <div class="o-title">Reason for Return</div>
            <div class="o-desc">{{ order?.user_cancel_reason}}</div>
          </li>
          <li *ngIf="order?.user_cancel_reason === 'Other Reason'">
            <div class="o-title">Remarks</div>
            <div class="o-desc">{{ order?.user_other_reason}}</div>
          </li>
        </ul>
      </div>
      <div class="col-12" style="margin-bottom:0.5rem;">
        <strong>
          <span class="error-message">**</span>
          <ng-container *ngIf="order?.status == 'On Hold' || order?.status == 'Temp Order' else elseNoOnHoldOrder">
            Order validity for {{ order?.status == 'Temp Order'?'Temporary':order?.status }} order is only 15 days, these orders will be automatically cancelled if not processed.
          </ng-container>
          <ng-template #elseNoOnHoldOrder>
            Order validity is 90 days and Order will be cancelled automatically if not serviced in 90 days.
          </ng-template>
        </strong>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6" *ngIf="(returnOrderList.length > 0 && isReturn) || isCancelOrderSelected || isInvoiceReturnSelected || isReport;">
        <strong style="font-size: 11px;" *ngIf="isOrderQuantityDiscountBased"><span 
          style="color: red;">(*)</span>
          Parts with Quantity Discount cannot be cancelled
        </strong>
        <h3 class="feedback-tbl-header">
          Parts in this order
        </h3>
        <div class="table-responsive">
          <table class="table table-md cancel-part-table">
            <thead>
              <tr>
                <th>
                  <div class="form-check form-check-inline mb-0" style="margin:0;min-height: 16px;" *ngIf="returnOrderList?.length && isReturn">
                    <input class="form-check-input" type="checkbox" id="allSelected" [checked]="isAllChecked || makeAllDisabled" [disabled]="makeAllDisabled"
                      (change)="selectAllItem($event)"/>
                  </div>
                </th>
                <th *ngIf="isInvoiceReturnSelected">Invoice No.</th>
                <th>Parts</th>
                <th>Unit price</th>
                <th>Total Qty</th>

                <th *ngIf="returnOrderList?.length && isReturn">Pending Qty</th>
                <!-- <th>Invoiced Qty</th>
                <th>Cancelled Qty</th>
                <th>Pending Qty</th>
                <th>Returned Qty</th> -->
                <th>(₹)Amount</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="returnOrderList?.length && isReturn">
                <tr *ngFor="let item of returnOrderList; let i = index">
                  <td class="text-nowrap">
                    <div class="form-check form-check-inline mb-0" style="margin:0;min-height: 16px;">
                      <input class="form-check-input" value="{{item}}"
                        [checked]="getStatus(item,'Checked')"
                        [disabled]="getStatus(item,'Disabled')"
                        (change)="selectItem($event,item)" type="checkbox" />
                    </div>
                    {{i+1}}
                  </td>
                  <td (click)="routeToProductDetails(item?.part_number);" class="text-nowrap">
                    <div class="showcursor d-inline-flex">
                      <img alt="" [src]="item?.images"
                        style="width: 44px; height: 30px;">
                    </div>
                    <div style="vertical-align: top; flex-direction: column;" class="showcursor d-inline-flex ps-2">
                      <div *ngIf="isReturn" class="tbl-c-title" style="cursor: pointer;" 
                        title="{{ (item?.part_desc == '' || item?.part_desc == null) ? '-' : item?.part_desc}}">
                        {{ (item?.part_desc == " " || item?.part_desc == null) ? "-" :
                                                item?.part_desc?.substr(0,13)}}
                      </div>
                      <div class="tbl-c-desc" >{{ item?.part_number }}<span *ngIf="(item?.invoiced_quantity > 0 && (item?.tml_discount_type === 'quantity_discount' || item?.dealer_discount_type === 'quantity_discount'))"
                        style="color: red;">(*)</span></div>
                    </div>
                  </td>
                  <!-- <td>{{item?.rate | currencyFormat:'INR'}}
                    <div class="td-actual-mrp"> <span *ngIf="item?.rate < item?.umrp">MRP
                        {{item?.umrp | currencyFormat:'INR'}}
                      </span>
                    </div>
                  </td> -->
                   <!-- change calculation devendra start -->
                  <td>{{ (item?.total_amount/item?.total_quantity ) | currencyFormat:'INR'}}
                    <div class="td-actual-mrp" *ngIf="(item?.total_amount/item?.total_quantity) > item?.siebel_umrp">
                      <span>MRP {{item?.siebel_umrp | currencyFormat:'INR'}}
                      </span>
                    </div>
                  </td>  
                  <!-- change calculation devendra end -->
                  <td>{{ item?.total_quantity }}</td>
                  <!-- pending -->

                  <td *ngIf="returnOrderList?.length && isReturn">{{ item?.pending_quantity }}</td>
                  <td class="text-end">{{ taxInclusivePrice(item) | currencyFormat:'INR' }}</td>
                </tr>
              </ng-container>
              <ng-container *ngIf="isCancelOrderSelected || isInvoiceReturnSelected">
                <tr *ngFor="let item of cancelOrderParts?.order_cancelation_line; let i = index">
                  <td>{{i+1}}</td>
                  <td *ngIf="isInvoiceReturnSelected">{{ item?.invoice_no }}</td>
                  <td (click)="routeToProductDetails(item?.part_number);" class="text-nowrap">
                    <div class="showcursor d-inline-block" >
                      <img alt="" [src]="item?.part_images"
                        style="width: 44px; height: 30px;">
                    </div>
                    <div style="vertical-align: top; flex-direction: column;" class="showcursor d-inline-block ps-2">                     
                      <div class="tbl-c-title" style="cursor: pointer;" 
                        title="{{ (item?.part_desc == '' || item?.part_desc == null) ? '-' : item?.part_desc}}">
                        {{ (item?.part_desc == " " || item?.part_desc == null) ? "-" :
                        item?.part_desc?.substr(0,20)}}
                      </div>
                      <div class="tbl-c-desc" >{{
                        item?.part_number }}</div>
                    </div>
                  </td>
                  <td>{{ (item?.total_amount/item?.total_quantity ) | currencyFormat:'INR'}}
                    <div class="td-actual-mrp" *ngIf="(item?.total_amount/item?.total_quantity) > item?.siebel_umrp">
                      <span>MRP {{item?.siebel_umrp | currencyFormat:'INR'}}
                      </span>
                    </div>
                  </td>
                  <td>{{ item?.total_quantity }}</td>
                  <!-- pending -->
                  <!-- <td>{{ item?.total_quantity - item?.returned_quantity }}</td> -->
                  <td class="text-end">{{ item?.total_amount  | currencyFormat:'INR' }}</td>
                </tr>
              </ng-container>
              <ng-container *ngIf="isReport">
                <tr *ngFor="let item of order?.order_line_items; let i = index">
                  <td>{{i+1}}</td>
                  <td (click)="routeToProductDetails(item?.part_number);" class="text-nowrap">
                    <div class="showcursor d-inline-block" >
                      <img alt="" [src]="item?.part_cover_image"
                        style="width: 44px; height: 30px;">
                    </div>
                    <div style="vertical-align: top; flex-direction: column;" class="showcursor d-inline-block ps-2">                  
                     
        <div class="tbl-c-title" style="cursor: pointer;" 
          title="{{ (item?.part_desc == '' || item?.part_desc == null) ? '-' : item?.part_desc}}">
          {{ (item?.part_desc == " " || item?.part_desc == null) ? "-" :
                        item?.part_desc?.substr(0,19)}}
        </div>
        <div class="tbl-c-desc" >{{
          item?.part_number }}</div>
                    </div>
                  </td>
                  <td>{{item?.item_final_amount | currencyFormat:'INR'}}
                    <div class="td-actual-mrp"> <span
                        *ngIf="item?.mrp > item?.item_final_amount">MRP
                        {{item?.mrp | currencyFormat:'INR'}}
                      </span>
                    </div>
                  </td>
                  <td>{{ item?.quantity }}</td>
                  <td class="text-end">{{ getTotal(item) | currencyFormat:'INR' }}</td>
                </tr>
              </ng-container>
            </tbody>
            <tfoot>
              <tr>
                <!-- <th colspan="6" class="text-end" *ngIf="isReturn">Total : {{
                                    orderValue.toFixed(0) |
                                    currencyFormat:'INR' || 0}}</th> -->
                <th colspan="6" class="text-end" *ngIf="isReport">Total : {{ subTotal?.toFixed(0) | currencyFormat:'INR' ||
                                    0}}</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <ng-container *ngIf="isReport">
          <div class="table-responsive">
            <table class="table mat-price-table">
              <thead>
                <tr>
                  <th>Total</th>
                  <th class="text-end">{{ subTotal?.toFixed(2) | currencyFormat:'INR'}}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Base Discount</td>
                  <td class="text-end"><span class="me-3">(-)</span>{{ baseDiscount |
                                        currencyFormat:'INR'}}</td>
                </tr>
                <tr>
                  <td>Scheme Discount <span></span></td>
                  <td class="text-end"><span class="me-3">(-)</span>{{schemediscount || 0 |
                                        currencyFormat:'INR'}}</td>
                </tr>
                <tr>
                  <td>TATA motors Discount <span></span></td>
                  <td class="text-end"><span class="me-3">(-)</span>{{ total_tml_rule_discount || 0 |
                                        currencyFormat:'INR' }}</td>
                </tr>
                <tr>
                  <td>Distributor Discount</td>
                  <td class="text-end"><span class="me-3">(-)</span>{{ total_dealer_rule_discount || 0
                                        | currencyFormat:'INR' }}
                  </td>
                </tr>
                <tr>
                  <td>Additional Discount</td>
                  <td class="text-end"><span class="me-3">(-)</span>{{ total_welcome_discount || 0
                                        | currencyFormat:'INR' }}
                  </td>
                </tr>
                <tr *ngIf="total_coupon_discount > 0;">
                  <td>Coupon Discount</td>
                  <td class="text-end"><span class="me-3">(-)</span>{{ total_coupon_discount || 0
                                        | currencyFormat:'INR' }}
                  </td>
                </tr>
                <tr *ngIf="total_cash_discount_amount > 0;">
                  <td>Cash Discount</td>
                  <td class="text-end"><span class="me-3">(-)</span>{{ total_cash_discount_amount || 0
                                        | currencyFormat:'INR' }}
                  </td>
                </tr>
                <tr>
                  <td>GST <span></span></td>
                  <td class="text-end"><span class="me-3">(+)</span>{{ taxTotal?.toFixed(2) || 0 |
                                        currencyFormat:'INR'}}</td>
                </tr>
              </tbody>
            </table>
            <div class="d-flex mat-grand-total border-top border-bottom">
              <div class="col" class="feedback-tbl-header"> Grand Total </div>
              <div class="col text-end feedback-tbl-header">{{ orderFinalTotal?.toFixed(0) || 0 |
                                currencyFormat:'INR'}}</div>
            </div>
          </div>
          <br>
          <div class="d-flex justify-content-between align-items-center mb-2 " *ngIf="order?.billing_address != '' && order?.billing_address != null && order?.billing_address != undefined">
            <h4 class="feedback-tbl-header">Billing Address:</h4>
          </div>
          <p class="mat-location-name">{{userDetails?.account_name}}<br>
          <p class="mat-location-name">{{ order?.billing_address?.bill_address_1 }}</p>
          <p class="mat-deliver-address">{{ order?.billing_address?.bill_address_2 }}{{
                        order?.billing_address?.bill_taluka?(order?.billing_address?.bill_taluka+','):'' }}
            {{ order?.billing_address?.bill_city?(order?.billing_address?.bill_city+','):'' }}
            {{ order?.billing_address?.bill_district?(order?.billing_address?.bill_district+','):'' }}
            {{ order?.billing_address?.bill_pincode?( ' - '+order?.billing_address?.bill_pincode+', '):'' }}
            {{ order?.billing_address?.bill_state ? (order?.billing_address?.bill_state+'.'):'' }}<br>
            {{ order?.billing_address?.bill_country }}
          </p><br>
          <p class="mat-deliver-address" *ngIf="order?.billing_address?.bill_gst_no">
            GST No.: {{ order?.billing_address?.bill_gst_no }}
          </p>

          <div class="d-flex justify-content-between align-items-center mb-2 " *ngIf="order?.shipping_address != '' && order?.shipping_address != null && order?.shipping_address != undefined">
            <h4 class="feedback-tbl-header">Shipping Address:</h4>
          </div>
          <p class="mat-location-name">{{ order?.shipping_address?.ship_address_1 }}</p>
          <p class="mat-deliver-address">{{ order?.shipping_address?.ship_address_2 }}
            {{ order?.shipping_address?.ship_taluka?(order?.shipping_address?.ship_taluka+','):'' }}
            {{ order?.shipping_address?.ship_city?(order?.shipping_address?.ship_city+','):'' }}
            {{ order?.shipping_address?.ship_district?(order?.shipping_address?.ship_district+','):'' }}
            {{ order?.shipping_address?.ship_pincode?( ' - '+order?.shipping_address?.ship_pincode+', '):'' }}
            {{ order?.shipping_address?.ship_state ? (order?.shipping_address?.ship_state+'.'):'' }}<br>
            {{ order?.shipping_address?.ship_country }}
          </p>
          <!-- <div class="d-flex justify-content-between align-items-center mb-2 ">
            <h4 class="feedback-tbl-header">Contact Person Details:</h4>
          </div>
          <p  class="mat-contact-person-detail">
            Name : SUDHARSHAN CHAKRAPANI<br>
            Number : 9744939256<br>
          </p> -->
          <div class="d-flex justify-content-between align-items-center mb-2 " *ngIf="order?.contact_person_detail  && order?.contact_person_detail.length > 0 ">
            <h4 class="feedback-tbl-header">Contact Person Details:</h4>
          </div>
          <p *ngIf="order?.contact_person_detail  && order?.contact_person_detail.length > 0 " class="mat-contact-person-detail">
            Name : {{ order?.contact_person_detail[0].first_name }} {{ order?.contact_person_detail[0].last_name }}<br>
            Number : {{ order?.contact_person_detail[0].contact_number }}<br>
          </p>
          <div class="d-flex justify-content-between align-items-center mb-2 " *ngIf="order?.division_address != '' && order?.division_address != null && order?.division_address != undefined">
            <h4 class="feedback-tbl-header">Distributor Details:</h4>
          </div>
          <p class="mat-location-name">{{ order?.division_address?.division_org_name }}</p>
          <p class="mat-deliver-address">{{
                        order?.division_address?.division_taluka?(order?.division_address?.division_taluka+','):'' }}
            {{ order?.division_address?.division_city?(order?.division_address?.division_city+','):'' }}
            {{ order?.division_address?.division_district?(order?.division_address?.division_district+','):''
                        }}
            {{ order?.division_address?.division_pincode?( ' - '+order?.division_address?.division_pincode+',
                        '):'' }}
            {{ order?.division_address?.division_state ? (order?.division_address?.division_state+','):''
                        }}<br>
            {{ order?.division_address?.division_contact_no ? (order?.division_address?.division_contact_no+'.'):''
                        }}
          </p>
          <p class="mat-deliver-address" *ngIf="order?.division_address?.GSTIN">
            GST No&nbsp;:&nbsp;{{ order?.division_address?.GSTIN }}
          </p>
          <!-- <br><br><br><br><br><br> -->

        </ng-container>
        <ng-container *ngIf="isReturn && partsToReturn?.length">
          <h4 class="rate-feedback-header">
            Parts to be cancelled
          </h4>
          <div class="table-responsive">
            <table class="table table-sm">
              <thead *ngIf="partsToReturn?.length">
                <tr>
                  <th></th>
                  <th>Parts</th>
                  <th>Unit price</th>
                  <th>Qty</th>
                  <th>(₹)Amount</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngIf="partsToReturn?.length">
                  <tr *ngFor="let item of partsToReturn; let i = index">
                    <td *ngIf="isReturn">
                      <div class="form-check form-check-inline mb-0" style="min-height: 16px;">
                        <!-- <input class="form-check-input" (change)="selectItem($event,item)" type="checkbox" /> -->
                      </div>
                      {{i+1}}
                    </td>
                    <td (click)="routeToProductDetails(item?.part_number);" class="text-nowrap">
                      <div  class="showcursor d-inline-flex">
                        <img alt="" [src]="item?.images" style="width: 44px; height: 30px;">
                      </div>
                      <div style="vertical-align: top; flex-direction: column;" class="showcursor d-inline-flex ps-2">                     
                        <div class="tbl-c-title"
                          title="{{ (item?.part_desc == '' || item?.part_desc == null) ? '-' : item?.part_desc}}">
                          {{ (item?.part_desc == " " || item?.part_desc == null) ? "-" :
                          item?.part_desc?.substr(0,20)}}</div>
                                                    <div class="tbl-c-desc">{{
                                                      item?.part_number }}</div>
                      </div>
                    </td>
                    <!-- <td>{{item?.rate | currencyFormat:'INR'}}
                    <div class="td-actual-mrp"> <span *ngIf="item?.rate < item?.umrp">MRP
                        {{item?.umrp | currencyFormat:'INR'}}
                      </span>
                    </div>
                  </td> -->
                   <!-- change calculation devendra start -->
                  <td>{{ (item?.total_amount/item?.total_quantity ) | currencyFormat:'INR'}}
                    <div class="td-actual-mrp" *ngIf="(item?.total_amount/item?.total_quantity) > item?.siebel_umrp">
                      <span>MRP {{item?.siebel_umrp | currencyFormat:'INR'}}
                      </span>
                    </div>
                  </td>  
                  <!-- change calculation devendra end -->
                    <td>{{ item.pending_quantity }}</td>
                    <td class="text-end">{{ cancellationPrice(item) | currencyFormat:'INR' }}</td>
                  </tr>
                </ng-container>
              </tbody>
              <tfoot>
                <tr>
                  <th colspan="5" class="text-end" *ngIf="partsToReturn?.length > 0">Total : {{
                                        sumOfParts?.toFixed(0) |
                                        currencyFormat:'INR' || 0}}</th>
                </tr>
              </tfoot>
            </table>
          </div>
          <ng-container [formGroup]="orderCancelForm">
            <div class="address-card__name">Reason For Cancellation<span class="error-message">*</span>:<br>
            </div>
            <div class="row mb-3">
              <div class="col-12 col-sm-12 position-relative">
                <select id="checkout-state" formControlName="ReasonRepalcement" required aria-placeholder="Select Reason" placeholder="Select Reason"
                  class="form-control form-control-select2" (change)="OnchangeReason($event.target.value)">
                  <option value="" selected disabled>-- Select Reason --</option>
                  <option *ngFor="let reasons of cancelReasons" [value]="reasons">{{reasons}}</option>
                  <!-- <option value="Wrong Part">Wrong Part</option>
                  <option value="Part broken">Part broken</option>
                  <option value="Short quantity">Short quantity</option>
                  <option value="Excess quantity">Excess quantity</option>
                  Newly added reason start
                  <option value="Incorrect part ordered">Incorrect part ordered</option>
                  <option value="My customer has cancelled the order">My customer has cancelled the order</option>
                  <option value="Incorrect Billing or delivery address selected / incorrect contact number entered">Incorrect Billing or delivery address selected / incorrect contact number entered</option>
                  <option value="Seller denied delivery (credit related, outstanding etc)">Seller denied delivery (credit related, outstanding etc)</option>
                  
                  <option value="Part locally arranged as ETA has been exceeded">Part locally arranged as ETA has been exceeded</option>
                  <option value="Can't wait, ETA is too high">Can't wait, ETA is too high</option>
                  <option value="Incorrect quantity ordered">Incorrect quantity ordered</option>
                  <option value="Part available at lower price">Part available at lower price</option>
                  <option value="Incorrect GST no entered in the order">Incorrect GST no entered in the order</option>
                  Newly added reason end
                  <option value="Other Reason">Other Reason</option> -->
                </select>
              </div>
            </div>
            <div class="row mb-3" *ngIf="showOther">
              <div class="col-12 col-sm-12 position-relative">
                <label class="Help-us-serve-you-be" style="margin-bottom: 1rem;">Help us to serve you better</label>
                <textarea class="form-control" rows="5" style="resize: none;" formControlName="OtherReason" maxlength="100" placeholder="Enter remarks in case of Other Reason (Character length cannot exceed 100 characters.)"></textarea>
                <!-- <a class="textarea-mic" style="position: absolute; top: 30px; right: 18px; z-index: 1;"><img alt=""
                  src="./assets/images/noun-mic.svg" /></a> -->
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-12 text-end">
                <button type="button" class="btn btn-sm btn-primary"
                  (click)="OrderCanceldetail('CancelOrder')">Submit</button>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="isCancelOrderSelected || isInvoiceReturnSelected">
          <div class="d-flex justify-content-between align-items-center mb-2 " *ngIf="cancelOrderBillingAddress">
            <h4 class="feedback-tbl-header">Billing Address:</h4>
          </div>
          <!-- <p class="mat-location-name">{{ cancelOrderParts?.ABILL_ADDR_s }}</p> -->
            
            <p class="mat-location-name">{{userDetails?.account_name}}<br></p>
          <p class="mat-deliver-address" *ngIf="cancelOrderBillingAddress">{{cancelOrderBillingAddress.street_address1}}
          </p>
          <p class="mat-deliver-address" *ngIf="cancelOrderBillingAddress">
            {{ cancelOrderBillingAddress?.street_address2 }}
            {{ cancelOrderBillingAddress.taluka?(cancelOrderBillingAddress.taluka+','):'' }}
            {{ cancelOrderBillingAddress.city?(cancelOrderBillingAddress.city+','):'' }}
            {{ cancelOrderBillingAddress.district?(cancelOrderBillingAddress.district+','):'' }}
            {{ cancelOrderBillingAddress.postal_code?( ' - '+cancelOrderBillingAddress.postal_code+', '):'' }}
            {{ cancelOrderBillingAddress.state ? (cancelOrderBillingAddress.state+'.'):'' }}<br>
            {{ cancelOrderBillingAddress.country }}
          </p><br>
          <p *ngIf="cancelOrderBillingAddress?.gst_no != '' && cancelOrderBillingAddress?.gst_no != null && cancelOrderBillingAddress?.gst_no != undefined">GST No.: {{cancelOrderBillingAddress?.gst_no}}</p>

          <div class="d-flex justify-content-between align-items-center mb-2 " *ngIf="cancelOrderShippingAddress">
            <h4 class="feedback-tbl-header">Shipping Address:</h4>
          </div>
          <p class="mat-location-name" *ngIf="cancelOrderShippingAddress">{{userDetails?.account_name}}<br></p>
          <p class="mat-deliver-address" *ngIf="cancelOrderShippingAddress">
            {{ cancelOrderShippingAddress?.street_address1 ? cancelOrderShippingAddress?.street_address1 + ',' : ''}}
            {{ cancelOrderShippingAddress?.street_address2 ? cancelOrderShippingAddress?.street_address2 + ',' : ''}}
            {{ cancelOrderShippingAddress.taluka?(cancelOrderShippingAddress.taluka+','):'' }}
            {{ cancelOrderShippingAddress.city?(cancelOrderShippingAddress.city+','):'' }}
            {{ cancelOrderShippingAddress.district?(cancelOrderShippingAddress.district+','):'' }}
            {{ cancelOrderShippingAddress.postal_code?( ' - '+cancelOrderShippingAddress.postal_code+', '):'' }}
            {{ cancelOrderShippingAddress.state ? (cancelOrderShippingAddress.state+'.'):'' }}<br>
            {{ cancelOrderShippingAddress.country }}
          </p>
          <p class="mat-location-name" *ngIf="cancelOrderShippingAddress?.contact_no">Contact No. +91
            {{ cancelOrderShippingAddress?.contact_no }}</p>
          <!-- <p class="mat-location-name" *ngIf="cancelOrderShippingAddress?.gst_no !=''">GST No.
            {{ cancelOrderShippingAddress?.gst_no }}</p> -->
        </ng-container>
      </div>
      
    </div>
    <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6" *ngIf="returnOrderList.length == 0 && isReturn && !showLoader">
      <div>
          <strong><span class="error-message">**</span>&nbsp;Currently, Invoice is generated for this order.</strong>&nbsp;<strong>You can return the order.</strong> <span class="error-message"></span><br>
      </div>
  </div>
  <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6" *ngIf="makeAllDisabled && returnOrderList.length > 0;">
    <div>
        <strong><span class="error-message">**</span>&nbsp;You have already raised a request to cancel this order.</strong>&nbsp;
    </div>
</div>
  </div>
  <!-- </div> -->

  <ng-template #cancelOrder let-d="dismiss">
    <app-logout [orderCancel]="orderCancel" (userLogout)="orderCancelled($event)"></app-logout>
  </ng-template>

  <ng-container *ngIf="showLoader">
    <div class="loader">
      <div class="ng-spinner ng-spinner-icon"></div>
    </div>
  </ng-container>
