<div class="modal-content" style="width: 100%; margin-left: 0px;">
  <div class="modal-header modal-title">
    Credit Details
    <div (click)="closeModal()" type="button"><i class="fa fa-times"></i></div>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="table-responsive">
        <table class="table table-md">
          <thead>
            <tr>
              <th class="text-nowrap">Credits Used</th>
              <th class="text-nowrap">Credit Available</th>
              <th class="text-nowrap">{{creditDetails?.user_credit_days ? 'Credit Period(Days)' : 'Due Date'}}</th>
              <th class="text-nowrap">Pending Order Amount</th>
              <th class="text-nowrap">Pending Invoice Amount</th>
              <th class="text-nowrap">Overdue Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let credit of creditDetailsList">
              <td> {{ (credit?.creditsUsed != null ) ? ( credit?.creditsUsed  | currencyFormat:'INR':true:'1.0-0' ) : 0 }}</td>
              <td>{{ (credit?.availableLimit != null ) ? ( credit?.availableLimit  | currencyFormat:'INR':true:'1.0-0' ) : 0 }}</td>
              <td class="text-nowrap" *ngIf="credit?.user_credit_days">{{ ( credit?.user_credit_days != null ) ? (credit?.user_credit_days + ' Days'):0 }}</td>
              <td class="text-nowrap" *ngIf="credit?.user_credit_duedate">{{credit?.user_credit_duedate | date: 'dd MMM, y'}}</td>
              <td>{{ (credit?.pending_order_amount != null) ? ( credit?.pending_order_amount  | currencyFormat:'INR':true:'1.0-0' ) : 0 }}</td>
              <td>{{ (credit?.pending_invoice_amount != null) ? ( credit?.pending_invoice_amount  | currencyFormat:'INR':true:'1.0-0' ):0 }}</td>
              <td>{{ (credit?.overdue_amount != null) ? ( credit?.overdue_amount | currencyFormat:'INR':true:'1.0-0'): 0 }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
