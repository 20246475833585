<section class="ng-sign  p-sm-4 p-md-5" [ngClass]="(showHeader) ? 'clsmargin' : ''">
    <div class="d-flex justify-content-center justify-content-sm-between otp-wrapper">
        <div class="sec-logo d-none d-sm-block">
            <div class="mat-aap">
                <h3 class="mb-0">AAP Ke liye</h3>
                <h4 class="mb-0">Apno ke liye</h4>
            </div>
            <div class="benfits bg-white">
                <h5 class="mb-0">Benefits :</h5>
                <p class="mb-0">Higher stability | Long life | Value for money</p>
            </div>
        </div>
        <mat-card class="login-form-card p-4 p-sm-5 otp-form-card">
            <mat-card-header class="justify-content-center mb-4">
                <mat-card-title class="mat-login-header">
                    <div class="login-header-img text-end">
                        <img src="./assets/images/logo.png" class="img-fluid" />
                    </div>
                    <h1>e- Dukaan</h1>
                </mat-card-title>
                <mat-card-subtitle class="mat-subtitle" *ngIf="!isGuestLogin">Sign in for eDukaan</mat-card-subtitle>
                <mat-card-subtitle class="mat-subtitle" *ngIf="isGuestLogin">Guest Login for eDukaan</mat-card-subtitle>
            </mat-card-header>
            <!-- For Login OTP -->
            <mat-card-content *ngIf="isLogin">
                <p class="mb-4">OTP has been sent to your registered Mobile No. +91 {{this.loginForm.value.contact_no
                    | mask}} and will be valid for 10 mins</p>
                <form>
                    <div class="form-group text-center">
                        <ng-otp-input (onInputChange)="onOtpChange($event)"
                            class="otp-input-control" [config]="config" (keyup.enter)="nextClicked()" #ngOtpInput>
                        </ng-otp-input>
                    </div>
                    <br />
                    <div class="text-center">
                        <button type="button" class="btn btn-primary text-uppercase login-btn"
                            (click)="nextClicked()">Next</button>&nbsp;&nbsp;
                            <button type="button" class="btn btn-primary text-uppercase login-btn"
                            (click)="resendOnSubmitOTP()" [disabled]="timeLeft !== 0 && isTimer" >Resend OTP</button>
                            <!-- <button type="button" class="btn btn-primary text-uppercase login-btn"
                            (click)="resendOnSubmitOTP()" *ngIf="timeLeft == 0">Resend OTP</button> -->
                        <!-- <p class="mt-2 mb-0" *ngIf="timeLeft !== 0 && isTimer">
                            Resend in {{ m ? m + " :" : "" }} {{ s ? s : "" }}
                        </p> -->
                        <!-- <p class="mt-2 mb-0 text-decoration-none"><a class="text-primary text-decoration-none" (click)="resendOnSubmitOTP()"
                                class="btn-resend" style="cursor: pointer;text-decoration: none !important;" *ngIf="timeLeft == 0">Resend OTP</a></p> -->
                                <p class="mt-2 mb-2" *ngIf="timeLeft == 0">After Resend OTP, earlier OTP will be invalid</p>
                                <p class="mt-2 mb-2">Go back to <a class="text-primary text-decoration-none" href="#">Homepage</a></p>
                                <p class="mt-2 mb-2">Re-Enter <a style="cursor: pointer;" class="text-primary text-decoration-none" (click)="backToLogin();">Mobile Number</a></p>
                    </div>
                </form>

                <p class="text-center">Not your number? Call <a class="text-primary text-decoration-none" href="tel:1800 209 7979">1800
                        209 7979</a>
                    or email
                    <!-- <a class="mailto:customercare@tatamotors.com text-decoration-none" style="cursor: pointer;text-decoration: none;">customercare@tatamotors.com </a> -->
                    <a class="mailto:customercare@tatamotors.com text-decoration-none" href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=customercare@tatamotors.com" style="cursor: pointer;text-decoration: none;" target="_blank">customercare@tatamotors.com</a>
                </p>
                <!-- <p class="text-center">Already have an account? <a class="text-primary" href="#">Login</a></p> -->
            </mat-card-content>
            <!-- For Registration OTP -->
            <mat-card-content *ngIf="isRegistration">
                <!-- *ngIf="timeLeft !== 0 && isTimer" -->
                <p class="mb-4" *ngIf="!isGuestLogin">
                    OTP has been sent to your mobile number +91 {{this.registrationForm.value.contact_no |
                    mask}} and will be valid for 10 mins</p>
                    <p class="mb-4" *ngIf="isGuestLogin">
                      OTP has been sent to your mobile number +91 {{this.guestLoginForm.value.contact_no |
                      mask}} and will be valid for 10 mins</p>
                <form [formGroup]="registrationForm" *ngIf="!isGuestLogin">
                    <div class="form-group text-center">
                        <ng-otp-input (onInputChange)="onOtpChange($event)" #ngOtpInput
                            class="otp-input-control" [config]="config" (keyup.enter)="nextClicked()">
                        </ng-otp-input>
                    </div>
                    <br />
                    <br />
                    <div class="form-group">
                        <mat-form-field appearance="standard" floatLabel="always" class="w-100">
                            <mat-label class="label-control">Password</mat-label>
                            <input matInput [type]="!hide ? 'password' : 'text'" formControlName="password"
                                maxlength="14" minlength="6" autocomplete="off" class="input-control" matInput
                                placeholder="Enter password" (keyup.enter)="nextClicked()">
                            <mat-error *ngIf="f.password?.errors !== null && f.password?.errors?.required">Password is
                                required</mat-error>
                            <mat-error *ngIf="f.password?.errors !== null && f.password?.errors?.minlength">Password must
                                be at least 6 characters
                            </mat-error>
                            <mat-icon matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                                [attr.aria-pressed]="hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                        </mat-form-field>
                    </div>
                    <div class="form-group" >
                        <mat-form-field appearance="standard" floatLabel="always" class="w-100">
                            <mat-label class="label-control">Confirm password</mat-label>
                            <input matInput [type]="!isHidden ? 'password' : 'text'" class="input-control"
                                maxlength="14" minlength="6" formControlName="confirm_password" (keyup.enter)="nextClicked()">
                            <mat-error *ngIf="f.confirm_password?.errors !== null && f.confirm_password?.errors?.required">
                                Confirm
                                Password is required</mat-error>
                            <!-- <mat-error *ngIf="">
                                Password and
                                confirm password does not match</mat-error> -->
                            <mat-error
                                *ngIf="(f.confirm_password?.errors !== null && f.confirm_password?.errors?.mustMatch) ||
                                (f.confirm_password?.value?.length < 6 && f.confirm_password?.value?.length !== 0)">
                                Password and
                                confirm password does not match</mat-error>
                            <mat-icon matSuffix (click)="isHidden = !isHidden"
                                [attr.aria-label]="'Hide confirm password'" [attr.aria-pressed]="hide">{{hide ?
                                'visibility' : 'visibility_off'}}</mat-icon>
                        </mat-form-field>
                    </div>
                    <div class="text-center mt-5">
                        <button type="button" class="btn btn-primary text-uppercase login-btn"
                        (click)="nextClicked()"  >REGISTER</button>&nbsp;&nbsp;
                        <button type="button" class="btn btn-primary text-uppercase login-btn"
                        (click)="resendOnSubmitOTP()" [disabled]="timeLeft !== 0 && isTimer" >Resend OTP</button>
                                <p class="mt-2 mb-2" *ngIf="timeLeft == 0">After Resend OTP, earlier OTP will be invalid</p>
                                <p class="mt-2 mb-2">Go back to <a style="cursor: pointer;" class="text-primary text-decoration-none" href="#">Homepage</a></p>
                                <p class="mt-2 mb-2">Re-Enter <a style="cursor: pointer;" class="text-primary text-decoration-none" (click)="backToRegistration();">Mobile Number</a></p>
                    </div>
                </form>
                <form [formGroup]="guestLoginForm" *ngIf="isGuestLogin">
                  <div class="form-group text-center">
                      <ng-otp-input (onInputChange)="onOtpChange($event)" 
                          class="otp-input-control" [config]="config" (keyup.enter)="nextClicked()" #ngOtpInput>
                      </ng-otp-input>
                  </div>
                  <br />
                  <br />
                  <div class="form-group">
                      <mat-form-field appearance="standard" floatLabel="always" class="w-100">
                          <mat-label class="label-control">Password</mat-label>
                          <input matInput [type]="!hide ? 'password' : 'text'" formControlName="password"
                              maxlength="14" minlength="6" autocomplete="off" class="input-control" matInput
                              placeholder="Enter password" (keyup.enter)="nextClicked()">
                          <mat-error *ngIf="f.password?.errors !== null && f.password?.errors?.required">Password is
                              required</mat-error>
                          <mat-error *ngIf="f.password?.errors !== null && f.password?.errors?.minlength">Password must
                              be at least 6 characters
                          </mat-error>
                          <mat-icon matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                              [attr.aria-pressed]="hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                      </mat-form-field>
                  </div>
                  <div class="form-group" >
                      <mat-form-field appearance="standard" floatLabel="always" class="w-100">
                          <mat-label class="label-control">Confirm password</mat-label>
                          <input matInput [type]="!isHidden ? 'password' : 'text'" class="input-control" placeholder="Enter Confirm Password"
                              maxlength="14" minlength="6" formControlName="confirm_password" (keyup.enter)="nextClicked()">
                          <mat-error *ngIf="f.confirm_password?.errors !== null && f.confirm_password?.errors?.required">
                              Confirm
                              Password is required</mat-error>
                          <mat-error
                              *ngIf="(f.confirm_password?.errors !== null && f.confirm_password?.errors?.mustMatch) ||
                              (f.confirm_password?.value?.length < 6 && f.confirm_password?.value?.length !== 0)">
                              Password and
                              confirm password does not match</mat-error>
                          <mat-icon matSuffix (click)="isHidden = !isHidden"
                              [attr.aria-label]="'Hide confirm password'" [attr.aria-pressed]="hide">{{hide ?
                              'visibility' : 'visibility_off'}}</mat-icon>
                      </mat-form-field>
                  </div>
                  <div class="text-center mt-5">
                      <button type="button" class="btn btn-primary text-uppercase login-btn"
                      (click)="nextClicked()"  >REGISTER</button>&nbsp;&nbsp;
                      <button type="button" class="btn btn-primary text-uppercase login-btn"
                      (click)="resendOnSubmitOTP()" [disabled]="timeLeft !== 0 && isTimer" >Resend OTP</button>
                              <p class="mt-2 mb-2" *ngIf="timeLeft == 0">After Resend OTP, earlier OTP will be invalid</p>
                              <p class="mt-2 mb-2">Go back to <a style="cursor: pointer;" class="text-primary text-decoration-none" href="#">Homepage</a></p>
                              <p class="mt-2 mb-2">Re-Enter <a style="cursor: pointer;" class="text-primary text-decoration-none" (click)="backToRegistration();">Mobile Number</a></p>
                  </div>
              </form>
                <p class="mt-5 text-center">Not your number? <a class="text-primary" style="cursor: pointer;" href="tel:1800 209 7979">Call 1800
                        209 7979</a>
                    or email <a class="mailto:customercare@tatamotors.com text-decoration-none" href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=customercare@tatamotors.com" style="cursor: pointer;text-decoration: none;" target="_blank">customercare@tatamotors.com</a></p>
            </mat-card-content>
        </mat-card>

    </div>
</section>
