<header>
  <div class="container-fluid" *ngIf="!showHeader">
    <div class="row align-items-center" (click)="clear()">
      <div class="col-12 col-sm-12 col-lg-12 col-md-12 col-xl-12 text-end top-header">
        <!-- <div *ngIf="isLoggedIn">{{userDetails?.account_id}}</div> -->
        <ul class="user-top-header">
          <!-- <li><span class="user"><img alt="User" src="./assets/images/user.png"/></span></li> -->
          <li *ngIf="isLoggedIn">{{userDetails?.account_id}} <span
              *ngIf="userDetails?.userRole != undefined && userDetails?.userRole != null && userDetails?.userRole != '' && userDetails?.userRole != 'Owner'">{{ '( ' + userDetails?.userRole + ' )'}}</span>
          </li>
          
          <li *ngIf="!isLoggedIn"><img alt="" src="./assets/images/profile-h.svg" [routerLink]="['/account/login']"
            [queryParams]="{'isLogin':true}" /></li>
          <li *ngIf="isLoggedIn" class="drop">
            <img alt="" src="./assets/images/profile-h.svg" id="user-details" />
            <!-- <div class="short-user-info">   (click)="openUserOptions();"
              <div class="user-name">{{userDetails?.account_name}}</div>
              <div class="user-ide">{{userDetails?.account_id}}</div>
            </div> -->
            <div class="dropdownContain dropContainOption">
              <div class="dropOut dropout-user-details">
                <div class="triangle"></div>
                <div class="profile-option-header">
                  <div class="short-user-info">
                    <div class="user-name">{{userDetails?.account_name}}</div>
                    <div class="user-id">{{userDetails?.account_id}}{{ ' ( ' + userDetails?.userRole + ' )'}}</div>
                  </div>
                  <ul>
                    <li class="position-relative text-start">
                      <a [routerLink]="['/account/home']" id="header-profile">My Dashboard</a>
                    </li>
                    <li class="position-relative text-start">
                      <a [routerLink]="['/account/profile']" id="header-profile">My Profile</a>
                    </li>
                    <!-- *ngIf="isChangePassPermission" -->
                    <li class="position-relative text-start" *ngIf="!isKeyClockUser">
                      <a [routerLink]="['/account/setpassword']" id="header-password">Set Password</a>
                    </li>
                    <li *ngIf="isorderHistoryProfilePermission" class="position-relative  text-start">
                      <a [routerLink]="['/orders/ecom-orders']" id="header-order-history">Order History</a>
                    </li>
                    <li class="position-relative text-start">
                      <a [routerLink]="['/account/address']" id="header-address">Address</a>
                    </li>
                    <li class="position-relative text-start" (click)="switchAccount()" id="header-switch-account">
                      <a id="header-switch-account">Switch Account</a>
                    </li>
                    <li class="position-relative text-start" (click)="logout('Logout')" id="header-logout">
                      <a id="header-logout">Logout</a>
                    </li>
                  </ul>
                </div>
              </div>

            </div>
          </li>
          <li *ngIf="isLoggedIn && loyalityInfo?.membership_number" class="drop"  (click)="openLoyality()">
            <a (click)="openLoyality()" class="cursor">
              <i style="color: #fff;" id="cart-loyality"
             class='fas fa-award'></i>
            </a>
            <div class="dropdownContainloyality" *ngIf="isPopupVisible">
              <div class="card dropOut-loyality cart-loyality-wrapper" >
                <div class="triangle"></div>
                <div class="cart-header p-2" id="card-item-container5">
                  <div class="row">
                    <div class="col">
                      <div><span class="loyality-label" style="font-weight: 600;color: blue;cursor: pointer;" (click)="onRedirectLoyalty()">Member Id</span></div>
                      <div><span class="loyality-label" style="color: blue;cursor: pointer;" (click)="onRedirectLoyalty()">{{loyalityInfo?.membership_number}}</span></div>
                    </div>
                    <div class="col">
                      <div><span class="loyality-label" style="font-weight: 600;cursor: pointer;color: blue" (click)="onRedirectLoyalty()">Account Name</span></div>
                      <div><span class="loyality-label" style="cursor: pointer;color: blue" (click)="onRedirectLoyalty()">{{loyalityInfo?.account_name}}</span></div>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col cursor" (click)="onRedirectLoyalty()">
                      <div><span class="loyality-label" style="font-weight: 600;">Account Id</span></div>
                      <div><span class="loyality-label" >{{loyalityInfo?.account_id}}</span></div>
                    </div>
                    <div class="col cursor" (click)="onRedirectLoyalty()">
                      <div><span class="loyality-label" style="font-weight: 600;">Tier Category</span></div>
                      <div>
                        <span class="loyality-label">{{loyalityInfo?.tier_category}} </span>
                        <span class="loyality-label">({{loyalityInfo?.tier_category == 'Emperor Gold'? '3%' : loyalityInfo.tier_category == 'Emperor Silver' ? '1.5%':loyalityInfo.tier_category == 'Emperor Silver +'?'2%':loyalityInfo.tier_category == 'Emperor Platinum'?'4%':''}})</span>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col cursor" (click)="onRedirectLoyalty()">
                      <div><span class="loyality-label" style="font-weight: 600;">Key Account Code</span></div>
                      <div><span class="loyality-label" >{{loyalityInfo?.key_account_code}}</span></div>
                    </div>
                    <div class="col cursor" (click)="onRedirectLoyalty()">
                      <div><span class="loyality-label" style="font-weight: 600;">Balance Points</span></div>
                      <div><span class="loyality-label" >{{loyalityInfo?.balance_points| currency:'':'':'1.0-0'}}</span></div>
                    </div>
                 
                  </div>
                  <div class="row mt-2">
                    <div class="col cursor" (click)="onRedirectLoyalty()">
                      <div><span class="loyality-label" style="font-weight: 600;">ARN Number</span></div>
                      <div><span class="loyality-label" style="word-break: break-all;">{{loyalityInfo?.ARN_number ? loyalityInfo?.ARN_number : '-'}}</span></div>
                    </div>
                    <div class="col cursor" (click)="onRedirectLoyalty()" *ngIf="userDetails?.contact_no">
                      <div><span class="loyality-label" style="font-weight: 600;">Master Mobile No</span></div>
                      <div><span class="loyality-label" style="word-break: break-all;">{{userDetails?.contact_no}}</span></div>
                    </div>
                  </div>
                  <div class="row mt-2 cursor" (click)="onRedirectLoyalty()">
                    <div class="col" *ngIf="loyalityInfo?.email_id">
                      <div><span class="loyality-label" style="font-weight: 600;">Email Id</span></div>
                      <div><span class="loyality-label" style="word-break: break-all;">{{loyalityInfo?.email_id}}</span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="wishlist-icon" *ngIf="isLoggedIn">
            <a title="Wishlist"  [routerLink]="['/info/wishlist']" >
              <i style="color: #fff;" aria-hidden="true" class="far fa-bookmark"></i>
            </a>
          </li>

          <li *ngIf="isLoggedIn" class="drop-notification">
            <img alt="" src="./assets/images/notification-bell.gif" [routerLink]="['/account/notification']" style="width:23px;height:18px;" />
            <!-- <span class="count">{{ edukaanTimelineData.count }}</span> -->
            
            <div class="card notify-hover-dropdown mt-2" style="display: none;">
              <div class="triangle"></div>
              <div class="card-body">
                <div class="d-flex notify-heading">
                  <h4 class="card-title">Notification </h4>
                  <div class="ms-auto">
                    <select name="noti-list-seletion" id="" class="form-select">
                      <option value="all">All</option>
                    </select>
                  </div>

                </div>
                <div class="date-wise-data mb-3">
                  <p class="date-p">Today, 17th Sep</p>
                  <div class="notifyCard-box">
                    <div class="card position-relative card-satus st-success">
                      <div class="card-body">
                        <p class="text-end mb-0 status-time">3.04 pm</p>
                        <h5 class="card-title">Order Recieved! </h5>
                        <p class="card-text status-desc">Your order <span>#41d5-4f6-c04f9355f004</span> for <span>8
                            items</span>
                          has been recieved by the distributor</p>
                        <p class="mb-0">Est Delivery : <span>Tomorrow, 19th Sep</span></p>
                        commented out
                        <p class="mb-0">Delivery. boy : <span>Shekhar (+91-9793629837)</span></p>
                        <p class="mb-0 text-end track-items-link"><a href="javascript:void(0)" class="text-end">Track
                            items</a>
                        </p>
                      </div>
                    </div>
                    <div class="card position-relative card-satus st-pending">
                      <div class="card-body">
                        <p class="text-end mb-0 status-time">3.04 pm</p>
                        <h5 class="card-title">Order Delayed</h5>
                        <p class="card-text status-desc"><span>3/6 items</span> from order
                          #<span>41d5-4f6-c04f9355f004</span> are delayed by 2 days due to late arrival from plant.</p>
                        <p class="mb-0">Est Delivery : <span>23rd Sep</span></p>
                        <p class="mb-0 text-end track-items-link"><a href="javascript:void(0)" class="text-end">Track
                            items</a>
                        </p>
                      </div>
                    </div>
                    <div class="card position-relative card-satus st-pending">
                      <div class="card-body">
                        <p class="text-end mb-0 status-time">3.04 pm</p>
                        <h5 class="card-title">Back in stock!</h5>
                        <p class="card-text status-desc">Part <span>#286309130191 (Air filter BS3)</span> from your
                          wishlist is back in stock!</p>
                        <p class="mb-0">Est Delivery : <span>23rd Sep</span></p>
                        <p class="mb-0 text-end track-items-link"><a href="javascript:void(0)" class="text-end">Track
                            items</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="date-wise-data mb-3">
                  <p class="date-p">Today, 16th Sep</p>
                  <div class="notifyCard-box">
                    <div class="card position-relative card-satus st-success">
                      <div class="card-body">
                        <p class="text-end mb-0 status-time">3.04 pm</p>
                        <h5 class="card-title">Refund Credited </h5>
                        <p class="card-text status-desc">Refund of <span>₹23,567.00</span> for cancelled order
                          <span class="text-nowrap">#41d5-4f6-c04f9355f004</span> has been credited to your account.
                        </p>
                        <p class="mb-0">Est Delivery : <span>Tomorrow, 19th Sep</span></p>
                        <p class="mb-0">Delivery. boy : <span>Shekhar (+91-9793629837)</span></p>
                        <p class="mb-0 text-end track-items-link"><a href="javascript:void(0)" class="text-end">Track
                            items</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <p class="text-center mb-0 mt-4"><a href="javascript:void(0)" class="view_all text-decoration-none">View
                    All(<span>15</span>)</a></p>
              </div>
            </div>
          </li>

          <!-- <li><img alt="" src="./assets/images/bookmark-h.svg"/></li>(click)="openCartForAwdhesh();"   openCart();-->
          <li class="drop"><img alt="" (click)="openCart()" src="./assets/images/cart-h.svg" id="cart-details" />
            <span class="count" (click)="openCart()" *ngIf="!isLoggedIn">{{ (cart?.allitems$|async)?.length}}
            </span>
            <span class="count" (click)="openCart()" *ngIf="isLoggedIn">{{ postLoginCartLength }}
            </span>
            <!--Cart dropdown-->
            <div class="dropdownContain">
              <div class="dropOut cart-item-wrapper" *ngIf="(cart?.allitems$|async)?.length && !isLoggedIn">
                <div class="triangle"></div>
                <!-- <ul class="nav nav-tabs tabs1" id="cartTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <a class="nav-link active" id="MHCV-tab" data-bs-toggle="tab" href="#MHCV" role="tab" aria-controls="MHCV" aria-selected="true">MHCV(3)</a>
                  </li>
                  <li class="nav-item" role="presentation">
                    <a class="nav-link" id="SLCV-tab" data-bs-toggle="tab" href="#SLCV" role="tab" aria-controls="SLCV" aria-selected="false">SLCV(2)</a>
                  </li>
                </ul> -->
                <!-- <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade show active" id="MHCV" role="tabpanel" aria-labelledby="MHCV-tab"> -->
                <div class="cart-header p-2" id="card-item-container1">
                  <ul>
                    <ng-container *ngFor="let item of cart?.allitems$|async; let i = index">
                      <li class="position-relative" *ngIf="i<6">
                        <div class="row">
                          <div class="col-3">
                            <!-- <img alt="" src="" class="img-fluid card-img" /> -->
                            <!-- <a [routerLink]="root.product(item.product)" *ngIf="item?.product?.image"> -->
                            <img class="img-fluid card-img" style="cursor: pointer;"
                              [routerLink]="['/shop/product-details']"
                              [queryParams]="{ part_Number : item?.product?.part_number }" [src]="item?.product?.part_image_path + '/resize/70x40'"
                              alt="">
                            <!-- </a> -->
                          </div>
                          <div class="col-9 ps-0 pe-4">
                            <div class="part-desc">{{item?.product?.short_description }}</div>
                            <div class="part-number" style="cursor: pointer;" [routerLink]="['/shop/product-details']"
                              [queryParams]="{ part_Number : item?.product?.part_number }">
                              <!-- <a [routerLink]="root.product(item.product)"> -->
                              Part #{{ item?.product?.part_number }}
                              <!-- </a> -->
                            </div>                  
                            <div class="pt-1">
                              <div *ngIf="item?.product?.resource_type == 'Pack'; else elseTmgoHeaderCart">
                                <app-input-number aria-label="Quantity" size="lg" [min]="1"
                                  [defaultValue]="item?.quantity / item?.product?.moq" [disabled]="item?.product?.unit_quantity"
                                  (currentQuantity)="CheckQuantity($event, i)" [isTmgoValue]="item?.product?.moq">
                                </app-input-number>
                                <span style="color: black;" *ngIf="item?.product?.moq > 1">
                                  ( {{ item?.quantity }} <span *ngIf="item?.quantity > 1">Quantities</span><span *ngIf="item?.quantity <= 1">Quantity</span>) 
                                </span>
                              </div>
                              <ng-template #elseTmgoHeaderCart>
                                <app-input-number aria-label="Quantity" size="lg" [min]="1"
                                  [defaultValue]="item.quantity"
                                  (currentQuantity)="CheckQuantity($event, i)" [isTmgoValue]="1">
                                </app-input-number>
                              </ng-template>
                              <div class="amount" *ngIf="item?.product?.total_amount">
                                {{ ((item?.product?.resource_type == 'Pack' ) ? item?.product?.crm_mrp * item?.quantity:(item?.product?.total_amount * item?.quantity)) |currencyFormat:'INR' }}
                              </div>
                              <div class="amount" *ngIf="!item?.product?.total_amount">
                                {{ (( item?.product?.resource_type == 'Pack')?item?.product?.crm_mrp*item.quantity:item?.product?.crm_mrp  * item?.quantity) |currencyFormat:'INR' }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <a href="javascript:void(0);" class="clear"><i class="fas fa-times"></i></a> -->
                        <a (click)="remove(item)" style="cursor: pointer;"
                          [ngClass]="{'btn-loading': removedItems.includes(item)}" class="clear"><i
                            class="fas fa-trash"></i></a>
                      </li>
                    </ng-container>

                    <!-- <li class="position-relative">
                          <div class="row">
                            <div class="col-3">
                              <img alt="" src="" class="img-fluid card-img" />
                            </div>
                            <div class="col-9 ps-0 pe-5">
                              <div class="part-number">Part #266835300130</div>
                              <div class="part-desc">Crown W. & Pinion (37/7),OER-Spirac BS4</div>
                              <div class="pt-1">
                                <app-input-number aria-label="Quantity"
                                size="lg" [min]="1" [defaultValue]="1">
                                </app-input-number>
                                 <div class="amount">
                                  9,900.00
                                 </div>
                              </div>
                            </div>
                          </div>
                          <a href="javascript:void(0);" class="clear"><i class="fas fa-times"></i></a>
                        </li>
                        <li class="position-relative">
                          <div class="row">
                            <div class="col-3">
                              <img alt="" src="" class="img-fluid card-img" />
                            </div>
                            <div class="col-9 ps-0 pe-5">
                              <div class="part-number">Part #266835300130</div>
                              <div class="part-desc">Crown W. & Pinion (37/7),OER-Spirac BS4</div>
                              <div class="pt-1">
                                <app-input-number aria-label="Quantity"
                                size="lg" [min]="1" [defaultValue]="1">
                                </app-input-number>
                                 <div class="amount">
                                  9,900.00
                                 </div>
                              </div>
                            </div>
                          </div>
                          <a href="javascript:void(0);" class="clear"><i class="fas fa-times"></i></a>
                        </li>
                        <li class="position-relative">
                          <div class="row">
                            <div class="col-3">
                              <img alt="" src="" class="img-fluid card-img" />
                            </div>
                            <div class="col-9 ps-0 pe-5">
                              <div class="part-number">Part #266835300130</div>
                              <div class="part-desc">Crown W. & Pinion (37/7),OER-Spirac BS4</div>
                              <div class="pt-1">
                                <app-input-number aria-label="Quantity"
                                size="lg" [min]="1" [defaultValue]="1">
                                </app-input-number>
                                 <div class="amount">
                                  9,900.00
                                 </div>
                              </div>
                            </div>
                          </div>
                          <a href="javascript:void(0);" class="clear"><i class="fas fa-times"></i></a>
                        </li> -->
                  </ul>
                </div>
                <div class="p-3 overflow-auto">
                  <button class="btn btnbtn btn-outline-primary" style="float:left;" id="view-all-items"
                    (click)="viewCart('mhv',false);">{{(cart?.allitems$|async)?.length > 3 ? 'View all items' : 'View Cart'}}</button>
                  <!-- <button class="btn btnbtn btn-primary" (click)="viewCart('mhv',true);">CHECKOUT</button> -->
                  <button class="btn btnbtn btn-primary" *ngIf="isLoggedIn" (click)="bulkOrderRoute()">Bulk Order
                    Upload</button>
                  <!-- [routerLink]="['/account/addbulkcart']" -->
                  <!-- <button></button> -->
                </div>
              </div>
              <div class="dropOut cart-item-wrapper" *ngIf="isLoggedIn && postLoginCartLength > 0">
                <div class="triangle"></div>
                <div class="cart-header p-2" id="card-item-container2">
                  <ul>
                    <ng-container *ngIf="isHeaderCartApiLoaded; else elseTemplate">
                      <li class="position-relative" *ngFor="let item of responseItems; let i = index">
                        <div class="row">
                          <div class="col-3">
                            <!-- <img alt="" src="" class="img-fluid card-img" /> -->
                            <!-- <a [routerLink]="root.product(item.product)" *ngIf="item?.product?.image"> -->
                            <img class="img-fluid card-img" style="cursor: pointer;"
                              [routerLink]="['/shop/product-details']"
                              [queryParams]="{ part_Number : item?.product?.part_number }" [src]="item?.product?.image + '/resize/70x40'"
                              alt="">
                            <!-- </a> -->
                          </div>
                          <div class="col-9 ps-0 pe-4">                           
                            <div class="part-number">{{item?.product?.part_desc }}</div>
                            <div class="part-desc" style="cursor: pointer;" [routerLink]="['/shop/product-details']"
                              [queryParams]="{ part_Number : item?.product?.part_number }">
                              <!-- <a [routerLink]="root.product(item.product)"> -->
                              Part #{{ item?.product?.part_number }}
                              <!-- </a> -->
                            </div>
                            <div class="pt-1">
                              <div [ngClass]="item?.product?.unit_quantity ? '':''">
                                <ng-container *ngIf="item?.product?.resource_type == 'Pack'; else elseTmgoHeaderCart">
                                  <app-input-number 
                                    aria-label="Quantity" size="lg" [min]="1"
                                    [defaultValue]="item?.product.total_quantity / item?.product?.moq"
                                    (currentQuantity)="CheckQuantity($event, i)" class="d-inline-block" [isTmgoValue]="item?.product?.moq">
                                  </app-input-number>
                                  <span style="color: black;" *ngIf="item?.product?.moq > 1">
                                    ({{ item?.quantityControl.value }} <span *ngIf="item?.quantityControl.value > 1">Quantities</span><span *ngIf="item?.quantityControl.value <= 1">Quantity</span>) 
                                  </span>
                                </ng-container>
                                  <!-- [disabled]="item?.product?.unit_quantity"
                                  [disabled]="item?.product?.unit_quantity" -->
                                <ng-template #elseTmgoHeaderCart>
                                  <app-input-number aria-label="Quantity" size="lg" [min]="1"
                                    [defaultValue]="item.product.total_quantity"
                                    (currentQuantity)="CheckQuantity($event, i)" [isTmgoValue]="1">
                                  </app-input-number>
                                </ng-template>
                              </div>
                              <div class="amount" *ngIf="item?.product?.total_amount">
                                {{ item?.product?.total_amount |currencyFormat:'INR' }}
                              </div>
                              <div class="amount" *ngIf="!item?.product?.total_amount">
                                {{ ( (item?.product?.resource_type == 'Pack')?item?.product?.crm_mrp*item.quantityControl.value:item?.product?.crm_mrp  * item.quantityControl.value) |currencyFormat:'INR' }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <a href="javascript:void(0);" class="clear"><i class="fas fa-times"></i></a> -->
                        <a (click)="remove(item)" style="cursor: pointer;"
                          [ngClass]="{'btn-loading': removedItems.includes(item)}" class="clear"><i
                            class="fas fa-trash"></i></a>
                      </li>
                    </ng-container>
                    <ng-template #elseTemplate>
                      <li class="position-relative" *ngFor="let item of userService.generateFake(3);let i = index;">
                        <div class="row">
                          <div class="col-3">
                            <skc-rectangle [loading]="loading" [skc-height]="'65px'" [skc-width]="'100%'">
                            </skc-rectangle>
                          </div>
                          <div class="col-9 ps-0 pe-4">
                            <div class="part-number">
                              <skc-rectangle [loading]="loading" [skc-height]="'20px'" [skc-width]="'100%'">
                              </skc-rectangle>
                            </div>
                            <div class="part-desc">
                              <skc-rectangle [loading]="loading" [skc-height]="'15px'" [skc-width]="'100%'">
                              </skc-rectangle>
                            </div>
                            <div class="pt-1">
                              <skc-rectangle [loading]="loading" [skc-height]="'24px'" [skc-width]="'100%'">
                              </skc-rectangle>
                            </div>
                          </div>

                        </div>
                      </li>
                    </ng-template>
                  </ul>
                </div>
                <div class="p-3 overflow-auto">
                  <!-- <button class="btn btnbtn btn-outline-primary" style="float:left;"
                    (click)="viewCart('mhv',false);">VIEW CART</button> -->
                  <button class="btn btnbtn btn-outline-primary" style="float:left;" id="view-all-items"
                    (click)="viewCart('mhv',false);">{{postLoginCartLength > 3 ? 'View all items' : 'View Cart'}}</button>
                  <!-- <button class="btn btnbtn btn-primary" (click)="viewCart('mhv',true);">CHECKOUT</button> -->
                  <button class="btn btnbtn btn-primary" id="header-bulk-upload" *ngIf="isLoggedIn"
                    (click)="bulkOrderRoute()">Bulk Order Upload</button>
                  <!-- <button class="btn btnbtn btn-primary"  *ngIf="isLoggedIn" (click)="bulkOrderRoute()">Bulk Order Upload</button> -->
                  <!-- [routerLink]="['/account/addbulkcart']" -->
                  <!-- <button></button> -->
                </div>
              </div>
              <div class="dropOut  cart-item-wrapper" *ngIf="!(cart?.allitems$|async)?.length && !isLoggedIn">
                <div class="triangle"></div>
                <div class="cart-header p-3" id="card-item-container3" style="color: #0a0a5f;text-align: center;">
                  Your shopping cart is empty !
                </div>
                <div class="text-center p-3 overflow-auto" *ngIf="isLoggedIn">
                  <button class="btn btnbtn btn-outline-primary" style="float:left;" id="view-all-items"
                    (click)="viewCart('mhv',false);">View Cart</button>
                  <button class="btn btnbtn btn-primary" id="header-bulk-upload" (click)="bulkOrderRoute()">Bulk Order
                    Upload</button>
                </div>
                <!-- <div class="dropcart__buttons" *ngIf="isLoggedIn">
                        <a *ngIf="isLoggedIn" class="btn btn-primary text-white" (click)="addbulkcart();">Bulk Order Upload</a>
                    </div> -->
              </div>
              <div class="dropOut  cart-item-wrapper"
                *ngIf="isLoggedIn && responseItems.length == 0 && isNoItemsPresent && postLoginCartLength === 0;">
                <div class="triangle"></div>
                <div class="cart-header p-3" id="card-item-container4" style="color: #0a0a5f;text-align: center;">
                  Your shopping cart is empty !
                </div>
                <div class="text-center p-3 overflow-auto" *ngIf="isLoggedIn">
                  <button class="btn btnbtn btn-outline-primary" style="float:left;" id="view-all-items"
                    (click)="viewCart('mhv',false);">View Cart</button>
                  <button class="btn btnbtn btn-primary" id="header-bulk-upload" (click)="bulkOrderRoute()">Bulk Order
                    Upload</button>
                </div>
                <!-- <div class="dropcart__buttons" *ngIf="isLoggedIn">
                        <a *ngIf="isLoggedIn" class="btn btn-primary text-white" (click)="addbulkcart();">Bulk Order Upload</a>
                    </div> -->
              </div>
              <!-- <div class="tab-pane fade" id="SLCV" role="ftabpanel" aria-labelledby="SLCV-tab">
                    hari
                  </div> -->

              <!-- </div>
              </div> -->
            </div>


          </li>
          <!-- <li *ngIf="isLoggedIn" (click)="logout('Logout')"><img src="./assets/icons/logout-24px.svg" /> </li> -->
          <!-- <li *ngIf="isLoggedIn" (click)="logout('Logout')"><img alt="" src="./assets/images/logout-h.svg" style="transform: rotate(-90deg);"/></li> -->
        </ul>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-3 col-sm-4 col-md-3 col-lg-2 col-xl-2 mobile-logo" *ngIf="!isLoggedIn">
        <a class="tata-logo">
          <div class="text-end d-inline-block pe-3">
            <!-- <img alt="TATA MOTORS" src="./assets/images/tata-logo.png" class="mb-2" />
            <br />
            <span class="main-logo">e - Dukaan</span> -->
            <img alt="logo" (click)="routeToHome()" src="./assets/images/e_Dukaan_Logo_Final.png" />
          </div>
        </a>
      </div>
      <div class="col-3 col-sm-4 col-md-3 col-lg-2 col-xl-2 mobile-logo" *ngIf="isLoggedIn">
        <a class="tata-logo">
          <div class="text-end d-inline-block pe-3">
            <!-- <img alt="TATA MOTORS" (click)="routeToHome()" src="./assets/images/tata-logo.png" class="mb-2" />
            <br />
            <span class="main-logo" (click)="routeToHome()">e - Dukaan</span> -->
            <!-- <img alt="logo" src="./assets/images/eDukaan_logo-new.svg"/> -->
            <img alt="logo" (click)="routeToHome()" src="./assets/images/e_Dukaan_Logo_Final.png" />
          </div>
        </a>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-6 col-lg-offset-2 mx-auto pb-3 pb-lg-0">
        <form [formGroup]="searchForm" name="searchForm" (ngSubmit)="onSubmitshowpopup()">
          <div [ngClass]="isLoggedIn === true ? 'search-container after-login-search':' search-container before-login-search'" id="pincode-base-search">
            <label class="text-nowrap" style="cursor: pointer;" (click)="SelectAddress()">
              <img alt="" src="./assets/images/location-h.svg" /> {{ pinCodeNumber }} 
              
            </label>
            <div class="dropdown">
              <!-- <input type="search" name="search" placeholder="Search by Part#, Part name...." /> -->
              <!-- <input type="search" name="search" formControlName="search" (change)="onPaste()" maxlength="30"  (search)="clear()"
                (keypress)="alphanumbericOnly($event);" (keyup)="searchAutoSuggest($event.target.value)" placeholder="{{searchType}}" autocomplete="off"/> -->
              <input type="search" name="search" formControlName="search" (change)="onPaste()" maxlength="30"
                (search)="clear()" id="header-paste-search" (keypress)="alphanumbericOnly($event);" key
                placeholder="{{searchType}}" autocomplete="off" 
                [style.font-size]="speechData ? '14px' : '' "
                [(ngModel)]="speechData" [ngModelOptions]="{standalone: true}" [readOnly]="startbtnClicked"/>
                <!-- (keyup)="searchAutoSuggestData($event.target.value)" -->
                <!-- <div class="snippet" data-title=".dot-pulse">
                  <div class="stage">
                    <div class="dot-pulse"></div>
                  </div>
                </div> -->
                <!-- <input type="text" class="form-control border-input" name="txtSpeechSearchMovieName"
                id="txtSpeechSearchMovieName" value="" placeholder="Click below button and then say something!!!"
                [(ngModel)]="speechData" [ngModelOptions]="{standalone: true}"> -->
              <!--before login-->
              <div class="dropdown-menu searchbox-search  d-none" *ngIf="!isLoggedIn && showdiv">
                <!--for post search width is 1024px-->
                <!--style="display: block;"-->
                <!--search on part number-->
                <div class="search-part-number" [ngClass]="showdiv === 'part-number' ? '':'d-none'">
                  <h3>33 parts found for <span>number ’0191’ Awadhesh</span></h3>
                  <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                      <div class="card mb-3">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-3 pe-0">
                              <img class="img-fluid" alt="" />
                            </div>
                            <div class="col-9 ps-1">
                              <div class="model-no">885433012829</div>
                              <div class="model-desc">Back and Front Back and Front </div>
                              <div class="model-type">BS6</div>
                              <div class="model-bread">Engine > Air Compressor</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                      <div class="card mb-3">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-3 pe-0">
                              <img class="img-fluid" alt="" />
                            </div>
                            <div class="col-9 ps-1">
                              <div class="model-no">885433012829</div>
                              <div class="model-desc">Back and Front Back and Front </div>
                              <div class="model-type">BS6</div>
                              <div class="model-bread">Engine > Air Compressor</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                      <div class="card mb-3">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-3 pe-0">
                              <img class="img-fluid" alt="" />
                            </div>
                            <div class="col-9 ps-1">
                              <div class="model-no">885433012829</div>
                              <div class="model-desc">Back and Front Back and Front </div>
                              <div class="model-type">BS6</div>
                              <div class="model-bread">Engine > Air Compressor</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                      <div class="card mb-3">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-3 pe-0">
                              <img class="img-fluid" alt="" />
                            </div>
                            <div class="col-9 ps-1">
                              <div class="model-no">885433012829</div>
                              <div class="model-desc">Back and Front Back and Front </div>
                              <div class="model-type">BS6</div>
                              <div class="model-bread">Engine > Air Compressor</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                      <div class="card mb-3">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-3 pe-0">
                              <img class="img-fluid" alt="" />
                            </div>
                            <div class="col-9 ps-1">
                              <div class="model-no">885433012829</div>
                              <div class="model-desc">Back and Front Back and Front </div>
                              <div class="model-type">BS6</div>
                              <div class="model-bread">Engine > Air Compressor</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                      <div class="card mb-3">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-3 pe-0">
                              <img class="img-fluid" alt="" />
                            </div>
                            <div class="col-9 ps-1">
                              <div class="model-no">885433012829</div>
                              <div class="model-desc">Back and Front Back and Front </div>
                              <div class="model-type">BS6</div>
                              <div class="model-bread">Engine > Air Compressor</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                      <div class="card mb-3">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-3 pe-0">
                              <img class="img-fluid" alt="" />
                            </div>
                            <div class="col-9 ps-1">
                              <div class="model-no">885433012829</div>
                              <div class="model-desc">Back and Front Back and Front </div>
                              <div class="model-type">BS6</div>
                              <div class="model-bread">Engine > Air Compressor</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-end">
                      <a href="javascript:void(0)" class="view-all">View All <img
                          src="./assets/images/arrow-right.svg" /></a>
                    </div>
                  </div>
                </div>
                <!--search on registration number-->
                <div class="search-registration-number" [ngClass]="showdiv === 'registration-number' ? '':'d-none'">
                  <h3>13 product suggestions for <span>‘MH 12 SE 6765 Air Filter’</span></h3>
                  <div class="d-flex">
                    <div class="image-container">
                      <div class="prod-img">
                        <img alt="" src="" class="img-fluid" />
                      </div>
                      <div class="prod-cat">PRIMA</div>
                      <div>
                        BS6
                        <span>Emission Norms</span>
                      </div>
                      <div>
                        557906
                        <span>Chasis Type</span>
                      </div>
                      <div>
                        2015
                        <span>Year</span>
                      </div>
                      <div>
                        TIPPER
                        <span>Body Type</span>
                      </div>
                      <div>
                        10
                        <span>No. of Tyre</span>
                      </div>

                    </div>
                    <div class="search-result-container">
                      <div class="row">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-end">
                          <a href="javascript:void(0)" class="view-all">View All <img
                              src="./assets/images/arrow-right.svg" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--search on own fleet-->
                <div class="search-own-fleet-old d-none" [ngClass]="showdiv === 'own-fleet' ? '':''">
                  <div class="d-flex">
                    <div class="image-container">
                      <div class="prod-img">
                        <img alt="" src="" class="img-fluid" />
                      </div>
                      <div class="prod-cat">PRIMA</div>
                      <div>
                        BS6
                        <span>Emission Norms</span>
                      </div>
                      <div>
                        557906
                        <span>Chasis Type</span>
                      </div>
                      <div>
                        2015
                        <span>Year</span>
                      </div>
                      <div>
                        TIPPER
                        <span>Body Type</span>
                      </div>
                      <div>
                        10
                        <span>No. of Tyre</span>
                      </div>

                    </div>
                    <div class="search-result-container">
                      <div class="row">
                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                          <div class="card mb-4">
                            <div class="card-body">
                              <div class="search-img-container">
                                <img alt="" src="" class="img-fluid" />
                              </div>
                              <div class="parts-type">
                                Engine
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                          <div class="card mb-4">
                            <div class="card-body">
                              <div class="search-img-container">
                                <img alt="" src="" class="img-fluid" />
                              </div>
                              <div class="parts-type">
                                Engine
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                          <div class="card mb-4">
                            <div class="card-body">
                              <div class="search-img-container">
                                <img alt="" src="" class="img-fluid" />
                              </div>
                              <div class="parts-type">
                                Engine
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                          <div class="card mb-4">
                            <div class="card-body">
                              <div class="search-img-container">
                                <img alt="" src="" class="img-fluid" />
                              </div>
                              <div class="parts-type">
                                Engine
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                          <div class="card mb-4">
                            <div class="card-body">
                              <div class="search-img-container">
                                <img alt="" src="" class="img-fluid" />
                              </div>
                              <div class="parts-type">
                                Engine
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                          <div class="card mb-4">
                            <div class="card-body">
                              <div class="search-img-container">
                                <img alt="" src="" class="img-fluid" />
                              </div>
                              <div class="parts-type">
                                Engine
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                          <div class="card mb-4">
                            <div class="card-body">
                              <div class="search-img-container">
                                <img alt="" src="" class="img-fluid" />
                              </div>
                              <div class="parts-type">
                                Engine
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                          <div class="card mb-4">
                            <div class="card-body">
                              <div class="search-img-container">
                                <img alt="" src="" class="img-fluid" />
                              </div>
                              <div class="parts-type">
                                Engine
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                          <div class="card mb-4">
                            <div class="card-body">
                              <div class="search-img-container">
                                <img alt="" src="" class="img-fluid" />
                              </div>
                              <div class="parts-type">
                                Engine
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                          <div class="card mb-4">
                            <div class="card-body">
                              <div class="search-img-container">
                                <img alt="" src="" class="img-fluid" />
                              </div>
                              <div class="parts-type">
                                Engine
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                          <div class="card mb-4">
                            <div class="card-body">
                              <div class="search-img-container">
                                <img alt="" src="" class="img-fluid" />
                              </div>
                              <div class="parts-type">
                                Engine
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-end">
                          <a href="javascript:void(0)" class="view-all">View All <img
                              src="./assets/images/arrow-right.svg" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
                <!--search on click-->
                <!-- [ngClass]="showdiv === 'on-click' ? 'd-block':'d-none'" -->
                <div class="search-on-click-old d-none">
                  <div class="d-flex">
                    <div class="recent-search">
                      <h5>
                        <img alt="" src="./assets/images/history.svg" /> Recent searches
                      </h5>
                      <ul>
                        <li>885433012829</li>
                        <li class="active">bearings axle 5mm back</li>
                        <li>bearings front axle</li>
                        <li>bearings </li>
                        <li>beering</li>
                        <li>Tata Ultra</li>
                        <li>Air Filter Assy.</li>
                        <li>1516 Air Filter</li>
                        <li>Air Filter</li>
                        <li>9802455248592</li>
                      </ul>
                    </div>
                    <div class="top-models">
                      <h5>
                        <img alt="" src="./assets/images/truck.svg" /> Top Models
                      </h5>
                      <div class="box-container">
                        <div class="text-con">ULTRA 500</div>
                        <div class="img-con"><img alt="" src="" class="img-fluid" /></div>
                      </div>
                      <div class="box-container active">
                        <div class="text-con">ULTRA 500</div>
                        <div class="img-con"><img alt="" src="" class="img-fluid" /></div>
                      </div>
                      <div class="box-container">
                        <div class="text-con">ULTRA 500</div>
                        <div class="img-con"><img alt="" src="" class="img-fluid" /></div>
                      </div>
                    </div>
                    <div class="popular-parts">
                      <h5>
                        <img alt="" src="./assets/images/settings.svg" /> Popular Parts
                      </h5>
                      <div class="row">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--new search after discussion-->
                <!--search on own fleet-->
                <div class="search-own-fleet d-none" [ngClass]="showdiv === 'own-fleet' ? '':''">
                  <div class="d-flex">
                    <div class="image-container">
                      <div class="prod-img">
                        <img alt="" src="" class="img-fluid" />
                      </div>
                      <div class="prod-cat">PRIMA</div>
                      <div>
                        BS6
                        <span>Emission Norms</span>
                      </div>
                      <div>
                        557906
                        <span>Chasis Type</span>
                      </div>
                      <div>
                        2015
                        <span>Year</span>
                      </div>
                      <div>
                        TIPPER
                        <span>Body Type</span>
                      </div>
                      <div>
                        10
                        <span>No. of Tyre</span>
                      </div>

                    </div>
                    <div class="search-result-container">
                      <div class="row">
                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                          <div class="card mb-4">
                            <div class="card-body">
                              <div class="search-img-container">
                                <img alt="" src="" class="img-fluid" />
                              </div>
                              <div class="parts-type">
                                Engine
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                          <div class="card mb-4">
                            <div class="card-body">
                              <div class="search-img-container">
                                <img alt="" src="" class="img-fluid" />
                              </div>
                              <div class="parts-type">
                                Engine
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                          <div class="card mb-4">
                            <div class="card-body">
                              <div class="search-img-container">
                                <img alt="" src="" class="img-fluid" />
                              </div>
                              <div class="parts-type">
                                Engine
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                          <div class="card mb-4">
                            <div class="card-body">
                              <div class="search-img-container">
                                <img alt="" src="" class="img-fluid" />
                              </div>
                              <div class="parts-type">
                                Engine
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                          <div class="card mb-4">
                            <div class="card-body">
                              <div class="search-img-container">
                                <img alt="" src="" class="img-fluid" />
                              </div>
                              <div class="parts-type">
                                Engine
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                          <div class="card mb-4">
                            <div class="card-body">
                              <div class="search-img-container">
                                <img alt="" src="" class="img-fluid" />
                              </div>
                              <div class="parts-type">
                                Engine
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                          <div class="card mb-4">
                            <div class="card-body">
                              <div class="search-img-container">
                                <img alt="" src="" class="img-fluid" />
                              </div>
                              <div class="parts-type">
                                Engine
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                          <div class="card mb-4">
                            <div class="card-body">
                              <div class="search-img-container">
                                <img alt="" src="" class="img-fluid" />
                              </div>
                              <div class="parts-type">
                                Engine
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                          <div class="card mb-4">
                            <div class="card-body">
                              <div class="search-img-container">
                                <img alt="" src="" class="img-fluid" />
                              </div>
                              <div class="parts-type">
                                Engine
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                          <div class="card mb-4">
                            <div class="card-body">
                              <div class="search-img-container">
                                <img alt="" src="" class="img-fluid" />
                              </div>
                              <div class="parts-type">
                                Engine
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                          <div class="card mb-4">
                            <div class="card-body">
                              <div class="search-img-container">
                                <img alt="" src="" class="img-fluid" />
                              </div>
                              <div class="parts-type">
                                Engine
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-end">
                          <a href="javascript:void(0)" class="view-all">View All <img
                              src="./assets/images/arrow-right.svg" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--search on click-->
                <!-- [ngClass]="showdiv === 'on-click' ? 'd-block':'d-none'" -->
                <div class="search-on-click d-block">
                  <div class="d-flex">
                    <!-- <div class="recent-search">
                    </div> -->
                    <div class="image-container">
                      <div class="prod-img">
                        <img alt="" src="" class="img-fluid" />
                      </div>
                      <div class="prod-cat">PRIMA</div>
                      <div>
                        BS6
                        <span>Emission Norms</span>
                      </div>
                      <div>
                        557906
                        <span>Chasis Type</span>
                      </div>
                      <div>
                        2015
                        <span>Year</span>
                      </div>
                      <div>
                        TIPPER
                        <span>Body Type</span>
                      </div>
                      <div>
                        10
                        <span>No. of Tyre</span>
                      </div>

                    </div>
                    <div class="top-models">
                      <h5>
                        <img alt="" src="./assets/images/truck.svg" /> Top Models
                      </h5>
                      <div class="box-container">
                        <div class="text-con">ULTRA 500</div>
                        <div class="img-con"><img alt="" src="" class="img-fluid" /></div>
                      </div>
                      <div class="box-container active">
                        <div class="text-con">ULTRA 500</div>
                        <div class="img-con"><img alt="" src="" class="img-fluid" /></div>
                      </div>
                      <div class="box-container">
                        <div class="text-con">ULTRA 500</div>
                        <div class="img-con"><img alt="" src="" class="img-fluid" /></div>
                      </div>
                    </div>
                    <div class="popular-parts d-none">
                      <h5>
                        <img alt="" src="./assets/images/settings.svg" /> Popular Parts
                      </h5>
                      <div class="row">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-end">
                        <a href="javascript:void(0)" class="view-all">View All <img
                            src="./assets/images/arrow-right.svg" /></a>
                      </div>
                    </div>
                    <div class="search-result-container">
                      <div class="container">
                        <div class="row">
                          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <div class="card mb-4">
                              <div class="card-body">
                                <div class="search-img-container">
                                  <img alt="" src="" class="img-fluid" />
                                </div>
                                <div class="parts-type">
                                  Engine
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <div class="card mb-4">
                              <div class="card-body">
                                <div class="search-img-container">
                                  <img alt="" src="" class="img-fluid" />
                                </div>
                                <div class="parts-type">
                                  Engine
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <div class="card mb-4">
                              <div class="card-body">
                                <div class="search-img-container">
                                  <img alt="" src="" class="img-fluid" />
                                </div>
                                <div class="parts-type">
                                  Engine
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <div class="card mb-4">
                              <div class="card-body">
                                <div class="search-img-container">
                                  <img alt="" src="" class="img-fluid" />
                                </div>
                                <div class="parts-type">
                                  Engine
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <div class="card mb-4">
                              <div class="card-body">
                                <div class="search-img-container">
                                  <img alt="" src="" class="img-fluid" />
                                </div>
                                <div class="parts-type">
                                  Engine
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <div class="card mb-4">
                              <div class="card-body">
                                <div class="search-img-container">
                                  <img alt="" src="" class="img-fluid" />
                                </div>
                                <div class="parts-type">
                                  Engine
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <div class="card mb-4">
                              <div class="card-body">
                                <div class="search-img-container">
                                  <img alt="" src="" class="img-fluid" />
                                </div>
                                <div class="parts-type">
                                  Engine
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <div class="card mb-4">
                              <div class="card-body">
                                <div class="search-img-container">
                                  <img alt="" src="" class="img-fluid" />
                                </div>
                                <div class="parts-type">
                                  Engine
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <div class="card mb-4">
                              <div class="card-body">
                                <div class="search-img-container">
                                  <img alt="" src="" class="img-fluid" />
                                </div>
                                <div class="parts-type">
                                  Engine
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <div class="card mb-4">
                              <div class="card-body">
                                <div class="search-img-container">
                                  <img alt="" src="" class="img-fluid" />
                                </div>
                                <div class="parts-type">
                                  Engine
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <div class="card mb-4">
                              <div class="card-body">
                                <div class="search-img-container">
                                  <img alt="" src="" class="img-fluid" />
                                </div>
                                <div class="parts-type">
                                  Engine
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-end">
                            <a href="javascript:void(0)" class="view-all">View All <img
                                src="./assets/images/arrow-right.svg" /></a>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-end">
                        <a href="javascript:void(0)" class="view-all">View All <img
                            src="./assets/images/arrow-right.svg" /></a>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end new search after discussion-->
                <!--search on colloquail-->
                <div class="search-on-coll" [ngClass]="showdiv === 'colloquail' ? '':'d-none'">
                  <h4>Did you mean</h4>
                  <ul>
                    <li>Gear Box</li>
                    <li>Gear valve box kit</li>
                    <li>Gear shift box</li>
                    <li>Gear level kit </li>
                    <li>Gear oil & grease kit</li>
                    <li>Gear holder box</li>
                  </ul>
                </div>
              </div>
              <!--after login-->
              <div class="dropdown-menu  searchbox-search" style="display: none; min-width: 1024px !important;"
                *ngIf="isLoggedIn && showdiv">
                <!--for post search width is 1024px-->
                <!--style="display: block;"-->
                <!--search on part number-->
                <div class="search-part-number" [ngClass]="showdiv === 'part-number' ? '':'d-none'">
                  <div class="row">
                    <div class="col-12 col-sm-8 col-md-9 col-lg-9 col-xl-9">
                      <h3>33 parts found for <span>number ’0191’</span></h3>
                      <div class="row">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-end">
                          <a href="javascript:void(0)" class="view-all">View All <img
                              src="./assets/images/arrow-right.svg" /></a>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-4 col-md-3 col-lg-3 col-xl-3 position-relative divider-vertical">
                      <h3>Past orders containing <span>number ’0191’</span></h3>
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div class="card mb-3">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-3 pe-0">
                                <img class="img-fluid" alt="" />
                              </div>
                              <div class="col-9 ps-1">
                                <div class="model-no">885433012829</div>
                                <div class="model-desc">Back and Front Back and Front </div>
                                <div class="model-type">BS6</div>
                                <div class="model-bread">Engine > Air Compressor</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div class="card mb-3">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-3 pe-0">
                                <img class="img-fluid" alt="" />
                              </div>
                              <div class="col-9 ps-1">
                                <div class="model-no">885433012829</div>
                                <div class="model-desc">Back and Front Back and Front </div>
                                <div class="model-type">BS6</div>
                                <div class="model-bread">Engine > Air Compressor</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div class="card mb-3">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-3 pe-0">
                                <img class="img-fluid" alt="" />
                              </div>
                              <div class="col-9 ps-1">
                                <div class="model-no">885433012829</div>
                                <div class="model-desc">Back and Front Back and Front </div>
                                <div class="model-type">BS6</div>
                                <div class="model-bread">Engine > Air Compressor</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-end">
                        <a href="javascript:void(0)" class="view-all">View All <img
                            src="./assets/images/arrow-right.svg" /></a>
                      </div>
                    </div>
                  </div>
                </div>
                <!--search on registration number-->
                <div class="search-registration-number" [ngClass]="showdiv === 'registration-number' ? '':'d-none'">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-9 col-xl-9 col-lg-9">
                      <div class="d-flex">
                        <div class="image-container">
                          <div class="prod-img">
                            <img alt="" src="" class="img-fluid" />
                          </div>
                          <div class="prod-cat">PRIMA</div>
                          <div>
                            BS6
                            <span>Emission Norms</span>
                          </div>
                          <div>
                            557906
                            <span>Chasis Type</span>
                          </div>
                          <div>
                            2015
                            <span>Year</span>
                          </div>
                          <div>
                            TIPPER
                            <span>Body Type</span>
                          </div>
                          <div>
                            10
                            <span>No. of Tyre</span>
                          </div>

                        </div>
                        <div class="search-result-container">
                          <h3>13 product suggestions for <span>‘MH 12 SE 6765 Air Filter’</span></h3>
                          <div class="row">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                              <div class="card mb-3">
                                <div class="card-body">
                                  <div class="row">
                                    <div class="col-3 pe-0">
                                      <img class="img-fluid" alt="" />
                                    </div>
                                    <div class="col-9 ps-1">
                                      <div class="model-no">885433012829</div>
                                      <div class="model-desc">Back and Front Back and Front </div>
                                      <div class="model-type">BS6</div>
                                      <div class="model-bread">Engine > Air Compressor</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                              <div class="card mb-3">
                                <div class="card-body">
                                  <div class="row">
                                    <div class="col-3 pe-0">
                                      <img class="img-fluid" alt="" />
                                    </div>
                                    <div class="col-9 ps-1">
                                      <div class="model-no">885433012829</div>
                                      <div class="model-desc">Back and Front Back and Front </div>
                                      <div class="model-type">BS6</div>
                                      <div class="model-bread">Engine > Air Compressor</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                              <div class="card mb-3">
                                <div class="card-body">
                                  <div class="row">
                                    <div class="col-3 pe-0">
                                      <img class="img-fluid" alt="" />
                                    </div>
                                    <div class="col-9 ps-1">
                                      <div class="model-no">885433012829</div>
                                      <div class="model-desc">Back and Front Back and Front </div>
                                      <div class="model-type">BS6</div>
                                      <div class="model-bread">Engine > Air Compressor</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                              <div class="card mb-3">
                                <div class="card-body">
                                  <div class="row">
                                    <div class="col-3 pe-0">
                                      <img class="img-fluid" alt="" />
                                    </div>
                                    <div class="col-9 ps-1">
                                      <div class="model-no">885433012829</div>
                                      <div class="model-desc">Back and Front Back and Front </div>
                                      <div class="model-type">BS6</div>
                                      <div class="model-bread">Engine > Air Compressor</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                              <div class="card mb-3">
                                <div class="card-body">
                                  <div class="row">
                                    <div class="col-3 pe-0">
                                      <img class="img-fluid" alt="" />
                                    </div>
                                    <div class="col-9 ps-1">
                                      <div class="model-no">885433012829</div>
                                      <div class="model-desc">Back and Front Back and Front </div>
                                      <div class="model-type">BS6</div>
                                      <div class="model-bread">Engine > Air Compressor</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                              <div class="card mb-3">
                                <div class="card-body">
                                  <div class="row">
                                    <div class="col-3 pe-0">
                                      <img class="img-fluid" alt="" />
                                    </div>
                                    <div class="col-9 ps-1">
                                      <div class="model-no">885433012829</div>
                                      <div class="model-desc">Back and Front Back and Front </div>
                                      <div class="model-type">BS6</div>
                                      <div class="model-bread">Engine > Air Compressor</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                              <div class="card mb-3">
                                <div class="card-body">
                                  <div class="row">
                                    <div class="col-3 pe-0">
                                      <img class="img-fluid" alt="" />
                                    </div>
                                    <div class="col-9 ps-1">
                                      <div class="model-no">885433012829</div>
                                      <div class="model-desc">Back and Front Back and Front </div>
                                      <div class="model-type">BS6</div>
                                      <div class="model-bread">Engine > Air Compressor</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                              <div class="card mb-3">
                                <div class="card-body">
                                  <div class="row">
                                    <div class="col-3 pe-0">
                                      <img class="img-fluid" alt="" />
                                    </div>
                                    <div class="col-9 ps-1">
                                      <div class="model-no">885433012829</div>
                                      <div class="model-desc">Back and Front Back and Front </div>
                                      <div class="model-type">BS6</div>
                                      <div class="model-bread">Engine > Air Compressor</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                              <div class="card mb-3">
                                <div class="card-body">
                                  <div class="row">
                                    <div class="col-3 pe-0">
                                      <img class="img-fluid" alt="" />
                                    </div>
                                    <div class="col-9 ps-1">
                                      <div class="model-no">885433012829</div>
                                      <div class="model-desc">Back and Front Back and Front </div>
                                      <div class="model-type">BS6</div>
                                      <div class="model-bread">Engine > Air Compressor</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                              <div class="card mb-3">
                                <div class="card-body">
                                  <div class="row">
                                    <div class="col-3 pe-0">
                                      <img class="img-fluid" alt="" />
                                    </div>
                                    <div class="col-9 ps-1">
                                      <div class="model-no">885433012829</div>
                                      <div class="model-desc">Back and Front Back and Front </div>
                                      <div class="model-type">BS6</div>
                                      <div class="model-bread">Engine > Air Compressor</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-end">
                              <a href="javascript:void(0)" class="view-all">View All <img
                                  src="./assets/images/arrow-right.svg" /></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 position-relative divider-vertical">
                      <h3>Past orders for <span>‘Air Filter’</span></h3>
                      <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-end">
                          <a href="javascript:void(0)" class="view-all">View All <img
                              src="./assets/images/arrow-right.svg" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--search on own fleet-->
                <div class="search-own-fleet" [ngClass]="showdiv === 'own-fleet' ? '':'d-none'">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
                      <div class="d-flex">
                        <div class="image-container">
                          <div class="prod-img">
                            <img alt="" src="" class="img-fluid" />
                          </div>
                          <div class="prod-cat">PRIMA</div>
                          <div>
                            BS6
                            <span>Emission Norms</span>
                          </div>
                          <div>
                            557906
                            <span>Chasis Type</span>
                          </div>
                          <div>
                            2015
                            <span>Year</span>
                          </div>
                          <div>
                            TIPPER
                            <span>Body Type</span>
                          </div>
                          <div>
                            10
                            <span>No. of Tyre</span>
                          </div>
                        </div>
                        <div class="search-result-container">
                          <h3>Assembely groups (19)</h3>
                          <div class="row">
                            <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                              <div class="card mb-4">
                                <div class="card-body">
                                  <div class="search-img-container">
                                    <img alt="" src="" class="img-fluid" />
                                  </div>
                                  <div class="parts-type">
                                    Engine
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                              <div class="card mb-4">
                                <div class="card-body">
                                  <div class="search-img-container">
                                    <img alt="" src="" class="img-fluid" />
                                  </div>
                                  <div class="parts-type">
                                    Engine
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                              <div class="card mb-4">
                                <div class="card-body">
                                  <div class="search-img-container">
                                    <img alt="" src="" class="img-fluid" />
                                  </div>
                                  <div class="parts-type">
                                    Engine
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                              <div class="card mb-4">
                                <div class="card-body">
                                  <div class="search-img-container">
                                    <img alt="" src="" class="img-fluid" />
                                  </div>
                                  <div class="parts-type">
                                    Engine
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                              <div class="card mb-4">
                                <div class="card-body">
                                  <div class="search-img-container">
                                    <img alt="" src="" class="img-fluid" />
                                  </div>
                                  <div class="parts-type">
                                    Engine
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                              <div class="card mb-4">
                                <div class="card-body">
                                  <div class="search-img-container">
                                    <img alt="" src="" class="img-fluid" />
                                  </div>
                                  <div class="parts-type">
                                    Engine
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                              <div class="card mb-4">
                                <div class="card-body">
                                  <div class="search-img-container">
                                    <img alt="" src="" class="img-fluid" />
                                  </div>
                                  <div class="parts-type">
                                    Engine
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                              <div class="card mb-4">
                                <div class="card-body">
                                  <div class="search-img-container">
                                    <img alt="" src="" class="img-fluid" />
                                  </div>
                                  <div class="parts-type">
                                    Engine
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                              <div class="card mb-4">
                                <div class="card-body">
                                  <div class="search-img-container">
                                    <img alt="" src="" class="img-fluid" />
                                  </div>
                                  <div class="parts-type">
                                    Engine
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                              <div class="card mb-4">
                                <div class="card-body">
                                  <div class="search-img-container">
                                    <img alt="" src="" class="img-fluid" />
                                  </div>
                                  <div class="parts-type">
                                    Engine
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                              <div class="card mb-4">
                                <div class="card-body">
                                  <div class="search-img-container">
                                    <img alt="" src="" class="img-fluid" />
                                  </div>
                                  <div class="parts-type">
                                    Engine
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-end">
                              <a href="javascript:void(0)" class="view-all">View All <img
                                  src="./assets/images/arrow-right.svg" /></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3 position-relative divider-vertical">
                      <h3>Past orders</h3>
                      <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-end">
                          <a href="javascript:void(0)" class="view-all">View All <img
                              src="./assets/images/arrow-right.svg" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--search on click-->
                <div class="search-on-click" [ngClass]="showdiv === 'on-click' ? '':'d-none'">
                  <div class="d-flex">
                    <div class="recent-search">
                      <h5>
                        <img alt="" src="./assets/images/history.svg" /> Recent searches
                      </h5>
                      <ul>
                        <li>885433012829</li>
                        <li class="active">bearings axle 5mm back</li>
                        <li>bearings front axle</li>
                        <li>bearings </li>
                        <li>beering</li>
                        <li>Tata Ultra</li>
                        <li>Air Filter Assy.</li>
                        <li>1516 Air Filter</li>
                        <li>Air Filter</li>
                        <li>9802455248592</li>
                      </ul>
                    </div>
                    <div class="top-models">
                      <h5>
                        <img alt="" src="./assets/images/truck.svg" /> Top Models
                      </h5>
                      <div class="box-container">
                        <div class="text-con">ULTRA 500</div>
                        <div class="img-con"><img alt="" src="" class="img-fluid" /></div>
                      </div>
                      <div class="box-container active">
                        <div class="text-con">ULTRA 500</div>
                        <div class="img-con"><img alt="" src="" class="img-fluid" /></div>
                      </div>
                      <div class="box-container">
                        <div class="text-con">ULTRA 500</div>
                        <div class="img-con"><img alt="" src="" class="img-fluid" /></div>
                      </div>
                    </div>
                    <div class="popular-parts">
                      <h5>
                        <img alt="" src="./assets/images/settings.svg" /> Popular Parts
                      </h5>
                      <div class="row">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--search on colloquail-->
                <div class="search-on-coll" [ngClass]="showdiv === 'on-coll' ? '':'d-none'">
                  <h4>Did you mean</h4>
                  <ul>
                    <li>Gear Box</li>
                    <li>Gear valve box kit</li>
                    <li>Gear shift box</li>
                    <li>Gear level kit </li>
                    <li>Gear oil & grease kit</li>
                    <li>Gear holder box</li>
                  </ul>
                </div>
                <!--Search on part description-->
                <div class="search-part-desc" [ngClass]="showdiv === 'part_description' ? '':'d-none'">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 search-suggestions">
                      <h4>Suggestions</h4>
                      <ul>
                        <li>Air filter primary</li>
                        <li>Air filter ejector</li>
                        <li>Air filter safety element</li>
                        <li>Air filter hose </li>
                      </ul>
                    </div>
                    <div class="col-12 col-sm-12 col-md-4 col-lg-7 col-xl-7 search-current">
                      <!-- <h4>43 product suggestions for  <span>’Air filter’</span></h4> -->
                      <h4>{{suggestPartNodata?.data?.length}} product suggestions for <span>’{{searchtext}}’</span></h4>
                      <div class="row">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4"
                          *ngFor="let objpart of suggestPartNodata?.data;let p = index">
                          <div class="card mb-3">
                            <div class="card-body" (click)="GotoPage(objpart,suggestPartNodata)">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" [src]="objpart?.image_url" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">{{objpart?.part_number}}</div>
                                  <div class="model-desc">{{objpart?.desc_text}} </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> -->
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-end">
                          <a href="javascript:void(0)" class="view-all">View All <img
                              src="./assets/images/arrow-right.svg" /></a>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 search-past">
                      <h4>Past orders for <span>’Air filter’</span></h4>
                      <div class="row">
                        <div class="col-12 col-sm-12 col-md-12">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-end">
                          <a href="javascript:void(0)" class="view-all">View All <img
                              src="./assets/images/arrow-right.svg" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="dropdown-menu searchbox-search"
                [ngClass]="showSearchdiv === 'SearchData' ? 'd-block':'d-none'">
                <!--for post search width is 1024px-->
                <!--style="display: block;"-->
                <!--multi search Awadhesh-->
                <!--new search after discussion-->
                <!--search on click-->
                <!-- [ngClass]="showdiv === 'on-click' ? 'd-block':'d-none'" -->
                <ng-container *ngIf="!isAutoSuggestApiLoaded;else showAutoSuggestModal">
                  <div class="custom-close">
                    <skc-rectangle [loading]="loading" [skc-height]="'43px'" [skc-width]="'43px'"> </skc-rectangle>
                  </div>
                  <div class="search-on-click d-block">
                    <div class="d-flex">
                      <div class="top-models">
                        <h5>
                          <skc-rectangle [loading]="loading" [skc-height]="'20px'" [skc-width]="'150px'">
                          </skc-rectangle>
                        </h5>
                        <ng-container *ngFor="let item of userService.generateFake(6)">
                          <div class="box-container">
                            <div class="text-con text-align-center">
                              <skc-rectangle [loading]="loading" [skc-height]="'18px'" [skc-width]="'90%'">
                              </skc-rectangle>
                            </div>
                            <div class="img-con">
                              <skc-rectangle [loading]="loading" [skc-height]="'35px'" [skc-width]="'45px'">
                              </skc-rectangle>
                            </div>
                          </div>
                        </ng-container>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-end">
                          <a class="view-all">
                            <skc-rectangle [loading]="loading" [skc-height]="'20px'" [skc-width]="'125px'">
                            </skc-rectangle>
                          </a>
                        </div>
                      </div>
                      <div class="popular-parts d-block">
                        <h5>
                          <skc-rectangle [loading]="loading" [skc-height]="'20px'" [skc-width]="'50%'"> </skc-rectangle>
                        </h5>
                        <div class="row">
                          <ng-container *ngFor="let item of userService.generateFake(12)">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                              <div class="card mb-3 card-height-for-search">
                                <div class="card-body">
                                  <div class="custom-img-wrapper">
                                    <skc-rectangle [loading]="loading" [skc-height]="'40px'" [skc-width]="'60px'">
                                    </skc-rectangle>
                                  </div>
                                  <div class="custom-text-wrapper">
                                    <div class="model-type">
                                      <skc-rectangle [loading]="loading" [skc-height]="'15px'" [skc-width]="'100%'">
                                      </skc-rectangle>
                                    </div>
                                    <div class="model-type">
                                      <skc-rectangle [loading]="loading" [skc-height]="'15px'" [skc-width]="'100%'">
                                      </skc-rectangle>
                                    </div>
                                    <div class="model-type">
                                      <skc-rectangle [loading]="loading" [skc-height]="'15px'" [skc-width]="'100%'">
                                      </skc-rectangle>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ng-container>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-end">
                          <a class="view-all">
                            <skc-rectangle [loading]="loading" [skc-height]="'18px'" [skc-width]="'100%'">
                            </skc-rectangle>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-template #showAutoSuggestModal>
                  <div class="custom-close">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                      (click)="clear()"></button>
                  </div>
                  <div class="search-on-click" [ngClass]="showSearchdiv === 'SearchData' ? 'd-block':'d-none'">
                    <div class="d-flex">
                      <div class="image-container" style="height: 500px;overflow: auto;" *ngIf="showdivModel">
                        <div class="prod-img">
                          <img alt="" [src]="singleModelResponse?.image_url"
                            onerror="this.src='./assets/tata-vehicle.jfif';" class="img-fluid" />
                        </div>
                        <div class="prod-cat">{{singleModelResponse?.base_model}}</div>
                        <div *ngIf="singleModelResponse?.model_cat">
                          {{singleModelResponse?.model_cat}}
                          <span>Model Category</span>
                        </div>
                        <div *ngIf="singleModelResponse?.model_desc">
                          {{singleModelResponse?.model_desc}}
                          <span>Model Description</span>
                        </div>
                        <div *ngIf="singleModelResponse?.chassis_type">
                          {{singleModelResponse?.chassis_type}}
                          <span>Chassis type</span>
                        </div>
                        <div *ngIf="singleModelResponse?.engine_type">
                          {{singleModelResponse?.engine_type}}
                          <span>Engine Type</span>
                        </div>
                        <div *ngIf="singleModelResponse?.fuel_type">
                          {{singleModelResponse?.fuel_type}}
                          <span>Fuel Type</span>
                        </div>
                        <div *ngIf="singleModelResponse?.vc_no">
                          {{singleModelResponse?.vc_no}}
                          <span>VC No.</span>
                        </div>
                      </div>
                      <div class="top-models" *ngIf="showdivMultiModel">
                        <!-- <h5><img alt="" src="./assets/images/truck.svg" /> Top Models</h5> -->
                        <h5><img alt="" src="./assets/images/truck.svg" /> Search Models</h5>
                        <ng-container *ngFor="let objmodel of allModelData; let m = index">
                          <div class="box-container" *ngIf="m<6" (click)="ShowModelAggregate(objmodel)"
                            [ngClass]="activeBaseModel == objmodel.base_model ? 'active':''">
                            <div class="text-con" title="{{objmodel?.base_model}}">
                              <!-- {{ (objmodel?.model_cat == " " || objmodel?.model_cat == null) ? "-" : objmodel?.model_cat?.substr(0,10)}} -->
                              {{ (objmodel?.base_model != null && objmodel?.base_model != undefined && objmodel?.base_model != '') ? objmodel?.base_model : '-' }}
                            </div>
                            <div class="img-con"><img alt="" [src]="objmodel?.image_url" class="img-fluid"
                                onerror="this.src='./assets/tata-vehicle.jfif';" /></div>
                          </div>
                        </ng-container>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-end"
                          *ngIf="allModelData?.length>6">
                          <!-- <a  href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#SearchModal" class="view-all">View All <img
                            src="./assets/images/arrow-right.svg" /></a> -->
                          <a (click)="ViewModel()" class="view-all">View All <img
                              src="./assets/images/arrow-right.svg" /></a>
                        </div>
                        <!-- <div class="box-container active">
                          <div class="text-con">ULTRA 500</div>
                          <div class="img-con"><img alt="" src=""
                              class="img-fluid" /></div>
                        </div>
                        <div class="box-container">
                          <div class="text-con">ULTRA 500</div>
                          <div class="img-con"><img alt="" src=""
                              class="img-fluid" /></div>
                        </div> -->
                      </div>
                      <div class="popular-parts" [ngClass]="showdiv === 'part_description' ? 'd-block':'d-none'">
                        <!-- <h5>
                          <img alt="" src="./assets/images/settings.svg" /> Popular Parts
                        </h5> -->
                        <h5>{{suggestPartNodata?.total_count}} product suggestions for <span>’{{searchtext}}’</span>
                        </h5>
                        <div class="row">
                          <!-- <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"> -->
                          <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4"
                            *ngFor="let objpart of suggestPartNodata?.data;let p = index">
                            <div class="card mb-3 card-height-for-search">
                              <div class="card-body" (click)="GotoPage(objpart,suggestPartNodata)">
                                <!-- <div class="row"> -->
                                <div class="custom-img-wrapper">
                                  <img class="img-fluid" alt="" [src]="objpart?.image_url"
                                    onerror="this.src='./assets/tata-vehicle.jfif';" />
                                </div>
                                <div class="custom-text-wrapper">
                                  <div class="model-no" title="{{objpart?.desc_text}}">{{objpart?.desc_text}} </div>
                                  <div class="model-desc">{{objpart?.part_number}}</div>    
                                  <div class="model-type">{{objpart?.line_2}}</div>
                                  <!-- <div class="model-bread">Engine > Air Compressor</div> -->
                                </div>
                                <!-- </div> -->
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-end"
                          *ngIf="suggestPartNodata?.total_count > 15">
                          <a (click)="showSearchdiv=''" (click)="GoTOAllModelPart('Part',searchtext)"
                            class="view-all">View All <img src="./assets/images/arrow-right.svg" /></a>
                        </div>
                      </div>
                      <ng-container *ngIf="isModelSelected && !isAggregateApiLoaded; else showAggregates">
                        <div class="search-result-container" style="height: 500px;overflow: auto;">
                          <div class="container">
                            <a>
                              <skc-skeleton [type]="'line'" [loading]="loading" [skc-height]="'1.5em'"
                                [skc-width]="'100%'"> </skc-skeleton>
                            </a>
                            <br />
                            <div class="row" style="margin-top: 5px;">
                              <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3"
                                *ngFor="let item of userService.generateFake(12);">
                                <div class="card mb-4">
                                  <div class="card-body">
                                    <div class="search-img-container">
                                      <skc-rectangle [loading]="loading" [skc-height]="'67px'" [skc-width]="'96px'">
                                      </skc-rectangle>
                                    </div>
                                    <div class="parts-type ellipse-2line pt-1" style="height: 40px;">
                                      <skc-rectangle [loading]="loading" [skc-height]="'40px'" [skc-width]="'136px'">
                                      </skc-rectangle>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                      <ng-template #showAggregates>
                        <div class="search-result-container" *ngIf="showdivAggregate"
                          style="height: 500px;overflow: auto;">
                          <div class="container">
                            <a style="float:right;" (click)="showSearchdiv=''" (click)="viewAll('', 'POPULAR MODELS');"
                              class="view-all">View all parts for this Model </a>
                            <br />
                            <div class="row" style="margin-top: 5px;">
                              <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3"
                                *ngFor="let objaggregate of modelAggregateData;let j = index">
                                <div class="card mb-4">
                                  <div class="card-body" (click)="gotoModel(objaggregate.aggregate,'aggregate')">
                                    <div class="search-img-container">
                                      <img alt="" [src]="objaggregate?.aggregate_image" class="img-fluid" />
                                    </div>
                                    <div class="parts-type ellipse-2line pt-1" title="{{objaggregate.aggregate}}"
                                      style="height: 40px;">
                                      {{objaggregate.aggregate}}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              `
                            </div>
                          </div>
                          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-end"
                            *ngIf="modelAggregateData?.length>0">
                            <!-- <a  (click)="showSearchdiv=''" [routerLink]="['/shop/productcards']" class="view-all">View All <img
                                  src="./assets/images/arrow-right.svg" /></a> -->
                          </div>
                        </div>
                      </ng-template>
                    </div>
                    <div class="no-search-found" *ngIf="showNoRecord">
                      <img alt="No result found" src="./assets/images/empty box.png" /><br> No Search Found !
                    </div>
                  </div>
                </ng-template>

                <!--end new search after discussion-->
              </div>
            
            </div>        
            <!--Search On Audio-->
            <div class="dropdown" style="display: none;">
              <button type="button" role="button"><img alt="" src="" /> </button>
              <div class="dropdown-menu voice-search" style="min-width: 1024px !important;">
                <!-- min-width: 1024px !important; when data search after login -->
                <!--style="display: block;"-->
                <!--Pre Search-->
                <div class="pre-search d-none">
                  <h3>Voice search</h3>
                  <div class="row align-items-center mb-4">
                    <div class="col-9 voice-text">Listening…
                      <!--Air Filter...-->
                    </div>
                    <div class="col-3 text-center" style="display: flex; justify-content: center;">
                      <div class="mic-wrapper">
                        <img alt="mic" src="./assets/images/voice-mic.svg" />
                      </div>
                    </div>
                  </div>
                </div>
                <!--Post Search-->
                <div class="post-search d-none">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 search-suggestions">
                      <h4>Suggestions</h4>
                      <ul>
                        <li>Air filter primary</li>
                        <li>Air filter ejector</li>
                        <li>Air filter safety element</li>
                        <li>Air filter hose </li>
                      </ul>
                    </div>
                    <div class="col-12 col-sm-12 col-md-4 col-lg-8 col-xl-8 search-current">
                      <h4>43 product suggestions for <span>’Air filter’</span></h4>
                      <div class="row">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-end">
                          <a href="javascript:void(0)" class="view-all">View All <img
                              src="./assets/images/arrow-right.svg" /></a>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 search-past">
                      <h4>Past orders for <span>’Air filter’</span></h4>
                      <div class="row">
                        <div class="col-12 col-sm-12 col-md-12">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-3 pe-0">
                                  <img class="img-fluid" alt="" />
                                </div>
                                <div class="col-9 ps-1">
                                  <div class="model-no">885433012829</div>
                                  <div class="model-desc">Back and Front Back and Front </div>
                                  <div class="model-type">BS6</div>
                                  <div class="model-bread">Engine > Air Compressor</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-end">
                          <a href="javascript:void(0)" class="view-all">View All <img
                              src="./assets/images/arrow-right.svg" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--Search on image-->
            <div class="dropdown" style="display: none;">
              <button type="button" role="button"><img alt="" src="" /></button>
              <div class="dropdown-menu image-search">
                <!-- style="display: block;"-->
                <!--Pre search UI-->
                <div class="pre-search">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3 divider">
                      <div class="box-container">
                        <img alt="" src="./assets/images/drag-drop.svg" />
                        <p>Drag and Drop selected products here</p>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3 divider">
                      <div class="box-container">
                        <img alt="" src="./assets/images/camera.svg" />
                        <p>Click photo of product</p>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3 divider">
                      <div class="box-container">
                        <img alt="" src="./assets/images/scan.svg" />
                        <p>SCAN Image</p>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                      <div class="box-container">
                        <label class="custom-file-upload">
                          <input type="file" /> BROWSE IMAGE
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <!--post search UI    ///   d-flex-->
                <div class="post-search d-flex">
                  <div class="picture-wrapper">
                    <h3>Picture Search</h3>
                    <div class="picture-container">
                      <a class="close" href="javascript:void(0);"><img alt="" src="./assets/images/close.svg" />
                      </a>
                      <img class="img-fluid picture" alt="" />
                    </div>
                    <div class="pt-1">
                      <div class="picture-name">Airfilter_1.jpeg</div>
                      <div class="Uploaded ">Uploaded <img alt="" src="./assets/images/right-green.svg" /></div>
                    </div>
                  </div>
                  <div class="picture-result-container">
                    <h4>Showing results</h4>
                    <div class="row mt-2">
                      <div class="col-12 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                        <div class="card mb-4">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-4 pe-0">
                                <img class="img-fluid" alt="" />
                              </div>
                              <div class="col-8 ps-1">
                                <div class="model-no">885433012829</div>
                                <div class="model-desc">Back and Front Back and Front </div>
                                <div class="model-type">BS6</div>
                                <div class="model-bread">Engine > Air Compressor</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                        <div class="card mb-4">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-4 pe-0">
                                <img class="img-fluid" alt="" />
                              </div>
                              <div class="col-8 ps-1">
                                <div class="model-no">885433012829</div>
                                <div class="model-desc">Back and Front Back and Front </div>
                                <div class="model-type">BS6</div>
                                <div class="model-bread">Engine > Air Compressor</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                        <div class="card mb-4">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-4 pe-0">
                                <img class="img-fluid" alt="" />
                              </div>
                              <div class="col-8 ps-1">
                                <div class="model-no">885433012829</div>
                                <div class="model-desc">Back and Front Back and Front </div>
                                <div class="model-type">BS6</div>
                                <div class="model-bread">Engine > Air Compressor</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- (click)="onSubmit()" -->
            
            <button class="btn-search" type="button" role="button" (click)="onSubmitshowpopup()" [style.cursor]="startbtnClicked ? 'not-allowed' : '' " [disabled]="startbtnClicked"><img alt=""
                src="./assets/images/search-h.svg"/></button>
                <!-- <button [ngClass]="showSearchButton ? 'btn-search nomargin' : 'btn-search nomargin btn-disabled' " name="btnActivateSpeechSearchMovie"
                id="btnActivateSpeechSearchMovie" (click)="activateSpeechSearchMovie()" [disabled]="!showSearchButton">
                <i class="fa fa-microphone" aria-hidden="true"></i>
              </button> -->
             
              <div id="speech_id" style="background-color: #080c5c;">
                <app-web-speech (emitText)="emitText($event)" (btnClick)="startClicked($event)" (stopClick)="stopClick($event)" [clearData]="clearData"></app-web-speech>
              </div>
              <div style="background-color: #080c5c;">
                <button class="btn-search" type="button"  role="button" (click)="openScannerModel()"><i style="font-size: 22px;" class="fa fa-qrcode" aria-hidden="true"></i></button>
              </div>
             
          </div>
          <div class="searchSuggest" id="search_Suggest" [style.width.px]="searchtextWidth" [id]="isLoggedIn? 'dynamic_margin_freetextserach':'dmf'" *ngIf="isAutoSuggest">
            <div *ngFor="let option of autoSuggestList">
              <div class="suggesionVal" style="padding: 5px;" (click)="onSuggetionClick(option)">
              <a (click)="onSuggetionClick(option)">{{option}}</a>
              </div>
              <!-- <div class="no-search-found" *ngIf="showNoRecord">
                <img alt="No result found" src="./assets/images/empty box.png" /> No Search Found !
              </div> -->
            </div>
          </div>
        </form>
      </div>
      <div class="col-1 col-sm-2 col-md-2 col-lg-1 col-xl-2 mobile-peacock">
        <div style="float: right;">
          <img src="./assets/images/youtube.png" (click)="openVideoLink()" class="img-fluid d-block"
            style="margin-left: 10px; max-height: 24px;cursor: pointer;" />
            <small (click)="openVideoLink()" style="cursor: pointer;font-size: 11px;">Help Video</small>
        </div>
      </div>
      <!-- <div class="col-5 col-sm-4 col-md-4 col-lg-2 col-xl-2 mx-auto">
        <li class="wsshopmyaccount clearfix drop-arriving" *ngIf="isLoggedIn && mintifylimit && userDetails?.user_type=='RT'" style="list-style: none;margin-left: 0px;">
          <a class="rightnavtext d-flex pb-2" style="list-style: none; text-decoration: none;"><span class="
            right-nav-icon
            d-flex
            align-items-center
            justify-content-center
          " style="color: #fff;"><i class="fa fa-rupee-sign"></i></span>
        
            <div class="">
              <span style="color: #fff;">{{ mintifylimit?.availableCredit.toFixed(2) }}%</span><span style="color: #6ec3ff;text-wrap: nowrap;">Mintifi Limit</span>
            </div>
          </a>
           <div class="card arriving-hover-dropdown-mintify mt-2">
             <div class="triangle"></div>
             <div class="card-body arriving-today">
               <div class="order-summary">
                 <h4 class="mb-2">Credit Details</h4>                   
             </div>
             <div class="card shadow">
              <div class="row">
                <div class="col-md-3" style="margin: auto; text-align: center;">
                  <img src="../../../../../assets/icons/active-address.svg" height="50px" width="50px" style="border-radius: 50%;">
                </div>
                <ng-container *ngIf="mintifylimit">
                  <div class="col-md-9 p-2">
                    <h5 style="color: #000; text-align: center; font-size: 15px;"> Mintifi Limit Available: {{ mintifylimit?.available_limit | currencyFormat:'INR':true:'1.0-0' }}</h5>
                    <hr style="color: grey; height: 2px; margin: 5px;">
                    <h5 style="color: #000; text-align: center; font-size: 15px;"> Mintifi Limit Used: {{ mintifylimit?.used_limit | currencyFormat:'INR':true:'1.0-0'}}</h5>
                  </div>
                </ng-container>        
              </div>
             </div>
           </div>
           </div>
         </li>
      </div> -->
      <!--mintifi-->
      <!-- <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 mx-auto pb-3 pb-lg-0">
        <img src="./assets/images/GPIPSA-Horizontal.jpg" class="img-fluid" />
      </div> -->
    </div>
    <div class="d-flex">

    </div>

    <!-- <app-menu [setAddress]="setAddress" [pinCodeNumber]="pinCodeNumber" (addressChange)="changePincode($event)"
      (setAddressChange)="setAddressChange()"></app-menu> -->
    <app-menu [setAddress]="setAddress"></app-menu>


  </div>
</header>


<ng-template #logoutModal let-d="dismiss">
  <app-logout (userLogout)="logoutAPI($event)" (modalClose)="logout($event)" [isLogout]="isLogout"></app-logout>
</ng-template>

<ng-template #selectUser let-d="dismiss">
  <select-user (closeUserPopUp)="closeUserPopUp();" [btndisabled]="btndisabled" [userIds]="userIds"
    (setselectedUser)="setUser($event)" [isSwitchAccount]="isSwitchAccount" [currentAccount]="previousAccount">
  </select-user>
</ng-template>

<ng-template #SearchModalPopup let-d="dismiss">
  <!-- <div class="modal fade" id="SearchModal" tabindex="-1" aria-labelledby="searchModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered"> -->
  <div class="modal-content">
    <div class="modal-header modal-title  pt-2 pb-2">
      Search Model
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
        (click)=SearchModalRef.hide()></button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 search-model mb-3"
          *ngFor="let objmodel of allModelData; let m = index">
          <div class="box-container" (click)="gotoModel(objmodel,'Model')"
            [ngClass]="activeBaseModel == objmodel.base_model ? 'active':''">
            <div class="text-con" title="{{objmodel?.base_model}}">{{objmodel?.base_model}}</div>
            <div class="img-con"><img alt="" [src]="objmodel?.image_url" class="img-fluid"
                onerror="this.src='./assets/tata-vehicle.jfif';" /></div>
          </div>
        </div>
        <!-- <div class="dropdown-menu searchbox-search" [ngClass]="showSearchdiv === 'SearchData' ? 'd-block':'d-none'">
            <div class="search-on-click"  [ngClass]="showSearchdiv === 'SearchData' ? 'd-block':'d-none'">
              <div class="d-flex">
                <div class="top-models">
                  <ng-container *ngFor="let objmodel of allModelData; let m = index">
                  <div class="box-container"  (click)="ShowModelAggregate(objmodel)" [ngClass]="activeModel == objmodel.model_cat ? 'active':''">
                    <div class="text-con" title="{{objmodel?.model_cat}}">{{objmodel?.model_cat}}</div>
                    <div class="img-con"><img alt="" [src]="objmodel?.image_url" class="img-fluid" onerror="this.src='./assets/tata-vehicle.jfif';" /></div>
                  </div>
                </ng-container>
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-end" *ngIf="allModelData?.length>3">
                  <a  (click)="GoTOAllModelPart('Model',searchtext)" class="view-all">View All <img
                      src="./assets/images/arrow-right.svg" /></a>
                </div>
                </div>
                
              </div>
            </div>
          </div> -->
      </div>
    </div>
  </div>
  <!-- </div>
</div> -->
</ng-template>

<!-- <ng-container *ngIf="showLoader">
	<div class="loader">
		<div class="ng-spinner ng-spinner-icon"></div>
	</div>
</ng-container> -->
<ng-template #videoPlayer>
  <video-player [videoList]="videoList" (closeVideoPlayer)="videoPlayerModal.hide();"></video-player>
</ng-template>

<ng-template #openScanner>
  <app-qr-scanner [closeModel]="scanModalservice"></app-qr-scanner>
</ng-template>