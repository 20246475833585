import { ChangeDetectorRef, Component, Input, OnInit, Output, EventEmitter, SimpleChange, TemplateRef, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../services/REST/user.service';
import { CartService } from '../../services/cart.service';
import { blockPermissionMsg } from '../../../constant';
import { secureStorage } from '../../services/securestorage';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { QuickviewService } from '../../services/quickview.service';
declare var $: any;

@Component({
  selector: 'app-partpopup',
  templateUrl: './partpopup.component.html',
  styleUrls: ['./partpopup.component.scss']
})
export class PartpopupComponent implements OnInit {
  @ViewChild('confimationmodal', { read: TemplateRef, static: false }) template1: TemplateRef<any>;
  blockPermissionMsg = blockPermissionMsg;

  showDivPartdetails: boolean;

  quantity: number;
  showLoader: boolean = false;
  cartTypeList: any;
  showPartData;
  @Input() showPart;
  @Input() fromTop;
  @Input() fromLeft;
  @Input() pageName;
  @Input() userDetail:any
  @Output() closePopup = new EventEmitter<any>();
  modalRef1: any;

  selectcarttype: any;
  btnshowConfrimed: boolean = false;
  // btndisabled: boolean = false;
  btnshowsubmit: boolean = false;
  selectedProduct: any;
  // addingToCart = false;
  showingQuickview = false;
  productInfo:any=[]

  displayQuanityt:number = 0;
  buyAgain : any = 'buyAgain'
  constructor(
    private toastrService: ToastrService,
    private userService: UserService,
    public cart: CartService,
    private cd: ChangeDetectorRef,
    private modalService: BsModalService,
    private localstorage: secureStorage,
    public quickview: QuickviewService,
    private router: Router) { }

  ngOnInit(): void {
    console.log("check_show_part",this.showPart)
    this.showPartData = this.showPart
    this.showDivPartdetails = true;
    this.updateTmgoQuantity();
  }
  ngOnChanges(changes: SimpleChange) {
    this.showDivPartdetails = true;
    this.showPartData = changes['showPart'].currentValue;
    this.updateTmgoQuantity();
  }

  updateTmgoQuantity(){
    if(this.showPartData['resource_type'] == 'Pack'){
        this.displayQuanityt = this.showPartData['customerQuantity'] / this.showPartData['moq'];
    }
  }


  addtoCartitems(Item) {
    try {
      let permission = this.userService.CheckPermission(
        'btn_cart'
      );
      if (permission) {
        if (isNaN(Item.customerQuantity) || Item['customerQuantity'] === 0) {
          this.toastrService.error('Quantity cannot be empty or 0');
          return;
        }
        else if (Item.customerQuantity !== null && Item.customerQuantity > 0 && Item.customerQuantity !== undefined) {
          if (Item.customerQuantity < Item['min_quantity']) {
            this.toastrService.error('Quantity field cannot be less than minimum quantity');
            return;
          }
          else {
            this.showDivPartdetails = false;
            this.showPartData = [];
            $('#btn_' + Item.part_number).prop('disabled', true);
            if (Item['ordered_resource_type'] == 'Pack') {
              this.quantity = Item.customerQuantity ? Item.customerQuantity : 1;
            } else {
              this.quantity = Item.customerQuantity ? Item.customerQuantity : 1;
            }
            if(Item['cart_type'] == "tmgo_cart" && this.quantity >=  210000  && this.quantity%210000 > 0){
              this.toastrService.error("Something Went Wrong! This part cannot be added in the cart through Buy Again feature. Please use regular part search option and then add to cart.");
              return 
            }
            // this.showLoader = true;
            if (this.localstorage.getItem('getCartType')) {
              this.cartTypeList = JSON.parse(this.localstorage.getItem('getCartType'));
            }

            let cartTypeData = this.router.url.includes('active-order') ? Item['cart_type'] : Item['distributor_category'];

            var commoncarttypedata = this.cartTypeList.filter((alitems) => {
              if (alitems.common_distributor_categories.includes(cartTypeData))
                return alitems;
            }
            );


            var Distributercarttypedata = this.cartTypeList.filter((alitems) => {
              if (this.router.url.includes('active-order')) {
                if (alitems.cart_type === cartTypeData)
                  return alitems;
              }
              else {
                if (alitems.distributor_categories.includes(cartTypeData))
                  return alitems;
              }



            });


            let cart_type_name = '';
            if (commoncarttypedata.length > 0) {
              // console.log(this.localstorage.getItem('selectcarttype'))
              if (this.localstorage.getItem('selectcarttype')) {
                cart_type_name = this.localstorage.getItem('selectcarttype');

                this.cart
                  .add(
                    Item,
                    this.quantity,
                    cartTypeData,
                    cart_type_name
                  )
                  .subscribe({
                    complete: () => {
                      $('#btn_' + Item.part_number).prop('disabled', false);
                      this.cd.markForCheck();
                      this.showLoader = false;
                      this.ClosePart();
                    },
                  });
                localStorage.removeItem('selectcarttype');
              }
              else {
                cart_type_name = commoncarttypedata[0]["cart_type"]
                if (this.cartTypeList.length > 0) {
                  // this.selectcarttype = this.cartTypeList[0]["cart_type"];
                  this.selectedProduct = Item;
                  // this.btndisabled = false;
                  // this.addingToCart = false
                  this.btnshowConfrimed = false;
                  this.btnshowsubmit = true;
                  this.productInfo.data = [Item]
                  this.productInfo.division_info = {
                    ORG_NAME:Item['organization_name']?Item['organization_name']:"",
                    DIV_NAME:Item['division_name']?Item['division_name']:"",
                    PRIMISE_ADDRLINE1_s:Item['primise_addrline1']?Item['primise_addrline1']:"",
                    PRIMISE_ADDRESS2_s:Item['primise_addrline2']?Item['primise_addrline2']:"",
                    DIV_ID:Item['division_id']?Item['division_id']:"",
                    GSTIN:Item['gstn']?Item['gstn']:"",
                    ORG_Id:Item['organization_id']?Item['organization_id']:"",
                };
                  // this.changeCartType(this.selectcarttype, product);
                  this.modalRef1 = this.modalService.show(this.template1, { class: 'modal-dialog-centered modal-md', backdrop: 'static' });
                }
              }
            } else if (Distributercarttypedata.length > 0) {
              cart_type_name = Distributercarttypedata[0]['cart_type'];
              this.cart
                .add(
                  Item,
                  this.quantity,
                  cartTypeData,
                  cart_type_name
                  // ,false
                )
                .subscribe({
                  complete: () => {
                    $('#btn_' + Item.part_number).prop('disabled', false);
                    this.cd.markForCheck();
                    this.ClosePart();
                  },
                });
            }
          }
        }
        else {
          if ((Item['resource_type'] == 'Pack') || ( Item['ordered_resource_type'] == 'Pack')) {
            if (Item.customerQuantity < Item['min_quantity']) {
              this.toastrService.error('Unit field should not be empty.');

              return;
            }
          } else if (Item.customerQuantity == 0) {

            this.toastrService.error('Quantity field cannot be 0 .Minimum quantity required.');
          } else {
            this.toastrService.error('Quantity field should not be empty.');
          }
        }
      } else {
        this.toastrService.error(blockPermissionMsg);
      }
    }
    catch (error) {
      // console.log(error);
    }

  }
  readQuantity(event, product) {
    // product.customerQuantity = event
    if( this.showPartData['resource_type'] == 'Pack' && this.pageName != 'activeOrder'){ 
      this.showPartData['customerQuantity'] = event * this.showPartData['moq']; 
    }else{    
      product.customerQuantity = this.pageName == 'activeOrder' ? (isNaN(event) ? 1 : parseInt(event)) : parseInt(event)
    }
  }
  ClosePart() {
    this.showDivPartdetails = false;
    this.showPartData = [];
    this.closePopup.emit('Close')
  }
  checkNan(val){
    return isNaN(val)
  }
  setAddCart(cartType, product?: any) {
    //called when user hit submit on popup
    try {
      let permission = this.userService.CheckPermission(
        'btn_cart'
      );
      if (permission) {
        // this.btndisabled = true;
        var cart_type_name = cartType ? cartType : this.localstorage.getItem('selectcarttype');
        this.showLoader = true;
        this.cart.add(this.selectedProduct, this.selectedProduct['customerQuantity'], this.selectedProduct['distributor_category'], cart_type_name
          // ,false
        ).subscribe({
          complete: () => {
            localStorage.removeItem('selectcarttype');
            // this.addingToCart = false; 
            this.showLoader = false;
            this.ClosePart();
            this.cd.markForCheck();
          }
        });
        localStorage.removeItem('selectcarttype');
        this.selectcarttype = "";
        this.modalRef1.hide();
        // this.btndisabled = false;
        this.showLoader = false;
      }
      else {

        this.toastrService.error(blockPermissionMsg);
      }
    }
    catch (error) {
      this.userService.InsertExceptionLog({ "method_name": "setAddCart()", "page_name": "ProductCardComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
    }
  }
  changeCartType(product) {
    // console.log(product)
    //1st called on selection of radiobutton
  }
  ShowPart() {
    try {
      // this.btndisabled = true;
      localStorage.removeItem('selectcarttype');
      this.localstorage.setItem('selectcarttype', this.selectcarttype);
      this.modalRef1.hide();
      this.showQuickview();
    }
    catch (error) {
      this.userService.InsertExceptionLog({ "method_name": "ShowPart()", "page_name": "ProductCardComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
    }
  }
  closePart() {
    if (this.btnshowConfrimed) {
      localStorage.removeItem("selectcarttype");
      this.showQuickview();
    }
    localStorage.removeItem('selectcarttype')
    this.selectcarttype = "";
    this.modalRef1.hide();
  }
  showQuickview(): void {
    if (this.showingQuickview) {
      return;
    }
    this.showingQuickview = true;
    this.quickview.show(this.selectedProduct).subscribe({
      complete: () => {
        this.showingQuickview = false;
        this.cd.markForCheck();
      }
    });
  }
}
