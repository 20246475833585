import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie';
import { Product } from '../../../../../../src/app/shared/interfaces/product';
import { AppStateService } from '../../../../../../src/app/shared/services/app-state/app-state.service';
import { CartService } from '../../../../../../src/app/shared/services/cart.service';
import { ProductService } from '../../../../../../src/app/shared/services/REST/product.service';
import { RootService } from '../../../../../../src/app/shared/services/REST/root.service';
import { UserService } from '../../../../../../src/app/shared/services/REST/user.service';
import { Inputdata, InputPartSearch } from '../../../../../../src/app/shared/interfaces/product';
import { ModelService } from '../../../../../../src/app/shared/services/REST/model.service';
import { ToastrService } from 'ngx-toastr';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { blockPermissionMsg, Part_Search_Request_Of_Recommendation } from '../../../../../../src/app/constant';
import { secureStorage } from '../../../../../../src/app/shared/services/securestorage';
import { Title } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'src/app/shared/services/GoogleAnalytics/GoogleAnalytics.service';

declare var $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  address_pk: any;
  account_pk: any;
  expiredDate: any;
  productList = [];
  result: boolean = false;
  productFilterData;
  showPagination: boolean = false;
  page: any = 0;
  pagestart: any;
  isLoggedIn: boolean;
  addingToCart = false;
  quantity: number;
  cartTypeList: any;
  selectcarttype: any;
  btnshowConfrimed: boolean = false;
  btndisabled: boolean = false;
  btnshowsubmit: boolean = false;
  modalRef1: any;
  baseProductdata: any;
  baseProductdiscount: any;
  showingQuickview = false;
  selectedProduct: any;
  showLoader: boolean = false;
  // featuredProducts1 = [];
  // featuredProducts2 = [];
  featuredProducts: any = [];
  shippingaddressid: any;
  billingaddressid: any;
  discount: any;
  isPrima: boolean = false;
  postloginRightBannerList = [];
  megaCostBannerList: any[] = [];
  isApiRecommededLoad: boolean = false
  categories = [
    {
      name: 'Tata Prima Lx 3130',
      model_cat: 'Prima',
      model_img_name:
        './assets/images/categories/Tipper -Tata Prima Lx 3130.K.png',
    },
    {
      name: 'Tata Signa 3718',
      model_cat: 'HCV-II : (30 TONS TO 39 TONS)',
      model_img_name: './assets/images/categories/HCV -Tata Signa 3718.T.png',
    },
    {
      name: 'Tata Ace Gold',
      model_cat: 'Ace',
      model_img_name: './assets/images/categories/Tata Ace Gold.png',
    },
    {
      name: 'Tata Ultra Light',
      model_cat: 'ULTRA TRUCK',
      model_img_name: './assets/images/categories/Tata Ultra Light Truck.png',
    },
  ];
  modelsCategory: any;
  activeMenuItem: string;
  modelName = [];
  modelAggregateData = [];
  BomPageStart: any = 0;
  BomPageEnd: any;
  modelData: any;
  BomIdData: any;
  FinalBomData: any;
  aggregateSelectedData = [];
  BomArrayNo: any;
  prodstart: any;
  prodend: any;
  selectedAggregate: string = '';
  isAllAggregate: boolean = true;
  PopularKitsData: any;
  vehicle = [];
  recentItems = [];
  isNoProductList: boolean = false;
  slickcall = true;
  showNewProducts: boolean = true;
  showeDukaanFeatures: boolean = true;
  showKits: boolean = true;
  showRecentItems: boolean = true;
  showFleetRecProducts:boolean = true;
  customOptions: OwlOptions = {
    items: 4,
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    autoWidth: false,
    navText: ["<div><img src='./assets/images/arrow-left.svg' /></div>", "<div><img src='./assets/images/arrow-right.svg' /></div>"],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      768: {
        items: 3
      },
      992: {
        items: 4
      }
    },
    nav: true
  }
  customOptionsForKits: OwlOptions = {
    items: 5,
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    autoWidth: false,
    navText: ["<div><img src='./assets/images/button-circle-prev-white.svg' /></div>", "<div><img src='./assets/images/button-circle-next-white.svg' /></div>"],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 5
      }
    },
    nav: true
  }
  customOptionsForRecentItems: OwlOptions = {
    items: 5,
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    autoWidth: false,
    navText: ["<div><img src='./assets/images/arrow-left.svg' /></div>", "<div><img src='./assets/images/arrow-right.svg' /></div>"],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 5
      }
    },
    nav: true
  }
  customOptionsForNewProducts: OwlOptions = {
    items: 4,
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    autoWidth: false,
    navText: ["<div><img src='./assets/images/arrow-left.svg' /></div>", "<div><img src='./assets/images/arrow-right.svg' /></div>"],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  }
  customOptionsForFleetRecProducts: OwlOptions = {
    items: 4,
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    autoWidth: false,
    navText: ["<div><img src='./assets/images/arrow-left.svg' /></div>", "<div><img src='./assets/images/arrow-right.svg' /></div>"],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  }
  customOptionsForModels: OwlOptions = {
    //items: 4,
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 1000,
    autoWidth: true,
    nav: true,
    navText: ["<div><img src='./assets/images/arrow-left.svg' /></div>", "<div><img src='./assets/images/arrow-right.svg' /></div>"],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },

  }
  customOptionsForAggregates: OwlOptions = {
    items: 8,
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 400,
    autoWidth: true,
    navText: ["<div><img src='./assets/images/arrow-left.svg' /></div>", "<div><img src='./assets/images/arrow-right.svg' /></div>"],
    responsive: {
      0: {
        items: 2
      },
      400: {
        items: 4
      },
      740: {
        items: 6
      },
      940: {
        items: 8
      }
    },
    nav: true
  }
  @ViewChild('confimationmodal', { read: TemplateRef, static: false })
  template1: TemplateRef<any>;
  cartTypeModal: any;
  @ViewChild('logoutModal', { read: TemplateRef, static: false })
  template: TemplateRef<any>;
  modalRef: any;
  isVehicleListLoaded: boolean = false;
  isAggregateListLoaded: boolean = false;
  isPartListLoaded: boolean = false;
  userData: any;
  fleetEdgePartList: any = [];
  productInfo:any=[]
  constructor(
    private appStateService: AppStateService,
    private router: Router,
    private cart: CartService,
    private rootService: RootService,
    private cookieService: CookieService,
    private userService: UserService,
    private modalService: BsModalService,
    private productService: ProductService,
    private cd: ChangeDetectorRef,
    private model: ModelService,
    private toasterService: ToastrService,
    private localstorage: secureStorage,
    private title:Title,
    private googleAnlyatics: GoogleAnalyticsService,
  ) {
  }

  ngOnInit() {
    this.userData = JSON.parse(this.localstorage.getItem('userData'));
    this.localstorage.removeitems('selectcarttype')
    this.appStateService.isLoggedIn.subscribe((isLog) => {
      this.isLoggedIn = isLog;
    });
    localStorage.removeItem('productcardBreadcrumb');
    localStorage.removeItem('mainheaderBreadcrumb');
    this.getMegaCostBanner();
    this.getPostLoginRightBanner();
    this.appStateService.recentItems.subscribe(() => {
      let recentItemsData = [];
      recentItemsData = JSON.parse(this.localstorage.getItem('Recent items')) == null || JSON.parse(this.localstorage.getItem('Recent items')) == undefined ? [] : JSON.parse(this.localstorage.getItem('Recent items'));
      if (recentItemsData.length > 0) {
        this.recentlyViewedItems(recentItemsData);
      }
      else {
        this.showRecentItems = false;
      }
    });
    let address = JSON.parse(this.localstorage.getItem('address'));
    let billingaddress = JSON.parse(this.localstorage.getItem('billingAddress'));
    if (address?.address['default']) {
      this.shippingaddressid = 'default';
    } else {
      this.shippingaddressid = address?.address['address_id'];
    }
    if (billingaddress?.address['default']) {
      this.billingaddressid = 'default';
    } else {
      this.billingaddressid = billingaddress?.address['address_id'];
    }

    // this.appStateService.refreshTimelineList.next(null);
    this.getProduct();
    this.PopularKits();
    this.userVehicle();
    this.getRecommnededPartlist()
    this.title.setTitle('E-Dukaan | My Dashboard');
  }



  async userVehicle() {
    try {
      this.vehicle = [];
      if (this.localstorage.getItem('userData')) {
        let userdatas = JSON.parse(this.localstorage.getItem('userData'));
        if (userdatas && userdatas.account_pk) {
          this.showLoader = true;
          await this.userService
            .userprofile({ account_pk: userdatas.account_pk })
            .subscribe(
              (response) => {
                if (response.success) {
                  this.vehicle = response.data;
                  this.getModelAggregate(response.data[0].model_cat, false);
                  //this.modelName=response.data[0].model_cat
                } else {
                  this.vehicle = this.categories;
                  this.getModelAggregate(this.categories[0].model_cat, false);
                  //this.modelName = categoryData
                  //this.toasterService.error(response.data.msg);
                }
                this.isVehicleListLoaded = true;
                this.showLoader = false;
                // setTimeout(() => {
                //   this.sliderFunction();
                // }, 300);
              },
              (error) => {
                this.showLoader = false;
                this.isVehicleListLoaded = true;
                // setTimeout(() => {
                //   this.sliderFunction();
                // }, 300);
              }
            );
        }
      }
    } catch (error) { }
  }

  UserLogout(type) {
    try {
      if (type === 'Logout') {
        this.modalRef.hide();
        this.appStateService.isLoggedIn.next(false);
        this.appStateService.userSubject.next(null);
        localStorage.removeItem('getCartType');
        this.localstorage.setItem('AddressId', '0');
        this.localstorage.setItem('Pincode', '');
        localStorage.removeItem('userData');
        localStorage.removeItem('user');
        localStorage.removeItem('address');
        localStorage.removeItem('billingAddress');
        localStorage.removeItem('type');
        localStorage.removeItem('wishlistItems');
        localStorage.removeItem('compareItems');

        localStorage.removeItem('isOtherUser');
        localStorage.removeItem('otheruser');
        localStorage.removeItem('OtherUserPermission');
        localStorage.removeItem('usertype');
        localStorage.removeItem('AccountData');
        localStorage.removeItem('PageList');
        localStorage.removeItem('pageHeader');
        localStorage.removeItem('OrderData');
        localStorage.removeItem('OrderType');
        localStorage.removeItem('Pincode');
        localStorage.removeItem('AddressId');
        localStorage.removeItem('ref_token');
        localStorage.removeItem('division_info');
        localStorage.removeItem('division_info1');
        localStorage.removeItem('SLVCart');
        localStorage.removeItem('MHVCart');
        localStorage.removeItem('userAccounts');
        localStorage.removeItem('edukaanTImelineData');
        localStorage.removeItem('loyaltyDetail')
        this.cookieService.removeAll();
        this.cart.cartConfiguration();
        
        if (!this.cookieService.getObject('access_token')) {
          this.showLoader = true;
          this.rootService.login().subscribe(
            (response) => {
              if (response['success'] === true) {
                this.expiredDate = new Date();
                let Minutes = response.data['expires_in'] * 1000; // convert 2 minutes to milliseconds
                var expirein = Minutes - Minutes / 10;
                let expiredate2 = new Date(
                  this.expiredDate.getTime() + expirein
                );
                this.cookieService.putObject(
                  'access_token',
                  response.data['access_token'],
                  { expires: expiredate2 }
                );
                this.cookieService.putObject(
                  'ref_token',
                  response.data['refresh_token']
                );
                this.localstorage.setItem(
                  'ref_token',
                  response.data['refresh_token']
                );
                this.localstorage.setItem('refreshTokenInProgress', 'false');
                this.router.navigate(['']);
                this.showLoader = false;
              } else {
                this.showLoader = false;
              }
            },
            (error) => {
              this.showLoader = false;
            }
          );
          this.router.navigate(['']);
        }
      } else {
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'UserLogout(item, type)',
        page_name: 'MenuComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  logout(item) {
    try {
      if (item === 'Logout') {
        if (this.modalRef) {
          this.modalRef.hide();
          this.modalRef = '';
        } else {
          this.modalRef = this.modalService.show(this.template, {
            class: 'modal-dialog-centered modal-md',
            backdrop: 'static',
          });
        }
      }
      // else if (item.label === 'Switch Account') {
      //     var userIddata = JSON.parse(this.localstorage.getItem('user'));
      //     if (userIddata) {
      //         this.userIds = userIddata.user_ids;
      //         let userData = JSON.parse(this.localstorage.getItem('userData'));
      //         this.user = userData.account_id;
      //         this.userAccounrDetails = userData.account_data;
      //         this.modalRef1 = this.modalService.show(this.template1, { class: 'modal-dialog-centered modal-lg', backdrop: 'static' });
      //     }

      // }
      // else if (item.label === 'Change Password') {
      //     var userIddata = JSON.parse(this.localstorage.getItem('user'));
      //     if (userIddata) {
      //         this.userIds = userIddata.user_ids;
      //         let userData = JSON.parse(this.localstorage.getItem('userData'));
      //         this.user = userData.account_id;
      //         this.userAccounrDetails = userData.account_data;
      //         this.cleardetails();
      //         this.modalRef2 = this.modalService.show(this.template2, { class: 'modal-dialog-centered modal-md', backdrop: 'static' });
      //     }
      // }
      // else {
      //     this.router.navigateByUrl(item.url);
      // }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'logout(item)',
        page_name: 'MenuComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  shopByPopularModels(models) {
    if (models == 'Tata Prima Lx 3130') {
      //this.modelsCategory = 'Prima';
    }
    if (models == 'Tata Ace Gold') {
      //this.modelsCategory = 'Ace';
    }
    if (models == 'Tata Ultra Light') {
      //this.modelsCategory = 'ULTRA TRUCK';
    }
    if (models == 'Tata Signa 3718') {
      //this.modelsCategory = 'HCV-II : (30 TONS TO 39 TONS)';
    }
    if (models == 'Tata Starbus Ultra') {
      //this.modelsCategory = 'BUS BODY CATALOG';
    }
    if (models == 'Tata-407-Light') {
      ///this.modelsCategory = 'xenon';
    }
    //this.loadData(this.modelsCategory, 'Model');
  }

  loadData(searchBy, categoryType) {
    try {
      this.modelsCategory = searchBy;
      document.body.scrollTop = 0;
      this.GetBomDetail(null, 0, false);
    } catch (error) { }
  }

  addToCart(product: Product, isRecentlyViewed?): void {
    try {
      let permission = this.userService.CheckPermission(
        'btn_cart'
      );
      if (permission) {
        if (this.addingToCart) {
          return;
        }

        if (isNaN(product['customerQuantity'])) {
          this.toasterService.error('Quantity cannot be empty or 0');
          return;
        }
        this.showLoader = true;
        this.addingToCart = true;
        if (product['resource_type'] == 'Pack') {
          this.quantity = parseInt(product['min_quantity'])
            ? parseInt(product['min_quantity']) * parseInt(product['moq'])
            : 1 * parseInt(product['moq']);
        } else {
          this.quantity = parseInt(product['min_quantity'])
            ? parseInt(product['min_quantity'])
            : 1;
        }
        if (this.quantity >= 1) {
          if (this.localstorage.getItem('getCartType')) {
            this.cartTypeList = JSON.parse(this.localstorage.getItem('getCartType'));
          }
          var commoncarttypedata = this.cartTypeList.filter((alitems) =>
            alitems.common_distributor_categories.includes(
              product['distributor_category']
            )
          );
          var Distributercarttypedata = this.cartTypeList.filter((alitems) =>
            alitems.distributor_categories.includes(
              product['distributor_category']
            )
          );
          let cart_type_name = '';
          if (commoncarttypedata.length > 0) {
            if (this.localstorage.getItem('selectcarttype')) {
              cart_type_name = this.localstorage.getItem('selectcarttype');
              this.showLoader = true;
              if (isRecentlyViewed !== true) {
                this.localstorage.setItem('recentlyaddedpage', isRecentlyViewed);
              }
              this.cart
                .add(
                  product,
                  this.isLoggedIn ? parseInt(product['customerQuantity']) ? parseInt(product['customerQuantity']) : 1: parseInt(product['customerQuantity']) ? product['customerQuantity'] * parseInt(product['moq']) : 1 * parseInt(product['moq']),
                  product['distributor_category'],
                  cart_type_name
                )
                .subscribe({
                  complete: () => {
                    localStorage.removeItem("selectcarttype");
                    this.addingToCart = false;
                    this.cd.markForCheck();
                    this.showLoader = false;
                  },
                });
              localStorage.removeItem('selectcarttype');
              this.showLoader = false;
            } else {
              cart_type_name = commoncarttypedata[1]['cart_type'];
              if (this.cartTypeList.length > 0) {
                this.selectcarttype = this.cartTypeList[1]['cart_type'];
                this.selectedProduct = product;
                this.changeCartType(this.selectcarttype, product);
                this.btndisabled = false;
                this.addingToCart = false;
                this.btnshowConfrimed = false;
                this.btnshowsubmit = true;
                this.productInfo.data = [product]
                this.productInfo.division_info = {
                  ORG_NAME:product['organization_name']?product['organization_name']:"",
                  DIV_NAME:product['division_name']?product['division_name']:"",
                  PRIMISE_ADDRLINE1_s:product['primise_addrline1']?product['primise_addrline1']:"",
                  PRIMISE_ADDRESS2_s:product['primise_addrline2']?product['primise_addrline2']:"",
                  DIV_ID:product['division_id']?product['division_id']:"",
                  GSTIN:product['gstn']?product['gstn']:"",
                  ORG_Id:product['organization_id']?product['organization_id']:"",
              };
                this.cartTypeModal = this.modalService.show(this.template1, {
                  class: 'modal-dialog-centered modal-md',
                  backdrop: 'static',
                });
              }
            }
          } else if (Distributercarttypedata.length > 0) {
            cart_type_name = Distributercarttypedata[0]['cart_type'];
            this.showLoader = true;

            if (isRecentlyViewed !== true) {
              this.localstorage.setItem('recentlyaddedpage', isRecentlyViewed);
            }
            this.cart
              .add(
                product,
                this.isLoggedIn ? parseInt(product['customerQuantity']) ? parseInt(product['customerQuantity']) : 1: parseInt(product['customerQuantity']) ? product['customerQuantity'] * parseInt(product['moq']) : 1 * parseInt(product['moq']),
                product['distributor_category'],
                cart_type_name
              )
              .subscribe({
                complete: () => {
                  this.addingToCart = false;
                  this.cd.markForCheck();
                  this.showLoader = false;
                },
              });
            this.showLoader = false;
          }
        } else {
          this.toasterService.error('Minimum Quantity must be 1');
        }
      }
      else {
        this.toasterService.error(blockPermissionMsg);
      }
    } catch (error) {
      this.addingToCart = false;
      this.showLoader = false;
      this.userService.InsertExceptionLog({
        method_name: 'addToCart()',
        page_name: 'ProductCardComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  changeCartType(cartType, product: Product) {
    try {
      this.selectcarttype = cartType['cart_type'];
      let user = JSON.parse(this.localstorage.getItem('userData'));
      let address = JSON.parse(this.localstorage.getItem('address'));
      let billingaddress = JSON.parse(this.localstorage.getItem('billingAddress'));
      let carttypes = cartType;
      let searchData = {};
      if (user && address && billingaddress) {
        this.account_pk = +JSON.stringify(user.account_pk);
        var addresstypecheck = address.address['default'];
        if (addresstypecheck === true) {
          this.shippingaddressid = 'default';
        } else {
          this.shippingaddressid = address.address['address_id'];
        }
        var Billingaddresscheck = billingaddress.address['default'];
        if (Billingaddresscheck === true) {
          this.billingaddressid = 'default';
        } else {
          this.billingaddressid = billingaddress.address['address_id'];
        }
        searchData = {
          search_text: '',
          bs_model_name: '',
          pg_line: '',
          multi_pg_line: [],
          multi_bs_model_name: [],
          multi_dispgroup_name: [],
          model_cat: '',
          mod_uid: '',
          category_type: '',
          bom_id: '',
          durafit_parts: false,
          kit_parts: false,
          part_number_last_four: '',
          offset: 0,
          limit: 20,
          pl_id: '',
          // is_discount_needed: true,
          account_pk: this.account_pk,
          // address_pk: this.address_pk,
          shipping_address_id: this.shippingaddressid,
          part_number: this.selectedProduct.part_number,
          is_for_epc_data: false,
          is_for_discount: true,
          is_for_ecom_detail: true,
          cart_type: cartType['cart_type']?cartType['cart_type']:cartType,
          is_for_inventory : this.userData?.user_type == 'MECH' ? true: false,
        };
      } else {
        searchData = {
          search_text: '',
          bs_model_name: '',
          pg_line: '',
          multi_pg_line: [],
          multi_bs_model_name: [],
          multi_dispgroup_name: [],
          model_cat: '',
          mod_uid: '',
          category_type: '',
          bom_id: '',
          durafit_parts: false,
          kit_parts: false,
          part_number_last_four: '',
          offset: 0,
          // start_row:0,
          limit: 20,
          pl_id: '',
          part_number: this.selectedProduct.part_number,
          is_for_epc_data: false,
          is_for_discount: false,
          is_for_ecom_detail: true,
          cart_type: cartType['cart_type']?cartType['cart_type']:cartType,
          is_for_inventory : this.userData?.user_type == 'MECH' ? true: false,
        };
      }
      this.partnumberbaseddiscount(searchData, this.selectedProduct);
      // let data = {};
      // let user = JSON.parse(this.localstorage.getItem('userData'));
      // let address = JSON.parse(this.localstorage.getItem('address'));
      // let carttypes;
      // if (user) {
      //   carttypes = cartType;
      //   if (address['type'] === 'default' && address['type'] !== null) {
      //     data = {
      //       cart_type: carttypes,
      //       account_pk: JSON.stringify(user.account_pk),
      //       address_pk: address.type,
      //       part_number: (product.part_number) ? (product.part_number) : this.selectedProduct.part_number,
      //       quantity: 1,
      //     }
      //   }
      //   else {
      //     var address_pkselect
      //     if (address.address.length === undefined || address.address.length === 0) {
      //       address_pkselect = address.address.id;
      //     }
      //     else {
      //       address_pkselect = address.address[0].id;
      //     }
      //     data = {
      //       cart_type: carttypes,
      //       account_pk: JSON.stringify(user.account_pk),
      //       address_pk: address_pkselect,
      //       part_number: (product.part_number) ? (product.part_number) : this.selectedProduct.part_number,
      //       quantity: 1,
      //     }
      //   }
      //   this.partnumberbaseddiscount(data, (product) ? product : this.selectedProduct);
      // }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'changeCartType()',
        page_name: 'ProductCardComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  partnumberbaseddiscount(json, product: Product) {
    try {
      this.showLoader = true;
      // this.productService.getpartnumberbaseddiscount(json).subscribe(response => {
      this.productService.get_part_search_md(json).subscribe(
        (response) => {
          if (response.success === true) {
            this.baseProductdata = response;
            var productPirce = parseFloat(product['crm_mrp'].toString()).toFixed(
              3
            );
            var basediscountPirce = parseFloat(response.data[0].total_amount).toFixed(3);
            if (productPirce === basediscountPirce) {
              this.baseProductdiscount = null;
            } else {
              this.baseProductdiscount = response.data[0].total_amount;
            }
          }
          else {
            if (response?.data?.msg) {
              let usertypeMessage = "The account Id '" + this.userData.account_id + "' you are trying to login, is not updated.";
              let errorMessage = (response.data.msg === "Account not found") ? usertypeMessage : response.data.msg;
              if (response.data.msg != "Data not found") {
                this.toasterService.error(errorMessage);
              }
            }
          }
          this.showLoader = false;
        },
        (err) => {
          this.showLoader = false;
        }
      );
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'partnumberbaseddiscount()',
        page_name: 'ProductCardComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  // NEW PRODUCTS LIST (BINDING PENDING)
  getProduct() {
    let searchData = {};
    let user = JSON.parse(this.localstorage.getItem('userData'));
    let address = JSON.parse(this.localstorage.getItem('address'));
    if (user && address) {
      this.account_pk = +JSON.stringify(user.account_pk);
      searchData = {
        search_text: '',
        bs_model_name: '',
        pg_line: '',
        multi_pg_line: [],
        multi_bs_model_name: [],
        multi_dispgroup_name: [],
        model_cat: '',
        mod_uid: '',
        category_type: '',
        bom_id: '',
        durafit_parts: false,
        kit_parts: false,
        part_number_last_four: '',
        offset: 0,
        size: 12,
        pl_id: '',
        is_home: false,
        is_for_new_parts: true,
        // is_discount_needed: true,
        account_pk: this.account_pk,
        // address_pk: this.address_pk,
        shipping_address_id: this.shippingaddressid,
        is_for_epc_data: false,
        is_for_discount: true,
        is_for_ecom_detail: false,
        is_for_inventory:this.userData?.user_type == 'MECH' ? true: false,
      };
    } else {
      searchData = {
        search_text: '',
        bs_model_name: '',
        pg_line: '',
        multi_pg_line: [],
        multi_bs_model_name: [],
        multi_dispgroup_name: [],
        model_cat: '',
        mod_uid: '',
        category_type: '',
        bom_id: '',
        durafit_parts: false,
        kit_parts: false,
        part_number_last_four: '',
        offset: 0,
        size: 12,
        pl_id: '',
        is_home: false,
        is_for_new_parts: true,
        is_for_epc_data: false,
        is_for_discount: false,
        is_for_ecom_detail: false,
        is_for_inventory:this.userData?.user_type == 'MECH' ? true: false,
      };
    }
    this.productService.get_part_search_md(searchData).subscribe(
      (response) => {
        if (response.success === true) {
          this.featuredProducts = response.data;
          this.featuredProducts.forEach((element) => {
            element.catType = element.distributor_category;
            element.customerQuantity = element.min_quantity;
            this.featuredProducts.push(element);
          });
          // this.featuredProducts['result'] = this.featuredProducts2;

        }
        else {
          if (response?.data?.msg) {
            let usertypeMessage = "The account Id '" + this.userData.account_id + "' you are trying to login, is not updated.";
            let errorMessage = (response.data.msg === "Account not found") ? usertypeMessage : response.data.msg;
            if (response.data.msg != "Data not found") {
              this.toasterService.error(errorMessage);
            }
          }
        }
        this.showNewProducts = true;
      },
      (err) => {
        this.showNewProducts = false;
      }
    );
  }
  readQuantity(event, product) {
    product.customerQuantity = event;
  }
  async getModelAggregate(item, isFromHtml) {
    //$('#aggregatelist').owlCarousel('destroy');
    if (isFromHtml) {
      this.isAggregateListLoaded = false;
      this.productList = [];
      this.isPartListLoaded = false;
      this.isNoProductList = true;
    }
    this.activeMenuItem = '';
    this.modelName = [];
    this.modelName = item;
    this.modelAggregateData = [];
    //this.modelName.push(item);
    const ListInput: Inputdata = {} as Inputdata;
    ListInput.offset = 0;
    ListInput.limit = 30;
    ListInput.dropdown_type = 'aggregate';
    ListInput.multi_model_cat = [item];
    //ListInput.is_image_required = true;
    //this.showLoader = true;
    let response = await this.model.getModelCategory(ListInput);
    if (response['success'] == true) {
      this.modelAggregateData = response['data']['Aggregates'];
      this.modelAggregateData = this.modelAggregateData.sort((a, b) => {
        if (a.aggregate < b.aggregate) return -1;
        else if (a.aggregate > b.aggregate) return 1;
        else return 0;
      });
      this.modelAggregateData.splice(0, 0, { aggregate: 'All' });
      this.selectedAggregate = this.modelAggregateData[0].aggregate;
      this.isAggregateListLoaded = true;
    } else {
      this.isAggregateListLoaded = true;
    }
    this.loadData(this.modelName, 'Model');
    // setTimeout(() => {
    //   this.aggregateSlider();
    // }, 100);
    //this.showLoader = false;
  }

  async GetBomDetail(aggregate: any, offset, isFromHtml) {
    //if (aggregate !== "All") {
    if (isFromHtml) {
      this.productList = [];
      this.isPartListLoaded = false;
      this.isNoProductList = true;
    }
    this.selectedAggregate = aggregate == null ? 'All' : aggregate;
    this.isAllAggregate = false;
    this.BomIdData = [];
    this.FinalBomData = [];
    const ListInput: Inputdata = {} as Inputdata;
    ListInput.offset = offset;
    ListInput.limit = 40;
    ListInput.multi_model_cat = this.modelName ? [this.modelName] : [];
    //ListInput.multi_base_model = this.subModelSelectedData?this.subModelSelectedData:[]
    // ListInput.multi_aggregate = this.searchBy?[this.searchBy]:[]
    //ListInput.multi_model_cat = [];
    ListInput.multi_aggregate =
      aggregate !== 'All' && aggregate !== null ? [aggregate] : [];
    let response = await this.model.getBom(ListInput);
    if (response['success'] == true) {
      this.BomPageEnd = offset;
      this.BomIdData = response['data'];
      const n = 10; //tweak this to add more items per line
      this.FinalBomData = new Array(Math.ceil(this.BomIdData.length / n))
        .fill('')
        .map((_) => this.BomIdData.splice(0, n));
      this.BomArrayNo = 0;
      this.BindProduct(this.FinalBomData[this.BomArrayNo], 0);
    } else {
      this.productList = [];
      this.isNoProductList = true;
      this.isPartListLoaded = true;
      if (response['data'].msg != "Data not found") {
        this.toasterService.error(response['data'].msg);
      }
    }
    //}
    // else {
    //   this.selectedAggregate = aggregate;
    //   this.loadData(this.modelName[0], 'Model');
    // }
    // setTimeout(() => {
    //   this.sliderFunction();
    // }, 100);
  }

  async BindProduct(BomIdData: any, offset) {
    if (BomIdData) {
      this.productList = [];
      let user = JSON.parse(this.localstorage.getItem('userData'));
      let address = JSON.parse(this.localstorage.getItem('address'));
      if (user && address) {
        this.account_pk = +JSON.stringify(user.account_pk);
        let billingaddress = JSON.parse(this.localstorage.getItem('billingAddress'));
        var addresstypecheck = address?.address['default'];
        if (addresstypecheck === true) {
          this.shippingaddressid = 'default';
        } else {
          this.shippingaddressid = address?.address['address_id'];
        }
        var Billingaddresscheck = billingaddress?.address['default'];
        if (Billingaddresscheck === true) {
          this.billingaddressid = 'default';
        } else {
          this.billingaddressid = billingaddress?.address['address_id'];
        }
      }
      const ListPartInput: InputPartSearch = {} as InputPartSearch;
      ListPartInput.offset = offset;
      ListPartInput.limit = 20;
      ListPartInput.search_text = '';
      ListPartInput.bs_model_name = '';
      ListPartInput.pg_line = '';
      //ListPartInput.multi_pg_line = this.pgline ? this.pgline : [];
      ListPartInput.multi_pg_line = [];
      ListPartInput.multi_bs_model_name = [];
      ListPartInput.multi_dispgroup_name = [];
      ListPartInput.model_cat = '';
      ListPartInput.mod_uid = '';
      ListPartInput.category_type = '';
      ListPartInput.bom_id = '';
      ListPartInput.durafit_parts = false;
      ListPartInput.kit_parts = false;
      ListPartInput.part_number_last_four = '';
      ListPartInput.pl_id = '';
      ListPartInput.is_home = false;
      ListPartInput.part_number = '';
      ListPartInput.is_for_epc_data = false;
      ListPartInput.is_for_discount = this.isLoggedIn == true ? true : false;
      ListPartInput.is_for_ecom_detail = false;
      ListPartInput.bom_id_list = BomIdData ? BomIdData : []; //result[i];
      ListPartInput.account_pk = this.isLoggedIn == true ? this.account_pk : '';
      ListPartInput.shipping_address_id =
        this.isLoggedIn == true ? this.shippingaddressid : '';
        ListPartInput.is_for_inventory  = this.userData?.user_type == 'MECH' ? true: false
      //this.showLoader = true;
      let data = await this.model.getPartSearchMd(ListPartInput);
      if (data['success'] === true) {
        for (let i = 0; i < data['data'].length; i++) {
          if (i < 8) {
            this.productList[i] = data['data'][i];
            console.log("kskskskk",this.productList)
            this.productList[i].customerQuantity = data['data'][i]['min_quantity'];
          }
        }
        this.prodstart = data['start'];
        this.prodend = data['end'];
        this.showLoader = false;
        this.isNoProductList = false;
        this.isPartListLoaded = true;
      } else if (
        data['success'] === false &&
        data['data']['msg'] === 'Data not found'
      ) {
        this.showLoader = true;
        if (this.FinalBomData.length >= this.BomArrayNo) {
          this.BomArrayNo = parseInt(this.BomArrayNo) + 1;
          if (this.BomArrayNo < this.FinalBomData.length) {
            this.prodend = 0;
            this.BindProduct(this.FinalBomData[this.BomArrayNo], this.prodend);
          } else {
            this.BomPageEnd = this.BomPageEnd + 100;
            this.GetBomDetail(this.selectedAggregate, this.BomPageEnd, false);
          }
          //this.showLoader = false;
        } else {
          this.BomPageEnd = this.BomPageEnd + 100;
          this.GetBomDetail(this.selectedAggregate, this.BomPageEnd, false);
          //this.showLoader = false;
        }
      } else {
        this.showLoader = false;
        this.isNoProductList = true;
        this.isPartListLoaded = true;
        this.productList = [];
      }
    }
  }



  KitSpecificParts(pg_line) {
    this.router.navigate(['/shop/productcards'], {
      queryParams: { searchBy: 'popularKits', kitName: pg_line },
    });
  }
  gotoAggregate() {
    if (this.selectedAggregate == 'All') {
      this.router.navigate(['/shop/productcards'], {
        queryParams: {
          ModelName: this.modelName,
          aggtype: 'aggregate'
        },
      });
    }
    else {
      this.router.navigate(['/shop/productcards'], {
        queryParams: {
          searchBy: this.selectedAggregate,
          ModelName: this.modelName,
          aggtype: 'aggregate'
        },
      });
    }
  }
  recentlyViewedItems(partNumberList) {


    let user = JSON.parse(this.localstorage.getItem('userData'));
    let address = JSON.parse(this.localstorage.getItem('address'));
    let billingaddress = JSON.parse(this.localstorage.getItem('billingAddress'));
    this.shippingaddressid =
      address?.address['default'] === true
        ? 'default'
        : address?.address['address_id'];
    this.billingaddressid =
      billingaddress?.address['default'] === true
        ? 'default'
        : billingaddress?.address['address_id'];
    const ListPartInput: InputPartSearch = {} as InputPartSearch;
    ListPartInput.offset = 0;
    ListPartInput.limit = 20;
    ListPartInput.search_text = '';
    ListPartInput.bs_model_name = '';
    ListPartInput.pg_line = '';
    ListPartInput.multi_pg_line = [];
    ListPartInput.multi_bs_model_name = [];
    ListPartInput.multi_dispgroup_name = [];
    ListPartInput.model_cat = '';
    ListPartInput.mod_uid = '';
    ListPartInput.category_type = '';
    ListPartInput.bom_id = '';
    ListPartInput.durafit_parts = false;
    ListPartInput.kit_parts = false;
    ListPartInput.part_number_last_four = '';
    ListPartInput.pl_id = '';
    ListPartInput.part_number = '';
    ListPartInput.is_for_epc_data = false;
    ListPartInput.is_for_discount = this.isLoggedIn;
    ListPartInput.is_for_ecom_detail = false;
    ListPartInput.account_pk = this.isLoggedIn == true ? user.account_pk : '';
    ListPartInput.shipping_address_id =
      this.isLoggedIn == true ? this.shippingaddressid : '';
    (ListPartInput.is_for_top_sold_parts = false),
      (ListPartInput.part_number_list = partNumberList);
       ListPartInput.is_for_inventory = this.userData?.user_type == 'MECH' ? true: false;

    this.productService.get_part_search_md(ListPartInput).subscribe(
      (response) => {
        if (response.success) {
          this.showRecentItems = true;
          this.recentItems = [];
          let reference_array = [];
          let responseArray = [];
          reference_array = partNumberList;
          responseArray = response.data;
          responseArray.sort((a, b) => {
            let position =
              reference_array.indexOf(a.part_number) -
              reference_array.indexOf(b.part_number);
            return position;
          });
          this.recentItems = responseArray;
          for (let i = 0; i < this.recentItems.length; i++) {
            this.recentItems[i].customerQuantity =
              this.recentItems[i].min_quantity;
          }
        } else {
          this.showRecentItems = false;
          if (response?.data?.msg) {
            let usertypeMessage = "The account Id '" + this.userData.account_id + "' you are trying to login, is not updated.";
            let errorMessage = (response.data.msg === "Account not found") ? usertypeMessage : response.data.msg;
            if (response.data.msg != "Data not found") {
              this.toasterService.error(errorMessage);
            }
          }
        }
      },
      (error) => {
        if (error?.error?.data?.msg) {
          this.toasterService.error(error.error.data.msg);
        }
        this.showRecentItems = false;
      }
    );
  }

  routeToProduct(product: Partial<Product>): string {
    const basePath = '/shop/product-details';

    if (product['part_number']) {
      this.router.navigate(['/shop/product-details'], {
        queryParams: { part_number: product.part_number },
      });
    }
    if (product['part_number']) {
      this.router.navigate(['/shop/product-details'], {
        queryParams: { part_number: product.part_number },
      });
      //this.router.navigateByUrl(`${basePath}/${product.part_number}`);
    }
    // if ('id' in product) {
    //     return `${basePath}/${product.id}`;
    // }

    throw Error('Provide product with "slug" or "id".');
  }
  getMegaCostBanner() {
    let newRequestBody = {
      banner_type: 'MegaCostBanner',
      account_pk: this.isLoggedIn == true ? this.userData.account_pk : null
    };
    this.userService.getBannerImages(newRequestBody).subscribe(
      (response) => {
        if (response.success) {
          this.showeDukaanFeatures = true;
          this.megaCostBannerList = response.data;
        } else {
          if (response?.data?.msg) {
            let usertypeMessage = "The account Id '" + this.userData.account_id + "' you are trying to login, is not updated.";
            let errorMessage = (response.data.msg === "Account not found") ? usertypeMessage : response.data.msg;
            if (response.data.msg != "Data not found") {
              this.toasterService.error(errorMessage);
            }
          }
          this.showeDukaanFeatures = false;
        }
      },
      (error) => {
        if (error?.error?.data?.msg) {
          this.toasterService.error(error.error.data.msg);
        }
        this.showeDukaanFeatures = false;
      }
    );
  }

  getPostLoginRightBanner() {
    let newRequestBody = {
      banner_type: 'PostLoginRightBanner',
      "limit": 2,
      "offset": 0,
      account_pk: this.isLoggedIn == true ? this.userData.account_pk : null
    };
    this.userService.getBannerImages(newRequestBody).subscribe(
      (response) => {
        if (response.success) {
          this.postloginRightBannerList = response.data;
        } else {
          if (response?.data?.msg) {
            let usertypeMessage = "The account Id '" + this.userData.account_id + "' you are trying to login, is not updated.";
            let errorMessage = (response.data.msg === "Account not found") ? usertypeMessage : response.data.msg;
            if (response.data.msg != "Data not found") {
              this.toasterService.error(errorMessage);
            }
          }
        }
      },
      (error) => {
        if (error?.error?.data?.msg) {
          this.toasterService.error(error?.error?.data?.msg);
        }
      }
    );
  }
  async PopularKits() {
    this.PopularKitsData = [];
    const ListInput: Inputdata = {} as Inputdata;
    ListInput.limit = 20;
    ListInput.is_kit_for_home = true;
    let response = await this.model.getdataforhome(ListInput);
    if (response['success'] == true) {
      this.showKits = true;
      this.PopularKitsData = response['data']['pg_line'];
      if (this.PopularKitsData?.length > 0) {
        this.PopularKitsData = this.PopularKitsData.sort((a, b) => {
          if (a.line_2 < b.line_2) return -1;
          else if (a.line_2 > b.line_2) return 1;
          else return 0;
        });
      }
      else {
        this.showKits = false;
        this.PopularKitsData = [];
      }
    } else {
      if (response['data']?.msg) {
        let usertypeMessage = "The account Id '" + this.userData.account_id + "' you are trying to login, is not updated.";
        let errorMessage = (response['data'].msg === "Account not found") ? usertypeMessage : response['data'].msg;
        if (response['data'].msg != "Data not found") {
          this.toasterService.error(errorMessage);
        }
      }
      this.showKits = false;
    }
  }

  setAddCart() {
    try {
      this.cartTypeModal.hide();
      this.btndisabled = true;
      var cart_type_name = this.localstorage.getItem("selectcarttype");;
      this.cart
        .add(
          this.selectedProduct,
          this.isLoggedIn ? parseInt(this.selectedProduct['customerQuantity']) ? parseInt(this.selectedProduct['customerQuantity']) : 1: parseInt(this.selectedProduct['customerQuantity']) ? this.selectedProduct['customerQuantity'] * parseInt(this.selectedProduct['moq']) : 1 * parseInt(this.selectedProduct['moq']),
          this.selectedProduct['distributor_category'],
          cart_type_name
          // ,false
        )
        .subscribe({
          complete: () => {
            localStorage.removeItem("selectcarttype");
            this.addingToCart = false;
            this.cd.markForCheck();
          },
        });
      this.selectcarttype = '';
      this.modalRef1.hide();
      this.btndisabled = false;
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'setAddCart()',
        page_name: 'ProductCardComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  getRecommnededPartlist() {
    try {
      let user = JSON.parse(this.localstorage.getItem('userData'));
      let address = JSON.parse(this.localstorage.getItem('address'));
      let shippingaddressid = "";
      if (address) {
          shippingaddressid = address.address['default'] === true?"default":address.address['address_id']
          ?address.address['address_id']:"";
      }
      let req = {
        "part_number": "NOPART12345",
        "account_id": user.account_id,
        "action_type": "recommendation_parts",
        // "is_for_inventory" : this.userData?.user_type == 'MECH' ? true: false,
      }
      this.productService.getRecommnededParts(req).subscribe(
        res => {
          if (res['Fleet_Recommendation'] && res['Fleet_Recommendation'].length > 0) {
            let FleetPartNum: any[] = [];
            res['Fleet_Recommendation'].forEach(element => {
              FleetPartNum.push(element);
            });
            let req = Part_Search_Request_Of_Recommendation(user.account_pk, FleetPartNum, shippingaddressid,this.userData);
            this.productService.get_part_search_md(req).subscribe(
              res => {
                if (res.success) {
                  this.fleetEdgePartList = res.data;
                  for (let i = 0; i < res.data.length; i++) {
                    this.fleetEdgePartList[i].customerQuantity = res.data[i].min_quantity;
                    this.fleetEdgePartList[i].unit_quantity = res.data[i].moq;
                  }
                  this.showFleetRecProducts = true;
                }else{
                  this.showFleetRecProducts = false;
                }
                
              }
            )
          }
        }, error => {
          this.showFleetRecProducts = false;
        }
      )
    }
    catch (error) {
      this.showFleetRecProducts = false;
      this.userService.InsertExceptionLog({ "method_name": "getRecommnededPartlist()", "page_name": "HomeComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
    }
  }
  alternateProductsSlider(id?) {
    $("#" + id).slick({
      dots: false,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 1920,
          settings: {
            arrows: false,
            centerMode: false,
            centerPadding: '30px',
            slidesToShow: 4
          }
        },
        {
          breakpoint: 1550,
          settings: {
            arrows: false,
            centerMode: false,
            centerPadding: '30px',
            slidesToShow: 3
          }
        },
        {
          breakpoint: 1024,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '30px',
            slidesToShow: 2
          }
        },
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '30px',
            slidesToShow: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '30px',
            slidesToShow: 1
          }
        }
      ]
    });
  }

  onBannerRedirect(url:any,type:any){
    if(url?.banner_hyper_link){
      if(this.isLoggedIn){
        var MobileNo = null;
        if (
          this.localstorage.getItem('userData') != null ||
          this.localstorage.getItem('userData') != undefined
        ) {
          let userData = JSON.parse(
            this.localstorage.getItem('userData')
          );
          MobileNo = userData.contact_no;
        }

        var Address = '';
        if (
          this.localstorage.getItem('address') != null ||
          this.localstorage.getItem('address') != undefined
        ) {
          let address = JSON.parse(this.localstorage.getItem('address'));
          Address =
            address.address.street_address1 +
            ', ' +
            address.address.state +
            ', ' +
            address.address.district +
            ', ' +
            address.address.city +
            ', ' +
            address.address.postal_code;
        }
        if(type == 'PostLoginRightBanner'){
          try {
            this.googleAnlyatics.bannerClick(
              'PostLoginRightBanner',
              Address,
              MobileNo
            );
          } catch (error) {}
        }
        if(type == 'MegaCostBanner'){
          try {
            this.googleAnlyatics.bannerClick(
              'MegaCostBanner',
              Address,
              MobileNo
            );
          } catch (error) {}
        }
      }
      window.open(url?.banner_hyper_link);
    }
    if(url?.part_numbers.length != 0){
      if(this.isLoggedIn){
        var MobileNo = null;
        if (
          this.localstorage.getItem('userData') != null ||
          this.localstorage.getItem('userData') != undefined
        ) {
          let userData = JSON.parse(
            this.localstorage.getItem('userData')
          );
          MobileNo = userData.contact_no;
        }

        var Address = '';
        if (
          this.localstorage.getItem('address') != null ||
          this.localstorage.getItem('address') != undefined
        ) {
          let address = JSON.parse(this.localstorage.getItem('address'));
          Address =
            address.address.street_address1 +
            ', ' +
            address.address.state +
            ', ' +
            address.address.district +
            ', ' +
            address.address.city +
            ', ' +
            address.address.postal_code;
        }
        if(type == 'PreloginHomeBanner'){
          try {
            this.googleAnlyatics.bannerClick(
              'PreloginHomeBanner',
              Address,
              MobileNo
            );
          } catch (error) {}
        }
        if(type == 'PreloginRightBanner'){
          try {
            this.googleAnlyatics.bannerClick(
              'PreloginRightBanner',
              Address,
              MobileNo
            );
          } catch (error) {}
        }
      }
      // if(url?.banner_title){
      //   this.localstorage.setItem("bannerTitle","Title");
      // }
      this.router.navigate(['/shop/productcards'], {
        queryParams: {
          bannerId: url?.id,
        },
      });
    }
  }

  convertToFloat(data:any){
    return parseFloat(data)
}
}
