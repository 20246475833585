import { Component, EventEmitter, Input, OnChanges, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { AddressService } from "../../services/REST/address.service";
import { secureStorage } from "../../services/securestorage";
import { UserService } from '../../services/REST/user.service';
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { ToastrService } from "ngx-toastr";
import { FitmentService } from "../../services/REST/fitment.service";
import Swal from "sweetalert2";
@Component({
    selector: 'nearby-location',
    templateUrl: './nearby-map-locator.component.html',
    styleUrls: ['./nearby-map-locator.component.scss'],
  })
  export class NearbyMapLocatorComponent implements  OnInit, OnChanges{
    @Output() closemodal = new EventEmitter<any>();
    @ViewChild('fitment', { read: TemplateRef, static: false })
    fitment: TemplateRef<any>;

    @Input() breadcrumbs;
    mechanicDetails:any;
    mechanicDataList:any[]= [];
    @Input() isfitment;
    @Input() orderDetails:any;
    @Input() otcNo:any
    @Input() PincodeForRequest:any;

    @ViewChild('mechanicDetail', { read: TemplateRef, static: false })
    mechanicDetail: TemplateRef<any>;
    mechanicDetailsModal: any;

    zoom = 10;
    mylocations:any[] = [];
    mapCenter:any;

    showLoader:boolean = true;
    previous;
    userDetails:any

    fitmentModal: any;
    mechanicData:any;
    orderInfo:any;
    otc_Number:any;
    openType:any;
    pinCode:any;

    // distanceBtn:any = '0KM - 10KM';
    // radioOptions = [
    //   { label: '0KM - 10KM', value: '0KM - 10KM' },
    //   { label: '10KM - 20KM', value: '10KM - 20KM'},
    //   { label: '20KM - 30KM', value: '20KM - 30KM'},
    //   { label: '30KM - 40KM', value: '0KM - 40KM'},
    //   { label: '40KM - 50KM', value: '40KM - 50KM'}
    // ];

    constructor( 
      private localstorage: secureStorage,
      private addressService: AddressService,
      private modalService: BsModalService,
      private userService: UserService,
      private router: Router,
      private toasterService:ToastrService,
      private fitmentService: FitmentService,
      private readonly location: Location
      ) {}

    ngOnInit(){
      this.userDetails = JSON.parse(this.localstorage.getItem('userData'));
      this.breadcrumbs = JSON.parse(this.localstorage.getItem(
        'productcardBreadcrumb'
      ));
      // if ((this.breadcrumbs != null && this.breadcrumbs.length < 1) ||
      //     (this.breadcrumbs == null || this.breadcrumbs == undefined)) {
      //     this.breadcrumbs = [];
      // }
      // this.loadMapWithMarker();
      // console.log(this.breadcrumbs);
      this.orderInfo = this.orderDetails;
      this.otc_Number = this.otcNo
    
      if(this.PincodeForRequest){
        this.pinCode = this.PincodeForRequest
        this.getMechanicList(this.PincodeForRequest);
      }
      else{
        this.pinCode = JSON.parse(this.localstorage.getItem("address"))?.address.postal_code
        this.getMechanicList( this.pinCode);
      }
     
    }

    loadMapWithMarker(){
      // let lat = 19.059984;
      // let long = 72.889999;
    }

    getMechanicList(data){
      this.mechanicDataList = []
      try{
        let req = {"account_pk":this.userDetails.account_pk,"address":data}
        this.fitmentService.getGuruList(req).subscribe(
          res => {
            this.showLoader = false;
            if(res){
              this.showLoader = false;
              this.mechanicDataList = res.result;
              var i = 1;
              for (let entry of this.mechanicDataList) {
                if(i<=100){
                  // var KM = this.getDistanceFromLatLonInKm(Number(data.lat), Number(data.lng), Number(entry.latitude), Number(entry.longitude))
                  const ListInput: Map = {} as Map;
                  ListInput.lat = Number(entry.latitude)
                  ListInput.lng = Number(entry.longitude)
                  ListInput.lABLE = i;
                  ListInput.Range = entry.distance
                  // ListInput.Quantity = entry.part_count
                  ListInput.Name = entry.mechanic_name
                  ListInput.dealer_id=entry.id
                  this.mylocations.push(ListInput)
                  i = Number(i) + Number(1);
                  this.mapCenter = {
                    lat: Number(entry.latitude),
                    lng: Number(entry.longitude)
                  }
                }
              }
            }else{
              this.showLoader = false;
              this.toasterService.error("Data Not Found");
            }
            this.showLoader = false;
          }
        )
      }
      catch (error) {
        this.showLoader = false;
          this.userService.InsertExceptionLog({ "method_name": "getMechanicList()", "page_name": "NearbyMapLocatorComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
      }
    }

    getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
      var R = 6371; // Radius of the earth in km
      var dLat = this.deg2rad(lat2 - lat1);  // deg2rad below
      var dLon = this.deg2rad(lon2 - lon1);
      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2)
        ;
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c; // Distance in km
      return d;
    }
  
    deg2rad(deg) {
      return deg * (Math.PI / 180)
    }

    getMechanicDetails(details){
      if(details){
        this.mechanicDetails = details;
        this.mechanicDetailsModal = this.modalService.show(
          this.mechanicDetail,
          { class: 'modal-dialog-centered modal-lg' }
        );
      }
      
    }

    getMechanicDetailsFromMpView(data){
      let mechanicDetail;
      this.mechanicDataList.forEach(element => {
        if(element.id == data.dealer_id){
          mechanicDetail = element;
        }
      });
      this.getMechanicDetails(mechanicDetail);
    }

    clickedMarker(infowindow) {
      if (this.previous) {
        this.previous.close();
      }
      this.previous = infowindow;
    }

    ngOnChanges() {
      // this.breadcrumbs = this.breadcrumbs;
      // let localbreadcrumb = JSON.parse(
      //     this.localstorage.getItem('productcardBreadcrumb')
      // );
      // if ((this.breadcrumbs != null && this.breadcrumbs.length < 1) ||
      //     (this.breadcrumbs == null || this.breadcrumbs == undefined)) {
      //       this.breadcrumbs.push({ url: 'account/nearbyme' });
      // }
    }

    goToBack(){
      if(this.isfitment){
        this.closemodal.emit();
      }
      else{
        this.location.back();
      }
    }

    closeModal() {
      this.closemodal.emit();
    }

    openFitment(data:any,type:any){
      if(data){
        this.mechanicData = data;
        this.openType = type
        this.fitmentModal = this.modalService.show(this.fitment, {
          class: 'modal-dialog-centered modal-lg',
          backdrop: 'static',
        });
      }
      else{
        this.mechanicData = data;
        this.openType = type
        Swal.fire({
          // title: "Are you sure?",
          text: "You have not selected any garage, please select one from the below list.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText : "No",
          backdrop: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.fitmentModal = this.modalService.show(this.fitment, {
              class: 'modal-dialog-centered modal-lg',
              backdrop: 'static',
            });
          }
        });
      }
    }

    closePopup(){
      this.fitmentModal.hide()
    }

    onRangeChange(event:any) {
      const selectedOption = event;
    }
  }


  export class Map {
    lat: any
    lng: any
    lABLE: any
    Name: string
    Quantity: any
    Range: any
    dealer_id :any;
  }
  
  
  
  export class List {
    dealer_name: any
    dealer_id: any
    part_count: any
    Range: any
    DivData: []
  }