import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ThemePalette } from "@angular/material/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AppFormErrorService } from "../../services/form-error.service";
import { RegexService } from "../../services/regex-service";
import { AuthService } from "../../services/REST/auth.service";
import { UserService } from "../../services/REST/user.service";

import { blockPermissionMsg } from 'src/app/constant';
import { secureStorage } from "../../services/securestorage";
@Component({
  selector: 'add-user',
  templateUrl: 'add-user.component.html',
  styleUrls: ['add-user.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddUserComponent implements OnInit {
  @Output() modalClose = new EventEmitter<any>();
  @Input() pkuserid: any;
  account_pk_s: any[];
  selectedAccount: any;
  is_active: boolean = false;
  issubmit: boolean = true;
  color: ThemePalette = 'accent';
  checked;
  CheckedLable: string;
  iscontactosevice: boolean = false;
  userdetails: any;
  AccountData: any;
  myControl = new FormControl();
  submitted: boolean = false;
  user: any;
  userRegistrationForm: FormGroup;
  RoleList = [];
  userIds = [];
  userAlldetails = [];
  issmssend: string = "";
  pgTitleSMS = 'Confirmation ';
  pgTextSMS = 'Do you want to send reset password SMS to user?';
  otpverify: boolean = false;
  showLoader: boolean = false;
  isFirstTime: boolean = false;
  isSendOtpError: boolean = false;
  selectedAccountPk = [];
  get f() {
    return this.userRegistrationForm.controls;
  }
  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private regexService: RegexService,
    private router: Router,
    private formErrorService: AppFormErrorService,
    private authService: AuthService,
    private toastrService: ToastrService,
    private localstorage: secureStorage
  ) {}

  ngOnInit(): void {
    try {
      this.getRoleList();
      let user = JSON.parse(this.localstorage.getItem('user'));
      this.checked = false;
      this.CheckedLable = 'Active';
      this.is_active = true;
      this.userRegistrationForm = this.formBuilder.group({
        first_name: ['',[Validators.required,Validators.pattern(this.regexService.HumanName)]],
        last_name: ['', [Validators.pattern(this.regexService.HumanName)]],
        email_id: ['', [Validators.pattern(this.regexService.Email)]],
        role_id: ['', [Validators.required]],
        contact_no: ['',[Validators.required, Validators.pattern(this.regexService.Phone)]],
        account_pk_s: ['', Validators.required],
        is_active: [true],
        otp_number: [''],
      });
      this.getUserList();
      this.iscontactosevice = false;
      if (this.pkuserid) {
        this.userdetails = [];
        if (this.localstorage.getItem('userData')) {
          let users = JSON.parse(this.localstorage.getItem('userData'));
          this.showLoader = true;
          this.userService.GetOtherUserbyId({
              id: '' + this.pkuserid,
              account_pk: '' + users.account_pk,
            }).subscribe(
              (response) => {
                if (response.success) {
                  this.issubmit = false;
                  this.userdetails = response.data;
                  this.is_active = this.userdetails.is_active;
                  this.userRegistrationForm.patchValue(response.data);
                  if (this.userdetails.is_active == true) {
                    this.iscontactosevice = true;
                    this.CheckedLable = 'Active';
                    this.checked = true;
                  }
                  if (this.userdetails.is_active == false) {
                    this.CheckedLable = 'Inactive';
                    this.checked = false;
                  } else {
                    this.CheckedLable = 'Active';
                    this.checked = true;
                  }
                  var arr = [];
                  let roledata = this.RoleList.filter(
                    (result) => result.role_name == this.userdetails.role_name
                  );
                  this.selectedAccount = this.userdetails.user_ids;
                  for (let i = 0; i < this.selectedAccount.length; i++) {
                    arr.push(String(this.selectedAccount[i]['id']));
                  }
                  this.account_pk_s = arr;
                  if (roledata.length > 0) {
                    this.userRegistrationForm.patchValue({
                      role_id: roledata[0].id,
                    });
                  }
                  if (this.account_pk_s) {
                    this.userRegistrationForm.patchValue({
                      account_pk_s: this.account_pk_s,
                    });
                  }
                  if (this.selectedAccount.length > 0) {
                    this.onOptionsSelected();
                  }
                } else {
                }
                this.showLoader = false;
              },
              (error) => {
                this.showLoader = false;
              }
            );
        }
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'ngOnInit()',
        page_name: 'PageUserRegistrationComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: '',
      });
    }
  }

  alphanumbericOnly(event): boolean {
    var inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  closePopUp() {
    this.modalClose.emit();
  }

  getRoleList() {
    try {
      this.RoleList = [];
      if (this.localstorage.getItem('userData')) {
        let user = JSON.parse(this.localstorage.getItem('userData'));
        this.showLoader = true;
        this.userService
          .GetOtherUserRoleList({ account_pk: '' + user.account_pk })
          .subscribe(
            (response) => {
              if (response.success) {
                this.RoleList = response.data;
              } else {
                this.toastrService.error(response.data.msg);
              }
              this.showLoader = false;
            },
            (error) => {
              this.showLoader = false;
            }
          );
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'getRoleList()',
        page_name: 'PageUserRegistrationComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: '',
      });
    }
  }

  getUserList() {
    try {
      var userIddata = JSON.parse(this.localstorage.getItem('userAccounts'));
      if (userIddata) {
        this.userIds = userIddata;
        let user = JSON.parse(this.localstorage.getItem('userData'));
        let userData = JSON.parse(this.localstorage.getItem('userData'));
        if (userData) {
          this.user = userData.account_pk;
        }
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'getUserList()',
        page_name: 'PageUserRegistrationComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: '',
      });
    }
  }

  onOptionsSelected() {
    try {
      this.userAlldetails = [];
      this.selectedAccountPk = [];
      var data = this.userRegistrationForm.value.account_pk_s;
      if (data === ' ' || data === '') {
      } else {
        for (var i = 0; i < data.length; i++) {
          var getuserdata = this.userIds.filter(
            (t) => t.account_pk == parseInt(data[i])
          );
          if (getuserdata.length > 0) {
            let address =
              getuserdata[0].address_1 +
              ' ' +
              getuserdata[0].address_2 +
              ' ' +
              getuserdata[0].address_3;
            this.userAlldetails.push(
              this.formData(
                getuserdata[0].account_id,
                getuserdata[0].account_name,
                address,
                getuserdata[0].state,
                getuserdata[0].city
              )
            );
            this.selectedAccountPk.push(getuserdata[0].account_pk);
          }
        }
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'onOptionsSelected()',
        page_name: 'PageUserRegistrationComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: '',
      });
    }
  }

  formData(AccountId, Name, Address, State, City: any): UserAccountData {
    const userData: UserAccountData = {} as UserAccountData;
    userData.AccountId = AccountId;
    userData.Name = Name;
    userData.Address = Address;
    userData.State = State;
    userData.City = City;
    return userData;
  }

  public hasRequiredError = (controlName: string): boolean =>
    this.formErrorService.hasRequiredError(
      controlName,
      this.userRegistrationForm
    );
  public hasPatternError = (controlName: string): boolean =>
    this.formErrorService.hasPatternError(
      controlName,
      this.userRegistrationForm
    );

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  OnUserGetOtp() {
    try {
      if (this.localstorage.getItem('userData')) {
        let user = JSON.parse(this.localstorage.getItem('userData'));
        if (user) {
          this.showLoader = true;
          this.authService
            .otheruserSendOtp({
              account_pk: parseInt(user.account_pk),
              contact_no: this.userRegistrationForm.value.contact_no,
            })
            .subscribe(
              (response) => {
                if (response.success) {
                  this.toastrService.success('OTP Sent.');
                  document.getElementById('otp_number').focus();
                } else {
                  this.toastrService.error(response.data.msg);
                }
                this.showLoader = false;
              },
              (error) => {
                this.showLoader = false;
              }
            );
        }
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'OnUserGetOtp()',
        page_name: 'PageUserRegistrationComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: '',
      });
    }
  }

  updateFunc(e) {
    if (e.checked == true) {
      this.CheckedLable = 'Active';
    } else {
      this.CheckedLable = 'Inactive';
    }
  }

  SendSMS() {
    try {
      let users = JSON.parse(this.localstorage.getItem('userData'));
      if (users) {
        //this.userService.SendSMS({ "account_pk":  users.user_ids[0].id }).subscribe(
        this.showLoader = true;
        this.issmssend = "";
        this.isSendOtpError = false;
        this.userService
          .SendSMS({
            account_pk: users.account_pk,
            other_user_pk: this.userdetails.id,
          })
          .subscribe(
            (data) => {
              if (data.success == true) {
                this.isSendOtpError = false;
                this.issmssend = 'SMS Sent. User can reset password anytime using the set password option in My Profile.';
                //this.otpverify = true;
              } else {
                this.isSendOtpError = true;
                this.issmssend = 'Something went wrong ! Please try again after sometime';
              }
              this.showLoader = false;
            },
            (err) => {
              this.showLoader = false;
              this.isSendOtpError = true;
              this.issmssend = 'Something went wrong ! Please try again after sometime';
            }
          );
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'SendSMS()',
        page_name: 'PageUserRegistrationComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: '',
      });
    }
  }

  onSubmit() {
    try {
      let permission = this.userService.CheckPermission(
        'btn_update_user_management'
      );

      if (permission) {
        this.submitted = true;
        if (this.userRegistrationForm.invalid) {
          this.formErrorService.displayFormErrors(this.userRegistrationForm);
          return;
        }
        if (this.otpverify) {
          if (!this.userRegistrationForm.value.otp_number) {
            return this.toastrService.error('Please Enter Otp.');
          }
        }
        let user = JSON.parse(this.localstorage.getItem('userData'));
        let usersData = JSON.parse(this.localstorage.getItem('user'));
        if (user && usersData) {
          let data = {
            account_pk: parseInt(user.account_pk),
            first_name: this.userRegistrationForm.value.first_name,
            last_name: this.userRegistrationForm.value.last_name,
            contact_no: this.userRegistrationForm.value.contact_no,
            email_id: this.userRegistrationForm.value.email_id,
            role_id: this.userRegistrationForm.value.role_id,
            //  "password": this.userRegistrationForm.value.password,
            account_pk_s: this.userRegistrationForm.value.account_pk_s,
            action_type: this.issubmit ? 'add' : 'update',
            is_active: this.userRegistrationForm.value.is_active,
            user_type: user.user_type,
            otp_number: this.otpverify ? this.userRegistrationForm.value.otp_number : '',
          };
          try {
            this.showLoader = true;
            this.userService.OtherUserRegistration(data).subscribe(
              (response) => {
                this.submitted = false;
                if (response.success === true) {
                  if (this.issubmit) {
                    this.toastrService.success('User Registered Successfully.');
                    this.modalClose.emit('User Registered Successfully.');
                  } else {
                    this.toastrService.success('User Data Updated Successfully.');
                    this.modalClose.emit('User Data Updated Successfully.');
                  }
                  //this.router.navigate(['/account/userRegistrationList']);
                } else {
                  try {
                    this.toastrService.error(response.data.msg);
                    if (
                      response.data.msg ===
                      'User already exists in another organization, OTP verification of user mobile no will be required to register user in your organization.'
                    ) {
                      this.otpverify = true;
                      this.OnUserGetOtp();
                    } else {
                      this.otpverify = false;
                    }
                  } catch (error) {
                    this.userService.InsertExceptionLog({
                      method_name: 'onSubmit()',
                      page_name: 'PageUserRegistrationComponent',
                      portal_type: 'Customer',
                      page_url: this.router.url,
                      error_message: error.message,
                      inner_exception: '',
                    });
                  }
                }
                this.showLoader = false;
              },
              (error) => {
                this.showLoader = false;
              }
            );
          } catch (error) {
            this.userService.InsertExceptionLog({
              method_name: 'onSubmit()',
              page_name: 'PageUserRegistrationComponent',
              portal_type: 'Customer',
              page_url: this.router.url,
              error_message: error.message,
              inner_exception: '',
            });
          }
        }
      } else {
        this.toastrService.error(blockPermissionMsg);
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'onSubmit()',
        page_name: 'PageUserRegistrationComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: '',
      });
    }
  }
}

export interface UserAccountData {
  AccountId: string;
  Name: string;
  Address: string;
  State: string;
  City: string;
}