import { Component, Input, OnInit, Output } from "@angular/core";
import { EventEmitter } from "@angular/core";

@Component({
    selector: 'part-discounts',
    templateUrl: './part-discounts.component.html',
    styleUrls: ['./part-discounts.component.scss']
})

export class PartDiscountsComponent implements OnInit {
    @Input() discountOffers: any[] = [];
    @Output() closePopUp = new EventEmitter<any>();
    constructor(){}
    ngOnInit(){}

    modalClose(){
        this.closePopUp.emit();
    }
}