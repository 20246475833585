import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from '../../services/REST/product.service';
import { UserService } from '../../services/REST/user.service';
import { secureStorage } from "../../../../../src/app/shared/services/securestorage";
import { AppStateService } from '../../services/app-state/app-state.service';

@Component({
  selector: 'app-contact-person-list',
  templateUrl: './contact-person-list.component.html',
  styleUrls: ['./contact-person-list.component.scss']
})
export class ContactPersonListComponent implements OnInit {

    contactPersonList:any[] = [];
    @Output() closeContactPerson = new EventEmitter<any>();
    @Output() updateContactDetails = new EventEmitter<any>();
    @Input() addressData:any;

    @ViewChild('confirmationContactPerson', { read: TemplateRef, static: false })
    confirmationContactPerson: TemplateRef<any>;
    confirmationContactPersonModal:any;

    @ViewChild('confirmationContactPersonRemove', { read: TemplateRef, static: false })
    confirmationContactPersonRemove: TemplateRef<any>;
    confirmationContactPersonRemoveModal:any;

    @ViewChild('addContactPerson', { read: TemplateRef, static: false })
    addContactPerson: TemplateRef<any>;
    addContactPersonModal:any;

    userData: any;
    isContactPersonUpdate:{ contactUpdat:boolean, contactPersonName:string } = { contactUpdat:false,contactPersonName:""};
    isContactPersonRemove:boolean = false;
    public selectedConatctPersonValue:any;
    contactPersonDetails:any;
    showLoader: boolean;
    isApiLoaded:boolean = false;
    contactRowId:string="";
    showHideContactList:boolean = false;
    selectedContactRowId:string="";

    constructor( 
        private appStateService: AppStateService,
        private modalService: BsModalService,
        private productService: ProductService,
        private router: Router,
        private toastrService: ToastrService,
        private userService: UserService,
        private localstorage: secureStorage
    ) { }

    ngOnInit(): void {
        this.userData =  JSON.parse(this.localstorage.getItem('userData'));
        this.selectedContactRowId = this.localstorage.getItem('selectedContactRowId')?
                                    this.localstorage.getItem('selectedContactRowId'):
                                    "";
        this.getContactPersonList()
    }

    getContactPersonList(){
        try{
            this.showLoader =true;
            this.showHideContactList = false;
            let req = {
                "account_pk":JSON.stringify(this.userData.account_pk),
                "action_type":"contact_data"
            }
            this.productService.getContactPersonList(req).subscribe(
                res => {
                    this.showLoader = false;
                    this.isApiLoaded = true;
                    if(res.success && res.data.length > 0  ){
                        this.contactPersonList = res.data;
                    }else{
                        this.contactPersonList = [];
                        // this.toastrService.error("No Contact Person added")
                    }
                }
            )
        }catch(error){
            this.userService.InsertExceptionLog({
                method_name: 'getContactPersonList()',
                page_name: 'CartAddress',
                portal_type: 'Customer',
                page_url: this.router.url,
                error_message: error.message,
                inner_exception: error,
            });
        }
    }

    getHideContactPersonList(){
        try{
            this.showLoader =true;
            this.showHideContactList = true;
            let req = {
                "account_pk":JSON.stringify(this.userData.account_pk),
                "action_type": "draft_list",
                "draft_type": "draft_contact",
                "offset": 0,
                "limit": 20
            }
            this.productService.getAddressDrafList(req).subscribe(
                res => {
                this.showLoader = false;
                    if(res){
                        this.contactPersonList = res;
                        this.isApiLoaded = true;
                    }else{
                        this.contactPersonList = [];
                        // this.toastrService.error("No Contact Person added")
                    }
                }
            )
        }catch(error){
            this.userService.InsertExceptionLog({
                method_name: 'getHideContactPersonList()',
                page_name: 'CartAddress',
                portal_type: 'Customer',
                page_url: this.router.url,
                error_message: error.message,
                inner_exception: error,
            });
        }
    }

    askUpdateContactPerson(contactData){
        
        this.isContactPersonUpdate.contactUpdat = true;
        this.isContactPersonUpdate.contactPersonName = contactData.first_name+" "+contactData.last_name;
        this.contactPersonDetails = contactData
        this.confirmationContactPersonModal =  this.modalService.show(this.confirmationContactPerson, {
            class: 'modal-dialog modal-dialog-centered modal-lg',
            backdrop: 'static',
        });
    }

    updateContactPerson(val){
        if(this.addressData != 'No'){
            try{
                this.showLoader = true;
                if(this.contactPersonDetails){
                    let req = {
                        "account_pk":Number( JSON.stringify(this.userData.account_pk) ),
                        "action_type":"add_contact_data",
                        "alt_contact_no": "",
                        "address_id":this.addressData.address_id,
                        "contact_first_name": this.contactPersonDetails.first_name,
                        "contact_last_name": this.contactPersonDetails.last_name,
                        "contact_no": this.contactPersonDetails.contact_number,
                        "contact_row_id": this.contactPersonDetails.row_id
                    }
                    if(val == 'update'){
                        this.productService.updateContactPerson(req).subscribe(
                            res => {
                                this.showLoader = false;
                                this.confirmationContactPersonModal.hide();
                                this.getContactPersonList();
                                if(res.success){
                                    this.toastrService.success(res.data.msg);
                                }
                                this.closeContactPerson.emit();
                            }
                        )
                    }
    
                }
            }catch(error){
                this.userService.InsertExceptionLog({
                method_name: 'updateContactPerson(val)',
                page_name: 'CartAddress',
                portal_type: 'Customer',
                page_url: this.router.url,
                error_message: error.message,
                inner_exception: error,
                });
            }
        }else{
            this.confirmationContactPersonModal.hide();
            this.localstorage.setItem('selectedContactRowId',this.contactPersonDetails.row_id);
            this.updateContactDetails.emit(this.contactPersonDetails);
            this.closeContactPerson.emit();
            // this.appStateService.openNewAddAddress.next("Yes");
        }
    }

    openAddContactPersonModal(){
        this.closeContactPerson.emit()
        this.addContactPersonModal = this.modalService.show(this.addContactPerson, {
            class: 'modal-dialog modal-dialog-centered modal-lg',
            backdrop: 'static',
        });
    }

    closeContactPersonModal(){
        this.appStateService.openContactPersonList.next("Yes");
        this.addContactPersonModal.hide();
    }
    
    askRemoveContactPerson(contactRowId){
        this.isContactPersonRemove = true;
        this.contactRowId = contactRowId;
        this.confirmationContactPersonRemoveModal = this.modalService.show(this.confirmationContactPersonRemove, {
            class: 'modal-dialog modal-dialog-centered modal-lg',
            backdrop: 'static',
        });
    }

    hidecontactPersonDetails(val){
        try{
            let req = {
                "account_pk": JSON.stringify(this.userData.account_pk),
                "address_id": this.contactRowId,
                "action_type": val=='remove'?"save_to_draft":val=='unhide'?"remove_draft":"",
                "draft_type": "draft_contact"
            }
            if(val=="remove" || val == "unhide") {
                this.showLoader = true;
                this.productService.getAddressDrafList(req).subscribe(
                    (response) => {
                        if(val=="remove"){
                            this.confirmationContactPersonRemoveModal.hide();
                        }
                        if(response.success === true){
                            if(val=="remove"){
                                this.getContactPersonList();
                            }else if(val=="unhide"){
                                this.getHideContactPersonList();
                            }
                            this.toastrService.success(response.data.msg);
                        }
                        else{
                            this.toastrService.error(response.data.msg);
                        }
                        this.showLoader = false;
                    },
                    (error) => { 
                        this.confirmationContactPersonRemoveModal.hide();
                        this.showLoader = false;
                        this.toastrService.error(error.data.msg);
                    }             
                )
            }
        }catch(error){
            this.confirmationContactPersonRemoveModal.hide();
            this.showLoader = false;
            this.userService.InsertExceptionLog({
                method_name: 'hideAddressFromList(check)',
                page_name: 'MenuheaderComponent',
                portal_type: 'Customer',
                page_url: this.router.url,
                error_message: error.message,
                inner_exception: error,
            });
        }
    }

    unhideContactPerson(val){
        this.contactRowId = val;
        this.hidecontactPersonDetails("unhide")
    }
}
