import { Input, TemplateRef, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie';
import { ToastrService } from 'ngx-toastr';
import { Address } from 'src/app/shared/interfaces/address';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { CartService } from 'src/app/shared/services/cart.service';
import { AppFormErrorService } from 'src/app/shared/services/form-error.service';
import { GoogleAnalyticsService } from 'src/app/shared/services/GoogleAnalytics/GoogleAnalytics.service';
import { RegexService } from 'src/app/shared/services/regex-service';
import { AuthService } from 'src/app/shared/services/REST/auth.service';
import { ProductService } from 'src/app/shared/services/REST/product.service';
import { RootService } from 'src/app/shared/services/REST/root.service';
import { UserService } from 'src/app/shared/services/REST/user.service';
import { UserCartService } from 'src/app/shared/services/REST/usercart.service';
import { MustMatch } from '../../../../shared/helpers/must-match.validator';
import { Subject } from 'rxjs';
import { secureStorage } from 'src/app/shared/services/securestorage';
import Swal from 'sweetalert2';
import { AddressService } from 'src/app/shared/services/REST/address.service';
import { Title } from '@angular/platform-browser';
import * as CryptoJS from 'crypto-js';
@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
  account_pk: any;
  showHeader: boolean = false;
  @Input() btndisabled: boolean = false;
  user: any;
  modalRef2: any;
  userAccounrDetails: any;
  address: Address;
  expiredDate: any;
  registrationForm: FormGroup;
  guestLoginForm: FormGroup;
  @Input() otpInputValue: string;
  userDetail = [];
  OtherUserNewOtp: string;
  OtherUserNewPassword: any;
  OtherUserConfrmPAssword: any;
  disabledAgreement: boolean = true;
  userIds = [];
  userData = [];
  mhvCart: any = [];
  slcvCart: any = [];
  cartArr = [];
  cartTypeList: any;
  isOtpGenerated: boolean = false;
  keys: any;
  showVehicleDetails: boolean = false;
  addresses: any = [];
  default_address: any;
  new_addresses: any = [];
  isTimer: boolean = false;
  eventsSubject: Subject<void> = new Subject<void>();
  isVehicleSelected: any;
  showLoader: boolean;
  isVehicleListPresent: boolean;
  vehicleinfo: any;
  isDelegateUser: boolean;
  delegateUserRole: any;
  isGuestLogin: boolean = false;
  districtList: any[];
  pincodeList: any[];
  talukaList: any[];
  cityList: any[];
  stateList: any[];
  isForgottenUser: boolean = false;
  searchChassisNum: string = '';
  searchRegNum: string = '';
  searchARNNum: string = '';
  searchCRNNum: string = '';
  searchByParameters: any = {
    isChassis: false,
    isReg: false,
    isARN: false,
    isCRN: false
  }
  stringParameters: any = "";
  relevantMobileNumbers: any;
  @ViewChild('Otpmodal', { read: TemplateRef, static: false })
  template: TemplateRef<any>;
  @ViewChild('selectUser', { read: TemplateRef, static: false })
  template1: TemplateRef<any>;
  @ViewChild('selectUserType', { read: TemplateRef, static: false })
  selectUserType: TemplateRef<any>;
  modalRef: any;
  modalRef1: any;
  hide = true;
  billingAddress:any;
  shippingAddress:any;
  phoneNoSearchParam:string = "Please Select Search Parameter";
  searchValueParam:string="";
  servicecall: boolean = false;
  constructor(
    private userService: UserService,
    private productService: ProductService,
    private googleAnlyatics: GoogleAnalyticsService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private modalService: BsModalService,
    private localstorage: secureStorage,
    private formErrorService: AppFormErrorService,
    private router: Router,
    private toastrService: ToastrService,
    private appStateService: AppStateService,
    private regexService: RegexService,
    private rootService: RootService,
    private cartService: UserCartService,
    private cart: CartService,
    private cookieService: CookieService,
    private route: ActivatedRoute,
    private title:Title,
    private addressService: AddressService
  ) { }
  ngOnInit(): void {
    this.createGuestLoginForm();
    this.registrationForm = this.formBuilder.group(
      {
        contact_no: [
          '',
          [Validators.required, Validators.pattern(this.regexService.Phone)],
        ],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(14),
          ],
        ],
        confirm_password: ['', Validators.required],
        user_type: [],
        otp_number: ['', [Validators.required]],
      },
      {
        validators: MustMatch('password', 'confirm_password'),
      }
    );
    this.route.queryParams.subscribe((params) => {
      var isLogin = params['isLogin'];
      var isRegistration = params['isRegistration'];
      if (isLogin === 'true') {
        this.showHeader = true;
      } else if (isRegistration === 'true') {
        this.showHeader = true;
      } else {
        this.showHeader = false;
      }
    });
    this.getStates();
    this.searchByParameters.isChassis = false;
    this.searchByParameters.isReg = false;
    this.searchByParameters.isARN = false;
    this.searchByParameters.isCRN = false;
    this.title.setTitle('E-Dukaan | Registration');
  }

  get f() {
    return this.registrationForm.controls;
  }
  //UserRegistration
  onSubmitOTP(otpinput = null, isViaForgot: boolean = false) {
    try {
      let number: string = this.registrationForm.value.contact_no;
      if (number.length < 10 || number.length == 0) {
        this.toastrService.error('Please enter 10 digit mobile number');
        return;
      }
      if (!this.registrationForm.controls['contact_no'].valid) {
        if (this.registrationForm.controls.contact_no.errors.pattern) {
          this.toastrService.error(
            'Mobile number should start with 6, 7, 8 or 9'
          );
        }
        this.formErrorService.displayFormErrors(this.registrationForm);
        return;
      }
      if (
        number.substring(0, 1) != '9' &&
        number.substring(0, 1) != '8' &&
        number.substring(0, 1) != '7' &&
        number.substring(0, 1) != '6'
      ) {
        this.toastrService.error(
          'Mobile number should start with 6, 7, 8 or 9'
        );
        return;
      }
      this.disabledAgreement = true;
      this.otpInputValue = '';
      let contact_no = this.registrationForm.value.contact_no;
      this.authService
        .UserRegistration({
          contact_no: this.registrationForm.value.contact_no,
        })
        .subscribe(
          (response) => {
            if (response.success) {
              if(isViaForgot){
                this.isForgottenUser = false;
                this.isGuestLogin = false;
              }
              this.keys = response['data']['keys'];
              this.toastrService.success('OTP sent on your mobile number');
              this.eventsSubject.next();
              this.isTimer = true;
              if (this.keys.length > 1) {
                this.modalRef2 = this.modalService.show(this.selectUserType, {
                  class: 'modal-dialog-centered modal-lg',
                  backdrop: 'static',
                });
              } else {
                this.registrationForm.controls['user_type'].setValue(this.keys[0]);
                this.isOtpGenerated = true;
                this.showVehicleDetails = false;
              }
            } else {
              try {
                this.googleAnlyatics.loginFail('Login_Fail_web', this.registrationForm.value.user_type, this.registrationForm.value.contact_no);
              }
              catch (error) { }
              if (response?.data?.msg === "User not present in TML Database.Please contact TML on 020-67136236 or edukaansupport@tatamotors.com for assistance" && response?.data?.error_code == 404) {
                // Swal.fire({
                //   title: "This number is not registered with TATA Motors, do you wish to register as a Guest User?",
                //   text: 'Do you want to register as a Guest?',
                //   icon: 'warning',
                //   showCancelButton: true,
                //   confirmButtonColor: '#3085d6',
                //   cancelButtonColor: '#d33',
                //   confirmButtonText: 'Yes'
                // }).then((result) => {
                //   if (result.value) {
                //     this.getStates();
                //     this.registrationForm.reset();
                //     this.createGuestLoginForm(contact_no);
                //     this.isForgottenUser = false;
                //     this.isGuestLogin = true;
                //   }
                // })
                this.toastrService.error("For registering on e-Dukaan, Kindly reach out to us at 020-67136236 or send a mail to edukaansupport@tatamotors.com");
              }
              else{
                if(response?.data?.msg?.length > 0){
                  this.toastrService.error(response.data.msg);
                }
              }
            }
          },
          (error) => {
            if (error?.error?.data?.msg) {
              this.toastrService.error(error.error.data.msg);
            }
          }
        );
    } catch (error) {
      this.userService.InsertExceptionLog({
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'PageLoginComponent',
        method_name: 'onSubmitOTP()',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  createGuestLoginForm(contact_number?:any){
    this.guestLoginForm = this.formBuilder.group({
      name: ['',Validators.required],
      city: ['',Validators.required],
      country: ['India'],
      postal_code: ['',Validators.required],
      state: ['',Validators.required],
      district: ['',Validators.required],
      taluka: [''],
      street_address: ['',[Validators.required, Validators.maxLength(60)]],
      location: [''],
      contact_no: [contact_number?contact_number:'',Validators.required],
      street_address2: ['',[Validators.required, Validators.maxLength(100)]],
      otp_number: [''],
      password: ['',Validators.minLength(6)],
      confirm_password: ['']
    },
    {
      validators: MustMatch('password', 'confirm_password'),
    })
  }

  verifyOTP() {
    try {
      if (!this.otpInputValue) {
        return this.toastrService.error('Please enter otp!');
      }
      if (!this.btndisabled && !this.isGuestLogin) {
        this.btndisabled = true;
        var skey = "edp@$3#drishti"
        var salt = "dRishtI";
        var iterations = 128;
        var bytes = CryptoJS.PBKDF2(skey, salt, { keySize: 48, iterations: iterations });
        var iv = CryptoJS.enc.Hex.parse(bytes.toString().slice(0, 32));
        var key = CryptoJS.enc.Hex.parse(bytes.toString().slice(32, 96));
        var cipherpassword = CryptoJS.AES.encrypt(this.registrationForm.value.password, key, { iv: iv }).toString();
        var cipherConfirmpassword = CryptoJS.AES.encrypt(this.registrationForm.value.confirm_password, key, { iv: iv }).toString();
        let userData = {
          contact_no: this.registrationForm.value.contact_no,
          password: cipherpassword,
          confirm_password: cipherConfirmpassword,
          user_type: this.registrationForm.value.user_type,
          otp_number: this.otpInputValue,
        };
        if (this.registrationForm.value.user_type !== 'other_user') {
          this.authService
            .VerifyOtpUserRegistration(userData)
            .subscribe((response) => {
              this.btndisabled = false;
              this.userDetail = response.data;
              if (response.success === true) {
                this.isDelegateUser = response.data.other_user_id ? true : false;
                this.delegateUserRole = this.isDelegateUser ? response.data.other_user_info.role_name : "Owner";
                this.isVehicleSelected = response.data.is_vehicle_checked;
                this.localstorage.setItem('isVehicleSelected', JSON.stringify(this.isVehicleSelected));
                if (response.data.user_type_list.length === 0) {
                  return this.toastrService.error('This mobile number is not associated with any Account ID.');
                }
                //Google Analyatics Code Start
                try {
                  this.googleAnlyatics.login(
                    'login_web',
                    this.registrationForm.value.user_type,
                    this.registrationForm.value.contact_no
                  );
                } catch (error) { }
                //Google Analyatics Code END
                this.expiredDate = new Date();
                let Minutes = response.data.token['expires_in'] * 1000; // convert 2 minutes to milliseconds
                var expirein = Minutes - Minutes / 10;
                let expiredate2 = new Date(this.expiredDate.getTime() + expirein);
                this.cookieService.putObject('access_token', response.data.token['access_token'], { expires: expiredate2 });
                this.cookieService.putObject('ref_token', response.data.token['refresh_token']);
                this.localstorage.setItem('ref_token', response.data.token['refresh_token']);
                this.localstorage.setItem('refreshTokenInProgress', 'false');
                this.rootService.getCartType();
                var json = {
                  user_type: this.registrationForm.value.user_type,
                  offset: 0,
                  limit: 30,
                };
                this.authService.getUserTypeList(json).subscribe(
                  (data) => {
                    if (data.success == true) {
                      if (data['data'].length == 1) {
                        data.data[0].isDelegateUser = this.isDelegateUser;
                        data.data[0].userRole = this.delegateUserRole;
                        this.localstorage.setItem('userAccounts', JSON.stringify(data.data));
                        this.localstorage.setItem('userData', JSON.stringify(data.data[0]));
                        this.localstorage.setItem('user', JSON.stringify(data.data[0]));
                        this.userData = data.data[0];
                        this.account_pk = data.data[0].account_pk;
                        if (this.isVehicleSelected) {
                          this.BindPageList();
                        } else {
                          this.servicecall = true;
                          this.checkVehicleList();
                          //     this.showVehicleDetails = true;
                          //  this.isOtpGenerated = false;
                        }

                      }
                      else {
                        this.userIds = data.data;
                        this.userIds.forEach(userId => {
                          userId.isDelegateUser = this.isDelegateUser;
                          userId.userRole = this.delegateUserRole;
                        });
                        this.setselectedUser(this.userIds[this.userIds.length - 1]);
                        //this.modalRef1 = this.modalService.show(this.template1,{ class: 'modal-dialog-centered modal-lg', backdrop: 'static'});
                      }
                    } else if (data.success == false) {
                      if (data?.data?.msg) {
                        this.toastrService.error(data.data.msg);
                      }
                    }
                  },
                  (error) => {
                    if (error?.error?.data?.msg) {
                      this.toastrService.error(error.error.data.msg);
                    }
                  }
                );
              } else {
                try {
                  this.googleAnlyatics.loginFail(
                    'Login_Fail_web',
                    this.registrationForm.value.user_type,
                    this.registrationForm.value.contact_no
                  );
                } catch (error) { }
                this.toastrService.error(response.data.msg);
              }
            });
        } else {
          this.localstorage.setItem('usertype', this.registrationForm.value.user_type);
          //  this.localstorage.setItem('isOtherUser', this.otherUserId ? 'true' : 'false');
          this.authService
            .otheruserverifyOTP(userData)
            .subscribe((response) => {
              this.userDetail = response.data;
              this.btndisabled = false;
              if (response.success === true) {
                if (response.data.user_ids.length === 0) {
                  this.modalRef.hide();
                  return this.toastrService.error(
                    'This mobile number is not associated with any Account ID.'
                  );
                }
                //Google Analyatics Code Start
                try {
                  this.googleAnlyatics.login(
                    'login_web',
                    this.registrationForm.value.user_type,
                    this.registrationForm.value.contact_no
                  );
                } catch (error) { }
                //Google Analyatics Code END
                this.modalRef.hide();
                this.expiredDate = new Date();
                let Minutes = response.data.token['expires_in'] * 1000; // convert 2 minutes to milliseconds
                var expirein = Minutes - Minutes / 10;
                let expiredate2 = new Date(
                  this.expiredDate.getTime() + expirein
                );
                this.cookieService.putObject('access_token', response.data.token['access_token'], { expires: expiredate2 });
                this.cookieService.putObject('ref_token', response.data.token['refresh_token']);
                this.localstorage.setItem('ref_token', response.data.token['refresh_token']);
                this.localstorage.setItem('refreshTokenInProgress', 'false');
                const tokenobj = { token: response.data.token['access_token'] };
                this.GetAccessPermission(tokenobj);
                if (response.data.user_ids.length > 1) {
                  this.userIds = response.data.user_ids;
                  this.modalRef1 = this.modalService.show(this.template1, {
                    class: 'modal-dialog-centered modal-lg',
                    backdrop: 'static',
                  });
                } else {
                  this.localstorage.setItem('userData', JSON.stringify(response.data));
                  this.localstorage.setItem('user', JSON.stringify(response.data));
                  this.userData = response.data.user_ids;
                  this.BindPageList();
                  // this.GetAddress();
                }
              } else {
                try {
                  this.googleAnlyatics.loginFail(
                    'Login_Fail_web',
                    this.registrationForm.value.user_type,
                    this.registrationForm.value.contact_no
                  );
                } catch (error) { }
                this.toastrService.error('Invalid OTP, verification failed.');
              }
            });
        }
      }
      else if(!this.btndisabled && this.isGuestLogin){
        this.btndisabled = true;
        let requestBody = {};
        for(let x in this.guestLoginForm.value){
          if(x != "confirm_password"){
            requestBody[x] = this.guestLoginForm.value[x];
          }
        }
        this.authService.guestUserRegistration(requestBody)
        .subscribe((response) => {
          this.btndisabled = false;
          this.userDetail = response.data;
          if (response.success === true) {
            this.isDelegateUser = response.data.other_user_id ? true : false;
            this.delegateUserRole = this.isDelegateUser ? response.data.other_user_info.role_name : "Owner";
            this.isVehicleSelected = response.data.is_vehicle_checked;
            this.localstorage.setItem('isVehicleSelected', JSON.stringify(this.isVehicleSelected));
            if (response.data.user_type_list.length === 0) {
              return this.toastrService.error('This mobile number is not associated with any Account ID.');
            }
            //Google Analyatics Code Start
            try {
              this.googleAnlyatics.login(
                'login_web',
                this.registrationForm.value.user_type,
                this.registrationForm.value.contact_no
              );
            } catch (error) { }
            //Google Analyatics Code END
            this.expiredDate = new Date();
            let Minutes = response.data.token['expires_in'] * 1000; // convert 2 minutes to milliseconds
            var expirein = Minutes - Minutes / 10;
            let expiredate2 = new Date(this.expiredDate.getTime() + expirein);
            this.cookieService.putObject('access_token', response.data.token['access_token'], { expires: expiredate2 });
            this.cookieService.putObject('ref_token', response.data.token['refresh_token']);
            this.localstorage.setItem('ref_token', response.data.token['refresh_token']);
            this.localstorage.setItem('refreshTokenInProgress', 'false');
            this.rootService.getCartType();
            var json = {
              user_type: response.data.user_type_list[0],
              offset: 0,
              limit: 30,
            };
            this.authService.getUserTypeList(json).subscribe(
              (data) => {
                if (data.success == true) {
                  if (data['data'].length == 1) {
                    data.data[0].isDelegateUser = this.isDelegateUser;
                    data.data[0].userRole = this.delegateUserRole;
                    this.localstorage.setItem('userAccounts', JSON.stringify(data.data));
                    this.localstorage.setItem('userData', JSON.stringify(data.data[0]));
                    this.localstorage.setItem('user', JSON.stringify(data.data[0]));
                    this.userData = data.data[0];
                    this.account_pk = data.data[0].account_pk;
                    if (this.isVehicleSelected) {
                      this.BindPageList();
                    } else {
                      this.servicecall = true;
                      this.checkVehicleList();
                      //     this.showVehicleDetails = true;
                      //  this.isOtpGenerated = false;
                    }

                  }
                  else {
                    this.userIds = data.data;
                    this.userIds.forEach(userId => {
                      userId.isDelegateUser = this.isDelegateUser;
                      userId.userRole = this.delegateUserRole;
                    });
                    this.setselectedUser(this.userIds[this.userIds.length - 1]);
                    //this.modalRef1 = this.modalService.show(this.template1,{ class: 'modal-dialog-centered modal-lg', backdrop: 'static'});
                  }
                } else if (data.success == false) {
                  if (data?.data?.msg) {
                    this.toastrService.error(data.data.msg);
                  }
                }
              },
              (error) => {
                if (error?.error?.data?.msg) {
                  this.toastrService.error(error.error.data.msg);
                }
              }
            );
          } else {
            try {
              this.googleAnlyatics.loginFail(
                'Login_Fail_web',
                this.registrationForm.value.user_type,
                this.registrationForm.value.contact_no
              );
            } catch (error) { }
            var i=0;
            if (response?.data?.msg === "You can't add new address identical address already exists." && response?.data?.error_code === 90 && i < 2 ){
              i++;
              this.guestLoginForm.controls['street_address'].setValue(this.guestLoginForm.controls['street_address'].value+",");
              this.appStateService.resendGuestOtp.next(true);
            }else{
              this.toastrService.error(response.data.msg);
            }
          }
        });
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'RegistrationComponent',
        method_name: 'verifyOTP()',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  GetAccessPermission(token) {
    try {
      this.authService
        .GetOtherUserPermissionList(token)
        .subscribe((response) => {
          if (response.success) {
            var vehicleList = response.data;
            this.localstorage.setItem(
              'OtherUserPermission',
              JSON.stringify(response.data)
            );
          } else {
          }
        });
    } catch (error) {
      this.userService.InsertExceptionLog({
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'PageLoginComponent',
        method_name: 'GetAccessPermission()',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  registerDirectWithNumber(val){
    if(val && val.length == 10 ){
      this.registrationForm.controls['contact_no'].setValue(val);
      this.btndisabled = false;
      this.isGuestLogin = false;
      this.onSubmitOTP();
    }
  }

  async BindPageList() {
    try {
      localStorage.removeItem('PageList');
      let user = JSON.parse(this.localstorage.getItem('userData'));
      if (user) {
        await this.authService
          .GetPageList({ account_pk: user.account_pk })
          .subscribe(
            (response) => {
              if (response.success === true) {
                let afterLoginMainHeader = ['HOME'];
                let layoutMenu = [
                  // 'Set Password',
                  'Account Management',
                  'User Management',
                  'User permission',
                  'Bulk Order Upload',
                ];
                let ordersMenu = [
                  'Active Order',
                  'Order History',
                  'Orders In-Process',
                  'Cancel Order History',
                  'Invoice Return',
                ];
                let orderHistoryMenu = ['E-Com Orders', 'CRM Orders', 'Invoice Payment','Pay By Loyalty' ];
                var datas: any[] = response.data;
                //profile menu start
                let profilelist = datas.filter((obj) => {
                  for (let i = 0; i <= layoutMenu.length; i++) {
                    if (obj.page_display_name == layoutMenu[i]) {
                      obj.page_url = '/' + obj.page_url;
                      obj.isPermission = true;
                      return obj;
                    } else if (obj.page_display_name == 'My Profile') {
                      obj.page_url = '/' + obj.page_url;
                      obj.isPermission = true;
                      this.localstorage.setItem('isProfilePermission', JSON.stringify(obj.isPermission));
                      this.appStateService.isProfilePermission.next(true);
                      return obj;
                    } else if (obj.page_display_name == 'Vehicle Management') {
                      obj.isPermission = true;
                      this.localstorage.setItem('isVehiclePermission', JSON.stringify(obj.isPermission));
                      this.appStateService.isVehiclePermission.next(true);
                    } else {
                    }
                  }
                });
                this.localstorage.setItem('profileLayoutMenu', JSON.stringify(profilelist));
                this.appStateService.isprofilelist.next(true);
                //profile menu end
                //----------------------------//
                //orderhistory start//
                let orderHistoryMenuAllowed = datas.filter((obj) => {
                  for (let i = 0; i <= orderHistoryMenu.length; i++) {
                    if (obj.page_display_name == orderHistoryMenu[i]) {
                      obj.page_url = '/' + obj.page_url;
                      obj.isPermission = true;
                      return obj;
                    }
                  }
                });
                this.localstorage.setItem('orderHistoryMenuList', JSON.stringify(orderHistoryMenuAllowed));
                //orderhistory end//
                //----------------------------//
                //orders menu start
                let orderMenuList = datas.filter((obj) => {
                  for (let i = 0; i <= ordersMenu.length; i++) {
                    if (obj.page_display_name == ordersMenu[i]) {
                      obj.page_url =
                        obj.page_display_name == 'Order History'
                          ? '/orders/ecom-orders'
                          : obj.page_url.includes('account')
                            ? '/' + obj.page_url.replace('account', 'orders')
                            : '/' + obj.page_url;

                      obj.page_display_name == 'Cancel Order History'
                        ? 'Cancel Order'
                        : obj.page_display_name;
                      obj.isPermission = true;
                      return obj;
                    }
                  }
                });

                let sortedOrderMenu: any[] = [];
                orderMenuList.forEach((menu) => {
                  if (menu.page_display_name === 'Active Order') {
                    sortedOrderMenu.splice(0, 0, menu);
                  }
                  if (menu.page_display_name === 'Order History') {
                    sortedOrderMenu.splice(1, 0, menu);
                  }
                  if (menu.page_display_name === 'Orders In-Process') {
                    sortedOrderMenu.splice(2, 0, menu);
                  }
                  if (menu.page_display_name === 'Cancel Order History' || menu.page_display_name === 'Cancel Order') {
                    sortedOrderMenu.splice(3, 0, menu);
                  }
                  if (menu.page_display_name === 'Invoice Return') {
                    sortedOrderMenu.splice(4, 0, menu);
                  }
                });
                this.localstorage.setItem('orderMenuList', JSON.stringify(sortedOrderMenu));
                //orders menu close
                //----------------------------//
                //after login menu start
                let reportsPermission = datas.filter((obj) => {
                  if (obj.page_display_name == 'Order Tracking') {
                    obj.page_url = '/' + obj.page_url;
                    obj.isPermission = true;
                    return obj;
                  }
                });
                if (orderMenuList?.length >= 1) {
                  afterLoginMainHeader.push('ORDER');
                }
                if (reportsPermission?.length >= 1) {
                  afterLoginMainHeader.push('REPORTS');
                }
                afterLoginMainHeader.splice(1, 0, 'MY DASHBOARD');
                afterLoginMainHeader.push('MY VEHICLES');
                afterLoginMainHeader.push('MY OFFERS');
                // afterLoginMainHeader.push('MY CREDIT');
                this.localstorage.setItem('afterLoginMainHeader', JSON.stringify(afterLoginMainHeader));
                this.appStateService.afterLoginPublish.next(true);
                //after login menu close
                //----------------------------//
                //dropdown menu start
                //  let changePasswordPermission = datas.filter((obj) => {
                //    if (obj.page_display_name == 'Set Password') {
                //      obj.isPermission = true;
                //      return obj;
                //    }
                //  });
                let orderHistoryProfilePermission = datas.filter((obj) => {
                  if (obj.page_display_name == 'Order History') {
                    obj.isPermission = true;
                    return obj;
                  }
                });
                let changePassBehaviour: boolean;
                let orderHistoryProfileBehaviour: boolean;
                //  if (changePasswordPermission.length == 0) {
                //    changePassBehaviour = false;
                //  } else {
                //    changePassBehaviour = true;
                //  }
                if (orderHistoryProfilePermission.length == 0) {
                  orderHistoryProfileBehaviour = false;
                } else {
                  orderHistoryProfileBehaviour = true;
                }

                this.localstorage.setItem(
                  'orderHistoryProfilePermission',
                  JSON.stringify(orderHistoryProfileBehaviour)
                );
                this.appStateService.isorderHistoryProfilePermission.next(
                  orderHistoryProfileBehaviour
                );
                // let changePasswordPermission = datas.filter((obj) => {
                //   if (obj.page_display_name == 'Set Password') {
                //     obj.isPermission = true;
                //     return obj;
                //   }
                // });
                // let changePassBehaviour: boolean =  changePasswordPermission.length == 0 ?  false : true;

                //  this.localstorage.setItem(
                //    'changePasswordPermission',
                //    JSON.stringify(changePassBehaviour)
                //  );
                //  this.appStateService.isChangepasswordPermission.next(
                //    changePassBehaviour
                //  );
                // dropdown menu end
                //----------------------------//
                //eta start
                //   let isETAReport  = datas.filter((obj) => {
                //   for(let i=0;i<=ordersMenu.length;i++){
                //   if(obj.page_display_name == 'ETA Report'){
                //     obj.page_url = "/" + obj.page_url;;
                //     obj.isPermission=true;
                //     return obj;
                //   }
                //   }
                //   });
                // this.localstorage.setItem(
                //   'isETAReport',
                //   JSON.stringify(isETAReport)
                // );
                //eta end
              } else {
                let setAllPermission = false;
                //  this.localstorage.setItem(
                //    'changePasswordPermission',
                //    JSON.stringify(setAllPermission)
                //  );
                this.localstorage.setItem(
                  'orderHistoryProfilePermission',
                  JSON.stringify(setAllPermission)
                );
                this.localstorage.setItem('isProfilePermission', null);
                this.localstorage.setItem('isVehiclePermission', null);

                this.appStateService.isProfilePermission.next(null);
                this.appStateService.isVehiclePermission.next(null);
                this.localstorage.setItem(
                  'profileLayoutMenu',
                  JSON.stringify(setAllPermission)
                );
                this.localstorage.setItem(
                  'orderMenuList',
                  JSON.stringify(setAllPermission)
                );

                let afterLoginMainHeader = ['HOME'];
                this.localstorage.setItem(
                  'afterLoginMainHeader',
                  JSON.stringify(afterLoginMainHeader)
                );
                this.appStateService.afterLoginPublish.next(true);
              }
              this.redirectPostLogin();
            },
            (error) => {
              this.redirectPostLogin();
            }
          );
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'BindPageList()',
        page_name: 'MenuComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }
  async GetAddress(routeUrl, queryParams?) {
    try {
      let userData = JSON.parse(this.localstorage.getItem('userData'));
      if(this.isGuestLogin){
        await Swal.fire({
            title: 'Welcome '+userData.account_name,
            html: '<h3>We are putting your account details together please wait for sometime<h3><br>'
                  +'<progress id="file-progreesive-bar" [value]="b" max="100"></progress>',
            timerProgressBar: true,
            showConfirmButton: false, //hide OK button
            allowOutsideClick: false,
            timer: 5000
          })
          .then(() => {
            Swal.close();
        })
      }
      if (userData) {
        let user = {
          account_pk: parseInt(JSON.stringify(userData.account_pk)),
          offset: 10,
          limit: 10,
        };
        if (user) {
          await this.productService.getAddressList(user).subscribe((response) => {
            if (response.success === true) {
              this.addresses = response.data;
              this.billingAddress = response.selected_address['billing_details'];
              this.shippingAddress = response.selected_address['shipping_details'];
              this.addresses.forEach((address) => {
                if (address.default == true) {
                  this.default_address = address;
                } else {
                  this.new_addresses.push(address);
                }
              });
              var pincode = this.default_address.postal_code ? parseInt(this.default_address.postal_code) : '';
              let address = {
                account_id: this.default_address.account_id,
                address_id: this.default_address.address_id,
                city: this.default_address.city,
                district: this.default_address.district,
                gst_no: this.default_address.gst_no,
                postal_code: this.default_address.postal_code,
                pr_bl_addr_id: this.default_address.pr_bl_addr_id,
                state: this.default_address.state,
                street_address1: this.default_address.street_address1,
                street_address2: this.default_address.street_address2,
                street_address3: this.default_address.street_address3,
                taluka: this.default_address.taluka,
                isDefault: this.default_address.default,
                default: this.default_address.default,
                isBillingAddress: this.default_address.default,
                isDeliveryAddress: this.default_address.default,
              };
              let userAddress = { address: address, type: 'default' };
              this.billingAddress
                ?this.localstorage.setItem('billingAddress', JSON.stringify({address: this.billingAddress, type:'new'}))
                :this.localstorage.setItem('billingAddress', JSON.stringify(userAddress));
                this.shippingAddress
                ?this.localstorage.setItem('address', JSON.stringify({address: this.shippingAddress, type:'new'}))
                :this.localstorage.setItem('address', JSON.stringify(userAddress));
                this.shippingAddress
                ? pincode = parseInt(this.shippingAddress.postal_code)
                : pincode = pincode;
              this.appStateService.postalCode.next(pincode);
              this.localstorage.setItem('Pincode', JSON.stringify(pincode));
              this.localstorage.setItem('AddressId', this.default_address.address_id);
            } else {
              // this.toastrService.error(response.data.msg);
            }
            this.localstorage.setItem("isCashDiscountApplicable",JSON.stringify(false));
            if (queryParams != '') {
              this.appStateService.userSubject.next({ user_ids: this.userData });
              this.appStateService.isLoggedIn.next(true);
              this.router.navigate(routeUrl, queryParams);
            }
            else {
              this.appStateService.userSubject.next({ user_ids: this.userData });
              this.appStateService.isLoggedIn.next(true);
              this.router.navigate(routeUrl);
            }
            this.showVehicleDetails = true;
          }, error => {
            this.localstorage.setItem("isCashDiscountApplicable",JSON.stringify(false));
            if (queryParams != '') {
              this.appStateService.userSubject.next({ user_ids: this.userData });
              this.appStateService.isLoggedIn.next(true);
              this.router.navigate(routeUrl, queryParams);
            }
            else {
              this.appStateService.userSubject.next({ user_ids: this.userData });
              this.appStateService.isLoggedIn.next(true);
              this.router.navigate(routeUrl);
            }
            this.showVehicleDetails = true;
          });
        }
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'PageLoginComponent',
        method_name: 'GetAddress()',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  BindMethod() {
    try {
      let user_ids = { user_ids: this.userData };
      this.appStateService.isLoggedIn.next(true);
      this.appStateService.userSubject.next(user_ids);
      this.pushCartData(this.userData['account_pk']);
      this.router.navigate(['/account/home']);
    } catch (error) {
      this.userService.InsertExceptionLog({
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'PageLoginComponent',
        method_name: 'BindMethod()',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  pushCartData(account_pk) {
    try {
      this.mhvCart = JSON.parse(this.localstorage.getItem('cartItems'));
      if (this.mhvCart !== null && this.mhvCart.length > 0) {
        this.mhvCart.forEach((result) => {
          if (this.localstorage.getItem('getCartType')) {
            this.cartTypeList = JSON.parse(this.localstorage.getItem('getCartType'));
          }
          var Distributercarttypedata = this.cartTypeList.filter((alitems) =>
            alitems.distributor_categories.includes(
              result.product.DISTRIBUTOR_CATEGORY_s
            )
          );
          let cart_type_name = Distributercarttypedata[0]['cart_type'];
          let cartData = {};
          cartData = {
            part_number: result.product.part_number,
            quantity: result.quantity,
            cart_type: cart_type_name,
          };
          this.cartArr.push(cartData);
        });
      }
      if (this.cartArr.length > 0) {
        let order_lines = this.cartArr;
        let cartData = {};
        cartData = {
          account_pk: account_pk,
          cart_items: order_lines,
          validate_request:true
        };
        this.productService.add_to_cart_ms(cartData).subscribe((response) => {
          if (response.success === true) {
            localStorage.removeItem('cartItems');
            setTimeout(() => {
              this.cart.load();
            }, 500);
          }
        });
      } else {
        setTimeout(() => {
          this.cart.load();
        }, 500);
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'PageLoginComponent',
        method_name: 'pushCartData()',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  async redirectPostLogin() {
    this.localstorage.setItem("isProfileDropdownOpened", "false");
    this.localstorage.setItem("isCartDropdownOpened", "false");
    let addToCartItems: any[] = [];
    let preloginItems: any[] = JSON.parse(this.localstorage.getItem('cartItems'));
    if (preloginItems != null && preloginItems != undefined && preloginItems?.length > 0) {
      preloginItems.forEach((item) => {
        addToCartItems.push({
          part_code: item.product.part_number,
          quantity: item.product.resource_type =='Pack' ? item.quantity/item.product.moq :item.quantity,
          price: item.product.umrp,
          cart_type: item.cart_type_name,
        });
      });
      let requestBody = {
        account_pk: this.userData['account_pk'],
        order_lines: addToCartItems,
        validate_request:true
      }
      this.showLoader = true;
      let cartQueryParams = (this.localstorage.getItem("cartQueryParams") != "" && this.localstorage.getItem("cartQueryParams") != null && this.localstorage.getItem("cartQueryParams") != undefined) ? JSON.parse(this.localstorage.getItem("cartQueryParams")) : null;
      await this.productService.add_to_cart_ms(requestBody).subscribe(response => {
        this.cart.cartConfiguration();
        if (response.success) {
          localStorage.removeItem('cartItems');
          if (cartQueryParams != null && cartQueryParams != undefined) {
            let permission = this.userService.CheckPermission('btn_cart');
            this.localstorage.setItem("cartQueryParams", null);
            if (permission) {
              this.localstorage.setItem("isCartLoggedIn", "true");
              this.GetAddress(['cart/address'], { queryParams: cartQueryParams, });
              this.showLoader = false;
            }
            else {
              this.GetAddress([''], '');
              this.showLoader = false;
            }
          }
          else {
            this.GetAddress([''], '');
            this.showLoader = false;
          }
        }
        else {
          localStorage.removeItem('cartItems');
          if (cartQueryParams != null && cartQueryParams != undefined) {
            let permission = this.userService.CheckPermission('btn_cart');
            this.localstorage.setItem("cartQueryParams", null);
            if (permission) {
              this.localstorage.setItem("isCartLoggedIn", "true");
              this.GetAddress(['cart/address'], { queryParams: cartQueryParams, });
              this.showLoader = false;
            }
            else {
              this.GetAddress([''], '');
              this.showLoader = false;
            }
          }
          else {
            this.GetAddress([''], '');
            this.showLoader = false;
          }
        }
      }, error => {
        localStorage.removeItem('cartItems');
        this.cart.cartConfiguration()
        if (cartQueryParams != null && cartQueryParams != undefined) {
          let permission = this.userService.CheckPermission('btn_cart');
          this.localstorage.setItem("cartQueryParams", null);
          if (permission) {
            this.localstorage.setItem("isCartLoggedIn", "true");
            this.GetAddress(['cart/address'], { queryParams: cartQueryParams, });
            this.showLoader = false;
          }
          else {
            this.GetAddress([''], '');
            this.showLoader = false;
          }
        }
        else {
          this.GetAddress([''], '');
          this.showLoader = false;
        }
      })
    }
    else {
      this.GetAddress([''], '');
      this.cart.cartConfiguration();
      this.showLoader = false;
    }
  }

  resendOnSubmitOTP() {
    this.otpInputValue = '';
    if(!this.isGuestLogin){
      this.onSubmitOTP();
    }
    else{
      this.sendOtpForGuestLogin();
    }
    this.reEnterMobile(true);
  }

  reEnterMobile(otpVal){
    this.isOtpGenerated = otpVal;
    if(this.isGuestLogin){
      this.btndisabled = false;
      this.guestLoginForm.controls.otp_number.setValue('');
      this.guestLoginForm.controls.password.setValue('');
      this.guestLoginForm.controls.confirm_password.setValue('');
      this.guestLoginForm.controls.otp_number.clearValidators();
      this.guestLoginForm.controls.password.clearValidators();
      this.guestLoginForm.controls.confirm_password.clearValidators();
      this.guestLoginForm.controls.password.setValidators(Validators.minLength(6));
    }else{
      this.registrationForm.controls.otp_number.setValue('');
      this.registrationForm.controls.password.setValue('');
      this.registrationForm.controls.confirm_password.setValue('');
      this.registrationForm.controls.otp_number.clearValidators();
      this.registrationForm.controls.password.clearValidators();
      this.registrationForm.controls.confirm_password.clearValidators();
      this.registrationForm.controls.password.setValidators(Validators.minLength(6));
      if(this.isForgottenUser){
        this.registrationForm.controls.contact_no.setValue('');
      }
    }
  }

  CloseOtpVerify() {
    this.modalRef.hide();
    this.disabledAgreement = !this.disabledAgreement;
  }

  setAndVerifyOtp(event) {
    if (event.length === 6) {
      this.otpInputValue = event;
      if(!this.isGuestLogin){
        this.registrationForm.controls['otp_number'].setValue(this.otpInputValue);
      }
      else{
        this.guestLoginForm.controls['otp_number'].setValue(this.otpInputValue);
      }
    }
  }

  closeUserPopUp() {
    this.modalRef1.hide();
  }

  setselectedUser(event) {
    this.user = event;
    this.setUser();
  }

  setUser() {
    try {
      if (!this.user) {
        return this.toastrService.error('Please select user!');
      }
      if (!this.btndisabled) {
        this.btndisabled = true;
        this.userData = this.userIds.filter(
          (user) => user.account_id === this.user['account_id']
        );
        if (this.userData && this.userData[0] && this.userData[0]['account_id']) {
          this.localstorage.setItem('user', JSON.stringify(this.userDetail));
          this.localstorage.setItem('userData', JSON.stringify(this.userData[0]));
          if (this.isVehicleSelected) {
            this.BindPageList();
          }
          else {
            this.servicecall = true;
            this.checkVehicleList();
          }
          this.toastrService.success('Login Success.');
          // this.GetAddress();
          this.btndisabled = false;
          if (this.modalRef1) {
            this.modalRef1.hide();
          }
        }
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'PageLoginComponent',
        method_name: 'setUser()',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  bindUserType(event) {
    this.registrationForm.controls['user_type'].setValue(event);
    this.isOtpGenerated = true;
    this.showVehicleDetails = false;
    this.modalRef2.hide();
  }

  alphanumbericOnly(event): boolean {
    var inp = String.fromCharCode(event.keyCode);
    if ( (/[a-zA-Z0-9-]/.test(inp)) || ( ( event.target.value.length > 0 && event.keyCode == 32 ) ) ) {
      return true;
    } else {
      this.toastrService.error("space not allowed as first character")
      event.preventDefault();
      return false;
    }
  }

  onlyNumberKey(event) {
    var ASCIICode = event.which ? event.which : event.keyCode;
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) return false;
    return true;
  }
  async checkVehicleList() {
    this.showLoader = true;
    if (this.servicecall) {
      await this.userService.VechileListWithoutLogin({ "account_pk": this.account_pk }).subscribe(
        response => {
          this.isVehicleListPresent = false;
          if (response.data?.length > 0) {
            for (let i = 0; i < response?.data?.length; i++) {
              if (response.data[i].count > 0) {
                this.isVehicleListPresent = true;
                break;
              }
            }
            if (this.isVehicleListPresent) {
              this.showVehicleDetails = true;
              this.isOtpGenerated = false;
              this.showLoader = false;
            }
            else {
              this.BindPageList();
              this.AddVehicleCheck();
            }
            // this.servicecall=false;

          }
          else {
            this.servicecall = false;
            this.BindPageList();
            this.AddVehicleCheck();
          }
        }, error => {
          this.servicecall = false;
          this.BindPageList();
          this.AddVehicleCheck();
        })
    }
  }

  async AddVehicleCheck() {
    try {
      await this.userService.AddVehicleCheck({ "account_pk": this.account_pk }).subscribe(response => {
        if (response.success) {
          this.vehicleinfo = response.data;
        } else {
          // if(response?.data?.msg){
          //   this.toastrService.error(response.data.msg);
          // }
        }
        this.localstorage.setItem('isVehicleSelected', JSON.stringify(true));
      }, error => {
        this.localstorage.setItem('isVehicleSelected', JSON.stringify(true));
      })
    }
    catch (error) {

    }
  }

  goBackToHome() {
    this.router.navigate(['']);
    this.localstorage.removeitems("cartForCheckout");
    this.localstorage.removeitems("cartIndex");
    this.localstorage.removeitems("cartQueryParams");
    this.localstorage.removeitems("cartSummaryQueryParams");
  }

  goBackToGuestLogin(val){
    this.isForgottenUser = val;
    this.stringParameters = "";
    this.searchValueParam = "";
    this.relevantMobileNumbers="";
  }

  sendOtpForGuestLogin(){
    this.guestLoginForm.markAllAsTouched();
    if(this.guestLoginForm.invalid){
      return;
    }
    let requestBody = {
      contact_no: this.guestLoginForm.controls.contact_no.value,
      is_guest_user: true
    }
    this.authService.UserRegistration(requestBody).subscribe((response:any) => {
      if(response.success){
        this.isOtpGenerated = true;
        this.toastrService.success('OTP sent on your mobile number');
        this.eventsSubject.next();
        this.isTimer = true;
        this.guestLoginForm.controls.otp_number.reset();
        this.guestLoginForm.controls.password.reset();
        this.guestLoginForm.controls.confirm_password.reset();
        this.guestLoginForm.controls.otp_number.setValidators(Validators.required);
        this.guestLoginForm.controls.password.setValidators(Validators.required);
        this.guestLoginForm.controls.confirm_password.setValidators(Validators.required);
      }
      else{
        if(response?.data?.msg){
          this.toastrService.error(response?.data?.msg);
        }
      }
    },
    error => {
      if(error?.error?.data?.msg){
        this.toastrService.error(error?.error?.data?.msg);
      }
    })
  }

  async getStates() {
    try {
      this.districtList = [];
      this.pincodeList = [];
      this.talukaList = [];
      this.cityList = [];
      const ListGeostateInput: GetModalAddressparameter =
        {} as GetModalAddressparameter;
      ListGeostateInput.offset = 0;
      ListGeostateInput.limit = 10000;
      ListGeostateInput.dropdown_type = 'state';
      ListGeostateInput.multi_city = [];
      ListGeostateInput.multi_pincode = [];
      let stateresponse = await this.addressService.getGeoData(ListGeostateInput);
      if (stateresponse['success'] === true) {
        let stategeodata = stateresponse['data']['States'];
        this.stateList = [];
        for (var i = 0; i < stategeodata.length; i++) {
          if (
            stategeodata[i].state_name != null &&
            stategeodata[i].state_name != undefined &&
            stategeodata[i].state_name != JSON.stringify({}) &&
            stategeodata[i].state_name != ''
          ) {
            this.stateList.push(this.formData(stategeodata[i]));
          }
        }
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'GuestLogin',
        method_name: 'getStates()',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  formData(data: any): StateData {
    const objstateData: StateData = {} as StateData;
    objstateData.code = data.state_code;
    objstateData.name = data.state_name;
    return objstateData;
  }

  getDataBasedOnPincode(){
    try {
        if(this.guestLoginForm.controls['postal_code'].value >= 100000){
          let requestBody = {
            dropdown_type: "get_data_by_pincode",
            multi_district: [],
            multi_taluka: [],
            multi_city: [],
            multi_pincode: [this.guestLoginForm.controls['postal_code'].value],
            offset: 0,
            limit: 10000
          }   
          this.addressService.addAddressByPincode(requestBody).subscribe((response : any) => {
            if (response['success']) {
              let addressDetails = response.data.data_by_pincode[0];
              this.guestLoginForm.patchValue({
                state: addressDetails?.state,
                city: addressDetails?.city_name,
                district: addressDetails?.district_name,
                taluka: addressDetails?.taluka_name
              });
              this.districtList = [];
              this.cityList = [];
              this.talukaList = [];
              this.pincodeList = [];
              this.districtList.push(addressDetails?.district_name);
              this.cityList.push(addressDetails?.city_name);
              if(addressDetails?.taluka_name != null && addressDetails?.taluka_name != undefined && addressDetails?.taluka_name != ""){
                this.talukaList.push(addressDetails?.taluka_name);
              }
            }
            else {
              if(response['data']?.msg){
                this.toastrService.error(response['data']?.msg);
              }
            }
          },error => {
            if(error?.error?.data?.msg){
              this.toastrService.error(error?.error?.data?.msg);
            }
          })
        }
    }catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'getDataBasedOnPincode()',
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'RegidtrationComponent',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  async getDistricts(selectedStateCode) {
    try {
        this.guestLoginForm.controls.district.reset();
        this.guestLoginForm.controls.city.reset();
        this.guestLoginForm.controls.taluka.reset();
        this.guestLoginForm.controls.postal_code.reset();
        this.districtList = [];
        this.pincodeList = [];
        this.talukaList = [];
        this.cityList = [];
        const ListGeodistrictInput: GetModalAddressparameter = {} as GetModalAddressparameter;
        ListGeodistrictInput.offset = 0;
        ListGeodistrictInput.limit = 10000;
        ListGeodistrictInput.dropdown_type = 'district';
        ListGeodistrictInput.multi_state = [selectedStateCode.value];
        ListGeodistrictInput.multi_city = [];
        ListGeodistrictInput.multi_pincode = [];
        let districtresponse = await this.addressService.getGeoData(ListGeodistrictInput);
        if (districtresponse['success'] === true) {
          let districtgeodata = districtresponse['data']['Districts'];
          this.districtList = [];
          for (var j = 0; j < districtgeodata.length; j++) {
            if (
              districtgeodata[j].district_name != null &&
              districtgeodata[j].district_name != undefined &&
              districtgeodata[j].district_name != JSON.stringify({}) &&
              districtgeodata[j].district_name != ''
            ) {
              this.districtList.push(districtgeodata[j].district_name);
            }
          }
        }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'getDistricts()',
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'Guest Login',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  async getCities(selectedDistrict) {
    try {
      this.guestLoginForm.controls.city.reset();
      this.guestLoginForm.controls.taluka.reset();
      this.guestLoginForm.controls.postal_code.reset();
      this.pincodeList = [];
      this.talukaList = [];
      this.cityList = [];
      const ListGeoCityInput: GetModalAddressparameter = {} as GetModalAddressparameter;
      ListGeoCityInput.offset = 0;
      ListGeoCityInput.limit = 10000;
      ListGeoCityInput.dropdown_type = 'city';
      ListGeoCityInput.multi_state = [this.guestLoginForm.controls.state.value];
      ListGeoCityInput.multi_city = [];
      ListGeoCityInput.multi_district = [selectedDistrict.value];
      ListGeoCityInput.multi_pincode = [];
      let citygeodata;
      let cityresponse = await this.addressService.getGeoData(ListGeoCityInput);
      if (cityresponse['success'] === true) {
        citygeodata = cityresponse['data']['Cities'];
        this.cityList = [];
        for (var j = 0; j < citygeodata.length; j++) {
          if (
            citygeodata[j].city_name != null &&
            citygeodata[j].city_name != undefined &&
            citygeodata[j].city_name != JSON.stringify({}) &&
            citygeodata[j].city_name != ''
          ) {
            this.cityList.push(citygeodata[j].city_name);
          }
        }
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'getCities()',
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'GuestLogin',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  async getTalukas(selectedCity) {
    try {
      this.guestLoginForm.controls.taluka.reset();
      this.guestLoginForm.controls.postal_code.reset();
      this.pincodeList = [];
      this.talukaList = [];
      const ListGeotalukaInput: GetModalAddressparameter = {} as GetModalAddressparameter;
      ListGeotalukaInput.offset = 0;
      ListGeotalukaInput.limit = 10000;
      ListGeotalukaInput.dropdown_type = 'taluka';
      ListGeotalukaInput.multi_state = [this.guestLoginForm.controls.state.value];
      ListGeotalukaInput.multi_district = [this.guestLoginForm.controls.district.value];
      ListGeotalukaInput.multi_city = [selectedCity.value];
      ListGeotalukaInput.multi_pincode = [];
      let Talukageodata;
      let talukaresponse = await this.addressService.getGeoData(
        ListGeotalukaInput
      );
      if (talukaresponse['success'] === true) {
        Talukageodata = talukaresponse['data']['Talukas'];
        this.talukaList = [];
        for (var j = 0; j < Talukageodata.length; j++) {
          if (
            Talukageodata[j].taluka_name != null &&
            Talukageodata[j].taluka_name != undefined &&
            Talukageodata[j].taluka_name != JSON.stringify({}) &&
            Talukageodata[j].taluka_name != ''
          ) {
            this.talukaList.push(Talukageodata[j].taluka_name);
          }
        }
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'getTalukas()',
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'Guest Login',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  async getPincodes(selectedTaluka) {
    try {
      this.guestLoginForm.controls.postal_code.reset();
      this.pincodeList = [];
      const ListGeoPincodeInput: GetModalAddressparameter = {} as GetModalAddressparameter;
      ListGeoPincodeInput.offset = 0;
      ListGeoPincodeInput.limit = 10000;
      ListGeoPincodeInput.dropdown_type = 'pincode';
      ListGeoPincodeInput.multi_state = [this.guestLoginForm.controls.state.value];
      ListGeoPincodeInput.multi_district = [this.guestLoginForm.controls.district.value];
      ListGeoPincodeInput.multi_city = [this.guestLoginForm.controls.city.value];
      ListGeoPincodeInput.multi_taluka = [selectedTaluka.value];
      ListGeoPincodeInput.multi_pincode = [];
      let pincodegeodata;
      let pincoderesponse = await this.addressService.getGeoData(ListGeoPincodeInput);
      if (pincoderesponse['success'] === true) {
        pincodegeodata = pincoderesponse['data']['Pincodes'];
        this.pincodeList = [];
        for (var j = 0; j < pincodegeodata.length; j++) {
          if (
            pincodegeodata[j].pincode != null &&
            pincodegeodata[j].pincode != undefined &&
            pincodegeodata[j].pincode != JSON.stringify({}) &&
            pincodegeodata[j].pincode != ''
          ) {
            this.pincodeList.push(pincodegeodata[j].pincode);
          }
        }
        this.pincodeList.length == 1?this.guestLoginForm.controls['postal_code'].setValue(this.pincodeList[0]):'';
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'getPincodes()',
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'Guest Login',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  changeInParameters(parameter){
    this.searchByParameters[parameter] = !this.searchByParameters[parameter];
    if(!this.searchByParameters[parameter]){
      this.stringParameters[parameter] = '';
    }
  }

  checkForRegisteredUser(){
    try{
      this.showLoader =true;
      if( ( !this.stringParameters || this.stringParameters =="" )  || (this.searchValueParam !='registration_no' && this.searchValueParam != 'chassis_no' && this.searchValueParam !='crn' && this.searchValueParam !='arn') ){
        (this.searchValueParam !='registration_no' && this.searchValueParam != 'chassis_no' && this.searchValueParam !='crn' && this.searchValueParam !='arn')
        ?this.toastrService.error("Please select value for search")
        :this.toastrService.error("Please enter value for all selected search parameters");
        this.showLoader = false;
        return;
      }
      
      let requestBody = {
          chassis_no:this.searchValueParam == 'chassis_no'?this.stringParameters:"",
          registration_no:this.searchValueParam =='registration_no'?this.stringParameters:"",
          crn:this.searchValueParam =='crn'?this.stringParameters:"",
          arn:this.searchValueParam =='arn'?this.stringParameters:""
      }
      this.authService.checkForRegisteredUser(requestBody).subscribe(response => {
        this.showLoader = false;
        if(response.success){
          this.relevantMobileNumbers = response.data;
          console.log(this.relevantMobileNumbers);
        } else{
            if(response?.data?.msg){
              this.toastrService.error(response?.data?.msg);
            }
          }
        },error => {
          this.showLoader = false;
          if(error?.error?.data?.msg){
            this.toastrService.error(error?.error?.data?.msg);
          }
      })

    }catch(error){

    }
    
    
    // let isErrorFound: boolean  = false;
    // for(let x in this.searchByParameters){
    //   if(this.searchByParameters[x] === true && (this.stringParameters[x] === undefined || this.stringParameters[x] === null || this.stringParameters[x] === '')){
    //     this.toastrService.error("Please enter value for all selected search parameters");
    //     isErrorFound = true;
    //     return;
    //   }
    // }
    // if(isErrorFound){
    //   return;
    // }
    // let requestBody = {
    //   chassis_no: this.stringParameters['isChassis'],
    //   registration_no: this.stringParameters['isReg'],
    //   crn: this.stringParameters['isCRN'],
    //   arn: this.stringParameters['isARN']
    // }
    // this.authService.checkForRegisteredUser(requestBody).subscribe(response => {
    //   if(response.success){
    //     this.relevantMobileNumbers = response.data;
    //     const registeredPhoneNumberModal = this.dialog.open(RegisteredPhoneNumberComponent,{
    //       width: '400px',
    //       height: '400px',
    //       hasBackdrop: false,
    //       data: this.relevantMobileNumbers
    //     });
    //     registeredPhoneNumberModal.afterClosed().subscribe(result => {
    //       if(result !== undefined && result !== null && result !== ''){
    //         let selectedNumber = null;
    //         for(let y in this.relevantMobileNumbers){
    //           if(this.relevantMobileNumbers[y] === result){
    //             selectedNumber = result;
    //             this.registrationForm.reset();
    //             this.registrationForm.controls.contact_no.setValue(selectedNumber);
    //             this.onSubmitOTP(null, true);
    //           }
    //         }
    //       }
    //     })
    //   }
    //   else{
    //     if(response?.data?.msg){
    //       this.toastrService.error(response?.data?.msg);
    //     }
    //   }
    // },error => {
    //   if(error?.error?.data?.msg){
    //     this.toastrService.error(error?.error?.data?.msg);
    //   }
    // });
  }
  getSearchParameter(event){
    this.searchValueParam = event.value;
    this.phoneNoSearchParam = 
    event.value=="chassis_no" ? "Enter Chassis Number"
    :event.value == "registration_no" ? "Enter Registration No"
    :event.value  == "arn" ? "Enter ARN No"
    :event.value == "crn" ? "Enter CRN No":"";
    this.stringParameters = "";
  }
  
}


export class GetModalAddressparameter {
  dropdown_type: string;
  multi_state: any;
  multi_district: any;
  multi_taluka: any;
  multi_city: any;
  multi_pincode: any;
  search_city: string;
  offset: any;
  limit: any;
}

export class StateData {
  code: string;
  name: string;
}
