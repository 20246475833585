<div class="container-fluid orders">
    <div class="row">
        <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 pb-3 pt-3">
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                    <ul class="nav nav-tabs tabs1" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <h5 class="profile-heading">Bulk Order Upload</h5>
                        </li>
                    </ul>
                   
                    <div class="tab-content" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="nav-Items" role="tabpanel"
                            aria-labelledby="nav-Items-tab">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <div>
                                                        <p>{{fileName}}</p>
                                                        <label>
                                                          
                                                            <input #inputFile type="file" onclick="this.value = null"
                                                                (change)="SelectDocumentFiles($event)" style="display:none" />
                                                            <a mat-raised-button color="primary" (click)="checkPermission()">
                                                                <mat-icon>file_upload</mat-icon>
                                                                Upload
                                                            </a>
                                                            
                                                            <button class="btn btn-primary ms-2" (click)="ShowPart()">Submit</button>
                                                        </label>
                                                    </div>
                                                    <div class="text-end">
                                                        <b style="color: red;">Instruction:</b>
                                                        <br />1.Download Template from <a style="color: blue;"
                                                            href="./assets/Samples/Bulk_Order_Upload.xlsx">here</a>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <b style="color: red;">Instructions for bulk order uploading:</b><br>
                                                        <h5 class="font-text">
                                                            <strong>
                                                                <span class="error-message">**</span>
                                                                Maximum 50 part line items can be uploaded per cart
                                                            </strong>
                                                          </h5>
                                                        <a href="./assets/Samples/new-bulk-order-image.png" target="_blank"><img
                                                                src="./assets/Samples/new-bulk-order-image.png" style="width: 100%;" alt=""
                                                                class="size"></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #uploadmodal let-d="dismiss">
    <div class="modal-header">
        <h6 class="modal-title">Please open the downloaded file to review the parts that were not uploaded with a
            reason.</h6>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="DownloadFile()">
            &nbsp;OK</button>
    </div>
</ng-template>



<ng-template #confimationmodal let-d="dismiss">
    <app-selectCartType [cartTypeList]="cartTypeList" (closeModal)="modalRef1.hide()" 
    (selectedCartType)="createExcel($event)" [isBulkUpload]="isBulkUpload" [fromPath]="bulkUpload" [btnshowConfrimed]="btnshowConfrimed"></app-selectCartType>
</ng-template>

<ng-template #buyAgain let-d="dismiss">
    <app-bulk-upload-part-details [parts]="buyAgainData" [invalidParts]="invalidParts"  (closemodal)="buyAgainModal.hide();" (submitData)="addToCart($event);buyAgainModal.hide();" [userDetail]="user"></app-bulk-upload-part-details>
  </ng-template>

<div class="loader" *ngIf="showLoader">
    <div class="ng-spinner ng-spinner-icon"></div>
</div>