import { Component, OnInit, Input, HostListener, ChangeDetectorRef, OnChanges, SimpleChanges, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { ProductService } from "../../../../shared/services/REST/product.service";
import { ActivatedRoute, Params } from '@angular/router';
import { GoogleAnalyticsService } from '../../../../../../src/app/shared/services/GoogleAnalytics/GoogleAnalytics.service';
import { Router } from '@angular/router';
import { UserService } from '../../../../../../src/app/shared/services/REST/user.service';
import { MenuService } from '../../../../../../src/app/shared/services/REST/menu.service';
import { ToastrService } from 'ngx-toastr';
import { Inputdata, InputPartSearch } from '../../../../../../src/app/shared/interfaces/product';
import { ModelService } from '../../../../../../src/app/shared/services/REST/model.service';
import { AppStateService } from '../../../../../../src/app/shared/services/app-state/app-state.service';
import { secureStorage } from '../../../../../../src/app/shared/services/securestorage';
import { BsModalService } from "ngx-bootstrap/modal";
import { Title } from '@angular/platform-browser';
declare var $: any;
@Component({
  selector: 'app-productcard',
  templateUrl: './productcard.component.html',
  styleUrls: ['./productcard.component.scss'],
})
export class ProductcardComponent implements OnInit, OnChanges, OnDestroy {
  searchuserData: any = '';
  totalResult: any = 0;
  kitName: any;
  loading: boolean = false;
  isLastPage: boolean = false;
  searchselectesubdModel: any;
  prodstart: any;
  prodend: any;
  aggregateData = [];
  IsAggregateApiLoaded: boolean = false;
  searchselecteaggregate: any;
  aggregateSelectedData = [];
  showMoreaggregate: boolean = false;
  submodelData = [];
  subModelSelectedData = [];
  showMoreModel: boolean = false;
  showMoreSubModel: boolean = false;
  modelData = [];
  BomIdData: any;
  FinalBomData: any;
  ProductListData: any;
  BomArrayNo: any;
  selectedModel: any;
  searchselectedModel: any;
  ModelSelectedData = [];
  address_pk: any;
  account_pk: any;
  featuredProducts1 = [];
  featuredProducts2 = [];
  featuredProducts: any = [];
  searchBy: string;
  page: any = 0;
  result: boolean;
  productFilterData: any[];
  showPagination: boolean = false;
  pagestart: any;
  isApplyFilter: boolean = false;
  filterArr = [];
  productList;
  kit_parts: boolean = true;
  durafit_parts: boolean = false;
  category_type: any;
  search_text: any;
  partnumberlastfour: any;
  searchValue: string;
  aggType: any;
  categoryType: string;
  halfProductsList: any[];
  isPartCategoryApiLoaded: boolean = false;
  AllProductsList = [];
  halfProducts: any[];
  AllProducts: any[];
  showMore: boolean = false;
  selectedPgLine: any;
  pgline = [];
  dispgroup = [];
  basemodel = [];
  Mainmodel = '';
  showLoader: boolean = false;
  shippingaddressid: any;
  billingaddressid: any;
  searchPgLine = '';
  isLoggedIn: boolean = false;
  layout: string = 'Grid';
  isNoDataFound: boolean = false;
  isViewAll: boolean = false;
  menuHeadName;
  displayname;
  pageHeader;
  breadcrumbs;
  ModelNameagg: any;
  breadcrumbs1;
  bomIdLimit: number = 0;
  submodelFromHeader: string = "";
  activeVcNo: any;
  isModelApiLoaded: boolean = false;
  isSubmodelApiLoaded: boolean = false;
  headerSearchText: any;
  isMenuModelViewAll: boolean = false;
  @ViewChild('aggregatesInPartList', { read: TemplateRef, static: false })
  aggregatesInPartList: TemplateRef<any>;
  aggregatesInPartListModal: any;
  subAggregatesList: any[] = [];
  selectedSubAggregate: any;
  subAggregateForZoomView: any;
  imageZoomViewModal: any;
  BomPageStart: any = 0;
  BomPageEnd: any;
  isPgLineDiscount: boolean = false;
  pincode: any;
  userData:any
  bannerId: any = '';
  bannerTitle:any;
  constructor(
    private productService: ProductService,
    private appStateService: AppStateService,
    private route: ActivatedRoute,
    private googleAnlyatics: GoogleAnalyticsService,
    private router: Router,
    public userService: UserService,
    private menu: MenuService,
    public modelService: ModelService,
    private toastrService: ToastrService,
    private modalService: BsModalService,
    private cd: ChangeDetectorRef,
    private localstorage: secureStorage,
    private title:Title
  ) {     this.pincode = JSON.parse(this.localstorage.getItem('Pincode'));
}

  ngOnInit(): void {
    this.userData = JSON.parse(this.localstorage.getItem('userData'));
    $(function () {
      $('.filter-btn').on('click', function () {
        $('.filter-fixed-sm').toggleClass('show');
      });
      $('.close-filter').on('click', function () {
        $('.filter-fixed-sm').removeClass('show');
      })
    });
    this.appStateService.clearHeaderTopSearch.next(true);
    this.appStateService.isLoggedIn.subscribe((isLog) => {
      this.isLoggedIn = isLog;
    });
    this.breadcrumbs = JSON.parse(this.localstorage.getItem('productcardBreadcrumb'));
    this.cd.markForCheck();
    this.searchuserData = '';
    this.searchValue = '';
    this.route.queryParams.subscribe((params) => {
      this.localstorage.setItem("CurrentListQueryParam", JSON.stringify(params));
      this.breadcrumbs = JSON.parse(this.localstorage.getItem('productcardBreadcrumb'));
      this.selectedSubAggregate = "";
      this.activeVcNo = "";
      this.headerSearchText = "";
      this.subModelSelectedData = [];
      this.ModelSelectedData = [];
      this.bannerTitle = "";
      document.body.scrollTop = 0;
      window.scrollTo(0, 0);
      this.searchBy = params['searchBy'];
      this.isPgLineDiscount = params['pgLineDiscount'];
      this.searchValue = params['search'];
      this.categoryType = params['type'];
      this.kitName = params['kitName'];
      this.aggType = params['aggtype'];
      this.ModelNameagg = params['ModelName'];
      this.isMenuModelViewAll = params['isMenuModelViewAll'] === 'true' ? true : false;
      this.isViewAll = params['isViewAll'] == 'true' ? true : false;
      this.bannerId = params['bannerId'];
      if (params["headerSearchText"] != undefined) {
        this.headerSearchText = params["headerSearchText"]
      }
      if (params['subModelName']) {
        this.subModelSelectedData.push(params['subModelName']);
      }
      if (params['baseModel']) {
        this.subModelSelectedData.push(params['baseModel']);
      }
      if (params['vcNo'] != "" && params['vcNo'] != undefined && params['vcNo'] != null) {
        this.activeVcNo = params['vcNo'];
      }
      if (params['ModelName']) {
        this.ModelSelectedData.push(this.ModelNameagg);
      }
      if (this.aggType === 'aggregate') {
        this.searchValue = this.searchBy;
        this.aggregateSelectedData = [];
        if (this.searchBy != '' && this.searchBy != undefined && this.searchBy != null) {
          this.aggregateSelectedData.push(this.searchBy);
        }
      }
      this.modelData = [];
      this.submodelData = [];
      this.ProductListData = [];
      this.isNoDataFound = false;
      this.totalResult = 0;
      this.ProductListData = [];
      this.page = 0;
      this.selectedPgLine = '';
      this.showMore = false;
      this.totalResult = 0;
      this.FinalBomData = [];
      this.prodstart = 0;
      this.prodend = 0
      this.getAllPartCategories();
      this.ModelCategory();
      this.GetSubModelDetail(this.ModelNameagg ? [this.ModelNameagg] : [], 0);
      this.BindAggregate();
      this.setheader();
      if (params['inListSearchText'] != undefined && params['isSecondCall'] == 'true') {
        this.searchuserData = params['inListSearchText'];
        if (this.aggType === 'aggregate') {
          this.GetBomDetail(this.ModelSelectedData, 0);
        }
        else if (this.categoryType === 'Model') {
          this.GetBomDetail(this.ModelSelectedData, 0);
        }
        else if (this.categoryType === 'PARTS CATEGORIES' && this.searchBy) {
          let objpgline = { LINE_2_s: this.searchBy };
          this.pgline.push(objpgline);
          this.bindpgProduct([], 0);
        }
        else {
          this.bindpgProduct([], 0);
        }
      }
      if (params['isSecondCall'] == undefined) {
        this.pgline = [];
        this.getProducts(this.page, false);
      }
    });
    // this.appStateService.refreshTimelineList.next(null);
  }

  ngOnChanges(changes: SimpleChanges) {
    let newbread = JSON.parse(
      this.localstorage.getItem('productcardBreadcrumb')
    );
    // this.appStateService.refreshTimelineList.next(null);
    this.cd.markForCheck();
  }

  setheader() {
    if (this.categoryType === 'PARTS CATEGORIES') {
      this.menuHeadName = this.searchBy;
      this.displayname = this.categoryType;
    } else {
      this.displayname = this.searchBy;
    }
    if (this.displayname === 'mhv') {
      this.pageHeader = 'MHCV Parts';
    } else if (this.displayname === 'slv') {
      this.pageHeader = 'SLCV Parts';
    } else if (this.displayname === 'durafit') {
      this.pageHeader = 'Durafit';
    } else if (this.displayname === 'kit') {
      this.pageHeader = 'Kit';
    } else if (this.displayname === 'oillubricants') {
      // this.pageHeader = 'OIL & GREASE';
      this.pageHeader = 'OIL, GREASE & DEF';
    } else if (this.displayname === 'TRUCKS') {
      this.pageHeader = 'Trucks';
    } else if (this.displayname === 'BUSES') {
      this.pageHeader = 'Buses';
    } else if (this.displayname === 'PARTS CATEGORIES') {
      this.pageHeader = 'Parts Categories';
    } else if (this.displayname === 'modelName') {
      this.pageHeader = 'Trucks & Buses Parts';
    } else {
      this.pageHeader = 'Parts';
    }
  }

  getProducts(page, isLeftFilterRemoved) {
    try {
      this.isNoDataFound = false;
      // document.body.scrollTop = 0;
      // window.scrollTo(0, 0);
      let user = JSON.parse(this.localstorage.getItem('userData'));
      let address = JSON.parse(this.localstorage.getItem('address'));
      if (user && address) {
        this.account_pk = +JSON.stringify(user.account_pk);
        let address = JSON.parse(this.localstorage.getItem('address'));
        let billingaddress = JSON.parse(
          this.localstorage.getItem('billingAddress')
        );
        var addresstypecheck = address?.address['default'];
        if (addresstypecheck === true) {
          this.shippingaddressid = 'default';
        } else {
          this.shippingaddressid = address?.address['address_id'];
        }
        var Billingaddresscheck = billingaddress?.address['default'];
        if (Billingaddresscheck === true) {
          this.billingaddressid = 'default';
        } else {
          this.billingaddressid = billingaddress?.address['address_id'];
        }
      }
      this.localstorage.setItem('page', this.searchBy);
      this.searchuserData = '';
      let data = {};
      // this.productList = [];
      if (this.searchBy === 'durafit') {
        let searchData = {};
        if (user && address) {
          searchData = {
            search_text: '',
            bs_model_name: '',
            pg_line: '',
            multi_pg_line: [],
            multi_bs_model_name: [],
            multi_dispgroup_name: [],
            model_cat: '',
            mod_uid: '',
            category_type: '',
            bom_id: '',
            durafit_parts: true,
            kit_parts: false,
            part_number_last_four: '',
            offset: page,
            // start_row:0,
            limit: 20,
            pl_id: '',
            //is_discount_needed: true,
            account_pk: this.account_pk,
            //address_pk: this.address_pk,
            shipping_address_id: this.shippingaddressid,
            part_number: '',
            is_for_epc_data: false,
            is_for_discount: true,
            is_for_ecom_detail: false,
            is_for_inventory : this.userData?.user_type == 'MECH' ? true: false
          };
        } else {
          searchData = {
            search_text: '',
            bs_model_name: '',
            pg_line: '',
            multi_pg_line: [],
            multi_bs_model_name: [],
            multi_dispgroup_name: [],
            model_cat: '',
            mod_uid: '',
            category_type: '',
            bom_id: '',
            durafit_parts: true,
            kit_parts: false,
            part_number_last_four: '',
            offset: page,
            // start_row:0,
            limit: 20,
            pl_id: '',
            is_for_inventory :this.userData?.user_type == 'MECH' ? true: false
          };
        }
        this.result = false;
        this.showLoader = true;
        if(this.pincode && (this.pincode != (address!=null?address["address"].postal_code : ""))){
          searchData["is_pincode_based_division"] = true;
          searchData["pincode"] = this.pincode ;
          searchData["is_for_discount"] = true;
        }        
        this.productService.get_part_search_md(searchData).subscribe(
          (response) => {
            this.productFilterData = [];
            this.beforecall();

            if (response.success) {
              this.commonResponse(page, response);
              this.GoogleAnalyticsUserDetails('View_all_Parts_web', 'Durafit', 'viewAllParts');
              this.showLoader = false;
            } else {
              this.aftercall();
              this.GoogleAnalyticsUserDetails('View_all_Parts_Fail_web', 'Durafit', 'viewAllPartsFail');
            }
          },
          (error) => {
            this.beforecall();
          }
        );
      }
      if (this.searchBy === 'kit') {
        let searchData = {};
        if (user && address) {
          searchData = {
            search_text: '',
            bs_model_name: '',
            pg_line: '',
            multi_pg_line: [],
            multi_bs_model_name: [],
            multi_dispgroup_name: [],
            model_cat: '',
            mod_uid: '',
            category_type: '',
            bom_id: '',
            durafit_parts: false,
            kit_parts: true,
            part_number_last_four: '',
            offset: page,
            // start_row:0,
            limit: 20,
            pl_id: '',
            // is_discount_needed: true,
            account_pk: this.account_pk,
            address_pk: this.address_pk,

            shipping_address_id: this.shippingaddressid,
            part_number: '',
            is_for_epc_data: false,
            is_for_discount: true,
            is_for_ecom_detail: false,
            is_for_inventory : this.userData?.user_type == 'MECH' ? true: false
          };
        } else {
          searchData = {
            search_text: '',
            bs_model_name: '',
            pg_line: '',
            multi_pg_line: [],
            multi_bs_model_name: [],
            multi_dispgroup_name: [],
            model_cat: '',
            mod_uid: '',
            category_type: '',
            bom_id: '',
            durafit_parts: false,
            kit_parts: true,
            part_number_last_four: '',
            offset: page,
            // start_row:0,
            limit: 20,
            pl_id: '',
            is_for_inventory :this.userData?.user_type == 'MECH' ? true: false
          };
        }
        this.result = false;
        this.showLoader = true;
        if(this.pincode && (this.pincode != (address!=null?address["address"].postal_code : ""))){
          searchData["is_pincode_based_division"] = true;
          searchData["pincode"] = this.pincode ;
          searchData["is_for_discount"] = true;
        }  
        this.productService.get_part_search_md(searchData).subscribe(
         (response) => {
            this.beforecall();
            if (response.success) {
              this.commonResponse(page, response);
              this.GoogleAnalyticsUserDetails('View_all_Parts_web', 'Kit', 'viewAllParts');
              this.showLoader = false;
            } else {
              this.aftercall();
              this.GoogleAnalyticsUserDetails('View_all_Parts_Fail_web', 'Kit', 'viewAllPartsFail');
              this.toastrService.error(response.data.msg);
            }
          },
          (error) => {
            this.beforecall();
          }
        );
      }
      if (this.searchBy === 'prolife') {
        let prolifeData = {};
        if (user && address) {
          prolifeData = {
            search_text: '',
            bs_model_name: '',
            pg_line: '',
            multi_pg_line: [],
            multi_bs_model_name: [],
            pg: '',
            multi_dispgroup_name: [],
            model_cat: '',
            mod_uid: '',
            category_type: '',
            bom_id: '',
            durafit_parts: false,
            kit_parts: false,
            prolife_parts: true,
            part_number_last_four: '',
            offset: page,
            size: 20,
            //  is_discount_needed: true,
            account_pk: this.account_pk,
            // address_pk: this.address_pk,
            shipping_address_id: this.shippingaddressid,
            part_number: '',
            is_for_epc_data: false,
            is_for_discount: true,
            is_for_ecom_detail: false,
            is_for_inventory : this.userData?.user_type == 'MECH' ? true: false
          };
        } else {
          prolifeData = {
            search_text: '',
            bs_model_name: '',
            pg_line: '',
            multi_pg_line: [],
            multi_bs_model_name: [],
            pg: '',
            multi_dispgroup_name: [],
            model_cat: '',
            mod_uid: '',
            category_type: '',
            bom_id: '',
            durafit_parts: false,
            kit_parts: false,
            prolife_parts: true,
            part_number_last_four: '',
            offset: page,
            size: 20,
            pl_id: '',
            is_for_inventory : this.userData?.user_type == 'MECH' ? true: false
          };
        }
        this.result = false;
        this.showLoader = true;
        if(this.pincode && (this.pincode != (address!=null?address["address"].postal_code : ""))){
          prolifeData["is_pincode_based_division"] = true;
          prolifeData["pincode"] = this.pincode ;
          prolifeData["is_for_discount"] = true;
        }        
        this.productService.get_part_search_md(prolifeData).subscribe(
          (response) => {
            this.result = true;
            this.loading = false;
            this.showLoader = false;
            this.beforecall();
            if (response.success) {

              this.localstorage.setItem('pgdata', JSON.stringify(response.data));
              this.commonResponse(page, response);
              this.GoogleAnalyticsUserDetails('View_all_Parts_web', 'OIL & LUBRICANTS', 'viewAllParts');

              this.showLoader = false;
            } else {
              this.aftercall();
              this.GoogleAnalyticsUserDetails('View_all_Parts_Fail_web', 'OIL & LUBRICANTS', 'viewAllPartsFail');
              this.toastrService.error(response.data.msg);
            }
          },
          (error) => {
            this.beforecall();
          });
      }
      if (this.searchBy === 'oillubricants') {
        let oilLubricantData = {};
        if (user && address) {
          oilLubricantData = {
            search_text: '',
            bs_model_name: '',
            pg_line: '',
            multi_pg_line: [],
            multi_bs_model_name: [],
            pg: 'PG6',
            multi_dispgroup_name: [],
            model_cat: '',
            mod_uid: '',
            category_type: '',
            bom_id: '',
            durafit_parts: false,
            kit_parts: false,
            part_number_last_four: '',
            offset: page,
            // start_row:0,
            limit: 20,
            pl_id: '',
            //  is_discount_needed: true,
            account_pk: this.account_pk,
            // address_pk: this.address_pk,
            shipping_address_id: this.shippingaddressid,
            part_number: '',
            is_for_epc_data: false,
            is_for_discount: true,
            is_for_ecom_detail: false,
            is_for_inventory : this.userData?.user_type == 'MECH' ? true: false

          };
        } else {
          oilLubricantData = {
            search_text: '',
            bs_model_name: '',
            pg_line: '',
            multi_pg_line: [],
            multi_bs_model_name: [],
            pg: 'PG6',
            multi_dispgroup_name: [],
            model_cat: '',
            mod_uid: '',
            category_type: '',
            bom_id: '',
            durafit_parts: false,
            kit_parts: false,
            part_number_last_four: '',
            offset: page,
            // start_row:0,
            limit: 20,
            pl_id: '',
            is_for_inventory : this.userData?.user_type == 'MECH' ? true: false

          };
        }
        this.result = false;
        this.showLoader = true;
        if(this.pincode && (this.pincode != (address!=null?address["address"].postal_code : ""))){
          oilLubricantData["is_pincode_based_division"] = true;
          oilLubricantData["pincode"] = this.pincode ;
          oilLubricantData["is_for_discount"] = true;
        }        
        this.productService.get_part_search_md(oilLubricantData).subscribe(response => {
          this.beforecall();
          if (response.success) {
            this.localstorage.setItem('pgdata', JSON.stringify(response.data));
            this.commonResponse(page, response);
            this.GoogleAnalyticsUserDetails('View_all_Parts_web', 'OIL & LUBRICANTS', 'viewAllParts');
            this.showLoader = false;
          } else {
            this.aftercall();
            this.GoogleAnalyticsUserDetails('View_all_Parts_Fail_web', 'OIL & LUBRICANTS', 'viewAllPartsFail');
          }
        },
          (error) => {
            this.beforecall();
          });
      }
      if (this.searchBy == 'description') {
        let searchData = {};
        if (user && address) {
          searchData = {
            search_text: this.searchValue,
            bs_model_name: '',
            pg_line: '',
            multi_pg_line: [],
            multi_bs_model_name: [],
            multi_dispgroup_name: [],
            model_cat: '',
            mod_uid: '',
            category_type: '',
            bom_id: '',
            durafit_parts: false,
            kit_parts: false,
            part_number_last_four: '',
            offset: page,
            limit: 20,
            pl_id: '',
            // is_discount_needed: true,
            account_pk: this.account_pk,
            //  address_pk: this.address_pk,
            shipping_address_id: this.shippingaddressid,
            part_number: '',
            is_for_epc_data: false,
            is_for_discount: true,
            is_for_ecom_detail: false,
            is_for_inventory : this.userData?.user_type == 'MECH' ? true: false

          };
        } else {
          searchData = {
            search_text: this.searchValue,
            bs_model_name: '',
            pg_line: '',
            multi_pg_line: [],
            multi_bs_model_name: [],
            multi_dispgroup_name: [],
            model_cat: '',
            mod_uid: '',
            category_type: '',
            bom_id: '',
            durafit_parts: false,
            kit_parts: false,
            part_number_last_four: '',
            offset: page,
            limit: 20,
            pl_id: '',
            is_for_inventory : this.userData?.user_type == 'MECH' ? true: false

          };
          if(this.pincode && (this.pincode != (address!=null?address["address"].postal_code : ""))){
            searchData["is_pincode_based_division"] = true;
            searchData["pincode"] = this.pincode ;
            searchData["is_for_discount"] = true;
          }    
        }
        this.result = false;
        this.showLoader = true;
        this.productService.get_part_search_md(searchData).subscribe(
          (response) => {
            this.beforecall();
            if (response.success) {
              this.commonResponse(page, response); this.GoogleAnalyticsUserDetails('Description_Search_web', this.searchValue, 'descriptionSearch');
              this.showLoader = false;
            }
            else {
              this.GoogleAnalyticsUserDetails('Description_Search_Fail_web', this.searchValue, 'descriptionSearchFail');
              this.aftercall();
            }
          },
          (error) => {
            this.beforecall();
            this.toastrService.error(error.error.data.msg);
          });
      }
      // debugger
      if (this.searchBy == 'popularKits') {
        let searchData = {};
        let Gearbox_GB750 = [269126307906,269126307913,269126307914,269126305438,269126305431,269126305422,269126305427,269126305436,269126305419,269126305420,269126305421,269126305426,269126355408,269126355404,269126357902,269126250173,269126250160,269126250156,269126250140]
        let Gearbox_GB27 = [251626307906,251626307904,251626305408,251626305407,251626305410,251626305411,251626355405,251626357905,251626257904,251626250106,251626257906,251626257905,251626254601,251626207905,251626205420,251626200145,251626205421,251626205431,251626200176,251626200137,251626200138]
        let Gearbox_GB550 = [285526204607,580326201601,885426011109,885426025830,885426041109,285526307903,285526307905,285526307906,285526305401,285526305408,285526305423,285526305406,285526305427,285526305409,285526305407,285526305428,285526305410,285526305404]
        if(this.kitName == 'Gearbox GB750' || this.kitName == 'Gearbox GB27' || this.kitName == 'Gearbox GB550'){
          if (user && address) {
            searchData = {
              search_text: '',
              bs_model_name: '',
              pg_line: '',
              multi_pg_line: [],
              multi_bs_model_name: [],
              multi_dispgroup_name: [],
              model_cat: '',
              mod_uid: '',
              category_type: '',
              bom_id: '',
              durafit_parts: false,
              kit_parts: false,
              part_number_last_four: '',
              offset: page,
              limit: 20,
              pl_id: '',
              account_pk: user.account_pk,
              shipping_address_id: this.shippingaddressid,
              part_number: '',
              is_for_epc_data: false,
              is_for_discount: true,
              is_for_ecom_detail: false,
              part_number_list :this.kitName == "Gearbox GB750"?Gearbox_GB750:this.kitName == 'Gearbox GB27'?Gearbox_GB27:Gearbox_GB550
            };
          } else {
            searchData = {
              search_text: '',
              bs_model_name: '',
              pg_line: "",
              multi_pg_line: [],
              multi_bs_model_name: [],
              multi_dispgroup_name: [],
              model_cat: '',
              mod_uid: '',
              category_type: '',
              bom_id: '',
              durafit_parts: false,
              kit_parts: false,
              part_number_last_four: '',
              offset: page,
              limit: 20,
              pl_id: '',
              part_number_list :this.kitName == "Gearbox GB750"?Gearbox_GB750:this.kitName == 'Gearbox GB27'?Gearbox_GB27:Gearbox_GB550
            };
          }
        }
        else{
          if (user && address) {
            searchData = {
              search_text: '',
              bs_model_name: '',
              pg_line: this.kitName,
              multi_pg_line: [],
              multi_bs_model_name: [],
              multi_dispgroup_name: [],
              model_cat: '',
              mod_uid: '',
              category_type: '',
              bom_id: '',
              durafit_parts: false,
              kit_parts: true,
              part_number_last_four: '',
              offset: page,
              limit: 20,
              pl_id: '',
              account_pk: user.account_pk,
              shipping_address_id: this.shippingaddressid,
              part_number: '',
              is_for_epc_data: false,
              is_for_discount: true,
              is_for_ecom_detail: false,
            };
          } else {
            searchData = {
              search_text: '',
              bs_model_name: '',
              pg_line: this.kitName == 'Gearbox GB750' || this.kitName == 'Gearbox GB27' || this.kitName == 'Gearbox GB550' ? '' : this.kitName,
              multi_pg_line: [],
              multi_bs_model_name: [],
              multi_dispgroup_name: [],
              model_cat: '',
              mod_uid: '',
              category_type: '',
              bom_id: '',
              durafit_parts: false,
              kit_parts: true,
              part_number_last_four: '',
              offset: page,
              limit: 20,
              pl_id: '',
            };
          }
        }     
        this.showLoader = true;
        this.productService.get_part_search_md(searchData).subscribe(
          (response) => {
            this.beforecall();
            if (response.success) {
              this.commonResponse(page, response);
              this.GoogleAnalyticsUserDetails('model_Search_web', this.searchBy, 'modelSearch');
              this.showLoader = false;
            } else {
              this.aftercall();
              this.GoogleAnalyticsUserDetails('model_Search_Fail_web', this.searchBy, 'modelSearchFail');
            }
          },
          (error) => {
            this.beforecall();
            this.ProductListData = [];
          }
        );
      }
      if (this.searchBy == 'fast-consumables') {
        const ListPartInput: InputPartSearch = {} as InputPartSearch;
        ListPartInput.offset = page;
        ListPartInput.limit = 20;
        ListPartInput.search_text = '';
        ListPartInput.bs_model_name = '';
        ListPartInput.pg_line = '';
        ListPartInput.multi_pg_line = [];
        ListPartInput.multi_bs_model_name = [];
        ListPartInput.multi_dispgroup_name = [];
        ListPartInput.model_cat = '';
        ListPartInput.mod_uid = '';
        ListPartInput.category_type = '';
        ListPartInput.bom_id = '';
        ListPartInput.durafit_parts = false;
        ListPartInput.kit_parts = false;
        ListPartInput.part_number_last_four = '';
        ListPartInput.pl_id = '';
        ListPartInput.part_number = '';
        ListPartInput.is_for_epc_data = false;
        ListPartInput.is_for_discount = this.isLoggedIn == true ? true : false;
        ListPartInput.is_for_ecom_detail = false;
        ListPartInput.account_pk = this.isLoggedIn == true ? this.account_pk : '';
        ListPartInput.account_id = this.isLoggedIn == true ? user.account_id : '';
        ListPartInput.shipping_address_id = this.isLoggedIn == true ? this.shippingaddressid : '';
        ListPartInput.is_for_top_sold_parts = true;
        ListPartInput.is_for_inventory = this.userData?.user_type == 'MECH' ? true: false;
        this.showLoader = true;
        if(this.pincode && (this.pincode != (address!=null?address["address"].postal_code : ""))){
          ListPartInput.is_pincode_based_division = true;
          ListPartInput.is_for_discount = true;
          ListPartInput.pincode = this.pincode;

        }       
        this.productService.get_part_search_md(ListPartInput).subscribe(
          (response) => {
            this.beforecall();
            if (response.success) {
              this.commonResponse(page, response);
              this.GoogleAnalyticsUserDetails('model_Search_web', this.searchBy, 'modelSearch');
              this.showLoader = false;
            } else {
              this.aftercall();
              this.GoogleAnalyticsUserDetails('model_Search_Fail_web', this.searchBy, 'modelSearchFail');
            }
          },
          (error) => {
            this.beforecall();
            this.ProductListData = [];
          }
        );
      }
      if (this.categoryType === 'PARTS CATEGORIES') {
        this.ModelSelectedData = [];
        this.subModelSelectedData = [];
        this.aggregateSelectedData = [];
        let partsCategories = {};
        if (user && address) {
          partsCategories = {
            search_text: '',
            bs_model_name: '',
            pg_line: !this.isViewAll ? this.searchBy : '',
            group_category: this.isViewAll ? this.searchBy : '',
            multi_pg_line: [],
            multi_bs_model_name: [],
            pg: '',
            multi_dispgroup_name: [],
            model_cat: '',
            mod_uid: '',
            category_type: '',
            bom_id: '',
            durafit_parts: false,
            kit_parts: false,
            part_number_last_four: '',
            offset: page,
            limit: 20,
            pl_id: '',
            account_pk: this.account_pk,
            shipping_address_id: this.shippingaddressid,
            part_number: '',
            is_for_epc_data: false,
            is_for_discount: true,
            is_for_ecom_detail: false,
            is_for_discount_banner:this.isPgLineDiscount?true:false,
            discount_code: this.isPgLineDiscount && this.localstorage.getItem('pgLineDiscountCode') ? this.localstorage.getItem('pgLineDiscountCode') : '',
            is_for_inventory : this.userData?.user_type == 'MECH' ? true: false
          };
        }
        else {
          partsCategories = {
            search_text: '',
            bs_model_name: '',
            pg_line: !this.isViewAll ? this.searchBy : '',
            group_category: this.isViewAll ? this.searchBy : '',
            multi_pg_line: [],
            multi_bs_model_name: [],
            pg: '',
            multi_dispgroup_name: [],
            model_cat: '',
            mod_uid: '',
            category_type: '',
            bom_id: '',
            durafit_parts: false,
            kit_parts: false,
            part_number_last_four: '',
            offset: page,
            limit: 20,
            pl_id: '',
            is_for_discount_banner:this.isPgLineDiscount?true:false,
            discount_code: this.isPgLineDiscount && this.localstorage.getItem('pgLineDiscountCode') ? this.localstorage.getItem('pgLineDiscountCode') : '',
            is_for_inventory : this.userData?.user_type == 'MECH' ? true: false
          };
          if(this.pincode && (this.pincode != (address!=null?address["address"].postal_code : ""))){
            partsCategories["is_pincode_based_division"] = true;
            partsCategories["pincode"] = this.pincode ;
            partsCategories["is_for_discount"] = true;
          }        
        }
        this.result = false;
        this.showLoader = true;
        this.productService.get_part_search_md(partsCategories).subscribe(response => {
          this.beforecall();
          if (response.success) {
            this.commonResponse(page, response);
            this.GoogleAnalyticsUserDetails('View_all_Parts_web', this.searchBy, 'viewAllParts');

            this.showLoader = false;
          } else {
            this.isNoDataFound = true;
            if (response.success == false) {
              this.loading = true;
            }
            this.GoogleAnalyticsUserDetails('View_all_Parts_Fail_web', this.searchBy, 'viewAllPartsFail');

            this.showLoader = false;
          }
        },
          (error) => {
            this.beforecall();
          });
      }
      if (this.categoryType === 'Model') {
        this.totalResult = 0;
        this.ProductListData = [];
        this.searchValue = this.searchBy;
        (this.partnumberlastfour = ''), (this.kit_parts = false);
        this.durafit_parts = false;
        this.search_text = '';
        this.ModelSelectedData = [];
        this.ModelSelectedData.push(this.ModelNameagg);
        this.pgline = [];
        this.aggregateSelectedData = [];
        this.GetBomDetail(this.ModelSelectedData, 0);
        return;
      }
      if (this.aggType === 'aggregate') {
        this.searchValue = this.searchBy;
        this.aggregateSelectedData = [];
        if (this.searchBy != '' && this.searchBy != undefined && this.searchBy != null) {
          this.aggregateSelectedData.push(this.searchBy);
        }
        this.ModelSelectedData = [];
        this.ModelSelectedData.push(this.ModelNameagg);
        this.partnumberlastfour = '';
        this.category_type = '';
        this.kit_parts = false;
        this.durafit_parts = false;
        this.search_text = '';
        this.totalResult = 0;
        this.ProductListData = [];
        this.GetBomDetail(this.ModelSelectedData, 0);
        return;
      }
      if(this.categoryType === 'PARTS CATEGORIES' || this.categoryType === 'Model' || this.aggType === 'aggregate'){
        this.title.setTitle('E-Dukaan | Product List');
      }
      if (this.bannerId != '' && this.bannerId != null && this.bannerId != undefined) {
        let searchData = {};
        if (user && address) {
          searchData = {
            search_text: '',
            bs_model_name: '',
            pg_line: '',
            multi_pg_line: [],
            multi_bs_model_name: [],
            multi_dispgroup_name: [],
            model_cat: '',
            mod_uid: '',
            category_type: '',
            bom_id: '',
            durafit_parts: false,
            kit_parts: false,
            part_number_last_four: '',
            offset: page,
            // start_row:0,
            limit: 20,
            pl_id: '',
            //is_discount_needed: true,
            account_pk: this.account_pk,
            //address_pk: this.address_pk,
            shipping_address_id: this.shippingaddressid,
            part_number: '',
            is_for_epc_data: false,
            is_for_discount: true,
            is_for_ecom_detail: false,
            banner_discount_id:this.bannerId
          };
        } else {
          searchData = {
            search_text: '',
            bs_model_name: '',
            pg_line: '',
            multi_pg_line: [],
            multi_bs_model_name: [],
            multi_dispgroup_name: [],
            model_cat: '',
            mod_uid: '',
            category_type: '',
            bom_id: '',
            durafit_parts: false,
            kit_parts: false,
            part_number_last_four: '',
            offset: page,
            // start_row:0,
            limit: 20,
            pl_id: '',
            banner_discount_id:this.bannerId
          };
        }
        this.result = false;
        this.showLoader = true;
        if(this.pincode && (this.pincode != (address!=null?address["address"].postal_code : ""))){
          searchData["is_pincode_based_division"] = true;
          searchData["pincode"] = this.pincode ;
          searchData["is_for_discount"] = true;
        }        
        this.productService.get_part_search_md(searchData).subscribe(
          (response) => {
            this.productFilterData = [];
            this.beforecall();

            if (response.success) {
              this.commonResponse(page, response);
              this.GoogleAnalyticsUserDetails('View_all_Parts_web', 'Durafit', 'viewAllParts');
              this.bannerTitle = response?.additional_info?.banner_title ;
              this.showLoader = false;
            } else {
              this.bannerTitle = ""
              this.aftercall();
              this.GoogleAnalyticsUserDetails('View_all_Parts_Fail_web', 'Durafit', 'viewAllPartsFail');
            }
          },
          (error) => {
             this.bannerTitle = ""
            this.beforecall();
          }
        );
      }
    }
    catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'getProducts(page)',
        page_name: 'PageCategoryComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  filter(event) {
    try {
      document.body.scrollTop = 0;
      if (event == 'next') {
        this.localstorage.setItem('prevOffset', this.page);
        document.body.scrollTop = 0;
      } else {
        let p = this.localstorage.getItem('prevOffset');
        document.body.scrollTop = 0;
        if (p) {
          if (parseInt(p) <= 20) {
            this.page = 0;
            this.localstorage.setItem('prevOffset', this.page);
          } else if (parseInt(p) >= 20) {
            this.page = this.page - this.pagestart;
            this.localstorage.setItem('prevOffset', this.page);
          } else {
            this.page = +p;
          }
        }
      }
      if (this.isApplyFilter) {
        this.applyFilter(this.filterArr, this.page);
      } else {
        this.getProducts(this.page, false);
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'filter(event)',
        page_name: 'PageCategoryComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  applyFilter(event, page = 0) {
    try {
      document.body.scrollTop = 0;
      var oils = '';
      this.filterArr = event;
      this.isApplyFilter = true;
      this.productList = false;
      if (this.searchBy === 'kit') {
        this.kit_parts = true;
        this.durafit_parts = false;
        this.category_type = '';
        this.search_text = '';
        this.partnumberlastfour = '';
      } else if (this.searchBy === 'durafit') {
        this.durafit_parts = true;
        this.kit_parts = false;
        this.category_type = '';
        this.search_text = '';
        this.partnumberlastfour = '';
      } else if (this.searchBy === 'oillubricants') {
        this.category_type = '';
        this.kit_parts = false;
        this.durafit_parts = false;
        this.search_text = '';
        this.partnumberlastfour = '';
        oils = 'PG6';
      } else if (this.searchBy === 'description') {
        this.category_type = '';
        this.kit_parts = false;
        this.durafit_parts = false;
        this.search_text = this.searchValue;
        this.partnumberlastfour = '';
      } else if (this.searchBy === 'partno') {
        (this.partnumberlastfour = this.searchValue), (this.category_type = '');
        this.kit_parts = false;
        this.durafit_parts = false;
        this.search_text = '';
      } else if (this.categoryType === 'PARTS CATEGORIES') {
        this.ModelSelectedData = [];
        this.subModelSelectedData = [];
        this.aggregateSelectedData = [];
        (this.partnumberlastfour = ''), (this.category_type = '');
        this.kit_parts = false;
        this.durafit_parts = false;
        this.search_text = '';
      } else if (this.aggType === 'aggregate') {
        this.searchValue = this.searchBy;
        (this.partnumberlastfour = ''), (this.ModelSelectedData = []);
        this.subModelSelectedData = [];
        this.category_type = '';
        this.kit_parts = false;
        this.durafit_parts = false;
        this.search_text = '';
        this.GetBomDetail([], 0);
        return;
      } else {
        this.kit_parts = false;
        this.durafit_parts = false;
        this.category_type = this.searchBy;
        this.search_text = '';
        this.partnumberlastfour = '';
      }
      let user = JSON.parse(this.localstorage.getItem('userData'));
      let address = JSON.parse(this.localstorage.getItem('address'));
      let searchData = {};
      if (user && address) {
        this.account_pk = +JSON.stringify(user.account_pk);
        if (address['type'] === 'default' && address['type'] !== null) {
          this.address_pk = address.type;
        } else {
          if (
            address.address.length === undefined ||
            address.address.length === 0
          ) {
            this.address_pk = address.address.id;
          } else {
            this.address_pk = address.address[0].id;
          }
        }
        searchData = {
          search_text: this.search_text,
          bs_model_name: '',
          pg_line: '',
          multi_pg_line: event.pgline ? event.pgline : '',
          multi_bs_model_name: event.basemodel ? event.basemodel : '',
          multi_dispgroup_name: event.dispgroup,
          model_cat: event.Mainmodel ? event.Mainmodel : '',
          mod_uid: '',
          category_type: this.category_type,
          bom_id: '',
          pg: oils ? oils : '',
          durafit_parts: this.durafit_parts,
          kit_parts: this.kit_parts,
          part_number_last_four: this.partnumberlastfour,
          // start_row: 0,
          offset: page,
          limit: 20,
          pl_id: '',
          // is_discount_needed: true,
          account_pk: this.account_pk,
          //  address_pk: this.address_pk,
          shipping_address_id: this.shippingaddressid,
          part_number: '',
          is_for_epc_data: false,
          is_for_discount: true,
          is_for_ecom_detail: false,
          is_for_inventory : this.userData?.user_type == 'MECH' ? true: false
        };
      } else {
        searchData = {
          search_text: this.search_text,
          bs_model_name: '',
          pg_line: '',
          multi_pg_line: event.pgline ? event.pgline : '',
          multi_bs_model_name: event.basemodel ? event.basemodel : '',
          multi_dispgroup_name: event.dispgroup,
          model_cat: event.Mainmodel ? event.Mainmodel : '',
          mod_uid: '',
          category_type: this.category_type,
          bom_id: '',
          pg: oils ? oils : '',
          durafit_parts: this.durafit_parts,
          kit_parts: this.kit_parts,
          part_number_last_four: this.partnumberlastfour,
          // start_row: 0,
          offset: page,
          limit: 20,
          pl_id: '',
          is_for_inventory : this.userData?.user_type == 'MECH' ? true: false
        };
      }
      this.result = false;
      this.productList = [];
      this.showLoader = true;
      this.productService.get_part_search_md(searchData).subscribe(
        (response) => {
          this.result = true;
          this.loading = false;
          this.showLoader = false;
          if (response.success) {
            this.commonResponse(page, response);
            window.scrollTo(0, 0);

            this.GoogleAnalyticsUserDetails('View_all_Parts_web', this.search_text, 'viewAllParts');

            this.showLoader = false;
          } else {
            this.showLoader = false;
            this.toastrService.error(response.data.msg);

            this.GoogleAnalyticsUserDetails('View_all_Parts_Fail_web', this.search_text, 'viewAllPartsFail');

          }
        },
        (error) => {
          this.result = true;
          this.loading = false;
          this.showLoader = false;
          this.productList = [];
        }
      );
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'applyFilter(event, page = 0)',
        page_name: 'PageCategoryComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  getAllPartCategories() {

    this.isPartCategoryApiLoaded = false;
    this.halfProductsList = [];
    this.AllProductsList = [];
    this.halfProducts = [];
    this.AllProducts = [];
    let json = '{"size":150}';
    let user = JSON.parse(this.localstorage.getItem('userData'));
    let address = JSON.parse(this.localstorage.getItem('address'));
    if (this.searchBy !== 'oillubricants') {
      this.menu.GetPgLineList(json).subscribe(
        (data) => {
          if (data.success) {
            try {
              var datas = data.data.sort();
              for (var i = 0; i < datas.length; i++) {
                this.AllProducts.push(datas[i]);
                if (i < 4) {
                  this.halfProducts.push(datas[i]);
                }
              }
              this.halfProductsList = this.halfProducts;
              this.AllProductsList = this.AllProducts;
              for (let i = 0; i < this.AllProductsList.length; i++) {
                if (this.searchBy === this.AllProductsList[i].LINE_2_s) {
                  let itemToChange = this.AllProductsList[i];
                  this.AllProductsList.splice(i, 1);
                  this.AllProductsList.splice(0, 0, itemToChange);
                  this.selectedPgLine = itemToChange;
                  this.pgline.push(itemToChange);
                }
              }
            } catch (error) {
              // this.showLoader = false;
              this.userService.InsertExceptionLog({
                method_name: 'ShowMore(item)',
                page_name: 'MenuheaderComponent',
                portal_type: 'Customer',
                page_url: this.router.url,
                error_message: error.message,
                inner_exception: error,
              });
            }
            this.isPartCategoryApiLoaded = true;
          } else {
            this.isPartCategoryApiLoaded = true;
            // this.showLoader = false;
          }
        },
        (error) => {
          this.isPartCategoryApiLoaded = true;
          // this.showLoader = false;
        }
      );
    } else if (this.searchBy == 'oillubricants') {
      let oilLubricantData = {};
      if (user && address) {
        oilLubricantData = {
          search_text: '',
          bs_model_name: '',
          pg_line: '',
          multi_pg_line: [],
          multi_bs_model_name: [],
          pg: 'PG6',
          multi_dispgroup_name: [],
          model_cat: '',
          mod_uid: '',
          category_type: '',
          bom_id: '',
          durafit_parts: false,
          kit_parts: false,
          part_number_last_four: '',
          offset: 0,
          size: 20,
          pl_id: '',
          is_discount_needed: true,
          account_pk: this.account_pk,
          address_pk: this.address_pk,
          is_for_inventory : this.userData?.user_type == 'MECH' ? true: false
        };
      } else {
        oilLubricantData = {
          search_text: '',
          bs_model_name: '',
          pg_line: '',
          multi_pg_line: [],
          multi_bs_model_name: [],
          pg: 'PG6',
          multi_dispgroup_name: [],
          model_cat: '',
          mod_uid: '',
          category_type: '',
          bom_id: '',
          durafit_parts: false,
          kit_parts: false,
          part_number_last_four: '',
          offset: 0,
          size: 20,
          pl_id: '',
          is_for_inventory : this.userData?.user_type == 'MECH' ? true: false
        };
      }
      // this.showLoader = true;
      ///web_part_search/
      this.productService.get_part_search_md(oilLubricantData).subscribe(
        (response) => {
          if (response.success) {
            this.isPartCategoryApiLoaded = true;
            this.AllProductsList = [];
            let pglistdata = [];
            var datas = response.data.result.pg_line_list;
            for (var i = 0; i < datas.length; i++) {
              pglistdata.push(this.FormPgOilData(datas[i]));
            }
            for (let i = 0; i < pglistdata.length; i++) {
              this.AllProductsList.push({
                LINE_2_s: pglistdata[i].pg_line_name,
              });
            }

            // this.showLoader = false;
          } else {
            this.isPartCategoryApiLoaded = true;
            // this.showLoader = false;
          }
        },
        (error) => {
          this.isPartCategoryApiLoaded = true;
          // this.showLoader = false;
        }
      );
    }
  }

  FormPgOilData(data): PgLineData {
    const objdata: PgLineData = {} as PgLineData;
    objdata.pg_line_name = data.pg_line_name;
    return objdata;
  }

  viewMore() {
    this.showMore = !this.showMore;
  }

  viewMoreModel() {
    this.showMoreModel = !this.showMoreModel;
  }

  viewMoreSubModel() {
    this.showMoreSubModel = !this.showMoreSubModel;
  }

  viewMoreAggregate() {
    this.showMoreaggregate = !this.showMoreaggregate ? true : false;
  }

  pgLineSelected(event, pgline) {
    // this.BomIdData = [];
    // this.FinalBomData = [];
    // this.ModelSelectedData = [];
    // this.subModelSelectedData = [];
    // this.aggregateSelectedData = [];
    if (event.target.checked === true) {
      this.selectedPgLine = pgline;
      this.pgline.push(pgline);
    } else {
      this.selectedPgLine = '';
      const index = this.pgline.indexOf(pgline);
      if (index > -1) {
        this.pgline.splice(index, 1);
      }
    }
    this.totalResult = 0;
    this.ProductListData = [];
    this.isNoDataFound = false;
    window.scrollTo(0, 0);
    if (this.ModelSelectedData.length > 0 || this.aggregateSelectedData.length > 0 || this.activeVcNo || this.subModelSelectedData.length > 0) {
      this.GetBomDetail(this.ModelSelectedData, 0);
    }
    else {
      this.bindpgProduct([], 0);
    }
    //this.applyFilter({ Mainmodel: this.Mainmodel, basemodel: this.basemodel, dispgroup: this.dispgroup, pgline: this.pgline })
    //this.GetBomDetail(this.ModelSelectedData, 0)

    // // try {
    // //   this.searchValue = pgline;
    // //   if (event.target.checked === true) {
    // //     this.selectedPgLine = ''
    // //     this.pgline = [];
    // //     this.selectedPgLine = pgline
    // //     this.pgline.push(pgline);
    // //     this.applyFilter({ Mainmodel: this.Mainmodel, basemodel: this.basemodel, dispgroup: this.dispgroup, pgline: this.pgline })
    // //   } else {
    // //     // this.selectedPgLine = ''
    // //     // this.pgline = [];
    // //     // this.selectedPgLine = this.searchBy
    // //     // this.pgline.push(this.searchBy);
    // //     // this.applyFilter(event)
    // //   }
    // // }
    // // catch (error) {
    // //   this.userService.InsertExceptionLog({ "method_name": "pgLineSelected(event, pgline)", "page_name": "WidgetFiltersComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
    // // }
  }

  async ModelCategory() {
    this.isModelApiLoaded = false;
    this.modelData = [];
    const ListInput: Inputdata = {} as Inputdata;
    ListInput.offset = 0;
    ListInput.limit = 100;
    ListInput.dropdown_type = 'model_cat';
    if (this.ModelNameagg != '' || this.ModelNameagg != null || this.ModelNameagg != undefined) {
      if (this.isMenuModelViewAll) {
        ListInput.multi_model_cat = [];
      }
      else {
        ListInput.multi_model_cat = this.ModelSelectedData ? this.ModelSelectedData : [];
      }
    }
    let response = await this.modelService.getModelCategory(ListInput);
    if (response['success'] == true) {
      this.modelData = response['data'];
      this.modelData = this.modelData.sort((a, b) => {
        if (a < b) return -1;
        else if (a > b) return 1;
        else return 0;
      });
      this.isModelApiLoaded = true;
    } else {
      this.isModelApiLoaded = true;
    }
  }

  ModelCategorySelected(event, model) {
    document.body.scrollTop = 0;
    window.scrollTo(0, 0);
    this.totalResult = 0;
    this.ProductListData = [];
    if (event.target.checked === true) {
      this.selectedModel = model;
      this.ModelSelectedData.push(model);
      this.GetBomDetail(this.ModelSelectedData, 0);
      this.GetSubModelDetail(this.ModelSelectedData, 0);
      this.BindAggregate();
    }
    else if (!event.target.checked) {
      if (this.ModelSelectedData.length === 1 && this.headerSearchText) {
        this.ModelSelectedData = [];
        this.subModelSelectedData = [];
        this.aggregateSelectedData = [];
        this.activeVcNo = "";
        this.headerSearchText = "";
        this.FinalBomData = [];
        this.ModelCategory();
        this.GetSubModelDetail([], 0);
        this.BindAggregate();
        this.bindpgProduct([], 0);
      }
      else if (this.ModelSelectedData.length === 1 && this.modelData.length === 1) {
        this.ModelSelectedData = [];
        this.subModelSelectedData = [];
        this.aggregateSelectedData = [];
        this.activeVcNo = "";
        this.headerSearchText = "";
        this.FinalBomData = [];
        this.ModelCategory();
        this.GetSubModelDetail([], 0);
        this.BindAggregate();
        this.bindpgProduct([], 0);
      }
      else if (this.ModelSelectedData.length === 1 && (this.subModelSelectedData.length > 0 || this.aggregateSelectedData.length > 0)) {
        this.ModelSelectedData = [];
        this.subModelSelectedData = [];
        this.aggregateSelectedData = [];
        this.GetSubModelDetail([], 0);
        this.BindAggregate();
        this.bindpgProduct([], 0);
      }
      else if (this.ModelSelectedData.length === 1 && this.FinalBomData.length > 0 && !(this.subModelSelectedData.length > 0 || this.aggregateSelectedData.length > 0)) {
        this.ModelSelectedData = [];
        this.FinalBomData = [];
        this.bindpgProduct([], 0);
      }
      else {
        this.selectedModel = '';
        const index = this.ModelSelectedData.indexOf(model);
        this.ModelSelectedData.splice(index, 1);
        this.GetBomDetail(this.ModelSelectedData, 0);
        this.GetSubModelDetail(this.ModelSelectedData, 0);
        this.BindAggregate();
      }
    }
  }

  async GetSubModelDetail(model: any, offset) {
    this.isSubmodelApiLoaded = false;
    this.submodelData = [];
    const ListInput: Inputdata = {} as Inputdata;
    ListInput.offset = offset;
    ListInput.limit = 200;
    ListInput.multi_model_cat = model ? model : this.ModelSelectedData.length > 0 ? this.ModelSelectedData : [];
    ListInput.dropdown_type = 'base_model';
    let response = await this.modelService.getModelCategory(ListInput);
    if (response['success'] == true) {
      this.submodelData = response['data'];
      this.submodelData = this.submodelData.sort((a, b) => {
        if (a < b) return -1;
        else if (a > b) return 1;
        else return 0;
      });
      for (let i = 0; i < this.submodelData.length; i++) {
        if (this.subModelSelectedData[0] == this.submodelData[i]) {
          let itemToChange = this.submodelData[i];
          this.submodelData.splice(i, 1);
          this.submodelData.splice(0, 0, itemToChange);
        }
      }
      this.isSubmodelApiLoaded = true;
    } else {
      this.isSubmodelApiLoaded = true;
    }
  }

  ModelSubCategorySelected(event, submodel) {
    this.totalResult = 0;
    this.ProductListData = [];
    this.totalResult = 0;
    document.body.scrollTop = 0;
    window.scrollTo(0, 0);
    if (event.target.checked === true) {
      this.subModelSelectedData.push(submodel);
    } else {
      const index = this.subModelSelectedData.indexOf(submodel);
      if (index > -1) {
        this.subModelSelectedData.splice(index, 1);
      }
    }
    this.aggregateSelectedData = [];
    this.BindAggregate();
    if (this.ModelSelectedData.length > 0 || this.subModelSelectedData.length > 0 || this.aggregateSelectedData.length > 0) {
      this.GetBomDetail(this.ModelSelectedData, 0);
    }
    else {
      this.bindpgProduct([], 0);
    }
  }

  async BindAggregate() {
    this.IsAggregateApiLoaded = false;
    this.aggregateData = [];
    const ListInput: Inputdata = {} as Inputdata;
    ListInput.offset = 0;
    ListInput.limit = 100;
    ListInput.dropdown_type = 'aggregate';
    ListInput.multi_model_cat = this.ModelSelectedData.length > 0 ? this.ModelSelectedData : [];
    ListInput.multi_base_model = this.subModelSelectedData.length > 0 ? this.subModelSelectedData : [];
    ListInput.multi_vc_no = this.activeVcNo != "" && this.activeVcNo != undefined && this.activeVcNo != null ? [this.activeVcNo] : []
    let response = await this.modelService.getModelCategory(ListInput);
    if (response['success'] == true) {
      this.aggregateData = response['data']['Aggregates'];
      this.aggregateData = this.aggregateData.sort((a, b) => {
        if (a.aggregate < b.aggregate) return -1;
        else if (a.aggregate > b.aggregate) return 1;
        else return 0;
      });
      for (let i = 0; i < this.aggregateData.length; i++) {
        if (this.aggregateSelectedData[0] == this.aggregateData[i].aggregate) {
          let itemToChange = this.aggregateData[i];
          this.aggregateData.splice(i, 1);
          this.aggregateData.splice(0, 0, itemToChange);
        }
      }
      this.IsAggregateApiLoaded = true;
    } else {
      this.IsAggregateApiLoaded = true;
    }
  }

  openAggregatesList() {
    this.aggregatesInPartListModal = this.modalService.show(this.aggregatesInPartList, {
      class: 'modal-dialog-centered modal-xl',
      backdrop: 'static',
    });
  }

  AggregateSelected(event, aggregates) {
    if (event.target.checked === true) {
      this.aggregateSelectedData.push(aggregates);
    } else {
      const index = this.aggregateSelectedData.indexOf(aggregates);
      if (index > -1) {
        this.aggregateSelectedData.splice(index, 1);
      }
    }
    this.ProductListData = [];
    this.totalResult = 0;
    document.body.scrollTop = 0;
    window.scrollTo(0, 0);
    if (this.ModelSelectedData.length > 0 || this.aggregateSelectedData.length > 0 || this.subModelSelectedData.length > 0) {
      this.GetBomDetail(this.ModelSelectedData, 0);
    }
    else {
      this.bindpgProduct([], 0);
    }
  }

  async GetBomDetail(model: any, offset, subAggregate?) {
    if (subAggregate) {
      this.selectedSubAggregate = subAggregate;
      this.ProductListData = [];
      if (this.aggregatesInPartListModal) {
        this.aggregatesInPartListModal.hide();
      }
    }
    this.BomIdData = [];
    this.FinalBomData = [];
    const ListInput: Inputdata = {} as Inputdata;
    ListInput.offset = offset;
    ListInput.limit = 200;
    ListInput.multi_model_cat = model ? model : this.ModelSelectedData ? this.ModelSelectedData : [];
    ListInput.multi_base_model = this.subModelSelectedData ? this.subModelSelectedData : [];
    ListInput.multi_aggregate = this.aggregateSelectedData ? this.aggregateSelectedData : [];
    ListInput.multi_vc_no = this.activeVcNo != "" && this.activeVcNo != undefined && this.activeVcNo != null ? [this.activeVcNo] : [];
    ListInput.multi_sub_aggregate_no = subAggregate ? [subAggregate] : this.selectedSubAggregate ? [this.selectedSubAggregate] : [];
    this.isNoDataFound = false;
    let response = await this.modelService.getBom(ListInput);
    if (response['success'] == true) {
      this.BomPageEnd = offset;
      this.BomIdData = response['data'];
      const n = 20;
      this.FinalBomData = new Array(Math.ceil(this.BomIdData.length / n)).fill('').map((_) => this.BomIdData.splice(0, n));
      this.BomArrayNo = 0;
      this.BindProduct(this.FinalBomData[this.BomArrayNo], 0);
    } else {
      this.showLoader = false;
      this.isNoDataFound = (this.ProductListData?.length === 0) ? true : false;
    }
  }

  async BindProduct(BomIdData: any, offset) {
    if (BomIdData) {
      let user = JSON.parse(this.localstorage.getItem('userData'));
      let address = JSON.parse(this.localstorage.getItem('address'));
      if (user && address) {
        this.account_pk = +JSON.stringify(user.account_pk);
        let billingaddress = JSON.parse(
          this.localstorage.getItem('billingAddress')
        );
        var addresstypecheck = address?.address['default'];
        if (addresstypecheck === true) {
          this.shippingaddressid = 'default';
        } else {
          this.shippingaddressid = address?.address['address_id'];
        }
        var Billingaddresscheck = billingaddress?.address['default'];
        if (Billingaddresscheck === true) {
          this.billingaddressid = 'default';
        } else {
          this.billingaddressid = billingaddress?.address['address_id'];
        }
      }

      const ListPartInput: InputPartSearch = {} as InputPartSearch;
      ListPartInput.offset = offset;
      ListPartInput.limit = 20;
      ListPartInput.search_text = this.searchuserData
        ? this.searchuserData
        // : this.searchValue
        // ? this.searchValue
        : '';
      ListPartInput.bs_model_name = '';
      ListPartInput.pg_line = '';
      ListPartInput.multi_pg_line = this.pgline ? this.pgline : [];
      ListPartInput.multi_bs_model_name = [];
      ListPartInput.multi_dispgroup_name = [];
      ListPartInput.model_cat = '';
      ListPartInput.mod_uid = '';
      ListPartInput.category_type = '';
      ListPartInput.bom_id = '';
      ListPartInput.durafit_parts = false;
      ListPartInput.kit_parts = false;
      ListPartInput.part_number_last_four = '';
      ListPartInput.pl_id = '';
      ListPartInput.is_home = false;
      ListPartInput.part_number = '';
      ListPartInput.is_for_epc_data = false;
      ListPartInput.is_for_discount = this.isLoggedIn == true ? true : false;
      ListPartInput.is_for_ecom_detail = false;
      ListPartInput.bom_id_list = BomIdData ? BomIdData : []; //result[i];
      ListPartInput.account_pk = this.isLoggedIn == true ? this.account_pk : '';
      ListPartInput.is_for_inventory = this.userData?.user_type == 'MECH' ? true: false;
      ListPartInput.shipping_address_id =
        this.isLoggedIn == true ? this.shippingaddressid : '';
        if(this.pincode && (this.pincode != (address!=null?address["address"].postal_code : ""))){
          ListPartInput.is_pincode_based_division = true;
          ListPartInput.is_for_discount = true;
          ListPartInput.pincode = this.pincode;
        }  
      // let searchData = {}

      // if (user && address) {
      //   searchData = {
      //     search_text: "",
      //     bs_model_name: "",
      //     pg_line: "",
      //     // multi_pg_line: [],
      //     multi_pg_line: this.pgline?this.pgline:[],
      //     multi_bs_model_name: [
      //     ],
      //     multi_dispgroup_name: [],
      //     model_cat: "",
      //     mod_uid: "",
      //     category_type: "",
      //     bom_id: "",
      //     durafit_parts: false,
      //     kit_parts: false,
      //     part_number_last_four: "",
      //     offset: offset,
      //     limit: 20,
      //     pl_id: "",
      //     is_home: false,
      //     account_pk: this.account_pk,
      //     shipping_address_id: this.shippingaddressid,
      //     part_number: "",
      //     is_for_epc_data: false,
      //     is_for_discount: true,
      //     is_for_ecom_detail: false,
      //     bom_id_list: BomIdData//result[i]
      //   }
      // }
      // else {
      //   searchData = {
      //     search_text: "",
      //     bs_model_name: "",
      //     pg_line: "",
      //     multi_pg_line: this.pgline?this.pgline:[],
      //     multi_bs_model_name: [
      //     ],
      //     multi_dispgroup_name: [],
      //     model_cat: "",
      //     mod_uid: "",
      //     category_type: "",
      //     bom_id: "",
      //     durafit_parts: false,
      //     kit_parts: false,
      //     part_number_last_four: "",
      //     offset: offset,
      //     limit: 20,
      //     pl_id: "",
      //     is_home: false,
      //     bom_id_list: BomIdData//result[i]
      //   }
      // }
      // this.totalResult = 0;
      this.showLoader = true;
      this.isNoDataFound = false;
      let data = await this.modelService.getPartSearchMd(ListPartInput);
      if (data['success'] === true) {
        if (offset === 0) {
          this.totalResult = this.totalResult + data['total_result'];
        }
        this.loading = false;
        var proddata = data['data'];
        for (let i = 0; i < proddata.length; i++) {
          proddata[i].customerQuantity = proddata[i].min_quantity;
          var dupicatecount = this.ProductListData.filter(
            (Data) => Data.part_number == proddata[i].part_number
          );
          if (dupicatecount.length > 1) {
          } else {
            this.ProductListData.push(proddata[i]);
          }
        }
        //let udata = this.ProductListData.filter((v,i,a)=>a.findIndex(t=>(t.place === v.place && t.name===v.name))===i)
        this.ProductListData = this.ProductListData.filter(
          (v, i, a) => a.findIndex((t) => t.part_number === v.part_number) === i
        );

        //this.ProductListData = this.ProductListData.filter(eachItem => eachItem.part_number !== item);
        this.prodstart = data['start'];
        this.prodend = data['end'];

        if (this.ProductListData.length < 20 && offset === 0) {
          this.BomArrayNo = parseInt(this.BomArrayNo) + 1;
          if (this.BomArrayNo < this.FinalBomData.length) {
            this.prodend = 0;
            this.BindProduct(this.FinalBomData[this.BomArrayNo], this.prodend);
          } else {
            // this.showLoader = false;
            this.BomPageEnd = this.BomPageEnd + 200;
            this.GetBomDetail(this.ModelSelectedData, this.BomPageEnd);
          }
        }
        else {
          this.showLoader = false;
        }
      } else if (
        data['success'] === false &&
        data['data']['msg'] === 'Data not found'
      ) {
        if (this.FinalBomData.length >= this.BomArrayNo) {
          this.BomArrayNo = parseInt(this.BomArrayNo) + 1;
          if (this.BomPageEnd === 200 && this.ProductListData.length === 0) {
            this.showLoader = false;
            this.isNoDataFound = true;
            this.ProductListData = [];
            this.totalResult = 0;
            return;
          }
          else if (this.BomArrayNo < this.FinalBomData.length) {
            this.prodend = 0;
            this.BindProduct(this.FinalBomData[this.BomArrayNo], this.prodend);
          }
          else {
            // this.showLoader = false;
            this.BomPageEnd = this.BomPageEnd + 200;
            this.GetBomDetail(this.ModelSelectedData, this.BomPageEnd);
          }
          // this.showLoader = false;
        } else {
          this.BomPageEnd = this.BomPageEnd + 200;
          this.GetBomDetail(this.ModelSelectedData, this.BomPageEnd);
          // this.showLoader = false;
        }
      } else if ( data['success'] === false &&
      data['data']['msg'] === 'issue with part data'){
        // this.showLoader = false;
        if (this.FinalBomData.length >= this.BomArrayNo) {
          this.BomArrayNo = parseInt(this.BomArrayNo) + 1;
          if (this.BomPageEnd === 200 && this.ProductListData.length === 0) {
            this.showLoader = false;
            this.isNoDataFound = true;
            this.ProductListData = [];
            this.totalResult = 0;
            return;
          }
          else if (this.BomArrayNo < this.FinalBomData.length) {
            this.prodend = 0;
            this.BindProduct(this.FinalBomData[this.BomArrayNo], this.prodend);
          }
          else {
            // this.showLoader = false;
            this.BomPageEnd = this.BomPageEnd + 200;
            this.GetBomDetail(this.ModelSelectedData, this.BomPageEnd);
          }
          // this.showLoader = false;
        } else {
          this.BomPageEnd = this.BomPageEnd + 200;
          this.GetBomDetail(this.ModelSelectedData, this.BomPageEnd);
          // this.showLoader = false;
        }
      }else {
        this.showLoader = false;
        this.isNoDataFound = true;
        this.ProductListData = [];
        this.totalResult = 0;
      }
    }
    //  }
  }

  async bindpgProduct(BomIdData: any, offset: any) {
    let user = JSON.parse(this.localstorage.getItem('userData'));
    let address = JSON.parse(this.localstorage.getItem('address'));
    if (user && address) {
      this.account_pk = +JSON.stringify(user.account_pk);
      let billingaddress = JSON.parse(
        this.localstorage.getItem('billingAddress')
      );
      var addresstypecheck = address?.address['default'];
      if (addresstypecheck === true) {
        this.shippingaddressid = 'default';
      } else {
        this.shippingaddressid = address?.address['address_id'];
      }
      var Billingaddresscheck = billingaddress?.address['default'];
      if (Billingaddresscheck === true) {
        this.billingaddressid = 'default';
      } else {
        this.billingaddressid = billingaddress?.address['address_id'];
      }
    }
    let pgLineReqList = [];
    this.pgline.forEach(element => {
      if (pgLineReqList.indexOf(element.LINE_2_s?element.LINE_2_s:element) === -1 || pgLineReqList.length == 0 ) {
        pgLineReqList.push(element.LINE_2_s?element.LINE_2_s:element);
      }
    });
    const ListPartInput: InputPartSearch = {} as InputPartSearch;
    ListPartInput.offset = offset;
    ListPartInput.limit = 20;
    // ListPartInput.search_text = this.searchuserData?this.searchuserData: "";
    ListPartInput.search_text = this.searchuserData
      ? this.searchuserData
      : this.searchValue
        ? this.searchValue
        : '';
    ListPartInput.bs_model_name = '';
    ListPartInput.multi_pg_line = pgLineReqList ? pgLineReqList : [];
    ListPartInput.pg_line =
      this.searchBy === 'popularKits'
        ? this.kitName
        : this.pgline
          ? ''
          : this.searchBy
            ? this.searchBy
            : '';
    ListPartInput.multi_bs_model_name = [];
    ListPartInput.multi_dispgroup_name = [];
    ListPartInput.model_cat = '';
    ListPartInput.mod_uid = '';
    ListPartInput.category_type = '';
    ListPartInput.bom_id = '';
    ListPartInput.pg = this.searchBy === 'oillubricants' ? 'PG6' : '';
    ListPartInput.durafit_parts = this.searchBy === 'durafit' ? true : false;
    ListPartInput.kit_parts = this.searchBy === 'kit' || this.searchBy === 'popularKits'? true : false;
    ListPartInput.prolife_parts = this.searchBy === 'prolife' ? true : false;
    ListPartInput.is_for_top_sold_parts =
      this.searchBy === 'fast-consumables' ? true : false;
    ListPartInput.part_number_last_four = '';
    ListPartInput.pl_id = '';
    ListPartInput.is_home = false;
    ListPartInput.part_number = '';
    ListPartInput.is_for_epc_data = false;
    ListPartInput.is_for_discount = this.isLoggedIn == true ? true : false;
    ListPartInput.is_for_ecom_detail = false;
    ListPartInput.bom_id_list = BomIdData ? BomIdData : []; //result[i];
    ListPartInput.account_pk = this.isLoggedIn == true ? this.account_pk : '';
    ListPartInput.shipping_address_id = this.isLoggedIn == true ? this.shippingaddressid : '';
    ListPartInput.account_id = (this.isLoggedIn == true && this.searchBy === 'fast-consumables') ? user.account_id : '';
    ListPartInput.group_category = this.isViewAll ? this.searchBy : '';
    ListPartInput.discount_code = this.isPgLineDiscount && this.localstorage.getItem('pgLineDiscountCode') ? this.localstorage.getItem('pgLineDiscountCode') : '';
    ListPartInput.is_for_inventory = this.userData?.user_type == 'MECH' ? true: false;
    ListPartInput.banner_discount_id=this.bannerId
    this.showLoader = true;
    this.isNoDataFound = false;
    let data = await this.modelService.getPartSearchMd(ListPartInput);

    if (data['success'] === true) {

      this.isNoDataFound = false;
      if (offset === 0) {
        this.totalResult = this.totalResult + data['total_result'];
      }
      this.loading = false;
      var proddata = data['data'];
      for (let i = 0; i < proddata.length; i++) {
        proddata[i].customerQuantity = proddata[i].min_quantity;
        var dupicatecount = this.ProductListData.filter(
          (Data) => Data.part_number == proddata[i].part_number
        );
        if (dupicatecount.length > 1) {
        } else {
          this.ProductListData.push(proddata[i]);
        }
      }
      this.ProductListData = this.ProductListData.filter(
        (v, i, a) => a.findIndex((t) => t.part_number === v.part_number) === i
      );
      this.prodstart = data['start'];
      this.prodend = data['end'];
      this.showLoader = false;
    } else if (
      data['success'] === false &&
      data['data']['msg'] === 'Data not found'
    ) {
      this.isNoDataFound = (this.ProductListData?.length === 0) ? true : false;
      this.showLoader = false;
      this.loading = true;
    } else {
      this.loading = false;
      this.showLoader = false;
    }
  }

  @HostListener('window:scroll', [])
  onScroll(): void {
    // if (
    //   window.innerHeight + window.scrollY + 950 >= document.body.offsetHeight &&
    //   !this.loading &&
    //   this.ProductListData.length
    // ) {
    //   this.loading = true; this.showLoader = true;
    //   this.loadingMoreData();
    // }
    if (this.ProductListData?.length === 0 && this.isNoDataFound) {
      return;
    }
    else {
      if (
        window.innerHeight + window.scrollY + 950 >= document.body.offsetHeight &&
        !this.loading
      ) {
        this.loading = true;
        // this.showLoader = true;
        this.loadingMoreData();
      } else if (
        window.innerHeight + window.scrollY <
        document.body.offsetHeight
      ) {
      }
    }

  }

  loadingMoreData() {
    // if (!this.isLastPage) {
    setTimeout(() => {
      if (this.FinalBomData.length > 0 ) {
      //     this.BomArrayNo = parseInt(this.BomArrayNo) + 1;
      //   this.BindProduct(this.FinalBomData[this.BomArrayNo], this.prodend);
      // if (this.ProductListData.length < 20 && offset === 0) {
        this.BomArrayNo = parseInt(this.BomArrayNo) + 1;
        if (this.BomArrayNo < this.FinalBomData.length) {
          this.prodend = 0;
          this.BindProduct(this.FinalBomData[this.BomArrayNo], this.prodend);
        } else {
          // this.showLoader = false;
          this.BomPageEnd = this.BomPageEnd + 200;
          this.GetBomDetail(this.ModelSelectedData, this.BomPageEnd);
        }
      } else {
        // if (this.searchBy === 'durafit' || this.searchBy === 'oillubricants' || this.searchBy === 'kit' || this.searchBy === 'prolife' || this.searchBy === 'popularKits' || this.searchBy == 'description') {
        //   this.getProducts(this.prodend)
        // }
        // else {
        if (this.aggType === 'aggregate') {
          return;
        }
        else if (this.categoryType === 'Model') {
          if (this.FinalBomData.length === 0) {
            this.BindProduct([], this.prodend);
          }
        }
        else if (this.categoryType === 'PARTS CATEGORIES') {
          if (this.FinalBomData.length === 0) {
            var page = this.pagestart + 20;
            this.getProducts(page, '');
          }
        }
        else if (this.selectedSubAggregate) {
          return;
        }
        else if (!(this.FinalBomData.length > 0)) {
          var productEnd = this.prodstart + 20
          this.bindpgProduct([], productEnd);
        }
        // }
      }
    }, 250);
    // }
  }

  GotoView(layoutMethod) {
    this.layout = layoutMethod;
  }

  searchUserdataFilter(search) {
    let sdata = '';
    if (search) {
      sdata = search;
    } else {
      sdata = this.searchuserData;
    }
    // if(search.length>4){
    this.isNoDataFound = false;
    this.totalResult = 0;
    this.ProductListData = [];
    this.searchuserData = sdata;
    // this.ModelSelectedData = [];
    // this.ModelSelectedData.push(this.searchBy);
    // this.pgline = [];
    // this.subModelSelectedData = [];
    // this.aggregateSelectedData = [];
    this.updateQueryParams(this.searchuserData);
    // if (this.aggType === 'aggregate') {
    //   this.GetBomDetail(this.ModelSelectedData, 0);
    // }
    // else if (this.categoryType === 'Model') {
    //   this.GetBomDetail(this.ModelSelectedData, 0);
    // }
    // else {
    //   this.bindpgProduct([], 0);
    // }
    // this.GetBomDetail(this.ModelSelectedData, 0);
    // }
  }

  GoogleAnalyticsUserDetails(eventName, Part_Viewed, funcname) {
    var MobileNo = null;
    if (
      this.localstorage.getItem('userData') != null ||
      this.localstorage.getItem('userData') != undefined
    ) {
      let userData = JSON.parse(
        this.localstorage.getItem('userData')
      );
      MobileNo = userData.contact_no || userData.phone_number;
    }
    var Address = '';
    if (
      this.localstorage.getItem('address') != null ||
      this.localstorage.getItem('address') != undefined
    ) {
      let address = JSON.parse(this.localstorage.getItem('address'));
      Address =
        address.address.street_address1 +
        ', ' +
        address.address.state +
        ', ' +
        address.address.district +
        ', ' +
        address.address.city +
        ', ' +
        address.address.postal_code;
    }
    try {
      this.googleAnlyatics[funcname](
        eventName,
        Address,
        Part_Viewed,
        MobileNo
      );
    } catch (error) { }
  }

  commonResponse(page, response) {
    if (page === 0) {
      this.totalResult = this.totalResult + response.total_result;
    }
    this.showPagination =
      response.total_result === response.end ? true : false;
    this.page = response.end;
    this.pagestart = response.start;
    this.prodend = response.end;
    this.prodstart = response.start;
    let t = +this.page - Math.abs(response.start - response.end);
    this.localstorage.setItem('prevOffset', '' + (t <= 0 ? 0 : t));
    this.localstorage.setItem('nextOffset', '' + this.showPagination);
    var proddata = response['data'];
    for (let i = 0; i < proddata.length; i++) {
      proddata[i].customerQuantity = proddata[i].min_quantity;
      var dupicatecount = this.ProductListData.filter(
        (Data) => Data.part_number == proddata[i].part_number
      );
      if (dupicatecount.length > 1) {
      } else {
        this.ProductListData.push(proddata[i]);
      }
    }

  }

  beforecall() {
    this.result = true;
    this.loading = false;
    this.showLoader = false;
  }

  aftercall() {
    this.isNoDataFound = true;
    this.loading = true;
    this.showLoader = false;
  }

  updateQueryParams(search) {
    var currentparam = JSON.parse(this.localstorage.getItem("CurrentListQueryParam"));
    currentparam.inListSearchText = search;
    currentparam.isSecondCall = true;
    const queryParams: Params = currentparam;
    this.router.navigate(
      [],
      {
        //relativeTo: router,
        queryParams: queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
  }

  ngOnDestroy() {
    this.bannerTitle = ""
    this.localstorage.removeitems("CurrentListQueryParam");
    this.localstorage.removeitems('bannerTitle')
  }

  onPartCategorySelection(event) {

  }

}

export interface PgLineData {
  pg_line_name: string;
}