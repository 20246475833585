import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../shared/services/REST/user.service'
import { secureStorage } from '../../../shared/services/securestorage';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { AppStateService } from '../../../shared/services/app-state/app-state.service';
@Component({
  selector: 'app-my-saving',
  templateUrl: './my-saving.component.html',
  styleUrls: ['./my-saving.component.scss']
})
export class MySavingComponent implements OnInit {
  amount:any; 
  today:Date;
  fromDate:string;
  toDate:string;
  showLoader:boolean=false;
  route:string="";
  isLoggedIn:boolean=false;
  userData:any;
  constructor( private appStateService:AppStateService, private datePipe:DatePipe,private router: Router, private userService:UserService , private localstorage:secureStorage , private toasterService:ToastrService ) { }
  ngOnInit(): void {
    this.appStateService.isLoggedIn.subscribe((isLog) => {
      this.isLoggedIn = isLog;
    });
    this.userData = JSON.parse(this.localstorage.getItem('userData'));
  }
  ismysavingsHide(): boolean {
    this.route = this.router.url
    return (
      this.route.indexOf('cart/summary') !== -1 ||
      this.route.indexOf('cart/address') !== -1 ||
      this.route.indexOf('cart/payment') !== -1 ||
      this.route.indexOf('cart/order-confirmation') !== -1
    );
  }
  search(){
    if(!this.fromDate && !this.toDate){
      return this.toasterService.error(
        'Please select valid date'
      );
    }else if (
      this.fromDate &&
      this.toDate &&
      !(this.toDate >= this.fromDate)
    ) {
      return this.toasterService.error(
        'To date should be greater than or equal to From date'
      );
    } else if (this.fromDate && !this.toDate) {
      return this.toasterService.error('To date is required');
    } else if (!this.fromDate && this.toDate) {
      return this.toasterService.error('From date is required');
    }else{  
      this.showLoader=true;
      this.collapseSideBar();
    }
  }
  reset(){
    this.toDate ="";
    this.fromDate="";
    this.showLoader=true;
    this.collapseSideBar();
  }
  collapseSideBar(){
    try{
      this.today = new Date();
      let user = JSON.parse(this.localstorage.getItem('userData'));
      let today = new Date();
      let to_date = this.toDate?this.datePipe.transform(this.toDate,"yyy-MM-dd"):this.datePipe.transform(new Date(),"yyy-MM-dd");
      let from_date = this.fromDate?this.datePipe.transform(this.fromDate,"yyy-MM-dd"):this.datePipe.transform(new Date(today.getFullYear(), today.getMonth(), 1),"yyy-MM-dd");
      if(user){
        let req = { 
          "account_pk":parseInt(user.account_pk),
          "from_date":from_date,
          "to_date":to_date
        };
        this.userService.getMySavingAmount(req).subscribe(
          res => {
            this.showLoader =false;
            if(res.success){
              this.amount = parseFloat(res.data) as any;
            }else{
              this.amount = 0;
            }
            document.getElementById("mySidenav").style.width = "400px";   
            document.getElementById("my-saving-btn").style.display = 'none';  
          },error => {
            this.showLoader =false;
            this.amount = 0;
            this.toasterService.error("No savings available");
          }
        )
      }
    }catch(error){
      this.showLoader =false;
      this.amount = 0;
      this.toasterService.error("No savings available");
    }
  }
  closeNav(){
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("my-saving-btn").style.display = 'block';  
  }

}
