import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/shared/services/REST/auth.service';
import { NavigationLink } from '../../../../shared/interfaces/navigation-link';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { Router, ActivatedRoute, NavigationEnd, Event } from '@angular/router';
import { ProductService } from 'src/app/shared/services/REST/product.service';
import { UserService } from 'src/app/shared/services/REST/user.service';
import { ModelService } from '../../../../shared/services/REST/model.service';
import { MenuService } from 'src/app/shared/services/REST/menu.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CartService } from 'src/app/shared/services/cart.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Inputdata, InputPartSearch, Product } from 'src/app/shared/interfaces/product';
import { ToastrService } from 'ngx-toastr';
import { blockPermissionMsg } from 'src/app/constant';
import { CookieService } from 'ngx-cookie';
import { secureStorage } from 'src/app/shared/services/securestorage';
import { Title } from '@angular/platform-browser';
declare var $: any;
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnChanges {

  url = "shop/productcards"
  prodcutmenuData = [];
  prodcutsubmenuData = [];
  showLoader: boolean = false;
  modelAggregateData: any;
  modelName: any;
  modalRef1: any;
  activepopItem: any;
  modalRef: any;
  halfData: any;
  AllData: any;
  MenuName: any;
  SubMenuShow: boolean = false;
  RedirectUrl: boolean = false;
  items: NavigationLink[];
  Pplitems: [];
  Modelitems: [];
  Plitems = [];
  halfPgLineitems = [];
  AllPgLineitems = [];
  Allitems = [];
  BusesItem = [];
  hoveredItem: NavigationLink = null;
  isLoggedIn: boolean = false;
  activeMenuItem = 'Home';
  activeSubMenuItem = '';
  activepplItem = '';
  activeliItem = '';
  activeProductModel = '';
  categoryType: string;
  ShowAddress: boolean = false;
  addresses: any = [];
  usertype: any;
  SelectAddressId: any;
  addressto: any;
  searchBy: any;
  routeData: any;
  @Output() routeChange = new EventEmitter<any>();
  @Output() addressChange = new EventEmitter<any>();
  @Output() setAddressChange = new EventEmitter<any>();
  halfProductsList: any[];
  AllProductsList: any[];
  halfProducts: any[];
  AllProducts: any[];
  @Input() setAddress: boolean;
  @Input() pinCodeNumber: number;
  @ViewChild('addressModal', { read: TemplateRef, static: false })
  template1: TemplateRef<any>;
  @ViewChild('confimationmodal', { read: TemplateRef, static: false })
  template2: TemplateRef<any>;
  @ViewChild('allModels', { read: TemplateRef, static: false })
  allModels: TemplateRef<any>;
  allModelsModal: any;
  default_address: any;
  isDeliveryAddressModal: boolean = false;
  currentPage;
  totalcount;
  updateForm = new FormGroup({});
  updateFormDelivery = new FormGroup({});
  idbillchanges: any = 'No';
  isDeliveruyChange: any = 'No';
  temparray: any;
  Dtemparray: any;
  singleProduct = [];
  currentUrl: string = '';
  queryParameter: string = '';
  isProduct: boolean = true;
  isModel: boolean = false;
  addingToCart = false;
  quantity: number;
  currentQuantity: number = 1;
  btnshowConfrimed: boolean = false;
  btndisabled: boolean = false;
  btnshowsubmit: boolean = false;

  // ACTIVE ORDERS
  orderStatistics = [];
  allItems: any;
  inTransit: any;
  Pending: any;
  cancelled: any;
  deliveredCount: number = 0;
  invoicedCount: number = 0;
  confirmedCount: number = 0;
  isActiveFilter: boolean = false;
  today: any;
  cancelledCount: number = 0;
  PendingCount: number = 0;
  outForDeliveryCount: number = 0;
  allItemsCount: number = 0;
  isOutOfDeliveryStatus: boolean = false;
  orderDetailsModal: any;
  isCancelOrderSelected: boolean = false;
  isOrderInProcessSelected: boolean = false;
  isFeedBackSelected: boolean = false;
  isOTCSelected: boolean = false;
  isReturn: boolean = true;
  orderInformations: any;
  activeOrders: any[];
  urlData: any;
  urlString: string = '';
  isActiveOrders: boolean = false;
  loading: boolean;
  result: boolean;
  showPagination: boolean;
  account_pk: any;
  shippingaddressid: any;
  page: any;
  pagestart: any;
  prodend: any;
  ProductListData: any[] = [];
  isNoDataFound: boolean;
  defaultAddress: any;
  getordertotal = 0;
  mysaving = 0;
  base_discount_amount = 0;
  scheme_discount_amount = 0;
  tml_rule_discount_amount = 0;
  dealer_rule_discount_amount = 0;
  fastconsumecount = 0;
  fastconsumeListProducts;
  cartTypeList: any;
  selectcarttype: any;
  selectedProduct: Product;
  billingaddressid: string;
  baseProductdata: any;
  baseProductdiscount: any;
  gotohome
  homeList;
  prodcardbreadcrumb = [];
  mainheader = [];
  isMenuProductLoaded: boolean = false;
  userData: any;
  productInfo:any=[]
  constructor(
    private appStateService: AppStateService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private productService: ProductService,
    private localstorage: secureStorage,
    private userService: UserService,
    private title: Title,
    private authService: AuthService,
    private router: Router,
    private model: ModelService,
    private menu: MenuService,
    private modalService1: BsModalService,
    private toastrService: ToastrService,
    private cart: CartService,
    private cd: ChangeDetectorRef,
    private modalService: BsModalService,
    private toasterService: ToastrService
  ) {
    this.appStateService.isLoggedIn.subscribe((isLog) => {
      this.isLoggedIn = isLog;
    });


    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        //this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        if (!this.isLoggedIn) {
          this.queryParameter =
            event.url.split('=', 2)[1] == 'durafit'
              ? 'DURAFIT'
              : event.url.split('=', 2)[1] == 'prolife'
                ? 'PROLIFE'
                : event.url.split('=', 2)[1] == 'oillubricants'
                  ? 'OIL, GREASE & DEF'
                  : event.url.split('=', 2)[1] == 'fast-consumables'
                    ? 'RECENTLY ORDERED'
                    : event.url.split('=', 2)[1] == 'kit'
                      ? 'SERVICE KIT'
                      : event.url.split('/', 3)[2] == 'productcards'
                        ? 'POPULAR MODELS'
                        : this.currentUrl.includes('myoffer')
                            ? 'OFFERS'
                        : '';
        } else if (this.isLoggedIn) {
          this.queryParameter = event.url.split('=', 2)[1] == 'durafit'
            ? 'DURAFIT'
            : event.url.split('=', 2)[1] == 'prolife'
              ? 'PROLIFE'
              : event.url.split('=', 2)[1] == 'oillubricants'
                ? 'OIL, GREASE & DEF'
                : event.url.split('=', 2)[1] == 'kit'
                  ? 'SERVICE KIT'
                  : this.currentUrl.includes('home')
                    ? 'MY DASHBOARD'
                    : this.currentUrl.includes('orders')
                      ? 'ORDER'
                      : this.currentUrl.includes('report')
                        ? 'REPORTS'
                        : this.currentUrl.includes('profile') || this.currentUrl.includes('accountlist') || this.currentUrl.includes('userRegistrationList') || this.currentUrl.includes('userpermission') || this.currentUrl.includes('addbulkcart') || this.currentUrl.includes('setpassword')
                          ? 'MY VEHICLES'
                          : this.currentUrl === "/"
                            ? 'HOME'
                            : this.currentUrl.includes("fast-consumables")
                            ? 'RECENTLY ORDERED'
                            : this.currentUrl.includes('myoffer') || this.currentUrl.includes('coupons') || this.currentUrl.includes('schemes')
                            ? 'MY OFFERS'
                            : this.currentUrl.includes('credit-list')
                            ? 'MY CREDIT'
                            : '';
          this.changeMenusAfterLogin();
        }
        this.urlData = event.url;
        this.urlString = event.url;
      }
    });
    let currentUrl = this.router.url;
    this.queryParameter = currentUrl.includes('durafit')
      ? 'DURAFIT'
      : currentUrl.includes('prolife')
        ? 'PROLIFE'
        : currentUrl.includes('oillubricants')
          ? 'OIL, GREASE & DEF'
          : currentUrl.includes('fast-consumables')
            ? 'RECENTLY ORDERED'
            : currentUrl.includes('kit')
              ? 'SERVICE KIT'
              : currentUrl.includes('productcards')
                ? 'POPULAR MODELS'
                : currentUrl.includes('home')
                  ? 'MY DASHBOARD'
                  : currentUrl.includes('orders')
                    ? 'ORDER'
                    : currentUrl.includes('report')
                      ? 'REPORTS'
                      : currentUrl.includes('profile') || currentUrl.includes('accountlist') || currentUrl.includes('userRegistrationList') || currentUrl.includes('userpermission') || currentUrl.includes('addbulkcart') || currentUrl.includes('setpassword')
                        ? 'MY VEHICLES'
                        : currentUrl === "/"
                          ? 'HOME'
                          : currentUrl.includes('myoffer') || currentUrl.includes('coupons') || currentUrl.includes('schemes')
                          ? 'MY OFFERS'
                          : currentUrl.includes('credit-list')
                          ? 'MY CREDIT'
                          : '' ;

  }

  ngOnInit() {
    $(document).ready(function () {
      initMenu();
    });
    $("#menu-toggle").click(function (e) {
      e.preventDefault();
      $("#wrapper").toggleClass("toggled");
    });
    $("#menu-toggle-2").click(function (e) {
      e.preventDefault();
      $("#wrapper").toggleClass("toggled-2");
      $('#menu ul').hide();
    });

    function initMenu() {
      $('#menu ul').hide();
      $('#menu ul').children('.current').parent().show();
      //$('#menu ul:first').show();
      $('#menu li a').click(
        function () {
          var checkElement = $(this).next();
          if ((checkElement.is('ul')) && (checkElement.is(':visible'))) {
            return false;
          }
          if ((checkElement.is('ul')) && (!checkElement.is(':visible'))) {
            $('#menu ul:visible').slideUp('normal');
            checkElement.slideDown('normal');
            return false;
          }
        }
      );
    }
    this.userData = JSON.parse(this.localstorage.getItem('userData'));
    this.route.queryParams.subscribe((params) => {
      this.prodcardbreadcrumb = JSON.parse(this.localstorage.getItem('productcardBreadcrumb'));
    });
    if (this.isProduct) {
      this.mainheader.push(
        {
          label: 'All Categories',
          url: ' ',
        },
        {
          label: 'Product',
          url: ' ',
        }
      );
      this.localstorage.setItem(
        'mainheaderBreadcrumb',
        JSON.stringify(this.mainheader)
      );
    }
    else {
      this.mainheader.push(
        {
          label: 'All Categories',
          url: ' ',
        },
        {
          label: 'Model',
          url: ' ',
        }
      );
      this.localstorage.setItem(
        'mainheaderBreadcrumb',
        JSON.stringify(this.mainheader)
      );
    }
    this.showProducts();
    this.showModels();
    this.showNewProduct();

    this.activeMenuItem = '';


    this.appStateService.isLoggedIn.subscribe((isLog) => {
      this.isLoggedIn = isLog;
      if (this.isLoggedIn) {
        this.route.queryParams.subscribe((params) => {
          this.categoryType = params['type'];
        });
        this.ShowAddress = true;
        this.SelectAddressId = this.localstorage.getItem('AddressId');
        let userData = JSON.parse(this.localstorage.getItem('userData'));
        let usersData = JSON.parse(this.localstorage.getItem('user'));
        const ListInput: GetAddressparameter = {} as GetAddressparameter;
        ListInput.account_pk = JSON.stringify(userData.account_pk);
        ListInput.offset = 0;
        ListInput.limit = 10;
        ListInput.state = '';
        ListInput.district = '';
        ListInput.city = '';
        // this.BindPageList();
        const token = this.cookieService.getObject('access_token');
        const tokenobj = { token: token }
        if (userData?.isDelegateUser === "true" || userData?.isDelegateUser === true) {
          this.GetAccessPermission(tokenobj);
        }
        // this.getAddressNew(ListInput);
        this.getActiveOrders(0, false);
        this.fastconsume();


        this.appStateService.afterLoginPublish.subscribe((isaftermenu) => {
          if (this.localstorage.getItem("displayMenu") === "PostLoginMenu") {
            let afterLoginMainHeader = JSON.parse(this.localstorage.getItem('afterLoginMainHeader'));
            let orderMenuList = JSON.parse(this.localstorage.getItem('orderMenuList'));
            let ordersUrl = orderMenuList == false ? '' : orderMenuList[0].page_url;
            let ordersName = orderMenuList == false ? '' : orderMenuList[0].page_display_name;
            let afterloginItems = [
              {
                labelID: '',
                label: 'HOME',
                url: '',
                icon: '',
                params: {},
                menu: {
                  type: 'menu',
                  items: [],
                },
              },
              {
                labelID: '',
                label: 'MY DASHBOARD',
                url: '/account/home',
                icon: '',
                params: {},
                menu: {
                  type: 'menu',
                  items: [],
                },
              },
              {
                labelID: '',
                label: 'ORDER',
                url: ordersUrl,
                icon: '',
                submenu: ordersName,
                params: ordersUrl == '/orders/active-orders' ? { isViewAll: true } : {},
                menu: {
                  type: 'menu',
                  items: [],
                },
              },
              {
                labelID: '',
                label: 'REPORTS',
                url: '/account/report',
                icon: '',
                params: {},
                menu: {
                  type: 'menu',
                  items: [],
                },
              },
              {
                labelID: '',
                label: 'MY VEHICLES',
                url: '/account/profile',
                icon: '',
                params: { isMyVehicles: true },
                menu: {
                  type: 'menu',
                  items: [],
                },
              },
              {
                labelID: '',
                label: 'MY OFFERS',
                url: '/account/myoffer',
                icon: '',
                params: { isMyOffer: true },
                menu: {
                  type: 'menu',
                  items: [],
                },
              }
            ];
            this.homeList = afterloginItems.filter((obj) => {
              for (let i = 0; i <= afterloginItems.length; i++) {
                for (let j = 0; j <= afterLoginMainHeader?.length; j++) {
                  if (obj.label == afterLoginMainHeader[i]) {
                    return obj;
                  }
                }
              }
            });
            this.items = this.homeList;
            // if(this.userData?.user_type != 'MECH'){
            //   this.items  = this.items.filter(
            //     items => items.label != 'MY OFFERS');
            // }
          }
          else {
            this.items = [
              {
                labelID: '',
                label: 'DURAFIT',
                url: '/shop/productcards',
                icon: './assets/images/menuicon/durafit black.png',
                params: { searchBy: 'durafit' },
                menu: {
                  type: 'menu',
                  items: [],
                },
              },
              {
                labelID: '',
                label: 'PROLIFE',
                url: '/shop/productcards',
                icon: '',
                params: { searchBy: 'prolife' },
                menu: {
                  type: 'menu',
                  items: [],
                },
              },
              {
                labelID: '',
                label: 'OIL, GREASE & DEF',
                url: '/shop/productcards',
                icon: './assets/images/menuicon/oil black.png',
                params: { searchBy: 'oillubricants' },
                menu: {
                  type: 'menu',
                  items: [],
                },
              },
              {
                labelID: '',
                label: 'SERVICE KIT',
                url: '/shop/productcards',
                icon: './assets/images/menuicon/kit part black.png',
                params: { searchBy: 'kit' },
                menu: {
                  type: 'menu',
                  items: [],
                },
              },
              {
                labelID: '',
                label: 'RECENTLY ORDERED',
                url: '/shop/productcards',
                icon: '',
                params: { searchBy: 'fast-consumables' },
                menu: {
                  type: 'menu',
                  items: [],
                },
              },
              {
                labelID: '',
                label: 'MY DASHBOARD',
                url: '/account/home',
                icon: '',
                params: {},
                menu: {
                  type: 'menu',
                  items: [],
                },
              },
            ];
          }
        });
      } else {

        this.ShowAddress = false;
        this.items = [
          {
            labelID: 'Model',
            label: 'POPULAR MODELS',
            url: '/shop/productcards',
            icon: './assets/images/menuicon/truck icon black.png',
            menu: {
              type: 'menu',
              items: [],
            },
          },
          {
            labelID: '',
            label: 'DURAFIT',
            url: '/shop/productcards',
            icon: './assets/images/menuicon/durafit black.png',
            params: { searchBy: 'durafit' },
            menu: {
              type: 'menu',
              items: [],
            },
          },
          {
            labelID: '',
            label: 'PROLIFE',
            url: '/shop/productcards',
            icon: '',
            params: { searchBy: 'prolife' },
            menu: {
              type: 'menu',
              items: [],
            },
          },
          {
            labelID: '',
            label: 'OIL, GREASE & DEF',
            url: '/shop/productcards',
            icon: './assets/images/menuicon/oil black.png',
            params: { searchBy: 'oillubricants' },
            menu: {
              type: 'menu',
              items: [],
            },
          },
          {
            labelID: '',
            label: 'RECENTLY ORDERED',
            url: '/shop/productcards',
            icon: '',
            params: { searchBy: 'fast-consumables' },
            menu: {
              type: 'menu',
              items: [],
            },
          },
          {
            labelID: '',
            label: 'SERVICE KIT',
            url: '/shop/productcards',
            icon: './assets/images/menuicon/kit part black.png',
            params: { searchBy: 'kit' },
            menu: {
              type: 'menu',
              items: [],
            },
          },
          {
            labelID: '',
            label: 'OFFERS',
            url: '/account/myoffer',
            icon: '',
            params: { isMyOffer: true },
            menu: {
              type: 'menu',
              items: [],
            },
          },
        ];
        if(this.userData?.user_type && this.userData?.user_type != 'MECH'){
          this.items  = this.items.filter(
            items => items.label != 'OFFERS');
        }
      }
    });
    this.appStateService.refreshOrderSummary.subscribe(refresh => {
      if (refresh) {
        this.getActiveOrders(0, false);
        this.appStateService.refreshOrderSummary.next(null);
      }
    });
    this.appStateService.accountSwitched.subscribe(isSwitch => {
      if (isSwitch) {
        this.appStateService.accountSwitched.next(null);
        this.fastconsume();
        this.getActiveOrders(0, false);
        this.checkCreditDetails();
        //this.cart.load();
      }
    })
    if(!this.router.url.includes("shop/productcards") && this.router.url !== "/"){
      this.checkCreditDetails();
    }
  }
  changeMenusAfterLogin() {
    if (this.router.url.includes("account/home") || this.router.url.includes("orders/active-orders") || this.router.url.includes("account/profile") || this.router.url.includes("account/address") || this.router.url.includes("account/setpassword") || this.router.url.includes("orders/ecom-orders") || this.router.url.includes("cart/address")) {
      this.localstorage.setItem("displayMenu", "PostLoginMenu");
      let afterLoginMainHeader = JSON.parse(this.localstorage.getItem('afterLoginMainHeader'));
      let orderMenuList = JSON.parse(this.localstorage.getItem('orderMenuList'));
      let ordersUrl = orderMenuList == false ? '' : orderMenuList[0].page_url;
      let ordersName = orderMenuList == false ? '' : orderMenuList[0].page_display_name;
      let afterloginItems = [
        {
          labelID: '',
          label: 'HOME',
          url: '',
          icon: '',
          params: {},
          menu: {
            type: 'menu',
            items: [],
          },
        },
        {
          labelID: '',
          label: 'MY DASHBOARD',
          url: '/account/home',
          icon: '',
          params: {},
          menu: {
            type: 'menu',
            items: [],
          },
        },
        {
          labelID: '',
          label: 'ORDER',
          url: ordersUrl,
          icon: '',
          submenu: ordersName,
          params: ordersUrl == '/orders/active-orders' ? { isViewAll: true } : {},
          menu: {
            type: 'menu',
            items: [],
          },
        },
        {
          labelID: '',
          label: 'REPORTS',
          url: '/account/report',
          icon: '',
          params: {},
          menu: {
            type: 'menu',
            items: [],
          },
        },
        {
          labelID: '',
          label: 'MY VEHICLES',
          url: '/account/profile',
          icon: '',
          params: { isMyVehicles: true },
          menu: {
            type: 'menu',
            items: [],
          },
        },
        {
          labelID: '',
          label: 'MY OFFERS',
          url: '/account/myoffer',
          icon: '',
          params: { isMyOffer: true },
          menu: {
            type: 'menu',
            items: [],
          },
        }
      ];
      this.homeList = afterloginItems.filter((obj) => {
        for (let i = 0; i <= afterloginItems.length; i++) {
          for (let j = 0; j <= afterLoginMainHeader?.length; j++) {
            if (obj.label == afterLoginMainHeader[i]) {
              return obj;
            }
          }
        }
      });
      this.items = this.homeList;
      // if(this.userData?.user_type != 'MECH'){
      //   this.items  = this.items.filter(
      //     items => items.label != 'MY OFFERS');
      // }
      this.checkCreditDetails();
    }
    else if (this.router.url === "/") {
      this.localstorage.setItem("displayMenu", "PreloginMenu");
      this.items = [
        {
          labelID: '',
          label: 'DURAFIT',
          url: '/shop/productcards',
          icon: './assets/images/menuicon/durafit black.png',
          params: { searchBy: 'durafit' },
          menu: {
            type: 'menu',
            items: [],
          },
        },
        {
          labelID: '',
          label: 'PROLIFE',
          url: '/shop/productcards',
          icon: '',
          params: { searchBy: 'prolife' },
          menu: {
            type: 'menu',
            items: [],
          },
        },
        {
          labelID: '',
          label: 'OIL, GREASE & DEF',
          url: '/shop/productcards',
          icon: './assets/images/menuicon/oil black.png',
          params: { searchBy: 'oillubricants' },
          menu: {
            type: 'menu',
            items: [],
          },
        },
        {
          labelID: '',
          label: 'SERVICE KIT',
          url: '/shop/productcards',
          icon: './assets/images/menuicon/kit part black.png',
          params: { searchBy: 'kit' },
          menu: {
            type: 'menu',
            items: [],
          },
        },
        {
          labelID: '',
          label: 'RECENTLY ORDERED',
          url: '/shop/productcards',
          icon: '',
          params: { searchBy: 'fast-consumables' },
          menu: {
            type: 'menu',
            items: [],
          },
        },
        {
          labelID: '',
          label: 'MY DASHBOARD',
          url: '/account/home',
          icon: '',
          params: {},
          menu: {
            type: 'menu',
            items: [],
          },
        },
      ];
    }else if(!this.router.url.includes("shop/productcards")){
      this.checkCreditDetails();
    }
  }

  checkCreditDetails(){
    let userType = JSON.parse(this.localstorage.getItem('userData'))
    if(userType){
      let req = {
        "account_pk": userType.account_pk,
        "action_type": "user_credit_list",
        "offset": 0,
        "limit": 10
      }
      let index = -1;
      this.userService.getCreditList(req).subscribe(
        (response) => {
          if ( response.success && response.total_result > 0 ) {
            index = this.items.findIndex(item => item.label === 'MY CREDIT');
            if(index == -1){
              this.items.push({
                labelID: '',
                label: 'MY CREDIT',
                url: '/account/credit-list',
                icon: '',
                params: { },
                menu: {
                  type: 'menu',
                  items: [],
                }
              })
            }
          }else if (!response.success || response.total_result<=0 ) {
            index = this.items.findIndex(item => item.label === 'MY CREDIT');
            index > -1 
            ? this.items.splice(index,1)
            :''
          }
        },error => {
          index = this.items.findIndex(item => item.label === 'MY CREDIT');
          index > -1 
          ? this.items.splice(index,1)
          :''
        }
      )
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['setAddress'] !== undefined) {
      if (changes['setAddress']['currentValue'] == true) {
        this.isDeliveryAddressModal = true;
        this.SelectAddress();
        this.setAddressChange.emit();
      }
    }
  }
  async BindPageList() {
    try {
      localStorage.removeItem('PageList');
      let user = JSON.parse(this.localstorage.getItem('userData'));
      if (user) {
        await this.authService.GetPageList({ account_pk: user.account_pk }).subscribe(
          (response) => {
            if (response.success === true) {
              let afterLoginMainHeader = ['HOME'];
              let layoutMenu = [
                // 'Set Password',
                'Account Management',
                'User Management',
                'User permission',
                'Bulk Order Upload',
              ];
              let ordersMenu = [
                'Active Order',
                'Order History',
                'Orders In-Process',
                'Cancel Order History',
                'Invoice Return',
              ];
              let orderHistoryMenu = ['E-Com Orders', 'CRM Orders', 'Invoice Payment'];

              var datas: any[] = response.data;
              //profile menu start
              let profilelist = datas.filter((obj) => {
                for (let i = 0; i <= layoutMenu.length; i++) {
                  if (obj.page_display_name == layoutMenu[i]) {
                    obj.page_url = '/' + obj.page_url;
                    obj.isPermission = true;
                    return obj;
                  } else if (obj.page_display_name == 'My Profile') {
                    obj.page_url = '/' + obj.page_url;
                    obj.isPermission = true;
                    this.localstorage.setItem(
                      'isProfilePermission',
                      JSON.stringify(obj.isPermission)
                    );
                    this.appStateService.isProfilePermission.next(true);
                    return obj;
                  } else if (obj.page_display_name == 'Vehicle Management') {
                    obj.isPermission = true;
                    this.localstorage.setItem(
                      'isVehiclePermission',
                      JSON.stringify(obj.isPermission)
                    );
                    this.appStateService.isVehiclePermission.next(true);
                  } else {
                  }
                }
              });
              this.localstorage.setItem(
                'profileLayoutMenu',
                JSON.stringify(profilelist)
              );

              this.appStateService.isprofilelist.next(true);
              //profile menu end
              //----------------------------//
              //orderhistory start//
              let orderHistoryMenuAllowed = datas.filter((obj) => {
                for (let i = 0; i <= orderHistoryMenu.length; i++) {
                  if (obj.page_display_name == orderHistoryMenu[i]) {
                    obj.page_url = '/' + obj.page_url;
                    obj.isPermission = true;
                    return obj;
                  }
                }
              });
              this.localstorage.setItem(
                'orderHistoryMenuList',
                JSON.stringify(orderHistoryMenuAllowed)
              );
              //orderhistory end//
              //----------------------------//
              //orders menu start
              let orderMenuList = datas.filter((obj) => {
                for (let i = 0; i <= ordersMenu.length; i++) {
                  if (obj.page_display_name == ordersMenu[i]) {
                    obj.page_url =
                      obj.page_display_name == 'Order History'
                        ? '/orders/ecom-orders'
                        : obj.page_url.includes('account')
                          ? '/' + obj.page_url.replace('account', 'orders')
                          : '/' + obj.page_url;
                    obj.page_display_name =
                      obj.page_display_name == 'Cancel Order History'
                        ? 'Cancel Order'
                        : obj.page_display_name;
                    obj.isPermission = true;
                    return obj;
                  }
                }
              });

              let sortedOrderMenu: any[] = [];
              orderMenuList.forEach((menu) => {
                if (menu.page_display_name === 'Active Order') {
                  sortedOrderMenu.splice(0, 0, menu);
                }
                if (menu.page_display_name === 'Order History') {
                  sortedOrderMenu.splice(1, 0, menu);
                }
                if (menu.page_display_name === 'Orders In-Process') {
                  sortedOrderMenu.splice(2, 0, menu);
                }
                if (menu.page_display_name === 'Cancel Order') {
                  sortedOrderMenu.splice(3, 0, menu);
                }
                if (menu.page_display_name === 'Invoice Return') {
                  sortedOrderMenu.splice(4, 0, menu);
                }
              });
              this.localstorage.setItem(
                'orderMenuList',
                JSON.stringify(sortedOrderMenu)
              );

              //orders menu close
              //----------------------------//
              //after login menu start
              let reportsPermission = datas.filter((obj) => {
                if (obj.page_display_name == 'Order Tracking') {
                  obj.page_url = '/' + obj.page_url;
                  obj.isPermission = true;
                  return obj;
                }
              });
              if (orderMenuList?.length >= 1) {
                afterLoginMainHeader.push('ORDER');
              }
              if (reportsPermission?.length >= 1) {
                afterLoginMainHeader.push('REPORTS');
              }
              afterLoginMainHeader.splice(1, 0, 'MY DASHBOARD');
              afterLoginMainHeader.push('MY VEHICLES');
              if(this.userData?.user_type && this.userData?.user_type != 'MECH'){
              afterLoginMainHeader.push('MY OFFERS');
              }
              this.localstorage.setItem('afterLoginMainHeader', JSON.stringify(afterLoginMainHeader));
              this.appStateService.afterLoginPublish.next(true);
              //after login menu close
              //----------------------------//
              //dropdown menu start

              let orderHistoryProfilePermission = datas.filter((obj) => {
                if (obj.page_display_name == 'Order History') {
                  obj.isPermission = true;
                  return obj;
                }
              });

              let orderHistoryProfileBehaviour: boolean = orderHistoryProfilePermission.length == 0 ? false : true;
              this.localstorage.setItem(
                'orderHistoryProfilePermission',
                JSON.stringify(orderHistoryProfileBehaviour)
              );
              this.appStateService.isorderHistoryProfilePermission.next(
                orderHistoryProfileBehaviour
              );
              // let changePasswordPermission = datas.filter((obj) => {
              //   if (obj.page_display_name == 'Set Password') {
              //     obj.isPermission = true;
              //     return obj;
              //   }
              // });
              // let changePassBehaviour: boolean =  changePasswordPermission.length == 0 ?  false : true;

              // localStorage.setItem(
              //   'changePasswordPermission',
              //   JSON.stringify(changePassBehaviour)
              // );
              // this.appStateService.isChangepasswordPermission.next(
              //   changePassBehaviour
              // );
              // dropdown menu end
              //----------------------------//
              //eta start
              //   let isETAReport  = datas.filter((obj) => {
              //   for(let i=0;i<=ordersMenu.length;i++){
              //   if(obj.page_display_name == 'ETA Report'){
              //     obj.page_url = "/" + obj.page_url;;
              //     obj.isPermission=true;
              //     return obj;
              //   }
              //   }
              //   });
              // this.localstorage.setItem(
              //   'isETAReport',
              //   JSON.stringify(isETAReport)
              // );
              //eta end
            } else {
              let setAllPermission = false;
              // localStorage.setItem(
              //   'changePasswordPermission',
              //   JSON.stringify(setAllPermission)
              // );
              this.localstorage.setItem(
                'orderHistoryProfilePermission',
                JSON.stringify(setAllPermission)
              );
              this.localstorage.setItem('isProfilePermission', null);
              this.localstorage.setItem('isVehiclePermission', null);

              this.appStateService.isProfilePermission.next(null);
              this.appStateService.isVehiclePermission.next(null);
              this.localstorage.setItem(
                'profileLayoutMenu',
                JSON.stringify(setAllPermission)
              );
              this.localstorage.setItem(
                'orderMenuList',
                JSON.stringify(setAllPermission)
              );
              this.localstorage.setItem('orderHistoryMenuList', JSON.stringify(setAllPermission));
              let afterLoginMainHeader = ['HOME'];
              this.localstorage.setItem(
                'afterLoginMainHeader',
                JSON.stringify(afterLoginMainHeader)
              );

              this.appStateService.afterLoginPublish.next(true);
            }
          },
          (error) => { }
        );
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'BindPageList()',
        page_name: 'MenuComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }
  GetAccessPermission(token) {
    try {

      this.showLoader = true;
      this.authService.GetOtherUserPermissionList(token).subscribe((response) => {

        if (response.success) {
          var vehicleList = response.data;
          this.showLoader = false;
          localStorage.removeItem('OtherUserPermission');

          this.localstorage.setItem(
            'OtherUserPermission',
            JSON.stringify(response.data)
          );

          //  this.appStateService.OtherUserPermissionPublish.next(true);
        } else {
          //  this.appStateService.OtherUserPermissionPublish.next(false);
          this.showLoader = false;
        }
      }),
        (error) => {
          this.showLoader = false;
        };
    } catch (error) {
      this.userService.InsertExceptionLog({
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'PageLoginComponent',
        method_name: 'GetAccessPermission()',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }
  showProducts() {
    this.activeMenuItem = '';

    let json = '{"size":22}';
    this.menu.GetPgLineList(json).subscribe(
      (data) => {
        this.halfProductsList = [];
        this.AllProductsList = [];
        this.halfProducts = [];
        this.AllProducts = [];
        if (data.success) {
          try {
            var datas = data.data.sort();
            for (var i = 0; i < datas.length; i++) {
              this.AllProducts.push(datas[i]);
              if (i < 10) {
                this.halfProducts.push(datas[i]);
              }
            }
            this.halfProductsList = this.halfProducts;
            this.AllProductsList = this.AllProducts;
          } catch (error) {
            this.userService.InsertExceptionLog({
              method_name: 'ShowMore(item)',
              page_name: 'MenuheaderComponent',
              portal_type: 'Customer',
              page_url: this.router.url,
              error_message: error.message,
              inner_exception: error,
            });
          }
        } else {
          this.halfProductsList = [];
          this.AllProductsList = [];
          this.halfProducts = [];
          this.AllProducts = [];
        }
      },
      (error) => { }
    );
  }

  showModels() {
    this.activeMenuItem = '';
    this.model.getAll().subscribe(
      (data) => {
        if (data.success) {
          this.halfPgLineitems = [];
          this.AllPgLineitems = [];
          this.halfData = [];
          this.AllData = [];
          try {
            var datas = data.result.sort();
            for (var i = 0; i < datas.length; i++) {
              this.AllData.push(this.FormNewData(datas[i].Model_Cat_s));
              if (i < 6) {
                this.halfData.push(this.FormNewData(datas[i].Model_Cat_s));
              }
            }

            this.halfPgLineitems = this.halfData;
            this.AllPgLineitems = this.AllData;
            this.modelName = [];
            this.modelName.push(this.AllData[0]);
            this.getModelName(this.AllData[0]);
          } catch (error) {
            this.userService.InsertExceptionLog({
              method_name: 'ShowMore(item)',
              page_name: 'MenuheaderComponent',
              portal_type: 'Customer',
              page_url: this.router.url,
              error_message: error.message,
              inner_exception: error,
            });
          }
        } else {
          this.halfPgLineitems = [];
          this.AllPgLineitems = [];
          this.halfData = [];
          this.AllData = [];
        }
      },
      (error) => {
        this.halfPgLineitems = [];
        this.AllPgLineitems = [];
        this.halfData = [];
        this.AllData = [];
      }
    );
  }

  FormNewData(data): ModelData {
    const objdata: ModelData = {} as ModelData;
    objdata.LINE_2_s = data;
    return objdata;
  }
  FormNavMenu(data): NavMenu {
    const objdata: NavMenu = {} as NavMenu;
    objdata.navtext = data;
    return objdata;
  }
  SelectAddress() {
    this.modalRef1 = this.modalService1.show(this.template1, {
      class: 'modal-dialog-centered modal-lg address',
      backdrop: 'static',
    });
  }

  onRefresh() {
    let currentUrl = this.router.url;
    if (currentUrl.includes('product-details')) {
      this.router.navigateByUrl(currentUrl).then(() => {
        this.router.navigated = false;
        this.router.navigate([this.router.url]);
      });
    }
  }

  closemodel(event) {
    if (event === "Address changed") {
      this.appStateService.refreshCartOnAddressChange.next(true);
    }
    this.modalRef1.hide();
  }

  getAddressNew(ListInput) {
    try {
      let userData = JSON.parse(this.localstorage.getItem('userData'));
      let usersData = JSON.parse(this.localstorage.getItem('user'));
      if (usersData) {
        this.usertype = usersData.user_type;
        this.productService.getAddressList(ListInput).subscribe((response) => {
          if (response.success === true) {
            var Data = response.data;
            if (this.usertype !== 'FO' && this.usertype !== 'GU') {
              this.addresses = Data.filter((Data) => Data.default === true);
              this.totalcount = 1;
              this.currentPage = 1;
            } else {
              this.addresses = Data;
              this.totalcount = response.total_result;
              this.currentPage = 1;
            }
            this.updateForm = new FormGroup({});
            this.updateFormDelivery = new FormGroup({});
            let Daddress = JSON.parse(this.localstorage.getItem('address'));
            let Baddress = JSON.parse(this.localstorage.getItem('billingAddress'));

            var BaddressId = Baddress?.address.address_id;
            var DaddressId = Daddress?.address.address_id;
            for (let formModule of this.addresses) {
              if (formModule.address_id == BaddressId) {
                this.updateForm.addControl(
                  formModule.address_id,
                  new FormControl(true)
                );
              } else {
                this.updateForm.addControl(
                  formModule.address_id,
                  new FormControl(false)
                );
              }
            }
            for (let formModule of this.addresses) {
              if (formModule.address_id == DaddressId) {
                this.updateFormDelivery.addControl(
                  formModule.address_id,
                  new FormControl(true)
                );
              } else {
                this.updateFormDelivery.addControl(
                  formModule.address_id,
                  new FormControl(false)
                );
              }
            }
            let userAddress = {
              address: response.data.default_address,
              type: 'default',
            };
          }
        });
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'getAddressNew(check)',
        page_name: 'MenuheaderComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  onpagechange(event) {
    let userData = JSON.parse(this.localstorage.getItem('userData'));
    let usersData = JSON.parse(this.localstorage.getItem('user'));
    const ListInput: GetAddressparameter = {} as GetAddressparameter;
    ListInput.account_pk = JSON.stringify(userData.account_pk);
    ListInput.offset = (event - 1) * 10;
    ListInput.limit = 100;
    ListInput.state = '';
    ListInput.district = '';
    ListInput.city = '';
    this.getAddressNew(ListInput);
  }

  onChangeBillig(event) {
    this.idbillchanges = 'Yes';
    this.temparray = [];
    this.temparray.push(event);
    var BaddressId = event.address_id; //Baddress.address.address_id;
    for (const field in this.updateForm.controls) {
      // 'field' is a string
      if (BaddressId == field) {
        this.updateForm.get(field).setValue(true);
      } else {
        this.updateForm.get(field).setValue(false);
      }
    }
  }

  onDeliveryChange(event) {
    this.isDeliveruyChange = 'Yes';
    this.Dtemparray = [];
    this.Dtemparray.push(event);
    var DaddressId = event.address_id; // Daddress.address.address_id;
    for (const field1 in this.updateFormDelivery.controls) {
      // 'field' is a string
      if (DaddressId == field1) {
        this.updateFormDelivery.get(field1).setValue(true);
      } else {
        this.updateFormDelivery.get(field1).setValue(false);
      }
    }
  }

  viewAll(item, type, isMenuModelViewAll?, isMenuProductViewAll?) {
    this.activeMenuItem = '';
    if (type == 'POPULAR MODELS') {
      if (isMenuModelViewAll) {
        this.viewAllModels();
        // this.prodcardbreadcrumb = [];
        // this.prodcardbreadcrumb.push({
        //   label: "Models",
        //   url: item.url ? item.url : '/shop/productcards',
        //   params: { ModelName: item?.LINE_2_s, type: 'Model', isMenuModelViewAll: true },
        // });
        // localStorage.removeItem('productcardBreadcrumb');
        // this.localstorage.setItem('productcardBreadcrumb', JSON.stringify(this.prodcardbreadcrumb));
        this.toggleMenu();
        // this.router.navigate(['/shop/productcards'], {
        //   queryParams: { ModelName: item?.LINE_2_s, type: 'Model', isMenuModelViewAll: true }
        // });
      }
      else {
        if (isMenuProductViewAll) {
          this.prodcardbreadcrumb = [];
          this.prodcardbreadcrumb.push({
            label: "Products",
            url: item.url ? item.url : '/shop/productcards',
            params: { ModelName: item?.LINE_2_s, type: 'Model' },
          });
          localStorage.removeItem('productcardBreadcrumb');
          this.localstorage.setItem('productcardBreadcrumb', JSON.stringify(this.prodcardbreadcrumb));
        }
        else {
          this.prodcardbreadcrumb = [];
          this.prodcardbreadcrumb.push({
            label: item.LINE_2_s,
            url: item.url ? item.url : '/shop/productcards',
            params: { ModelName: item?.LINE_2_s, type: 'Model' },
          });
          localStorage.removeItem('productcardBreadcrumb');
          this.localstorage.setItem('productcardBreadcrumb', JSON.stringify(this.prodcardbreadcrumb));
        }
        this.toggleMenu();
        this.router.navigate(['/shop/productcards'], {
          queryParams: { ModelName: item?.LINE_2_s, type: 'Model' }
        });
      }
    }
    if (type == 'PARTS CATEGORIES') {
      if (isMenuProductViewAll) {
        this.prodcardbreadcrumb = [];
        this.prodcardbreadcrumb.push({
          label: "Products",
          url: item.url ? item.url : '/shop/productcards',
          params: { ModelName: item?.LINE_2_s, type: 'Model' },
        });
        localStorage.removeItem('productcardBreadcrumb');
        this.localstorage.setItem('productcardBreadcrumb', JSON.stringify(this.prodcardbreadcrumb));
      }
      this.toggleMenu();
      this.router.navigate(['/shop/productcards'], {
        queryParams: { searchBy: item.line_2, type: type },
      });
    }
  }
  click(item) {
    this.updateTitle(item);
    this.activeMenuItem = item.label;
    if (item.label != 'POPULAR MODELS') {
      this.prodcardbreadcrumb = [];
      localStorage.removeItem('productcardBreadcrumb');
      this.prodcardbreadcrumb.push({
        label: item.label == 'ORDER' ? item.submenu : item.label,
        url: item.label == 'POPULAR MODELS' ? ' ' : item.url,
        params: item.params,
      });
      this.localstorage.setItem('productcardBreadcrumb', JSON.stringify(this.prodcardbreadcrumb))
    }
    // else{
    //   this.queryParameter = "POPULAR MODELS";
    // }
  }

  clickProductModel() {
    if (this.activeProductModel == '') {
      this.activeProductModel = 'ProductModel';
    }
    else {
      // this.activeProductModel = '';
    }

  }

  async getModelName(item) {
    this.prodcardbreadcrumb = [];
    this.activeMenuItem = '';
    this.modelName = [];
    this.modelName.push(item);
    this.prodcardbreadcrumb.push({
      label: item.LINE_2_s,
      url: this.url,
      params: {
        ModelName: item.LINE_2_s,
        type: 'Model',
      },
    });

    const ListInput: Inputdata = {} as Inputdata;
    ListInput.offset = 0;
    // ListInput.limit = 24;
    ListInput.limit = 100;
    ListInput.dropdown_type = 'aggregate';
    ListInput.multi_model_cat = [this.modelName[0].LINE_2_s];
    ListInput.is_image_required = true;
    let response = await this.model.getModelCategory(ListInput);
    if (response['success'] == true) {
      this.modelAggregateData = [];
      this.modelAggregateData = response['data']['Aggregates'];
      if (this.modelAggregateData?.length > 0) {
        this.modelAggregateData = this.modelAggregateData.sort((a, b) => {
          if (a.aggregate < b.aggregate) return -1;
          else if (a.aggregate > b.aggregate) return 1;
          else return 0;
        });
      }
    } else {
      this.modelAggregateData = [];
    }
  }


  gotoCatlog(item, type) {
    //via model
    if (type === 'aggregate') {

      this.prodcardbreadcrumb.push({
        label: item,
        url: this.url,
        params: {
          searchBy: item,
          ModelName: this.modelName[0].LINE_2_s,
          aggtype: type,
        },
      });

      let newarr = [...this.mainheader, ...this.prodcardbreadcrumb];
      this.localstorage.setItem('productcardBreadcrumb', JSON.stringify(newarr))
      this.toggleMenu();
      this.router.navigate(['/shop/productcards'], {
        queryParams: {
          searchBy: item,
          aggtype: type,
          ModelName: this.modelName[0].LINE_2_s,
        },
      });
    } else {
      // this.router.navigate(['/shop/Main'], { queryParams: { searchBy: 'model', search: item.trim(), type: type } });
    }
  }

  async showNewProduct() {
    this.activeMenuItem = '';
    const ListInput: Inputdata = {} as Inputdata;
    // ListInput.offset = 0;
    // ListInput.limit = 100;
    let response = await this.model.getProductMenu(ListInput);
    if (response['success'] == true) {
      this.prodcutmenuData = [];
      this.prodcutmenuData = response['data'];
      this.prodcutmenuData = this.prodcutmenuData.sort((a, b) => {
        if (a.group_category < b.group_category) return -1;
        else if (a.group_category > b.group_category) return 1;
        else return 0;
      });
      this.singleProduct = [];
      this.singleProduct.push(this.prodcutmenuData[0]);
      this.getProductName(this.prodcutmenuData[0]);
      // this.modelData = this.modelData.sort((a, b) => {
      //   if (a.model_name < b.model_name) return -1;
      //   else if (a.model_name > b.model_name) return 1;
      //   else return 0;
      // });
    } else {
      if (response['data']?.msg) {
        let usertypeMessage = "The account Id '" + JSON.parse(this.localstorage.getItem('userData')).account_id + "' you are trying to login, is not updated.";
        let errorMessage = (response['data'].msg === "Account not found") ? usertypeMessage : response['data'].msg;
        this.toasterService.error(errorMessage);
      }
      this.prodcutmenuData = [];
    }
  }

  async getProductName(item) {
    this.isMenuProductLoaded = false;
    this.singleProduct = [];
    this.singleProduct.push(item);
    const ListInput: Inputdata = {} as Inputdata;
    ListInput.group_category_list = item.category;
    let response = await this.model.getdataforhome(ListInput);
    if (response['success'] == true) {
      this.prodcutsubmenuData = [];
      var prodcatdata = response['data'];
      for (var type in response['data']) {
        let searchval: any[] = response['data'][type]['pg_line'];
        if (searchval?.length) {
          this.prodcardbreadcrumb = [];
          let searchValLine2 = searchval[0];
          this.prodcardbreadcrumb.push({
            label: item.group_category,
            url: this.url,
            params: {
              searchBy: searchValLine2?.line_2,
              type: 'PARTS CATEGORIES',
            },
          });
        }
        if (response['data'][type]['pg_line']) {
          this.prodcutsubmenuData.push(this.productsubmenuformData(response['data'][type]['pg_line'], type));
        }
        else {
          this.prodcutsubmenuData.push(this.productsubmenuformData([], type));
        }

      }
      this.isMenuProductLoaded = true;
      //  for(let i=0; i< prodcatdata.length; i++){
      //   this.prodcutsubmenuData.push(this.productsubmenuformData( prodcatdata[i] , item.category[i]))
      //  }
    } else {
      this.isMenuProductLoaded = true;
      this.prodcutsubmenuData = [];
    }
  }

  productsubmenuformData(categoylist: any, itemname: any): productsubmenu {
    const objstateData: productsubmenu = {} as productsubmenu;
    objstateData.groupcategoryname = categoylist?.length > 0 ? itemname : "";
    objstateData.categorylist = categoylist;
    return objstateData;
  }

  GotoPartCategory(partcategory, type, isViewAll) {

    // if (type === 'MainCategory') {
    localStorage.removeItem(
      'productcardBreadcrumb'
    );
    this.prodcardbreadcrumb.push({
      label: partcategory,
      url: this.url,
      params: { searchBy: partcategory, type: type, isViewAll: isViewAll },
    });
    let newarr = [...this.mainheader, ...this.prodcardbreadcrumb];
    this.cd.markForCheck();
    this.localstorage.setItem('productcardBreadcrumb', JSON.stringify(newarr));
    this.toggleMenu();
    this.router.navigate(['/shop/productcards'], {
      queryParams: { searchBy: partcategory, type: type, isViewAll: isViewAll },
    });
    // }
  }

  changeNavigationCategory(type) {
    this.prodcardbreadcrumb = [];
    this.mainheader = [];
    this.activeMenuItem = '';
    this.currentUrl = '';
    if (type == 'isProduct') {
      this.isProduct = true;
      this.isModel = false;
      this.singleProduct = [];

      this.mainheader.push(
        {
          label: 'All Categories',
          url: ' ',
        },
        {
          label: 'Product',
          url: ' ',
        }
      );
      this.localstorage.setItem(
        'mainheaderBreadcrumb',
        JSON.stringify(this.mainheader)
      );
      this.singleProduct.push(this.prodcutmenuData[0]);
      this.getProductName(this.prodcutmenuData[0]);
    } else if (type == 'isModel') {
      this.isModel = true;
      this.isProduct = false;
      this.modelName = [];
      this.mainheader.push({
        label: 'All Categories',
        url: ' ',
      },
        {
          label: 'Model',
          url: ' ',
        });
      this.localstorage.setItem(
        'mainheaderBreadcrumb',
        JSON.stringify(this.mainheader)
      );
      if (this.AllData.length > 0) {
        this.modelName.push(this.AllData[0]);
        this.getModelName(this.AllData[0]);
      }
    }
  }

  getActiveOrders(page, isFilter, isStatus?) {
    this.activeOrders = [];
    this.isOutOfDeliveryStatus = isStatus == 'out for delivery' ? true : false;
    let user = JSON.parse(this.localstorage.getItem('userData'));
    if (user && user.account_pk) {
      let newRequestBody = {
        account_pk: user.account_pk,
        order_number: '',
        otc_number: '',
        invoice_number: '',
        part_number: '',
        invoice_from_date: '',
        invoice_to_date: '',
        invoice_status: isStatus ? isStatus : '',
        action_type: 'active_orders',
        offset: 0,
        limit: 10,
      };
      this.showLoader = true;
      this.userService.getActiveOrdersList(newRequestBody).subscribe(
        (response) => {
          this.orderStatistics = response.data.order_statistics;
          this.deliveredCount = 0;
          this.invoicedCount = 0;
          this.confirmedCount = 0;
          this.cancelledCount = 0;
          this.outForDeliveryCount = 0;
          this.PendingCount = 0;
          if (response.success) {

            if (this.outForDeliveryCount > 0) {
              this.getOutForDelivery(0, false);
            }
          } else {
            //this.toastrService.error(response.data.msg);
          }
          if (!isFilter) {
            // setTimeout(() => {
            //     this.displayTable();
            // }, 300)
          }
          this.showLoader = false;
          for (let i = 0; i < this.orderStatistics.length; i++) {
            if (this.orderStatistics[i].invoice_status == 'delivered') {
              this.deliveredCount = parseInt(this.orderStatistics[i].count);
            }
            if (this.orderStatistics[i].invoice_status == 'invoiced') {
              this.invoicedCount = parseInt(this.orderStatistics[i].count);
            }
            if (this.orderStatistics[i].invoice_status == 'Confirmed') {
              this.confirmedCount = parseInt(this.orderStatistics[i].count);
            }
            if (this.orderStatistics[i].invoice_status == 'Cancelled') {
              this.cancelledCount = parseInt(this.orderStatistics[i].count);
            }
            if (
              this.orderStatistics[i].invoice_status == 'out for delivery'
            ) {
              this.outForDeliveryCount = parseInt(
                this.orderStatistics[i].count
              );
            }
            if (this.orderStatistics[i].invoice_status == 'Pending') {
              this.PendingCount = parseInt(this.orderStatistics[i].count);
            }
          }
          this.allItemsCount =
            this.deliveredCount +
            this.invoicedCount +
            this.confirmedCount +
            this.cancelledCount +
            this.outForDeliveryCount +
            this.PendingCount;
        },
        (error) => {
          this.showLoader = false;
          // this.toastrService.error(error.error.data.msg);
          if (!isFilter) {
            // setTimeout(() => {
            //     this.displayTable();
            // }, 300)
          }
        }
      );
    }
  }

  getOutForDelivery(page, isFilter) {
    let user = JSON.parse(this.localstorage.getItem('userData'));
    if (user && user.account_pk) {
      let newRequestBody = {
        account_pk: user.account_pk,
        order_number: '',
        otc_number: '',
        invoice_number: '',
        part_number: '',
        invoice_from_date: '',
        invoice_to_date: '',
        invoice_status: 'out for delivery',
        action_type: 'active_orders',
        offset: 0,
        limit: 10,
      };
      this.showLoader = true;
      this.activeOrders = [];
      this.userService.getActiveOrdersList(newRequestBody).subscribe(
        (response) => {
          if (response.success) {
            for (let i = 0; i < response.data.order_list.length; i++) {
              if (i < 3) {
                this.activeOrders.push(response.data.order_list[i]);
              }
            }
          } else {
            this.activeOrders = [];
            //this.toastrService.error(response.data.msg);
          }
          this.showLoader = false;
        },
        (error) => {
          this.activeOrders = [];
          if (error?.error?.data?.msg != "Data not found") {
            this.toastrService.error(error.error.data.msg);
          }
          this.showLoader = false;
        }
      );
    }
  }

  routeToOrders(type) {

    let ordersMenu = JSON.parse(this.localstorage.getItem('orderMenuList'));
    let activeorderPermission = ordersMenu.filter(
      (obj) => obj.page_display_name === 'Active Order'
    );
    let permission = this.userService.CheckPermission(
      'view_active_order'
    );
    //check activeorder permission
    if (permission) {
      //active order is in pagelist api
      if (activeorderPermission.length == 1) {

        type['isActiveOrders'] =
          this.urlString.includes('active-orders') ||
            this.urlString.includes('/orders?isViewAll=true')
            ? true
            : false;
        this.router.navigate(['/orders/active-orders'], { queryParams: type });
      }
    } else {
      this.toastrService.error(blockPermissionMsg);
    }
  }

  fastconsume() {
    this.ProductListData = [];
    let user = JSON.parse(this.localstorage.getItem('userData'));
    let address = JSON.parse(this.localstorage.getItem('address'));

    this.account_pk = +JSON.stringify(user.account_pk);

    if (user && address) {
      this.account_pk = +JSON.stringify(user.account_pk);

      let address = JSON.parse(this.localstorage.getItem('address'));
      let billingaddress = JSON.parse(this.localstorage.getItem('billingAddress'));
      var addresstypecheck = address?.address['default'];
      this.defaultAddress = address ? address.address : null;
      if (addresstypecheck === true) {
        this.shippingaddressid = 'default';
      } else {
        this.shippingaddressid = address?.address['address_id'];
      }
    }
    const ListPartInput: InputPartSearch = {} as InputPartSearch;
    ListPartInput.limit = 20;
    ListPartInput.search_text = '';
    ListPartInput.bs_model_name = '';
    ListPartInput.pg_line = '';
    ListPartInput.multi_pg_line = [];
    ListPartInput.multi_bs_model_name = [];
    ListPartInput.multi_dispgroup_name = [];
    ListPartInput.model_cat = '';
    ListPartInput.mod_uid = '';
    ListPartInput.category_type = '';
    ListPartInput.bom_id = '';
    ListPartInput.durafit_parts = false;
    ListPartInput.kit_parts = false;
    ListPartInput.part_number_last_four = '';
    ListPartInput.pl_id = '';
    ListPartInput.part_number = '';
    ListPartInput.is_for_epc_data = false;
    ListPartInput.is_for_discount = this.isLoggedIn == true ? true : false;
    ListPartInput.is_for_ecom_detail = false;
    ListPartInput.account_pk = this.isLoggedIn == true ? this.account_pk : '';
    ListPartInput.shipping_address_id = this.isLoggedIn == true ? this.shippingaddressid : '';
    ListPartInput.is_for_top_sold_parts = true;
    ListPartInput.account_id = this.isLoggedIn == true ? user.account_id : '';
    ListPartInput.is_for_inventory = this.userData?.user_type == 'MECH' ? true: false
    // ListPartInput.Cost = "";
    //ListPartInput.Cost ="";
    this.showLoader = true;
    this.ProductListData = [];
    this.fastconsumecount = 0;
    this.productService.get_part_search_md(ListPartInput).subscribe(
      (response) => {
        this.result = true;
        this.loading = false;

        if (response.success) {
          this.fastconsumecount = response.total_result;
          this.fastconsumeListProducts = response.data;
          response.data.forEach((element) => {
            this.getordertotal =
              Number(this.getordertotal) + Number(
                element['resource_type'] == 'Pack' ? element.total_amount * element['moq'] : element.total_amount 
              );

            this.mysaving =
              Number(this.mysaving) +
              Number(element.base_discount_amount) +
              Number(element.scheme_discount_amount) +
              Number(element.tml_discount_amount) +
              Number(element.distributor_discount_amount);
          });
          var proddata = response['data'];
          for (let i = 0; i < proddata.length; i++) {

            var dupicatecount = this.ProductListData.filter(
              (Data) => Data.part_number == proddata[i].part_number
            );
            if (dupicatecount.length > 0) {
            } else {
              proddata[i].customerQuantity = proddata[i].min_quantity;
              // proddata[i].unit_quantity = proddata[i].column_4;
              this.ProductListData.push(proddata[i]);
            }
          }
        } else {
          if (response?.data?.msg) {
            let usertypeMessage = "The account Id '" + JSON.parse(this.localstorage.getItem('userData')).account_id + "' you are trying to login, is not updated.";
            let errorMessage = (response.data.msg === "Account not found") ? usertypeMessage : response.data.msg;
            if (response.data.msg != "Data not found") {
              this.toasterService.error(errorMessage);
            }
          }
          this.isNoDataFound = true;
          this.ProductListData = [];
          this.fastconsumecount = 0;
          //this.productList = []
          this.loading = true;
          this.showLoader = false;
        }
        this.showLoader = false;
      },
      (error) => {
        this.result = true;
        this.loading = false;
        this.showLoader = false;
        this.ProductListData = [];
        this.fastconsumecount = 0;
      }
    );
  }

  readQuantity(event, product) {
    product.customerQuantity = event;
  }

  addToCart(product: Product, isRecentItem?): void {
    try {
      let permission = this.userService.CheckPermission('btn_cart');
      if (permission) {
        if (this.addingToCart) {
          return;
        }
        if (isNaN(product.customerQuantity)) {
          this.toastrService.error('Quantity cannot be empty or 0');
          return;
        }
        this.addingToCart = true;
        if (product['resource_type'] == 'Pack') {
          product['customerQuantity'] =this.isLoggedIn ? parseInt(product['customerQuantity']) ? parseInt(product['customerQuantity']) : 1: parseInt(product['customerQuantity']) ? product['customerQuantity'] * parseInt(product['moq']) : 1 * parseInt(product['moq'])
        } else {
          product['customerQuantity'] = parseInt(product.customerQuantity) ? parseInt(product.customerQuantity) : 1;
        }
        if (this.localstorage.getItem('getCartType')) {
          this.cartTypeList = JSON.parse(this.localstorage.getItem('getCartType'));
        }
        var commoncarttypedata = this.cartTypeList.filter((alitems) =>
          alitems.common_distributor_categories.includes(
            product['distributor_category']
          )
        );
        var Distributercarttypedata = this.cartTypeList.filter((alitems) =>
          alitems.distributor_categories.includes(
            product['distributor_category']
          )
        );
        let cart_type_name = '';
        if (commoncarttypedata.length > 0) {
          if (this.localstorage.getItem('selectcarttype')) {
            cart_type_name = this.localstorage.getItem('selectcarttype');
            this.cart.add(
              product,
              product.customerQuantity,
              product['distributor_category'],
              cart_type_name
              // false
            )
              .subscribe({
                complete: () => {
                  this.addingToCart = false;
                  product.customerQuantity = product['resource_type'] == 'Pack' ? product.customerQuantity/product['moq']:product.customerQuantity;
                  this.cd.markForCheck();
                },
              });
            localStorage.removeItem('selectcarttype');
          } else {
            cart_type_name = commoncarttypedata[1]['cart_type'];
            if (this.cartTypeList.length > 0) {
              this.selectcarttype = this.cartTypeList[1]['cart_type'];
              this.changeCartType(this.selectcarttype, product);
              this.btndisabled = false;
              this.addingToCart = false;
              this.btnshowConfrimed = false;
              this.btnshowsubmit = true;
              this.selectedProduct = product;
              this.productInfo.data = [product]
              this.productInfo.division_info = {
                ORG_NAME:product['organization_name']?product['organization_name']:"",
                DIV_NAME:product['division_name']?product['division_name']:"",
                PRIMISE_ADDRLINE1_s:product['primise_addrline1']?product['primise_addrline1']:"",
                PRIMISE_ADDRESS2_s:product['primise_addrline2']?product['primise_addrline2']:"",
                DIV_ID:product['division_id']?product['division_id']:"",
                GSTIN:product['gstn']?product['gstn']:"",
                ORG_Id:product['organization_id']?product['organization_id']:"",
            };
              setTimeout(() => {
                this.modalRef1 = this.modalService.show(this.template2, {
                  class: 'modal-dialog-centered modal-md',
                  backdrop: 'static',
                });
              },1000)
             
            }
          }
        } else if (Distributercarttypedata.length > 0) {
          cart_type_name = Distributercarttypedata[0]['cart_type'];
          this.cart.add(
            product,
            product.customerQuantity,
            product['distributor_category'],
            cart_type_name
            // ,false
          )
            .subscribe({
              complete: () => {
                this.addingToCart = false;
                product.customerQuantity = product['resource_type'] == 'Pack' ? product.customerQuantity/product['moq']:product.customerQuantity;
                this.cd.markForCheck();
              },
            });
        }
      } else {
        this.toastrService.error(blockPermissionMsg);
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'addToCart()',
        page_name: 'ProductCardComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  changeCartType(cartType, product: Product) {
    try {
      this.showLoader = true
      this.selectcarttype = cartType;
      let data = {};
      let user = JSON.parse(this.localstorage.getItem('userData'));
      let address = JSON.parse(this.localstorage.getItem('address'));
      let billingaddress = JSON.parse(this.localstorage.getItem('billingAddress'));
      let carttypes = this.selectcarttype.cart_type?this.selectcarttype.cart_type:this.selectcarttype;
      let searchData = {};
      if (user && address && billingaddress) {
        this.account_pk = +JSON.stringify(user.account_pk);
        var addresstypecheck = address.address['default'];
        if (addresstypecheck === true) {
          this.shippingaddressid = 'default';
        } else {
          this.shippingaddressid = address.address['address_id'];
        }
        var Billingaddresscheck = billingaddress.address['default'];
        if (Billingaddresscheck === true) {
          this.billingaddressid = 'default';
        } else {
          this.billingaddressid = billingaddress.address['address_id'];
        }
        searchData = {
          search_text: '',
          bs_model_name: '',
          pg_line: '',
          multi_pg_line: [],
          multi_bs_model_name: [],
          multi_dispgroup_name: [],
          model_cat: '',
          mod_uid: '',
          category_type: '',
          bom_id: '',
          durafit_parts: false,
          kit_parts: false,
          part_number_last_four: '',
          offset: 0,
          limit: 20,
          pl_id: '',
          // is_discount_needed: true,
          account_pk: this.account_pk,
          // address_pk: this.address_pk,
          shipping_address_id: this.shippingaddressid,
          part_number: product && product.part_number
            ? product.part_number
            : this.selectedProduct.part_number,
          is_for_epc_data: false,
          is_for_discount: true,
          is_for_ecom_detail: true,
          cart_type: carttypes,
          is_for_inventory :this.userData?.user_type == 'MECH' ? true: false
        };
      } else {
        searchData = {
          search_text: '',
          bs_model_name: '',
          pg_line: '',
          multi_pg_line: [],
          multi_bs_model_name: [],
          multi_dispgroup_name: [],
          model_cat: '',
          mod_uid: '',
          category_type: '',
          bom_id: '',
          durafit_parts: false,
          kit_parts: false,
          part_number_last_four: '',
          offset: 0,
          // start_row:0,
          limit: 20,
          pl_id: '',
          part_number: product && product.part_number
            ? product.part_number
            : this.selectedProduct.part_number,
          is_for_epc_data: false,
          is_for_discount: false,
          is_for_ecom_detail: true,
          cart_type: carttypes,
          is_for_inventory :this.userData?.user_type == 'MECH' ? true: false
        };
      }
      this.partnumberbaseddiscount(
        searchData,
        product ? product : this.selectedProduct
      );
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'changeCartType()',
        page_name: 'ProductCardComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  partnumberbaseddiscount(json, product: Product) {
    try {
      this.productService.get_part_search_md(json).subscribe(
        (response) => {
          this.showLoader = false
          if (response.success === true) {
            this.baseProductdata = response;
            this.baseProductdata.division_info = {
              ORG_NAME:this.baseProductdata.data[0].organization_name?this.baseProductdata.data[0].organization_name:"",
              DIV_NAME:this.baseProductdata.data[0].division_name?this.baseProductdata.data[0].division_name:"",
              PRIMISE_ADDRLINE1_s:this.baseProductdata.data[0].primise_addrline1?this.baseProductdata.data[0].primise_addrline1:"",
              PRIMISE_ADDRESS2_s:this.baseProductdata.data[0].primise_addrline2?this.baseProductdata.data[0].primise_addrline2:"",
              DIV_ID:this.baseProductdata.data[0].division_id?this.baseProductdata.data[0].division_id:"",
              GSTIN:this.baseProductdata.data[0].gstn?this.baseProductdata.data[0].gstn:""
          }
            var productPirce = parseFloat(product['crm_mrp'].toString()).toFixed(
              3
            );
            var basediscountPirce = parseFloat(
              response.data[0].total_amount
            ).toFixed(3);
            if (productPirce === basediscountPirce) {
              this.baseProductdiscount = null;
            } else {
              this.baseProductdiscount = response.data[0].total_amount;
            }
          } else {
          }
        },
        (err) => { this.showLoader = false}
      );
    } catch (error) {
      this.showLoader = false
      this.userService.InsertExceptionLog({
        method_name: 'partnumberbaseddiscount()',
        page_name: 'ProductCardComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }
  closePart() {
    // if (this.btnshowConfrimed) {
    //     localStorage.removeItem("selectcarttype");
    //     this.showQuickview();
    // }
    this.selectcarttype = "";
    this.modalRef1.hide();

  }

  setAddCart(cartType, product?: any) {
    try {
      let permission = this.userService.CheckPermission(
        'btn_cart'
      );
      if (permission) {
        this.btndisabled = true;
        var cart_type_name = this.localstorage.getItem('selectcarttype');
        this.showLoader = true;
        this.cart.add(this.selectedProduct, this.selectedProduct.customerQuantity, this.selectedProduct['distributor_category'], cart_type_name
          // ,false
        ).subscribe({
          complete: () => {
            // let currentUrl = this.router.url;
            // if (currentUrl.includes("/cart/summary")) {
            //   setTimeout(() => {
            //     this.appStateService.refreshCartItems.next(true);
            //   }, 1000);
            // }
            localStorage.removeItem('selectcarttype');
            this.addingToCart = false;
            this.showLoader = false;
            this.cd.markForCheck();
          }
        });
        localStorage.removeItem('selectcarttype');
        this.selectcarttype = "";
        this.modalRef1.hide();
        this.btndisabled = false;
        this.showLoader = false;
      }
      else {
        this.toasterService.error(blockPermissionMsg);
      }
    }
    catch (error) {
      this.userService.InsertExceptionLog({ "method_name": "setAddCart()", "page_name": "ProductCardComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
    }
  }
  toggleMenu() {
    //$("#wrapper").toggleClass('toggled', 'remove');
    //$("#wrapper").toggleClass('toggled').siblings().removeClass('toggled');
    // $('#menu ul').children('.current').parent().hide(); // COMMENTED ON 4TH AUG AT 12.02PM
    // $('#menu ul').hide();
    $("#wrapper").toggleClass("toggled");
    this.activeProductModel = '';
    // $("#menu-toggle").click(function (e) {
    //   e.preventDefault();
    //   $("#wrapper").toggleClass("toggled");
    // });
  }
  viewAllModels() {
    this.allModelsModal = this.modalService.show(this.allModels, {
      class: 'modal-dialog-centered modal-lg',
      backdrop: 'static',
    });
  }
  updateTitle(item){
    let label = item.label == 'ORDER'?'Active Order':item.label == 'REPORTS'?'Order Tracking':item.label == "MY VEHICLES"?'PROFILE MANAGEMENT':item.label;
    this.title.setTitle('E-Dukaan | '+label);
  }
}

export interface ModelData {
  LINE_2_s: string;
}

export class GetAddressparameter {
  account_pk: any;
  offset: any;
  limit: any;
  state: string;
  district: string;
  city: string;
}

export class productsubmenu {
  groupcategoryname: any;
  categorylist: any;
}
export interface NavMenu {
  navtext: string;
}
