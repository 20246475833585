import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { interval } from 'rxjs';
import { secureStorage } from '../../services/securestorage';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PaginationComponent),
      multi: true,
    },
  ],
})
export class PaginationComponent
  implements OnChanges, OnInit, ControlValueAccessor
{
  @Input() siblings = 1;
  @Input() current = 1;
  @Input() total = 1;

  @Output() pageChange: EventEmitter<number> = new EventEmitter();
  @Output() pageChange1: EventEmitter<number> = new EventEmitter();
  @Input() showPagination;

  buttonValue: any;
  pages: number[] = [];
  isPrev: boolean = false;
  isNext: boolean = false;
  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private localstorage: secureStorage
  ) {}

  ngOnInit(): void {
    interval(500).subscribe((value: number) => {
      this.isPrev = this.localstorage.getItem('prevOffset') == '0';
      this.isNext = this.localstorage.getItem('nextOffset') != 'false';
    });
    this.calc();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.calc();
  }

  setPage(value: number, emitEvent: boolean = true): void {
    this.onTouched();

    if (value < 1 || value > this.total || value === this.current) {
      return;
    }

    if (this.current !== value) {
    }

    this.current = value;
    this.calc();

    if (emitEvent) {
      this.pageChange.emit(this.current);
    }
  }

  trackByFn(index: number): number {
    return index;
  }

  private calc(): void {
    const min = Math.max(
      1,
      this.current -
        this.siblings -
        Math.max(0, this.siblings - this.total + this.current)
    );
    const max = Math.min(this.total, min + this.siblings * 2);

    this.pages = [];

    for (let i = min; i <= max; i++) {
      this.pages.push(i);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    if (typeof obj === 'number') {
      this.setPage(obj, false);
    }
  }

  setPage1(value) {
    this.pageChange1.emit(value);
  }
}
