<div class="modal-content">
    <div class="modal-header modal-title">
        Add User
        <button type="button" class="btn-close" (click)="closePopUp()"></button>
    </div>
    <div class="modal-body">
        <div class="row">
            <form [formGroup]="userRegistrationForm" name="userRegistrationForm">
                <div class="row pb-3">
                    <div class="form-group col-sm-12 col-md-6 col-lg-6">
                        <label for="" class="pb-2">First Name<span class="error-message">*</span></label> &nbsp;&nbsp;
                        <input type="text" maxlength="50" formControlName="first_name"
                            (keypress)="alphanumbericOnly($event)" class="form-control" placeholder="Enter First Name">
                        <div class="error-message" *ngIf="hasRequiredError('first_name')"> &nbsp;
                            <b>*required</b>
                        </div>
                        <div class="error-message" *ngIf="hasPatternError('first_name')"><b> &nbsp;
                                *invalid</b></div>
                        <div class="text-end">
                        </div>
                    </div>
                    <div class="form-group col-sm-12 col-md-6 col-lg-6">
                        <label for="user-last-name" class="pb-2">Last Name</label>
                        <input type="text" maxlength="50" formControlName="last_name" class="form-control"
                            (keypress)="alphanumbericOnly($event)" id="user-Last-Name" placeholder="Enter Last Name">
                        <div class="error-message" *ngIf="hasRequiredError('last_name')"> &nbsp;
                            <b>*required</b>
                        </div>
                        <div class="error-message" *ngIf="hasPatternError('last_name')"><b> &nbsp;
                                *invalid</b></div>
                    </div>
                </div>
                <div class="row pb-3">
                    <div class="form group col-sm-12 col-md-6 col-lg-6" *ngIf="pkuserid;else addNumberTemplate">
                        <label for="user-contact" class="pb-2">Contact No<span class="error-message">*</span></label>
                        <input type="text" formControlName="contact_no" class="form-control" maxlength="10"
                            (keypress)="numberOnly($event)" id="user-Phone_Number" placeholder="Enter Contact No" disabled>
                        <div class="error-message" *ngIf="hasRequiredError('contact_no')"> &nbsp;
                            <b>*required</b>
                        </div>
                        <div class="error-message" *ngIf="hasPatternError('contact_no')"><b> &nbsp;
                                *invalid</b></div>
                    </div>
                    <ng-template #addNumberTemplate>
                        <div class="form group col-sm-12 col-md-6 col-lg-6">
                            <label for="user-contact" class="pb-2">Contact No<span class="error-message">*</span></label>
                            <input type="text" formControlName="contact_no" class="form-control" maxlength="10"
                                (keypress)="numberOnly($event)" id="user-Phone_Number" placeholder="Enter Contact No">
                            <div class="error-message" *ngIf="hasRequiredError('contact_no')"> &nbsp;
                                <b>*required</b>
                            </div>
                            <div class="error-message" *ngIf="hasPatternError('contact_no')"><b> &nbsp;
                                    *invalid</b></div>
                        </div>
                    </ng-template>
                    <div class="form-group col-sm-12 col-md-6 col-lg-6">
                        <label for="user-email" class="pb-2">Email</label>
                        <input type="text" maxlength="50" formControlName="email_id" class="form-control"
                            pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" id="user-Email"
                            placeholder="Enter Email">
                        <div class="error-message" *ngIf="hasRequiredError('email_id')"> &nbsp;
                            <b>*required</b>
                        </div>
                        <div class="error-message" *ngIf="hasPatternError('email_id')"><b> &nbsp;
                                *invalid</b></div>
                    </div>
                </div>
                <div class="row pb-3">
                    <div class="form-group col-sm-12 col-md-6 col-lg-6">
                        <label for="user-Account">Assign Account to User<span class="error-message">*</span></label>
                        &nbsp; &nbsp;<br /><br>
                        <!-- <mat-form-field appearance="fill" style="width: 750px;"> -->
                        <mat-form-field class="full-width-field" appearance="outline">
                            <mat-label>Assign account to user</mat-label>
                            <mat-select formControlName="account_pk_s" (ngModelChange)="onOptionsSelected()"
                                [(ngModel)]="selectedAccountPk" multiple>
                                <mat-option *ngFor="let userid of userIds" [value]="userid.account_pk">
                                    {{userid.account_id}}_{{userid.account_name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <!-- <select formControlName="account_pk_s" class="form-control" (change)="onOptionsSelected()">
                            <option value="">Assign Account to User</option>
                            <option *ngFor="let userid of userIds" value="{{userid.account_pk}}">{{userid.account_id}}_{{userid.account_name}}
                            </option>
                        </select> -->
                        <!-- <mat-select placeholder="--Select Account--" formControlName="account_pk_s"
                            (ngModelChange)="onOptionsSelected()" class="form-control" multiple>
                            <mat-option *ngFor="let userid of userIds" [value]="userid.id">
                                {{userid.account_id}}_{{userid.account_name}}
                            </mat-option>
                        </mat-select> -->
                        <div class="error-message" *ngIf="hasRequiredError('account_pk_s')">
                            <b> &nbsp; *required. </b>
                        </div>
                    </div>
                    <div class="form-gorup col-sm-12 col-md-6 col-lg-6">
                        <label for="profile-last-name">Assign Role<span class="error-message">*</span></label>
                        <!-- <mat-form-field appearance="fill" style="width: 750px;"> -->&nbsp; &nbsp;<br /><br>
                        <mat-form-field appearance="outline" class="full-width-field">
                            <mat-label>Assign Role</mat-label>
                            <mat-select formControlName="role_id">
                                <mat-option *ngFor="let div of RoleList" [value]="div.id">
                                    {{div.role_name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <!-- <select formControlName="role_id" class="form-control">
                            <option value="" disabled>--Select Assign Role--</option>
                            <option *ngFor="let div of RoleList" value="{{div.id}}">{{div.role_name}}
                            </option>
                        </select> -->
                        <div class="error-message" *ngIf="hasRequiredError('role_id')">
                            <b>*required. </b>
                        </div>
                    </div>
                </div>
                <div class="form-group" *ngIf="userAlldetails.length > 0">
                    <div class="table-responsive">
                        <table class="table table-sm">
                            <thead>
                                <tr>
                                    <th>Account Details</th>
                                    <th>Location</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let users of userAlldetails">
                                    <td>{{users.AccountId}}_{{users.Name}}</td>
                                    <td>{{users.City}}, {{users.State}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div><br>
                <div class="row pb-3" *ngIf="otpverify">
                    <div class="form-group col-sm-12 col-md-6 col-lg-6">
                        <label for="user-contact" class="pb-2">OTP<span class="error-message">*</span></label> &nbsp; &nbsp;
                        <input type="text" formControlName="otp_number" class="form-control" maxlength="6"
                            (keypress)="numberOnly($event)" id="otp_number" placeholder="Enter OTP"><br>
                        <a (click)="OnUserGetOtp()" style="color: blue;cursor: pointer;margin-top: 5px !important;"
                            class="link">Resend OTP</a>
                        <div class="error-message" *ngIf="hasRequiredError('otp_number')"> &nbsp;
                            <b>*required</b>
                        </div>
                        <div class="error-message" *ngIf="hasPatternError('otp_number')"><b> &nbsp;
                                *invalid</b></div>
                    </div>
                </div>
                <!-- <div class="form-group txtwidth" *ngIf="otpverify">
                    <label for="user-contact">OTP<span class="error-message">*</span></label> &nbsp; &nbsp;
                    <input type="text" formControlName="otp_number" class="form-control" maxlength="6"
                        (keypress)="numberOnly($event)" id="otp_number" placeholder="Enter OTP">
                    <a (click)="OnUserGetOtp()" style="color: blue;cursor: pointer;margin-top: 5px !important;"
                        class="link">Resend OTP</a>
                    <div class="error-message" *ngIf="hasRequiredError('otp_number')"> &nbsp;
                        <b>*required</b>
                    </div>
                    <div class="error-message" *ngIf="hasPatternError('otp_number')"><b> &nbsp;
                            *invalid</b></div>
                </div><br> -->
                <div class="form-group">
                    <section class="example-section">
                        <mat-slide-toggle class="example-margin" [color]="color" [checked]="is_active"
                            (change)="updateFunc($event)" formControlName="is_active">
                            {{CheckedLable}}
                        </mat-slide-toggle>
                    </section>
                    <a *ngIf="iscontactosevice" style=" text-decoration-line: none; color: blue; cursor: pointer;"
                        (click)="SendSMS()" routerLinkActive="active" routerLinkActive="active">
                        <strong>Click here</strong></a><strong *ngIf="iscontactosevice"> to send password via SMS</strong>
                    <div style="color: #198754;" *ngIf="issmssend != '' && !isSendOtpError"> <strong>{{issmssend}}</strong></div>
                    <div style="color: #e90808;" *ngIf="issmssend != '' && isSendOtpError"> <strong>{{issmssend}}</strong></div>
                </div><br>
                <b *ngIf="userRegistrationForm.invalid && submitted">All fields marked with <span
                        class="error-message">*</span> are mandatory.</b><br>
                <button type="button" class="btn btn-sm btn-primary" [ngStyle]="{'float':'right'}" (click)="onSubmit()"
                    *ngIf="issubmit">Submit</button>
                <button type="button" class="btn btn-sm btn-primary" [ngStyle]="{'float':'right'}" (click)="onSubmit()"
                    *ngIf="!issubmit">Update</button>
            </form>
        </div>
    </div>
</div>

<ng-container *ngIf="showLoader">
    <div class="loader">
        <div class="ng-spinner ng-spinner-icon"></div>
    </div>
</ng-container>