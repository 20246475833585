import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { secureStorage } from "../../../../shared/services/securestorage";
import { UserService } from "../../../../shared/services/REST/user.service";
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-credit-list',
  templateUrl: './credit-list.component.html',
  styleUrls: ['./credit-list.component.scss']
})
export class CreditListComponent implements OnInit {

  public dtTrigger: Subject<any> = new Subject();
  filterForm: FormGroup;
  newRequestBody: any;
  dtOptions: DataTables.Settings = {};
  datatsetempty = false;
  isFormValid: boolean = true;
  totalrecord: any;
  showLoader: boolean = false;
  creditList: any[] = [];
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  selectedCreditDetails: any;
  @ViewChild('creditDetails', { read: TemplateRef, static: false })
  creditDetailsTemplate: TemplateRef<any>;
  creditDetailsModal: any;
  constructor(private router: Router, private localstorage: secureStorage, private userService: UserService, private toastr: ToastrService, private modalService: BsModalService,) { }

  ngOnInit(): void {
    this.calldatatable();
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  calldatatable() {
    let user = JSON.parse(this.localstorage.getItem('userData'));
    if (user && user.account_pk) {
      this.newRequestBody = {
        account_pk: user.account_pk,
        action_type: "user_credit_list",
        offset: 0,
        limit: 10
      };
      this.dtOptions = {
        pagingType: 'full_numbers',
        responsive: true,
        serverSide: true,
        processing: true,
        lengthChange: false,
        searching: false,
        pageLength: 10,
        ordering: false,
        language: {
          info: 'Showing _START_ to _END_ of _TOTAL_ entries',
          infoEmpty: 'No records available',
          // infoFiltered: ' ',
        },
        ajax: (dataTablesParameters: any, callback) => {
          if (this.isFormValid) {
            this.newRequestBody['offset'] = dataTablesParameters.start;
            this.totalrecord = 0;

            this.showLoader = true;
            this.userService.getCreditList(this.newRequestBody).subscribe(
              (response) => {
                window.scrollTo(0, 0);
                this.totalrecord = 0;
                if (response.success) {
                  this.creditList = response.data;
                  this.totalrecord = response.total_result;
                  this.showLoader = false;
                } else {
                  this.showLoader = false;
                  this.totalrecord = 0;
                  // this.toastrService.error(response.data.msg);
                  if (response.data.msg == 'Data not found') {
                    this.datatsetempty = true;
                  }
                }
                callback({
                  recordsTotal: this.totalrecord,
                  recordsFiltered: this.totalrecord,
                  data: [],
                });
              },
              (err) => {
                this.showLoader = false;
              }
            );
          }
        },
      };
    }
    else {
      this.router.navigate(['account/login']);
      this.toastr.error("Please login");
    }
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  getCreditDetails(distributorId) {
    let user = JSON.parse(this.localstorage.getItem('userData'));
    let requestBody = {
      account_pk: user.account_pk,
      action_type: "user_credit_detail",
      organization_id:distributorId
    }
    this.userService.getCreditList(requestBody).subscribe(response => {
      if(response.success){
        this.selectedCreditDetails = response.data;
        this.showCreditDetails();
      }
      else{
        if(response?.data?.msg){
          this.toastr.error(response?.data?.msg);
        }
      }
    },
    error => {
      if(error?.error?.data?.msg){
        this.toastr.error(error?.error?.data?.msg);
      }
    })
  }

  showCreditDetails(){
    this.creditDetailsModal = this.modalService.show(this.creditDetailsTemplate, {
      class: 'modal-dialog-centered modal-xl',
    });
  }
}
