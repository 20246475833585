<div class="row">
  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
    <div class="wsmenucontainer">
      <!--Mobile Header-->
      <div class="wsmobileheader clearfix" id="menu-toggle">
        <a id="wsnavtoggle" class="wsanimated-arrow"><span></span></a>
      </div>
      <!--Mobile Header-->
      <div class="wsmain clearfix">
        <nav class="wsmenu clearfix">
          <div class="overlapblackbg"></div>
          <ul class="wsmenu-list mt-3">
            <li aria-haspopup="true" (mouseover)="clickProductModel()">
              <span class="wsmenu-click"><i class="wsmenu-arrow"></i></span><a class="navtext ps-0"><span><i
                    class="fas fa-bars"></i> ALL CATEGORIES</span></a>
              <div class="wsshoptabing wtsdepartmentmenu clearfix" *ngIf="activeProductModel === 'ProductModel'">
                <div class="wsshopwp clearfix">
                  <!--style="height: 400px;"-->
                  <span class="category-tab-wrapper">
                    <div class="cursor" (mouseenter)="changeNavigationCategory('isProduct')" [ngClass]="
                        isProduct
                          ? 'form-check form-check-inline category-tab-wrapper-product p-0 ms-3 me-3 nav-cat-avtive'
                          : 'form-check form-check-inline category-tab-wrapper-product p-0 ms-3 me-3'
                      ">
                      <input class="form-check-input" type="radio" name="category-option" checked id="Product-nav"
                        value="Product" />
                      <label class="form-check-label cursor" for="Product-nav">Product</label>
                    </div>
                    <div class="cursor" (mouseenter)="changeNavigationCategory('isModel')" [ngClass]="
                        isModel
                          ? 'form-check form-check-inline category-tab-wrapper-modals p-0 ms-3 me-3 nav-cat-avtive'
                          : 'form-check form-check-inline category-tab-wrapper-modals p-0 ms-3 me-3'
                      ">
                      <input class="form-check-input" type="radio" name="category-option" id="Modals-nav"
                        value="Modals" />
                      <label class="form-check-label cursor" for="Modals-nav">Models</label>
                    </div>
                  </span>
                  <!--Product-->
                  <ul [ngClass]="
                      isProduct
                        ? 'wstabitem clearfix nav-product-wrapper'
                        : 'wstabitem clearfix nav-product-wrapper d-none'
                    ">
                    <!-- <li [ngClass]="(halfProduct == singleProduct[0]) ? 'wsshoplink-active' : ''" *ngFor="let halfProduct of halfProducts;let i=index;" (mouseenter)="getProductName(halfProduct)"><span class="wsmenu-click02"><i class="wsmenu-arrow"></i></span>
                        <a >{{ halfProduct.LINE_2_s }}</a> -->
                        <ng-container *ngIf="!prodcutmenuData?.length; else productMenu">
                          <ng-container *ngFor="let item of userService.generateFake(10);let i = index;">
                            <li [ngClass]="item == 0 ? 'wsshoplink-active' : ''">
                              <span class="wsmenu-click02"><i class="wsmenu-arrow"></i></span>
                              <a class="ellipse-2line">
                                <!-- product name here -->
                                <skc-rectangle [loading]="loading" [skc-height]="'35px'" [skc-width]="'100%'"> </skc-rectangle>
                              </a>
                              <div class="wstitemright clearfix wstitemrightactive">
                                <div class="container-fluid">
                                  <div class="row mt-3 mb-3">
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 product-nav-divider" *ngFor="let item of userService.generateFake(3);let i = index;">
                                      <h3>
                                        <!-- sub product list -->
                                        <skc-rectangle [loading]="loading" [skc-height]="'35px'" [skc-width]="'100%'"> </skc-rectangle>
                                      </h3>
                                      <ul>
                                        <!-- <li *ngFor="let item of userService.generateFake(4);let i = index;">
                                              <a style="display: flex; align-items: center">
                                                <div class="d-inline-block">
                                                  <skc-rectangle [loading]="loading" [skc-height]="'15px'" [skc-width]="'100%'"> </skc-rectangle>
                                                </div>
                                                <div class="d-inline-block">
                                                  <skc-rectangle [loading]="loading" [skc-height]="'10px'" [skc-width]="'100%'"> </skc-rectangle>
                                                </div>
                                              </a>
                                        </li> -->
                                        <li *ngFor="let item of userService.generateFake(5);let i = index;">
                                          <a class="view-all">
                                            <skc-rectangle [loading]="loading" [skc-height]="'25px'" [skc-width]="'100%'"> </skc-rectangle>
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ng-container>
                          
                          <li class="">
                            <a class="view-all">
                              <!-- view all product -->
                              <skc-rectangle [loading]="loading" [skc-height]="'10px'" [skc-width]="'100%'"> </skc-rectangle>
                            </a>
                          </li>
                        </ng-container>
                        <ng-template #productMenu>
                          <ng-container *ngFor="let objProduct of prodcutmenuData; let p = index">
                            <li *ngIf="p < 10" [ngClass]="
                                objProduct == singleProduct[0]
                                  ? 'wsshoplink-active'
                                  : ''
                              " (click)="getProductName(objProduct)">
                              <span class="wsmenu-click02"><i class="wsmenu-arrow"></i></span>
                              <a class="ellipse-2line" title="{{ objProduct.group_category | titlecase }}">
                                <!-- {{ (objProduct?.group_category == " " || objProduct?.group_category == null) ? "-" : objProduct?.group_category?.substr(0,30)  }} -->
                                {{ objProduct?.group_category | titlecase }}
                              </a>
                              <div class="wstitemright clearfix wstitemrightactive">
                                <div class="container-fluid">
                                  <div class="row mt-3 mb-3">
                                    <ng-container *ngIf="!prodcutsubmenuData?.length; else submenuList">
                                      <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 product-nav-divider" *ngFor="let item of userService.generateFake(3);let j = index;">
                                        <h3>
                                          <skc-rectangle [loading]="loading" [skc-height]="'20px'" [skc-width]="'50%'"> </skc-rectangle>
                                        </h3>
                                        <ul>
                                          <li *ngFor="let item of userService.generateFake(4);let j = index;">
                                            <skc-rectangle [loading]="loading" [skc-height]="'45px'" [skc-width]="'100%'"> </skc-rectangle>
                                            <skc-rectangle [loading]="loading" [skc-height]="'15px'" [skc-width]="'100%'"> </skc-rectangle>
                                          </li>
                                          <li class="text-end">
                                            <a class="view-all">
                                              <skc-rectangle [loading]="loading" [skc-height]="'20px'" [skc-width]="'100%'"> </skc-rectangle>
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </ng-container>
                                    <ng-template #submenuList>
                                      <div class="
                                      col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4
                                      product-nav-divider
                                    " *ngFor="
                                      let objsubmenuProduct of prodcutsubmenuData;
                                      let s = index
                                    ">
                                    <h3>
                                      {{ objsubmenuProduct.groupcategoryname }}
                                    </h3>
                                    <ul>
                                      <li *ngFor="
                                          let objsubmenucat of objsubmenuProduct?.categorylist
                                        ">
                                        <a (click)="
                                            GotoPartCategory(
                                              objsubmenucat.line_2,
                                              'PARTS CATEGORIES',
                                              false
                                            )
                                          " style="display: flex; align-items: center">
                                          <img class="d-inline-block" alt="" [src]="objsubmenucat?.image_url + '/resize/45x46'" />
                                          <div class="d-inline-block">
                                            {{ objsubmenucat.line_2 | titlecase }}
                                          </div>
                                        </a>
                                      </li>
                                      <li class="text-end" *ngIf="
                                          objsubmenuProduct?.categorylist?.length >
                                          0
                                        ">
                                        <a class="view-all" (click)="
                                            GotoPartCategory(
                                              objsubmenuProduct.groupcategoryname,
                                              'PARTS CATEGORIES',
                                              true
                                            )
                                          ">View All
                                          <i class="fas fa-angle-right" aria-hidden="true"></i>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                  </ng-template>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ng-container>
                          <li class="" (click)="viewAll(prodcutmenuData[0], 'PARTS CATEGORIES',false,true)">
                            <a class="view-all">VIEW ALL </a>
                          </li>
                        </ng-template>
                    
                  </ul>
                  <!--Models-->
                  <ul [ngClass]="
                      isModel
                        ? 'wstabitem clearfix nav-modals-wrapper'
                        : 'wstabitem clearfix nav-modals-wrapper d-none'
                    ">
                    <ng-container *ngIf="!AllPgLineitems?.length; else modelsList">
                      <ng-container  *ngFor="let item of userService.generateFake(10);let i = index;">
                        <li [ngClass]="item == 0 ? 'wsshoplink-active' : ''" class="">
                          <span class="wsmenu-click02"><i class="wsmenu-arrow"></i></span>
                          <a class="ellipse-2line">
                            <skc-rectangle [loading]="loading" [skc-height]="'35px'" [skc-width]="'100%'"> </skc-rectangle>
                          </a>
                        </li>
                      </ng-container>
                    </ng-container>
                    <ng-template #modelsList>
                      <li [ngClass]="
                      PgLineitem == modelName[0] ? 'wsshoplink-active' : ''
                    " class="" *ngFor="
                      let PgLineitem of AllPgLineitems.slice(0, 10);
                      let i = index
                    " (click)="getModelName(PgLineitem)">
                    <span class="wsmenu-click02"><i class="wsmenu-arrow"></i></span><a class="ellipse-2line"
                      title="{{ PgLineitem?.LINE_2_s | titlecase }}">
                      <!-- {{ (PgLineitem?.LINE_2_s == " " || PgLineitem?.LINE_2_s == null) ? "-" : PgLineitem?.LINE_2_s?.substr(0,26) | titlecase}} -->
                      {{ PgLineitem?.LINE_2_s | titlecase }}
                    </a>
                    
                      <div class="wstitemright clearfix wstitemrightactive">
                        <div class="container-fluid">
                          <div class="row">
                          <ng-container *ngIf="!modelAggregateData?.length; else aggregatesForModels">
                            <div class="col-lg-12 col-xl-12 col-md-12 clearfix">
                              <div class="wstheading clearfix">
                                <skc-rectangle [loading]="loading" [skc-height]="'25px'" [skc-width]="'100%'"> </skc-rectangle>
                                <!-- <a style="float: right" class="view-all"> <skc-rectangle [loading]="loading" [skc-height]="'25px'" [skc-width]="'100%'"> </skc-rectangle> </a> -->
                              </div>
                              <div class="row">
                                <div class="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-2 mb-3" *ngFor="let item of userService.generateFake(18);let j = index;">
                                  <a class="menu-option d-block" style="width: 95%">
                                    <div class="card">
                                      <div class="card-body text-center">
                                        <skc-rectangle [loading]="loading" [skc-height]="'130px'" [skc-width]="'128.55px'"> </skc-rectangle>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </ng-container>
                          <ng-template #aggregatesForModels>
                            <div class="col-lg-12 col-xl-12 col-md-12 clearfix">
                              <div class="wstheading clearfix">Aggregates
                                <a style="float: right" (click)="viewAll(this.modelName[0], 'POPULAR MODELS')"
                                  class="view-all">View all parts for this Model </a>
                              </div>
  
                              <div class="row">
                                <div class="
                                    col-6 col-sm-4 col-md-3 col-lg-2 col-xl-2
                                    mb-3
                                  " *ngFor="
                                    let objaggregate of modelAggregateData;
                                    let j = index
                                  ">
                                  <a (click)="
                                      gotoCatlog(
                                        objaggregate.aggregate,
                                        'aggregate'
                                      )
                                    " class="menu-option d-block" style="width: 95%">
                                    <div class="card">
                                      <div class="card-body text-center">
                                        <img alt="menu-icon" [src]="objaggregate?.aggregate_image + '/resize/80x50'"
                                          style="width: 80px; height: 80px" />
                                        <div class="menu-name ellipse-2line" style="height: 40px" title="{{
                                            objaggregate.aggregate | titlecase
                                          }}">
                                          {{
                                          objaggregate.aggregate | titlecase
                                          }}
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                          </div>
                        </div>
                      </div>
                    
                  </li>
                  <li *ngIf="AllPgLineitems?.length > 0" class=""
                    (click)="viewAll(AllPgLineitems[0], 'POPULAR MODELS',true)">
                    <a class="view-all">VIEW ALL </a>
                  </li>
                    </ng-template>
                    
                  </ul>
                </div>
              </div>
            </li>

            <ng-container *ngIf="isLoggedIn; else elseLgBlock">
              <li aria-haspopup="true" *ngFor="let item of items; let i = index">
                <span class="wsmenu-click"><i class="wsmenu-arrow"></i></span>
                <a [ngClass]="
                    queryParameter === item.label ? 'navtext active' : 'navtext'
                  " [routerLink]="item.url" [queryParams]="item.params" (click)="click(item)"><span> {{ item.label }}
                  </span></a>
              </li>
            </ng-container>

            <ng-template #elseLgBlock>
              <li style="cursor: pointer;" aria-haspopup="true" *ngFor="let item of items; let i = index">
                <span class="wsmenu-click"><i class="wsmenu-arrow"></i></span>
                <a *ngIf="item.label === 'POPULAR MODELS'" [ngClass]="
                    queryParameter === item.label ? 'navtext active' : 'navtext'
                  " (mouseover)="click(item)"><span> {{ item.label }} </span></a>
                <a *ngIf="item.label !== 'POPULAR MODELS'" [ngClass]="
                    queryParameter === item.label ? 'navtext active' : 'navtext'
                  " [routerLink]="item.url" [queryParams]="item.params" (click)="click(item)"
                  (mouseover)="activeMenuItem = ''"><span> {{ item.label }} </span></a>

                <div class="wsmegamenu clearfix" *ngIf="activeMenuItem === 'POPULAR MODELS'">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" *ngFor="
                          let PgLineitem of AllPgLineitems;
                          let mn = index
                        ">
                        <a class="ellipse-2line" title="{{ PgLineitem?.LINE_2_s | titlecase }}"
                          (click)="viewAll(PgLineitem, 'POPULAR MODELS')">
                          {{ PgLineitem?.LINE_2_s | titlecase }}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ng-template>

            <li class="wsshopmyaccount clearfix drop-arriving" *ngIf="isLoggedIn">
              <a class="rightnavtext d-flex pb-2"><span class="
                    right-nav-icon
                    d-flex
                    align-items-center
                    justify-content-center
                  ">{{ allItemsCount }}</span>
                <div>
                  <span></span>
                  <span>Order Summary</span>
                </div>
              </a>
              <!--drop arriving popup-->
              <div class="card arriving-hover-dropdown mt-2">
                <div class="triangle"></div>
                <div class="card-body arriving-today">
                  <!-- <div class="d-flex align-items-center">
                      <h4 class="mb-0 me-5">Product Value</h4>
                      <div class="month-select ms-5">
                          <select name="month" id="" class="form-select month">
                              <option value="">September</option>
                              <option value="">October</option>
                              <option value="">November</option>
                          </select>
                      </div>
                  </div>
                  <div class="list table-responsive mt-4">
                      <table class="table table-borderless">
                          <tbody>
                              <tr>
                                  <td>Total spendings</td>
                                  <td>2,54,321</td>
                                  <td class="g g-loss">03%</td>
                              </tr>
                              <tr>
                                  <td>Savings</td>
                                  <td>54,321</td>
                                  <td class="g g-profit">03%</td>
                              </tr>
                              <tr>
                                  <td>Forgone savings</td>
                                  <td>4,321</td>
                                  <td class="g g-profit">03%</td>
                              </tr>
                          </tbody>
                      </table>
                      <p class="text-end"><a href="#" class="text-decoration-none view-details">View Details</a></p>
                  </div> -->
                  <div class="order-summary">
                    <h4 class="mb-2">Order Summary</h4>
                    <div class="row">
                      <div class="col-4">
                        <table class="ads">
                          <tr (click)="
                              routeToOrders({
                                isViewPending: true,
                                isActiveOrders: false
                              })
                            ">
                            <td>
                              <img alt="" src="./assets/images/delay.svg" />
                            </td>
                            <td class="ps-3">
                              <div>{{ PendingCount }}</div>
                              <div>Pending</div>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <br /><br /><br />
                      <div class="col-4">
                        <table class="ads">
                          <tr (click)="
                              routeToOrders({
                                isViewInvoiced: true,
                                isActiveOrders: false
                              })
                            ">
                            <td>
                              <img alt="" src="./assets/images/allItems-1.svg" />
                            </td>
                            <td class="ps-3">
                              <div>{{ invoicedCount }}</div>
                              <div>Invoiced</div>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div class="col-4">
                        <table class="ads">
                          <tr (click)="
                              routeToOrders({
                                isViewDelivery: true,
                                isActiveOrders: false
                              })
                            ">
                            <td>
                              <img alt="" src="./assets/images/transit.svg" />
                            </td>
                            <td class="ps-3">
                              <div>{{ outForDeliveryCount }}</div>
                              <div>Out For Delivery</div>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div class="col-4">
                        <table class="ads">
                          <tr (click)="
                              routeToOrders({
                                isViewDelivered: true,
                                isActiveOrders: false
                              })
                            ">
                            <td>
                              <img alt="" src="./assets/images/divilerd.svg" />
                            </td>
                            <td class="ps-3">
                              <div>{{ deliveredCount }}</div>
                              <div>Delivered</div>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div class="col-4">
                        <table class="ads">
                          <tr (click)="
                              routeToOrders({
                                isViewCancelled: true,
                                isActiveOrders: false
                              })
                            ">
                            <td>
                              <img alt="" src="./assets/images/canclled.svg" />
                            </td>
                            <td class="ps-3">
                              <div>{{ cancelledCount }}</div>
                              <div>Cancelled</div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <h5 class="mt-3" *ngIf="activeOrders?.length > 0">
                      Current Arrivals
                    </h5>
                    <div class="bind-data-arrival-list table-responsive" *ngIf="activeOrders?.length > 0">
                      <!-- <h6>Arriving Today <span class="arrive-date ms-3">19 Aug</span></h6> -->
                      <table class="table table-borderless">
                        <thead>
                          <tr>
                            <th>Parts</th>
                            <th></th>
                            <th>Qty</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let order of activeOrders; let i = index">
                            <td>
                              <img src="{{ order?.part_image }}" class="img-size" alt="" />
                            </td>
                            <td>
                              <p>
                                {{
                                order?.part_number == " " ||
                                order?.part_number == null
                                ? "-"
                                : order?.part_number
                                }}
                              </p>
                              {{
                              order?.part_desc == " " ||
                              order?.part_desc == null
                              ? "-"
                              : order?.part_desc
                              }}
                            </td>
                            <td>{{ order?.total_quantity }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <!-- <div class="bind-data-arrival-list table-responsive">
                          <h6>Arriving Tommarrow <span class="arrive-date ms-3">20 Aug</span></h6>
                          <table class="table table-borderless">
                              <thead>
                                  <tr>
                                      <th>Parts</th>
                                      <th></th>
                                      <th>Qty</th>
                                  </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor=" let order of activeOrders;let i =index">
                                  <td>
                                      <img src="{{order?.part_image}}" class="img-size" alt="">
                                  </td>
                                  <td>
                                      <p>{{ (order?.part_number == " " || order?.part_number == null) ? "-" : order?.part_number}}</p>
                                      {{ (order?.part_desc == " " || order?.part_desc == null) ? "-" : order?.part_desc}}
                                  </td>
                                  <td>{{order?.total_quantity}}</td>
                              </tr>
                              </tbody>
                          </table>
                      </div> -->
                  </div>
                  <div class="text-end">
                    <a href="javascript:void(0)" (click)="
                        routeToOrders({
                          isViewDelivery: true,
                          isActiveOrders: false
                        })
                      " class="view-all" *ngIf="activeOrders?.length > 0">VIEW ALL</a>
                  </div>
                </div>
              </div>
            </li>
            <li class="wsshopmyaccount clearfix drop-consumable" *ngIf="isLoggedIn">
              <a class="rightnavtext d-flex pb-2"><img alt="" src="./assets/images/a-drop.svg" />
                <div class="ps-2">
                  <span>{{ fastconsumecount }} items</span><span>Buy Again</span>
                </div>

                <!-- <div class="ps-2"><span>{{(ProductListData?.length > 0) ? ProductListData?.length : 0}} items</span><span>Recently Ordered</span></div> -->
              </a>
              <!--drop consumable popup-->
              <div class="card consumable-hover-dropdown mt-2">
                <div class="triangle"></div>
                <div class="card-body consumable" style="height: calc(100vh - 165px); overflow-y: auto">
                  <div class="d-flex notify-heading">
                    <h4 class="card-title">Buy Again</h4>
                    <!-- <div class="ms-auto">
                      <a href="javascript:void(0)" class="view-all">Import</a>
                    </div> -->
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div *ngIf="ProductListData?.length === 0">
                        <h4 class="error-message">No Records Found</h4><br><br>
                      </div>
                      <div class="table-responsive table-container" *ngIf="ProductListData?.length > 0">
                        <table class="
                            table table-borderless
                            datatable
                            display
                            align-top
                            consumable-table
                          ">
                          <thead>
                            <tr>
                              <th>Parts</th>
                              <th class="text-nowrap"></th>
                              <!-- <th class="text-nowrap">Last Order</th>
                                  <th class="text-nowrap">In Stock</th> -->
                              <th class="text-nowrap">Order Qty</th>
                              <th class="text-nowrap">Cost (₹)</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let product of ProductListData">
                              <td class="
                                  td-img
                                  mx-auto
                                  text-center
                                  position-relative
                                ">
                                <!-- <img alt="" class="img-fluid" src="../../../../../assets/images/crown-wheel-pinion.png"/>  -->
                                <img [src]="product?.part_image_path + '/resize/54x37'" class="img-fluid" alt="prod-img-alt" srcset="" style="
                                    max-width: 100%;
                                    max-height: 100%;
                                    top: 50%;
                                    position: absolute;
                                    transform: translate(-50%, -50%);
                                  " />
                              </td>
                              <td>
                                
                                <app-wishlist-icon [product]="product" [layout]="'Nolayout'"></app-wishlist-icon>                         
                                <div class="p-title" [routerLink]="['/shop/product-details']" [queryParams]="{ part_Number : product?.part_number }">
                                  {{
                                  product.short_description == " " ||
                                  product.short_description == null
                                  ? "-"
                                  : product.short_description?.substr(0, 28)
                                  }}
                                </div>
                                <div class="p-desc" style="cursor: pointer;" [routerLink]="['/shop/product-details']"
                                [queryParams]="{ part_Number : product?.part_number }">
                                #{{ product?.part_number }} 
                              </div>
                              </td>
                              <!-- <td class="">15 Aug 2021</td>
                                  <td class="">05</td> -->
                              <td>
                                <div class="quantity-sec d-inline-block" *ngIf="(userData?.user_type == 'MECH' && (product?.inventory_quantity != 0 && product?.inventory_quantity != null)) || userData?.user_type != 'MECH'">
                                  <!-- <div class="qty-input">
                                        <button class="qty-count qty-count--minus" data-action="minus" type="button">-</button>
                                        <input class="product-qty" type="number" name="product-qty">
                                        <button class="qty-count qty-count--add" type="button">+</button>
                                      </div> -->

                                  <app-input-number aria-label="Quantity" size="lg" [min]="1" [isTmgoValue]="product['resource_type'] == 'Pack' ? product.moq:'1'"
                                    (currentQuantity)="readQuantity($event,product)" [defaultValue]="product.min_quantity" [userType]="userData" [inventoryQty]="product?.inventory_quantity">
                                  </app-input-number>
                                </div>
                              </td>
                              <td *ngIf="!product?.total_amount">
                                <span>
                                    {{ ( product['resource_type'] == 'Pack'? product.crm_mrp * product['moq'] : product.crm_mrp ) | currencyFormat: "INR"  }}
                                </span>
                              </td>
                              <td *ngIf="product?.total_amount">
                                <span *ngIf="
                                          product.base_discount_per +
                                          product.scheme_discount_per +
                                          product.rule_discount_per ==
                                    0.0">
                                    <span>
                                      {{ ( product['resource_type'] == 'Pack' ? product.crm_mrp * product['moq'] : product.crm_mrp ) | currencyFormat: "INR"  }}
                                    </span>
                                </span>
                                <span *ngIf="
                                    product.base_discount_per +
                                      product.scheme_discount_per +
                                      product.rule_discount_per >
                                    0.0
                                  ">
                                  <span>
                                    {{ ( product['resource_type'] == 'Pack' ? product.total_amount * product['moq'] : product.total_amount ) | currencyFormat: "INR"  }}
                                  </span>
                                </span>
                              </td>

                              <td class="text-end">
                                <!-- <button class="add-to-card">
                                  <img alt="" src="./assets/images/cart.svg" />
                                  Add to Cart
                                </button> -->
                                <ng-container *ngIf="userData?.user_type != 'MECH' || (userData?.user_type == 'MECH' && product?.inventory_quantity > 0 && product.moq <= product.inventory_quantity)">
                                  <button class="add-to-card" (click)="addToCart(product)">
                                    <img alt="" src="./assets/images/cart.svg" />
                                    Add to Cart
                                  </button>
                                </ng-container>
                                  <ng-container *ngIf="userData?.user_type == 'MECH' && (product?.inventory_quantity <= 0 || product?.inventory_quantity == null || product.moq > product.inventory_quantity)">
                                    <h6 class="col-7 text-end d-flex align-items-center" style="font-size: 13px;
                                    position: relative;
                                    font-weight: 700;
                                    color: red;
                                    top: 5px; text-wrap: nowrap;">Out Of Stock</h6>
                                </ng-container>
                                <!-- <a href="javascript: void(0);" class="view-all">
                                      Undo
                                    </a> -->
                              </td>
                            </tr>


                          </tbody>
                          <tfoot>
                            <tr>
                              <td colspan="2"></td>
                              <td colspan="3" class="text-start">
                                <div class="total-g">
                                  GRAND TOTAL <span>( including GST )</span>
                                </div>
                                <div class="total-s" *ngIf="userData?.user_type != 'MECH'">My Savings</div>
                              </td>
                              <td class="text-end">
                                <div class="total-g">
                                  {{ getordertotal | currencyFormat: "INR" }}
                                </div>
                                <div class="total-s" *ngIf="userData?.user_type != 'MECH'">
                                  {{ mysaving | currencyFormat: "INR" }}
                                </div>
                              </td>
                              <!-- <td>

                              </td> -->
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                    <!-- <div class="col-12 text-end mb-3">
                      <button class="btn btn-primary">Place Order</button>
                    </div> -->
                    <div class="col-12">
                      <!--Default Address-->
                      <div class="mat-default-Address" *ngIf="defaultAddress">
                        <p class="pb-2 m-0">
                          <span class="text-success"><img alt="" src="./assets/images/green-right-check.svg"
                              class="pe-1" />
                            DEFAULT DELIVERY ADDRESS
                          </span>
                        </p>
                        <div class="row">
                          <div class="
                              col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4
                              mt-1
                            ">
                            <div class="p-0">
                              <!-- <div class="d-flex justify-content-between align-items-center mb-2 ">
                                  <h4 class="mb-0 mat-location-type">Office</h4> <a href="javascript:void(0);" class="view-all">Edit</a>
                                </div> -->
                              <p class="mat-location-name m-0">
                                {{ defaultAddress?.street_address1 }}
                              </p>
                              <!-- <p class="mat-deliver-address">{{defaultAddress.MIDC 122 TTC industrial Area D- 104, B building 411330, Pune MH}}, -->
                              <p class="mat-deliver-address">
                                {{ defaultAddress?.defaultAddress1 ? defaultAddress?.street_address1 : "" }}
                                {{ defaultAddress?.defaultAddress2 ? defaultAddress?.street_address2 + "," : "" }}
                                {{ defaultAddress?.street_defaultAddress3 ? defaultAddress?.street_address3 + "," : "" }}
                                {{ defaultAddress?.district ? defaultAddress?.district + "," : "" }}
                                {{ defaultAddress?.taluka ? defaultAddress?.taluka + "," : "" }}
                                {{ defaultAddress?.city ? defaultAddress?.city + "," : "" }}
                                {{ defaultAddress?.state ? defaultAddress?.state + "," : "" }}
                                {{ defaultAddress?.postal_code ? defaultAddress?.postal_code + "," : "" }}
                              </p>
                              <p class="mat-gst" *ngIf="
                                  defaultAddress?.gst_no &&
                                  defaultAddress?.gst_no != ''
                                ">
                                GST No. {{ defaultAddress?.gst_no }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <!-- <li class="wsshopmyaccount clearfix drop-consumable" *ngIf="isLoggedIn">
              <a class="rightnavtext d-flex pb-2"><img alt="" src="../../../../../assets/images/a-drop.svg"/>
                <div class="ps-2"><span>60 items</span><span>Recently Ordered</span></div>
              </a> -->
            <!--drop consumable popup-->
            <!-- <div class="card consumable-hover-dropdown  mt-2">
                <div class="triangle"></div>
                <div class="card-body consumable">
                  <div class="d-flex notify-heading">
                    <h4 class="card-title">Recently Ordered </h4>
                    <div class="ms-auto">
                      <a href="javascript:void(0)" class="view-all">Import</a>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="table-responsive table-container">
                        <table class="table table-borderless datatable display align-top consumable-table">
                          <thead>
                              <tr>
                                  <th>Parts</th>
                                  <th class="text-nowrap"></th>
                                  <th class="text-nowrap">Last Order</th>
                                  <th class="text-nowrap">In Stock</th>
                                  <th class="text-nowrap">Order Qty</th>
                                  <th class="text-nowrap">Cost (₹)</th>
                                  <th></th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr>
                                  <td class="td-img">
                                    <img alt="" class="img-fluid" src="../../../../."/> 
                                  </td>                                  
                                  <td>                                      
                                    <div class="p-title">#266835300130</div>
                                    <div class="p-desc">2Crown W. & Pinion (37/7), OER- Spirac BS4</div>                                    
                                  </td>
                                  <td class="">15 Aug 2021</td>
                                  <td class="">05</td>
                                  <td>
                                    <div class="quantity-sec d-inline-block">
                                      <div class="qty-input">
                                        <button class="qty-count qty-count--minus" data-action="minus" type="button">-</button>
                                        <input class="product-qty" type="number" name="product-qty">
                                        <button class="qty-count qty-count--add" type="button">+</button>
                                      </div>
                               </div>
                                  </td>
                                  <td>
                                    49,500
                                  </td>
                                  
                                  <td class="text-end">
                                    <a href="javascript:void(0);">
                                      <img class="close" alt="" src="./assets/images/close.svg"/>
                                    </a>
                                    <a href="javascript: void(0);" class="view-all">
                                      Undo
                                    </a>
                                  </td>
                              </tr>
                              <tr>
                                <td class="td-img">
                                  <img alt="" class="img-fluid" src=""/> 
                                </td>                                  
                                <td>                                      
                                  <div class="p-title">#266835300130</div>
                                  <div class="p-desc">2Crown W. & Pinion (37/7), OER- Spirac BS4</div>                                    
                                </td>
                                <td class="">15 Aug 2021</td>
                                <td class="">05</td>
                                <td>
                                  <div class="quantity-sec d-inline-block">
                                    <div class="qty-input">
                                      <button class="qty-count qty-count--minus" data-action="minus" type="button">-</button>
                                      <input class="product-qty" type="number" name="product-qty">
                                      <button class="qty-count qty-count--add" type="button">+</button>
                                    </div>
                             </div>
                                </td>
                                <td>
                                  49,500
                                </td>
                                
                                <td class="text-end">
                                  <a href="javascript:void(0);">
                                    <img class="close" alt="" src="../../../../../assets/images/close.svg"/>
                                  </a>
                                  <a href="javascript: void(0);" class="view-all">
                                    Undo
                                  </a>
                                </td>
                            </tr>
                          
                          </tbody>
                          <tfoot>
                            <tr>
                              <td colspan="2"></td>
                              <td colspan="3" class="text-start">
                                <div class="total-g">GRAND TOTAL <span>( including GST )</span></div>
                                <div class="total-s">My Savings</div>
                              </td>
                              <td class="text-end">
                                <div class="total-g">0987654321.00</div>
                                <div class="total-s">098765432.00</div>
                              </td>
                              <td>

                              </td>
                            </tr>
                          </tfoot>
                      </table>
                      </div>
                    </div>
                    <div class="col-12 text-end mb-3">
                      <button class="btn btn-primary">Place Order</button>
                    </div>
                    <div class="col-12"> -->
            <!--Default Address-->
            <!-- <div class="mat-default-Address">
                          <p class="pb-2 m-0"><span class="text-success"><img alt="" src="../../../../../assets/images/green-right-check.svg" class="pe-1"/> DEFAULT DELIVERY ADDRESS </span></p>
                          <div class="row">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 mt-1">
                              <div class="p-0">
                                <div class="d-flex justify-content-between align-items-center mb-2 ">
                                  <h4 class="mb-0 mat-location-type">Office</h4> <a href="javascript:void(0);" class="view-all">Edit</a></div>
                                <p class="mat-location-name m-0">TATA Telematics</p>
                                <p class="mat-deliver-address">MIDC 122 TTC industrial Area D- 104, B building 411330, Pune MH </p>
                                <p class="mb-0 mat-gst m-0">GST no. 22UTRHV0000A1Z5 </p>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </li> -->
            <!-- <li class="wsshopmyaccount clearfix" *ngIf="isLoggedIn">
              <a class="rightnavtext d-flex pb-2"><img alt="" src="../../../../../assets/images/a-rupee.svg"/>
                <div class="ps-2"><span>29%</span><span>Credit limit</span></div>
              </a>
            </li>   -->
          </ul>
        </nav>
      </div>
    </div>
  </div>

  <div class="col-12 col-sm-12 col-md-12 ps-0 d-lg-none mobile-horizen-menu-wrapper">
    <ul class="mobile-horizen-menu ">
      <ng-container *ngIf="isLoggedIn; else elseBlock">
        <li aria-haspopup="true" *ngFor="let item of items; let i = index">
          <span class="wsmenu-click"><i class="wsmenu-arrow"></i></span>
          <a [ngClass]="
            queryParameter === item.label ? 'navtext active' : 'navtext'
          " [routerLink]="item.url" [queryParams]="item.params" (click)="click(item)"><span> {{ item.label }}
            </span></a>
        </li>
        <li class="wsshopmyaccount clearfix drop-arriving" *ngIf="isLoggedIn">
          <a class="rightnavtext d-flex pb-2"><span class="
                right-nav-icon
                d-flex
                align-items-center
                justify-content-center
              ">{{ allItemsCount }}</span>
            <div>
              <span></span>
              <span>Order Summary</span>
            </div>
          </a>
          <!--drop arriving popup-->
          <div class="card arriving-hover-dropdown mt-2">
            <div class="triangle"></div>
            <div class="card-body arriving-today">
              <!-- <div class="d-flex align-items-center">
                  <h4 class="mb-0 me-5">Product Value</h4>
                  <div class="month-select ms-5">
                      <select name="month" id="" class="form-select month">
                          <option value="">September</option>
                          <option value="">October</option>
                          <option value="">November</option>
                      </select>
                  </div>
              </div>
              <div class="list table-responsive mt-4">
                  <table class="table table-borderless">
                      <tbody>
                          <tr>
                              <td>Total spendings</td>
                              <td>2,54,321</td>
                              <td class="g g-loss">03%</td>
                          </tr>
                          <tr>
                              <td>Savings</td>
                              <td>54,321</td>
                              <td class="g g-profit">03%</td>
                          </tr>
                          <tr>
                              <td>Forgone savings</td>
                              <td>4,321</td>
                              <td class="g g-profit">03%</td>
                          </tr>
                      </tbody>
                  </table>
                  <p class="text-end"><a href="#" class="text-decoration-none view-details">View Details</a></p>
              </div> -->
              <div class="order-summary">
                <h4 class="mb-2">Order Summary</h4>
                <div class="row">
                  <div class="col-4" style="padding-left: 0px;">
                    <table class="ads">
                      <tr (click)="
                          routeToOrders({
                            isViewPending: true,
                            isActiveOrders: false
                          })
                        ">
                        <td>
                          <img class="img-left-margin-for-mobile" alt="" src="./assets/images/delay.svg" />
                        </td>
                        <td class="">
                          <div>{{ PendingCount }}</div>
                          <div style="font-size: 10px;">Pending</div>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <br /><br /><br />
                  <div class="col-4" style="padding-left: 0px;">
                    <table class="ads">
                      <tr (click)="
                          routeToOrders({
                            isViewInvoiced: true,
                            isActiveOrders: false
                          })
                        ">
                        <td>
                          <img class="img-left-margin-for-mobile" alt="" src="./assets/images/allItems-1.svg" />
                        </td>
                        <td class="">
                          <div>{{ invoicedCount }}</div>
                          <div style="font-size: 10px;">Invoiced</div>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-4" style="padding-left: 0px;">
                    <table class="ads">
                      <tr class="mobile-text-align" (click)="
                          routeToOrders({
                            isViewDelivery: true,
                            isActiveOrders: false
                          })
                        ">
                        <td>
                          <img class="img-left-margin-for-mobile" alt="" src="./assets/images/transit.svg" />
                        </td>
                        <td >
                          <div>{{ outForDeliveryCount }}</div>
                          <div style="font-size: 10px;">Out For Delivery</div>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-4" style="padding-left: 0px;">
                    <table class="ads">
                      <tr (click)="
                          routeToOrders({
                            isViewDelivered: true,
                            isActiveOrders: false
                          })
                        ">
                        <td>
                          <img class="img-left-margin-for-mobile" alt="" src="./assets/images/divilerd.svg" />
                        </td>
                        <td class="">
                          <div>{{ deliveredCount }}</div>
                          <div style="font-size: 10px;">Delivered</div>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-4" style="padding-left: 0px;">
                    <table class="ads">
                      <tr (click)="
                          routeToOrders({
                            isViewCancelled: true,
                            isActiveOrders: false
                          })
                        ">
                        <td>
                          <img class="img-left-margin-for-mobile" alt="" src="./assets/images/canclled.svg" />
                        </td>
                        <td class="">
                          <div>{{ cancelledCount }}</div>
                          <div style="font-size: 10px;">Cancelled</div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <h5 class="mt-3" *ngIf="activeOrders?.length > 0">
                  Current Arrivals
                </h5>
                <div class="bind-data-arrival-list table-responsive" *ngIf="activeOrders?.length > 0">
                  <!-- <h6>Arriving Today <span class="arrive-date ms-3">19 Aug</span></h6> -->
                  <table class="table table-borderless">
                    <thead>
                      <tr>
                        <th>Parts</th>
                        <th></th>
                        <th>Qty</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let order of activeOrders; let i = index">
                        <td>
                          <img src="{{ order?.part_image }}" class="img-size" alt="" />
                        </td>
                        <td>
                          <p>
                            {{
                            order?.part_number == " " ||
                            order?.part_number == null
                            ? "-"
                            : order?.part_number
                            }}
                          </p>
                          {{
                          order?.part_desc == " " ||
                          order?.part_desc == null
                          ? "-"
                          : order?.part_desc
                          }}
                        </td>
                        <td>{{ order?.total_quantity }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- <div class="bind-data-arrival-list table-responsive">
                      <h6>Arriving Tommarrow <span class="arrive-date ms-3">20 Aug</span></h6>
                      <table class="table table-borderless">
                          <thead>
                              <tr>
                                  <th>Parts</th>
                                  <th></th>
                                  <th>Qty</th>
                              </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor=" let order of activeOrders;let i =index">
                              <td>
                                  <img src="{{order?.part_image}}" class="img-size" alt="">
                              </td>
                              <td>
                                  <p>{{ (order?.part_number == " " || order?.part_number == null) ? "-" : order?.part_number}}</p>
                                  {{ (order?.part_desc == " " || order?.part_desc == null) ? "-" : order?.part_desc}}
                              </td>
                              <td>{{order?.total_quantity}}</td>
                          </tr>
                          </tbody>
                      </table>
                  </div> -->
              </div>
              <div class="text-end">
                <a href="javascript:void(0)" (click)="
                    routeToOrders({
                      isViewDelivery: true,
                      isActiveOrders: false
                    })
                  " class="view-all" *ngIf="activeOrders?.length > 0">VIEW ALL</a>
              </div>
            </div>
          </div>
        </li>
        <li class="wsshopmyaccount clearfix drop-consumable mt-2" *ngIf="isLoggedIn">
          <a class="rightnavtext d-flex pb-2"><img alt="" src="./assets/images/a-drop.svg" />
            <div class="ps-2">
              <span>{{ fastconsumecount }} items</span><span>Recently Ordered</span>
            </div>

            <!-- <div class="ps-2"><span>{{(ProductListData?.length > 0) ? ProductListData?.length : 0}} items</span><span>Recently Ordered</span></div> -->
          </a>
          <!--drop consumable popup-->
          <div class="card consumable-hover-dropdown mt-2">
            <div class="triangle"></div>
            <div class="card-body consumable" style="height: calc(100vh - 165px); overflow-y: auto">
              <div class="d-flex notify-heading">
                <h4 class="card-title">Recently Ordered</h4>
                <!-- <div class="ms-auto">
                  <a href="javascript:void(0)" class="view-all">Import</a>
                </div> -->
              </div>
              <div class="row">
                <div class="col-12">
                  <div *ngIf="ProductListData?.length === 0">
                    <h4 class="error-message">No Records Found</h4><br><br>
                  </div>
                  <div class="table-responsive table-container" *ngIf="ProductListData?.length > 0">
                    <table class="
                        table table-borderless
                        datatable
                        display
                        align-top
                        consumable-table
                      ">
                      <thead>
                        <tr>
                          <th>Parts</th>
                          <th class="text-nowrap"></th>
                          <!-- <th class="text-nowrap">Last Order</th>
                              <th class="text-nowrap">In Stock</th> -->
                          <th class="text-nowrap">Order Qty</th>
                          <th class="text-nowrap">Cost (₹)</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let product of ProductListData">
                          <td class="
                              td-img
                              mx-auto
                              text-center
                              position-relative
                            ">
                            <!-- <img alt="" class="img-fluid" src="../../../../../assets/images/crown-wheel-pinion.png"/>  -->
                            <img [src]="product?.part_image_path" class="img-fluid" alt="prod-img-alt" srcset="" style="
                                max-width: 100%;
                                max-height: 100%;
                                top: 50%;
                                position: absolute;
                                transform: translate(-50%, -50%);
                              " />
                          </td>
                          <td>
                            
                            <app-wishlist-icon [product]="product" [layout]="'Nolayout'"></app-wishlist-icon>                        
                            <div class="p-title">
                              {{
                              product.short_description == " " ||
                              product.short_description == null
                              ? "-"
                              :(product.short_description.length > 15 ?(product.short_description.substr(0, 15) + '...') :product.short_description)
                              }}
                            </div>
                            <div class="p-desc" style="cursor: pointer;" [routerLink]="['/shop/product-details']"
                            [queryParams]="{ part_Number : product?.part_number }">
                            #{{ product?.part_number }} 
                          </div>
                          </td>
                          <!-- <td class="">15 Aug 2021</td>
                              <td class="">05</td> -->
                          <td>
                            <div class="quantity-sec d-inline-block" *ngIf="(userData?.user_type == 'MECH' && (product?.inventory_quantity != 0 && product?.inventory_quantity != null)) || userData?.user_type != 'MECH'">
                              <!-- <div class="qty-input">
                                    <button class="qty-count qty-count--minus" data-action="minus" type="button">-</button>
                                    <input class="product-qty" type="number" name="product-qty">
                                    <button class="qty-count qty-count--add" type="button">+</button>
                                  </div> -->

                              <app-input-number aria-label="Quantity" size="lg" [min]="1" [isTmgoValue]="(product['resource_type'] == 'Pack')?product.moq:'1'"
                                (currentQuantity)="readQuantity($event,product)" [defaultValue]="product.min_quantity" [userType]="userData" [inventoryQty]="product?.inventory_quantity">
                              </app-input-number>
                            </div>
                          </td>
                          <td *ngIf="!product?.total_amount">
                            <span>
                              {{ ( product['resource_type'] == 'Pack' ? product.crm_mrp * product['moq'] : product.crm_mrp ) | currencyFormat: "INR"  }}
                            </span>
                          </td>
                          <td *ngIf="product?.total_amount">
                            <span *ngIf="
                                product.base_discount +
                                  product.scheme_discount +
                                  product.rule_discount ==
                                0.0
                              ">
                              <span>
                                {{ ( product['resource_type'] == 'Pack' ? product.crm_mrp * product['moq'] : product.crm_mrp ) | currencyFormat: "INR"  }}
                              </span>
                            </span>
                            <span *ngIf="
                                product.base_discount_per +
                                  product.scheme_discount_per +
                                  product.rule_discount_per >
                                0.0
                              ">
                              <span>
                                {{ ( product['resource_type'] == 'Pack' ? product.total_amount * product['moq'] : product.total_amount ) | currencyFormat: "INR"  }}
                              </span>
                            </span>
                          </td>

                          <td class="text-end" >
                            <ng-container *ngIf="userData?.user_type != 'MECH' || (userData?.user_type == 'MECH' && product?.inventory_quantity > 0)">
                              <button class="add-to-card" (click)="addToCart(product)">
                                <img alt="" src="./assets/images/cart.svg" />
                                Add to Cart
                              </button>
                            </ng-container>
                              <ng-container *ngIf="userData?.user_type == 'MECH' && (product?.inventory_quantity <= 0 || product?.inventory_quantity == null || product.moq > product.inventory_quantity)">
                                <h6 class="col-7 text-end d-flex align-items-center" style="font-size: 13px;
                                position: relative;
                                font-weight: 700;
                                color: red;
                                top: 5px; text-wrap: nowrap;">Out Of Stock</h6>
                            </ng-container>
                         
                            <!-- <a href="javascript: void(0);" class="view-all">
                                  Undo
                                </a> -->
                          </td>
                        </tr>


                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="2"></td>
                          <td colspan="3" class="text-start">
                            <div class="total-g">
                              GRAND TOTAL <span>( including GST )</span>
                            </div>
                            <div class="total-s" *ngIf="userData?.user_type != 'MECH'">My Savings</div>
                          </td>
                          <td class="text-end">
                            <div class="total-g">
                              {{ getordertotal | currencyFormat: "INR" }}
                            </div>
                            <div class="total-s" *ngIf="userData?.user_type != 'MECH'">
                              {{ mysaving | currencyFormat: "INR" }}
                            </div>
                          </td>
                          <!-- <td>

                          </td> -->
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <!-- <div class="col-12 text-end mb-3">
                  <button class="btn btn-primary">Place Order</button>
                </div> -->
                <div class="col-12">
                  <!--Default Address-->
                  <div class="mat-default-Address" *ngIf="defaultAddress">
                    <p class="pb-2 m-0">
                      <span class="text-success"><img alt="" src="./assets/images/green-right-check.svg"
                          class="pe-1" />
                        DEFAULT DELIVERY ADDRESS
                      </span>
                    </p>
                    <div class="row">
                      <div class="
                          col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4
                          mt-1
                        ">
                        <div class="p-0">
                          <!-- <div class="d-flex justify-content-between align-items-center mb-2 ">
                              <h4 class="mb-0 mat-location-type">Office</h4> <a href="javascript:void(0);" class="view-all">Edit</a>
                            </div> -->
                          <p class="mat-location-name m-0">
                            {{ defaultAddress?.street_address1 }}
                          </p>
                          <!-- <p class="mat-deliver-address">{{defaultAddress.MIDC 122 TTC industrial Area D- 104, B building 411330, Pune MH}}, -->
                          <p class="mat-deliver-address">
                            {{ defaultAddress?.defaultAddress1 ? defaultAddress?.street_address1 : "" }}
                            {{ defaultAddress?.defaultAddress2 ? defaultAddress?.street_address2 + "," : "" }}
                            {{ defaultAddress?.street_defaultAddress3 ? defaultAddress?.street_address3 + "," : "" }}
                            {{ defaultAddress?.district ? defaultAddress?.district + "," : "" }}
                            {{ defaultAddress?.taluka ? defaultAddress?.taluka + "," : "" }}
                            {{ defaultAddress?.city ? defaultAddress?.city + "," : "" }}
                            {{ defaultAddress?.state ? defaultAddress?.state + "," : "" }}
                            {{ defaultAddress?.postal_code ? defaultAddress?.postal_code + "," : "" }}
                          </p>
                          <p class="mat-gst" *ngIf="
                              defaultAddress?.gst_no &&
                              defaultAddress?.gst_no != ''
                            ">
                            GST No. {{ defaultAddress?.gst_no }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ng-container>
      <ng-template #elseBlock>
        <li style="cursor: pointer;" aria-haspopup="true" *ngFor="let item of items; let i = index">
          <span class="wsmenu-click"><i class="wsmenu-arrow"></i></span>
          <a *ngIf="item.label === 'POPULAR MODELS'" [ngClass]="
            queryParameter === item.label ? 'navtext active' : 'navtext'
          "(mouseover)="click(item)"><span> {{ item.label }} </span></a>
          <a *ngIf="item.label !== 'POPULAR MODELS'" [ngClass]="
            queryParameter === item.label && activeMenuItem != 'POPULAR MODELS' ? 'navtext active' : 'navtext'
          " [routerLink]="item.url" [queryParams]="item.params" (click)="click(item)"
            (mouseover)="activeMenuItem = ''"><span> {{ item.label }} </span></a>
        </li>
        <div class="wsmegamenu clearfix" *ngIf="activeMenuItem === 'POPULAR MODELS'">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" *ngFor="
                let PgLineitem of AllPgLineitems;
                let mn = index
              ">
                <a class="ellipse-2line" title="{{ PgLineitem?.LINE_2_s | titlecase }}"
                  (click)="viewAll(PgLineitem, 'POPULAR MODELS')">
                  {{ PgLineitem?.LINE_2_s | titlecase }}</a>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ul>
  </div>
  <!--vertical menu-->

  <div id="wrapper" class="d-lg-none">
    <!-- Sidebar -->
    <div id="sidebar-wrapper">
      <nav>
        <div class="nav nav-tabs" id="nav-tab-categories" role="tablist">
          <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Product</button>
          <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Models</button>
         
        </div>
      </nav>
      <div class="tab-content" id="nav-tabContent">
        <!-- MOBILE PRODUCT CATEGORIES -->
        <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
          <ul class="sidebar-nav nav-pills nav-stacked" id="menu">
            <li [ngClass]="objProduct == singleProduct[0] ? 'active': ''"  *ngFor="let objProduct of prodcutmenuData; let p = index" (click)="getProductName(objProduct)">
              <a >{{ objProduct?.group_category | titlecase }}</a>
              
      <!-- {{objProduct == singleProduct[0]}} -->
              <div [ngClass]="objProduct == singleProduct[0] ? '': 'd-none'">
                <ul class="nav-pills nav-stacked">
                  <li *ngFor="let objsubmenuProduct of prodcutsubmenuData; let s = index">
                    <a>{{ objsubmenuProduct.groupcategoryname }}</a>

                   
                    <ul class="nav-pills nav-stacked" >
                      <li *ngFor="let objsubmenucat of objsubmenuProduct?.categorylist">
                        <a (click)="GotoPartCategory(objsubmenucat.line_2,'PARTS CATEGORIES',false)">{{ objsubmenucat.line_2 | titlecase }}</a>
                      </li>
                   </ul>
                   

                  </li>
                </ul>
              </div>
             
            </li>
            
    
          </ul>
        </div>
        <!-- MOBILE MODEL AGGREGATE -->
        <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
          <ul class="sidebar-nav nav-pills nav-stacked" id="menu">
            <li [ngClass]="PgLineitem == modelName[0] ? 'active': ''" *ngFor="let PgLineitem of AllPgLineitems.slice(0, 10);let i = index" (click)="getModelName(PgLineitem)">
               <a>{{ PgLineitem?.LINE_2_s | titlecase }}</a>
               <div [ngClass]="PgLineitem == modelName[0] ? '': 'd-none'">
               <ul class="nav-pills nav-stacked">
                  <li *ngFor="let objaggregate of modelAggregateData;let j = index">
                <a (click)="gotoCatlog(objaggregate.aggregate,'aggregate')">{{objaggregate.aggregate | titlecase}}</a>
                  </li>
               </ul>
              </div>
            </li>
         </ul>
        </div>
      </div>
      
    </div>
    <!-- /#sidebar-wrapper -->

  </div>


</div>



<ng-template #addressModal let-d="dismiss" style="width: auto !important">
  <delivery-address [pinCodeNumber]="pinCodeNumber" [categoryType]="categoryType" [addresses]="addresses"
    [totalcount]="totalcount" [currentPage]="currentPage" [usertype]="usertype" (closemodal)="closemodel($event);"
    [updateForm]="updateForm" [updateFormDelivery]="updateFormDelivery" (changedAddress)="onDeliveryChange($event)"
    (SelectedAddress)="setSelectedAddress($event)"></delivery-address>
</ng-template>

<ng-container *ngIf="showLoader">
  <div class="loader">
    <div class="ng-spinner ng-spinner-icon"></div>
  </div>
</ng-container>

<ng-template #confimationmodal let-d="dismiss">
  <app-selectCartType [btnshowConfrimed]="btnshowConfrimed" [btnshowsubmit]="btnshowsubmit" [baseProductdata]="productInfo"
    [cartTypeList]="cartTypeList" [baseProductdata]="baseProductdata" (selectedCartType)="ShowPart()"
    (inputChange)="changeCartType($event,null)" (closeModal)="closePart()" (setCartType)="setAddCart(cartType,product)" [currentlyAddingProduct]="selectedProduct"></app-selectCartType>
</ng-template>

<ng-template #allModels let-d="dismiss">
  <all-models [allModels]="AllPgLineitems" (closeModalPopup)="allModelsModal.hide()"></all-models>
</ng-template>