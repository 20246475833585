declare let gtag: Function;
export class GoogleAnalyticsService {


  public addressConfirmation(
    eventName: string,
    UserMobileNumber: number,
    UserAddress: string,
  ) {

    var baseurl = window.location.href;
    if (baseurl.includes('https://edukaan.home.tatamotors/#/')) {
      gtag('event', eventName, {
        UserMobileNumber: UserMobileNumber,
        UserAddress: UserAddress,
      })
    }
  }
  public availability(
    eventName: string,
    Number_OF_Parts: number,
    Part_number: string,
    Pending_Parts: number,
    Available_Parts: number,
  ) {
    var baseurl = window.location.href;
    if (baseurl.includes('https://edukaan.home.tatamotors/#/')) {
      gtag('event', eventName, {
        Number_OF_Parts: Number_OF_Parts,
        Part_number: Part_number,
        Pending_Parts: Pending_Parts,
        Available_Parts: Available_Parts
      })
    }
  }
  public emergencyContact(
    eventName: string,
    ContactNumber: number,
  ) {
    var baseurl = window.location.href;
    if (baseurl.includes('https://edukaan.home.tatamotors/#/')) {
      gtag('event', eventName, {
        ContactNumber: ContactNumber,
      })
    }
  }
  //orderConfirmation('Order_Confirmation-web',user.phone_number,address,this.orderConfirmData.order_lines.length,this.orderConfirmData.umrp_total,this.orderConfirmData.total_base_discount,this.orderConfirmData.total_scheme_discount,this.orderConfirmData.total_tax,this.orderConfirmData.total_amount)
  public orderConfirmation(eventName: string, UserMobileNumber: number, UserAddress: string, PartNo: string, Number_OF_Parts: number, Sub_Total: number, Base_Discount: number, Scheme_Discount: number, Tax: number, Total: number,latitude:string,longitude:string,ipAddress:string
  ) {
    var baseurl = window.location.href;
    if (baseurl.includes('https://edukaan.home.tatamotors/#/')) {
      gtag('event', eventName, {
        UserMobileNumber: UserMobileNumber,
        UserAddress: UserAddress,
        PartNo: PartNo,
        Number_OF_Parts: Number_OF_Parts,
        Sub_Total: Sub_Total,
        Base_Discount: Base_Discount,
        Scheme_Discount: Scheme_Discount,
        Tax: Tax,
        Total: Total,
        Latitude:latitude,
        Longitude:longitude,
        Ip_Address:ipAddress
      })
    }
  }
  public OTC_Failure(
    eventName: string,
    Status: string,
    Status_Message: string
  ) {
    var baseurl = window.location.href;
    if (baseurl.includes('https://edukaan.home.tatamotors/#/')) {
      gtag('event', eventName, {
        Status: Status,
        Status_Message: Status_Message
      })
    }
  }
  public OTC_Fail(
    eventName: string,
    UserMobileNumber: number,
    OTC_Fail: string,
    Order_No: number
  ) {
    var baseurl = window.location.href;
    if (baseurl.includes('https://edukaan.home.tatamotors/#/')) {
      gtag('event', eventName, {
        UserMobileNumber: UserMobileNumber,
        OTC_Fail: OTC_Fail,
        Order_No: Order_No
      })
    }
  }
  public OTC(
    eventName: string,
    UserMobileNumber: number,
    Order_Number: number,
    OTC_Order_Number: number,
    OTC_Order_Total: number,
    OTC_Order_Date: string,
    OTC_Order_Tax: number,
    OTC_Scheme_Discount: number,
    OTC_Base_Discount: number,

  ) {
    var baseurl = window.location.href;
    if (baseurl.includes('https://edukaan.home.tatamotors/#/')) {
      gtag('event', eventName, {
        UserMobileNumber: UserMobileNumber,
        Order_Number: Order_Number,
        OTC_Order_Number: OTC_Order_Number,
        OTC_Order_Total: OTC_Order_Total,
        OTC_Order_Date: OTC_Order_Date,
        OTC_Order_Tax: OTC_Order_Tax,
        OTC_Scheme_Discount: OTC_Scheme_Discount,
        OTC_Base_Discount: OTC_Base_Discount,
      })
    }
  }
  public Registration(
    eventName: string,
    UserMobileNumber: number,
    UserAddress: string,
    RegistrationType: string,
    UserType: string,
    USER_ID: number,
  ) {
    var baseurl = window.location.href;
    if (baseurl.includes('https://edukaan.home.tatamotors/#/')) {
      gtag('event', eventName, {
        UserMobileNumber: UserMobileNumber,
        UserAddress: UserAddress,
        RegistrationType: RegistrationType,
        UserType: UserType,
        USER_ID: USER_ID,
      })
    }
  }
  public cartList(
    eventName: string,
    UserAddress: string,
    CartValue: number,
    TotalAmount: number,
    UserMobileNumber: number,
  ) {
    var baseurl = window.location.href;
    if (baseurl.includes('https://edukaan.home.tatamotors/#/')) {
      gtag('event', eventName, {
        UserMobileNumber: UserMobileNumber,
        UserAddress: UserAddress,
        CartValue: CartValue,
        TotalAmount: TotalAmount,
      })
    }
  }
  public descriptionSearch(
    eventName: string,
    Address: string,
    Part_Description: string,
    UserMobileNumber: number,
  ) {
    var baseurl = window.location.href;
    if (baseurl.includes('https://edukaan.home.tatamotors/#/')) {
      gtag('event', eventName, {
        UserMobileNumber: UserMobileNumber,
        Address: Address,
        Part_Description: Part_Description,
        //page_view: 'Home',
      })
    }
  }
  public descriptionSearchFail(
    eventName: string,
    Address: string,
    Part_Description: string,
    UserMobileNumber: number,
  ) {
    var baseurl = window.location.href;
    if (baseurl.includes('https://edukaan.home.tatamotors/#/')) {
      gtag('event', eventName, {
        UserMobileNumber: UserMobileNumber,
        Address: Address,
        Part_Description: Part_Description,
      })
    }
  }
  public viewAllParts(
    eventName: string,
    Address: string,
    Part_Viewed: string,
    UserMobileNumber: number,
  ) {
    var baseurl = window.location.href;
    if (baseurl.includes('https://edukaan.home.tatamotors/#/')) {
      gtag('event', eventName, {
        UserMobileNumber: UserMobileNumber,
        Address: Address,
        Part_Viewed: Part_Viewed,
      })
    }
  }
  public viewAllPartsFail(
    eventName: string,
    Address: string,
    Part_Viewed: string,
    UserMobileNumber: number,
  ) {
    var baseurl = window.location.href;
    if (baseurl.includes('https://edukaan.home.tatamotors/#/')) {
      gtag('event', eventName, {
        UserMobileNumber: UserMobileNumber,
        Address: Address,
        Part_Viewed: Part_Viewed,
      })
    }
  }

  public paymentMethod(eventName: string, UserMobileNumber: number, UserAddress: string, Order_Id: number, Payment_Method: string,) {
    var baseurl = window.location.href;
    if (baseurl.includes('https://edukaan.home.tatamotors/#/')) {
      gtag('event', eventName, {
        UserMobileNumber: UserMobileNumber,
        UserAddress: UserAddress,
        Order_Id: Order_Id,
        Payment_Method: Payment_Method
      })
    }
  }
  public addToCart(eventName: string, Address: string, PartNumber: string, Quantity: number, UserMobileNumber: number,) {
    var baseurl = window.location.href;
 
    // if(`${environment.apiUrl}` == 'https://skinprod.api.tatamotors/'){
    if (baseurl.includes('https://edukaan.home.tatamotors/#/')) {
      
      gtag('event', eventName, {
        UserMobileNumber: UserMobileNumber,
        Address: Address,
        Quantity: Quantity,
        PartNumber: PartNumber
      })
    }
  }
  public addToCartFail(eventName: string, Address: string, PartNumber: string, Quantity: number, UserMobileNumber: number, Message: string) {
    var baseurl = window.location.href;
    if (baseurl.includes('https://edukaan.home.tatamotors/#/')) {
      gtag('event', eventName, {
        UserMobileNumber: UserMobileNumber,
        Address: Address,
        Quantity: Quantity,
        PartNumber: PartNumber
      })
    }
  }
  public login(
    eventName: string,
    UserType: string,
    UserMobileNumber: number,
  ) {
    var baseurl = window.location.href;
    if (baseurl.includes('https://edukaan.home.tatamotors/#/')) {
      gtag('event', eventName, {
        UserMobileNumber: UserMobileNumber,
        UserType: UserType,
      })
    }
  }
  public chassisSearch(eventName: string, Address: string, chassis_num: string, UserMobileNumber: number,) {
    var baseurl = window.location.href;
    if (baseurl.includes('https://edukaan.home.tatamotors/#/')) {
      gtag('event', eventName, {
        chassis_num: chassis_num,
        Address: Address,
        UserMobileNumber: UserMobileNumber
      })
    }
  }
  public chassisSearchFail(eventName: string, Address: string, chassis_num: string, UserMobileNumber: number,) {
    var baseurl = window.location.href;
   if (baseurl.includes('https://edukaan.home.tatamotors/#/')) {
      gtag('event', eventName, {
        chassis_num: chassis_num,
        Address: Address,
        UserMobileNumber: UserMobileNumber
      })
    }
  }
  public regSearch(eventName: string, Address: string, reg_num: string, UserMobileNumber: number,) {
    var baseurl = window.location.href;
    if (baseurl.includes('https://edukaan.home.tatamotors/#/')) {
      gtag('event', eventName, {
        reg_num: reg_num,
        Address: Address,
        UserMobileNumber: UserMobileNumber
      })
    }
  }
  public regSearchFail(eventName: string, Address: string, reg_num: string, UserMobileNumber: number,) {
    var baseurl = window.location.href;
   if (baseurl.includes('https://edukaan.home.tatamotors/#/')) {
      gtag('event', eventName, {
        reg_num: reg_num,
        Address: Address,
        UserMobileNumber: UserMobileNumber
      })
    }
  }
  public modelSearch(
    eventName: string,
    Address: string,
    model_name: string,
    UserMobileNumber: number,

  ) {
    var baseurl = window.location.href;
    if (baseurl.includes('https://edukaan.home.tatamotors/#/')) {
      gtag('event', eventName, {
        model_name: model_name,
        Address: Address,
        UserMobileNumber: UserMobileNumber
      })
    }
  }
  public modelSearchFail(
    eventName: string,
    Address: string,
    model_name: string,
    UserMobileNumber: number,

  ) {
    var baseurl = window.location.href;
    if (baseurl.includes('https://edukaan.home.tatamotors/#/')) {
      gtag('event', eventName, {
        model_name: model_name,
        Address: Address,
        UserMobileNumber: UserMobileNumber
      })
    }
  }
  public RegistrationFail(
    eventName: string,
    RegType: string,
    UserType: string,
    RegistrationValue: string
  ) {
    var baseurl = window.location.href;
    if (baseurl.includes('https://edukaan.home.tatamotors/#/')) {
      gtag('event', eventName, {
        RegType: RegType,
        UserType: UserType,
        RegistrationValue: RegistrationValue
      })
    }
  }

  public loginFail(
    eventName: string,
    UserType: string,
    UserMobileNumber: number,
  ) {
    var baseurl = window.location.href;
    if (baseurl.includes('https://edukaan.home.tatamotors/#/')) {
      gtag('event', eventName, {
        UserType: UserType,
        UserMobileNumber: UserMobileNumber,

      })
    }
  }

  public bannerClick(
    eventName: string,
    Address: string,
    UserMobileNumber: number,

  ) {
    var baseurl = window.location.href;
    if (baseurl.includes('https://edukaan.home.tatamotors/#/')) {
      gtag('event', eventName, {
        Address: Address,
        UserMobileNumber: UserMobileNumber
      })
    }
  }
}