import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product-details-schemes',
  templateUrl: './product-details-schemes.component.html',
  styleUrls: ['./product-details-schemes.component.scss']
})
export class ProductDetailsSchemesComponent implements OnInit {
  @Input() schemeDetails:any[]=[];
  @Input() offerDetails:any[]=[];
  @Input() slabMaster:any[]=[];

  @Input() slabDetails:any[]=[];

  @Input() slabOffer:any=[]
  @Output() closeModel = new EventEmitter<any>();
  @Input() popupOpenType:any;
  schemeSlabList:any[] = [];
  slabDetailarr:any = [];
  slabMasterarr:any = [];
  skuItemArr:any = [];
  @Input() dilogRef:any
  constructor(private route:Router,
  ) { }

  ngOnInit(): void {
    // console.log("check_market_desx sjxbjs js js",this.popupOpenType,this.slabDetails,this.slabMaster);
    this.skuItemArr = [];
    this.skuItemArr = this.slabMaster.reduce((accumulator, current) => {
      if (!accumulator.some(obj => obj.sku_item === current.sku_item)) {
          accumulator.push(current);
      }
      return accumulator;
  }, []);
  this.skuItemArr.forEach(ele => {
    ele['tempQuantity'] = 1;
  })
    // if(this.schemeDetails.length > 0 ){
    //   this.schemeSlabList = Object.values(this.schemeDetails.reduce((result, element) => {
    //     if (!result[element.scheme_code]) {
    //       result[element.scheme_code] = [];
    //     }
    //     result[element.scheme_code].push({scheme_code:element.scheme_code,slab_from:element.slab_from,slab_to:element.slab_to,percentage:element.percentage});
    //     return result;
    //   }, {}));
    //   this.schemeDetails = [...new Map(this.schemeDetails.map((m) => [m.scheme_code, m])).values()];
    //   this.schemeDetails.forEach((element) => {
    //     for(var i = 0; i < this.schemeSlabList.length; i++){
    //         if( this.schemeSlabList[i][0].scheme_code ==  element.scheme_code ){
    //           this.schemeDetails[i].slab_details = this.schemeSlabList[i];
    //         }
    //     }
    //   });
    // }
    this.schemeSlabList =[]
    this.showOffer()

  }

  // New table Design Merge two API get_part_discount and scheme_detail
  showOffer(){
    if(this.popupOpenType == 'otherOffer'){
      if(this.schemeDetails){
        for(var i=0;i<this.schemeDetails.length;i++){
          const objOfferdata: offerInput = {} as offerInput;
          objOfferdata.discount_name = this.schemeDetails[i].scheme_name,
          objOfferdata.discount_code = this.schemeDetails[i].scheme_code,
          objOfferdata.discount_type = "slab Discount",
          objOfferdata.min_qty = this.schemeDetails[i].slab_from,
          objOfferdata.max_qty = this.schemeDetails[i].slab_to,
          objOfferdata.percentage = this.schemeDetails[i].percentage,
          objOfferdata.start_end = this.schemeDetails[i].start_dt,
          objOfferdata.end_date = this.schemeDetails[i].end_dt,
          objOfferdata.is_active = this.schemeDetails[i].is_active,
          this.schemeSlabList.push(objOfferdata)
        }
      }
      if(this.offerDetails){
        for(var j=0;j<this.slabDetails.length;j++){

          const objOfferdata: offerInput = {} as offerInput;
          objOfferdata.discount_name = this.offerDetails[j].discount_name,
          objOfferdata.discount_code = this.offerDetails[j].discount_code,
          objOfferdata.discount_type =  this.discountTpe(this.offerDetails[j].discount_type),
          objOfferdata.min_qty = this.offerDetails[j].discount_type == 'quantity_discount'? this.offerDetails[j].quantity: 'NA',
          objOfferdata.max_qty = 'NA',
          objOfferdata.percentage = this.offerDetails[j].discount_type == 'quantity_discount'?this.offerDetails[j].discount_detail_percentage:this.offerDetails[j].percentage,
          objOfferdata.start_end = this.offerDetails[j].from_date,
          objOfferdata.end_date = this.offerDetails[j].to_date,
          objOfferdata.is_active = this.offerDetails[j].is_active,
          this.schemeSlabList.push(objOfferdata)
        }
      }
    }
    else if(this.popupOpenType == 'cartaddress'){
      for(var j=0;j<this.slabDetails.length;j++){
        const objOfferdata: slabInput = {} as slabInput;
        objOfferdata.quantity = this.slabDetails[j].quantity,
        objOfferdata.percentage = this.slabDetails[j].percentage,
        this.slabDetailarr.push(objOfferdata)
      }
      // console.log("in_model_component", this.slabDetailarr)
    }else{
      for(var j=0;j<this.slabOffer.length;j++){
        const objOfferdata: offerInput = {} as offerInput;
        objOfferdata.discount_name = this.slabOffer[j].discount_name,
        objOfferdata.discount_code = this.slabOffer[j].discount_code,
        objOfferdata.discount_type =  this.discountTpe(this.slabOffer[j].discount_type),
        objOfferdata.min_qty = this.slabOffer[j].discount_type == 'quantity_discount'? this.slabOffer[j].quantity: 'NA',
        objOfferdata.max_qty = 'NA',
        objOfferdata.percentage = this.slabOffer[j].discount_type == 'quantity_discount'?this.slabOffer[j].discount_detail_percentage:this.slabOffer[j].percentage,
        objOfferdata.start_end = this.slabOffer[j].from_date,
        objOfferdata.end_date = this.slabOffer[j].to_date,
        objOfferdata.is_active = this.slabOffer[j].is_active,
        this.schemeSlabList.push(objOfferdata)
      }
    }
    
  }

  discountTpe(type:any){
    if(type == 'quantity_discount'){
        return 'Quantity Discount'
    }
    else if(type == 'default_discount'){
      return 'Standard Discount'
    }
    else if(type == 'cash_discount'){
      return 'Cash Discount'
    }
    else {
      return type
    }
  }
  routeToProduct(value){
    if (value.sku_item) {
      this.route.navigate(['/shop/product-details'], {
        queryParams: { part_Number: value.sku_item },
      }).then(res => {
        this.dilogRef.hide()
      });
    }
  }

  readproductdetaailQuantity(data,event,index){
    // console.log("check_event_index>>",data,event,index);
    this.skuItemArr[index].tempQuantity =  event;
    // console.log("checK-temppnxsndcdcdocdc",this.skuItemArr[index].tempQuantity,this.skuItemArr[index].resource_type)
        if( this.skuItemArr[index].resource_type == 'Pack' ){
          this.skuItemArr[index].tempQuantity = event * this.skuItemArr[index]?.moq; 
        }
  }
  partAddtoCart(product,index){
    // console.log("chec_product_index",product,index);
  }
}

export class slabInput{
  quantity:any
  percentage:string;

}
export class offerInput{
  discount_name:string;
  discount_type:any;
  discount_code:string;
  percentage:string;
  min_qty:string;
  max_qty:string;
  start_end:any;
  end_date:any;
  is_active:any;
}
