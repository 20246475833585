import { NgModule } from '@angular/core';

// modules (angular)
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {MatInputModule} from '@angular/material/input';
// modules (third-party)

// modules
import { SharedModule } from '../../shared/shared.module';
import { WidgetFiltersComponent } from './widget-filters/widget-filters.component';



// widgets

@NgModule({
    declarations: [
        // widgets
        WidgetFiltersComponent
    ],
    imports: [
        // modules (angular)
        CommonModule,
        FormsModule,
        MatInputModule,
        ReactiveFormsModule,
        RouterModule,
        
        // modules (third-party)

        // modules
        SharedModule
    ],
    exports: [
        // widgets
        WidgetFiltersComponent
    ]
})
export class WidgetsModule { }
