import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Inputdata } from '../../interfaces/product';
import { ModelService } from '../../services/REST/model.service';


@Component({
  selector: 'app-productsubmodel',
  templateUrl: './productsubmodel.component.html',
  styleUrls: ['./productsubmodel.component.scss']
})
export class ProductsubmodelComponent implements OnInit {

  allsubModelData: any;  
  activesubModel :any;
  @Input() selectmodel: any;
  @Output() closeSubModelPopUp = new EventEmitter<any>();
  @Output() showVcDetails = new EventEmitter<any>();
  
  constructor( private model: ModelService,) { }

  ngOnInit(): void {
        this.GetSubModelDetail(this.selectmodel,0);
  }

  

async GetSubModelDetail(model: any, offset) {
  this.allsubModelData = [];
  const ListInput: Inputdata = {} as Inputdata;
  ListInput.offset = offset;
  ListInput.limit = 1000;
  ListInput.multi_model_cat = [model];
  ListInput.dropdown_type = "base_model";
  let response = await this.model.getModelCategory(ListInput);
  if (response["success"] == true) {
    this.allsubModelData = response["data"]["Base_models"];
    this.allsubModelData = this.allsubModelData.sort((a, b) => {
      if (a.base_model_name < b.base_model_name) return -1;
      else if (a.base_model_name > b.base_model_name) return 1;
      else return 0;
    });
  }
  else {
  }
}

GetVcNo(submodel){
  this.activesubModel =submodel.base_model_name;
  this.showVcDetails.emit( submodel.base_model_name);
}

closeModal(){
  this.closeSubModelPopUp.emit();
}

}
