import { Component, OnInit, ElementRef, ViewChild, TemplateRef } from '@angular/core';
import { UserService } from 'src/app/shared/services/REST/user.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UserCartService } from 'src/app/shared/services/REST/usercart.service';
import { CartService } from 'src/app/shared/services/cart.service';
import { ExcelCreatorService } from '../../../../shared/services/excel-creator.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import readXlsxFile from 'read-excel-file';
import { blockPermissionMsg } from 'src/app/constant'
import { secureStorage } from 'src/app/shared/services/securestorage';
import * as XLSX from 'xlsx';
import { ProductService } from 'src/app/shared/services/REST/product.service';

@Component({
  selector: 'bulk-cart',
  templateUrl: './bulk-cart.component.html',
  styleUrls: ['./bulk-cart.component.scss'],
})
export class BulkCartComponent implements OnInit {
  blockPermissionMsg = blockPermissionMsg;
  modalRef1: any;
  @ViewChild('confimationmodal', { read: TemplateRef, static: false })
  template1: TemplateRef<any>;
  @ViewChild('buyAgain', { read: TemplateRef, static: false })
  buyAgain: TemplateRef<any>;
  drpCartType: any;
  cartTypeList: any;
  UploadResponseData: any;
  modalRef: any;
  @ViewChild('uploadmodal', { read: TemplateRef, static: false })
  template: TemplateRef<any>;
  btndisabled: boolean = false;
  errormsg: any;
  sidebarPosition: 'start' | 'end' = 'start'; // For LTR scripts "start" is "left" and "end" is "right"
  @ViewChild('inputFile', { static: false }) myInputVariable: ElementRef;
  DocumentFile: File = null;
  Filemessage: any;
  TypeName: any;
  btnshowConfrimed: boolean = true;
  isBulkUpload: boolean = true;
  submitted: boolean = false;
  showLoader: boolean = false;
  isUploadButtonClicked: boolean = false;
  isSubmitButtonCicked: boolean = false;
  printdata: string;
  excel = [];
  fileName: string;
  user: any;
  buyAgainModal: any;
  partData: any = []
  partDetails: any;
  buyAgainData: any = [];
  invalidParts:any=[]
  updatedCartData:any;
  bulkUpload:any = 'bulkUpload';
  nonPgCart_type:any;
  userDetail:any
  constructor(
    private modalService: BsModalService,
    private toastrService: ToastrService,
    private userService: UserService,
    private usercartService: UserCartService,
    private cartservice: CartService,
    private router: Router,
    private excelCreatorService: ExcelCreatorService,
    private localstorage: secureStorage,
    private cart: CartService,
    private productservice:ProductService
  ) { }

  ngOnInit(): void {
    this.cartTypeList = [];
    this.user = JSON.parse(this.localstorage.getItem('userData'));
    if (this.localstorage.getItem('getCartType')) {
      this.cartTypeList = JSON.parse(this.localstorage.getItem('getCartType'));
    }
    this.drpCartType = '';
  }
  SelectDocumentFiles(event) {
    try {
      this.fileName = '';
      if (event.target.files && event.target.files[0]) {
        this.Filemessage =
          'Are You Sure to upload ' + event.target.files[0].name + '?';
        var Extension = event.target.files[0].name
          .substring(event.target.files[0].name.lastIndexOf('.') + 1)
          .toLowerCase();
        if (Extension == 'xlsx' || Extension == 'XLSX') {
          const reader = new FileReader();
          const file = event.target.files[0];
        
          this.fileName = event.target.files[0].name;
          this.DocumentFile = event.target.files[0];
          if (file.size < 5000000) {
            reader.readAsDataURL(event.target.files[0]);
            this.readExcel(file);
            reader.onload = (event) => {
              let target: any = event.target;
            };
          } else {
            this.toastrService.error('Upload only 5 MB size files!');
            this.myInputVariable.nativeElement.value = " ";
          }
        } else {
          this.toastrService.error('Upload only XLSX Files');
          this.myInputVariable.nativeElement.value = " ";
        }
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'SelectDocumentFiles(event) ',
        page_name: 'PageAddBulkCartComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  readExcel(file: File): void {
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const binaryString: string = e.target.result;
      const workbook: XLSX.WorkBook = XLSX.read(binaryString, { type: 'binary' });
      const sheetName: string = workbook.SheetNames[0];
      const worksheet: XLSX.WorkSheet = workbook.Sheets[sheetName];
      const data: any[] = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      this.processData(data);
    };
    reader.readAsBinaryString(file);
  }

  processData(data: any[]): void {
    const headers = data[0];
    if (headers.length > 2) {
      this.toastrService.error('Excel file should contain only 2 column!');
      this.DocumentFile = null;
      this.fileName = '';
      return
    }
    const headerCol = ['part_number', 'quantity'];
    const  isCheckColHeader = headerCol.every(item => headers.includes(item));
    if(!isCheckColHeader){
      this.toastrService.error('Invalid column name!');
      this.DocumentFile = null;
      this.fileName = '';
      return
    }
    this.partData = data.slice(1).map(row => {
      const rowData: { [key: string]: any } = {};
      headers.forEach((header, index) => {
        rowData[header] = row[index];
      });
      return rowData;
    });
  }

  checkPermission() {
    if (!this.isUploadButtonClicked) {
      this.isUploadButtonClicked = true;
      let cartpermission = this.userService.CheckPermission('btn_cart');
      if (!cartpermission) {
        this.myInputVariable.nativeElement.value = " ";
        document.getElementById('inputFile') ? document.getElementById('inputFile').style.display = "none" : '';
        this.toastrService.error(this.blockPermissionMsg);
      }
      this.isUploadButtonClicked = false;
    }
    else {
      return;
    }
  }
  Upload(event) {
    try {
      let cartpermission = this.userService.CheckPermission('btn_cart');
      if (cartpermission) {
        this.drpCartType = event;
        if (this.drpCartType) {
          if (this.DocumentFile) {
            var Check = false;
            // let user = JSON.parse(this.localstorage.getItem('userData'));
            if (this.user) {
              this.UploadResponseData = [];
              const formData = new FormData();
              formData.append('files', this.DocumentFile);
              formData.append('account_pk', this.user.account_pk);
              formData.append('common_part_cart_type', this.drpCartType);
              Check = true;
              if (Check) {
                this.showLoader = true;
                this.usercartService
                  .bulkUploadAddToCart(formData)
                  .subscribe((response) => {
                    if (response.success === true) {
                      if (
                        response.data.msg ===
                        'Provided xls or xlsx file is empty.' ||
                        response.data.msg ===
                        'Invalid file format. Please reupload using the assigned format only.'
                      ) {
                        if (this.modalRef) {
                          this.modalRef.hide();
                        }
                        if (this.modalRef1) {
                          this.modalRef1.hide();
                        }
                        this.toastrService.error(response.data.msg);
                      } else {
                        this.UploadResponseData = response.data.Part_not_added_with_reason;
                        if(response.data.row_count != response.data.Part_not_added_with_reason.length){
                          this.toastrService.success(response.data.msg);
                        }
                        if (this.modalRef) {
                          this.modalRef.hide();
                        }
                        if (this.modalRef1) {
                          this.modalRef1.hide();
                        }
                        if (response.data.Part_not_added_with_reason.length > 0) {
                          this.modalRef = this.modalService.show(
                            this.template,
                            {
                              class: 'modal-dialog-centered modal-md',
                              backdrop: 'static',
                            }
                          );
                        } else {
                          this.cartservice.cartConfiguration();
                        }

                        this.DocumentFile = null;
                        this.myInputVariable.nativeElement.value = '';
                        this.fileName = '';
                        this.drpCartType = '';
                      }
                    } else {
                      if (this.modalRef) {
                        this.modalRef.hide();
                      }
                      if (this.modalRef1) {
                        this.modalRef1.hide();
                      }
                      this.drpCartType = '';
                      this.DocumentFile = null;
                      this.fileName = '';
                      this.myInputVariable.nativeElement.value = '';
                      if (response?.data?.msg) {
                        this.toastrService.error(response.data.msg);
                      }
                    }
                    this.showLoader = false;
                  },
                    error => {
                      if (error?.error?.data?.msg) {
                        this.toastrService.error(error.error.data.msg);
                      }
                      this.showLoader = false;
                    });
              } else {
                this.toastrService.error('Please Try After Some Times');
              }
            }
          } else {
            this.toastrService.error('Please Upload Excel File.');
          }
        } else {
          this.toastrService.error('Please Select cart type.');
        }
      } else {
        this.toastrService.error(this.blockPermissionMsg);
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'Upload() ',
        page_name: 'PageAddBulkCartComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  DownloadFile() {
    try {
      if (this.UploadResponseData.length > 0) {
        this.showLoader = true;
        this.excelCreatorService.exportAsExcelFileVehicleStatus(
          this.UploadResponseData,
          'Bulk_Order_upload_status'
        );
        setTimeout(() => {
          this.showLoader = false;
        }, 400);
      }
      this.UploadResponseData = [];
      if (this.modalRef) {
        this.modalRef.hide();
      }
      this.cartservice.cartConfiguration();
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'DownloadFile() ',
        page_name: 'PageAddBulkCartComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  ShowPart() {
    try {
      if (this.modalRef) {
        this.modalRef.hide();
      }
      if (this.modalRef1) {
        this.modalRef1.hide();
      }
      if (!this.isSubmitButtonCicked) {
        this.isSubmitButtonCicked = true;
        let cartpermission = this.userService.CheckPermission('btn_cart');
        if (cartpermission) {
          let dataCount = 0;
          let cartPerCount = this.cartTypeList.length * 50 + 1;
          let isInvalid: boolean = false;
          if (this.DocumentFile) {
            readXlsxFile(this.DocumentFile).then((rows) => {
              if(rows.length <= 51){
              if (rows.length <= cartPerCount) {
                for (var i = 0; i < rows.length; i++) {
                  if (rows[i][0] != null && rows[i][1] != null) {
                    dataCount += 1;
                  } else if (rows[i][0] == null || rows[i][1] == null) {
                    isInvalid = true;
                  }                 
                }
                if (isInvalid) {
                  this.toastrService.error('Part number or quantity  missing');
                  isInvalid = false;
                } else if (rows.length == dataCount) {
                  this.isBulkUpload = true;
                  // this.modalRef1 = this.modalService.show(this.template1, {
                  //   class: 'modal-dialog-centered modal-md',
                  //   backdrop: 'static',
                  // });
                  this.addtocartpopup()
                }
              } else {
                this.toastrService.error(
                  'Only 50 Unique Items per cart can be ordered against a single order. A separate order will have to be raised for the remaining parts.'
                );
                this.DocumentFile = null;
                this.myInputVariable.nativeElement.value = '';
                this.fileName = '';
                this.drpCartType = '';
              }
            }
            else{
              this.toastrService.error(
                'Maximum 50 Items can be uploaded in single upload'
              );
            }
            });
            this.isSubmitButtonCicked = false;
          } else {
            this.toastrService.error('Please Upload Excel File.');
            this.isSubmitButtonCicked = false;
          }
        }
        else {
          this.toastrService.error(this.blockPermissionMsg);
          this.isSubmitButtonCicked = false;
        }
      }
      else {
        return;
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'ShowPart()',
        page_name: 'PageAddBulkCartComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  addtocartpopup() {
    let address = JSON.parse(this.localstorage.getItem('address'));
    var part_list = [];
    this.buyAgainData =[];
    this.partDetails =[];
    this.invalidParts = []
    this.partData.forEach(element => {
      if(element?.part_number != null){
      part_list.push(element.part_number)
      }
    });
    try {
      var requestBody = {
        "parts_list": part_list,
        "account_pk": this.user.account_pk,
        "is_for_basic_info": true,
        "offset" : 0,
        "limit" : 50,
        "is_for_inventory":this.user?.user_type == 'MECH' ? true: false,
        "shipping_address_id": address?.address['default'] ? "default" : address?.address['address_id'],
      }
      this.productservice.get_part_search_md(requestBody).subscribe(
        (data) => {
          if (data.success === true) {
            this.partDetails = data.data
            this.invalidParts = data?.invalid_part_number
            if (this.modalRef) {
              this.modalRef.hide();
            }
            if (this.modalRef1) {
              this.modalRef1.hide();
            }
            for (var i = 0; i < this.partDetails.length; i++) {
              for (var j = 0; j < this.partData.length; j++) {
                if (this.partDetails[i].part_number == this.partData[j].part_number) {
                  this.buyAgainData.push({
                    part_number: this.partDetails[i].part_number,
                    moq: this.partDetails[i].moq,
                    resource_type: this.partDetails[i].resource_type,
                    quantity: this.partData[j].quantity,
                    uom : this.partDetails[i].uom,
                    orderQuantity: (this.partDetails[i].resource_type == 'Pack') ? this.partData[j].quantity * this.partDetails[i].moq : this.partData[j].quantity,
                    inventory_quantity : this.partDetails[i].inventory_quantity,
                  })
                  const specifiedTypeItems = this.buyAgainData.filter(item => item.resource_type == 'Pack');
                  const otherTypeItems = this.buyAgainData.filter(item =>item.resource_type != 'Pack');
                  this.buyAgainData = specifiedTypeItems.concat(otherTypeItems);
                }
              }
            }
            this.buyAgainModal = this.modalService.show(this.buyAgain, {
              class: 'modal-dialog-centered modal-lg',
              backdrop: 'static',
            });
          } else {
            this.toastrService.error(data.data.msg);
            if (this.modalRef) {
              this.modalRef.hide();
            }
            if (this.modalRef1) {
              this.modalRef1.hide();
            }
          }
        },
        (err) => {
        }
      );
      this.cartservice.cartConfiguration();
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'DownloadFile() ',
        page_name: 'PageAddBulkCartComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  addToCart(order) {
    this.updatedCartData = order
    this.modalRef1 = this.modalService.show(this.template1, {
      class: 'modal-dialog-centered modal-md',
      backdrop: 'static',
    });
  }

  createExcel(event) {
    try {
      let cartpermission = this.userService.CheckPermission('btn_cart');
      if (cartpermission) {
        this.drpCartType = event.Pg;
        this.nonPgCart_type  = event.nonPg;
        if (this.drpCartType && this.nonPgCart_type) {
            var Check = false;
            // let user = JSON.parse(this.localstorage.getItem('userData'));
            if (this.user) {
              this.UploadResponseData = [];
              const cart_items =[]

              this.updatedCartData.forEach(element => {
                cart_items.push({
                  part_number:element.part_number,
                  quantity: ( element?.resource_type == 'Pack') ? (element.orderQuantity/element.moq):element.orderQuantity,
                })
              });   
             var requestBody =  {
                "common_part_cart_type":this.drpCartType,
                "pg6_common_cart_type":this.nonPgCart_type,
                "account_pk":this.user.account_pk,
                "cart_items":cart_items,
                "validate_request": false,
                "source": 'Excel'
                }
              Check = true;
              if (Check) {
                this.showLoader = true;
                this.productservice
                  .add_to_cart_ms(requestBody)
                  .subscribe((response) => {
                    if (response.success === true) {
                      if (
                        response.data.msg ===
                        'Provided xls or xlsx file is empty.' ||
                        response.data.msg ===
                        'Invalid file format. Please reupload using the assigned format only.'
                      ) {
                        if (this.modalRef) {
                          this.modalRef.hide();
                        }
                        if (this.modalRef1) {
                          this.modalRef1.hide();
                        }
                        this.toastrService.error(response.data.msg);
                      } else {
                        this.UploadResponseData = response.data.Part_not_added_with_reason;
                        if(response.data.row_count != response.data.Part_not_added_with_reason.length){
                          this.toastrService.success(response.data.msg);
                        }
                        if (this.modalRef) {
                          this.modalRef.hide();
                        }
                        if (this.modalRef1) {
                          this.modalRef1.hide();
                        }
                        if (response.data.Part_not_added_with_reason.length > 0) {
                          this.modalRef = this.modalService.show(
                            this.template,
                            {
                              class: 'modal-dialog-centered modal-md',
                              backdrop: 'static',
                            }
                          );
                        } else {
                          this.cartservice.cartConfiguration();
                        }

                        this.DocumentFile = null;
                        this.myInputVariable.nativeElement.value = '';
                        this.fileName = '';
                        this.drpCartType = '';
                      }
                    } else {
                      if (this.modalRef) {
                        this.modalRef.hide();
                      }
                      if (this.modalRef1) {
                        this.modalRef1.hide();
                      }
                      this.drpCartType = '';
                      this.DocumentFile = null;
                      this.fileName = '';
                      this.partData= [];
                      this.partDetails= [];
                      this.buyAgainData= [];
                      this.updatedCartData= [];
                      this.myInputVariable.nativeElement.value = '';
                      if (response?.data?.msg) {
                        this.toastrService.error(response.data.msg);
                      }
                    }
                    this.showLoader = false;
                  },
                    error => {
                      if (error?.error?.data?.msg) {
                        this.toastrService.error(error.error.data.msg);
                      }
                      this.showLoader = false;
                    });
              } else {
                this.toastrService.error('Please Try After Some Times');
              }
            }
        } else {
          this.toastrService.error('Please Select cart type.');
        }
      } else {
        this.toastrService.error(this.blockPermissionMsg);
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'Upload() ',
        page_name: 'PageAddBulkCartComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
    }
}
