<div class="container-fluid colntainer-md pt-3 margin-top-for-mobile" id="scheme-list">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 Fcol-xl-12 col-xxl-12 product-lists">
            <ul class="nav nav-tabs tabs1" id="myTab" role="tablist">
                <li class="nav-item" role="presentation" [routerLink]="['/account/myoffer']" [queryParams]="{'isMyOffer':true}">
                    <a class="nav-link" id="order-tab" data-bs-toggle="tab"  role="tab"
                        aria-controls="order" aria-selected="true">My Discounts</a>
                </li>
                <li class="nav-item" role="presentation" [routerLink]="['/account/coupons']" [queryParams]="{'isMyOffer':true}">
                    <a class="nav-link" id="order-tab" data-bs-toggle="tab"  role="tab"
                        aria-controls="order" aria-selected="true">Coupons</a>
                </li>
                <li class="nav-item" role="presentation" [routerLink]="['/account/schemes']" [queryParams]="{'isMyOffer':true}">
                    <a class="nav-link active" id="order-tab order-tab-scheme-list" data-bs-toggle="tab"  role="tab"
                        aria-controls="order" aria-selected="true">My Schemes</a>
                </li>
                <li class="nav-item" role="presentation" [routerLink]="['/account/combo-offer']" [queryParams]="{'isMyOffer':true}">
                    <a class="nav-link" id="order-tab" data-bs-toggle="tab"  role="tab"
                        aria-controls="order" aria-selected="true">My Combo Offers</a>
                  </li>
            </ul>
            <hr class="hr-page-divider" />
            <div class="tab-pane">
                <nav>
                    <div class="nav nav-tabs tabs-inner" id="nav-tab" role="tablist">
                        <div class="push">
                            <div class="search">
                                <img class="search-icon" alt="" src="./assets/images/search.svg" />
                                <input (focusout)="applyFilter()"
                                [formControl]="filterForm.controls['scheme_name']" class="form-control" type="search"
                                (search)="applyFilter()"
                                placeholder="Search by Scheme Name" />
                            </div>
                            <div class="filter drop-filter position-relative">
                                <a (click)="filter();"><img alt="" src="./assets/images/filter-off.svg" /></a>
                                <div class="filter-container">
                                <div class="triangle"></div>
                                <ul class="filter-area">
                                    <!-- <li>
                                        <h3 class="title">Search By </h3>
                                        <div>
                                            <div class="date-picker-container d-table-cell">
                                            <input type="text" [formControl]="filterForm.controls['scheme_name']"
                                                (keyup.enter)="applyFilter()"
                                                
                                                (search)="applyFilter()"
                                                class="form-control d-inline-flex form-control-sm search-by" placeholder="Search by Scheme Name" />
                                            </div>
                                        </div>
                                    </li> -->
                                    <li>
                                        <div>
                                            <div class="sub-title ps-1 pe-1">
                                            <label>Created Date</label>
                                            </div>
                                            <div class="date-picker-container d-table-cell ps-2">
                                                <mat-form-field style="display: inline">
                                                    <input matInput  readonly placeholder="From Date"
                                                        (click)="picker2?.open()" [formControl]="filterForm.controls['from_date']"
                                                        [matDatepicker]="picker2">
                        
                                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                                    <mat-datepicker #picker2 disabled="false"></mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                            <div class="date-picker-container d-table-cell ps-2">
                                                <mat-form-field style="display: inline">
                                                    <input placeholder="To Date" readonly matInput 
                                                        (click)="dp2?.open()" [formControl]="filterForm.controls['to_date']"
                                                        [matDatepicker]="dp2">
                                                    <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                                                    <mat-datepicker #dp2 disabled="false"></mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                            <div class="sub-title ps-1 pe-1" style="padding-bottom: 1.25em;">
                                                <mat-form-field class="example-full-width" style="display: inline">
                                                    <mat-label>Scheme Type</mat-label>
                                                    <mat-select [formControl]="filterForm.controls['scheme_type']">
                                                        <mat-option value="">All</mat-option>
                                                        <mat-option value="value_scheme">Value Scheme</mat-option>
                                                        <mat-option value="quantity_scheme">Quantity Scheme</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div class="sub-title ps-1 pe-1" style="padding-bottom: 1.25em;">
                                                <mat-form-field class="example-full-width" style="display: inline">
                                                    <mat-label>Scheme Status</mat-label>
                                                    <mat-select [formControl]="filterForm.controls['is_active']">
                                                        <mat-option value="active">Active</mat-option>
                                                        <mat-option value="in_active">In Active</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div class="d-table-cell ps-2">
                                            <button type="button" class="btn btn-outline-primary" (click)="applyFilter();">Search</button>
                                            </div>
                                            <div class="d-table-cell ps-2">
                                            <button type="button" class="btn btn-outline-primary" (click)="reset($event)">Reset</button>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
                <div class="tab-content mt-3" id="nav-tabContent">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-left: 15px; padding-right: 15px">
                      <div class="table-responsive table-container">
                        <table class="table datatable display" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                          <thead>
                            <tr>
                              <th class="text-nowrap">Scheme Name</th>
                              <th class="text-nowrap">Scheme Type</th>
                              <th class="text-nowrap">Start Date</th>
                              <th class="text-nowrap">End Date</th>
                              <th class="text-nowrap">Created Date</th>
                              <th class="text-nowrap">Status</th>
                            </tr>
                          </thead>
                            <tbody>
                                <tr *ngFor="let item of schemeList; let i = index">
                                    <td class="text-nowrap sticky-col first-col">
                                        <a class="me-2" (click)="details(item?.scheme_code)">
                                            {{ item?.scheme_name }}
                                        </a>
                                    </td>
                                    <td class="showcursor text-nowrap"> {{ item.scheme_type }}</td>
                                    <td class="text-nowrap">{{ item?.start_dt | date: "dd-MM-yyyy" }}</td>
                                    <td class="text-nowrap">{{ item?.end_dt | date: "dd-MM-yyyy" }}</td>
                                    <td class="text-nowrap">{{ item?.created_at | date: "dd-MM-yyyy" }}</td>
                                    <td class="text-nowrap">{{ item?.is_active == true?'Active':'In Active' }}</td>
                                </tr>
                            </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>
    </div>
</div>

<ng-template #schemeDetails let-d="dismiss">
    <app-scheme-details [schemeCode]="scheme_code" (closeModel)="closeDetails()"></app-scheme-details>
</ng-template>