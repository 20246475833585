import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppStateService } from '../../services/app-state/app-state.service';
import { ProductService } from '../../services/REST/product.service';
import { secureStorage } from '../../services/securestorage';

@Component({
  selector: 'app-wishlist-icon',
  templateUrl: './wishlist-icon.component.html',
  styleUrls: ['./wishlist-icon.component.scss']
})
export class WishlistIconComponent implements OnInit {
  @Input() product : any;
  @Input() layout : any;
  @Input() isWishlistedItem : any;
    isLoggedIn: boolean = false;
    account_pk: any;
  wishlistcalled="";
  cartTypeList;
  constructor(
    private router:Router,
    private appStateService: AppStateService,
    private productService : ProductService,
    private toasterService : ToastrService,
    private localstorage: secureStorage
    ) { }

  ngOnInit(): void {
    this.appStateService.isLoggedIn.subscribe((isLog) => {
      this.isLoggedIn = isLog;
  });
  }
  addToWishlist(){
    
   
    if(this.isLoggedIn){
      if(this.isWishlistedItem){
        return;
      }
      let user = JSON.parse(this.localstorage.getItem('userData'));
      this.account_pk =  JSON.stringify(user.account_pk);
      let data ={
        "account_pk":this.account_pk,
        "parts_list": [
          {
            "part_number":this.product.part_number,
            "cart_type": this.getCartType()
              // "quantity": "1",
             
          }
      ]
    }
      this.productService.addtoWishlist(data).subscribe((res)=>{
        if(res.success){
          if(res.data.msg == "Part added to wishlist."){
            let toasterMsg =  `Product "${data.parts_list[0].part_number}" added to wishlist!`
            this.toasterService.success(toasterMsg);
          }
          this.appStateService.wishlistItemadded.next(true);
        }
        else{

          this.toasterService.error(res.data.msg)
        }
      }  ,
      (err) => {})
    }
    else{
      this.router.navigate(['/account/login'], {
        queryParams: { 'isLogin':true },
      });
      // let wishListProductsLs:any[] = [];
      // if(JSON.parse(this.localstorage.getItem('wishListItem'))){
      //   wishListProductsLs = JSON.parse(this.localstorage.getItem('wishListItem'));
      // }
      // wishListProductsLs.push(this.product);
      // this.localstorage.setItem('wishListItem',JSON.stringify(wishListProductsLs));
      // this.toasterService.success(`Product ${this.product.part_number} added to wishlist!`)
    }
  }

  getCartType(){
    if (this.localstorage.getItem('getCartType')) {
      this.cartTypeList = JSON.parse(this.localstorage.getItem('getCartType'));
    }
    var commoncarttypedata = this.cartTypeList.filter(alitems => alitems.common_distributor_categories.includes(this.product['distributor_category']));
    var Distributercarttypedata = this.cartTypeList.filter(alitems => alitems.distributor_categories.includes(this.product['distributor_category']));
    let cart_type_name = "";
          if (commoncarttypedata.length > 0) {
            //selectcarttype => sets when user has selected a cart type in common part popup 
            if (this.localstorage.getItem('selectcarttype')) {
              cart_type_name = this.localstorage.getItem('selectcarttype');
            }
            else {
              cart_type_name = commoncarttypedata[0]["cart_type"]
            }
          }
          else if (Distributercarttypedata.length > 0) {
            cart_type_name = Distributercarttypedata[0]["cart_type"];
          }
          else{}
          return cart_type_name;
  }
}
