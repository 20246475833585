<div class="container-fluid colntainer-md pt-3 margin-top-for-mobile">
  <div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 Fcol-xl-12 col-xxl-12 product-lists">
      <ul class="nav nav-tabs tabs1" id="myTab" role="tablist">
        <li class="nav-item" role="presentation" [routerLink]="['/account/myoffer']" [queryParams]="{'isMyOffer':true}">
            <a class="nav-link active" id="order-tab" data-bs-toggle="tab" href="#order" role="tab"
                aria-controls="order" aria-selected="true">My Discounts</a>
        </li>
        <li *ngIf="isLoggedIn" class="nav-item" role="presentation" [routerLink]="['/account/coupons']" [queryParams]="{'isMyOffer':true}">
            <a class="nav-link" id="order-tab" data-bs-toggle="tab" href="#order" role="tab"
                aria-controls="order" aria-selected="true">Coupons</a>
        </li>
        <li *ngIf="isLoggedIn" class="nav-item" role="presentation" [routerLink]="['/account/schemes']" [queryParams]="{'isMyOffer':true}">
          <a class="nav-link" id="order-tab" data-bs-toggle="tab"  role="tab"
              aria-controls="order" aria-selected="true">My Schemes</a>
        </li>
        <li *ngIf="isLoggedIn" class="nav-item" role="presentation" [routerLink]="['/account/combo-offer']" [queryParams]="{'isMyOffer':true}">
          <a class="nav-link" id="order-tab" data-bs-toggle="tab"  role="tab"
              aria-controls="order" aria-selected="true">My Combo Offers</a>
        </li>
      </ul>
      <hr class="hr-page-divider" />
      <!-- <h4 style="float:left;" class="">My Discounts</h4> -->
      <div *ngIf="ProductListData?.length == 0 && isNoDataFound" class="empty-box" style="margin-top: 10%;">
        <img src="./assets/images/empty box.png" />
        <p class="text-center">No Discounts Available</p>
      </div>
      <div class="d-flex mb-4 justify-content-between align-items-center" *ngIf="ProductListData?.length > 0">
        <div clas="prod-count col-12 col-sm-6">

          <span class="search-results"
            *ngIf="headerSearchText != '' && headerSearchText != undefined && headerSearchText != null">
            {{'Search Results For ' + headerSearchText}} </span>
        </div>
        <div class="d-flex mb-4 justify-content-between align-items-center">
          <!-- <div class="grid-flter col-2 col-sm-1 text-nowrap text-end d-none d-md-flex">
            <button class="btn btn-link p-0" (click)="GotoView('Grid')" *ngIf="layout == 'Grid'">
              <img src="./assets/images/card.svg" alt="icon" srcset="" />
            </button>
            <button class="btn btn-link p-0" (click)="GotoView('Grid')" *ngIf="layout == 'list'">
              <img src="./assets/images/card-inactive.svg" alt="icon" srcset="" />
            </button>
            <button class="btn btn-link p-0 me-1" (click)="GotoView('list')" *ngIf="layout == 'Grid'">
              <img src="./assets/images/list.svg" alt="icon" srcset="" />
            </button>
            <button class="btn btn-link p-0 me-1" (click)="GotoView('list')" *ngIf="layout == 'list'">
              <img src="./assets/images/list-active.svg" alt="icon" srcset="" />
            </button>
          </div> -->
        </div>
      </div>

      <!-- Card "Grid"-->
      <div class="row">
        <div class="col-12">
          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4 row-cols-xl-4 row-cols-xxl-4 g-3"
            *ngIf="layout == 'Grid' && ProductListData?.length > 0; else skeletonForGrid">
            <div *ngFor="let product of ProductListData">
              <div class="card border-0">
                <div class="discount" *ngIf="((product?.base_discount + product?.scheme_discount +
            product?.tml_rule_discount + product?.dealer_rule_discount +
            product?.rule_discount) > 0) && isLoggedIn"
                  (click)="GotoPartCategory(product.pg_line,'PARTS CATEGORIES',false,product.discount_code)">
                </div>

                <div class="card-body p-3 pb-0">
                  <div (click)="GotoPartCategory(product.pg_line,'PARTS CATEGORIES',false,product.discount_code)" style="margin-top: 5px; "
                    [breadcrumbs]="breadcrumbs">
                    <h5 class="fw-bold prod-model mb-2 cursor">{{ product.discount_name }}</h5>
                  </div>
                  <div class="col-12">
                    <h6 *ngIf="product.isShowOnlyToDate==false">Validity: {{product.from_date}} - {{product.to_date }}
                    </h6>
                    <h6 *ngIf="product.isShowOnlyToDate==true">Valid till {{product.to_date }}</h6>

                  </div>
                  <div class="row col-12" style="margin-bottom: 10px;">
                    <div class="col-6">

                    </div>
                    <div (click)="GotoPartCategory(product.pg_line,'PARTS CATEGORIES',false,product.discount_code)" [breadcrumbs]="breadcrumbs"
                      class="prod-img mx-auto text-center  position-relative col-6">
                      <img [src]="product?.banner_url" onerror="this.src='./assets/tata-vehicle.jfif';"
                        class="img-fluid cursor" alt="prod-img-alt" srcset=""
                        style="max-width: 100%; max-height: 100%; top: 50%; position: absolute; transform: translate(-50%, -50%);" />
                    </div>
                  </div>
                  <div class="prod-content position-relative row col-12">
                    <div class="col-8" (click)="GotoPartCategory(product.pg_line,'PARTS CATEGORIES',false, product.discount_code)"
                      [breadcrumbs]="breadcrumbs">
                      <h4 class="prod-name mb-2">{{product.pg_line}}</h4>
                    </div>
                    <div class="col-4">
                      <button class="btnclick" (click)="GotoPartCategory(product.pg_line,'PARTS CATEGORIES',false, product.discount_code)">View All Parts</button>
                    </div>
                  </div>
                </div>
                <div class="discount">
                  <!-- [routerLink]="['/shop/product-details']" [queryParams]="{ part_Number : product.part_number }"> -->
                  {{'SAVE ' + product.percentage + ' %'}}
                </div>

                <!-- *ngIf="(product?.base_discount + product?.scheme_discount +
                                                            product?.tml_rule_discount + product?.dealer_rule_discount +
                                                            product?.rule_discount) > 0" -->
                <!-- {{(((product.umrp-product.total_amount)/product.umrp)*100)?.toFixed(1) +
                                              '%'}} -->
              </div>
            </div>
          </div>

        </div>
      </div>
      <ng-template #skeletonForGrid>
        <div *ngIf="layout == 'Grid' && ProductListData?.length == 0 && !isNoDataFound">
          <div class="col-12" style="display: flex; flex-wrap: wrap; flex-direction:row;">
            <div class="row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4 row-cols-xl-4 row-cols-xxl-4 g-4"
              *ngFor="let item of generateFake(12);let i = index;" style="padding: 25px;">
              <div class="card border-0" style="width: 100%;">
                <div class="card-body p-3 ">
                  <div class="prod-content position-relative">
                    <skc-rectangle [loading]="loading" [skc-height]="'12px'" [skc-width]="'250px'">
                    </skc-rectangle>
                  </div>
                  <div class="prod-img mx-auto text-center  position-relative">
                    <skc-rectangle [loading]="loading" [skc-height]="'12px'" [skc-width]="'250px'">
                    </skc-rectangle>
                    <skc-rectangle [loading]="loading" [skc-height]="'122px'" [skc-width]="'250px'">
                    </skc-rectangle>
                  </div>
                  <div class="prod-content position-relative">
                    <skc-rectangle [loading]="loading" [skc-height]="'8px'" [skc-width]="'250px'">
                    </skc-rectangle>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <!-- End of cards -->

      <!-- Product List -->
      <!-- <div class="row" *ngIf="layout == 'list'">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
          <div class="table-responsive table-container">
            <table class="table datatable display align-middle">
              <thead>
                <tr>
                  <th class="text-nowrap">Sr. No.</th>
                  <th>Images</th>
                  <th class="text-nowrap">Discount Name</th>
                  <th class="text-nowrap">PG Line</th>
                </tr>
              </thead>
              <tbody *ngIf="ProductListData?.length > 0; else elseTemplate">
                <tr *ngFor="let product of ProductListData;let i = index;">
                  <td>{{i+1}}</td>
                  <td>
                    <img alt="" style="width: 44px; height: 30px;cursor: pointer;"
                      (click)="GotoPartCategory(product.pg_line,'PARTS CATEGORIES',true)" [src]="product?.banner_url" />
                  </td>
                  <td>
                    <div class="d-inline-flex align-items-start">
                      <div class="ps-2 ">
                        <div style="display: flex;">
                          <div class="p-title" style="cursor: pointer;">{{ product.discount_name }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="p-offer">
                      {{product.pg_line}}
                    </div>
                  </td>
                </tr>
              </tbody>
              <ng-template #elseTemplate>
                <tbody>
                  <tr *ngFor="let item of generateFake(12);let i = index;">
                    <td>
                      <skc-rectangle [loading]="loading" [skc-height]="'16px'" [skc-width]="'89px'"> </skc-rectangle>
                    </td>
                    <td>
                      <skc-rectangle [loading]="loading" [skc-height]="'16px'" [skc-width]="'89px'"> </skc-rectangle>
                    </td>
                    <td>
                      <skc-rectangle [loading]="loading" [skc-height]="'16px'" [skc-width]="'100%'"> </skc-rectangle>
                    </td>
                    <td>
                      <skc-rectangle [loading]="loading" [skc-height]="'16px'" [skc-width]="'100%'"> </skc-rectangle>
                    </td>
                  </tr>
                </tbody>
              </ng-template>
            </table>
          </div>
        </div>
      </div> -->
    </div>
  </div>