import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  showHeader: boolean = false;
  constructor( private route: ActivatedRoute ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      var isLogin = params['isLogin'];
      var isRegistration = params['isRegistration'];
      if(isLogin === 'true'){
        this.showHeader = true;
      }
      else if(isRegistration === 'true'){
        this.showHeader = true;
      }else if(params['isguestLogin'] === 'true' ){
        this.showHeader = true
      }else{ 
        this.showHeader = false;

      }
    });
    
  }
  
}
