<!-- <div class="modal-header">
    <h4 class="modal-title">Select Cart Type</h4>
    <button type="button" class="btn-close" (click)="modalClose()"></button>
</div> -->
<!-- <div class="modal-header modal-title">
    Select Cart Type
    <div type="button"><a (click)="modalClose()" class="fa fa-times" style="text-decoration: none;color: black;"></a>
    </div>
</div>
<div class="modal-body">
    <div class="form-group form-row row">
        <div class="custom-control custom-radio m-4 col" *ngFor="let typeitem of cartTypeList;let isFirst=first;">
            <input type="radio" class="custom-control-input" id="rd_{{typeitem.cart_type}}"
                (change)="changedCartType(typeitem)" value="{{typeitem.cart_type}}" [(ngModel)]="confirmedCartType"
                name="typeitem" [checked]="isFirst">
            &nbsp;<label class="custom-control-label"
                for="rd_{{typeitem.cart_type}}">{{ typeitem.display_name }}</label>
        </div>
    </div>
    <div class="seller dist" *ngIf="baseProductdata">
        <div class="name-of-the-seller" *ngIf="baseProductdata?.division_info?.ORG_NAME"
            style="font-size: 16px !important">
            Name of the Seller : {{baseProductdata?.division_info?.ORG_NAME}}
        </div>

        <div class="shop-no-29-j-k-cham" *ngIf="baseProductdata?.division_info?.DIV_NAME">
            <i class="fa fa-map-marker" aria-hidden="true"></i>
            {{baseProductdata?.division_info?.DIV_NAME}},
            {{baseProductdata?.division_info?.PRIMISE_ADDRLINE1_s}},
            {{baseProductdata?.division_info?.PRIMISE_ADDRESS2_s}}

        </div>
    </div>

</div>
<div class="modal-footer">
    <button type="button" *ngIf="btnshowsubmit" class="btn btn-primary" [disabled]="btndisabled"
        (click)="setAddCart()">&nbsp;Submit</button>
    <button type="button" *ngIf="btnshowConfrimed" class="btn btn-primary" [disabled]="btndisabled"
        (click)="confirmPart()">&nbsp;Confirm</button>
</div> -->

<div class="modal-content">
    <div class="modal-header modal-title">
        Select Cart Type
        <div (click)="modalClose()" type="button"><i class="fa fa-times"></i></div>
    </div>
    <div class="modal-body">
        <!-- <div class="form-group form-row row">
            <ng-container *ngFor="let typeitem of cartTypeList;let isFirst=first;">
                <div class="custom-control custom-radio m-4 col text-nowrap" *ngIf="typeitem.is_common_part_valid">
                    <input type="radio" class="custom-control-input pb-2 me-2" id="rd_{{typeitem.cart_type}}"
                        (change)="changedCartType(typeitem)" value="{{typeitem.cart_type}}" [(ngModel)]="confirmedCartType"
                        name="typeitem" [checked]="isFirst">
                    <label class="custom-control-label pb-2"
                        for="rd_{{typeitem.cart_type}}"><h4 class="mb-0 mat-location-type">{{ (typeitem.display_name == 'tmgo Cart') ? 'TMGO Cart' : typeitem.display_name }}</h4></label>
                </div>
            </ng-container>
            
        </div> -->
        <div *ngIf="fromPath != 'bulkUpload'" class="form-group form-row row">
            <ng-container *ngFor="let typeitem of cartTypeList;let isFirst=first;">
            <ng-container *ngIf="typeitem.display_name === 'TMGO Cart'">
                <div class="custom-control custom-radio m-4 col text-nowrap"  *ngIf="
                baseProductdata.data &&
                baseProductdata?.data[0].distributor_category &&
                baseProductdata?.data[0].distributor_category === 'COM' &&
                baseProductdata?.data[0].line_1 &&
                baseProductdata?.data[0].line_1.includes('PG6') 
              ">
              <!-- &&
              baseProductdata?.data[0].line_1.includes('UREA') -->
                    <input type="radio" class="custom-control-input pb-2 me-2" id="rd_{{typeitem.cart_type}}"
                        (change)="changedCartType(typeitem)" value="{{typeitem.cart_type}}" [(ngModel)]="confirmedCartType"
                        name="typeitem" [checked]="isFirst">
                    <label class="custom-control-label pb-2"
                        for="rd_{{typeitem.cart_type}}"><h4 class="mb-0 mat-location-type">{{ (typeitem.display_name == 'tmgo Cart') ? 'TMGO Cart' : typeitem.display_name }}</h4></label>
                </div>
            </ng-container>
                <ng-container>
                    <div class="custom-control custom-radio m-4 col text-nowrap" *ngIf="typeitem.display_name != 'TMGO Cart'">
                        <input type="radio" class="custom-control-input pb-2 me-2" id="rd_{{typeitem.cart_type}}"
                            (change)="changedCartType(typeitem)" value="{{typeitem.cart_type}}" [(ngModel)]="confirmedCartType"
                            name="typeitem" [checked]="isFirst">
                        <label class="custom-control-label pb-2"
                            for="rd_{{typeitem.cart_type}}"><h4 class="mb-0 mat-location-type">{{ (typeitem.display_name == 'tmgo Cart') ? 'TMGO Cart' : typeitem.display_name }}</h4></label>
                    </div>
                </ng-container>
            </ng-container>
            
        </div>
            <div *ngIf="fromPath == 'bulkUpload'" class="form-group form-row row">
                <ng-container >
                    <h3 style="font-weight: 700;font-size: 16px;">PG6</h3>
    
                    <div class="custom-control custom-radio m-4 col text-nowrap" *ngFor="let typeitem of cartTypeList; let isFirst = first;">
                        <input type="radio" class="custom-control-input pb-2 me-2" id="rd_{{typeitem.cart_type}}"
                            (change)="changedCartType(typeitem)" value="{{typeitem.display_name}}" 
                             name="cartType" [checked]="confirmedCartTypesbulk === typeitem.display_name">
                        <label class="custom-control-label pb-2"
                            for="rd_{{typeitem.cart_type}}"><h4 class="mb-0 mat-location-type">{{ (typeitem.display_name == 'tmgo Cart') ? 'TMGO Cart' : typeitem.display_name }}</h4></label>
                    </div>
                </ng-container>
                <hr>
                <ng-container >
                    <h3 style="font-weight: 700;font-size: 16px;">Non-PG6</h3>
                    <ng-container *ngFor="let nonpg of newRadiobtns; let i = index;">       
                        <div class="custom-control custom-radio m-4 col text-nowrap" *ngIf="nonpg.is_common_part_valid">
                            <input type="radio" class="custom-control-input pb-2 me-2" id="rd_{{nonpg.cart_type}}"
                                value="{{nonpg.cart_type}}" 
                                [(ngModel)]="secondSectionRadiobtn"
                                [checked]="secondSectionRadiobtn == nonpg.cart_type" 
                                [disabled]="!section1SelectedTMGO" 
                                (change)="onSection2Change(nonpg)">
                            <label class="custom-control-label pb-2"
                                for="rd_{{nonpg.cart_type}}" style="pointer-events: none;"><h4 class="mb-0 mat-location-type">{{ (nonpg.display_name == 'tmgo Cart') ? 'TMGO Cart' : nonpg.display_name }}</h4></label>
                        </div>
                    </ng-container>
                </ng-container>   
            </div>
    
        <div class="seller dist" *ngIf="baseProductdata">
            <div class="name-of-the-seller" *ngIf="disAddress?.ORG_NAME"
                style="font-size: 16px !important">
                <h4 class="mb-0 mat-location-type">Name of the Seller :</h4> <p class="mat-location-name">{{disAddress?.ORG_NAME}}</p>
            </div>

            <div class="shop-no-29-j-k-cham" *ngIf="disAddress?.DIV_NAME">
                <p class="mat-deliver-address"><i class="fa fa-map-marker" aria-hidden="true"></i>&nbsp;&nbsp;{{disAddress?.DIV_NAME != '' ? (disAddress?.DIV_NAME + ',') : ''}}
                {{disAddress?.PRIMISE_ADDRLINE1_s != '' ? (disAddress?.PRIMISE_ADDRLINE1_s + ',') : ''}}
                {{disAddress?.PRIMISE_ADDRESS2_s != '' ? (disAddress?.PRIMISE_ADDRESS2_s + ',') : ''}}
                </p>
            </div>
        </div>
        <div class="text-end">
            <button type="button" *ngIf="btnshowsubmit" class="btn btn-primary" [disabled]="btndisabled"
            (click)="setAddCart()">&nbsp;Submit</button>
        <button type="button" *ngIf="btnshowConfrimed" class="btn btn-primary" [disabled]="btndisabled"
            (click)="confirmPart()">&nbsp;Confirm</button>
        <button type="button" *ngIf="btnshowConfrimedCartType" class="btn btn-primary" [disabled]="btndisabled"
            (click)="confirmCartType()">&nbsp;Confirm</button>
        </div>
    </div>
</div>