import { NgModule } from '@angular/core';
// modules (angular)
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
// modules
import { SharedModule } from '../../shared/shared.module';
// components
import { HeaderComponent } from './header.component';
import { MenuComponent } from "./components/menu/menu.component";
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from "@angular/material/tooltip";
import { NgxSkeletonSmartModule } from 'ngx-smart-skeleton';
@NgModule({
    declarations: [
        HeaderComponent,MenuComponent
    ],
    imports: [
        CommonModule,
        NgxSkeletonSmartModule,
        RouterModule,
        // modules
        SharedModule,
        ModalModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        MatTooltipModule
    ],
    exports: [MenuComponent,HeaderComponent],
    providers: [BsModalService]
})
export class HeaderModule { }
