import { Component, EventEmitter, Input, OnInit, OnChanges, Output } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'order-pagination',
  templateUrl: './order-pagination.component.html',
  styleUrls: ['./order-pagination.component.scss'],
})
export class OrderPaginationComponent implements OnInit, OnChanges {
  @Input() siblings = 1;
  @Input() current = 1;
  @Input() total = 1;
  @Input() compatibleModelPage;
  @Output()
  pageChange: EventEmitter<number> = new EventEmitter();
  @Output() pageChange1: EventEmitter<number> = new EventEmitter();
  pages: number[] = [];
  isPrev: boolean = false;
  onChange: any = () => {};
  onTouched: any = () => {};

  @Input() totalRecords;
  @Input() recordsPerPage;
  @Input() vehicleCount;
  @Output() onPageChange: EventEmitter<number> = new EventEmitter();
  @Input() hideShowingRecords;
  showrecordCompatibleModel=false;
  @Input()
  activePage!: number;
  pageCount: any;
  pagestart = 1;
  pageend;
  perpagecount = 0;
  currentpages: number[] = [];
  constructor(
    private router: Router,
  ) {
    this.showrecordCompatibleModel =
      this.compatibleModelPage == true ? true : false;
      this.pageend = this.recordsPerPage;
    
  }

  ngOnInit(): void {
    this.paging();
  }

  paging() {
    this.pageCount = this.getPageCount();
    this.pages = this.getArrayOfPage(this.pageCount);
    this.currentpages = [];

    var count = Math.ceil(this.totalRecords / this.recordsPerPage);

    if (count >= 0) {
      count = 5;
    }

    for (
      let index = 1;
      index <= Math.ceil(this.totalRecords / this.recordsPerPage);
      index++
    ) {
      this.currentpages.push(index);
    }
    this.currentpages = this.getPageList(
      this.currentpages.length,
      this.activePage,
      10
    );
  }
  trackByFn(index: number): number {
    return index;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  Noofpagechange() {
    this.onRefresh();
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setPage1(value: number | undefined) {
    this.pageChange1.emit(value);
  }

  onRefresh() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl(currentUrl).then(() => {
      this.router.navigated = false;
      this.router.navigate([this.router.url]);
    });
  }

  private getPageCount(): number {
    let totalPage: number = 0;

    if (this.totalRecords > 0 && this.recordsPerPage > 0) {
      const pageCount = this.totalRecords / this.recordsPerPage;

      const roundedPageCount = Math.floor(pageCount);

      totalPage =
        roundedPageCount < pageCount ? roundedPageCount + 1 : roundedPageCount;
    }

    return totalPage;
  }

  private getArrayOfPage(pageCount: number): number[] {
    let pageArray: number[] = [];

    var temppagecount = 5;

    if (pageCount > 0) {
      for (var i = 1; i <= pageCount; i++) {
        pageArray.push(i);
      }
    }
    return pageArray;
  }
  ngOnChanges() {
    this.paging();
    this.pagestart = (this.activePage - 1) * this.recordsPerPage + 1;
    this.pageend = this.pagestart - 1 + this.vehicleCount;
    
  }

  onClickPage(pageNumber: number) {
    this.pageCount = this.getPageCount();
    this.pages = this.getArrayOfPage(this.pageCount);
    this.currentpages = [];
    for (
      let index = 1;
      index <= Math.ceil(this.totalRecords / this.recordsPerPage);
      index++
    ) {
      this.currentpages.push(index);
    }
    this.currentpages = this.getPageList(
      this.currentpages.length,
      this.activePage,
      10
    );
    if (pageNumber < 1) return;
    if (pageNumber > this.pages.length) return;
    this.activePage = pageNumber;
    this.onPageChange.emit(pageNumber);
  }

  //   totalPages:     total number of pages
  //   page:           current page
  //   maxLength:      maximum size of returned array
  getPageList(totalPages: number, page: number, maxLength: number) {
    if (maxLength < 5) throw 'maxLength must be at least 5';

    function range(start: number, end: number) {
      return Array.from(Array(end - start + 1), (_, i) => i + start);
    }

    var sideWidth = maxLength < 9 ? 1 : 2;
    var leftWidth = (maxLength - sideWidth * 2 - 3) >> 1;
    var rightWidth = (maxLength - sideWidth * 2 - 2) >> 1;
    if (totalPages <= maxLength) {
      // no breaks in list
      return range(1, totalPages);
    }
    if (page <= maxLength - sideWidth - 1 - rightWidth) {
      // no break on left of page
      return range(1, maxLength - sideWidth - 1).concat(
        0,
        range(totalPages - sideWidth + 1, totalPages)
      );
    }
    if (page >= totalPages - sideWidth - 1 - rightWidth) {
      // no break on right of page
      return range(1, sideWidth).concat(
        0,
        range(totalPages - sideWidth - 1 - rightWidth - leftWidth, totalPages)
      );
    }
    // Breaks on both sides
    return range(1, sideWidth).concat(
      0,
      range(page - leftWidth, page + rightWidth),
      0,
      range(totalPages - sideWidth + 1, totalPages)
    );
  }
}
