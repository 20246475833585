import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Data } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  // Documentation on BehaviorSubject http://reactivex.io/rxjs/manual/overview.html#behaviorsubject
  public userSubject: BehaviorSubject<any>;
  public postalCode: BehaviorSubject<any>;
  public downloadExcelType: BehaviorSubject<any>;
  public isLoggedIn: BehaviorSubject<boolean>;
  // public isChangepasswordPermission: BehaviorSubject<boolean>;
  public isorderHistoryProfilePermission: BehaviorSubject<boolean>;
  public isprofilelist: BehaviorSubject<boolean>;
  public afterLoginPublish: BehaviorSubject<boolean>;
  public isVehiclePermission: BehaviorSubject<boolean>;
  public isProfilePermission: BehaviorSubject<boolean>;
  public address: BehaviorSubject<Data> = new BehaviorSubject<Data>(null);
  public showSearch: BehaviorSubject<boolean>;
  public recentItems: BehaviorSubject<boolean>;
  public refreshCartItems: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public refreshOrderSummary: BehaviorSubject<boolean>;
  public clearHeaderTopSearch: BehaviorSubject<boolean>;
  public accountSwitched: BehaviorSubject<boolean>;
  //public refreshCartOnAddressChange: BehaviorSubject<boolean>;
  public refreshCartOnAddressChange: BehaviorSubject<boolean>;
  public refreshCartAddress: BehaviorSubject<boolean>;
  public refreshPaymentAddress: BehaviorSubject<boolean>;
  public redirectToCart: BehaviorSubject<boolean>;
  public refreshDiscounts: BehaviorSubject<boolean>;
  public refreshCheckout: Subject<string> = new Subject<string>();
  public refresPayment: Subject<string> = new Subject<string>();
  public refreshSummary: BehaviorSubject<boolean>;
  public refreshcartConfiguration: BehaviorSubject<any>;
  public updateHeaderCartList: BehaviorSubject<boolean>;
  public reloadcartConfiguration: BehaviorSubject<any>;
  public refreshHeaderCart: BehaviorSubject<boolean>;
  public openNewOrderDetails: BehaviorSubject<string>;
  public openNewAddAddress: BehaviorSubject<string>;
  public openContactPersonList: BehaviorSubject<string>;
  public invoiceDetails: BehaviorSubject<any>;
  public wishlistItemadded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public currentCartValue: number[];
  public refreshTimelineList: BehaviorSubject<Data> = new BehaviorSubject<Data>(null)
  public refreshTimelineData: BehaviorSubject<string> = new BehaviorSubject<string>(null)
  public refreshOfferBanner: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  public refreshOfferHomePage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  public resendGuestOtp:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public refreshOrderShedulerAddress:BehaviorSubject<Data> = new BehaviorSubject<Data>(null);
  public coachMarksCheckedOrSkip:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public refreshMySchemes:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public refreshHeaderSubject = new Subject<void>();
  public partNumberForDuplicateOrder:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public otcNumberObs:BehaviorSubject<any>;
  // public OtherUserPermissionPublish: BehaviorSubject<boolean>;

  // public exceldownloaded: BehaviorSubject<boolean>;

  // refreshCartItemsObservable = this.refreshCartItems.asObservable();
  // refreshCheckoutObservable = this.refreshCheckout.asObservable();
  // refreshPaymentObservable = this.refresPayment.asObservable();
  constructor() {
    //this.refreshCartOnAddressChange = new BehaviorSubject<boolean>(false);
    this.reloadcartConfiguration = new BehaviorSubject<any>("");
    this.refreshHeaderCart = new BehaviorSubject<boolean>(false);
    this.refreshCartOnAddressChange = new BehaviorSubject<boolean>(false);
    this.userSubject = new BehaviorSubject<any>(null);
    this.isLoggedIn = new BehaviorSubject<boolean>(false);
    this.refreshcartConfiguration = new BehaviorSubject<any>(null);
    this.updateHeaderCartList = new BehaviorSubject<boolean>(false);
    // this.isChangepasswordPermission = new BehaviorSubject<boolean>(false);
    this.showSearch = new BehaviorSubject<boolean>(false);
    this.postalCode = new BehaviorSubject<any>('');
    this.downloadExcelType = new BehaviorSubject<any>('');
    this.recentItems = new BehaviorSubject<boolean>(false);
    this.refreshCartItems = new BehaviorSubject<boolean>(false);
    this.refreshOrderSummary = new BehaviorSubject<boolean>(false);
    this.clearHeaderTopSearch = new BehaviorSubject<boolean>(false);
    this.accountSwitched = new BehaviorSubject<boolean>(false);
    this.isprofilelist = new BehaviorSubject<boolean>(false);
    this.isVehiclePermission = new BehaviorSubject<boolean>(false);
    this.isProfilePermission = new BehaviorSubject<boolean>(false);
    this.isorderHistoryProfilePermission = new BehaviorSubject<boolean>(false);
    this.afterLoginPublish = new BehaviorSubject<boolean>(false);
    this.refreshDiscounts = new BehaviorSubject<boolean>(false);
    this.redirectToCart = new BehaviorSubject<boolean>(false);
    this.refreshCartAddress = new BehaviorSubject<boolean>(false);
    this.refreshPaymentAddress = new BehaviorSubject<boolean>(false);
    // this.exceldownloaded = new BehaviorSubject<boolean>(false);
    this.refreshCheckout = new Subject<string>();
    this.refresPayment = new Subject<string>();
    this.refreshSummary = new BehaviorSubject<boolean>(false);
    this.openNewOrderDetails = new BehaviorSubject<string>("");
    this.openNewAddAddress = new BehaviorSubject<string>("");
    this.invoiceDetails = new BehaviorSubject<any>(null);
    this.wishlistItemadded = new BehaviorSubject<boolean>(false);
    this.refreshTimelineList = new BehaviorSubject<any>(null);
    this.refreshTimelineData = new BehaviorSubject<any>(null);
    this.refreshOfferBanner = new BehaviorSubject<boolean>(false);
    this.refreshOfferHomePage = new BehaviorSubject<boolean>(false);
    this.openContactPersonList = new BehaviorSubject("");
    this.currentCartValue = [0, 0, 0];
    this.resendGuestOtp = new BehaviorSubject(false);
    this.refreshOrderShedulerAddress = new BehaviorSubject(null);
    this.coachMarksCheckedOrSkip = new BehaviorSubject(false);
    this.refreshMySchemes = new BehaviorSubject(false);
    this.partNumberForDuplicateOrder = new BehaviorSubject(null);
    this.otcNumberObs = new BehaviorSubject("");
  }

  private storageSub = new Subject<String>();

  refreshHeader() {
    this.refreshHeaderSubject.next();
  }

  getHeaderRefreshObservable() {
    return this.refreshHeaderSubject.asObservable();
  }

  watchStorage(): Observable<any> {
    return this.storageSub.asObservable();
  }

  setItem(key: string, data: any) {
    localStorage.setItem(key, data);
    this.storageSub.next('changed');
  }

  removeItem(key) {
    localStorage.removeItem(key);
    this.storageSub.next('changed');
  }
}
