import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-full-image-popup',
  templateUrl: './full-image-popup.component.html',
  styleUrls: ['./full-image-popup.component.scss']
})
export class FullImagePopupComponent implements OnInit {

  @Input() modalImage:any;
  @Input() modelClose:any
  
  constructor() { }

  ngOnInit(): void {
    console.log("modal",this.modalImage)
  }

}
