<div class="container-fluid orders">
    <div class="row">
        <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 pb-3 pt-3">
            <!-- <ul class="nav nav-tabs tabs1" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                    <a class="nav-link active" id="profile-tab" data-bs-toggle="tab" href="#profile" role="tab"
                        aria-controls="profile" aria-selected="false">Account List</a>
                </li>
            </ul> -->
            <!-- <hr class="hr-page-divider" /> -->
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                    <ul class="nav nav-tabs tabs1 position-relative" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <h5 class="profile-heading mb-3">User List</h5>
                        </li>
                        <button style="position: absolute; right: 16px; top: -5px;" type="button" class="btn btn-outline-primary" (click)="addUser()"
                            href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#cancelorderModal">Add
                            User</button>
                    </ul>
                    <div class="tab-content mt-3" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="nav-Items" role="tabpanel"
                            aria-labelledby="nav-Items-tab">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                    style="padding-left: 15px; padding-right: 15px;">
                                    <div class="table-responsive table-container">
                                        <table id="user-management"  class="table datatable display"  datatable [dtOptions]="dtOptions"
                                         [dtTrigger]="dtTrigger">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Contact No</th>
                                                    <th>Role</th>
                                                    <th>Status</th>
                                                    <th>Created By</th>
                                                    <th>Created Date</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr style="width: 100%;" *ngFor="let user of userList">
                                                    <td>{{ user.first_name}}  {{ user.last_name }}</td>
                                                    <td>{{ user.contact_no }}</td>
                                                    <td>{{ user.role_name}}</td>
                                                    <td>
                                                        <span [ngClass]=" user.is_active === true ? 'spanActive' : 'spanInactive'">
                                                            {{user.is_active ? 'Active' : 'Inactive'}}
                                                        </span>
                                                    
                                                    </td>
                                                    <td>{{ user.master_contact_no}}</td>
                                                    <td>{{ user.created_at | date: 'dd-MM-yyyy' }}</td>
                                                    <td>
                                                        <a class="link" title="Edit" (click)="addUser(user)"><i class="fa fa-edit"></i></a>
                                                        &nbsp;<a class="link" title="Delete User" (click)="deleteRequest(user)"><i class="fas fa-trash"></i></a>
                                                    </td>
                                                </tr>
                                                <!-- <tr>
                                                    <td>41d5-4f6-c04f9355f004</td>
                                                    <td>
                                                        21 Aug, 4 PM
                                                    </td>
                                                    <td class="part-qty">20 <span>Parts</span> 100 <span>Qty</span></td>
                                                    <td class="text-end">45,450.00</td>
                                                    <td>Credit</td>
                                                    <td>
                                                        Today, 10 PM
                                                    </td>
                                                    <td class="feedback text-nowrap">
                                                        <i class="fas fa-star"></i><i class="fas fa-star"></i><i
                                                            class="fas fa-star"></i><i class="fas fa-star"></i><i
                                                            class="far fa-star"></i>
                                                        <a href="javascript:void(0);" data-bs-toggle="modal"
                                                            data-bs-target="#feedbackModal">Edit</a>
                                                        <div class="feedback-desc">Good service. Delivery boy was 1 hour
                                                            late, lost Good service. Delivery boy was 1 hour late, lost
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <a class="me-2" href="javascript:void(0);"
                                                            data-bs-toggle="modal">Re-order</a>
                                                        <a class="me-2" href="javascript:void(0);"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#returnModal">Return</a>
                                                        <a class="me-2" href="javascript:void(0);"
                                                            data-bs-toggle="modal">Pay</a>
                                                        <a class="me-2 paid" href="javascript:void(0);"
                                                            data-bs-toggle="modal">Paid</a>
                                                    </td>
                                                </tr> -->

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="modal fade" id="cancelorderModal" tabindex="-1" aria-labelledby="cancelorderModalLabel" aria-hidden="true"
    data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog model-sm modal-dialog-centered">
    </div>
</div> -->
<ng-template #addUserPopUp let-d="dismiss">
    <add-user (modalClose)="closeModal($event)" [pkuserid]="pkuserid"></add-user>
</ng-template>

<ng-template #deleteModal let-d="dismiss">
    <app-logout [isUserDelete]="isUserDelete" (userLogout)="Delete();" (modalClose)="modalRef.hide()"></app-logout>
</ng-template>

<ng-container *ngIf="showLoader">
    <div class="loader">
        <div class="ng-spinner ng-spinner-icon"></div>
    </div>
</ng-container>