<app-banner></app-banner>
<div class="container-fluid">
    <!--Our Moto-->
    <div class="row mt-3">
        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3  mb-3">
            <div class="card our-moto">
                <div class="card-body">
                    <ul>
                        <li><img alt="" src="./assets/images/thumbUp.webp" /></li>
                        <li>
                            <h3 class="title">Recommendations for fleet</h3>
                            <div class="description">
                                Part Recommendation to match your requirements
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3">
            <div class="card our-moto">
                <div class="card-body">
                    <ul>
                        <li><img alt="" src="./assets/images/warranty.webp" /></li>
                        <li>
                            <h3 class="title">Parts With Warranty</h3>
                            <div class="description">
                                Get your parts from genuine TATA distributors with Warranty
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3">
            <div class="card our-moto">
                <div class="card-body">
                    <ul>
                        <li><img alt="" src="./assets/images/discount.webp" /></li>
                        <li>
                            <h3 class="title">Discount</h3>
                            <div class="description">
                                Purchase your Parts at Discounted Rates
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3">
            <div class="card our-moto">
                <div class="card-body">
                    <ul>
                        <li><img alt="" src="./assets/images/delivery.webp" /></li>
                        <li>
                            <h3 class="title">On Time Delivery</h3>
                            <div class="description">
                                Get timely delivery of your order all across India
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <!--deals of day-->
    <div class="row deals-day mb-4 justify-content-md-end align-items-center" id="deals-day" *ngIf="showDealDay" >
        <div class="col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 text-left" (click)="onBannerRedirect(dealOfTheDayBanner?.banner_hyper_link,'DealOfTheDayBanner')" [ngStyle]="{'cursor':dealOfTheDayBanner?.banner_hyper_link ? 'pointer' : ''}">
            <span class="deals-text">
                Deal of the day <img alt="" src="./assets/images/price-tag.png" />
            </span>
        </div>
        <div class="col-12 col-sm-12 col-md-9 col-lg-10 col-xl-10 pb-4 ps-5 pe-0">
            <!-- <div class="dealsofday slider"> -->
            <ng-container *ngIf="!dealProductList?.length;else dealList">
                <!-- <ng-container *ngIf="takeFalse"> -->
                <owl-carousel-o [options]="customOptions">
                    <ng-container *ngFor="let item of userService.generateFake(4);let i = index;">
                        <ng-template carouselSlide [id]="i">
                            <div class="me-2">
                                <div class="card">
                                    <div class="card-body cursor" style="height: 228px;">
                                        <div class="prod-image">
                                            <skc-rectangle [loading]="loading" [skc-height]="'80px'"
                                                [skc-width]="'190px'"> </skc-rectangle>
                                        </div>
                                        <div class="prod-desc">
                                            <div class="row">
                                                <div class="col-11">
                                                    <skc-skeleton [skc-loading]="loading" [skc-type]="'line'">
                                                    </skc-skeleton>
                                                    <skc-skeleton [skc-loading]="loading" [skc-type]="'line'">
                                                    </skc-skeleton>
                                                    <skc-skeleton [skc-loading]="loading" [skc-type]="'line'">
                                                    </skc-skeleton>
                                                    <skc-skeleton [skc-loading]="loading" [skc-type]="'line'">
                                                    </skc-skeleton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>

            </ng-container>
            <ng-template #dealList>
                <owl-carousel-o [options]="customOptions">
                    <ng-container *ngFor="let product of dealProductList;let i = index;">
                        <ng-template carouselSlide [id]="product.part_number">
                            <div class="me-2">
                                <div class="card">
                                    <div class="card-body cursor" style="height: 263px;">
                                        <div class="prod-image" [routerLink]="['/shop/product-details']"
                                            [queryParams]="{ part_Number : product.part_number }">
                                            <img class="product image cursor" [src]="product?.part_image_path + '/resize/120x75'" />
                                        </div>
                                        <div class="prod-desc">
                                            <div class="row">
                                                <div class="col-11">

                                                    <app-wishlist-icon [product]="product"
                                                        [layout]="'Nolayout'"></app-wishlist-icon>
                                                    <div [routerLink]="['/shop/product-details']"
                                                        [queryParams]="{ part_Number : product.part_number }">

                                                        <div class="modal-name"
                                                            title="{{ (product.short_description == '' || product.short_description == null) ? '-' : product.short_description}}">
                                                            {{ (product.short_description == " " || product.short_description == null) ?
                                                            "-" :
                                                            (product.short_description.length > 20 ?
                                                            (product.short_description.substr(0, 20) + '...') :
                                                            product.short_description)}}
                                                        </div>
                                                        <div class="modal-number cursor">
                                                            {{ product.part_number }}
                                                        </div>
                                                        <div class="delivery-time w-50 d-inline-block text-nowrap"
                                                            style="text-overflow: ellipsis; overflow:hidden;"
                                                            *ngIf="product?.hsn_code && product?.hsn_code != ''">
                                                            HSN: <span title="{{ product?.hsn_code }}">{{
                                                                product?.hsn_code }}</span>
                                                        </div>
                                                        <div class="delivery-time w-50 d-inline-block text-end text-nowrap"
                                                            style="text-overflow: ellipsis; overflow:hidden;"
                                                            *ngIf="product?.tax_slab && product?.tax_slab!=''">
                                                            GST: <span title="{{ product?.tax_slab }}">{{
                                                                product?.tax_slab }}%</span>
                                                        </div>
                                                        <!-- <div class="delivery-time">
                                                                Estd. Delivery: <span>Today</span>
                                                            </div> -->
                                                            <ng-container *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true); else elseTemplate">
                                                            <ng-container *ngIf="!product.total_amount">
                                                                <div class="price fw-bold">
                                                                    {{ (  ( product.resource_type == 'Pack' )?product.crm_mrp*product.moq:product.crm_mrp ) |currencyFormat:'INR' }}
                                                                </div>
                                                            </ng-container>
                                                            <ng-container *ngIf="product.total_amount">
                                                                <div
                                                                *ngIf="(product.base_discount_per +  product.scheme_discount_per + product.rule_discount_per + product.distributor2_discount_amount ) == 0.0">
                                                                    <div class="price fw-bold">
                                                                        {{ (  ( product.resource_type == 'Pack' )?product.crm_mrp*product.moq:product.crm_mrp ) |currencyFormat:'INR' }}
                                                                    </div>
                                                                </div>
                                                                <div
                                                                 *ngIf="(product.base_discount_per +  product.scheme_discount_per + product.rule_discount_per + product.distributor2_discount_amount ) > 0.0">
                                                                    <div class="price fw-bold" >
                                                                        {{  (( product.resource_type == 'Pack' ) ? product.total_amount*product.moq
                                                                            : product.total_amount) |currencyFormat:'INR'
                                                                        }}
                                                                        <div class="card-actual-mrp">
                                                                            <span>
                                                                                MRP
                                                                                {{ (( product.resource_type == 'Pack' ) ? product.crm_mrp*product.moq
                                                                                    : product.crm_mrp) |currencyFormat:'INR'
                                                                                }}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>
                                                        <ng-template #elseTemplate>
                                                            <div class="price fw-bold">
                                                            {{ (( product.resource_type == 'Pack' )?product.crm_mrp*product.moq:product.crm_mrp ) |currencyFormat:'INR' }}
                                                            </div>
                                                        </ng-template>
                                                            <div class="tmgproduct moqProductInfo" *ngIf="( product.resource_type == 'Pack' && product.moq > 1)">
                                                                <p class="save-mode">1 Unit contains {{product.moq | number: '1.0-0'}} <span *ngIf="product.moq > 1">Quantities</span><span *ngIf="product.moq <= 1">Quantity</span></p>
                                                                <!-- <br>(MRP is applicable for 1 Unit =
                                                                {{ product.column_4 ? product?.column_4 / 1000 + ' litres' : product.moq/1 + ' Nos.' }}
                                                                ) -->
                                                            </div>
                                                        <span class="error-message-service text-nowrap"
                                                            *ngIf="product.is_nls == 'in_active'">No Longer
                                                            Serviced</span>
                                                        <span class="error-message-service text-nowrap"
                                                            *ngIf="product?.is_nls!= 'in_active' && product?.alternate_part?true:false">No
                                                            Longer available</span>
                                                        <div class="col-12 view-alternate text-nowrap"
                                                            *ngIf="product.is_nls == 'in_active' || (product?.alternate_part?true:false)"
                                                            style="margin-top:3px;">
                                                            <span [routerLink]="['/shop/product-details']"
                                                                [queryParams]="{ part_Number : product.alternate_part?product.alternate_part:product.part_number }"
                                                                style="cursor: pointer;">View Alternate Part</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="col-1 text-end p-0">
                                                            <a   class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                                        </div> -->
                                            </div>
                                            <ng-container *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                                                <div class="discount"
                                                *ngIf="((convertToFloat(product?.base_discount_per) + convertToFloat(product?.scheme_discount_per) +
                                                    convertToFloat(product?.tml_discount_per) + convertToFloat(product?.distributor_discount_per) +
                                                    convertToFloat(product?.rule_discount_per) + convertToFloat(product.distributor2_discount_amount)) > 0)"
                                                [routerLink]="['/shop/product-details']"
                                                [queryParams]="{ part_Number : product.part_number }">
                                                <!-- {{(product?.base_discount + product?.scheme_discount +
                                                        product?.tml_rule_discount + product?.dealer_rule_discount +
                                                        product?.rule_discount) + '%'}} -->
                                                        {{'SAVE ' + (((product.crm_mrp-product.total_amount)/product.crm_mrp)*100)?.toFixed(1) + '%'}}
                                            </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <hr *ngIf="product.is_nls!= 'in_active'"  style="margin: 0px;"/>
                                    <div class="card-footer" style="border-top: none;" [ngStyle]="product.inventory_quantity <= 0 ? {'display':'flex'}:{}">
                                        <div class="row" style="width: 100%;">
                                        <div class="col-5 "
                                        *ngIf="(isUserType?.user_type == 'MECH' && (product.inventory_quantity != 0 && product.inventory_quantity != null && product.moq <= product.inventory_quantity) && product.is_nls != 'in_active' && (product?.alternate_part?false:true)) || (isUserType?.user_type != 'MECH' && product.is_nls != 'in_active' && (product?.alternate_part?false:true))">
                                        <app-input-number aria-label="Quantity" size="lg" [min]="1"
                                            (currentQuantity)="readQuantity($event,product);"
                                            [defaultValue]="product.min_quantity" [isTmgoValue]="( product.resource_type == 'Pack' )?product.moq:'1'" [userType]="isUserType" [inventoryQty]="product?.inventory_quantity">
                                        </app-input-number>
                                    </div>
                                    <div style="display: contents;" *ngIf="isUserType?.user_type == 'MECH'">
                                        <ng-container  *ngIf="product.inventory_quantity > 0 && product.moq <= product.inventory_quantity">
                                            <div class="col-7 text-end d-flex align-items-center"
                                            *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                                            <button class="add-to-card" (click)="addToCart(product)"><img alt=""
                                                    src="./assets/images/cart.svg" /> Add to
                                                Cart</button>
                                        </div>
                                        </ng-container>
                                        <ng-container  *ngIf="(product.inventory_quantity <= 0 || product.inventory_quantity == null || product.moq > product.inventory_quantity) && product.is_nls != 'in_active' && (product?.alternate_part?false:true)" style="margin-left: auto;">
                                            <h6 style="font-size: 13px;
                                            position: relative;
                                            font-weight: 700;
                                            color: red;
                                            top: 5px;">Out Of Stock</h6>
                                        </ng-container>
                                    </div>
                                    <div class="col-7 text-end d-flex align-items-center"
                                    *ngIf="isUserType?.user_type != 'MECH' && product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                                    <button class="add-to-card" (click)="addToCart(product)"><img alt=""
                                            src="./assets/images/cart.svg" /> Add to
                                        Cart</button>
                                </div>
                                    </div>
                                    </div>
                                    <div class="card-footer" style="border-top: none; height: 25px;" *ngIf="(product.is_nls == 'in_active') || (product.is_nls != 'in_active' && (product?.alternate_part?true:false))">
                                        <div class="row">
                                           <div class="col-md-12">
                                           </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </ng-template>

            <!-- </div> -->
        </div>
    </div>
    <ng-container *ngIf="loadingData">
        <!--Mansoon offer-->
        <!-- <div class="row mansoon-offer mb-4 justify-content-md-end align-items-center">
            <div class="col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 text-left">
                <span class="mansoon-text">
                    MONSOON
                </span>
                <span class="mansoon-text text-white">OFFER <img alt="" src="./assets/images/umbrella.png" /></span>
            </div>
            <div class="col-12 col-sm-12 col-md-9 col-lg-10 col-xl-10 pt-4 pb-4 ps-5 pe-0">
                <div class="mansoon slider">
                    <div class="me-2">
                        <div class="card">
                            <div class="card-body">
                                <div class="prod-image">
                                    <img class="product image" src="./assets/images/menuIcon.PNG" />
                                </div>
                                <div class="prod-desc">
                                    <div class="row">
                                        <div class="col-11">
                                            <div class="modal-number">
                                                266835300130
                                            </div>
                                            <div class="modal-name">
                                                Crown W. & Pinion (37/7),OER - Spirac BS4
                                            </div>
                                            <div class="delivery-time">
                                                Estd. Delivery: <span>Today</span>
                                            </div>
                                            <div class="price">
                                                <img alt="indian rupee sign" src="./assets/images/rupee-indian.svg" />
                                                993.00 <span>MRP 11,0.00</span>
                                            </div>

                                        </div>
                                        <div class="col-1 text-end p-0">
                                            <a   class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                        </div>
                                        <hr />
                                        <div class="col-5 ">
                                            <app-input-number aria-label="Quantity" size="lg" [min]="1" [defaultValue]="1"> </app-input-number>
                                        </div>
                                        <div class="col-7 text-end d-flex align-items-center">
                                            <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/> Add to Cart</button>
                                        </div>
                                    </div>
                                    <div class="discount" *ngIf="isLoggedIn">
                                        20%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="me-2">
                        <div class="card">
                            <div class="card-body">
                                <div class="prod-image">
                                    <img class="product image" src="./assets/images/menuIcon.PNG" />
                                </div>
                                <div class="prod-desc">
                                    <div class="row">
                                        <div class="col-11">
                                            <div class="modal-number">
                                                266835300130
                                            </div>
                                            <div class="modal-name">
                                                Crown W. & Pinion (37/7),OER - Spirac BS4
                                            </div>
                                            <div class="delivery-time">
                                                Estd. Delivery: <span>Today</span>
                                            </div>
                                            <div class="price">
                                                <img alt="indian rupee sign" src="./assets/images/rupee-indian.svg" />
                                                993.00 <span>MRP 110.00</span>
                                            </div>

                                        </div>
                                        <div class="col-1 text-end p-0">
                                            <a   class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                        </div>
                                        <hr />
                                        <div class="col-5 ">
                                            <app-input-number aria-label="Quantity" size="lg" [min]="1" [defaultValue]="1"> </app-input-number>
                                        </div>
                                        <div class="col-7 text-end d-flex align-items-center">
                                            <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/> Add to Cart</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="me-2">
                        <div class="card">
                            <div class="card-body">
                                <div class="prod-image">
                                    <img class="product image" src="./assets/images/menuIcon.PNG" />
                                </div>
                                <div class="prod-desc">
                                    <div class="row">
                                        <div class="col-11">
                                            <div class="modal-number">
                                                266835300130
                                            </div>
                                            <div class="modal-name">
                                                Crown W. & Pinion (37/7),OER - Spirac BS4
                                            </div>
                                            <div class="delivery-time">
                                                Estd. Delivery: <span>Today</span>
                                            </div>
                                            <div class="price">
                                                <img alt="indian rupee sign" src="./assets/images/rupee-indian.svg" />
                                                993.00 <span>MRP 110.00</span>
                                            </div>

                                        </div>
                                        <div class="col-1 text-end p-0">
                                            <a   class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                        </div>
                                        <hr />
                                        <div class="col-5 ">
                                            <app-input-number aria-label="Quantity" size="lg" [min]="1" [defaultValue]="1"> </app-input-number>
                                        </div>
                                        <div class="col-7 text-end d-flex align-items-center">
                                            <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/> Add to Cart</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="me-2">
                        <div class="card">
                            <div class="card-body">
                                <div class="prod-image">
                                    <img class="product image" src="./assets/images/menuIcon.PNG" />
                                </div>
                                <div class="prod-desc">
                                    <div class="row">
                                        <div class="col-11">
                                            <div class="modal-number">
                                                266835300130
                                            </div>
                                            <div class="modal-name">
                                                Crown W. & Pinion (37/7),OER - Spirac BS4
                                            </div>
                                            <div class="delivery-time">
                                                Estd. Delivery: <span>Today</span>
                                            </div>
                                            <div class="price">
                                                <img alt="indian rupee sign" src="./assets/images/rupee-indian.svg" />
                                                993.00 <span>MRP 110.00</span>
                                            </div>

                                        </div>
                                        <div class="col-1 text-end p-0">
                                            <a   class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                        </div>
                                        <hr />
                                        <div class="col-5 ">
                                            <app-input-number aria-label="Quantity" size="lg" [min]="1" [defaultValue]="1"> </app-input-number>
                                        </div>
                                        <div class="col-7 text-end d-flex align-items-center">
                                            <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/> Add to Cart</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="me-2">
                        <div class="card">
                            <div class="card-body">
                                <div class="prod-image">
                                    <img class="product image" src="./assets/images/menuIcon.PNG" />
                                </div>
                                <div class="prod-desc">
                                    <div class="row">
                                        <div class="col-11">
                                            <div class="modal-number">
                                                266835300130
                                            </div>
                                            <div class="modal-name">
                                                Crown W. & Pinion (37/7),OER - Spirac BS4
                                            </div>
                                            <div class="delivery-time">
                                                Estd. Delivery: <span>Today</span>
                                            </div>
                                            <div class="price">
                                                <img alt="indian rupee sign" src="./assets/images/rupee-indian.svg" />
                                                993.00 <span>MRP 110.00</span>
                                            </div>

                                        </div>
                                        <div class="col-1 text-end p-0">
                                            <a   class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                        </div>
                                        <hr />
                                        <div class="col-5 ">
                                            <app-input-number aria-label="Quantity" size="lg" [min]="1" [defaultValue]="1"> </app-input-number>
                                        </div>
                                        <div class="col-7 text-end d-flex align-items-center">
                                            <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/> Add to Cart</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="me-2">
                        <div class="card">
                            <div class="card-body">
                                <div class="prod-image">
                                    <img class="product image" src="./assets/images/menuIcon.PNG" />
                                </div>
                                <div class="prod-desc">
                                    <div class="row">
                                        <div class="col-11">
                                            <div class="modal-number">
                                                266835300130
                                            </div>
                                            <div class="modal-name">
                                                Crown W. & Pinion (37/7),OER - Spirac BS4
                                            </div>
                                            <div class="delivery-time">
                                                Estd. Delivery: <span>Today</span>
                                            </div>
                                            <div class="price">
                                                <img alt="indian rupee sign" src="./assets/images/rupee-indian.svg" />
                                                993.00 <span>MRP 110.00</span>
                                            </div>

                                        </div>
                                        <div class="col-1 text-end p-0">
                                            <a   class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                        </div>
                                        <hr />
                                        <div class="col-5 ">
                                            <app-input-number aria-label="Quantity" size="lg" [min]="1" [defaultValue]="1"> </app-input-number>
                                        </div>
                                        <div class="col-7 text-end d-flex align-items-center">
                                            <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/> Add to Cart</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- Seasonal Parts  -->
        <div class="row new-product mb-4" id="seasonal-part-rec-list" *ngIf="showSesonalParts">
            <div class="col-12 col-sm-12 col-lg-12 col-md-12 col-xl-12">
                <h2>Seasonal Products</h2>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                [ngClass]="seasonalPartList?.length < 5 ? 'carousel-single-item':''">
                <ng-container *ngIf="!seasonalPartList.length;else seasonalProductsList;">
                    <!-- <ng-container *ngIf="takeFalse"> -->
                    <owl-carousel-o [options]="customOptionsForSeasonalProducts">
                        <ng-container *ngFor="let item of userService.generateFake(7);let i = index;">
                            <ng-template carouselSlide [id]="i">
                                <div class="me-2 ms-2">
                                    <div class="card">
                                        <div class="card-body cursor" style="height: 263px;">
                                            <div class="row align-items-center">
                                                <div class="col-4 p-0 cursor">
                                                    <div class="prod-image text-center">
                                                        <skc-rectangle [loading]="loading" [skc-height]="'80px'"
                                                            [skc-width]="'100%'"> </skc-rectangle>
                                                    </div>
                                                </div>
                                                <div class="col-8 cursor">
                                                    <div class="row">
                                                        <div class="col-12  prod-desc">
                                                            <div class="modal-number">
                                                                <skc-rectangle [loading]="loading" [skc-height]="'19px'"
                                                                    [skc-width]="'100%'"> </skc-rectangle>
                                                            </div>
                                                            <div class="modal-name">
                                                                <skc-rectangle [loading]="loading" [skc-height]="'16px'"
                                                                    [skc-width]="'75%'"> </skc-rectangle>
                                                            </div>
                                                            <div class="delivery-time w-50 d-inline-block text-nowrap">
                                                                <skc-rectangle [loading]="loading" [skc-height]="'13px'"
                                                                    [skc-width]="'50%'"> </skc-rectangle>
                                                            </div>
                                                            <div
                                                                class="delivery-time w-50 d-inline-block text-end text-nowrap">
                                                                <skc-rectangle [loading]="loading" [skc-height]="'13px'"
                                                                    [skc-width]="'50%'"> </skc-rectangle>
                                                            </div>
                                                            <div class="price fw-bold">
                                                                <skc-rectangle [loading]="loading" [skc-height]="'19px'"
                                                                    [skc-width]="'50%'"> </skc-rectangle>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </ng-container>
                <ng-template #seasonalProductsList>
                    <owl-carousel-o [options]="customOptionsForSeasonalProducts">
                        <ng-container *ngFor="let product of seasonalPartList">
                            <ng-template carouselSlide [id]="product.part_number">
                                <div class="me-2 ms-2">
                                    <div class="card">
                                        <div class="card-body cursor" style="height: 263px;">
                                            <div class="prod-image" [routerLink]="['/shop/product-details']"
                                                [queryParams]="{ part_Number : product.part_number }">
                                                <img class="product image" [src]="product?.part_image_path + '/resize/120x75'" />
                                            </div>
                                            <div class="prod-desc">
                                                <div class="row">
                                                    <div class="col-12">

                                                        <app-wishlist-icon [product]="product"
                                                            [layout]="'Nolayout'"></app-wishlist-icon>
                                                        <div [routerLink]="['/shop/product-details']"
                                                            [queryParams]="{ part_Number : product.part_number }">
                                                            <div class="modal-name"
                                                                title="{{ (product.short_description == '' || product.short_description == null) ? '-' : product.short_description}}">
                                                                {{ (product.short_description == " " || product.short_description == null) ?
                                                                "-" :
                                                                (product.short_description.length > 20 ?
                                                                (product.short_description.substr(0, 20) + '...') :
                                                                product.short_description)}}
                                                            </div>
                                                            <div class="modal-number">
                                                                {{product?.part_number }}
                                                            </div>
                                                            <div class="delivery-time w-50 d-inline-block text-nowrap"
                                                                style="text-overflow: ellipsis; overflow:hidden;"
                                                                *ngIf="product?.hsn_code && product?.hsn_code != ''">
                                                                HSN: <span title="{{ product?.hsn_code }}">{{
                                                                    product?.hsn_code }}</span>
                                                            </div>
                                                            <div class="delivery-time w-50 d-inline-block text-end text-nowrap"
                                                                style="text-overflow: ellipsis; overflow:hidden;"
                                                                *ngIf="product?.tax_slab && product?.tax_slab!=''">
                                                                GST: <span title="{{ product?.tax_slab }}">{{
                                                                    product?.tax_slab }}%</span>
                                                            </div>
                                                            <!-- <div class="delivery-time">
                                                            Estd. Delivery: <span>Today</span>
                                                        </div> -->
                                                        <ng-container *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true); else elseTemplate">
                                                            <ng-container *ngIf="!product.total_amount">
                                                                <div class="price fw-bold">
                                                                    {{ ( ( product.resource_type == 'Pack' )?product.crm_mrp*product.moq:product.crm_mrp ) |currencyFormat:'INR' }}
                                                                </div>
                                                            </ng-container>
                                                            <ng-container *ngIf="product.total_amount">
                                                                <div
                                                                    *ngIf="(product.base_discount_per +  product.scheme_discount_per + product.rule_discount_per + product.distributor2_discount_amount ) == 0.0">
                                                                    <div class="price fw-bold">
                                                                        {{ ( ( product.resource_type == 'Pack' )?product.crm_mrp*product.moq:product.crm_mrp ) |currencyFormat:'INR' }}
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    *ngIf="(product.base_discount_per +  product.scheme_discount_per + product.rule_discount_per + product.distributor2_discount_amount ) > 0.0">
                                                                    <div class="price fw-bold ">
                                                                        {{  (( product.resource_type == 'Pack' ) ? product.total_amount*product.moq
                                                                            : product.total_amount) |currencyFormat:'INR'
                                                                        }}
                                                                        <div class="card-actual-mrp">
                                                                            <span>
                                                                                MRP
                                                                                {{ ((product.resource_type == 'Pack' ) ? product.crm_mrp*product.moq
                                                                                    : product.crm_mrp) |currencyFormat:'INR'
                                                                                }}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>
                                                        <ng-template #elseTemplate>
                                                            <div class="price fw-bold">
                                                            {{ (( product.resource_type == 'Pack' )?product.crm_mrp*product.moq:product.crm_mrp ) |currencyFormat:'INR' }}
                                                            </div>
                                                        </ng-template>
                                                            <div class="tmgproduct moqProductInfo" *ngIf="( product.resource_type == 'Pack' && product.moq > 1 )">
                                                                <p class="save-mode">1 Unit contains {{product.moq | number: '1.0-0'}} <span *ngIf="product.moq > 1">Quantities</span><span *ngIf="product.moq <= 1">Quantity</span></p>
                                                            </div>
                                                            <span class="error-message-service text-nowrap"
                                                                *ngIf="product.is_nls == 'in_active'">No Longer
                                                                Serviced</span>
                                                            <span class="error-message-service text-nowrap"
                                                                *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?true:false)">No
                                                                Longer Available</span>
                                                            <div class="col-12 text-nowrap view-alternate"
                                                                *ngIf="product.is_nls == 'in_active' || (product?.alternate_part?true:false)">
                                                                <span [routerLink]="['/shop/product-details']"
                                                                    [queryParams]="{ part_Number : product.alternate_part?product.alternate_part:product.part_number }"
                                                                    style="cursor: pointer;">View Alternate Part</span>
                                                            </div>
                                                        </div>
                                                        <!-- <div class="col-1 text-end p-0">
                                                        <a   class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                                    </div> -->
                                                    </div>
                                                    <!-- <hr /> -->
                                                </div>
                                                <ng-container *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                                                <div class="discount"
                                                    *ngIf="(convertToFloat(product?.base_discount_per) + convertToFloat(product?.scheme_discount_per) +
                                                    convertToFloat(product?.tml_discount_per) + convertToFloat(product?.distributor_discount_per) +
                                                    convertToFloat(product?.rule_discount_per) + convertToFloat(product.distributor2_discount_amount)) > 0"
                                                    [routerLink]="['/shop/product-details']"
                                                    [queryParams]="{ part_Number : product.part_number }">
                                                    <!-- {{(product?.base_discount + product?.scheme_discount +
                                                                product?.tml_rule_discount + product?.dealer_rule_discount +
                                                                product?.rule_discount)}} % -->
                                                                {{'SAVE ' + (((product.crm_mrp-product.total_amount)/product.crm_mrp)*100)?.toFixed(1) + '%'}}
                                                </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <hr *ngIf="product.is_nls != 'in_active'  &&  ( product.is_nls == 'active' && !product?.alternate_part )"  style="margin: 0px;"/>
                                        <div class="card-footer" style="border-top: none;" [ngStyle]="product.inventory_quantity <= 0 ? {'display':'flex'}:{}">
                                            <div class="row" style="width: 100%;">
                                            <div class="col-5 "
                                            *ngIf="(isUserType?.user_type == 'MECH' && (product.inventory_quantity != 0 && product.inventory_quantity != null && product.moq <= product.inventory_quantity) && product.is_nls != 'in_active' && (product?.alternate_part?false:true)) || (isUserType?.user_type != 'MECH' && product.is_nls != 'in_active' && (product?.alternate_part?false:true))">
                                            <app-input-number aria-label="Quantity" size="lg" [min]="1"
                                                (currentQuantity)="readQuantity($event,product);"
                                                [defaultValue]="product.min_quantity" [isTmgoValue]="( product.resource_type == 'Pack' )?product.moq:'1'" [userType]="isUserType" [inventoryQty]="product?.inventory_quantity">
                                            </app-input-number>
                                        </div>
                                        <div style="display: contents;" *ngIf="isUserType?.user_type == 'MECH'">
                                            <ng-container  *ngIf="product.inventory_quantity > 0 && product.moq <= product.inventory_quantity">
                                                <div class="col-7 text-end d-flex align-items-center"
                                                *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                                                <button class="add-to-card" (click)="addToCart(product)"><img alt=""
                                                        src="./assets/images/cart.svg" /> Add to
                                                    Cart</button>
                                            </div>
                                            </ng-container>
                                            <ng-container  *ngIf="(product.inventory_quantity <= 0 || product.inventory_quantity == null || product.moq > product.inventory_quantity) && product.is_nls != 'in_active' && (product?.alternate_part?false:true)" style="margin-left: auto;">
                                                <h6 style="font-size: 13px;
                                                position: relative;
                                                font-weight: 700;
                                                color: red;
                                                top: 5px;">Out Of Stock</h6>
                                            </ng-container>
                                        </div>
                                        <div class="col-7 text-end d-flex align-items-center"
                                        *ngIf="isUserType?.user_type != 'MECH' && product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                                        <button class="add-to-card" (click)="addToCart(product)"><img alt=""
                                                src="./assets/images/cart.svg" /> Add to
                                            Cart</button>
                                    </div>
                                        </div>
                                        </div>
                                        <div class="card-footer" style="border-top: none; height: 25px;" *ngIf="(product.is_nls == 'in_active') || (product.is_nls != 'in_active' && (product?.alternate_part?true:false))">
                                            <div class="row">
                                               <div class="col-md-12">
                                               </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </ng-template>

            </div>
        </div>
        <!--New Product-->
        <div class="row new-product mb-4" *ngIf="showNewProducts">
            <div class="col-12 col-sm-12 col-lg-12 col-md-12 col-xl-12">
                <h2>New Products</h2>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                [ngClass]="newProductList?.length < 5 ? 'carousel-single-item':''">
                <ng-container *ngIf="!newProductList.length;else newProductsList;">
                    <!-- <ng-container *ngIf="takeFalse"> -->
                    <owl-carousel-o [options]="customOptionsForNewProducts">
                        <ng-container *ngFor="let item of userService.generateFake(7);let i = index;">
                            <ng-template carouselSlide [id]="i">
                                <div class="me-2 ms-2">
                                    <div class="card">
                                        <div class="card-body cursor" style="height: 245px;">
                                            <div class="row align-items-center">
                                                <div class="col-4 p-0 cursor">
                                                    <div class="prod-image text-center">
                                                        <skc-rectangle [loading]="loading" [skc-height]="'80px'"
                                                            [skc-width]="'100%'"> </skc-rectangle>
                                                    </div>
                                                </div>
                                                <div class="col-8 cursor">
                                                    <div class="row">
                                                        <div class="col-12  prod-desc">
                                                            <div class="modal-number">
                                                                <skc-rectangle [loading]="loading" [skc-height]="'19px'"
                                                                    [skc-width]="'100%'"> </skc-rectangle>
                                                            </div>
                                                            <div class="modal-name">
                                                                <skc-rectangle [loading]="loading" [skc-height]="'16px'"
                                                                    [skc-width]="'75%'"> </skc-rectangle>
                                                            </div>
                                                            <div class="delivery-time w-50 d-inline-block text-nowrap">
                                                                <skc-rectangle [loading]="loading" [skc-height]="'13px'"
                                                                    [skc-width]="'50%'"> </skc-rectangle>
                                                            </div>
                                                            <div
                                                                class="delivery-time w-50 d-inline-block text-end text-nowrap">
                                                                <skc-rectangle [loading]="loading" [skc-height]="'13px'"
                                                                    [skc-width]="'50%'"> </skc-rectangle>
                                                            </div>
                                                            <div class="price fw-bold">
                                                                <skc-rectangle [loading]="loading" [skc-height]="'19px'"
                                                                    [skc-width]="'50%'"> </skc-rectangle>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </ng-container>
                <ng-template #newProductsList>
                    <owl-carousel-o [options]="customOptionsForNewProducts">
                        <ng-container *ngFor="let product of newProductList">
                            <ng-template carouselSlide [id]="product.part_number">
                                <div class="me-2 ms-2">
                                    <div class="card">
                                        <div class="card-body cursor" style="height: 245px;">
                                            <div class="prod-image" [routerLink]="['/shop/product-details']"
                                                [queryParams]="{ part_Number : product.part_number }">
                                                <img class="product image" [src]="product?.part_image_path + '/resize/120x75'" />
                                            </div>
                                            <div class="prod-desc">
                                                <div class="row">
                                                    <div class="col-12">

                                                        <app-wishlist-icon [product]="product"
                                                            [layout]="'Nolayout'"></app-wishlist-icon>
                                                        <div [routerLink]="['/shop/product-details']"
                                                            [queryParams]="{ part_Number : product.part_number }">
                                                            <div class="modal-name"
                                                                title="{{ (product.short_description == '' || product.short_description == null) ? '-' : product.short_description}}">
                                                                {{ (product.short_description == " " || product.short_description == null) ?
                                                                "-" :
                                                                product.short_description?.substr(0,20)}}
                                                            </div>
                                                            <div class="modal-number">
                                                                {{product?.part_number }}
                                                            </div>
                                                            <div class="delivery-time w-50 d-inline-block text-nowrap"
                                                                style="text-overflow: ellipsis; overflow:hidden;"
                                                                *ngIf="product?.hsn_code && product?.hsn_code != ''">
                                                                HSN: <span title="{{ product?.hsn_code }}">{{
                                                                    product?.hsn_code }}</span>
                                                            </div>
                                                            <div class="delivery-time w-50 d-inline-block text-end text-nowrap"
                                                                style="text-overflow: ellipsis; overflow:hidden;"
                                                                *ngIf="product?.tax_slab && product?.tax_slab!=''">
                                                                GST: <span title="{{ product?.tax_slab }}">{{
                                                                    product?.tax_slab }}%</span>
                                                            </div>
                                                            <!-- <div class="delivery-time">
                                                            Estd. Delivery: <span>Today</span>
                                                        </div> -->
                                                        <ng-container *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true); else elseTemplate">
                                                            <ng-container *ngIf="!product.total_amount">
                                                                <div class="price fw-bold">
                                                                    {{ (( product.resource_type == 'Pack' )?product.crm_mrp*product.moq:product.crm_mrp ) |currencyFormat:'INR' }}
                                                                </div>
                                                            </ng-container>
                                                            <ng-container *ngIf="product.total_amount">
                                                                <div
                                                                    *ngIf="(product.base_discount_per +  product.scheme_discount_per + product.rule_discount_per + product.distributor2_discount_amount ) == 0.0">
                                                                    <div class="price fw-bold">
                                                                        {{ (( product.resource_type == 'Pack')?product.crm_mrp*product.moq:product.crm_mrp ) |currencyFormat:'INR' }}
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    *ngIf="(product.base_discount_per +  product.scheme_discount_per + product.rule_discount_per + product.distributor2_discount_amount ) > 0.0">
                                                                    <div class="price fw-bold">
                                                                        {{   (( product.resource_type == 'Pack' ) ? product.total_amount*product.moq
                                                                                : product.total_amount) |currencyFormat:'INR'
                                                                        }}
                                                                        <div class="card-actual-mrp">
                                                                            <span>
                                                                                MRP
                                                                                {{
                                                                                    (( product.resource_type == 'Pack' ) ? product.crm_mrp*product.moq
                                                                                    : product.crm_mrp) |currencyFormat:'INR'
                                                                                }}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>
                                                        <ng-template #elseTemplate>
                                                            <div class="price fw-bold">
                                                            {{ (( product.resource_type == 'Pack' )?product.crm_mrp*product.moq:product.crm_mrp ) |currencyFormat:'INR' }}
                                                            </div>
                                                        </ng-template>
                                                            <div class="tmgproduct moqProductInfo" *ngIf="( product.resource_type == 'Pack' && product.moq > 1)">
                                                                <p class="save-mode">1 Unit contains {{product.moq | number: '1.0-0'}} <span *ngIf="product.moq > 1">Quantities</span><span *ngIf="product.moq <= 1">Quantity</span></p>
                                                            </div>
                                                            <span class="error-message-service text-nowrap"
                                                                *ngIf="product.is_nls == 'in_active'">No Longer
                                                                Serviced</span>
                                                            <span class="error-message-service text-nowrap"
                                                                *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?true:false)">No
                                                                Longer Available</span>
                                                            <div class="col-12 text-nowrap view-alternate"
                                                                *ngIf="product.is_nls == 'in_active' || (product?.alternate_part?true:false)">
                                                                <span [routerLink]="['/shop/product-details']"
                                                                    [queryParams]="{ part_Number : product.alternate_part?product.alternate_part:product.part_number }"
                                                                    style="cursor: pointer;">View Alternate Part</span>
                                                            </div>
                                                        </div>
                                                        <!-- <div class="col-1 text-end p-0">
                                                        <a   class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                                    </div> -->
                                                    </div>
                                                    <!-- <hr /> -->
                                                    <!-- <hr
                                                        *ngIf="product.is_nls != 'in_active'  &&  ( product.is_nls == 'active' && !product?.alternate_part )" /> -->
                                                </div>
                                                <ng-container *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                                                <div class="discount"
                                                    *ngIf="((convertToFloat(product?.base_discount_per) + convertToFloat(product?.scheme_discount_per) +
                                                            convertToFloat(product?.tml_discount_per) + convertToFloat(product?.distributor_discount_per) +
                                                            convertToFloat(product?.rule_discount_per) + convertToFloat(product.distributor2_discount_amount)) > 0)"
                                                    [routerLink]="['/shop/product-details']"
                                                    [queryParams]="{ part_Number : product.part_number }">
                                                    <!-- {{(product?.base_discount + product?.scheme_discount +
                                                                product?.tml_discount_per + product?.distributor_discount_per +
                                                                product?.rule_discount)}} % -->
                                                                {{'SAVE ' + (((product.crm_mrp-product.total_amount)/product.crm_mrp)*100)?.toFixed(1) + '%'}}
                                                </div>
                                            </ng-container>
                                            </div>
                                        </div>
                                        <hr
                                        *ngIf="product.is_nls != 'in_active'  &&  ( product.is_nls == 'active' && !product?.alternate_part )" style="margin: 0px;"/>
                                        <div class="card-footer" style="border-top: none;" [ngStyle]="product.inventory_quantity <= 0 ? {'display':'flex'}:{}">
                                            <div class="row" style="width: 100%;">
                                            <div class="col-5 "
                                            *ngIf="(isUserType?.user_type == 'MECH' && (product.inventory_quantity != 0 && product.inventory_quantity != null && product.moq <= product.inventory_quantity) && product.is_nls != 'in_active' && (product?.alternate_part?false:true)) || (isUserType?.user_type != 'MECH' && product.is_nls != 'in_active' && (product?.alternate_part?false:true))">
                                            <app-input-number aria-label="Quantity" size="lg" [min]="1"
                                                (currentQuantity)="readQuantity($event,product);"
                                                [defaultValue]="product.min_quantity" [isTmgoValue]="( product.resource_type == 'Pack' )?product.moq:'1'" [userType]="isUserType" [inventoryQty]="product?.inventory_quantity">
                                            </app-input-number>
                                        </div>
                                        <div style="display: contents;" *ngIf="isUserType?.user_type == 'MECH'">
                                            <ng-container  *ngIf="product.inventory_quantity > 0 && product.moq <= product.inventory_quantity">
                                                <div class="col-7 text-end d-flex align-items-center"
                                                *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                                                <button class="add-to-card" (click)="addToCart(product)"><img alt=""
                                                        src="./assets/images/cart.svg" /> Add to
                                                    Cart</button>
                                            </div>
                                            </ng-container>
                                            <ng-container  *ngIf="(product.inventory_quantity <= 0 || product.inventory_quantity == null || product.moq > product.inventory_quantity) && product.is_nls != 'in_active' && (product?.alternate_part?false:true)" style="margin-left: auto;">
                                                <h6 style="font-size: 13px;
                                                position: relative;
                                                font-weight: 700;
                                                color: red;
                                                top: 5px;">Out Of Stock</h6>
                                            </ng-container>
                                        </div>
                                        <div class="col-7 text-end d-flex align-items-center"
                                        *ngIf="isUserType?.user_type != 'MECH' && product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                                        <button class="add-to-card" (click)="addToCart(product)"><img alt=""
                                                src="./assets/images/cart.svg" /> Add to
                                            Cart</button>
                                    </div>
                                        </div>
                                        </div>
                                        <div class="card-footer" style="border-top: none; height: 25px;" *ngIf="(product.is_nls == 'in_active') || (product.is_nls != 'in_active' && (product?.alternate_part?true:false))">
                                            <div class="row">
                                               <div class="col-md-12">
                                               </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </ng-template>

            </div>
        </div>
        <!--Mega Cost Saving-->
        <div class="row mega-cost-saving mb-4" *ngIf="showeDukaanFeatures">
            <div
                class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 mb-3 d-flex justify-content-between align-items-center">
                <div>
                    <h2>e-Dukaan Features</h2>
                    <div class="sub-title">Get your Tata Parts from nearest distributor in your area at great prices.</div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12"
                [ngClass]="megaCostBannerList?.length < 4 ? 'carousel-single-item':''">
                <ng-container *ngIf="!megaCostBannerList.length;else mcsList;">
                    <owl-carousel-o [options]="customOptionsForMCSBanners">
                        <ng-container *ngFor="let item of userService.generateFake(7);let i = index;">
                            <ng-template carouselSlide [id]="i">
                                <div class="me-2 ms-2">
                                    <div class="card mb-3">
                                        <skc-rectangle [loading]="loading" [skc-height]="'150px'" [skc-width]="'100%'">
                                        </skc-rectangle>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </ng-container>
                <ng-template #mcsList>
                    <owl-carousel-o [options]="customOptionsForMCSBanners">
                        <ng-container *ngFor="let data of megaCostBannerList">
                            <ng-template carouselSlide [id]="data?.description">
                                <div class="me-2 ms-2">
                                    <div class="card mb-3" (click)="onBannerRedirect(data,'MegaCostBanner')" [ngStyle]="{'cursor':data?.banner_hyper_link ? 'pointer' : ''}" >
                                        <img [src]="data?.banner_url+ '/resize/346x200'" class="card-img-top" alt="car" style="height: 200px;">
                                        <div *ngIf="data?.description" class="cost-saving text-center">
                                            {{data?.description | uppercase}}
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </ng-template>

            </div>
        </div>
        <!--Customer Favourites-->
        <div class="row customer-favourite mb-4" *ngIf="showCustomerFavourites">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <h2>Customer Favourites</h2>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                [ngClass]="cfProductList?.length < 5 ? 'carousel-single-item' : ''">
                <!-- <div class="customer-favourite-list slider"> -->
                <ng-container *ngIf="!cfProductList?.length;else cfList;">
                    <owl-carousel-o [options]="customOptionsForCS">
                        <ng-container *ngFor="let item of userService.generateFake(7);let i = index;">
                            <ng-template carouselSlide [id]="i">
                                <div class="me-2 ms-2">
                                    <div class="card">
                                        <div class="card-body cursor" style="height: 263px;">
                                            <div class="prod-image">
                                                <skc-rectangle [loading]="loading" [skc-height]="'80px'"
                                                    [skc-width]="'100%'"> </skc-rectangle>
                                            </div>
                                            <div class="prod-desc">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="modal-number">
                                                            <skc-rectangle [loading]="loading" [skc-height]="'19px'"
                                                                [skc-width]="'100%'"> </skc-rectangle>
                                                        </div>
                                                        <div class="modal-name">
                                                            <skc-rectangle [loading]="loading" [skc-height]="'16px'"
                                                                [skc-width]="'100%'"> </skc-rectangle>
                                                        </div>
                                                        <div class="delivery-time w-50 d-inline-block text-nowrap">
                                                            <skc-rectangle [loading]="loading" [skc-height]="'10px'"
                                                                [skc-width]="'50%'"> </skc-rectangle>
                                                        </div>
                                                        <div class="delivery-time w-50 d-inline-block text-end text-nowrap">
                                                            <skc-rectangle [loading]="loading" [skc-height]="'10px'"
                                                                [skc-width]="'50%'"> </skc-rectangle>
                                                        </div>
                                                        <div class="price fw-bold">
                                                            <skc-rectangle [loading]="loading" [skc-height]="'19px'"
                                                                [skc-width]="'100%'"> </skc-rectangle>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </ng-container>
                <ng-template #cfList>
                    <owl-carousel-o [options]="customOptionsForCS">
                        <ng-container *ngFor="let product of cfProductList">
                            <ng-template carouselSlide [id]="product.part_number">
                                <div class="me-2 ms-2">
                                    <div class="card">
                                        <div class="card-body cursor" style="height: 263px;">
                                            <div class="prod-image" [routerLink]="['/shop/product-details']"
                                                [queryParams]="{ part_Number : product.part_number }">
                                                <img class="product image" [src]="product?.part_image_path + '/resize/120x75'" />
                                            </div>
                                            <div class="prod-desc">
                                                <div class="row">
                                                    <div class="col-12">

                                                        <app-wishlist-icon [product]="product"
                                                            [layout]="'Nolayout'"></app-wishlist-icon>
                                                        <div [routerLink]="['/shop/product-details']"
                                                            [queryParams]="{ part_Number : product.part_number }">
                                                            <div class="modal-name"
                                                                title="{{ (product.short_description == '' || product.short_description == null) ? '-' : product.short_description}}">
                                                                {{ (product.short_description == " " || product.short_description == null) ?
                                                                "-" :
                                                                (product.short_description.length > 20 ?
                                                                (product.short_description.substr(0, 20) + '...') :
                                                                product.short_description)}}
                                                            </div>
                                                            <div class="modal-number">
                                                                {{product?.part_number }}
                                                            </div>
                                                            <div class="delivery-time w-50 d-inline-block text-nowrap"
                                                                style="text-overflow: ellipsis; overflow:hidden;"
                                                                *ngIf="product?.hsn_code && product?.hsn_code != ''">
                                                                HSN: <span title="{{ product?.hsn_code }}">{{
                                                                    product?.hsn_code }}</span>
                                                            </div>
                                                            <div class="delivery-time w-50 d-inline-block text-end text-nowrap"
                                                                style="text-overflow: ellipsis; overflow:hidden;"
                                                                *ngIf="product?.tax_slab && product?.tax_slab!=''">
                                                                GST: <span title="{{ product?.tax_slab }}">{{
                                                                    product?.tax_slab }}%</span>
                                                            </div>
                                                            <!-- <div class="delivery-time">
                                                                Estd. Delivery: <span>Today</span>
                                                            </div> -->
                                                            <ng-container *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true); else elseTemplate">
                                                            <ng-container *ngIf="!product.total_amount">
                                                                <div class="price fw-bold">
                                                                    {{ (( product.resource_type == 'Pack' )?product.crm_mrp*product.moq:product.crm_mrp ) |currencyFormat:'INR' }}
                                                                </div>
                                                            </ng-container>
                                                            <ng-container *ngIf="product.total_amount">
                                                                <div
                                                                    *ngIf="(product.base_discount_per +  product.scheme_discount_per + product.rule_discount_per + product.distributor2_discount_amount ) == 0.0">
                                                                    <div class="price fw-bold">
                                                                        {{ (( product.resource_type == 'Pack' )?product.crm_mrp*product.moq:product.crm_mrp ) |currencyFormat:'INR' }}
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    *ngIf="(product.base_discount_per +  product.scheme_discount_per + product.rule_discount_per + product.distributor2_discount_amount ) > 0.0">
                                                                    <div class="price fw-bold">
                                                                        {{  (( product.resource_type == 'Pack' ) ? product.total_amount*product.moq
                                                                            : product.total_amount) |currencyFormat:'INR'
                                                                        }}
                                                                        <div class="card-actual-mrp">
                                                                            <span>
                                                                                MRP
                                                                                {{
                                                                                    (( product.resource_type == 'Pack' ) ? product.crm_mrp*product.moq
                                                                                    : product.crm_mrp) |currencyFormat:'INR'
                                                                                }}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>
                                                        <ng-template #elseTemplate>
                                                            <div class="price fw-bold">
                                                            {{ (( product.resource_type == 'Pack' )?product.crm_mrp*product.moq:product.crm_mrp ) |currencyFormat:'INR' }}
                                                            </div>
                                                        </ng-template>
                                                            <div class="tmgproduct moqProductInfo" *ngIf="( product.resource_type == 'Pack' && product.moq > 1)">
                                                                <p class="save-mode">1 Unit contains {{product.moq | number: '1.0-0'}} <span *ngIf="product.moq > 1">Quantities</span><span *ngIf="product.moq <= 1">Quantity</span></p>
                                                            </div>
                                                            <span class="error-message-service text-nowrap"
                                                                *ngIf="product.is_nls == 'in_active'">No Longer
                                                                Serviced</span>
                                                            <span class="error-message-service text-nowrap"
                                                                *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?true:false)">No
                                                                Longer Available</span>
                                                            <div class="col-12 text-nowrap view-alternate"
                                                                *ngIf="product.is_nls == 'in_active' || (product?.alternate_part?true:false)">
                                                                <span [routerLink]="['/shop/product-details']"
                                                                    [queryParams]="{ part_Number : product.alternate_part?product.alternate_part:product.part_number }"
                                                                    style="cursor: pointer;">View Alternate Part</span>
                                                            </div>
                                                        </div>
                                                        <!-- <div class="col-1 text-end p-0">
                                                            <a   class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                                        </div> -->
                                                    </div>
                                                    <!-- <hr /> -->
                                                </div>
                                                <ng-container *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                                                <div class="discount"
                                                    *ngIf="((convertToFloat(product?.base_discount_per) + convertToFloat(product?.scheme_discount_per) +
                                                                convertToFloat(product?.tml_discount_per) + convertToFloat(product?.distributor_discount_per )+
                                                                convertToFloat(product?.rule_discount_per) + convertToFloat(product.distributor2_discount_amount)) > 0)"
                                                    [routerLink]="['/shop/product-details']"
                                                    [queryParams]="{ part_Number : product.part_number }">
                                                    <!-- {{(product?.base_discount + product?.scheme_discount +
                                                                    product?.tml_rule_discount + product?.dealer_rule_discount +
                                                                    product?.rule_discount)}} % -->
                                                                    {{'SAVE ' + (((product.crm_mrp-product.total_amount)/product.crm_mrp)*100)?.toFixed(1) + '%'}}
                                                </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <hr
                                        *ngIf="product.is_nls != 'in_active'  &&  ( product.is_nls == 'active' && !product?.alternate_part )" style="margin: 0px;" />
                                        <div class="card-footer" style="border-top: none;" [ngStyle]="product.inventory_quantity <= 0 ? {'display':'flex'}:{}">
                                            <div class="row" style="width: 100%;">
                                            <div class="col-5 "
                                            *ngIf="(isUserType?.user_type == 'MECH' && (product.inventory_quantity != 0 && product.inventory_quantity != null && product.moq <= product.inventory_quantity) && product.is_nls != 'in_active' && (product?.alternate_part?false:true)) || (isUserType?.user_type != 'MECH' && product.is_nls != 'in_active' && (product?.alternate_part?false:true))">
                                            <app-input-number aria-label="Quantity" size="lg" [min]="1"
                                                (currentQuantity)="readQuantity($event,product);"
                                                [defaultValue]="product.min_quantity" [isTmgoValue]="( product.resource_type == 'Pack' )?product.moq:'1'" [userType]="isUserType" [inventoryQty]="product?.inventory_quantity">
                                            </app-input-number>
                                        </div>
                                        <div style="display: contents;" *ngIf="isUserType?.user_type == 'MECH'">
                                            <ng-container  *ngIf="product.inventory_quantity > 0 && product.moq <= product.inventory_quantity">
                                                <div class="col-7 text-end d-flex align-items-center"
                                                *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                                                <button class="add-to-card" (click)="addToCart(product)"><img alt=""
                                                        src="./assets/images/cart.svg" /> Add to
                                                    Cart</button>
                                            </div>
                                            </ng-container>
                                            <ng-container  *ngIf="(product.inventory_quantity <= 0 || product.inventory_quantity == null || product.moq > product.inventory_quantity) && product.is_nls != 'in_active' && (product?.alternate_part?false:true)" style="margin-left: auto;">
                                                <h6 style="font-size: 13px;
                                                position: relative;
                                                font-weight: 700;
                                                color: red;
                                                top: 5px;">Out Of Stock</h6>
                                            </ng-container>
                                        </div>
                                        <div class="col-7 text-end d-flex align-items-center"
                                        *ngIf="isUserType?.user_type != 'MECH' && product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                                        <button class="add-to-card" (click)="addToCart(product)"><img alt=""
                                                src="./assets/images/cart.svg" /> Add to
                                            Cart</button>
                                    </div>
                                        </div>
                                        </div>
                                        <div class="card-footer" style="border-top: none; height: 25px;" *ngIf="(product.is_nls == 'in_active') || (product.is_nls != 'in_active' && (product?.alternate_part?true:false))">
                                            <div class="row">
                                               <div class="col-md-12">
                                               </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </ng-template>


                <!-- <div class="me-2 ms-2">
                        <div class="card">
                            <div class="card-body">
                                <div class="prod-image">
                                    <img class="product image" src="./assets/images/menuIcon.PNG" />
                                </div>
                                <div class="prod-desc">
                                    <div class="row">
                                        <div class="col-11">
                                            <div class="modal-number">
                                                266835300130
                                            </div>
                                            <div class="modal-name">
                                                Crown W. & Pinion (37/7),OER - Spirac BS4
                                            </div>
                                            <div class="delivery-time">
                                                Estd. Delivery: <span>Today</span>
                                            </div>
                                            <div class="price">
                                                <img alt="indian rupee sign" src="./assets/images/rupee-indian.svg" />
                                                9,90.00 <span>MRP 11,0.00</span>
                                            </div>

                                        </div>
                                        <div class="col-1 text-end p-0">
                                            <a   class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                        </div>
                                        <hr />
                                        <div class="col-5 ">
                                            <app-input-number aria-label="Quantity" size="lg" [min]="1" [defaultValue]="1"> </app-input-number>
                                        </div>
                                        <div class="col-7 text-end d-flex align-items-center">
                                            <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/> Add to Cart</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="me-2 ms-2">
                        <div class="card">
                            <div class="card-body">
                                <div class="prod-image">
                                    <img class="product image" src="./assets/images/menuIcon.PNG" />
                                </div>
                                <div class="prod-desc">
                                    <div class="row">
                                        <div class="col-11">
                                            <div class="modal-number">
                                                266835300130
                                            </div>
                                            <div class="modal-name">
                                                Crown W. & Pinion (37/7),OER - Spirac BS4
                                            </div>
                                            <div class="delivery-time">
                                                Estd. Delivery: <span>Today</span>
                                            </div>
                                            <div class="price">
                                                <img alt="indian rupee sign" src="./assets/images/rupee-indian.svg" />
                                                9,90.00 <span>MRP 11,0.00</span>
                                            </div>

                                        </div>
                                        <div class="col-1 text-end p-0">
                                            <a   class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                        </div>
                                        <hr />
                                        <div class="col-5 ">
                                            <app-input-number aria-label="Quantity" size="lg" [min]="1" [defaultValue]="1"> </app-input-number>
                                        </div>
                                        <div class="col-7 text-end d-flex align-items-center">
                                            <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/> Add to Cart</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="me-2 ms-2">
                        <div class="card">
                            <div class="card-body">
                                <div class="prod-image">
                                    <img class="product image" src="./assets/images/menuIcon.PNG" />
                                </div>
                                <div class="prod-desc">
                                    <div class="row">
                                        <div class="col-11">
                                            <div class="modal-number">
                                                266835300130
                                            </div>
                                            <div class="modal-name">
                                                Crown W. & Pinion (37/7),OER - Spirac BS4
                                            </div>
                                            <div class="delivery-time">
                                                Estd. Delivery: <span>Today</span>
                                            </div>
                                            <div class="price">
                                                <img alt="indian rupee sign" src="./assets/images/rupee-indian.svg" />
                                                9,90.00 <span>MRP 11,0.00</span>
                                            </div>

                                        </div>
                                        <div class="col-1 text-end p-0">
                                            <a   class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                        </div>
                                        <hr />
                                        <div class="col-5 ">
                                            <app-input-number aria-label="Quantity" size="lg" [min]="1" [defaultValue]="1"> </app-input-number>
                                        </div>
                                        <div class="col-7 text-end d-flex align-items-center">
                                            <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/> Add to Cart</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="me-2 ms-2">
                        <div class="card">
                            <div class="card-body">
                                <div class="prod-image">
                                    <img class="product image" src="./assets/images/menuIcon.PNG" />
                                </div>
                                <div class="prod-desc">
                                    <div class="row">
                                        <div class="col-11">
                                            <div class="modal-number">
                                                266835300130
                                            </div>
                                            <div class="modal-name">
                                                Crown W. & Pinion (37/7),OER - Spirac BS4
                                            </div>
                                            <div class="delivery-time">
                                                Estd. Delivery: <span>Today</span>
                                            </div>
                                            <div class="price">
                                                <img alt="indian rupee sign" src="./assets/images/rupee-indian.svg" />
                                                9,90.00 <span>MRP 11,0.00</span>
                                            </div>

                                        </div>
                                        <div class="col-1 text-end p-0">
                                            <a   class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                        </div>
                                        <hr />
                                        <div class="col-5 ">
                                            <app-input-number aria-label="Quantity" size="lg" [min]="1" [defaultValue]="1"> </app-input-number>
                                        </div>
                                        <div class="col-7 text-end d-flex align-items-center">
                                            <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/> Add to Cart</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="me-2 ms-2">
                        <div class="card">
                            <div class="card-body">
                                <div class="prod-image">
                                    <img class="product image" src="./assets/images/menuIcon.PNG" />
                                </div>
                                <div class="prod-desc">
                                    <div class="row">
                                        <div class="col-11">
                                            <div class="modal-number">
                                                266835300130
                                            </div>
                                            <div class="modal-name">
                                                Crown W. & Pinion (37/7),OER - Spirac BS4
                                            </div>
                                            <div class="delivery-time">
                                                Estd. Delivery: <span>Today</span>
                                            </div>
                                            <div class="price">
                                                <img alt="indian rupee sign" src="./assets/images/rupee-indian.svg" />
                                                9,90.00 <span>MRP 11,0.00</span>
                                            </div>

                                        </div>
                                        <div class="col-1 text-end p-0">
                                            <a   class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                        </div>
                                        <hr />
                                        <div class="col-5 ">
                                            <app-input-number aria-label="Quantity" size="lg" [min]="1" [defaultValue]="1"> </app-input-number>
                                        </div>
                                        <div class="col-7 text-end d-flex align-items-center">
                                            <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/> Add to Cart</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                <!-- </div> -->
            </div>
        </div>
        <!--Recently viewed-->
        <div class="row recently-viewed mb-4" *ngIf="showRecentlyViewedProduct">
            <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 d-flex justify-content-between align-items-center">
                <h2>RECENTLY VIEWED AND RELATED ITEMS</h2>
                <!-- <a class="view-all">
                    View All <i class="fas fa-angle-right"></i>
                </a> -->
            </div>
            <div
                [ngClass]="recentItems.length < 5  ? 'col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 single-item': 'col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12'">
                <!-- <div class="recently-viewed-list slider"> -->
                <ng-container *ngIf="!recentItems.length;else recentList;">
                    <owl-carousel-o [options]="customOptionsForRecentItems">
                        <ng-container *ngFor="let item of userService.generateFake(7);let i = index;">
                            <ng-template carouselSlide [id]="i">
                                <div class="me-2 ms-2">
                                    <div class="card">
                                        <div class="card-body cursor" style="height: 263px;">
                                            <div class="prod-image">
                                                <skc-rectangle [loading]="loading" [skc-height]="'80px'"
                                                    [skc-width]="'100%'"> </skc-rectangle>
                                            </div>
                                            <div class="prod-desc">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="modal-number">
                                                            <skc-rectangle [loading]="loading" [skc-height]="'19px'"
                                                                [skc-width]="'100%'"> </skc-rectangle>
                                                        </div>
                                                        <div class="modal-name">
                                                            <skc-rectangle [loading]="loading" [skc-height]="'16px'"
                                                                [skc-width]="'100%'"> </skc-rectangle>
                                                        </div>
                                                        <div class="delivery-time w-50 d-inline-block text-nowrap">
                                                            <skc-rectangle [loading]="loading" [skc-height]="'10px'"
                                                                [skc-width]="'50%'"> </skc-rectangle>
                                                        </div>
                                                        <div class="delivery-time w-50 d-inline-block text-end text-nowrap">
                                                            <skc-rectangle [loading]="loading" [skc-height]="'10px'"
                                                                [skc-width]="'50%'"> </skc-rectangle>
                                                        </div>
                                                        <div class="price fw-bold">
                                                            <skc-rectangle [loading]="loading" [skc-height]="'19px'"
                                                                [skc-width]="'100%'"> </skc-rectangle>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </ng-container>
                <ng-template #recentList>
                    <owl-carousel-o [options]="customOptionsForRecentItems">
                        <ng-container *ngFor="let product of recentItems">
                            <ng-template carouselSlide [id]="product.part_number">
                                <div class="me-2 ms-2">
                                    <div class="recent-card">
                                        <div class="card-body cursor" style="height: 263px;">
                                            <div class="prod-image cursor" [routerLink]="['/shop/product-details']"
                                                [queryParams]="{ part_Number : product.part_number }">
                                                <img class="product image" [src]="product?.part_image_path + '/resize/120x75'"
                                                    onerror="this.src='./assets/tata-vehicle.jfif';" />
                                            </div>
                                            <div class="prod-desc">
                                                <div class="row">
                                                    <div class="col-12">

                                                        <app-wishlist-icon [product]="product"
                                                            [layout]="'Nolayout'"></app-wishlist-icon>
                                                            <div class="modal-name"
                                                                title="{{ (product.short_description == '' || product.short_description == null) ? '-' : product.short_description}}">
                                                                {{ (product.short_description == " " || product.short_description == null) ?
                                                                "-" :(product.short_description.length > 20 ?(product.short_description.substr(0, 20) + '...') :product.short_description)}}
                                                            </div>
                                                        <div class="modal-number cursor"
                                                            [routerLink]="['/shop/product-details']"
                                                            [queryParams]="{ part_Number : product.part_number }">
                                                            {{ product?.part_number }}
                                                        </div>
                                                        <div [routerLink]="['/shop/product-details']"
                                                            [queryParams]="{ part_Number : product.part_number }">
                                                            <div class="delivery-time w-50 d-inline-block text-nowrap"
                                                                style="text-overflow: ellipsis; overflow:hidden;"
                                                                *ngIf="product?.hsn_code && product?.hsn_code != ''">
                                                                HSN: <span title="{{ product?.hsn_code }}">{{
                                                                    product?.hsn_code }}</span>
                                                            </div>
                                                            <div class="delivery-time w-50 d-inline-block text-end text-nowrap"
                                                                style="text-overflow: ellipsis; overflow:hidden;"
                                                                *ngIf="product?.tax_slab && product?.tax_slab!=''">
                                                                GST: <span title="{{ product?.tax_slab }}">{{
                                                                    product?.tax_slab }}%</span>
                                                            </div>
                                                            <!-- <div class="delivery-time">
                                                                Estd. Delivery: <span>Today</span>
                                                            </div> -->
                                                            <!-- <div class="price">
                                                                <img alt="indian rupee sign" src="./assets/images/rupee-indian.svg" />
                                                                9,90.00 <span>MRP 11,0.00</span>
                                                            </div> -->
                                                            <ng-container *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true); else elseTemplate">
                                                            <ng-container *ngIf="!product.total_amount">
                                                                <div class="price fw-bold">
                                                                    {{ (( product.resource_type == 'Pack'  )?product.crm_mrp*product.moq:product.crm_mrp ) |currencyFormat:'INR'}}
                                                                </div>
                                                            </ng-container>
                                                            <ng-container *ngIf="product.total_amount">
                                                                <div
                                                                    *ngIf="(product.base_discount_per +  product.scheme_discount_per + product.rule_discount_per + product.distributor2_discount_amount ) == 0.0">
                                                                    <div class="price fw-bold">
                                                                        {{ (( product.resource_type == 'Pack' )?product.crm_mrp*product.moq:product.crm_mrp ) |currencyFormat:'INR' }}
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    *ngIf="(product.base_discount_per +  product.scheme_discount_per + product.rule_discount_per + product.distributor2_discount_amount ) > 0.0">
                                                                    <div class="price fw-bold" >
                                                                        {{  (( product.resource_type == 'Pack' ) ? product.total_amount*product.moq
                                                                            : product.total_amount) |currencyFormat:'INR'
                                                                        }}
                                                                        <div class="card-actual-mrp">
                                                                            <span class="pt-1">
                                                                                MRP
                                                                                {{
                                                                                   (( product.resource_type == 'Pack' ) ? product.crm_mrp*product.moq
                                                                                    : product.crm_mrp )|currencyFormat:'INR'
                                                                                }}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>
                                                        <ng-template #elseTemplate>
                                                            <div class="price fw-bold">
                                                            {{ (( product.resource_type == 'Pack' )?product.crm_mrp*product.moq:product.crm_mrp ) |currencyFormat:'INR' }}
                                                            </div>
                                                        </ng-template>
                                                            <div class="tmgproduct moqProductInfo" *ngIf="( product.resource_type == 'Pack' && product.moq > 1)">
                                                                <p class="save-mode">1 Unit contains {{product.moq | number: '1.0-0'}} <span *ngIf="product.moq > 1">Quantities</span><span *ngIf="product.moq <= 1">Quantity</span></p>
                                                                <!-- <br>(MRP is applicable for 1 Unit =
                                                                {{ product.column_4 ? product?.column_4 / 1000 + ' litres' : product.moq/1 + ' Nos.' }}
                                                                ) -->
                                                            </div>
                                                            <span class="error-message-service text-nowrap"
                                                                *ngIf="product.is_nls == 'in_active'">No Longer
                                                                Serviced</span>
                                                            <span class="error-message-service text-nowrap"
                                                                *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?true:false)">No
                                                                Longer Available</span>
                                                            <div class="col-12 text-nowrap view-alternate"
                                                                *ngIf="product.is_nls == 'in_active' || (product?.alternate_part?true:false)">
                                                                <span [routerLink]="['/shop/product-details']"
                                                                    [queryParams]="{ part_Number : product.alternate_part?product.alternate_part:product.part_number }"
                                                                    style="cursor: pointer;">View Alternate Part</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-1 text-end p-0">
                                                            <a   class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                                        </div> -->
                                                </div>
                                                <ng-container *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                                                <div class="discount"
                                                    *ngIf="(convertToFloat(product?.base_discount_per) + convertToFloat(product?.scheme_discount_per) +
                                                    convertToFloat(product?.tml_discount_per) + convertToFloat(product?.distributor_discount_per) +
                                                    convertToFloat(product?.rule_discount_per) + convertToFloat(product.distributor2_discount_amount)) > 0"
                                                    [routerLink]="['/shop/product-details']"
                                                    [queryParams]="{ part_Number : product.part_number }">
                                                    <!-- {{(product?.base_discount + product?.scheme_discount +
                                                                    product?.tml_rule_discount + product?.dealer_rule_discount +
                                                                    product?.rule_discount)}} % -->
                                                                    {{'SAVE ' + (((product.crm_mrp-product.total_amount)/product.crm_mrp)*100)?.toFixed(1) + '%'}}
                                                </div>
                                            </ng-container>
                                            </div>
                                        </div>
                                        <hr
                                        *ngIf="product.is_nls != 'in_active'  &&  ( product.is_nls == 'active' && !product?.alternate_part )" style="margin: 0px;"/>
                                        <div class="card-footer" style="border-top: none;" [ngStyle]="product.inventory_quantity <= 0 ? {'display':'flex'}:{}">
                                            <div class="row" style="width: 100%;">
                                            <div class="col-5 "
                                            *ngIf="(isUserType?.user_type == 'MECH' && (product.inventory_quantity != 0 && product.inventory_quantity != null && product.moq <= product.inventory_quantity) && product.is_nls != 'in_active' && (product?.alternate_part?false:true)) || (isUserType?.user_type != 'MECH' && product.is_nls != 'in_active' && (product?.alternate_part?false:true))">
                                            <app-input-number aria-label="Quantity" size="lg" [min]="1"
                                                (currentQuantity)="readQuantity($event,product);"
                                                [defaultValue]="product.min_quantity" [isTmgoValue]="( product.resource_type == 'Pack' )?product.moq:'1'" [userType]="isUserType" [inventoryQty]="product?.inventory_quantity">
                                            </app-input-number>
                                        </div>
                                        <div style="display: contents;" *ngIf="isUserType?.user_type == 'MECH'">
                                            <ng-container  *ngIf="product.inventory_quantity > 0 && product.moq <= product.inventory_quantity">
                                                <div class="col-7 text-end d-flex align-items-center"
                                                *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                                                <button class="add-to-card" (click)="addToCart(product)"><img alt=""
                                                        src="./assets/images/cart.svg" /> Add to
                                                    Cart</button>
                                            </div>
                                            </ng-container>
                                            <ng-container  *ngIf="(product.inventory_quantity <= 0 || product.inventory_quantity == null || product.moq > product.inventory_quantity) && product.is_nls != 'in_active' && (product?.alternate_part?false:true)" style="margin-left: auto;">
                                                <h6 style="font-size: 13px;
                                                position: relative;
                                                font-weight: 700;
                                                color: red;
                                                top: 5px;">Out Of Stock</h6>
                                            </ng-container>
                                        </div>
                                        <div class="col-7 text-end d-flex align-items-center"
                                        *ngIf="isUserType?.user_type != 'MECH' && product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                                        <button class="add-to-card" (click)="addToCart(product)"><img alt=""
                                                src="./assets/images/cart.svg" /> Add to
                                            Cart</button>
                                    </div>
                                        </div>
                                        </div>
                                        <div class="card-footer" style="border-top: none; height: 25px;" *ngIf="(product.is_nls == 'in_active') || (product.is_nls != 'in_active' && (product?.alternate_part?true:false))">
                                            <div class="row">
                                               <div class="col-md-12">
                                               </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </ng-template>


                <!-- <div class="me-2 ms-2">
                        <div class="card">
                            <div class="card-body">
                                <div class="prod-image">
                                    <img class="product image" src="./assets/images/menuIcon.PNG" />
                                </div>
                                <div class="prod-desc">
                                    <div class="row">
                                        <div class="col-11">
                                            <div class="modal-number">
                                                266835300130
                                            </div>
                                            <div class="modal-name">
                                                Crown W. & Pinion (37/7),OER - Spirac BS4
                                            </div>
                                            <div class="delivery-time">
                                                Estd. Delivery: <span>Today</span>
                                            </div>
                                            <div class="price">
                                                <img alt="indian rupee sign" src="./assets/images/rupee-indian.svg" />
                                                9,90.00 <span>MRP 11,0.00</span>
                                            </div>

                                        </div>
                                        <div class="col-1 text-end p-0">
                                            <a   class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                        </div>
                                        <hr />
                                        <div class="col-5 ">
                                            <app-input-number aria-label="Quantity" size="lg" [min]="1" [defaultValue]="1"> </app-input-number>
                                        </div>
                                        <div class="col-7 text-end d-flex align-items-center">
                                            <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/> Add to Cart</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="me-2 ms-2">
                        <div class="card">
                            <div class="card-body">
                                <div class="prod-image">
                                    <img class="product image" src="./assets/images/menuIcon.PNG" />
                                </div>
                                <div class="prod-desc">
                                    <div class="row">
                                        <div class="col-11">
                                            <div class="modal-number">
                                                266835300130
                                            </div>
                                            <div class="modal-name">
                                                Crown W. & Pinion (37/7),OER - Spirac BS4
                                            </div>
                                            <div class="delivery-time">
                                                Estd. Delivery: <span>Today</span>
                                            </div>
                                            <div class="price">
                                                <img alt="indian rupee sign" src="./assets/images/rupee-indian.svg" />
                                                9,90.00 <span>MRP 11,0.00</span>
                                            </div>

                                        </div>
                                        <div class="col-1 text-end p-0">
                                            <a   class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                        </div>
                                        <hr />
                                        <div class="col-5 ">
                                            <app-input-number aria-label="Quantity" size="lg" [min]="1" [defaultValue]="1"> </app-input-number>
                                        </div>
                                        <div class="col-7 text-end d-flex align-items-center">
                                            <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/> Add to Cart</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="me-2 ms-2">
                        <div class="card">
                            <div class="card-body">
                                <div class="prod-image">
                                    <img class="product image" src="./assets/images/menuIcon.PNG" />
                                </div>
                                <div class="prod-desc">
                                    <div class="row">
                                        <div class="col-11">
                                            <div class="modal-number">
                                                266835300130
                                            </div>
                                            <div class="modal-name">
                                                Crown W. & Pinion (37/7),OER - Spirac BS4
                                            </div>
                                            <div class="delivery-time">
                                                Estd. Delivery: <span>Today</span>
                                            </div>
                                            <div class="price">
                                                <img alt="indian rupee sign" src="./assets/images/rupee-indian.svg" />
                                                9,90.00 <span>MRP 11,0.00</span>
                                            </div>

                                        </div>
                                        <div class="col-1 text-end p-0">
                                            <a   class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                        </div>
                                        <hr />
                                        <div class="col-5 ">
                                            <app-input-number aria-label="Quantity" size="lg" [min]="1" [defaultValue]="1"> </app-input-number>
                                        </div>
                                        <div class="col-7 text-end d-flex align-items-center">
                                            <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/> Add to Cart</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="me-2 ms-2">
                        <div class="card">
                            <div class="card-body">
                                <div class="prod-image">
                                    <img class="product image" src="./assets/images/menuIcon.PNG" />
                                </div>
                                <div class="prod-desc">
                                    <div class="row">
                                        <div class="col-11">
                                            <div class="modal-number">
                                                266835300130
                                            </div>
                                            <div class="modal-name">
                                                Crown W. & Pinion (37/7),OER - Spirac BS4
                                            </div>
                                            <div class="delivery-time">
                                                Estd. Delivery: <span>Today</span>
                                            </div>
                                            <div class="price">
                                                <img alt="indian rupee sign" src="./assets/images/rupee-indian.svg" />
                                                9,90.00 <span>MRP 11,0.00</span>
                                            </div>

                                        </div>
                                        <div class="col-1 text-end p-0">
                                            <a   class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                        </div>
                                        <hr />
                                        <div class="col-5 ">
                                            <app-input-number aria-label="Quantity" size="lg" [min]="1" [defaultValue]="1"> </app-input-number>
                                        </div>
                                        <div class="col-7 text-end d-flex align-items-center">
                                            <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/> Add to Cart</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="me-2 ms-2">
                        <div class="card">
                            <div class="card-body">
                                <div class="prod-image">
                                    <img class="product image" src="./assets/images/menuIcon.PNG" />
                                </div>
                                <div class="prod-desc">
                                    <div class="row">
                                        <div class="col-11">
                                            <div class="modal-number">
                                                266835300130
                                            </div>
                                            <div class="modal-name">
                                                Crown W. & Pinion (37/7),OER - Spirac BS4
                                            </div>
                                            <div class="delivery-time">
                                                Estd. Delivery: <span>Today</span>
                                            </div>
                                            <div class="price">
                                                <img alt="indian rupee sign" src="./assets/images/rupee-indian.svg" />
                                                9,90.00 <span>MRP 11,0.00</span>
                                            </div>

                                        </div>
                                        <div class="col-1 text-end p-0">
                                            <a   class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                                        </div>
                                        <hr />
                                        <div class="col-5 ">
                                            <app-input-number aria-label="Quantity" size="lg" [min]="1" [defaultValue]="1"> </app-input-number>
                                        </div>
                                        <div class="col-7 text-end d-flex align-items-center">
                                            <button class="add-to-card"><img alt="" src="./assets/images/cart.svg"/> Add to Cart</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                <!-- </div> -->
            </div>
        </div>
    </ng-container>

      <!--Popular service kit-->
      <div class="row popular-service-kit mb-4" *ngIf="showKits">
        <div
            class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 mb-3 d-flex justify-content-between align-items-center">
            <div>
                <h2>Popular service kit</h2>
            </div>
            <a class="view-all" [routerLink]="['/shop/productcards']" [queryParams]="{searchBy: 'kit'}">
                View All <i class="fas fa-angle-right"></i>
            </a>
        </div>
        <div class="col-12 col-sm-12 col-lg-12 col-md-12 col-xl-12 mb-4 pe-0"
            [ngClass]="PopularKitsData?.length < 5 ? 'carousel-single-item':''">
            <!-- <skeleton-loader sHeight="255 !important" sWidth="241 !important" *ngIf="!isKitLoaded"></skeleton-loader> -->
            <!-- <div class="service-kit slider"> -->
            <ng-container *ngIf="!PopularKitsData?.length;else kitsPart;">
                <!-- <ng-container *ngIf="takeFalse"> -->
                <owl-carousel-o [options]="customOptionsForKits">
                    <ng-container *ngFor="let item of userService.generateFake(4);let i = index;">
                        <ng-template carouselSlide [id]="i">
                            <div class="me-3">
                                <div class="card" style="cursor: pointer;">
                                    <div class="service-kit-image-wrapper">
                                        <skc-rectangle [loading]="loading" [skc-height]="'175px'" [skc-width]="'307px'">
                                        </skc-rectangle>
                                    </div>

                                    <div class="card-body" (click)="KitSpecificParts(kit?.line_2)">
                                        <h5 class="service-kit-title"><skc-rectangle [loading]="loading"
                                                [skc-height]="'40px'" [skc-width]="'274px'"> </skc-rectangle></h5>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>

            </ng-container>
            <ng-template #kitsPart>
                <owl-carousel-o [options]="customOptionsForKits">
                    <ng-container *ngFor="let kit of PopularKitsData">
                        <ng-template carouselSlide [id]="kit.line_2">
                            <!-- <div class="me-3"> -->
                            <div class="me-3">
                                <div class="card" style="cursor: pointer;">
                                    <div class="service-kit-image-wrapper">
                                        <img [src]="kit?.image_url + '/resize/276x156'" (click)="KitSpecificParts(kit?.line_2)"
                                            class="card-img-top" alt="Service Kit Name">
                                    </div>

                                    <div class="card-body" (click)="KitSpecificParts(kit?.line_2)">
                                        <h5 class="service-kit-title">{{ kit?.line_2 }}</h5>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </ng-template>
        </div>
    </div>
</div>

<ng-template #confimationmodal let-d="dismiss">
    <app-selectCartType [btnshowConfrimed]="btnshowConfrimed" [btnshowsubmit]="btnshowsubmit"
        (setCartType)="setAddCart();" [cartTypeList]="cartTypeList" [baseProductdata]="productInfo"
        (selectedCartType)="ShowPart()" (inputChange)="changeCartType($event,null)"
        (closeModal)="closePart()"></app-selectCartType>
</ng-template>

<!-- <div class="loader" *ngIf="showLoader">
    <div class="ng-spinner ng-spinner-icon"></div>
  </div> -->
