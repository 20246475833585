import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AppStateService } from '../../services/app-state/app-state.service';
import { RegexService } from '../../services/regex-service';
import { UserService } from '../../services/REST/user.service';
import { secureStorage } from '../../services/securestorage';
import { ViewChild } from '@angular/core';
import { Router,Event,NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-query-form',
  templateUrl: './query-form.component.html',
  styleUrls: ['./query-form.component.scss']
})
export class QueryFormComponent implements OnInit {
  @ViewChild('myInput') myInputVariable: ElementRef;
  queryform: FormGroup;
  account_id: any;
  account_name: any;
  email_id: any;
  contact_no: any;
  account_pk: any;
  imageSrc: string;
  isSizeIssue: boolean = false;
  isFileTypeIssue: boolean = false;
  userDetails;
  categoryList: any = [];
  selectedCategory:any = "";
  isLoggedIn:boolean = false;
  disableFields:boolean = false;
  otheruserdata
  currentUrl;
  isfileSelected :boolean = false;
  submitted = false;
  showLoader: boolean = false;
    constructor(
    private userService: UserService,
    private localstorage: secureStorage,
    private formbuilder: FormBuilder,
    private toaster: ToastrService,
    private regexService: RegexService,
    private appStateService: AppStateService,
    private router: Router) {

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url.includes("account/login") || event.url.includes("account/registration") ? false : true;
        if(this.currentUrl==false){
          this.close();
        }
      }
    });

}

  ngOnInit(): void {

    let data = {
      "category_id": "",
      "category_name": ""
    }
    this.userService.userHelplineCategoryList(data).subscribe(response => {
      if (response.success) {
        this.categoryList.push(response.data[0]);
      }
      else {
        this.categoryList = [];
      }
    });
    this.appStateService.isLoggedIn.subscribe((isLog) => {
      this.isLoggedIn = isLog;
      this.resetform();
    });
  }
  resetform() {

    this.userDetails = JSON.parse(this.localstorage.getItem('userData'));
    this.otheruserdata = JSON.parse(this.localstorage.getItem('otheruserdata'));
    if (this.userDetails) {
      this.disableFields = true;
      this.account_pk = this.userDetails ? parseInt(this.userDetails.account_pk) : '',
      this.account_id = this.userDetails.account_id ? this.userDetails.account_id : '';
      this.account_name = this.userDetails.account_name ? this.userDetails.account_name : '';
      this.contact_no = this.otheruserdata ? this.otheruserdata?.contact_no : this.userDetails.contact_no ? this.userDetails.contact_no : '';
    }
    this.buildForm();

  }
  buildForm() {

    this.queryform = this.formbuilder.group({
      account_pk: this.account_pk ? this.account_pk : '',
      account_id: this.account_id ? this.account_id : '',
      account_name: this.account_name ?[this.account_name, [Validators.required, Validators.pattern(this.regexService.NamewithAnd)]]
                                      : ['', [Validators.required, Validators.pattern(this.regexService.NamewithAnd)]],
      email_id: ['', [Validators.required]],
      contact_no: this.contact_no ? [this.contact_no,[Validators.required, Validators.pattern(this.regexService.Phone)]]
                                  :['',[Validators.required, Validators.pattern(this.regexService.Phone)]],
      category_id: '',
      part_number: '',
      description: ['', [Validators.required]],
      registration_number: '',
      chassis_number: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9]*$")]],
      image_file: ''
    });
  }

  get mobileNumber() {
    return this.queryform.get('contact_no');
  }

  get accountName() {
    return this.queryform.get('account_name');
  }

  onlyNumberKey(event) {
    var ASCIICode = event.which ? event.which : event.keyCode;
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) return false;
    return true;
  }

  get f() { return this.queryform.controls; }

  postQuery() {
    this.submitted = true;
    this.account_name = this.queryform.value.account_name
    this.contact_no = this.queryform.value.contact_no
    this.account_id = this.queryform.value.account_id
    if(this.isFileTypeIssue){
      this.toaster.error('Please upload a pdf, jpeg or jpg file');
      return;
    }
    if (this.queryform.invalid) {

      // if(!this.account_name || this.accountName.errors){
      //   this.toaster.error("Please enter Full Name");
      //   return;
      // }
      // else if (!this.contact_no || this.mobileNumber.errors) {
      //   this.toaster.error("Please enter valid Mobile Number");

      //   return;
      // }
      // else if(!this.queryform.value.description){
      //   this.toaster.error("Please Add Remarks");

      //   return;
      // }
      // else if(this.queryform.controls.email_id.errors){
      //   this.toaster.error("Please enter valid Email ID");
      //   return;
      // }
      // this.toaster.error("Please fill the required fields");
      return false;
    }else{
      let data = {
        "account_pk": this.account_pk,
        "account_id": this.account_id,
        "account_name": this.account_name,
        "contact_no": this.contact_no,
        "category_id": this.selectedCategory.id,
        "email_id": this.queryform.value.email_id,
        "part_number": this.queryform.value.part_number,
        "description": this.queryform.value.description,
        "registration_number": this.queryform.value.registration_number,
        "chassis_number": this.queryform.value.chassis_number,
        "image_file": this.imageSrc
      }
      this.showLoader = true;
      this.userService.userHelplineQuery(data).subscribe(response => {
        this.showLoader = false;
        if (response.success) {
          this.toaster.success(response.data.msg);
          this.selectedCategory = '';
          this.imageSrc = '';
          this.myInputVariable.nativeElement.value = "";

          this.submitted = false;
          this.close();
          // this.queryform.patchValue({
          //   account_name: '',
          //   contact_no: '',
          // });
        } else {
          this.showLoader = false;
          this.toaster.error(response.data.msg);
        }
      });
    }
  }

  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files.length == 0) {
      this.isFileTypeIssue = false;
      this.isfileSelected = false;
      return;
    }
    if (event.target.files) {
      let imageData = event.target.files[0];
      var kbSize = imageData.size / 1024;
      if ( imageData.type === 'application/pdf' || imageData.type === 'image/jpeg' ||imageData.type === 'image/jpg') {
        if (event.target.files.length) {

      this.isfileSelected = true;
          const [file] = event.target.files;
          reader.readAsDataURL(file);
          reader.onload = () => {
            this.imageSrc = reader.result as string;
            this.queryform.patchValue({
              image_file: reader.result,
            });
          };
        }
        this.isSizeIssue = false;
        this.isFileTypeIssue = false;
      }
      else if ( imageData.type !== 'application/pdf' || imageData.type !== 'image/jpeg' ||imageData.type !== 'image/jpg'){
        this.isfileSelected = true;
        this.isFileTypeIssue = true;
        this.toaster.error('Please upload a pdf, jpeg or jpg file');
        return;
      }
      else if (Math.round(kbSize / 1024) > 2) {
        this.isfileSelected = true;
        this.isSizeIssue = true;
        this.toaster.error('Image size must be less than or equal to 2mb.');
        return;
      }
    }
  }
  Onselectedcategory(category) {
    this.selectedCategory = category;
  }
  clearImage(){
    this.isFileTypeIssue = false;
    this.isfileSelected = false;

    this.imageSrc = "";
    this.myInputVariable.nativeElement.value = "";
  }
close(){
  this.submitted = false;
  document.getElementById('chat-wrapper').style.display = "none";
  if(this.isLoggedIn){
    this.resetform();
  }
  else{
    if(this.queryform){
      this.queryform.reset();
    }
  }
}
  open() {

    document.getElementById('chat-wrapper').style.display = "block";

  }
}
