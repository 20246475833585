<section class="mat-fleet-sec  p-sm-4 p-md-5" [ngClass]="(showHeader) ? 'clsmargin' : ''">
    <div class="container">
        <mat-card class="">
            <mat-card-header>
                <mat-card-title class="mat-login-header">
                    <div class="login-header-img text-end">
                        <picture>
                            <source srcset="./assets/images/e-dukaan-logo.png" media="(min-width: 600px)">
                            <img src="./assets/images/e-dukaan-logo.png" class="img-fluid" alt="">
                          </picture> 
                    </div>
                </mat-card-title>
            </mat-card-header>
            <div class="ps-md-5 pe-md-5">
                <div class="c_fleet_heading text-center" *ngIf="isRegistration">
                    <h4 class="mb-2">My Fleet Inventory</h4>
                    <p>The vehicles associated with your mobile number +91 {{this.registrationForm.value.contact_no | mask}}</p>
                </div>
                <div class="c_fleet_heading text-center" *ngIf="isLogin">
                    <h4 class="mb-2">My Fleet Inventory</h4>
                    <p>The vehicles associated with your mobile number +91 {{this.loginForm.value.contact_no | mask}}</p>
                </div>
                <div id="fleetCro" class="owl-carousel owl-theme fleet-nav-slide mb-4" >
                    <div class="item">
                        <div *ngFor="let obj of vehicle" [ngClass]="obj?.model_cat === selectedModelCategory?.model_cat ? 'card-check-box active position-relative' : 'card-check-box position-relative'">                                
                                <!-- <input class="form-check-input" [checked]="obj?.model_cat === selectedModelCategory?.model_cat" type="checkbox" value="" id="item-Check1"> -->
                                <div class="form-check-input" id="item-Check1" (click)="getVehicleList(obj);"></div>
                                <label class="form-check-label d-block" (click)="getVehicleList(obj);" for="item-Check1">
                                    <div class="d-flex">
                                        <div class="card-check-img mr-3">
                                            <img [src]="obj.model_img_name" class="img-fluid" alt="" >
                                        </div>
                                        <div class="card-ckeck-content">
                                            <p class="prod-name">{{obj.model_cat}}</p>
                                            <p class="prod-count-item">{{obj.count}}</p>
                                        </div>
                                    </div>
                                </label>
                        </div>
                    </div>
                    <!-- <div class="item">
                        <div class="card-check-box">
                                <input class="form-check-input" type="checkbox" value="" id="item-Check2">
                                <label class="form-check-label d-block" for="item-Check2">
                                    <div class="d-flex">
                                        <div class="card-check-img mr-3">
                                            <img src="../../assets/images/item-1.PNG" class="img-fluid" alt="" srcset="../../assets/images/item-1.PNG">
                                        </div>
                                        <div class="card-ckeck-content">
                                            <p class="prod-name">M&HCV Construck</p>
                                            <p class="prod-count-item">28 vehicles</p>
                                        </div>
                                    </div>
                                </label>
                            
                        </div>
                        
                    </div>
                    <div class="item">
                        <div class="card-check-box">
                                <input class="form-check-input" type="checkbox" value="" id="item-Check3">
                                <label class="form-check-label d-block" for="item-Check3">
                                    <div class="d-flex">
                                        <div class="card-check-img mr-3">
                                            <img src="../../assets/images/item-1.PNG" class="img-fluid" alt="" srcset="../../assets/images/item-1.PNG">
                                        </div>
                                        <div class="card-ckeck-content">
                                            <p class="prod-name">M&HCV Construck</p>
                                            <p class="prod-count-item">28 vehicles</p>
                                        </div>
                                    </div>
                                </label>
                            
                        </div>
                        
                    </div>
                    <div class="item">
                        <div class="card-check-box">
                                <input class="form-check-input" type="checkbox" value="" id="item-Check4">
                                <label class="form-check-label d-block" for="item-Check4">
                                    <div class="d-flex">
                                        <div class="card-check-img mr-3">
                                            <img src="../../assets/images/item-1.PNG" class="img-fluid" alt="" srcset="../../assets/images/item-1.PNG">
                                        </div>
                                        <div class="card-ckeck-content">
                                            <p class="prod-name">M&HCV Construck</p>
                                            <p class="prod-count-item">28 vehicles</p>
                                        </div>
                                    </div>
                                </label>
                            
                        </div>
                        
                    </div>
                    <div class="item">
                        <div class="card-check-box">
                                <input class="form-check-input" type="checkbox" value="" id="item-Check2">
                                <label class="form-check-label d-block" for="item-Check2">
                                    <div class="d-flex">
                                        <div class="card-check-img mr-3">
                                            <img src="../../assets/images/item-1.PNG" class="img-fluid" alt="" srcset="../../assets/images/item-1.PNG">
                                        </div>
                                        <div class="card-ckeck-content">
                                            <p class="prod-name">M&HCV Construck</p>
                                            <p class="prod-count-item">28 vehicles</p>
                                        </div>
                                    </div>
                                </label>
                            
                        </div>
                        
                    </div>
                    <div class="item">
                        <div class="card-check-box">
                                <input class="form-check-input" type="checkbox" value="" id="item-Check2">
                                <label class="form-check-label d-block" for="item-Check2">
                                    <div class="d-flex">
                                        <div class="card-check-img mr-3">
                                            <img src="../../assets/images/item-1.PNG" class="img-fluid" alt="" srcset="../../assets/images/item-1.PNG">
                                        </div>
                                        <div class="card-ckeck-content">
                                            <p class="prod-name">M&HCV Construck</p>
                                            <p class="prod-count-item">28 vehicles</p>
                                        </div>
                                    </div>
                                </label>
                            
                        </div>
                        
                    </div> -->
                </div>
                <mat-card class="mat-fleet-table pe-md-5 ps-md-5 bg-white">
                    <div class="table-responsive">
                        <table class="table mat-data-table">
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Registration Number</th>
                                    <th>Chasis Type</th>
                                    <th>Year</th>
                                    <th>Model</th>
                                    <!-- <th></th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let VCobj of vehicleinfo let i=index">
                                    <td>{{i+1}}</td>
                                    <td>{{VCobj?.REGISTRATION_NUM_s}}</td>
                                    <td>{{VCobj?.CHASSIS_NUM_s}}</td>
                                    <td>{{VCobj?.LAST_UPD_dt | date:'yyyy'}}</td>
                                    <td>{{VCobj?.Base_Model_s}}</td>
                                    <!-- <td><a href="#" class="text-decoration-none del-action">Delete</a></td> -->
                                </tr>
                                <!-- <tr>
                                    <td>1</td>
                                    <td>MH12 EA 4589</td>
                                    <td>713001</td>
                                    <td>2017</td>
                                    <td>2528.K</td>
                                    <td><a href="#" class="text-decoration-none del-action">Delete</a></td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>MH12 EA 4589</td>
                                    <td>713001</td>
                                    <td>2017</td>
                                    <td>2528.K</td>
                                    <td><a href="#" class="text-decoration-none del-action">Delete</a></td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>MH12 EA 4589</td>
                                    <td>713001</td>
                                    <td>2017</td>
                                    <td>2528.K</td>
                                    <td><a href="#" class="text-decoration-none del-action">Delete</a></td>
                                </tr> -->
                            </tbody>
                        </table>
                    </div> 
                    
                </mat-card>
                <div class="text-end pt-3">
                    <button type="button" (click)="openUserType()" class="btn btn-primary ps-4 pe-4">Continue</button>
                </div>
            </div>
            
        </mat-card>
</div>
</section>
