import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { applicationConfiguration, AppConfig } from 'src/app/config/app.config';
import { AppStateService } from '../app-state/app-state.service';
import { CookieService } from 'ngx-cookie';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { JSEncrypt } from 'jsencrypt';
import { secureStorage } from '../securestorage';


@Injectable({
  providedIn: 'root'
})
export class RootService {
  showServicecall: boolean = false;
  expiredDate: any;
  protected server = this.appConfig.apiUrl + 'api/v1/ecom/';
  protected serve = this.appConfig.apiUrl;
  readonly options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'request-id': 'b997a77ae2b3f30c1bed151c3e42434f',
    }),
  };
  encryptedData: any;
  $encrypt: JSEncrypt;
  constructor(
    //private userService: UserService,
    private router: Router,
    private appStateService: AppStateService,
    private http: HttpClient,
    private cookieService: CookieService,
    @Inject(applicationConfiguration) private appConfig: AppConfig,
    private localstorage: secureStorage
  ) {
   }

  login(): Observable<any> {
    try {
      let device_id;
      let deviceid = this.localstorage.getItem('uniqueId');

      if (deviceid) {
        device_id = deviceid;
      } else {
        var result = '';
        let random;
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < 10; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));

        }
        random = Date.now() + Math.floor(Math.random() * 100000000000000000000)
        random += Math.floor(Math.random() * 100000000000000000000)
        random += result
        this.localstorage.setItem('uniqueId', random);
        device_id = random
      }
      // RSA Encryption
      
      let user = {
        "device_id": device_id,
      }

      return this.http.post(`${this.serve}api/v1/ecom/FreeSearchTokenRequest/`, user, this.options);
    }
    catch (error) {
    }
  }

  encryptAES(value: string){
  let data= CryptoJS.AES.encrypt("test_data", "mypassword");  
    // console.log(data);
    // const encryption= new JSEncrypt();
    // const publickey = environment.public_key;
    // encryption.setPublicKey(publickey);
    // let encryptedData = encryption.encrypt(value.toString());
  }

  get jsonFormatter() {
    return {
      stringify: (cipherParams: any) => {
        const jsonObj = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64), iv: null, s: null };
        if (cipherParams.iv) {
          jsonObj.iv = cipherParams.iv.toString();
        }
        if (cipherParams.salt) {
          jsonObj.s = cipherParams.salt.toString();
        }
        return JSON.stringify(jsonObj);
      },
      parse: (jsonStr) => {
        const jsonObj = JSON.parse(jsonStr);
        // extract ciphertext from json object, and create cipher params object
        const cipherParams = CryptoJS.lib.CipherParams.create({
          ciphertext: CryptoJS.enc.Base64.parse(jsonObj.ct)
        });
        if (jsonObj.iv) {
          cipherParams.iv = CryptoJS.enc.Hex.parse(jsonObj.iv);
        }
        if (jsonObj.s) {
          cipherParams.salt = CryptoJS.enc.Hex.parse(jsonObj.s);
        }
        return cipherParams;
      }
    };
  }

  getToken(): Promise<any> {
    try {
      let device_id;
      let deviceid = this.localstorage.getItem('uniqueId');

      if (deviceid) {
        device_id = deviceid;
      } else {
        var result = '';
        let random;
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < 10; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));

        }
        random = Date.now() + Math.floor(Math.random() * 100000000000000000000)
        random += Math.floor(Math.random() * 100000000000000000000)
        random += result
        this.localstorage.setItem('uniqueId', random);
        device_id = random
      }

      let user = {
        "device_id": device_id,
      }
      // RSA Encryption
      const currentDate = this.getNowUTC();
      let encryptedCurrentDate = this.encryptAES(String(currentDate));

      if (this.appStateService.isLoggedIn.value) {
        return new Promise((resolve, reject) => {
         // resolve()
        });
      }

      if (!this.showServicecall) {
        this.showServicecall = true;
        const promise = this.http.post(`${this.serve}api/v1/ecom/FreeSearchTokenRequest/`, user, this.options).toPromise()
          .then((response: any) => {
            if (response) {
              this.showServicecall = false;
              this.expiredDate = new Date();
              let Minutes = response.data['expires_in'] * 1000; // convert 2 minutes to milliseconds
              var expirein = (Minutes - (Minutes / 10))
              let expiredate2 = new Date(this.expiredDate.getTime() + expirein);

              this.cookieService.putObject('access_token', response.data['access_token'], { 'expires': expiredate2 });
              this.cookieService.putObject('ref_token', response.data['refresh_token']);
              this.localstorage.setItem('ref_token', response.data['refresh_token']);
              this.localstorage.setItem('refreshTokenInProgress', 'false');
              this.getCartType();

            }
            return
          })
        return promise;
      }

    }
    catch (error) {
    }
  }

  getNowUTC(){
    const now = new Date();
    return new Date(now.getTime() + (now.getTimezoneOffset() * 60000));
  }

  refreshToken(): Promise<any> {
    try {
      let refreshToken = this.cookieService.get("ref_token") ? JSON.parse(this.cookieService.get("ref_token")) : this.localstorage.getItem('ref_token');
      let data = {}
      if (refreshToken) {
        data = {
          refresh_token: refreshToken
        }
        if (!this.showServicecall) {
          this.showServicecall = true;
          const promise = this.http.post(`${this.serve}api/v1/ecom/token/refresh/`, data, this.options).toPromise()
            .then((response: any) => {
              this.showServicecall = false;
              if (response['success'] === true) {
                this.expiredDate = new Date();
                let Minutes = response.data['expires_in'] * 1000;
                var expirein = (Minutes - (Minutes / 10))
                let expiredate2 = new Date(this.expiredDate.getTime() + expirein);

                this.cookieService.putObject('access_token', response.data['access_token'], { 'expires': expiredate2 });
                this.cookieService.putObject('ref_token', response.data['refresh_token']);
                this.localstorage.setItem('ref_token', response.data['refresh_token']);
                this.localstorage.setItem('refreshTokenInProgress', 'false');
                this.getCartType();
              }
              return
            }).catch(err => { 
              if(err.status == 400){
                  if(err.error.data.error_code==64){
                    this.clearsession();
                  }
              }
          });
          return promise;
        }
      }
    }
    catch (error) {
    }

  }


  refreshToken2() {
    let refreshToken = this.cookieService.get("ref_token") ? JSON.parse(this.cookieService.get("ref_token")) : this.localstorage.getItem('ref_token');
    let data = {}
    if (refreshToken) {
      data = {
        refresh_token: refreshToken
      }
    }
    if (!this.showServicecall) {
      this.showServicecall = true;
      return this.http.post<any>(`${this.serve}api/v1/ecom/token/refresh/`, data, this.options
      ).pipe(tap((response) => {
        this.showServicecall = false;
        this.localstorage.setItem('refreshTokenInProgress', 'false');
        this.storeJwtToken(response);
        this.getCartType();

      }));
    }
  }


  private storeJwtToken(response) {
    if (response['success'] === true) {
      this.expiredDate = new Date();
      let Minutes = response.data['expires_in'] * 1000;
      var expirein = (Minutes - (Minutes / 10))
      let expiredate2 = new Date(this.expiredDate.getTime() + expirein);

      this.cookieService.putObject('access_token', response.data['access_token'], { 'expires': expiredate2 });
      this.cookieService.putObject('ref_token', response.data['refresh_token']);
      this.localstorage.setItem('ref_token', response.data['refresh_token']);
      this.localstorage.setItem('refreshTokenInProgress', 'false');

    }
  }

  getCartType() {
    try {
     if (!this.localstorage.getItem('getCartType')) {
          localStorage.removeItem('getCartType');
          this.http.post(`${this.serve}api/v1/ecom/cart/cart_type/list/`, this.options).toPromise()
            .then((response: any) => {
              if (response['success'] === true) {
                let cartArray = [];
                cartArray = response.data;
                cartArray.forEach(element => {
                  element.cart_count = 0;
                })
                this.localstorage.setItem('getCartType', JSON.stringify(cartArray));
              }
            })
     }
    }
    catch (error) {
    }

  }

    clearsession(){
    this.appStateService.isLoggedIn.next(false);
    this.appStateService.userSubject.next(null);
    localStorage.removeItem('getCartType');
    this.localstorage.setItem('AddressId', '0')
    this.localstorage.setItem('Pincode', '')
    localStorage.removeItem('userData');
    localStorage.removeItem('user');
    localStorage.removeItem('address');
    localStorage.removeItem('type');
    localStorage.removeItem('wishlistItems');
    localStorage.removeItem('compareItems');
    
        localStorage.removeItem('isOtherUser');
        localStorage.removeItem('otheruser');
    localStorage.removeItem('OtherUserPermission');
    localStorage.removeItem('usertype');
    localStorage.removeItem("AccountData");
    localStorage.removeItem("PageList");
    localStorage.removeItem('pageHeader');
    localStorage.removeItem('OrderData');
    localStorage.removeItem('OrderType');
    localStorage.removeItem('Pincode');
    localStorage.removeItem('AddressId');
    localStorage.removeItem('ref_token');
    localStorage.removeItem('division_info');
    localStorage.removeItem('division_info1');
    localStorage.removeItem('SLVCart');
    localStorage.removeItem('MHVCart');
    localStorage.removeItem('loyaltyDetail')
    this.cookieService.removeAll();
    this.router.navigate(['']);
  }

  async getTokenOldServer(){
    let device_id;
    let deviceid = this.localstorage.getItem('uniqueId');

    if (deviceid) {
      device_id = deviceid;
    }else {
      var result = '';
      let random;
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for (var i = 0; i < 10; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));

      }
      random = Date.now() + Math.floor(Math.random() * 100000000000000000000)
      random += Math.floor(Math.random() * 100000000000000000000)
      random += result
      this.localstorage.setItem('uniqueId', random);
      device_id = random
    }
    // RSA Encryption
    let user = {
      "device_id": device_id,
    }
    await this.http.post(`${this.appConfig.previousUrl}api/v1/ecom/FreeSearchTokenRequest/`, user, this.options).subscribe(
      (res:any) => {
        if(res.success){
          this.expiredDate = new Date();
          let Minutes = res.data['expires_in'] * 1000; // convert 2 minutes to milliseconds
          var expirein = Minutes - Minutes / 10;
          let expiredate2 = new Date(this.expiredDate.getTime() + expirein);
          this.cookieService.putObject('previousUrlToken', res.data['access_token'], { expires: expiredate2 });
        }
      });
    }

}

