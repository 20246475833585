import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppFormErrorService } from '../../services/form-error.service';
import { RegexService } from '../../services/regex-service';
import { ProductService } from '../../services/REST/product.service';
import { UserService } from '../../services/REST/user.service';
import { secureStorage } from '../../services/securestorage';

@Component({
  selector: 'app-add-contact-person',
  templateUrl: './add-contact-person.component.html',
  styleUrls: ['./add-contact-person.component.scss']
})
export class AddContactPersonComponent implements OnInit {

  showLoader:boolean=false;
  @Output() closeModal = new EventEmitter<any>();
  addContactPersonForm:FormGroup;
  issubmit: boolean = true;
  submitted: boolean = false;
  userData:any;
  patternErrMsgPhone:string = "";
  patternErrMsgPhoneAlt:string = "";

  constructor( 
    private formErrorService: AppFormErrorService, 
    private formbuilder:FormBuilder,
    private productService: ProductService,
    private regexService: RegexService,
    private userService: UserService,
    private router: Router,
    private toastrService: ToastrService,
    private localstorage: secureStorage
    ) { }

  ngOnInit(): void {
    this.userData = JSON.parse(this.localstorage.getItem('userData'));
    this.buildContactPersonForm();
  }

  public hasRequiredError = (controlName: string): boolean =>
  this.formErrorService.hasRequiredError(
    controlName,
    this.addContactPersonForm
  );

  public hasPatternError = (controlName: string): boolean =>
  this.formErrorService.hasPatternError(
    controlName,
    this.addContactPersonForm
  );
  
  get f(){ return this.addContactPersonForm.controls }

  getValidationError(){
    this.issubmit = true;
    this.patternErrMsgPhone="";
    this.patternErrMsgPhoneAlt="";
    Object.keys(this.addContactPersonForm.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.addContactPersonForm.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          if(controlErrors[keyError] == true ){
            this.issubmit = false;
          }
          if(controlErrors[keyError].requiredPattern==this.regexService.Phone && key == 'contact_no' ){
            this.issubmit = false;
            this.patternErrMsgPhone = "Enter a valid conatct number"
          }
          if(controlErrors[keyError].requiredPattern==this.regexService.Phone && key == 'alt_contact_no' ){
            this.issubmit = false;
            this.patternErrMsgPhoneAlt = "Enter a valid alternate conatct number"
          }
        });
      }
    });
  }

  buildContactPersonForm(){
    this.addContactPersonForm = this.formbuilder.group({
        first_name: ['',[Validators.required,Validators.pattern(this.regexService.HumanName)]],
        last_name: ['', [Validators.required, Validators.pattern(this.regexService.HumanName)]],
        contact_no: ['',[Validators.required, Validators.pattern(this.regexService.Phone)]],
        alt_contact_no: [''],
    })
  }

  alphanumbericOnly(event): boolean {
    var inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onSubmit(){
    try{
      this.showLoader =true;
      this.submitted = true;
      this.getValidationError();
      if(this.issubmit ){
        let req = { 
          "contact_no":this.addContactPersonForm.controls['contact_no'].value?this.addContactPersonForm.controls['contact_no'].value:"",
          "alt_contact_no":this.addContactPersonForm.controls['alt_contact_no'].value?this.addContactPersonForm.controls['alt_contact_no'].value:"",
          "first_name":this.addContactPersonForm.controls['first_name'].value?this.addContactPersonForm.controls['first_name'].value:"",
          "last_name":this.addContactPersonForm.controls['last_name'].value?this.addContactPersonForm.controls['last_name'].value:"",
          "account_pk":this.userData.account_pk
        }
        if(req){
          this.productService.addContactPerson(req).subscribe(
            res => {
              if(res.success){
                this.toastrService.success("contact person has been added");
              }else{
                this.toastrService.error(res.data.msg);
              }
              this.showLoader = false;
              this.closeModal.emit();
            }
          )
        }
      }else{
        this.showLoader = false;
      }
    }catch(error){
      this.closeModal.emit();
      this.showLoader = false;
      this.userService.InsertExceptionLog({
        method_name: 'getContactPersonList()',
        page_name: 'CartAddress',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
    });
    }
  }

}
