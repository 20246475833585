import { Injectable, Inject } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { applicationConfiguration, AppConfig } from '../../../config/app.config';
import { RootService } from './root.service';
import { CookieService } from 'ngx-cookie';
@Injectable({
  providedIn: 'root'
})
export class AddressService {
  protected server = this.appConfig.apiUrl;
  Previoustoken = this.cookiesService.getObject('previousUrlToken');
  // protected server = 'https://eguruskin.api.tatamotors/api/states/';
 
 //// protected Eguruserver='https://eguruskindev.api.tatamotors/';
 protected Eguruserver= this.appConfig.apiUrl;
  //https://skinprod.api.tatamotors/
  // private options;
  protected googleapiServer = 'https://maps.googleapis.com/';
  readonly options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  constructor(
    private http: HttpClient,
    private handler: HttpBackend,
    private httpNoInterceptor:HttpClient,
    private cookiesService:CookieService,
    private rootService:RootService,
    // private router: Router,
    // private appStateService: AppStateService,
    @Inject(applicationConfiguration) private appConfig: AppConfig
  ) { 
    this.httpNoInterceptor = new HttpClient(this.handler);
  }

  setToken(user):Observable<any> {
    // return this.http.post(`${this.server}api/login/`,user,this.options);
    return this.http.post(`${this.Eguruserver}api/login/`,user,this.options);
  }
  getStates(): Observable<any> {
    if(!this.Previoustoken){
      this.rootService.getTokenOldServer();
      this.Previoustoken = this.cookiesService.getObject('previousUrlToken');
    }
    return this.httpNoInterceptor.post(`${this.appConfig.previousUrl}api/states/`,null,{ headers: new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Bearer ' + this.Previoustoken}) });
  }
  getCities(user): Observable<any> {
    return this.http.post(`${this.server}api/cities/`, user, this.options);
   // return this.http.post(`${this.Eguruserver}api/cities/`, user, this.options);
  }
  getDistricts(user): Observable<any> {
    return this.http.post(`${this.server}api/districts/`, user, this.options);
    //return this.http.post(`${this.Eguruserver}api/districts/`, user, this.options);
  }
  getTalukas(user): Observable<any> {
     return this.http.post(`${this.server}api/talukas/`, user, this.options);
    //return this.http.post(`${this.Eguruserver}api/talukas/`, user, this.options);
  }
  getPincodes(user): Observable<any> {
  return this.http.post(`${this.server}api/pincodes/`, user, this.options);
  //return this.http.post(`${this.Eguruserver}api/pincodes/`, user, this.options);
  }
  getAddressByPincode(user):Observable<any> {
    return this.http.post(`${this.server}/api/get/address/`, user, this.options);
  }

  addAddressByPincode(requestBody){
    return this.http.post(`${this.server}api/v2/ecom/search/geo_dropdowns/`,requestBody,this.options);
  }

  getUserGeoData(data): Observable<any> {
    return this.http.post(`${this.server}api/v2/ecom/search/address_dropdowns/`,data,this.options);
  }

  getLatLong(data): Observable<any> {
    return this.http.post(`${this.server}api/v2/ecom/common/get_lat_long/`, data, this.options);
  }

  getNearbyMechanics(data): Observable<any> {
    if(!this.Previoustoken){
      this.rootService.getTokenOldServer();
      this.Previoustoken = this.cookiesService.getObject('previousUrlToken');
    }
    return this.httpNoInterceptor.post(`${this.appConfig.previousUrl}api/emandi/v2/latlong_reg_guru/list/`, data, { headers: new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Bearer ' + this.Previoustoken}) });
  }

  getDivisionInfo(data):Observable<any>{
    return this.http.post(`${this.server}api/v2/ecom/order/get_division_info/`, data, this.options);
  }

  async getGeoData(data) {
    return  await this.http.post(`${this.server}api/v2/ecom/search/geo_dropdowns/`, data, this.options)
      .toPromise().then(res => {  return res; });
  }
}