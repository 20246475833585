import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders,  } from '@angular/common/http';
import { Observable, throwError, of, interval } from 'rxjs';
import { applicationConfiguration, AppConfig } from '../../../../../src/app/config/app.config';
import { timeout, retry, flatMap, retryWhen } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  protected server = this.appConfig.apiUrl+'api/v1/ecom/';
  protected newServer = this.appConfig.apiUrl+'api/v2/ecom/';
  // private options;
  readonly options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      // 'X-Content-Type-Options':  'nosniff',
      // 'X-XSS-Protection': '1',
      //  'mode': 'block'

    }),
  };
  constructor(
    private http: HttpClient,
    // private router: Router,
    // private appStateService: AppStateService,
    @Inject(applicationConfiguration) private appConfig: AppConfig
  ) { }

  getProduct(product): Observable<any> {
    return this.http.post(`${this.server}list/parts/`, product,this.options);
  }

  filterByPg(product): Observable<any> {
    return this.http.post(`${this.server}list/parts/`, product,this.options);
  }

  filterByPl(product): Observable<any> {
    return this.http.post(`${this.server}list/parts/`, product,this.options);
  }

  search(product): Observable<any> {
    return this.http.post(`${this.server}search/model_by_chassis_or_reg/`, product,this.options);
  }
  getProductListByRegOrChassiono(data):Observable<any> {
    return this.http.post(`${this.newServer}search/model_by_chassis_or_reg/`, data,this.options);
  }
   getProductListByRegOrChassionov2(data):Observable<any> {
    return this.http.post(
      `${this.newServer}search/get_model_by_chassis_or_reg/`,
      data,
      this.options
    );
  }
  getProductListByModel(data):Observable<any> {
    //http://139.59.77.169:2501/api/v1/ecom/models_by_bs_model/
    return this.http.post(`${this.server}models_by_bs_model/`, data,this.options);
  }
  getProductListByModels(data):Observable<any> {
    //http://139.59.77.169:2501/api/v1/ecom/models_by_bs_model/
    //return this.http.post(`${this.appConfig.apiUrl}api/v1/get/list/model_list_with_variant/`, data,this.options);
    return this.http.post(`${this.appConfig.apiUrl}api/v1/ecom/get/list/model_list_with_variant/`, data,this.options);
  }
  getModels(data):Observable<any> {
    //http://139.59.77.169:2501/api/v1/ecom/models_by_bs_model/
    return this.http.post(`${this.server}search/models/`, data,this.options);
  }
  // getProductListByModelNumber(data):Observable<any> {
  //   http://139.59.77.169:2501/api/v1/ecom/search/models/
  //   return this.http.post(`${this.server}search/model_by_chassis_or_reg/`, data,this.options);
  // }

  
  // getPartList(data):Observable<any> {
  //   // return this.http.post(`${this.appConfig.apiUrl}api/v1/get/search/web_part_search/`, data,this.options);
  //   return this.http.post(`${this.appConfig.apiUrl}api/v1/ecom/get/search/web_part_search/`, data,this.options);
  // }

  // TML api for overall and account level recommnedation

  getRecommnededParts(data):Observable<any>{
    return this.http.post(`${this.newServer}account/call/`,data,this.options);
  }

  // getPart_Search(data):Observable<any> {
  //   return this.http.post(`${this.newServer}search/part_search/`, data,this.options);
  // }


  getProductListByPartno(data):Observable<any> {
    return this.http.post(`${this.server}search/part_by_p_num/`, data,this.options);
  }

  // getProductDetails(data):Observable<any> {
  //   return this.http.post(`${this.newServer}search/part_search/`, data,this.options);
  // }

  getProductListByPartdescription(data):Observable<any> {
    return this.http.post(`${this.server}search/parts_by_desc/`, data,this.options);
  }
  getProductByKit(data):Observable<any> {
    return this.http.post(`${this.server}list/kit/parts/`, data,this.options);
  }
  // getProductByOilLubricant(data):Observable<any> {
  //   return this.http.post(`${this.server}list/pgline/`, data,this.options);
  // }
  getProductByOilLubricant(data):Observable<any> {
    //return this.http.post(`${this.appConfig.apiUrl}api/v1/get/search/web_part_search/`, data,this.options);
    return this.http.post(`${this.appConfig.apiUrl}api/v1/ecom/get/search/web_part_search/`, data,this.options);
  }

  // getNewModels(data):Observable<any> {
  //   //return this.http.post(`${this.appConfig.apiUrl}api/v1/get/search/web_part_search/`, data,this.options);
  //   return this.http.post(`${this.newServer}search/part_search/`, data,this.options);
  // }

  getProductListByLastDigits(data):Observable<any> {
    return this.http.post(`${this.server}search/part_by_p_num_digits/`, data,this.options);
  }
  async getAddress(data):Promise<any> {
    return await this.http.post(`${this.newServer}account/address_list/`, data,this.options).toPromise();
  }

  getAddressList(data):Observable<any> {
    return this.http.post(`${this.newServer}account/address_list/`, data,this.options);
  }

  setBillingShippingAddress(data):Observable<any>{
    return this.http.post(`${this.newServer}account/set_address/`, data,this.options );
  }

  getContactPersonList(data):Observable<any>{
    return this.http.post(`${this.newServer}account/dropdown/`, data,this.options);
  }

  addContactPerson(data):Observable<any>{
    return this.http.post(`${this.newServer}account/crm_contact_creation/`, data,this.options);
  }

  updateContactPerson(data):Observable<any>{
    return this.http.post(`${this.newServer}account/add_address/`, data,this.options);
  }

  getCartData(user):Observable<any> {
    return this.http.post(`${this.server}cart/list/`, user, this.options);
    // .pipe(timeout(500000))
  }

  getAddressDrafList(data):Observable<any>{
    return this.http.post(`${this.newServer}account/address_save_to_draft/`,data,this.options);
  }
  // removeProduct(product):Observable<any> {
  //   return this.http.post(`${this.server}cart/delete/`, product, this.options);
  // }
  removeProduct(product):Observable<any> {
    return this.http.post(`${this.newServer}cart/delete_cart_item/`, product, this.options);
  }
  // addToCart(product):Observable<any> {
  //   return this.http.post(`${this.server}cart/`, product, this.options);
  // }
  addToCart(product):Observable<any> {
    return this.http.post(`${this.newServer}cart/add_to_cart/`, product, this.options);
  }
  saveForLater(product):Observable<any>{
    return this.http.post(`${this.newServer}cart/save_for_later/`, product, this.options);   // save for later api server call
  }
  updateCart(product):Observable<any> {
    // return this.http.post(`${this.server}cart/update/`, product, this.options);
    return this.http.post(`${this.newServer}cart/update_cart_item/`, product, this.options);
  }
  // updateCart(product):Observable<any> {
  //   return this.http.post(`${this.newServer}cart/update_cart_item/`, product, this.options);
  // }
  checkStock(user):Observable<any> {
    return this.http.post(`${this.server}order/check_stock/`, user, this.options);
  }
  orderCalculation(product):Observable<any> {
    return this.http.post(`${this.server}order/calc/`, product, this.options);
  }
  orderConfirm(product):Observable<any> {
    return this.http.post(`${this.newServer}order/create_otc/`, product, this.options).pipe( timeout(500000));
    // .pipe(timeout(1000)).subscribe((res)=>{
    //   return res
    // })
  }

  checkOrderStatus(data):Observable<any> {
    data={...data,
      "request_count":1 
    }    
    return this.http.post(`${this.server}user/CheckOrderStatus/`, data, this.options).pipe(retryWhen(_ => {
      return interval(10000).pipe(
        flatMap(count =>{ 
          if(count == 5){
            data.request_count = 3 ;
          }
          return count == 6 ? throwError("Giving up") : of(count)})
      )
    }))
  }

  getProductByDurafit(product):Observable<any> {
    return this.http.post(`${this.server}list/durafit/parts/`, product, this.options);
  }
  generateGatewayId(data):Observable<any> {
    return this.http.post(`${this.newServer}order/generate_gateway_id/ `, data, this.options);
  }
  getabort_payment(data):Observable<any> {
    return this.http.post(`${this.server}order/abort_payment/`, data, this.options);
  }
  getGatewayList(data):Observable<any>{
    return this.http.post(`${this.server}/api/v2/ecom/order/payment_check/`,data,this.options);
  }
  // Invoice Payment

  getinvoice_payment_status(data):Observable<any> {
    return this.http.post(`${this.newServer}order/invoice_payment_status/`, data, this.options);
  }

  getinvoice_payment_abort(data):Observable<any>{
    return this.http.post(`${this.newServer}order/abort_invoice_payment/`, data, this.options);
  }

  getCashInvoiceStatus(data):Observable<any> {
    return this.http.post(`${this.newServer}order/cash_invoice_tat/`, data, this.options);
  }

  getpartnumberbaseddiscount(data):Observable<any> {
    return this.http.post(`${this.server}order/partnumber_based_discount_calc/`, data,this.options);
  }
  ordercancellation(data):Observable<any> {
    return this.http.post(`${this.newServer}order/cancellation/`, data,this.options);
  }

  getMyVehickleFilterDrp(data):Observable<any> {
    return this.http.post(`${this.server}vehicle/filters/`, data,this.options);
  }

  NeworderCalculation(product):Observable<any> {
    return this.http.post(`${this.server}order/calculation/ `, product, this.options);
  }

  getCartType():Observable<any> {
    return this.http.post(`${this.server}cart/cart_type/list//`, this.options);
  }

  getVehicleList(data):Observable<any> {
    return this.http.post(`${this.newServer}search/one_time_vehicle_list/`, data, this.options);
  }
  getCartDataList(user):Observable<any> {
    return this.http.post(`${this.newServer}cart/cart_list/`, user, this.options);
  }

  async CartDataList(Data :any): Promise<any> {
    return await this.http.post(`${this.newServer}cart/cart_list/`, Data, this.options).toPromise();
  }

  // getSaveForLaterDataList(user):Observable<any>{
  //   return this.http.post(`${this.newServer}cart/save_for_later_list/`, user, this.options);   // get all save item api server call
  // }

  getSeasonalPartsData(req):Observable<any>{
    return this.http.post(`${this.newServer}/account/get_seasonal_part/`, req, this.options)
  }
  order_calculation(product):Observable<any> {
    return this.http.post(`${this.newServer}order/order_calculation/ `, product, this.options);
  }
  async getCartCount(data) {
    return  await this.http.post(`${this.server}cart/cart_configuration/`, data, this.options)
      .toPromise().then(res => {  return res; });
  }  

  getChildPartList(data):Observable<any> {
    return this.http.post(`${this.server}get/get_kit_parts/`, data,this.options);
  }

  UpdateGSTNo(data):Observable<any> {
    return this.http.post(`${this.newServer}account/update_gst_no/`, data,this.options);
  }

  async get_action_based_data(data) {
    return  await this.http.post(`${this.newServer}search/get_action_based_data/`, data, this.options)
      .toPromise().then(res => {  return res; });
  }
  
  get_applicable_model(data):Observable<any> {
    return this.http.post(`${this.newServer}search/get_applicable_model/`, data,this.options);
  }

  addtoWishlist(data):Observable<any>{
    return this.http.post(`${this.newServer}cart/add_to_wishlist/`,data,this.options)
  }
  getWishlist(data):Observable<any>{
    return this.http.post(`${this.newServer}cart/get_wishlist/`,data,this.options)
  }
  deleteWishlistItem(data):Observable<any>{
    return this.http.post(`${this.newServer}cart/remove_wishlist/`,data,this.options)
  }
  
  getOfferDetailList(data):Observable<any>{
    return this.http.post(`${this.newServer}account/get_banner_list/`,data, this.options)
  }
  getCouponList(data):Observable<any>{
    return this.http.post(`${this.newServer}order/get_discount_list/`, data, this.options);
  }
  getSchemeList(data):Observable<any>{
    return this.http.post(`${this.newServer}order/schemes/`,data,this.options);
  }
  getSchemeDetails(data):Observable<any>{
    return this.http.post(`${this.newServer}order/scheme_detail/`,data,this.options);
  }
  getPartSpecificScheme(data):Observable<any>{
    return this.http.post(`${this.newServer}order/scheme_detail/`,data,this.options);
  }

  // Multi seller api 
  get_order_calculation(request):Observable<any>{
    return this.http.post(`${this.newServer}order/order_calculation_v3/`,request, this.options);
  }
  get_cart_list_ms(request):Observable<any>{
    return this.http.post(`${this.newServer}cart/cart_list_v3/`,request,this.options);
  }
  add_to_cart_ms(product):Observable<any> {
    return this.http.post(`${this.newServer}/cart/add_to_cart_v3/`, product, this.options);
  }
  get_part_search_md(data):Observable<any>{
    return this.http.post(`${this.newServer}search/part_search_md/`,data,this.options)
  }
  get_part_details_df(data):Observable<any>{
    return this.http.post(`${this.newServer}search/part_details_df/`,data,this.options)
  }
  get_part_limit_check(data):Observable<any>{
    return this.http.post(`${this.newServer}search/part_limit_check/`,data,this.options)
  }
  get_duplicate_order_alert(data):Observable<any>{
    return this.http.post(`${this.newServer}order/duplicate_order_alert/`,data,this.options)
  }
  getMarketbasket(request):Observable<any>{
    return this.http.post(`${this.newServer}order/market_basket_list/`,request, this.options);
  }
  getMarketbasketDetails(request):Observable<any>{
    return this.http.post(`${this.newServer}order/market_basket_detail/`,request, this.options);
  }
  getMarketBasketList(data):Observable<any>{
    return this.http.post(`${this.newServer}account/get_market_basket_list/`,data,this.options);
  }
  get_part_search_md_2(data):Observable<any>{
    return this.http.post(`${this.newServer}search/part_search_md_v2/`,data,this.options)
  }
}
// https://digital-edukaan-cv-dev-ms.api.tatamotors/api/v2/ecom/account/get_market_basket_list/