<div class="modal-content">
  <div class="modal-header modal-title">
    Offers & Discounts
    <div (click)="modalClose()" type="button"><i class="fa fa-times"></i></div>
  </div>
  <div class="modal-body">
    <div class="table-responsive">
      <table class="table mat-price-table">
        <thead>
          <tr>
            <th>Discount Type</th>
            <th class="text-center">Quantity</th>
            <th class="text-end">Percentage</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let discount of discountOffers">
            <td>
              {{ discount?.owner === 'TML' ? 'Tata Motors Discount' : discount?.owner === 'DLR' ? 'Distributor Discount' : discount?.owner }}
            </td>
          <td class="text-center">{{ discount?.quantity }}</td>
          <td class="text-end percent-color">{{ discount?.percentage + '%'}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
