<div id="add_vehicleModal" class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title mb-0" id="add_vehicleModalLabel">Add Vehicle</h5>
    <button type="button" class="btn-close" (click)="closeModal()"></button>
  </div>
  <div class="modal-body pe-md-5">
    <div [formGroup]="userVehicleForm">
      <div class="form-group">
        <!-- <mat-radio-button value="RegistrationNo" class="fw-bold" (change)="radioChange($event)"
          [checked]="isRegistration">Registration Number</mat-radio-button>&nbsp;
        <mat-radio-button value="ChassisNo" class="fw-bold" (change)="radioChange($event)" [checked]="isChassis">Chassis
          Number</mat-radio-button> -->
          <mat-radio-group name="vehicleList"  fxLayout="column" [(ngModel)]="chosenItem" [ngModelOptions]="{standalone: true}" >
      <mat-radio-button  class="fw-bold newspace mb-2 mb-sm-0"  [checked]="item.checked" [disabled]="item.disabled" *ngFor="let item of vehicleList" [value]="item.name" 
      name="vehicleList"(change)="radioChange($event,item)" >{{ item.name}}</mat-radio-button>
  </mat-radio-group>
      </div><br />
      <div class="form-group" *ngIf="isRegistration">
        <label for="" class="pb-2">Enter Vehicle Registration Number</label>
        <div class="input-validatebtn">
          <input [disabled]="disableRegistrationInput" type="text" class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="typevalue"
          (keyup.enter)="applyFilter()" placeholder="">
          <button type="button" class="btn btn-primary" (click)="applyFilter()">Validate</button>
        </div>
        <label class="verified-msg mt-2 d-flex align-items-center" *ngIf="isApiCalled && typevalue && !errormsg"><img
            src="./assets/images/button-radio-selected-tick.svg" class="img-fluid me-1 icon-verify" />Vehicle
          registration verified</label>
        <label class="unable_verify d-flex align-items-center" *ngIf="errormsg"><img
            src="./assets/images/button-circle-warning.svg" class="img-fluid me-1 icon-verify" />{{errormsg}}</label>
      </div>
      <div class="form-group" *ngIf="isChassis">
        <label for="" class="pb-2">Enter Vehicle Chassis Number</label>
        <div class="input-validatebtn">
          <input type="text" class="form-control" [disabled]="disableChassisInput" [ngModelOptions]="{standalone: true}" [(ngModel)]="typevalue"
          (blur)="applyFilter()" placeholder="">
          <button type="button" class="btn btn-primary" (click)="applyFilter()">Validate</button>
        </div>
        <label class="verified-msg mt-2 d-flex align-items-center" *ngIf="isApiCalled && typevalue && !errorinNo"><img
            src="./assets/images/button-radio-selected-tick.svg" class="img-fluid me-1 icon-verify" />Chassis Number verified</label>
        <label class="unable_verify d-flex align-items-center" *ngIf="errormsg"><img
            src="./assets/images/button-circle-warning.svg" class="img-fluid me-1 icon-verify" />{{errormsg}}</label>
      </div>
      <!-- <div class="form-group" *ngIf="searchedVehicles">
        <label for="">Select a variant</label>
        <select class="form-select" aria-label="variant" formControlName="varient">
          <option selected >Select a Variant</option>
          <option value="{{vehicle.VC_No}}" *ngFor="let vehicle of searchedVehicles">{{vehicle.VC_No}}</option>
      </select>
      </div> --><br />
      <div class="form-group">
        <label for="uploadFile" class="pb-2">Upload copy of <span class="fw-bold">RC Book</span> to add vehicle</label>
        <input class="form-control" type="file" (change)="onFileChange($event)" id="uploadFile">
        <label class="uplad_file_format">( .pdf, .jpg )</label>
        <!-- <div *ngIf="vehicleDetails?.rc_book_doc || imageSrc" class="mat-prod-img me-3" style="
    width: 100%;
    text-align: center;">
          <img src="{{ imageSrc ? imageSrc : (vehicleDetails?.rc_book_doc) ? vehicleDetails?.rc_book_doc : ' '}}"
            class="img-fluid" alt="">
        </div> -->
        <label class="unable_verify d-flex align-items-center" *ngIf="imageSrc">Verification will take upto 2
          hours</label>
      </div>
      <div class="data-type" *ngIf="vehicleDetails?.rc_book_doc">
        <label for="" class="pb-2">RC Book Document :</label>
        <a class="view-all" target="_blank" [href]="vehicleDetails?.rc_book_doc" > View</a>
      </div>
      <div class="v-box border rounded position-relative mt-4" *ngIf="vehicleDetails">
        <div class="v-box-body p-2 d-flex align-items-center">
          <div class="v-box-img">
            <div class="mat-prod-img me-3" style="max-width: 100px;">
              <img src="{{(vehicleDetails?.mod_img_name) ? vehicleDetails?.mod_img_name : ''}}" class="img-fluid"
                alt="">
            </div>
          </div>
          <div class="v-box-content">
            <h5 class="mb-0">{{(vehicleDetails?.model_cat) ? vehicleDetails?.model_cat : ''}}
              {{(vehicleDetails?.registration_no) ? vehicleDetails?.registration_no : ''}}</h5>
            <div class="chahis_number">
              <label *ngIf="vehicleDetails?.chassis_no">Chassis number</label>
              <p class="mb-0 label-data">{{(vehicleDetails?.chassis_no) ? vehicleDetails?.chassis_no : ''}}</p>
            </div>
          </div>
        </div>
      </div>


      <div class="v-box border rounded position-relative mt-4" *ngIf="searchedVehicles">
        <div class="v-box-body p-2 d-flex align-items-center" *ngFor="let vehicle of searchedVehicles">
          <div class="v-box-img">
            <div class="mat-prod-img me-3" style="max-width: 100px;">
              <img src="{{(vehicle?.mod_img_name) ? vehicle?.mod_img_name : ''}}" class="img-fluid" alt="">
            </div>
          </div>
          <div class="v-box-content">
            <h5 class="mb-0">{{(vehicle?.Model_Cat) ? vehicle?.Model_Cat : ''}}
              {{(vehicle?.registration_no) ? vehicle?.registration_no : ''}}</h5>
            <div class="chahis_number">
              <label *ngIf="vehicle?.Chassis_Type">Chassis number</label>
              <p class="mb-0 label-data">{{(vehicle?.Chassis_Type) ? vehicle?.Chassis_Type : ''}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer border-0">
    <button type="button" class="btn btn-outline-primary" (click)="closeModal()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="onSubmit()" [disabled]="submitButtonDisable">Submit</button>
  </div>
</div>

<ng-container *ngIf="showLoader">
  <div class="loader">
    <div class="ng-spinner ng-spinner-icon"></div>
  </div>
</ng-container>