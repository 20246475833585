import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { AddressService } from '../../../../../../src/app/shared/services/REST/address.service';
import { ExcelCreatorService } from '../../../../../../src/app/shared/services/excel-creator.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../../../../../src/app/shared/services/REST/user.service';
import { Subject } from 'rxjs';
import { formatDate } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
declare var $: any;
import { DataTableDirective } from 'angular-datatables';
import { blockPermissionMsg } from '../../../../../../src/app/constant';
import { secureStorage } from '../../../../../../src/app/shared/services/securestorage';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnInit, AfterViewInit, OnDestroy {
  public dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  newRequestBody: any;
  datatsetempty = false;
  dtOptions: DataTables.Settings = {};
  txtstate: any;
  ordertrackingreportForm: FormGroup;
  txtdistributor_name: string;
  filteredOptions: any;
  DistributorData: any;
  stateList = [];
  DataPrepareArray: any;
  myControl = new FormControl();
  filterValue: any;
  options: string[] = [];
  ordertrackingData: any[];
  totalrecord: any;
  inprocesstoDate: any;
  inprocessfromDate: any;
  inprocesstoDate1: any;
  inprocessfromDate1: any;
  today: any;
  showOrders: boolean = false;
  currentPage = 1;
  showLoader: boolean = false;
  partStatusArray = [];
  orderStatusArray = [];
  isOrderInProcessSelected: boolean = false;
  orderInformations: any;
  orderDetailsModal: any;
  selectedPartStatus: any[];
  selectedOrderStatus: any[];
  isFeedBackSelected: boolean = false;
  isOTCSelected: boolean = false;
  isReturn: boolean = false;
  isCancelOrderSelected: boolean = false;
  isActivePage: boolean = false;
  isReport: boolean = true;
  isFormValid: boolean = true;
  @ViewChild('orderDetails', { read: TemplateRef, static: false })
  orderDetails: TemplateRef<any>;
  activeorderPermission;
  showActiveOrderList = false;
  txtOrder_status: any;
  txtpart_status: any;
  userDetails:any
  constructor(
    private addressService: AddressService,
    private excelCreatorService: ExcelCreatorService,
    private router: Router,
    private toastrService: ToastrService,
    private userService: UserService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private localstorage: secureStorage,
    private title:Title
  ) { }
  ngOnInit() {
    // btn_view_order_tracking
    this.userDetails = JSON.parse(this.localstorage.getItem('userData'));
    let mainHeaderMenu: any[];
    if (this.localstorage.getItem('afterLoginMainHeader')) {
      mainHeaderMenu = JSON.parse(this.localstorage.getItem('afterLoginMainHeader'));
    }
    for (let i = 0; i <= mainHeaderMenu.length; i++) {
      if (mainHeaderMenu[i] == 'REPORTS') {
        this.showActiveOrderList = true;
      }
    }
    $(function () {
      $('.filter-btn').on('click', function () {
        $('.filter-fixed-sm').toggleClass('show');
      });
      $('.close-filter').on('click', function () {
        $('.filter-fixed-sm').removeClass('show');
      })
    });
    // this.orderstatus = ["In-Process", "Failure"]
    this.partStatusArray = [
      { statusName: 'Fully Invoiced', isChecked: false },
      { statusName: 'Partially Invoiced', isChecked: false },
      { statusName: 'In-Process', isChecked: false },
      { statusName: 'New', isChecked: false },
      { statusName: 'Cancelled', isChecked: false },
    ];
    this.orderStatusArray = [
      { statusName: 'Confirmed', isChecked: false },
      { statusName: 'Partially Confirmed', isChecked: false },
      { statusName: 'In-Process', isChecked: false },
      { statusName: 'Cancelled', isChecked: false },
      { statusName: 'Failure', isChecked: false },
    ];
    this.today = new Date();
    //this.openmodel();
    // this.appStateService.refreshTimelineList.next(null);
    this.buildForm();
    this.getStates();
    this.loadData();
    this.title.setTitle('E-Dukaan | Order Tracking');
  }
  openmodel() {
    $(document).ready(function () {
      $('.datatable').DataTable({
        dom: 't<"bottom"><"clear">',
        scrollX: true,
      });
    });
  }
  buildForm() {
    this.txtdistributor_name = '';
    // var enddt = new Date(); // today!
    // var x = 30; // go back 30 days!
    // enddt.setDate(enddt.getDate() - x);

    // var startdate = enddt;
    // var  enddate= this.today;

    this.ordertrackingreportForm = this.fb.group({
      // 'start': [startdate, [ Validators.required]],
      // 'end': [enddate, [Validators.required]],
      start: ['', [Validators.required]],
      state: ['', [Validators.required]],
      end: ['', [Validators.required]],
      otc_number: ['', [Validators.required]],
      part_number: ['', [Validators.required]],
      edukan_order_no: ['', [Validators.required]],
      status: ['', [Validators.required]],
      order_status: ['', [Validators.required]],
      distributor_name: ['', [Validators.required]],
    });

    // this.filterValue = null;
    // this.ordertrackingreportForm.value.distributor_name.valueChanges.subscribe(value => {
    //     this._filter(value)
    // });
    let user = JSON.parse(this.localstorage.getItem('userData'));
    if (user) {
      const data: InputData = {} as InputData;
      data.account_pk = user.account_pk;
      data.size = 10;
      data.org_search_text = '';
      this.GetDistributor(data);
    }
  }
  loadData() {
    try {
      if (
        this.ordertrackingreportForm.value.start &&
        this.ordertrackingreportForm.value.end &&
        !(
          this.ordertrackingreportForm.value.end >=
          this.ordertrackingreportForm.value.start
        )
      ) {
        return this.toastrService.error(
          'To date should be greater than or equal to From date'
        );
      } else if (
        this.ordertrackingreportForm.value.start &&
        !this.ordertrackingreportForm.value.end
      ) {
        this.isFormValid = false;
        return this.toastrService.error('To date is required');
      } else if (
        !this.ordertrackingreportForm.value.start &&
        this.ordertrackingreportForm.value.end
      ) {
        this.isFormValid = false;
        return this.toastrService.error('From date is required');
      } else {
        this.isFormValid = true;
      }
      if (this.ordertrackingreportForm.value.start) {
        this.inprocessfromDate = new Date(
          this.ordertrackingreportForm.value.start
        );
        this.inprocessfromDate1 = formatDate(
          this.inprocessfromDate,
          'yyyy-MM-dd',
          'en-US'
        );
      }
      if (this.ordertrackingreportForm.value.end) {
        this.inprocesstoDate = new Date(this.ordertrackingreportForm.value.end);
        this.inprocesstoDate1 = formatDate(
          this.inprocesstoDate,
          'yyyy-MM-dd',
          'en-US'
        );
      }

      this.showOrders = false;
      let user = JSON.parse(this.localstorage.getItem('userData'));
      if (user) {
        if (user && user.account_pk) {
          // var distbname = this.ordertrackingreportForm.value.distributor_name
          // var distbid = "";
          // if (distbname) {
          //     var datadist = this.DistributorData.filter(a => a.distributor_name == distbname);
          //     distbid = datadist[0].distributor_id
          // }

          this.calldatatable();
          // // this.ordertrackingData = [];
          // // this.showLoader = true;
          // // this.userService.orderTrackingReport({
          // //   account_pk: '' + user.account_pk,
          // //   "account_id": '' + user.account_id,
          // //   "offset": (page * 10),
          // //   "size": 10,
          // //   // "start": (page * 10),
          // //   // "end": (page * 10) + 10,
          // //   "otc_number": this.ordertrackingreportForm.value.otc_number ? this.ordertrackingreportForm.value.otc_number.trim() : "",
          // //   "edukan_order_no": this.ordertrackingreportForm.value.edukan_order_no ? this.ordertrackingreportForm.value.edukan_order_no.trim() : "",
          // //   "part_number": this.ordertrackingreportForm.value.part_number ? this.ordertrackingreportForm.value.part_number.trim() : "",
          // //   "status": this.ordertrackingreportForm.value.status ? this.ordertrackingreportForm.value.status.trim() : "",
          // //   "order_status": this.ordertrackingreportForm.value.order_status ? this.ordertrackingreportForm.value.order_status.trim() : "",
          // //   //"distributor_id": distbid ? distbid : "",
          // //   "org_name": this.ordertrackingreportForm.value.distributor_name ? this.ordertrackingreportForm.value.distributor_name : "",
          // //   "state": this.ordertrackingreportForm.value.state.code ? this.ordertrackingreportForm.value.state.code.trim() : "",
          // //   "div_id": "",
          // //   "invoice_no": "",
          // //   "rating": "",
          // //   "from_date": this.inprocessfromDate1 ? this.inprocessfromDate1 : "",
          // //   "to_date": this.inprocesstoDate1 ? this.inprocesstoDate1 : "",
          // // })
          // //   .subscribe(response => {
          // //     this.showOrders = true;
          // //     if (response.Success) {
          // //       this.ordertrackingData = response.data
          // //       this.totalrecord = response.total_element;
          // //       if(!isFilter){
          // //       this.openmodel();
          // //       }
          // //     } else {
          // //       this.toastrService.error(response.data.msg);
          // //       this.ordertrackingData = []
          // //     }
          // //     this.showLoader = false;
          // //   })
        }
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'loadData(page)',
        page_name: 'OrderTrackingReportComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }
  detail(order) {
    this.router.navigateByUrl(`account/orders/${order.otc_number}`, order);
  }
  pageChange(page) {
    document.body.scrollTop = 0;
    this.currentPage = page;
    page = page - 1;
    this.loadData();
    this.rerender();
  }
  applyInprocessFilter(formControl) {
    if (
      (formControl.value !== '' &&
        formControl.value !== null &&
        formControl.value !== undefined) ||
      formControl == 'Global'
    ) {
      this.currentPage = 1;
      this.loadData();
      this.rerender();
    }
  }
  reset() {
    // this.orderstatus = [];
    this.inprocessfromDate = '';
    this.inprocessfromDate1 = '';
    this.inprocesstoDate = '';
    this.inprocesstoDate1 = '';
    this.txtdistributor_name = '';
    this.txtpart_status = '';
    this.txtOrder_status = '';
    this.txtstate = '';
    this.ordertrackingreportForm.reset();
    this.partStatusArray = [
      { statusName: 'Fully Invoiced', isChecked: false },
      { statusName: 'Partially Invoiced', isChecked: false },
      { statusName: 'In-Process', isChecked: false },
      { statusName: 'New', isChecked: false },
      { statusName: 'Cancelled', isChecked: false },
    ];
    this.orderStatusArray = [
      { statusName: 'Confirmed', isChecked: false },
      { statusName: 'Partially Confirmed', isChecked: false },
      { statusName: 'In-Process', isChecked: false },
      { statusName: 'Cancelled', isChecked: false },
      { statusName: 'Failure', isChecked: false },
    ];
    this.buildForm();
    this.loadData();
    this.rerender();
  }
  _filter(value) {
    let user = JSON.parse(this.localstorage.getItem('userData'));
    if (user) {
      this.filterValue = value;
      if (this.filterValue == '') {
        this.filterValue = null;
      }
      const data: InputData = {} as InputData;
      data.account_pk = user.account_pk;
      data.size = 10;
      data.org_search_text = this.filterValue;
      this.GetDistributor(data);
      return this.options.filter((option) =>
        option.toLowerCase().includes(this.filterValue)
      );
    }
  }
  filterMyOptions(Event) {
    if (this.localstorage.getItem('userData')) {
      let user = JSON.parse(this.localstorage.getItem('userData'));
      this.myControl.setValue('');
      var distlist = this.DistributorData.filter(
        (a) => a.distributor_id == Event
      );
      this.txtdistributor_name = distlist[0].distributor_name;
      this.filterValue = Event;
      const data: InputData = {} as InputData;
      data.distributor_id = Event;
      data.account_pk = user.account_pk;
      data.size = 100;
      data.org_search_text = '';
      this.GetDistributor(data);
    }
  }
  GetDistributor(Data) {
    this.userService.DistributorList(Data).subscribe(
      (data) => {
        if (data.success == true) {
          this.DistributorData = data.data.result;
          this.filteredOptions = data.data.result;
        } else {
        }
      },
      (err) => { }
    );
  }
  async DownloadReport() {
    try {
      this.showLoader = true;
      this.DataPrepareArray = [];
      if (
        this.ordertrackingreportForm.value.start &&
        this.ordertrackingreportForm.value.end &&
        !(
          this.ordertrackingreportForm.value.end >=
          this.ordertrackingreportForm.value.start
        )
      ) {
        return this.toastrService.error(
          'To date should be greater than or equal to From date'
        );
      }
      if (this.ordertrackingreportForm.value.start) {
        this.inprocessfromDate = new Date(
          this.ordertrackingreportForm.value.start
        );
        this.inprocessfromDate1 = formatDate(
          this.inprocessfromDate,
          'yyyy-MM-dd',
          'en-US'
        );
      }
      if (this.ordertrackingreportForm.value.end) {
        this.inprocesstoDate = new Date(this.ordertrackingreportForm.value.end);
        this.inprocesstoDate1 = formatDate(
          this.inprocesstoDate,
          'yyyy-MM-dd',
          'en-US'
        );
      }

      this.showOrders = false;
      let user = JSON.parse(this.localstorage.getItem('userData'));
      if (user) {
        if (user && user.account_pk) {
          await this.userService
            .orderTrackingReport({
              account_pk: '' + user.account_pk,
              account_id: '' + user.account_id,
              offset: 0,
              size: this.totalrecord,
              otc_number: this.ordertrackingreportForm.value.otc_number
                ? this.ordertrackingreportForm.value.otc_number.trim()
                : '',
              edukan_order_no: this.ordertrackingreportForm.value
                .edukan_order_no
                ? this.ordertrackingreportForm.value.edukan_order_no.trim()
                : '',
              part_number: this.ordertrackingreportForm.value.part_number
                ? this.ordertrackingreportForm.value.part_number.trim()
                : '',
              status: this.ordertrackingreportForm.value.status
                ? this.ordertrackingreportForm.value.status.trim()
                : '',
              order_status: this.ordertrackingreportForm.value.order_status
                ? this.ordertrackingreportForm.value.order_status.trim()
                : '',
              org_name: this.ordertrackingreportForm.value.distributor_name
                ? this.ordertrackingreportForm.value.distributor_name
                : '',
              state: this.ordertrackingreportForm.value.state.code
                ? this.ordertrackingreportForm.value.state.code.trim()
                : '',
              div_id: '',
              invoice_no: '',
              rating: '',
              from_date: this.inprocessfromDate1 ? this.inprocessfromDate1 : '',
              to_date: this.inprocesstoDate1 ? this.inprocesstoDate1 : '',
            })
            .subscribe((response) => {
              this.showOrders = true;
              if (response.Success) {
                this.showLoader = false;
                for (let entry of response.data) {
                  const ListInput: FinalData = {} as FinalData;
                  ListInput.OTC_No = entry.otc_number;
                  ListInput.Edukaan_Order_no = entry.edukan_orders;
                  ListInput.Po_No = entry.po_no;
                  ListInput.Month_Year = entry.month_year;
                  ListInput.State = entry.state;
                  ListInput.Distributor_name = entry.distributor_name;
                  ListInput.Order_Status = entry.order_status;
                  ListInput.Order_Amount = '₹ ' + entry.order_amount;
                  ListInput.Coupon_Code = entry.coupon_code?entry.coupon_code:'NA';
                  ListInput.Coupon_Amount = '₹ ' + entry.flat_discount;
                  ListInput.Order_Date = entry.order_date; //  this.datepipe.transform(entry.order_date, 'dd-MM-yyyy')//entry.order_date
                  ListInput.Part_No = entry.part_number;
                  ListInput.Part_Desc = entry.part_desc;
                  ListInput.Part_Status = entry.status;
                  ListInput.Order_Quantity = entry.order_quantity;
                  ListInput.Pending = entry.pending;
                  ListInput.shipped_quantity = entry.shipped_quantity;
                  ListInput.Invoiced_but_not_dispatched =
                    entry.invoiced_but_not_dispatched;
                  ListInput.Out_For_Delivery_Delivery = entry.out_for_delivery;
                  ListInput.Delivered = entry.delivered;
                  ListInput.Cancelled = entry.cancelled_order;
                  ListInput.Total_Inoviced_QTY = entry.total_invoiced_qty;

                  ListInput.SortDate = entry.order_date;
                  this.DataPrepareArray.push(ListInput);
                }
                if (this.DataPrepareArray.length == this.totalrecord) {
                  this.DataPrepareArray.sort((a: any, b: any) => {
                    return Date.parse(b.SortDate) - Date.parse(a.SortDate);
                  });
                  this.DataPrepareArray.forEach(function (x) {
                    delete x.SortDate;
                  });
                }
                this.excelCreatorService.exportAsExcelFileVehicleStatus(
                  this.DataPrepareArray,
                  'OrderTracking'
                );
                (<HTMLInputElement>(
                  document.getElementById('selectexceloptionreport')
                )).value = 'Select';
              } else {
                this.showLoader = false;
                this.toastrService.error(response.data.msg);
              }
              this.showLoader = false;
            });
        }
      }
    } catch (error) {
      this.showLoader = false;
      this.userService.InsertExceptionLog({
        method_name: 'DownloadReport()',
        page_name: 'OrderTrackingReportComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }
  OnselectedPartStatus(partstatus) {
    this.txtpart_status = partstatus;
  }
  OnselectedOrderStatus(orderstatus) {
    this.txtOrder_status = orderstatus;
  }
  Onselectedstate(state) {
    this.txtstate = state.name;
  }
  getStates() {
    try {
      this.addressService.getStates().subscribe((response) => {
        if (response) {
          this.stateList = response;
        }
      });
    } catch (error) {
      this.userService.InsertExceptionLog({
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'PageEditAddressComponent',
        method_name: 'getStates()',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }
  downloadExcel() {
    // if (event.target.value == 'Excel') {
      this.DownloadReport();
    // }
  }
  bindStatus(event, status, formControl, filterType) {
    if (event.target.checked) {
      formControl.setValue(status);
    } else {
      formControl.setValue('');
    }
  }
  details(order, type) {
    let permission = this.userService.CheckPermission(
      'btn_view_order_tracking'
    );

    if (permission) {
      order.order_date = order.ord_date;
      this.orderInformations = order;
      if (type == 'isReport') {
        this.isReport = true;
        this.isOrderInProcessSelected = false;
        this.isFeedBackSelected = false;
        this.isOTCSelected = false;
        this.isReturn = false;
        this.isCancelOrderSelected = false;
        this.isActivePage = false;
      }
      if (order.otc_number != 'NA') {
        this.orderDetailsModal = this.modalService.show(this.orderDetails, {
          class: 'modal-dialog-centered modal-xl',
          backdrop: 'static',
        });
      }
    } else {
      this.toastrService.error(blockPermissionMsg);
    }
  }
  calldatatable() {
    const that = this;
    let user = JSON.parse(this.localstorage.getItem('userData'));
    if (user && user.account_pk) {
      this.newRequestBody = {
        account_pk: '' + user.account_pk,
        account_id: '' + user.account_id,
        // offset: page * 10,
        size: 10,
        // "start": (page * 10),
        // "end": (page * 10) + 10,
        otc_number: this.ordertrackingreportForm.value.otc_number
          ? this.ordertrackingreportForm.value.otc_number.trim()
          : '',
        edukan_order_no: this.ordertrackingreportForm.value.edukan_order_no
          ? this.ordertrackingreportForm.value.edukan_order_no.trim()
          : '',
        part_number: this.ordertrackingreportForm.value.part_number
          ? this.ordertrackingreportForm.value.part_number.trim()
          : '',
        status: this.ordertrackingreportForm.value.status
          ? this.ordertrackingreportForm.value.status.trim()
          : '',
        order_status: this.ordertrackingreportForm.value.order_status
          ? this.ordertrackingreportForm.value.order_status.trim()
          : '',
        //"distributor_id": distbid ? distbid : "",
        org_name: this.ordertrackingreportForm.value.distributor_name
          ? this.ordertrackingreportForm.value.distributor_name
          : '',
        state: this.ordertrackingreportForm.value.state.code
          ? this.ordertrackingreportForm.value.state.code.trim()
          : '',
        div_id: '',
        invoice_no: '',
        rating: '',
        from_date: this.inprocessfromDate1 ? this.inprocessfromDate1 : '',
        to_date: this.inprocesstoDate1 ? this.inprocesstoDate1 : '',
      };
      this.showLoader = true;
      this.dtOptions = {
        pagingType: 'full_numbers',
        responsive: true,
        serverSide: true,
        processing: true,
        lengthChange: false,
        searching: false,
        pageLength: 10,
        ordering: false,
        scrollX: true,
        language: {
          info: 'Showing _START_ to _END_ of _TOTAL_ entries',
          infoEmpty: 'No records available',
          // infoFiltered: ' ',
        },
        ajax: (dataTablesParameters: any, callback) => {
          if (this.isFormValid) {
            this.newRequestBody['offset'] = dataTablesParameters.start;

            this.userService.orderTrackingReport(this.newRequestBody).subscribe(
              (response) => {
                window.scrollTo(0, 0);
                if (response.Success) {
                  this.showLoader = false;
                  this.ordertrackingData = response.data;

                  this.totalrecord = response.total_element;
                } else if (response.success == false) {
                  this.totalrecord = 0;
                  this.ordertrackingData = [];
                  if (response.data.msg == 'Data not found') {
                    this.datatsetempty = true;
                  }

                  // this.toastrService.error(response.data.msg);
                } else {
                  this.totalrecord = 0;
                  this.ordertrackingData = [];
                  if (response.data.msg == 'Data not found') {
                    this.datatsetempty = true;
                  }

                  this.toastrService.error(response.data.msg);
                }
                this.showLoader = false;

                callback({
                  recordsTotal: this.totalrecord,
                  recordsFiltered: this.totalrecord,
                  data: [],
                });
              },
              (err) => {
                this.showLoader = false;
              }
            );
          }
        },
        columns: [
          {
            data: 'otc_number',
            cellType: 'th/td',
            className: 'sticky-col first-col',
            width: '170px',
          },
          { data: 'edukan_orders', width: '145px' },
          { data: 'month_year', width: '5px' },
          { data: 'po_number' },
          { data: 'state', width: '30px' },
          { data: 'distributor_name', width: '195px' },
          { data: 'status' },
          { data: 'order_amount', width: '80px' },
          { data: 'coupon_code', width: '80px' },
          { data: 'coupon_amount', width: '80px' },
          { data: 'order_date', width: '120px' },
          { data: 'part_number', width: '120px' },
          { data: 'part_desc', width: '240px' },
          { data: 'order_status' },
          { data: 'order_quantity' },
          { data: 'pending' },
          { data: 'shipped_quantity' },
          { data: 'invoiced_but_not_dispatched' },
          { data: 'out_for_delivery' },
          { data: 'delivered' },
          { data: 'cancelled_order' },
          { data: 'total_invoiced_qty' },
        ],
      };
    }
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }
  ngAfterViewInit() {
    this.dtTrigger.next();
  }
}
export class InputData {
  account_pk: number;
  org_search_text: string;
  distributor_id: string;
  offset: number;
  size: number;
}
export interface FinalData {
  Total_Inoviced_QTY: String;
  Cancelled: String;
  Delivered: String;
  Out_For_Delivery_Delivery: String;
  Invoiced_but_not_dispatched: String;
  Pending: String;
  Order_Quantity: String;
  shipped_quantity: String;
  Part_Status: String;
  Part_Desc: String;
  Part_No: String;
  Po_No:string;
  Order_Amount: String;
  Coupon_Code:string;
  Coupon_Amount:string
  Order_Date: String;
  OTC_No: String;
  Order_Status: String;
  Edukaan_Order_no: String;
  Distributor_name: String;
  State: String;
  Month_Year: String;
  SortDate: String;
}
