<div class="profile-page-sec pt-5">
  <!-- <h5 class="profile-heading mb-3" style="margin: 12.5px;font-size: 22px;">My Profile</h5> -->
  <div class="container-fluid">
    <!-- <ul class="nav nav-tabs tabs1" id="user-tab" role="tablist">
            <li class="nav-item cursor" role="presentation" (click)="changeTab('Profile')">
                <a [ngClass]="(isMyProfile) ? 'nav-link active' : 'nav-link'" data-bs-toggle="tab" role="tab"
                  aria-controls="profile" aria-selected="true">Profile Management</a>
              </li>
              <li class="nav-item cursor" role="presentation" (click)="changeTab('Account')">
                <a [ngClass]="(isAccount) ? 'nav-link active' : 'nav-link'" data-bs-toggle="tab" role="tab"
                  aria-controls="inprocess" aria-selected="true">Account Management</a>
              </li>
              <li class="nav-item cursor" role="presentation" (click)="changeTab('userManagement')">
                <a [ngClass]="(isUserManagement) ? 'nav-link active' : 'nav-link'" data-bs-toggle="tab" role="tab" aria-controls="contact"
                  aria-selected="false">User Management</a>
              </li>
              <li class="nav-item cursor" role="presentation" (click)="changeTab('userPermission')">
                <a [ngClass]="(isUserPermission) ? 'nav-link active' : 'nav-link'" data-bs-toggle="tab" role="tab" aria-controls="contact"
                  aria-selected="false">User Permission</a>
              </li>
        </ul> -->
    <!-- <h5 class="profile-heading mb-3">Account Management</h5>
        <h5 class="profile-heading mb-3">User Management</h5>
        <h5 class="profile-heading mb-3">User Permission</h5> -->

    <h5 class="profile-heading mb-3" *ngIf="isProfilePermission">My Profile&nbsp;&nbsp;<a class="mat-hsn-name"
        (click)="redirectToMyVehicles();" matTooltip="Go to My Vehicles List">My Vehicles List&nbsp;&darr;</a></h5>
    <ng-container>
      <div class="row">
        <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6">
          <div class="card profile-card shadow border-0" *ngIf="isProfilePermission">

            <div class="card-body">
              <div class="d-flex flex-wrap d-sm-inline-flex align-items-center">
                <ng-container *ngIf="userdatas?.account_name; else elseTemplate">
                  <div class="media d-flex d-sm-inline-flex">
                    <div class="media-left me-3">
                      <div class="user-profile-img">
                        <img src="./assets/images/user_png.png" class="img-fluid" />
                      </div>
                    </div>
                    <div class="media-body profile-content">
                      <h6 class="profile-name">
                        {{ userdatas?.account_name }}<br />
                        <!-- <span class="user_store_name">Sahani Motors Pvt. Ltd</span><br/> -->
                        <span
                          class="user_code">{{ userdatas?.account_id }}{{ ' ( ' + userdatas?.userRole + ' )'}}</span>
                      </h6>
                      <p class="user_contact">
                        <img src="./assets/images/phone.svg" class="mobile_img me-2" />
                        {{ userdatas?.contact_no }}
                      </p>
                      <!-- <p class="user_mail"><img src="./assets/images/email.svg" class="email_img me-2"/> jassi.singh@gmail.com</p> -->
                    </div>
                    <div class="default-address ps-4">
                      <div class="border p-2 text-success d-addr-heading mb-4">
                        <i class="fas fa-check-circle me-2"></i> DEFAULT ADDRESS
                      </div>
                      <!-- <div class="border p-2 d-addr-heading mb-4" style="cursor: pointer;" [routerLink]="['/account/address']">MY ADDRESSES</div> -->
                      <div class="">
                        <div class="
                          d-flex
                          justify-content-between
                          align-items-center
                          mb-2
                        " id="myVehicles">
                          <h4 class="mb-0 mat-store-name text-break">
                            {{ userdatas?.address_1 }}
                          </h4>
                        </div>
                        <p class="mat-store-address text-break">
                          {{ userdatas?.address_2 }} {{ userdatas?.address_3 }}
                          {{ userdatas?.state }} {{ userdatas?.district }}
                          {{ userdatas?.city }} {{ userdatas?.pincode }}
                        </p>
                        <!-- <p class="mb-0 mat-gst">GST No. - </p> -->
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-template #elseTemplate>
                  <ng-container *ngIf="!userdatas?.account_name else noRecordsBlock">
                    <div class="media d-flex d-sm-inline-flex">
                      <div class="media-left me-3">
                        <div class="user-profile-img">
                          <skc-rectangle [loading]="loading" [skc-height]="'123px'" [skc-width]="'100%'">
                          </skc-rectangle>
                        </div>
                      </div>
                      <div class="media-body profile-content">
                        <h6 class="profile-name">
                          <skc-rectangle [loading]="loading" [skc-height]="'48px'" [skc-width]="'215px'">
                          </skc-rectangle>
                        </h6>
                        <p class="user_contact">
                          <skc-rectangle [loading]="loading" [skc-height]="'23px'" [skc-width]="'100%'">
                          </skc-rectangle>
                        </p>
                      </div>
                      <div class="default-address ps-4">
                        <div class="border p-2 text-success d-addr-heading mb-4">
                          <skc-rectangle [loading]="loading" [skc-height]="'17px'" [skc-width]="'131px'">
                          </skc-rectangle>
                        </div>

                        <div class="">
                          <div class="
                              d-flex
                              justify-content-between
                              align-items-center
                              mb-2
                            ">
                            <h4 class="mb-0 mat-store-name text-break">
                              <skc-rectangle [loading]="loading" [skc-height]="'22px'" [skc-width]="'144px'">
                              </skc-rectangle>
                            </h4>
                          </div>
                          <p class="mat-store-address text-break">
                            <skc-rectangle [loading]="loading" [skc-height]="'40px'" [skc-width]="'100%'">
                            </skc-rectangle>
                          </p>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ng-template>
                <ng-template #noRecordsBlock>

                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div class="card Vehicle-card mt-5 mb-3 border-0 shadow" id="topPosition" *ngIf="isVehiclePermission">
            <div class="card-body" *ngIf="isBulkVehicleUpload">
              <div class="d-flex justify-content-end align-items-center" style="flex-wrap: wrap;">
                <h5 class="v-card-heading me-auto mb-0 pe-3">My Vehicles</h5>
                <div class="cta">
                  <a class="btn btn-outline-primary me-3 mt-3 mt-sm-0" (click)="deleteVehicleRequest()"
                    *ngIf="vehicleinfo.length > 0">REMOVE VEHICLE</a>
                  <a class="btn btn-primary me-3 mt-3 mt-sm-0" (click)="addvehicle()"><img
                      src="./assets/images/plus-white.svg" class="add_icon" />ADD VEHICLE</a>
                  <a class="btn btn-primary mt-3 mt-sm-0" (click)="changeBody()"><img
                      src="./assets/images/plus-white.svg" class="add_icon" />BULK VEHICLE UPLOAD</a>
                </div>
              </div>
              <!-- <div *ngIf="vehicle.length > 0"> -->
              <div>
                <div id="Vehicleslist">
                  <!-- <div
                    class="item v-box rounded position-relative"
                    *ngFor="let obj of vehicle"
                  >
                    <div
                      [ngClass]="
                        obj?.model_cat == vehicleinfo[0]?.model_cat
                          ? 'v-box-body border p-3 m-2 d-flex align-items-center select-cat position-relative'
                          : 'v-box-body border p-3 m-2 d-flex align-items-center position-relative'
                      "
                      class=""
                      (click)="getvehicle(obj,null,true)"
                    >
                      <div class="v-box-img">
                        <img
                          src="{{ obj?.model_img_name }}"
                          class="img-fluid"
                          alt=""
                          onerror="this.src='./assets/tata-vehicle.jfif';"
                        />
                      </div>
                      <div class="v-box-content">
                        <h5 class="mb-0">{{ obj?.model_cat }}</h5>
                        <p class="mb-0">{{ obj?.count + " Vehicles" }}</p>
                      </div>
                    </div>
                  </div> -->
                  <ng-container *ngIf="vehicle?.length; else ModelList">
                    <owl-carousel-o [options]="customOptionsForModels">
                      <ng-container *ngFor="let obj of vehicle;let i=index;">
                        <ng-template carouselSlide [id]="obj?.model_cat">
                          <div class="item v-box rounded position-relative">
                            <div [ngClass]="
                        obj?.model_cat == vehicleinfo[0]?.model_cat
                          ? 'v-box-body border p-3 m-2 d-flex align-items-center select-cat position-relative'
                          : 'v-box-body border p-3 m-2 d-flex align-items-center position-relative'
                      " class="" (click)="getvehicle(obj,null,true)">
                              <div class="v-box-img">
                                <img src="{{ obj?.model_img_name + '/resize/53x40' }}" class="img-fluid" alt=""
                                  onerror="this.src='./assets/tata-vehicle.jfif';" />
                              </div>
                              <div class="v-box-content">
                                <h5 class="mb-0">{{ obj?.model_cat }}</h5>
                                <p class="mb-0">{{ obj?.count + " Vehicles" }}</p>
                              </div>
                            </div>
                          </div>
                        </ng-template>
                      </ng-container>
                    </owl-carousel-o>
                  </ng-container>
                  <ng-template #ModelList>
                    <ng-container *ngIf="!isVehicleEmpty && !vehicle?.length else noRecordsVehicleBlock">
                      <owl-carousel-o [options]="customOptionsForModels">
                        <ng-container *ngFor="let item of userService.generateFake(10);let i=index;">
                          <ng-template carouselSlide [id]="i">
                            <div class="item v-box rounded position-relative">
                              <div class="v-box-body border p-3 m-2 d-flex align-items-center position-relative">
                                <div class="v-box-img">
                                  <skc-rectangle [loading]="loading" [skc-height]="'40px'" [skc-width]="'53px'">
                                  </skc-rectangle>
                                </div>
                                <div class="v-box-content">
                                  <skc-rectangle [loading]="loading" [skc-height]="'35px'" [skc-width]="'90px'">
                                  </skc-rectangle>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                        </ng-container>
                      </owl-carousel-o>
                    </ng-container>
                  </ng-template>
                  <ng-template #noRecordsVehicleBlock>
                    <!-- <div style="text-align: center" *ngIf="isVehicleEmpty">
                      <h6>No Record Found</h6>
                    </div>  -->
                  </ng-template>
                </div>
                <!-- Filtter Area-->
                <div class="filter-area d-flex justify-content-start mb-4">
                  <form [formGroup]="userVehicleForm">
                    <div class="input-area d-flex flex-wrap">
                      <div class="search-box filter_box position-relative">
                        <input type="search" [formControl]="userVehicleForm.controls['searchText']" (keyup)="
                            filterData(userVehicleForm.controls['searchText'])
                          " (keyup.enter)="
                            filterData(userVehicleForm.controls['searchText'])
                          " (search)="clearsearch($event,userVehicleForm.controls['searchText'])"
                          class="form-control input_search ps-4" placeholder="Type a keyword to search" />
                        <img alt="" src="./assets/images/search.svg" />
                      </div>
                      <!-- <div class="selection-box filter_box">
                                            <select class="form-select" aria-label="years">
                                                <option selected>Years</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                        <div class="selection-box filter_box">
                                            <select class="form-select" aria-label="models">
                                                <option selected>Models</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                        <div class="selection-box filter_box">
                                            <select class="form-select" aria-label="type_chasis">
                                                <option selected>All Chasis Types</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div> -->
                    </div>
                  </form>

                  <div class="filter_grid ms-auto" *ngIf="vehicleinfo?.length;">
                    <button class="btn btn-link" (click)="GotoView('card')" *ngIf="layout == 'card'">
                      <img src="./assets/images/card.svg" alt="icon" srcset="" />
                    </button>
                    <button class="btn btn-link" (click)="GotoView('card')" *ngIf="layout == 'list'">
                      <img src="./assets/images/card-inactive.svg" alt="icon" srcset="" />
                    </button>
                    <button class="btn btn-link" (click)="GotoView('list')" *ngIf="layout == 'card'">
                      <img src="./assets/images/list.svg" alt="icon" srcset="" />
                    </button>
                    <button class="btn btn-link" (click)="GotoView('list')" *ngIf="layout == 'list'">
                      <img src="./assets/images/list-active.svg" alt="icon" srcset="" />
                    </button>
                  </div>
                </div>
                <!--Filtter Data card-->
                <div class="row filter_data_cards" *ngIf="layout === 'card'">
                  <ng-container *ngIf="vehicleinfo?.length; else elseVehicleInfoTemplate">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxl-3 mb-3"
                      *ngFor="let VCobj of vehicleinfo">
                      <div class="card" style="height: 100%;">
                        <div class="card-body d-flex p-2">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="{{ VCobj }}" id="1"
                              [(ngModel)]="VCobj.isSelected" value="{{VCobj.id}}" (change)="isAllSelected()" />
                          </div>
                          <div class="filter-data-content d-grid">
                            <h5 style="cursor: pointer" (click)="viewPartsForSelectedVehicle(VCobj,VCobj?.registration_no)" matTooltip="View Parts For {{VCobj?.registration_no}}" *ngIf="VCobj?.registration_no;else elseBlockRegistration">
                              {{ VCobj?.registration_no }}
                            </h5>
                            <ng-template #elseBlockRegistration>
                              <h5 style="cursor: pointer">
                                {{ VCobj?.registration_no }}
                              </h5>
                            </ng-template>
                            <div class="d-flex flex-wrap align-items-center model_part_type">
                              <div class="data-type">
                                <h6>Chassis type</h6>
                                <p style="cursor: pointer;color: #417be2 !important;" (click)="viewPartsForSelectedVehicle(VCobj,VCobj?.chassis_no)" matTooltip="View Parts For {{VCobj?.chassis_no}}" *ngIf="VCobj?.chassis_no; else elseBlockChassis">
                                  {{ VCobj?.chassis_no || "-" }}
                                </p>
                                <ng-template #elseBlockChassis>
                                  <p style="cursor: pointer">
                                    {{ "-" }}
                                  </p>
                                </ng-template>
                              </div>
                              <div class="data-type">
                                <h6>Year</h6>
                                <p>{{ VCobj?.created_at | date: "yyyy" || "-" }}</p>
                              </div>
                              <div class="data-type">
                                <h6>Model</h6>
                                <p>{{ VCobj?.model_cat || "-" }}</p>
                              </div>
                              <div class="data-type">
                                <h6>Model Description</h6>
                                <p class="ellipse-2line" style="height: 45px" title="{{ VCobj?.model_desc == '' || VCobj?.model_desc == null ? '-' : VCobj?.model_desc }}">
                                  {{ VCobj?.model_desc == " " || VCobj?.model_desc == null ? "-" : VCobj?.model_desc?.substr(0, 28) || "-" }}
                                </p>
                              </div>
                              <div class="data-type">
                                <h6>Engine type</h6>
                                <p class="ellipse-2line" style="height: 45px; font-size: 12px">
                                  {{ VCobj?.engine_type || "-" }}
                                </p>
                              </div>
                              <div class="data-type" *ngIf="VCobj?.rc_book_doc !== null">
                                <h6>RC Book Document</h6>
                                <!-- <a class="view-all" (click)="openrcpopup(VCobj)">View</a> -->
                                <a class="view-all" target="_blank" [href]="VCobj?.rc_book_doc"> View</a>
                              </div>
                              <div class="data-type">
                                <a class="view-all" (click)="addvehicle(VCobj)" matTooltip="Upload RC Book Document">Upload RC Book Document</a>
                              </div>
                              <div class="data-type" class="hide-for-mobile">
                                <a class="view-all hide-for-mobile"(click)="downloadVehicleParts(VCobj)" matTooltip="Download Part Catalogue" target="_blank">Download Part Catalogue<i class="fas fa-download"></i></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-template #elseVehicleInfoTemplate>
                    <ng-container *ngIf="!isVehicleListUploaded && !vehicleinfo?.length; else noVehiclesBlock">
                      <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxl-3 mb-3"
                        *ngFor="let VCobj of userService.generateFake(8); let i=index;">
                        <div class="card" style="height: 100%;">
                          <div class="card-body d-flex p-2">
                            <div class="form-check">

                            </div>
                            <div class="filter-data-content d-grid">
                              <h5 style="cursor: pointer">
                                <skc-rectangle [loading]="loading" [skc-height]="'25px'" [skc-width]="'120px'">
                                </skc-rectangle>
                              </h5>
                              <div class="
                              d-flex
                              flex-wrap
                              align-items-center
                              model_part_type
                            ">
                                <div class="data-type">
                                  <skc-rectangle [loading]="loading" [skc-height]="'46px'" [skc-width]="'145px'">
                                  </skc-rectangle>
                                </div>

                                <div class="data-type">
                                  <skc-rectangle [loading]="loading" [skc-height]="'46px'" [skc-width]="'31px'">
                                  </skc-rectangle>
                                </div>

                                <div class="data-type">
                                  <skc-rectangle [loading]="loading" [skc-height]="'46px'" [skc-width]="'198px'">
                                  </skc-rectangle>
                                </div>

                                <div class="data-type">
                                  <skc-rectangle [loading]="loading" [skc-height]="'68px'" [skc-width]="'235px'">
                                  </skc-rectangle>
                                </div>
                                <div class="data-type">
                                  <skc-rectangle [loading]="loading" [skc-height]="'68px'" [skc-width]="'174px'">
                                  </skc-rectangle>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-template #noVehiclesBlock>
                      <div style="text-align: center" *ngIf="isVehicleEmpty && isVehicleListUploaded">
                        <h6>No Vehicles Added</h6>
                      </div>
                    </ng-template>
                  </ng-template>

                </div>
                <div class="filter_data_table table-responsive" *ngIf="layout === 'list'">
                  <table class="table align-middle">
                    <thead>
                      <tr>
                        <th>
                          <div class="form-check">
                            <input [disabled]="vehicleinfo?.length == 0" class="form-check-input" type="checkbox"
                              [(ngModel)]="selectAll" (change)="checkUncheckAll()" />
                          </div>
                        </th>
                        <th>Sr. No.</th>
                        <th>Registration Number</th>
                        <th>Chasis Type</th>
                        <th>Year</th>
                        <th>Model</th>
                        <th>Model Description</th>
                        <th>Status</th>
                        <th>Comment</th>
                        <th class="text-nowrap">RC Upload</th>
                        <th class="text-nowrap">Part Catalogue</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngIf="vehicleinfo?.length; else elseVehicleInfoTemplate">
                        <tr *ngFor="let VCobj of vehicleinfo; let i = index">
                          <td>
                            <div class="form-check">

                              <!-- (click)="selectedVehicle($event, VCobj)" -->
                              <input class="form-check-input" type="checkbox" [checked]="VCobj.isSelected"
                                [(ngModel)]="VCobj.isSelected" value="{{VCobj.id}}" (change)="isAllSelected()" />
                            </div>
                          </td>
                          <td>{{ i + 1 }}</td>
                          <td class="reg_no" (click)="addvehicle(VCobj)" style="cursor: pointer">
                            {{ VCobj?.registration_no || "-" }}
                          </td>
                          <td>{{ VCobj?.chassis_no || "-" }}</td>
                          <td>{{ VCobj?.created_at | date: "yyyy" }}</td>
                          <td>{{ VCobj?.model_cat || "-" }}</td>
                          <td>{{ VCobj?.model_desc || "-" }}</td>
                          <td class="status st-vprogress">
                            Verification in progress
                          </td>
                          <td>—</td>
                          <td>
                            <!-- <a *ngIf="VCobj?.rc_book_doc !== null" class="link-style"
                              class="ellipse-2line"
                                style="height: 30px"
                                (click)="openrcpopup(VCobj)"
                              >
                                View
                            </a> -->
                            <a *ngIf="VCobj?.rc_book_doc !== null" class="link-style" class="ellipse-2line"
                              style="height: 30px" target="_blank" [href]="VCobj?.rc_book_doc"> View</a>
                          </td>
                          <td>
                            <div class="data-type hide-for-mobile">
                              <a class="view-all" (click)="downloadVehicleParts(VCobj)" matTooltip="Download Part Catalogue" target="_blank"><i class="fas fa-download"></i></a>
                            </div>
                          </td>
                        </tr>
                      </ng-container>
                      <ng-template #elseVehicleInfoTemplate>
                        <ng-container *ngIf="!isVehicleListUploaded && !vehicleinfo?.length else noRecordsTableBlock">
                          <tr *ngFor="let VCobj of userService.generateFake(8); let i=index;">
                            <td>
                              <skc-rectangle [loading]="loading" [skc-height]="'18px'" [skc-width]="'16px'">
                              </skc-rectangle>
                            </td>
                            <td>
                              <skc-rectangle [loading]="loading" [skc-height]="'18px'" [skc-width]="'50px'">
                              </skc-rectangle>
                            </td>
                            <td class="reg_no" style="cursor: pointer">
                              <skc-rectangle [loading]="loading" [skc-height]="'15px'" [skc-width]="'155px'">
                              </skc-rectangle>
                            </td>
                            <td>
                              <skc-rectangle [loading]="loading" [skc-height]="'15px'" [skc-width]="'87px'">
                              </skc-rectangle>
                            </td>
                            <td>
                              <skc-rectangle [loading]="loading" [skc-height]="'15px'" [skc-width]="'35px'">
                              </skc-rectangle>
                            </td>
                            <td>
                              <skc-rectangle [loading]="loading" [skc-height]="'15px'" [skc-width]="'85px'">
                              </skc-rectangle>
                            </td>
                            <td>
                              <skc-rectangle [loading]="loading" [skc-height]="'15px'" [skc-width]="'360px'">
                              </skc-rectangle>
                            </td>
                            <td class="status st-vprogress">
                              <skc-rectangle [loading]="loading" [skc-height]="'15px'" [skc-width]="'165px'">
                              </skc-rectangle>
                            </td>
                            <td>
                              <skc-rectangle [loading]="loading" [skc-height]="'15px'" [skc-width]="'70px'">
                              </skc-rectangle>
                            </td>
                            <td>
                              <skc-rectangle [loading]="loading" [skc-height]="'15px'" [skc-width]="'70px'">
                              </skc-rectangle>
                            </td>
                          </tr>
                        </ng-container>
                      </ng-template>
                      <ng-template #noRecordsTableBlock>
                        <tr>
                          <th colspan="12">
                            <div class="no-record-block" *ngIf="isVehicleEmpty">
                              <h6>No Record Found</h6>
                            </div>
                          </th>
                        </tr>
                      </ng-template>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="paging" *ngIf="!isVehicleEmpty && totalrecord > 12">
                <order-pagination [activePage]="currentPage" [totalRecords]="totalrecord" [vehicleCount]="vehiclecount"
                  [recordsPerPage]="12" [hideShowingRecords]="false" (onPageChange)="pageChange($event)">
                </order-pagination>
              </div>



            </div>
            <div class="card-body" *ngIf="!isBulkVehicleUpload">
              <div class="d-flex justify-content-end align-items-center" style="flex-wrap: wrap;">
                <h5 class="v-card-heading me-auto mb-0 pe-3">Bulk Upload Vehicle</h5>
                <div class="cta">
                  <!-- <a
                    class="btn btn-outline-primary me-3 mt-3 mt-sm-0"
                    (click)="deleteVehicleRequest()"
                    *ngIf="vehicleinfo.length > 0 && !isBulkVehicleUpload"
                    >REMOVE VEHICLE</a
                  > -->
                  <a class="btn btn-primary me-3 mt-3 mt-sm-0" (click)="addvehicle()"><img
                      src="./assets/images/plus-white.svg" class="add_icon" />ADD VEHICLE</a>
                  <a class="btn btn-primary mt-3 mt-sm-0" (click)="userVehicle()">GO TO MY VEHICLES</a>
                </div>
              </div>
              <br />
              <label>
                <p>{{ fileName }}</p>
                &nbsp; &nbsp; &nbsp; &nbsp;
                <input #inputFile type="file" onclick="this.value = null" (change)="SelectDocumentFiles($event)"
                  style="display: none" />
                <a mat-raised-button color="primary">
                  <mat-icon>file_upload</mat-icon>
                  Upload
                </a>
                &nbsp; &nbsp; &nbsp; &nbsp;
                <button class="btn btn-primary" (click)="Upload()">
                  Submit
                </button>
              </label><br /><br />
              <div class="text-end">
                <b style="color: red">Instruction:</b>
                <br />1.Download Template from
                <a class="view-all" href="./assets/Samples/Bulk_Upload_Edukan.xlsx">here</a>
              </div>
              <div>
                <b style="color: red">Instructions for bulk uploading:</b><br />
                <a href="./assets/Samples/new-bulk-vehicle-upload-image.png" target="_blank"><img
                    src="./assets/Samples/new-bulk-vehicle-upload-image.png" style="width: 100%; cursor: pointer" alt=""
                    class="size" /></a>
              </div>
            </div>
          </div>
        </div>

      </div>


      <div class="card Vehicle-card mt-5 mb-3 border-0 shadow"
        *ngIf="isVehiclePermission ==null && isProfilePermission == null ">
        <div class="access-restricted">
          Your access is restricted, please contact Organisation Manager...!
        </div>
      </div>
    </ng-container>

    <!-- <ng-container *ngIf="!isMyProfile">
            <div class="card Vehicle-card mt-5 mb-3 border-0 shadow">
                <div class="card-body">
                    Next Page
                </div>
            </div>
        </ng-container> -->
  </div>
</div>

<ng-template #addVehicle let-d="dismiss">
  <app-add-vehicle [vehicleDetails]="vehicleDetails" (closePopUp)="closeModal($event)"></app-add-vehicle>
</ng-template>

<ng-template #delete let-d="dismiss">
  <app-logout [isVehicleDelete]="isVehicleDelete" (modalClose)="deleteVehicleModal.hide()" (deleteVehicle)="Delete()">
  </app-logout>
</ng-template>
<ng-template #viewRCBookModalVehicle let-d="dismiss">
  <app-view-document [rcbookdetail]="rcbookdetail" (closeRCPopUp)="closeRCPopUp($event)"></app-view-document>
</ng-template>

<ng-container *ngIf="showLoader">
  <div class="loader">
    <div class="ng-spinner ng-spinner-icon"></div>
  </div>
</ng-container>

<ng-template #uploadmodal let-d="dismiss">
  <div class="modal-header">
    <h6 class="modal-title">
      Please open the downloaded file to check if vehicle was uploaded
      successfully.
    </h6>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="DownloadFile()">
      &nbsp;OK
    </button>
  </div>
</ng-template>
