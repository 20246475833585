import { Component, EventEmitter, OnInit, Output, Input, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { AppFormErrorService } from '../../services/form-error.service';
import { GoogleAnalyticsService } from '../../services/GoogleAnalytics/GoogleAnalytics.service';
import { RegexService } from '../../services/regex-service';
import { AddressService } from '../../services/REST/address.service';
import { UserService } from '../../services/REST/user.service';
import { UserAddressService } from '../../services/REST/useraddress.service';
import { environment } from '../../../../../src/environments/environment';
import * as AWS from 'aws-sdk';
var $: any;
declare var TextDecoder;

import { blockPermissionMsg } from '../../../../../src/app/constant';
import { secureStorage } from '../../services/securestorage';
import { ProductService } from '../../services/REST/product.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AppStateService } from '../../services/app-state/app-state.service';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['./add-address.component.scss'],
})
export class AddAddressComponent implements OnInit, OnDestroy {
  StateJson: any;
  blockPermissionMsg = blockPermissionMsg;
  showAddressDetail: boolean = false;
  showPersonalDetail: boolean = true;
  @Output() closePopUp = new EventEmitter<any>();
  addressForm: FormGroup;
  personalDetailsForm: FormGroup;
  stateList = [];
  cityList = [];
  districtList = [];
  pincodeList = [];
  talukaList = [];
  isFirstFormError: boolean = false;
  btndisabled: boolean = false;
  Error: any;
  submitted: boolean = false;
  isgstcontacterror: boolean = false;
  searchByPincode: any;
  showLoader: boolean = false;
  validPincode: boolean = false;
  geocityList = [];
  selectedCity: any;
  public cityInput$ = new Subject<string | null>();
  isManual: boolean = true;
  isPincode: boolean = false;
  isCity: boolean = false;
  currentNameValue: any;
  isAddressView: boolean = false;
  @Input() isCartAddress: boolean = false;
  isFirstTime: boolean = false;
  isSecondTime: boolean = false;
  isThirdTime: boolean = false;
  servicecalls3:boolean=false;

  // @ViewChild('addContactPerson', { read: TemplateRef, static: false })
  // addContactPerson: TemplateRef<any>;
  // addContactPersonModal:any;
  // @ViewChild('confirmationContactPerson', { read: TemplateRef, static: false })
  // confirmationContactPerson: TemplateRef<any>;
  // confirmationContactPersonModal:any;

  // contactPersonList:any[] = [];
  // isContactPersonUpdate:boolean = false;
  // public selectedConatctPersonValue:any;
  contactPersonDetails:any = "No";
  contactPersonData:any = {};
  @ViewChild('listContactPersonModal', { read: TemplateRef, static: false })
    listContactPersonModal: TemplateRef<any>;
    listContactPersonModalDetails:any;
    private ngUnSubscribe: Subject<void> = new Subject();
  // addressData:any;

  constructor(
    private appStateService: AppStateService,
    private regexService: RegexService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private modalService: BsModalService,
    private addressService: AddressService,
    private router: Router,
    private formErrorService: AppFormErrorService,
    private userAddressService: UserAddressService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private toaster: ToastrService,
    private localstorage: secureStorage,
    private productService:ProductService
  ) {
    this.cityInput$.subscribe((newTerm) => {
      if (newTerm) {
        this.geocityList = [];
        this.getGeoCities(newTerm);
      }
    });
  }

  ngOnInit() {
    this.newForm();
    this.getS3GstCode();
    this.getStates();
    this.appStateService.openContactPersonList.pipe(takeUntil(this.ngUnSubscribe)).subscribe( addAddress => {
      if( ( this.router.url.includes("account/address" ) && addAddress!="" ) ){
        this.openContactPersonList();
        this.appStateService.openContactPersonList.next("");
      }
  })
    // this.getContactPersonList();
    
  }

  changeSearchType(type) {
    if (type == 'Manual') {
      this.isManual = true;
      this.isPincode = false;
      this.isCity = false;
      //this.getStates();
    } else if (type == 'City') {
      this.isManual = false;
      this.isPincode = false;
      this.isCity = true;
      this.searchByPincode = '';
      this.validPincode = false;
      this.selectedCity = null;
      //  this.getStates();
      // this.stateList = [];
      this.isAddressView = false;
    } else if (type == 'Pincode') {
      this.isManual = false;
      this.isPincode = true;
      this.isCity = false;
      this.searchByPincode = '';
      this.validPincode = false;
      // this.stateList = [];
      this.isAddressView = false;
      //  this.getStates();
    }
    if (this.personalDetailsForm.controls['name'].value) {
      this.currentNameValue = this.personalDetailsForm.controls['name'].value;
    }
    // this.newForm();
    this.getStates();
    this.addressForm.patchValue({
      address: '',
      gst_no: '',
      state: '',
      city: '',
      district: '',
      taluka: '',
      pincode: '',
    });
  }

  newForm() {
    this.addressForm = this.formBuilder.group({
      address: ['', [Validators.required, Validators.maxLength(100)]],
      gst_no: ['', [Validators.pattern(this.regexService.GstNo)]],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]],
      district: ['', [Validators.required]],
      taluka: [''],
      pincode: ['', [Validators.required]],
    });
    this.personalDetailsForm = this.formBuilder.group({
      name: [
        this.currentNameValue ? this.currentNameValue : '',
        [Validators.required, Validators.maxLength(62)],
      ],

      contact_person_name: [''],
      contact_no: ['', [Validators.pattern(this.regexService.Phone)]],
    })

    this.currentNameValue = '';
  }

  getS3GstCode() {
    try{
      if (!this.servicecalls3) {
          let req = {
              "file_name": "StateGstCode.json"
          }
          this.productService.getSeasonalPartsData(req).subscribe(
              res => {
                  res = JSON.stringify(res);
                  this.servicecalls3 = true;
                  localStorage.removeItem('gstdata');
                  localStorage.setItem('gstdata', res);
              }, error => {

              }
          )
      }
    }catch(error){ }
  }

  async getStates() {
    try {
      this.districtList = [];
      this.pincodeList = [];
      this.talukaList = [];
      this.cityList = [];
      const ListGeostateInput: GetModalAddressparameter =
        {} as GetModalAddressparameter;
      ListGeostateInput.offset = 0;
      ListGeostateInput.limit = 10000;
      ListGeostateInput.dropdown_type = 'state';
      ListGeostateInput.multi_city =
        this.isCity === true ? [this.selectedCity.city_name] : [];
      ListGeostateInput.multi_pincode =
        this.isPincode === true
          ? this.addressForm.value.pincode
            ? [this.addressForm.value.pincode]
            : []
          : [];
      let stateresponse = await this.addressService.getGeoData(
        ListGeostateInput
      );
      if (stateresponse['success'] === true) {
        let stategeodata = stateresponse['data']['States'];
        this.stateList = [];
        for (var i = 0; i < stategeodata.length; i++) {
          if (
            stategeodata[i].state_name != null &&
            stategeodata[i].state_name != undefined &&
            stategeodata[i].state_name != JSON.stringify({}) &&
            stategeodata[i].state_name != ''
          ) {
            this.stateList.push(this.formData(stategeodata[i]));
          }
        }
      }
      // this.districtList = [];
      // this.showLoader = true;
      // this.addressService.getStates().subscribe((response) => {
      //   if (response) {
      //     this.showLoader = false;
      //     this.stateList = response;
      //   }
      // });
    } catch (error) {
      this.userService.InsertExceptionLog({
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'PageEditAddressComponent',
        method_name: 'getStates()',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  async getDistricts(checkdata, addresstype) {
    try {
      if (addresstype === 'city') {
        let data = this.stateList.filter(
          (result) => result.code === this.addressForm.value.state
        );
        const ListGeodistrictInput: GetModalAddressparameter =
          {} as GetModalAddressparameter;
        ListGeodistrictInput.offset = 0;
        ListGeodistrictInput.limit = 300;
        ListGeodistrictInput.dropdown_type = 'district';
        ListGeodistrictInput.multi_state = [data[0]['code']];
        ListGeodistrictInput.multi_city =
          this.isCity === true ? [this.selectedCity.city_name] : [];
        ListGeodistrictInput.multi_pincode =
          this.isPincode === true
            ? this.addressForm.value.pincode
              ? [this.addressForm.value.pincode]
              : []
            : [];
        let districtgeodata;
        let districtresponse = await this.addressService.getGeoData(
          ListGeodistrictInput
        );
        if (districtresponse['success'] === true) {
          districtgeodata = districtresponse['data']['Districts'];
          this.districtList = [];
          for (var j = 0; j < districtgeodata.length; j++) {
            this.districtList.push(districtgeodata[j].district_name);
          }
          // if(this.districtList.length > 0){
          //   this.addressForm.patchValue({
          //     district: this.districtList[0]
          //   })
          // }
          if (this.isCity === true) {
            this.addressForm.patchValue({
              district: this.districtList[0],
            });
            if (this.districtList.length > 1) {
              this.toaster.error(
                'Entered City is appearing in multiple districts, please select the desired district.'
              );
            }
          }
          this.getCities('true');
        }
      } else {
        this.districtList = [];
        this.pincodeList = [];
        this.talukaList = [];
        this.cityList = [];
        let data = this.stateList.filter(
          (result) => result.code === this.addressForm.value.state
        );
        // let ll = {};
        // ll = {
        //   code: data[0]['code'],
        //   state: data[0]['name'],
        // };
        // let state = {
        //   state: ll,
        // };
        // await this.addressService.getDistricts(state).subscribe((response) => {
        //   this.districtList = response;
        //   if (checkdata == 'false') {
        //     this.addressForm.patchValue({
        //       district: '',
        //       pincode: '',
        //       taluka: '',
        //     });
        //   }
        // });
        const ListGeodistrictInput: GetModalAddressparameter =
          {} as GetModalAddressparameter;
        ListGeodistrictInput.offset = 0;
        ListGeodistrictInput.limit = 10000;
        ListGeodistrictInput.dropdown_type = 'district';
        ListGeodistrictInput.multi_state = [data[0].code];
        ListGeodistrictInput.multi_city =
          this.isCity === true ? [this.selectedCity.city_name] : [];
        ListGeodistrictInput.multi_pincode =
          this.isPincode === true
            ? this.addressForm.value.pincode
              ? [this.addressForm.value.pincode]
              : []
            : [];
        let districtresponse = await this.addressService.getGeoData(
          ListGeodistrictInput
        );
        if (districtresponse['success'] === true) {
          let districtgeodata = districtresponse['data']['Districts'];
          this.districtList = [];
          for (var j = 0; j < districtgeodata.length; j++) {
            if (
              districtgeodata[j].district_name != null &&
              districtgeodata[j].district_name != undefined &&
              districtgeodata[j].district_name != JSON.stringify({}) &&
              districtgeodata[j].district_name != ''
            ) {
              this.districtList.push(districtgeodata[j].district_name);
            }
          }
          if (checkdata == 'false') {
            this.addressForm.patchValue({
              city: '',
              district: '',
              pincode: '',
              taluka: '',
            });
          }
          if (this.districtList.length == 1) {
            this.addressForm.patchValue({
              district: this.districtList[0],
            });
          }
          if (this.isCity === true) {
            this.addressForm.patchValue({
              district: this.districtList[0],
            });
            if (this.districtList.length > 1) {
              this.toaster.error(
                'Entered City is appearing in multiple districts, please select the desired district.'
              );
            }
          }
          this.getCities('true');
        }
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'getDistricts()',
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'PageEditAddressComponent',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  async getCities(checkdata) {
    try {
      this.pincodeList = [];
      this.talukaList = [];
      this.cityList = [];
      let data = this.stateList.filter(
        (result) => result.code === this.addressForm.value.state
      );
      // let ll = {};
      // ll = {
      //   code: data[0]['code'],
      //   state: data[0]['name'],
      // };
      // let state = {
      //   state: ll,
      //   district: this.addressForm.value.district,
      // };
      // this.addressService.getCities(state).subscribe((response) => {
      //   this.cityList = response;
      //   if (checkdata == 'false') {
      //     this.addressForm.patchValue({
      //       city: '',
      //       pincode: '',
      //       taluka: '',
      //     });
      //   }
      // });
      const ListGeoCityInput: GetModalAddressparameter =
        {} as GetModalAddressparameter;
      ListGeoCityInput.offset = 0;
      ListGeoCityInput.limit = 10000;
      ListGeoCityInput.dropdown_type = 'city';
      ListGeoCityInput.multi_state = [data[0]['code']];
      ListGeoCityInput.multi_city =
        this.isCity === true ? [this.selectedCity.city_name] : [];
      ListGeoCityInput.multi_district = [this.addressForm.value.district];
      ListGeoCityInput.multi_pincode =
        this.isPincode === true
          ? this.addressForm.value.pincode
            ? [this.addressForm.value.pincode]
            : []
          : [];
      let citygeodata;
      let cityresponse = await this.addressService.getGeoData(ListGeoCityInput);
      if (cityresponse['success'] === true) {
        citygeodata = cityresponse['data']['Cities'];
        this.cityList = [];
        for (var j = 0; j < citygeodata.length; j++) {
          if (
            citygeodata[j].city_name != null &&
            citygeodata[j].city_name != undefined &&
            citygeodata[j].city_name != JSON.stringify({}) &&
            citygeodata[j].city_name != ''
          ) {
            this.cityList.push(citygeodata[j].city_name);
          }
        }
        if (checkdata == 'false') {
          this.addressForm.patchValue({
            city: '',
            pincode: '',
            taluka: '',
          });
        }
        if (this.selectedCity) {
          if (this.selectedCity.city_name) {
            this.getTalukas('false');
          }
        }
        if (this.cityList.length == 1) {
          this.addressForm.patchValue({
            city: this.cityList[0],
          });
        }
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'getCities()',
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'PageEditAddressComponent',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  async getTalukas(checkdata) {
    try {
      this.pincodeList = [];
      this.talukaList = [];
      let data = this.stateList.filter(
        (result) => result.code === this.addressForm.value.state
      );
      // let ll = {};
      // ll = {
      //   code: data[0]['code'],
      //   state: data[0]['name'],
      // };
      // let state = {
      //   state: ll,
      //   district: this.addressForm.value.district,
      //   city: this.addressForm.value.city,
      // };
      // this.addressService.getTalukas(state).subscribe((response) => {
      //   this.talukaList = response;
      //   if (checkdata == 'false') {
      //     this.addressForm.patchValue({
      //       taluka: '',
      //       pincode: '',
      //     });
      //   } else if (checkdata == 'true') {
      //     this.addressForm.patchValue({
      //       taluka: response[0],
      //     });
      //   }
      // });
      const ListGeotalukaInput: GetModalAddressparameter =
        {} as GetModalAddressparameter;
      ListGeotalukaInput.offset = 0;
      ListGeotalukaInput.limit = 10000;
      ListGeotalukaInput.dropdown_type = 'taluka';
      ListGeotalukaInput.multi_state = [data[0]['code']];
      ListGeotalukaInput.multi_district = [this.addressForm.value.district];
      ListGeotalukaInput.multi_city =
        this.isCity === true
          ? [this.selectedCity.city_name]
          : this.addressForm.value.city
            ? [this.addressForm.value.city]
            : [];
      ListGeotalukaInput.multi_pincode =
        this.isPincode === true
          ? this.addressForm.value.pincode
            ? [this.addressForm.value.pincode]
            : []
          : [];
      let Talukageodata;
      let talukaresponse = await this.addressService.getGeoData(
        ListGeotalukaInput
      );
      if (talukaresponse['success'] === true) {
        Talukageodata = talukaresponse['data']['Talukas'];
        this.talukaList = [];
        for (var j = 0; j < Talukageodata.length; j++) {
          if (
            Talukageodata[j].taluka_name != null &&
            Talukageodata[j].taluka_name != undefined &&
            Talukageodata[j].taluka_name != JSON.stringify({}) &&
            Talukageodata[j].taluka_name != ''
          ) {
            this.talukaList.push(Talukageodata[j].taluka_name);
          }
        }
        if (this.talukaList.length == 1) {
          this.addressForm.patchValue({
            taluka: this.talukaList[0],
          });
        }

        if (checkdata == 'false') {
          this.addressForm.patchValue({
            taluka: '',
            pincode: '',
          });
        }
        if (this.selectedCity) {
          if (this.selectedCity.city_name) {
            if (this.talukaList.length > 0) {
              this.addressForm.patchValue({
                taluka: this.talukaList[0],
              });
            }
            this.getPincodes('false');
          }
        }
        this.getPincodes('false');
      } else {
        this.getPincodes('false');
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'getTalukas()',
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'PageEditAddressComponent',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  async getPincodes(checkdata) {
    try {
      let data = this.stateList.filter(
        (result) => result.code === this.addressForm.value.state
      );
      // let ll = {};
      // ll = {
      //   code: data[0]['code'],
      //   state: data[0]['name'],
      // };
      // let state = {
      //   state: ll,
      //   district: this.addressForm.value.district,
      //   city: this.addressForm.value.city,
      //   taluka: this.addressForm.value.taluka,
      // };
      // if (this.addressForm.value.taluka) {
      //   this.addressService.getPincodes(state).subscribe((response) => {
      //     this.pincodeList = response.pincodes;
      //   });
      // }
      const ListGeoPincodeInput: GetModalAddressparameter =
        {} as GetModalAddressparameter;
      ListGeoPincodeInput.offset = 0;
      ListGeoPincodeInput.limit = 10000;
      ListGeoPincodeInput.dropdown_type = 'pincode';
      ListGeoPincodeInput.multi_state = [data[0]['code']];
      ListGeoPincodeInput.multi_district = [this.addressForm.value.district];
      ListGeoPincodeInput.multi_city =
        this.isCity === true
          ? [this.selectedCity.city_name]
          : this.addressForm.value.city
            ? [this.addressForm.value.city]
            : [];
      ListGeoPincodeInput.multi_taluka = (this.addressForm.value.taluka === "" || this.addressForm.value.taluka === null || this.addressForm.value.taluka === undefined) ? [] : [this.addressForm.value.taluka];
      if (this.isPincode) {
        //ListGeoPincodeInput.multi_pincode = this.isPincode === true ? this.addressForm.value.pincode ? [this.addressForm.value.pincode] : [] : [];
        ListGeoPincodeInput.multi_pincode = (this.addressForm.value.pincode === "" || this.addressForm.value.pincode === null || this.addressForm.value.pincode === undefined) ? [] : [this.addressForm.value.pincode];
      }

      let pincodegeodata;
      let pincoderesponse = await this.addressService.getGeoData(
        ListGeoPincodeInput
      );
      if (pincoderesponse['success'] === true) {
        pincodegeodata = pincoderesponse['data']['Pincodes'];
        this.pincodeList = [];
        for (var j = 0; j < pincodegeodata.length; j++) {
          if (
            pincodegeodata[j].pincode != null &&
            pincodegeodata[j].pincode != undefined &&
            pincodegeodata[j].pincode != JSON.stringify({}) &&
            pincodegeodata[j].pincode != ''
          ) {
            this.pincodeList.push(pincodegeodata[j].pincode);
          }
        }
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'getPincodes()',
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'PageEditAddressComponent',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }
  // StateJson = [
  //   {
  //     'state_name ▾': 'Andaman and Nicobar Islands',
  //     alpha_code: 'AN',
  //     gst_state_code: '35',
  //   },
  //   {
  //     'state_name ▾': 'Andhra Pradesh',
  //     alpha_code: 'AP',
  //     gst_state_code: '37',
  //   },
  //   {
  //     'state_name ▾': 'Arunachal Pradesh',
  //     alpha_code: 'AR',
  //     gst_state_code: '12',
  //   },
  //   {
  //     'state_name ▾': 'Assam',
  //     alpha_code: 'AS',
  //     gst_state_code: '18',
  //   },
  //   {
  //     'state_name ▾': 'Bihar',
  //     alpha_code: 'BR',
  //     gst_state_code: '10',
  //   },
  //   {
  //     'state_name ▾': 'Chandigarh',
  //     alpha_code: 'CH',
  //     gst_state_code: '04',
  //   },
  //   {
  //     'state_name ▾': 'Chhattisgarh',
  //     alpha_code: 'CG',
  //     gst_state_code: '22',
  //   },
  //   {
  //     'state_name ▾': 'Dadra and Nagar Haveli',
  //     alpha_code: 'DN',
  //     gst_state_code: '26',
  //   },
  //   {
  //     'state_name ▾': 'Daman and Diu',
  //     alpha_code: 'DD',
  //     gst_state_code: '25',
  //   },
  //   {
  //     'state_name ▾': 'Delhi',
  //     alpha_code: 'DL',
  //     gst_state_code: '07',
  //   },
  //   {
  //     'state_name ▾': 'Foreign Country',
  //     alpha_code: 'FC',
  //     gst_state_code: '96',
  //   },
  //   {
  //     'state_name ▾': 'Goa',
  //     alpha_code: 'GA',
  //     gst_state_code: '30',
  //   },
  //   {
  //     'state_name ▾': 'Gujarat',
  //     alpha_code: 'GJ',
  //     gst_state_code: '24',
  //   },
  //   {
  //     'state_name ▾': 'Haryana',
  //     alpha_code: 'HR',
  //     gst_state_code: '06',
  //   },
  //   {
  //     'state_name ▾': 'Himachal Pradesh',
  //     alpha_code: 'HP',
  //     gst_state_code: '02',
  //   },
  //   {
  //     'state_name ▾': 'Jammu and Kashmir',
  //     alpha_code: 'JK',
  //     gst_state_code: '01',
  //   },
  //   {
  //     'state_name ▾': 'Jharkhand',
  //     alpha_code: 'JH',
  //     gst_state_code: '20',
  //   },
  //   {
  //     'state_name ▾': 'Karnataka',
  //     alpha_code: 'KA',
  //     gst_state_code: '29',
  //   },
  //   {
  //     'state_name ▾': 'Kerala',
  //     alpha_code: 'KL',
  //     gst_state_code: '32',
  //   },
  //   {
  //     'state_name ▾': 'Ladakh',
  //     alpha_code: 'LA',
  //     gst_state_code: '38',
  //   },
  //   {
  //     'state_name ▾': 'Lakshadweep',
  //     alpha_code: 'LD',
  //     gst_state_code: '31',
  //   },
  //   {
  //     'state_name ▾': 'Madhya Pradesh',
  //     alpha_code: 'MP',
  //     gst_state_code: '23',
  //   },
  //   {
  //     'state_name ▾': 'Maharashtra',
  //     alpha_code: 'MH',
  //     gst_state_code: '27',
  //   },
  //   {
  //     'state_name ▾': 'Manipur',
  //     alpha_code: 'MN',
  //     gst_state_code: '14',
  //   },
  //   {
  //     'state_name ▾': 'Meghalaya',
  //     alpha_code: 'ML',
  //     gst_state_code: '17',
  //   },
  //   {
  //     'state_name ▾': 'Mizoram',
  //     alpha_code: 'MZ',
  //     gst_state_code: '15',
  //   },
  //   {
  //     'state_name ▾': 'Nagaland',
  //     alpha_code: 'NL',
  //     gst_state_code: '13',
  //   },
  //   {
  //     'state_name ▾': 'Odisha',
  //     alpha_code: 'OR',
  //     gst_state_code: '21',
  //   },
  //   {
  //     'state_name ▾': 'Other Territory',
  //     alpha_code: 'OT',
  //     gst_state_code: '97',
  //   },
  //   {
  //     'state_name ▾': 'Puducherry',
  //     alpha_code: 'PY',
  //     gst_state_code: '34',
  //   },
  //   {
  //     'state_name ▾': 'Punjab',
  //     alpha_code: 'PB',
  //     gst_state_code: '03',
  //   },
  //   {
  //     'state_name ▾': 'Rajasthan',
  //     alpha_code: 'RJ',
  //     gst_state_code: '08',
  //   },
  //   {
  //     'state_name ▾': 'Sikkim',
  //     alpha_code: 'SK',
  //     gst_state_code: '11',
  //   },
  //   {
  //     'state_name ▾': 'Tamil Nadu',
  //     alpha_code: 'TN',
  //     gst_state_code: '33',
  //   },
  //   {
  //     'state_name ▾': 'Telangana',
  //     alpha_code: 'TS',
  //     gst_state_code: '36',
  //   },
  //   {
  //     'state_name ▾': 'Tripura',
  //     alpha_code: 'TR',
  //     gst_state_code: '16',
  //   },
  //   {
  //     'state_name ▾': 'Uttar Pradesh',
  //     alpha_code: 'UP',
  //     gst_state_code: '09',
  //   },
  //   {
  //     'state_name ▾': 'Uttarakhand',
  //     alpha_code: 'UA',
  //     gst_state_code: '05',
  //   },
  //   {
  //     'state_name ▾': 'West Bengal',
  //     alpha_code: 'WB',
  //     gst_state_code: '19',
  //   },
  // ];
  showAddressDetails() {
    if (
      this.personalDetailsForm.controls['name'].invalid ||
      this.personalDetailsForm.controls['contact_no'].invalid ||
      this.addressForm.controls['gst_no'].invalid
    ) {
      this.isFirstFormError = true;
      return;
    } else {
      this.showAddressDetail = true;
      this.showPersonalDetail = false;

    }
  }

  closeModal() {
    this.closePopUp.emit('Address not added successfully.');
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onSubmit() {
    this.Error = '';
    if (this.addressForm.value.gst_no) {
      this.StateJson = JSON.parse(localStorage.getItem('gstdata'));
      var Selectedstate = this.addressForm.value.state;
      let GstCode = this.StateJson.filter(
        (Data) => Data.alpha_code === Selectedstate
      );
      var Statecode = this.addressForm.value.gst_no.substring(0, 2);
      var Stategstfromjson = GstCode[0].gst_state_code;
      if (Statecode !== Stategstfromjson) {
        this.Error =
          'Please enter Valid GST Code (Valid GST State code is : ' +
          Stategstfromjson +
          ')';
        return false;
      }
    }
    try {
      this.submitted = true;
      if (this.addressForm.invalid) {
        this.formErrorService.displayFormErrors(this.addressForm);
        if (
          this.addressForm.value.gst_no ||
          this.addressForm.value.contact_no
        ) {
          this.isgstcontacterror = true;
        } else {
          this.isgstcontacterror = false;
        }
        return;
      }
      this.btndisabled = true;
      let user = JSON.parse(this.localstorage.getItem('userData'));
      
      if (user) {
        let data = {
          street_address1: this.personalDetailsForm.value.name,
          street_address2: this.addressForm.value.address,
          city: (this.addressForm.value.city != '' && this.addressForm.value.city != undefined && this.addressForm.value.city != null) ? this.addressForm.value.city.toUpperCase() : '',
          state: this.addressForm.value.state,
          taluka: (this.addressForm.value.taluka != '' && this.addressForm.value.taluka != null && this.addressForm.value.taluka != undefined)
            ? this.addressForm.value.taluka.toUpperCase()
            : '',
          postal_code: this.addressForm.value.pincode
            ? this.addressForm.value.pincode
            : this.searchByPincode,
          district: (this.addressForm.value.district != '' && this.addressForm.value.district != null && this.addressForm.value.district != undefined) ? this.addressForm.value.district.toUpperCase() : '',
          country: 'India',
          account_pk: JSON.stringify(user.account_pk),
          user_type: user.user_type,
          gst_no: this.addressForm.value.gst_no,
          contact_no: this.contactPersonData.contact_number?this.contactPersonData.contact_number:"",
          alt_contact_no:this.contactPersonData.alt_contact_no?this.contactPersonData.alt_contact_no:"",
          contact_first_name:this.contactPersonData.first_name?this.contactPersonData.first_name:"",
          contact_last_name:this.contactPersonData.last_name?this.contactPersonData.last_name:"",
          contact_row_id:this.contactPersonData.row_id?this.contactPersonData.row_id:""
        };
        let permission = this.userService.CheckPermission('btn_add_address');
        if (permission) {
          var Address = this.personalDetailsForm.value.name + ', ' + this.addressForm.value.state + ', ' + ', ' + ', ' + this.addressForm.value.pincode;
          this.showLoader = true;
          this.userAddressService.addAddress(data).subscribe(
            (response) => {
              this.btndisabled = false;
              if (response.success === true) {
                this.showLoader = false;
                this.toaster.success('Address added successfully.');
                this.googleAnalyticsService.addressConfirmation('Address_Confirmation_web', user.phone_number, Address);
                this.closePopUp.emit('Address added successfully.');
              } else {
                if (response?.data?.msg === "You can't add new address identical address already exists." && response?.data?.error_code === 90 && !this.isFirstTime) {
                  this.isFirstTime = true;
                  data.street_address1 = data.street_address1 + ",";
                  this.userAddressService.addAddress(data).subscribe(response => {
                    if (response.success) {
                      this.toaster.success('Address added successfully.');
                      this.closePopUp.emit('Address added successfully.');
                      this.googleAnalyticsService.addressConfirmation('Address_Confirmation_web', user.phone_number, Address);
                    }
                    else {
                      if (response?.data?.msg === "You can't add new address identical address already exists." && response?.data?.error_code === 90 && !this.isSecondTime) {
                        this.isSecondTime = true;
                        data.street_address1 = data.street_address1 + ",";
                        this.userAddressService.addAddress(data).subscribe(response => {
                          if (response.success) {
                            this.toaster.success('Address added successfully.');
                            this.closePopUp.emit('Address added successfully.');
                            this.googleAnalyticsService.addressConfirmation('Address_Confirmation_web', user.phone_number, Address);
                          }
                          else {
                            if (response?.data?.msg === "You can't add new address identical address already exists." && response?.data?.error_code === 90 && !this.isThirdTime) {
                              this.isThirdTime = true;
                              data.street_address1 = data.street_address1 + ",";
                              this.userAddressService.addAddress(data).subscribe(response => {
                                if (response.success) {
                                  this.toaster.success('Address added successfully.');
                                  this.closePopUp.emit('Address added successfully.');
                                  this.googleAnalyticsService.addressConfirmation('Address_Confirmation_web', user.phone_number, Address);
                                }
                                else {
                                  if (response?.data?.msg) {
                                    this.toaster.error(response.data.msg);
                                  }
                                  this.closePopUp.emit();
                                  this.googleAnalyticsService.addressConfirmation('Address_Confirmation_Fail_web', user.phone_number, Address);
                                }
                              }, error => {
                                this.showLoader = false;
                                this.closePopUp.emit();
                                if (error?.error?.data?.msg) {
                                  this.toaster.error(error?.error?.data?.msg);
                                }
                              })
                            }
                            else {
                              if (response?.data?.msg) {
                                this.toaster.error(response.data.msg);
                              }
                              this.closePopUp.emit();
                              this.googleAnalyticsService.addressConfirmation('Address_Confirmation_Fail_web', user.phone_number, Address);
                            }
                          }
                        }, error => {
                          this.showLoader = false;
                          this.closePopUp.emit();
                          if (error?.error?.data?.msg) {
                            this.toaster.error(error?.error?.data?.msg);
                          }
                        })
                      }
                      else {
                        if (response?.data?.msg) {
                          this.toaster.error(response.data.msg);
                        }
                        this.closePopUp.emit();
                        this.googleAnalyticsService.addressConfirmation('Address_Confirmation_Fail_web', user.phone_number, Address);
                      }
                    }
                  }, error => {
                    this.showLoader = false;
                    this.closePopUp.emit();
                    if (error?.error?.data?.msg) {
                      this.toaster.error(error?.error?.data?.msg);
                    }
                  })
                }
                else {
                  this.showLoader = false;
                  if (response?.data?.msg) {
                    this.toaster.error(response.data.msg);
                  }
                  this.closePopUp.emit();
                  this.googleAnalyticsService.addressConfirmation('Address_Confirmation_Fail_web', user.phone_number, Address);
                }
              }
            },
            (error) => {
              this.showLoader = false;
              this.closePopUp.emit();
              if (error?.error?.data?.msg) {
                this.toaster.error(error?.error?.data?.msg);
              }
            }
          );
        } else {
          this.toaster.error(blockPermissionMsg);
        }
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'onSubmit()',
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'PageEditAddressComponent',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  // add or update contact person

  openContactPersonList(addressData?){
    // this.closeModal();
    this.listContactPersonModalDetails = this.modalService.show(this.listContactPersonModal, {
        class: 'modal-dialog modal-dialog-centered modal-lg',
        backdrop: 'static',
    });
  }

  hideContactPersonList(){
      this.listContactPersonModalDetails.hide();
  }

  updateContactDetailsForAddAddress(val){
    this.contactPersonData = val;
    
  }

  ngOnDestroy(){
    this.localstorage.removeitems('selectedContactRowId');
  }

  // getContactPersonList(){
  //     try{
  //       let user = JSON.parse(this.localstorage.getItem('userData'));
  //         let req = {
  //             "account_pk":JSON.stringify(user.account_pk),
  //             "action_type":"contact_data"
  //         }
  //         this.productService.getContactPersonList(req).subscribe(
  //             res => {
  //                 if(res.success && res.data.length > 0  ){
  //                     this.contactPersonList = res.data;
  //                 }else{
  //                     this.contactPersonList = [];
  //                     this.toaster.error("No Contact Person added")
  //                 }
  //             }
  //         )
  //     }catch(error){
  //         this.userService.InsertExceptionLog({
  //             method_name: 'getContactPersonList()',
  //             page_name: 'CartAddress',
  //             portal_type: 'Customer',
  //             page_url: this.router.url,
  //             error_message: error.message,
  //             inner_exception: error,
  //         });
  //     }
  // }

  // askUpdateContactPerson(val){
  //     // this.isContactPersonUpdate = true;
  //     // this.confirmationContactPersonModal =  this.modalService.show(this.confirmationContactPerson, {
  //     //     class: 'modal-dialog modal-dialog-centered modal-lg',
  //     //     backdrop: 'static',
  //     // });
  //     // this.addressData = addressData;
  //     this.contactPersonList.forEach(element => {
  //         if(val == element.row_id){
  //             this.contactPersonDetails = element;
  //         }
  //     });
  // }

  // // updateContactPerson(val){
  // //   try{
  // //         console.log(val);
  // //         console.log(this.contactPersonDetails);
  // //         this.showLoader = true;
  // //         let user = JSON.parse(this.localstorage.getItem('userData'));
  // //         if(this.contactPersonDetails){
  // //             let req = {
  // //                 "account_pk":Number( JSON.stringify(user.account_pk) ),
  // //                 "action_type":"add_contact_data",
  // //                 "alt_contact_no": "99999999",
  // //                 "address_id":this.addressData.address_id,
  // //                 "contact_first_name": this.contactPersonDetails.first_name,
  // //                 "contact_last_name": this.contactPersonDetails.last_name,
  // //                 "contact_row_id": this.contactPersonDetails.row_id
  // //             }
  // //             if(val == 'update'){
  // //                 this.productService.updateContactPerson(req).subscribe(
  // //                     res => {
  // //                         this.showLoader = false;
  // //                         this.confirmationContactPersonModal.hide();
  // //                         this.getContactPersonList();
  // //                         if(res.success){
  // //                             this.toaster.success(res.data.msg);
  // //                         }else{
  // //                             this.toaster.error(res.data.msg);
  // //                         }
  // //                     }
  // //                 )
  // //             }

  // //         }
  // //     }catch(error){
  // //         this.userService.InsertExceptionLog({
  // //         method_name: 'updateContactPerson(val)',
  // //         page_name: 'CartAddress',
  // //         portal_type: 'Customer',
  // //         page_url: this.router.url,
  // //         error_message: error.message,
  // //         inner_exception: error,
  // //         });
  // //     }
  // // }

  // openAddContactPersonModal(){
  //   this.closeModal();
  //   this.addContactPersonModal = this.modalService.show(this.addContactPerson, {
  //       class: 'modal-dialog modal-dialog-centered modal-lg',
  //       backdrop: 'static',
  //   });
  // }

  // closeContactPersonModal(){
  //   this.addContactPersonModal.hide();
  //   this.appStateService.openNewAddAddress.next("yes");
  //   this.getContactPersonList();
  // }

  public hasRequiredError = (controlName: string): boolean =>
    this.formErrorService.hasRequiredError(controlName, this.addressForm);
  public hasPatternError = (controlName: string): boolean =>
    this.formErrorService.hasPatternError(controlName, this.addressForm);
  public hasPatternErrorPersonalDetailForm = (controlName: string): boolean =>
    this.formErrorService.hasPatternError(controlName, this.personalDetailsForm);
  public hasRequiredErrorPersonalDetailForm = (controlName: string): boolean =>
    this.formErrorService.hasRequiredError(controlName, this.personalDetailsForm);

  showPersonalDetails() {
    this.showPersonalDetail = true;
    this.showAddressDetail = false;
  }

  checkPincodeValidate(pincode) {
    if (!/^[0-9]{6}$/.test(pincode)) {
      return (this.validPincode = true);
    }
    this.validPincode = false;
  }

  addressByPinCode() {
    if (this.searchByPincode === '') {
      return this.toaster.error('Please Enter pincode');
    }
    if (!/^[1-9][0-9]{5}$/.test(this.searchByPincode)) {
      return this.toaster.error('Please Enter valid 6 digit pincode');
    }
    try {
      this.addressService
        .getAddressByPincode({ pincode: this.searchByPincode })
        .subscribe((response) => {
          if (response) {
            if (response['pincode'] != undefined) {
              this.addressForm.patchValue({
                state:
                  response.state && response.state.code
                    ? response.state.code
                    : '',
                city: response.city ? response.city : '',
                district: response.district ? response.district : '',
                pincode: response.pincode ? response.pincode : '',
                taluka: response.taluka ? response.taluka : '',
              });
              this.isAddressView = true;
              this.getDistricts('true', '');
              this.getCities('true');
              this.getTalukas('true');
              this.getPincodes('true');
            }
          }
        });
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'addressByPinCode()',
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'PageEditAddressComponent',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  addAddressByPinCode() {
    if (this.searchByPincode === '') {
      return this.toaster.error('Please Enter pincode');
    }
    if (!/^[1-9][0-9]{5}$/.test(this.searchByPincode)) {
      return this.toaster.error('Please Enter valid 6 digit pincode');
    }
    try {
      let requestBody = {
        dropdown_type: "get_data_by_pincode",
        multi_district: [],
        multi_taluka: [],
        multi_city: [],
        multi_pincode: [this.searchByPincode],
        offset: 0,
        limit: 10000
      }
      this.addressService.addAddressByPincode(requestBody).subscribe((response : any) => {
        if (response['success']) {
          let addressDetails = response.data.data_by_pincode[0];
          this.addressForm.patchValue({
            state: addressDetails?.state,
            city: addressDetails?.city_name,
            district: addressDetails?.district_name,
            pincode: addressDetails?.pincode,
            taluka: addressDetails?.taluka_name
          });
          this.isAddressView = true;
          this.districtList = [];
          this.cityList = [];
          this.talukaList = [];
          this.pincodeList = [];
          this.districtList.push(addressDetails?.district_name);
          this.cityList.push(addressDetails?.city_name);
          if(addressDetails?.taluka_name != null && addressDetails?.taluka_name != undefined && addressDetails?.taluka_name != ""){
            this.talukaList.push(addressDetails?.taluka_name);
          }
          if(addressDetails?.pincode != null && addressDetails?.pincode != undefined && addressDetails?.pincode != ""){
            this.pincodeList.push(addressDetails?.pincode);
          }
        }
        else {
          if(response['data']?.msg){
            this.toaster.error(response['data']?.msg);
          }
        }
      },error => {
        if(error?.error?.data?.msg){
          this.toaster.error(error?.error?.data?.msg);
        }
      })
    }
    catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'addressByPinCode()',
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'addAddress',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  onRefresh() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl(currentUrl).then(() => {
      this.router.navigated = false;
      this.router.navigate(['/account/address']);
    });

  }

  async getGeoCities(checkdata) {
    try {
      this.geocityList = [];
      const ListGeoInput: GetModalAddressparameter =
        {} as GetModalAddressparameter;
      ListGeoInput.offset = 0;
      ListGeoInput.limit = 300;
      ListGeoInput.dropdown_type = 'city';
      ListGeoInput.search_city = checkdata;
      let cityresponse = await this.addressService.getGeoData(ListGeoInput);
      if (cityresponse['success'] === true) {
        this.geocityList = cityresponse['data']['Cities'];
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'getGeoCities(checkdata)',
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'PageEditAddressComponent',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  async addressBycity() {
    if (this.selectedCity.city_name === '') {
      return this.toaster.error('Please Select City');
    }
    //this.submitted = true;
    try {
      //this.submitted = false;
      let stategeodata, districtgeodata;
      const ListGeostateInput: GetModalAddressparameter =
        {} as GetModalAddressparameter;
      ListGeostateInput.offset = 0;
      ListGeostateInput.limit = 300;
      ListGeostateInput.dropdown_type = 'state';
      ListGeostateInput.multi_city = [this.selectedCity.city_name];
      let stateresponse = await this.addressService.getGeoData(
        ListGeostateInput
      );
      if (stateresponse['success'] === true) {
        stategeodata = stateresponse['data']['States'];
        this.stateList = [];
        for (var i = 0; i < stategeodata.length; i++) {
          this.stateList.push(this.formData(stategeodata[i]));
        }
      }
      const ListGeodistrictInput: GetModalAddressparameter =
        {} as GetModalAddressparameter;
      ListGeodistrictInput.offset = 0;
      ListGeodistrictInput.limit = 300;
      ListGeodistrictInput.dropdown_type = 'district';
      ListGeodistrictInput.multi_state = [this.stateList[0].code];
      ListGeodistrictInput.multi_city = [this.selectedCity.city_name];
      let districtresponse = await this.addressService.getGeoData(
        ListGeodistrictInput
      );
      if (districtresponse['success'] === true) {
        districtgeodata = districtresponse['data']['Districts'];
        this.districtList = [];
        for (var j = 0; j < districtgeodata.length; j++) {
          this.districtList.push(districtgeodata[j].district_name);
        }
      }
      if (stategeodata.length > 1) {
        this.addressForm.patchValue({
          state:
            this.stateList[0] && this.stateList[0].code
              ? this.stateList[0].code
              : '',
          district: this.districtList[0] ? this.districtList[0] : '',
          city: this.selectedCity.city_name ? this.selectedCity.city_name : '',
        });
        this.isAddressView = true;
        this.toaster.error(
          'Entered City is appearing in multiple states, please select the desired state.'
        );
        this.getCities('true');
      } else {
        this.addressForm.patchValue({
          state:
            this.stateList[0] && this.stateList[0].code
              ? this.stateList[0].code
              : '',
          district: this.districtList[0] ? this.districtList[0] : '',
          city: this.selectedCity.city_name ? this.selectedCity.city_name : '',
        });
        this.isAddressView = true;
        this.getDistricts('true', 'city');
        this.getCities('true');
        this.getTalukas('true');
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'addressBycity()',
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'PageEditAddressComponent',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  formData(data: any): StateData {
    const objstateData: StateData = {} as StateData;
    objstateData.code = data.state_code;
    objstateData.name = data.state_name;
    return objstateData;
  }

  SelectedCity(event) {
    this.selectedCity = event;
  }

  onlyNumberKey(event) {
    var charCode = event.keyCode;
    if (event.target.selectionStart === 0 && event.code === "Space") {
      event.preventDefault();
    }
    else{
    var ASCIICode = event.which ? event.which : event.keyCode;
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) return false;
    return true;
    }
  }

  clearAll(currentState) {
    if (currentState == null) {
      this.addressForm.reset();
      this.geocityList = [];
      this.isAddressView = false;
      this.searchByPincode = '';
    }
    else {
      this.addressBycity();
    }
  }

  alphanumbericOnly(event): boolean {
    var charCode = event.keyCode;
    if (event.target.selectionStart === 0 && event.code === "Space") {
      event.preventDefault();
    }
    else{
      if (charCode == 46 || charCode == 38 || charCode == 44 || charCode == 47 || charCode == 45) {
        return;
      }
      else {
        if ((charCode > 63 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode == 8 || charCode == 32 || (charCode >= 48 && charCode <= 57)) {
          return;
        }
        event.preventDefault();
      }
  }
  }
}

export class GetModalAddressparameter {
  dropdown_type: string;
  multi_state: any;
  multi_district: any;
  multi_taluka: any;
  multi_city: any;
  multi_pincode: any;
  search_city: string;
  offset: any;
  limit: any;
}

export class StateData {
  code: string;
  name: string;
}
