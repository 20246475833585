import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import * as CryptoJS from "crypto-js";

function _window(): any {
  // return the global native browser window object
  return window;
}

@Injectable({
  providedIn: "root",
})
export class PaymentService {
  constructor(@Inject(PLATFORM_ID) private platformId: object) {}
  get nativeWindow(): any {
    if (isPlatformBrowser(this.platformId)) {
      return _window();
    }
  }
  decrypt(encryptedBase64: string) {
    try {
        // Decode Base64
        const ciphertext = CryptoJS.enc.Base64.parse(encryptedBase64);

        // Extract IV and Key
        const bytes = CryptoJS.PBKDF2("edp@$3#drishti", "dRishtI", { keySize: 48 / 4, iterations: 128 });
        const iv = CryptoJS.lib.WordArray.create(bytes.words.slice(0, 4));
        const key = CryptoJS.lib.WordArray.create(bytes.words.slice(4, 12));

        // Decrypt using AES
        const decrypted = CryptoJS.AES.decrypt({ ciphertext }, key, { iv, mode: CryptoJS.mode.CBC });

        // Convert the result to a UTF-8 string
        const decryptedText = CryptoJS.enc.Utf8.stringify(decrypted);

        return decryptedText;
    } catch (ex) {
      return false;
    }
  }
}
