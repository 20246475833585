import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { UserService } from "src/app/shared/services/REST/user.service";
import { secureStorage } from "src/app/shared/services/securestorage";
declare var $: any;
@Component({
    selector: 'user-permission',
    templateUrl: './user-permission.component.html',
    styleUrls: ['./user-permission.component.scss']
})

export class UserPermissionComponent implements OnInit {
    PermissionList = [];
    modalRef: any;
    @ViewChild('modal', { read: TemplateRef, static: false }) template: TemplateRef<any>;
    VehicleFilterForm: FormGroup;
    totalrecord: any;
    inprocesstoDate: any;
    inprocessfromDate: any;
    inprocesstoDate1: any;
    inprocessfromDate1: any;
    today: any;
    currentPage = 1;
    vehicleList: any[];
    userIdData = [];
    RoleList = [];
    ShowPermission: boolean = false;
    roleid: any;
    showLoader: boolean = false;
    constructor(
        private formBuilder: FormBuilder,
        private userService: UserService,
        private router: Router,
        private localstorage: secureStorage,
    ) { }

    ngOnInit() { 
        this.buildForm();
        this.getRoleList();
    }

    buildForm() {
        this.today = new Date();
        this.VehicleFilterForm = this.formBuilder.group({
            'roleid': ['', [Validators.required]],
        });
    }

    getRoleList() {
        try {
            let user = JSON.parse(this.localstorage.getItem('userData'));
            if (user) {
                this.showLoader = true;
                this.userService.GetOtherUserRoleList({ account_pk: '' + user.account_pk }).subscribe(response => {
                    if (response) {
                        this.RoleList = response.data;
                        //this.OnselectedRole(this.RoleList[0].id,'')
                    }
                    else {
                    }
                    this.showLoader = false;
                },
                error => {
                    this.showLoader = false;
                })
            }
        }
        catch (error) {
            this.userService.InsertExceptionLog({ "method_name": "getRoleList()", "page_name": "PageViewPermissionComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
        }
    }

    OnselectedRole(value ) {
        try {
            this.roleid = value ? value : this.VehicleFilterForm.value.roleid;
            this.PermissionList = [];
            let user = JSON.parse(this.localstorage.getItem('userData'));
            if (user) {
                this.showLoader = true;
                this.userService.GetOtherUserRoleList({ account_pk: '' + user.account_pk, role_id: '' + this.roleid }).subscribe(response => {
                    if (response.success) {
                        this.ShowPermission = true;
                        this.PermissionList = response.data;
                        this.openmodel();
                    }
                    else {
                        this.ShowPermission = false;
                    }
                    this.showLoader = false;
                },
                error => {
                    this.showLoader = false;
                })
            }
        }
        catch (error) {
            this.userService.InsertExceptionLog({ "method_name": "OnselectedRole(value,page)", "page_name": "PageViewPermissionComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
        }
    }

    FormUserData(data): UserIdData {
        const objdata: UserIdData = {} as UserIdData;
        objdata.id = + data.account_pk;
        objdata.account_id = data.account_id;
        return objdata;
    }

    openmodel() {
        $(document).ready(function () {
          $('[data-toggle="popover"]').popover();
          $('#user-permission').DataTable({
            "dom": 't<"bottom"ipl><"clear">',
            'aoColumnDefs': [{
              'bSortable': false,
              'aTargets': [-1] /* 1st one, start by the right */
            }]
          });
        });
      }

}

export interface UserIdData {
    id: number;
    account_id: string;
  }