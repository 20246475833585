import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { formatDate } from '@angular/common';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { UserService } from 'src/app/shared/services/REST/user.service';
import { Router } from '@angular/router';
import { secureStorage } from 'src/app/shared/services/securestorage';
import { ProductService } from 'src/app/shared/services/REST/product.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Title } from '@angular/platform-browser';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
@Component({
  selector: 'app-my-combo-offer',
  templateUrl: './my-combo-offer.component.html',
  styleUrls: ['./my-combo-offer.component.scss']
})
export class MyComboOfferComponent implements OnInit {
  public dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  datatsetempty = false;
  dtOptions: DataTables.Settings = {};
  schemeList:any[] = [];
  userDetails:any;
  isNoDataFound:boolean=false;
  filterForm: FormGroup;
  isToggleOpened:boolean=false;
  fromDate:any;
  toDate:any;
  isFormValid:boolean=true;
  request:any = {};
  totalrecord:number=0;
  showLoader:boolean = false;
  @ViewChild('comboDiscountDetails', { read: TemplateRef, static: false }) comboDiscountDetails:TemplateRef<any>; 
  discount_code:string="";
  schemeDetailsModal:any;
  pinCode:string="";
  constructor(private appStateService:AppStateService,
    private fb:FormBuilder, 
    private toastrService:ToastrService, 
    private modalService:BsModalService,
    private userService:UserService, 
    private productService:ProductService,
    private router:Router,
    private title:Title,
    private localStorage:secureStorage ) { }

  ngOnInit(): void {
    this.buildFilterForm();
    this.userDetails = JSON.parse(this.localStorage.getItem('userData'));
    this.getOrderData();
    this.appStateService.refreshMySchemes.subscribe((val) => {
      if (val == true){
        this.getOrderData();
      }
    });
    this.title.setTitle('E-Dukaan | Combo Offers');
  }
  ngAfterViewInit() {
    this.dtTrigger.next();
  } 
  buildFilterForm(){
    this.filterForm = this.fb.group({
      discount_name:[''],
      from_date:[''],
      to_date:[''],
      discount_type:[''],
      is_active:['']
    })
  }
  filter() {
    this.isToggleOpened = true;
    $('.filter-container').toggle();
  }
  calldatatable() {
    try{
      if ( this.userDetails ) {
        this.pinCode = JSON.parse(this.localStorage.getItem('Pincode'));

        this.request = {
          account_pk:this.userDetails['account_pk'],
          discount_type:"market_basket",
          division_id:"",
          organization_id:"",
          discount_name:this.filterForm.controls['discount_name'].value?this.filterForm.controls['discount_name'].value:"",
          from_date: this.fromDate?this.fromDate:"",
          is_active:this.filterForm.controls['is_active'].value?this.filterForm.controls['is_active'].value:"",
          to_date:this.toDate?this.toDate:"",
          offset:0,
          limit:20,
          pincode:this.pinCode ?this.pinCode.toString():"",
          action_type:"get_market_basket_list"
          
        };

       
        this.dtOptions = {
            pagingType: 'full_numbers',
            responsive: true,
            serverSide: true,
            processing: true,
            lengthChange: false,
            searching: false,
            pageLength: 10,
            ordering: false,
            language: {
              info: 'Showing _START_ to _END_ of _TOTAL_ entries',
              infoEmpty: 'No records available',
              // infoFiltered: ' ',
            },
            ajax: (dataTablesParameters: any, callback) => {
                if (this.isFormValid) {
                  this.request['offset'] = dataTablesParameters.start;
                  this.schemeList = [];
                  this.totalrecord = 0;
                  this.showLoader = true;
                  this.productService.getMarketBasketList(this.request).subscribe(
                    (response) => {
                      window.scrollTo(0,0);
                      this.schemeList = [];
                      this.totalrecord = 0;
                      if (response.success) {
                        let today:Date = new Date();
                        this.schemeList = response.data;
                        this.totalrecord = response.total_result;
                        this.showLoader = false;
                      } else {
                        this.schemeList = [];
                        this.showLoader = false;
                        this.totalrecord = 0;
                        if (response.data.msg == 'Data not found') {
                          this.datatsetempty = true;
                        }
                      }
                      callback({
                        recordsTotal: this.totalrecord,
                        recordsFiltered: this.totalrecord,
                        data: [],
                      });
                    },
                    (err) => {
                      this.schemeList = [];
                      this.showLoader = false;
                    }
                  );
                }
            },
        };
      }
    }catch(error){
      this.userService.InsertExceptionLog({
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'MyComboOfferComponent',
        method_name: 'calldatatable()',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }
  reset(event){
    if(this.isToggleOpened){
      $('.filter-container').toggle();
      this.isToggleOpened = false;
    }
    this.isFormValid = true;
    this.fromDate = "";
    this.toDate = "";
    this.filterForm.reset();
    this.getOrderData();
  }
  getOrderData() {
    try {
      document.body.scrollTop = 0;
      if (this.filterForm.value.from_date) {
        this.fromDate = formatDate(new Date(this.filterForm.value.from_date), 'yyyy-MM-dd', 'en-US');
      }
      if (this.filterForm.value.to_date) {
        this.toDate = formatDate(new Date(this.filterForm.value.to_date), 'yyyy-MM-dd', 'en-US');
      }
      this.dtOptions.destroy = true;
      this.calldatatable();
      this.dtTrigger.next();
    }catch(error){
      this.userService.InsertExceptionLog({
          method_name: 'getOrderData(page, type)',
          page_name: 'MyComboOfferComponent',
          portal_type: 'Customer',
          page_url: this.router.url,
          error_message: error.message,
          inner_exception: error,
      });
    }
  }
  applyFilter(){ 
    if (
      this.filterForm.value.from_date &&
      this.filterForm.value.to_date &&
      !(this.filterForm.value.to_date >= this.filterForm.value.from_date)
    ) {
      return this.toastrService.error(
        'To date should be greater than or equal to From date'
      );
    } else if (this.filterForm.value.from_date && !this.filterForm.value.to_date) {
      this.isFormValid = false;
      return this.toastrService.error('To date is required');
    } else if (!this.filterForm.value.from_date && this.filterForm.value.to_date) {
      this.isFormValid = false;
      return this.toastrService.error('From date is required');
    }else{  
      if(this.isToggleOpened){
        $('.filter-container').toggle();
        this.isToggleOpened = false;
      }
      this.isFormValid = true;
      this.getOrderData();
    }
  }
  details(scheme_code){
    this.discount_code = scheme_code;
    this.schemeDetailsModal = this.modalService.show(this.comboDiscountDetails, {
      class: 'modal-dialog-centered modal-xl', backdrop: 'static',
    });
  }
  closeDetails(){
    this.schemeDetailsModal.hide();
  }
}
