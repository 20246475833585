<ng-container *ngIf="!ismysavingsHide() && isLoggedIn">
  <div class="tab-content" id="my-saving-btn" *ngIf="userData?.user_type != 'MECH'">
    <div class="mysaving d-flex position-fixed fw-bold zindex-2 top-40 end-0 transform-90 mt-lg-20" id="rotate-text">
      <button class="shadow btn btn-flex h-35px bg-btnn btn-color-gray-700 btn-active-color-gray-900 shadow-sm rounded-top-0" (click)="collapseSideBar()"><i class="fa fa-chevron-left"></i><span style="margin-left: -28px;" id="myDiv">My Savings</span></button>
    </div>
  </div>
  <div id="mySidenav" class="sidenav shadow">
      <div class="container header-bg" *ngIf="userData?.user_type != 'MECH'">
        <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</a>
        <h4 class="header-txt">My Savings</h4>
        <hr style="color: #ebedf7;">
      </div>
      <div class="container">
        <p style="font-size: 16px;"><strong>You have saved Rs.</strong> <span id="amountDisplay" class="price-txt" [style.color]="amount <= 0 ? 'red' : 'green'">₹{{ amount }}</span>
        <ng-container *ngIf="(!toDate && !fromDate) || (toDate=='' || fromDate == '')"><strong>in this month</strong></ng-container></p> 
      <ul class="filter-area" style="padding-left: 0px;">
        <li style="list-style: none;">
          <h3 class="title" style="font-size: 15px;"><strong>Select Date</strong></h3>
          <div class="row">
            <div class="col-md-5">
              <div class="date-picker-container">
                <mat-form-field style="display: inline">
                  <input matInput [max]="today" [(ngModel)]="fromDate" placeholder="From Date" (click)="picker4?.open()" readonly [matDatepicker]="picker4" />
                  <!-- [formControl]="inProcessFilterForm.controls['start']" -->
                  <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                  <mat-datepicker #picker4 disabled="false"></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-5">
              <div class="date-picker-container">
                <mat-form-field style="display: inline">
                  <input matInput [max]="today" [(ngModel)]="toDate" placeholder="To Date" (click)="dp4?.open()" readonly [matDatepicker]="dp4" />
                  <!-- [formControl]="inProcessFilterForm.controls['end']" -->
                  <mat-datepicker-toggle matSuffix [for]="dp4"></mat-datepicker-toggle>
                  <mat-datepicker #dp4 disabled="false"></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-2 d-flex justify-content-center">
              <span  class="material-icons icon-search cursor" (click)="search()"> search </span>
              <span  class="material-icons icon-reset cursor" (click)="reset()" style="margin-right: 20px; margin-left: 10px;"> refresh </span>
            </div>
          </div>  
        </li>
        <li style="list-style: none;" *ngIf="route != '/orders/ecom-orders'">
          <div class=" d-table-cell">
            <p class="redirect-txt" [routerLink]="['/orders/ecom-orders']">See orderwise savings</p>
          </div>
        </li>
      </ul>
      </div>
  </div>
</ng-container>

<ng-container *ngIf="showLoader">
  <div class="loader">
    <div class="ng-spinner ng-spinner-icon"></div>
  </div>
</ng-container>