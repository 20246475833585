<div class="product-details-layout">
  <div class="container-fluid">
    <div class="box-prod-detail">
      <app-page-header [header]="'My Account'" [breadcrumbs]="breadcrumbs"></app-page-header>
      <ng-container *ngIf="showProduct">
        <ng-container *ngIf="!productList?.length; else productDetails">
          <div class="row" *ngFor="let item of userService.generateFake(1)">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div class="card shadow">
                <div class="card-body">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-5 col-lg-5 colxl-5 col-xxl-5">
                      <div class="slider-wrapper">
                        <ul class="slider-thumb">
                          <li class="type-image" *ngFor="let image of userService.generateFake(8);">
                            <div class="img-fluid">
                              <skc-rectangle [loading]="loading" [skc-height]="'50px'" [skc-width]="'100%'"> </skc-rectangle>
                            </div>
                          </li>
                        </ul>
                        <ul class="slider-preview">
                          <li class="type-image" *ngFor="let image of userService.generateFake(4);">
                            <div class="img-fluid">
                              <skc-rectangle [loading]="loading" [skc-height]="'450px'" [skc-width]="'100%'"> </skc-rectangle>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-4 colxl-4 col-xxl-4 position-relative">
                      <div class="prod-cont-details pe-5">
                        <div class="prod-cont-heading">
                          <h4 class="modal-no mb-3">
                            <skc-rectangle [loading]="loading" [skc-height]="'19px'" [skc-width]="'100%'"> </skc-rectangle>
                          </h4>
                          <h6 class="ellipse-2line" style="cursor: pointer;">
                            <skc-rectangle [loading]="loading" [skc-height]="'48px'" [skc-width]="'100%'"> </skc-rectangle>
                          </h6>
                          <div class="delivery-time w-30 d-inline-block">
                            <skc-rectangle [loading]="loading" [skc-height]="'18px'" [skc-width]="'100%'"> </skc-rectangle>
                            <skc-rectangle [loading]="loading" [skc-height]="'18px'" [skc-width]="'100%'"> </skc-rectangle>
                            <skc-rectangle [loading]="loading" [skc-height]="'18px'" [skc-width]="'100%'"> </skc-rectangle>
                          </div>
                        </div>
                        <div class="price mt-4">
                          <skc-rectangle [loading]="loading" [skc-height]="'18px'" [skc-width]="'100%'"> </skc-rectangle>
                        </div>
                        <div class="d-flex pt-4 pb-4 quantity-sec align-items-center">
                          <skc-rectangle [loading]="loading" [skc-height]="'22px'" [skc-width]="'100%'"> </skc-rectangle>
                          <skc-rectangle [loading]="loading" [skc-height]="'22px'" [skc-width]="'100%'"> </skc-rectangle>
                          <skc-rectangle [loading]="loading" [skc-height]="'22px'" [skc-width]="'100%'"> </skc-rectangle>
                        </div>
                        <a >
                          <skc-rectangle [loading]="loading" [skc-height]="'40px'" [skc-width]="'100%'"> </skc-rectangle>
                        </a>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3 col-lg-3 colxl-3 col-xxl-3 border-start">
                      <h5 class="highlights"><skc-rectangle [loading]="loading" [skc-height]="'22px'" [skc-width]="'100%'"> </skc-rectangle></h5>
                      <ul class="lists-highlights" *ngFor="let image of userService.generateFake(4);">
                        <li><skc-rectangle [loading]="loading" [skc-height]="'20px'" [skc-width]="'100%'"> </skc-rectangle></li>
                      </ul>
                      <div class="form-highlights-ques" *ngIf="isLoggedIn">
                        <h5 class="highlights"><skc-rectangle [loading]="loading" [skc-height]="'44px'" [skc-width]="'100%'"> </skc-rectangle></h5>
                        <div class="form-control fs-6">
                          <skc-rectangle [loading]="loading" [skc-height]="'86px'" [skc-width]="'100%'"> </skc-rectangle>
                        </div>
                        <div class="text-end mt-2">
                          <a class="text-decoration-none text-uppercase btn-anchor" role="button">
                            <skc-rectangle [loading]="loading" [skc-height]="'19px'" [skc-width]="'100%'"> </skc-rectangle>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #productDetails>
          <div class="row" *ngFor="let product of productList">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div class="card shadow">
                <div class="card-body">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-5 col-lg-5 colxl-5 col-xxl-5">
                      <!-- product image -->
                        <!-- <owl-carousel-o [options]="customOptions">
                          <ng-container *ngFor="let image of product?.multi_img">
                              <ng-template carouselSlide [id]="image.img">
                                      <div class="me-2">
                                          <div class="card">
                                              <div class="card-body cursor">
                                                      <img [src]="image.img" onerror="this.src='./assets/tata-vehicle.jfif';"
                                                      style="cursor: pointer;" class="img-fluid" alt="prod-img-alt">
                                                
                                              </div>
                                          </div>
                                      </div>
                              </ng-template>
                          </ng-container>
                      </owl-carousel-o> -->
                      <div class="slider-wrapper">
                        <ng-container *ngIf="showStaticImage">
                          <!-- <ng-container *ngIf="partNumber == '885425201613'">
                            <ng-container *ngFor="let item of xenonImages;let i = index">
                              <ul class="slider-thumb">
                                <li class="type-image" *ngFor="let image of item?.miniImg;let j = index">
                                  <img src="./../../../../assets/pdp-images/Xenon-Images/mini-images/{{image}}" 
                                       (click)="selectImage(image,j,'xenon')"
                                       style="cursor: pointer;" class="img-fluid" alt="prod-img-alt">
                                </li>
                              </ul>
                          
                              <ul  class="slider-preview" *ngIf="medimTemparr.length > 0">
                                <li class="type-image" *ngFor="let mimage of medimTemparr">
                                  <img style="cursor: pointer;" (click)="openFullimage(mimage,'xenon')"
                                       src="./../../../../assets/pdp-images/Xenon-Images/medium-images/{{mimage}}" 
                                       onerror="this.src='./assets/tata-vehicle.jfif';" 
                                       class="img-fluid" alt="prod-img-alt">
                                </li>
                              </ul>
                            </ng-container>
                          </ng-container> -->
                          <ng-container *ngIf="partNumber == '203241100118'">
                            <ng-container *ngFor="let item of ganeshImages;let i = index">
                              <ul class="slider-thumb">
                                <li class="type-image" *ngFor="let image of item?.miniImg;let j = index">
                                  <img [src]="image" 
                                       (click)="selectImage(image,j,'ganesh')"
                                       style="cursor: pointer;" class="img-fluid" alt="prod-img-alt">
                                </li>
                              </ul>
                          
                              <ul  class="slider-preview" *ngIf="ganeshMediumarr.length > 0">
                                <li class="type-image" *ngFor="let mimage of ganeshMediumarr">
                                  <img style="cursor: pointer;" (click)="openFullimage(mimage,'ganesh')"
                                       [src]="mimage" 
                                       onerror="this.src='./assets/tata-vehicle.jfif';" 
                                        alt="prod-img-alt">
                                </li>
                              </ul>
                            </ng-container>
                          </ng-container>
                          <ng-container *ngIf="partNumber == '285426203104'">
                            <!-- <ng-container *ngFor="let item of PartsImages;let i = index">
                              <ul class="slider-thumb">
                                <li class="type-image" *ngFor="let image of item?.miniImg;let j = index">
                                  <img src="./../../../../assets/pdp-images/Parts-Images/mini-images/{{image}}" 
                                       (click)="selectImage(image,j,'parts')"
                                       style="cursor: pointer;" class="img-fluid" alt="prod-img-alt">
                                </li>
                              </ul>
                          
                              <ul class="slider-preview" *ngIf="partsMediumImg.length > 0" >
                                <li class="type-image" *ngFor="let mimage of partsMediumImg">
                                  <img style="cursor: pointer;" (click)="openFullimage(mimage,'parts')"
                                       src="./../../../../assets/pdp-images/Parts-Images/medium-images/{{mimage}}" 
                                       onerror="this.src='./assets/tata-vehicle.jfif';" 
                                       alt="prod-img-alt">
                                </li>
                              </ul>
                            </ng-container> -->
                            <ng-container *ngFor="let item of PartsImages;let i = index">
                              <ul class="slider-thumb">
                                <li class="type-image" *ngFor="let image of item?.miniImg;let j = index">
                                  <img [src]="image" 
                                       (click)="selectImage(image,j,'parts')"
                                       style="cursor: pointer;" class="img-fluid" alt="prod-img-alt">
                                </li>
                              </ul>
                          
                              <ul class="slider-preview" *ngIf="partsMediumImg.length > 0" >
                                <li class="type-image" *ngFor="let mimage of partsMediumImg">
                                  <img style="cursor: pointer;" (click)="openFullimage(mimage,'parts')"
                                       [src]="mimage" 
                                       onerror="this.src='./assets/tata-vehicle.jfif';" 
                                       alt="prod-img-alt">
                                </li>
                              </ul>
                            </ng-container>
                          </ng-container>
                          <ng-container *ngIf="partNumber == '253414605802'">
                            <ng-container *ngFor="let item of xenonImages;let i = index">
                              <ul class="slider-thumb">
                                <li class="type-image" *ngFor="let image of item?.miniImg;let j = index">
                                  <img [src]="image" 
                                       (click)="selectImage(image,j,'xenon')"
                                       style="cursor: pointer;" class="img-fluid" alt="prod-img-alt">
                                </li>
                              </ul>
                          
                              <ul  class="slider-preview" *ngIf="medimTemparr.length > 0">
                                <li class="type-image" *ngFor="let mimage of medimTemparr">
                                  <img style="cursor: pointer;" (click)="openFullimage(mimage,'xenon')"
                                       [src]="mimage" 
                                       onerror="this.src='./assets/tata-vehicle.jfif';" 
                                        alt="prod-img-alt">
                                </li>
                              </ul>
                            </ng-container>
                          </ng-container>
                          <ng-container *ngIf="partNumber == '511854600146'">
                            <ng-container *ngFor="let item of part511854600146Img;let i = index">
                              <ul class="slider-thumb">
                                <li class="type-image" *ngFor="let image of item?.miniImg;let j = index">
                                  <img [src]="image" 
                                       (click)="selectImage(image,j,'partfour')"
                                       style="cursor: pointer;" class="img-fluid" alt="prod-img-alt">
                                </li>
                              </ul>
                          
                              <ul  class="slider-preview" *ngIf="partfourTemparr.length > 0">
                                <li class="type-image" *ngFor="let mimage of partfourTemparr">
                                  <img style="cursor: pointer;" (click)="openFullimage(mimage,'partfour')"
                                       [src]="mimage" 
                                       onerror="this.src='./assets/tata-vehicle.jfif';" 
                                        alt="prod-img-alt">
                                </li>
                              </ul>
                            </ng-container>
                          </ng-container>
                          <ng-container *ngIf="partNumber == '580326513803'">
                            <ng-container *ngFor="let item of part580326513803Img;let i = index">
                              <ul class="slider-thumb">
                                <li class="type-image" *ngFor="let image of item?.miniImg;let j = index">
                                  <img [src]="image" 
                                       (click)="selectImage(image,j,'partfive')"
                                       style="cursor: pointer;" class="img-fluid" alt="prod-img-alt">
                                </li>
                              </ul>
                          
                              <ul  class="slider-preview" *ngIf="partfiveTemparr.length > 0">
                                <li class="type-image" *ngFor="let mimage of partfiveTemparr">
                                  <img style="cursor: pointer;" (click)="openFullimage(mimage,'partfive')"
                                       [src]="mimage" 
                                       onerror="this.src='./assets/tata-vehicle.jfif';" 
                                        alt="prod-img-alt">
                                </li>
                              </ul>
                            </ng-container>
                          </ng-container>
                          <ng-container *ngIf="partNumber == '273235305113'">
                            <ng-container *ngFor="let item of part885425202518Img;let i = index">
                              <ul class="slider-thumb">
                                <li class="type-image" *ngFor="let image of item?.miniImg;let j = index">
                                  <img [src]="image" 
                                       (click)="selectImage(image,j,'partsix')"
                                       style="cursor: pointer;" class="img-fluid" alt="prod-img-alt">
                                </li>
                              </ul>
                          
                              <ul  class="slider-preview" *ngIf="partsixTemparr.length > 0">
                                <li class="type-image" *ngFor="let mimage of partsixTemparr">
                                  <img style="cursor: pointer;" (click)="openFullimage(mimage,'partsix')"
                                       [src]="mimage" 
                                       onerror="this.src='./assets/tata-vehicle.jfif';" 
                                        alt="prod-img-alt">
                                </li>
                              </ul>
                            </ng-container>
                          </ng-container>
                          <ng-container *ngIf="partNumber == '13150611614'">
                            <ng-container *ngFor="let item of part13150611614Img;let i = index">
                              <ul class="slider-thumb">
                                <li class="type-image" *ngFor="let image of item?.miniImg;let j = index" style="width: 60px;height: 34px;padding: 0px;">
                                  <img [src]="image" 
                                       (click)="selectImage(image,j,'partseven')"
                                       style="cursor: pointer;" class="img-fluid" alt="prod-img-alt">
                                </li>
                              </ul>
                          
                              <ul  class="slider-preview" *ngIf="partsevenTemparr.length > 0">
                                <li class="type-image" *ngFor="let mimage of partsevenTemparr" style="height: 299px;">
                                  <img style="cursor: pointer;" (click)="openFullimage(mimage,'partseven')"
                                       [src]="mimage" 
                                       onerror="this.src='./assets/tata-vehicle.jfif';" 
                                        alt="prod-img-alt">
                                </li>
                              </ul>
                            </ng-container>
                          </ng-container>
                          <ng-container *ngIf="partNumber == '250526207903'">
                            <ng-container *ngFor="let item of part250526207903Img;let i = index">
                              <ul class="slider-thumb">
                                <li class="type-image" *ngFor="let image of item?.miniImg;let j = index" style="width: 60px;height: 34px;padding: 0px;">
                                  <img [src]="image" 
                                       (click)="selectImage(image,j,'parteight')"
                                       style="cursor: pointer;" class="img-fluid" alt="prod-img-alt">
                                </li>
                              </ul>
                          
                              <ul  class="slider-preview" *ngIf="parteightTemparr.length > 0">
                                <li class="type-image" *ngFor="let mimage of parteightTemparr" style="height: 299px;">
                                  <img style="cursor: pointer;" (click)="openFullimage(mimage,'parteight')"
                                       [src]="mimage" 
                                       onerror="this.src='./assets/tata-vehicle.jfif';" 
                                        alt="prod-img-alt">
                                </li>
                              </ul>
                            </ng-container>
                          </ng-container>
                          <ng-container *ngIf="partNumber == '250526706009'">
                            <ng-container *ngFor="let item of part250526706009Img;let i = index">
                              <div class="row">
                                <div class="col-2">
                                  <ul class="slider-thumb">
                                    <li class="type-image" *ngFor="let image of item?.miniImg;let j = index" style="width: 110px;height: 60px;padding: 0px; text-align: center;">
                                      <img [src]="image" 
                                           (click)="selectImage(image,j,'partnine')"
                                           style="cursor: pointer;margin: auto;" class="img-fluid" alt="prod-img-alt">
                                    </li>
                                  </ul>
                                </div>
                                <div class="col-10">
                                  <ul  class="slider-preview" *ngIf="partnineTemparr.length > 0">
                                    <li class="type-image" *ngFor="let mimage of partnineTemparr" style="height: 280px;">
                                      <img style="cursor: pointer;" (click)="openFullimage(mimage,'partnine')"
                                           [src]="mimage" 
                                           onerror="this.src='./assets/tata-vehicle.jfif';" 
                                            alt="prod-img-alt">
                                    </li>
                                  </ul>
                                </div>
                              </div>
                       
                          
                            
                            </ng-container>
                          </ng-container>
                          <ng-container *ngIf="partNumber == '251526203802'">
                            <ng-container *ngFor="let item of part251526203802Img;let i = index">
                              <ul class="slider-thumb">
                                <li class="type-image" *ngFor="let image of item?.miniImg;let j = index" style="width: 60px;height: 34px;padding: 0px;">
                                  <img [src]="image" 
                                       (click)="selectImage(image,j,'partten')"
                                       style="cursor: pointer;" class="img-fluid" alt="prod-img-alt">
                                </li>
                              </ul>
                          
                              <ul  class="slider-preview" *ngIf="parttenTemparr.length > 0">
                                <li class="type-image" *ngFor="let mimage of parttenTemparr" style="height: 299px;">
                                  <img style="cursor: pointer;" (click)="openFullimage(mimage,'partten')"
                                       [src]="mimage" 
                                       onerror="this.src='./assets/tata-vehicle.jfif';" 
                                        alt="prod-img-alt">
                                </li>
                              </ul>
                            </ng-container>
                          </ng-container>
                          <ng-container *ngIf="partNumber == '251626307906'">
                            <ng-container *ngFor="let item of part251626307906Img;let i = index">
                              <ul class="slider-thumb">
                                <li class="type-image" *ngFor="let image of item?.miniImg;let j = index" style="width: 60px;height: 34px;padding: 0px;">
                                  <img [src]="image" 
                                       (click)="selectImage(image,j,'parteleven')"
                                       style="cursor: pointer;" class="img-fluid" alt="prod-img-alt">
                                </li>
                              </ul>
                          
                              <ul  class="slider-preview" *ngIf="partelevenTemparr.length > 0">
                                <li class="type-image" *ngFor="let mimage of partelevenTemparr" style="height: 299px;">
                                  <img style="cursor: pointer;" (click)="openFullimage(mimage,'parteleven')"
                                       [src]="mimage" 
                                       onerror="this.src='./assets/tata-vehicle.jfif';" 
                                        alt="prod-img-alt">
                                </li>
                              </ul>
                            </ng-container>
                          </ng-container>
                          <ng-container *ngIf="partNumber == '580725601601'">
                            <ng-container *ngFor="let item of part580725601601Img;let i = index">
                              <ul class="slider-thumb">
                                <li class="type-image" *ngFor="let image of item?.miniImg;let j = index"  style="width: 60px;height: 34px;padding: 0px;">
                                  <img [src]="image" 
                                       (click)="selectImage(image,j,'partTwlve')"
                                       style="cursor: pointer;" class="img-fluid" alt="prod-img-alt">
                                </li>
                              </ul>
                          
                              <ul  class="slider-preview" *ngIf="partTwelveTemparr.length > 0">
                                <li class="type-image" *ngFor="let mimage of partTwelveTemparr" style="height: 299px;">
                                  <img style="cursor: pointer;" (click)="openFullimage(mimage,'partTwlve')"
                                       [src]="mimage" 
                                       onerror="this.src='./assets/tata-vehicle.jfif';" 
                                        alt="prod-img-alt">
                                </li>
                              </ul>
                            </ng-container>
                          </ng-container>
                          <ng-container *ngIf="partNumber == '571001155313'">
                            <ng-container *ngFor="let item of part571001155313Img;let i = index">
                              <ul class="slider-thumb">
                                <li class="type-image" *ngFor="let image of item?.miniImg;let j = index"  style="width: 60px;height: 34px;padding: 0px;">
                                  <img [src]="image" 
                                       (click)="selectImage(image,j,'partThirteen')"
                                       style="cursor: pointer;" class="img-fluid" alt="prod-img-alt">
                                </li>
                              </ul>
                          
                              <ul  class="slider-preview" *ngIf="partThirteenTemparr.length > 0">
                                <li class="type-image" *ngFor="let mimage of partThirteenTemparr" style="height: 299px;">
                                  <img style="cursor: pointer;" (click)="openFullimage(mimage,'partThirteen')"
                                       [src]="mimage" 
                                       onerror="this.src='./assets/tata-vehicle.jfif';" 
                                        alt="prod-img-alt">
                                </li>
                              </ul>
                            </ng-container>
                          </ng-container>
                          <ng-container *ngIf="partNumber == '269126204651'">
                            <ng-container *ngFor="let item of part269126204651Img;let i = index">
                              <ul class="slider-thumb">
                                <li class="type-image" *ngFor="let image of item?.miniImg;let j = index"  style="width: 60px;height: 34px;padding: 0px;">
                                  <img [src]="image" 
                                       (click)="selectImage(image,j,'partEourteen')"
                                       style="cursor: pointer;" class="img-fluid" alt="prod-img-alt">
                                </li>
                              </ul>
                          
                              <ul  class="slider-preview" *ngIf="partFourteenTemparr.length > 0">
                                <li class="type-image" *ngFor="let mimage of partFourteenTemparr" style="height: 299px;">
                                  <img style="cursor: pointer;" (click)="openFullimage(mimage,'partEourteen')"
                                       [src]="mimage" 
                                       onerror="this.src='./assets/tata-vehicle.jfif';" 
                                        alt="prod-img-alt">
                                </li>
                              </ul>
                            </ng-container>
                          </ng-container>
                          <ng-container *ngIf="partNumber == '551747100150'">
                            <ng-container *ngFor="let item of part551747100150Img;let i = index">
                              <ul class="slider-thumb">
                                <li class="type-image" *ngFor="let image of item?.miniImg;let j = index" style="width: 60px;height: 50px;padding: 0px;">
                                  <img [src]="image"
                                       (click)="selectImage(image,j,'partFifteen')" height="50px" width="100%"
                                        class="img-fluid" alt="prod-img-alt" >
                                </li>
                              </ul>
                          
                              <ul  class="slider-preview" *ngIf="partFifteenTemparr.length > 0">
                                <li class="type-image" *ngFor="let mimage of partFifteenTemparr" style="height: 299px;">
                                  <img style="cursor: pointer;" (click)="openFullimage(mimage,'partFifteen')"
                                       [src]="mimage" 
                                       onerror="this.src='./assets/tata-vehicle.jfif';" 
                                        alt="prod-img-alt" style="cursor: pointer;height: 300px;width: 100%;">
                                </li>
                              </ul>
                            </ng-container>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!showStaticImage">
                          <ul class="slider-thumb">
                            <!-- <li class="type-image" *ngFor="let image of images">
                            <img [src]="image.url" class="img-fluid" alt="prod-img-alt">
                          </li> -->
                          <li class="type-image" *ngFor="let image of product?.multi_img;let i = index" style="width: 60px;height: 40px;padding: 0px;">
                            <img [src]="image.img + '/resize/60x36'" onerror="this.src='./assets/tata-vehicle.jfif';" (mouseover)="selectImage(image.img,i,'partFifteen')"
                              style="cursor: pointer;" class="img-fluid" alt="prod-img-alt" (click)="selectImg(image.img,i,'thumbnil')">
                          </li>
                          </ul>
    
                          <ul class="slider-preview">
                            <li class="type-image" *ngFor="let image of imageTempArray;let j = index" (click)="selectImg(image,j,'fullScreen')" style="cursor: pointer;">
                              <img [src]="image" onerror="this.src='./assets/tata-vehicle.jfif';" class="img-fluid"
                                alt="prod-img-alt">
                            </li>
                          </ul>
                        </ng-container>
                     
                        <ng-container  *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                        <div class="product-offer" *ngIf="normalPercentage > 0 && !isQuantityDiscountAvailable">
                          <!-- {{(product?.base_discount + product?.scheme_discount +
                        product?.tml_rule_discount + product?.dealer_rule_discount +
                        product?.rule_discount + product?.distributor2_discount_amount )}} % -->
  
                          {{'SAVE ' + normalPercentage + '%'}}
  
                          <!-- <span>2 days left</span> -->
                        </div>
                        <div class="product-offer" *ngIf="quantityBasedPercentage > 0 && isQuantityDiscountAvailable">
                          <!-- {{(product?.base_discount + product?.scheme_discount +
                        product?.tml_rule_discount + product?.dealer_rule_discount +
                        product?.rule_discount + product?.distributor2_discount_amount)}} % -->
                          {{'SAVE ' + quantityBasedPercentage + '%'}}
                          <!-- <span>2 days left</span> -->
                        </div>
                        </ng-container>
                        <!-- ShowEngine() -->
                        <div class="view360" (click)="getCompatibleModel(partNumber)" style="cursor: pointer;">
                          <img alt="scale2" src="./assets/images/scale.svg" />
                        </div>
                        <!-- <div class="view360">
                          <img alt="" src="./assets/images/360.svg" />
                        </div>
                        <div class="viewvideo">
                          <img alt="" src="./assets/images/video.svg" />
                        </div>
                        <div class="viewscale" (click)="ShowEngine()" style="cursor: pointer;">
                          <img alt="scale" src="./assets/images/scale.svg" />
                        </div> -->
                      </div>
                      <!-- <img [src]="product?.multi_img[0].img" class="img-fluid" alt="prod-img-alt"> -->
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-4 colxl-4 col-xxl-4 position-relative">
                      <div class="bookmark-share">
                        <div class="btn-grop">
                          <!-- <a href="http://"><img alt="" src="./assets/images/share.svg" /></a> -->
                          <!-- <a href="http://" class="ms-3"><img alt="" src="./assets/images/bookmark.svg" /></a> -->
                        </div>
                      </div>
                      <div class="prod-cont-details pe-5">
                        <div class="prod-cont-heading">
                          <div id="socialShare" class="btn-group share-group">
                            <a data-toggle="dropdown" class="btn btn-info" style="border: 1px solid #000; border-radius: 8px; padding: 2px; margin-left: 5px;">
                                 <i class="fa fa-share-alt fa-inverse" data-toggle="tooltip" data-placement="top" title="Click here to share product"></i>
                                 <span style="margin-left: 5px;">share</span>

                                </a>
                    <ul class="dropdown-menu p-2" style="min-width: 20px; z-index: 5;">
                        <li>
                          <div>
                            <a data-toggle="tooltip" title="WhatsApp" target="_blank"
                              rel="noopener noreferrer" (click)="share('whatsapp')" style="cursor: pointer;">
                              <img alt="WhatsApp" src="./assets/icons/WhatsApp_icon.png" height="25px"/>
                            </a>
                          </div>
                      </li>
                      <li>
                        <div>
                          <a data-toggle="tooltip" title="Twitter" (click)="share('Twitter')" style="cursor: pointer;"
                            rel="noopener noreferrer">
                            <img alt="Twitter" src="./assets/icons/twitter.png" height="22px" class="mt-3"/>
                          </a>
                        </div>
                      </li>					
                      <li>
                        <div>
                          <a data-toggle="tooltip" title="Facebook" (click)="share('Facebook')" style="cursor: pointer;"
                            rel="noopener noreferrer">
                            <img alt="Facebook" src="./assets/icons/Facebook.png" height="22px" class="mt-3"/>
                          </a>
                        </div>
                      </li>
                        <li>
                          <div>
                            <a data-toggle="tooltip" title="Mail" target="_blank" rel="noopener noreferrer" (click)="share('mail')">
                              <img alt="Mail" src="./assets/icons/mail.png" height="22px" class="mt-3"/>
                            </a>
                          </div>
                      </li>
                      <li>
                        <div>
                          <a data-toggle="tooltip" class="cursor-pointer" title="Copy link" style="cursor: pointer;"
                            (click)="copyMessage()" target="_blank" rel="noopener noreferrer">
                            <img alt="Copy link" src="./assets/icons/link.png" height="22px" class="mt-3"/>
                          </a>
                        </div>
                      </li>
                            </ul>
                  </div>
                          <app-wishlist-icon [product]="product" [layout]="'Nolayout'" [isWishlistedItem]="isWishlistedItem"></app-wishlist-icon>
                         
                          <h4 class="ellipse-2line" style="cursor: pointer; line-height: 35px;"
                            title="{{ (product.short_description == '' || product.short_description == null) ? '-' : product.short_description}}">
                            {{(product.short_description == " " || product.short_description == null) ? "-" :
                            product.short_description}}</h4>
                            <h6 class="modal-no " style="display: flex;">{{ product?.part_number }}
                            </h6>
                          <div class="delivery-time w-30 d-inline-block" *ngIf="product?.hsn_code">
                            HSN: <span class="fw-bold">{{ product?.hsn_code }}</span>
                          </div>&nbsp;&nbsp;
                          <div class="delivery-time w-30 d-inline-block text-end" *ngIf="product?.tax_slab">
                            GST: <span class="fw-bold">{{ product?.tax_slab }}%</span>
                          </div>
                        </div>
                        <p *ngIf="( product.resource_type == 'Pack' && product.moq > 1 )" class="tmgo mb-2">
                          <!-- <span class="save-mode">Pack of {{product.moq | number: '1.0-0'}} {{product.uom}}</span><br/> -->
                          (MRP is applicable for 1 Unit =
                          {{product.moq | number :'1.0-0'}}<span *ngIf="product.moq > 1">Quantities</span><span *ngIf="product.moq <= 1">Quantity</span>
                          )</p>  
                        <ng-container *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true);else nslpartCondition">
                        <ng-container *ngIf="baseProductdiscount">
                          <div class="price mt-4" *ngIf="isNormalDiscount && Productdiscount < ProductMRP && !isQuantityDiscountAvailable;">
                            {{ Productdiscount | currencyFormat :'INR' }}
                            <span class="actual-mrp"> MRP {{ ProductMRP |
                              currencyFormat :'INR' }} </span>
                          </div>
                          <div class="price mt-4" *ngIf="!isNormalDiscount && !isQuantityDiscountAvailable && quantityBasedPartPrice === 0;">
                            {{ ProductMRP | currencyFormat :'INR' }}
                            <!-- <span class="ms-3">MRP 12,290.00 </span> -->
                          </div>
                          <div class="price mt-4" *ngIf="!isNormalDiscount && !isQuantityDiscountAvailable && quantityBasedPartPrice > 0;">
                            {{ ProductMRP | currencyFormat :'INR' }}
                            <!-- <span class="ms-3">MRP 12,290.00 </span> -->
                          </div>
                          <div class="price mt-4" *ngIf="isQuantityDiscountAvailable && quantityBasedPartPrice > 0;">
                            {{ quantityBasedPartPrice | currencyFormat :'INR' }}
                            <span class="actual-mrp" *ngIf="quantityBasedPartPrice != ProductMRP"> MRP {{ ProductMRP |
                                currencyFormat :'INR' }} </span>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="!baseProductdiscount">
                          <div class="price mt-4">
                            <i></i>{{ ProductMRP | currencyFormat :'INR' }}
                            <!-- <span class="ms-3">MRP 12,290.00 </span> -->
                          </div>
                        </ng-container>
                      </ng-container>
                      <ng-template #nslpartCondition>
                        <div class="price mt-2">
                          <i></i>{{ ProductMRP | currencyFormat :'INR' }}
                          <!-- <span class="ms-3">MRP 12,290.00 </span> -->
                        </div>
                      </ng-template>
                        <!-- <div class="price mt-4">
                                <i class="fas fa-rupee-sign"></i> 10,254.00 <span class="ms-3">MRP 12,290.00 </span>
                             </div> -->
                        <!-- <div class="save-mode"
                          *ngIf="isLoggedIn && ((ProductMRP - Productdiscount) > 0) && !isQuantityDiscountAvailable">You
                          save {{ (ProductMRP 
                          -
                          Productdiscount) |
                          currencyFormat :'INR' }} </div> -->
                          <ng-container  *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                        <div class="save-mode"
                          *ngIf="isLoggedIn && savedAmount > 0 && isQuantityDiscountAvailable;else normalDiscountTemplate">
                          You save {{ savedAmount | currencyFormat :'INR' }} </div>
                          <ng-template #normalDiscountTemplate>
                            <div class="save-mode"
                            *ngIf="( ProductMRP > Productdiscount && Productdiscount > 0 && (ProductMRP - Productdiscount)) > 0 ">
                            You save {{ (ProductMRP - Productdiscount) | currencyFormat :'INR' }} </div>
                          </ng-template>
                        </ng-container>
                        <div style="margin-top: 5px;" *ngIf="product?.loyalty_accrual?.point_percentage && (Productdiscount / 100 * product?.loyalty_accrual?.point_percentage) >= 0.5 && product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                          <span  class="total-saving">On purchase of this part, you are eligible to earn up to </span><span class="total-saving" style="font-weight: bold;">{{Productdiscount / 100 * product?.loyalty_accrual?.point_percentage | number:'1.0-0'}}</span>
                          <span style="margin-left: 5px;"  class="total-saving">loyalty points. <a style="color: blue;cursor: pointer;text-decoration: underline;" (click)="onRedirectLoyalty()">Click here to know more</a> </span>
                          <!-- So start earning reward points today -->
                        </div>
                        <div class="mt-3" *ngIf="isLoggedIn && isUserType?.user_type == 'RT'">
                          <p class="pin mb-0"><span style="font-weight: 600;">Discount category</span>&nbsp;:&nbsp;{{product?.discount_code_cvbu}}</p>  
                        </div>
                          <div class="deliv-sec_label mt-3 mb-3" >
                            <ng-container *ngIf="isUserType?.user_type == 'MECH'">
                              <div class="deliv-sec_label mt-1 mb-1" *ngIf="(product.inventory_quantity > 0  || product.moq <= product.inventory_quantity) && product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                                <span class="mt-2 mat-location-type" style="font-weight: 700;" *ngIf="product?.is_nls != 'in_active' && !product?.alternate_part">Expected Delivery Timeline&nbsp;:&nbsp;
                                  <span class="save-mode" *ngIf="etaList.length > 0">{{ etaList[0].ETA | date:'dd MMMM yyyy' }}</span>
                                  <!-- <a class="text-decoration-none aeta cursor" (click)="displayDeliveryTimeline(product);" style="color: #ff6a19;">Click here</a> -->
                                </span>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="isUserType?.user_type != 'MECH'">
                              <div class="deliv-sec_label mt-1 mb-1"  >
                                <span class="mt-2 mat-location-type" style="font-weight: 700;" *ngIf="product?.is_nls != 'in_active' && !product?.alternate_part">Expected Delivery Timeline&nbsp;:&nbsp;
                                  <span class="save-mode" *ngIf="etaList.length > 0">{{ etaList[0].ETA | date:'dd MMMM yyyy' }}</span>
                                  <!-- <a class="text-decoration-none aeta cursor" (click)="displayDeliveryTimeline(product);" style="color: #ff6a19;">Click here</a> -->
                                </span>
                              </div>
                            </ng-container>
                          </div>
                              <div class="deliv-sec mt-1 mb-1">
                                <!-- <p class="date-deliv mb-0">Get it by : <span class="ms-3 fw-normal">20 Sep</span></p> -->
                                <p class="pin mb-0">Delivery Pincode: <span
                                    class="ms-3">{{pinCodeNumber?pinCodeNumber:'NA'}}</span> <a class="ms-3 text-decoration-none"
                                    (click)="viewAddressModal()" style="cursor: pointer;">
                                    Change</a></p>                            
                              </div>                      
                        <li *ngIf="divisionInfoData || baseProductdata?.division_info" class="mb-0 mt-2 mat-location-type pb-2 drop-consumable"><p class="mat-location-name" data-toggle="modal" data-target="#addressmodal">{{baseProductdata?.division_info?.ORG_NAME}}</p>
                          <!-- <div class="card consumable-hover-dropdown mt-2 shadow" *ngIf="isLoggedIn">
                            <div class="triangle"></div>
                            <div class="card-body consumable">
                              <div class="d-flex notify-heading">
                                <div class="row">
                          <p class="mat-deliver-address">
                            {{ (baseProductdata?.division_info?.DIV_NAME != '' && baseProductdata?.division_info?.DIV_NAME != null && baseProductdata?.division_info?.DIV_NAME != undefined) ? (baseProductdata?.division_info?.DIV_NAME + ',') : ''}}
                            {{ (baseProductdata?.division_info?.PRIMISE_ADDRLINE1_s != '' && baseProductdata?.division_info?.PRIMISE_ADDRLINE1_s != null && baseProductdata?.division_info?.PRIMISE_ADDRLINE1_s != undefined) ? (baseProductdata?.division_info?.PRIMISE_ADDRLINE1_s + ',') : ''}}
                            {{ (baseProductdata?.division_info?.PRIMISE_ADDRESS2_s != '' && baseProductdata?.division_info?.PRIMISE_ADDRESS2_s != null && baseProductdata?.division_info?.PRIMISE_ADDRESS2_s != undefined) ? (baseProductdata?.division_info?.PRIMISE_ADDRESS2_s + ',') : ''}}
                            
                          </p>
                          </div>
                              </div>
                              </div>
                              </div> -->
<!-- Modal -->
<div class="modal fade" id="addressmodal" tabindex="-1" role="dialog" aria-labelledby="addressmodalTitle" aria-hidden="true" *ngIf="isLoggedIn">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="text-end m-1">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="border: none; background-color: transparent;">
          <i class="fa fa-times"></i>
         </button>
      </div>
      <div class="modal-body">
        <p class="mat-deliver-address">
          {{ (baseProductdata?.division_info?.DIV_NAME != '' && baseProductdata?.division_info?.DIV_NAME != null && baseProductdata?.division_info?.DIV_NAME != undefined) ? (baseProductdata?.division_info?.DIV_NAME + ',') : ''}}
          {{ (baseProductdata?.division_info?.PRIMISE_ADDRLINE1_s != '' && baseProductdata?.division_info?.PRIMISE_ADDRLINE1_s != null && baseProductdata?.division_info?.PRIMISE_ADDRLINE1_s != undefined) ? (baseProductdata?.division_info?.PRIMISE_ADDRLINE1_s + ',') : ''}}
          {{ (baseProductdata?.division_info?.PRIMISE_ADDRESS2_s != '' && baseProductdata?.division_info?.PRIMISE_ADDRESS2_s != null && baseProductdata?.division_info?.PRIMISE_ADDRESS2_s != undefined) ? (baseProductdata?.division_info?.PRIMISE_ADDRESS2_s + ',') : ''}}
          
        </p>
      </div>
    </div>
  </div>
</div>

                        </li>
                        
                        <ng-container *ngIf="isLoggedIn; else elseNoLoginDistrDetails">
                         
                        <ng-container  *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                          <!-- <span *ngIf="(validSchemeList && validSchemeList.length > 0) || (discountOfferData && discountOfferData.length > 0)" (click)="openSchemeListModal('otherOffer')" style="cursor: pointer; display: flex;"> -->
                            <span *ngIf="(validSchemeList && validSchemeList.length > 0) || (discountOfferData && discountOfferData.length > 0)" style="display: flex;">
                            <img width="25px" height="25px" src="assets/images/offerg-1550058133.png">
                            <p class="use-coupon-text"> View Offers</p>
                          </span>
                        </ng-container>
                          <p class="mat-deliver-address mt-2" *ngIf="baseProductdata?.division_info?.GSTIN">
                            GST No&nbsp;:&nbsp;{{ baseProductdata?.division_info?.GSTIN }}
                          </p>
                        </ng-container>
                        <ng-template #elseNoLoginDistrDetails>
                          <!-- <p class="mat-location-name">{{divisionInfoData?.ORG_NAME}}</p>
                          <p class="mat-deliver-address">
                            bbbb
                            {{ (divisionInfoData?.DIV_NAME != '' && divisionInfoData?.DIV_NAME != null && divisionInfoData?.DIV_NAME != undefined) ? (divisionInfoData?.DIV_NAME + ',') : ''}}
                            {{ (divisionInfoData?.PRIMISE_ADDRLINE1_s != '' && divisionInfoData?.PRIMISE_ADDRLINE1_s != null && divisionInfoData?.PRIMISE_ADDRLINE1_s != undefined) ? (divisionInfoData?.PRIMISE_ADDRLINE1_s + ',') : ''}}
                            {{ (divisionInfoData?.PRIMISE_ADDRESS2_s != '' && divisionInfoData?.PRIMISE_ADDRESS2_s != null && divisionInfoData?.PRIMISE_ADDRESS2_s != undefined) ? (divisionInfoData?.PRIMISE_ADDRESS2_s + ',') : ''}}
                            
                          </p> -->
                          <p class="mat-deliver-address" *ngIf="divisionInfoData?.GSTIN">
                            GST No&nbsp;:&nbsp;{{ divisionInfoData?.GSTIN }}
                          </p>
                        </ng-template>
                        <!-- <p class="distributor mb-0" *ngIf="isLoggedIn">Distributor: <span
                            class="ms-3">{{baseProductdata?.division_info?.ORG_NAME}}</span></p><br> -->
                        <!-- <a *ngIf="discountOffers.length > 0;"
                              class="text-decoration-none" style="cursor: pointer;" (click)="ViewOffers();">
                              View Offers</a> -->
                              <ng-container  *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                        <span *ngIf="showQuantityDiscountMessage" class="text-decoration-none save-mode">
                          <!-- <span
                            class="highlights-header" *ngIf="!isshowMoreSlabDiscount"><img src="./assets/images/buy_more_new.png"
                              height="100%" width="25%">&nbsp;<span style="color: #1d3087;" *ngIf="( product.resource_type == 'Pack' )">ORDER
                              {{(discountOffers?.quantity / product.moq) | number}} UNIT, GET
                              {{((quantityBasedPercentage === NaN) ? 0 : quantityBasedPercentage) + '%'}} OFF</span>
                              <span style="color: #1d3087;" *ngIf="!(product.resource_type == 'Pack' )">ORDER
                                {{discountOffers?.quantity | number}} QUANTITY, GET
                                {{((quantityBasedPercentage === NaN) ? 0 : quantityBasedPercentage) + '%'}} OFF</span>&nbsp;<br></span>
                                <span
                                class="highlights-header" *ngIf="isshowMoreSlabDiscount"><img src="./assets/images/buy_more_new.png"
                                  height="100%" width="25%">&nbsp;<span style="color: #1d3087;cursor: pointer;" (click)="openSchemeListModal('slabOffer')">view quantity slabs</span></span> -->
                                  <span
                            class="highlights-header" *ngIf="!isshowMoreSlabDiscount">
                                <div class="main-card">
                                  <div class="small-card">
                                    <span
                                        class="highlights-header p-2">Buy More Save More</span>
                                  </div>
                                  <div class="card-content">
                                    <span *ngIf="showQuantityDiscountMessage" class="text-decoration-none save-mode"><span
                                      class="highlights-header" *ngIf="!isshowMoreSlabDiscount"><span style="color: #1d3087;" *ngIf="( product.resource_type == 'Pack' )">ORDER
                                        {{(discountOffers?.quantity / product.moq) | number}} UNIT, GET
                                        {{((quantityBasedPercentage === NaN) ? 0 : quantityBasedPercentage) + '%'}} OFF</span>
                                        <span style="color: #1d3087;" *ngIf="!(product.resource_type == 'Pack' )">ORDER
                                          {{discountOffers?.quantity | number}} QUANTITY, GET
                                          {{((quantityBasedPercentage === NaN) ? 0 : quantityBasedPercentage) + '%'}} OFF</span> <br></span>
                                        </span>
                                  </div>
                              </div>
                            </span>
                                <span
                                class="highlights-header" *ngIf="isshowMoreSlabDiscount">
                                <div class="main-card">
                                  <div class="small-card-2">
                                    <span
                                        class="highlights-header p-2">Buy More Save More</span>
                                  </div>
                                  <div class="card-content">
                                    <span *ngIf="showQuantityDiscountMessage" class="text-decoration-none save-mode">
                                          <span
                                          class="highlights-header" *ngIf="isshowMoreSlabDiscount"><span style="color: #1d3087;cursor: pointer;" >Quantity Slab </span><span><a (click)="openSchemeListModal('slabOffer')" style="float: right; cursor: pointer; color: blue;">View</a></span></span>
                                        </span>
                                  </div>
                              </div>
                            </span>
                        </span>
                        </ng-container>
                        <div class="d-flex pb-2 quantity-sec align-items-center pt-2"
                          *ngIf="(isUserType?.user_type == 'MECH' && (product.inventory_quantity > 0 && product.inventory_quantity != null && convertToInt(product.moq) <= product.inventory_quantity) && product.is_nls != 'in_active' && (product?.alternate_part?false:true)) || (isUserType?.user_type != 'MECH' && product.is_nls != 'in_active' && (product?.alternate_part?false:true)) ">
                          <span class="mat-del" *ngIf=" (product.resource_type == 'Pack') && product?.uom">UNIT: &nbsp;</span>
                          <!-- //main addtocart  -->
                          <app-input-number aria-label="Quantity" size="lg" [min]="1" [isTmgoValue]="product.resource_type == 'Pack' ?product.moq:'1'"
                            (currentQuantity)="readQuantity($event,product,true)" [defaultValue]="product?.min_quantity" [userType]="isUserType" [inventoryQty]="product.inventory_quantity" [externalSource]="externalTrackSource">
                            <!-- [defaultValue]="product.customerQuantity ? product.customerQuantity : product?.min_quantity" -->
                          </app-input-number>
                          <span class="mat-del" *ngIf="(product.resource_type == 'Pack' && product.moq > 1 ) && product?.uom">&nbsp;&nbsp;QUANTITY:
                            &nbsp;</span>
                          <input class="qty-input input-number__input ipunitquantity" type="text"
                            *ngIf="product.resource_type == 'Pack' && product.moq > 1" size="lg" disabled
                            value="{{ currentQuantity ? product?.moq * currentQuantity : 0 }}" maxlength="50">
                          <!-- <input type="text" style="width: 42.25%;"
                        value="{{'QUANTITY: ' +  }}" maxlength="50"> -->
                          <!-- <p class="ms-3 mb-0">Only 4 left</p> -->
                        </div>
                        <span class="error-message" *ngIf="(product.resource_type == 'Pack' && product?.moq > 1) && product?.uom">Note: </span>
                        <span class="mat-del" *ngIf="(product.resource_type == 'Pack' && product?.moq > 1) && product?.uom">1 Unit contains {{product.moq | number: '1.0-0'}} <span *ngIf="product.moq > 1">Quantities</span><span *ngIf="product.moq <= 1">Quantity</span><br></span>
                          
                        <!-- //tmgo addtocart -->
                        <span class="error-message" *ngIf="partLimitCheck.length !=0 && isLoggedIn">Max quantity allowed is {{partLimitCheck[0]?.available_qty}}<br></span>
                        <div *ngIf="isUserType?.user_type == 'MECH'">
                          <ng-container *ngIf="product.inventory_quantity > 0 && convertToInt(product.moq) <= product.inventory_quantity">
                            <a class="btn btn-primary btn--add-cart mt-2" role="button" (click)="addToCart(product)"
                            *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true)" [ngStyle]="addtocartDisable ? {'opactity':'0.4','pointer-events':'none'}:{}"> Add To Cart</a><br>
                          </ng-container>
                          <ng-conatiner *ngIf="(product.inventory_quantity <= 0 || product.inventory_quantity == null || convertToInt(product.moq) > product.inventory_quantity) && product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                            <span class="outOfStock mt-2">Out Of Stock</span>
                          </ng-conatiner>
                        </div>
                        <div *ngIf="isUserType?.user_type != 'MECH'">
                          <a class="btn btn-primary btn--add-cart mt-2" role="button" (click)="addToCart(product)"
                          *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true)"> Add To Cart</a><br>
                        </div>
                       
                        <!-- <span class="error-message text-nowrap" *ngIf="product.is_nls == 'in_active'">No Longer Serviced</span>
                        <div class="col-4 offset-4 text-nowrap" *ngIf="product.is_nls == 'in_active'">
                          <span style="color: blue;">View Alternate Part</span>
                      </div> -->
                        <!-- <span class="col-12 text-nowrap">
                        View Offers
                      </span> -->                   
                        <span class="error-message-service text-nowrap" *ngIf="product?.is_nls == 'in_active'">No
                          Longer Serviced...</span>
                          <span class="error-message-service text-nowrap" *ngIf="product?.is_nls != 'in_active' && (product?.alternate_part?true:false) ">No
                          Longer Available...</span>
                        <!-- <div class="col-12 view-alternate text-nowrap" *ngIf="product?.is_nls == 'in_active' && alternateProductsList.length > 0" (click)="showRelatedList()"> -->
                        <div class="col-12 view-alternate text-nowrap"
                          *ngIf="( product?.is_nls == 'in_active' && alternateProductsList.length > 0 ) || ( product?.alternate_part && alternatePartData )"
                          (click)="showRelatedList(product.alternate_part)">
                          <span>View Alternate Part</span>
                        </div>
                        <a class="btn btn-primary btn--add-cart secondbtn" role="button" (click)="ViewChildPart()"
                          *ngIf="btnshowChildParts"> Show Child Parts</a>
                        <div class="part-description mt-4" *ngIf="product?.large_description != '' && product?.large_description != null && product?.large_description != undefined">
                          <h5>Part description</h5>
                          <p>{{ product?.large_description }} </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3 col-lg-3 colxl-3 col-xxl-3 border-start">
                      <!-- Button trigger modal -->
                      <!-- <h5 class="highlights">Highlights</h5>
                      <ul class="lists-highlights">
                        <li>We have catalogue for each part we deliver</li>
                        <li>Purchase Parts at Discounted Rates</li>
                        <li>Part Recommendation to match your requirements</li>
                        <li>Get timely delivery of your order</li> -->
                        <!-- <li>High performance filtration systems play critical role in engines</li>
                      <li>Nano fiber technology is applied to air filters.</li>
                      <li>Exclusive filter media blend of natural and synthetic fibers provide superior performance and
                        durability
                        in air filters.</li> -->
                      <!-- </ul> -->
                      <!-- <div>
                        <div class="col-12 col-sm-12">
                          <div class="viewfullimage">
                            <div class="social-share d-flex" style="justify-content: space-around;">
                              <div>
                                <a data-toggle="tooltip" title="WhatsApp" target="_blank"
                                  rel="noopener noreferrer" (click)="share('whatsapp')" style="cursor: pointer;">
                                  <img alt="WhatsApp" src="./assets/icons/WhatsApp_icon.png" height="25px"/>
                                </a>
                              </div>
                              <div>
                                <a data-toggle="tooltip" title="Twitter" (click)="share('Twitter')" style="cursor: pointer;"
                                  rel="noopener noreferrer">
                                  <img alt="Twitter" src="./assets/icons/twitter.png" height="22px"/>
                                </a>
                              </div>
                              <div>
                                <a data-toggle="tooltip" title="Facebook" (click)="share('Facebook')" style="cursor: pointer;"
                                  rel="noopener noreferrer">
                                  <img alt="Facebook" src="./assets/icons/Facebook.png" height="22px"/>
                                </a>
                              </div>
                              <div>
                                <a href="mailto:?body={{this.pageURL}}" data-toggle="tooltip" title="Mail" target="_blank" rel="noopener noreferrer">
                                  <img alt="Mail" src="./assets/icons/mail.png" height="22px"/>
                                </a>
                              </div>
                              <div>
                                <a data-toggle="tooltip" class="cursor-pointer" title="Copy link" style="cursor: pointer;"
                                  (click)="copyMessage()" target="_blank" rel="noopener noreferrer">
                                  <img alt="Copy link" src="./assets/icons/link.png" height="22px"/>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> -->
                      <div class="finance-offer mt-2" *ngIf="isLoggedIn && isUserType?.user_type != 'MECH' && product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                        <div class="offer-header">
                          <h4>
                            <img width="25px" height="25px" src="assets/images/offerg-1550058133.png">
                            <span class="use-coupon-text">Other Offers</span></h4>
                        </div>           
                      </div>
                  <!--offersidebar-->
                  <div class="sidebar shadow" [ngClass]="{ 'open': isSidebarOpen }">
                    <div class="container header-bg">
                      <a href="javascript:void(0)" class="closebtn" (click)="toggleSidebar('','')">&times;</a>
                      <h4 class="header-txt">{{displayToggleName == 'Default Discount' ? 'Standard Discount' :displayToggleName == 'Market Basket' ? 'Combo Offers': displayToggleName}}</h4>
                      <hr style="color: #ebedf7;">
                    </div>
                    <div style="max-height: 70vh;overflow: auto;scrollbar-width: thin;" class="accordion p-2" id="accordionExample">
                      <ng-container  *ngIf="displayToggleName != 'Quantity Discount' && displayToggleName != 'Value Discount' && displayToggleName != 'Bulk Purchase'">
                        <div *ngFor="let item of innerViewoffer; let i = index">
                          <div class="accordion-item">
                            <h2 class="accordion-header" [id]="'heading'+i">
                              <button data-toggle="tooltip" data-placement="top" title="{{item.discount_name ? item.discount_name : item.scheme_name }}" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse'+i" aria-expanded="false" [attr.aria-controls]="'collapse'+i" style="border: none;" (click)="item.discount_type == 'market_basket' ? marketBasketCollapeApi(item,i): ismarketbasketapicall =false">
                                {{ truncateText(item.discount_name ? item.discount_name : item.scheme_name,30)  }}        
                              </button>
                            </h2>
                            <div [id]="'collapse'+i" class="accordion-collapse collapse" [attr.aria-labelledby]="'heading'+i" data-bs-parent="#accordionExample" style="border: none;">
                              <div class="accordion-body">
                                <div *ngIf="!ismarketbasketapicall" style="    display: grid;
                                grid-template-columns: 1fr 1fr;">
                                 <span *ngIf="item.discount_description"><p class="headingFont" >Description</p><p class="innerheading">{{item.discount_description}}</p></span>

                                 <!-- for no value discount -->
                                 <ng-container>
                                  <span ><p class="headingFont">Percentage</p><p class="innerheading">{{item.percentage}}</p></span>
                                  <!-- <span *ngIf="item.discount_type == 'quantity_discount'"><p class="headingFont">Percentage</p><p class="innerheading">{{item.discount_detail_percentage}}</p></span> -->
                                  <span *ngIf="item.quantity"><p  class="headingFont">Quantity</p><p class="innerheading">{{item.quantity != 0 ? (item.quantity | number:'1.0-0') : 'NA'}}</p></span> 
                                 </ng-container>
                                  <!-- end no value based discount -->
                               

                                  <span><p  class="headingFont">Start Date</p><p class="innerheading">{{(item.from_date ? item.from_date :item.start_dt) | date: "dd-MM-yyyy"}}</p></span>
  
                                  <span><p  class="headingFont">End Date</p><p class="innerheading">{{(item.to_date ? item.to_date : item.end_dt) | date: "dd-MM-yyyy"}}</p></span> 
                                  <span *ngIf="item.slab_from"><p  class="headingFont">Slab From</p><p class="innerheading">{{item.slab_from}}</p></span> 
                                  <span *ngIf="item.slab_to"><p  class="headingFont">Slab To</p><p class="innerheading">{{item.slab_to}}</p></span> 
                                  <span><p  class="headingFont">Status</p><p class="innerheading">{{item.is_active ? 'Active' : 'Not Active'}}</p></span>
                      
                                </div>
                                <!-- for market_basket_details -->
                                <ng-container *ngIf="ismarketbasketapicall">
                                  <!-- <div style="max-height: 20vh;overflow: auto;">
                                    <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                      <p class="headingFont">Quantity</p>
                                      <p class="headingFont">Percentage</p>                               
                                    </div>
                                    <div *ngFor="let slab of marketDetailsarr" style="display: grid; grid-template-columns: 1fr 1fr;">
                                      <p class="innerheading">{{ slab.quantity }}</p>
                                      <p class="innerheading">{{ slab.percentage  }}</p>
                                    </div>
                                  </div> -->
                                  <div style="width: 100%;">
                                 <span *ngIf="marketBasketDescription"><p class="headingFont" >Description</p>
                                  <div  class="innerheading" style="padding-bottom: 10px;">
                                    <div [innerHTML]="marketBasketDescription" [class.collapsed]="isCollapsed" [style.height]="isCollapsed ? maxHeight+'px' : 'auto'">
                                    </div>
                                        <a *ngIf="isCollapsable && marketBasketDescription.length > 200" (click)="isCollapsed =! isCollapsed" style="cursor: pointer;">Read {{isCollapsed? 'more':'less'}}</a>
                                  </div>
                          
                                </span>
                                    <div style="    display: grid;
                                    grid-template-columns: 24% 1fr 1fr;
                                    position: sticky;
                                    background: white;
                                    z-index: 999;
                                    width: 90%;">
                                    <span class="headingFont"></span>
                                      <span class="headingFont">Quantity</span>
                                      <span class="headingFont">Percentage (%)</span>
                                    </div>
                                    <div style="    max-height: 20vh;
                                    overflow: auto;">
                                      <div *ngFor="let slab of marketDetailsarr" style="display: grid; grid-template-columns: 24% 1fr 1fr;margin-top: 10px;">
                                        <span><p class="innerheading">Slab {{slab.slab}}</p></span>
                                        <span><p class="innerheading">{{ slab.quantity  | number:'1.0-0'}}</p></span>
                                        <span><p class="innerheading">{{ slab.percentage  }}</p></span>
                                      </div>
                                    </div>
  
                                  </div>
                                 <hr>
                                  <div>
                                    <p class="headingFont" >Part Number</p>
                                      
                                  <!-- <div style="overflow: auto;
                                  width: 100%;
                                  max-height: 25vh;
                                  padding: 5px;"  >
                                    <span *ngFor="let mrkdetail of newArrayforPartnumner;let counterIndex = index">
                                      <p class="innerheading" style="display: flex;width: 100%;gap: 10px;"> 
                                      <span style="display: flex;
                                      align-items: center;
                                      width: 100%;">
                                        <img src="{{imgURL}}{{mrkdetail.part_image_path}}" height="50x" width="30px"  alt="banner 1">
                                        <span (click)="routeToProduct(mrkdetail)" style="cursor: pointer;">{{mrkdetail.part_name}} {{mrkdetail.sku_item}}</span></span> 
                                      <span *ngIf="mrkdetail.sku_item" style="align-items: center;
                                      width: 100%;
                                      display: flex;
                                  ">
                                      <app-input-number aria-label="Quantity" size="lg" [min]="1" [max]="8" [isTmgoValue]="mrkdetail.resource_type == 'Pack' && mrkdetail.moq > 1?mrkdetail.moq:'1'"
                                        (currentQuantity)="readproductdetaailQuantity(mrkdetail,$event,counterIndex)" [defaultValue]="mrkdetail.minimum_quantity">
                                      </app-input-number>
                                      <span style="position: relative;margin-left: 5px; width: 35px;" class="col-7 text-end d-flex align-items-center justify-content-center">
                                        <button class="add-to-card-new-product" (click)="partAddtoCart(mrkdetail,counterIndex)"><img alt=""
                                          src="./assets/images/cart.svg" /></button>
                                      </span>
                                    </span>
                                  </p>
                                </span>
                                   
                                  </div> -->
                                  <div style="overflow: auto;
                                  width: 100%;
                                  max-height: 25vh;
                                  padding: 5px;">
                                  <ng-container *ngFor="let mrkdetail of newArrayforPartnumner;let counterIndex = index">
                                    <div style="display: grid;
                                    grid-template-columns: 20% 40% 40%;text-align: center;
                                    align-items: center;
                                    justify-items: center;margin-bottom: 10px;" >
                                      <div>
                                        <img *ngIf="mrkdetail.sku_item !== 'ALL'" (click)="routeToProduct(mrkdetail)" src="{{mrkdetail.part_image_path + '/resize/62x50'}}" height="50x" width="100%"  alt="banner 1">
                                        <img *ngIf="mrkdetail.sku_item == 'ALL'" src="{{mrkdetail.part_image_path + '/resize/62x50'}}" height="50x" width="100%"  alt="banner 1">
                                      </div>
                                      <div>
                                        <span *ngIf="mrkdetail.sku_item !== 'ALL'" (click)="routeToProduct(mrkdetail)" style="cursor: pointer;"><span style="font-weight: bold;">{{mrkdetail.part_name}}</span> {{mrkdetail.sku_item}}</span>
                                        <span *ngIf="mrkdetail.sku_item == 'ALL'"><span style="font-weight: bold;">{{mrkdetail.part_name}}</span> {{mrkdetail.sku_item}}</span>
                                      </div>
                                      <div *ngIf="mrkdetail.sku_item !== 'ALL'" style="display: flex;align-items: center;">
                                        <app-input-number aria-label="Quantity" size="lg" [min]="1" [max]="8" [isTmgoValue]="mrkdetail.resource_type == 'Pack' && mrkdetail.moq > 1?mrkdetail.moq:'1'"
                                          (currentQuantity)="readproductdetaailQuantity(mrkdetail,$event,counterIndex)" [defaultValue]="mrkdetail.minimum_quantity" [userType]="isUserType" [inventoryQty]="mrkdetail.inventory_quantity" [externalSource]="externalTrackSource">
                                        </app-input-number>
                                        <span style="position: relative;margin-left: 5px; width: 35px;" class="col-7 text-end d-flex align-items-center justify-content-center">
                                          <button class="add-to-card-new-product" (click)="partAddtoCart(mrkdetail,counterIndex)"><img alt=""
                                            src="./assets/images/cart.svg" /></button>
                                        </span>
                                      </div>
                                    </div>
                                    <hr>
                                  </ng-container>
                                   
                                  </div>
                                  </div>
                                </ng-container>
                                
                                <!-- <ng-container *ngIf="ismarketbasketapicall">
                                  <div  *ngFor="let itemmarket of marketDetailsarr;let i = index">
                                  <div *ngFor="let mrkdetail of itemmarket.discount_slab_detail;let i = index">
                                    <div style="display: grid;grid-template-columns: 1fr 1fr;">
                                      <span><p  class="headingFont">Quantity</p><p class="innerheading">{{mrkdetail.quantity}}</p></span>
                                      <span><p  class="headingFont">Percentage</p><p class="innerheading">{{mrkdetail.percentage }}</p></span> 
                                    </div>
                                    <div style="    display: flex;
                                    width: 100%;">
                                      <span><p class="headingFont" >Part Number</p>
                                        <p class="innerheading" style="display: flex;"> 
                                        <span style="display: flex;align-items: center;">
                                          {{mrkdetail.part_number}}</span> 
                                        <span *ngIf="mrkdetail.part_number" style="margin-left: 21%;
                                        align-items: center;
                                        display: flex;">
                                        <app-input-number aria-label="Quantity" size="lg" [min]="1" [max]="8" [isTmgoValue]="product.resource_type == 'Pack' && product.moq > 1?product.moq:'1'"
                                          (currentQuantity)="readQuantity(product,$event)" [defaultValue]="product.min_quantity">
                                        </app-input-number>
                                        <span class="col-7 text-end d-flex align-items-center justify-content-center">
                                          <button class="add-to-card-new-product"><img alt=""
                                            src="./assets/images/cart.svg" /></button>
                                        </span>
                                      </span>
                                    </p>
                                  </span>
                                     
                                    </div>
                                    
                                  </div>
                          
                                  </div>
                                </ng-container> -->
                            
                              </div>
                            </div>
                          </div>
                          <hr>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="displayToggleName == 'Quantity Discount' || displayToggleName == 'Value Discount' || displayToggleName == 'Bulk Purchase'">
                        <div *ngFor="let item of uniqueListContact; let i = index">
                          <div class="accordion-item">
                            <h2 class="accordion-header" [id]="'heading'+i">
                              <button data-toggle="tooltip" data-placement="top" title="{{item.discount_name ? item.discount_name : item.scheme_name}}" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse'+i" aria-expanded="false" [attr.aria-controls]="'collapse'+i" style="border: none;" (click)="item.discount_type == 'market_basket' ? marketBasketCollapeApi(item,i): ismarketbasketapicall =false">
                                {{ truncateText(item.discount_name ? item.discount_name : item.scheme_name,30)}}        
                              </button>
                            </h2>
                            <div [id]="'collapse'+i" class="accordion-collapse collapse" [attr.aria-labelledby]="'heading'+i" data-bs-parent="#accordionExample" style="border: none;">
                              <div class="accordion-body">
                                <div style="    display: grid;
                                grid-template-columns:24% 1fr 1fr 1fr;
                                position: sticky;
                                background: white;
                                z-index: 999;
                                width: 90%;">
                                 <span class="headingFont"></span>
                                 
                                
                                     <ng-container *ngIf="displayToggleName == 'Quantity Discount'">
                                      <span class="headingFont">Quantity</span>
                                      <span class="headingFont">Percentage (%)</span>
                                     </ng-container >
                                     <ng-container *ngIf="displayToggleName == 'Value Discount'">
                                     <span class="headingFont">Amount</span>
                                     <span class="headingFont">Discount</span>
                                    </ng-container>
                                    <ng-container *ngIf="displayToggleName == 'Bulk Purchase'">
                                      <span class="headingFont">From</span>
                                      <span class="headingFont">To</span>
                                      <span class="headingFont">Percentage (%)</span>
                                     </ng-container >
                                 
                                </div>
                                <div style="    max-height: 20vh;
                                overflow: auto;">
                                  <div *ngFor="let slab of item.newArr;let id = index" style="display: grid; grid-template-columns:24% 1fr 1fr 1fr;margin-top: 10px;">
                                    <span><p class="innerheading">Slab {{ id + 1 }}</p></span>
                                    <ng-container *ngIf="displayToggleName == 'Quantity Discount'">
                                      <span><p class="innerheading">{{ slab.quantity  | number:'1.0-0'}}</p></span>
                                      <span><p class="innerheading">{{ slab.discount_detail_percentage  }}</p></span>
                                    </ng-container>
                                    <ng-container *ngIf="displayToggleName == 'Value Discount'">
                                      <span><p class="innerheading">{{ slab.value  | number:'1.0-0'}}</p></span>
                                      <span><p class="innerheading">{{ slab.discount_amount != 0 && slab.discount_amount != null ? slab.discount_amount : slab.discount_detail_percentage  + '%' }}</p></span>
                                    </ng-container>
                                    <ng-container *ngIf="displayToggleName == 'Bulk Purchase'">
                                      <span><p class="innerheading">{{ slab.slab_from  | number:'1.0-0'}}</p></span>
                                      <span><p class="innerheading">{{ slab.slab_to  }}</p></span>
                                      <span><p class="innerheading">{{ slab.percentage  }}</p></span>
                                    </ng-container>
                                  </div>
                                </div>
                                <div *ngIf="!ismarketbasketapicall" style="    display: grid;
                                grid-template-columns: 1fr 1fr;">
                                
                                 <!-- <span *ngIf="item.discount_description"><p class="headingFont" >Description</p><p class="innerheading">{{item.discount_description}}</p></span> -->
                                  <span><p  class="headingFont">Start Date</p><p class="innerheading">{{(item.from_date ? item.from_date : item.start_dt) | date: "dd-MM-yyyy"}}</p></span>
                                  <span><p  class="headingFont">End Date</p><p class="innerheading">{{(item.to_date ? item.to_date : item.end_dt) | date: "dd-MM-yyyy"}}</p></span>
                                  <span><p  class="headingFont">Status</p><p class="innerheading">{{item.is_active ? item.is_active ? 'Active' : 'Not Active': item.is_active ? 'Active' : 'Not Active'}}</p></span>
                      
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                      <!--offersidebar-->
                      <ng-container *ngIf="isLoggedIn && isUserType?.user_type != 'MECH' && product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                      <div class="container offersection"  *ngIf="storeCrouselname && storeCrouselname?.length > 0; else noDataMessage">
                        <ul [ngClass]="{'scrollable': storeCrouselname.length > 3}" class="mleft">
                          <li class="list-style" *ngFor="let data of storeCrouselname;let i = index">
                            <div class="card shadow m-auto p-2 sidecontainer mt-3 mb-2 h-100">
                              <div class="row mt-3">
                                <div class="col-8">
                                  <span class="d-flex"><img src="../../../../assets/images/offerg-1550058133.png" style="height: 20px; width: 20px;"><span style="margin-left: 10px;word-break: break-all;text-wrap: nowrap"><p class="offer-txt" style="font-size: 12px;"><strong>{{ data == 'Default Discount' ? 'Standard Discount' :data == 'Market Basket' ? 'Combo Offers': data}}</strong></p></span></span>   
                                </div>
                                <div class="col-4">
                                  <p style="color: blue; font-size: 12px; text-align: center;"class="cursor" (click)="toggleSidebar(data,i)"><strong>View</strong></p>
                                </div>
                              </div>                                                                       
                            </div> 
                          </li>
                        </ul>
                </div>
              </ng-container>

                <ng-template #noDataMessage>
                  <div class="container offersection" *ngIf="isLoggedIn && isUserType?.user_type != 'MECH' && product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                      <p class="mt-5"><strong> New Offer Not Available</strong></p>
                  </div>
              </ng-template>
      
                <!-- <ng-template #skeletonForGrid>
                  <div *ngIf="storeCrouselname?.length == 0 && !isNoDataFound">
                    <div class="col-12">
                      <div class="row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4 row-cols-xl-4 row-cols-xxl-4 "
                        *ngFor="let item of generateFake(3);let i = index;">
                        <div class="card border-0" style="width: 100%;">
                          <div class="card-body">
                            <div class="prod-content position-relative">
                              <skc-rectangle [loading]="loading" [skc-height]="'12px'" [skc-width]="'250px'">
                              </skc-rectangle>
                            </div>
                            <div class="prod-img mx-auto text-center  position-relative">
                              <skc-rectangle [loading]="loading" [skc-height]="'12px'" [skc-width]="'250px'">
                              </skc-rectangle>
                              <skc-rectangle [loading]="loading" [skc-height]="'60px'" [skc-width]="'250px'">
                              </skc-rectangle>
                            </div>
                            <div class="prod-content position-relative">
                              <skc-rectangle [loading]="loading" [skc-height]="'8px'" [skc-width]="'250px'">
                              </skc-rectangle>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template> -->
            
                      <div class="form-highlights-ques" *ngIf="isLoggedIn">
                        <h5 class="highlights cursor mt-3" data-toggle="modal" data-target="#exampleModal"  data-placement="bottom" title="Click Here To Ask Your Question"> Do you have questions about this product ?</h5>
                        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div class="modal-dialog" role="document">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5 class="modal-title"> Do you have questions about this product ?</h5>                            
                                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="border: none; background-color: transparent;">
                                    <i class="fa fa-times"></i>
                                   </button>
                              </div>
                              <div class="modal-body">
                                <textarea class="form-control fs-6" [(ngModel)]="userQuery" id="type-que" rows="3"
                                placeholder="Type your question..." (keydown)="preventLeadingSpace($event)"  (keypress)="notAllowSpaceatFirst($event)"></textarea>
                              <div class="text-end mt-2">
                                <a class="text-decoration-none text-uppercase btn-anchor" role="button"
                                  (click)="onSubmitQuery(product?.part_number);">Submit</a>
                        
                              </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-highlights-ques-not-login" *ngIf="!isLoggedIn">
                        <h5 class="highlights mt-2">Do you have questions about this product ?<br>Please contact TML on
                          020-67136236 or</h5>
                        <!-- <textarea class="form-control fs-6" id="type-que" rows="3"
                        placeholder="Type your question..."></textarea> -->
                        <div>
                          <!-- <a href="https://edukaansupport@tatamotors.com"
                            target="_blank">edukaansupport@tatamotors.com</a> -->
                          <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=edukaansupport@tatamotors.com"
                            target="_blank" style="text-decoration: none;">edukaansupport@tatamotors.com</a>
                        </div>
                      </div>
                      <!-- <div class="form-highlights-ques" *ngIf="isLoggedIn">
                        <a class="ms-3 geolocation-anchor-tag" (click)="goToLocationNearbyMe()">
                          <img class="mb-3 nearby-mechanics-img" src="assets/icons/Bandhu1.png">
                        </a>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="showProductEngine">
          <app-product-detail-engine [product]="product" [showProduct]="showProduct" [divisionInfoData]="divisionInfoData"
          [pinCodeNumber]="pinCodeNumber" [baseProductdiscount]="baseProductdiscount" [modelVcData]="modelVcData"
          (addEngineCart)="addToCart(product)" [discountOffers]="discountOffers" (ShowEnginemodel)="ShowEngine()"
          (viewAddressModal)="viewAddressModal()" [loyaltyDatapercentage]="loyaltyData" (addEngineMBCart)="productEngineAddtocart($event)" (backToDetails)="backToDetails(data)"></app-product-detail-engine>
      </ng-container>
    </div>
  </div>
</div>
<div class="product-specfication pt-5" id="compatible-header">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="card">
          <div class="card-body bg-white shadow">
            <div class="specfication-box-area">
              <div class="row  pb-3">

                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <app-productspecification [product]="product"></app-productspecification>
                </div>
                <div *ngIf="isProductDetailEngineComponent" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 border-start">
                  <app-productcompatiblemodel [partNumber]="partNumber" (showsubModel)="showsubModel($event)"
                    (slideToTop)="slideToTop();"></app-productcompatiblemodel>
                </div>
                <div *ngIf="isProductDetailComponent" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 border-start">
                    <h5 class="chkttxt">Vehicle Compatibility Check ({{ partNumber}})</h5>
<h4 class="chksutxt">Check the product's compatibility with your vehicle using Reg. No or Chassis Number.</h4>
<div class="modal-body">
  <div class="">
    <mat-radio-group name="vehicleList"  fxLayout="column" [(ngModel)]="chosenItem" [ngModelOptions]="{standalone: true}" >
      <mat-radio-button  class="fw-bold newspace mb-2 mb-sm-0"  [checked]="item.checked" [disabled]="item.disabled" *ngFor="let item of vehicleList" [value]="item.name" 
      name="vehicleList"(change)="radioChange($event,item)" >{{ item.name}}</mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="mt-3">
    <div class="row">
      <label for="" class="pb-2">Enter Vehicle {{chosenItem}}</label>
      <div class="input-validatebtn">
          <input class="form-control inputwidth" type="text" [(ngModel)]="typevalue">
          <!-- <button type="button" class="btn btn-primary" (click)="applyFilter()">Validate</button> -->
          <label class="verified-msg d-flex align-items-center"  *ngIf="isApiCalled && typevalue && !errormsg">
            <img src="./assets/images/select-tick.gif" class="img-fluid me-1 icon-verify" *ngIf="checkCompatible == true"/>
            <img src="./assets/images/cross-mark.gif" class="img-fluid me-1 icon-verify" *ngIf="checkCompatible == false"/>                                   
          </label>
      </div>
      <div class="row mt-3">
        <div class="col-md-7">
          <span [ngStyle]="{'color': checkCompatible ? 'green' : 'red'}" style="margin-top: 10px;"><strong>{{ compatibilityMessage }}</strong></span>
          <label class="unable_verify d-flex align-items-center mt-2" *ngIf="errormsg"><img
              src="./assets/images/button-circle-warning.svg" class="img-fluid me-1 icon-warning" /><strong>{{errormsg}}</strong></label>
        </div>
        <div class="col-md-1">
          <button type="button" class="btn btn-primary btnmleft" (click)="applyFilter()">Check</button>
        </div>
      </div>
    </div>
  </div>
</div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</div>
<div  id="relatedlistview"></div>
<div class="related-product rel-prod"  *ngIf="alternateProductsList.length > 0">
  <div class="container-fluid"  >
    <h4 class="fw-bold mb-4  mt-4 rel-prod-heading" >Alternate Part</h4>
    <div class="row">
      <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12">
        <div id="related-product">
          <div class="item me-2 ms-2" *ngFor="let product of alternateProductsList">
            <div class="card">
              <div class="row g-0 align-items-center">
                <div class="col-md-4">
                  <div class="prod-img mx-auto">
                    <img [src]="product?.part_image_path + '/resize/75x50'" (click)="onRouteSameUrl(product.part_number);" class="img-fluid"
                      alt="prod-img-alt" srcset="">
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <div class="prod-content position-relative">
                      <!-- <div class="bookmarks-fav text-end p-0 position-absolute">
                        <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                      </div> -->
                      
                      <app-wishlist-icon [product]="product" [layout]="'Nolayout'" [isWishlistedItem]="isWishlistedItem"></app-wishlist-icon>
                      <h3 class="fw-bold prod-model mb-2" (click)="onRouteSameUrl(product.part_number);">
                        {{ product.part_number }}</h3>

                      <h4 class="prod-name mb-2" (click)="onRouteSameUrl(product.part_number);">{{ product.short_description }}
                      </h4>
                      <!-- <p class="fw-bold"><span class="fw-normal">Estd. Delivery:</span> Today</p> -->
                      <ng-container *ngIf="!product.total_amount">
                        <div class="price fw-bold mb-3">
                          {{ ((product.resource_type == 'Pack') ? product.crm_mrp * product.moq : product.crm_mrp) | currencyFormat :'INR' }}
                        </div>
                      </ng-container>
                      <ng-container *ngIf="product.total_amount">
                        <div *ngIf="(product.base_discount_per +  product.scheme_discount_per + product.rule_discount_per + product.distributor2_discount_amount + product.tml_discount_per + product.distributor_discount_per ) == 0.0">
                          <div class="price fw-bold mb-3">
                            {{ ( ( product.resource_type == 'Pack')?product.crm_mrp*product.moq:product.crm_mrp ) |currencyFormat:'INR' }}
                          </div>
                        </div>
                        <div *ngIf="(product.base_discount_per +  product.scheme_discount_per + product.rule_discount_per + product.distributor2_discount_amount + product.tml_discount_per + product.distributor_discount_per ) > 0.0">
                          <div class="price fw-bold mb-3">
                            {{   (( product.resource_type == 'Pack') ? product.total_amount*product.moq
                                : product.total_amount) |currencyFormat:'INR'
                            }}
                            <div class="card-actual-mrp">
                                <span>
                                  MRP  {{
                                        ( product.resource_type == 'Pack' ) ? product.crm_mrp*product.moq
                                        : product.crm_mrp |currencyFormat:'INR' 
                                    }}
                                </span>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                      <span class="error-message-service text-nowrap" *ngIf="product?.is_nls == 'in_active'">No Longer
                        Serviced
                      </span>
                      <span class="error-message-service text-nowrap"
                        *ngIf="product?.is_nls != 'in_active' && product?.alternate_part?true:false">
                        No Longer available
                      </span>
                      <div class="col-12 view-alternate text-nowrap mt-1" *ngIf="product.is_nls == 'in_active' || (product?.alternate_part?true:false) ">
                          <span style="color: blue;" [routerLink]="['/shop/product-details']"
                              [queryParams]="{ part_Number : product.part_number }" style="cursor: pointer;">View Alternate Part</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="discount" *ngIf="(product?.base_discount_per + product?.scheme_discount_per +
                      product?.tml_discount_per + product?.distributor_discount_per +
                      product?.rule_discount_per + product.distributor2_discount_amount ) > 0" [routerLink]="['/shop/product-details']"
                  [queryParams]="{ part_Number : product.part_number }">
                  <!-- {{(product?.base_discount + product?.scheme_discount +
                          product?.tml_discount_per + product?.distributor_discount_per +
                          product?.rule_discount)}} % -->
                  {{'SAVE ' + (((product.crm_mrp-product.total_amount)/product.crm_mrp)*100)?.toFixed(1) + '%'}}
              </div>
              <div class="card-footer bg-white d-flex justify-content-between">
                <!-- //for related-product -->
                <div *ngIf="(isUserType?.user_type == 'MECH' && (product.inventory_quantity != 0 && product.inventory_quantity != null && convertToInt(product.moq) <= product.inventory_quantity) && product.status != 'in_active' && (product?.alternate_part?false:true)) || (isUserType?.user_type != 'MECH' && product.status != 'in_active' && (product?.alternate_part?false:true))">
                  <app-input-number aria-label="Quantity" size="lg" [min]="1" [max]="8" [isTmgoValue]="product.resource_type == 'Pack' ?product.moq:'1'"
                    (currentQuantity)="readQuantity(product,$event)" [defaultValue]="product.min_quantity" [userType]="isUserType" [inventoryQty]="product.inventory_quantity" [externalSource]="externalTrackSource">
                  </app-input-number>
                </div>
                <div *ngIf="isUserType?.user_type == 'MECH'">
                  <ng-container *ngIf="product.inventory_quantity > 0 && product.moq < product.inventory_quantity">
                    <div class="d-grid gap-2" *ngIf="product?.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                      <button class="add-to-card" (click)="addToCart(product)"><i aria-hidden="true"
                          class="fas fa-shopping-cart"></i> Add to Cart</button>
                    </div>
                  </ng-container>
                  <ng-conatiner *ngIf="(product.inventory_quantity <= 0 || product.inventory_quantity == null || convertToInt(product.moq) > product.inventory_quantity) && product.status != 'in_active' && (product?.alternate_part?false:true)">
                    <span class="outOfStock mt-2">Out Of Stock</span>
                  </ng-conatiner>
                </div>
                <ng-container *ngIf="isUserType?.user_type != 'MECH'">
                  <div class="d-grid gap-2" *ngIf="product?.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                    <button class="add-to-card" (click)="addToCart(product)"><i aria-hidden="true"
                        class="fas fa-shopping-cart"></i> Add to Cart</button>
                  </div>
                </ng-container>

              </div>
              <div class="card-footer" style="border-top: none; height: 25px;" *ngIf="product.is_nls == 'in_active'">
                <div class="row">
                  <div class="col-md-12"></div>
                </div>
            </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
<!-- account level Recommendation -->
<div class="related-product rel-prod"  *ngIf="accountLevelPartList &&  isLoggedIn; elseNoaccountLevelPartList ">
  <div class="container-fluid"  >
    <h4 class="fw-bold mb-4  mt-4 rel-prod-heading" >Parts Combination Recommendation</h4>
    <div class="row">
      <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12">
        <div id="related-product-account-level">
          <div class="item me-2 ms-2" *ngFor="let product of accountLevelPartList">
            <div class="card">
              <div class="row g-0 align-items-center">
                <div class="col-md-3">
                  <div class="prod-img mx-auto">
                    <img [src]="product?.part_image_path + '/resize/75x50'" (click)="onRouteSameUrl(product.part_number);" class="img-fluid"
                      alt="prod-img-alt" srcset="">
                  </div>
                </div>
                <div class="col-md-9">
                  <div class="card-body">
                    <div class="prod-content position-relative">
                      <!-- <div class="bookmarks-fav text-end p-0 position-absolute">
                        <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                      </div> -->
                      
                      <app-wishlist-icon [product]="product" [layout]="'Nolayout'" ></app-wishlist-icon>
                      <h3 class="fw-bold prod-model mb-2" (click)="onRouteSameUrl(product.part_number);">
                        {{ product.part_number }}</h3>

                      <h4 class="prod-name mb-2" (click)="onRouteSameUrl(product.part_number);">
                        {{ (product.short_description == " " || product.short_description == null) ? "-" : product.short_description?.substr(0,23) }}
                      </h4>
                      <!-- <p class="fw-bold"><span class="fw-normal">Estd. Delivery:</span> Today</p> -->
                      <ng-container *ngIf="!product.total_amount">
                        <div class="price fw-bold mb-3">
                          {{ ( ( product.resource_type == 'Pack' )?product.crm_mrp*product.moq:product.crm_mrp ) |currencyFormat:'INR' }}
                        </div>
                      </ng-container>
                      <ng-container *ngIf="product.total_amount">
                        <div *ngIf="(product.base_discount_per +  product.scheme_discount_per + product.rule_discount_per + product.distributor2_discount_amount + product.tml_discount_per + product.distributor_discount_per ) == 0.0">
                          <div class="price fw-bold mb-3">
                            {{ ( ( product.resource_type == 'Pack' )?product.crm_mrp*product.moq:product.crm_mrp ) |currencyFormat:'INR' }}
                          </div>
                        </div>
                        <div *ngIf="(product.base_discount_per +  product.scheme_discount_per + product.rule_discount_per + product.distributor2_discount_amount + product.tml_discount_per + product.distributor_discount_per ) > 0.0">
                          <div class="price fw-bold mb-3">
                            {{   (( product.resource_type == 'Pack') ? product.total_amount*product.moq
                                : product.total_amount)|currencyFormat:'INR'
                            }}
                            <div class="card-actual-mrp">
                                <span>
                                  MRP  {{
                                        ( product.resource_type == 'Pack' ) ? product.crm_mrp*product.moq
                                        : product.crm_mrp |currencyFormat:'INR' 
                                    }}
                                </span>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                      <span class="error-message-service text-nowrap" *ngIf="product?.is_nls == 'in_active'">No Longer
                        Serviced
                      </span>
                      <span class="error-message-service text-nowrap"
                        *ngIf="product?.is_nls != 'in_active' && product?.alternate_part?true:false">
                        No Longer available
                      </span>
                      <div class="col-12 view-alternate text-nowrap mt-1" *ngIf="product.is_nls == 'in_active' || (product?.alternate_part?true:false) ">
                        <span style="color: blue;" [routerLink]="['/shop/product-details']"
                            [queryParams]="{ part_Number : product.part_number }" style="cursor: pointer;">View Alternate Part</span>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="discount" *ngIf="(product?.base_discount_per + product?.scheme_discount_per +
                      product?.tml_discount_per + product?.distributor_discount_per +
                      product?.rule_discount_per + product.distributor2_discount_amount ) > 0" [routerLink]="['/shop/product-details']"
                  [queryParams]="{ part_Number : product.part_number }">
                  <!-- {{(product?.base_discount + product?.scheme_discount +
                          product?.tml_discount_per + product?.distributor_discount_per +
                          product?.rule_discount)}} % -->
                  {{'SAVE ' + (((product.crm_mrp-product.total_amount)/product.crm_mrp)*100)?.toFixed(1) + '%'}}
              </div>
              <div class="card-footer bg-white d-flex justify-content-between">
                <!-- //for related-product -->
                <div *ngIf="(isUserType?.user_type == 'MECH' && (product.inventory_quantity != 0 && product.inventory_quantity != null && convertToInt(product.moq) <= product.inventory_quantity) && product.status != 'in_active' && (product?.alternate_part?false:true)) || (isUserType?.user_type != 'MECH' && product.status != 'in_active' && (product?.alternate_part?false:true))">
                  <app-input-number aria-label="Quantity" size="lg" [min]="1" [max]="8" [isTmgoValue]="product.resource_type == 'Pack'?product.moq:'1'"
                    (currentQuantity)="readQuantity(product,$event)" [defaultValue]="product.min_quantity" [userType]="isUserType" [inventoryQty]="product.inventory_quantity" [externalSource]="externalTrackSource">
                  </app-input-number>
                </div>
                <div *ngIf="isUserType?.user_type == 'MECH'">
                  <ng-container *ngIf="product.inventory_quantity > 0 && product.moq < product.inventory_quantity">
                    <div class="d-grid gap-2" *ngIf="product?.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                      <button class="add-to-card" (click)="addToCart(product)"><i aria-hidden="true"
                          class="fas fa-shopping-cart"></i> Add to Cart</button>
                    </div>
                  </ng-container>
                  <ng-conatiner *ngIf="(product.inventory_quantity <= 0 || product.inventory_quantity == null || convertToInt(product.moq) > product.inventory_quantity) && product?.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                    <span class="outOfStock">Out Of Stock</span>
                  </ng-conatiner>
                </div>
                <ng-container *ngIf="isUserType?.user_type != 'MECH'">
                  <div class="d-grid gap-2" *ngIf="product?.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                    <button class="add-to-card" (click)="addToCart(product)"><i aria-hidden="true"
                        class="fas fa-shopping-cart"></i> Add to Cart</button>
                  </div>
                </ng-container>
            
              </div>
              <div class="card-footer" style="border-top: none; height: 25px;" *ngIf="product.is_nls == 'in_active'">
                <div class="row">
                  <div class="col-md-12"></div>
                </div>
            </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
<span #elseNoaccountLevelPartList></span>
<!-- Fleet Recommendation -->
<!-- <div class="related-product rel-prod"  *ngIf="fleetEdgePartList &&  isLoggedIn; elseNoFleetEdgePartList ">
  <div class="container-fluid"  >
    <h4 class="fw-bold mb-4  mt-4 rel-prod-heading" >Fleet Recommendation</h4>
    <div class="row">
      <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12">
        <div id="related-product-fleet-edge-level">
          <div class="item me-2 ms-2" *ngFor="let product of fleetEdgePartList">
            <div class="card">
              <div class="row g-0 align-items-center">
                <div class="col-md-3">
                  <div class="prod-img mx-auto">
                    <img [src]="product?.image" (click)="onRouteSameUrl(product.part_number);" class="img-fluid"
                      alt="prod-img-alt" srcset="">
                  </div>
                </div>
                <div class="col-md-9">
                  <div class="card-body">
                    <div class="prod-content position-relative">
                      <app-wishlist-icon [product]="product" [layout]="'Nolayout'" ></app-wishlist-icon>
                      <h3 class="fw-bold prod-model mb-2" (click)="onRouteSameUrl(product.part_number);">
                        {{ product.part_number }}</h3>

                      <h4 class="prod-name mb-2" (click)="onRouteSameUrl(product.part_number);">
                        {{ (product.part_desc == " " || product.part_desc == null) ? "-" : product.part_desc?.substr(0,23) }}
                      </h4>
                      <ng-container *ngIf="!product.total_amount">
                        <div class="price fw-bold mb-3" *ngIf="product.column_4">
                          {{ (product.crm_mrp*product.column_4) |currencyFormat:'INR' }}
                        </div>
                        <div class="price fw-bold mb-3" *ngIf="!product.column_4">
                          {{ product.crm_mrp |currencyFormat:'INR' }}
                        </div>
                      </ng-container>
                      <ng-container *ngIf="product.total_amount">
                        <div *ngIf="(product.base_discount +  product.scheme_discount + product.rule_discount + product?.distributor2_discount_amount) == 0.0">
                          <div class="price fw-bold mb-3" *ngIf="product.column_4">
                            {{ (product.crm_mrp*product.column_4) |currencyFormat:'INR' }}
                          </div>
                          <div class="price fw-bold mb-3" *ngIf="!product.column_4">
                            {{ product.crm_mrp |currencyFormat:'INR' }}
                          </div>
                        </div>
                        <div *ngIf="(product.base_discount +  product.scheme_discount + product.rule_discount + product?.distributor2_discount_amount) > 0.0">
                          <div class="price fw-bold mb-3" *ngIf="product.column_4">
                            {{ (product.total_amount * product.column_4) |currencyFormat:'INR' }}
                            <span>
                              MRP {{ (product.crm_mrp * product.column_4) | currencyFormat :'INR' }} </span>
                          </div>
                          <div class="price fw-bold mb-3" *ngIf="!product.column_4">
                            {{ product.total_amount |currencyFormat:'INR' }}
                            <span>
                              MRP {{ product.crm_mrp | currencyFormat :'INR' }} </span>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer bg-white d-flex justify-content-between">
                <app-input-number aria-label="Quantity" size="lg" [min]="1" [max]="8"
                  [defaultValue]="product.min_quantity" (currentQuantity)="readQuantity($event,product,false);">
                </app-input-number>
                <div class="d-grid gap-2">
                  <button class="add-to-card" (click)="addToCart(product)"><i aria-hidden="true"
                      class="fas fa-shopping-cart"></i> Add to Cart</button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
<span #elseNoFleetEdgePartList></span> -->
<!-- Overall Recommendation -->
<ng-container *ngIf="!overallPartList && isLoggedIn && !isApiRecommededLoad; else overallRecommendationList">
  <div class="related-product rel-prod">
    <div class="container-fluid"  >
      <h4 class="fw-bold mb-4  mt-4 rel-prod-heading" >Similar Parts Ordered By Other Customer
      </h4>
      <div class="row">
        <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12">
          <div id="related-product-overall">
            <div class="item me-2 ms-2 mb-5">
              <owl-carousel-o [options]="customOptionsForAccountRec">
                <ng-container *ngFor="let item of userService.generateFake(4);let i = index;">
                    <ng-template carouselSlide [id]="i">
                        <div class="me-2 mb-4">
                            <div class="recent-item-card card">
                                <div class="card-body cursor">
                                    <div class="prod-image cursor">
                                        <skc-rectangle [loading]="loading" [skc-height]="'80px'" [skc-width]="'100%'"> </skc-rectangle>
                                    </div>
                                    <div class="prod-desc" >
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="modal-number">
                                                    <skc-rectangle [loading]="loading" [skc-height]="'19px'" [skc-width]="'100%'"> </skc-rectangle>
                                                </div>
                                                <div class="modal-name">
                                                    <skc-rectangle [loading]="loading" [skc-height]="'16px'" [skc-width]="'100%'"> </skc-rectangle>
                                                </div>
                                                <div class="delivery-time w-50 d-inline-block text-nowrap">
                                                    <skc-rectangle [loading]="loading" [skc-height]="'14px'" [skc-width]="'100%'"> </skc-rectangle>
                                                </div>
                                                <div class="price fw-bold ">
                                                    <skc-rectangle [loading]="loading" [skc-height]="'14px'" [skc-width]="'100%'"> </skc-rectangle>
                                                </div>
                                            </div>
                                            <div class="col-5">
                                                <skc-rectangle [loading]="loading" [skc-height]="'20px'" [skc-width]="'100%'"> </skc-rectangle>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>
              </owl-carousel-o>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #overallRecommendationList>
  <div class="related-product rel-prod"  *ngIf="overallPartList && isLoggedIn; elseNoOverallPartList">
    <div class="container-fluid"  >
      <h4 class="fw-bold mb-4  mt-4 rel-prod-heading" >Similar Parts Ordered By Other Customers
      </h4>
      <div class="row">
        <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12">
          <div id="related-product-overall">
            <div class="item me-2 ms-2 mb-4" *ngFor="let product of overallPartList">
              <div class="card" style="height: 207px;">
                <div class="card-body p-2">
                <div class="row g-0 align-items-center">
                  
                  <div class="col-md-4">
                    <div class="prod-img mx-auto">
                      <img [src]="product?.part_image_path + '/resize/75x50'" (click)="onRouteSameUrl(product.part_number);" class="img-fluid"
                        alt="prod-img-alt" srcset="">
                    </div>
                  </div>
                  <div class="col-md-8">
                      <div class="prod-content">
                        <!-- <div class="bookmarks-fav text-end p-0 position-absolute">
                          <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                        </div> -->
                        
                        <app-wishlist-icon [product]="product" [layout]="'Nolayout'"></app-wishlist-icon>
                         
                        <h3 class="fw-bold prod-model mb-2" (click)="onRouteSameUrl(product.part_number);">
                          <!-- {{ product.part_desc }} -->
                          {{ (product.short_description == " " || product.short_description == null) ? "-" : (product.short_description.length > 35 ?(product.short_description.substr(0, 35) + '...') :product.short_description) }}
                        </h3>
                        <h4 class="prod-name mb-2" (click)="onRouteSameUrl(product.part_number);">
                          {{ product.part_number }}</h4>
                        <!-- <p class="fw-bold"><span class="fw-normal">Estd. Delivery:</span> Today</p> -->
                        <!-- <span class="save-mode" *ngIf="product.resource_type == 'Pack'">Pack of {{ product.column_4 ? product?.column_4 / 1000 + ' litres' : product.moq / 1 + ' pieces' }}</span> -->
                        <ng-container *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true); else elseTemplate"> 
                        <ng-container *ngIf="!product.total_amount">
                          <div class="price fw-bold mb-3">
                            {{ ( ( product.resource_type == 'Pack' )?product.crm_mrp*product.moq:product.crm_mrp ) |currencyFormat:'INR' }}
                          </div>
                        </ng-container>
                        <ng-container *ngIf="product.total_amount">
                          <div *ngIf="(product.base_discount_per +  product.scheme_discount_per + product.rule_discount_per + product.distributor2_discount_amount + product.tml_discount_per + product.distributor_discount_per ) == 0.0">
                            <div class="price fw-bold mb-3">
                              {{ ( ( product.resource_type == 'Pack' )?product.crm_mrp*product.moq:product.crm_mrp ) |currencyFormat:'INR' }}
                            </div>
                          </div>
                          <div *ngIf="(product.base_discount_per +  product.scheme_discount_per + product.rule_discount_per + product.distributor2_discount_amount + product.tml_discount_per + product.distributor_discount_per ) > 0.0">
                            <div class="price fw-bold mb-3">
                              {{   (( product.resource_type == 'Pack' ) ? product.total_amount*product.moq
                                  : product.total_amount) |currencyFormat:'INR'
                              }}
                              <div class="card-actual-mrp">
                                  <span>
                                    MRP  {{
                                          ( product.resource_type == 'Pack' ) ? product.crm_mrp*product.moq
                                          : product.crm_mrp |currencyFormat:'INR' 
                                      }}
                                  </span>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </ng-container>
                        <ng-template #elseTemplate>
                          <div class="price fw-bold">
                          {{ (( product.resource_type == 'Pack' )?product.crm_mrp*product.moq:product.crm_mrp ) |currencyFormat:'INR' }}
                          </div>
                      </ng-template>
                        <span class="error-message-service text-nowrap" *ngIf="product?.is_nls == 'in_active'">No Longer
                          Serviced
                        </span>
                        <span class="error-message-service text-nowrap"
                          *ngIf="product?.is_nls != 'in_active' && product?.alternate_part?true:false">
                          No Longer available
                        </span>
                        <div class="col-12 view-alternate text-nowrap mt-1" *ngIf="product.is_nls == 'in_active' || (product?.alternate_part?true:false) ">
                          <span style="color: blue;" [routerLink]="['/shop/product-details']"
                              [queryParams]="{ part_Number : product.part_number }" style="cursor: pointer;">View Alternate Part</span>
                        </div>
                      </div>
                  </div>
                </div>
                <ng-container *ngIf="product.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                <div class="discount" *ngIf="(product?.base_discount_per + product?.scheme_discount_per +
                        product?.tml_discount_per + product?.distributor_discount_per +
                        product?.rule_discount_per + product.distributor2_discount_amount ) > 0" [routerLink]="['/shop/product-details']"
                    [queryParams]="{ part_Number : product.part_number }">
                    <!-- {{(product?.base_discount + product?.scheme_discount +
                            product?.tml_discount_per + product?.distributor_discount_per +
                            product?.rule_discount)}} % -->
                    {{'SAVE ' + (((product.crm_mrp-product.total_amount)/product.crm_mrp)*100)?.toFixed(1) + '%'}}
                </div>         
                </ng-container>
                </div>
              <!-- </ng-container> -->
                <div class="card-footer bg-white d-flex justify-content-between">
                  <!-- //for related-product -->
                  <div *ngIf="(isUserType?.user_type == 'MECH' && (product.inventory_quantity != 0 && product.inventory_quantity != null && product.moq <= product.inventory_quantity) && product.is_nls != 'in_active' && (product?.alternate_part?false:true)) || (isUserType?.user_type != 'MECH' && product.is_nls != 'in_active' && (product?.alternate_part?false:true))">
                    <app-input-number aria-label="Quantity" size="lg" [min]="1" [max]="8" [isTmgoValue]="product.resource_type == 'Pack' ?product.moq:'1'"
                      (currentQuantity)="readQuantity(product,$event)" [defaultValue]="product.min_quantity" [userType]="isUserType" [inventoryQty]="product.inventory_quantity" [externalSource]="externalTrackSource">
                    </app-input-number>
                  </div>
                  <div *ngIf="isUserType?.user_type == 'MECH'">
                    <ng-container *ngIf="product.inventory_quantity > 0 && product.moq < product.inventory_quantity">
                      <div class="d-grid gap-2" *ngIf="product?.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                        <button class="add-to-card" (click)="addToCart(product)"><i aria-hidden="true"
                            class="fas fa-shopping-cart"></i> Add to Cart</button>
                      </div>
                    </ng-container>
                    <ng-conatiner *ngIf="(product.inventory_quantity <= 0 || product.inventory_quantity == null || convertToInt(product.moq) > product.inventory_quantity) && product?.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                      <span class="outOfStock mt-2">Out Of Stock</span>
                    </ng-conatiner>
                  </div>
                  <ng-container *ngIf="isUserType?.user_type != 'MECH'">
                    <div class="d-grid gap-2" *ngIf="product?.is_nls != 'in_active' && (product?.alternate_part?false:true)">
                      <button class="add-to-card" (click)="addToCart(product)"><i aria-hidden="true"
                          class="fas fa-shopping-cart"></i> Add to Cart</button>
                    </div>
                  </ng-container>
                  
                </div>
                <div class="card-footer" style="border-top: none; height: 25px;" *ngIf="product.is_nls == 'in_active'">
                    <div class="row">
                      <div class="col-md-12"></div>
                    </div>
                </div>
              </div>
            </div>
          </div>
  
        </div>
      </div>
    </div>
  </div>
  <span #elseNoOverallPartList></span>
</ng-template>




<!-- <div class="row pt-2 rel-prod" *ngIf="alternateProductsList.length > 0">
  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <h4 class=" mb-3 rel-prod-heading ">Related Products</h4>
  </div>
  
  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
      <div id="related-product">
            <div class="item m-2" *ngFor="let product of alternateProductsList">
              <div class="card">
                <div class="row g-0 align-items-center">
                  <div class="col-md-4">
                    <div class="prod-img mx-auto">
                      <img [src]="product?.image" class="img-fluid" alt="prod-img-alt" srcset="">
                    </div>
                  </div>
                  <div class="col-md-8">
                      <div class="card-body">
                        <div class="prod-content position-relative">
                            <div class="bookmarks-fav text-end p-0 position-absolute">
                              <a href="#" class="add-to-favorite"><img alt="" src="./assets/images/bookmark.svg"/></a>
                            </div>
                            <h3 class="fw-bold prod-model mb-2">{{ product.part_number }}</h3>
                            <h4 class="prod-name mb-2">{{ product.desc }}</h4>
                            <p class="fw-bold"><span class="fw-normal">Estd. Delivery:</span> Today</p>
                            
                          
                              <div class="mt-3">
                                
                                <div class="price fw-bold ">
                                    8,383.00
                                  <span>
                                    MRP 8,383.00 </span>
                                </div>
                              </div>
                              <ng-container *ngIf="!product.total_amount">
                                <div class="price fw-bold" *ngIf="product.column_4">
                                  {{ (product.crm_mrp*product.column_4) |currencyFormat:'INR' }}
                                </div>
                                <div class="price fw-bold" *ngIf="!product.column_4">
                                  {{ product.crm_mrp |currencyFormat:'INR' }}
                                </div>
                              </ng-container>
                              <ng-container *ngIf="product.total_amount">
                                <div *ngIf="(product.base_discount +  product.scheme_discount + product.rule_discount + product?.distributor2_discount_amount) == 0.0">
                                  <div class="price fw-bold" *ngIf="product.column_4">
                                    {{ (product.crm_mrp*product.column_4) |currencyFormat:'INR' }}
                                  </div>
                                  <div class="price fw-bold" *ngIf="!product.column_4">
                                    {{ product.crm_mrp |currencyFormat:'INR' }}
                                  </div>
                                </div>
                                <div *ngIf="(product.base_discount +  product.scheme_discount + product.rule_discount + product?.distributor2_discount_amount) > 0.0">
                                  <div class="mt-3">
                                  <div class="price fw-bold" *ngIf="product.column_4">
                                    {{ (product.total_amount * product.column_4) |currencyFormat:'INR' }}
                                    <span>
                                      MRP {{ (product.crm_mrp * product.column_4) | currencyFormat :'INR' }} </span>
                                  </div>
                                </div>
                                <div class="mt-3">
                                  <div class="price fw-bold" *ngIf="!product.column_4">
                                    {{ product.total_amount |currencyFormat:'INR' }}
                                    <span>
                                      MRP {{ product.crm_mrp | currencyFormat :'INR' }} </span>
                                  </div>
                                </div>
                                </div>
                              </ng-container>
                          </div>   
                      </div>
                                               
                  </div>
                </div>
                <div class="card-footer bg-white ">
                    <div class="row g-0 align-items-center">
                        <div class="col-4"></div>
                        <div class="col-8 d-flex justify-content-between">
                            <app-input-number aria-label="Quantity" size="lg" [min]="1" >
                            </app-input-number>
                            <div class="d-grid gap-2">
                              <button class="add-to-card"><img alt="" src="../../assets/images/cart.svg" class="d-inline-block"/> Add to Cart</button>
                            </div>
                        </div>
                    </div>
                    
                  
                </div>
              </div>
            </div>
        </div>
  </div>
</div> -->
<br>
<!-- <div class="compare-products mb-4">
  <div class="container-fluid">
    <div class="">
      <h4 class="fw-bold mb-4 cp-prod-heading text-uppercase">Compare Products</h4>
      <div class="table-responsive pe-3 ps-3  bg-white shadow pt-3 pb-3">
        <table class="table table-borderless table-sm align-bottom c-parent-table">
          <tbody>
            <tr>
              <td>
                <table class="table table-compatible table-borderless sub-table table-sm">
                  <tbody>
                    <tr>
                      <td>Price</td>
                    </tr>
                    <tr>
                      <td>Brand</td>
                    </tr>
                    <tr>
                      <td>Recomended Use</td>
                    </tr>
                    <tr>
                      <td>Material</td>
                    </tr>
                    <tr>
                      <td>Product Fit</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>
                <img src="" class="img-fluid img-prod">
                <div class="comp-title">#272425400159</div>
                <div class="comp-desc">160 Dia. Assly. Clutch Cover</div>
                <table class="table table-compatible table-borderless table-sm sub-table">
                  <tbody>
                    <tr>
                      <td>₹ 10,025.00</td>
                    </tr>
                    <tr>
                      <td>TATA Genuine parts</td>
                    </tr>
                    <tr>
                      <td>OE replacement</td>
                    </tr>
                    <tr>
                      <td>Organic disc</td>
                    </tr>
                    <tr>
                      <td>Direct Fit</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>
                <img src="" class="img-fluid img-prod">
                <div class="comp-title">#272425400159</div>
                <div class="comp-desc">160 Dia. Assly. Clutch Cover</div>
                <table class="table table-compatible  table-borderless table-sm sub-table">

                  <tbody>
                    <tr>
                      <td>₹ 10,025.00</td>
                    </tr>
                    <tr>
                      <td>TATA Genuine parts</td>
                    </tr>
                    <tr>
                      <td>OE replacement</td>
                    </tr>
                    <tr>
                      <td>Organic disc</td>
                    </tr>
                    <tr>
                      <td>Direct Fit</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>
                <img src="" class="img-fluid img-prod">
                <div class="comp-title">#272425400159</div>
                <div class="comp-desc">160 Dia. Assly. Clutch Cover</div>
                <table class="table table-compatible  table-borderless table-sm sub-table">

                  <tbody>
                    <tr>
                      <td>₹ 10,025.00</td>
                    </tr>
                    <tr>
                      <td>TATA Genuine parts</td>
                    </tr>
                    <tr>
                      <td>OE replacement</td>
                    </tr>
                    <tr>
                      <td>Organic disc</td>
                    </tr>
                    <tr>
                      <td>Direct Fit</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>


  </div>
</div> -->

<ng-template #confimationmodal let-d="dismiss">
  <app-selectCartType [btnshowConfrimed]="btnshowConfrimed" [currentlyAddingProduct]="currentlyAddingProduct"
    [btnshowsubmit]="btnshowsubmit" [cartTypeList]="cartTypeList" [baseProductdata]="productInfo"
    (setCartType)="setAddCart($event)" (selectedCartType)="ShowPart()" [fromPath]="productdetailpage" [productDetail]="productList" (inputChange)="changeCartType($event)"
    (closeModal)="closePart()">
  </app-selectCartType>
</ng-template>

<ng-template #addressModal let-d="dismiss" style="width: auto !important;">
  <delivery-address (closemodal)="closemodel()" [isProductDetails]="isProductDetails"></delivery-address>
</ng-template>

<ng-container *ngIf="showLoader">
  <div class="loader">
    <div class="ng-spinner ng-spinner-icon"></div>
  </div>
</ng-container>

<ng-template #childParts let-d="dismiss">
  <child-parts [sendchildproduct]="sendchildproduct" (closePopUp)="closePopUp()"></child-parts>
</ng-template>

<ng-template #selectModalPopup let-d="dismiss">
  <app-productmodel [partNumber]="partNumber" (closeModelPopUp)="closeModelPopUp()"
    (showviewAddress)="viewAddressModal()" (showsubModel)="showsubModel($event)"></app-productmodel>
</ng-template>

<ng-template #selectsubModalPopup let-d="dismiss">
  <app-productsubmodel [selectmodel]="selectmodel" (closeSubModelPopUp)="closeSubModelPopUp()"
    (showVcDetails)="showVcDetails($event)"></app-productsubmodel>
</ng-template>

<ng-template #partDiscounts let-d="dismiss">
  <part-discounts [discountOffers]="discountOffers" (closePopUp)="partDiscountsModal.hide();"></part-discounts>
</ng-template>

<ng-template #alternatePartModal let-d="dismiss">
  <alternate-part [alternatePartData]="alternatePartData" (closemodal)="alternatePart.hide();"></alternate-part>
</ng-template>

<ng-template #schemeList let-d="dismiss">
  <app-product-details-schemes (closeModel)="schemeListModal.hide()" [schemeDetails]="validSchemeList" [offerDetails]="discountOfferData" [slabOffer]="discountSlabOffer"
  [popupOpenType]="popupOpenType"></app-product-details-schemes>
</ng-template>

<!-- <ng-template #fullImage>
  <app-pdp-fullimage [modalImage]="modalImage" [type]="imgType" [modelClose]="fullimageModal"></app-pdp-fullimage>
</ng-template> -->
<ng-template #fullImagePopup>
  <app-full-image-popup [modalImage]="modalImage"  [modelClose]="fullimageModal"></app-full-image-popup>
</ng-template>