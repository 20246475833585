<div class="modal-header modal-title">
  All Models
  <div (click)="closeModal()" type="button"><i class="fa fa-times"></i></div>
</div>
<div class="modal-body">
  <div class="form-group form-row">
    <div class="addresses-list">
      <div class="row m-0 w-100" id="addressHead">
        <div class="p-1 mat-min-h setdeliveryaddress col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4" *ngFor="let model of allModels;let i=index;">
          <div class="box-container" (click)="viewPartsForSelectedModel(model);" [ngClass]="activeModelCategory == model.LINE_2_s ? 'active':''">
            <div class="text-con" title="{{model?.LINE_2_s}}" >
              {{ model.LINE_2_s | titlecase }}
            </div>
            <div class="img-con"><img alt="" [src]="model?.image_url" class="img-fluid"
                onerror="this.src='./assets/tata-vehicle.jfif';" /></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
