import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core'; 

@Component({
    selector: 'image-zoom-view',
    templateUrl: './image-zoom-view.component.html',
    styleUrls: ['./image-zoom-view.component.scss']
})

export class ImageZoomViewComponent implements OnInit {
    @Input() subAggregate: any;
    @Output() closeImageZoomView = new EventEmitter<any>();
    constructor(){}
    ngOnInit(){}

    closeModal(){
        this.closeImageZoomView.emit();
    }
}