<div class="modal-header modal-title">
  Isometric Parts List
  <div (click)="closeModal()" type="button"><i class="fa fa-times"></i></div>
</div>
<div class="modal-body">
  <ul id="progressbar">
    <li [ngClass]="(currentTab === 'Vehicle Details') ? 'active' : 'active complete'"><span></span>Vehicle Details</li>
    <li [ngClass]="(currentTab === 'Part List') ? 'active' : ''"><span></span>Part Lists</li>
  </ul>
  <span class="" *ngIf="currentTab === 'Part List'">
    <a (click)="currentTab = 'Vehicle Details';closePopup('Close');" style="color: #1f3488;cursor: pointer;">
      <img src="./assets/images/U_turn_back_button.jpg" style="width: 42px;"><br>
      <span>Go Back</span>
    </a>
  </span>
  <!-- <span class="" *ngIf="currentTab === 'Vehicle Details' && figBasedPartList && selectedSubAggregate">
    <a (click)="currentTab = 'Part List'" style="float: right;color: #1f3488;cursor: pointer;">
      <div class="text-center">
        <img src="./assets/images/U_turn_back_button.jpg"
        style="width: 42px;-webkit-transform: scaleX(-1);transform: scaleX(-1);"><br>
      </div>
        <span>Go Back To Selected SubAggregate</span>
    </a>
  </span> -->
  <ng-template [ngIf]="currentTab === 'Vehicle Details'">
    <form [formGroup]="vehicleDetailsForm">
      <div class="row">
        <div class="col-4">
          <div class="nav tabs1" id="myTab" role="tablist">
            <mat-form-field class="nav-item" style="display: inline;padding: 16px !important;">
              <mat-select placeholder="Vehicle Model" (selectionChange)="onModelChange($event.value)"
                name="modelCategory" formControlName="modelCategory">
                <mat-option value="">Select Vehicle Model</mat-option>
                <mat-option *ngFor="let model of modelList" value="{{model}}">{{model}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="col-4">
          <div class="nav tabs1" id="myTab" role="tablist">
            <mat-form-field class="nav-item" style="display: inline;padding: 16px !important;">
              <mat-select placeholder="Vehicle Sub Model" (selectionChange)="onSubModelChange($event.value)"
                name="subModelCategory" formControlName="subModelCategory">
                <mat-option value="">Select Vehicle Sub Model</mat-option>
                <mat-option *ngFor="let subModel of subModelList" value="{{subModel}}">
                  {{subModel}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="col-4">
          <div class="nav tabs1" id="myTab" role="tablist">
            <mat-form-field class="nav-item" style="display: inline;padding: 16px !important;">
              <mat-select placeholder="Vehicle VC No." (selectionChange)="onVcNoChange($event.value)" name="vcNo"
                formControlName="vcNo">
                <mat-option value="">Select Vehicle VC No.</mat-option>
                <mat-option *ngFor="let vcNo of vcNoList" value="{{vcNo}}">{{vcNo}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div id="aggregatelist" class="recommended-option">
        <ng-container *ngIf="!isAggregateListLoaded; else aggregateListData">
          <owl-carousel-o [options]="customOptionsForAggregates">
            <ng-container *ngFor="let item of userService.generateFake(10);let i = index;">
              <ng-template carouselSlide [id]="i">
                <div class="form-check  parts-option ms-3" style="width: 8rem;">
                  <skc-rectangle [loading]="loading" [skc-height]="'20px'" [skc-width]="'100%'"> </skc-rectangle>
                </div>
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </ng-container>
        <ng-template #aggregateListData>
          <ng-template [ngIf]="selectedModelCategory && selectedSubModelCategory && selectedVcNo">
            <owl-carousel-o [options]="customOptionsForAggregates">
              <ng-container *ngFor="let aggregate of aggregateList">
                <ng-template carouselSlide [id]="aggregate.aggregate">
                  <div
                    [ngClass]="(selectedAggregate === aggregate.aggregate) ? 'form-check  parts-option select-part-option ms-3 pb-2' : 'form-check  parts-option ms-3 pb-2'">
                    <input class="form-check-input" formControlName="aggregate" type="radio" name="aggregate"
                      id="{{aggregate.aggregate}}" checked="" value="{{aggregate.aggregate}}">
                    <label class="form-check-label" (click)="viewSubAggregates(aggregate.aggregate)"
                      for="aggregate.aggregate">{{aggregate.aggregate}} </label>
                  </div>
                </ng-template>
              </ng-container>
            </owl-carousel-o>
          </ng-template>

        </ng-template>
        <div class="row pt-2">
          <div class="addresses-list">
            <div class="row m-0 w-100" id="addressHead">
              <ng-container *ngIf="!isSubAggregatesLoaded; else elseBlock">
                <div
                  class="p-3 border border-dashed mat-min-h setdeliveryaddress col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4"
                  *ngFor="let address of userService.generateFake(9); let i=index;">
                  <div class="d-flex justify-content-between align-items-center mb-2 ">
                    <h4 class="mat-location-type mb-0">
                    </h4>
                  </div>
                  <p class="mat-location-name">
                    <skc-rectangle [loading]="loading" [skc-height]="'19px'" [skc-width]="'100%'"> </skc-rectangle>
                  </p>
                  <p class="mat-deliver-address">
                    <skc-rectangle [loading]="loading" [skc-height]="'57px'" [skc-width]="'100%'"> </skc-rectangle>
                  </p>
                  <p class="mat-gst">
                    <skc-rectangle [loading]="loading" [skc-height]="'19px'" [skc-width]="'100%'"> </skc-rectangle>
                  </p>
                  <div class="from-bottom">
                    <skc-rectangle [loading]="loading" [skc-height]="'48px'" [skc-width]="'100%'"> </skc-rectangle>
                  </div>
                </div>
              </ng-container>
              <ng-template #elseBlock>
                <ng-template
                  [ngIf]="selectedModelCategory && selectedSubModelCategory && selectedVcNo && selectedAggregate">
                  <div class="p-3 mat-min-h setdeliveryaddress col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4"
                    *ngFor="let subAggregate of subAggregatesList;let j = index">
                    <a class="menu-option d-block" style="width: 95%">
                      <div class="card">
                        <div class="card-body text-center" (click)="getPartListForSubAggregate(subAggregate);">
                          <img alt="menu-icon" [src]="subAggregate?.sub_aggregate_image"
                            style="width: 80px; height: 80px" />
                          <div class="menu-name ellipse-2line" style="height: 40px"
                            title="{{subAggregate.sub_aggregate_no | titlecase }}">
                            {{subAggregate.sub_aggregate_no}}
                          </div>
                          <div class="menu-name ellipse-2line" style="height: 40px"
                            title="{{subAggregate.sub_aggregate | titlecase }}">
                            {{subAggregate.sub_aggregate | titlecase}}
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </ng-template>
              </ng-template>
              <div class="addresses-list__divider"></div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template [ngIf]="currentTab === 'Part List'">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 mt-3 large-img-container">
        <div class="img-part border">
          <img alt="" class="img-fluid" [src]="selectedSubAggregate?.sub_aggregate_image" />
        </div>

      </div>
      <div class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 mt-3 part-table">
        
        <app-partpopup *ngIf="showPopup" [showPart]="showPartData" (closePopup)="closePopup($event)" [pageName]="Isometric"></app-partpopup>
       <div class="table-responsive">
          <table class="table table-sm" id="tbl-part-details">
            <thead>
              <tr>
                <!-- <th></th> -->
                <th>FIG</th>
                <th>Part No.</th>
                <th>Part Name</th>
                <th>Price(₹) </th>
                <th>Qty</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="!isProductListLoaded; else showProductList">
                <tr *ngFor="let items of userService.generateFake(20); let i=index;">
                  <td>
                    <skc-rectangle [loading]="loading" [skc-height]="'24px'" [skc-width]="'20px'"> </skc-rectangle>
                  </td>
                  <td>
                    <skc-rectangle [loading]="loading" [skc-height]="'24px'" [skc-width]="'95px'"> </skc-rectangle>
                  </td>
                  <td>
                    <skc-rectangle [loading]="loading" [skc-height]="'24px'" [skc-width]="'178px'"> </skc-rectangle>
                  </td>
                  <td>
                    <skc-rectangle [loading]="loading" [skc-height]="'24px'" [skc-width]="'64px'"> </skc-rectangle>
                  </td>
                  <td>
                    <skc-rectangle [loading]="loading" [skc-height]="'24px'" [skc-width]="'74px'"> </skc-rectangle>
                  </td>
                  <td>
                    <skc-rectangle [loading]="loading" [skc-height]="'24px'" [skc-width]="'18px'"> </skc-rectangle>
                  </td>
                </tr>
              </ng-container>
              <ng-template #showProductList>
                <ng-container *ngIf="figBasedPartList?.length; else noRecordsProductBlock">
                  <tr id="tr_{{items?.part_number}}" *ngFor="let items of figBasedPartList">
                    <td (click)="temp()">
                      {{ items?.fig_no }}
                    </td>
                    <td>
                      <a class="btn-part-popup text-decoration-none" style="cursor: pointer;" (click)="showparts(items)">{{ items?.part_number }}</a>
                    </td>
                    <td (click)="temp()"> {{ items?.short_description }}</td>
                    <td (click)="temp()">{{ items?.crm_mrp|currencyFormat:'INR' }}</td>
                    <td (click)="temp()">

                      <app-input-number aria-label="Quantity" size="lg" [min]="1"
                        (currentQuantity)="readQuantityForEnginePartList($event,items)"
                        [defaultValue]="items?.min_quantity" [isTmgoValue]="(items?.resource_type == 'Pack')?item?.moq:'1'">
                      </app-input-number>
                    </td>
                    <td>
                      <img class="cursor" id="btn_{{items?.part_number}}" alt="" src="./assets/images/cart.svg"
                        (click)="addtoCartitemsForEnginePartList(items)" />
                    </td>
                  </tr>
                </ng-container>
              </ng-template>
              <ng-template #noRecordsProductBlock>
                <tr *ngIf="figBasedPartList?.length === 0">
                  <th colspan="12">
                    <div class="no-record-block">
                      <h6>No Record Found</h6>
                    </div>
                  </th>
                </tr>
              </ng-template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #imageZoomView let-d="dismiss">
  <image-zoom-view [subAggregate]="selectedSubAggregate" (closeImageZoomView)="imageZoomViewModal.hide();">
  </image-zoom-view>
</ng-template>
