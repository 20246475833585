import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../services/REST/auth.service';
@Component({
    selector: 'select-user-type',
    templateUrl: './select-user-type.component.html',
    styleUrls: ['./select-user-type.component.scss']
})
export class SelectUserTypeComponent implements OnInit {
    @Input() userIds: any[];
    @Input() userTypes:any[];
    @Output() bindUserAccounts = new EventEmitter<any>();
    // isRTSeleceted: boolean = false;
    // isFOSeleceted: boolean = false;
    // isGuestSelected: boolean = false;
    isLogin: boolean;
    isRegistration: boolean;
    firstcall=true;
    @Output() bindUserType = new EventEmitter<any>();
    size400_900 = false;

    constructor(private authService: AuthService,
        private toastrService: ToastrService,
        private route: ActivatedRoute,) { this.onResize(); }
    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            this.isLogin = params['isLogin'];
            this.isRegistration = params['isRegistration'];
        })
        this.userTypes = this.userTypes.sort((a,b) => a > b ? 1 : -1);
    }

    @HostListener('window:resize', ['$event'])
    onResize(event?) {
        this.size400_900 = false;
        if(window.innerWidth > 417 && window.innerWidth < 991) {
            this.size400_900 = true;
        } 
    }
    getUsertypeList(type, offSet, limit) {
        if(this.firstcall){
        var json = { "user_type": type, "offset": offSet, "limit": limit };
        this.authService.getUserTypeList(json).subscribe(response => {
            if (response.success === true) {
                this.userIds = response["data"];
                // if (type == "FO") {
                //     this.isFOSeleceted = true;
                //     this.isRTSeleceted = false;
                //     this.isGuestSelected = false;
                // }
                // else if (type = "RT") {
                //     this.isRTSeleceted = true;
                //     this.isFOSeleceted = false;
                //     this.isGuestSelected = false;
                // }
                // else if (type == "GU") {
                //     this.isFOSeleceted = false;
                //     this.isRTSeleceted = false;
                //     this.isGuestSelected = true;
                // }
                this.bindUserAccounts.emit(this.userIds);
            }
            else {
                return this.toastrService.error(response.data.msg);
            }

        },
        error => {
          this.toastrService.error(error.error.data.msg);
        })
        this.firstcall=false;
    }
    }

    bindUsertype(event) {
        // if (event == "FO") {
        //     this.isFOSeleceted = true;
        //     this.isRTSeleceted = false;
        // }
        // else if (event = "RT") {
        //     this.isRTSeleceted = true;
        //     this.isFOSeleceted = false;
        // }
        this.bindUserType.emit(event);
    }

}