import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { applicationConfiguration, AppConfig } from '../../../../../src/app/config/app.config';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class FitmentService {

  protected newServer = this.appConfig.apiUrl+'api/v2/ecom/';
  readonly options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      // 'X-Content-Type-Options':  'nosniff',
      // 'X-XSS-Protection': '1',
      //  'mode': 'block'

    }),
  };

  constructor(
    private http: HttpClient,
    @Inject(applicationConfiguration) private appConfig: AppConfig
  ) { }

  getCustInfoFromReg(product:any): Observable<any> {
    return this.http.post(`${this.newServer}order/ds_new_customer_true/`, product,this.options);
  }

  getServicetype(data:any): Observable<any> {
    return this.http.post(`${this.newServer}order/get_service_types_edukaan/`, data,this.options);
  }

  getGuruList(data:any): Observable<any> {
    return this.http.post(`${this.newServer}order/guru_list_by_eta/`, data,this.options);
  }

  sendBooking(data:any): Observable<any> {
    return this.http.post(`${this.newServer}order/ds_booking/`, data,this.options);
  }

  getLatLongByAddress(data:any): Observable<any> {
    return this.http.post(`${this.newServer}order/get_lat_long_by_address_edukan/`, data,this.options);
  }

  // getBookingbyStatus(data:any): Observable<any> {
  //   return this.http.post(`${this.newServer}order/get_ds_booking_by_status/`, data,this.options);
  // }

  getBookingbyStatus(data): Observable<any> {
    return this.http.post(
      `${this.newServer}order/get_ds_booking_by_status/`,
      data,
      this.options
    );
  }

  getMilestoneStatus(data:any): Observable<any> {
    return this.http.post(`${this.newServer}order/show_milestone_status_edukaan/`, data,this.options);
  }
}
