<div class="paging">
  <div class="row">
    <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-4" *ngIf="!hideShowingRecords">
      
      <div class="info" >
        Showing {{ pagestart }} to {{ pageend }} records (filtered from
        {{ totalRecords }} total entries)
      </div>
    </div>
    <div [ngClass]="!hideShowingRecords ? 'col-12 col-sm-6 col-md-7 col-lg-8 col-xl-8' : '' ">
      <ul class="pagination justify-content-center">
        <li class="page-item" [class.disabled]="activePage <= 1">
          <a
            class="page-link page-link--with-arrow cursor"
            aria-label="Previous"
            (click)="onClickPage(activePage - 1)"
          >
            <span class="material-icons" style="font-size: 15px">
              chevron_left
            </span>
            <!-- <app-icon class="page-link__arrow page-link__arrow--left" aria-hidden="true" name="arrow-rounded-left-8x13"
                  size="8x13"></app-icon> -->
          </a>
        </li>
        <li
          *ngFor="let page of currentpages; trackBy: trackByFn"
          class="page-item"
          [class.active]="page === activePage"
        >
          <a class="page-link cursor" (click)="onClickPage(page)">
            <ng-container *ngIf="page != 0">
              {{ page }}
            </ng-container>
            <ng-container *ngIf="page == 0">
              <span class="block">...</span>
            </ng-container>
            <span *ngIf="page === activePage" class="sr-only">(activePage)</span>
          </a>
        </li>
  
        <li
          class="page-item"
          [class.disabled]="activePage >= pageCount"
          (click)="onClickPage(activePage + 1)"
        >
          <a class="page-link page-link--with-arrow cursor" aria-label="Next">
            <span class="material-icons" style="font-size: 15px">
              chevron_right
            </span>
            <!-- <app-icon class="page-link__arrow page-link__arrow--right" aria-hidden="true"
                  name="arrow-rounded-right-8x13" size="8x13"></app-icon> -->
          </a>
        </li>
      </ul>
    </div>
    
  </div>
</div>
