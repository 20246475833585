// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  version: require('../../package.json').version,
  production: false,
  // PRODUCTION API URL
  // previousUrl: 'https://skinprod.api.tatamotors/', 
  // apiUrl: 'https://digital-edukaan-cv-prod-ms.api.tatamotors/',
  // mintifiURL : 'https://bnpl.mintifi.com/?anchor_key=dgWnQnPrhexheZoijyUsdaKhvWE91b81&customer_code=',
  
  // SANDBOX API URL
  previousUrl: 'https://skindevreplica.api.tatamotors/',
  apiUrl: 'https://digital-edukaan-cv-dev-ms.api.tatamotors/', 
  mintifiURL : 'https://bnpl2.test.mintifi.com/?anchor_key=2yCMj9YvAmsUZmqYmUkekhMrn4fQQSkP&customer_code=',
  // QA API URL
  // previousUrl: 'https://skinqa-cv.api.tatamotors/', // If QA Build asked, then give this Url Said By Deepak Gupta
  // apiUrl: 'https://digital-edukaan-cv-qa-ms.api.tatamotors/',
  // apiUrl: 'https://skincicdqa.api.tatamotors/',
  // mintifiURL : 'https://bnpl2.test.mintifi.com/?anchor_key=2yCMj9YvAmsUZmqYmUkekhMrn4fQQSkP&customer_code=',
  
  CCAVENUE_URLS:{ 
    // Sandbox
    base_url:"https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction",
    portal_url:"edukaan-dev.home.tatamotors/#/"
    // QA
    // base_url:"https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction",
    // portal_url:"edukaan-dev.home.tatamotors/QA/#/"
    // Prod
    // base_url:"https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction",
    // portal_url:"edukaan.home.tatamotors/#/"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
