import { Component, OnInit, Input, OnChanges, ChangeDetectorRef, ViewChild, TemplateRef, SimpleChanges } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Product } from 'src/app/shared/interfaces/product';
import { AppStateService } from "../../../shared/services/app-state/app-state.service";
import { UserService } from '../../services/REST/user.service';
import { RootService } from 'src/app/shared/services/REST/root.service';
import { CartService } from '../../services/cart.service';
import { Router } from '@angular/router';
import { QuickviewService } from '../../services/quickview.service';
import { ProductService } from '../../services/REST/product.service';
import { blockPermissionMsg } from 'src/app/constant';
import { secureStorage } from '../../services/securestorage';
@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit, OnChanges {
  account_pk: any;
  shippingaddressid: any;
  billingaddressid: any;
  @Input() dproduct : any;
  @Input() product: Product;
  @Input() breadcrumbs;
  isLoggedIn: boolean;
  addingToCart = false;
  quantity: number;
  cartTypeList: any;
  selectcarttype: any;
  btnshowConfrimed: boolean = false;
  btndisabled: boolean = false;
  btnshowsubmit: boolean = false;
  modalRef1: any;
  baseProductdata: any;
  baseProductdiscount: any;
  showingQuickview = false;
  selectedProduct: any;
  showLoader: boolean = false;
  moreShown: boolean = false;
  limit: any;
  productInfo:any=[];
  userDetails:any
  @ViewChild('confimationmodal', { read: TemplateRef, static: false }) template1: TemplateRef<any>;
  @Input() layout;
  @Input() productList: Array<any[]> = [];
  @Input() showMore;
  @Input() showMoreModel;
  @Input() showMoreSubModel;
  @Input() showMoreaggregate;
  @Input() loading;
  
  constructor(private appStateService: AppStateService,
    private userService: UserService,
    private modalService: BsModalService,
    private cd: ChangeDetectorRef,
    public root: RootService,
    public cart: CartService,
    private router: Router,
    public quickview: QuickviewService,
    private productService: ProductService,
    private toasterService: ToastrService,
    private localstorage: secureStorage,) {

  }

  ngOnInit(): void {
    this.appStateService.isLoggedIn.subscribe(isLog => {
      this.isLoggedIn = isLog;
    })
    this.appStateService.watchStorage().subscribe((data: string) => {
      // this will call whenever your this.localstorage data changes
      // use this.localstorage code here and set your data here for ngFor

    });
    this.userDetails = JSON.parse(this.localstorage.getItem('userData'));
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.showMore || changes.showMoreModel || changes.showMoreSubModel || changes.showMoreaggregate ||
      !changes.showMore || !changes.showMoreModel || !changes.showMoreSubModel || !changes.showMoreaggregate) &&
      (!changes.layout && !changes.product)) {
      //window.scrollTo(0,0);
    }
  }

  addToCart(product: Product): void {
    try {
      let permission = this.userService.CheckPermission(
        'btn_cart'
      );
      if (permission) {
        if (this.addingToCart) {
          return;
        }
        if (isNaN(product['customerQuantity'])) {
          this.toasterService.error("Quantity cannot be empty or 0");
          return;
        }
        this.addingToCart = true;
        // if (product['moq']) {
        //   this.quantity = parseInt(product['min_quantity']) ? parseInt(product['min_quantity']) * parseInt(product['moq']) : 1 * parseInt(product['moq'])
        // }
        // else {
        //   this.quantity = parseInt(product['min_quantity']) ? parseInt(product['min_quantity']) : 1;
        // }
        if (product['resource_type'] == 'Pack') {
          product['customerQuantity'] =this.isLoggedIn ? parseInt(product['customerQuantity']) ? parseInt(product['customerQuantity']) : 1: parseInt(product['customerQuantity']) ? product['customerQuantity'] * parseInt(product['moq']) : 1 * parseInt(product['moq'])
        }
        else {
          product['customerQuantity'] = parseInt(product['customerQuantity']) ? parseInt(product['customerQuantity']) : 1;
        }
        if ( product['customerQuantity'] >= 1) {
          if (this.localstorage.getItem('getCartType')) {
            this.cartTypeList = JSON.parse(this.localstorage.getItem('getCartType'));
          }
          var commoncarttypedata = this.cartTypeList.filter(alitems => alitems.common_distributor_categories.includes(product['distributor_category']));
          var Distributercarttypedata = this.cartTypeList.filter(alitems => alitems.distributor_categories.includes(product['distributor_category']));
          let cart_type_name = "";
          this.productInfo.data = [product]
          this.productInfo.division_info = {
            ORG_NAME:product['organization_name']?product['organization_name']:"",
            DIV_NAME:product['division_name']?product['division_name']:"",
            PRIMISE_ADDRLINE1_s:product['primise_addrline1']?product['primise_addrline1']:"",
            PRIMISE_ADDRESS2_s:product['primise_addrline2']?product['primise_addrline2']:"",
            DIV_ID:product['division_id']?product['division_id']:"",
            GSTIN:product['gstn']?product['gstn']:"",
            ORG_Id:product['organization_id']?product['organization_id']:"",
        };
          if (commoncarttypedata.length > 0) {
            if (this.localstorage.getItem('selectcarttype')) {
              cart_type_name = this.localstorage.getItem('selectcarttype');
              this.showLoader = true;
              this.cart.add(product, 
                product['customerQuantity'], product['distributor_category'], cart_type_name
                // ,false
              ).subscribe({
                complete: () => {
                  this.showLoader = false;
                  this.addingToCart = false;
                  this.cd.markForCheck();
                  if (product['resource_type'] == 'Pack') {
                    product['customerQuantity'] = parseInt(product['customerQuantity']) ? product['customerQuantity'] / parseInt(product['moq']) : 1 * parseInt(product['moq'])
                  }
                  localStorage.removeItem('selectcarttype');
                }
              });
              localStorage.removeItem('selectcarttype')
            }
            else {
              cart_type_name = commoncarttypedata[1]["cart_type"]
              if (this.cartTypeList.length > 0) {
                this.selectcarttype = this.cartTypeList[1]["cart_type"];
                this.selectedProduct = product;
                this.btndisabled = false;
                this.addingToCart = false
                this.btnshowConfrimed = false;
                this.btnshowsubmit = true;
                this.changeCartType(this.selectcarttype, product);
                this.modalRef1 = this.modalService.show(this.template1, { class: 'modal-dialog-centered modal-md', backdrop: 'static' });
              }
            }
          }
          else if (Distributercarttypedata.length > 0) {
            cart_type_name = Distributercarttypedata[0]["cart_type"];
            this.showLoader = true;
            this.cart.add(product, 
              product['customerQuantity'], product['distributor_category'], cart_type_name
              // ,false
            ).subscribe({
              complete: () => {
                this.showLoader = false;
                this.addingToCart = false;
                this.cd.markForCheck();
                if (product['resource_type'] == 'Pack') {
                  product['customerQuantity'] = parseInt(product['customerQuantity']) ? product['customerQuantity'] / parseInt(product['moq']) : 1 * parseInt(product['moq'])
                }
              }
            });
          }
        }
        else{
          
          this.addingToCart = false;
          this.toasterService.error('Minimum Quantity must be 1');
        }
      }
      else {

        this.toasterService.error(blockPermissionMsg);
      }
    }
    catch (error) {
      this.userService.InsertExceptionLog({ "method_name": "addToCart()", "page_name": "ProductCardComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
    }
  }

  changeCartType(cartType, product: Product) {
    try {
      this.selectcarttype = cartType;
      let user = JSON.parse(this.localstorage.getItem('userData'));
      let address = JSON.parse(this.localstorage.getItem('address'));
      let billingaddress = JSON.parse(this.localstorage.getItem('billingAddress'));
      let carttypes = cartType["cart_type"]?cartType["cart_type"]:cartType;
      let searchData = {}
      if (user && address && billingaddress) {
        this.account_pk = + JSON.stringify(user.account_pk);
        var addresstypecheck = address.address['default'];
        if (addresstypecheck === true) {
          this.shippingaddressid = "default";
        }
        else {
          this.shippingaddressid = address.address['address_id'];
        }
        var Billingaddresscheck = billingaddress.address['default'];
        if (Billingaddresscheck === true) {
          this.billingaddressid = "default";
        }
        else {
          this.billingaddressid = billingaddress.address['address_id'];
        }
        searchData = {
          search_text: "",
          bs_model_name: "",
          pg_line: "",
          multi_pg_line: [],
          multi_bs_model_name: [
          ],
          multi_dispgroup_name: [],
          model_cat: "",
          mod_uid: "",
          category_type: "",
          bom_id: "",
          durafit_parts: false,
          kit_parts: false,
          part_number_last_four: "",
          offset: 0,
          limit: 20,
          pl_id: "",
          // is_discount_needed: true,
          account_pk: this.account_pk,
          // address_pk: this.address_pk,
          shipping_address_id: this.shippingaddressid,
          part_number: (product?.part_number || this.selectedProduct?.part_number),
          is_for_epc_data: false,
          is_for_discount: true,
          is_for_ecom_detail: true,
          cart_type: carttypes,
          is_for_inventory :this.userDetails?.user_type == 'MECH' ? true: false
        }
      }
      else {
        searchData = {
          search_text: "",
          bs_model_name: "",
          pg_line: "",
          multi_pg_line: [],
          multi_bs_model_name: [
          ],
          multi_dispgroup_name: [],
          model_cat: "",
          mod_uid: "",
          category_type: "",
          bom_id: "",
          durafit_parts: false,
          kit_parts: false,
          part_number_last_four: "",
          offset: 0,
          // start_row:0,
          limit: 20,
          pl_id: "",
          part_number: (product?.part_number || this.selectedProduct?.part_number),
          is_for_epc_data: false,
          is_for_discount: false,
          is_for_ecom_detail: true,
          cart_type: carttypes,
          is_for_inventory :this.userDetails?.user_type == 'MECH' ? true: false

        }
      }
      this.partnumberbaseddiscount(searchData, (product || this.selectedProduct));
      // let data = {};
      // let user = JSON.parse(this.localstorage.getItem('userData'));
      // let address = JSON.parse(this.localstorage.getItem('address'));
      // let carttypes;
      // if (user) {
      //   carttypes = cartType;
      //   if (address['type'] === 'default' && address['type'] !== null) {
      //     data = {
      //       cart_type: carttypes,
      //       account_pk: JSON.stringify(user.account_pk),
      //       address_pk: address.type,// 'default',//address.type,
      //       part_number: (product.part_number) ? (product.part_number) : this.selectedProduct.part_number,
      //       quantity: 1,
      //     }
      //   }
      //   else {
      //     var address_pkselect
      //     if (address.address.length === undefined || address.address.length === 0) {
      //       address_pkselect = address.address.id;
      //     }
      //     else {
      //       address_pkselect = address.address[0].id;
      //     }
      //     data = {
      //       cart_type: carttypes,
      //       account_pk: JSON.stringify(user.account_pk),
      //       address_pk: address_pkselect,// 'default',//address.type,
      //       part_number: (product.part_number) ? (product.part_number) : this.selectedProduct.part_number,
      //       quantity: 1,
      //     }
      //   }
      //   this.partnumberbaseddiscount(data, (product) ? product : this.selectedProduct);
      // }
    }
    catch (error) {
      this.userService.InsertExceptionLog({ "method_name": "changeCartType()", "page_name": "ProductCardComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
    }
  }

  partnumberbaseddiscount(json, product: Product) {
    try {
      this.showLoader = true;
      // this.productService.getpartnumberbaseddiscount(json).subscribe(response => {
      this.productService.get_part_search_md(json).subscribe(response => {
        if (response.success === true) {
          this.baseProductdata = response;
          var productPirce = parseFloat(product['crm_mrp'].toString()).toFixed(3);
          var basediscountPirce = parseFloat(response.data[0].total_amount).toFixed(3);
          if (productPirce === basediscountPirce) {
            this.baseProductdiscount = null;
          }
          else {
            this.baseProductdiscount = response.data[0].total_amount;
          }
          this.showLoader = false;
        }
        else {
          this.showLoader = false;
        }
      }, (err) => {
        this.showLoader = false;
      })
    }
    catch (error) {
      this.userService.InsertExceptionLog({ "method_name": "partnumberbaseddiscount()", "page_name": "ProductCardComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
    }
  }

  closePart() {
    if (this.btnshowConfrimed) {
      localStorage.removeItem("selectcarttype");
      this.showQuickview();
    }
    localStorage.removeItem('selectcarttype')
    this.selectcarttype = "";
    this.modalRef1.hide();
  }

  showQuickview(): void {
    if (this.showingQuickview) {
      return;
    }
    this.showingQuickview = true;
    this.quickview.show(this.selectedProduct).subscribe({
      complete: () => {
        this.showingQuickview = false;
        this.cd.markForCheck();
      }
    });
  }

  setAddCart(cartType, product?: any) {
    try {
      let permission = this.userService.CheckPermission(
        'btn_cart'
      );
      if (permission) {
      this.btndisabled = true;
      var cart_type_name = cartType?cartType :this.localstorage.getItem('selectcarttype');
      this.showLoader = true;
      this.cart.add(this.selectedProduct, this.selectedProduct['customerQuantity'], this.selectedProduct['distributor_category'], cart_type_name
      // ,false
      ).subscribe({ 
        complete: () => 
        { 
          localStorage.removeItem('selectcarttype');
          this.addingToCart = false; 
          this.showLoader = false;
          this.cd.markForCheck(); 
        } 
      });
      localStorage.removeItem('selectcarttype');
      this.selectcarttype = "";
      this.modalRef1.hide();
      this.btndisabled = false;
      this.showLoader = false;
    }
    else{

        this.toasterService.error(blockPermissionMsg);
      }
    }
    catch (error) {
      this.userService.InsertExceptionLog({ "method_name": "setAddCart()", "page_name": "ProductCardComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
    }
  }

  ShowPart() {
    try {
      this.btndisabled = true;
      localStorage.removeItem('selectcarttype');
      this.localstorage.setItem('selectcarttype', this.selectcarttype);
      this.modalRef1.hide();
      this.showQuickview();
    }
    catch (error) {
      this.userService.InsertExceptionLog({ "method_name": "ShowPart()", "page_name": "ProductCardComponent", "portal_type": "Customer", "page_url": this.router.url, "error_message": error.message, "inner_exception": error });
    }
  }

  readQuantity(product,event) {
    product.customerQuantity = event;
  }

  convertToInteger(data:any){
    return parseInt(data)
}

}
