import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { merge, Observable, of, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { defaultLanguage, languages } from '../../services/speech-recognizer.service';
import { SpeechError } from '../../services/speech-recognizer.service';
import { SpeechEvent } from '../../services/speech-recognizer.service';
import { SpeechRecognizerService } from '../../services/speech-recognizer.service';
// import { ActionContext } from '../../services/speech-recognizer.service';
import { SpeechNotification } from '../../services/speech-recognizer.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-web-speech',
  templateUrl: './web-speech.component.html',
  styleUrls: ['./web-speech.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebSpeechComponent implements OnInit {
  languages: string[] = languages;
  currentLanguage: string = defaultLanguage;
  totalTranscript?: string;

  transcript$?: Observable<string>;
  listening$?: Observable<boolean>;
  errorMessage$?: Observable<string>;
  defaultError$ = new Subject<string | undefined>();
  @Output() emitText = new EventEmitter<any>();
  @Output() btnClick = new EventEmitter<any>();
  @Output() stopClick = new EventEmitter<any>();
  @Input() clearData;
  constructor(
    private speechRecognizer: SpeechRecognizerService,
    public toasterService: ToastrService,
    // private actionContext: ActionContext
  ) { }

  ngOnInit(): void {
    const webSpeechReady = this.speechRecognizer.initialize(this.currentLanguage);
    if (webSpeechReady) {
      this.initRecognition();
    } else {
      this.errorMessage$ = of('Your Browser is not supported. Please try Google Chrome.');
      let message = 'Your Browser is not supported. Please try Google Chrome.';
      Swal.fire({
        title: message,
        text: '',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  }

  start(val): void {
    if(val.pointerId>=1){
      this.btnClick.emit(true)
      if (this.speechRecognizer.isListening) {
        this.stop();
        return;
      }
  
      this.defaultError$.next(undefined);
      this.speechRecognizer.start();

      setTimeout(() => {
        if(this.speechRecognizer.isListening){
        let message = 'No speech has been detected. Please try again.';
      Swal.fire({
        title: message,
        text: '',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      
      this.stop('stopclicked2')
    }
      }, 30000);
    }

  }

  stop(val?): void {
    this.speechRecognizer.stop();
    if(val == 'stopclicked'){
      this.stopClick.emit('stopclicked');
      
    }
    if(val == 'stopclicked2'){
      this.stopClick.emit('error');
      
    }
  }

  selectLanguage(language: string): void {
    if (this.speechRecognizer.isListening) {
      this.stop();
    }
    this.currentLanguage = language;
    this.speechRecognizer.setLanguage(this.currentLanguage);
  }

 
  private initRecognition(): void {
    this.transcript$ = this.speechRecognizer.onResult().pipe(
      tap((notification) => {
        this.processNotification(notification);
      }),
      map((notification) =>  notification.content || '' )
    );
    this.transcript$.subscribe(val=>{
    })
    
    this.listening$ = merge(
      this.speechRecognizer.onStart(),
      this.speechRecognizer.onEnd()
    ).pipe(map((notification) =>notification.event === SpeechEvent.Start));
    this.listening$.subscribe(val=>{

    })

    this.errorMessage$ = merge(
      this.speechRecognizer.onError(),
      this.defaultError$
    ).pipe(
      map((data) => {
        if (data === undefined) {
          return '';
        }
        if (typeof data === 'string') {
          return data;
        }
        let message;
        switch (data.error) {
          case SpeechError.NotAllowed:
            message = `Your browser is not authorized to access your microphone.
            Verify that your browser has access to your microphone and try again.`;

            break;
          case SpeechError.NoSpeech:
            message = `No speech has been detected. Please try again.`;
            break;
          case SpeechError.AudioCapture:
            message = `Microphone is not available. Please verify the connection of your microphone and try again.`;
            break;
          default:
            message = '';
            break;
        }

        return message;
      })
    );
    this.errorMessage$.subscribe(val => {
      if(val !== ''){
        this.stopClick.emit('error');
      Swal.fire({
        title: val,
        text: '',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
    });

}
  private processNotification(notification: SpeechNotification<string>): void {
  if(notification.event === SpeechEvent.FinalContent) {
  const message = notification.content?.trim() || '';
  // this.actionContext.processMessage(message, this.currentLanguage);
  // this.actionContext.runAction(message, this.currentLanguage);
  
  this.totalTranscript = this.clearData ? notification.content : this.totalTranscript
    ? `${this.totalTranscript}\n${message}`
    : notification.content;
    this.emitText.emit(this.totalTranscript);
    this.stop();
}
  }
}
