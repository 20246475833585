<!-- <app-header *ngIf="!showHeader"></app-header>
<section  class="main-container" *ngIf="!showHeader">
    <router-outlet ></router-outlet>
</section>
<section *ngIf="showHeader">
    <router-outlet ></router-outlet>
</section>

<app-footer *ngIf="!showHeader"></app-footer> -->
<ng-container *ngIf="showCoachMarks">
    <app-coach-marks></app-coach-marks>
</ng-container>
<app-header class="coachmarksenabled"></app-header>
<section class="main-container coachmarksenabled">
    <router-outlet ></router-outlet>
    <ng-container *ngIf="isLoggedIn && userData?.user_type != 'MECH'">
        <app-my-saving></app-my-saving>
    </ng-container>
</section>
<app-footer class="coachmarksenabled"></app-footer>