<div class="container-fluid orders">
    <div class="row">
        <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 pb-3 pt-3">
            <!-- <hr class="hr-page-divider" /> -->
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                    <ul class="nav nav-tabs tabs1" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <h5 class="profile-heading mb-3">User Permission List</h5>
                        </li>
                        <!-- <mat-form-field style="display: inline;padding: 17px !important;">
                            <mat-select placeholder="Role" [formControl]="VehicleFilterForm.controls['roleid']"
                                (selectionChange)="OnselectedRole($event.value,0)">
                                <mat-option value="">--Select Role--</mat-option>
                                <mat-option *ngFor="let div of RoleList" value="{{div.id}}">{{div.role_name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field> -->
                    </ul>
                    <div class="nav nav-tabs tabs1" id="myTab" role="tablist">
                        <mat-form-field class="nav-item" style="display: inline;padding: 16px !important;">
                            <mat-select placeholder="Role" [formControl]="VehicleFilterForm.controls['roleid']"
                                (selectionChange)="OnselectedRole($event.value)">
                                <mat-option value="">--Select Role--</mat-option>
                                <mat-option *ngFor="let div of RoleList" value="{{div.id}}">{{div.role_name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="tab-content mt-3" id="nav-tabContent" *ngIf="PermissionList.length > 0">
                        <div class="tab-pane fade show active" id="nav-Items" role="tabpanel"
                            aria-labelledby="nav-Items-tab">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                    style="padding-left: 15px; padding-right: 15px;">
                                    <div class="table-responsive table-container">
                                        <table id="user-permission" class="table datatable display">
                                            <thead>
                                                <tr>
                                                    <th>Access Name</th>
                                                    <th>Permission</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr style="width: 100%;" *ngFor="let permission of PermissionList">
                                                    <td>{{ permission.access_name}}</td>
                                                    <td>
                                                        <span
                                                            [ngClass]='permission.is_permission === true ? "spanActivePermission" : "spanInactivePermission"'>{{permission.is_permission
                                                            ? 'Yes' : 'No'}}</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="showLoader">
    <div class="loader">
        <div class="ng-spinner ng-spinner-icon"></div>
    </div>
</ng-container>