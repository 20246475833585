import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { timer, Subject, Observable, Subscription } from "rxjs";
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit {
  showHeader: boolean = false;
  otpValue: string = '';
  showOtpComponent = true;
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  @Output() enteredOtp = new EventEmitter<any>();
  @Output() nextClick = new EventEmitter<any>();
  @Output() resendOtp = new EventEmitter<any>();
  @Output() redirectToLogin = new EventEmitter<any>();
  @Output() redirectToRegistration = new EventEmitter<any>();
  @Output() redirectToGuestLogin = new EventEmitter<any>();
  @Input() loginForm: FormGroup;
  @Input() registrationForm: FormGroup;
  @Input() guestLoginForm: FormGroup;
  @ViewChild('ngOtpInput') ngOtpInputRef:any;
  @Input() isGuestLogin: boolean = false;
  isLogin:boolean = false;
  isRegistration:boolean = false;
  submitted: boolean = false;
  m: any;
  s: any;
  timeLeft: number;
  @Input() isTimer: boolean;
  destroy = new Subject();
  eventsSubscription: Subscription;
  @Input() events: Observable<void>;
  constructor(
    private appStateService:AppStateService,
    private route: ActivatedRoute,
    private toastrService: ToastrService,) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.isLogin = params['isLogin'];
      this.isRegistration = params['isRegistration'];
      var isLoginnew = params['isLogin'];
      var isRegistrationnew = params['isRegistration'];
      if(isLoginnew === 'true'){
        this.showHeader = true;
      }
      else if(isRegistrationnew === 'true'){
        this.showHeader = true;
      }
      else{
        this.showHeader = false;
      }
    });

    this.appStateService.resendGuestOtp.subscribe(
      res => {
        if(res == true){
          this.resendOnSubmitOTP();
        }
      }
    )
    this.eventsSubscription = this.events.subscribe(() => {
      this.startTimer(120);
    });
    this.startTimer(120);
  }
  
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: true,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      'width': '30px',
      'height': '30px',
      'border': 'unset',
      'border-bottom': 'solid 2px #979797',
      'background': 'unset',
      'border-radius': 'unset',
      'margin-right': '15px',
      'outline': 'unset'
    }
  };

  onOtpChange(event) {
    if (event.length === 6) {
      this.otpValue = event;
    }
    this.enteredOtp.emit(event);
  }

  nextClicked() {
    if(this.isRegistration){
      if(!this.isGuestLogin){
        this.registrationForm.markAllAsTouched();
        if(this.registrationForm.invalid){
          if(this.otpValue.length == 0 || this.otpValue.length < 6){
            this.toastrService.error('Please enter 6 digit OTP');
          }
            return;
        }
        else if (this.otpValue.length === 6) {
          this.nextClick.emit();
        }
      }
      else{
        this.guestLoginForm.markAllAsTouched();
        if(this.guestLoginForm.invalid){
          if(this.otpValue.length == 0 || this.otpValue.length < 6){
            this.toastrService.error('Please enter 6 digit OTP');
          }
            return;
        }
        else if (this.otpValue.length === 6) {
          this.nextClick.emit();
        }
      }
    }
    if(this.isLogin){
      if(this.otpValue.length == 0 || this.otpValue.length < 6){
        this.toastrService.error('Please enter 6 digit OTP');
      }
      else{
        this.nextClick.emit();
      }
    }
  }

  resendOnSubmitOTP(){
    this.otpValue = "";
    if(this.ngOtpInputRef){
      this.ngOtpInputRef.setValue("");
    }
    this.enteredOtp.emit("");
    this.resendOtp.emit();
  }

  get f() {
    if(!this.isGuestLogin){
      return this.registrationForm.controls;
    }
    else{
      return this.guestLoginForm.controls;
    }
  };

  startTimer(timeLeft) {
    this.timeLeft = timeLeft;
    const source = timer(1000, 1000);
    source.pipe(takeUntil(this.destroy)).subscribe((val) => {
      if (val >= 0 && val < 121) {
        if (this.timeLeft > 0) {
          this.isTimer = true;
          this.m = Math.floor(this.timeLeft / 60);
          this.s = this.timeLeft % 60;

          this.m = this.m < 10 ? "0" + this.m : this.m;
          this.s = this.s < 10 ? "0" + this.s : this.s;
          this.timeLeft--;
        } else {
          this.timeLeft = 0;
          this.m = 0;
          this.s = 0;
          this.isTimer = false;
        }
      } else {
        this.destroy.next();
        this.destroy.complete();
      }
    });
  }

  backToLogin(){
    this.redirectToLogin.emit();
  }

  backToRegistration(){
    if(!this.isGuestLogin){
      this.redirectToRegistration.emit();
    }
    else{
      this.redirectToGuestLogin.emit();
    }
  }
}
