<!-- <div class="modal-header modal-title">
    <div class="form-group" *ngIf="!isSwitchAccount">
        <label class="signinlabel">Please Select Your User Account</label>
    </div>
    <div class="form-group" *ngIf="isSwitchAccount">
        <label class="signinlabel">Please Select Your User Account</label>
    </div>
    <button type="button" class="btn-close" (click)="closePopUp()">x</button>
</div> -->
<div class="modal-header modal-title">
    Select User Account
    <div (click)="closePopUp()" type="button"><i class="fa fa-times"></i></div>
</div>

<div class="modal-body">
    <!-- <div class="container-fluid" style="height: 430px; overflow-y: auto;"> -->
        <div style="max-height:calc(100vh - 220px);" class="table-responsive">
            <table class="table table-bordered  mb-0 modal-body-table">
                <thead>
                    <tr style="background: #ebebeb;">
                        <th scope="col">Account ID</th>
                        <th scope="col">Account Details</th>
                        <th scope="col">Organization Name</th>
                    </tr>
                </thead>
                <tbody *ngIf="!isSwitchAccount">
                    <tr *ngFor="let userid of userIds">
                        <td >
                            <div class="custom-control custom-radio m-1 text-nowrap">
                                <label class="custom-control-label" for="{{userid.account_id}}">
                                <input type="radio" class="custom-control-input" id="{{userid.account_id}}"
                                    value="{{userid.account_id}}" name="userId" (change)="selectUser(userid)">
                                &nbsp;{{ userid.account_id
                                    }}</label>
                            </div>
                        </td>
                        <td class="breakwords">
                            Customer Name : {{userid?.account_name}}
                            <div>
                                <i class="fa fa-map-marker" aria-hidden="true"></i>
                                {{userid?.address_1}},
                                {{userid?.address_2}},
                                {{userid?.address_3}},
                                {{userid?.city}},
                                {{userid?.district}},
                                {{userid?.state}},
                                {{userid?.pincode}}
                            </div>
    
                        </td>
                        <td >
                            {{userid?.organization_name }}
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="isSwitchAccount">
                    <tr *ngFor="let userid of userIds">
                        <td >
                            <div class="custom-control custom-radio m-1 text-nowrap">
                                <input type="radio" class="custom-control-input" id="{{userid.account_id}}"
                                    value="{{userid.account_id}}" [(ngModel)]="currentAccount" (change)="selectUser(userid)">
                                &nbsp;<label class="custom-control-label" for="{{userid.account_id}}">{{ userid.account_id
                                    }}</label>
                            </div>
                        </td>
                        
                        <td class="breakwords">
                            Customer Name : {{userid?.account_name}}
                            <div>
                                <i class="fa fa-map-marker" aria-hidden="true"></i>
                                {{userid?.address_1}},
                                {{userid?.address_2}},
                                {{userid?.address_3}},
                                {{userid?.city}},
                                {{userid?.district}},
                                {{userid?.state}},
                                {{userid?.pincode}}
                            </div>
    
                        </td>
                        <td>
                            {{userid?.organization_name }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        
    <!-- </div> -->
    <br>
        <div class="text-end" *ngIf="!isSwitchAccount">
            <button type="button" class="btn btn-primary" [disabled]="btndisabled" (click)="setUser()">
                &nbsp;Confirm</button>
        </div>
        <div class="text-end" *ngIf="isSwitchAccount">
            <button type="button" class="btn btn-primary" [disabled]="isCurrentAccountSelected" (click)="setUser()">
                &nbsp;Confirm</button>
        </div>
</div>