import { ChangeDetectorRef, Component, HostListener, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Product } from '../../../../src/app/shared/interfaces/product';
import { AppStateService } from '../../../../src/app/shared/services/app-state/app-state.service';
import { CartService } from '../../../../src/app/shared/services/cart.service';
import { QuickviewService } from '../../../../src/app/shared/services/quickview.service';
import { ModelService } from '../../../../src/app/shared/services/REST/model.service';
import { ProductService } from '../../../../src/app/shared/services/REST/product.service';
import { RootService } from '../../../../src/app/shared/services/REST/root.service';
import { UserService } from '../../../../src/app/shared/services/REST/user.service';
import { Inputdata, InputPartSearch } from '../../../../src/app/shared/interfaces/product';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { secureStorage } from '../../../../src/app/shared/services/securestorage';
import { PartSearchRequestBodyService } from '../../../../src/app/shared/helpers/part_search_requestbody.service';
import { DatePipe } from '@angular/common';
import { Part_Search_Request_Of_Recommendation } from 'src/app/constant';
import { Title } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'src/app/shared/services/GoogleAnalytics/GoogleAnalytics.service';
declare var $: any;
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
})
export class IndexComponent implements OnInit {
  
  PopularKitsData: any[] = [];
  address_pk: any;
  account_pk: any;
  featuredProducts1 = [];
  featuredProducts2 = [];
  featuredProducts: any = [];
  takeFalse: boolean = true;
  addingToCart = false;
  quantity: number;
  cartTypeList: any;
  selectcarttype: any;
  btnshowConfrimed: boolean = false;
  btndisabled: boolean = false;
  btnshowsubmit: boolean = false;
  modalRef1: any;
  baseProductdata: any;
  baseProductdiscount: any;
  showingQuickview = false;
  selectedProduct: any;
  shippingaddressid: any;
  billingaddressid: any;
  currentQuantity: number = 1;
  isLoggedIn: boolean;
  newProductList = [];
  @ViewChild('confimationmodal', { read: TemplateRef, static: false })
  template1: TemplateRef<any>;
  cfProductList: any;
  recentItems = [];
  showLoader: boolean = false;
  megaCostBannerList: any[] = [];
  itemsOnfirstLoad = true;
  areKitsLoaded: boolean = false;
  isKitLoaded: boolean = false;
  @Input() loading;
  showRecentlyViewedProduct: boolean = true;
  showDealDay: boolean = true;
  showNewProducts: boolean = true;
  showKits: boolean = true;
  showeDukaanFeatures: boolean = true;
  showCustomerFavourites: boolean = true;
  previousOffsetForDeals: number = 0;
  previousLimitForDeals: number = 5;
  previousOffsetForNewProducts: number = 0;
  previousLimitForNewProducts: number = 5;
  dealProductList: any[] = [];
  seasonalPartList:any[] = [];
  customOptionsForMCS: OwlOptions = {
    items: 4,
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    autoWidth: false,
    navText: ['PREV', 'NEXT'],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 4,
      },
    },
    nav: true,
  };
  customOptions: OwlOptions = {
    items: 4,
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    autoWidth: false,
    navText: [
      "<div><img src='./assets/images/button-circle-prev-white.svg' /></div>",
      "<div><img src='./assets/images/button-circle-next-white.svg' /></div>",
    ],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 4,
      },
    },
    nav: true,
  };
  customOptionsForBanners: OwlOptions = {
    items: 5,
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    autoWidth: false,
    navText: [
      "<div><img src='./assets/images/arrow-left.svg' /></div>",
      "<div><img src='./assets/images/arrow-right.svg' /></div>",
    ],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 5,
      },
    },
    nav: true,
  };
  customOptionsForMCSBanners: OwlOptions = {
    items: 4,
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    autoWidth: false,
    navText: [
      "<div><img src='./assets/images/arrow-left.svg' /></div>",
      "<div><img src='./assets/images/arrow-right.svg' /></div>",
    ],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 4,
      },
    },
    nav: true,
  };
  customOptionsForNewProducts: OwlOptions = {
    items: 5,
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    autoWidth: false,
    navText: [
      "<div><img src='./assets/images/arrow-left.svg' /></div>",
      "<div><img src='./assets/images/arrow-right.svg' /></div>",
    ],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 5,
      },
    },
    nav: true,
  };
  customOptionsForKits: OwlOptions = {
    items: 5,
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    autoWidth: false,
    navText: [
      "<div><img src='./assets/images/button-circle-prev-white.svg' /></div>",
      "<div><img src='./assets/images/button-circle-next-white.svg' /></div>",
    ],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 5,
      },
    },
    nav: true,
  };
  customOptionsForRecentItems: OwlOptions = {
    items: 5,
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    autoWidth: false,
    navText: [
      "<div><img src='./assets/images/arrow-left.svg' /></div>",
      "<div><img src='./assets/images/arrow-right.svg' /></div>",
    ],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 5,
      },
    },
    nav: true,
  };
  customOptionsForCS: OwlOptions = {
    items: 5,
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    autoWidth: false,
    navText: [
      "<div><img src='./assets/images/arrow-left.svg' /></div>",
      "<div><img src='./assets/images/arrow-right.svg' /></div>",
    ],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 5,
      },
    },
    nav: true,
  };
  customOptionsForSeasonalProducts: OwlOptions = {
    items: 5,
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    autoWidth: false,
    navText: [
      "<div><img src='./assets/images/arrow-left.svg' /></div>",
      "<div><img src='./assets/images/arrow-right.svg' /></div>",
    ],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 5,
      },
    },
    nav: true,
  };
  servicecalls3: boolean = false;
  showSesonalParts:boolean = true;
  pincode: any;
  isUserType:any;
  seasonalParts:any[] = [];
  dealOfTheDayBanner :any;
  productInfo:any=[];
  userdetail:any
  userDetails:any;
  constructor(
    private datePipe:DatePipe,
    private productService: ProductService,
    private userService: UserService,
    private modalService: BsModalService,
    private model: ModelService,
    private cd: ChangeDetectorRef,
    public root: RootService,
    public cart: CartService,
    private router: Router,
    public quickview: QuickviewService,
    private toastr: ToastrService,
    private appStateService: AppStateService,
    private localstorage: secureStorage,
    private partSearchRequestBody: PartSearchRequestBodyService,
    private title:Title,
    private googleAnlyatics: GoogleAnalyticsService,
  ) {
    this.pincode = JSON.parse(this.localstorage.getItem('Pincode'));

  }
  ngOnInit() {
    let user = JSON.parse(this.localstorage.getItem('userData'));
    this.isUserType = user;
    this.appStateService.isLoggedIn.subscribe((isLog) => {
      this.isLoggedIn = isLog;
      this.userDetails = JSON.parse(this.localstorage.getItem('userData'));
    });
    this.recentItems = [];
    this.appStateService.recentItems.subscribe((recentItems) => {
      let recentItemsData = [];
      recentItemsData = JSON.parse(this.localstorage.getItem('Recent items')) == null || JSON.parse(this.localstorage.getItem('Recent items')) == undefined ? [] : JSON.parse(this.localstorage.getItem('Recent items'));
      if (recentItemsData.length > 0) {
        this.recentlyViewedItems(recentItemsData, recentItems);
      }
      else {
        this.showRecentlyViewedProduct = false;
      }
    });
    this.dealOfTheDayCalled = false;
    this.getDealOfTheDayBanner();
    this.getS3SeasonalData();
    // this.PopularKits();
    this.showLoader = true;

    this.title.setTitle('E-Dukaan');
    this.appStateService.refreshOfferHomePage.subscribe((val) => {
      if (val == true){
        // this.getProduct(0,20);
        this.pincode = JSON.parse(this.localstorage.getItem('Pincode'));
        this.dealProductList =[];
        this.newProductList = [];
        this.cfProductList = [];
        this.servicecalls3 = false;
        this.seasonalParts = [];
        // this.getProduct('dotd', 0, 12);
        this.getProduct('np', 0, 20);
        this.customerFavourites(0);
        // this.getS3SeasonalData();
        this.getDealOfTheDayBanner();
      }
    });

    }

  loadingData: boolean = false;
  @HostListener('window:scroll', [])
  onScroll(): void {
    if (window.innerHeight + window.scrollY + 550 >= document.body.offsetHeight && !this.loadingData) {
      this.loadingData = true;
      this.PopularKits()
      this.getProduct('np', 0, 20);
      this.getMegaCostBanner();
      if(!this.dealOfTheDayCalled){
        this.getDealOfTheDayBanner();
      }
      // this.getS3SeasonalData();
      this.customerFavourites(0);
    }
  }

  dealofftheday(id) {
    let user = JSON.parse(this.localstorage.getItem('userData'));
    let address = JSON.parse(this.localstorage.getItem('address'));
    if (user && address) {
      this.account_pk = +JSON.stringify(user.account_pk);
      this.shippingaddressid = address?.address['address_id'];
      }

    let request = {
        "offset": 0,
        "limit": 12,
        "banner_discount_id": id,
        "shipping_address_id": this.isLoggedIn ? this.shippingaddressid : '',
        "account_pk": this.isLoggedIn? this.account_pk : '',
        "is_for_inventory": this.isUserType?.user_type === 'MECH',
        "is_for_discount":this.isLoggedIn ? true : false
    };
    console.log("Account pk", request.account_pk);
    this.productService.get_part_search_md(request).subscribe(
        (data) => {
            if (data.success) {
                // if (type === 'dotd') {
                    this.showDealDay = true;
                    let responseArray = data.data;
                    responseArray.forEach(part => {
                        if (!this.dealProductList.includes(part)) {
                            part.customerQuantity = part.min_quantity;
                            this.dealProductList.push(part);
                        }
                    });
                // } else {
                //     this.showDealDay = false;
                // }
            }
        },
        (err) => {
            this.showDealDay = false;
            if (err?.error?.error_code === 7) {
                this.toastr.error(err.error.msg);
            }
            if (err?.error?.data?.msg) {
                this.toastr.error(err.error.data.msg);
            }
        }
    );
}
  async getProduct(type, offset, limit) {
    let requestBody = this.partSearchRequestBody.returnRequestbodyFor(type, offset, limit)
    await this.productService.get_part_search_md(requestBody).subscribe(
      (data) => {
        if (data.success === true) {
          if (type == 'dotd') {
            this.showDealDay = true;
            let responseArray:any[] = data.data;
            responseArray.forEach(part => {
              if(!this.dealProductList?.includes(part)){
                part.customerQuantity = part.min_quantity;
                this.dealProductList.push(part);
              }
            })
          } else if (type == 'np') {
            this.showNewProducts = true;
            let newProductResponse: any[] = data.data;
            newProductResponse.forEach(part => {
              if(!this.newProductList.includes(part)){
                part.customerQuantity = part.min_quantity;
                this.newProductList.push(part);
              }
            })
          }
        } else {
          if (type === 'dotd') {
            this.showDealDay = false;
          }
          if (type === 'np') {
            this.showNewProducts = false;
          }
        }
      },
      (err) => {
        if (type === 'dotd') {
          this.showDealDay = false;
        }
        if (type === 'np') {
          this.showNewProducts = false;
        }
        if (err?.error?.error_code == 7) {
          this.toastr.error(err.error.msg);
        }
        if (err?.error?.data?.msg) {
          this.toastr.error(err.error.data.msg);
        }
      }
    );
  }

  addToCart(product: Product, isRecentlyAdded?): void {
    try {
      if (this.addingToCart) {
        return;
      }
      if (isNaN(product['customerQuantity'])) {
        this.toastr.error('Quantity cannot be empty or 0');
        return;
      }
      this.addingToCart = true;
      if (product['resource_type'] == 'Pack') {
        this.quantity = parseInt(product['min_quantity'])
          ? parseInt(product['min_quantity']) * parseInt(product['moq'])
          : 1 * parseInt(product['moq']);
      } else {
        this.quantity = parseInt(product['min_quantity'])
          ? parseInt(product['min_quantity'])
          : 1;
      }

      if (this.localstorage.getItem('getCartType')) {
        this.cartTypeList = JSON.parse(
          this.localstorage.getItem('getCartType')
        );
      }
      var commoncarttypedata = this.cartTypeList.filter((alitems) =>
        alitems.common_distributor_categories.includes(
          product['distributor_category']
        )
      );
      var Distributercarttypedata = this.cartTypeList.filter((alitems) =>
        alitems.distributor_categories.includes(product['distributor_category'])
      );
      let cart_type_name = '';
      if (commoncarttypedata.length > 0) {
        if (this.localstorage.getItem('selectcarttype')) {
          cart_type_name = this.localstorage.getItem('selectcarttype');

          this.localstorage.setItem('recentlyaddedpage', JSON.stringify(isRecentlyAdded));
          this.cart
            .add(
              product,
              // product['resource_type'] == 'Pack' ? product['customerQuantity'] * product['moq'] : product['customerQuantity'],
              this.isLoggedIn ? parseInt(product['customerQuantity']) ? parseInt(product['customerQuantity']) : 1: parseInt(product['customerQuantity']) ? product['customerQuantity'] * parseInt(product['moq']) : 1 * parseInt(product['moq']),
              product['distributor_category'],
              cart_type_name
            )
            .subscribe({
              complete: () => {
                localStorage.removeItem("selectcarttype");
                this.addingToCart = false;
                this.cd.markForCheck();
              },
            });
          localStorage.removeItem('selectcarttype');
        } else {
          cart_type_name = commoncarttypedata[1]['cart_type'];
          if (this.cartTypeList.length > 0) {
            this.selectcarttype = this.cartTypeList[1]['cart_type'];
            this.changeCartType(this.selectcarttype, product);
            this.btndisabled = false;
            this.addingToCart = false;
            this.btnshowConfrimed = false;
            this.btnshowsubmit = true;
            this.selectedProduct = product;
            this.productInfo.data = [product]
            this.productInfo.division_info = {
              ORG_NAME:product['organization_name']?product['organization_name']:"",
              DIV_NAME:product['division_name']?product['division_name']:"",
              PRIMISE_ADDRLINE1_s:product['primise_addrline1']?product['primise_addrline1']:"",
              PRIMISE_ADDRESS2_s:product['primise_addrline2']?product['primise_addrline2']:"",
              DIV_ID:product['division_id']?product['division_id']:"",
              GSTIN:product['gstn']?product['gstn']:"",
              ORG_Id:product['organization_id']?product['organization_id']:"",
          };
            this.modalRef1 = this.modalService.show(this.template1, {
              class: 'modal-dialog-centered modal-md',
              backdrop: 'static',
            });
          }
        }
      } else if (Distributercarttypedata.length > 0) {
        cart_type_name = Distributercarttypedata[0]['cart_type'];

        this.localstorage.setItem('recentlyaddedpage', JSON.stringify(isRecentlyAdded));
        this.cart
          .add(
            product,
            this.isLoggedIn ? parseInt(product['customerQuantity']) ? parseInt(product['customerQuantity']) : 1: parseInt(product['customerQuantity']) ? product['customerQuantity'] * parseInt(product['moq']) : 1 * parseInt(product['moq']),
            product['distributor_category'],
            cart_type_name
          )
          .subscribe({
            complete: () => {
              this.addingToCart = false;

              this.cd.markForCheck();
            },
          });
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'addToCart()',
        page_name: 'ProductCardComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  changeCartType(selectedCart, product: Product) {
    try {
      this.selectcarttype = selectedCart.cart_type;
      let data = {};
      let user = JSON.parse(this.localstorage.getItem('userData'));
      let address = JSON.parse(this.localstorage.getItem('address'));
      let billingaddress = JSON.parse(
        this.localstorage.getItem('billingAddress')
      );
      let carttypes = selectedCart.cart_type?selectedCart.cart_type:selectedCart;
      let searchData = {};
      if (user && address && billingaddress) {
        this.account_pk = +JSON.stringify(user.account_pk);
        var addresstypecheck = address.address['default'];
        if (addresstypecheck === true) {
          this.shippingaddressid = 'default';
        } else {
          this.shippingaddressid = address.address['address_id'];
        }
        var Billingaddresscheck = billingaddress.address['default'];
        if (Billingaddresscheck === true) {
          this.billingaddressid = 'default';
        } else {
          this.billingaddressid = billingaddress.address['address_id'];
        }
        searchData = {
          search_text: '',
          bs_model_name: '',
          pg_line: '',
          multi_pg_line: [],
          multi_bs_model_name: [],
          multi_dispgroup_name: [],
          model_cat: '',
          mod_uid: '',
          category_type: '',
          bom_id: '',
          durafit_parts: false,
          kit_parts: false,
          part_number_last_four: '',
          offset: 0,
          limit: 20,
          pl_id: '',
          account_pk: this.account_pk,
          shipping_address_id: this.shippingaddressid,
          part_number: product && product.part_number
            ? product.part_number
            : this.selectedProduct.part_number,
          is_for_epc_data: false,
          is_for_discount: true,
          is_for_ecom_detail: true,
          cart_type: carttypes,
          is_for_inventory :this.isUserType?.user_type == 'MECH' ? true: false

        };
      } else {
        searchData = {
          search_text: '',
          bs_model_name: '',
          pg_line: '',
          multi_pg_line: [],
          multi_bs_model_name: [],
          multi_dispgroup_name: [],
          model_cat: '',
          mod_uid: '',
          category_type: '',
          bom_id: '',
          durafit_parts: false,
          kit_parts: false,
          part_number_last_four: '',
          offset: 0,
          limit: 20,
          pl_id: '',
          part_number: product && product.part_number
            ? product.part_number
            : this.selectedProduct.part_number,
          is_for_epc_data: false,
          is_for_discount: false,
          is_for_ecom_detail: true,
          cart_type: carttypes,
          is_for_inventory :this.isUserType?.user_type == 'MECH' ? true: false

        };
      }
      this.partnumberbaseddiscount(
        searchData,
        product ? product : this.selectedProduct
      );
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'changeCartType()',
        page_name: 'ProductCardComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  partnumberbaseddiscount(json, product: Product) {
    try {
      this.productService.get_part_search_md(json).subscribe(
        (response) => {
          if (response.success === true) {
            this.baseProductdata = response;
            var productPirce = parseFloat(product['crm_mrp'].toString()).toFixed(3);
            var basediscountPirce = parseFloat(response.data[0].total_amount).toFixed(3);
            if (productPirce === basediscountPirce) {
              this.baseProductdiscount = null;
            } else {
              this.baseProductdiscount = response.data[0].total_amount;
            }
          } else {
          }
        },
        (err) => {
        }
      );
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'partnumberbaseddiscount()',
        page_name: 'ProductCardComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  closePart() {
    if (this.btnshowConfrimed) {
      localStorage.removeItem('selectcarttype');
      this.showQuickview();
    }
    this.selectcarttype = '';
    this.modalRef1.hide();
  }

  showQuickview(): void {
    if (this.showingQuickview) {
      return;
    }
    this.showingQuickview = true;
    this.quickview.show(this.selectedProduct).subscribe({
      complete: () => {
        this.showingQuickview = false;
        this.cd.markForCheck();
      },
    });
  }

  setAddCart() {
    try {
      this.btndisabled = true;
      var cart_type_name = (!this.selectcarttype && this.selectedProduct['distributor_category'] == "COM")?"mhv_cart":this.selectcarttype;
      this.cart
        .add(
          this.selectedProduct,
          this.isLoggedIn ? parseInt(this.selectedProduct['customerQuantity']) ? parseInt(this.selectedProduct['customerQuantity']) : 1: parseInt(this.selectedProduct['customerQuantity']) ? this.selectedProduct['customerQuantity'] * parseInt(this.selectedProduct['moq']) : 1 * parseInt(this.selectedProduct['moq']),
          this.selectedProduct['distributor_category'],
          cart_type_name
          // ,false
        )
        .subscribe({
          complete: () => {
            localStorage.removeItem("selectcarttype");
            this.addingToCart = false;
            this.cd.markForCheck();
          },
        });
      this.selectcarttype = '';
      this.modalRef1.hide();
      this.btndisabled = false;
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'setAddCart()',
        page_name: 'ProductCardComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  ShowPart() {
    try {
      this.btndisabled = true;
      localStorage.removeItem('selectcarttype');
      this.localstorage.setItem('selectcarttype', this.selectcarttype);
      this.modalRef1.hide();
      this.showQuickview();
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'ShowPart()',
        page_name: 'ProductCardComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }
  getS3SeasonalData() {
    if (!this.servicecalls3) {
      let user = JSON.parse(this.localstorage.getItem('userData'));
      let address = JSON.parse(this.localstorage.getItem('address'));
      let shippingaddressid = "";
      if (address) {
          shippingaddressid = address.address['default'] === true?"default":address.address['address_id']
          ?address.address['address_id']:"";
      }
      let req = {
        "file_name": "seasonalParts.json"
      }
      // Set up credentials
      this.productService.getSeasonalPartsData(req).subscribe(
        res => {
          this.servicecalls3 = true;
          let Month = this.datePipe.transform(new Date(),"MMMM");
          let seasonalParts:any[] = [];
          res.forEach(element => {
            if( element[Month] == 1 ){
              seasonalParts.push(element.Material);
            }
          });
          if(seasonalParts.length >  0){
            let req = Part_Search_Request_Of_Recommendation( user?user?.account_pk:'', seasonalParts, shippingaddressid,this.isUserType);
            if(this.pincode && (this.pincode != (address!=null?address["address"].postal_code : ""))){
              req["is_pincode_based_division"] = true;
              req["pincode"] = this.pincode ;
              req["is_for_discount"] = true;
            }
            this.productService.get_part_search_md(req).subscribe(
              res => {
                  if(res.success){
                      this.showSesonalParts = true;
                      this.seasonalPartList = res.data;
                      for (let i = 0; i < res.data.length; i++) {
                          this.seasonalPartList[i].customerQuantity = res.data[i].min_quantity;
                          this.seasonalPartList[i].unit_quantity = res.data[i].moq;
                      }
                  }else{
                    this.showSesonalParts = false;
                  }
              },error => {
                this.showSesonalParts = false;
              }
            )
          }
        }, error => {
          this.showSesonalParts = false;
      })
    }
  }
  readQuantity(event, product) {
    product.customerQuantity = event;
  }
  async PopularKits() {
    const staticKits = [
      {
        line_2: 'Gearbox GB750',
        image_url: '../assets/images/Gearbox GB750.png',
      },
      {
        line_2: 'Gearbox GB27',
        image_url: '../assets/images/Gearbox GB27.png',
      },
      {
        line_2: 'Gearbox GB550',
        image_url: '../assets/images/Gearbox GB550.png',
      }
    ];
    this.PopularKitsData = [...staticKits];
    const ListInput: Inputdata = {} as Inputdata;
    ListInput.limit = 20;
    ListInput.is_kit_for_home = true;
    this.isKitLoaded = false;
    let response = await this.model.getdataforhome(ListInput);
    if (response['success'] == true) {
      this.showKits = true;
      let PopularKitsData = response['data']['pg_line'];
      this.PopularKitsData = response['data']['pg_line'];
      if(this.PopularKitsData?.length > 0){
        this.PopularKitsData = this.PopularKitsData.sort((a, b) => {
          if (a.line_2 < b.line_2) return -1;
          else if (a.line_2 > b.line_2) return 1;
          else return 0;
        });
        this.PopularKitsData = [...staticKits, ...PopularKitsData];
      }
      else{
        this.showKits = false;
      }
      this.isKitLoaded = true;
    } else {
      this.showKits = false;
      this.isKitLoaded = true;
    }
  }

  KitSpecificParts(pg_line) {
    this.router.navigate(['/shop/productcards'], {
      queryParams: { searchBy: 'popularKits', kitName: pg_line },
    });
  }

  customerFavourites(offset) {
    let user = JSON.parse(this.localstorage.getItem('userData'));
    let address = JSON.parse(this.localstorage.getItem('address'));
    if (user && address) {
      this.account_pk = +JSON.stringify(user.account_pk);
      let billingaddress = JSON.parse(
        this.localstorage.getItem('billingAddress')
      );
      var addresstypecheck = address?.address['default'];
      if (addresstypecheck === true) {
        this.shippingaddressid = 'default';
      } else {
        this.shippingaddressid = address?.address['address_id'];
      }
      var Billingaddresscheck = billingaddress?.address['default'];
      if (Billingaddresscheck === true) {
        this.billingaddressid = 'default';
      } else {
        this.billingaddressid = billingaddress?.address['address_id'];
      }
    }
    this.cfProductList = [];
    const ListPartInput: InputPartSearch = {} as InputPartSearch;
    ListPartInput.offset = offset;
    ListPartInput.limit = 20;
    ListPartInput.search_text = '';
    ListPartInput.bs_model_name = '';
    ListPartInput.pg_line = '';
    ListPartInput.multi_pg_line = [];
    ListPartInput.multi_bs_model_name = [];
    ListPartInput.multi_dispgroup_name = [];
    ListPartInput.model_cat = '';
    ListPartInput.mod_uid = '';
    ListPartInput.category_type = '';
    ListPartInput.bom_id = '';
    ListPartInput.durafit_parts = false;
    ListPartInput.kit_parts = false;
    ListPartInput.part_number_last_four = '';
    ListPartInput.pl_id = '';
    ListPartInput.part_number = '';
    ListPartInput.is_for_epc_data = false;
    ListPartInput.is_for_discount = true;
    ListPartInput.is_for_ecom_detail = false;
    ListPartInput.account_pk = this.isLoggedIn == true ? this.account_pk : '';
    ListPartInput.shipping_address_id =
      this.isLoggedIn == true ? this.shippingaddressid : '';
    ListPartInput.is_for_top_sold_parts = true;
    ListPartInput.is_for_inventory = this.isUserType?.user_type == 'MECH' ? true: false
    if(this.pincode && (this.pincode != (address!=null?address["address"].postal_code : ""))){
      ListPartInput.is_pincode_based_division = true;
      ListPartInput.pincode = this.pincode ;
    }
    this.productService.get_part_search_md(ListPartInput).subscribe(
      (response) => {
        if (response.success == true) {
          this.showCustomerFavourites = true;
          this.cfProductList = response.data;
          for (let i = 0; i < this.cfProductList.length; i++) {
            this.cfProductList[i].customerQuantity =
              this.cfProductList[i].min_quantity;
          }
        } else {
          this.showCustomerFavourites = false;
          this.toastr.error(response.data.msg);
        }
      },
      (error) => {
        this.showCustomerFavourites = false;
        this.toastr.error(error.error.data.msg);
      }
    );
  }

  recentlyViewedItems(partNumberList, isRecentlyAdded) {
    let user = JSON.parse(this.localstorage.getItem('userData'));
    let address = JSON.parse(this.localstorage.getItem('address'));
    let billingaddress = JSON.parse(
      this.localstorage.getItem('billingAddress')
    );
    this.shippingaddressid =
      address?.address['default'] === true
        ? 'default'
        : address?.address['address_id'];
    this.billingaddressid =
      billingaddress?.address['default'] === true
        ? 'default'
        : billingaddress?.address['address_id'];
    const ListPartInput: InputPartSearch = {} as InputPartSearch;
    ListPartInput.offset = 0;
    ListPartInput.limit = 20;
    ListPartInput.search_text = '';
    ListPartInput.bs_model_name = '';
    ListPartInput.pg_line = '';
    ListPartInput.multi_pg_line = [];
    ListPartInput.multi_bs_model_name = [];
    ListPartInput.multi_dispgroup_name = [];
    ListPartInput.model_cat = '';
    ListPartInput.mod_uid = '';
    ListPartInput.category_type = '';
    ListPartInput.bom_id = '';
    ListPartInput.durafit_parts = false;
    ListPartInput.kit_parts = false;
    ListPartInput.part_number_last_four = '';
    ListPartInput.pl_id = '';
    ListPartInput.part_number = '';
    ListPartInput.is_for_epc_data = false;
    ListPartInput.is_for_discount = true;
    ListPartInput.is_for_ecom_detail = false;
    ListPartInput.account_pk = this.isLoggedIn == true ? user.account_pk : '';
    ListPartInput.shipping_address_id =
      this.isLoggedIn == true ? this.shippingaddressid : '';
    (ListPartInput.is_for_top_sold_parts = false),
      (ListPartInput.part_number_list = partNumberList);
      ListPartInput.is_for_inventory = this.isUserType?.user_type == 'MECH' ? true: false

      if(this.pincode && (this.pincode != (address!=null?address["address"].postal_code : ""))){
        ListPartInput.is_pincode_based_division = true;
        ListPartInput.pincode = this.pincode ;
      }

    this.productService.get_part_search_md(ListPartInput).subscribe(
      (response) => {
        if (response.success) {
          this.recentItems = [];
          let reference_array = [];
          let responseArray = [];
          reference_array = partNumberList;
          responseArray = response.data;
          responseArray.sort((a, b) => {
            let position =
              reference_array.indexOf(a.part_number) -
              reference_array.indexOf(b.part_number);
            return position;
          });
          this.recentItems = responseArray;
          for (let i = 0; i < this.recentItems.length; i++) {
            this.recentItems[i].customerQuantity =
              this.recentItems[i].min_quantity;
          }
          this.showRecentlyViewedProduct = true;
        } else {
          this.showRecentlyViewedProduct = false;
          this.toastr.error(response.data.msg);
        }
        if (this.itemsOnfirstLoad) {
          this.itemsOnfirstLoad = false;
        } else {

        }
      },
      (error) => {
        this.showRecentlyViewedProduct = false;
        if (error?.error?.data?.msg) {
          this.toastr.error(error.error.data.msg);
        }
      }
    );
  }

  getMegaCostBanner() {
    let newRequestBody = {
      banner_type: 'MegaCostBanner',
      account_pk: this.isLoggedIn == true ? this.userDetails.account_pk : null
    };
    this.userService.getBannerImages(newRequestBody).subscribe(
      (response) => {
        if (response.success) {
          this.showeDukaanFeatures = true;
          this.megaCostBannerList = response.data;
        } else {
          this.showeDukaanFeatures = false;
          if(response.data.msg!="Data not found"){
            this.toastr.error(response.data.msg);
          }
        }
      },
      (error) => {
        this.showeDukaanFeatures = false;
        this.toastr.error(error.error.data.msg);
      }
    );
  }

  private dealOfTheDayCalled: boolean = false;
  getDealOfTheDayBanner() {
    const requestBody = {
      banner_type: 'DealOfTheDayBanner',
      account_pk: this.isLoggedIn == true ? this.userDetails.account_pk : null
    };

    this.userService.getBannerImages(requestBody).subscribe({
      next: ({ success, data }) => {
        if (success) {
          // this.showDealDay = true;
          const bannerUrl = data[0]?.banner_url || '';
          this.dealOfTheDayBanner = data[0]
          if(this.dealOfTheDayBanner && this.dealOfTheDayBanner?.part_numbers?.length > 0){
            this.dealOfTheDayCalled = true;
            this.dealofftheday(this.dealOfTheDayBanner?.id);
          }else{
            this.dealOfTheDayCalled = true;
            this.getProduct('dotd',0,12)
          }
          document.getElementById('deals-day').style.backgroundImage = `url(${bannerUrl})`;
        } else {
          // this.showDealDay = false;
          if (data.msg !== "Data not found") {
            this.toastr.error(data.msg);
          }
          this.getProduct('dotd',0,12)
        }
      },
      error: (error) => {
        // this.showDealDay = false;
        const errorMsg = error?.error?.data?.msg || 'An error occurred';
        this.toastr.error(errorMsg);
      }
    });
  }


  sliderUser(event, sliderName) {
    if (sliderName === 'dotd') {
      this.previousOffsetForDeals = this.previousOffsetForDeals + 5;
      this.previousLimitForDeals = this.previousLimitForDeals + 5;
      this.getProduct(sliderName, this.previousOffsetForDeals, 5);
      return;
    }
    if(sliderName === 'np'){
      this.previousOffsetForNewProducts = this.previousOffsetForNewProducts + 5;
      this.previousLimitForNewProducts = this.previousLimitForNewProducts + 5;
      this.getProduct(sliderName, this.previousOffsetForNewProducts, 5);
      return;
    }
  }

  onBannerRedirect(url:any,type:any){
    if(url?.banner_hyper_link){
      if(this.isLoggedIn){
        var MobileNo = null;
        if (
          this.localstorage.getItem('userData') != null ||
          this.localstorage.getItem('userData') != undefined
        ) {
          let userData = JSON.parse(
            this.localstorage.getItem('userData')
          );
          MobileNo = userData.contact_no;
        }

        var Address = '';
        if (
          this.localstorage.getItem('address') != null ||
          this.localstorage.getItem('address') != undefined
        ) {
          let address = JSON.parse(this.localstorage.getItem('address'));
          Address =
            address.address.street_address1 +
            ', ' +
            address.address.state +
            ', ' +
            address.address.district +
            ', ' +
            address.address.city +
            ', ' +
            address.address.postal_code;
        }
        if(type == 'DealOfTheDayBanner'){
          try {
            this.googleAnlyatics.bannerClick(
              'DealOfTheDayBanner',
              Address,
              MobileNo
            );
          } catch (error) {}
        }
        if(type == 'MegaCostBanner'){
          try {
            this.googleAnlyatics.bannerClick(
              'MegaCostBanner',
              Address,
              MobileNo
            );
          } catch (error) {}
        }
      }
      window.open(url?.banner_hyper_link);
    }
    if(url?.part_numbers.length != 0){
      if(this.isLoggedIn){
        var MobileNo = null;
        if (
          this.localstorage.getItem('userData') != null ||
          this.localstorage.getItem('userData') != undefined
        ) {
          let userData = JSON.parse(
            this.localstorage.getItem('userData')
          );
          MobileNo = userData.contact_no;
        }

        var Address = '';
        if (
          this.localstorage.getItem('address') != null ||
          this.localstorage.getItem('address') != undefined
        ) {
          let address = JSON.parse(this.localstorage.getItem('address'));
          Address =
            address.address.street_address1 +
            ', ' +
            address.address.state +
            ', ' +
            address.address.district +
            ', ' +
            address.address.city +
            ', ' +
            address.address.postal_code;
        }
        if(type == 'PreloginHomeBanner'){
          try {
            this.googleAnlyatics.bannerClick(
              'PreloginHomeBanner',
              Address,
              MobileNo
            );
          } catch (error) {}
        }
        if(type == 'PreloginRightBanner'){
          try {
            this.googleAnlyatics.bannerClick(
              'PreloginRightBanner',
              Address,
              MobileNo
            );
          } catch (error) {}
        }
      }
      // if(url?.banner_title){
      //   this.localstorage.setItem("bannerTitle","Title");
      // }
      this.router.navigate(['/shop/productcards'], {
        queryParams: {
          bannerId: url?.id,
        },
      });
    }
  }

  convertToFloat(data:any){
    return parseFloat(data)
}
}
