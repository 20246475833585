import { Component, ElementRef, EventEmitter, forwardRef, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
function parseNumber<T>(value: any, def: T): number | T {
  if (typeof value === 'string') {
    value = parseFloat(value);
  } else if (typeof value !== 'number') {
    value = def;
  }

  return isNaN(value) ? def : value;
}
@Component({
  selector: 'app-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputNumberComponent),
      multi: true,
    },
  ],
})
export class InputNumberComponent implements ControlValueAccessor {
  options = {
    step: 1,
    min: null,
    max: null,
    disabled: false,
    readonly: false,
  };

  currVal;
  isEmpty: boolean = false;
  initialQuantity: string;
  @Input() userType:any;
  @Input() externalSource:any;
  @Input() inventoryQty:any;
  @HostBinding('class.input-number') class = true;

  @Input() size: 'sm' | 'lg' = null;

  @Input() set step(value: number) {
    this.options.step = parseNumber(value, 1);
  }

  @Input() set min(value: number) {
    this.options.min = parseNumber(value, null);
  }

  @Input() set max(value: number) {
    this.options.max = null;
  }

  @Input() set disabled(value: boolean) {
    this.options.disabled = !!value;
  }

  @Input() set readonly(value: boolean) {
    this.options.readonly = !!value;
  }

  @Input() isCheckBuy :any

  @ViewChild('inputElement', { static: true }) inputElementRef: ElementRef;

  get inputElement(): HTMLInputElement {
    return this.inputElementRef.nativeElement;
  }

  get value(): '' | number {
    return this.inputElement.value === ''
      ? ''
      : parseFloat(this.inputElement.value);
  }
  set value(value: '' | number) {
    this.writeValue(value);
  }
  maxLength = 9;
  @Input() isTmgoValue;
  @Input() defaultValue;
  @Output() currentQuantity = new EventEmitter<any>();
  currentValue: number = 1;

  onChange = (_: any) => {
  };
  onTouched = () => {
  };


  disablebtn = false;
  constructor(private toasterService: ToastrService) {
    this.maxLength = this.isTmgoValue == 210000 ? 2 : 9;
  }

  add(): void {
    console.log("chcek_user_type>>InventoryQty>>External_source",this.userType,this.inventoryQty,this.externalSource);
    if(!this.isTmgoValue || this.isTmgoValue <= 1 || (this.isTmgoValue && this.isTmgoValue > 1 && this.value <= 98)){
      
      this.disablebtn = false;
this.change(1,'add');
this.changeByTimer(1);
    }
    else{
      this.disablebtn = true;
    }
    
  }

  sub(): void {
    if(!this.isTmgoValue || this.isTmgoValue <= 1 || (this.isTmgoValue && this.isTmgoValue > 1 && this.value <= 99)){
      
      this.disablebtn = false;
    this.change(-1,'sub');
    this.changeByTimer(-1);
    }
    else{
      
      this.disablebtn = true;
    }
  }
  checkInput(value){
    if(value == 0){
      this.value = 1;
      // this.input(value);
      this.value =
          this.isTmgoValue > 1
            ? this.value >= 1 && this.value <= 99
              ? this.value
              : 1
            : this.value < 1
              ? 1
              : this.value;
        // this.value = this.value < 1 ? 1 : this.value;
        this.isEmpty = this.value > 0 ? false : true;
        this.currentQuantity.emit(Number(this.value));
      return
    }
  }
  input(value): void {
    if(!value || value == 0){
      this.toasterService.error('Quantity field should not be empty or 0.');
      return
      this.currentQuantity.emit(parseInt('0'));
      
    }
    this.disablebtn = false;
    if (
      this.value > this.defaultValue ||
      this.value < this.defaultValue ||
      this.value == this.defaultValue
    )
     {
      if (this.value == '' || this.value == 0) {
        this.toasterService.error('Quantity field should not be empty or 0.');
        this.isEmpty = this.value == '' ? true : false;
        return
        this.currentQuantity.emit(parseInt(value));
        //  this.toasterService.error('Quantity field should not be empty...');
        //  return;
      } else {
        // && this.externalSource.includes('Delhivery')
        if(this.userType && this.userType.user_type == 'MECH' && !this.isCheckBuy){
          if((value * this.isTmgoValue) <= this.inventoryQty){
            if(!this.isTmgoValue || this.isTmgoValue <= 1 || (this.isTmgoValue && this.isTmgoValue > 1 && this.value <= 99)){
      
              this.disablebtn = false;
            this.value =
            this.isTmgoValue > 1
              ? this.value >= 1 && this.value <= 99
                ? this.value
                : 1
              : this.value < 1
                ? 1
                : this.value;
          // this.value = this.value < 1 ? 1 : this.value;
          this.isEmpty = this.value > 0 ? false : true;
          this.currentQuantity.emit(parseInt(value));
            }
            else{
              this.disablebtn = true;
              this.value = 99
              this.currentQuantity.emit(parseInt('99'));
           
            }
          }else{
            // this.disablebtn = true;
            this.value = 1
            this.toasterService.error('Insufficient Inventory; Please adjust the quantity to proceed with your order')
          }
        }else{
          if(!this.isTmgoValue || this.isTmgoValue <= 1 || (this.isTmgoValue && this.isTmgoValue > 1 && this.value <= 99)){
      
            this.disablebtn = false;
          this.value =
          this.isTmgoValue > 1
            ? this.value >= 1 && this.value <= 99
              ? this.value
              : 1
            : this.value < 1
              ? 1
              : this.value;
        // this.value = this.value < 1 ? 1 : this.value;
        this.isEmpty = this.value > 0 ? false : true;
        this.currentQuantity.emit(parseInt(value));
          }
          else{
            this.disablebtn = true;
            this.value = 99
            this.currentQuantity.emit(parseInt('99'));
         
          }
        }
       
       
      }
    } 
    else {
      if (this.value == 0) {
        this.value =
        this.isTmgoValue > 1
          ? this.value >= 1 && this.value <= 99
            ? this.value
            : 1
          : this.value < 1
            ? 1
            : this.value;
      // this.value = this.value < 1 ? 1 : this.value;
      this.isEmpty = this.value > 0 ? false : true;
      this.currentQuantity.emit(parseInt(value));
        this.value = 1;
      } else {
        this.toasterService.error('Quantity field should not be empty...');
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    if (typeof obj === 'number') {
      this.inputElement.value = obj.toString();
    } else {
      this.inputElement.value = '';
    }
  }

  /**
   * @param direction - one of [-1, 1]
   */
  private change(direction: number,source:string): void {
    let value =
      (this.value === '' || isNaN(this.value) ? 0 : this.value) +
      this.options.step * direction;
    // console.log("check_add_value>>",this.userType,value,this.value);
    // && this.externalSource?.includes('Delhivery')
    if(this.userType && this.userType.user_type == 'MECH' && !this.isCheckBuy){
      if(source == 'add'){
        if((Number(value) * this.isTmgoValue)  <= Number(this.inventoryQty)){
          if (this.options.max !== null) {
            value = Math.min(this.options.max, value);
          }
          if (this.options.min !== null) {
            value = Math.max(this.options.min, value);
          }
          if (value !== this.value) {
      
            this.isEmpty = (value === 0) ? true : false;
            this.onChange(value);
            this.value = value;
            this.currentQuantity.emit(this.value);
          }
        }else{
          this.toasterService.error('Insufficient Inventory; Please adjust the quantity to proceed with your order')
        }
      }
      else{
        if((Number(value) * this.isTmgoValue)  > Number(this.inventoryQty)){
          if (this.options.max !== null) {
            value = Math.min(this.options.max, value);
          }
          if (this.options.min !== null) {
            value = Math.max(this.options.min, value);
          }
          if (value !== this.value) {
      
            this.isEmpty = (value === 0) ? true : false;
            this.onChange(value);
            this.value = value;
            this.currentQuantity.emit(this.value);
          }
          this.toasterService.error('Insufficient Inventory; Please adjust the quantity to proceed with your order')
        }
        else{
          if (this.options.max !== null) {
            value = Math.min(this.options.max, value);
          }
          if (this.options.min !== null) {
            value = Math.max(this.options.min, value);
          }
          if (value !== this.value) {
      
            this.isEmpty = (value === 0) ? true : false;
            this.onChange(value);
            this.value = value;
            this.currentQuantity.emit(this.value);
          }
        }
      }
     
    }else{
      if (this.options.max !== null) {
        value = Math.min(this.options.max, value);
      }
      if (this.options.min !== null) {
        value = Math.max(this.options.min, value);
      }
      if (value !== this.value) {
  
        this.isEmpty = (value === 0) ? true : false;
        this.onChange(value);
        this.value = value;
        this.currentQuantity.emit(this.value);
      }
    }
  
  }

  /**
   * @param direction - one of [-1, 1]
   */
  private changeByTimer(direction: number): void {
    let interval;
    const timer = setTimeout(() => {
      interval = setInterval(() => this.change(direction,''), 50);
    }, 300);

    const documentMouseUp = () => {
      clearTimeout(timer);
      clearInterval(interval);

      document.removeEventListener('mouseup', documentMouseUp, false);
    };

    document.addEventListener('mouseup', documentMouseUp, false);
  }

  
}
