import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { secureStorage } from '../../services/securestorage';
import { UserService } from '../../services/REST/user.service';
import { Router } from '@angular/router';
import { ProductService } from '../../services/REST/product.service';
import { element } from 'protractor';

@Component({
  selector: 'app-scheme-details',
  templateUrl: './scheme-details.component.html',
  styleUrls: ['./scheme-details.component.scss']
})
export class SchemeDetailsComponent implements AfterViewChecked, OnInit {

  @Input() schemeCode:string="";
  @Output() closeModel = new EventEmitter<any>();
  schemeDetails:any;
  constructor(private localStorage:secureStorage, private productService:ProductService, private userService:UserService, private router:Router ) { }

  @ViewChild('myDiv') myDiv: ElementRef;
  progress:number = 0;
  points:any[] = [];
  lengt:number=0;
  scheme_percentage_applied:number[]=[];

  ngOnInit(): void {
    this.getSchemeDetails();
  }
  ngAfterViewChecked(){
    if(this.schemeDetails.scheme_detail){  
      if(this.schemeDetails.scheme_detail[0].scheme_type == 'value_scheme'){
        var progressBarWidth = document.getElementById('progress_element').offsetWidth;
        this.lengt = ( progressBarWidth) /(this.points.length - 1);
        this.schemeDetails.scheme_detail[0].slab_detail.find( element => 
          {
          if(this.schemeDetails.scheme_detail[0].slab_detail[this.schemeDetails.scheme_detail[0].slab_detail.length - 1].slab_to <= this.schemeDetails.total_amount.invoice_amount ){
            this.scheme_percentage_applied.push(this.schemeDetails.scheme_detail[0].slab_detail[this.schemeDetails.scheme_detail[0].slab_detail.length - 1].percentage);
            return 
          }else if(this.schemeDetails.scheme_detail[0].slab_detail[0].slab_from > this.schemeDetails.total_amount.invoice_amount ){
            this.scheme_percentage_applied.push(0);
            return 
          }else if( element.slab_to <= this.schemeDetails.total_amount.invoice_amount ){
            this.scheme_percentage_applied.push(element.percentage);
          }
        })
        this.points.forEach((element,index) => {
          let progressBarFill = document.getElementById('progressBarFill');
          if(!this.points.includes(this.schemeDetails.total_amount.invoice_amount) && this.points[this.points.length  - 1] < this.schemeDetails.total_amount.invoice_amount){
            progressBarFill.style.width = ((this.points.length  - 1) * this.lengt ) + 'px';
            progressBarFill.setAttribute('aria-valuenow', ((this.points.length  - 1) * this.lengt ).toString());
            return
          }else if(element == this.schemeDetails.total_amount.invoice_amount){
            progressBarFill.style.width = (this.lengt * index ) + 'px';
            progressBarFill.setAttribute('aria-valuenow', (this.lengt * index ).toString());
          }else if( !this.points.includes(this.schemeDetails.total_amount.invoice_amount)  &&  element < this.schemeDetails.total_amount.invoice_amount && this.points[index+1] > this.schemeDetails.total_amount.invoice_amount){
            let diffPerc = (( this.schemeDetails.total_amount.invoice_amount - element ) / (this.points[index+1] - element ))*100;
            let extraPixel = (diffPerc / 100) * this.lengt;
            progressBarFill.style.width = ((this.lengt  * index) + extraPixel ) + 'px';
            progressBarFill.setAttribute('aria-valuenow', ((this.lengt  * index) + extraPixel ).toString());
          }
        });
      }else {
        this.points.forEach((element1, index1) => {
          let progress_bar_width = document.getElementById('progress_element_'+index1).offsetWidth;
          let length  = progress_bar_width / ( element1.list.length - 1);
          if(progress_bar_width){
            element1.mar_leng = length;
            element1.list.forEach((elem,index) => {
              let progress_bar_fill = document.getElementById('progressBarFill_'+index1);
              let base_quantity = 0;
              this.schemeDetails.part_number_stats.forEach(element2 => {
                if(element2.part_number == element1.part_number){
                  base_quantity = element2.total_quantity
                  return
                }
              });
              if(!element1.list.includes(base_quantity) && base_quantity > element1.list[element1.list.length - 1]){
                progress_bar_fill.style.width = (length * (element1.list.length - 1)) + 'px'; 
                progress_bar_fill.setAttribute('aria-valuenow', (this.lengt * (element1.list.length - 1)).toString());
                return 
              }else if(elem == base_quantity){
                progress_bar_fill.style.width = (length * index) + 'px'; 
                progress_bar_fill.setAttribute('aria-valuenow', (this.lengt * index ).toString());
              }else if( !element1.list.includes(base_quantity) && elem < base_quantity && base_quantity < element1.list[index + 1] ){
                let diff_Perce = (((base_quantity - elem)) / (element1.list[index + 1] - elem))*100;
                let extra_Pixcel = (diff_Perce / 100) * length;
                progress_bar_fill.style.width = ((length * index) + extra_Pixcel) + 'px'; 
                progress_bar_fill.setAttribute('aria-valuenow', ((length * index) + extra_Pixcel).toString());
              }
            });
          }
        });
      }
    }
  }
  getSchemeDetails(){
    try{
      this.schemeDetails = {};
      let userDetails = JSON.parse(this.localStorage.getItem('userData'));
      if(userDetails){
        let req = {
          "scheme_code": this.schemeCode,
          "account_pk": userDetails['account_pk'],
          "action_type":"get_inprocess_scheme"
        }
        this.productService.getSchemeDetails(req).subscribe(
          res => {
            if(res.success){
              this.points = [];
              this.schemeDetails = res.data;
              let lastSlab:number=0;
              if(this.schemeDetails.scheme_detail[0].scheme_type == 'value_scheme'){
                this.schemeDetails.scheme_detail[0].slab_detail.forEach((element,index) => {
                  if(index == 0){
                    this.points.push(element.slab_from);
                    this.points.push(element.slab_to);
                  }else{
                    this.points.push(element.slab_to);
                  }
                  if(index == ( this.schemeDetails.scheme_detail[0].slab_detail.length - 1) ){
                    lastSlab = element.slab_to;
                  }
                });
                this.points.unshift(0)
              }else{
                let slabParts = Object.values(this.schemeDetails.scheme_detail[0].slab_detail.reduce((result, element) => {
                  if (!result[element.part_number]) {
                    result[element.part_number] = [];
                  }
                  result[element.part_number].push(element);
                  return result;
                }, {}));
                slabParts.forEach( (element:any[], index) => {
                  let listPart:any[]=[];
                  let lastSlabVal:number=0;
                  let progressBar:number=0;
                  let PartQty:number=0;
                  let slabPercentage:number=0;
                  this.schemeDetails.part_number_stats.forEach(elem => {
                    if( elem.part_number == element[0].part_number ){
                      element.find( elementMain => {
                        if(element.length > 1){
                          if(element[element.length - 1].slab_to < elem.total_quantity){
                            slabPercentage = element[element.length - 1].percentage;
                            PartQty = elem.total_quantity
                            return 
                          }else if( element[0].slab_from > elem.total_quantity){
                              slabPercentage = 0;
                              PartQty = elem.total_quantity
                              return
                          }
                          else if( elementMain.slab_to <= elem.total_quantity ){
                            PartQty = elem.total_quantity;
                            slabPercentage = elementMain.percentage;
                          }
                        }else{
                          PartQty = elem.total_quantity;
                          slabPercentage = elementMain.slab_to <= elem.total_quantity?elementMain.percentage:0;
                        }
                      })

                    }
                  });
                  element.forEach((element1,index) => {
                    if(index == 0){
                      listPart.push(element1.slab_from);
                      listPart.push(element1.slab_to);
                    }else{
                      listPart.push(element1.slab_to);
                    }
                    if(index == ( element.length - 1) ){
                      lastSlabVal = element1.slab_to;
                    }
                  });
                  listPart.unshift(0);
                  this.points.push({"part_number":element[0].part_number,"totalQuantity":PartQty,"slabPerc":slabPercentage, "list": listPart, "progress": progressBar });
                });
              }
            }
          }
        )
      }
    }catch(error){
      this.userService.InsertExceptionLog({
        portal_type: 'Customer',
        page_url: this.router.url,
        page_name: 'SchemeDetailsComponent',
        method_name: 'getSchemeDetails()',
        error_message: error.message,
        inner_exception: error,
      });
    }
  }
  
}
