<div class="container-fluid">
  <div class="row ">
    <div
      class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 pt-3 pb-3 position-relative banner-section d-flex justify-content-center align-items-center mobile-margin-top">
      <div class="banner-wrapper" *ngIf="showCenterBanner">
        <!-- <div id="bannerslider" class="carousel carousel-dark slide" data-bs-ride="carousel">
                  <div class="carousel-inner">
                    <div [ngClass]="currentBanner === image ? 'carousel-item active' : 'carousel-item'" *ngFor="let image of preloginHomeBannerList">
                      <img [src]="image.banner_url"  alt="banner 1">
                    </div>
                  </div>
                  <div class="carousel-indicators" *ngIf="preloginHomeBannerList?.length>1">
                    <button type="button" *ngFor="let data of preloginHomeBannerList;let i = index"  data-bs-target="#bannerslider" data-bs-slide-to= "i"  [ngClass]="currentBanner === data ? 'active' : ''" aria-current="true" aria-label="Slide 1"></button>
                  </div>
                </div> -->
            <ng-container *ngIf="!centerBanners.length; else centerBannerList;">
              <owl-carousel-o [options]="customOptionsForBanner">
                <ng-container *ngFor="let item of userService.generateFake(1);let i = index;">
                  <ng-template carouselSlide [id]="i">
                    <div class="carousel-item active">
                      <skc-rectangle [loading]="loading" [skc-height]="'300px'" [skc-width]="'100%'"> </skc-rectangle>
                    </div>
                  </ng-template>
                </ng-container>
              </owl-carousel-o>
            </ng-container>
            <ng-template #centerBannerList>
              <owl-carousel-o [options]="customOptionsForBanner">
                <ng-container *ngFor="let data of centerBanners">
                  <ng-template carouselSlide [id]="data.banner_url">
                    <div class="carousel-item active" [ngStyle]="{'cursor':data?.banner_hyper_link ? 'pointer' : ''}" (click)="onBannerRedirect(data,'PreloginHomeBanner')">
                      <img [src]="data.banner_url + '/resize/1012x378'" alt="banner 1">
                      <div class="top-left">{{data.description}}</div>
                    </div>
                  </ng-template>
                </ng-container>
              </owl-carousel-o>
            </ng-template>
            
    </div>
    <div class="left-banner-menu" *ngIf="showLeftBanner">
      <ul>
        <ng-container *ngIf="!prodcutmenuData.length; else modelList">
          <li *ngFor="let item of userService.generateFake(6);let i = index;">
            <a class="ellipse-2line">
              <div class="d-inline-block img-wrapper"></div>
              <skc-rectangle [loading]="loading" [skc-height]="'31px'" [skc-width]="'250px'"> </skc-rectangle>
            </a>
          </li>
        </ng-container>
        <ng-template #modelList>
          <ng-container *ngFor="let objProduct of prodcutmenuData; let p=index;">
            <li *ngIf="p<8" (click)="getProductName(objProduct)">
              <a class="ellipse-2line" title="{{ objProduct?.group_category | titlecase }}">
                <div class="d-inline-block img-wrapper"><img alt="" src="./assets/images/left_banner.webp" /></div>
                {{ (objProduct?.group_category == " " || objProduct?.group_category == null) ? "-" : objProduct?.group_category?.substr(0,30)  }}
              </a>
            </li>
          </ng-container>
        </ng-template>
        <!-- <li>
                    <a href="javascript:void(0);"><div class="d-inline-block img-wrapper"><img alt="" src="./assets/images/fan-blade.svg"/></div>Air, Fuel, Emission & Exhaust</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);"><div class="d-inline-block img-wrapper"><img alt="" src="./assets/images/windshield.svg"/></div>Accessories, Body & Wipers</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);"><div class="d-inline-block img-wrapper"><img alt="" src="./assets/images/steering.svg"/></div>Brakes, Steering & Suspension</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);"><div class="d-inline-block img-wrapper"><img alt="" src="./assets/images/flame.svg"/></div>Cooling & Heating</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);"><div class="d-inline-block img-wrapper"><img alt="" src="./assets/images/engine.svg"/></div>Engines & Ignition</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);"><div class="d-inline-block img-wrapper"><img alt="" src="./assets/images/tools.svg"/></div>Tools, Fluids & Garage</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);"><div class="d-inline-block img-wrapper"><img alt="" src="./assets/images/battery.svg"/></div>Battery, Electrical & Wiring</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);"><div class="d-inline-block img-wrapper"><img alt="" src="./assets/images/navigation.svg"/></div>Electronics & Navigation</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);"><div class="d-inline-block img-wrapper"><img alt="" src="./assets/images/transmission.svg"/></div>Transmission & Drivetrain</a>
                  </li> -->
      </ul>
    </div>
      <div class="right-banner-menu" *ngIf="showRightBanner">
        <ul>
          <ng-container *ngIf="!preloginRightBannerList.length;else rbList">
            <li *ngFor="let item of userService.generateFake(3);let i = index;">
              <skc-rectangle [loading]="loading" [skc-height]="'120px'" [skc-width]="'216px'"> </skc-rectangle>
            </li>
          </ng-container>
          <ng-template #rbList>
            <li *ngFor="let image of preloginRightBannerList" >
              <img class="img-fluid" alt="" [src]="image.banner_url + '/resize/216x120'" (click)="onBannerRedirect(image,'PreloginRightBanner')" [ngStyle]="{'cursor':image?.banner_hyper_link ? 'pointer' : ''}"/>
            </li>
          </ng-template>
        </ul>
      </div>
  </div>

</div>
</div>

<!-- (click)="changeBanner(data);" -->

<!-- <ol class="carousel-indicators" *ngIf="preloginHomeBannerList?.length>1">
                      <li *ngFor="let data of preloginHomeBannerList;let i = index" 
                      data-bs-target="#bannerslider" data-bs-slide-to = "i" [ngClass]="currentBanner === data ? 'active' : ''"></li>
                     
                    </ol> -->
