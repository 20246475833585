import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef, ChangeDetectorRef } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";
import { ToastrService } from "ngx-toastr";
import { UserService } from 'src/app/shared/services/REST/user.service';
import { ModelService } from "../../services/REST/model.service";
import { secureStorage } from "../../services/securestorage";
import { AppStateService } from "../../services/app-state/app-state.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CartService } from "../../services/cart.service";
import { blockPermissionMsg } from 'src/app/constant';
import { Router } from "@angular/router";
@Component({
    selector: 'aggregates-in-partlist',
    templateUrl: './aggregates-in-partlist.component.html',
    styleUrls: ['./aggregates-in-partlist.component.scss']
})

export class AggregatesInPartListComponent implements OnInit {
    @ViewChild('imageZoomView', { read: TemplateRef, static: false }) imageZoomView: TemplateRef<any>;
    @Output() closePopUp = new EventEmitter<any>();
    @Output() selectedSubAggregateEmitter = new EventEmitter<any>();
    @Output() openZoomedImage = new EventEmitter<any>();
    isAggregateListLoaded: boolean = true;
    @Input() modelList: any[] = [];
    @Input() subModelList: any[] = [];
    @Input() aggregateList: any[] = [];
    @Input() selectedSubModelCategory: any = "";
    @Input() selectedModelCategory: any[] = [];
    @Input() selectedAggregate: any;
    subAggregatesList: any[] = [];
    selectedSubAggregate: any;
    isSubAggregatesLoaded: boolean = false;
    customOptionsForAggregates: OwlOptions = {
        items: 5,
        loop: false,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        dots: false,
        navSpeed: 400,
        autoWidth: true,
        navText: ["<div><img src='./assets/images/arrow-left.svg' /></div>", "<div><img src='./assets/images/arrow-right.svg' /></div>"],
        responsive: {
            0: {
                items: 1
            },
            400: {
                items: 2
            },
            740: {
                items: 3
            },
            940: {
                items: 4
            }
        },
        nav: true
    }
    imageZoomViewModal: any;
    bomIdList: any[] = [];
    figBasedPartList: any[] = [];
    isLoggedIn: boolean = false;
    vehicleDetailsForm: FormGroup;
    currentTab: string = 'Vehicle Details';
    vcNoList: any[] = [];
    @Input() selectedVcNo: any;
    productList: any[] = [];
    isProductListLoaded: boolean = false;
    quantity: number = 0;
    cartTypeList: any[] = [];
    showLoader: boolean = false;
    showDivPartdetails: boolean = false;
    showPartData: any;
    
  showPopup: boolean = false;
    constructor(private userService: UserService, private router: Router, private modelService: ModelService, private toasterService: ToastrService,
        private localstorage: secureStorage, private appStateService: AppStateService, private formBuilder: FormBuilder,
        private cart: CartService, private cd: ChangeDetectorRef,) {
        this.appStateService.isLoggedIn.subscribe((isLog) => {
            this.isLoggedIn = isLog;
        });
    }
    ngOnInit() {
        this.createVehicleForm();
        if (this.selectedModelCategory && this.modelList.length === 1) {
            this.getAllModelsList();
        }
        else if (this.selectedModelCategory && this.modelList.length > 0) {
            this.vehicleDetailsForm.controls['modelCategory'].patchValue(this.selectedModelCategory);
            if (this.selectedSubModelCategory) {
                this.vehicleDetailsForm.controls['subModelCategory'].patchValue(this.selectedSubModelCategory);
                this.getAllVcNo(this.selectedModelCategory, this.selectedSubModelCategory, true);
            }
            else {
                this.getAllSubModelList(this.selectedModelCategory, true);
            }
        }
        else {
            this.getAllModelsList();
        }
    }

    createVehicleForm() {
        this.vehicleDetailsForm = this.formBuilder.group({
            modelCategory: ['', [Validators.required, Validators.maxLength(65)]],
            subModelCategory: ['', [Validators.required]],
            vcNo: ['', [Validators.required]],
            aggregate: ['', [Validators.required]],
            subAggregate: ['', Validators.required]
        });
    }

    async getAllModelsList() {
        this.modelList = [];
        let requestBody = {
            offset: 0,
            limit: 100,
            dropdown_type: 'model_cat',
            multi_model_cat: []
        }
        let response = await this.modelService.getModelCategory(requestBody);
        if (response['success'] && response['data']?.length > 0) {
            this.modelList = response['data'];
            this.modelList = this.modelList.sort((a, b) => {
                if (a < b) return -1;
                else if (a > b) return 1;
                else return 0;
            });
            if (this.selectedModelCategory) {
                this.vehicleDetailsForm.controls['modelCategory'].patchValue(this.selectedModelCategory);
                for (let i = 0; i < this.modelList.length; i++) {
                    if (this.selectedModelCategory == this.modelList[i]) {
                        let itemToChange = this.modelList[i];
                        this.modelList.splice(i, 1);
                        this.modelList.splice(0, 0, itemToChange);
                    }
                }
                if (this.selectedSubModelCategory && this.subModelList.length > 0) {
                    this.vehicleDetailsForm.controls['subModelCategory'].patchValue(this.selectedSubModelCategory);
                    this.getAllVcNo(this.selectedModelCategory, this.selectedSubModelCategory, true);
                }
                else {
                    this.getAllSubModelList(this.selectedModelCategory, true);
                }
            }
            else {
                this.vehicleDetailsForm.controls['modelCategory'].patchValue(this.modelList[0]);
                this.selectedModelCategory = this.modelList[0];
                this.getAllSubModelList(this.selectedModelCategory, true);
            }
        }
    }

    async getAllSubModelList(model: any, isFirstTime) {
        this.subModelList = [];
        let requestBody = {
            offset: 0,
            limit: 1000,
            multi_model_cat: model ? [model] : [],
            dropdown_type: 'base_model'
        }
        let response = await this.modelService.getModelCategory(requestBody);
        if (response['success'] == true && response['data']?.length > 0) {
            this.subModelList = response['data'];
            this.subModelList = this.subModelList.sort((a, b) => {
                if (a < b) return -1;
                else if (a > b) return 1;
                else return 0;
            });
            if (isFirstTime) {
                if (this.selectedSubModelCategory) {
                    this.vehicleDetailsForm.controls['subModelCategory'].patchValue(this.selectedSubModelCategory);
                    for (let i = 0; i < this.subModelList.length; i++) {
                        if (this.selectedSubModelCategory == this.subModelList[i]) {
                            let itemToChange = this.subModelList[i];
                            this.subModelList.splice(i, 1);
                            this.subModelList.splice(0, 0, itemToChange);
                        }
                    }
                    this.getAllVcNo(this.selectedModelCategory, this.selectedSubModelCategory, true);
                }
                else {
                    this.selectedSubModelCategory = this.subModelList[0];
                    this.vehicleDetailsForm.controls['subModelCategory'].patchValue(this.selectedSubModelCategory);
                    this.getAllVcNo(this.selectedModelCategory, this.selectedSubModelCategory, true);
                }
            }

        }
    }

    async getAllVcNo(model, submodel, isFirstTime) {
        this.vcNoList = [];
        let requestBody = {
            dropdown_type: "vc_no",
            multi_model_cat: [model],
            multi_base_model: [submodel],
            offset: 0,
            limit: 1000
        }
        let response = await this.modelService.getModelCategory(requestBody);
        if (response['success']) {
            if (response['data'].length > 0) {
                this.vcNoList = response['data'];
                if (isFirstTime && !this.selectedVcNo) {
                    this.vehicleDetailsForm.controls['vcNo'].patchValue(this.vcNoList[0]);
                    this.selectedVcNo = this.vcNoList[0];
                    this.getAllAggregatesList(model, submodel, this.selectedVcNo);
                }
                else if (this.selectedVcNo) {
                    var selectedVcNoIndex = null;
                    this.vcNoList.forEach((x, index) => {
                        if (x === this.selectedVcNo) {
                            selectedVcNoIndex = index;
                        }
                    });
                    if(selectedVcNoIndex === null){
                        this.vehicleDetailsForm.controls['vcNo'].patchValue(this.vcNoList[0]);
                        this.selectedVcNo = this.vcNoList[0];
                        this.getAllAggregatesList(model, submodel, this.selectedVcNo);
                    }
                    else{
                        this.vcNoList.splice(selectedVcNoIndex, 1);
                        this.vcNoList.splice(0, 0,this.selectedVcNo);
                        this.vehicleDetailsForm.controls['vcNo'].patchValue(this.vcNoList[0]);
                        this.selectedVcNo = this.vcNoList[0];
                        this.getAllAggregatesList(model, submodel, this.selectedVcNo);
                    }
                }
            }
            else {
                this.vcNoList = [];
            }
        }
        else {

        }
    }

    async getAllAggregatesList(model, subModel, vcNo) {
        this.aggregateList = [];
        let requestBody = {
            offset: 0,
            limit: 100,
            dropdown_type: 'aggregate',
            multi_model_cat: model ? [model] : [],
            multi_base_model: subModel ? [subModel] : [],
            multi_vc_no: vcNo ? [vcNo] : []
        }
        let response = await this.modelService.getModelCategory(requestBody);
        if (response['success']) {
            if (response['data']['Aggregates'].length > 0) {
                this.aggregateList = response['data']['Aggregates'];
                this.aggregateList = this.aggregateList.sort((a, b) => {
                    if (a.aggregate < b.aggregate) return -1;
                    else if (a.aggregate > b.aggregate) return 1;
                    else return 0;
                });
                if (this.selectedAggregate) {
                    for (let i = 0; i < this.aggregateList.length; i++) {
                        if (this.selectedAggregate == this.aggregateList[i].aggregate) {
                            let itemToChange = this.aggregateList[i];
                            this.aggregateList.splice(i, 1);
                            this.aggregateList.splice(0, 0, itemToChange);
                        }
                    }
                }
                else {
                    this.selectedAggregate = this.aggregateList[0].aggregate;
                    this.vehicleDetailsForm.controls['aggregate'].patchValue(this.selectedAggregate);
                }
                this.getAllSubAggregates(model, subModel, this.selectedAggregate,this.selectedVcNo);
            }
            else {
                this.aggregateList = [];
            }
        }
    }

    getAllSubAggregates(model, submodel, aggregate, vcNo) {
        this.subAggregatesList = [];
        let requestBody = {
            dropdown_type: "sub_aggregate_no",
            multi_aggregate: [aggregate],
            multi_model_cat: [model],
            multi_base_model: [submodel],
            multi_vc_no: vcNo ? [vcNo] : [],
            offset: 0,
            limit: 1000
        }
        this.isSubAggregatesLoaded = false;
        this.modelService.getSubAggregates(requestBody).subscribe(response => {
            if (response.success) {
                if (response?.data['sub_aggregate_no'].length > 0) {
                    this.subAggregatesList = response.data['sub_aggregate_no'];
                }
                else {
                    this.toasterService.error("No Sub Aggregates Found For Selected Aggregate");
                }
            }
            this.isSubAggregatesLoaded = true;
        })
    }

    onModelChange(event) {
        this.selectedSubModelCategory = "";
        this.selectedVcNo = "";
        this.selectedAggregate = "";
        this.vehicleDetailsForm.reset();
        this.selectedModelCategory = event;
        this.vcNoList = [];
        this.aggregateList = [];
        this.subAggregatesList = [];
        this.vehicleDetailsForm.controls['modelCategory'].patchValue(event);
        this.vehicleDetailsForm.controls['vcNo'].patchValue("");
        this.vehicleDetailsForm.controls['aggregate'].patchValue("");
        this.getAllSubModelList(event, false);
    }

    onSubModelChange(selectedSubModel) {
        this.selectedSubModelCategory = selectedSubModel;
        this.selectedVcNo = "";
        this.selectedAggregate = "";
        this.vcNoList = [];
        this.aggregateList = [];
        this.subAggregatesList = [];
        this.vehicleDetailsForm.controls['subModelCategory'].patchValue(selectedSubModel);
        this.vehicleDetailsForm.controls['vcNo'].patchValue("");
        this.vehicleDetailsForm.controls['aggregate'].patchValue("");
        this.getAllVcNo(this.selectedModelCategory, this.selectedSubModelCategory, false);
    }

    onVcNoChange(selectedVcNo) {
        this.selectedAggregate = "";
        this.subAggregatesList = [];
        this.selectedVcNo = selectedVcNo;
        this.vehicleDetailsForm.controls['aggregate'].patchValue("");
        this.vehicleDetailsForm.controls['vcNo'].patchValue(selectedVcNo);
        this.getAllAggregatesList(this.selectedModelCategory, this.selectedSubModelCategory, this.selectedVcNo);
    }

    viewSubAggregates(aggregate) {
        this.selectedAggregate = aggregate;
        this.getAllSubAggregates(this.selectedModelCategory, this.selectedSubModelCategory, this.selectedAggregate,this.selectedVcNo);
    }

    viewZoomedImage(subAggregate, modal) {
        this.selectedSubAggregate = subAggregate;
        this.bomIdList = [];
        let requestBody = {
            offset: 0,
            limit: 40,
            multi_model_cat: [this.selectedModelCategory],
            multi_aggregate: [this.selectedAggregate],
            multi_sub_aggregate_no: [this.selectedSubAggregate.sub_aggregate_no]
        }
        this.modelService.getBomUidList(requestBody).subscribe(response => {
            if (response.success) {
                this.bomIdList = response.data;
                // this.getPartListForSubAggregate(0, 100, this.bomIdList);
            }
            else {
                if (response?.data?.msg) {
                    this.toasterService.error(response.data.msg);
                }
            }
        })
    }

    async getPartListForSubAggregate(subAggregate) {
        this.selectedSubAggregate = subAggregate;
        this.isProductListLoaded = false;
        let userData = JSON.parse(this.localstorage.getItem('userData'));
        let address = JSON.parse(this.localstorage.getItem('address'));
        let requestBody = {
            account_pk: this.isLoggedIn ? userData.account_pk : "",
            offset: 0,
            limit: 100,
            bom_id: subAggregate?.bom_uid,
            durafit_parts: false,
            is_for_discount: this.isLoggedIn == true ? true : false,
            is_for_ecom_detail: false,
            is_for_epc_data: true,
            is_home: false,
            kit_parts: false,
            shipping_address_id: address?.address['default'] ? "default" : address?.address['address_id'] ? address?.address['address_id'] : "",
        }
        this.figBasedPartList = [];
        let response = await this.modelService.getPartSearchMd(requestBody);
        if (response['success']) {
            this.figBasedPartList = response['data'];
            this.figBasedPartList.forEach(x => {
                x.customerQuantity = x.min_quantity;
            });
            this.figBasedPartList = this.figBasedPartList.sort((a, b) => {
                let aNo = (a.fig_no === "-" || a.fig_no === "" || a.fig_no === null || a.fig_no === undefined) ? this.figBasedPartList.length : parseFloat(a.fig_no);
                let abNo = (b.fig_no === "-" || b.fig_no === "" || b.fig_no === null || b.fig_no === undefined) ? this.figBasedPartList.length : parseFloat(b.fig_no);
                if (aNo > abNo) {
                    return 1;
                } else if (aNo < abNo) {
                    return -1;
                } else {
                    return 0;
                }
            });
            this.currentTab = 'Part List';
            this.isProductListLoaded = true;
        }
        else {
            this.isProductListLoaded = true;
            if (response['data']?.msg) {
                this.toasterService.error(response['data']?.msg);
            }
        }
    }

    closeModal() {
        this.closePopUp.emit();
    }

    readQuantityForEnginePartList(customerQuantity, product) {
        product.customerQuantity = customerQuantity;
    }

    addtoCartitemsForEnginePartList(Item) {
        this.showPopup = false;
        let permission = this.userService.CheckPermission(
            'btn_cart'
        );
        if (permission) {
            if (isNaN(Item.customerQuantity)) {
                this.toasterService.error('Quantity cannot be empty or 0');
                return;
            }
            else if (
                Item.customerQuantity !== null &&
                Item.customerQuantity > 0 &&
                Item.customerQuantity !== undefined
            ) {
                if (Item.customerQuantity < Item['min_quantity']) {
                    this.toasterService.error('Quantity field cannot be less than minimum quantity');
                    return;
                }
                else {
                    $('#btn_' + Item.part_number).prop('disabled', true);
                    if (Item['resource_type'] == 'Pack') {
                        this.quantity =this.isLoggedIn ? parseInt(Item.customerQuantity) ? parseInt(Item.customerQuantity) : 1: parseInt(Item.customerQuantity) ? Item.customerQuantity * parseInt(Item['moq']) : 1 * parseInt(Item['moq'])
                    } else {
                        this.quantity = Item.customerQuantity ? Item.customerQuantity : 1;
                    }
                    this.showLoader = true;
                    if (this.localstorage.getItem('getCartType')) {
                        this.cartTypeList = JSON.parse(this.localstorage.getItem('getCartType'));
                    }
                    var commoncarttypedata = this.cartTypeList.filter((alitems) =>
                        alitems.common_distributor_categories.includes(
                            Item['distributor_category']
                        )
                    );
                    var Distributercarttypedata = this.cartTypeList.filter((alitems) =>
                        alitems.distributor_categories.includes(Item['distributor_category'])
                    );
                    let cart_type_name = '';
                    if (commoncarttypedata.length > 0) {
                        if (this.localstorage.getItem('selectcarttype')) {
                            cart_type_name = this.localstorage.getItem('selectcarttype');

                            this.cart
                                .add(
                                    Item,
                                    this.quantity,
                                    Item['distributor_category'],
                                    cart_type_name
                                )
                                .subscribe({
                                    complete: () => {
                                        $('#btn_' + Item.part_number).prop('disabled', false);
                                        this.cd.markForCheck();
                                        this.showLoader = false;
                                    },
                                });
                            localStorage.removeItem('selectcarttype');
                        }
                    } else if (Distributercarttypedata.length > 0) {
                        cart_type_name = Distributercarttypedata[0]['cart_type'];
                        this.cart
                            .add(
                                Item,
                                this.quantity,
                                Item['distributor_category'],
                                cart_type_name
                            )
                            .subscribe({
                                complete: () => {
                                    $('#btn_' + Item.part_number).prop('disabled', false);
                                    this.cd.markForCheck();
                                },
                            });
                    }
                }
            }
            else {
                if (Item['resource_type'] == 'Pack') {
                    if (Item.customerQuantity < Item['min_quantity']) {
                        this.toasterService.error('Unit field should not be empty.');
                        return;
                    }
                } else if (Item.customerQuantity == 0) {

                    this.toasterService.error('Quantity field cannot be 0 .Minimum quantity required.');
                } else {
                    this.toasterService.error('Quantity field should not be empty.');
                }
            }
        }
        else {
            this.toasterService.error(blockPermissionMsg);
        }
    }

    // addtoCartitems(Item) {
    //     let permission = this.userService.CheckPermission('btn_cart');
    //     if (permission) {
    //         if (isNaN(Item.customerQuantity)) {
    //             this.toasterService.error('Quantity cannot be empty or 0');
    //             return;
    //         }
    //         else if (Item.customerQuantity !== null && Item.customerQuantity > 0 && Item.customerQuantity !== undefined) {
    //             if (Item.customerQuantity < Item['min_quantity']) {
    //                 this.toasterService.error('Quantity field cannot be less than minimum quantity');
    //                 return;
    //             }
    //             else {
    //                 this.showDivPartdetails = false;
    //                 this.showPartData = [];
    //                 $('#btn_' + Item.part_number).prop('disabled', true);
    //                 if (Item['column_4']) {
    //                     this.quantity = Item.customerQuantity ? Item.customerQuantity * parseInt(Item['column_4']) : 1 * parseInt(Item['column_4']);
    //                 } else {
    //                     this.quantity = Item.customerQuantity ? Item.customerQuantity : 1;
    //                 }
    //                 this.showLoader = true;
    //                 if (this.localstorage.getItem('getCartType')) {
    //                     this.cartTypeList = JSON.parse(this.localstorage.getItem('getCartType'));
    //                 }
    //                 var commoncarttypedata = this.cartTypeList.filter((alitems) =>
    //                     alitems.common_distributor_categories.includes(Item['distributor_category'])
    //                 );
    //                 var Distributercarttypedata = this.cartTypeList.filter((alitems) =>
    //                     alitems.distributor_categories.includes(Item['distributor_category'])
    //                 );
    //                 let cart_type_name = '';
    //                 if (commoncarttypedata.length > 0) {
    //                     if (this.localstorage.getItem('selectcarttype')) {
    //                         cart_type_name = this.localstorage.getItem('selectcarttype');
    //                         this.cart.add(Item, this.quantity, Item['distributor_category'], cart_type_name).subscribe({
    //                             complete: () => {
    //                                 $('#btn_' + Item.part_number).prop('disabled', false);
    //                                 this.cd.markForCheck();
    //                                 this.showLoader = false;
    //                             },
    //                         });
    //                         localStorage.removeItem('selectcarttype');
    //                     }
    //                 } else if (Distributercarttypedata.length > 0) {
    //                     cart_type_name = Distributercarttypedata[0]['cart_type'];
    //                     this.cart
    //                         .add(
    //                             Item,
    //                             this.quantity,
    //                             Item['distributor_category'],
    //                             cart_type_name
    //                             // ,false
    //                         )
    //                         .subscribe({
    //                             complete: () => {
    //                                 $('#btn_' + Item.part_number).prop('disabled', false);
    //                                 this.cd.markForCheck();
    //                             },
    //                         });
    //                 }
    //             }
    //         }
    //         else {
    //             if (Item['column_4']) {
    //                 if (Item.customerQuantity < Item['min_quantity']) {
    //                     this.toasterService.error('Unit field should not be empty.');
    //                     return;
    //                 }
    //             }
    //             else if (Item.customerQuantity == 0) {
    //                 this.toasterService.error('Quantity field cannot be 0 .Minimum quantity required.');
    //             }
    //             else {
    //                 this.toasterService.error('Quantity field should not be empty.');
    //             }
    //         }
    //     }
    //     else {
    //         this.toasterService.error(blockPermissionMsg);
    //     }
    // }
    showparts(order) {
        var x = window.matchMedia("(max-width: 767px)")
        var y = window.matchMedia("(max-width: 991px)")
        var z = window.matchMedia("(max-width: 1200px)")
        if(x.matches){
            this.router.navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/shop/product-details'], { queryParams: { part_Number: order.part_number } }));
          this.closeModal();
        }else if(y.matches){
            $('#tbl-part-details').on('click', 'td', function () {
                console.log($(this).position().top)
                var fromTop = $(this).position().top + 115 ;
                $('.part-popup').css('top', fromTop);
                var fromLeft = (- 91 + $(this).position().left);
                console.log($(this).position().left);
                $('.part-popup').css('left', fromLeft);
            });
        }else if(z.matches){
            $('#tbl-part-details').on('click', 'td', function () {
                console.log($(this).position().top)
                var fromTop = $(this).position().top + 115 ;
                $('.part-popup').css('top', fromTop);
                var fromLeft = 131 + $(this).position().left;
                console.log($(this).position().left);
                $('.part-popup').css('left', fromLeft);
            });
        }else{
            $('#tbl-part-details').on('click', 'td', function () {
                console.log($(this).position().top)
                var fromTop = $(this).position().top + 115 ;
                $('.part-popup').css('top', fromTop);
                var fromLeft = 380 - $(this).position().left;
                console.log($(this).position().left);
                $('.part-popup').css('left', fromLeft);
            });
        }
        
        this.showPopup = true;
        this.showPartData = order;
      }
      closePopup(event) {
        if (event == 'Close') {
          this.showPopup = false;
        }
      }
    // showPartDetails(partDetails) {
    //     $('#tbl-part-details').on('click', 'td', function () {
    //         var fromTop = $(this).position().top - 70;
    //         $('.part-popup').css('top', fromTop);
    //     });
    //     this.showDivPartdetails = true;
    //     this.showPartData = partDetails;
    // }

    ClosePart() {
        this.showDivPartdetails = false;
        this.showPartData = null;
    }

    onRouteSameUrl(partnumber) {
        this.closePopUp.emit();
        this.router.navigate(["/shop/product-details"], { queryParams: { part_Number: partnumber } });
    }

    readQuantity(customerQuantity, product) {
        product.customerQuantity = customerQuantity;
    }

    temp(){
        this.showPopup = false;
    }
}