import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { UserService } from 'src/app/shared/services/REST/user.service';
declare var $: any;
import { blockPermissionMsg } from 'src/app/constant';
import { AuthService } from 'src/app/shared/services/REST/auth.service';
import { secureStorage } from 'src/app/shared/services/securestorage';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit, AfterViewInit, OnDestroy {
  blockPermissionMsg = blockPermissionMsg;
  public dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  newRequestBody: any;
  datatsetempty = false;
  dtOptions: DataTables.Settings = {};

  totalrecord: any;
  currentPage = 1;
  accountList: any[];
  userIdData = [];
  showLoader: boolean = false;
  // ADD ACCOUNT IMPLEMENTATION
  userAccountForm: FormGroup;
  accountid: any;
  btndisabled: boolean = false;
  @ViewChild('addUserAccount', { read: TemplateRef, static: false })
  addUserAccount: TemplateRef<any>;
  addUserAccountModal: any;
  userDetails: any;
  constructor(
    private toastrService: ToastrService,
    private userService: UserService,
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private localstorage: secureStorage,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    //this.openmodel();
    this.userDetails = JSON.parse(this.localstorage.getItem('userData'));
    this.loadAccountList();
    this.accountForm();
  }

  openmodel() {
    // $(document).ready(function () {
    // $('[data-toggle="popover"]').popover();
    $('#account-management').DataTable({
      // dom: 't<"bottom"ipl><"clear">',
      aoColumnDefs: [
        {
          bSortable: false,
          aTargets: [-1] /* 1st one, start by the right */,
        },
      ],
    });
    // });
  }

  loadAccountList(type?) {
    try {
      let user = JSON.parse(this.localstorage.getItem('userData'));
      if (user) {
        if (user && user.account_pk && user.account_pk) {
          this.calldatatable(type);
          // this.showLoader = true;
          // this.userService.GetAccountList({ account_pk: '' + user.account_pk, "offset": page, "size": 100 })
          //   .subscribe(response => {
          //     if (response.success) {
          //       this.accountList = response.data.result;
          //       this.totalrecord = response.data.total_results;
          //       if (this.localstorage.getItem("AccountData") == "true") {
          //         this.localstorage.setItem("AccountData", "false")
          //         for (let data of this.accountList) {
          //           this.userIdData.push(this.FormUserData(data))
          //         }
          //         var userDetail = JSON.parse(this.localstorage.getItem('user'));
          //         userDetail.user_ids = this.userIdData
          //         this.localstorage.setItem('user', JSON.stringify(userDetail));
          //       }
          //       //if(type !== "Account Id Added Successfully.")
          //       this.openmodel();
          //     } else {
          //       this.accountList = [];
          //       //this.openmodel();
          //     }
          //     this.showLoader = false;
          //   }, error => {
          //     this.showLoader = false;
          //   })
        }
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: 'loadAccountList(page)',
        page_name: 'PageAccountListComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  FormUserData(data): UserIdData {
    const objdata: UserIdData = {} as UserIdData;
    objdata.id = +data.account_pk;
    objdata.account_id = data.account_id;
    return objdata;
  }

  // ADD ACCOUNT IMPLEMENTATION DOWNWARDS

  addAccount() {
    let permission = this.userService.CheckPermission('btn_add_account');
    if (permission) {
      this.addUserAccountModal = this.modalService.show(this.addUserAccount, {
        class: 'modal-dialog model-sm modal-dialog-centered',
        backdrop: 'static',
      });
    } else {
      this.toastrService.error(blockPermissionMsg);
    }
  }
  accountForm() {
    this.userAccountForm = this.formBuilder.group({
      name: [''],
      custid: ['', [Validators.required]],
    });
  }

  alphanumbericOnly(event): boolean {
    var inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z0-9-]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  onSubmit() {
    try {
      if (!this.accountid) {
        //this.formErrorService.displayFormErrors(this.userAccountForm);
        return;
      }
      this.btndisabled = true;
      let user = JSON.parse(this.localstorage.getItem('userData'));
      if (user) {
        let data = {
          account_id: this.accountid,
          account_pk: parseInt(user.account_pk),
        };
        let permission = this.userService.CheckPermission('btn_add_account');
        if (permission) {
          this.localstorage.setItem('AccountData', 'true'); //for test
          this.showLoader = true;
          this.userService.AddAccount(data).subscribe(
            (response) => {
              if (response.success === true) {
                this.showLoader = false;
                this.accountid = '';
                localStorage.removeItem('AccountData');
                this.localstorage.setItem('AccountData', 'true');
                this.toastrService.success('Account Id Added Successfully.');
                // this.router.navigate(['/account/accountlist']);
              } else {
                this.showLoader = false;
                this.btndisabled = false;
                this.toastrService.error(response.data.msg);
              }
              this.showLoader = false;
            },
            (error) => {
              this.showLoader = false;
            }
          );
        } else {
          this.toastrService.error(blockPermissionMsg);
        }
      }
    } catch (error) {
      this.userService.InsertExceptionLog({
        method_name: ' onSubmit()',
        page_name: 'PageAddAccountComponent',
        portal_type: 'Customer',
        page_url: this.router.url,
        error_message: error.message,
        inner_exception: error,
      });
    }
  }

  closeModal(event) {
    // if (event == 'Account Id Added Successfully.') {
    //  this.router.navigate(['/account/accountlist']);
    this.loadAccountList(true);
    this.rerender();
    this.accountForm();
    this.refreshAccountList();
    // }
    this.addUserAccountModal.hide();
  }

  refreshAccountList() {
    let userData = JSON.parse(this.localstorage.getItem('userData'));
    var json = { user_type: userData.user_type, offset: 0, limit: 30 };
    this.authService.getUserTypeList(json).subscribe(
      (response) => {
        if (response.success === true) {
          localStorage.removeItem('userAccounts');
          this.localstorage.setItem(
            'userAccounts',
            JSON.stringify(response['data'])
          );
        }
      },
      (error) => {
        this.toastrService.error(error.error.data.msg);
      }
    );
  }

  // pageChange(page: any) {
  //   this.currentPage = page;
  //   page = page - 1;
  //   this.loadAccountList(page, 'Fresh Run');
  //    this.rerender();
  // }

  calldatatable(newAccAdded) {
    let user = JSON.parse(this.localstorage.getItem('userData'));

    if (user && user.account_pk) {
      this.newRequestBody = {
        account_pk: '' + user.account_pk,
        limit: 10,
        status: "All"
      };
      this.showLoader = true;
      this.dtOptions = {
        pagingType: 'full_numbers',
        responsive: true,
        serverSide: true,
        processing: true,
        lengthChange: false,
        searching: false,
        pageLength: 10,
        ordering: false,
        language: {
          info: 'Showing _START_ to _END_ of _TOTAL_ entries',
          infoEmpty: 'No records available',
          // infoFiltered: ' ',
        },
        ajax: (dataTablesParameters: any, callback) => {
          this.newRequestBody['offset'] = dataTablesParameters.start;
          this.accountList = [];
          this.totalrecord = 0;

          this.userService.GetAccountList(this.newRequestBody).subscribe(
            (response) => {
              if (response.success) {
                this.showLoader = false;
                this.accountList = response.data;
                // localStorage.removeItem('userAccounts');
                // this.localstorage.setItem('userAccounts',JSON.stringify(this.accountList));
                if (dataTablesParameters?.order?.length > 0) {
                  if (dataTablesParameters.order[0].dir) {
                    let sortdatacolumn = dataTablesParameters.order[0].column;
                    let sortdataorder = dataTablesParameters.order[0].dir;
                    let textA, textB;
                    //for string,name
                    this.accountList.sort((a, b) => {
                      if (sortdatacolumn == 1) {
                        textA = a.account_name.toUpperCase();
                        textB = b.account_name.toUpperCase();
                      }
                      //   else if(sortdatacolumn == 4){
                      //    textA = new Date(a.created_at).getTime();
                      //  textB = new Date(b.created_at).getTime();
                      //   }
                      if (sortdataorder == 'asc') {
                        return textA < textB ? -1 : textA > textB ? 1 : 0;
                      } else {
                        return textB < textA ? -1 : textB > textA ? 1 : 0;
                      }
                    });
                  }
                }
                this.totalrecord = response.total_result;

                if (this.localstorage.getItem('AccountData') == 'true') {
                  this.localstorage.setItem('AccountData', 'false');
                  for (let data of this.accountList) {
                    this.userIdData.push(this.FormUserData(data));
                  }
                  var userDetail = JSON.parse(this.localstorage.getItem('user'));
                  userDetail.user_ids = this.userIdData;
                  this.localstorage.setItem('user', JSON.stringify(userDetail));
                }

                //if(type !== "Account Id Added Successfully.")
                //this.openmodel();
                // this.showLoader = false;
              } else {
                this.showLoader = false;
                this.toastrService.error(response.data.msg);
                this.accountList = [];
                if (response.data.msg == 'Data not found') {
                  this.totalrecord = 0;
                  this.datatsetempty = true;
                }
                // this.dtTrigger.next();
              }

              callback({
                recordsTotal: this.totalrecord,
                recordsFiltered: this.totalrecord,
                data: [],
                // data: response.data.result,
              });
            },
            (err) => {
              this.accountList = [];
              this.showLoader = false;
            }
          );
        },
        columns: [
          { data: 'account_id' }, //0
          { data: 'account_name' }, //1
          { data: 'contact_no' }, //2
          { data: 'address_1' }, //3
          { data: 'status' }, //4
          // { data: 'account.created_at' }, //4
        ],
        columnDefs: [
          {
            orderable: false,
            targets: [0, 2, 3],
          },
        ],
        //this above line will disable sort for all menntioned columns in targets

        order: [[1, 'asc']],

        //this above line will set sorting to asc for menntioned columns in order
      };
    }
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  changeAccountStatus(event: MatSlideToggleChange, accountId) {
    let requestBody = {
      account_pk: JSON.parse(this.localstorage.getItem('userData')).account_pk,
      account_id: accountId,
      status: event.checked ? "active" : "in_active"
    }
    this.userService.updateAccountStatus(requestBody).subscribe(response => {
      if(response['success']){
        this.toastrService.success(response['data']?.msg);
        this.accountList.forEach(x => {
          if(x.account_id === accountId){
            x.is_active = event.checked;
          }
        });
      }
      else{
        if(response['data']?.msg){
          this.toastrService.error(response['data'].msg);
        }
        this.accountList.forEach(x => {
          if(x.account_id === accountId){
            x.is_active = !event.checked;
          }
        });
        event.checked = !event.checked;
      }
    },error => {
      if(error?.error?.data?.msg){
        this.toastrService.error(error?.error?.data?.msg);
      }
      event.checked = !event.checked;
    })
  }
}

export interface UserIdData {
  id: number;
  account_id: string;
}
