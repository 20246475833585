<h5 class="mb-3" *ngIf="!Compatiblemodeldata?.length && !isNoDataFound"><skc-rectangle [loading]="loading" [skc-height]="'18px'" [skc-width]="'50%'"></skc-rectangle></h5>
<h5 class="mb-3" *ngIf="Compatiblemodeldata?.length">Compatible Models ({{ Compatiblemodeldata?.length }})</h5>
<h5 class="mb-3" *ngIf="Compatiblemodeldata.length == 0 && isNoDataFound">Compatible Models (0)</h5>
<div class="table-responsive">
  <ng-container *ngIf="!Compatiblemodeldata?.length && !isNoDataFound; else variantDataList">
    <table class="table datatable display tbl-compactiable">
      <thead class="text-uppercase">
        <tr>
          <th><skc-rectangle [loading]="loading" [skc-height]="'30px'" [skc-width]="'100%'"></skc-rectangle></th>
          <th><skc-rectangle [loading]="loading" [skc-height]="'30px'" [skc-width]="'100%'"></skc-rectangle></th>
          <th><skc-rectangle [loading]="loading" [skc-height]="'30px'" [skc-width]="'100%'"></skc-rectangle></th>
          <th><skc-rectangle [loading]="loading" [skc-height]="'30px'" [skc-width]="'100%'"></skc-rectangle></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of userService.generateFake(2);">
          <td>
            <div class="img-fluid"><skc-rectangle [loading]="loading" [skc-height]="'60px'" [skc-width]="'100%'"></skc-rectangle></div>
            <div class="model"><skc-rectangle [loading]="loading" [skc-height]="'20px'" [skc-width]="'100%'"></skc-rectangle></div>
            <div class="variants"><skc-rectangle [loading]="loading" [skc-height]="'15px'" [skc-width]="'100%'"></skc-rectangle></div>
          </td>
          <td>
            <table class="table table-borderless table-sm table align-middle mb-0">
              <tbody>
                <tr *ngFor="let item of userService.generateFake(4)">
                  <td><skc-rectangle [loading]="loading" [skc-height]="'15px'" [skc-width]="'100%'"></skc-rectangle></td>
                </tr>
              </tbody>
            </table>
          </td>
          <td>
            <table class="table table-borderless table-sm table align-middle mb-0">
              <tbody>
                <tr *ngFor="let item of userService.generateFake(4)">
                  <td><skc-rectangle [loading]="loading" [skc-height]="'20px'" [skc-width]="'100%'"></skc-rectangle></td>
                </tr>
              </tbody>
            </table>
          </td>
          <td>
            <table class="table table-borderless table-sm table align-middle mb-0">
              <tbody>
                <tr *ngFor="let item of userService.generateFake(4)">
                  <td><skc-rectangle [loading]="loading" [skc-height]="'20px'" [skc-width]="'100%'"></skc-rectangle></td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>
  <ng-template #variantDataList>
    <!-- <div class="d-none">
      d-lg-block d-md-none d-xl-block d-sm-block
      <table class="table datatable display tbl-compactiable" *ngIf="Compatiblemodeldata.length>0" >
        datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
        <thead class="text-uppercase">
          <tr>
            <th>Model</th>
            <th>Variant</th>
            <th>Engine</th>
            <th>Capacity</th>
            <th>Fuel</th>
          </tr>
        </thead>
        <tbody *ngIf="Compatiblemodeldata.length>0 && !isNoDataFound">
          <tr *ngFor="let objcmodel of Compatiblemodeldata">
            specific model name and image
            <td>
              <img [src]="objcmodel?.model_image" alt="" class="img-fluid"
                onerror="this.src='./assets/tata-vehicle.jfif';" style="max-width: 60px;">
              <div class="model">{{ objcmodel?.model_cat }}</div>
              <div class="variants">{{ objcmodel?.vcArray?.length }} Variants</div>
    
            </td>
            variantlist of the specific model
            <td>
              <table class="table table-borderless table-sm table align-middle mb-0">
                <tbody>
                  <tr *ngFor="let objvc of objcmodel?.vcArray">
                    <td (click)="GetsubModelDeails(objcmodel,objvc)" ><a style="cursor: pointer;color: #0d6efd;">{{ objvc?.vc_no }}</a></td>
                  </tr>
                </tbody>
              </table>
            </td>
            engine type of specific variant
            <td>
              <table class="table table-borderless table-sm table align-middle mb-0">
                <tbody>
                  <tr *ngFor="let objengine of objcmodel?.vcArray">
                    <td>{{ objengine?.engine_type }}</td>
                  </tr>
                </tbody>
              </table>
            </td>
            fuel type of specific variant
            <td>
              <table class="table table-borderless table-sm table align-middle mb-0">
                <tbody>
                  <tr *ngFor="let objdisel of objcmodel?.vcArray">
                    <td>{{ objdisel?.fuel_type }}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div> -->
    <div class="d-block">
      <!-- d-lg-none d-md-block d-xl-none d-sm-none -->
      <table class="table datatable display tbl-compactiable" *ngIf="Compatiblemodeldata.length>0" >
        <!-- datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"> -->
        <thead class="text-uppercase">
          <tr>
            <th>Model</th>
            <th>Variant</th>
            <th>Engine</th>
            <!-- <th>Capacity</th> -->
            <th>Fuel</th>
          </tr>
        </thead>
        <tbody *ngIf="Compatiblemodeldata.length>0 && !isNoDataFound">
          <ng-container *ngFor="let objcmodel of Compatiblemodeldata">
            <!-- specific model name and image -->
            
              <tr *ngFor="let objvc of objcmodel?.vcArray; let i=index">
                <td *ngIf="i==0" [attr.rowspan]="objcmodel?.vcArray?.length">
                  <img [src]="objcmodel?.model_image + '/resize/60x60'" alt="" class="img-fluid"
                    onerror="this.src='./assets/tata-vehicle.jfif';" style="max-width: 60px;">
                  <div class="model">{{ objcmodel?.model_cat }}</div>
                  <div class="variants">{{ objcmodel?.vcArray?.length }} Variants</div>
                </td>
                <td (click)="GetsubModelDeails(objcmodel,objvc)">
                  <a style="cursor: pointer;color: #0d6efd;">{{ objvc?.vc_no }}</a>
                </td>
                <td>
                  {{ objvc?.engine_type }}
                </td>
                <td>
                  {{ objvc?.fuel_type }}
                </td>
              </tr>
            </ng-container>
            <!-- variantlist of the specific model -->
            <!-- <td>
              <table class="table table-borderless table-sm table align-middle mb-0">
                <tbody>
                  <tr *ngFor="let objvc of objcmodel?.vcArray">
                    <td (click)="GetsubModelDeails(objcmodel,objvc)" >
                      <a style="cursor: pointer;color: #0d6efd;">{{ objvc?.vc_no }}</a>
                      <br>
                      {{ objvc?.engine_type }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td> -->
            <!-- engine type of specific variant -->
            <!-- <td>
              <table class="table table-borderless table-sm table align-middle mb-0">
                <tbody>
                  <tr *ngFor="let objengine of objcmodel?.vcArray">
                    <td>{{ objengine?.engine_type }}</td>
                  </tr>
                </tbody>
              </table>
            </td> -->
            <!-- fuel type of specific variant -->
            <!-- <td>
              <table class="table table-borderless table-sm table align-middle mb-0">
                <tbody>
                  <tr *ngFor="let objdisel of objcmodel?.vcArray">
                    <td>{{ objdisel?.fuel_type }}</td>
                  </tr>
                </tbody>
              </table>
            </td> -->
        </tbody>
      </table>
    </div>
  </ng-template>
  
  <!-- <div class="card-footer" >
    <order-pagination [activePage]="currentPage" [totalRecords]="totalcomprecord" *ngIf="totalcomprecord>10" [recordsPerPage]="10" >
    </order-pagination>
</div> -->
<ng-container *ngIf="!Compatiblemodeldata?.length && !isNoDataFound; else paginationList">
  <div class="paging">
    <skc-rectangle [loading]="loading" [skc-height]="'50px'" [skc-width]="'100%'"></skc-rectangle>
  </div>
</ng-container>
<ng-template #paginationList>
<div class="paging">
  <order-pagination [activePage]="compcurrentPage" [totalRecords]="totalcomprecord" 
              [vehicleCount]="vehiclecount"
  [compatibleModelPage]="compatibleModelPage" *ngIf="totalcomprecord>10" 
  [hideShowingRecords] = "false"
      [recordsPerPage]="20" (onPageChange)="pageChange($event)"></order-pagination>
</div>
</ng-template>
  <div *ngIf="Compatiblemodeldata.length == 0 && isNoDataFound" style=" text-align: center;">
    <span> <b>No Data Found</b></span>
  </div>
</div>
<!-- <div class="waranty-policy">
  <h6>Warranty Policy</h6>
  <p>Right parts, guaranteed. If you find any defects, let us know within 90 days and we’ll take care
    of it.
    Check out our Warranty Policy for more details.</p>
</div>
<div class="shipping-policy">
  <h6>Shipping Policy</h6>
  <p>We ship fast so you can get on the road that much faster. Check out our Shipping Policy for more
    details.
  </p>
</div>
<div class="return-policy">
  <h6>Easy Return Policy</h6>
  <p>We’re only happy if you’re happy. If you’re not entirely satisfied with your order, you may be
    entitled
    to a refund. Check out our Return Policy for more details.</p>
</div> -->
<!-- <ng-container *ngIf="showLoader">
  <div class="loader">
    <div class="ng-spinner ng-spinner-icon"></div>
  </div>
</ng-container> -->