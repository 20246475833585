<!--first div-->
<div class="modal-content">
    <div class="modal-header modal-title">
        Coupon Details
        <div (click)="closeCouponDetails.emit()" type="button"><i class="fa fa-times"></i></div>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="section" style="margin-top: 20px;">
                <div class="container">
                    <div id="tb-testimonial" class="testimonial testimonial-default shadow">
                        <div class="testimonial-section h-100">
                            <div class="row">
                                <div class="col-md-6 text-center">
                                   
                                        <img [src]="couponDetails?.discount_image_path" class="img-fluid" style="height: 200px; width: 370px; vertical-align:middle">                           
                                   
                                    <p class="mt-1"><span class="valid-till-tetx-for-mobile">Valid till {{couponDetails?.to_dt| date: 'dd-MM-yyyy h:mm:ss a'}}</span></p> 
                                </div>
                                <div class="col-md-6" style="padding-left: 2rem;">
                                    <p class="mb-0" style="font-size: 25px;word-wrap: break-word;"><strong>{{couponDetails?.discount_name}}</strong> </p>
                                    <p class="mb-0 text-ml ml-2"><strong>Code :</strong><span>{{couponDetails?.coupon_code}}</span></p>
                                    <p class="mb-0 text">Get RS <span>{{ couponDetails?.flat_discount }}</span> instant discount</p>
                                    <div style="overflow-y: auto; max-height: 110px; overflow-x: hidden;" [innerHTML]="couponDetails?.discount_description">                                 
                                    </div>                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>