import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Inputdata } from '../../interfaces/product';
import { ModelService } from '../../services/REST/model.service';

@Component({
  selector: 'app-productmodel',
  templateUrl: './productmodel.component.html',
  styleUrls: ['./productmodel.component.scss']
})
export class ProductmodelComponent implements OnInit {
  allModelData: any;  
  activeModel :any;
  
  @Input() partNumber: any;
  @Input() Compatiblemodeldata: any;

  @Output() closeModelPopUp = new EventEmitter<any>();
  @Output() showsubModel = new EventEmitter<any>();

  constructor( private model: ModelService,) { }

  ngOnInit(): void {
    this.allModelData = [];
    this.allModelData = this.Compatiblemodeldata;
    //this.showModels();
  }

  async showModels() {
    this.allModelData = [];
    const ListInput: Inputdata = {} as Inputdata;
    ListInput.offset = 0;
    ListInput.limit = 100;
    ListInput.dropdown_type = "model_cat";
    let response = await this.model.getModelCategory(ListInput);
    if (response["success"] == true) {
      this.allModelData = response["data"]["Models"]
      this.allModelData = this.allModelData.sort((a, b) => {
        if (a.model_name < b.model_name) return -1;
        else if (a.model_name > b.model_name) return 1;
        else return 0;
      });
    }
    else {
    }
    // this.model.getAll().subscribe(data => {
    //   if (data.success) {
    //     try {
    //       var datas = data.result.sort();
    //       for (var i = 0; i < datas.length; i++) {
    //         this.allModelData.push(this.FormNewData(datas[i].Model_Cat_s));
    //       }
         
    //     }
    //     catch (error) {
  
    //     }
    //   } else {

  
    //   }
    // }, error => {
    // })
  }
  FormNewData(data): ModelData {
    const objdata: ModelData = {} as ModelData;
    objdata.LINE_2_s = data;
    return objdata;
  }
  closeModal(){
    this.closeModelPopUp.emit();
}

async GetsubModelDeails(item) {
 //// this.activeModel =item.model_name;
  //this.closeModelPopUp.emit();
  this.showsubModel.emit(item);
}
}
export interface ModelData {
  LINE_2_s: string;
}