import { Injectable, PLATFORM_ID, Inject } from "@angular/core";
import * as CryptoJS from "crypto-js";
import { isPlatformBrowser } from "@angular/common";
import { Router } from "@angular/router";
import { environment } from '../../../../src/environments/environment';
@Injectable({ providedIn: "root" })
export class secureStorage {

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private router: Router
    ) { }
   
    setItem(key: any, value: any) {
        if (isPlatformBrowser(this.platformId)) {
            let valuenew = CryptoJS.AES.encrypt(value, "chair@tata$987");
            localStorage.setItem(key, valuenew.toString());

            // this.storageSub.next('changed');
            return null;
        } else {
            return null;
        }
    }

    getItem(key: any) {
        if (isPlatformBrowser(this.platformId)) {
            let valuenew = localStorage.getItem(key);
            if (valuenew) {
                let data = CryptoJS.AES.decrypt(valuenew, "chair@tata$987");
                data = data.toString(CryptoJS.enc.Utf8);
                return data;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    clear() {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.clear();
            window.location.reload();
            localStorage.setItem("relogin", "true");
            const APP_VERSION = environment?.version;
            localStorage.setItem('APP_VERSION', APP_VERSION);
        } else {
            return null;
        }
        this.router.navigateByUrl("");
    }
    
    removeitems(key: any) {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem(key);
                return null;
           
        } else {
            return null;
        }
    }
}
